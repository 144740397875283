import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";
import { supabaseSingleOverride } from "./supabaseDataModifiers";
import { ProcessType, UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { SnLookupRpc } from "../../features/sn-lookup/types";
import { ComponentProcessLink, ProcessEntry, UniqueIdentifier } from "@shared/types/databaseTypes";
import { getRpcWithRetries } from "./supabaseGeneral";

// ------------------- READ FUNCTIONS ------------------ //

export const fetchSnLookupPageData = async (unique_identifier_id: string): Promise<SnLookupRpc> => {
  const { data, error } = await getRpcWithRetries<SnLookupRpc>("sn_lookup_page", { root_unique_identifier_id: unique_identifier_id });

  if (error || !data) {
    console.error(error);
    return {
      unique_identifier: null,
      process_entries: [],
      genealogy: [],
    };
  }

  return data as SnLookupRpc;
};

export const fetchParentLinks = async (childId: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { data: queryData, error: uniqueIdentifierError } = (await supabase
      .from("unique_identifier_links")
      .select("*, unique_identifiers:unique_identifier_id(*)")
      .eq("has_child_of_id", childId)) as any;
    if (uniqueIdentifierError || !queryData || queryData.length === 0) {
      return null;
    }

    return queryData;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchParentUniqueIdentifier = async (childIdentifier: string): Promise<UniqueIdentifier | null> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Process Data Query
    const { data: queryData, error: uniqueIdentifierError } = (await supabase
      .from("unique_identifier_links")
      .select("unique_identifier:unique_identifier_id(*)")
      .eq("is_active", true)
      .eq("has_child_of_id", childIdentifier)) as any;
    if (uniqueIdentifierError || !queryData || queryData.length === 0) {
      return null;
    }

    const partNumberId = queryData[0].unique_identifier?.part_number_id;
    if (partNumberId) {
      const { data: partNumberData } = (await supabase.from("part_numbers").select("*").eq("id", partNumberId)) as any;
      if (partNumberData) {
        queryData[0].unique_identifier.part_number = partNumberData[0];
      }
    }
    return queryData[0].unique_identifier;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchLotLinks = async (lotCodeId: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  // Query
  let { data: queryData, error } = await supabase
    .from("unique_identifier_links")
    .select(
      "id, unique_identifiers:unique_identifier_id!inner(id, identifier, component_id, status), process_entries:process_entry_id(timestamp)",
    ) // use !inner to make the .eq and .neq filters work on the joined tables
    .neq("unique_identifiers.is_archived", true)
    .eq("has_child_of_id", lotCodeId)
    .eq("is_active", true);

  // Handle request  and DB errors
  if (error || !queryData) {
    console.log(error);
    return [];
  }

  // remove any duplicate elements with the same unique identifier id
  queryData = queryData.filter(
    (link: any, index: number, self: any) =>
      self.findIndex((t: any) => {
        return t.unique_identifiers.id === link.unique_identifiers.id;
      }) === index,
  );

  return queryData;
};

// finds the next and previous unique identifiers based on the last_updated_at field
export const fetchNextAndPrevUniqueIdentifiers = async (uniqueIdentifier: UniqueIdentifier) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // two queries to get next and previsou unique identifiers
    const [prevUniqueIdentifier, nextUniqueIdentifier] = await Promise.all([
      supabase
        .from("unique_identifiers")
        .select("*")
        .eq("component_id", uniqueIdentifier.component_id)
        .gt("last_updated_at", uniqueIdentifier.last_updated_at)
        .order("last_updated_at", { ascending: true })
        .limit(1),
      supabase
        .from("unique_identifiers")
        .select("*")
        .eq("component_id", uniqueIdentifier.component_id)
        .lt("last_updated_at", uniqueIdentifier.last_updated_at)
        .order("last_updated_at", { ascending: false })
        .limit(1),
    ]);

    const { data: prevUniqueIdentifierData, error: prevUniqueIdentifierError } = supabaseSingleOverride(prevUniqueIdentifier, true);
    const { data: nextUniqueIdentifierData, error: nextUniqueIdentifierError } = supabaseSingleOverride(nextUniqueIdentifier, true);
    if (prevUniqueIdentifierError) {
      console.error("previousUniqueIdentifierError: ", prevUniqueIdentifierError);
    }
    if (nextUniqueIdentifierError) {
      console.error("nextUniqueIdentifierError: ", nextUniqueIdentifierError);
    }

    return [prevUniqueIdentifierData, nextUniqueIdentifierData];
  } catch (error) {
    console.error(error);
  }
};

// function for getting all processes of a component from the backend
export const fetchProcessData = async (componentId: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Process Data Query
    let { data: queriedProcessData, error } = await supabase
      .from("component_process_links")
      .select("order, processes:processes( * )")
      .eq("component_id", componentId)
      .eq("is_active", true)
      .order("order", { ascending: true });

    if (queriedProcessData) {
      // flatten query results to convert from [{processes:{...}}, {processes:{...}}] -> [{...}, {...}]
      queriedProcessData = queriedProcessData.map((link, index) => {
        return { ...link.processes, order: index };
      }) as any;
      // filter out any processes that are not production processes
      queriedProcessData = queriedProcessData?.filter((process: any) => process.type === ProcessType.Production) as any;
      // sort by order
      queriedProcessData = queriedProcessData?.map((process, index) => {
        return { ...process, order: index };
      }) as any;
    }

    // Handle request and DB errors
    if (error) {
      console.log("Error is thrown for all components without any processes...");
      console.log(error);
    }

    // return
    return queriedProcessData;
  } catch (error) {
    console.error(error);
  }
};

// ------------------- WRITE FUNCTIONS ------------------ //

export const updateProcessEntry = async (processEntry: ProcessEntry) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Query
    const { data: processEntryData, error: processEntryError } = await supabase
      .from("process_entries")
      .update(processEntry)
      .eq("id", processEntry.id)
      .select()
      .single();

    // Handle request  and DB errors
    if (processEntryError) {
      console.log(processEntryError);
    }
    return processEntryData;
  } catch (error) {
    console.error(error);
  }
};

export const updateUniqueIdentifierStatus = async (uniqueIdentifier: UniqueIdentifier) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Get data to check if all the mandatory processes have been completed
    const [{ data: processEntries, error: processEntriesError }, { data: allProcessLinks, error: allProcessLinksError }] =
      await Promise.all([
        supabase
          .from("process_entries")
          .select("*")
          .eq("unique_identifier_id", uniqueIdentifier.id)
          .order("timestamp", { ascending: true })
          .returns<ProcessEntry[]>(),
        supabase
          .from("component_process_links")
          .select("order, process_id, processes:processes(is_mandatory)")
          .eq("component_id", uniqueIdentifier.component_id)
          .eq("is_active", true)
          .order("order", { ascending: true })
          .returns<ComponentProcessLink[]>(),
      ]);
    if (processEntriesError || allProcessLinksError) {
      console.log("Error getting check status supplemenatry data");
      console.log(processEntriesError);
      console.log(allProcessLinksError);
    }

    // get list of all processses in the list of process entries
    let uniqueProcesses = processEntries?.map((processEntry) => processEntry.process_id);
    // change list into a set
    uniqueProcesses = [...new Set(uniqueProcesses)];

    // Compute if all mandatory processes have been completed
    const mandatoryProcessIds = allProcessLinks?.filter((link: any) => link?.processes?.is_mandatory).map((link) => link.process_id);
    const allMandatoryProcessesCompleted = mandatoryProcessIds?.every((id) => uniqueProcesses?.includes(id));

    // map uniqueProcesses to latestEntryIsPass which should be the value of is_pass for the latest process entry
    const latestEntryIsPass = uniqueProcesses.map((processId) => {
      const latestEntry = processEntries?.filter((processEntry) => processEntry.process_id === processId).pop();
      return latestEntry?.is_pass;
    });

    let newStatus = uniqueIdentifier.status;

    // if all none of the latest process entries are false then the status should be COMPLETE or WIP dependeing on if all mandatory processes have been completed
    if (latestEntryIsPass.every((isPass) => isPass === true || isPass === null)) {
      newStatus = allMandatoryProcessesCompleted ? UniqueIdentifierStatus.Complete : UniqueIdentifierStatus.Wip;
    }
    // if any of the latest process entries are false then the status should be DEFECTIVE
    else if (latestEntryIsPass.some((isPass) => isPass === false)) {
      newStatus = UniqueIdentifierStatus.Defective;
    }

    const { data: uniqueIdentifierData, error: uniqueIdentifierError } = await supabase
      .from("unique_identifiers")
      .update({ status: newStatus })
      .eq("id", uniqueIdentifier.id)
      .select()
      .single();

    if (processEntriesError) {
      console.error(uniqueIdentifierError);
      return { ...uniqueIdentifierError, error: true };
    }

    return uniqueIdentifierData;
  } catch (error) {
    console.error(error);
  }
};

export const setUniqueIdentifierArchiveFlag = async (uniqueIdentifierId: string, value: boolean) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: uniqueIdentifierData, error: uniqueIdentifierError } = await supabase
    .from("unique_identifiers")
    .update({ is_archived: value })
    .eq("id", uniqueIdentifierId)
    .select()
    .single();

  if (uniqueIdentifierError) {
    console.error(uniqueIdentifierError);
    return { ...uniqueIdentifierError, error: true };
  }

  return { data: uniqueIdentifierData, error: uniqueIdentifierError };
};
