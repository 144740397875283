import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import UserAvatar from "@shared/components/UserAvatar";
import { formatTimeAgo } from "@shared/utils/time";
import { WorkOrderActivity } from "@shared/types/databaseTypes";
import { WorkOrderActivityType } from "@shared/types/databaseEnums";
import TypeTag from "@shared/components/TypeTag";
import WorkOrderStatusTag from "./WorkOrderStatusTag";
import { workOrderStatusStyle } from "../contants";
import { useTranslation } from "react-i18next";

interface WorkOrderActivityItemProps {
  activity: WorkOrderActivity;
  isLast: boolean;
}

const WorkOrderActivityItem: React.FC<WorkOrderActivityItemProps> = ({ activity, isLast }) => {
  const users = useSelector((state: RootState) => state.db.users);
  const { t } = useTranslation();
  const user = useMemo(() => users.find((u) => u.supabase_uid === activity.added_by_user_id), [users, activity.added_by_user_id]);

  let activityBody = (
    <>
      <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
      <span className="text-serial-palette-600 mr-1.5 text-sm">{activity.message}</span>
      <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
      <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
    </>
  );

  if (activity.type === WorkOrderActivityType.Comment) {
    activityBody = (
      <div className="flex w-full flex-col gap-y-0.5 rounded border px-2 pb-1 pt-0.5 shadow">
        <div className="inline">
          <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name} </span>
          <span className="text-serial-palette-600 mr-1.5 text-sm">commented</span>
          <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
          <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
        </div>
        <span className="text-serial-palette-600 mr-1.5 text-sm">{activity.message}</span>
      </div>
    );
  } else if (activity.type === WorkOrderActivityType.Create) {
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">created {t("workOrder").toLowerCase()}</span>
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  } else if (activity.type === WorkOrderActivityType.SetStatus) {
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">set status to</span>
        {activity.data.status && (
          <div className="mr-1 inline-flex w-[20px] overflow-clip">
            <WorkOrderStatusTag status={activity.data.status} size={20} />
          </div>
        )}
        {activity.data.status && (
          <span className="text-serial-palette-600 mr-1.5 text-sm font-bold">{workOrderStatusStyle[activity.data.status].name}</span>
        )}
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  } else if (activity.type === WorkOrderActivityType.UpdateWODetails) {
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">updated {t("workOrder").toLowerCase()} details</span>
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  } else if (activity.type === WorkOrderActivityType.SetApproval) {
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">set approval to</span>
        <div className="mr-1 inline-flex w-24 overflow-clip">
          <TypeTag type={activity.data?.approval_status ?? "PENDING"} className="w-24 text-[9px]" />
        </div>
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  } else if (activity.type === WorkOrderActivityType.RequestApproval) {
    const approver = users.find((u) => u.supabase_uid === activity.data?.approver_user_id);
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">requested approval from</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm font-bold">{approver?.first_name}</span>
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  } else if (activity.type === WorkOrderActivityType.RemoveApproval) {
    const approver = users.find((u) => u.supabase_uid === activity.data?.approver_user_id);
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">removed approval from</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm font-bold">{approver?.first_name}</span>
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  } else if (activity.type === WorkOrderActivityType.AddAttachment) {
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">added</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm font-bold">{activity.data.attachement_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">as an attachment</span>
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  } else if (activity.type === WorkOrderActivityType.RemoveAttachment) {
    activityBody = (
      <>
        <span className="text-serial-palette-700 mr-1.5 text-sm font-bold">{user?.first_name}</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm">removed</span>
        <span className="text-serial-palette-600 mr-1.5 text-sm font-bold">{activity.data.attachement_name}</span>
        <span className="text-serial-palette-400 mr-1.5 text-xs">•</span>
        <span className="text-serial-palette-500 mr-1.5 text-sm">{formatTimeAgo(activity.added_at)}</span>
      </>
    );
  }

  return (
    <div className="relative flex w-full flex-col">
      <div className={`ml-[14px] inline w-full items-center gap-1.5 pb-2 pl-5 pr-5 pt-[1px] ${!isLast && "border-l"}`}>{activityBody}</div>
      <div className="absolute left-0 bg-white p-0.5">
        <UserAvatar user={user} size="xs" />
      </div>
    </div>
  );
};

export default WorkOrderActivityItem;
