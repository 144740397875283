import { ComponentType } from "@shared/types/databaseEnums";
import {
  Company,
  Station,
  UniqueIdentifier,
  Component,
  Process,
  Dataset,
  ProcessEntry,
  UniqueIdentifierLink,
  Image,
  Checkbox,
  TimestamptzString,
  File,
  Operator,
  ParametricQualitative,
  ParametricQuantitative,
  PartNumber,
  DatetimeData,
} from "@shared/types/databaseTypes";

// ------------------- PAGE LEVEL TYPES ------------------- //

export enum SnLookupTabOptions {
  Timeline = "Timeline",
  ProcessEntries = "Process Entries",
  Images = "Images",
}

export enum SnLookupRepeatProcessFilterOptions {
  AllData = "All Data",
  FirstOnly = "First Only",
  LastOnly = "Last Only",
}

export interface SnLookupNav {
  [key: string]: {
    heading: SnLookupTabOptions;
    count: number;
  };
}

export interface SnLookupFilterOptions {
  [key: string]: boolean;
}

export interface GenealogyTreeItem {
  id?: string;
  identifier?: string;
  component_id: string;
  component_type: string;
  component_name: string;
  status: string | null;
  part_number?: PartNumber;
  created_at?: TimestamptzString;
  children: GenealogyTreeItem[];
}

export interface ProcessWithEntries extends Process {
  entries: ProcessEntryAllData[];
}

// ------------------- Timeline Interfaces ------------------- //

export enum SnLookupTimelineType {
  ProcessEntryGroup = "PROCESS_ENTRY_GROUP",
  Creation = "CREATION",
  Property = "PROPERTY",
  Comment = "COMMENT",
  AssignPartNumber = "ASSIGN_PART_NUMBER",
  AssignWorkOrder = "ASSIGN_WORK_ORDER",
}

export interface SnLookupTimeline {
  type: SnLookupTimelineType;
  timestamp: TimestamptzString;
  userId?: string;
  operatorId?: string;
  componentId?: string;
  processEntries?: ProcessEntryAllData[];
}

export interface SnLookupTimelineProcessEntryGroup extends SnLookupTimeline {
  type: SnLookupTimelineType.ProcessEntryGroup;
  processEntries: ProcessEntryAllData[];
}

export interface SnLookupTimelineCreation extends SnLookupTimeline {
  type: SnLookupTimelineType.Creation;
  uniqueIdentifier: UniqueIdentifier | null;
}

export interface SnLookupTimelineProperty extends SnLookupTimeline {
  type: SnLookupTimelineType.Property;
  key: string;
  value: string;
}

export interface SnLookupTimelineComment extends SnLookupTimeline {
  type: SnLookupTimelineType.Comment;
  comment: string;
}

export interface SnLookupTimelineAssignPartNumber extends SnLookupTimeline {
  type: SnLookupTimelineType.AssignPartNumber;
  part_number_id: string;
}

export interface SnLookupTimelineAssignWorkOrder extends SnLookupTimeline {
  type: SnLookupTimelineType.AssignWorkOrder;
  work_order_id: string;
}

export interface SnLookupTimelineMonthGroup {
  month: string;
  activity: SnLookupTimeline[];
}

export interface SnLookupTimelineGroup {
  year: string;
  months: SnLookupTimelineMonthGroup[];
}

// ------------------- SUPABASE RPC & COMPOSITE TYPES ------------------- //

export interface SnLookupRpc {
  genealogy: GenealogyFamilyMember[];
  process_entries: ProcessEntryAllData[];
  unique_identifier: UniqueIdentifier | null;
}

export interface GenealogyFamilyMember {
  id: string; // id of unique_identifier table
  component_id: string;
  component_name: string;
  component_type: ComponentType;
  identifier: string;
  parent_id: string; // id of unique_identifiers table
  status: string | null;
  part_number?: PartNumber;
  created_at: TimestamptzString;
  link: UniqueIdentifierLink | null; // null for the root node
  process_entry_index_map: { [id: string]: { index: number; process_id: string } };
}

export interface ProcessEntryAllData {
  id: string;
  is_pass: boolean | null;
  timestamp: TimestamptzString;
  company_id: string;
  process_id: string;
  station_id: string | null;
  operator_id: string | null;
  override_user_id: string | null;
  process_revision: number | null;
  unique_identifier_id: string;
  cycle_time: number | null;
  operator: Operator;
  station?: Station;
  component?: Component;
  process?: Process;
  textData: ParametricQualitative[];
  numericalData: ParametricQuantitative[];
  linkData: UniqueIdentifierLink[];
  fileData: File[];
  imageData: Image[];
  checkboxData: Checkbox[];
  datetimeData: DatetimeData[];
  testCount: number; // 2 means it's the 3rd occurrence of the process, second time it's been retested
}

export type AllDataTypes = ParametricQualitative | ParametricQuantitative | UniqueIdentifierLink | Image | File | Checkbox;

// Combination of: 'parametric_qualitative' and 'parametric_quantitative'
export interface Parametric {
  id: string;
  company_id: string;
  process_entry_id: string;
  unique_identifier_id: string;
  value: number | string;
  dataset_id: string;
  created_at: TimestamptzString;
  // quantitative vs qualitative flag (not stored in database):
  is_quantitative?: boolean;
  // quantitative data only:
  is_discrete?: boolean | null;
  is_pass?: boolean | null;
  lsl?: number | null;
  usl?: number | null;
  unit?: string | null;
  // foreign key joins
  dataset?: Dataset;
  company?: Company;
  process_entry?: ProcessEntry;
  unique_identifier?: UniqueIdentifier;
}

export interface ParametricQuantitativeWithProcessEntry extends ParametricQuantitative {
  process_entry: ProcessEntry;
}

export interface ParametricQualitativeWithProcessEntry extends ParametricQualitative {
  process_entry: ProcessEntry;
}

export interface UniqueIdentifierLinkWithProcessEntry extends UniqueIdentifierLink {
  process_entry: ProcessEntry;
}

export interface CheckboxWithProcessEntry extends Checkbox {
  process_entry: ProcessEntry;
}

export interface DateTimeWithProcessEntry extends DatetimeData {
  process_entry: ProcessEntry;
}
