import store from "@shared/redux/store";
import { getSupabase } from "../../../shared/connections/supabaseAuth";
import moment from "moment";
import { StationEntriesGraphData } from "../types";
import { ComponentProcessLink, ProcessEntry, Station, StationProcessLink } from "@shared/types/databaseTypes";
import { ProcessType } from "@shared/types/databaseEnums";
import { getRpcWithRetries } from "../../../shared/connections/supabaseGeneral";

// ------------------- READ FUNCTIONS ------------------- //

export const fetchStationLatestProcessEntries = async (stationId: string, limit: number): Promise<ProcessEntry[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);
  const { data, error } = await supabase
    .from("process_entries")
    .select("*, unique_identifier:unique_identifier_id(*), process:processes(*)")
    .eq("station_id", stationId)
    .order("created_at", { ascending: false })
    .limit(limit)
    .returns<ProcessEntry[]>();

  if (error || !data) {
    console.error(error);
    return [];
  }

  return data;
};

export const fetchStationProcessLinks = async (stationId: string): Promise<StationProcessLink[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data, error } = await supabase
    .from("station_process_links")
    .select("*, process:processes(*)")
    .eq("station_id", stationId)
    .returns<StationProcessLink[]>();

  if (error || !data) {
    console.error(error);
    return [];
  }

  return data;
};

export const fetchStationEntriesGraphData = async (stationId: string): Promise<StationEntriesGraphData | null> => {
  // Generates an array of objects representing the time ranges for every hour of the last 48hrs in timestamptz format.
  // The last value of the array should be the time between the last hour (ex 14:00:00) and the current time (ex 14:38:23)
  // [
  //   {start: timestamptz, end: timestamptz},
  //   {start: timestamptz, end: timestamptzt},
  //   ...
  // ]

  const now = new Date(); // current date and time
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 0, 0, 0); // round down to current hour
  const start = new Date(end.getTime() - 48 * 60 * 60 * 1000); // 48 hours ago

  const timeRanges = [];

  for (let i = start.getTime(); i <= end.getTime(); i += 60 * 60 * 1000) {
    const rangeStart = new Date(i);
    const rangeEnd = new Date(i + 60 * 60 * 1000); // end of range is end of hour
    timeRanges.push({ start: rangeStart.toISOString(), end: rangeEnd.toISOString() });
  }

  const lastRange = timeRanges[timeRanges.length - 1];
  lastRange.end = now.toISOString(); // last range end time is current time

  const request = {
    station_id: stationId,
    time_ranges: timeRanges,
  };

  const { data, error } = await getRpcWithRetries<StationEntriesGraphData>("stations-graph-entires", { request });

  if (error || !data) {
    console.error(error);
    return null;
  }
  return data as StationEntriesGraphData;
};

export const fetchAllComponentProcessLinks = async (): Promise<ComponentProcessLink[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  // returns only active links of active components
  const { data, error } = await supabase
    .from("component_process_links")
    .select("*, component:component_id!inner(*), process:processes!inner(*)") // use !inner to make the .eq and .neq filters work on the joined tables
    .eq("component.is_active", true)
    .neq("process.type", ProcessType.Instantiation)
    .eq("is_active", true)
    .returns<ComponentProcessLink[]>();

  if (error) {
    console.error(error);
    return [];
  }

  return data;
};

// ------------------- WRITE FUNCTIONS ------------------- //

export const updateStation = async (stationId: string, stationData: any): Promise<Station | null> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: updatedStation, error: updatedStationError } = await supabase
    .from("stations")
    .update(stationData)
    .eq("id", stationId)
    .select()
    .single();

  if (updatedStationError) {
    console.log("Could not update station");
    console.log(updatedStationError);
    return null;
  }
  return updatedStation as Station;
};

export const insertStationProcessLink = async (stationId: string, processId: string, componentId: string): Promise<void> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  if (!state.db.company) {
    console.error("No company found in state");
    return;
  }

  const newLink = {
    company_id: state.db.company.id,
    station_id: stationId,
    component_id: componentId,
    process_id: processId,
  };

  const { error: insertedLinkError } = await supabase.from("station_process_links").insert([newLink]).select().single();

  if (insertedLinkError) {
    console.error("Could not insert new station process link");
    console.log(insertedLinkError);
  }
};

// function to link all processes to a station
// this is used for universal stations
export const linkAllProcesses = async (stationId: string): Promise<void> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Delete all existing links
    const { error: deletedLinkError } = await supabase.from("station_process_links").delete().eq("station_id", stationId);
    if (deletedLinkError) {
      console.log("Could not delete station process link");
      console.log(deletedLinkError);
    }

    // Get all component process links
    const { data: componentProcessLinks, error: componentProcessLinksError } = await supabase
      .from("component_process_links")
      .select("*")
      .eq("is_active", true);
    if (componentProcessLinksError || !componentProcessLinks) {
      console.log("Could not get component process links");
      console.log(componentProcessLinksError);
      return;
    }

    // Insert new station process links
    componentProcessLinks.forEach((link) => {
      insertStationProcessLink(stationId, link.process_id, link.component_id);
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteStationProcessLink = async (id: string): Promise<void> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { error: deletedLinkError } = await supabase.from("station_process_links").delete().eq("id", id);

  if (deletedLinkError) {
    console.error("Could not delete station process link");
    console.log(deletedLinkError);
  }
};

export const deleteStation = async (stationId: string): Promise<void> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  // first check to make sure there are no process_entries with a foreign key to this station
  const { data: processEntries, error: processEntriesError } = await supabase.from("process_entries").select().eq("station_id", stationId);
  if (processEntriesError) {
    console.error("Could not fetch process entries");
    console.log(processEntriesError);
    return;
  }
  if (processEntries.length > 0) {
    console.error("Cannot delete station with process entries");
    return;
  }

  // delete all station process links with this station id
  const { error: deletedLinksError } = await supabase.from("station_process_links").delete().eq("station_id", stationId);
  if (deletedLinksError) {
    console.error("Could not delete station process links");
    console.log(deletedLinksError);
    return;
  }

  // delete the station
  const { error: deletedStationError } = await supabase.from("stations").delete().eq("id", stationId);

  if (deletedStationError) {
    console.error("Could not delete station");
    console.log(deletedStationError);
  }
};

export const createStation = async (): Promise<Station | null> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  if (!state.db.company) {
    console.error("No company found in state");
    return null;
  }

  // New station_process_links payload
  const newStation = {
    company_id: state.db.company.id,
    // name the new station with today's date and time
    name: `New Station ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
    use_api: false,
    device_id: null,
    // is_active is set automatically
    // is_locked is set automatically
  };
  // Write to supabase
  const { data: insertedStation, error: insertedStationError } = await supabase.from("stations").insert([newStation]).select().single();
  if (insertedStationError) {
    console.log("Could not insert new station");
    console.log(insertedStationError);
    return null;
  } else {
    return insertedStation as Station;
  }
};
