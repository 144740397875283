import TagKeyValue from "@shared/components/primitives/TagKeyValue";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import moment from "moment";

const FrontendPrimitivesTagsKeyValue = () => {
  return (
    <FrontendPrimitivesCard title="Key Value Tags">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Sizes</div>
        <div className="flex flex-wrap items-start gap-2">
          {["xs", "sm", "md", "lg"].map((size) => (
            <TagKeyValue.Root key={size} size={size as "xs" | "sm" | "md" | "lg"}>
              <TagKeyValue.Key>Key</TagKeyValue.Key>
              <TagKeyValue.Value>Value</TagKeyValue.Value>
            </TagKeyValue.Root>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Inheriting the Style from Basic Tags</div>
        <div className="flex flex-wrap items-start gap-2">
          <TagKeyValue.Root variant="PARAMETRIC_QUANTITATIVE">
            <TagKeyValue.Key>USL</TagKeyValue.Key>
            <TagKeyValue.Value>23.1</TagKeyValue.Value>
          </TagKeyValue.Root>
          <TagKeyValue.Root variant="CYCLE_TIME">
            <TagKeyValue.Key>Cycle Time</TagKeyValue.Key>
            <TagKeyValue.Value>19s</TagKeyValue.Value>
          </TagKeyValue.Root>
          <TagKeyValue.Root variant="TIMESTAMP">
            <TagKeyValue.Key>Created At</TagKeyValue.Key>
            <TagKeyValue.Value>{moment().format("MM/DD/YY HH:mm a")}</TagKeyValue.Value>
          </TagKeyValue.Root>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">With tooltip</div>
        <div className="flex w-72 flex-wrap items-start gap-2">
          <TagKeyValue.Root tooltip="Part number with extraordinarily super absurdly long name that nobody would ever use or at least we hope they wouldn't">
            <TagKeyValue.Key>Part Number</TagKeyValue.Key>
            <TagKeyValue.Value>
              Part number with extraordinarily super absurdly long name that nobody would ever use or at least we hope they wouldn't
            </TagKeyValue.Value>
          </TagKeyValue.Root>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesTagsKeyValue;
