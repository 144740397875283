import React from "react";
import { IHeaderGroupParams } from "ag-grid-community";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { Component } from "@shared/types/databaseTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import useMeasures from "@shared/measures/MeasuresProvider";

interface MeasuresGridHeaderGroupCellProps extends IHeaderGroupParams {
  component: Component;
}

const MeasuresGridHeaderGroupCell: React.FC<MeasuresGridHeaderGroupCellProps> = (props) => {
  const { uniqueIdentifiers, componentId, numFilteredUniqueIdentifiers } = useMeasures();

  const groupIsOpen = props.columnGroup.isExpanded();

  return (
    <div className="flex h-full w-full items-center justify-between">
      <div className="flex items-center justify-start overflow-hidden">
        <ComponentAvatar component={props.component} size="sm" />
        <p className="truncate pl-2 font-bold text-black">{props.component?.name}</p>
        {uniqueIdentifiers.length > 0 && componentId === props.component.id && (
          <p className="text-serial-palette-400 pl-3 font-medium">{numFilteredUniqueIdentifiers}</p>
        )}
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        className={`ml-2 cursor-pointer text-black transition-all duration-500 ${groupIsOpen && "rotate-180"}`}
        onClick={() => props.setExpanded(!groupIsOpen)}
      />
    </div>
  );
};

export default MeasuresGridHeaderGroupCell;
