import React, { useContext, useEffect } from "react";
import { ProductionFormGenericFieldProps } from "../../types";
import { ProductionContext } from "../../ProductionProvider";

const ProductionFormPassFail: React.FC<ProductionFormGenericFieldProps> = ({ field, draftData }) => {
  const { handleUpdateDraftSubmission, handleSetFocusedField, focusedFieldId } = useContext(ProductionContext);

  // if field is focused, pressing enter will set the value to true
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (focusedFieldId !== field.id || keyCode !== 13) return;
      handleUpdateDraftSubmission(field.id, { value: true });
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [focusedFieldId, field.id, draftData?.value, handleUpdateDraftSubmission]);

  return (
    <div className="flex w-full items-center">
      <div className="text-serial-palette-500 flex w-1/2 text-sm font-medium">
        {!field.is_optional && <span className="pr-1 text-rose-500">*</span>}
        {field.prompt}
      </div>
      <div className="border-serial-palette-200 flex w-1/2 shrink-0 flex-nowrap justify-end gap-2 pb-1">
        <button
          onFocus={() => handleSetFocusedField(field.id)}
          onClick={() => handleUpdateDraftSubmission(field.id, { value: true })}
          className={`btn w-24 text-lg focus:outline-none focus:ring ${draftData?.value === true ? "text-serial-palette-100 bg-green-600" : "border-serial-palette-200 text-green-600"}`}
        >
          Pass
        </button>
        <button
          tabIndex={-1}
          onClick={() => handleUpdateDraftSubmission(field.id, { value: false })}
          className={`btn w-24 text-lg ${draftData?.value === false ? "text-serial-palette-200 bg-rose-600" : "border-serial-palette-200 text-rose-600"}`}
        >
          Fail
        </button>
      </div>
    </div>
  );
};

export default ProductionFormPassFail;
