import React, { useContext, useMemo } from "react";
import { Dataset } from "@shared/types/databaseTypes";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import SidebarSelector from "@shared/components/primitives/SidebarSelector";
import { cn } from "@shared/utils/tailwind";
import TagBasic from "@shared/components/primitives/TagBasic";

interface ProcessBuilderAutoParsingSelectorItemProps {
  dataset: Dataset;
}

const ProcessBuilderAutoParsingSelectorItem: React.FC<ProcessBuilderAutoParsingSelectorItemProps> = ({ dataset }) => {
  const { draftSchemas, existingSchemas } = useContext(ProcessBuilderContext);

  const relatedSchema = useMemo(() => {
    const draftSchema = draftSchemas.flat().find((schema) => schema.source_dataset_id === dataset.id);
    const existingSchema = existingSchemas.flat().find((schema) => schema.source_dataset_id === dataset.id);
    const schema = draftSchema || existingSchema;
    return schema;
  }, [dataset, draftSchemas, existingSchemas]);

  return (
    <SidebarSelector.Item key={dataset.id} value={dataset.id}>
      <div className="flex w-full items-center justify-between gap-2">
        <span className="truncate font-semibold">{dataset.name}</span>
        <TagBasic variant={dataset.data_type} size="sm" />
      </div>
      <div className={cn("text-sm font-light", relatedSchema ? "text-serial-palette-500" : "text-serial-palette-400")}>
        {relatedSchema ? "Schema Configured" : "No Schema Configured"}
      </div>
    </SidebarSelector.Item>
  );
};

const ProcessBuilderAutoParsingSelector: React.FC = () => {
  const { parseableDatasets, selectedSourceDatasetId, setSelectedSourceDatasetId, setSelectedSchemaId, existingSchemas, draftSchemas } =
    useContext(ProcessBuilderContext);

  const handleSelectSchemaSourceDataset = (datasetId: string | null) => {
    setSelectedSourceDatasetId(datasetId);
    // Check the draft schemas and update the selected schema if it exists
    const draftSchema = draftSchemas.flat().find((schema) => schema.source_dataset_id === datasetId);
    if (draftSchema) {
      setSelectedSchemaId(draftSchema.id);
      return;
    }
    // Check the existing schemas and update the selected schema if it exists
    const schema = existingSchemas.flat().find((schema) => schema.source_dataset_id === datasetId);
    if (schema) {
      setSelectedSchemaId(schema.id);
      return;
    }
    setSelectedSchemaId(null);
  };

  return (
    <SidebarSelector.Root value={selectedSourceDatasetId} onValueChange={handleSelectSchemaSourceDataset}>
      <SidebarSelector.Header className="h-14 justify-center py-0">
        <div className="flex w-full gap-2">
          <span className="font-bold">Auto Parse-Able Files</span>
          <span className="text-serial-palette-400">{parseableDatasets?.length}</span>
        </div>
      </SidebarSelector.Header>
      <SidebarSelector.List>
        {parseableDatasets?.map((dataset) => {
          return <ProcessBuilderAutoParsingSelectorItem key={dataset.id} dataset={dataset} />;
        })}
      </SidebarSelector.List>
    </SidebarSelector.Root>
  );
};

export default ProcessBuilderAutoParsingSelector;
