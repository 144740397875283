import useDarkMode from "@shared/hooks/useDarkMode";
import { RootState } from "@shared/redux/store";
import React, { useMemo } from "react";
import Map from "react-map-gl";
import { useSelector } from "react-redux";
import { useTracking } from "../TrackingProvider";
import Button from "@shared/components/primitives/Button";
import TrackingMarker from "./TrackingMarker";
import TrackingPopup from "./TrackingPopup";
import Combobox from "@shared/components/primitives/Combobox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import useMeasures from "@shared/measures/MeasuresProvider";
import Popover from "@shared/components/primitives/Popover";
import MeasuresFilterBuilder from "@shared/measures/components/filters/MeasuresFilterBuilder";
import mapboxgl from "mapbox-gl";
const MAPBOX_TOKEN = import.meta.env.VITE_APP_MAPBOX_TOKEN;

const TrackingMap: React.FC = () => {
  const components = useSelector((state: RootState) => state.db.components);

  const { uniqueIdentifiers, numFullyDefinedFilters, filterMapping } = useMeasures();
  const { selectedComponent, handleSelectComponent, setMapBounds } = useTracking();
  const { darkMode } = useDarkMode();

  const filteredUniqueIdentifiers = useMemo(() => {
    return uniqueIdentifiers.filter((uniqueIdentifier) => filterMapping[uniqueIdentifier.id] === true);
  }, [uniqueIdentifiers, filterMapping]);

  const defaultBounds = mapboxgl.LngLatBounds.convert([
    [-179.999999, -89.999999],
    [179.999999, 89.999999],
  ]);
  return (
    <div className="relative flex min-h-0 min-w-0 flex-grow bg-white">
      <Map
        id="map"
        onZoomEnd={(event) => setMapBounds(event.target.getBounds() ?? defaultBounds)}
        onDragEnd={(event) => setMapBounds(event.target.getBounds() ?? defaultBounds)}
        initialViewState={{
          latitude: 40,
          longitude: -100,
          zoom: 3.5,
          bearing: 0,
          pitch: 0,
        }}
        style={{ width: "100%", height: "100%" }}
        mapStyle={`mapbox://styles/mapbox/${darkMode ? "dark" : "light"}-v11`}
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {filteredUniqueIdentifiers.map((uniqueIdentifier, index) => (
          <TrackingMarker key={index} uniqueIdentifier={uniqueIdentifier} />
        ))}
        <TrackingPopup />
      </Map>
      <div className="absolute right-3 top-3 flex gap-1">
        <Popover.Root>
          <Popover.Trigger asChild>
            <Button variant={numFullyDefinedFilters > 0 ? "default" : "outline"}>
              <FontAwesomeIcon icon={faFilter} />
              <span>Filters</span>
              {numFullyDefinedFilters > 0 && <span>{numFullyDefinedFilters}</span>}
            </Button>
          </Popover.Trigger>
          <Popover.Content align="end" className="w-[500px] overflow-hidden rounded-md border shadow-lg">
            <MeasuresFilterBuilder />
          </Popover.Content>
        </Popover.Root>
        <Combobox.Root value={selectedComponent?.id ?? null} onValueChange={handleSelectComponent}>
          <Combobox.Trigger>{selectedComponent?.name}</Combobox.Trigger>
          <Combobox.Content>
            {[...components]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((component, index) => (
                <Combobox.Item key={index} value={component.id}>
                  {component.name}
                </Combobox.Item>
              ))}
          </Combobox.Content>
        </Combobox.Root>
      </div>
    </div>
  );
};

export default TrackingMap;
