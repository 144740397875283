import { useMemo } from "react";
import { useTracking } from "../TrackingProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import TagBasic from "@shared/components/primitives/TagBasic";
import ComponentAvatar from "@shared/components/primitives/ComponentAvatar";
import useMeasures from "@shared/measures/MeasuresProvider";
import TrackingSidebarTile from "./TrackingSidebarTile";

const TrackingSidebarContents = () => {
  const { selectedComponent, mapBounds, uniqueIdentifierLocations } = useTracking();
  const { filterMapping, numFullyDefinedFilters, uniqueIdentifiers, numFilteredUniqueIdentifiers } = useMeasures();

  const uniqueIdentifierTiles = useMemo(() => {
    return uniqueIdentifiers
      .filter((uniqueIdentifier) => filterMapping[uniqueIdentifier.id])
      .map((uniqueIdentifier) => {
        const locations = uniqueIdentifierLocations[uniqueIdentifier.id] ?? [];
        const isShowing = locations.some((location) => (mapBounds ? mapBounds?.contains([location.longitude, location.latitude]) : false));
        return { uniqueIdentifier, locations, isShowing };
      })
      .sort((a, b) => {
        if (a.isShowing && !b.isShowing) return -1;
        if (!a.isShowing && b.isShowing) return 1;
        return 0;
      });
  }, [uniqueIdentifiers, filterMapping, uniqueIdentifierLocations, mapBounds]);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col">
      <div className="group relative flex items-center gap-x-3 border-b bg-white p-3">
        <div className="rounded-md border">{selectedComponent && <ComponentAvatar component={selectedComponent} size="md" />}</div>
        <div className="flex flex-col gap-0.5">
          <div className="flex items-end gap-2">
            <span className="text-serial-palette-800 text-xl font-bold">{selectedComponent?.name}</span>
          </div>
          <TagBasic variant={selectedComponent?.component_type ?? "SN"} size="md">
            <TagBasic.Icon />
            <TagBasic.Name />
          </TagBasic>
        </div>
      </div>
      {numFullyDefinedFilters > 0 && (
        <div className="bg-serial-palette-100 flex h-8 w-full shrink-0 items-center justify-center border-b text-sm font-medium italic">
          <FontAwesomeIcon icon={faFilter} className="mr-2" size="xs" />
          <span>{`Showing ${numFilteredUniqueIdentifiers} of ${uniqueIdentifiers.length} Units`}</span>
        </div>
      )}
      <div className="flex min-h-0 w-full flex-grow flex-col gap-y-2 overflow-auto p-3">
        {uniqueIdentifierTiles.map(({ uniqueIdentifier, locations, isShowing }) => (
          <TrackingSidebarTile key={uniqueIdentifier.id} uniqueIdentifier={uniqueIdentifier} locations={locations} isShowing={isShowing} />
        ))}
      </div>
    </div>
  );
};

export default TrackingSidebarContents;
