import TypeTag from "@shared/components/TypeTag";
import React from "react";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { Handle, Position } from "reactflow";
import { COMPONENT_INSTANCE_STATUS_HEIGHT_CLASS_NAME, COMPONENT_INSTANCE_STATUS_WIDTH_CLASS_NAME } from "./constants";

interface ComponentInstanceStatusNodeProps {
  status: UniqueIdentifierStatus;
  hideSourceHandle?: boolean;
  draggable?: boolean;
}

const ComponentInstanceStatusNode: React.FC<{ data: ComponentInstanceStatusNodeProps }> = ({ data }) => {
  return (
    <div className={`${data.draggable ? "" : "cursor-default"}`}>
      {data.status !== UniqueIdentifierStatus.Planned && <Handle type="target" position={Position.Top} isConnectable={false} />}
      <TypeTag
        type={data.status}
        className={`text-[8px] ${COMPONENT_INSTANCE_STATUS_HEIGHT_CLASS_NAME} ${COMPONENT_INSTANCE_STATUS_WIDTH_CLASS_NAME}`}
      />
      {!data.hideSourceHandle && <Handle type="source" position={Position.Bottom} isConnectable={false} />}
    </div>
  );
};

export default ComponentInstanceStatusNode;
