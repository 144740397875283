import React, { useEffect, useState } from "react";
import { faCamera, faRefresh } from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownFull from "@shared/components/dropdowns/DropdownFull";
import Banner2 from "@shared/components/Banner2";

interface WebcamBoxProps {
  handleCaptureImage: () => void;
  handleResetImage: () => void;
  imageUrl: string | null;
  webcamRef: any;
}

const WebcamBox: React.FC<WebcamBoxProps> = ({ handleCaptureImage, handleResetImage, imageUrl, webcamRef }) => {
  const [devices, setDevices] = useState<any>([]);
  const [selected, setSelected] = useState<number>(0);
  const [accessGranted, setAccessGranted] = useState<boolean>(false);
  const [warningBannerOpen, setWarningBannerOpen] = useState<boolean>(true);

  useEffect(() => {
    getUserMedia();
  }, []);

  const getUserMedia = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setAccessGranted(true);
      getVideoInputDevices();
    } catch (error) {
      console.log("Camera access denied");
    }
  };

  const getVideoInputDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter((device) => device.kind === "videoinput");
      setDevices(videoInputDevices);
    } catch (error) {
      console.error("Error getting list of devices:", error);
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center rounded-md text-center">
      {accessGranted ? (
        imageUrl ? (
          <div className="relative">
            <img src={imageUrl} className="rounded-md" />
            <button
              onClick={() => handleResetImage()}
              className="btn absolute right-2 top-2 h-8 gap-1 border-red-600 bg-white text-red-600 hover:bg-red-100"
            >
              <FontAwesomeIcon icon={faRefresh} />
              Reset
            </button>
          </div>
        ) : (
          <div className="-mb-20 flex h-full flex-col items-center justify-center space-y-2 pb-2">
            <Webcam videoConstraints={devices[selected]} ref={webcamRef} className="overflow-crop rounded-md" />
            <button
              className="relative bottom-24 h-16 w-16 rounded-full bg-white bg-opacity-30 ring-4 ring-white focus:ring"
              onClick={() => handleCaptureImage()}
            >
              <FontAwesomeIcon className="text-white" icon={faCamera} size="xl" />
            </button>
          </div>
        )
      ) : (
        <div className="w-full">
          <Banner2 className="w-full p-2 text-center not-italic" type="warning" open={warningBannerOpen} setOpen={setWarningBannerOpen}>
            Click the button below to allow access to the camera. <br /> Camera has not been enabled.
          </Banner2>
          <button tabIndex={-1} className="btn-lg border-serial-palette-200 my-24 bg-white text-green-500" onClick={() => getUserMedia()}>
            Enable Camera
          </button>
        </div>
      )}

      {accessGranted && (
        <div className="my-4">{devices.length > 0 && <DropdownFull options={devices} selected={selected} setSelected={setSelected} />}</div>
      )}
    </div>
  );
};

export default WebcamBox;
