import { getSupabase } from "@shared/connections/supabaseAuth";
import store from "@shared/redux/store";
import { ParametricQuantitative, ProcessEntry, UniqueIdentifier } from "@shared/types/databaseTypes";

export interface ProcessEntryWithData {
  processEntries: ProcessEntry[];
  data: ParametricQuantitative[];
}

export const fetchUniqueIdentifierProcessData = async (processIDs: string[], uniqueIdentifierId: string): Promise<ProcessEntryWithData> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);
  let allData: ParametricQuantitative[] = [];
  let allProcessEntries: ProcessEntry[] = [];

  for (const processID of processIDs) {
    const { data: processEntry, error: processEntryError } = await supabase
      .from("process_entries")
      .select("*, process:processes!inner(*)")
      .eq("unique_identifier_id", uniqueIdentifierId)
      .eq("process.id", processID)
      .order("timestamp", { ascending: false })
      .limit(1);

    if (processEntryError || !processEntry.length) {
      console.log("processEntryError", processEntryError);
      continue;
    }

    allProcessEntries.push(processEntry[0]);
    const processEntryId = processEntry[0].id;

    const { data: numericalData, error: numericalDataError } = await supabase
      .from("parametric_quantitative")
      .select("*, dataset:dataset_id(*)")
      .eq("process_entry_id", processEntryId);

    if (numericalDataError) {
      console.log("DataError", numericalDataError);
      continue;
    }

    allData.push(...numericalData);
  }

  return { processEntries: allProcessEntries, data: allData };
};

export const fetchUniqueIdentifiers = async (componentId: string, workOrderId?: string | null): Promise<UniqueIdentifier[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let query = supabase.from("unique_identifiers").select("*").eq("component_id", componentId).eq("status", "COMPLETE");

  if (workOrderId) {
    query = query.eq("work_order_id", workOrderId);
  }

  const { data: uniqueIdentifiers, error: uniqueIdentifierError } = await query;

  if (uniqueIdentifierError) {
    console.log("uniqueIdentifierError", uniqueIdentifierError);
    return [];
  }

  return uniqueIdentifiers;
};
