import { ComponentType } from "@shared/types/databaseEnums";
import { UniqueIdentifier, UniqueIdentifierLink } from "@shared/types/databaseTypes";
import { GeoLocation } from "../types";

// component instances in serial inherit the location of their parents (since they are physically located there)
// since lot codes can physically be in multiple locations, we need to recursively find the location itself + every parent
export const findLocationsRecursive = (
  uniqueIdentifier: UniqueIdentifier,
  uniqueIdentifierLinks: UniqueIdentifierLink[],
  componentType: ComponentType,
): GeoLocation[] => {
  const location: { latitude: number; longitude: number }[] = [];
  if (uniqueIdentifier.latitude && uniqueIdentifier.longitude) {
    // if location is found, add it to the locations array
    location.push({ latitude: uniqueIdentifier.latitude, longitude: uniqueIdentifier.longitude });
    if (componentType === ComponentType.Sn) return location; // stop recursion if we are at a SN. Continue for LOTs since they can have more than one location
  }
  const parents = uniqueIdentifierLinks.filter((link) => link.has_child_of_id === uniqueIdentifier.id);
  // recursively find the location of each parent
  const parentLocations = parents.flatMap((parent) => {
    const parentUniqueIdentifier = parent.unique_identifiers;
    if (!parentUniqueIdentifier) return [];
    return findLocationsRecursive(parentUniqueIdentifier, uniqueIdentifierLinks, componentType);
  });
  return [...location, ...parentLocations];
};
