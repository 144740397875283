import {
  Company,
  Component,
  ComponentProcessLink,
  Dataset,
  PartNumber,
  Process,
  Station,
  UniqueIdentifier,
  WorkOrder,
} from "@shared/types/databaseTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useEffect, useState } from "react";

export interface ProductionAppLocalDb {
  isLoaded: boolean;
  company: Company | undefined;
  components: Component[];
  processes: Process[];
  componentProcessLinks: ComponentProcessLink[];
  workOrders: WorkOrder[];
  partNumbers: PartNumber[];
  stations: Station[];
  componentInstances: UniqueIdentifier[];
  datasets: Dataset[];
}

const useProductionAppLocalDb = (freezeDb: boolean): ProductionAppLocalDb => {
  // NOTE: Eventually the goal is to make the production app use only the Serial API for getting / setting data.
  //       This will allow us to have more sophisticated authentication logic than provided by supabase RSL.
  //       For now this file may contain functions that access redux or supabase directly.
  //       Functions should be refactored after all production app APIs have been implemented
  const [db, setDb] = useState<ProductionAppLocalDb>({
    isLoaded: false,
    company: undefined,
    components: [],
    processes: [],
    componentProcessLinks: [],
    partNumbers: [],
    workOrders: [],
    stations: [],
    componentInstances: [],
    datasets: [],
  });

  const isLoaded = useSelector((state: RootState) => state.db.isLoaded);
  const company = useSelector((state: RootState) => state.db.company);
  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const partNumbers = useSelector((state: RootState) => state.db.partNumbers);
  const workOrders = useSelector((state: RootState) => state.db.workOrders);
  const stations = useSelector((state: RootState) => state.db.stations);
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  useEffect(() => {
    if (freezeDb) return;
    setDb({
      isLoaded,
      company: company.id ? company : undefined,
      components,
      processes,
      componentProcessLinks,
      partNumbers,
      workOrders,
      stations,
      componentInstances,
      datasets,
    });
  }, [
    isLoaded,
    company,
    components,
    processes,
    componentProcessLinks,
    partNumbers,
    workOrders,
    stations,
    componentInstances,
    datasets,
    freezeDb,
  ]);
  return db;
};

export default useProductionAppLocalDb;
