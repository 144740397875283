import { makeFetchRequest } from "@shared/connections/api/helpers";
import { ParsedResponse, ProcessDataType } from "@shared/types/apiTypes";
import { ProcessEntry } from "@shared/types/databaseTypes";

const API_URL = import.meta.env.VITE_APP_SERIAL_API_URL;

export const createProcessEntry = async (
  componentInstanceId: string,
  processId: string,
  timestamp?: string,
  station_id?: string,
  operatorId?: string,
): Promise<ParsedResponse<ProcessEntry>> => {
  return makeFetchRequest(
    `${API_URL}/processes/entries`,
    {
      method: "POST",
      body: JSON.stringify({
        component_instance_id: componentInstanceId,
        process_id: processId,
        timestamp: timestamp,
        station_id: station_id,
        operator_id: operatorId,
      }),
    },
    "Could not create process entry",
  );
};

interface AddProcessEntryDataOptionalParams {
  value?: string;
  expectedValue?: string | null;
  usl?: string;
  lsl?: string;
  fileId?: string;
  fileName?: string;
}

export const addProcessEntryData = async (
  processEntryId: string,
  type: ProcessDataType,
  datasetId: string,
  data: AddProcessEntryDataOptionalParams,
): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/processes/entries/${processEntryId}`,
    {
      method: "PUT",
      body: JSON.stringify({
        type: type,
        dataset_id: datasetId,
        value: data.value,
        expected_value: data.expectedValue,
        usl: data.usl,
        lsl: data.lsl,
        file_id: data.fileId,
        file_name: data.fileName,
      }),
    },
    "Could not add process entry data",
  );
};

export const updateProcessEntry = async (
  processEntryId: string,
  isComplete?: boolean,
  cycleTime?: number,
  isPass?: boolean,
): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/processes/entries/${processEntryId}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        is_complete: isComplete,
        cycle_time: cycleTime,
        is_pass: isPass,
      }),
    },
    "Could not update process entry",
  );
};

export const createComponentInstanceLink = async (
  parentComponentInstanceId: string,
  childComponentInstanceId: string,
  processEntryId: string,
  datasetId: string,
  breakPriorLinks?: boolean,
): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/components/instances/links`,
    {
      method: "PUT",
      body: JSON.stringify({
        parent_component_instance_id: parentComponentInstanceId,
        child_component_instance_id: childComponentInstanceId,
        process_entry_id: processEntryId,
        dataset_id: datasetId,
        break_prior_links: breakPriorLinks,
      }),
    },
    "Could not create component instance link",
  );
};
