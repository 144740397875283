import { FilterConditionOperator, FilterJoinOperator, MeasureFilterCondition, MeasureType, UniqueIdentifierWithMeasures } from "../types";
import { ComponentLink } from "@shared/types/databaseTypes";
import { hashMeasureKey } from "./measureKeys";

export const testFilterCondition = (condition: MeasureFilterCondition, value: string): boolean => {
  if (!value || !condition.value) return false;

  let numericalConditionValue = Number(condition.value);
  let numericalValue = Number(value);
  if ([MeasureType.Datetime, MeasureType.Timestamp].includes(condition.key.type)) {
    numericalConditionValue = new Date(condition.value).getTime();
    numericalValue = new Date(value).getTime();
  }

  switch (condition.operator) {
    case FilterConditionOperator.Equals:
      return value === condition.value;
    case FilterConditionOperator.NotEquals:
      return value !== condition.value;
    case FilterConditionOperator.GreaterThan:
      return numericalValue > numericalConditionValue;
    case FilterConditionOperator.LessThan:
      return numericalValue < numericalConditionValue;
    case FilterConditionOperator.GreaterThanOrEqualTo:
      return numericalValue >= numericalConditionValue;
    case FilterConditionOperator.LessThanOrEqualTo:
      return numericalValue <= numericalConditionValue;
    case FilterConditionOperator.Contains:
      return value.includes(condition.value);
    case FilterConditionOperator.DoesNotContain:
      return !value.includes(condition.value);
    case FilterConditionOperator.In:
      // serialize the string to a list
      return condition.value.split(",").includes(value);
    case FilterConditionOperator.NotIn:
      // serialize the string to a list
      return condition.value.split(",").includes(value);
    default:
      return false;
  }
};

// This filter tester looks only at the root unique identifier
// For filter conditions that apply to multiple child unique identifiers of the same component, and every() condition will be used
// For example if a filter tests to see if a car's color is red and it's tire pressure is > 30, the filter will return true if a car is red an EVERY tire has a pressure > 30
// It will not return true if a car is red and only SOME tires have a pressure > 30, nor will it return true only for the rows that have a tire pressure > 30
// Note also that only the latest value of a measure is used for the test
export const testFilter = (
  filterConditions: MeasureFilterCondition[],
  filterJoinOperator: FilterJoinOperator,
  uniqueIdentifier: UniqueIdentifierWithMeasures,
): boolean => {
  if (filterConditions.length === 0) return true;

  // keys are the measure key hash, test data is the set of all latest values for each measure (one for each unique identifier if there are multiple of the same component)
  // the values are the stringified values of the measures
  const testDataHashMap: { [hash: string]: string[] } = {};
  for (const measure of uniqueIdentifier.measures) {
    const hash = hashMeasureKey(measure.key);
    // get a list of uniqueIdentifierIds in the root component's genealogy that are related to this measure
    const relatedUniqueIdentifierIds = uniqueIdentifier.genealogy.uniqueIdentifiers
      .filter((uniqueIdentifier) => uniqueIdentifier.component_id === measure.key.component_id)
      .map((uniqueIdentifier) => uniqueIdentifier.id);
    // for each related unique identifier, get the latest value of the measure (if it exists) and add it to the test data
    const latestStringifiedValues: string[] = [];
    for (const relatedUniqueIdentifierId of relatedUniqueIdentifierIds) {
      const valuesWithAggregation = measure.valuesByIdentifier[relatedUniqueIdentifierId];
      if (valuesWithAggregation?.aggregation) {
        latestStringifiedValues.push(valuesWithAggregation.aggregation.value);
      }
    }
    testDataHashMap[hash] = latestStringifiedValues;
  }

  // test each condition
  const conditionResults = filterConditions.map((condition) => {
    const testValues = testDataHashMap[hashMeasureKey(condition.key)]; // one test value for each repeated unique identifier of the same component
    if (!testValues || testValues.length === 0) {
      if (
        // If the condition is testing a "not" operator, and there are no values to test, return true
        condition.operator === FilterConditionOperator.DoesNotContain ||
        condition.operator === FilterConditionOperator.NotEquals ||
        condition.operator === FilterConditionOperator.NotIn
      ) {
        return true;
      }
      // otherwise if there are no values to test, return false
      return false;
    }
    const result = testValues.every((value) => testFilterCondition(condition, value));
    return result;
  });

  // return based on join operator
  if (filterJoinOperator === FilterJoinOperator.And) {
    return conditionResults.every((result) => result === true);
  } else {
    return conditionResults.some((result) => result === true);
  }
};

export const getParentIds = (componentId: string, links: ComponentLink[]): string[] => {
  let parentIds = links.filter((link) => link.has_child_of_id === componentId).map((link) => link.component_id);
  let allParentIds = [...parentIds];

  for (const parentId of parentIds) {
    allParentIds = allParentIds.concat(getParentIds(parentId, links));
  }

  return [...allParentIds, componentId];
};
