import { useMemo } from "react";
import { Field, FieldType } from "@shared/types/databaseTypes";

// TODO: This custom hook should be moved to the shared folder and unified with the one in production-app
//       They have been split right now because of the differences in type definitions for Fields

interface UseFieldGroupsProps {
  fields: Field[] | null;
}

export interface FieldGroup {
  name: string;
  type: FieldType;
  fields: Field[];
}

const useFieldGroups = ({ fields }: UseFieldGroupsProps): FieldGroup[] => {
  const fieldGroups = useMemo(() => {
    const groups: FieldGroup[] = [];
    let currIndex: number = -1; // -1 to force first iteration to push a new group
    for (const field of fields ?? []) {
      if ([FieldType.File, FieldType.Image, FieldType.Signature, FieldType.Label].includes(field.type)) {
        groups.push({
          name: field.group_name ?? field.prompt,
          type: field.type,
          fields: [field],
        });
        currIndex++;
      } else if (groups[currIndex]?.name === field.group_name && groups[currIndex]?.type === field.type) {
        groups[currIndex].fields.push(field);
      } else {
        groups.push({
          name: field.group_name ?? field.prompt,
          type: field.type,
          fields: [field],
        });
        currIndex++;
      }
    }
    return groups;
  }, [fields]);

  return fieldGroups;
};

export default useFieldGroups;
