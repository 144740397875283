import { useMemo, useState } from "react";
import "react18-json-view/src/style.css";
import SidebarSelector from "@shared/components/primitives/SidebarSelector";
import moment from "moment";
import UserAvatar from "@shared/components/primitives/UserAvatar";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import TagBasic from "@shared/components/primitives/TagBasic";
import { ProcessEntry } from "@shared/types/databaseTypes";
import Tooltip from "@shared/components/primitives/Tooltip";
import { TextInput } from "@shared/components/primitives/Input";
import DateRangePicker from "@shared/components/DateRangePicker";
import { DateRange } from "react-day-picker";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ProcessEntryWithIndex extends ProcessEntry {
  index: number;
}

const DataAnalysisModalProcessEntriesSidebarItem = ({
  processEntry,
  numProcessEntries,
}: {
  processEntry: ProcessEntryWithIndex;
  numProcessEntries: number;
}) => {
  const users = useSelector((state: RootState) => state.db.users);

  const user = useMemo(() => {
    return users.find((user) => user.supabase_uid === processEntry.operator?.user_id);
  }, [processEntry.operator?.user_id, users]);

  const indexWidth = numProcessEntries.toString().length * 10;

  return (
    <SidebarSelector.Item key={processEntry.id} value={processEntry.id} className="py-1.5">
      <div className="flex w-full items-center justify-between gap-x-2.5 overflow-hidden text-sm">
        <div className="flex min-w-0 flex-grow gap-x-2.5">
          <div className={`w-[${indexWidth}px] mr-1 shrink-0 font-semibold`}>{numProcessEntries - processEntry.index}</div>
          <div className="w-32 shrink-0 font-light">{moment(processEntry.timestamp).format("MM/DD/YY HH:mma")}</div>

          <Tooltip.Root>
            <Tooltip.Trigger className="flex min-w-0 flex-grow items-center gap-x-1.5">
              <TagBasic size="sm" variant="STATION" />
              <div className="truncate">{processEntry.station?.name ?? "No Station Set"}</div>
            </Tooltip.Trigger>
            <Tooltip.Content>{processEntry.station?.name ?? "No Station Set"}</Tooltip.Content>
          </Tooltip.Root>
        </div>
        {user && <UserAvatar tooltip={true} user={user} size="xs" />}{" "}
      </div>
    </SidebarSelector.Item>
  );
};

const DataAnalysisModalProcessEntriesSelector = ({
  allProcessEntries,
  selectedProcessEntryId,
  setSelectedProcessEntryId,
}: {
  allProcessEntries: ProcessEntry[];
  selectedProcessEntryId: string | null;
  setSelectedProcessEntryId: (value: string | null) => void;
}) => {
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredProcessEntries = useMemo(() => {
    let filtered: ProcessEntryWithIndex[] = allProcessEntries.map((processEntry, index) => {
      return { ...processEntry, index };
    });
    if (dateRangeFilter) {
      filtered = filtered.filter((processEntry) => {
        return moment(processEntry.timestamp).isBetween(dateRangeFilter.from, dateRangeFilter.to, "day", "[]");
      });
    }
    if (searchTerm) {
      filtered = filtered.filter((processEntry) => {
        return (
          (processEntry.station?.name ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
          `${processEntry.operator?.first_name ?? ""} ${processEntry.operator?.last_name ?? ""}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      });
    }
    return filtered;
  }, [allProcessEntries, dateRangeFilter, searchTerm]);

  return (
    <SidebarSelector.Root className="w-[400px]" value={selectedProcessEntryId} onValueChange={(value) => setSelectedProcessEntryId(value)}>
      <SidebarSelector.Header className="flex w-full flex-row">
        <div className="relative w-full">
          <TextInput placeholder="Search" className="pl-8" value={searchTerm} onValueChange={setSearchTerm} />
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-serial-palette-400 absolute left-2.5 top-2.5" />
        </div>
        <DateRangePicker showDateRange={false} dateRange={dateRangeFilter} onDateRangeChange={setDateRangeFilter} clearButton />
      </SidebarSelector.Header>
      <SidebarSelector.List className="bg-white">
        {filteredProcessEntries.map((processEntry) => (
          <DataAnalysisModalProcessEntriesSidebarItem
            key={processEntry.id}
            processEntry={processEntry}
            numProcessEntries={allProcessEntries.length}
          />
        ))}
      </SidebarSelector.List>
    </SidebarSelector.Root>
  );
};

export default DataAnalysisModalProcessEntriesSelector;
