import React, { useState, useRef, useEffect } from "react";
import Transition from "../Transition";

// Usage:
//
//  options is an array of objects with the following shape:
//  [
//    {id: 1, label: 'Option 1'},
//    {id: 2, label: 'Option 2'},
//    ...
//  ]
//  selected is the id of the selected option
//  setSelected is a function that takes an option object as a parameter
//  className is a string of classes to apply to the dropdown container
//  placeholder is the text to display when no option is selected
//  dropdownOpenOverride is a boolean that controls the dropdown open state from outside the component
//  setDropdownOpenOverride is a function that takes a boolean parameter and sets the dropdownOpenOverride state

function DropdownSearch({
  options,
  selected,
  setSelected,
  className,
  dropdownClassName = "",
  placeholder,
  persistent = true,
  dropdownOpenOverride = undefined,
  setDropdownOpenOverride = undefined,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [focusedOption, setFocusedOption] = useState(selected);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchTextRef = useRef(null);
  const optionRefs = useRef([]);

  // This function returns another function that takes a `ref` parameter.
  // The inner function sets the corresponding option ref in the `optionRefs.current` array.
  // The outer function uses a closure to capture the `index` variable and make it available to the inner function.
  const handleRefs = (index) => (ref) => {
    optionRefs.current[index] = ref;
  };

  // update the selected text when the selected option changes
  useEffect(() => {
    searchTextRef.current.textContent = options.find((o) => o.id === selected)?.label;
  }, [selected]);

  // update the filtered options when the options change
  useEffect(() => {
    handleSearchTextChange();
  }, [options]);

  // Note: dropdownOpenOverride is used to control the dropdown open state from outside the component
  // update dropdown open state when the dropdownOpenOverride changes
  useEffect(() => {
    if (dropdownOpenOverride !== undefined) {
      setDropdownOpen(dropdownOpenOverride);
    }
  }, [dropdownOpenOverride]);

  // update dropdownOpenOverride when the dropdownOpen state changes
  useEffect(() => {
    if (setDropdownOpenOverride) {
      setDropdownOpenOverride(dropdownOpen);
    }
  }, [dropdownOpen]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // Auto field advancement with tab, enter keys and arrow keys
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (dropdownOpen) {
        // Check if enter was pressed
        if (keyCode === 13) {
          if (focusedOption !== undefined) {
            handleSelect(options.find((o) => o.id === focusedOption));
            searchTextRef.current.blur();
          }
        }
        // Check if tab was pressed
        else if (keyCode === 9) {
          // If the tab key was pressed, set the focused option to the next option or the first option if the last option was focused
          const nextOptionIndex = (filteredOptions.findIndex((o) => o.id === focusedOption) + 1) % filteredOptions.length;
          setFocusedOption(filteredOptions[nextOptionIndex].id);
          trigger.current?.focus();
        }
        // Check if up arrow was pressed
        else if (keyCode === 38) {
          // If the up arrow was pressed, set the focused option to the previous option or the last option if the first option was focused
          const nextOptionIndex =
            (filteredOptions.findIndex((o) => o.id === focusedOption) - 1 + filteredOptions.length) % filteredOptions.length;
          setFocusedOption(filteredOptions[nextOptionIndex].id);
        }
        // Check if down arrow was pressed
        else if (keyCode === 40) {
          // If the down arrow was pressed, set the focused option to the next option or the first option if the last option was focused
          const nextOptionIndex = (filteredOptions.findIndex((o) => o.id === focusedOption) + 1) % filteredOptions.length;
          setFocusedOption(filteredOptions[nextOptionIndex].id);
        }
      }
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // update filtered options based on search text. If search text is empty, show all options
  // if search text is contained in the label, show that option
  const handleSearchTextChange = () => {
    const nullOption = options.find((o) => o.id === null);
    if (searchTextRef?.current?.textContent === "" || searchTextRef?.current?.textContent === nullOption?.label) {
      setFocusedOption(null);
      setFilteredOptions(options);
      if (selected) setSelected(null);
    } else {
      setFilteredOptions(options.filter((o) => o.label.toLowerCase().includes(searchTextRef?.current?.textContent.toLowerCase())));
    }
  };

  const handleSelect = (option) => {
    setSelected(option?.id ?? null);
    setDropdownOpen(false);
    searchTextRef.current.textContent = option?.label ?? "";
  };

  const selectAllText = () => {
    const range = document.createRange();
    range.selectNodeContents(searchTextRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleOpenDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (persistent) {
      selectAllText();
      setFilteredOptions(options);
    } else {
      setSelected(null);
      searchTextRef.current.textContent = "";
    }
    // Use setTimeout to allow the dropdownOpen state to change the display state of the contentEditable div before focusing it
    setTimeout(() => {
      searchTextRef.current.focus();
    }, 0);
  };

  const handleSelectText = () => {
    if (!dropdownOpen) {
      handleOpenDropdown();
    }
  };

  return (
    <div className="relative inline-flex w-full">
      <button
        ref={trigger}
        tabIndex={-1}
        className={
          "btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-500 hover:text-serial-palette-600 w-full justify-between bg-white" +
          " " +
          className
        }
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => handleSelectText()}
        aria-expanded={dropdownOpen}
      >
        <div
          className={`${!dropdownOpen ? "hidden" : "flex"} w-full items-center text-left focus:outline-none`}
          contentEditable={true}
          placeholder={placeholder ?? "Search"}
          ref={searchTextRef}
          tabIndex={-1}
          onClick={(e) => handleSelectText(e)}
          onInput={() => handleSearchTextChange()}
          style={{ whiteSpace: "break-spaces" }}
        />
        <div
          className={`${dropdownOpen ? "hidden" : "flex"} items-center truncate text-left ${!options.find((o) => o.id === selected)?.label && "text-serial-palette-400 font-light"}`}
        >
          <span className="truncate">{options.find((o) => o.id === selected)?.label ?? placeholder ?? "Search"}</span>
        </div>
        <svg className="text-serial-palette-400 ml-1 flex shrink-0 fill-current" width="11" height="7" viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`border-serial-palette-200 absolute top-full z-10 mt-1 w-full overflow-hidden rounded border bg-white py-1.5 shadow ${dropdownClassName}`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="text-serial-palette-600 scrollbar-hide max-h-56 overflow-y-auto text-sm font-medium"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {filteredOptions.map((option, index) => {
            return (
              <span key={index}>
                <button
                  ref={handleRefs(option.id)}
                  onMouseEnter={() => setFocusedOption(option.id)}
                  onMouseLeave={() => setFocusedOption(selected)}
                  tabIndex="-1"
                  className={`flex w-full cursor-pointer items-center px-3 py-1 ${option.id === focusedOption && "bg-serial-palette-50"} ${option.id === selected && "text-serial-palette-500"}`}
                  onClick={() => handleSelect(option)}
                >
                  <svg
                    className={`text-serial-palette-500 mr-2 shrink-0 fill-current ${option.id !== selected && "invisible"}`}
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                  >
                    <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                  </svg>
                  <span className="truncate whitespace-nowrap">{option.label}</span>
                </button>
                <div
                  id="FocusCatcher"
                  className="h-0"
                  tabIndex={option.id === focusedOption ? "0" : "-1"}
                  onFocus={() => searchTextRef.current?.focus()}
                ></div>
              </span>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownSearch;
