import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@shared/utils/tailwind";
import Tooltip from "./Tooltip";
import Loader from "./Loader";

// Use the class-variance-authority library to generate the classes for the button.
// Allows for type safe and consistent button styles.
export const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-serial-palette-600 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 gap-x-2",
  {
    variants: {
      variant: {
        default: "serial-btn-dark",
        danger: "serial-btn-danger",
        success: "serial-btn-success",
        warning: "serial-btn-warning",
        outline: "serial-btn-light",
        ghost: "hover:bg-serial-palette-100 hover:text-serial-palette-900",
        link: "text-serial-palette-900 underline-offset-4 hover:underline",
      },
      size: {
        xs: "!h-6 px-2 text-xs",
        sm: "!h-7 px-3 text-sm",
        md: "!h-9 px-4 py-2",
        lg: "!h-11 px-6",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  },
);

const symmetricWidths = {
  xs: "!w-6",
  sm: "!w-7",
  md: "!w-9",
  lg: "!w-11",
};

export type ButtonVariant = "default" | "danger" | "success" | "warning" | "outline" | "ghost" | "link";
export type ButtonSize = "xs" | "sm" | "md" | "lg";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, Omit<VariantProps<typeof buttonVariants>, "size"> {
  size?: ButtonSize;
  tooltip?: string | React.ReactNode;
  asChild?: boolean;
  isLoading?: boolean;
  symmetric?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, tooltip, variant, size = "md", asChild = false, isLoading, symmetric, children, disabled, ...props }, ref) => {
    const ButtonContents = asChild ? Slot : "button";
    if (tooltip)
      return (
        <Tooltip.Root delayDuration={400}>
          <Tooltip.Trigger asChild>
            <ButtonContents
              className={cn(buttonVariants({ variant, size, className }), symmetric && symmetricWidths[size])}
              ref={ref}
              disabled={disabled || isLoading}
              {...props}
            >
              {isLoading ? <Loader size={size === "lg" ? "sm" : "xs"} /> : children}
            </ButtonContents>
          </Tooltip.Trigger>
          <Tooltip.Content>{tooltip}</Tooltip.Content>
        </Tooltip.Root>
      );
    return (
      <ButtonContents
        className={cn(buttonVariants({ variant, size, className }), symmetric && symmetricWidths[size])}
        ref={ref}
        disabled={disabled || isLoading}
        {...props}
      >
        {isLoading ? <Loader size={size === "lg" ? "sm" : "xs"} /> : children}
      </ButtonContents>
    );
  },
);
Button.displayName = "Button";

export default Button;
