import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "@shared/utils/tailwind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ModalRoot = DialogPrimitive.Root;

const ModalTrigger = DialogPrimitive.Trigger;

const ModalPortal = DialogPrimitive.Portal;

const ModalClose = DialogPrimitive.Close;

const ModalOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  fixed inset-0 z-50 bg-[rgba(0,0,0,0.4)]",
      className,
    )}
    {...props}
  />
));
ModalOverlay.displayName = DialogPrimitive.Overlay.displayName;

const ModalContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <ModalPortal>
    <ModalOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-50 flex w-full max-w-lg translate-x-[-50%] translate-y-[-50%] flex-col border bg-white shadow-lg duration-200 sm:rounded-md",
        className,
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </ModalPortal>
));
ModalContent.displayName = DialogPrimitive.Content.displayName;

const ModalHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex h-14 w-full shrink-0 items-center justify-between border-b pl-5 pr-4", className)} {...props} />
);
ModalHeader.displayName = "ModalHeader";

const ModalHeaderClose = ({ className }: React.HTMLAttributes<HTMLDivElement>) => (
  <DialogPrimitive.Close
    tabIndex={-1}
    className={cn(
      "focus:ring-serial-palette-600 data-[state=open]:bg-serial-palette-100 data-[state=open]:text-serial-palette-500 h-6 w-6 rounded-sm opacity-50 ring-offset-white transition-opacity hover:opacity-100 focus:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none",
      className,
    )}
  >
    <FontAwesomeIcon icon={faTimes} />
    <span className="sr-only">Close</span>
  </DialogPrimitive.Close>
);
ModalHeaderClose.displayName = "ModalHeaderClose";

const ModalFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex h-14 w-full shrink-0 flex-row-reverse items-center justify-between border-t px-5", className)} {...props} />
);
ModalFooter.displayName = "ModalFooter";

const ModalMain = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex min-h-0 min-w-0 flex-grow flex-col px-5 py-3", className)} {...props} />
);
ModalMain.displayName = "ModalMain";

const ModalTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title ref={ref} className={cn("text-serial-palette-800 font-semibold", className)} {...props} />
));
ModalTitle.displayName = DialogPrimitive.Title.displayName;

const Modal = Object.assign(ModalRoot, {
  Root: ModalRoot,
  Portal: ModalPortal,
  Overlay: ModalOverlay,
  Close: ModalClose,
  HeaderClose: ModalHeaderClose,
  Trigger: ModalTrigger,
  Content: ModalContent,
  Main: ModalMain,
  Header: ModalHeader,
  Footer: ModalFooter,
  Title: ModalTitle,
});

export default Modal;
