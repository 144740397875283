// Description: This file contains utility functions that are used in the application
import { ProcessEntry } from "@shared/types/databaseTypes";
import { DailyData } from "./connections/supabaseYield";

export const filterProcessEntries = (
  processEntries: ProcessEntry[],
  component_instance_ids: string[],
  processType = "PRODUCTION",
  start_date: string | null,
  end_date: string | null,
) => {
  processEntries = processEntries.filter((processEntry) => processEntry.process?.type === processType);

  if (component_instance_ids.length > 0) {
    processEntries = processEntries.filter((processEntry) => component_instance_ids.includes(processEntry.unique_identifier_id));
  }
  if (start_date) {
    processEntries = processEntries.filter((processEntry) => processEntry.timestamp >= start_date);
  }
  if (end_date) {
    processEntries = processEntries.filter((processEntry) => processEntry.timestamp <= end_date);
  }
  return processEntries;
};

export interface ProcessYield {
  first_pass_sum: number;
  all_pass_sum: number;
  total_sum: number;
  first_pass_yield?: number;
  all_pass_yield?: number;
}

interface PerProcessYieldData {
  date: string;
  first_pass_yield: number;
  all_pass_yield: number;
}
export interface PerProcessYields {
  [processId: string]: PerProcessYieldData[];
}

// Need to clean processes that never had a pass record
export const calculateYields = (dailyData: DailyData[]) => {
  const processYields: { [processId: string]: ProcessYield } = {};
  const dailyYields: { [date: string]: ProcessYield } = {};
  const perProcessYields: PerProcessYields = {};

  dailyData.forEach((data) => {
    if (!perProcessYields[data.process_id]) {
      perProcessYields[data.process_id] = [];
    }
    if (data.total === 0) {
      // Skip if there are no records
      return;
    }
    const allPassYield = data.total > 0 ? data.all_pass / data.total : 0;
    const firstPassYield = data.total > 0 ? data.first_pass / data.total : 0;

    perProcessYields[data.process_id].push({
      date: data.date,
      all_pass_yield: allPassYield,
      first_pass_yield: firstPassYield,
    });
    if (!processYields[data.process_id]) {
      processYields[data.process_id] = { first_pass_sum: 0, all_pass_sum: 0, total_sum: 0 };
    }
    if (!dailyYields[data.date]) {
      dailyYields[data.date] = { first_pass_sum: 0, all_pass_sum: 0, total_sum: 0 };
    }
    processYields[data.process_id].first_pass_sum += data.first_pass;
    processYields[data.process_id].all_pass_sum += data.all_pass;
    processYields[data.process_id].total_sum += data.total;
    dailyYields[data.date].first_pass_sum += data.first_pass;
    dailyYields[data.date].all_pass_sum += data.all_pass;
    dailyYields[data.date].total_sum += data.total;
  });

  // Calculate yields
  Object.keys(processYields).forEach((processId) => {
    const processYield = processYields[processId];
    processYield.first_pass_yield = processYield.total_sum > 0 ? processYield.first_pass_sum / processYield.total_sum : 0;
    processYield.all_pass_yield = processYield.total_sum > 0 ? processYield.all_pass_sum / processYield.total_sum : 0;
  });

  Object.keys(dailyYields).forEach((date) => {
    const dailyYield = dailyYields[date];
    dailyYield.first_pass_yield = dailyYield.total_sum > 0 ? dailyYield.first_pass_sum / dailyYield.total_sum : 0;
    dailyYield.all_pass_yield = dailyYield.total_sum > 0 ? dailyYield.all_pass_sum / dailyYield.total_sum : 0;
  });

  let overallFirstPassYield = 1;
  let overallAllPassYield = 1;

  Object.values(processYields).forEach((yieldData) => {
    if (yieldData.first_pass_yield) {
      overallFirstPassYield *= yieldData.first_pass_yield;
    }
    if (yieldData.all_pass_yield) {
      overallAllPassYield *= yieldData.all_pass_yield;
    }
  });

  return {
    dailyYields,
    perProcessYields,
    overallFirstPassYield,
    overallAllPassYield,
  };
};
