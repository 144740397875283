import React, { useState, useEffect, useMemo } from "react";
import { Image } from "@shared/types/databaseTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight, faCaretSquareRight, faCircle, faCaretSquareLeft } from "@fortawesome/free-solid-svg-icons";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";
import ImageLoading from "@images/image-loading.svg";
import ModalBasic from "./modals/ModalBasic";

const IMAGES_PER_PAGE = 8;
const MAX_PAGINATION_DOTS = 15;

interface ImageCarouselProps {
  images: Image[];
  activeImageIndex: number;
  setActiveImageIndex: React.Dispatch<React.SetStateAction<number>> | ((index: number) => void);
  quicklookOpen?: boolean;
  setQuicklookOpen?: React.Dispatch<React.SetStateAction<boolean>> | ((isOpen: boolean) => void);
  imagesPerPage?: number;
  quicklookTitle?: string;
  quicklookSubtitle?: string;
  overrideHeight?: number;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  activeImageIndex,
  setActiveImageIndex,
  quicklookOpen: externalQuicklookOpen,
  setQuicklookOpen: externalSetQuicklookOpen,
  imagesPerPage = IMAGES_PER_PAGE,
  quicklookTitle,
  quicklookSubtitle,
  overrideHeight,
}) => {
  // Initialize internal state based on whether external state is provided
  const [internalQuicklookOpen, setInternalQuicklookOpen] = useState<boolean>(externalQuicklookOpen ?? false);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [imageUrls, setImageUrls] = useState<{ [key: string]: string | undefined }>({}); // key is image id, value is image url

  // Combine external and internal state/setting functions
  const quicklookOpen = externalQuicklookOpen ?? internalQuicklookOpen;
  const setQuicklookOpen = externalSetQuicklookOpen ?? setInternalQuicklookOpen;

  const addImageUrls = async (requiredImages: Image[]) => {
    requiredImages.forEach(async (image) => {
      if (imageUrls[image.id] !== undefined) return;
      let newUrl = image.url;
      if (!newUrl) {
        newUrl = await fetchDownloadUrl(image.bucket_name, image.file_id ?? image.id, image.company_id, true);
      }
      setImageUrls((prevImageUrls) => ({ ...prevImageUrls, [image.id]: newUrl ?? undefined }));
    });
  };

  const handleNextImage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveImageIndex((activeImageIndex + 1) % images.length);
  };

  const handlePreviousImage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveImageIndex((activeImageIndex - 1 + images.length) % images.length);
  };

  const totalImagePages = useMemo(() => {
    return Math.ceil(images.length / imagesPerPage);
  }, [images]);

  const currentPageImages = useMemo(() => {
    const newPageImages = images.slice(currentPageIndex * imagesPerPage, (currentPageIndex + 1) * imagesPerPage);
    addImageUrls(newPageImages);
    return newPageImages;
  }, [currentPageIndex, images]);

  const currentImage = useMemo(() => {
    return images[activeImageIndex];
  }, [activeImageIndex, images]);

  // update currentPageIndex when activeImageIndex changes
  useEffect(() => {
    setCurrentPageIndex(Math.floor(activeImageIndex / imagesPerPage));
  }, [activeImageIndex]);

  return (
    <div className="flex w-full flex-col">
      {/* Image */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setQuicklookOpen(!quicklookOpen);
        }}
        className="border-serial-palette-200 group relative p-2"
        style={{ height: overrideHeight ?? 300 }}
      >
        <div className="relative flex h-full w-full max-w-full cursor-zoom-in flex-col items-center justify-center overflow-hidden rounded-md">
          {currentImage && <img src={imageUrls[currentImage.id] ?? ImageLoading} className="absolute inset-0 h-full w-full object-cover" />}
        </div>
        {images.length > 1 && (
          <button
            className="text-serial-palette-200 absolute left-5 top-1/2 z-50 -translate-y-1/2 transform opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50 group-hover:hover:opacity-100"
            onClick={(e) => handlePreviousImage(e)}
          >
            <FontAwesomeIcon icon={faCaretSquareLeft} size="3x" />
          </button>
        )}
        {images.length > 1 && (
          <button
            className="text-serial-palette-200 absolute right-5 top-1/2 z-50 -translate-y-1/2 transform opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50 group-hover:hover:opacity-100"
            onClick={(e) => handleNextImage(e)}
          >
            <FontAwesomeIcon icon={faCaretSquareRight} size="3x" />
          </button>
        )}
      </div>

      {/* Thumbnails */}
      {images.length > 1 && (
        <div className="flex w-full gap-x-1.5 p-2 pt-0">
          {currentPageImages.map((image, index) => (
            <button
              key={index}
              onClick={() => setActiveImageIndex(index + currentPageIndex * imagesPerPage)}
              className={`${activeImageIndex - currentPageIndex * imagesPerPage === index && "ring"} relative flex h-16 cursor-pointer flex-col items-center justify-center overflow-hidden rounded-sm ${currentPageImages.length === imagesPerPage ? "w-full" : "w-16"}`}
            >
              <img src={imageUrls[image.id] ?? ImageLoading} className="absolute inset-0 h-full w-full object-cover" />
            </button>
          ))}
        </div>
      )}

      {/* Pagination */}
      {totalImagePages > 1 && (
        <div className="flex items-center justify-center px-2 pb-2">
          <button
            onClick={() => setCurrentPageIndex(currentPageIndex - 1 < 0 ? totalImagePages - 1 : currentPageIndex - 1)}
            className="flex h-8 items-center px-2 opacity-70 hover:opacity-100"
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </button>
          {Array.from(Array(totalImagePages > MAX_PAGINATION_DOTS ? MAX_PAGINATION_DOTS : totalImagePages).keys()).map((_page, index) => {
            return (
              <button onClick={() => setCurrentPageIndex(index)} key={index} className="flex h-8 items-center">
                <FontAwesomeIcon
                  icon={faCircle}
                  className={`px-1 ${index === (currentPageIndex + 1 > MAX_PAGINATION_DOTS ? MAX_PAGINATION_DOTS - 1 : currentPageIndex) ? "text-[10px] opacity-70" : "text-[8px] opacity-30 "} hover:text-[10px] hover:opacity-100`}
                />
              </button>
            );
          })}
          {totalImagePages > MAX_PAGINATION_DOTS && <p className="opacity-30">···</p>}
          <button
            onClick={() => setCurrentPageIndex(currentPageIndex + 1 >= totalImagePages ? 0 : currentPageIndex + 1)}
            className="flex h-8 items-center px-2 opacity-70 hover:opacity-100"
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        </div>
      )}

      <ModalBasic
        id="carousel"
        classOverride="flex flex-col overflow-hidden w-[60vw] h-[80vh] relative"
        modalOpen={quicklookOpen}
        setModalOpen={setQuicklookOpen}
        title={
          <span>
            {quicklookTitle ?? "Images"}
            {quicklookSubtitle && <span className="text-serial-palette-600 pl-2 font-normal">{quicklookSubtitle}</span>}
          </span>
        }
      >
        <div className="flex h-full w-full items-center justify-center overflow-hidden rounded border-b">
          {currentImage && <img src={imageUrls[currentImage.id] ?? ImageLoading} className="object-cover" />}
        </div>
        <div className="flex w-full justify-between px-3 py-1.5">
          <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={(e) => handlePreviousImage(e)}>
            <FontAwesomeIcon icon={faCaretSquareLeft} size="2x" />
          </button>
          <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={(e) => handleNextImage(e)}>
            <FontAwesomeIcon icon={faCaretSquareRight} size="2x" />
          </button>
        </div>
      </ModalBasic>
    </div>
  );
};

export default ImageCarousel;
