import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { BlankDataReason, blankDataReasonStyle, featureImplementationTimestamps } from "./types";
import { MeasureAggregationOperator, MeasureKey, MeasureValue } from "@shared/measures/types";
import { Process, ProcessEntry } from "@shared/types/databaseTypes";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import Tooltip from "@shared/components/primitives/Tooltip";

const MeasuresGridBodyCellBlank = ({
  measureKey,
  measureValues,
  processEntries,
  processRevisions,
}: {
  measureKey: MeasureKey;
  measureValues: MeasureValue[] | null;
  processRevisions: Process[];
  processEntries: ProcessEntry[];
}) => {
  const uniqueIdentifiers = useSelector((state: RootState) => state.db.componentInstances);
  const processes = useSelector((state: RootState) => state.db.processes);

  const blankDataReason = useMemo(() => {
    // ---------------- Supplemental data ----------------
    const aggregationOperator = measureKey.aggregation_operator ?? MeasureAggregationOperator.Latest;
    const sortedMeasureValues = [...(measureValues ?? [])].sort(
      (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    );
    const firstOrLatMeasureValue =
      aggregationOperator === MeasureAggregationOperator.Latest
        ? sortedMeasureValues[0]
        : aggregationOperator === MeasureAggregationOperator.First
          ? sortedMeasureValues[sortedMeasureValues.length - 1]
          : undefined;
    const uniqueIdentifierSet = new Set((measureValues ?? []).map((value) => value.uniqueIdentifierId));
    if (uniqueIdentifierSet.size > 1) {
      console.error("Multiple unique identifiers found in the same MeasureValuesWithAggregation object");
    }
    const uniqueIdentifierId = firstOrLatMeasureValue?.uniqueIdentifierId;
    const uniqueIdentifierStatus =
      uniqueIdentifiers.find((uniqueIdentifier) => uniqueIdentifier.id === uniqueIdentifierId)?.status ?? undefined;
    const processId = measureKey.process_id;
    const latestProcessRevision = processes.find((process) => process.id === processId);
    const processRevision = processRevisions.find(
      (process) => process.id === processId && process.revision === firstOrLatMeasureValue?.processEntry?.process_revision,
    );
    const filteredProcessEntries = processEntries.filter((entry) => entry.unique_identifier_id === uniqueIdentifierId);

    // ---------------- Conditional logic ----------------
    // ---- CHECK 1 ---- If the cell data has a timestamp but that timestamp is before the feature was implemented, then the data is not supported
    if (measureValues && measureValues.length > 0) {
      const featureImplementationTimestamp = featureImplementationTimestamps[measureKey.type];
      const dataTimestamp = firstOrLatMeasureValue?.timestamp;
      if (dataTimestamp && featureImplementationTimestamp && new Date(dataTimestamp) < new Date(featureImplementationTimestamp)) {
        return BlankDataReason.NotSupported;
      }
    }
    // ---- CHECK 2 ---- If the process is not mandatory and the unit is COMPLETE. (TODO: add check to see if the field is optional and the unit has already gone through the process)
    if (processRevision?.is_mandatory === false && uniqueIdentifierStatus !== UniqueIdentifierStatus.Complete) {
      return BlankDataReason.OptionalData;
    }
    // ---- CHECK 3 ---- If the unit is not COMPLETE, and there are no process entries for the current process, and the process is mandatory, then the data is in progress
    if (
      uniqueIdentifierStatus !== UniqueIdentifierStatus.Complete &&
      filteredProcessEntries.length === 0 &&
      latestProcessRevision?.is_mandatory
    ) {
      return BlankDataReason.InProgress;
    }
    // ---- CHECK 4 ---- TODO: Check if the data was collected with a different version of the process that did not include this field / dataset
    // ---- DEFAULT ----
    return BlankDataReason.NotRecorded; // default condition
  }, [measureKey, measureValues, processEntries, processRevisions, uniqueIdentifiers, processes]);

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <div className="text-serial-palette-300 flex h-full w-full items-center justify-center gap-x-2 text-sm">
          <FontAwesomeIcon icon={faExclamationCircle} />
          {blankDataReason && <span>{blankDataReasonStyle[blankDataReason].label}</span>}
        </div>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className="w-56 text-sm">{blankDataReasonStyle[blankDataReason].explanation}</Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export default MeasuresGridBodyCellBlank;
