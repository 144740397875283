import store from "@shared/redux/store";
import { ParsedResponse } from "@shared/types/apiTypes";
import { ApprovalStatus, DataType, WorkOrderStatus } from "@shared/types/databaseEnums";
import { WorkOrder } from "@shared/types/databaseTypes";
import { WorkOrderAttachmentType } from "../types";
import { makeFetchRequest } from "@shared/connections/api/helpers";
import i18next from "@shared/i18n/config";

const apiUrl = import.meta.env.VITE_APP_SERIAL_API_URL;

const getSupabaseUid = () => {
  const state = store.getState();
  return state.auth.supabase_uid;
};

export const createWorkOrder = async (workOrder: WorkOrder): Promise<ParsedResponse> => {
  const errorMessage = `Could not create ${i18next.t("workOrder").toLowerCase()}`;
  return makeFetchRequest(
    `${apiUrl}/work-orders`,
    {
      method: "POST",
      body: JSON.stringify({
        name: workOrder.name,
        component_id: workOrder.component_id,
        quantity: workOrder.quantity,
        created_by_user_id: getSupabaseUid(),
        description: workOrder.description,
        part_number_id: workOrder.part_number_id,
        phase: workOrder.phase,
        assignee_user_id: workOrder.assignee_user_id,
        deadline: workOrder.deadline,
      }),
    },
    errorMessage,
  );
};

export const updateWorkOrder = async (workOrder: WorkOrder): Promise<ParsedResponse> => {
  const errorMessage = `Could not update ${i18next.t("workOrder").toLowerCase()}`;
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrder.id}`,
    {
      method: "PATCH",
      body: JSON.stringify({
        name: workOrder.name,
        component_id: workOrder.component_id,
        quantity: workOrder.quantity,
        updated_by_user_id: getSupabaseUid(),
        description: workOrder.description,
        part_number_id: workOrder.part_number_id,
        phase: workOrder.phase,
        assignee_user_id: workOrder.assignee_user_id,
        deadline: workOrder.deadline,
      }),
    },
    errorMessage
  );
};

export const deleteWorkOrder = async (workOrderId: string): Promise<ParsedResponse> => {
  const errorMessage = `Could not delete ${i18next.t("workOrder").toLowerCase()}`;
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}`,
    { method: "DELETE" },
    errorMessage,
  );
};

export const approveOrRejectReview = async (
  workOrderId: string,
  approverUserId: string,
  approvalStatus: ApprovalStatus | null,
  comment?: string,
): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}/approvals`,
    {
      method: "PATCH",
      body: JSON.stringify({
        approver_user_id: approverUserId,
        status: approvalStatus,
        comment: comment,
      }),
    },
    "Could not approve or reject review",
  );
};

export const addApprover = async (workOrderId: string, approverUserId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}/approvals`,
    {
      method: "PUT",
      body: JSON.stringify({
        approver_user_id: approverUserId,
        requested_by_user_id: getSupabaseUid(),
      }),
    },
    "Could not add approver",
  );
};

export const deleteApprover = async (workOrderId: string, approverUserId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}/approvals/${approverUserId}`,
    {
      method: "DELETE",
      body: JSON.stringify({
        requested_by_user_id: getSupabaseUid(),
      }),
    },
    "Could not remove approver",
  );
};

export const addComment = async (workOrderId: string, comment: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}/comments`,
    {
      method: "POST",
      body: JSON.stringify({
        comment: comment,
        added_by_user_id: getSupabaseUid(),
      }),
    },
    "Could not add comment",
  );
};

export const updateStatus = async (workOrderId: string, status: WorkOrderStatus, comment?: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}/status`,
    {
      method: "PATCH",
      body: JSON.stringify({
        status: status,
        updated_by_user_id: getSupabaseUid(),
        comment: comment,
      }),
    },
    "Could not update status",
  );
};

export const addAttachment = async (
  workOrderId: string,
  dataType: WorkOrderAttachmentType,
  attachment: Blob | undefined,
  name: string,
  fileName?: string,
  value?: string,
): Promise<ParsedResponse> => {
  // upload multipart/form-data with one json part called metadata and one file part called attachment
  const formData = new FormData();
  formData.append(
    "metadata",
    JSON.stringify({
      type: dataType === DataType.ParametricQualitative ? "TEXT" : dataType, // Cast DataType.ParametricQualitative to "TEXT"
      name: name,
      added_by_user_id: getSupabaseUid(),
      file_name: fileName,
      value: value,
    }),
  );
  if (attachment) {
    formData.append("attachment", attachment);
  }
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}/attachments`,
    {
      method: "PUT",
      body: formData,
    },
    "Could not add attachment",
  );
};

export const deleteAttachment = async (workOrderId: string, datasetId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/work-orders/${workOrderId}/attachments/${datasetId}`,
    {
      method: "DELETE",
      body: JSON.stringify({
        requested_by_user_id: getSupabaseUid(),
      }),
    },
    "Could not remove approver",
  );
};
