import React, { useContext, useMemo } from "react";
import { DatasetsContext } from "../Datasets";
import DropdownEditMenu from "@shared/components/dropdowns/DropdownEditMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";
import { Component } from "@shared/types/databaseTypes";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import TypeTag from "@shared/components/TypeTag";
import moment from "moment";
import { DataType } from "@shared/types/databaseEnums";

interface DatasetSummaryProps {}

const DatasetSummary: React.FC<DatasetSummaryProps> = () => {
  const context = useContext(DatasetsContext);
  const navigate = useNavigate();

  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const components = useSelector((state: RootState) => state.db.components);
  const field = useSelector((state: RootState) => state.db.fields).find((field) => field.dataset_id === context.selectedDataset?.id);
  const dataset = useSelector((state: RootState) => state.db.datasets).find((dataset) => dataset.id === context.selectedDataset?.id);

  const relatedComponents: Component[] = useMemo(() => {
    return componentProcessLinks
      .filter((link) => {
        return link.process_id === context.selectedDataset?.process_id;
      })
      .map((link) => {
        return components.find((component) => component.id === link.component_id);
      })
      .filter((c) => c !== undefined) as Component[];
  }, [componentProcessLinks, context.selectedDataset]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full justify-between">
        {/* Type Tag */}
        <div className="text-serial-palette-800 mr-5 flex min-w-0 flex-grow items-center gap-2 text-xl font-bold md:text-3xl">
          {context.selectedDataset && <TypeTag type={context.selectedDataset.data_type} className="text-base" hideText />}
          <p className="truncate">{context.selectedDataset?.name}</p>
        </div>
        <DropdownEditMenu align="right" size="9" dark>
          <div
            className="text-serial-palette-600 hover:bg-serial-palette-50 flex cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
            onClick={(e) => {
              e.preventDefault();
              context.setDatasetEditModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
            <p>Edit</p>
          </div>
        </DropdownEditMenu>
      </div>
      <div className="flex w-full flex-wrap items-center gap-2 pt-2">
        {/* Component Tag */}
        {relatedComponents.map((component, index) => {
          return (
            <button
              key={index}
              onClick={() => navigate(`/component/${component.id}`)}
              className="flex h-8 items-center gap-1 rounded-md border bg-white px-2"
            >
              {component.url && <ComponentAvatar component={component} size="xs" />}
              <div className="text-serial-palette-600 text-sm font-medium">{component.name}</div>
            </button>
          );
        })}
        {/* Process Tag */}
        <div className="flex h-8 items-center overflow-clip rounded-md border bg-white">
          <div className="flex h-full items-center bg-slate-600 px-2">
            <p className="text-sm font-semibold text-white">Process</p>
          </div>
          <div className="text-serial-palette-600 px-2 text-sm font-medium">{context.selectedDataset?.process?.name}</div>
        </div>
        {/* Created */}
        <div className="flex h-8 items-center overflow-clip rounded-md border bg-white">
          <div className="flex h-full items-center bg-slate-600 px-2">
            <p className="text-sm font-semibold text-white">Created</p>
          </div>
          <div className="text-serial-palette-600 px-2 text-sm font-medium">{moment(field?.created_at).format("MM/DD/YY")}</div>
        </div>
        {/* Spec Tags */}
        {context.selectedDataset?.data_type === DataType.ParametricQuantitative && (
          <div className="flex h-8 items-center overflow-clip rounded-md border bg-white">
            <div className="flex h-full items-center bg-blue-100 px-2">
              <p className="text-sm font-semibold text-blue-500">LSL</p>
            </div>
            <div className="text-serial-palette-600 px-2 text-sm font-medium">{dataset?.lsl ?? "-"}</div>
          </div>
        )}
        {context.selectedDataset?.data_type === DataType.ParametricQuantitative && (
          <div className="flex h-8 items-center overflow-clip rounded-md border bg-white">
            <div className="flex h-full items-center bg-blue-100 px-2">
              <p className="text-sm font-semibold text-blue-500">USL</p>
            </div>
            <div className="text-serial-palette-600 px-2 text-sm font-medium">{dataset?.usl ?? "-"}</div>
          </div>
        )}
        {/* Unit Tag */}
        {context.selectedDataset?.data_type === DataType.ParametricQuantitative && (
          <div className="flex h-8 items-center overflow-clip rounded-md border bg-white">
            <div className="flex h-full items-center bg-blue-100 px-2">
              <p className="text-sm font-semibold text-blue-500">Unit</p>
            </div>
            <div className="text-serial-palette-600 px-2 text-sm font-medium">{dataset?.unit ?? "-"}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatasetSummary;
