import Popover from "@shared/components/primitives/Popover";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import Button from "@shared/components/primitives/Button";

const FrontendPrimitivesPopovers = () => {
  return (
    <FrontendPrimitivesCard title="Popovers">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Alignment</div>
        <div className="flex gap-2">
          <Popover.Root>
            <Popover.Trigger asChild>
              <Button>Align Start</Button>
            </Popover.Trigger>
            <Popover.Content align="start" className="flex h-36 items-center justify-center text-sm italic">
              Place content for the popover here.
            </Popover.Content>
          </Popover.Root>
          <Popover.Root>
            <Popover.Trigger asChild>
              <Button>Align Center</Button>
            </Popover.Trigger>
            <Popover.Content align="center" className="flex h-36 items-center justify-center text-sm italic">
              Place content for the popover here.
            </Popover.Content>
          </Popover.Root>
          <Popover.Root>
            <Popover.Trigger asChild>
              <Button>Align End</Button>
            </Popover.Trigger>
            <Popover.Content align="end" className="flex h-36 items-center justify-center text-sm italic">
              Place content for the popover here.
            </Popover.Content>
          </Popover.Root>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesPopovers;
