import React from "react";

interface ProgressBarProps {
  passPercent: number;
  failPercent: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ passPercent, failPercent }) => {
  return (
    <div className="relative h-4 w-full overflow-hidden rounded-full bg-gray-200">
      <div
        className={`absolute left-0 top-0 h-full bg-green-600 ${passPercent !== 100 ? "rounded-r-none" : "rounded-full"}`}
        style={{ width: `${passPercent}%` }}
      ></div>
      <div
        className={`absolute top-0 h-full rounded-r-full bg-red-600 ${passPercent !== 0 ? "rounded-l-none" : "rounded-full"}`}
        style={{ left: `${passPercent}%`, width: `${failPercent}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
