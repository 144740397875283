import Button from "@shared/components/primitives/Button";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIcons } from "@fortawesome/free-solid-svg-icons";
import ButtonGroup from "@shared/components/primitives/ButtonGroup";

const FrontendPrimitivesButtons = () => {
  return (
    <FrontendPrimitivesCard title="Buttons">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Basic Variants</div>
        <div className="flex gap-2">
          <Button variant="default">Default</Button>
          <Button variant="outline">Outline</Button>
          <Button variant="ghost">Ghost</Button>
          <Button variant="link">Link</Button>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Action Variants</div>
        <div className="flex gap-2">
          <Button variant="default">Default</Button>
          <Button variant="danger">Danger</Button>
          <Button variant="success">Success</Button>
          <Button variant="warning">Warning</Button>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">With Icons</div>
        <div className="flex gap-2">
          <Button symmetric>
            <FontAwesomeIcon icon={faIcons} />
          </Button>
          <Button>
            <FontAwesomeIcon icon={faIcons} />
            With Text
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">With Tooltip</div>
        <div className="flex gap-2">
          <Button tooltip="Tooltip!">Hover Over Me</Button>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Button Size</div>
        <div className="flex gap-2">
          <Button size="xs">Extra Small</Button>
          <Button size="sm">Small</Button>
          <Button size="md">Medium (default)</Button>
          <Button size="lg">Large</Button>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Loading Buttons</div>
        <div className="flex gap-2">
          <Button size="xs" isLoading>
            Default
          </Button>
          <Button size="sm" isLoading>
            Outline
          </Button>
          <Button size="md" isLoading>
            Ghost
          </Button>
          <Button size="lg" isLoading>
            Link
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Button Group</div>
        <div className="flex gap-2">
          <ButtonGroup.Root>
            <ButtonGroup.Button>
              <FontAwesomeIcon icon={faIcons} />
              Button 1
            </ButtonGroup.Button>
            <ButtonGroup.Button>Button 2</ButtonGroup.Button>
            <ButtonGroup.Button>Button 3</ButtonGroup.Button>
            <ButtonGroup.Button>Button 4</ButtonGroup.Button>
          </ButtonGroup.Root>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Button Group Variants</div>
        <div className="flex flex-col gap-2">
          <ButtonGroup.Root variant="default">
            <ButtonGroup.Button>Default 1</ButtonGroup.Button>
            <ButtonGroup.Button>Default 2</ButtonGroup.Button>
          </ButtonGroup.Root>
          <ButtonGroup.Root variant="default">
            <ButtonGroup.Button>Default 1</ButtonGroup.Button>
            <ButtonGroup.Button>Default 2</ButtonGroup.Button>
          </ButtonGroup.Root>
          <ButtonGroup.Root variant="danger">
            <ButtonGroup.Button>Danger 1</ButtonGroup.Button>
            <ButtonGroup.Button>Danger 2</ButtonGroup.Button>
          </ButtonGroup.Root>
          <ButtonGroup.Root variant="success">
            <ButtonGroup.Button>Success 1</ButtonGroup.Button>
            <ButtonGroup.Button>Success 2</ButtonGroup.Button>
          </ButtonGroup.Root>
          <ButtonGroup.Root variant="warning">
            <ButtonGroup.Button>Warning 1</ButtonGroup.Button>
            <ButtonGroup.Button>Warning 2</ButtonGroup.Button>
          </ButtonGroup.Root>
          <ButtonGroup.Root variant="outline">
            <ButtonGroup.Button>Outline 1</ButtonGroup.Button>
            <ButtonGroup.Button>Outline 2</ButtonGroup.Button>
          </ButtonGroup.Root>
          <ButtonGroup.Root variant="ghost">
            <ButtonGroup.Button>Ghost 1</ButtonGroup.Button>
            <ButtonGroup.Button>Ghost 2</ButtonGroup.Button>
          </ButtonGroup.Root>
          <ButtonGroup.Root variant="link">
            <ButtonGroup.Button>Link 1</ButtonGroup.Button>
            <ButtonGroup.Button>Link 2</ButtonGroup.Button>
          </ButtonGroup.Root>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesButtons;
