import { getRpcWithRetries } from "@shared/connections/supabaseGeneral";

export interface DailyData {
  date: string;
  first_pass: number;
  all_pass: number;
  total: number;
  process_id: string;
  process_name?: string;
  isMandatory?: boolean;
}

export const fetchYieldByTime = async (
  componentId: string | null,
  workOrderId: string | null,
  startDate: Date | undefined,
  endDate: Date | undefined,
): Promise<DailyData[]> => {
  if (!componentId || !startDate || !endDate) {
    return [];
  }

  let { data, error } = await getRpcWithRetries<DailyData[]>("insight_yield_by_time", {
    component_id_arg: componentId,
    work_order_id_arg: workOrderId,
    start_date: startDate,
    end_date: endDate,
  });

  if (error || !data) {
    console.error(error);
    return [];
  }

  return data;
};
