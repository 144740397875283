import React, { createContext, useState, useEffect, FC } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ProcessEntryAllData, GenealogyFamilyMember, ProcessWithEntries, SnLookupTimeline } from "./types";
import { UniqueIdentifier, Image, Company, Process, User, File } from "@shared/types/databaseTypes";
import { squashGenealogy } from "./helpers";
import { fetchSnLookupPageData } from "@shared/connections/supabaseSnLookup";
import {
  fetchImageDataBulk,
  fetchFileDataBulk,
  fetchLinkDataBulk,
  fetchCheckboxDataBulk,
  fetchImageDownloadUrls,
  fetchFileDownloadUrls,
  fetchParametricQualitativeDataBulk,
  fetchParametricQuantitativeDataBulk,
  fetchDatetimeDataBulk,
} from "@shared/connections/supabaseSnLookupTable";
import { UserRole } from "@shared/types/databaseEnums";
import useSnLookupTimelineLog from "./hooks/useSnLookupTimeline";
import useSnLookupHardwareRevisions from "./hooks/useSnLookupHardwareRevisions";
import { HardwareRevision } from "./hooks/useSnLookupHardwareRevisions";
import useComponentGenealogy from "@shared/hooks/useComponentGenealogy";

interface SnLookupContextProps {
  // States
  uniqueIdentifier: UniqueIdentifier | null;
  printModalOpen: boolean;
  isLoading: boolean;
  processTableIsLoading: boolean;
  overrideModalOpen: boolean;
  overrideModalEntry: ProcessEntryAllData | null;
  dataAnalysisModalOpen: boolean;
  // Setters
  setUniqueIdentifier: React.Dispatch<React.SetStateAction<UniqueIdentifier | null>>;
  setPrintModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOverrideModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOverrideModalEntry: React.Dispatch<React.SetStateAction<ProcessEntryAllData | null>>;
  setDataAnalysisModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProcess: React.Dispatch<React.SetStateAction<ProcessWithEntries | null>>;
  // Data
  activityLog: SnLookupTimeline[];
  hwRevisions: HardwareRevision[];
  processEntries: ProcessEntryAllData[];
  genealogy: GenealogyFamilyMember[];
  selectedProcess: ProcessWithEntries | null;
  company: Company | null;
  processes: Process[] | null;
  allImages: Image[];
  allFiles: File[];
  // Functions
  loadData: (uniqueIdentifier: UniqueIdentifier) => void;
  loadAllFilesAndImagesHighQuality: () => void;
  fetchComponentInstanceProcessData: (componentId: string, uniqueIdentifierId: string) => ProcessWithEntries[];
  fetchUser: (userId: string) => void;
}

const defaultContext: SnLookupContextProps = {
  // States
  uniqueIdentifier: null,
  printModalOpen: false,
  isLoading: false,
  processTableIsLoading: false,
  overrideModalOpen: false,
  overrideModalEntry: null,
  dataAnalysisModalOpen: false,
  // Setters - using noop functions for defaults
  setUniqueIdentifier: () => {},
  setPrintModalOpen: () => {},
  setIsLoading: () => {},
  setOverrideModalOpen: () => {},
  setOverrideModalEntry: () => {},
  setDataAnalysisModalOpen: () => {},
  setSelectedProcess: () => {},
  // Data
  activityLog: [],
  hwRevisions: [],
  processEntries: [],
  genealogy: [],
  selectedProcess: null,
  company: null,
  processes: null,
  allImages: [],
  allFiles: [],
  // Functions - using noop functions for defaults
  loadData: () => {},
  loadAllFilesAndImagesHighQuality: () => {},
  fetchComponentInstanceProcessData: () => [],
  fetchUser: () => {},
};

export const SnLookupContext = createContext<SnLookupContextProps>(defaultContext);

const SnLookupProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  // Redux
  const location = useLocation();
  const supabaseToken = useSelector((state: RootState) => state.auth.token);
  const role = useSelector((state: RootState) => state.auth.role);
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const dbIsLoaded = useSelector((state: RootState) => state.db.isLoaded);
  const company = useSelector((state: RootState) => state.db.company);
  const processes = useSelector((state: RootState) => state.db.processes);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const operators = useSelector((state: RootState) => state.db.operators);
  const users = useSelector((state: RootState) => state.db.users);

  // Rendering States
  const [uniqueIdentifierId, setUniqueIdentifierId] = useState<string>("");
  const [uniqueIdentifier, setUniqueIdentifier] = useState<UniqueIdentifier | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processTableIsLoading, setProcessTableIsLoading] = useState<boolean>(false);

  // Modals
  const [overrideModalEntry, setOverrideModalEntry] = useState<ProcessEntryAllData | null>(null);
  const [overrideModalOpen, setOverrideModalOpen] = useState<boolean>(false);
  const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);
  const [dataAnalysisModalOpen, setDataAnalysisModalOpen] = useState<boolean>(false);
  const [selectedProcess, setSelectedProcess] = useState<ProcessWithEntries | null>(null);

  // Data
  const [genealogy, setGenealogy] = useState<GenealogyFamilyMember[]>([]);
  const [processEntries, setProcessEntries] = useState<ProcessEntryAllData[]>([]);
  const [allImages, setAllImages] = useState<Image[]>([]);
  const [allFiles, setAllFiles] = useState<File[]>([]);

  const realtimeComponentInstance = componentInstances.find((instance) => instance.id === uniqueIdentifierId);

  const componentGenealogy = useComponentGenealogy(uniqueIdentifier?.component_id);
  const activityLog = useSnLookupTimelineLog({ processEntries, uniqueIdentifier, genealogy });
  const hwRevisions = useSnLookupHardwareRevisions({ componentGenealogy, genealogy, uniqueIdentifier });

  // set the uid prameter based on the url
  useEffect(() => {
    setUniqueIdentifierId(new URLSearchParams(location.search).get("uid") ?? "");
  }, [location]);

  useEffect(() => {
    if (overrideModalEntry && role === UserRole.Admin) setOverrideModalOpen(true);
  }, [overrideModalEntry]);

  // load data from backend on page load
  useEffect(() => {
    if (supabaseToken && uniqueIdentifierId) {
      // Reset all data and start loading
      setProcessEntries([]);
      setGenealogy([]);
      setUniqueIdentifier(null);
      loadData();
    }
  }, [supabaseToken, uniqueIdentifierId]);

  useEffect(() => {
    if (realtimeComponentInstance?.last_updated_at && dbIsLoaded) {
      loadData();
    }
  }, [realtimeComponentInstance?.last_updated_at]);

  const loadData = async () => {
    setProcessTableIsLoading(true);

    // Get genealogy and process entries without the associated data (parametric, images, files, links, etc.)
    const {
      genealogy: newGenealogy,
      unique_identifier: newUniqueIdentifier,
      process_entries: newProcessEntries,
    } = await fetchSnLookupPageData(uniqueIdentifierId);
    const squashedGenealogy = squashGenealogy(newGenealogy);

    if (!newProcessEntries) {
      setGenealogy(squashedGenealogy ?? []);
      setUniqueIdentifier(newUniqueIdentifier);
      setProcessTableIsLoading(false);
      return;
    }

    // UniqueIds in genealogy tree
    const uniqueIdentifierIds = newGenealogy.map((member) => member.id);
    const processEntryIds = newProcessEntries.map((entry) => entry.id);

    try {
      // Fetch images and files for all processEntries in the entire genealogy tree concurrently
      const [fileData, imageData, entriesData] = await Promise.all([
        fetchFileDataBulk(uniqueIdentifierIds),
        fetchImageDataBulk(uniqueIdentifierIds),
        loadProcessEntryData(uniqueIdentifierIds, processEntryIds),
      ]);

      setAllFiles(fileData);

      // Populate newProcessEntries with the fetched data
      for (let i = 0; i < newProcessEntries.length; i++) {
        newProcessEntries[i].fileData = fileData.filter((item) => item?.process_entry_id === newProcessEntries[i]?.id);
        newProcessEntries[i].linkData = entriesData.linkData.filter((item) => item?.process_entry_id === newProcessEntries[i]?.id);
        newProcessEntries[i].numericalData = entriesData.numericalData.filter(
          (item) => item?.process_entry_id === newProcessEntries[i]?.id,
        );
        newProcessEntries[i].textData = entriesData.textData.filter((item) => item?.process_entry_id === newProcessEntries[i]?.id);
        newProcessEntries[i].checkboxData = entriesData.checkboxData.filter((item) => item?.process_entry_id === newProcessEntries[i]?.id);
        newProcessEntries[i].datetimeData = entriesData.datetimeData.filter((item) => item?.process_entry_id === newProcessEntries[i]?.id);
      }
      setGenealogy(squashedGenealogy ?? []);
      setUniqueIdentifier(newUniqueIdentifier);
      setProcessEntries([...newProcessEntries]); // Make copy so that data manipulations from above propagate properly
      setProcessTableIsLoading(false);

      // Load the actual images and files into local storage and add the URLs into the imageData
      let newImageData: Image[] = [];
      if (imageData && imageData.length > 0) {
        newImageData = await fetchImageDownloadUrls(imageData, 20);
        setAllImages(newImageData);
      }
      for (let i = 0; i < newProcessEntries.length; i++) {
        newProcessEntries[i].imageData = newImageData.filter((item) => item?.process_entry_id === newProcessEntries[i]?.id);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const loadProcessEntryData = async (uniqueIdentifierIds: string[], processEntryIds: string[]) => {
    // Fetch all the required data in one go, for each data type
    const [linkData, numericalData, textData, checkboxData, datetimeData] = await Promise.all([
      fetchLinkDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchParametricQuantitativeDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchParametricQualitativeDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchCheckboxDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchDatetimeDataBulk(uniqueIdentifierIds, processEntryIds),
    ]);
    const resultMap = {
      linkData,
      numericalData,
      textData,
      checkboxData,
      datetimeData,
    };

    return resultMap;
  };

  const loadAllFilesAndImagesHighQuality = async () => {
    let newProcessEntries = [...processEntries];

    // load the actual images and files into local storage and add the urls into the imageData and fileData arrays
    const promises = newProcessEntries.map(async (entry: ProcessEntryAllData, i: number) => {
      if (entry.imageData && entry.imageData.length > 0) {
        const newImageData = await fetchImageDownloadUrls(entry.imageData, 100);
        newProcessEntries[i].imageData = newImageData;
      }
      if (entry.fileData && entry.fileData.length > 0) {
        const newFileData = await fetchFileDownloadUrls(entry.fileData);
        newProcessEntries[i].fileData = newFileData;
      }
    });

    await Promise.all(promises);
    setProcessEntries([...newProcessEntries]); // return as a new array to force react to re-render once the data loads
    return [...newProcessEntries];
  };

  const fetchComponentInstanceProcessData = (componentId: string, uniqueIdentifierId: string): ProcessWithEntries[] => {
    const filteredComponentProcessLinks = componentProcessLinks
      .filter((process) => process.component_id === componentId && process.is_active === true)
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    const filteredProcessIds = new Set(filteredComponentProcessLinks.map((process) => process.process_id));
    const filteredProcesses = [...filteredProcessIds]
      .map((id) => processes.find((process) => process.id === id))
      .filter((process) => process && process.type === "PRODUCTION") as Process[];

    const filteredProcessEntries = processEntries.filter(
      (entry) => entry.unique_identifier_id === uniqueIdentifierId && filteredProcessIds.has(entry.process_id),
    );

    const processesWithEntries: ProcessWithEntries[] = filteredProcesses.map((process) => {
      return {
        ...process,
        entries: filteredProcessEntries.filter((entry) => entry.process_id === process.id),
      };
    });

    return processesWithEntries;
  };

  const fetchUser = (userId: string): User | null => {
    let user = users.find((usr) => usr.supabase_uid === userId) || null;

    if (!user && operators) {
      const operator = operators.find((op) => op.id === userId) || null;
      if (operator) {
        user = users.find((usr) => usr.supabase_uid === operator.user_id) || null;
      }
    }

    return user;
  };

  return (
    <SnLookupContext.Provider
      value={{
        // States
        uniqueIdentifier,
        printModalOpen,
        isLoading,
        processTableIsLoading,
        overrideModalOpen,
        overrideModalEntry,
        dataAnalysisModalOpen,
        // Setters
        setUniqueIdentifier,
        setPrintModalOpen,
        setIsLoading,
        setOverrideModalOpen,
        setOverrideModalEntry,
        setDataAnalysisModalOpen,
        setSelectedProcess,
        // Data
        activityLog,
        hwRevisions,
        processEntries,
        genealogy,
        selectedProcess,
        company,
        processes,
        allImages,
        allFiles,
        // Functions
        loadData,
        loadAllFilesAndImagesHighQuality,
        fetchComponentInstanceProcessData,
        fetchUser,
      }}
    >
      {children}
    </SnLookupContext.Provider>
  );
};

export default SnLookupProvider;
