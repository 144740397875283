import { WorkInstructionBlock, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { allowedFileExtensionsByBlockType, workInstructionBlockAliases, workInstructionBlockOptions } from "../constants";
import { ProcessBuilderAddWorkInstructionBlockDropdown } from "./ProcessBuilderAddDropdown";
import { useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import { ProcessBuilderDraggableType } from "../types";
import ContentEditable from "../../../shared/components/ContentEditable";
import { workInstructionBlocksToMarkdown } from "../helpers";
import { generateWorkInstructionsSuggestions } from "../connections/api";

interface ProcessBuilderWorkInstructionBlockPlaceholderProps {
  stepIndex: number;
  workInstructionBlockIndex: number;
  placeholderInputRef: React.RefObject<HTMLDivElement>;
}

const DROPDOWN_OPTION_HEIGHT = 30;

const ProcessBuilderWorkInstructionBlockPlaceholder: React.FC<ProcessBuilderWorkInstructionBlockPlaceholderProps> = ({
  stepIndex,
  workInstructionBlockIndex,
  placeholderInputRef,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    readOnly,
    handleAddNewWorkInstructionBlock,
    handleMoveWorkInstructionBlock,
    hoveredWorkInstructionBlockId,
    setHoveredWorkInstructionBlockId,
    handleUploadFile,
    handleUpdateWorkInstructionBlocks,
    draftProcess,
  } = useContext(ProcessBuilderContext);

  const placeholderId = useMemo(() => `STEP_${stepIndex}_PLACEHOLDER`, [stepIndex]);

  const [placeholderText, setPlaceholderText] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<typeof workInstructionBlockOptions>([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(0);
  const [dropdownPosition, setDropdownPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [allowPaste, setAllowPaste] = useState<boolean>(false);
  const [dragOver, setDragOver] = useState<boolean>(false);

  const handlePlaceholderTextChange = (text: string) => {
    setPlaceholderText(text);
    // if text starts with /, then it's a command. filter the the options based on the text that follows the /
    if (text.trim().startsWith("/")) {
      const command = text.slice(1);
      let filteredOptions: typeof workInstructionBlockOptions = [];
      Object.entries(workInstructionBlockAliases).forEach(([blockType, aliases]) => {
        // if the command exists as a substring of any of the aliases, add the option to the filtered options
        const filteredAliases = aliases.filter((alias) => alias.includes(command.toLowerCase()));
        if (filteredAliases.length > 0) {
          const option = workInstructionBlockOptions.find((option) => option.type === blockType);
          option && filteredOptions.push(option);
        }
      });
      setFilteredOptions(filteredOptions);
      setFocusedOptionIndex(0);
      // set dropdown position to bottom of the placeholder input
      const placeholderInput = placeholderInputRef?.current;
      if (placeholderInput) {
        const { x, y } = placeholderInput.getBoundingClientRect();
        const maxDropdownHeight = Object.entries(workInstructionBlockAliases).length * DROPDOWN_OPTION_HEIGHT + 16;
        const currDropdownHeight = filteredOptions.length * DROPDOWN_OPTION_HEIGHT + 16;
        if (y > window.innerHeight - maxDropdownHeight - 50) {
          setDropdownPosition({ x, y: y - currDropdownHeight - 32 });
        } else {
          setDropdownPosition({ x, y: y });
        }
      }
    } else {
      setFilteredOptions([]);
    }
  };

  const handleAcceptCopilotSuggestion = (newContent: string) => {
    setPlaceholderText("");
    handleAddNewWorkInstructionBlock(stepIndex, workInstructionBlockIndex, WorkInstructionBlockType.Text, false, { text: newContent });
  };

  const getCopilotSuggestion = async (newContent: string) => {
    // get all work instructions up to and including the current step index
    const workInstructionsUpToCurrentStep =
      draftProcess?.process_steps.slice(0, stepIndex + 1).flatMap((step) => step.work_instruction_blocks) ?? [];
    let markdownWorkInstructions = workInstructionBlocksToMarkdown(workInstructionsUpToCurrentStep);
    // append the current work instruction block with the new content that is in the placeholder on a new line
    if (newContent.trim() !== "") {
      markdownWorkInstructions += `\n\n${newContent}`;
    }
    return generateWorkInstructionsSuggestions(markdownWorkInstructions);
  };

  const handleSelectOption = (type: WorkInstructionBlockType) => {
    setPlaceholderText("");
    setFilteredOptions([]);
    handleAddNewWorkInstructionBlock(stepIndex, workInstructionBlockIndex, type, true);
  };

  const handleBlur = () => {
    // if text is not a command, add a new text block
    if (!placeholderText.trim().startsWith("/") && placeholderText.trim() !== "") {
      handleAddNewWorkInstructionBlock(stepIndex, workInstructionBlockIndex, WorkInstructionBlockType.Text, false, {
        text: placeholderText,
      });
      setTimeout(() => placeholderInputRef?.current?.focus(), 0);
    }
    setPlaceholderText("");
    setFilteredOptions([]);
    setAllowPaste(false);
  };

  const handleDropOrPasteFile = async (file: File) => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    let type:
      | WorkInstructionBlockType.Image
      | WorkInstructionBlockType.Video
      | WorkInstructionBlockType.PDF
      | WorkInstructionBlockType.File = WorkInstructionBlockType.File;
    for (const [blockType, allowedExtensions] of Object.entries(allowedFileExtensionsByBlockType)) {
      if (allowedExtensions.includes(fileExtension ?? "")) {
        type = blockType as
          | WorkInstructionBlockType.Image
          | WorkInstructionBlockType.Video
          | WorkInstructionBlockType.PDF
          | WorkInstructionBlockType.File;
        break;
      }
    }
    const newBlockId = handleAddNewWorkInstructionBlock(stepIndex, workInstructionBlockIndex, type, true);
    if (newBlockId) {
      // set file name first to trigger the loader in the file component
      handleUpdateWorkInstructionBlocks(stepIndex, [newBlockId], "file_name", file.name, true);
      const fileId = await handleUploadFile(file, type);
      if (!fileId) return;
      handleUpdateWorkInstructionBlocks(stepIndex, [newBlockId], "file_id", fileId, false);
    }
  };

  const onDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
    handleDropOrPasteFile(e.dataTransfer.files[0]);
  };

  const onPaste = (e: any) => {
    const clipboardItems = e.clipboardData?.items;
    if (!clipboardItems) return;
    for (let i = 0; i < clipboardItems.length; i++) {
      if (clipboardItems[i].kind === "file") {
        e.preventDefault();
        const file = clipboardItems[i].getAsFile();
        handleDropOrPasteFile(file);
      }
    }
  };

  useEffect(() => {
    if (!allowPaste) return;
    window.addEventListener("paste", onPaste);
    return () => {
      window.removeEventListener("paste", onPaste);
    };
  }, [allowPaste]);

  // bind up and down arrow keys to navigate the filtered options and enter to select the option
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // add a new item when enter is pressed
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent new line in content editable
      if (filteredOptions[focusedOptionIndex]?.type) {
        handleSelectOption(filteredOptions[focusedOptionIndex].type);
      } else {
        handleBlur();
      }
    }
    // if down arrow is pressed, focus on the next item if it exists
    if (event.key === "ArrowDown" || event.key === "Tab") {
      event.preventDefault(); // Prevent new line in content editable
      if (focusedOptionIndex === filteredOptions.length - 1) return;
      setFocusedOptionIndex(focusedOptionIndex + 1);
    }
    // if up arrow is pressed, focus on the previous item if it exists
    if (event.key === "ArrowUp") {
      event.preventDefault(); // Prevent new line in content editable
      if (focusedOptionIndex === 0) return;
      setFocusedOptionIndex(focusedOptionIndex - 1);
    }
  };

  const [{ handlerId }, drop] = useDrop<WorkInstructionBlock, void, { handlerId: Identifier | null }>({
    accept: ProcessBuilderDraggableType.WorkInstructionBlock,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: WorkInstructionBlock, _monitor) {
      if (!ref.current) return;
      handleMoveWorkInstructionBlock(item.id, stepIndex, Math.max(workInstructionBlockIndex - 1, 0));
    },
    hover() {
      setHoveredWorkInstructionBlockId(placeholderId);
    },
  });

  drop(ref);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={"group relative flex min-h-[46px] w-full flex-grow items-start"}
      onMouseLeave={() => setHoveredWorkInstructionBlockId(null)}
      onDragOver={() => setDragOver(true)}
      onDragLeave={() => setDragOver(false)}
      onClick={() => placeholderInputRef?.current?.focus()}
      onDrop={onDropFile}
    >
      {!readOnly && (
        <div className="absolute left-3 top-3 flex gap-x-1.5">
          <ProcessBuilderAddWorkInstructionBlockDropdown stepIndex={stepIndex} tileIndex={workInstructionBlockIndex} />
        </div>
      )}
      <div className={`relative mx-10 flex min-w-0 flex-grow flex-col p-2.5 ${dragOver && "rounded border border-dashed"}`}>
        {dragOver && <div className="text-serial-palette-300 mt-6 flex w-full justify-center whitespace-nowrap">Add File</div>}
        <ContentEditable
          contentRef={placeholderInputRef}
          className="w-full opacity-0 outline-0 focus:opacity-100 group-hover:opacity-100"
          onKeyDown={(e) => handleKeyDown(e)}
          onBlur={() => handleBlur()}
          onFocus={() => setAllowPaste(true)}
          onInput={() => handlePlaceholderTextChange(placeholderInputRef?.current?.innerText ?? "")}
          placeholder="Press '/' for commands or type here for text"
          content={placeholderText}
          copilot={!placeholderText.trim().startsWith("/")}
          handleAcceptCopilotSuggestion={(newContent) => handleAcceptCopilotSuggestion(newContent)}
          getCopilotSuggestion={(newContent) => getCopilotSuggestion(newContent)}
        />
        {filteredOptions.length > 0 && (
          <div
            className="left-0 top-12 z-20 w-40 rounded border bg-white py-1.5"
            style={{ position: "fixed", left: `${dropdownPosition.x}px`, top: `${dropdownPosition.y + 32}px` }}
          >
            {filteredOptions.map((option, index) => {
              return (
                <button
                  key={index}
                  className={`text-serial-palette-600 hover:bg-serial-palette-50 flex w-full items-center gap-2 text-sm font-medium ${index === focusedOptionIndex && "bg-serial-palette-50"} h-[30px] px-2 py-1`}
                  onMouseDownCapture={() => handleSelectOption(option.type)}
                >
                  <div className="flex w-5 justify-center">
                    <FontAwesomeIcon icon={option.icon} className="text-serial-palette-500" />
                  </div>
                  <div className="">{option.label}</div>
                </button>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={`border-serial-palette-100 absolute -top-0.5 w-full border-b-4 transition-opacity ${placeholderId === hoveredWorkInstructionBlockId ? "opacity-100" : "opacity-0"}`}
      />
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlockPlaceholder;
