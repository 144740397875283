import { useContext, useMemo } from "react";
import { CalloutBlock } from "@shared/types/databaseTypes";
import { ProductionWorkInstructionBlockContentProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faInfoCircle, faSkullCrossbones } from "@fortawesome/free-solid-svg-icons";
import { useMentions } from "@shared/hooks/useMentions";
import { ProductionContext } from "../../ProductionProvider";

const ProductionWorkInstructionBlockCallout: React.FC<ProductionWorkInstructionBlockContentProps<CalloutBlock>> = ({ block }) => {
  const { partNumber } = useContext(ProductionContext);
  const { DecodedContent } = useMentions({ encodedContent: block.content.text, partNumber: partNumber ?? undefined });

  const { icon, iconClass, bgClass, textClass } = useMemo(() => {
    switch (block.content.icon) {
      case "INFO":
        return {
          icon: faInfoCircle,
          iconClass: "text-blue-600",
          bgClass: "border border-blue-600 bg-blue-50",
          textClass: "text-blue-600",
        };
      case "WARNING":
        return {
          icon: faExclamationTriangle,
          iconClass: "text-amber-600",
          bgClass: "border border-amber-600 bg-amber-50",
          textClass: "text-amber-600",
        };
      case "DANGER":
        return {
          icon: faSkullCrossbones,
          iconClass: "text-red-600",
          bgClass: "border border-red-600 bg-red-50",
          textClass: "text-red-600",
        };
      default:
        return {
          icon: faInfoCircle,
          iconClass: "text-blue-500",
          bgClass: "border border-blue-600 bg-blue-50",
          textClass: "text-blue-500",
        };
    }
  }, [block.content.icon]);

  return (
    <div className={`flex min-h-[44px] w-full gap-x-2 rounded-md p-2 ${bgClass}`}>
      <FontAwesomeIcon icon={icon} className={`px-2 pt-1.5 ${iconClass}`} />
      <div className={`w-full font-medium ${textClass}`}>{DecodedContent}</div>
    </div>
  );
};

export default ProductionWorkInstructionBlockCallout;
