import { useContext, useEffect, useState } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { Process } from "@shared/types/databaseTypes";
import { fetchProcessRevisions } from "../connections/supabase";
import moment from "moment";
import UserAvatar from "@shared/components/UserAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { ToastContext } from "@shared/context/ToastProvider";
import { updateProcessRevisionDescription } from "../connections/revisionDescription";
import { Loader } from "@shared/components/Loader";
import VersionBlock from "@shared/components/VersionBlock";
import { UserRole } from "@shared/types/databaseEnums";
import { approveProcessRevision } from "../connections/api";

interface ProcessBuilderRevisionHistoryRowProps {
  process: Process;
  handleReload: () => void;
  index: number;
  showApproveButton?: boolean;
}

const ProcessBuilderRevisionHistoryRow: React.FC<ProcessBuilderRevisionHistoryRowProps> = ({
  process,
  handleReload,
  index,
  showApproveButton,
}) => {
  const { triggerConfirmation } = useContext(ToastContext);
  const user = useSelector((state: RootState) => state.db.users).find((usr) => usr.supabase_uid === process.created_by_user_id);
  const role = useSelector((state: RootState) => state.auth.role);
  const approvedByUser = useSelector((state: RootState) => state.db.users.find((usr) => usr.supabase_uid === process.approved_by_user_id));

  const [isLoading, setIsLoading] = useState(false);
  const [showEntireDescription, setShowEntireDescription] = useState(false);

  const handleGenerateAISummary = (process: Process) => {
    triggerConfirmation(
      "Generate AI Summary?",
      "By clicking this button you agree to send the current revision description to the OpenAI API",
      async () => {
        setIsLoading(true);
        await updateProcessRevisionDescription(process.id, process.revision, process.revision_description, true);
        await handleReload();
        setIsLoading(false);
      },
      undefined,
      "Summarize",
    );
  };

  const handleApproveRevision = (process: Process) => {
    triggerConfirmation(
      "Approve Revision?",
      "By clicking this button you agree to approve this revision. This will make it the default revision in the production app.",
      async () => {
        setIsLoading(true);
        await approveProcessRevision(process.id, process.revision);
        await handleReload();
        setIsLoading(false);
      },
      undefined,
      "Approve",
    );
  };

  return (
    <tr className="group bg-white" key={index}>
      <td className="justify-center whitespace-nowrap px-4 py-3 first:pl-5 last:pr-5">
        <div className="flex justify-center">
          <VersionBlock
            version={process.revision.toString()}
            size="md"
            color={process.approved ? "green" : index === 0 ? "black" : "grey"}
          />
        </div>
      </td>
      <td className="whitespace-nowrap px-4 py-3 first:pl-5 last:pr-5">
        <div className="flex h-full w-full items-center justify-center gap-x-3">
          {user && <UserAvatar user={user} customTooltip={moment(process.created_at).format("MM/DD/YY hh:mma")} size="md" enableToolTip />}
        </div>
      </td>
      <td className="relative whitespace-pre-line px-4 py-3 text-left first:pl-5 last:pr-5">
        <div
          onClick={() => setShowEntireDescription(!showEntireDescription)}
          className={`cursor-pointer ${showEntireDescription ? "" : "line-clamp-4"}`}
        >
          {(process.ai_revision_description || process.revision_description).trim()}
        </div>
        {(!process.ai_revision_description || isLoading) && (
          <button
            onClick={() => handleGenerateAISummary(process)}
            className={`absolute bottom-2 right-2 ${!isLoading && "hidden group-hover:flex"} serial-btn-light btn-sm h-7`}
          >
            {!isLoading && <FontAwesomeIcon icon={faMagicWandSparkles} className="text-violet-500" />}
            {isLoading && <Loader styleOverride="h-[18px] w-[18px] text-violet-500" />}
          </button>
        )}
      </td>
      <td className="px-4 py-3 text-center first:pl-5 last:pr-5">
        {process.approved && approvedByUser && (
          <div className="flex h-full w-full items-center justify-center gap-x-3">
            <UserAvatar
              user={approvedByUser}
              customTooltip={moment(process.approved_at).format("MM/DD/YY hh:mma")}
              size="md"
              enableToolTip
            />
          </div>
        )}
        {!process.approved && showApproveButton && role === UserRole.Admin && (
          <button onClick={() => handleApproveRevision(process)} className="serial-btn-success btn-sm h-7">
            Approve
          </button>
        )}
      </td>
    </tr>
  );
};

const ProcessBuilderRevisionHistory: React.FC = () => {
  const { draftProcess } = useContext(ProcessBuilderContext);
  const [processRevisions, setProcessRevisions] = useState<Process[]>([]);
  // Find the highest revision number among approved revisions
  const highestApprovedRevisionNumber = processRevisions.reduce((max, revision) => {
    return revision.approved && revision.revision > max ? revision.revision : max;
  }, -1);

  const loadRevisionHistory = async () => {
    if (draftProcess?.id) {
      const response = await fetchProcessRevisions(draftProcess.id);
      setProcessRevisions(response.data?.filter((process: Process) => process.revision !== 0) ?? []);
    }
  };

  useEffect(() => {
    loadRevisionHistory();
  }, [draftProcess?.id]);

  return (
    <div className="scrollbar-hide h-full overflow-x-auto">
      <div className="text-serial-palette-800 w-full whitespace-nowrap bg-white px-4 py-3 font-semibold">Revision History</div>
      <table className="w-full table-auto">
        {/* Table header */}
        <thead className="neutral-500 border-b border-t text-xs font-semibold uppercase">
          <tr>
            <th className="whitespace-nowrap px-4 py-3 first:pl-5 last:pr-5">
              <div className="text-center font-semibold">Revision</div>
            </th>
            <th className="whitespace-nowrap px-4 py-3 first:pl-5 last:pr-5">
              <div className="text-center font-semibold">Edited By</div>
            </th>
            <th className="whitespace-nowrap px-4 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Revision Description</div>
            </th>
            <th className="whitespace-nowrap px-4 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Approved By</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="divide- neutral-200 divide-y overflow-y-auto text-sm">
          {processRevisions.map((processRevision, index) => {
            // Determine if this revision should show the approve button
            const showApproveButton =
              processRevision.revision === processRevisions.length && processRevision.revision !== highestApprovedRevisionNumber;
            return (
              <ProcessBuilderRevisionHistoryRow
                key={index}
                process={processRevision}
                handleReload={loadRevisionHistory}
                index={index}
                showApproveButton={showApproveButton}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProcessBuilderRevisionHistory;
