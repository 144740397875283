import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Transition from "./Transition";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

interface CopyToClipboardProps {
  text: string;
  size?: SizeProp;
  className?: string;
  buttonText?: string;
  noTooltip?: boolean;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text, size, className, buttonText, noTooltip = false }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      setTooltipOpen(true);
    } catch (error) {
      console.error("Error copying text: ", error);
    }
  };

  // A timer to dismiss the tooltip
  useEffect(() => {
    if (tooltipOpen) {
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);
    }
  }, [tooltipOpen]);

  return (
    <div className={`relative`}>
      <div onClick={(e) => handleCopy(e)} className={`cursor-pointer whitespace-nowrap px-2 opacity-80 hover:opacity-100 ${className}`}>
        <FontAwesomeIcon icon={tooltipOpen && noTooltip ? faCheckSquare : faCopy} size={size || "sm"} />
        {buttonText && <span className="pl-2">{buttonText}</span>}
      </div>
      <div className={`z-60 absolute bottom-full left-1/2 -translate-x-1/2`}>
        {!noTooltip && (
          <Transition
            show={tooltipOpen}
            tag="div"
            className={`bg-serial-palette-700 border-serial-palette-900 text-serial-palette-100 mb-1.5 rounded  border p-1.5`}
            enter="transition ease-out duration-200 transform"
            enterStart="opacity-0 -translate-y-2"
            enterEnd="opacity-100 translate-y-0"
            leave="transition ease-out duration-200"
            leaveStart="opacity-100"
            leaveEnd="opacity-0"
            appear={null}
          >
            <div className="text-sm font-medium">Copied!</div>
          </Transition>
        )}
      </div>
    </div>
  );
};

export default CopyToClipboard;
