import React, { useContext, useMemo, useState } from "react";
import WorkOrderStatusTag from "./WorkOrderStatusTag";
import { workOrderStatusStyle } from "../contants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faChevronRight, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { UniqueIdentifier, WorkOrder } from "@shared/types/databaseTypes";
import { UniqueIdentifierStatus, WorkOrderStatus } from "@shared/types/databaseEnums";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { Loader } from "@shared/components/Loader";
import { WorkOrderContext } from "../WorkOrders";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { useTranslation } from "react-i18next";

interface WorkOrderStatusBarProps {
  workOrder: WorkOrder;
  unitList: UniqueIdentifier[];
}

const validStatusTransitions: Record<WorkOrderStatus, WorkOrderStatus[]> = {
  [WorkOrderStatus.Draft]: [WorkOrderStatus.Review, WorkOrderStatus.Canceled],
  [WorkOrderStatus.Review]: [WorkOrderStatus.Draft, WorkOrderStatus.Production, WorkOrderStatus.Canceled],
  [WorkOrderStatus.Production]: [WorkOrderStatus.Complete, WorkOrderStatus.Canceled],
  [WorkOrderStatus.Complete]: [WorkOrderStatus.Archived, WorkOrderStatus.Production],
  [WorkOrderStatus.Canceled]: [WorkOrderStatus.Archived, WorkOrderStatus.Draft],
  [WorkOrderStatus.Archived]: [],
};

const defaultNextStatus: Record<WorkOrderStatus, WorkOrderStatus> = {
  [WorkOrderStatus.Draft]: WorkOrderStatus.Review,
  [WorkOrderStatus.Review]: WorkOrderStatus.Production,
  [WorkOrderStatus.Production]: WorkOrderStatus.Complete,
  [WorkOrderStatus.Complete]: WorkOrderStatus.Archived,
  [WorkOrderStatus.Canceled]: WorkOrderStatus.Archived,
  [WorkOrderStatus.Archived]: WorkOrderStatus.Archived,
};

const WorkOrderStatusBar: React.FC<WorkOrderStatusBarProps> = ({ workOrder, unitList }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useContext(WorkOrderContext);
  const observe = useContext(ObservabilityContext);
  const { t } = useTranslation();

  const numUnitsCompleted = useMemo(() => {
    return unitList.filter((u) => u.status === UniqueIdentifierStatus.Complete).length;
  }, [unitList]);

  const handleSetStatus = async (status: WorkOrderStatus) => {
    setIsLoading(true);
    // confirm with user if numUnitsCompleted is less than workOrder.quantity
    if (status === WorkOrderStatus.Complete && numUnitsCompleted < workOrder.quantity) {
      const confirm = window.confirm(
        `${numUnitsCompleted} units are finished production of ${workOrder.quantity} required by the ${t("workOrder").toLowerCase()}. Are you sure you want to change the status to "Completed"?`,
      );
      if (!confirm) {
        setIsLoading(false);
        return;
      }
    }
    await context?.handleSetStatus(workOrder.id, status);
    setIsLoading(false);
    observe.track("work-order_status-change", { workOrderStatus: status });
  };

  return (
    <div className="flex w-full justify-between gap-3">
      <div className="border-serial-palette-200 flex h-16 w-full items-center justify-evenly rounded-md border bg-white">
        {Object.values(WorkOrderStatus)
          .filter((status) => {
            if (workOrder.status === WorkOrderStatus.Canceled) {
              return status !== WorkOrderStatus.Archived;
            }
            return status !== WorkOrderStatus.Canceled;
          })
          .map((status, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={`flex items-center gap-3 px-2 py-1.5 xl:px-6 ${status === workOrder.status ? "bg-serial-palette-50 rounded-lg border-2 font-bold" : "opacity-20"}`}
                >
                  <WorkOrderStatusTag status={status} size={28} />
                  <div className="hidden lg:flex">{workOrderStatusStyle[status].name}</div>
                </div>
                {index < Object.values(WorkOrderStatus).length - 2 && (
                  <FontAwesomeIcon key={index} className="text-serial-palette-300" icon={faChevronRight} />
                )}
              </React.Fragment>
            );
          })}
      </div>
      {workOrder.status !== WorkOrderStatus.Archived && !location.pathname.includes("/workorder/new") && (
        <div className="border-serial-palette-100 flex border">
          {validStatusTransitions[workOrder.status].length > 0 && (
            <button
              className="btn w-full rounded-r-none bg-green-600 pl-5 text-white last:rounded-r last:px-5 hover:bg-green-500"
              onClick={() => handleSetStatus(defaultNextStatus[workOrder.status])}
            >
              {isLoading ? (
                <Loader styleOverride="text-white w-[24px] h-[24px]" />
              ) : (
                <FontAwesomeIcon icon={workOrderStatusStyle[defaultNextStatus[workOrder.status]].icon} size="xl" />
              )}
              <div className="ml-3 hidden px-0 font-bold md:block">
                {workOrderStatusStyle[defaultNextStatus[workOrder.status]].transitionLabel}
              </div>
            </button>
          )}
          {validStatusTransitions[workOrder.status].length > 1 && (
            <div className="flex h-full">
              <DropdownCustom
                align="right"
                className="flex h-full"
                dropdownClassName="w-52 pb-1.5"
                keepOpen={true}
                buttonContents={
                  <div className="btn border-l-1 text-white' flex h-full items-center rounded-l-none border-l-green-200 bg-green-600 text-white hover:bg-green-500">
                    <FontAwesomeIcon icon={faCaretDown} />
                  </div>
                }
              >
                {validStatusTransitions[workOrder.status].map((alternateStatus, index) => {
                  if (alternateStatus !== defaultNextStatus[workOrder.status]) {
                    return (
                      <button
                        key={index}
                        className="hover:bg-serial-palette-200 flex w-full items-center gap-3 px-4 py-1"
                        onClick={() => handleSetStatus(alternateStatus)}
                      >
                        <FontAwesomeIcon icon={workOrderStatusStyle[alternateStatus].icon} className="text-serial-palette-500" />
                        <div className="">{workOrderStatusStyle[alternateStatus].transitionLabel}</div>
                      </button>
                    );
                  }
                })}
                {workOrder.status === WorkOrderStatus.Draft && (
                  <button
                    className="hover:bg-serial-palette-200 flex w-full items-center gap-3 px-4 py-1"
                    onClick={() => (context?.handleDeleteWorkOrder(workOrder.id), observe.track("work-order_delete"))}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="text-serial-palette-500" />
                    <div className="">Delete {t("workOrder")}</div>
                  </button>
                )}
              </DropdownCustom>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkOrderStatusBar;
