import React, { useState, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import Tooltip from "@shared/components/Tooltip";
import DropdownOptions from "@shared/components/dropdowns/DropdownOptions";
import { ProcessType } from "@shared/types/databaseEnums";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { RootState } from "@shared/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const DependencySpecialOptions = {
  All: "All",
  AllMandatory: "All Mandatory",
};

const ProcessBuilderSettingsDependencies: React.FC = () => {
  const { draftProcess, handleUpdateDraftProcess, component } = useContext(ProcessBuilderContext);
  const componentProcessLinks =
    useSelector((state: RootState) => state.db.componentProcessLinks).filter((link) => link.component_id === component?.id) ?? [];

  const [dependencySpecialOptions, setDependencySpecialOptions] = useState([
    { name: DependencySpecialOptions.All, checked: false },
    { name: DependencySpecialOptions.AllMandatory, checked: false },
  ]);

  const processes = useMemo(() => {
    return componentProcessLinks
      .filter((link) => {
        return link.process?.type === ProcessType.Production;
      })
      .map((link) => {
        return {
          ...link.process,
          order: link.order,
        };
      })
      .sort((a, b) => {
        if (!a.order || !b.order) {
          return 0;
        }
        return a.order - b.order;
      });
  }, [componentProcessLinks]);

  const dependencyOptions = useMemo(() => {
    return processes
      .map((process) => {
        // if the process is active and is a production process, or if the process is a draft and is a dependent process, add it to the dependency options
        const componentProcessLink = componentProcessLinks.find((link) => link.process_id === process.id);
        const draftProcessOrder = componentProcessLinks.find((link) => link.process_id === draftProcess?.id)?.order;
        if (
          componentProcessLink?.is_active &&
          process.type === ProcessType.Production &&
          (draftProcess?.id === null ||
            (draftProcessOrder && componentProcessLink?.order && componentProcessLink?.order < draftProcessOrder))
        ) {
          return { id: process.id, name: process.name, order: process.order, isMandatory: process.is_mandatory };
        } else if (draftProcess?.dependent_process_ids.includes(process.id)) {
          return { id: process.id, name: process.name, order: process.order, notApplicable: true, isMandatory: process.is_mandatory };
        }
        return null;
      })
      .filter((p) => p !== null) as { id: string; name: string; order: number | null; notApplicable?: boolean; isMandatory: boolean }[];
  }, [processes, draftProcess]);

  const toggleDependency = (processId: string) => {
    var modifiedDependencies = [...(draftProcess?.dependent_process_ids ?? [])];
    if (modifiedDependencies.includes(processId)) {
      modifiedDependencies = modifiedDependencies.filter((id) => id !== processId);
    } else {
      modifiedDependencies.push(processId);
    }
    handleUpdateDraftProcess("dependent_process_ids", modifiedDependencies);
    setDependencySpecialOptions([
      { name: DependencySpecialOptions.All, checked: false },
      { name: DependencySpecialOptions.AllMandatory, checked: false },
    ]);
  };

  const handleSelectSpecialOption = (specialOptionId: string, bool: boolean) => {
    if (specialOptionId === DependencySpecialOptions.All) {
      let modifiedDependencyIds: string[] = [];
      if (bool) {
        modifiedDependencyIds = dependencyOptions.map((p) => p.id);
      }
      handleUpdateDraftProcess("dependent_process_ids", modifiedDependencyIds);
      setDependencySpecialOptions([
        { name: DependencySpecialOptions.All, checked: bool },
        { name: DependencySpecialOptions.AllMandatory, checked: false },
      ]);
    } else if (specialOptionId === DependencySpecialOptions.AllMandatory) {
      let modifiedDependencyIds: string[] = [];
      if (bool) {
        modifiedDependencyIds = dependencyOptions.filter((p) => p.isMandatory && !p.notApplicable).map((p) => p.id);
      }
      handleUpdateDraftProcess("dependent_process_ids", modifiedDependencyIds);
      setDependencySpecialOptions([
        { name: DependencySpecialOptions.All, checked: false },
        { name: DependencySpecialOptions.AllMandatory, checked: bool },
      ]);
    }
  };

  return (
    <>
      {dependencyOptions?.length > 0 && (
        <div className="flex flex-col lg:w-full lg:flex-row lg:items-center lg:justify-between">
          <div className="mr-5 flex items-center space-x-2">
            <label className="lg:text flex h-min text-sm font-medium">Dependencies</label>
            <Tooltip
              position="right"
              size="lg"
              content={
                <div className="text-sm">
                  <div className="pb-2"> Select processes that must be completed before this one.</div>
                  <a href="https://docs.serial.io" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                    Read more
                  </a>
                </div>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
            </Tooltip>
          </div>
          <div className="flex flex-nowrap pt-2">
            <DropdownOptions
              classOverride=""
              align="right"
              options={dependencyOptions}
              selected={draftProcess?.dependent_process_ids}
              toggle={toggleDependency}
              title={`${draftProcess?.dependent_process_ids.length} Dependent Process${draftProcess?.dependent_process_ids.length !== 1 ? "es" : ""}`}
              subtitle={null}
              specialOptions={dependencySpecialOptions}
              handleSelectSpecialOptions={handleSelectSpecialOption}
              numbered={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProcessBuilderSettingsDependencies;
