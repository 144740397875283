import React, { useState, useEffect } from "react";
import SnLookUpGenealogyTableItem from "./SnLookUpGenealogyTableItem";
import { fetchParentUniqueIdentifier } from "@shared/connections/supabaseSnLookup";
import { useSelector } from "react-redux";
import Tooltip from "@shared/components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { SnLookupContext } from "../SnLookupProvider";

/* ----------------------- Information ----------------------- 

The genealogy array is an array of object with the following interface:
genealogy: {
  id: uuid,
  identifier: string,
  parent_id: uuid,
  component_id: uuid,
  component_type: string,
  component_name: string,
  status: string,
}

This array is then mapped into a geneology tree, where each object in the array is a node in the tree.
The tree is then rendered recursively in the SnLookUpGenealogyTableItem component.

geneologyTree has the following interface:
genealogyTree: {
  id: uuid,
  identifier: string,
  component_id: uuid,
  component_type: string,
  component_name: string,
  status: string,
  children: geneologyTree[],
}
*/

function SnLookUpGenealogyTable() {
  const { genealogy, loadData } = useContext(SnLookupContext);

  const [genealogyTree, setGenealogyTree] = useState([]);
  const components = useSelector((state) => state.db.components);

  const addParentToTree = async () => {
    const root = genealogyTree[0];
    const parentIdentifier = await fetchParentUniqueIdentifier(root.id);
    // only add parent to geneaology tree if the component is not a LOT
    if (parentIdentifier != null && root.component_type !== "LOT") {
      const component = components.find((component) => component.id === parentIdentifier.component_id);
      const parentData = {
        ...parentIdentifier,
        component_type: component.component_type,
        component_name: component.name,
        children: [...genealogyTree],
      };
      setGenealogyTree([parentData]);
    }
  };

  // function to transform genealogy array into a tree
  const transformGenealogyToTree = (parent_id = null) => {
    let tree = [];
    const genealogyCopy = JSON.parse(JSON.stringify(genealogy?.filter((member) => member.link?.is_active !== false)));

    genealogyCopy
      .filter((item) => item.parent_id === parent_id)
      .forEach((item) => {
        let children = transformGenealogyToTree(item.id);
        if (children.length) {
          item.children = children;
        }
        tree.push(item);
      });
    return tree;
  };

  // transforms genealogy tree into a recursive table
  useEffect(() => {
    if (genealogy?.filter((member) => member.link?.is_active !== false)?.length > 0) {
      const tree = transformGenealogyToTree(null);
      setGenealogyTree(tree);
    }
  }, [genealogy]);

  // add parent to genealogy tree when components are loaded
  useEffect(() => {
    if (genealogyTree?.length > 0 && components?.length > 0) {
      addParentToTree();
    }
  }, [genealogyTree, components]);

  return (
    <div className="flex w-full flex-col">
      <div className="border-serial-palette-200  relative rounded-md border bg-white ">
        <header className="flex items-center px-5 py-4">
          <h2 className="text-serial-palette-800 pr-2 font-semibold">Assembly Tree</h2>
          <Tooltip position="bottom" size="md" content="These are the exact sub-assemblies used in this part">
            <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
          </Tooltip>
        </header>
        <div>
          {/* Divider */}
          <div className="border-serial-palette-200 border-t"></div>

          {/* Table */}
          <div className="overflow-x-auto">
            <div className="w-full table-auto pb-4 pt-2">
              <div className="divide-serial-palette-200 divide-y text-sm">
                <SnLookUpGenealogyTableItem genealogyTree={genealogyTree} loadData={loadData} level={0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SnLookUpGenealogyTable;
