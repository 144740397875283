import { getSupabase } from "./supabaseAuth";
import { ReportTemplate } from "@shared/types/databaseTypes";
import { ParsedResponse } from "@shared/types/apiTypes";

export const upsertReportTemplate = async (reportTemplate: ReportTemplate): Promise<ParsedResponse<ReportTemplate>> => {
  const supabase = getSupabase();

  const { data, error } = await supabase.from("report_templates").upsert(reportTemplate).select().returns<ReportTemplate>();

  if (error || !data) {
    console.error(error);
    return { data: undefined, error: error?.message ?? "Unknown error" };
  }

  return { data, error: undefined };
};

export const deleteReportTemplate = async (reportTemplateId: string): Promise<ParsedResponse<boolean>> => {
  const supabase = getSupabase();

  const { error } = await supabase.from("report_templates").delete().eq("id", reportTemplateId);

  if (error) {
    console.error(error);
    return { data: false, error: error.message };
  }

  return { data: true, error: undefined };
};
