import store from "@shared/redux/store";
import { makeFetchRequest } from "./helpers";
import { User } from "@shared/types/databaseTypes";

const apiUrl: string = import.meta.env.VITE_APP_SERIAL_API_URL;

export async function updateUser(userData: Partial<User>): Promise<User | null> {
  const auth: string = `Bearer ${store.getState().auth.token}`;
  const userId: string | undefined = userData.supabase_uid;
  if (userId === "" || userId == null || userId == undefined) {
    console.error(`No userId in ${userData}`);
    return null;
  }
  const body: BodyInit = JSON.stringify(userData);
  const options: RequestInit = { method: "PATCH", headers: { Authorization: auth, "Content-Type": "application/json" }, body: body };

  const { data, error } = await makeFetchRequest(
    `${apiUrl}/users/${userId}`,
    options,
    `Error setting user ${userId} to: ${userData}`,
    10000,
  );
  if (error) {
    console.error(error);
    return null;
  }
  return data;
}

export async function inviteUser(userData: Partial<User>): Promise<User | null> {
  const auth: string = `Bearer ${store.getState().auth.token}`;
  const body: BodyInit = JSON.stringify({
    first_name: userData.first_name,
    last_name: userData.last_name,
    email: userData.email,
    role: userData.role,
    title: userData.title,
  });
  const options: RequestInit = { method: "POST", headers: { Authorization: auth, "Content-Type": "application/json" }, body: body };

  const { data, error } = await makeFetchRequest(`${apiUrl}/users`, options, `Error inviting user with properties: ${userData}`, 10000);
  if (error) {
    console.error(error);
    return null;
  }
  return data;
}
