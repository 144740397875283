import React, { useEffect, useState, useRef } from "react";
import { fetchIdentifierCounts } from "../connections/supabase";
import { Loader } from "@shared/components/Loader";
import { tailwindConfig } from "@shared/utils/helpers";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController } from "chart.js";

interface ProductionComponentWipGraphProps {
  componentId: string | null;
  workOrderId?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  productionSidebar?: boolean;
}

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController);

export const ProductionComponentWipGraph: React.FC<ProductionComponentWipGraphProps> = React.memo(
  ({ componentId, workOrderId, productionSidebar }) => {
    const canvas = useRef<HTMLCanvasElement>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [wipSum, setWipSum] = useState<number>(0);
    const [chartData, setChartData] = useState<{ processes: string[]; counts: number[] }>({ processes: [], counts: [] });
    // Fetch data for graph
    useEffect(() => {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const { process_names, wip_counts } = await fetchIdentifierCounts(
            componentId,
            workOrderId ?? null,
          );
          setChartData({ processes: process_names, counts: wip_counts });
          setWipSum(wip_counts.reduce((a: number, b: number) => a + b, 0));
          setIsLoading(false);
        } catch (error) {
          // Handle the error here
          console.error("Error fetching data:", error);
        }
      };

      if (componentId) {
        fetchData();
      }
    }, [componentId, workOrderId]);

    // Handle graph formatting
    useEffect(() => {
      const ctx = canvas.current;

      if (ctx === null) {
        return;
      }

      const chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: chartData.processes,
          datasets: [
            {
              data: chartData.counts,
              backgroundColor: (tailwindConfig().theme?.colors?.sky as any)?.[600],
            },
          ],
        },
        options: {
          layout: {
            padding: {
              top: 30,
              bottom: 60,
            },
          },
          scales: {
            y: {
              display: true,
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
              type: "linear",
            },
            x: {
              grid: {
                display: false,
              },
              display: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: (context) => context[0].label,
                label: (context) => context.parsed.y.toString(),
              },
              bodyAlign: "center",
            },
          },
          interaction: {
            intersect: false,
            mode: "nearest",
          },
          maintainAspectRatio: false,
          resizeDelay: 100,
        },
      });

      return () => chart.destroy();
    }, [chartData]);

    return (
      <div className={`bg-white ${productionSidebar ? "border-b" : "rounded-md border "} border-serial-palette-200 h-[25vh]`}>
        <header className={`flex items-center justify-between ${productionSidebar ? "pt-4" : "border-b py-4"} pl-4 pr-2 `}>
          <div className={`flex items-center space-x-2 ${productionSidebar && "mr-3 w-full border-b py-2"}`}>
            <span className="text-serial-palette-800 font-semibold">WIP Distribution by Process</span>
            <div className="text-serial-palette-400">{wipSum}</div>
            {isLoading && (
              <span className="text-serial-palette-400 font-medium">{isLoading ? <Loader styleOverride="w-[18px] h-[18px]" /> : ""}</span>
            )}
          </div>
        </header>
        {!isLoading &&
          (wipSum > 0 ? (
            <div className="h-full px-5 py-1">
              <canvas ref={canvas}></canvas>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <span className="text-serial-palette-400 italic">No Parts in WIP</span>
            </div>
          ))}
      </div>
    );
  },
);

export default ProductionComponentWipGraph;
