import { FilterCondition, FilterConditionOperator, FilterJoinOperator } from "@shared/types/databaseTypes";
import { FilterTestData } from "./types";

export const testFilterCondition = (condition: FilterCondition, value: any): boolean => {
  if (!value || !condition.value) return false;
  switch (condition.operator) {
    case FilterConditionOperator.Equals:
      return value === condition.value;
    case FilterConditionOperator.NotEquals:
      return value !== condition.value;
    case FilterConditionOperator.GreaterThan:
      return Number(value) > Number(condition.value);
    case FilterConditionOperator.LessThan:
      return Number(value) < Number(condition.value);
    case FilterConditionOperator.GreaterThanOrEqualTo:
      return Number(value) >= Number(condition.value);
    case FilterConditionOperator.LessThanOrEqualTo:
      return Number(value) <= Number(condition.value);
    case FilterConditionOperator.Contains:
      return value.includes(condition.value);
    case FilterConditionOperator.DoesNotContain:
      return !value.includes(condition.value);
    case FilterConditionOperator.In:
      return condition.value.split(",").includes(value);
    case FilterConditionOperator.NotIn:
      return !condition.value.split(",").includes(value);
    default:
      return false;
  }
};

export const testFilter = (
  filterConditions: FilterCondition[],
  filterJoinOperator: FilterJoinOperator,
  filterTestData: FilterTestData,
): boolean => {
  // check to make sure the filterTestData has all the required data
  const testDataConditionIds = Object.keys(filterTestData);
  const filterConditionIds = filterConditions.map((condition) => condition.id);
  const missingConditionIds = filterConditionIds.filter((id) => !testDataConditionIds.includes(id));

  if (missingConditionIds.length > 0) {
    return false;
  }

  // test each condition
  const conditionResults = filterConditions.map((condition) => {
    const value = filterTestData[condition.id];
    const test = testFilterCondition(condition, value);
    return test;
  });

  // return based on join operator
  if (filterJoinOperator === FilterJoinOperator.And) {
    return conditionResults.every((result) => result === true);
  } else {
    return conditionResults.some((result) => result === true);
  }
};
