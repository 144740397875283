import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faExpandAlt, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import UserAvatar from "@shared/components/UserAvatar";
import Editor from "@monaco-editor/react";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { formatTimeAgo } from "@shared/utils/time";
import { OpenaiCompletionRole } from "@shared/types/openai";
import { formatOpenaiMessage } from "@shared/utils/openai";

const ProcessBuilderApiChatMessage: React.FC<{
  role: OpenaiCompletionRole;
  content: string;
  timestamp?: string;
  actionButtonLabel?: string;
  handleActionButton?: () => void;
  handleInsertCode?: (code: string) => void;
}> = ({ role, content, timestamp, actionButtonLabel, handleActionButton, handleInsertCode }) => {
  const user = useCurrentUser();

  // split the message into segments of code and text (possibly multiple alternating segments) by looking for the first occurrence of ```<file type> and ```
  const parsedMessage = useMemo(() => {
    const segments = [];
    const regex = /```(.*?)\n([\s\S]*?)```|\n/g;
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(content)) !== null) {
      // Text segment before code or new line
      if (match.index > lastIndex) {
        segments.push({ type: "text", content: content.slice(lastIndex, match.index) });
      }
      if (match[0] === "\n") {
        // Do nothing
      } else {
        // Code segment
        segments.push({ type: "code", content: match[2].trim(), fileType: match[1] });
      }
      lastIndex = regex.lastIndex;
    }

    // Remaining text after the last code segment
    if (lastIndex < content.length) {
      segments.push({ type: "text", content: content.slice(lastIndex) });
    }

    return segments;
  }, [content]);

  return (
    <div className="flex flex-col gap-3 border-b bg-white px-5 py-6">
      <div className="flex items-center gap-3">
        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${role === "assistant" && "bg-violet-500"}`}>
          {role === "assistant" && <FontAwesomeIcon className="text-white" size="sm" icon={faWandMagicSparkles} />}
          {role === "user" && <UserAvatar user={user} size="sm" />}
        </div>
        <div className="font-semibold">{role === "assistant" ? "Serial AI" : `${user?.first_name} ${user?.last_name}`}</div>
        {timestamp && <div className="text-serial-palette-400 font-light">{formatTimeAgo(timestamp)}</div>}
      </div>
      <div className="flex flex-col gap-1 px-2">
        {parsedMessage.map((segment, index) => {
          const numLines = segment.content.split("\n").length;
          const codeHeightPx = Math.min(150, numLines * 20 + 20);
          if (segment.type === "code")
            return (
              <div key={index} className="relative overflow-clip rounded-xl border">
                <Editor
                  height={`${codeHeightPx}px`}
                  theme="vs-dark"
                  defaultLanguage="python"
                  options={{
                    fontSize: 14,
                    minimap: {
                      enabled: false,
                    },
                    readOnly: true,
                    scrollbar: { vertical: "hidden" },
                    scrollBeyondLastLine: false,
                    padding: { top: 10, bottom: 10 },
                  }}
                  value={segment.content}
                />
                <div className="absolute right-1.5 top-1.5 flex">
                  <button
                    className="btn-xs serial-btn-light w-8 rounded-r-none border-r-0"
                    onClick={() => handleInsertCode && handleInsertCode(segment.content)}
                  >
                    <FontAwesomeIcon icon={faExpandAlt} size="sm" />
                  </button>
                  <div className="btn-xs serial-btn-light w-8 rounded-l-none">
                    <CopyToClipboard className="!px-0 !py-0" text={segment.content} noTooltip />
                  </div>
                </div>
              </div>
            );
          return <div key={index}>{formatOpenaiMessage(segment.content)}</div>;
        })}
      </div>
      {actionButtonLabel && handleActionButton && (
        <button className="btn-sm serial-btn-dark w-fit gap-2 px-3" onClick={handleActionButton}>
          <FontAwesomeIcon icon={faBook} />
          {actionButtonLabel}
        </button>
      )}
    </div>
  );
};

export default ProcessBuilderApiChatMessage;
