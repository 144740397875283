import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ProductionContext } from "../../ProductionProvider";
import { ProductionFormGenericFieldProps } from "../../types";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import Loader from "@shared/components/primitives/Loader";
import Button from "@shared/components/primitives/Button";
import { PrinterType, useLabelPrinter } from "@shared/context/LabelPrinterProvider";

const ProductionFormLabel: React.FC<ProductionFormGenericFieldProps> = ({ field, draftData }) => {
  const [labelPreviewUrl, setLabelPreviewUrl] = useState<string | undefined>(undefined);
  const { triggerToast } = useContext(ToastContext);
  const { handleUpdateDraftSubmission, identifiers, identifiersValidation, labels } = useContext(ProductionContext);

  const selectedLabel = useMemo(() => labels.find((label) => label.id === field.label_format_id), [labels, field.label_format_id]);

  const { printers } = useLabelPrinter();
  const { status, getPreviewUrl, printLabel } = printers[selectedLabel?.xml ? PrinterType.Dymo : PrinterType.Zebra];

  useEffect(() => {
    if (selectedLabel) {
      getPreviewUrl(selectedLabel, identifiers[0]).then((url) => setLabelPreviewUrl(url));
    }
  }, [selectedLabel?.id, identifiers, status.driverInstalled]);

  const allowPrinting = useMemo(() => {
    if (!identifiersValidation || identifiers.length !== 1) return false;
    const identifersAreValid = Object.values(identifiersValidation).every((datapoints) =>
      datapoints?.every((datapoint) => datapoint.isValid === true),
    );
    return identifersAreValid;
  }, [identifiersValidation, identifiers]);

  const handlePrint = async () => {
    if (!selectedLabel) {
      console.error("No label format found");
      triggerToast(ToastType.Error, "No label format found", "Please try again or contact support");
      return;
    }
    if (!identifiers[0]) {
      triggerToast(ToastType.Error, "No identifier", "Please enter a serial number or lot code and try again");
      return;
    }
    const printSuccessful = await printLabel(selectedLabel, identifiers[0]);
    if (printSuccessful) {
      handleUpdateDraftSubmission(field.id, { value: true });
    } else {
      triggerToast(ToastType.Warning, "Failed to print label", "Make sure a Zebra printer is connected and BrowserPrint is installed.");
    }
  };

  return (
    <div className="flex w-full cursor-pointer flex-col items-center justify-between gap-y-2">
      {labelPreviewUrl ? (
        <img
          className={`max-h-[250px] w-fit rounded border object-contain ${draftData?.value === true && "border-green-500"}`}
          src={labelPreviewUrl}
          alt="Label"
        />
      ) : (
        <div className="flex h-32 w-48 items-center justify-center">
          <Loader size="md" />
        </div>
      )}
      <Button disabled={!allowPrinting} onClick={handlePrint}>
        <div className="relative">
          <FontAwesomeIcon icon={faPrint} />
          <div className={`absolute -right-0.5 top-0 h-1 w-1 rounded-full ${status.readyToPrint ? "bg-green-500" : "bg-red-500"}`} />
          {status.readyToPrint && <div className="absolute -right-1 -top-0.5 h-2 w-2 animate-pulse rounded-full bg-green-500 opacity-10" />}
        </div>
        <span>Print</span>
      </Button>
    </div>
  );
};

export default ProductionFormLabel;
