import React, { useState, useCallback, useContext } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { LogFileSchemaDatasetLink, LogFileSchemaDatasetLinkPathKeyItem } from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import FileUploadBox from "@shared/components/FileInputBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUpload, faEdit } from "@fortawesome/free-solid-svg-icons";
import Banner from "@shared/components/primitives/Banner";
import {
  findSubPathKeys,
  generateBlankLogFileSchema,
  generateBlankSchemaDatasetLink,
  getValueInObjectByPathKey,
  validateJSON,
  generateBlankDataset,
} from "../helpers";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { v4 as uuidv4 } from "uuid";
import Button from "@shared/components/primitives/Button";
import ProcessBuilderAutoParsingJsonPreview from "./ProcessBuilderAutoParsingJsonPreview";

const ProcessBuilderAutoParsingJson: React.FC = () => {
  const company = useSelector((state: RootState) => state.db.company);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [expandAll, setExpandAll] = useState<boolean>(false);

  const {
    draftProcess,
    selectedSourceDatasetId,
    setSchemaDesignerOpen,
    existingSchemas,
    existingSchemaDatasetLinks,
    draftSchemas,
    setDraftSchemas,
    draftSchemaDatasetLinks,
    setDraftSchemaDatasetLinks,
    selectedSchemaId,
    setSelectedSchemaId,
    editingSchema,
    setEditingSchema,
  } = useContext(ProcessBuilderContext);

  const handleNewSchema = useCallback(
    (sampleFileName: string, sampleFileData: any) => {
      const relatedSchemaId = existingSchemas.flat().find((schema) => schema.source_dataset_id === selectedSourceDatasetId)?.id;
      const currentRevision = Math.max(
        ...existingSchemas
          .flat()
          .filter((schema) => schema.id === relatedSchemaId)
          .map((schema) => schema.revision),
      );
      const newSchema = {
        ...generateBlankLogFileSchema({
          id: relatedSchemaId ?? uuidv4(),
          revision: currentRevision !== -Infinity ? currentRevision : 0,
          company_id: company.id,
          sample_file_name: sampleFileName,
          sample_file_data: sampleFileData,
          source_dataset_id: selectedSourceDatasetId ?? "",
          sample_file_id: null,
        }),
      };
      if (draftSchemas.length === 0) {
        const clearedExistingSchemas = existingSchemas.flat().filter((schema) => schema.id !== relatedSchemaId);
        setDraftSchemas(clearedExistingSchemas.concat(newSchema));
        const clearedExistingSchemaDatasetLinks = existingSchemaDatasetLinks.filter((link) => link.schema_id !== relatedSchemaId);
        setDraftSchemaDatasetLinks(clearedExistingSchemaDatasetLinks);
      } else {
        const clearedDraftSchemas = draftSchemas.flat().filter((schema) => schema.id !== relatedSchemaId);
        setDraftSchemas([...clearedDraftSchemas, newSchema]);
        const clearedDraftSchemaDatasetLinks = draftSchemaDatasetLinks.filter((link) => link.schema_id !== relatedSchemaId);
        setDraftSchemaDatasetLinks(clearedDraftSchemaDatasetLinks);
      }
      setSelectedSchemaId(newSchema.id);
      setEditingSchema(true);
    },
    [
      selectedSchemaId,
      existingSchemas,
      draftSchemas,
      company,
      selectedSourceDatasetId,
      existingSchemaDatasetLinks,
      setDraftSchemas,
      setDraftSchemaDatasetLinks,
      setSelectedSchemaId,
      setEditingSchema,
    ],
  );

  const handleClearSelectedSchema = useCallback(() => {
    if (!selectedSchemaId) return;
    setExpandAll(true);
    const relatedSchemaId = existingSchemas.flat().find((schema) => schema.source_dataset_id === selectedSourceDatasetId)?.id;
    if (!editingSchema) {
      setEditingSchema(true);
      const clearedDraftSchemas = existingSchemas.flat().filter((schema) => schema.id !== relatedSchemaId);
      const clearedDraftSchemaDatasetLinks = existingSchemaDatasetLinks.filter((link) => link.schema_id !== relatedSchemaId);
      setDraftSchemas(clearedDraftSchemas);
      setDraftSchemaDatasetLinks(clearedDraftSchemaDatasetLinks);
    } else {
      const clearedDraftSchemas = draftSchemas.flat().filter((schema) => schema.id !== relatedSchemaId);
      const clearedDraftSchemaDatasetLinks = draftSchemaDatasetLinks.filter((link) => link.schema_id !== relatedSchemaId);
      setDraftSchemas(clearedDraftSchemas);
      setDraftSchemaDatasetLinks(clearedDraftSchemaDatasetLinks);
    }
    setSelectedSchemaId(null);
  }, [selectedSchemaId, draftSchemas, existingSchemas, draftSchemaDatasetLinks, existingSchemaDatasetLinks, editingSchema]);

  const handleFileUpload = useCallback(
    async (files: File[]) => {
      const file = files?.[0];
      if (!file) return;
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (typeof e.target?.result !== "string") {
          setError("Invalid JSON file.");
          setIsLoading(false);
          return;
        }
        const jsonData = validateJSON(e.target.result);
        if (jsonData) {
          setError(null);
          handleNewSchema(file.name, jsonData);
        } else {
          setError("Invalid JSON file.");
        }
        setIsLoading(false);
      };
      reader.readAsText(file);
    },
    [handleNewSchema],
  );

  const handleUpdateLinkedDatasets = useCallback(
    (pathKey: LogFileSchemaDatasetLinkPathKeyItem[], checked: boolean) => {
      // check if pathKey corresponds to an object or array
      const selectedSchema = draftSchemas.flat().find((schema) => schema.id === selectedSchemaId);
      const correspondingValue = getValueInObjectByPathKey(selectedSchema?.sample_file_data, pathKey);
      const subPathKeys = findSubPathKeys(pathKey, correspondingValue);
      const relevantDraftSchemaDatasetLinks = draftSchemaDatasetLinks.filter((link) => link.schema_id === selectedSchemaId);
      const remainingDraftSchemaDatasetLinks = draftSchemaDatasetLinks.filter((link) => link.schema_id !== selectedSchemaId);
      let updatedSchemaAutoGeneratedDatasets = relevantDraftSchemaDatasetLinks;
      subPathKeys.forEach((subPathKey) => {
        const existingSchemaDataset = updatedSchemaAutoGeneratedDatasets.find(
          (dataset) => JSON.stringify(dataset.path_key) === JSON.stringify(subPathKey),
        );
        if (existingSchemaDataset) {
          updatedSchemaAutoGeneratedDatasets = updatedSchemaAutoGeneratedDatasets.map((datasetLink: LogFileSchemaDatasetLink) => {
            if (JSON.stringify(datasetLink.path_key) === JSON.stringify(subPathKey)) {
              if (!datasetLink.dataset) return datasetLink;
              const updatedDataset = {
                ...datasetLink.dataset,
                is_active: checked,
              };
              return { ...datasetLink, is_active: checked, dataset: updatedDataset };
            }
            return datasetLink;
          });
        } else {
          const value = getValueInObjectByPathKey(selectedSchema?.sample_file_data, subPathKey);
          const valueType = (() => {
            if (typeof value === "string") return DataType.ParametricQualitative;
            if (typeof value === "number") return DataType.ParametricQuantitative;
            if (typeof value === "boolean") return DataType.Checkbox;
            return DataType.ParametricQualitative;
          })();
          const newDataset = generateBlankDataset(
            company.id,
            draftProcess?.id ?? "",
            draftProcess?.revision ?? 0,
            subPathKey.map((key) => String(key.key)).join("."),
            valueType,
          );
          const newSchemaDatasetLink = generateBlankSchemaDatasetLink({
            company_id: company.id,
            schema_id: selectedSchema?.id,
            schema_revision: selectedSchema?.revision,
            path_key: subPathKey,
            dataset_id: newDataset.id,
            dataset: newDataset,
          });
          updatedSchemaAutoGeneratedDatasets = [...updatedSchemaAutoGeneratedDatasets, newSchemaDatasetLink];
        }
      });
      setDraftSchemaDatasetLinks([...remainingDraftSchemaDatasetLinks, ...updatedSchemaAutoGeneratedDatasets]);
    },
    [draftSchemas, draftSchemaDatasetLinks, selectedSchemaId, company, draftProcess],
  );

  const handleUpdateDatasetName = useCallback(
    (updatedDatasetLink: { path_key: LogFileSchemaDatasetLinkPathKeyItem[]; newName: string }) => {
      const relevantDraftSchemaDatasetLinks = draftSchemaDatasetLinks.filter((link) => link.schema_id === selectedSchemaId);
      const remainingDraftSchemaDatasetLinks = draftSchemaDatasetLinks.filter((link) => link.schema_id !== selectedSchemaId);
      const updatedSchemaAutoGeneratedDatasets = relevantDraftSchemaDatasetLinks.map((datasetLink: LogFileSchemaDatasetLink) => {
        if (JSON.stringify(datasetLink.path_key) === JSON.stringify(updatedDatasetLink.path_key)) {
          if (!datasetLink.dataset) return datasetLink;
          const updatedDataset = {
            ...datasetLink.dataset,
            name: updatedDatasetLink.newName,
          };
          return { ...datasetLink, dataset: updatedDataset };
        }
        return datasetLink;
      });
      setDraftSchemaDatasetLinks([...updatedSchemaAutoGeneratedDatasets, ...remainingDraftSchemaDatasetLinks]);
    },
    [draftSchemaDatasetLinks],
  );

  const handleEdit = useCallback(() => {
    setEditingSchema(true);
    setExpandAll(true);
    setDraftSchemas(existingSchemas);
    setDraftSchemaDatasetLinks(existingSchemaDatasetLinks);
  }, [
    existingSchemas,
    existingSchemaDatasetLinks,
    selectedSchemaId,
    draftSchemas,
    draftSchemaDatasetLinks,
    setDraftSchemas,
    setDraftSchemaDatasetLinks,
    setEditingSchema,
    setExpandAll,
  ]);

  const handleClose = useCallback(() => {
    setSchemaDesignerOpen(false);
    setExpandAll(false);
  }, [setSchemaDesignerOpen]);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col">
      <div className="flex h-14 shrink-0 items-center justify-between border-b bg-white px-2">
        <div className="flex justify-center gap-1.5">
          {selectedSchemaId && !editingSchema && (
            <Button onClick={() => handleEdit()}>
              <FontAwesomeIcon icon={faEdit} />
              Edit
            </Button>
          )}
          {selectedSchemaId && (
            <Button onClick={() => handleClearSelectedSchema()}>
              <FontAwesomeIcon icon={faUpload} />
              New
            </Button>
          )}
        </div>
        <div className="flex justify-center gap-1.5">
          <Button onClick={() => handleClose()}>
            <FontAwesomeIcon icon={faTimes} />
            Close
          </Button>
        </div>
      </div>
      <div className="flex min-h-0 min-w-0 flex-grow">
        {error && (
          <div className="p-2">
            {!error && (
              <Banner.Root variant="warning">
                <Banner.Text>{error}</Banner.Text>
                <Banner.Dismiss handleDismiss={() => setError(null)} />
              </Banner.Root>
            )}
          </div>
        )}
        {!selectedSourceDatasetId && (
          <div className="text-serial-palette-400 flex h-full w-full items-center justify-center font-light italic">
            Select a dataset to view its schema
          </div>
        )}
        {selectedSourceDatasetId && !selectedSchemaId && (
          <div className="flex min-h-0 min-w-0 shrink-0 flex-grow bg-white">
            <FileUploadBox fileInfo={null} handleUploadFiles={handleFileUpload} isLoading={isLoading} prompt="Upload JSON file" />
          </div>
        )}
        {selectedSourceDatasetId && selectedSchemaId && (
          <ProcessBuilderAutoParsingJsonPreview
            schemaDatasetLinks={
              editingSchema
                ? draftSchemaDatasetLinks.filter((link) => link.schema_id === selectedSchemaId)
                : existingSchemaDatasetLinks.filter((link) => link.schema_id === selectedSchemaId)
            }
            handleUpdateLinkedDatasets={handleUpdateLinkedDatasets}
            handleUpdateDatasetName={handleUpdateDatasetName}
            selectedSchemaId={selectedSchemaId}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
            editing={editingSchema}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessBuilderAutoParsingJson;
