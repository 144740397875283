import SidebarSelector from "@shared/components/primitives/SidebarSelector";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import { useState } from "react";

const dummySidebarItems = [
  {
    id: "1",
    heading: "Apples",
    subheading: "A delicious snack",
  },
  {
    id: "2",
    heading: "Cereal",
    subheading: "A breakfast staple",
  },
  {
    id: "3",
    heading: "Baking Powder",
    subheading: "A leavening agent",
  },
];

const FrontendPrimitivesSidebarSelector = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  return (
    <FrontendPrimitivesCard title="Sidebar Selector">
      <div className="flex h-[800px] w-full shrink-0 border">
        <SidebarSelector.Root value={selectedItem} onValueChange={setSelectedItem}>
          <SidebarSelector.Header>
            <span className="italic">Filters can go here..</span>
          </SidebarSelector.Header>
          <SidebarSelector.List>
            {dummySidebarItems.map((item) => (
              <SidebarSelector.Item key={item.id} value={item.id}>
                <div className="font-semibold">{item.heading}</div>
                <div className="text-sm font-light">{item.subheading}</div>
              </SidebarSelector.Item>
            ))}
          </SidebarSelector.List>
        </SidebarSelector.Root>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesSidebarSelector;
