import React, { useContext, useEffect, useMemo, useState } from "react";
import { LabelFormatterContext } from "../LabelFormatterProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faMinus, faPlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import LabelFormatterLabel from "./LabelFormatterLabel";
import { formatLabelSize } from "../helpers/helpers";
import { PLACEHOLDER_IDENTIFIER, PX_PER_MM } from "../constants";
import { LabelSizeUnit } from "@shared/types/databaseEnums";
import Banner from "@shared/components/primitives/Banner";
import Popover from "@shared/components/primitives/Popover";
import { NumberInput } from "@shared/components/primitives/Input";
import ButtonGroup from "@shared/components/primitives/ButtonGroup";
import { PrinterType, useLabelPrinter } from "@shared/context/LabelPrinterProvider";

const LabelFormatterEditor: React.FC = () => {
  const { zoom, setZoom, draftLabels, selectedLabelId, handleEditLabel } = useContext(LabelFormatterContext);

  const [tempLabelSize, setTempLabelSize] = useState<{ width: number; height: number } | null>(null);
  const [labelPreviewUrl, setLabelPreviewUrl] = useState<string | undefined>(undefined);

  const label = useMemo(() => draftLabels.find((label) => label.id === selectedLabelId), [draftLabels, selectedLabelId]);

  const { printers } = useLabelPrinter();
  const { status, getPreviewUrl, printLabel } = printers[label?.xml ? PrinterType.Dymo : PrinterType.Zebra];

  const handleUpdateSize = (width: number, height: number) => {
    if (!label) return;
    handleEditLabel("width", label.displayed_size_unit === LabelSizeUnit.mm ? Number(width) : Number(width) * 25.4);
    handleEditLabel("height", label.displayed_size_unit === LabelSizeUnit.mm ? Number(height) : Number(height) * 25.4);
    setTempLabelSize(null);
  };

  const handlePrint = async () => {
    if (!label) return;
    printLabel(label, label.xml_identifier ?? PLACEHOLDER_IDENTIFIER);
  };

  const { labelScaledWidth, labelScaledHeight } = useMemo(() => {
    if (!label) return { labelScaledWidth: 0, labelScaledHeight: 0 };
    return {
      labelScaledWidth: label.width * PX_PER_MM * zoom,
      labelScaledHeight: label.height * PX_PER_MM * zoom,
    };
  }, [label, zoom]);

  useEffect(() => {
    if (label?.xml) {
      getPreviewUrl(label, label.xml_identifier ?? PLACEHOLDER_IDENTIFIER).then((url) => setLabelPreviewUrl(url));
    }
  }, [label?.xml, getPreviewUrl]);

  if (!label)
    return (
      <div className="text-serial-palette-400 bg-serial-palette-50 flex h-full w-full flex-col items-center justify-center italic">
        No Label Selected
      </div>
    );

  return (
    <div className="bg-serial-palette-50 relative flex min-h-0 min-w-0 flex-grow items-center justify-center overflow-hidden">
      <div className="absolute bottom-2 left-2 z-10 flex flex-col">
        <button className="btn-xs serial-btn-light rounded rounded-b-none border border-b-0 p-1.5" onClick={() => setZoom(zoom + 0.2)}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <button className="btn-xs serial-btn-light rounded-none border p-1.5" onClick={() => setZoom(zoom - 0.2)}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <button className="btn-xs serial-btn-light rounded rounded-t-none border border-t-0 p-1.5" onClick={() => setZoom(1)}>
          <FontAwesomeIcon icon={faExpand} />
        </button>
      </div>
      <div className="absolute right-0 top-0 z-10 flex w-full flex-row-reverse justify-between p-2">
        <button className="btn serial-btn-dark h-9 gap-2 p-3" onClick={() => handlePrint()}>
          <div className="relative">
            <FontAwesomeIcon icon={faPrint} />
            <div className={`absolute -right-0.5 top-0 h-1 w-1 rounded-full ${status.readyToPrint ? "bg-green-500" : "bg-red-500"}`} />
            {status.readyToPrint && (
              <div className="absolute -right-1 -top-0.5 h-2 w-2 animate-pulse rounded-full bg-green-500 opacity-10" />
            )}
          </div>
          <span>Test Print</span>
        </button>
        {label.xml && (!label.xml_identifier || !label.xml?.includes(label.xml_identifier)) && (
          <Banner variant="error" className="w-1/2 ">
            Sample identifier is not present in label XML template
          </Banner>
        )}
      </div>
      <div className="flex flex-col gap-1.5 overflow-hidden">
        <div className="flex justify-between">
          <input
            type="text"
            className="form-input !w-1/2 truncate border-0 bg-transparent p-0 text-sm font-medium"
            value={label.name}
            placeholder="Label Name"
            onChange={(e) => handleEditLabel("name", e.target.value)}
            style={{ width: `${labelScaledWidth}px` }}
          />
          {label.xml && (
            <input
              type="text"
              className="form-input !w-1/2 truncate border-0 bg-transparent p-0 text-right text-sm font-medium"
              value={label?.xml_identifier ?? ""}
              placeholder="Sample Identifier"
              onChange={(e) => {
                handleEditLabel("xml_identifier", e.target.value);
              }}
              style={{ width: `${labelScaledWidth}px` }}
            />
          )}
        </div>
        <div className="flex items-center justify-center" style={{ width: `${labelScaledWidth}px`, height: `${labelScaledHeight}px` }}>
          <LabelFormatterLabel label={label} previewImageUrl={labelPreviewUrl} />
        </div>
        <div className="flex w-full justify-end text-xs">
          <Popover.Root>
            <Popover.Trigger>{formatLabelSize(label)}</Popover.Trigger>
            <Popover.Content align="end" className="flex w-48 flex-col gap-2 py-2 text-sm">
              <div className="text-serial-palette-600 flex h-8 w-full items-center justify-between gap-2 pl-3 pr-2 font-medium">
                <div className="">Width</div>
                <NumberInput
                  className="w-24"
                  size="sm"
                  value={tempLabelSize?.width ?? (label.displayed_size_unit === LabelSizeUnit.mm ? label.width : label.width / 25.4)}
                  onValueChange={(value) =>
                    setTempLabelSize((draft) => {
                      return {
                        width: value,
                        height: draft?.height ?? (label.displayed_size_unit === LabelSizeUnit.mm ? label.height : label.height / 25.4),
                      };
                    })
                  }
                  onBlur={() => tempLabelSize && handleUpdateSize(tempLabelSize.width, tempLabelSize.height)}
                />
              </div>
              <div className="text-serial-palette-600 flex h-8 w-full items-center justify-between gap-2 pl-3 pr-2 font-medium">
                <div className="">Height</div>
                <NumberInput
                  className="w-24"
                  size="sm"
                  value={
                    tempLabelSize?.height ??
                    (label.displayed_size_unit === LabelSizeUnit.mm ? label.height : label.height / 25.4).toFixed(2)
                  }
                  onValueChange={(value) =>
                    setTempLabelSize((draft) => {
                      return {
                        height: value,
                        width: draft?.width ?? (label.displayed_size_unit === LabelSizeUnit.mm ? label.width : label.width / 25.4),
                      };
                    })
                  }
                  onBlur={() => tempLabelSize && handleUpdateSize(tempLabelSize.width, tempLabelSize.height)}
                />
              </div>
              <div className="text-serial-palette-600 flex h-8 w-full items-center justify-between gap-2 pl-3 pr-2 font-medium">
                <div className="">Unit</div>
                <ButtonGroup.Root size="sm">
                  <ButtonGroup.Button
                    className="w-10"
                    onClick={() => handleEditLabel("displayed_size_unit", LabelSizeUnit.mm)}
                    variant={label.displayed_size_unit === LabelSizeUnit.mm ? "default" : "outline"}
                  >
                    mm
                  </ButtonGroup.Button>
                  <ButtonGroup.Button
                    className="w-10"
                    onClick={() => handleEditLabel("displayed_size_unit", LabelSizeUnit.in)}
                    variant={label.displayed_size_unit === LabelSizeUnit.in ? "default" : "outline"}
                  >
                    in
                  </ButtonGroup.Button>
                </ButtonGroup.Root>
              </div>
            </Popover.Content>
          </Popover.Root>
        </div>
      </div>
    </div>
  );
};

export default LabelFormatterEditor;
