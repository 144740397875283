import { Dataset } from "@shared/types/databaseTypes";
import { useContext } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import Table from "@shared/components/primitives/Table";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import TagBasic from "@shared/components/primitives/TagBasic";
import { Link } from "react-router-dom";
import ProcessBuilderApiDatasetEditPopover from "./ProcessBuilderApiDatasetEditPopover";

const ProcessBuilderApiDatasetTable: React.FC = () => {
  const { draftProcess, processDatasets } = useContext(ProcessBuilderContext);

  return (
    <Table.Root>
      <Table.Title title="Datasets" count={processDatasets.length}>
        {draftProcess && (
          <ProcessBuilderApiDatasetEditPopover process={draftProcess}>
            <Button
              size="md"
              disabled={draftProcess?.revision === 0 || draftProcess === undefined}
              tooltip={draftProcess?.revision === 0 ? "Please save the process before adding datasets" : undefined}
            >
              <FontAwesomeIcon icon={faPlus} />
              Add Dataset
            </Button>
          </ProcessBuilderApiDatasetEditPopover>
        )}
      </Table.Title>
      <Table.Contents>
        {processDatasets.length > 0 && (
          <Table.Header>
            <Table.Row>
              <Table.Head className="w-[70px]">Type</Table.Head>
              <Table.Head>Name</Table.Head>
              <Table.Head className="w-[115px] text-center">Active</Table.Head>
              <Table.Head className="w-[70px] text-center">Edit</Table.Head>
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>
          {processDatasets.length === 0 && <Table.NoData>No datasets have been set or uploaded for this process yet</Table.NoData>}
          {processDatasets.map((dataset: Dataset, index: number) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <TagBasic variant={dataset.data_type} size="sm" />
                </Table.Cell>
                <Table.Cell className="max-w-[300px] truncate">
                  <Link to={`/datasets/${dataset.id}`} className="hover:underline">
                    {dataset.name}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex w-full justify-center">
                    <TagBasic.Root variant={dataset.is_active ? "ACTIVE" : "INACTIVE"} size="sm">
                      <TagBasic.Name />
                    </TagBasic.Root>
                  </div>
                </Table.Cell>
                <Table.Cell className="py-1 text-center">
                  {draftProcess && (
                    <ProcessBuilderApiDatasetEditPopover process={draftProcess} dataset={dataset}>
                      <Button disabled={draftProcess === undefined} size="sm" variant="outline" className="h-7 w-7">
                        <FontAwesomeIcon icon={faEdit} size="sm" />
                      </Button>
                    </ProcessBuilderApiDatasetEditPopover>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Contents>
    </Table.Root>
  );
};

export default ProcessBuilderApiDatasetTable;
