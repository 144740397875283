import React from "react";
import {
  SnLookupTimeline,
  SnLookupTimelineType,
  SnLookupTimelineCreation,
  SnLookupTimelineProperty,
  SnLookupTimelineComment,
} from "../types";
import { formatTimeAgo } from "@shared/utils/time";
import { Link } from "react-router-dom";
import { User } from "@shared/types/databaseTypes";

interface SnLookUpTimelineItemProps {
  activity: SnLookupTimeline;
  user: User | undefined;
}

const renderCreationTimeline = (activity: SnLookupTimelineCreation) => (
  <>
    <div className="inline-flex w-full justify-between text-sm">
      <div className="flex space-x-2">
        <div className="text-sm">{`${activity.uniqueIdentifier?.component?.name} ${activity.uniqueIdentifier?.component?.component_type === "LOT" ? "Lot" : "Serial Number"} Created`}</div>
        <Link
          to={`/snlookup?uid=${activity.uniqueIdentifier?.id}`}
          className="text-serial-palette-400 text-sm font-normal hover:cursor-pointer hover:underline"
        >
          {`(${activity.uniqueIdentifier?.identifier})`}
        </Link>
      </div>
      <div className="whitespace-pre">{formatTimeAgo(activity.timestamp)}</div>
    </div>
  </>
);

const renderPropertyTimeline = (activity: SnLookupTimelineProperty, user: User | undefined) => (
  <>
    <div className="flex w-full flex-col text-sm">
      <div className="inline-flex justify-between space-x-2 text-sm">
        <div className="inline-flex space-x-2 ">
          <div className="font-bold">{user && user.first_name}</div>
          <div className="whitespace-pre">property update</div>
        </div>
        <div className="whitespace-pre">{formatTimeAgo(activity.timestamp)}</div>
      </div>
      <div className="inline-flex space-x-2">
        <div className="italic">{activity.key}</div>
        {activity.value != "" ? (
          <div className="inline-flex space-x-2">
            <div>changed to</div>
            <div className="italic">{activity.value}</div>
          </div>
        ) : (
          <div className=""> was removed</div>
        )}
      </div>
    </div>
  </>
);

const renderCommentTimeline = (activity: SnLookupTimelineComment, user: User | undefined) => (
  <>
    <div className="flex w-full flex-col text-sm">
      <div className="inline-flex justify-between space-x-2">
        <div className="inline-flex space-x-2 ">
          <div className="font-bold">{user && user.first_name}</div>
          <div className="">commented</div>
        </div>
        <div className="">{formatTimeAgo(activity.timestamp)}</div>
      </div>
      <div className="whitespace-pre-wrap">{activity.comment}</div>
    </div>
  </>
);

const SnLookUpTimelineItem: React.FC<SnLookUpTimelineItemProps> = ({ activity, user }) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex h-full w-full justify-between px-2 py-2">
        <div className="flex h-full w-full items-center justify-between">
          <div className="flex h-full w-full items-center space-x-2">
            {activity.type === SnLookupTimelineType.Creation && renderCreationTimeline(activity as SnLookupTimelineCreation)}
            {activity.type === SnLookupTimelineType.Property && renderPropertyTimeline(activity as SnLookupTimelineProperty, user)}
            {activity.type === SnLookupTimelineType.Comment && renderCommentTimeline(activity as SnLookupTimelineComment, user)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnLookUpTimelineItem;
