import {
  CollectionType,
  FilterCondition,
  FilterConditionOperator,
  FilterJoinOperator,
  ProcessStepWithReferences,
} from "@shared/types/databaseTypes";
import { RootState } from "@shared/redux/store";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { Dataset } from "@shared/types/databaseTypes";
import { FilterConditionValueOption } from "@shared/components/filters/types";
import { v4 as uuidv4 } from "uuid";
import { DataType } from "@shared/types/databaseEnums";
import FilterDropdown from "@shared/components/filters/FilterDropdown";

interface ProcessBuilderStepFilterProps {
  step: ProcessStepWithReferences;
  stepIndex: number;
  align: "left" | "right";
  buttonContents?: React.ReactNode;
}

const ProcessBuilderStepFilter: React.FC<ProcessBuilderStepFilterProps> = ({ step, stepIndex, align, buttonContents }) => {
  const { component, handleUpdateFilter } = useContext(ProcessBuilderContext);
  const partNumbers = useSelector((state: RootState) => state.db.partNumbers).filter(
    (partNumber) => partNumber.component_id === component?.id,
  );
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const company = useSelector((state: RootState) => state.db.company);

  const partNumberDatasets: Dataset[] = useMemo(() => {
    if (!component) return [];
    // get all datasets ids from partNumbers
    const partNumberDatasetsIds = [...new Set(partNumbers.flatMap((partNumber) => Object.keys(partNumber.metadata)))];
    // join datasets with partNumberDatasetsIds
    let partNumberDatasets = partNumberDatasetsIds
      .map((datasetId) => {
        return datasets.find((dataset) => datasetId === dataset.id);
      })
      .filter((dataset) => dataset !== undefined) as Dataset[];
    // --------> Hack to cast datasets with only strings that are numbers to ParametricQuantitative
    partNumberDatasets = partNumberDatasets.map((dataset) => {
      const datasetValues = partNumbers
        .map((partNumber) => partNumber.metadata[dataset.id as string]?.value)
        .filter((value) => value !== undefined);
      const allValuesAreNumbers = datasetValues.every((value) => !isNaN(Number(value)));
      if (allValuesAreNumbers) {
        return {
          ...dataset,
          data_type: DataType.ParametricQuantitative,
        };
      }
      return dataset;
    });
    // End Hack <--------
    return partNumberDatasets;
  }, [component, partNumbers]);

  const filterConditionOptions: FilterCondition[] = useMemo(() => {
    const partNumberFilter: FilterCondition = {
      id: uuidv4(),
      company_id: company.id,
      process_step_id: step.id,
      name: "Part Number",
      type: CollectionType.PartNumber,
      operator: FilterConditionOperator.Equals,
      value: "",
      label: "",
      created_at: new Date().toISOString(),
    };
    const partNumberDataFilters: FilterCondition[] = partNumberDatasets.map((dataset) => {
      return {
        id: uuidv4(),
        company_id: company.id,
        process_step_id: step.id,
        name: dataset.name,
        dataset_id: dataset.id,
        type: dataset.data_type as string as CollectionType,
        operator: FilterConditionOperator.Equals,
        value: "",
        label: "",
        created_at: new Date().toISOString(),
      };
    });
    return [partNumberFilter, ...partNumberDataFilters];
  }, [partNumberDatasets]);

  const filterValueOptions: Record<string, FilterConditionValueOption[]> = useMemo(() => {
    const options: Record<string, FilterConditionValueOption[]> = {};
    // Add options for part number metadata
    for (const filter of step.filter_conditions ?? []) {
      switch (filter.type) {
        case CollectionType.PartNumber:
          options[filter.id] = partNumbers.map((partNumber) => {
            return {
              value: partNumber.id,
              label: partNumber.pn,
            };
          });
          break;
        case CollectionType.ParametricQuantitative:
          const numericValues = [
            ...new Set(
              partNumbers
                .map((partNumber) => {
                  return partNumber.metadata[filter.dataset_id as string]?.value;
                })
                .filter((value) => value !== undefined),
            ),
          ];
          for (const value of numericValues) {
            options[filter.id] = [
              ...(options[filter.id] ?? []),
              {
                value,
                label: value,
              },
            ];
          }
          break;
        case CollectionType.ParametricQualitative:
          const textValues = [
            ...new Set(
              partNumbers
                .map((partNumber) => {
                  return partNumber.metadata[filter.dataset_id as string]?.value;
                })
                .filter((value) => value !== undefined),
            ),
          ];
          for (const value of textValues) {
            options[filter.id] = [
              ...(options[filter.id] ?? []),
              {
                value,
                label: value,
              },
            ];
          }
        default:
          break;
      }
    }
    return options;
  }, [step, partNumbers, component]);

  const handleAddFilterCondition = (filterCondition: FilterCondition) => {
    const newFilterCondition: FilterCondition = {
      ...filterCondition,
      id: uuidv4(),
    };
    handleUpdateFilter(stepIndex, [...(step.filter_conditions ?? []), newFilterCondition]);
  };

  const handleSetFilterConditionOperator = (filterConditionId: string, conditionOperator: FilterConditionOperator) => {
    const updatedFilterConditions = step?.filter_conditions.map((filterCondition) => {
      if (filterCondition.id === filterConditionId) {
        return {
          ...filterCondition,
          operator: conditionOperator,
        };
      }
      return filterCondition;
    });
    handleUpdateFilter(stepIndex, updatedFilterConditions);
  };

  const handleSetFilterConditionValue = (
    filterConditionId: string,
    value: string,
    label: string,
    conditionOperator?: FilterConditionOperator,
  ) => {
    const updatedFilterConditions = step?.filter_conditions.map((filter) => {
      if (filter.id === filterConditionId) {
        if (
          conditionOperator &&
          (conditionOperator === FilterConditionOperator.In || conditionOperator === FilterConditionOperator.NotIn)
        ) {
          if (filter.value.includes(value)) return filter;
          const newValue = filter.value.length !== 0 ? `${filter.value},${value}` : value;
          const newLabel = filter.value.length !== 0 ? `${filter.label}, ${label}` : label;
          return {
            ...filter,
            value: newValue,
            label: newLabel,
          };
        }
        return {
          ...filter,
          value,
          label,
        };
      }
      return filter;
    });
    handleUpdateFilter(stepIndex, updatedFilterConditions);
  };

  const handleRemoveFilterCondition = (filterId: string) => {
    const updatedFilterConditions = step?.filter_conditions.filter((filter) => filter.id !== filterId);
    handleUpdateFilter(stepIndex, updatedFilterConditions);
  };

  const handleSetFilterJoinOperator = (joinOperator: FilterJoinOperator) => {
    handleUpdateFilter(stepIndex, step?.filter_conditions, joinOperator);
  };

  return (
    <FilterDropdown
      filterConditions={step.filter_conditions}
      filterJoinOperator={step.filter_join_operator}
      filterConditionOptions={filterConditionOptions}
      filterConditionValueOptions={filterValueOptions}
      buttonContents={buttonContents}
      align={align}
      handleSetFilterJoinOperator={handleSetFilterJoinOperator}
      handleAddFilterCondition={handleAddFilterCondition}
      handleRemoveFilterCondition={handleRemoveFilterCondition}
      handleSetFilterConditionValue={handleSetFilterConditionValue}
      handleSetFilterConditionOperator={handleSetFilterConditionOperator}
    />
  );
};

export default ProcessBuilderStepFilter;
