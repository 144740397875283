import {
  Checkbox,
  DatetimeData,
  File,
  Image,
  ParametricQualitative,
  ParametricQuantitative,
  ProcessEntry,
  UniqueIdentifier,
  UniqueIdentifierLink,
} from "@shared/types/databaseTypes";
import {
  LinkStatus,
  MeasureKey,
  MeasureTimeOperator,
  MeasureType,
  MeasureValue,
  MeasureValueCheckbox,
  MeasureValueCycleTime,
  MeasureValueFile,
  MeasureValueIdentifier,
  MeasureValueImage,
  MeasureValueLink,
  MeasureValueLinkStatus,
  MeasureValueOperator,
  MeasureValueParametricQualitative,
  MeasureValueParametricQuantitative,
  MeasureValuePartNumber,
  MeasureValuePassFail,
  MeasureValueProcessResult,
  MeasureValueTimestamp,
  MeasureValueStation,
  MeasureValueIdentifierStatus,
  MeasureValueWorkOrder,
  MeasureValueWorkOrderPhase,
  MeasureValueDatetime,
  MeasureAggregationOperator,
  MeasureValueSummary,
  UniqueIdentifierWithMeasures,
} from "../types";
import {
  fetchAllUniqueIdentifierDataByComponentId,
  fetchAllUniqueIdentifierLinkDataByComponentId,
  fetchDataByTableNameAndProcessId,
} from "../connections/supabase";
import { ComponentType, UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import moment from "moment";
import { extractTimestamp } from "./measureKeys";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { isSameMeasureKey } from "./measureKeys";
import { findRootToLeafPaths, findAssociatedUniqueIdentifierIdByComponentId } from "./genealogy";

export const fetchAllMeasureValuesMappedByUniqueIdentifier = async (
  measureKey: MeasureKey,
): Promise<{ [uniqueIdentifierId: string]: MeasureValue[] }> => {
  switch (measureKey.type) {
    case MeasureType.Status:
      return parseStatuses(await fetchAllUniqueIdentifierDataByComponentId(measureKey.component_id));
    case MeasureType.LinkStatus:
      return parseLinkStatus(await fetchAllUniqueIdentifierLinkDataByComponentId(measureKey.component_id));
    case MeasureType.WorkOrder:
      return parseWorkOrders(await fetchAllUniqueIdentifierDataByComponentId(measureKey.component_id));
    case MeasureType.WorkOrderPhase:
      return parseWorkOrderPhases(await fetchAllUniqueIdentifierDataByComponentId(measureKey.component_id));
    case MeasureType.PartNumber:
      return parsePartNumbers(await fetchAllUniqueIdentifierDataByComponentId(measureKey.component_id));
    case MeasureType.Identifier:
      return parseIdentifiers(await fetchAllUniqueIdentifierDataByComponentId(measureKey.component_id));
    case MeasureType.Station:
      if (!measureKey.process_id) return {};
      return parseStations(await fetchDataByTableNameAndProcessId<ProcessEntry>("process_entries", measureKey.process_id));
    case MeasureType.Operator:
      if (!measureKey.process_id) return {};
      return parseOperators(await fetchDataByTableNameAndProcessId<ProcessEntry>("process_entries", measureKey.process_id));
    case MeasureType.Timestamp:
      if (!measureKey.process_id) return {};
      return parseTimestamps(await fetchDataByTableNameAndProcessId<ProcessEntry>("process_entries", measureKey.process_id));
    case MeasureType.CycleTime:
      if (!measureKey.process_id) return {};
      return parseCycleTimes(await fetchDataByTableNameAndProcessId<ProcessEntry>("process_entries", measureKey.process_id));
    case MeasureType.ProcessResult:
      if (!measureKey.process_id) return {};
      return parseProcessResults(await fetchDataByTableNameAndProcessId<ProcessEntry>("process_entries", measureKey.process_id));
    case MeasureType.Link:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parseLinks(
        await fetchDataByTableNameAndProcessId<UniqueIdentifierLink>(
          "unique_identifier_links",
          measureKey.process_id,
          measureKey.dataset_id,
        ),
      );
    case MeasureType.ParametricQualitative:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parseParametricQualitative(
        await fetchDataByTableNameAndProcessId<ParametricQualitative>(
          "parametric_qualitative",
          measureKey.process_id,
          measureKey.dataset_id,
        ),
      );
    case MeasureType.ParametricQuantitative:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parseParametricQuantitative(
        await fetchDataByTableNameAndProcessId<ParametricQuantitative>(
          "parametric_quantitative",
          measureKey.process_id,
          measureKey.dataset_id,
        ),
      );
    case MeasureType.Image:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parseImages(await fetchDataByTableNameAndProcessId<Image>("images", measureKey.process_id, measureKey.dataset_id));
    case MeasureType.File:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parseFiles(await fetchDataByTableNameAndProcessId<File>("files", measureKey.process_id, measureKey.dataset_id));
    case MeasureType.Checkbox:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parseCheckboxes(await fetchDataByTableNameAndProcessId<Checkbox>("checkboxes", measureKey.process_id, measureKey.dataset_id));
    case MeasureType.PassFail:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parsePassFails(
        await fetchDataByTableNameAndProcessId<ParametricQualitative>(
          "parametric_qualitative",
          measureKey.process_id,
          measureKey.dataset_id,
        ),
      );
    case MeasureType.Datetime:
      if (!measureKey.process_id || !measureKey.dataset_id) return {};
      return parseDatetime(
        await fetchDataByTableNameAndProcessId<DatetimeData>("datetime_data", measureKey.process_id, measureKey.dataset_id),
      );
    default:
      return {};
  }
};

// For Serial Number / Lot Code
const parseStatuses = (uniqueIdentifiers: UniqueIdentifier[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return uniqueIdentifiers.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, uniqueIdentifier) => {
    if (!acc[uniqueIdentifier.id]) {
      acc[uniqueIdentifier.id] = [];
    }
    const newValue: MeasureValueIdentifierStatus = {
      type: MeasureType.Status,
      uniqueIdentifierId: uniqueIdentifier.id,
      timestamp: uniqueIdentifier.last_updated_at,
      data: uniqueIdentifier,
    };
    acc[uniqueIdentifier.id].push(newValue);
    return acc;
  }, {});
};

// For Link Status
const parseLinkStatus = (uniqueIdentifierLinks: UniqueIdentifierLink[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return uniqueIdentifierLinks.reduce<{ [uniqueIdentifierId: string]: MeasureValueLinkStatus[] }>((acc, uniqueIdentifierLink) => {
    if (!acc[uniqueIdentifierLink.has_child_of_id]) {
      acc[uniqueIdentifierLink.has_child_of_id] = [
        {
          type: MeasureType.LinkStatus,
          uniqueIdentifierId: uniqueIdentifierLink.has_child_of_id,
          timestamp: new Date().toISOString(),
          data: [],
        },
      ];
    }
    acc[uniqueIdentifierLink.has_child_of_id][0].data.push(uniqueIdentifierLink);
    return acc;
  }, {});
};

// For Serial Number / Lot Code
const parseIdentifiers = (uniqueIdentifiers: UniqueIdentifier[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return uniqueIdentifiers.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, uniqueIdentifier) => {
    if (!acc[uniqueIdentifier.id]) {
      acc[uniqueIdentifier.id] = [];
    }
    const newValue: MeasureValueIdentifier = {
      type: MeasureType.Identifier,
      uniqueIdentifierId: uniqueIdentifier.id,
      timestamp: uniqueIdentifier.last_updated_at,
      data: uniqueIdentifier,
    };
    acc[uniqueIdentifier.id].push(newValue);
    return acc;
  }, {});
};

// For PartNumber
const parsePartNumbers = (uniqueIdentifiers: UniqueIdentifier[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return uniqueIdentifiers.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, uniqueIdentifier) => {
    if (!acc[uniqueIdentifier.id]) {
      acc[uniqueIdentifier.id] = [];
    }
    if (uniqueIdentifier.part_number_id && uniqueIdentifier.part_number) {
      const newValue: MeasureValuePartNumber = {
        type: MeasureType.PartNumber,
        uniqueIdentifierId: uniqueIdentifier.id,
        timestamp: uniqueIdentifier.last_updated_at,
        data: uniqueIdentifier.part_number,
      };
      acc[uniqueIdentifier.id].push(newValue);
    }
    return acc;
  }, {});
};

// For Work Order Name
const parseWorkOrders = (uniqueIdentifiers: UniqueIdentifier[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return uniqueIdentifiers.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, uniqueIdentifier) => {
    if (!acc[uniqueIdentifier.id]) {
      acc[uniqueIdentifier.id] = [];
    }
    if (uniqueIdentifier.work_order_id && uniqueIdentifier.work_order) {
      const newValue: MeasureValueWorkOrder = {
        type: MeasureType.WorkOrder,
        uniqueIdentifierId: uniqueIdentifier.id,
        timestamp: uniqueIdentifier.last_updated_at,
        data: uniqueIdentifier.work_order,
      };
      acc[uniqueIdentifier.id].push(newValue);
    }
    return acc;
  }, {});
};

// For Work Order Phase
const parseWorkOrderPhases = (uniqueIdentifiers: UniqueIdentifier[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return uniqueIdentifiers.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, uniqueIdentifier) => {
    if (!acc[uniqueIdentifier.id]) {
      acc[uniqueIdentifier.id] = [];
    }
    if (uniqueIdentifier.work_order) {
      const newValue: MeasureValueWorkOrderPhase = {
        type: MeasureType.WorkOrderPhase,
        uniqueIdentifierId: uniqueIdentifier.id,
        timestamp: uniqueIdentifier.last_updated_at,
        data: uniqueIdentifier.work_order,
      };
      acc[uniqueIdentifier.id].push(newValue);
    }
    return acc;
  }, {});
};

// For Station
const parseStations = (processEntries: ProcessEntry[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return processEntries.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, processEntry) => {
    if (!acc[processEntry.unique_identifier_id]) {
      acc[processEntry.unique_identifier_id] = [];
    }
    if (processEntry.station) {
      const newValue: MeasureValueStation = {
        type: MeasureType.Station,
        uniqueIdentifierId: processEntry.unique_identifier_id,
        timestamp: processEntry.timestamp,
        processEntry: processEntry,
        data: processEntry.station,
      };
      acc[processEntry.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For Operator
const parseOperators = (processEntries: ProcessEntry[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return processEntries.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, processEntry) => {
    if (!acc[processEntry.unique_identifier_id]) {
      acc[processEntry.unique_identifier_id] = [];
    }
    if (processEntry.operator) {
      const newValue: MeasureValueOperator = {
        type: MeasureType.Operator,
        uniqueIdentifierId: processEntry.unique_identifier_id,
        timestamp: processEntry.timestamp,
        processEntry: processEntry,
        data: processEntry.operator,
      };
      acc[processEntry.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For Process Timestamps
const parseTimestamps = (processEntries: ProcessEntry[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return processEntries.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, processEntry) => {
    if (!acc[processEntry.unique_identifier_id]) {
      acc[processEntry.unique_identifier_id] = [];
    }
    const newValue: MeasureValueTimestamp = {
      type: MeasureType.Timestamp,
      uniqueIdentifierId: processEntry.unique_identifier_id,
      timestamp: processEntry.timestamp,
      processEntry: processEntry,
      data: processEntry,
    };
    acc[processEntry.unique_identifier_id].push(newValue);
    return acc;
  }, {});
};

const parseCycleTimes = (processEntries: ProcessEntry[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return processEntries.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, processEntry) => {
    if (!acc[processEntry.unique_identifier_id]) {
      acc[processEntry.unique_identifier_id] = [];
    }
    if (processEntry.cycle_time !== null) {
      const newValue: MeasureValueCycleTime = {
        type: MeasureType.CycleTime,
        uniqueIdentifierId: processEntry.unique_identifier_id,
        timestamp: processEntry.timestamp,
        processEntry: processEntry,
        data: processEntry,
      };
      acc[processEntry.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For Process Result
const parseProcessResults = (processEntries: ProcessEntry[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return processEntries.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, processEntry) => {
    if (!acc[processEntry.unique_identifier_id]) {
      acc[processEntry.unique_identifier_id] = [];
    }
    if (processEntry.cycle_time !== null) {
      const newValue: MeasureValueProcessResult = {
        type: MeasureType.ProcessResult,
        uniqueIdentifierId: processEntry.unique_identifier_id,
        timestamp: processEntry.timestamp,
        processEntry: processEntry,
        data: processEntry,
      };
      acc[processEntry.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

const parseLinks = (uniqueIdentifierLinks: UniqueIdentifierLink[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return uniqueIdentifierLinks.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, uniqueIdentifierLink) => {
    if (!acc[uniqueIdentifierLink.unique_identifier_id]) {
      acc[uniqueIdentifierLink.unique_identifier_id] = [];
    }
    if (uniqueIdentifierLink.process_entry) {
      const newValue: MeasureValueLink = {
        type: MeasureType.Link,
        uniqueIdentifierId: uniqueIdentifierLink.unique_identifier_id,
        timestamp: uniqueIdentifierLink.process_entry?.timestamp ?? "",
        processEntry: uniqueIdentifierLink.process_entry,
        data: uniqueIdentifierLink,
      };
      acc[uniqueIdentifierLink.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For Parametric Qualitative
const parseParametricQualitative = (data: ParametricQualitative[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return data.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, datapoint) => {
    if (!acc[datapoint.unique_identifier_id]) {
      acc[datapoint.unique_identifier_id] = [];
    }
    if (datapoint.process_entry) {
      const newValue: MeasureValueParametricQualitative = {
        type: MeasureType.ParametricQualitative,
        uniqueIdentifierId: datapoint.unique_identifier_id,
        timestamp: datapoint.process_entry.timestamp,
        processEntry: datapoint.process_entry,
        data: datapoint,
      };
      acc[datapoint.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For Parametric Quantitative
const parseParametricQuantitative = (data: ParametricQuantitative[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return data.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, datapoint) => {
    if (!acc[datapoint.unique_identifier_id]) {
      acc[datapoint.unique_identifier_id] = [];
    }
    if (datapoint.process_entry) {
      const newValue: MeasureValueParametricQuantitative = {
        type: MeasureType.ParametricQuantitative,
        uniqueIdentifierId: datapoint.unique_identifier_id,
        timestamp: datapoint.process_entry.timestamp,
        processEntry: datapoint.process_entry,
        data: datapoint,
      };
      acc[datapoint.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For Image
const parseImages = (data: Image[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return data.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, datapoint) => {
    if (!acc[datapoint.unique_identifier_id]) {
      acc[datapoint.unique_identifier_id] = [];
    }
    if (datapoint.process_entry) {
      const newValue: MeasureValueImage = {
        type: MeasureType.Image,
        uniqueIdentifierId: datapoint.unique_identifier_id,
        timestamp: datapoint.process_entry.timestamp,
        processEntry: datapoint.process_entry,
        data: datapoint,
      };
      acc[datapoint.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For File
const parseFiles = (data: File[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return data.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, datapoint) => {
    if (!acc[datapoint.unique_identifier_id]) {
      acc[datapoint.unique_identifier_id] = [];
    }
    if (datapoint.process_entry) {
      const newValue: MeasureValueFile = {
        type: MeasureType.File,
        uniqueIdentifierId: datapoint.unique_identifier_id,
        timestamp: datapoint.process_entry.timestamp,
        processEntry: datapoint.process_entry,
        data: datapoint,
      };
      acc[datapoint.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For Checkbox
const parseCheckboxes = (data: Checkbox[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return data.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, datapoint) => {
    if (!acc[datapoint.unique_identifier_id]) {
      acc[datapoint.unique_identifier_id] = [];
    }
    if (datapoint.process_entry) {
      const newValue: MeasureValueCheckbox = {
        type: MeasureType.Checkbox,
        uniqueIdentifierId: datapoint.unique_identifier_id,
        timestamp: datapoint.process_entry.timestamp,
        processEntry: datapoint.process_entry,
        data: datapoint,
      };
      acc[datapoint.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

// For PassFail
const parsePassFails = (data: ParametricQualitative[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return data.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, datapoint) => {
    if (!acc[datapoint.unique_identifier_id]) {
      acc[datapoint.unique_identifier_id] = [];
    }
    if (datapoint.process_entry) {
      const newValue: MeasureValuePassFail = {
        type: MeasureType.PassFail,
        uniqueIdentifierId: datapoint.unique_identifier_id,
        timestamp: datapoint.process_entry.timestamp,
        processEntry: datapoint.process_entry,
        data: datapoint,
      };
      acc[datapoint.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

const parseDatetime = (data: DatetimeData[]): { [uniqueIdentifierId: string]: MeasureValue[] } => {
  return data.reduce<{ [uniqueIdentifierId: string]: MeasureValue[] }>((acc, datapoint) => {
    if (!acc[datapoint.unique_identifier_id]) {
      acc[datapoint.unique_identifier_id] = [];
    }
    if (datapoint.process_entry) {
      const newValue: MeasureValueDatetime = {
        type: MeasureType.Datetime,
        uniqueIdentifierId: datapoint.unique_identifier_id,
        timestamp: datapoint.process_entry.timestamp,
        processEntry: datapoint.process_entry,
        data: datapoint,
      };
      acc[datapoint.unique_identifier_id].push(newValue);
    }
    return acc;
  }, {});
};

export const summarizeMeasureValue = (measureValue: MeasureValue, numValues?: number): MeasureValueSummary => {
  switch (measureValue.type) {
    case MeasureType.Identifier:
      const uniqueIdentifierData = (measureValue as MeasureValueIdentifier).data;
      return {
        value: uniqueIdentifierData.identifier,
        formattedValue: uniqueIdentifierData.identifier,
        tag: uniqueIdentifierData.status ?? UniqueIdentifierStatus.Planned,
        href: `/snlookup?uid=${uniqueIdentifierData.id}`,
        numValues: numValues,
      };
    case MeasureType.Status:
      const uniqueIdentifierStatus = (measureValue as MeasureValueIdentifierStatus).data.status ?? UniqueIdentifierStatus.Planned;
      return {
        value: uniqueIdentifierStatus,
        formattedValue: uniqueIdentifierStatus,
        tag: uniqueIdentifierStatus,
        numValues: numValues,
      };
    case MeasureType.LinkStatus:
      const parentLinks = (measureValue as MeasureValueLinkStatus).data;
      const numActiveParents = parentLinks.filter((parentLink) => parentLink.is_active).length;
      const numInactiveParents = parentLinks.filter((parentLink) => !parentLink.is_active).length;
      const status = numActiveParents > 0 ? LinkStatus.Linked : numInactiveParents > 0 ? LinkStatus.UnLinked : null;
      const count = numInactiveParents === 0 && numActiveParents === 0 ? null : numActiveParents;
      const componentType = parentLinks.length > 0 ? parentLinks[0].child?.component?.component_type : null;
      if (count !== null && componentType !== null) {
        if (componentType === ComponentType.Sn) {
          return {
            value: count > 0 ? "Linked" : "Unlinked",
            formattedValue: count > 0 ? "Linked" : "Unlinked",
            tag: status ?? "",
          };
        } else if (componentType === ComponentType.Lot) {
          return {
            value: String(count),
            formattedValue: count ? `${count} Linked` : "Unlinked",
            tag: status ?? "",
          };
        }
      }
      return {
        value: "",
        formattedValue: "",
        tag: "",
      };
    case MeasureType.WorkOrderPhase:
      const workOrderPhaseData = (measureValue as MeasureValueWorkOrderPhase).data;
      return {
        value: workOrderPhaseData.phase ?? "",
        formattedValue: workOrderPhaseData.phase ?? "",
        tag: null,
        numValues: numValues,
      };
    case MeasureType.WorkOrder:
      const workOrderData = (measureValue as MeasureValueWorkOrder).data;
      return {
        value: workOrderData.name,
        formattedValue: workOrderData.name ?? "",
        tag: null,
        href: `/workorder/${workOrderData.id}`,
        numValues: numValues,
      };
    case MeasureType.PartNumber:
      const partNumberData = (measureValue as MeasureValuePartNumber).data;
      return {
        value: partNumberData.pn,
        formattedValue: partNumberData.pn,
        tag: null,
        numValues: numValues,
      };
    case MeasureType.Station:
      const stationData = (measureValue as MeasureValueStation).data;
      return {
        value: stationData.name ?? "",
        formattedValue: stationData.name ?? "",
        tag: null,
        numValues: numValues,
      };
    case MeasureType.Operator:
      const operatorData = (measureValue as MeasureValueOperator).data;
      return {
        value: `${operatorData.first_name} ${operatorData.last_name}`,
        formattedValue: `${operatorData.first_name} ${operatorData.last_name}`,
        tag: null,
        numValues: numValues,
      };
    case MeasureType.Timestamp:
      const timestampData = (measureValue as MeasureValueTimestamp).data;
      return {
        value: timestampData.timestamp,
        formattedValue: moment(timestampData.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        tag: null,
        numValues: numValues,
      };
    case MeasureType.CycleTime:
      const cycleTimeData = (measureValue as MeasureValueCycleTime).data;
      return {
        value: String(cycleTimeData.cycle_time),
        formattedValue: cycleTimeData.cycle_time !== null ? `${parseFloat(cycleTimeData.cycle_time.toFixed(3))} s` : "",
        tag: null,
        numValues: numValues,
      };
    case MeasureType.ProcessResult:
      const processResultData = (measureValue as MeasureValueProcessResult).data;
      const processIsPass = processResultData.is_pass === null ? "NA" : processResultData.is_pass ? "PASS" : "FAIL";
      return {
        value: processIsPass,
        formattedValue: processIsPass,
        tag: processIsPass,
        numValues: numValues,
      };
    case MeasureType.Checkbox:
      const checkboxData = (measureValue as MeasureValueCheckbox).data;
      return {
        value: String(checkboxData.is_checked),
        formattedValue: checkboxData.is_checked ? "Checked" : "Unchecked",
        tag: checkboxData.is_pass === null ? null : checkboxData.is_pass ? "PASS" : "FAIL",
        numValues: numValues,
      };
    case MeasureType.PassFail:
      const passFailData = (measureValue as MeasureValuePassFail).data;
      return {
        value: passFailData.value,
        formattedValue: passFailData.value,
        tag: passFailData.value,
        numValues: numValues,
      };
    case MeasureType.ParametricQuantitative:
      const parametricQuantitativeData = (measureValue as MeasureValueParametricQuantitative).data;
      let formattedValue = String(parseFloat(parametricQuantitativeData.value.toFixed(3)));
      if (parametricQuantitativeData.value > 1000 || parametricQuantitativeData.value < 0.001) {
        formattedValue = String(parametricQuantitativeData.value.toExponential(2));
      }
      return {
        value: String(parametricQuantitativeData.value),
        formattedValue: `${formattedValue} ${parametricQuantitativeData.unit ?? ""}`,
        tag: parametricQuantitativeData.is_pass === null ? null : parametricQuantitativeData.is_pass ? "PASS" : "FAIL",
        numValues: numValues,
      };
    case MeasureType.ParametricQualitative:
      const parametricQualitativeData = (measureValue as MeasureValueParametricQualitative).data;
      return {
        value: parametricQualitativeData.value,
        formattedValue: parametricQualitativeData.value,
        tag: null,
        numValues: numValues,
      };
    case MeasureType.Link:
      const linkData = (measureValue as MeasureValueLink).data;
      return {
        value: linkData.child?.identifier ?? "",
        formattedValue: linkData.child?.identifier ?? "",
        tag: linkData.child?.status ?? UniqueIdentifierStatus.Planned,
        href: `/snlookup?uid=${linkData.child?.id}`,
        numValues: numValues,
      };
    case MeasureType.Image:
      const imageData = (measureValue as MeasureValueImage).data;
      return {
        value: imageData.file_name,
        formattedValue: imageData.file_name,
        tag: null,
        fileId: imageData.file_id ?? imageData.id,
        bucketName: imageData.bucket_name,
        numValues: numValues,
      };
    case MeasureType.File:
      const fileData = (measureValue as MeasureValueFile).data;
      return {
        value: fileData.file_name,
        formattedValue: fileData.file_name,
        tag: null,
        fileId: fileData.file_id ?? fileData.id,
        bucketName: fileData.bucket_name,
        numValues: numValues,
      };
    case MeasureType.Datetime:
      const datetimeData = (measureValue as MeasureValueDatetime).data;
      return {
        value: datetimeData.value,
        formattedValue: moment(datetimeData.value).format("YYYY-MM-DD"),
        tag: null,
        numValues: numValues,
      };
    default:
      console.error(`Stringification not implemented for type ${measureValue}`);
      return { value: "", formattedValue: "", tag: null };
  }
};

export const filterMeasureValuesByTimeOperator = (
  values: MeasureValue[],
  timeOperator: MeasureTimeOperator | undefined,
  uniqueIdentifiersWithMeasures: UniqueIdentifierWithMeasures[],
): MeasureValue[] => {
  if (values.length === 0) {
    throw new Error("No measure values found");
  }
  // This is the UUID associated with the measure value being filtered
  const childUUID = values[0].uniqueIdentifierId;
  // This is the root for the row that the measure value is associated with
  const rootIdentifierWithMeasures = uniqueIdentifiersWithMeasures.filter((measureInfo) =>
    measureInfo.genealogy.uniqueIdentifiers.map((uniqueIdentifier) => uniqueIdentifier.id).includes(childUUID),
  )[0];
  const rootToLeafPaths = findRootToLeafPaths(rootIdentifierWithMeasures.genealogy.uniqueIdentifierLinks, rootIdentifierWithMeasures);
  if (timeOperator) {
    if (timeOperator.since) {
      if ((timeOperator.since as MeasureKey).process_id) {
        // Note, timestamp keys don't have dataset ids, but they will be undefined for the time operator and the parent measure
        const timeOperatorValues = rootIdentifierWithMeasures.measures.filter((measure) =>
          isSameMeasureKey(measure.key, timeOperator.since as MeasureKey),
        );
        // Use the rootToLeafsPaths to find the uuid associated with the time operator
        // We do this by filtering the paths to find the one that includes the childUUID
        // and then get the uuid of the entry associated with the time operator's component id
        const uuidKey = findAssociatedUniqueIdentifierIdByComponentId(
          rootToLeafPaths,
          childUUID,
          (timeOperator.since as MeasureKey).component_id,
        );
        if (!uuidKey) {
          throw new Error(`Could not find associated unique identifier id for time operator ${timeOperator.since}`);
        }
        const timestamp = timeOperatorValues?.[0]?.valuesByIdentifier?.[uuidKey]?.values;
        const finalTimestamp = extractTimestamp(timestamp);
        if (finalTimestamp) {
          values = [...values].filter((value) => new Date(value.timestamp).getTime() >= new Date(finalTimestamp as string).getTime());
        }
      } else {
        values = [...values].filter((value) => new Date(value.timestamp).getTime() >= new Date(timeOperator.since as string).getTime());
      }
    }
    if (timeOperator.until) {
      if ((timeOperator.until as MeasureKey).process_id) {
        // Note, timestamp keys don't have dataset ids, but they will be undefined for the time operator and the parent measure
        const timeOperatorValues = rootIdentifierWithMeasures.measures.filter((measure) =>
          isSameMeasureKey(measure.key, timeOperator.until as MeasureKey),
        );
        // Use the rootToLeafsPaths to find the uuid associated with the time operator
        // We do this by filtering the paths to find the one that includes the childUUID
        // and then get the uuid of the entry associated with the time operator's component id
        const uuidKey = findAssociatedUniqueIdentifierIdByComponentId(
          rootToLeafPaths,
          childUUID,
          (timeOperator.until as MeasureKey).component_id,
        );
        if (!uuidKey) {
          throw new Error(`Could not find associated unique identifier id for time operator ${timeOperator.until}`);
        }
        const timestamp = timeOperatorValues?.[0]?.valuesByIdentifier?.[uuidKey]?.values;

        const finalTimestamp = extractTimestamp(timestamp);
        if (finalTimestamp) {
          values = [...values].filter((value) => new Date(value.timestamp).getTime() <= new Date(finalTimestamp as string).getTime());
        }
      } else {
        values = [...values].filter((value) => new Date(value.timestamp).getTime() <= new Date(timeOperator.until as string).getTime());
      }
    }
  }
  return values;
};

export const aggregateMeasureValues = (
  values: MeasureValue[],
  key: MeasureKey,
  aggregationOperator: MeasureAggregationOperator,
): MeasureValueSummary => {
  const defaultAggregation: MeasureValueSummary = {
    value: "",
    formattedValue: "",
    tag: null,
  };
  // Find the parent measure
  const type = key.type;
  if (values.length === 0) {
    // Handle this appropriately
    return {
      value: "",
      formattedValue: "",
      tag: null,
      numValues: 0,
    };
  }
  if ([MeasureAggregationOperator.First, MeasureAggregationOperator.Latest].includes(aggregationOperator)) {
    const sortedValues = [...values].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    const displayedValue =
      aggregationOperator === MeasureAggregationOperator.Latest ? sortedValues[0] : sortedValues[sortedValues.length - 1];
    return summarizeMeasureValue(displayedValue, values.length);
  } else if (aggregationOperator === MeasureAggregationOperator.Count) {
    return {
      value: String(values.length),
      formattedValue: String(values.length),
      tag: null,
    };
  } else if (
    [
      MeasureAggregationOperator.Average,
      MeasureAggregationOperator.Sum,
      MeasureAggregationOperator.Max,
      MeasureAggregationOperator.Min,
    ].includes(aggregationOperator)
  ) {
    const quantitativeValues: number[] = [];
    switch (type) {
      case MeasureType.ParametricQuantitative:
        quantitativeValues.push(...values.map((value) => (value as MeasureValueParametricQuantitative).data.value));
        break;
      case MeasureType.CycleTime:
        quantitativeValues.push(
          ...(values.map((value) => (value as MeasureValueCycleTime).data.cycle_time ?? null).filter((v) => v !== null) as number[]),
        );
        break;
      default:
        console.error(`${aggregationOperator} aggregation not implemented for type ${type}`);
    }
    let aggregatedValue: number;
    switch (aggregationOperator) {
      case MeasureAggregationOperator.Average:
        aggregatedValue = Number((quantitativeValues.reduce((acc, value) => acc + value, 0) / quantitativeValues.length).toFixed(3));
        break;
      case MeasureAggregationOperator.Sum:
        aggregatedValue = parseFloat(quantitativeValues.reduce((acc, value) => acc + value, 0).toFixed(3));
        break;
      case MeasureAggregationOperator.Max:
        aggregatedValue = Math.max(...quantitativeValues);
        break;
      case MeasureAggregationOperator.Min:
        aggregatedValue = Math.min(...quantitativeValues);
        break;
      default:
        console.error(`${aggregationOperator} aggregation not implemented for type ${type}`);
        return defaultAggregation;
    }
    const formattedValue = String(aggregatedValue);
    switch (type) {
      case MeasureType.ParametricQuantitative:
        return {
          value: formattedValue,
          formattedValue: `${formattedValue} ${(values[0] as MeasureValueParametricQuantitative).data.unit ?? ""}`,
          tag: null,
          numValues: values.length,
        };
      case MeasureType.CycleTime:
        return {
          value: formattedValue,
          formattedValue: `${formattedValue} s`,
          tag: null,
          numValues: values.length,
        };
      default:
        return {
          value: String(aggregatedValue),
          formattedValue: String(aggregatedValue),
          tag: null,
          numValues: values.length,
        };
    }
  } else {
    console.error(`Aggregation operator ${aggregationOperator} not implemented`);
    return defaultAggregation;
  }
};

export const formatSummaryMeasureValue = (summaryValue: string, type: MeasureType): string => {
  switch (type) {
    case MeasureType.Status:
    case MeasureType.LinkStatus:
    case MeasureType.Checkbox:
    case MeasureType.PassFail:
    case MeasureType.ProcessResult:
      return screamingSnakeCaseToTitleCase(summaryValue);
    case MeasureType.WorkOrderPhase:
    case MeasureType.WorkOrder:
    case MeasureType.PartNumber:
    case MeasureType.Identifier:
    case MeasureType.Station:
    case MeasureType.Operator:
      return summaryValue;
    case MeasureType.Timestamp:
      return moment(summaryValue).format("YYYY-MM-DD HH:mm:ss");
    case MeasureType.CycleTime:
      return `${parseFloat(Number(summaryValue).toFixed(3))} s`;
    case MeasureType.ParametricQuantitative:
      const floatValue = parseFloat(summaryValue);
      if (floatValue > 1000 || floatValue < 0.001) {
        return String(floatValue.toExponential(2));
      }
      return String(parseFloat(floatValue.toFixed(3)));
    case MeasureType.Link:
    case MeasureType.ParametricQualitative:
    case MeasureType.Image:
    case MeasureType.File:
      return summaryValue;
    case MeasureType.Datetime:
      return moment(summaryValue).format("YYYY-MM-DD");
    default:
      return summaryValue;
  }
};
