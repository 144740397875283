import { RootState } from "@shared/redux/store";
import { User } from "@shared/types/databaseTypes";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useCurrentUser = (): User | undefined => {
  const supabaseUid = useSelector((state: RootState) => state.auth.supabase_uid);
  const users = useSelector((state: RootState) => state.db.users);

  const currentUser = useMemo(() => {
    return users.find((user) => user.supabase_uid === supabaseUid);
  }, [supabaseUid, users]);

  return currentUser;
};

export default useCurrentUser;
