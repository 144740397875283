import React, { useEffect, useState, useRef, useContext } from "react";
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import { Dataset } from "@shared/types/databaseTypes";
import { fetchNumericalDatasetValuesRandomX } from "../../connections/supabaseDataset";
import { Loader } from "@shared/components/Loader";
import useWindowSize from "@shared/hooks/useWindowSize";
import Switch from "@shared/components/Switch";
import { SnLookupContext } from "../../SnLookupProvider";

Chart.register(LineController, LinearScale, LineElement, Filler, Tooltip, PointElement, TimeScale);

interface NumericalViewProps {
  selectedDataset: Dataset;
  chartYAxisBounds: [number | undefined, number | undefined];
  setChartYAxisBounds: React.Dispatch<React.SetStateAction<[number | undefined, number | undefined]>>;
}

interface AllValues {
  x: number[];
  y: number[];
  usl?: number[];
  lsl?: number[];
}

const DataByPopulation: React.FC<NumericalViewProps> = ({ selectedDataset, chartYAxisBounds, setChartYAxisBounds }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [retestValues, setRetestValues] = useState<AllValues>({ x: [], y: [], usl: [], lsl: [] });
  const [allValues, setAllValues] = useState<AllValues>({ x: [], y: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [removeOutliersActive, setRemoveOutliersActive] = useState<boolean>(true);
  const { height: windowHeight } = useWindowSize();
  const { selectedProcess } = useContext(SnLookupContext);

  useEffect(() => {
    const uniqueIdentifierId = selectedProcess?.entries[0]?.unique_identifier_id;
    if (!uniqueIdentifierId) return;
    const fetchData = async () => {
      const { retestData, allData } = await fetchNumericalDatasetValuesRandomX(selectedDataset.id, uniqueIdentifierId);
      if (retestData) {
        setRetestValues(retestData);
      }
      if (allData) {
        processAllData(allData);
      }
    };
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  }, [selectedDataset, selectedProcess, removeOutliersActive]);

  const processAllData = (allData: AllValues) => {
    if (removeOutliersActive) {
      const filteredData = filterOutliers(allData.y);
      setAllValues({ x: allData.x.filter((_, index) => filteredData.indices.includes(index)), y: filteredData.values });
    } else {
      setAllValues(allData);
    }
  };

  useEffect(() => {
    if (isLoading) return;
    const allYValues = [
      ...allValues.y.filter((val) => val !== null),
      ...(retestValues.usl ? retestValues.usl.filter((val) => val !== null) : []),
      ...(retestValues.lsl ? retestValues.lsl.filter((val) => val !== null) : []),
    ];
    if (allYValues.length === 0) return;
    const maxY = Math.max(...allYValues);
    const minY = Math.min(...allYValues);
    const range = Math.max(Math.abs(maxY - minY), 1);
    const newChartYAxisBounds: [number, number] = [minY - range * 0.1, maxY + range * 0.1];
    if (newChartYAxisBounds[0] !== chartYAxisBounds[0] || newChartYAxisBounds[1] !== chartYAxisBounds[1]) {
      setChartYAxisBounds(newChartYAxisBounds);
    }
  }, [allValues, retestValues]);

  const filterOutliers = (values: number[]) => {
    const sortedValues = values.slice().sort((a, b) => a - b);
    const q1 = sortedValues[Math.floor(sortedValues.length / 4)];
    const q3 = sortedValues[Math.ceil(sortedValues.length * (3 / 4))];
    const iqr = q3 - q1;
    const indices: number[] = [];
    const filteredValues = values.filter((y, index) => {
      const isNotOutlier = y >= q1 - 1.5 * iqr && y <= q3 + 1.5 * iqr;
      if (isNotOutlier) indices.push(index);
      return isNotOutlier;
    });
    return { values: filteredValues, indices };
  };

  useEffect(() => {
    const datasets = [];

    if (retestValues.x.length > 0 && retestValues.y.length === retestValues.x.length) {
      datasets.push({
        label: "Test Values",
        data: retestValues.x.map((date, index) => ({
          x: date,
          y: retestValues.y[index],
        })),
        borderColor: "blue",
        backgroundColor: "blue",
        pointRadius: 3,
        borderWidth: 2,
        showLine: false,
      });
    }

    if (allValues.x.length > 0 && allValues.y.length === allValues.y.length) {
      datasets.push({
        label: "All Values",
        data: allValues.x.map((date, index) => ({
          x: date,
          y: allValues.y[index],
        })),
        borderColor: "lightgray",
        backgroundColor: "lightgray",
        pointRadius: 3,
        borderWidth: 1,
        showLine: false,
      });
    }

    const ctx = canvasRef.current?.getContext("2d");

    if (ctx) {
      const chart = new Chart(ctx, {
        type: "line",
        data: {
          datasets,
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              display: false,
              type: "linear",
              suggestedMax: 2,
              suggestedMin: -2,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
            y: {
              beginAtZero: false,
              min: chartYAxisBounds[0],
              max: chartYAxisBounds[1],
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return context.parsed.y.toString();
                },
                title: function () {
                  return "";
                },
              },
              displayColors: false,
              titleFont: {
                weight: "bold",
              },
              bodyFont: {
                weight: "bold",
              },
            },
            legend: {
              position: "bottom",
              title: {
                display: true,
                text: "",
                padding: 5,
              },
            },
            zoom: {
              pan: {
                enabled: true,
                mode: "xy",
              },
            },
          },
        },
      });

      return () => chart.destroy();
    }
  }, [selectedDataset, allValues, retestValues, windowHeight, chartYAxisBounds, removeOutliersActive]);

  return (
    <div className="relative flex h-full w-full flex-col rounded-md border bg-white">
      <div className="flex h-12 flex-grow-0 items-center justify-between gap-x-2 overflow-hidden border-b px-3">
        <div className="flex gap-2 overflow-hidden">
          <div className="text-serial-palette-800 truncate whitespace-nowrap font-semibold">By Population</div>
          {isLoading && <Loader styleOverride="h-6 w-6" />}
        </div>
        <div className="flex items-center gap-1.5">
          <label className="text-serial-palette-600">Outliers</label>
          <Switch size="sm" onChange={() => setRemoveOutliersActive(!removeOutliersActive)} checked={!removeOutliersActive} />
        </div>
      </div>
      {!isLoading && chartYAxisBounds[0] !== undefined && chartYAxisBounds[1] !== undefined && (
        <div className="flex-grow">
          <canvas ref={canvasRef} className="p-3"></canvas>
        </div>
      )}
    </div>
  );
};

export default DataByPopulation;
