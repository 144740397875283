import { Dataset, Process } from "@shared/types/databaseTypes";
import { useContext, useEffect, useState } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { DataType } from "@shared/types/databaseEnums";
import Button from "@shared/components/primitives/Button";
import TagBasic from "@shared/components/primitives/TagBasic";
import Popover from "@shared/components/primitives/Popover";
import { NumberInput, TextInput } from "@shared/components/primitives/Input";
import Switch from "@shared/components/primitives/Switch";
import { generateBlankDataset } from "../helpers";
import Select from "@shared/components/primitives/Select";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { ToastContext } from "@shared/context/ToastProvider";
import { createDataset, updateDataset } from "@shared/connections/api/datasets";
import { ToastType } from "@shared/components/Toast";

// Temporary until we rename ParametricQuantitative to Numerical, ParametricQualitative to Text, Check to Boolean, etc
const dataTypeAliases: Record<DataType, string> = {
  [DataType.ParametricQuantitative]: "NUMERICAL",
  [DataType.ParametricQualitative]: "TEXT",
  [DataType.Checkbox]: "BOOLEAN",
  [DataType.PassFail]: DataType.PassFail,
  [DataType.Image]: DataType.Image,
  [DataType.File]: DataType.File,
  [DataType.Link]: DataType.Link,
  [DataType.Uid]: DataType.Uid,
  [DataType.Datetime]: DataType.Datetime,
};

const ProcessBuilderApiDatasetEditPopover = ({
  process,
  dataset,
  children,
}: {
  process: Process;
  dataset?: Dataset;
  children: React.ReactNode;
}) => {
  const { triggerToast } = useContext(ToastContext);
  const { setSchemaDesignerOpen } = useContext(ProcessBuilderContext);

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tempDataset, setTempDataset] = useState<Dataset>(
    dataset ?? generateBlankDataset(process.company_id, process.id, process.revision, "", DataType.ParametricQuantitative),
  );

  useEffect(() => {
    if (dataset) {
      setTempDataset({ ...dataset });
    } else {
      setTempDataset(generateBlankDataset(process.company_id, process.id, process.revision, "", DataType.ParametricQuantitative));
    }
  }, [dataset]);

  const handleSave = async () => {
    setIsLoading(true);
    if (dataset) {
      const { error: updateError } = await updateDataset(tempDataset.id, {
        name: tempDataset.name,
        is_active: tempDataset.is_active,
      });
      if (updateError) triggerToast(ToastType.Error, "Could not save dataset", updateError);
    } else {
      const { error: createError } = await createDataset({
        type: dataTypeAliases[tempDataset.data_type] as DataType, // Temporary until we rename ParametricQuantitative to Numerical, ParametricQualitative to Text, Check to Boolean, etc
        name: tempDataset.name,
        process_id: tempDataset.process_id,
        usl: tempDataset.usl ?? undefined,
        lsl: tempDataset.lsl ?? undefined,
        unit: tempDataset.unit ?? undefined,
      });
      if (createError) triggerToast(ToastType.Error, "Could not create dataset", createError);
    }
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>{children}</Popover.Trigger>
      <Popover.Content align="end" className="flex w-72 flex-col">
        <div className="flex flex-col">
          <div className=" flex w-full flex-col gap-y-3 border-b p-2">
            {dataset === undefined && (
              <div className="flex flex-col gap-y-1">
                <label className="whitespace-nowrap text-xs font-medium">Data Type</label>
                <Select.Root
                  value={tempDataset?.data_type ?? ""}
                  onValueChange={(value) => setTempDataset({ ...tempDataset, data_type: value as DataType })}
                  disabled={dataset !== undefined}
                >
                  <Select.Trigger className="flex w-full pl-2" size="sm">
                    <div className="flex w-full flex-nowrap items-center gap-2">
                      <TagBasic variant={tempDataset.data_type} size="xs" />
                      <span className="truncate">{screamingSnakeCaseToTitleCase(dataTypeAliases[tempDataset.data_type])}</span>
                    </div>
                  </Select.Trigger>
                  <Select.Content>
                    {Object.values(DataType)
                      .filter((type) => type !== DataType.Uid && type !== DataType.Datetime && type !== DataType.PassFail)
                      .map((type, index) => (
                        <Select.Item key={index} value={type}>
                          <div className="flex w-full flex-nowrap items-center gap-2">
                            <TagBasic variant={type} size="xs" />
                            <span className="truncate">{screamingSnakeCaseToTitleCase(dataTypeAliases[type])}</span>
                          </div>
                        </Select.Item>
                      ))}
                  </Select.Content>
                </Select.Root>
              </div>
            )}
            <div className="flex flex-col gap-y-1">
              <label className="text-xs font-medium">Dataset Name</label>
              <TextInput
                size="sm"
                value={tempDataset.name ?? ""}
                placeholder="Name"
                onValueChange={(value) => setTempDataset({ ...tempDataset, name: value })}
              />
            </div>
            {tempDataset.data_type === DataType.ParametricQuantitative && (
              <div className="flex w-full gap-x-2">
                <div className="flex flex-col gap-y-1">
                  <label className="text-xs font-medium">Unit</label>
                  <TextInput
                    size="sm"
                    value={tempDataset.unit ?? ""}
                    placeholder="Unit"
                    disabled={dataset ? true : false}
                    onValueChange={(value) => setTempDataset({ ...tempDataset, unit: value })}
                  />
                </div>
                <div className="flex flex-col gap-y-1">
                  <label className="text-xs font-medium">LSL</label>
                  <NumberInput
                    size="sm"
                    value={tempDataset.lsl ?? undefined}
                    placeholder="LSL"
                    disabled={dataset ? true : false}
                    onValueChange={(value) => setTempDataset({ ...tempDataset, lsl: value })}
                  />
                </div>
                <div className="flex flex-col gap-y-1">
                  <label className="text-xs font-medium">USL</label>
                  <NumberInput
                    size="sm"
                    value={tempDataset.usl ?? undefined}
                    placeholder="USL"
                    disabled={dataset ? true : false}
                    onValueChange={(value) => setTempDataset({ ...tempDataset, usl: value })}
                  />
                </div>
              </div>
            )}
            <div className="flex items-center gap-x-3">
              <label className="text-xs font-medium">Active</label>
              <Switch
                size="sm"
                checked={tempDataset.is_active}
                onCheckedChange={(checked) => setTempDataset({ ...tempDataset, is_active: checked })}
              />
            </div>
          </div>
          <div className=" bg-serial-palette-50 flex w-full flex-row-reverse justify-between px-2 py-1.5">
            <Button size="xs" onClick={handleSave} isLoading={isLoading}>
              {dataset ? "Save Changes" : "Add Dataset"}
            </Button>
            {dataset?.data_type === DataType.File && (
              <Button
                size="xs"
                variant="outline"
                onClick={() => {
                  setSchemaDesignerOpen(true);
                  setOpen(false);
                }}
              >
                Auto Parsing
              </Button>
            )}
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default ProcessBuilderApiDatasetEditPopover;
