import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TypeTag from "@shared/components/TypeTag";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import PaginationFooter from "@shared/components/PaginationFooter";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { Component, ProcessEntry, UniqueIdentifier } from "@shared/types/databaseTypes";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { formatTimeAgo } from "@shared/utils/time";
import { fetchUniqueIdentifiersByComponentIdWithPagination, fetchUniqueIdentifiersCountByComponentId } from "../connections/supabase";
import useWindowSize from "@shared/hooks/useWindowSize";

interface ComponentInstanceTableProps {
  component: Component;
  statusFilter: UniqueIdentifierStatus | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showArchivedComponentInstances: boolean;
  showActiveComponentInstances: boolean;
}

type ExtendedUniqueIdentifier = UniqueIdentifier & {
  latest_process_entry: ProcessEntry;
};

export const ComponentInstanceTable: React.FC<ComponentInstanceTableProps> = ({ component, statusFilter, setIsLoading, showArchivedComponentInstances, showActiveComponentInstances }) => {
  const [hoverIdentiferIndex, setHoverIdentiferIndex] = useState<number | null>(null);
  const [uniqueIdentifiers, setUniqueIdentifiers] = useState<ExtendedUniqueIdentifier[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [totalItems, setTotalItems] = useState<number>(0);

  const navigate = useNavigate();

  const { height: windowHeight } = useWindowSize();

  const observe = useContext(ObservabilityContext);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page
  };

  // load data from backend on page load
  useEffect(() => {
    loadData();
  }, [component, currentPage, rowsPerPage, statusFilter, showArchivedComponentInstances, showActiveComponentInstances]);

  const loadData = async () => {
    setIsLoading(true);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage - 1;

    const loadedUniqueIdentifiers = await fetchUniqueIdentifiersByComponentIdWithPagination(
      component.id,
      statusFilter,
      startIndex,
      endIndex,
      showArchivedComponentInstances,
      showActiveComponentInstances,
    );
    const loadedUniqueIdentifiersCount = (await fetchUniqueIdentifiersCountByComponentId(component.id, statusFilter, showArchivedComponentInstances, showActiveComponentInstances)) as number;
    const totalItems = Math.ceil(loadedUniqueIdentifiersCount / rowsPerPage);

    setUniqueIdentifiers(loadedUniqueIdentifiers);
    setTotalItems(totalItems);
    setIsLoading(false);
  };

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col bg-white">
      {uniqueIdentifiers?.length === 0 && (
        <div className="w-full space-y-2 border-t py-6">
          <div className="text-center italic">No instances of this component yet</div>
        </div>
      )}

      {uniqueIdentifiers?.length > 0 && (
        <div className="flex h-full flex-col justify-between">
          <div className="scrollbar-hide flex w-full overflow-y-auto border-t">
            <table className="table w-full">
              <thead className="text-serial-palette-500 bg-serial-palette-50 sticky top-0 rounded-md border-b text-xs font-semibold uppercase">
                <tr>
                  <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold">Identifier</div>
                  </th>
                  <th className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 2xl:table-cell">
                    <div className="text-left font-semibold">Last Process Entry</div>
                  </th>
                  <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold">Last Updated</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-serial-palette-200 border-serial-palette-200 divide-y border-b text-sm">
                {uniqueIdentifiers.map((identifier, index) => {
                  return (
                    <tr
                      key={index}
                      onMouseEnter={() => setHoverIdentiferIndex(index)}
                      onMouseLeave={() => setHoverIdentiferIndex(null)}
                      onClick={() => (navigate("/snlookup?uid=" + identifier.id), observe.track("component_nav-to-sn-lookup-via-button"))}
                      className="hover:bg-serial-palette-100 cursor-pointer"
                    >
                      <td className="cursor-pointer whitespace-nowrap px-0.5 py-3 first:pl-5 last:pr-5">
                        <div className="inline-flex max-w-[180px] gap-x-2">
                          {identifier?.status && (
                            <TypeTag type={identifier.is_archived ? "ARCHIVED" : identifier?.status} className="h-[20px] w-auto text-[10px]" hideText={true} />
                          )}
                          <Link
                            to={`/snlookup?uid=${identifier.id}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              observe.track("component_nav-to-sn-lookup-via-identifier");
                            }}
                            className="truncate text-left text-sm hover:underline"
                          >
                            {identifier.identifier}
                          </Link>
                          <CopyToClipboard
                            text={identifier.identifier}
                            className={`${hoverIdentiferIndex === index ? "" : "text-white"}`}
                          />
                        </div>
                      </td>
                      <td className="hidden px-2 py-3 first:pl-5 last:pr-5 2xl:table-cell">
                        <div className="inline-flex items-center space-x-3">
                          <div className="text-left text-sm">
                            {identifier?.latest_process_entry?.process?.name ?? "No Processes Logged"}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div className="text-left text-sm">{formatTimeAgo(identifier?.last_updated_at)}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <PaginationFooter
            className="border-t"
            currentPage={currentPage}
            totalPages={totalItems}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={windowHeight > 1000 ? [25, 50, 100] : [15, 30, 50]}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default ComponentInstanceTable;
