import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import TypeTag from "@shared/components/TypeTag";
import moment from "moment";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { ProcessEntry } from "@shared/types/databaseTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

interface StationDetailsSnTableRowProps {
  processEntry: ProcessEntry;
  index: number;
}

const StationDetailsSnTableRow: React.FC<StationDetailsSnTableRowProps> = ({ processEntry, index }) => {
  const [hoverIdentiferIndex, setHoverIdentiferIndex] = useState<number | null>(null);

  const components = useSelector((state: RootState) => state.db.components);
  const component = useMemo(
    () => components.find((c) => c.id === processEntry?.unique_identifier?.component_id),
    [components, processEntry],
  );
  return (
    <tr onMouseEnter={() => setHoverIdentiferIndex(index)} onMouseLeave={() => setHoverIdentiferIndex(null)}>
      <td className="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
        <div className="flex">
          <Link to={`/snlookup?uid=${processEntry?.unique_identifier_id}`} className="text-left hover:underline">
            {processEntry?.unique_identifier?.identifier}
          </Link>
          <CopyToClipboard
            text={processEntry?.unique_identifier?.identifier ?? ""}
            className={`${hoverIdentiferIndex === index ? "" : "text-white"}`}
          />
        </div>
      </td>
      <td className="hidden cursor-pointer px-2 py-3 first:pl-5 last:pr-5 xl:block">
        <Link to={`/component/${processEntry?.unique_identifier?.component_id}`} className="text-left hover:underline">
          {component?.name ?? "-"}
        </Link>
      </td>
      <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
        <div className="flex justify-center text-[10px]">
          {processEntry.is_pass === null || processEntry?.is_pass === undefined ? (
            <TypeTag className="w-16" type={"NA"} />
          ) : processEntry?.is_pass ? (
            <TypeTag className="w-16" type={"PASS"} />
          ) : (
            <TypeTag className="w-16" type={"FAIL"} />
          )}
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 lg:block">
        <div className="truncate text-left" title={processEntry?.process?.name ?? "-"}>
          {processEntry?.process?.name ?? "-"}
        </div>
      </td>
      <td className="px-2 py-3 first:pl-5 last:pr-5">
        <div className="text-left">{moment(processEntry?.timestamp).format("MM/DD/YY HH:mma")}</div>
      </td>
    </tr>
  );
};

export default StationDetailsSnTableRow;
