import { createRef, useContext, useEffect, useRef, useState } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { ListBlock } from "@shared/types/databaseTypes";
import { ProcessBuilderWorkInstructionBlockContentProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import ContentEditable from "@shared/components/ContentEditable";
import { workInstructionBlocksToMarkdown } from "../../helpers";
import { generateWorkInstructionsSuggestions } from "../../connections/api";

const ProcessBuilderWorkInstructionBlockList: React.FC<ProcessBuilderWorkInstructionBlockContentProps<ListBlock>> = ({
  block,
  stepIndex,
  blockIndex,
}) => {
  const [nextFocusIndex, setNextFocusIndex] = useState<number | null>(null);
  const { readOnly, handleUpdateWorkInstructionBlocks, handleDeleteWorkInstructionBlock, draftProcess } = useContext(ProcessBuilderContext);
  const contentRefs = useRef<React.RefObject<HTMLDivElement>[]>(block.content.items.map(() => createRef()));

  contentRefs.current = block.content.items.map((_item, index) => contentRefs.current[index] ?? createRef());

  // Focus on the correct item when the next focus index changes
  useEffect(() => {
    if (nextFocusIndex !== null && contentRefs?.current[nextFocusIndex]) {
      contentRefs?.current?.[nextFocusIndex]?.current?.focus();
      setNextFocusIndex(null);
    }
  }, [block.content.items, nextFocusIndex, contentRefs]);

  const handleUpdateItem = (index: number, item: string) => {
    handleUpdateWorkInstructionBlocks(
      stepIndex,
      [block.id],
      "items",
      block.content.items.map((i, iIndex) => (iIndex === index ? item : i)),
      true,
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    // add a new item when enter is pressed
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent new line in content editable
      const newItems = [...block.content.items];
      newItems.splice(index + 1, 0, "");
      handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "items", newItems, true);
      setTimeout(() => setNextFocusIndex(index + 1), 0);
    }
    // focus on the previous item when backspace is pressed on an empty item
    if (event.key === "Backspace" && contentRefs?.current?.[index]?.current?.innerText === "") {
      if (index === 0 && block.content.items.length === 1) {
        handleDeleteWorkInstructionBlock(stepIndex, blockIndex);
        return;
      }
      event.preventDefault(); // Prevent new line in content editable
      const newItems = [...block.content.items];
      newItems.splice(index, 1);
      handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "items", newItems, true);
      setNextFocusIndex(index - 1);
    }
    // if down arrow is pressed, focus on the next item if it exists
    if (event.key === "ArrowDown") {
      event.preventDefault(); // Prevent new line in content editable
      if (index === block.content.items.length - 1) return;
      setNextFocusIndex(index + 1);
    }
    // if up arrow is pressed, focus on the previous item if it exists
    if (event.key === "ArrowUp") {
      event.preventDefault(); // Prevent new line in content editable
      if (index === 0) return;
      setNextFocusIndex(index - 1);
    }
  };

  const handleBlockClick = () => {
    // if any of the contentRefs are focused, don't focus on the first contentRef
    if (contentRefs?.current?.some((item) => item?.current === document.activeElement)) return;
    contentRefs?.current?.[0]?.current?.focus();
  };

  const getCopilotSuggestion = (index: number) => {
    const priorSteps = draftProcess?.process_steps.slice(0, stepIndex).flatMap((step) => step.work_instruction_blocks) ?? [];
    const currentStepPreviousBlocks = draftProcess?.process_steps[stepIndex]?.work_instruction_blocks?.slice(0, blockIndex) ?? [];
    const currentStepCurrentBlockUpToCursor = { ...block, content: { ...block.content, items: block.content.items.slice(0, index + 1) } };
    const markdownWorkInstructions = workInstructionBlocksToMarkdown([
      ...priorSteps,
      ...currentStepPreviousBlocks,
      currentStepCurrentBlockUpToCursor,
    ]);
    return generateWorkInstructionsSuggestions(markdownWorkInstructions);
  };

  return (
    <div className="flex w-full flex-col gap-y-0.5 pl-5" id={block.id} onClick={() => handleBlockClick()}>
      {block.content.items.map((_item, index) => {
        return (
          <div className="flex gap-x-1.5" key={index}>
            {block.content.listType === "NUMBERED" && <div className="flex w-5 shrink-0 font-medium">{index + 1}.</div>}
            {block.content.listType === "BULLETED" && (
              <div className="flex w-3 shrink-0 pt-2 text-[6px]">
                <FontAwesomeIcon icon={faCircle} />
              </div>
            )}
            <ContentEditable
              contentRef={contentRefs?.current?.[index]}
              onInput={() => handleUpdateItem(index, contentRefs?.current?.[index]?.current?.innerText ?? "")}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="w-full outline-0"
              placeholder="List item"
              content={block.content.items[index]}
              copilot={true}
              handleAcceptCopilotSuggestion={(newContent) => handleUpdateItem(index, newContent)}
              getCopilotSuggestion={(_newContent) => getCopilotSuggestion(index)}
              readOnly={readOnly}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlockList;
