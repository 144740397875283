import React, { useContext, useEffect, useRef, useState } from "react";
import { LabelElement, LabelFormat } from "@shared/types/databaseTypes";
import { LabelElementType } from "@shared/types/databaseEnums";
import { TextElement, ImageElement, BarcodeElement, QrCodeElement, IdentifierElement } from "../types";
import LabelElementBarcode from "./elements/LabelElementBarcode";
import LabelElementQRCode from "./elements/LabelElementQRCode";
import LabelElementIdentifier from "./elements/LabelElementIdentifier";
import LabelElementText from "./elements/LabelElementText";
import LabelElementImage from "./elements/LabelElementImage";
import { PX_PER_MM } from "../constants";
import { LabelFormatterContext } from "../LabelFormatterProvider";
import LabelFormatterLabelElementSettings from "./LabelFormatterLabelElementSettings";

interface ResizeCorner {
  bottom: boolean;
  right: boolean;
}

interface Coordinate {
  x: number;
  y: number;
}

interface LabelFormatterLabelElementProps {
  element: LabelElement;
  label: LabelFormat;
  identifier: string;
}

const LabelFormatterLabelElement: React.FC<LabelFormatterLabelElementProps> = ({ element, label, identifier }) => {
  const { handleEditElement, focusedElementId, setFocusedElementId, handleDeleteElement, zoom } = useContext(LabelFormatterContext);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [tempPosition, setTempPosition] = useState<Coordinate | null>(null);

  const elementRef = useRef<HTMLDivElement>(null);
  const mouseClickPositionRef = useRef<Coordinate>({ x: 0, y: 0 });
  const resizeCornerRef = useRef<ResizeCorner | null>(null);

  const handleDragMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    mouseClickPositionRef.current = { x: e.clientX, y: e.clientY };
    setIsDragging(true);
    setTempPosition({
      x: element.x ?? 0,
      y: element.y ?? 0,
    });
    window.addEventListener("mousemove", handleDragMouseMove);
    window.addEventListener("mouseup", handleDragMouseUp);
  };

  const calculateNewPosition = (e: MouseEvent): Coordinate => {
    const dx = e.clientX - mouseClickPositionRef.current.x;
    const dy = e.clientY - mouseClickPositionRef.current.y;
    return {
      x: (element.x ?? 0) + dx / zoom / PX_PER_MM,
      y: (element.y ?? 0) + dy / zoom / PX_PER_MM,
    };
  };

  const handleDragMouseMove = (e: MouseEvent) => {
    setTempPosition(calculateNewPosition(e));
  };

  const handleDragMouseUp = (e: MouseEvent) => {
    setIsDragging(false);
    window.removeEventListener("mousemove", handleDragMouseMove);
    window.removeEventListener("mouseup", handleDragMouseUp);
    const finalPosition = calculateNewPosition(e);
    handleEditElement(element.id, "x", finalPosition.x);
    handleEditElement(element.id, "y", finalPosition.y);
    setTempPosition(null);
  };

  const handleResizeMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, resizeCorner: ResizeCorner) => {
    e.stopPropagation(); // prevent the element from also being dragged while resizing
    mouseClickPositionRef.current = { x: e.clientX, y: e.clientY };
    resizeCornerRef.current = resizeCorner;
    window.addEventListener("mousemove", handleResizeMouseMove);
    window.addEventListener("mouseup", handleResizeMouseUp);
  };

  const handleResizeMouseMove = (e: MouseEvent) => {
    if ("width" in element) {
      const dx = e.clientX - mouseClickPositionRef.current.x;
      const newWidth = (element.width ?? 0) + (resizeCornerRef.current?.right ? dx : -dx) / zoom / PX_PER_MM;
      handleEditElement(element.id, "width", newWidth);
      setTempPosition({
        x: (element.x ?? 0) + (resizeCornerRef.current?.right ? 0 : dx / zoom / PX_PER_MM),
        y: element.y ?? 0,
      });
    }
    if ("height" in element) {
      const dy = e.clientY - mouseClickPositionRef.current.y;
      const newHeight = (element.height ?? 0) + (resizeCornerRef.current?.bottom ? dy : -dy) / zoom / PX_PER_MM;
      handleEditElement(element.id, "height", newHeight);
      setTempPosition({
        x: element.x ?? 0,
        y: (element.y ?? 0) + (resizeCornerRef.current?.bottom ? 0 : dy / zoom / PX_PER_MM),
      });
    }
    if ("font_size" in element) {
      const dy = e.clientY - mouseClickPositionRef.current.y;
      const newFontSize = (element.font_size ?? 0) + (resizeCornerRef.current?.bottom ? dy : -dy) / zoom / PX_PER_MM;
      handleEditElement(element.id, "font_size", newFontSize);
      setTempPosition({
        x: element.x ?? 0,
        y: (element.y ?? 0) + (resizeCornerRef.current?.bottom ? 0 : dy / zoom / PX_PER_MM),
      });
    }
  };

  // delete the element if it's focused and the delete key is pressed
  useEffect(() => {
    const handleDelete = (e: KeyboardEvent) => {
      if (focusedElementId === element.id && (e.key === "Delete" || e.key === "Backspace")) {
        handleDeleteElement(element.id);
      }
    };
    window.addEventListener("keydown", handleDelete);
    return () => window.removeEventListener("keydown", handleDelete);
  }, [focusedElementId, element.id, handleDeleteElement]);

  const handleResizeMouseUp = (e: MouseEvent) => {
    window.removeEventListener("mousemove", handleResizeMouseMove);
    window.removeEventListener("mouseup", handleResizeMouseUp);
    const dx = e.clientX - mouseClickPositionRef.current.x;
    const dy = e.clientY - mouseClickPositionRef.current.y;
    const finalPosition = {
      x: element.x ?? 0,
      y: element.y ?? 0,
    };
    if ("width" in element) {
      finalPosition.x += resizeCornerRef.current?.right ? 0 : dx / zoom / PX_PER_MM;
    }
    if ("height" in element || "font_size" in element) {
      finalPosition.y += resizeCornerRef.current?.bottom ? 0 : dy / zoom / PX_PER_MM;
    }
    handleEditElement(element.id, "x", finalPosition.x);
    handleEditElement(element.id, "y", finalPosition.y);
    setTempPosition(null);
    resizeCornerRef.current = null;
  };

  // drop focus on mouse down if there's a click outside the bounds of the element
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (elementRef.current && !elementRef.current.contains(e.target as Node)) {
        setFocusedElementId(null);
      }
    };
    window.addEventListener("mousedown", handleClick);
    return () => window.removeEventListener("mousedown", handleClick);
  }, [setFocusedElementId]);

  return (
    <div
      ref={elementRef}
      className={`group flex flex-col items-center justify-center ${focusedElementId === element.id || isDragging ? "outline outline-sky-500" : "outline-sky-100 hover:outline"} outline-1 `}
      style={{
        position: "absolute",
        top: (tempPosition !== null ? tempPosition.y : element.y ?? 0) * PX_PER_MM,
        left: (tempPosition !== null ? tempPosition.x : element.x ?? 0) * PX_PER_MM,
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseDown={(e) => handleDragMouseDown(e)}
      onClick={() => setFocusedElementId(element.id)}
    >
      {element.type === LabelElementType.Barcode && (
        <LabelElementBarcode element={element as BarcodeElement} label={label} identifier={identifier} />
      )}
      {element.type === LabelElementType.QRCode && (
        <LabelElementQRCode element={element as QrCodeElement} label={label} identifier={identifier} />
      )}
      {element.type === LabelElementType.Identifier && (
        <LabelElementIdentifier element={element as IdentifierElement} label={label} identifier={identifier} />
      )}
      {element.type === LabelElementType.Text && (
        <LabelElementText element={element as TextElement} label={label} identifier={identifier} />
      )}
      {element.type === LabelElementType.Image && (
        <LabelElementImage element={element as ImageElement} label={label} identifier={identifier} />
      )}
      <div
        onMouseDown={(e) => handleResizeMouseDown(e, { bottom: false, right: false })}
        className={`${focusedElementId === element.id || isDragging ? "bg-sky-500" : "hidden bg-sky-100"} absolute -left-1 -top-1 z-20 h-2 w-2 cursor-nw-resize items-center justify-center p-0 group-hover:flex`}
      />
      <div
        onMouseDown={(e) => handleResizeMouseDown(e, { bottom: false, right: true })}
        className={`${focusedElementId === element.id || isDragging ? "bg-sky-500" : "hidden bg-sky-100"} absolute -right-1 -top-1 z-20 h-2 w-2 cursor-ne-resize items-center justify-center p-0 group-hover:flex`}
      />
      <div
        onMouseDown={(e) => handleResizeMouseDown(e, { bottom: true, right: true })}
        className={`${focusedElementId === element.id || isDragging ? "bg-sky-500" : "hidden bg-sky-100"} absolute -bottom-1 -right-1 z-20 h-2 w-2 cursor-se-resize items-center justify-center p-0 group-hover:flex`}
      />
      <div
        onMouseDown={(e) => handleResizeMouseDown(e, { bottom: true, right: false })}
        className={`${focusedElementId === element.id || isDragging ? "bg-sky-500" : "hidden bg-sky-100"} absolute -bottom-1 -left-1 z-20 h-2 w-2 cursor-sw-resize items-center justify-center p-0 group-hover:flex`}
      />
      <LabelFormatterLabelElementSettings element={element} elementRef={elementRef} />
    </div>
  );
};

export default LabelFormatterLabelElement;
