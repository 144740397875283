import React, { useContext, useMemo } from "react";
import { ProductionContext } from "../ProductionProvider";
import ProductionProcessSelectorUniversal from "./ProductionProcessSelectorUniversal";
import ProductionProcessSelectorDedicated from "./ProductionProcessSelectorDedicated";
import { StationType, UserRole } from "@shared/types/databaseEnums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faGear, faGlobe } from "@fortawesome/free-solid-svg-icons";

const ProductionProcessSelector: React.FC = () => {
  const { station, user, configureStation, setConfigureStation, handleUpdateStation } = useContext(ProductionContext);

  const showFooter = useMemo(() => {
    return (
      (user?.role === UserRole.Admin || user?.role === UserRole.Member) &&
      station?.is_active &&
      !station?.is_locked &&
      (configureStation || station?.type === StationType.Dedicated)
    );
  }, [station, user, configureStation]);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col">
      {/* Station Type Buttons */}
      {configureStation && (
        <div className="bg-serial-palette-50 border-serial-palette-300 flex w-full border-b pb-8">
          <div className="mx-4 flex w-full flex-col justify-center gap-x-8 gap-y-4 pt-8 md:flex-row">
            <button
              className={`border-serial-palette-200 flex items-center gap-x-6 rounded-md border  p-4 md:p-8 xl:w-[550px] ${station?.type === StationType.Dedicated ? "bg-serial-palette-300" : "bg-white"}`}
              onClick={() => handleUpdateStation({ type: StationType.Dedicated })}
            >
              <FontAwesomeIcon icon={faCrosshairs} className="hidden md:block" size="3x" />
              <div>
                <div className="text-left text-xl font-bold">Dedicated Workstation</div>
                <div className="pt-3 text-left">Select specific process steps that can be completed on this workstation</div>
              </div>
            </button>
            <button
              className={`border-serial-palette-200 flex items-center gap-x-6 rounded-md border  p-4 md:p-8 xl:w-[550px] ${station?.type === StationType.Universal ? "bg-serial-palette-300" : "bg-white"}`}
              onClick={() => handleUpdateStation({ type: StationType.Universal })}
            >
              <FontAwesomeIcon icon={faGlobe} className="hidden md:block" size="3x" />
              <div>
                <div className="text-left text-xl font-bold">Universal Workstation</div>
                <div className="pt-3 text-left">Automatically pull up next process step by scanning identifier</div>
              </div>
            </button>
          </div>
        </div>
      )}
      {/* Station Selector */}
      {(station?.type === StationType.Universal || !station) && <ProductionProcessSelectorUniversal />}
      {station?.type === StationType.Dedicated && <ProductionProcessSelectorDedicated />}
      {/* Footer */}
      {showFooter && (
        <div
          className={`z-10 flex w-full flex-col items-center justify-between gap-y-2 px-3 py-2 md:flex-row ${configureStation ? "fixed bottom-0 bg-green-300" : "bg-serial-palette-50"} border-serial-palette-200 border-t`}
        >
          <div className="hidden md:flex" />
          <div className={`${!configureStation && "hidden md:flex"}`}>
            {configureStation && (
              <div className="text-center">
                <span className="font-bold">Setup Mode: </span>
                {`Select the workstation type${station?.type === StationType.Dedicated ? " and the processes you want to enable" : ""}`}
              </div>
            )}
          </div>
          <button onClick={() => setConfigureStation(!configureStation)} className="btn-sm serial-btn-dark gap-x-2">
            <FontAwesomeIcon icon={faGear} className="text-sm" />
            {configureStation ? "Save Configuration" : "Configure"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductionProcessSelector;
