import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import CopyToClipboard from "@shared/components/CopyToClipboard";

interface DebugTokensProps {}

const DebugTokens: React.FC<DebugTokensProps> = () => {
  const supabaseToken = useSelector((state: RootState) => state.auth.token);

  return (
    <div className="flex w-full flex-col gap-y-4">
      <div className="flex w-full items-center justify-between">
        <h1 className="text-lg">
          <span className="font-bold">Supabase Token:</span> Use{" "}
          <a className="text-blue-500 underline" href={"https://jwt.io/"}>
            jwt.io
          </a>{" "}
          to parse
        </h1>
        <div className="flex max-w-[50%] rounded-lg bg-white px-2 py-0.5">
          <pre className="truncate">{supabaseToken ?? ""}</pre>
          <CopyToClipboard text={supabaseToken ?? ""} />
        </div>
      </div>
    </div>
  );
};

export default DebugTokens;
