import React, { useMemo } from "react";
import { ReportTemplateDashboard } from "@shared/types/databaseTypes";
import UserAvatar from "@shared/components/UserAvatar";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useNavigate } from "react-router-dom";

interface DashboardListCardProps {
  dashboard: ReportTemplateDashboard;
}

const DashboardListCard: React.FC<DashboardListCardProps> = ({ dashboard }) => {
  const users = useSelector((state: RootState) => state.db.users);
  const navigate = useNavigate();

  const user = useMemo(() => {
    return users.find((user) => user.supabase_uid === dashboard.last_edited_by) ?? null;
  }, [users, dashboard.last_edited_by]);

  return (
    <div
      className="hover:bg-serial-palette-50 flex h-44 w-full cursor-pointer justify-between gap-2 rounded border bg-white p-3"
      onClick={() => navigate(`/dashboards/${dashboard.id}`)}
    >
      {/* Name and Description */}
      <div className="flex w-full flex-col justify-between lg:w-1/2">
        <div className="flex w-full flex-col">
          <p className="text-xl font-bold">{dashboard.name}</p>
          <p>{dashboard.description}</p>
        </div>
        {!dashboard.config.is_prebuilt && user && (
          <div className="mt-2 flex gap-x-2 pl-1 ">
            <p className="mt-1 text-left text-sm">Last Edited By </p>
            <UserAvatar user={user} enableToolTip={true} size={"sm"} />
          </div>
        )}
      </div>
      {/* Preview */}
      <div className="flex h-full w-1/4 items-end pr-2">{dashboard?.config?.icon}</div>
    </div>
  );
};

export default DashboardListCard;
