import React, { useEffect } from "react";
import { useContext, useRef } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { ManualEntryField } from "@shared/types/databaseTypes";
import { ProcessBuilderFieldContentsProps } from "../../types";
import { DataType } from "@shared/types/databaseEnums";
import ContentEditable from "@shared/components/ContentEditable";
import { fetchProcessRevisions } from "@shared/connections/supabaseProcessBuilder";
import Tooltip from "@shared/components/Tooltip";

const ProcessBuilderFieldManualEntry: React.FC<ProcessBuilderFieldContentsProps<ManualEntryField>> = ({ field, stepIndex }) => {
  const { readOnly, handleUpdateFields, enablePreviewMode, handleUpdateFieldDataset, draftProcess, processBuilderOpen } =
    useContext(ProcessBuilderContext);
  const promptRef = useRef<HTMLDivElement>(null);

  // TEMPORARY
  // TODO: Remove this once we have dataset versions
  const [hasApprovedRevision, setHasApprovedRevision] = React.useState<boolean>(false);
  const loadRevisionHistory = async () => {
    if (draftProcess?.id) {
      const response = await fetchProcessRevisions(draftProcess.id);
      setHasApprovedRevision(response?.some((revision) => revision.approved) ?? false);
    }
  };

  useEffect(() => {
    loadRevisionHistory();
  }, [draftProcess?.id, processBuilderOpen]);

  // END TEMPORARY

  return (
    <div className="flex w-full flex-col gap-y-1">
      <div className="flex">
        {!field.is_optional && <div className="pr-1 text-rose-500">*</div>}
        <ContentEditable
          contentRef={promptRef}
          onInput={() => handleUpdateFields(stepIndex, [field.id], "prompt", promptRef.current?.innerText)}
          className="input-box w-full text-sm outline-0"
          placeholder="Prompt..."
          content={field.prompt}
          readOnly={readOnly}
        />
      </div>
      <div className="border-serial-palette-200 text-serial-palette-400 flex  w-full shrink-0 justify-between rounded border bg-white px-3 py-2 font-light italic leading-5">
        {field.data_validation == "STRING" && <div>abc</div>}
        {field.data_validation == "INTEGER" && <div>#</div>}
        {field.data_validation == "NUMBER" && <div>#.##</div>}
        {!field.data_validation && <div>abc123</div>}
      </div>
      {!enablePreviewMode && field.dataset?.data_type === DataType.ParametricQuantitative && (
        <div className="flex w-full justify-between gap-x-2 pt-2">
          {/* Unit */}
          {hasApprovedRevision ? (
            <Tooltip content="This field is locked because it has an approved revision." position="bottom" size="sm">
              <div className="flex flex-col">
                <label className="mb-1 block text-xs font-medium">Unit</label>
                <input
                  id="unit"
                  className="form-input disabled:bg-serial-palette-100 w-full flex-1 focus:placeholder-opacity-0 disabled:cursor-not-allowed"
                  type="text"
                  placeholder="Unit"
                  value={field.dataset?.unit ?? ""}
                  onChange={(event) => handleUpdateFieldDataset(stepIndex, field.id, "unit", event.target.value)}
                  disabled={readOnly || hasApprovedRevision}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex flex-col">
              <label className="mb-1 block text-xs font-medium">Unit</label>
              <input
                id="unit"
                className="form-input disabled:bg-serial-palette-100 w-full flex-1 focus:placeholder-opacity-0 disabled:cursor-not-allowed"
                type="text"
                placeholder="Unit"
                value={field.dataset?.unit ?? ""}
                onChange={(event) => handleUpdateFieldDataset(stepIndex, field.id, "unit", event.target.value)}
                disabled={readOnly || hasApprovedRevision}
              />
            </div>
          )}

          {/* LSL */}
          {hasApprovedRevision ? (
            <Tooltip content="This field is locked because it has an approved revision." position="bottom" size="sm">
              <div className="flex flex-col">
                <label className="mb-1 block text-xs font-medium">LSL</label>
                <input
                  id="lsl"
                  className="form-input disabled:bg-serial-palette-100 w-full flex-1 focus:placeholder-opacity-0 disabled:cursor-not-allowed"
                  type="text"
                  placeholder="LSL"
                  value={field.dataset?.lsl ?? ""}
                  onChange={(event) =>
                    handleUpdateFieldDataset(stepIndex, field.id, "lsl", event.target.value === "" ? null : event.target.value)
                  }
                  disabled={readOnly || hasApprovedRevision}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex flex-col">
              <label className="mb-1 block text-xs font-medium">LSL</label>
              <input
                id="lsl"
                className="form-input disabled:bg-serial-palette-100 w-full flex-1 focus:placeholder-opacity-0 disabled:cursor-not-allowed"
                type="text"
                placeholder="LSL"
                value={field.dataset?.lsl ?? ""}
                onChange={(event) =>
                  handleUpdateFieldDataset(stepIndex, field.id, "lsl", event.target.value === "" ? null : event.target.value)
                }
                disabled={readOnly || hasApprovedRevision}
              />
            </div>
          )}

          {/* USL */}
          {hasApprovedRevision ? (
            <Tooltip content="This field is locked because it has an approved revision." position="bottom" size="sm">
              <div className="flex flex-col">
                <label className="mb-1 block text-xs font-medium">USL</label>
                <input
                  id="usl"
                  className="form-input disabled:bg-serial-palette-100 w-full flex-1 focus:placeholder-opacity-0 disabled:cursor-not-allowed"
                  type="text"
                  placeholder="USL"
                  value={field.dataset?.usl ?? ""}
                  onChange={(event) =>
                    handleUpdateFieldDataset(stepIndex, field.id, "usl", event.target.value === "" ? null : event.target.value)
                  }
                  disabled={readOnly || hasApprovedRevision}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex flex-col">
              <label className="mb-1 block text-xs font-medium">USL</label>
              <input
                id="usl"
                className="form-input disabled:bg-serial-palette-100 w-full flex-1 focus:placeholder-opacity-0 disabled:cursor-not-allowed"
                type="text"
                placeholder="USL"
                value={field.dataset?.usl ?? ""}
                onChange={(event) =>
                  handleUpdateFieldDataset(stepIndex, field.id, "usl", event.target.value === "" ? null : event.target.value)
                }
                disabled={readOnly || hasApprovedRevision}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProcessBuilderFieldManualEntry;
