import { getSupabase } from "@shared/connections/supabaseAuth";
import {
  Company,
  ComponentProcessLink,
  Process,
  ProcessEntry,
  Station,
  StationProcessLink,
  UniqueIdentifier,
  UniqueIdentifierLink,
} from "@shared/types/databaseTypes";
import { DraftSubmissionData, IdentifierValidationDatapoint, BatchValidationData, IdentifierValidationResultType, FieldValidationResult } from "../types";
import { ComponentType, StationType, UniqueIdentifierStatus } from "@shared/types/databaseEnums";

// This function uses regular expressions to check if the string contains only digits (0-9) or negative digits.
// If the string passes this test, the function returns true; otherwise, it returns false.
const stringIsInteger = (str: string) => {
  return /^\d+$/.test(str) || /^-\d+$/.test(str);
};

// This regular expression pattern checks for the following conditions:
//  - The string may start with an optional "-" sign to allow negative numbers.
//  - The string must contain at least one digit.
//  - The string may contain an optional decimal point (escaped using a backslash).
//  - If the string contains a decimal point, it must be followed by one or more digits (which are optional, in case of integers).
// Note that this regular expression pattern does not handle scientific notation
function stringIsNumeric(str: string) {
  return /^-?\d+\.?\d*$/.test(str);
}

interface IdentifierValidationSupplementaryData {
  componentInstance: UniqueIdentifier | null;
  componentProcessLinks: ComponentProcessLink[];
  stationProcessLink: StationProcessLink | null;
  processEntries: ProcessEntry[];
  dependentProcesses: Process[];
}

export const fetchIdentifierValidationSupplementaryData = async (
  identifiers: string[],
  process: Process,
  stationId: string | undefined,
  abortSignal: AbortSignal,
): Promise<{ [identifier: string]: IdentifierValidationSupplementaryData }> => {
  const supabase = getSupabase();
  if (identifiers.length === 0) return {};
  const { data: componentInstanceData, error: componentInstanceError } = await supabase
    .from("unique_identifiers")
    .select("*, component:component_id(*)")
    .in("identifier", identifiers)
    .abortSignal(abortSignal)
    .returns<UniqueIdentifier[]>();
  if (componentInstanceError) {
    console.log("Error fetching component instances", componentInstanceError);
    return {};
  }
  if (!componentInstanceData || componentInstanceData.length === 0)
    return identifiers.reduce<{ [identifier: string]: IdentifierValidationSupplementaryData }>((acc, identifier) => {
      acc[identifier] = {
        componentInstance: null,
        componentProcessLinks: [],
        stationProcessLink: null,
        processEntries: [],
        dependentProcesses: [],
      };
      return acc;
    }, {});
  const componentId = componentInstanceData?.[0]?.component_id ?? "";
  const componentInstanceIds = componentInstanceData.map((componentInstance) => componentInstance.id);
  const [componentProcessLinksResponse, stationProcessLinkResponse, processEntries, dependentProcesses] = await Promise.all([
    supabase
      .from("component_process_links")
      .select("*, process:processes(*)")
      .eq("component_id", componentId)
      .eq("is_active", true)
      .order("order", { ascending: true })
      .abortSignal(abortSignal)
      .returns<ComponentProcessLink[]>(),
    supabase
      .from("station_process_links")
      .select("*")
      .eq("station_id", stationId)
      .eq("process_id", process.id)
      .eq("component_id", componentId)
      .abortSignal(abortSignal)
      .returns<StationProcessLink[]>(),
    supabase
      .from("process_entries")
      .select("*")
      .in("unique_identifier_id", componentInstanceIds)
      .order("timestamp", { ascending: true })
      .abortSignal(abortSignal)
      .returns<ProcessEntry[]>(),
    supabase
      .from("processes")
      .select("*")
      .eq("is_latest_revision", true)
      .in("id", process.dependent_process_ids)
      .abortSignal(abortSignal)
      .returns<Process[]>(),
  ]);

  // parse the output
  const { data: componentProcessLinksData, error: componentProcessLinksError } = componentProcessLinksResponse;
  if (componentProcessLinksError) {
    console.error("Error fetching component process links", componentProcessLinksError);
  }
  const { data: stationProcessLinkData, error: stationProcessLinksError } = stationProcessLinkResponse;
  // It's possible for the stationId to be empty, so we only log an error if the stationId is not empty
  if (stationProcessLinksError && stationId !== undefined) {
    console.error("Error fetching station process links", stationProcessLinksError);
  }
  const { data: processEntriesData, error: processEntriesError } = processEntries;
  if (processEntriesError) {
    console.error("Error fetching process entries", processEntriesError);
  }
  const { data: dependentProcessesData, error: dependentProcessesError } = dependentProcesses;
  if (dependentProcessesError) {
    console.error("Error fetching dependent processes", dependentProcessesError);
  }

  // filter dependencies to return only those who's order is less than that the current process
  // this can happen if someone changes the order of the processes after the dependency has has been created
  const currProcessLink = componentProcessLinksData?.find((componentProcessLink) => componentProcessLink.process_id === process.id);
  const filteredDependentProcessesData = dependentProcessesData?.filter((dependentProcess) => {
    const dependentProcessLink = componentProcessLinksData?.find(
      (componentProcessLink) => componentProcessLink.process_id === dependentProcess.id,
    );
    if (!dependentProcessLink?.order || !currProcessLink?.order) return false;
    return dependentProcessLink.order < currProcessLink.order;
  });

  const validationDataMap = identifiers.reduce<{ [identifier: string]: IdentifierValidationSupplementaryData }>((acc, identifier) => {
    const componentInstance = componentInstanceData.find((ci) => ci.identifier === identifier) ?? null;
    const componentProcessLinks = componentProcessLinksData ?? [];
    const stationProcessLink = stationProcessLinkData?.[0] ?? null;
    const processEntries = processEntriesData?.filter((entry) => entry.unique_identifier_id === componentInstance?.id) ?? [];
    const dependentProcesses = filteredDependentProcessesData ?? [];
    acc[identifier] = { componentInstance, componentProcessLinks, stationProcessLink, processEntries, dependentProcesses };
    return acc;
  }, {});
  return validationDataMap;
};

const fetchLinkValidationSupplementaryData = async (identifier: string, abortSignal: AbortSignal) => {
  const supabase = getSupabase();
  const { data: linkedComponentInstances, error: linkedComponentInstancesError } = await supabase
    .from("unique_identifiers")
    .select("*, component:component_id(*)")
    .eq("identifier", identifier)
    .abortSignal(abortSignal)
    .returns<UniqueIdentifier[]>();
  if (linkedComponentInstancesError) {
    console.log("Error fetching linked component instances", linkedComponentInstancesError);
  }
  if (!linkedComponentInstances || linkedComponentInstances.length === 0)
    return {
      linkedComponentInstance: null,
      linkedComponentInstanceParents: [],
    };
  const { data: linkedComponentInstanceParents, error: linkedComponentInstanceParentsError } = await supabase
    .from("unique_identifier_links")
    .select("*, unique_identifiers:unique_identifier_id(*))")
    .eq("has_child_of_id", linkedComponentInstances?.[0]?.id ?? "")
    .eq("is_active", true)
    .abortSignal(abortSignal)
    .returns<UniqueIdentifierLink[]>();
  if (linkedComponentInstanceParentsError) {
    console.log("Error fetching linked component instance parents", linkedComponentInstanceParentsError);
  }
  return {
    linkedComponentInstance: linkedComponentInstances?.[0] ?? null,
    linkedComponentInstanceParents: linkedComponentInstanceParents ?? [],
  };
};

// Function to handle validation of all field types. Returns and object with {isValid: bool, message: string}. If isValid is true but there's a non null message then the message is a warning
export const validateFieldData = async (
  company: Company,
  process: Process,
  fieldId: string,
  draftSubmissionData: DraftSubmissionData,
  identifiers: string[],
  abortSignal: AbortSignal,
): Promise<FieldValidationResult> => {
  // Get the field to validate
  const allFields = process.process_steps?.flatMap((step) => step.fields ?? []) ?? [];
  const field = allFields.find((field) => field.id === fieldId);
  if (!field) {
    return { isValid: false, message: "Field not found in process" };
  }

  const enteredData = draftSubmissionData[fieldId];

  // --- PRELIMINARY CHECK #1 --- : Check if the field has no data
  if (enteredData === undefined || enteredData === null || Object.values(enteredData).length === 0) {
    if (field.is_optional) {
      // if option and no data then it's okay
      return { isValid: true };
    }
    return { isValid: false, message: `"${field.prompt}" is a required field` };
  }

  switch (field.type) {
    case "LINK":
      const { linkedComponentInstance, linkedComponentInstanceParents } = await fetchLinkValidationSupplementaryData(
        enteredData.linkedIdentifier ?? "",
        abortSignal,
      );
      // --- CHECK #1 --- : Check that the linked identifier exists
      if (linkedComponentInstance === null) {
        return {
          isValid: false,
          message: "This SN or LOT code cannot be found. Linked SN / LOT code must be generated before it is linked.",
        };
      }
      // --- CHECK #2 --- : Check that the component id of the linked identifier matches the component id of the field
      if (linkedComponentInstance.component_id !== field.dataset?.child_component_id) {
        return {
          isValid: false,
          message: `${linkedComponentInstance.component?.name ?? "This"} is not the correct component for this link`,
        };
      }
      // --- CHECK #3 --- : Check that the linked identifier is not DEFECTIVE unless the company config allows it
      if (linkedComponentInstance.status === UniqueIdentifierStatus.Defective && company.config?.allow_defective_linking !== true) {
        return { isValid: false, message: "This component is defective. It cannot be used in this assembly" };
      }
      // --- CHECK #4 --- : Check that the linked identifier is COMPLETE or WIP if the company config allows it
      if (
        (linkedComponentInstance.status === UniqueIdentifierStatus.Wip ||
          linkedComponentInstance.status === UniqueIdentifierStatus.Planned) &&
        company.config?.allow_wip_linking !== true
      ) {
        return {
          isValid: false,
          message: "This component isn't complete yet. It's mandatory processes must be finished before it can used in this assembly",
        };
      }
      // --- CHECK #5 --- : Check that the same SN identifier has not been entered in more than one link field
      const duplicateLinkedIdentifiers =
        Object.values(draftSubmissionData).filter((data) => data?.linkedIdentifier === enteredData?.linkedIdentifier).length > 1;
      if (duplicateLinkedIdentifiers && linkedComponentInstance.component?.component_type === ComponentType.Sn) {
        return { isValid: false, message: "This serial number has been entered more than once" };
      }
      // --- CHECK #6 --- : Warn user if the same LOT identifier has been entered in more than one link field
      if (duplicateLinkedIdentifiers && linkedComponentInstance.component?.component_type === ComponentType.Lot) {
        return {
          isValid: true,
          message: "This lot code has been entered more than once. Are you sure you intended to link the same lot twice?",
        };
      }
      const allParentsAreTheTargetIdentifier = linkedComponentInstanceParents.every(
        (parentLink) => parentLink?.unique_identifiers?.identifier === identifiers[0],
      );
      // --- CHECK #7 --- : Warn user if the linked identifier is of type SN and has already been linked to another identifier (which is not the current identifier)
      if (linkedComponentInstanceParents.length > 0 && linkedComponentInstance.component?.component_type === ComponentType.Sn) {
        // remove duplicates from the list of parent identifiers
        const parentIdentifiers = [
          ...new Set(linkedComponentInstanceParents.map((parentLink) => parentLink?.unique_identifiers?.identifier)),
        ].join(", ");
        if (!allParentsAreTheTargetIdentifier) {
          return {
            isValid: true,
            message: `Warning! This component has already been linked to another SN (${parentIdentifiers}). The previous link will be removed.`,
          };
        }
        // Per SER-1144 we're not warning users if the linking SN is already linked to the current SN
      }
      break;

    case "IMAGE":
      // --- CHECK #1 --- : If it uses the upload method, check if the file type is an image
      if (
        field.method == "UPLOAD" &&
        enteredData.fileInfo?.type &&
        !["jpeg", "jpg", "png", "tiff", "tif", "heic"].includes(enteredData.fileInfo.type.split("/")[1]?.toLowerCase())
      ) {
        return { isValid: false, message: "Incorrect file format. Must be .jpeg, .png, .heic, .tif or .tiff" };
      }

      // --- CHECK #2 --- : If the file is included but there's no image data
      if (
        enteredData.fileId &&
        !enteredData.fileInfo
      ) {
        return { isValid: false, message: "Image file upload is pending.", showInvalidAsWarning: true };
      }
      break;

    case "FILE":
      // --- CHECK #1 --- : If the file is included but there's no file data
      if (
        enteredData.fileId &&
        !enteredData.fileInfo
      ) {
        return { isValid: false, message: "File upload is pending.", showInvalidAsWarning: true };
      }
      // Allow any file type
      break;

    case "MANUAL_ENTRY":
      // --- CHECK #1 --- : Check if the data matches the desired input type (integer)
      if (field.data_validation == "INTEGER" && !stringIsInteger(String(enteredData.value))) {
        return { isValid: false, message: "Entry must be an integer." };
      }
      // --- CHECK #2 --- : Check if the data matches the desired input type (number)
      if (field.data_validation == "NUMBER" && !stringIsNumeric(String(enteredData.value))) {
        return { isValid: false, message: "Entry must be a number." };
      }
      break;

    case "PASSFAIL":
      // No ambiguity here
      break;

    default:
      break;
  }
  return { isValid: true };
};

export const validateIdentifiers = async (
  process: Process,
  station: Station | null,
  identifiers: string[],
  abortSignal: AbortSignal,
): Promise<BatchValidationData> => {
  const supplementaryDataMap = await fetchIdentifierValidationSupplementaryData(identifiers, process, station?.id, abortSignal);

  let validationResult: { [validationResultType in IdentifierValidationResultType]?: IdentifierValidationDatapoint[] } = {};

  identifiers.forEach((identifier) => {
    const supplementaryData = supplementaryDataMap[identifier];
    if (!supplementaryData) {
      console.log(`Supplementary data not found for identifier ${identifier}`);
      return;
    }
    const result = performValidationChecks(identifier, supplementaryData, process, station);
    const groupKey = result.result ?? IdentifierValidationResultType.Ok;
    if (!validationResult[groupKey]) {
      validationResult[groupKey] = [];
    }
    validationResult[groupKey]!.push(result);
  });

  return validationResult;
};

function performValidationChecks(
  identifier: string,
  validationData: {
    componentInstance: UniqueIdentifier | null;
    componentProcessLinks: ComponentProcessLink[];
    stationProcessLink: StationProcessLink | null;
    processEntries: ProcessEntry[];
    dependentProcesses: Process[];
  },
  process: Process,
  station: Station | null,
): IdentifierValidationDatapoint {
  const { componentInstance, componentProcessLinks, stationProcessLink, processEntries, dependentProcesses } = validationData;

  const latestEntry = processEntries
    .filter((entry) => entry.process_id === process.id)
    .sort((a, b) => {
      const timestampA = Number(new Date(a.timestamp));
      const timestampB = Number(new Date(b.timestamp));
      return timestampB - timestampA;
    })[0];

  // Check #1: Non-existent component instance
  if (!componentInstance) {
    return {
      identifier,
      isValid: false,
      message: `"${identifier}" cannot be found. Click "Initialize" to create a new unit`,
      result: IdentifierValidationResultType.NeedInitialization,
    };
  }

  // Check #2: Component not linked to the given process
  if (!componentProcessLinks.some((link) => link.process_id === process.id && link.component_id === componentInstance.component_id)) {
    return {
      identifier,
      isValid: false,
      message: `The ${componentInstance.component?.name} component is not linked to this process`,
      result: IdentifierValidationResultType.DifferentComponent,
    };
  }

  // Check #3: Station capability
  if (station && station.type === StationType.Dedicated && !stationProcessLink) {
    return {
      identifier,
      isValid: false,
      message: "This station is not able to run this component / process",
      result: IdentifierValidationResultType.InvalidStation,
    };
  }

  // Check #4: Dependent processes completion
  const missingDependentProcesses = dependentProcesses.filter(
    (dp) => !processEntries.some((pe) => pe.process_id === dp.id && pe.is_pass !== false && !pe.upload_error),
  );
  if (missingDependentProcesses.length > 0) {
    return {
      identifier,
      isValid: false,
      message:
        "This unit has not completed all prior dependent processes. Unit must first complete: " +
        missingDependentProcesses.map((p) => p.name).join(", "),
      result: IdentifierValidationResultType.MissingPriorProcess,
    };
  }

  // Check #5: Component instance is defective but it's a retest
  const isRetest = processEntries.some((entry) => entry.process_id === process.id && entry.upload_error === false);
  if (componentInstance.status === UniqueIdentifierStatus.Defective && isRetest) {
    return {
      identifier,
      isValid: true,
      message:
        "This component failed prior processes. It may rerun this process but must pass all prior processes before proceeding to new ones",
      result: IdentifierValidationResultType.DefectiveOnRetestProcess,
    };
  }

  // Check #6: Component instance is defective and is not a retest
  if (componentInstance.status === UniqueIdentifierStatus.Defective && !isRetest) {
    return {
      identifier,
      isValid: false,
      message: "This component failed prior process. It must pass all previous processes before proceeding",
      result: IdentifierValidationResultType.DefectiveOnNewProcess,
    };
  }

  // Check #7: Process resubmission with previous upload errors
  if (latestEntry && latestEntry.upload_error === true) {
    return {
      identifier,
      isValid: true,
      message: `${identifier} did not receive data properly from ${process.name} the last time data was uploaded. Please try submitting again.`,
      result: IdentifierValidationResultType.UploadErrorWarning,
    };
  }

  // Check #8: Process already submitted successfully
  if (latestEntry && !latestEntry.is_pass !== true && !latestEntry.upload_error) {
    return {
      identifier,
      isValid: true,
      message: `${identifier} has already completed and passed ${process.name}. Are you sure you want to resubmit the data?`,
      result: IdentifierValidationResultType.ResubmissionWarning,
    };
  }

  // If none of the checks fail
  return { identifier, isValid: true, message: "passed all validation checks", result: IdentifierValidationResultType.Ok };
}
