import React, { useContext } from "react";
import ComponentTableItem from "./ComponentTableItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Loader } from "@shared/components/Loader";
import moment from "moment";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

const csvColumnKeyMap = {
  total: "Total Quantity",
  linked: "Linked",
  unlinked: "Unlinked",
  wip: "WIP",
  complete: "Complete",
  defective: "Defective",
};

const generateCsv = (components) => {
  let csvStr = ["Component", "Part Number", ...Object.values(csvColumnKeyMap)].join(",") + "\n";
  components.forEach((component) => {
    const countsData = [component.name, "-- All Part Numbers --", ...Object.keys(csvColumnKeyMap).map((key) => component.counts[key])].join(
      ",",
    );
    csvStr += countsData + "\n";
    component.part_number_counts?.forEach((partNumber) => {
      const pnData = ["-", partNumber.pn, ...Object.keys(csvColumnKeyMap).map((key) => partNumber[key])].join(",");
      csvStr += pnData + "\n";
    });
  });
  return csvStr;
};

function ComponentTable({ components }) {
  const db = useSelector((state) => state.db);
  const observe = useContext(ObservabilityContext);

  const handleDownloadCsv = () => {
    const csv = generateCsv(components);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = `Unit Counts ${moment().format("YYYY-MM-DD_HH-mm-ss")}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="relative overflow-hidden rounded-md border bg-white">
      <header className="flex h-14 items-center justify-between pl-5 pr-3">
        <h2 className="text-serial-palette-800 font-semibold">
          Components <span className="text-serial-palette-400 font-medium">{components.length}</span>
        </h2>
        <button
          className="btn serial-btn-dark h-9"
          onClick={() => (handleDownloadCsv(), observe.track("Download Data", { "UI Source": "Components List" }))}
          disabled={!db.isLoaded}
        >
          {db.isLoaded ? (
            <>
              <FontAwesomeIcon icon={faDownload} />
              <span className="ml-2">Download CSV</span>
            </>
          ) : (
            <Loader styleOverride="w-[16px] h-[16px]" />
          )}
        </button>
      </header>
      <div>
        {/* Table */}
        <table className="w-full table-auto">
          {/* Table header */}
          <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b border-t text-xs font-semibold uppercase">
            <tr>
              <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="text-left font-semibold">Component</div>
              </th>
              <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="w-[75px] text-center font-semibold">Total Quantity</div>
              </th>
              <th className="flex justify-end whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="w-[75px] text-center font-semibold">Linked</div>
              </th>
              <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="w-[75px] text-center font-semibold">Unlinked</div>
              </th>
              <th className="flex justify-end whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="w-[75px] text-center font-semibold text-sky-600">WIP</div>
              </th>
              <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="min-w-[75px] text-center font-semibold text-green-600">Complete</div>
              </th>
              <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="w-[75px] text-center font-semibold text-red-600">Defective</div>
              </th>
              <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                <div className="text-center font-semibold">Details</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="divide-serial-palette-200 divide-y text-sm">
            {components.map((component) => {
              return <ComponentTableItem key={component.id} component={component} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ComponentTable;
