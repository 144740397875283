import { useMemo } from "react";
import { MeasureKey, MeasureType } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { Component, Dataset, Process } from "@shared/types/databaseTypes";
import useMeasures from "../MeasuresProvider";
import { getComponentDescendents } from "../helpers/availableKeys";
import { ComponentType } from "@shared/types/databaseEnums";
import { useTranslation } from "react-i18next";

export interface MeasureKeyWithReferences extends MeasureKey {
  component?: Component;
  process?: Process;
  dataset?: Dataset;
  label: string;
  componentOrder: number;
  processOrder: number;
  isActive: boolean;
}

const useMeasureKeysWithReferences = (keys: MeasureKey[]): MeasureKeyWithReferences[] => {
  const { componentId: rootComponentId } = useMeasures();
  const components = useSelector((state: RootState) => state.db.components);
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const processes = useSelector((state: RootState) => state.db.processes);
  const componentLinks = useSelector((state: RootState) => state.db.componentLinks);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const { t } = useTranslation();

  const descendantComponents = useMemo(() => {
    if (!rootComponentId) return [];
    return getComponentDescendents(componentLinks, rootComponentId);
  }, [componentLinks, rootComponentId]);

  return useMemo(() => {
    return keys.map((key) => {
      const component = components.find((component) => component.id === key.component_id);
      const process = processes.find((process) => process.id === key.process_id);
      const dataset = datasets.find((dataset) => dataset.id === key.dataset_id);
      const componentOrder = descendantComponents.find((descendant) => descendant.id === key.component_id)?.ancestryLevel ?? 0;

      // Set name, processOrder, and isActive based on key type
      let label: string;
      let processOrder: number;
      let isActive: boolean;
      switch (key.type) {
        case MeasureType.Station:
          label = "Station";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.Operator:
          label = "Operator";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.Timestamp:
          label = "Timestamp";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.CycleTime:
          label = "Cycle Time";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.ProcessResult:
          label = `${process?.name} Result`;
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.PartNumber:
          label = "Part Number";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.Status:
          label = "Status";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.LinkStatus:
          label = "Link Status";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.Identifier:
          label = component?.component_type === ComponentType.Lot ? "Lot Code" : "Serial Number";
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.WorkOrder:
          label = t("workOrder");
          processOrder = -Infinity;
          isActive = true;
          break;
        case MeasureType.WorkOrderPhase:
          label = `${t("workOrder")} Phase`;
          processOrder = -Infinity;
          isActive = true;
          break;
        default:
          label = dataset?.name ?? "";
          processOrder =
            componentProcessLinks.find((link) => link.component_id === key.component_id && link.process_id === key.process_id)?.order ?? 0;
          isActive =
            (componentProcessLinks.find((link) => link.component_id === key.component_id && link.process_id === key.process_id)
              ?.is_active &&
              dataset?.is_active) ??
            false;
      }

      return {
        ...key,
        component,
        process,
        dataset,
        label,
        processOrder,
        componentOrder,
        isActive,
      };
    });
  }, [keys, components, datasets, processes, componentProcessLinks, descendantComponents]);
};

export default useMeasureKeysWithReferences;
