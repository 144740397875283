import { Operator, User } from "@shared/types/databaseTypes";
import { useContext, useEffect, useState } from "react";
import { fetchOperatorByPin, fetchOperatorByUserId } from "../connections/supabase";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";

const OPERATOR_PIN_TIMEOUT = 300000; // 5 minutes in milliseconds

interface UseCurrentOperatorProps {
  enablePins: boolean;
  user: User | undefined;
  setOperatorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UseCurrentOperatorReturn {
  operator: Operator | null;
  handleLoadOperatorByPin: (pin: string) => Promise<void>;
}

const useCurrentOperator = ({ enablePins, user, setOperatorModalOpen }: UseCurrentOperatorProps): UseCurrentOperatorReturn => {
  const [operator, setOperator] = useState<Operator | null>(null);

  const { triggerToast } = useContext(ToastContext);

  const loadOperatorByCurrentUser = async () => {
    if (!user) return;
    const { data, error } = await fetchOperatorByUserId(user.supabase_uid);
    setOperator(data ?? null);
    if (error) {
      console.error("Could not load operator: ", error);
      setOperator(null);
    }
  };

  const handleLoadOperatorByPin = async (pin: string) => {
    const { data, error } = await fetchOperatorByPin(pin);
    if (error) {
      triggerToast(ToastType.Error, "Error Loading Operator:", error);
      setOperator(null);
      return;
    }
    if (data) {
      setOperator(data);
      setOperatorModalOpen(false);
    }
  };

  useEffect(() => {
    if (!user) return;
    if (enablePins) {
      setOperatorModalOpen(true);
    } else {
      loadOperatorByCurrentUser();
    }
  }, [enablePins, user?.supabase_uid]);

  // timer to trigger operator modal after a period of inactivity
  useEffect(() => {
    if (!enablePins) return;
    let timeout: NodeJS.Timeout;
    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setOperatorModalOpen(true);
        setOperator(null);
      }, OPERATOR_PIN_TIMEOUT);
    };
    resetTimeout();
    const events: string[] = ["mousemove", "keydown"];
    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
    };
  }, [enablePins]);

  return { operator, handleLoadOperatorByPin };
};

export default useCurrentOperator;
