import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useEffect, useState } from "react";
import { deactivateAlertsById, fetchAlerts } from "@shared/connections/supabaseAlerts";
import { useNavigate } from "react-router-dom";
import Banner2 from "@shared/components/Banner2";
import { AlertType } from "@shared/types/databaseEnums";
import { Alert } from "@shared/types/databaseTypes";
import { Loader } from "@shared/components/Loader";

const HomeAlerts: React.FC = () => {
  const company = useSelector((state: RootState) => state.db.company);
  const [alerts, setAlerts] = useState<Alert[] | null>(null);
  const navigate = useNavigate();

  const handleDismissAlert = async (alertId: string) => {
    const confirmation = confirm("Are you sure you want to dismiss this alert?");
    if (confirmation) {
      await deactivateAlertsById(alertId);
      const alerts = await fetchAlerts();
      setAlerts(alerts);
    }
  };

  useEffect(() => {
    if (company !== null) {
      fetchAlerts().then((alerts) => setAlerts(alerts));
    }
  }, [company]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full justify-between gap-2 border-b p-3">
        <div className="flex min-w-0 flex-grow gap-2">
          <h2 className="text-serial-palette-800 truncate font-semibold">Alerts</h2>
          {!alerts && <Loader styleOverride="h-6 w-6" />}
        </div>
      </div>
      <div className="scrollbar-hide flex min-h-0 min-w-0 flex-grow flex-col gap-y-1.5 overflow-auto p-3">
        {alerts
          ?.filter((alert) => alert.is_active)
          .map((alert, index) => {
            let bannerType = "error";
            switch (alert.type) {
              case AlertType.Override:
                bannerType = "warning";
                break;
            }
            return (
              <div
                key={index}
                className="cursor-pointer text-left"
                onClick={() => {
                  if (alert.link) navigate(alert.link);
                }}
              >
                <Banner2 type={bannerType} open={true} clickXHandler={handleDismissAlert} id={alert.id}>
                  {alert.message}
                </Banner2>
              </div>
            );
          })}
        {alerts?.filter((alert) => alert.is_active).length === 0 && (
          <Banner2 type="success" open={true} hideX>
            You have no more active warnings or alerts. Have a great day!
          </Banner2>
        )}
      </div>
    </div>
  );
};

export default HomeAlerts;
