import Section from "./MeasuresGraphConfigSection";
import Select from "@shared/components/primitives/Select";
import { GraphType, graphTypeGroups } from "./types";
import { useMeasuresGraph } from "./MeasuresGraph";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonGroup from "@shared/components/primitives/ButtonGroup";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";

const MeasuresGraphConfigChartType = () => {
  const { graphType, handleSetGraphType } = useMeasuresGraph();

  const selectedGraphGroup = useMemo(() => {
    return graphTypeGroups.find((group) => graphType && group.graphTypes.includes(graphType));
  }, [graphType]);

  return (
    <Section.Root>
      <Section.Title>Chart Type</Section.Title>
      <Section.Option>
        <Section.Label>Base Chart</Section.Label>
        <Select.Root
          value={selectedGraphGroup?.graphTypes?.[0] ?? undefined}
          onValueChange={(type) => handleSetGraphType(type as GraphType)}
        >
          <Select.Trigger>{selectedGraphGroup && <Select.Value />}</Select.Trigger>
          <Select.Content>
            {graphTypeGroups.map((group, index) => (
              <Select.Item
                key={index}
                value={group.graphTypes[0]}
                disabled={!["line", "scatter"].includes(group.graphTypes[0])} // temporarily disable non line/scatter graphs until they are implemented
              >
                <FontAwesomeIcon icon={group.icon} className="mr-2" />
                <span>{group.label}</span>
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Section.Option>
      {graphType && (
        <Section.Option>
          <Section.Label>Variant</Section.Label>
          <ButtonGroup.Root>
            {selectedGraphGroup?.graphTypes.map((graphVariant, index) => (
              <ButtonGroup.Button
                key={index}
                size="sm"
                variant={graphType === graphVariant ? "default" : "outline"}
                onClick={() => handleSetGraphType(graphVariant)}
              >
                {screamingSnakeCaseToTitleCase(graphVariant)}
              </ButtonGroup.Button>
            ))}
          </ButtonGroup.Root>
        </Section.Option>
      )}
    </Section.Root>
  );
};
export default MeasuresGraphConfigChartType;
