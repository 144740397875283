import { useEffect, useMemo, useRef, useState } from "react";

import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import StationSelector from "./components/StationSelector";
import StationDetails from "./components/StationDetails";

import { getSupabase } from "@shared/connections/supabaseAuth";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { StationWithLinksAndProcessEntries } from "./types";
import { fetchStationLatestProcessEntries, fetchStationProcessLinks } from "./connections/supabase";
import { useLocation } from "react-router-dom";

function Stations() {
  const stations = useSelector((state: RootState) => state.db.stations);
  const dbIsLoaded = useSelector((state: RootState) => state.db.isLoaded);

  const [stationsWithReferences, setStationsWithReferences] = useState<StationWithLinksAndProcessEntries[]>(stations);
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const location = useLocation();

  const selectedStation = useMemo(() => {
    const stationId = location.pathname.split("/")[2];
    const station = stationsWithReferences.find((station) => station.id === stationId) ?? null;
    return station;
  }, [stationsWithReferences, location.pathname]);

  // load stations from supabase. also use this function to reload stations when a change is detected
  const loadData = async (): Promise<StationWithLinksAndProcessEntries[] | null> => {
    setIsLoading(true);
    const newStations = await Promise.all(
      stations.map(async (station) => {
        const stationProcessLinks = await fetchStationProcessLinks(station.id);
        const latestProcessEntries = await fetchStationLatestProcessEntries(station.id, 10);
        return {
          ...station,
          station_process_links: stationProcessLinks,
          latest_process_entries: latestProcessEntries,
        };
      }),
    );
    setStationsWithReferences(newStations);
    setLastUpdatedTimestamp(new Date());
    setIsLoading(false);
    return newStations;
  };

  const supabaseRef: any = useRef(null);

  // subscribe to DB changes on process_entries table
  useEffect(() => {
    if (dbIsLoaded) {
      if (!supabaseRef.current) {
        supabaseRef.current = getSupabase();
      }

      supabaseRef.current
        .channel("any")
        .on("postgres_changes", { event: "INSERT", schema: "public", table: "process_entries" }, loadData)
        .subscribe((status: string) => {
          if (status === "SUBSCRIBED") {
            console.log("Successfully subscribed to DB changes.");
          }
        });
    }
  }, [dbIsLoaded]);

  // load supplementary data when the db is loaded or stations change
  useEffect(() => {
    if (dbIsLoaded) loadData();
  }, [dbIsLoaded, stations]);

  const breadcrumbs = useMemo(() => {
    const updatedBreadcrumbs = [
      {
        label: "Stations",
        link: "/stations",
      },
    ];
    if (selectedStation) {
      updatedBreadcrumbs.push({
        label: selectedStation.name ?? "Station",
        link: `/stations/${selectedStation.id}`,
      });
    }
    return updatedBreadcrumbs;
  }, [selectedStation]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header breadcrumbs={breadcrumbs} />

        <main>
          {/* Timestamp */}
          <div className="flex w-full flex-col gap-2 p-2 md:flex-row md:gap-4 md:p-8">
            {/* Selector (left side) */}
            <div className="flex h-[50vh] w-full md:h-[calc(100vh-8rem)] md:w-1/4 md:min-w-[300px]">
              <StationSelector
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                stations={stationsWithReferences}
                selectedStation={selectedStation}
                reloadData={loadData}
              />
            </div>

            {/* Details (right side) */}
            <div className="flex h-full w-full md:h-[calc(100vh-8rem)] md:w-3/4">
              <StationDetails selectedStation={selectedStation} reloadData={loadData} lastUpdatedTimestamp={lastUpdatedTimestamp} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Stations;
