import { LabelElementType } from "@shared/types/databaseEnums";
import { Field, LabelFormat, LabelElement } from "@shared/types/databaseTypes";
import { LabelFormatWithContents } from "../types";
import { getSupabase } from "@shared/connections/supabaseAuth";

export const getLabels = async (componentId: string): Promise<LabelFormatWithContents[]> => {
  const supabase = getSupabase();
  const { data: labelFormats, error: labelFormatsError } = await supabase
    .from("label_formats")
    .select("*")
    .eq("component_id", componentId)
    .returns<LabelFormat[]>();

  if (labelFormatsError) {
    console.error(labelFormatsError);
    throw labelFormatsError;
  }
  const formatIds = labelFormats.map((format) => format.id);
  const { data: labelElements, error: labelElementsError } = await supabase
    .from("label_elements")
    .select("*")
    .in("label_format_id", formatIds)
    .returns<LabelElement[]>();

  if (labelElementsError) {
    console.error(labelElementsError);
    throw labelElementsError;
  }

  const usedLabelIds = (await getUsedLabelFields(formatIds, true)).map((field) => field.label_format_id);
  // Group label elements by their label format id
  const elementsByFormatId: Record<string, LabelElement[]> = labelElements.reduce(
    (acc, element) => {
      const formatId = element.label_format_id;
      if (!acc[formatId]) {
        acc[formatId] = [];
      }
      acc[formatId].push(element);
      return acc;
    },
    {} as Record<string, LabelElement[]>,
  );
  // Attach contents to each label format
  const labelFormatsWithContents = labelFormats.map((format) => ({
    ...format,
    contents: elementsByFormatId[format.id] || [],
    used: usedLabelIds.includes(format.id),
  }));

  return labelFormatsWithContents;
};

export const getUsedLabelFields = async (labelIds: string[], latestApprovedProcess?: boolean): Promise<Field[]> => {
  const supabase = getSupabase();
  const { data: labelFields, error: labelFieldError } = await supabase
    .from("fields")
    .select("*, process_step:process_step_id(*, process:processes(*))")
    .in("label_format_id", labelIds);
  if (latestApprovedProcess) {
    const { data: latestApprovedProcesses, error: latestApprovedProcessesError } = await supabase
      .from("processes")
      .select("id, revision, name")
      .or("approved.eq.true,is_latest_revision.eq.true");
    if (latestApprovedProcessesError) {
      console.error(latestApprovedProcessesError);
      throw latestApprovedProcessesError;
    }
    const latestApprovedProcessRevisionIds = latestApprovedProcesses.map((process) => ({
      id: process.id,
      revision: process.revision,
    }));
    return (
      labelFields?.filter((field) => {
        if (!field.process_step) {
          return false;
        }
        return latestApprovedProcessRevisionIds.some(
          (process) => process.id === field.process_step.process_id && process.revision === field.process_step.process.revision,
        );
      }) ?? []
    );
  }
  if (labelFieldError) {
    console.error(labelFieldError);
    throw labelFieldError;
  }
  return labelFields;
};

export const saveLabels = async (labels: LabelFormatWithContents[], componentId: string): Promise<void> => {
  const supabase = getSupabase();
  // Get current labels from the database
  const currentLabels = await getLabels(componentId);
  const labelElements = labels.flatMap((label) =>
    label.contents.map((content) => ({ ...content, label_format_id: label.id, company_id: label.company_id })),
  );
  // TODO: Remove this once we have a better way to handle images
  labelElements.forEach((element) => {
    if (element.type === LabelElementType.Image) {
      element.file_id = "00000000-0000-0000-0000-000000000000";
    }
  });
  const labelFormats = labels.map((label) => {
    const { contents, used, ...format } = label; // eslint-disable-line @typescript-eslint/no-unused-vars
    return format;
  });

  const { error: labelFormatsError } = await supabase.from("label_formats").upsert(labelFormats, { onConflict: "id" });
  if (labelFormatsError) {
    console.error(labelFormatsError);
    throw labelFormatsError;
  }

  const { error: labelElementsError } = await supabase.from("label_elements").upsert(labelElements, { onConflict: "id" });
  if (labelElementsError) {
    console.error(labelElementsError);
    throw labelElementsError;
  }
  const labelIds = labels.map((label) => label.id);
  const labelsToDelete = currentLabels.filter((label) => label.component_id === componentId && !labelIds.includes(label.id));
  const labelElementIds = labels.flatMap((label) => label.contents.map((content) => content.id));
  const elementsToDelete = currentLabels.flatMap((label) => label.contents.filter((content) => !labelElementIds.includes(content.id)));
  const fieldsOfLabelsToBeDeleted = await getUsedLabelFields(labelsToDelete.map((label) => label.id));
  if (fieldsOfLabelsToBeDeleted.length > 0) {
    throw new Error(
      `Cannot delete label because it is/was used in the following processes: ${fieldsOfLabelsToBeDeleted
        .map((field) => `${field.process_step?.process?.name} - ${field.process_step?.name}`)
        .join(", ")}`,
    );
  }

  const { error: deleteElementsError } = await supabase
    .from("label_elements")
    .delete()
    .in(
      "id",
      elementsToDelete.map((element) => element.id),
    );
  if (deleteElementsError) {
    console.error(deleteElementsError);
    throw deleteElementsError;
  }
  const { error: deleteLabelsError } = await supabase
    .from("label_formats")
    .delete()
    .in(
      "id",
      labelsToDelete.map((label) => label.id),
    );
  if (deleteLabelsError) {
    console.error(deleteLabelsError);
    throw deleteLabelsError;
  }
};
