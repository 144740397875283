import TypeTag from "@shared/components/TypeTag";
import React from "react";
import { Handle, Position } from "reactflow";
import { Component } from "@shared/types/databaseTypes";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { useNavigate } from "react-router-dom";

interface ParentNodeData {
  component: Component;
}

const ParentNode: React.FC<{ data: ParentNodeData }> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="border-serial-palette-300 component-node-drag-handle relative flex h-full w-full shrink-0 items-center gap-2 overflow-hidden rounded-md border-2 border-dashed bg-white px-2">
      <ComponentAvatar component={data.component} size="sm" />
      <div className="flex w-full flex-col overflow-hidden pb-1">
        <div
          className="cursor-pointer truncate text-sm font-bold hover:underline"
          onMouseDownCapture={() => navigate(`/component/${data.component.id}`)}
        >
          {data.component.name}
        </div>
        <TypeTag type={data.component.component_type} className="h-[14px] w-12 text-[8px]" />
      </div>
      <Handle type="target" position={Position.Right} isConnectable={false} />
    </div>
  );
};

export default ParentNode;
