import { ProcessType } from "@shared/types/databaseEnums";
import store from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { supabaseSingleOverride } from "@shared/connections/supabaseDataModifiers";
import { UniqueIdentifier } from "@shared/types/databaseTypes";

// ------------------- READ FUNCTIONS ------------------- //

export const fetchLatestProcessEntries = async (qty: number) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // First get the most recent unique identifiers to be updated
    const { data: uniqueIdentifiers, error: uniqueIdentifiersError } = (await supabase
      .from("unique_identifiers")
      .select("*, component:component_id(*)")
      .order("last_updated_at", { ascending: false })
      .neq("is_archived", true)
      .limit(qty)) as any;

    if (uniqueIdentifiersError) {
      console.error("Could not find uniqueIdentifiers for latest activity: ", uniqueIdentifiersError);
      return [];
    }

    // Attach the most recent process entry to each unique identifier
    let processEntries = await Promise.all(
      uniqueIdentifiers.map(async (identifier: UniqueIdentifier) => {
        const { data: processEntry, error: processEntryError } = supabaseSingleOverride(
          await supabase
            .from("process_entries")
            .select("*, processes:processes!inner(*)") // use !inner to make the .eq and .neq filters work on the joined tables
            .eq("unique_identifier_id", identifier.id)
            .eq("processes.type", ProcessType.Production)
            .order("timestamp", { ascending: false })
            .limit(1),
        ) as any;

        if (processEntryError) {
          console.error("Could not find processEntries for latest activity: ", processEntryError);
          return undefined;
        }
        return { ...processEntry, unique_identifiers: identifier, components: identifier.component } as any;
      }),
    );

    // Filter out any undefined entries
    processEntries = processEntries.filter((entry) => entry !== undefined);

    return processEntries;
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};
