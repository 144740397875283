import { CollectionType, PartNumber, ProcessStepWithReferences } from "@shared/types/databaseTypes";
import { useMemo } from "react";
import { FilterTestData } from "@shared/components/filters/types";
import { testFilter } from "@shared/components/filters/helpers";

const useFilteredSteps = (allProcessSteps: ProcessStepWithReferences[], partNumber: PartNumber | null): ProcessStepWithReferences[] => {
  const allFilterConditions = useMemo(() => {
    return allProcessSteps.map((step) => step.filter_conditions).flat();
  }, [allProcessSteps]);

  const allFilterConditionsTestData = useMemo(() => {
    const filterTestData: FilterTestData = {};
    if (!partNumber) return filterTestData;
    allFilterConditions.forEach((filterCondition) => {
      switch (filterCondition.type) {
        case CollectionType.PartNumber:
          filterTestData[filterCondition.id] = partNumber.id;
          break;
        case CollectionType.ParametricQualitative:
          filterTestData[filterCondition.id] = partNumber.metadata[filterCondition.dataset_id as string]?.value;
          break;
        case CollectionType.ParametricQuantitative:
          filterTestData[filterCondition.id] = partNumber.metadata[filterCondition.dataset_id as string]?.value;
          break;
        default:
          break;
      }
    });
    return filterTestData;
  }, [allFilterConditions, partNumber]);

  const filteredSteps = useMemo(() => {
    if (!partNumber || allFilterConditions.length === 0) return allProcessSteps.filter((step) => !step.is_hidden);
    const filteredSteps = allProcessSteps.filter((step) => {
      return testFilter(step.filter_conditions, step.filter_join_operator, allFilterConditionsTestData) && !step.is_hidden;
    });
    return filteredSteps;
  }, [allProcessSteps, allFilterConditions, allFilterConditionsTestData, partNumber]);

  return filteredSteps;
};

export default useFilteredSteps;
