import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import Modal from "@shared/components/primitives/Modal";
import { ReportTemplate } from "@shared/types/databaseTypes";
import Button from "@shared/components/primitives/Button";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { TextInput } from "@shared/components/primitives/Input";
import { TextArea } from "@shared/components/primitives/TextArea";
import Switch from "@shared/components/primitives/Switch";
import Combobox from "@shared/components/primitives/Combobox";
import UserAvatar from "@shared/components/primitives/UserAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "../Toast";
import { deleteReportTemplate, upsertReportTemplate } from "@shared/connections/supabaseReportTemplates";

const ReportTemplateEditModal = ({
  reportTemplate,
  trigger,
  title,
  onSave,
  open: openControlled,
  setOpen: setOpenControlled,
}: {
  reportTemplate: ReportTemplate;
  trigger?: React.ReactElement;
  title: string;
  onSave?: () => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}) => {
  const [openUncontrolled, setOpenUncontrolled] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [tempReportTemplate, setTempReportTemplate] = useState<ReportTemplate>(reportTemplate);
  const users = useSelector((state: RootState) => state.db.users);
  const company = useSelector((state: RootState) => state.db.company);
  const currentUser = useCurrentUser();
  const { triggerToast } = useContext(ToastContext);

  const open = openControlled ?? openUncontrolled;
  const setOpen = setOpenControlled ?? setOpenUncontrolled;

  const sharedWithUserIds = useMemo(() => {
    return [
      tempReportTemplate?.created_by,
      ...Object.entries(tempReportTemplate?.shared_with ?? {}).map(([userId, isShared]) => {
        return isShared ? userId : null;
      }),
    ].filter((userId) => userId !== null && userId !== undefined) as string[];
  }, [tempReportTemplate]);

  const currUserIsOwner = useMemo(() => {
    return tempReportTemplate?.created_by === currentUser?.supabase_uid;
  }, [tempReportTemplate, currentUser]);

  useEffect(() => {
    setTempReportTemplate({ ...reportTemplate });
  }, [reportTemplate]);

  const handleSave = async () => {
    setSaveIsLoading(true);
    const { error } = await upsertReportTemplate(tempReportTemplate);
    if (error) {
      triggerToast(ToastType.Error, "Failed to save report template", error);
    }
    setOpen(false);
    setSaveIsLoading(false);
    if (onSave) onSave();
  };

  const handleDelete = async () => {
    setDeleteIsLoading(true);
    const { error } = await deleteReportTemplate(tempReportTemplate.id);
    if (error) {
      triggerToast(ToastType.Error, "Failed to delete report template", error);
    }
    setOpen(false);
    setDeleteIsLoading(false);
  };

  return (
    <Modal.Root open={open} onOpenChange={(open) => setOpen(open)}>
      {trigger && (
        <Modal.Trigger
          asChild
          onMouseDown={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {trigger}
        </Modal.Trigger>
      )}
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Modal.HeaderClose />
        </Modal.Header>
        <Modal.Main className="flex w-full flex-col gap-4 p-5">
          {currUserIsOwner && (
            <div className="flex flex-col gap-y-1">
              <label className="text-sm font-medium">
                Name <span className="text-red-600">*</span>
              </label>
              <TextInput
                onValueChange={(value) => setTempReportTemplate({ ...tempReportTemplate, name: value })}
                value={tempReportTemplate.name}
                placeholder="Report Name"
              />
            </div>
          )}

          {currUserIsOwner && (
            <div className="flex flex-col gap-y-1">
              <label className="text-sm font-medium">Description</label>
              <TextArea
                onValueChange={(value) => setTempReportTemplate({ ...tempReportTemplate, description: value })}
                value={tempReportTemplate.description ?? ""}
                placeholder="Description"
              />
            </div>
          )}

          {currUserIsOwner && (
            <div className="flex flex-col gap-y-1">
              <label className="flex gap-x-1 text-sm font-medium">
                <span>Public</span>
                <span className="text-serial-palette-500 font-normal">{`(Everyone at ${company.name} can see)`}</span>
              </label>
              <div className="flex items-center">
                <Switch
                  checked={tempReportTemplate?.is_public ?? false}
                  onCheckedChange={(checked) => setTempReportTemplate({ ...tempReportTemplate, is_public: checked })}
                />
                <div className="text-serial-palette-400 ml-2 text-sm italic">{tempReportTemplate?.is_public ? "Public" : "Private"}</div>
              </div>
            </div>
          )}

          {!tempReportTemplate?.is_public && (
            <div className="flex flex-col gap-y-1">
              <label className="flex gap-x-1 text-sm font-medium">
                <span className="font-medium">Shared With</span>
                <span className="text-serial-palette-500">{sharedWithUserIds.length > 1 && `(${sharedWithUserIds.length - 1})`}</span>
              </label>
              {users.filter((user) => !sharedWithUserIds.includes(user.supabase_uid)).length > 0 && (
                <Combobox.Root
                  value={null}
                  onValueChange={(value) => {
                    if (!value) return;
                    setTempReportTemplate({ ...tempReportTemplate, shared_with: { ...tempReportTemplate.shared_with, [value]: true } });
                  }}
                >
                  <Combobox.Trigger>User Name</Combobox.Trigger>
                  <Combobox.Content className="md:w-[470px]">
                    {users
                      .filter((user) => !sharedWithUserIds.includes(user.supabase_uid))
                      .map((user, index) => (
                        <Combobox.Item
                          key={index}
                          className="h-10"
                          value={user.supabase_uid}
                          keywords={[user.first_name ?? "", user.last_name ?? ""]}
                        >
                          <div className="flex flex-nowrap items-center gap-2">
                            <UserAvatar user={user} size="xs" />
                            <span>{`${user.first_name} ${user.last_name}`}</span>
                          </div>
                        </Combobox.Item>
                      ))}
                  </Combobox.Content>
                </Combobox.Root>
              )}
              <div className="scrollbar-hide mt-2 max-h-64 overflow-scroll">
                {sharedWithUserIds.map((userId) => {
                  const user = users.find((user) => user.supabase_uid === userId);
                  const isOwner = tempReportTemplate?.created_by === userId;
                  if (!user) return null;
                  return (
                    <div key={userId} className="bg-serial-palette-100 mb-2 flex items-center justify-between rounded-md p-4">
                      <div className="flex items-center gap-3">
                        <UserAvatar user={user} size="md" />
                        <div className="flex flex-col">
                          <p className="text-sm">
                            {user.first_name} {user.last_name} {isOwner && <span className="text-serial-palette-500">(Owner)</span>}
                          </p>
                          <p className="text-serial-palette-500 text-sm">{user.email}</p>
                        </div>
                      </div>
                      {!isOwner && (
                        <Button
                          variant="danger"
                          symmetric
                          onClick={() =>
                            setTempReportTemplate({
                              ...tempReportTemplate,
                              shared_with: { ...tempReportTemplate.shared_with, [userId]: false },
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Modal.Main>
        <Modal.Footer className="sm:justify-between">
          <div className="flex gap-2">
            <Button size="sm" isLoading={saveIsLoading} onClick={handleSave}>
              Save
            </Button>
            <Modal.Close asChild>
              <Button size="sm" type="button" variant="outline">
                Close
              </Button>
            </Modal.Close>
          </div>
          {currUserIsOwner && (
            <Button size="sm" variant="danger" isLoading={deleteIsLoading} onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ReportTemplateEditModal;
