import React, { useRef, useContext, useMemo } from "react";
import { LabelFormatterContext } from "../LabelFormatterProvider";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { LabelDraggableType } from "../types";
import { LabelElementType } from "@shared/types/databaseEnums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// NOTE: This is a temporary icon for XML. The actual xml file icon is locked behind font awesome pro.
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { LabelElementStyle } from "../constants";
import { useDrag } from "react-dnd";
import { findXmlIdentifier, getXmlWidth, getXmlHeight } from "../helpers/helpers";

const LabelFormatterPaletteOption: React.FC<{ type: LabelElementType | "xml" }> = ({ type }) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { selectedLabelId, draftLabels, handleEditLabel } = useContext(LabelFormatterContext);
  const { triggerConfirmation, triggerToast } = useContext(ToastContext);

  const [{}, drag] = useDrag({
    type: LabelDraggableType.NewElement,
    item: () => {
      return { type: type };
    },
    canDrag: () => type !== "xml",
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      // nothing
    },
  });

  if (type !== "xml") {
    drag(dragRef);
  }

  const currentLabel = useMemo(() => draftLabels.find((label) => label.id === selectedLabelId), [draftLabels, selectedLabelId]);

  const handleClick = () => {
    if (type === "xml" && fileInputRef.current) {
      if (currentLabel && currentLabel.contents && currentLabel.contents.length > 0) {
        triggerConfirmation(
          "XML Label Overwrite Warning",
          "Adding an XML label will clear the current label. Are you sure you want to proceed?",
          () => {
            if (fileInputRef.current) {
              fileInputRef.current.click();
              handleEditLabel("contents", []);
            }
          },
          () => {},
        );
      } else {
        if (fileInputRef.current) fileInputRef.current.click();
      }
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && file.name.endsWith(".dymo")) {
      // Process the DYMO file
      const labelXml = await file.text();
      handleEditLabel("xml", labelXml);
      handleEditLabel("xml_identifier", findXmlIdentifier(labelXml));
      handleEditLabel("height", getXmlHeight(labelXml) * 25.4);
      handleEditLabel("width", getXmlWidth(labelXml) * 25.4);
    } else {
      triggerToast(ToastType.Error, "Invalid DYMO File", "Please upload a valid DYMO Connect label file (.dymo).");
      console.error("Please upload a valid DYMO Connect label file (.dymo).");
    }
  };

  const icon = type !== "xml" ? LabelElementStyle[type].icon : faFileImport;
  const label = type !== "xml" ? LabelElementStyle[type].label : "XML";
  const disabled = type === LabelElementType.Image || (currentLabel?.xml !== null && type !== "xml");

  return (
    <div
      ref={dragRef}
      onClick={handleClick}
      className={`flex h-[4.5rem] w-full cursor-grab flex-col items-center justify-center gap-2 rounded border pt-1 hover:border-2 ${disabled && "pointer-events-none opacity-40"} ${!selectedLabelId && "pointer-events-none opacity-40"}`}
    >
      <FontAwesomeIcon icon={icon} size="xl" />
      <div className="text-xs font-medium">{label}</div>
      {type === "xml" && <input ref={fileInputRef} type="file" accept=".dymo" style={{ display: "none" }} onChange={handleFileChange} />}
    </div>
  );
};

const LabelFormatterPalette: React.FC = () => {
  return (
    <div className="scrollbar-hide flex h-full w-24 shrink-0 flex-col gap-2 overflow-y-auto border-l p-3">
      {Object.values(LabelElementType).map((type, index) => {
        return (
          <React.Fragment key={index}>
            <LabelFormatterPaletteOption type={type} />
            {index === Object.values(LabelElementType).length - 1 && <LabelFormatterPaletteOption type="xml" />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default LabelFormatterPalette;
