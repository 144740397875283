import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { formatTimeAgo } from "@shared/utils/time";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import WorkOrderStatusTag from "../../../work-orders/components/WorkOrderStatusTag";
import WorkOrderProgressBar from "../../../work-orders/components/WorkOrderProgressBar";
import { WorkOrderWithCounts } from "../../types";
import { WorkOrderStatus } from "@shared/types/databaseEnums";
import { CountsByStatus } from "../../types";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { DashboardInsightContext } from "../DashboardInsightCard";

const BuildTracker: React.FC<void> = () => {
  const navigate = useNavigate();
  const context = useContext(DashboardInsightContext);
  const builds = useSelector((state: RootState) => state.db.workOrders);
  const components = useSelector((state: RootState) => state.db.components);
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const selectedComponentId = context.filters.componentId;
  const startDate = context.filters.dateRange?.from;
  const endDate = context.filters.dateRange?.to;

  let activeBuilds = useMemo<WorkOrderWithCounts[]>(() => {
    const statusOrder = ["PRODUCTION", "REVIEW", "COMPLETE"];

    const filteredBuilds = builds.filter((build) => {
      // Filter based on selectedComponentId if it's provided
      if (selectedComponentId && build.component_id !== selectedComponentId) {
        return false;
      }

      // Check if last_edited_at is within the startDate and endDate range
      if (build.last_edited_at !== null && startDate !== null && endDate !== null && build.status) {
        const lastEditedDate = new Date(build.last_edited_at);
        if ((startDate && lastEditedDate < startDate) || (endDate && lastEditedDate > endDate)) {
          return false;
        }
      }

      // Remove canceled and draft builds and complete builds that are older than 7 days
      const isNotCanceledOrDraft =
        build.status !== WorkOrderStatus.Canceled && build.status !== WorkOrderStatus.Draft && build.status !== WorkOrderStatus.Archived;
      if (build.status === WorkOrderStatus.Complete && build.last_edited_at !== null) {
        const lastEditedDate = new Date(build.last_edited_at);
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        return lastEditedDate >= oneMonthAgo && isNotCanceledOrDraft;
      }
      return isNotCanceledOrDraft;
    });

    // Sort builds by status
    const sortedBuilds = filteredBuilds.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));

    // Add counts by status to each build
    return sortedBuilds.map((build) => {
      const counts_by_status: CountsByStatus = {
        [UniqueIdentifierStatus.Planned]: 0,
        [UniqueIdentifierStatus.Wip]: 0,
        [UniqueIdentifierStatus.Complete]: 0,
        [UniqueIdentifierStatus.Defective]: 0,
      };

      const filteredInstances = componentInstances.filter((instance) => instance.work_order_id === build.id);

      filteredInstances.forEach((instance) => {
        if (instance.status !== null && counts_by_status.hasOwnProperty(instance.status)) {
          counts_by_status[instance.status]++;
        }
      });

      return { ...build, counts_by_status };
    });
  }, [builds, componentInstances, selectedComponentId, startDate, endDate]);

  return (
    <div className="col-span-full flex flex-col lg:col-span-4">
      <table className="w-full table-auto ">
        {/* Table header */}
        <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
          <tr>
            <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 sm:table-cell ">
              <div className="text-left font-semibold">Build</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Component</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-center font-semibold">Quantity</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Deadline</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Last Updated</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Progress</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="divide-serial-palette-200 divide-y text-sm ">
          {activeBuilds.map((build: any, index: number) => {
            let component = components.find((component) => component.id === build.component_id);
            return (
              <tr key={index} className="hover:bg-serial-palette-100 cursor-pointer" onClick={() => navigate("/workorder/" + build.id)}>
                <td className="max-w-[120px] cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                  <div className="inline-flex w-full items-center space-x-3 overflow-hidden">
                    <div className="">
                      <WorkOrderStatusTag status={build.status} />
                    </div>
                    <div className="truncate text-sm">{build.name}</div>
                  </div>
                </td>
                <td className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                  <div className="flex items-center space-x-3">
                    <ComponentAvatar component={component} size="xs" />
                    <div className="truncate text-sm">{component?.name}</div>
                  </div>
                </td>
                <td className="max-w-[100px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                  <div className="truncat text-center text-sm">{build.quantity}</div>
                </td>
                <td className="w-[130px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                  {build.deadline ? formatTimeAgo(build.deadline) : "N/A"}
                </td>
                <td className="w-[130px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                  {build.last_edited_at ? formatTimeAgo(build.last_edited_at) : "N/A"}
                </td>
                <td className="min-w-[150px] whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                  <div className="truncate text-sm">
                    <WorkOrderProgressBar counts_by_status={build.counts_by_status} quantity={build.quantity} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BuildTracker;
