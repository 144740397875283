import { useState } from "react";
import { Operator, User } from "@shared/types/databaseTypes";

type UserAvatarProps = {
  user: User | Operator | undefined;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  enableToolTip?: boolean;
  customTooltip?: string;
};

const sizeMapping = {
  xs: { w: "w-6", h: "h-6", text: "text-[10px]" },
  sm: { w: "w-8", h: "h-8", text: "text-sm" },
  md: { w: "w-10", h: "h-10", text: "text-base" },
  lg: { w: "w-16", h: "h-16", text: "text-lg" },
  xl: { w: "w-20", h: "h-20", text: "text-xl" },
};

const UserAvatar: React.FC<UserAvatarProps> = ({ user, size, enableToolTip, customTooltip }) => {
  if (!user) {
    return null;
  }

  const [isHovered, setIsHovered] = useState(false); // Add state for hover
  const abbreviation = (user?.first_name ? user?.first_name.slice(0, 1) : "?") + (user?.last_name ? user?.last_name.slice(0, 1) : "?");

  return (
    <div
      className="relative flex"
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true when mouse enters
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false when mouse leaves
    >
      {/* Show tooltip if hovered and tooltip is enabled */}
      {enableToolTip && isHovered && user?.first_name && (
        <div className="bg-serial-palette-700 absolute bottom-full left-1/2 z-10 mb-2 -translate-x-1/2 transform whitespace-nowrap rounded px-2 py-1 text-sm text-white">
          {user?.first_name} {user?.last_name}
          {customTooltip && (
            <>
              <br />
              {customTooltip}
            </>
          )}
        </div>
      )}

      {/* Render user image if it exists, else use abbreviation */}
      <div
        className={`rounded-full ${sizeMapping[size].w} ${sizeMapping[size].h} flex items-center justify-center overflow-hidden bg-gray-200`}
      >
        {"url" in user && user?.url ? (
          <img className="max-w-64 h-full max-h-64 w-full object-cover" src={user?.url} alt="Avatar" />
        ) : (
          <span className={`text-black ${sizeMapping[size].text} font-semibold`}>{abbreviation}</span>
        )}
      </div>
    </div>
  );
};

export default UserAvatar;
