import Popover from "@shared/components/primitives/Popover";
import Section from "./MeasuresGraphConfigSection";
import Button from "@shared/components/primitives/Button";
import MeasureKeySelector from "../MeasureKeySelector";
import { useMeasuresGraph } from "./MeasuresGraph";
import Select from "@shared/components/primitives/Select";
import { MeasureAggregationOperator, allowedMeasureAggregationOperators } from "@shared/measures/types";
import TagBasic from "@shared/components/primitives/TagBasic";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { getMeasureName } from "@shared/measures/helpers/naming";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { graphConfigurations } from "./types";
import { faChevronDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@shared/components/primitives/Tooltip";
import MeasuresGraphConfigRetestAggregationTooltip from "./MeasuresGraphConfigRetestAggregationTooltip.tsx";

const MeasuresGraphConfigXAxis = () => {
  const [measuresPopoverOpen, setMeasuresPopoverOpen] = useState<boolean>(false);

  const { setXAxisMeasureKey, xAxisMeasureKey, graphType, showRetests } = useMeasuresGraph();
  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const selectedMeasureName = useMemo(() => {
    if (!xAxisMeasureKey) {
      return "";
    }
    return getMeasureName(xAxisMeasureKey, {
      components,
      processes,
      datasets,
    });
  }, [xAxisMeasureKey, components, processes, datasets]);

  return (
    <Section.Root>
      <Section.Title>X Axis</Section.Title>
      <Section.Option>
        <Section.Label>Measure</Section.Label>
        <Popover.Root open={measuresPopoverOpen} onOpenChange={setMeasuresPopoverOpen}>
          <Popover.Trigger asChild disabled={!graphType}>
            <Button variant="outline" className="justify-between pl-2.5" onClick={() => setMeasuresPopoverOpen(true)}>
              {xAxisMeasureKey ? (
                <>
                  <div className="flex items-center justify-start gap-1.5 overflow-hidden">
                    <TagBasic variant={xAxisMeasureKey.type} size="xs" />
                    <span className="truncate">{selectedMeasureName}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </>
              ) : (
                <span className="text-serial-palette-400 font-light">Select Measure...</span>
              )}
            </Button>
          </Popover.Trigger>
          <Popover.Content align="start" className="w-[560px] rounded-md border shadow-lg">
            {graphType && (
              <MeasureKeySelector
                onSelect={(measureKey) => {
                  setXAxisMeasureKey({ ...measureKey, aggregation_operator: MeasureAggregationOperator.Latest });
                  setMeasuresPopoverOpen(false);
                }}
                keyTypes={graphConfigurations[graphType].xAxisAllowedMeasures ?? undefined}
              />
            )}
          </Popover.Content>
        </Popover.Root>
      </Section.Option>
      {!showRetests && (
        <Section.Option>
          <Section.Label className="flex items-center gap-1">
            <span>Retest Aggregation</span>
            <Tooltip.Root>
              <Tooltip.Trigger>
                <FontAwesomeIcon icon={faInfoCircle} className="text-gray-500" />
              </Tooltip.Trigger>
              <Tooltip.Content variant="light" align="start" className="flex w-72 flex-col gap-1">
                <MeasuresGraphConfigRetestAggregationTooltip />
              </Tooltip.Content>
            </Tooltip.Root>
          </Section.Label>
          <Select.Root
            value={xAxisMeasureKey?.aggregation_operator}
            onValueChange={(aggregationOperator) =>
              xAxisMeasureKey &&
              setXAxisMeasureKey({ ...xAxisMeasureKey, aggregation_operator: aggregationOperator as MeasureAggregationOperator })
            }
          >
            <Select.Trigger disabled={!xAxisMeasureKey}>
              {screamingSnakeCaseToTitleCase(xAxisMeasureKey?.aggregation_operator ?? MeasureAggregationOperator.Latest)}
            </Select.Trigger>
            <Select.Content>
              {xAxisMeasureKey &&
                allowedMeasureAggregationOperators[xAxisMeasureKey?.type]?.map((operator, index) => (
                  <Select.Item key={index} value={operator}>
                    {screamingSnakeCaseToTitleCase(operator)}
                  </Select.Item>
                ))}
            </Select.Content>
          </Select.Root>
        </Section.Option>
      )}
    </Section.Root>
  );
};
export default MeasuresGraphConfigXAxis;
