import { useTracking } from "../TrackingProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import TagBasic from "@shared/components/primitives/TagBasic";
import { NavLink } from "react-router-dom";
import { useMap } from "react-map-gl";
import { cn } from "@shared/utils/tailwind";
import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { GeoLocation } from "../types";

const TrackingSidebarTile = ({
  uniqueIdentifier,
  locations,
  isShowing,
}: {
  uniqueIdentifier: UniqueIdentifier;
  locations: GeoLocation[];
  isShowing: boolean;
}) => {
  const { setSelectedUniqueIdentifier, setSelectedUniqueIdentifierLocationIndex } = useTracking();
  const { map } = useMap();

  return (
    <div id={uniqueIdentifier.id} className="group relative flex w-full flex-col gap-y-1 rounded border bg-white p-2">
      <div className={cn("flex items-center gap-x-2", !isShowing && "opacity-50")}>
        <NavLink to={`/snlookup?uid=${uniqueIdentifier.id}`}>
          <div className="cursor-pointer text-sm hover:underline">{uniqueIdentifier.identifier}</div>
        </NavLink>
        <TagBasic size="xs" variant={uniqueIdentifier.status ?? UniqueIdentifierStatus.Planned} />
      </div>
      {locations.map((location, index) => (
        <button
          key={index}
          className="text-serial-palette-400 flex items-center gap-1 text-xs italic hover:underline"
          onClick={() => {
            location?.latitude && location?.longitude && map?.flyTo({ center: [location.longitude, location.latitude] });
            setSelectedUniqueIdentifier(uniqueIdentifier);
            setSelectedUniqueIdentifierLocationIndex(index);
          }}
        >
          <FontAwesomeIcon icon={faLocationArrow} className="pb-0.5" />
          <span>{`${location.latitude ?? "Not Available"}, ${location.longitude ?? "Not Available"}`}</span>
        </button>
      ))}
      {locations.length === 0 && <div className="text-serial-palette-400 text-xs italic">No location available</div>}
    </div>
  );
};

export default TrackingSidebarTile;
