import { useMemo } from "react";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faCircleSolid, faTimes } from "@fortawesome/free-solid-svg-icons";
import DropdownMenu from "@shared/components/primitives/DropdownMenu";
import ColorWheel from "@images/color-wheel.svg";
import { tailwindConfig } from "@shared/utils/helpers";
import { HexColorPicker } from "react-colorful";
import useDarkMode from "@shared/hooks/useDarkMode";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const tailwindColors = tailwindConfig().theme.colors;
const defaultColors = [
  tailwindColors["red"][600],
  tailwindColors["orange"][500],
  tailwindColors["yellow"][500],
  tailwindColors["green"][600],
  tailwindColors["blue"][600],
  tailwindColors["indigo"][600],
  tailwindColors["pink"][600],
];

const ImageMarkupPaletteColorPicker = ({
  color,
  onOpen,
  onLeave,
  onColorChange,
  onColorHover,
  icon,
  tooltip,
  allowTransparent,
  disabled,
}: {
  color: string | null;
  onOpen?: () => void;
  onLeave?: () => void;
  onColorChange: (color: string | null) => void;
  onColorHover?: (color: string | null) => void;
  icon: IconProp;
  tooltip: string;
  allowTransparent: boolean;
  disabled: boolean;
}) => {
  const { darkMode } = useDarkMode();

  // change white and dark icon colors slightly to make them visible the background
  const iconColor = useMemo(() => {
    if (darkMode) {
      if (color === "#000000") return tailwindColors["neutral"][800];
      if (color === "#ffffff") return "white";
      return color;
    } else {
      if (color === "#000000") return "black";
      if (color === "#ffffff") return tailwindColors["neutral"][200];
      return color;
    }
  }, [color, darkMode]);

  return (
    <DropdownMenu.Root onOpenChange={(open) => (open ? onOpen?.() : onLeave?.())}>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <Button disabled={disabled} size="sm" symmetric variant="ghost" tooltip={tooltip}>
          <FontAwesomeIcon size="xs" icon={icon} style={{ color: iconColor ?? undefined }} />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="!w-8 !min-w-0" sideOffset={10} onMouseLeave={() => onLeave?.()}>
        <DropdownMenu.Group>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="p-0">
              <div className="flex h-7 w-8 shrink-0 items-center justify-center">
                <img src={ColorWheel} className="h-4 w-4" />
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent className="border-0 bg-transparent p-0" sideOffset={6} alignOffset={-5}>
              <HexColorPicker color={color ?? "#aabbcc"} onChange={onColorChange} />
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
        </DropdownMenu.Group>
        <DropdownMenu.Group>
          <DropdownMenu.Item
            className="h-7 justify-center"
            onMouseDown={() => onColorChange("#000000")}
            onMouseEnter={() => onColorHover?.("#000000")}
          >
            <FontAwesomeIcon icon={darkMode ? faCircle : faCircleSolid} size="xs" style={{ color: darkMode ? "white" : "black" }} />
          </DropdownMenu.Item>
          {defaultColors.map((color, index) => (
            <DropdownMenu.Item
              key={index}
              className="h-7 justify-center"
              onMouseDown={() => onColorChange(color)}
              onMouseEnter={() => onColorHover?.(color)}
            >
              <FontAwesomeIcon icon={faCircleSolid} size="xs" style={{ color }} />
            </DropdownMenu.Item>
          ))}
          <DropdownMenu.Item
            className="h-7 justify-center"
            onMouseDown={() => onColorChange("#ffffff")}
            onMouseEnter={() => onColorHover?.("#ffffff")}
          >
            <FontAwesomeIcon icon={darkMode ? faCircleSolid : faCircle} size="xs" style={{ color: darkMode ? "white" : "black" }} />
          </DropdownMenu.Item>
        </DropdownMenu.Group>
        {allowTransparent && (
          <DropdownMenu.Group>
            <DropdownMenu.Item
              className="h-7 justify-center"
              onMouseDown={() => onColorChange(null)}
              onMouseEnter={() => onColorHover?.(null)}
            >
              <FontAwesomeIcon icon={faTimes} size="sm" />
            </DropdownMenu.Item>
          </DropdownMenu.Group>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default ImageMarkupPaletteColorPicker;
