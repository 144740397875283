var $jscomp = $jscomp || {};
$jscomp.scope = {};
$jscomp.checkStringArgs = function (e, g, k) {
  if (null == e) throw new TypeError("The 'this' value for String.prototype." + k + " must not be null or undefined");
  if (g instanceof RegExp) throw new TypeError("First argument to String.prototype." + k + " must not be a regular expression");
  return e + "";
};
$jscomp.ASSUME_ES5 = !1;
$jscomp.ASSUME_NO_NATIVE_MAP = !1;
$jscomp.ASSUME_NO_NATIVE_SET = !1;
$jscomp.defineProperty =
  $jscomp.ASSUME_ES5 || "function" == typeof Object.defineProperties
    ? Object.defineProperty
    : function (e, g, k) {
        e != Array.prototype && e != Object.prototype && (e[g] = k.value);
      };
$jscomp.getGlobal = function (e) {
  return "undefined" != typeof window && window === e ? e : "undefined" != typeof global && null != global ? global : e;
};
$jscomp.global = $jscomp.getGlobal(this);
$jscomp.polyfill = function (e, g, k, h) {
  if (g) {
    k = $jscomp.global;
    e = e.split(".");
    for (h = 0; h < e.length - 1; h++) {
      var f = e[h];
      if (k === undefined || k === null) {
        k = {}; // Initialize k if it is undefined or null
      }
      if (!(f in k)) {
        k[f] = {};
      } else if (typeof k[f] !== "object" || k[f] === null) {
        k[f] = {}; // Reset to an empty object if it's not an object
      }
      k = k[f];
    }
    e = e[e.length - 1];
    h = k[e];
    g = g(h);
    g != h && null != g && $jscomp.defineProperty(k, e, { configurable: !0, writable: !0, value: g });
  }
};
$jscomp.polyfill(
  "String.prototype.startsWith",
  function (e) {
    return e
      ? e
      : function (e, k) {
          var h = $jscomp.checkStringArgs(this, e, "startsWith");
          e += "";
          var f = h.length,
            g = e.length;
          k = Math.max(0, Math.min(k | 0, h.length));
          for (var q = 0; q < g && k < f; ) if (h[k++] != e[q++]) return !1;
          return q >= g;
        };
  },
  "es6",
  "es3",
);
export var BrowserPrint = (function () {
  function e(a, c) {
    var b = new XMLHttpRequest();
    "withCredentials" in b
      ? b.open(a, c, !0)
      : "undefined" != typeof XDomainRequest
        ? ((b = new XDomainRequest()), b.open(a, c))
        : (b = null);
    return b;
  }
  function g(a, c, b, d) {
    void 0 !== a && (void 0 === b && (b = a.sendFinishedCallback), void 0 === d && (d = a.sendErrorCallback));
    return h(c, b, d);
  }
  function k(a, c, b) {
    void 0 === c && (c = f.defaultSuccessCallback);
    void 0 === b && (b = f.defaultErrorCallback);
    return h(a, c, b);
  }
  function h(a, c, b) {
    a.onreadystatechange = function () {
      a.readyState === XMLHttpRequest.DONE && 200 === a.status
        ? "" === a.responseType
          ? c(a.responseText)
          : c(a.response)
        : a.readyState === XMLHttpRequest.DONE && (b ? b(a.response) : console.log("error occurred with no errorCallback set."));
    };
    return a;
  }
  var f = {},
    p = {},
    q = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  navigator.userAgent.indexOf("Trident/7.0");
  var m = "http://127.0.0.1:9100/";
  q && "https:" === location.protocol && (m = "https://127.0.0.1:9101/");
  f.Device = function (a) {
    var c = this;
    this.name = a.name;
    this.deviceType = a.deviceType;
    this.connection = a.connection;
    this.uid = a.uid;
    this.version = 2;
    this.provider = a.provider;
    this.manufacturer = a.manufacturer;
    this.readRetries = "bluetooth" === this.connection ? 1 : 0;
    this.sendErrorCallback = function (b) {};
    this.sendFinishedCallback = function (b) {};
    this.readErrorCallback = function (b) {};
    this.readFinishedCallback = function (b) {};
    this.send = function (b, a, l) {
      var d = e("POST", m + "write");
      d &&
        (g(c, d, a, l),
        d.send(
          JSON.stringify({
            device: {
              name: this.name,
              uid: this.uid,
              connection: this.connection,
              deviceType: this.deviceType,
              version: this.version,
              provider: this.provider,
              manufacturer: this.manufacturer,
            },
            data: b,
          }),
        ));
    };
    this.sendUrl = function (b, a, l, r) {
      var d = e("POST", m + "write");
      d &&
        (g(c, d, a, l),
        (b = {
          device: {
            name: this.name,
            uid: this.uid,
            connection: this.connection,
            deviceType: this.deviceType,
            version: this.version,
            provider: this.provider,
            manufacturer: this.manufacturer,
          },
          url: b,
        }),
        null != r && void 0 != r && (b.options = r),
        d.send(JSON.stringify(b)));
    };
    this.sendFile = function (b, a, l) {
      if ("string" === typeof b)
        f.loadFileFromUrl(
          b,
          function (b) {
            c.sendFile(b, a, l);
          },
          l,
        );
      else {
        var d = e("POST", m + "write");
        if (d) {
          d.responseType = "text";
          k(d, a, l);
          var n = new FormData(),
            g = {};
          g.device = c;
          n.append("json", JSON.stringify(g));
          n.append("blob", b);
          d.send(n);
        }
      }
    };
    this.convertAndSendFile = function (b, a, l, c) {
      c || (c = {});
      c.action || (c.action = "print");
      f.convert(b, this, c, a, l);
    };
    this.read = function (b, a) {
      var d = e("POST", m + "read");
      d &&
        (void 0 !== c && (void 0 === b && (b = c.readFinishedCallback), void 0 === a && (a = c.readErrorCallback)),
        h(d, b, a),
        d.send(
          JSON.stringify({
            device: {
              name: this.name,
              uid: this.uid,
              connection: this.connection,
              deviceType: this.deviceType,
              version: this.version,
              provider: this.provider,
              manufacturer: this.manufacturer,
            },
          }),
        ));
    };
    this.readUntilStringReceived = function (a, d, c, e, f) {
      f || (f = "");
      void 0 === e && (e = this.readRetries);
      d = (function (b, d, c, l, e) {
        return function (f) {
          if (f && 0 !== f.length) l = 0;
          else if (0 >= l) {
            d(e);
            return;
          }
          f = e + f;
          "" !== a && -1 < f.indexOf(a) ? d(f) : b.readUntilStringReceived(a, d, c, l - 1, f);
        };
      })(this, d, c, e, f);
      this.read(d, c);
    };
    this.readAllAvailable = function (a, d, c) {
      this.readUntilStringReceived("", a, d, c);
    };
    this.sendThenRead = function (a, d, c) {
      this.send(
        a,
        (function (a) {
          return function () {
            a.read(d, c);
          };
        })(this),
        c,
      );
    };
    this.sendThenReadUntilStringReceived = function (a, d, c, e, f) {
      this.send(
        a,
        (function (a) {
          return function () {
            a.readUntilStringReceived(d, c, e, f);
          };
        })(this),
        e,
      );
    };
    this.sendThenReadAllAvailable = function (a, d, c, e) {
      this.send(
        a,
        (function (a) {
          return function () {
            a.readUntilStringReceived("", d, c, e);
          };
        })(this),
        c,
      );
    };
  };
  f.defaultSuccessCallback = function () {};
  f.defaultErrorCallback = function () {};
  f.ApplicationConfiguration = function () {
    this.application = { version: "1.2.0.3", build_number: 3, api_level: 2, platform: "", supportedConversions: {} };
  };
  f.getLocalDevices = function (a, c, b) {
    var d = e("GET", m + "available");
    if (d) {
      var finishedFunction = function (c) {
        var response = c;
        response = JSON.parse(response);
        for (var d in response)
          if (response.hasOwnProperty(d) && response[d].constructor === Array)
            for (var arr = response[d], c = 0; c < arr.length; ++c) arr[c] = new f.Device(arr[c]);
        void 0 === b ? a(response) : (response.hasOwnProperty(b) || (response[b] = []), a(response[b]));
      };
      k(d, finishedFunction, c);
      d.send();
    }
  };
  f.getDefaultDevice = function (a, c, b) {
    var d = "default";
    void 0 !== a && null != a && (d = d + "?type=" + a);
    var req = e("GET", m + d);
    if (req) {
      var finishedFunction = function (a) {
        var response = a;
        "" === response ? c(null) : ((response = JSON.parse(response)), (a = new f.Device(response)), c(a));
      };
      k(req, finishedFunction, b);
      req.send();
    }
  };
  f.getApplicationConfiguration = function (a, c) {
    var b = e("GET", m + "config");
    if (b) {
      var finishedFunction = function (b) {
        var response = b;
        "" === response ? a(null) : ((response = JSON.parse(response)), a(response));
      };
      k(b, finishedFunction, c);
      b.send();
    }
  };
  f.readOnInterval = function (a, c, b) {
    if (void 0 === b || 0 === b) b = 1;
    var readFunc = function () {
      a.read(
        function (d) {
          c(d);
          p[a] = setTimeout(readFunc, b);
        },
        function (c) {
          p[a] = setTimeout(readFunc, b);
        },
      );
    };
    p[a] = setTimeout(readFunc, b);
  };
  f.stopReadOnInterval = function (a) {
    p[a] && clearTimeout(p[a]);
  };
  f.bindFieldToReadData = function (a, c, b, d) {
    f.readOnInterval(
      a,
      function (a) {
        "" !== a && ((c.value = a), void 0 !== d && null != d && d());
      },
      b,
    );
  };
  f.loadFileFromUrl = function (a, c, b) {
    var request = e("get", a);
    console.log("ResponseType: " + request.responseType);
    request.responseType = "blob";
    k(request, c, b);
    request.send();
  };
  f.convert = function (a, c, b, d, l) {
    if (a)
      if ("string" === typeof a)
        f.loadFileFromUrl(
          a,
          function (e) {
            b.fromFormat || (b.fromFormat = a.substring(a.length - 3));
            f.convert(e, c, b, d, l);
          },
          l,
        );
      else {
        var g = e("POST", m + "convert");
        a.type &&
          (a.type.startsWith("image/") || a.type.startsWith("application/")) &&
          (b.fromFormat = a.type.toLowerCase().replace("image/", "").replace("application/", "").replace("x-ms-", ""));
        if (g) {
          g.responseType = "text";
          var h = void 0;
          void 0 != d &&
            (h = function (a) {
              d(JSON.parse(a));
            });
          k(g, h, l);
          h = new FormData();
          var t = {};
          null != b && void 0 !== b && (t.options = b);
          c && (t.device = c);
          h.append("json", JSON.stringify(t));
          h.append("blob", a);
          g.send(h);
        }
      }
    else l ? l("Resource not specified") : f.defaultErrorCallback("Resource not specified");
  };
  f.scanImage = function (a, c, b, d) {
    if (a)
      if ("string" === typeof a)
        f.loadFileFromUrl(
          a,
          function (e) {
            c.format || (c.format = a.substring(a.length - 3));
            f.scanImage(e, c, b, d);
          },
          d,
        );
      else {
        var g = e("POST", m + "convert/scan");
        a.type &&
          (a.type.startsWith("image/") || a.type.startsWith("application/")) &&
          (c.format = a.type.toLowerCase().replace("image/", "").replace("application/", "").replace("x-ms-", ""));
        if (g) {
          g.responseType = "text";
          var h = void 0;
          void 0 != b &&
            (h = function (a) {
              b(JSON.parse(a));
            });
          k(g, h, d);
          h = new FormData();
          var n = {};
          n.options = c;
          h.append("json", JSON.stringify(n));
          h.append("blob", a);
          g.send(h);
        }
      }
    else d ? d("Resource not specified") : f.defaultErrorCallback("Resource not specified");
  };
  return f;
})();
