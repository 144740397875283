import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { WorkOrder } from "@shared/types/databaseTypes";
import { RootState } from "@shared/redux/store";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { DataType, WorkOrderStatus } from "@shared/types/databaseEnums";
import TypeTag from "@shared/components/TypeTag";
import WorkOrderAttachments from "./WorkOrderAttachments";
import { WorkOrderAttachmentDraft, WorkOrderAttachmentType } from "../types";
import WorkOrderUnitList from "./WorkOrderUnitList";
import { UserRole } from "@shared/types/databaseEnums";
import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

enum WorkOrderConfigurationTabs {
  Attachments = "Attachments",
  UnitList = "Unit List",
}

export const workOrderAttachmentTypeOptions = [
  {
    name: "Text",
    type: DataType.ParametricQualitative as WorkOrderAttachmentType,
  },
  {
    name: "File",
    type: DataType.File as WorkOrderAttachmentType,
  },
  {
    name: "Image",
    type: DataType.Image as WorkOrderAttachmentType,
  },
];

interface WorkOrderConfigurationProps {
  workOrder: WorkOrder;
  unitList: UniqueIdentifier[];
}

const getDefaultTab = (status: WorkOrderStatus) => {
  if (status === WorkOrderStatus.Draft || status === WorkOrderStatus.Review) {
    return WorkOrderConfigurationTabs.Attachments;
  } else {
    return WorkOrderConfigurationTabs.UnitList;
  }
};

const WorkOrderConfiguration: React.FC<WorkOrderConfigurationProps> = ({ workOrder, unitList }) => {
  const role = useSelector((state: RootState) => state.auth.role);
  const [activeTab, setActiveTab] = useState<string>(getDefaultTab(workOrder.status));
  const [newAttachments, setNewAttachments] = useState<WorkOrderAttachmentDraft[]>([]);

  const observe = useContext(ObservabilityContext);

  const handleNewAttachment = (metadataType: WorkOrderAttachmentType) => {
    const blankAttachement = {
      type: metadataType,
      name: "",
      value: "",
      file_name: "",
      url: "",
    };
    setNewAttachments([...newAttachments, blankAttachement]);
    observe.track("work-order_new-attachment");
  };

  return (
    <div className="border-serial-palette-200 flex h-full min-h-[500px] w-full flex-col rounded-md border bg-white">
      <header className="inline-flex justify-between border-b pr-2">
        <div className="inline-flex shrink-0 items-center overflow-x-auto ">
          {Object.values(WorkOrderConfigurationTabs).map((tab, index) => {
            return (
              <div key={index} className="flex justify-center pt-2.5">
                <h2
                  onClick={() => setActiveTab(tab)}
                  className={`text-serial-palette-800 flex shrink-0 ${index === 0 ? "pl-5" : "pl-4"} border-b-[3.5px] pb-[9px] pr-4 ${activeTab === tab ? "border-serial-palette-600 font-semibold" : "hover:border-serial-palette-400 border-white font-medium"} cursor-pointer`}
                >
                  <span className={`${activeTab !== tab && "opacity-60"}`}>{tab}</span>
                </h2>
              </div>
            );
          })}
        </div>
        {role === UserRole.Admin && activeTab === WorkOrderConfigurationTabs.Attachments && workOrder.status === WorkOrderStatus.Draft && (
          <DropdownCustom
            align="left"
            buttonContents={
              <div className=" btn-sm bg-serial-palette-800 hover:bg-serial-palette-600 right-2 top-2 z-10 whitespace-nowrap text-white">
                <svg className="h-4 w-4 shrink-0 fill-current" viewBox="0 0 16 16">
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="ml-2 hidden sm:block">Add Attachment</span>
              </div>
            }
            className=""
            dropdownClassName="w-full"
          >
            {workOrderAttachmentTypeOptions.map((option) => {
              return (
                <div
                  key={option.name}
                  className={`hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center px-4 py-1 last:mb-1.5 `}
                  onClick={() => handleNewAttachment(option.type)}
                >
                  <TypeTag className={"mr-2 w-10 text-xs"} hideText={true} type={option.type} />
                  <span className="text-serial-palette-600 text-sm font-medium">{option.name}</span>
                </div>
              );
            })}
          </DropdownCustom>
        )}
      </header>
      <div className="flex h-full w-full">
        <div className="flex h-full w-full">
          {activeTab === WorkOrderConfigurationTabs.Attachments && (
            <div className="w-full">
              <WorkOrderAttachments workOrder={workOrder} newAttachments={newAttachments} setNewAttachments={setNewAttachments} />
            </div>
          )}
          {activeTab === WorkOrderConfigurationTabs.UnitList && (
            <div className="">
              <WorkOrderUnitList workOrder={workOrder} unitList={unitList} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkOrderConfiguration;
