import React from "react";
import { SVG_LOADER } from "@shared/constants/svgs";

export const Loader = (props) => {
  return (
    <div className="loader">
      <svg className={`text-serial-palette-400 h-40 w-40 shrink-0 animate-spin fill-current ${props.styleOverride}`} viewBox="0 0 16 16">
        <path d={SVG_LOADER} />
      </svg>
    </div>
  );
};
