import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MeasuresGridBodyCellSameAsAbove = ({ formattedValue }: { formattedValue: string }) => {
  return (
    <div className="relative flex h-full w-full flex-col items-center justify-start">
      <div className="border-serial-palette-400 mt-1.5 flex h-1/3 w-11/12 border-b border-l" />
      <FontAwesomeIcon
        icon={faAngleRight}
        size="xs"
        className="text-serial-palette-400 absolute right-1.5 top-1/2 -translate-y-1/2 transform pb-[1px]"
      />
      {formattedValue && (
        <div className="text-serial-palette-500 absolute top-5 flex h-[10px] w-full items-start justify-center overflow-hidden px-1 text-[10px] italic leading-none">
          (<span className="truncate px-0.5">{formattedValue}</span>)
        </div>
      )}
    </div>
  );
};

export default MeasuresGridBodyCellSameAsAbove;
