import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect } from "react";
import Tooltip from "@shared/components/Tooltip";
import { ProductionFormGenericFieldProps } from "../../types";
import { ProductionContext } from "../../ProductionProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductionFormCheckbox: React.FC<ProductionFormGenericFieldProps> = ({ field, draftData }) => {
  const { db, handleUpdateDraftSubmission, inputRefs, handleSetFocusedField, focusedFieldId } = useContext(ProductionContext);

  // if field is focused, pressing enter will toggle the checkbox
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (focusedFieldId !== field.id || keyCode !== 13) return;
      handleUpdateDraftSubmission(field.id, { value: !draftData?.value });
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [focusedFieldId, field.id, draftData?.value, handleUpdateDraftSubmission]);

  return (
    <div
      className="flex min-h-[1.5rem] w-full cursor-pointer justify-between"
      onClick={() => handleUpdateDraftSubmission(field.id, { value: !(draftData?.value === true) })}
    >
      <div className="flex items-center gap-2">
        <input
          ref={(element) => (inputRefs.current[field.id] = element)}
          onFocus={() => handleSetFocusedField(field.id)}
          type="checkbox"
          className="form-checkbox bg-serial-palette-100 my-0.5 h-6 w-6 cursor-pointer"
          onChange={(e) => handleUpdateDraftSubmission(field.id, { value: e.target.checked })}
          checked={draftData?.value === true}
        />
        <div className="text-sm font-light">{field.prompt}</div>
      </div>
      {db.company?.config?.show_specs_in_production_app && (
        <div tabIndex={-1} className="">
          {draftData?.value !== true && field.dataset?.expected_value === "true" && draftData?.isValid && (
            <Tooltip
              position="left"
              size="md"
              override={true}
              timeout={3500}
              content={
                <span className="text-sm italic">
                  After submission, this unit will be marked as <span className="font-bold text-rose-500">DEFECTIVE</span>. Value must be{" "}
                  <span className="font-bold">checked</span> to pass.
                </span>
              }
            >
              <FontAwesomeIcon icon={faXmarkCircle} className="text-[1rem] text-rose-600" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductionFormCheckbox;
