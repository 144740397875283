import React, { useContext } from "react";
import { ProcessWithOrderActivityDatasets } from "../../../../features/component/types";
import { Handle, Position } from "reactflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faEdit } from "@fortawesome/free-solid-svg-icons";
import { PROCESS_NODE_HEIGHT_CLASS_NAME, PROCESS_NODE_WIDTH_CLASS_NAME } from "./constants";
import useDataTypeCount from "@shared/hooks/useDataTypeCount";
import VersionBlock from "@shared/components/VersionBlock";
import { ComponentContext } from "../../../../features/component/ComponentProvider";

interface ProcessNodeData {
  process: ProcessWithOrderActivityDatasets;
  hideSourceHandle?: boolean;
  hideAvatar?: boolean;
  connectable?: boolean;
  draggable?: boolean;
  handleEditProcess?: (processId: string, componentId?: string) => void;
  componentId?: string;
}

const abbreviateProcessName = (processName: string) => {
  const cleanString = processName.replace(/[^\w\s]/g, ""); // remove all non-alphanumeric characters
  const words = cleanString.split(" ");
  const firstLetter = words[0].charAt(0).toUpperCase();
  const secondLetter = words.length > 1 ? words[1].charAt(0).toUpperCase() : "";
  return firstLetter + secondLetter;
};

const ProcessNode: React.FC<{ data: ProcessNodeData }> = ({ data }) => {
  const { dataTypeCounts, tags } = useDataTypeCount({ datasets: data.process.datasets });
  const { approvedProcessRevisions } = useContext(ComponentContext);

  <div className="flex w-64">
    <div className="flex w-10">static width</div>
    <div className="">fills the remaining space in the parent</div>
  </div>;

  return (
    <div
      className={`relative flex items-center gap-2 rounded-md border ${data.draggable ? "hover:shadow" : "cursor-default"} group bg-white px-2 ${PROCESS_NODE_WIDTH_CLASS_NAME} ${PROCESS_NODE_HEIGHT_CLASS_NAME}`}
    >
      <Handle type="target" position={Position.Top} isConnectable={data.connectable ?? true} />
      {!data.hideAvatar && (
        <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-gray-200">
          {/* @ts-ignore will be adding url to process soon */}
          {data.process?.url ? (
            <img className="h-full w-full object-cover" src={""} alt="Avatar" />
          ) : (
            <div className="text-serial-palette-900 text-xl font-semibold">{abbreviateProcessName(data.process.name)}</div>
          )}
        </div>
      )}
      <div className="flex min-w-0 flex-grow flex-col gap-0.5 pb-1">
        <div className="flex w-full items-center gap-0.5">
          <div className="truncate text-sm font-bold">{data.process.name}</div>
          {data.process.is_mandatory && <div className="text-left text-sm font-bold text-red-600">*</div>}
          {data.process.use_api && (
            <div className="border-serial-palette-600 text-serial-palette-600 ml-0.5 flex h-[16px] w-10 items-center justify-center rounded-full border bg-white text-[8px] font-bold">
              <FontAwesomeIcon icon={faCode} />
              <div className={`pl-0.5`}>API</div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          {tags}
          <div className={`${data.handleEditProcess && "group-hover:hidden"}`}>
            <VersionBlock
              version={
                approvedProcessRevisions[data.process.id] !== undefined
                  ? approvedProcessRevisions[data.process.id].toString()
                  : data.process?.revision.toString() || "0"
              }
              size="xs"
              color={approvedProcessRevisions[data.process.id] !== undefined ? "green" : "black"}
            />
          </div>
        </div>
      </div>
      {data.handleEditProcess && (
        <div
          className="btn bg-serial-palette-50 border-serial-palette-200 hover:border-serial-palette-300 absolute bottom-1.5 right-1.5 hidden h-7 w-7 cursor-pointer rounded-full hover:border-2 group-hover:flex"
          onMouseDownCapture={() => data.handleEditProcess && data.handleEditProcess(data.process.id, data.componentId)} // use onMouseDownCapture to prevent the process builder failing to open when clicking and dragging slightly
        >
          <FontAwesomeIcon icon={faEdit} size="xs" className="text-serial-palette-500" />
        </div>
      )}
      <Handle
        id="linkhandle"
        type="target"
        position={Position.Right}
        isConnectable={false}
        className={dataTypeCounts["LINK"] ? "visible" : "invisible"}
      />
      {!data.hideSourceHandle && <Handle type="source" position={Position.Bottom} isConnectable={data.connectable ?? true} />}
    </div>
  );
};

export default ProcessNode;
