// We can remove this file if the bundle gets too big

import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import FrontendPrimitivesButtons from "./components/FrontendPrimitivesButtons";
import FrontendPrimitivesTooltips from "./components/FrontendPrimitivesTooltips";
import FrontendPrimitivesTables from "./components/FrontendPrimitivesTables";
import FrontendPrimitivesBanners from "./components/FrontendPrimitivesBanners";
import FrontendPrimitivesModals from "./components/FrontendPrimitivesModals";
import FrontendPrimitivesSelect from "./components/FrontendPrimitivesSelect";
import FrontendPrimitivesPopovers from "./components/FrontendPrimitivesPopovers";
import FrontendPrimitivesComponentAvatars from "./components/FrontendPrimitivesComponentAvatars";
import FrontendPrimitivesUserAvatars from "./components/FrontendPrimitivesUserAvatars";
import FrontendPrimitivesLoaders from "./components/FrontendPrimitivesLoaders";
import FrontendPrimitivesTagsBasic from "./components/FrontendPrimitivesTagsBasic";
import FrontendPrimitivesTagsKeyValue from "./components/FrontendPrimitivesTagsKeyValue";
import FrontendPrimitivesSwitch from "./components/FrontendPrimitivesSwitch";
import FrontendPrimitivesDropdowns from "./components/FrontendPrimitivesDropdowns";
import FrontendPrimitivesCheckbox from "./components/FrontendPrimitivesCheckbox";
import FrontendPrimitivesCommand from "./components/FrontendPrimitivesCommand";
import FrontendPrimitivesCombobox from "./components/FrontendPrimitivesCombobox";
import FrontendPrimitivesTextInput from "./components/FrontendPrimitivesTextInput";
import FrontendPrimitivesCharts from "./components/FrontendPrimitivesCharts";
import FrontendPrimitivesSidebarSelector from "./components/FrontendPrimitivesSidebarSelector";

function FrontendPrimitives() {
  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <Header />
        <div className="flex w-full flex-col gap-y-8 p-8">
          <FrontendPrimitivesButtons />
          <FrontendPrimitivesTooltips />
          <FrontendPrimitivesTables />
          <FrontendPrimitivesComponentAvatars />
          <FrontendPrimitivesUserAvatars />
          <FrontendPrimitivesBanners />
          <FrontendPrimitivesLoaders />
          <FrontendPrimitivesModals />
          <FrontendPrimitivesSidebarSelector />
          <FrontendPrimitivesCommand />
          <FrontendPrimitivesCombobox />
          <FrontendPrimitivesPopovers />
          <FrontendPrimitivesDropdowns />
          <FrontendPrimitivesTextInput />
          <FrontendPrimitivesSelect />
          <FrontendPrimitivesCheckbox />
          <FrontendPrimitivesSwitch />
          <FrontendPrimitivesTagsBasic />
          <FrontendPrimitivesTagsKeyValue />
          <FrontendPrimitivesCharts />
        </div>
      </div>
    </div>
  );
}

export default FrontendPrimitives;
