import { cn } from "@shared/utils/tailwind";
import * as React from "react";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onValueChange?: (value: string) => void;
  validationState?: "valid" | "invalid" | "warning" | undefined;
}

const validationStateClasses = {
  valid: "!border-green-500 hover:!border-green-500 focus:!border-green-500",
  invalid: "!border-rose-500 hover:!border-rose-500 focus:!border-rose-500",
  warning: "!border-yellow-500 hover:!border-yellow-500 focus:!border-yellow-500",
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, onValueChange, validationState, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        "!border-serial-palette-200 placeholder:text-serial-palette-500 focus-visible:ring-serial-palette-600 flex min-h-[90px] w-full rounded border bg-white py-2 ring-offset-white placeholder:font-light focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className,
        validationState && `border ${validationStateClasses[validationState]}`,
      )}
      ref={ref}
      onChange={(e) => {
        if (onValueChange) onValueChange(e.target.value);
        if (props.onChange) props.onChange(e);
      }}
      {...props}
    />
  );
});
TextArea.displayName = "TextArea";

export { TextArea };
