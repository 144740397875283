import React, { useState, useEffect, useMemo } from "react";
import { Image as PdfImage, Page, Text, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import moment from "moment";

import checkImage from "@images/pdf/check.png";

import RobotoThin from "@styles/fonts/roboto/Roboto-Thin.ttf";
import RobotoThinItalic from "@styles/fonts/roboto/Roboto-ThinItalic.ttf";
import RobotoLight from "@styles/fonts/roboto/Roboto-Light.ttf";
import RobotoLightItalic from "@styles/fonts/roboto/Roboto-LightItalic.ttf";
import RobotoRegular from "@styles/fonts/roboto/Roboto-Regular.ttf";
import RobotoItalic from "@styles/fonts/roboto/Roboto-Italic.ttf";
import RobotoMedium from "@styles/fonts/roboto/Roboto-Medium.ttf";
import RobotoMediumItalic from "@styles/fonts/roboto/Roboto-MediumItalic.ttf";
import RobotoBold from "@styles/fonts/roboto/Roboto-Bold.ttf";
import RobotoBoldItalic from "@styles/fonts/roboto/Roboto-BoldItalic.ttf";
import RobotoBlack from "@styles/fonts/roboto/Roboto-Black.ttf";
import RobotoBlackItalic from "@styles/fonts/roboto/Roboto-BlackItalic.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoThin, fontWeight: 100 },
    { src: RobotoThinItalic, fontWeight: 100, fontStyle: "italic" },
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoLightItalic, fontWeight: 300, fontStyle: "italic" },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoItalic, fontWeight: 400, fontStyle: "italic" },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoMediumItalic, fontWeight: 500, fontStyle: "italic" },
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBoldItalic, fontWeight: 700, fontStyle: "italic" },
    { src: RobotoBlack, fontWeight: 900 },
    { src: RobotoBlackItalic, fontWeight: 900, fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  subsection: {
    margin: 0,
    padding: 10,
    flexGrow: 1,
    marginTop: 10,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    textAlign: "left",
    fontWeight: "black",
    marginBottom: 6,
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    fontStyle: "italic",
    fontWeight: "medium",
  },
  header: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "left",
    fontWeight: "black",
    marginBottom: 4,
    marginTop: 12,
  },
  subheader: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: 5,
  },
  unitInfo: {
    fontFamily: "Roboto",
    fontSize: 12,
    marginBottom: 5,
  },
  dataType: {
    fontFamily: "Roboto",
    fontStyle: "italic",
    fontWeight: "medium",
    fontSize: 12,
    marginBottom: 5,
  },
  process: {
    fontFamily: "Roboto",
    fontSize: 10,
    marginBottom: 5,
    marginRight: 10,
  },
  dataIndented: {
    paddingLeft: 20,
  },
  boldText: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  mediumText: {
    fontFamily: "Roboto",
    fontWeight: "medium",
  },
  lightText: {
    fontFamily: "Roboto",
    fontWeight: "thin",
  },
  companyLogo: {
    width: 100,
    position: "absolute",
    top: 20,
    right: 20,
  },
  imageContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  dataImage: {
    width: 165,
    marginRight: 5,
  },
  dataImageCaption: {
    width: 160,
    marginRight: 10,
    marginTop: 5,
    marginBottom: 18,
  },
  canvas: {
    width: "100%",
    height: "auto",
  },
  horizontalLine: {
    borderBottom: "1 solid black",
    width: "100%",
    marginBottom: 5,
  },
  processDetails: {
    flexDirection: "row",
    fontSize: 8,
    flexWrap: "wrap",
    marginBottom: 5,
  },
  processPass: {
    fontWeight: "bold",
    color: "green",
  },
  processFail: {
    fontWeight: "bold",
    color: "red",
  },
  dataPass: {
    color: "green",
  },
  dataFail: {
    color: "red",
  },
  checkbox: {
    width: 12,
    height: 12,
    borderWidth: 1,
    borderColor: "black",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
});

const CanvasImage = ({ url, style, getBase64ImageFromUrl }) => {
  return url ? <PdfImage style={style} src={getBase64ImageFromUrl(url)} /> : null;
};

const Checkbox = ({ isChecked }) => {
  return <View style={styles.checkbox}>{isChecked && <PdfImage src={checkImage} />}</View>;
};

const GenealogyItem = ({ genealogyObject, indentLevel }) => {
  return (
    <>
      <View style={{ flexDirection: "row", marginLeft: indentLevel * 20 }}>
        <Checkbox isChecked={false} />
        <Text style={styles.unitInfo}>
          <Text style={styles.boldText}>{genealogyObject.component_name} </Text> || {genealogyObject.identifier} ||{" "}
          {genealogyObject.part_number?.pn}
        </Text>
      </View>
      {genealogyObject?.children?.map((child, index) => (
        <GenealogyItem key={index} genealogyObject={child} indentLevel={indentLevel + 1} />
      ))}
    </>
  );
};

function SnLookupPrint({
  companyLogoUrl,
  genealogy,
  uniqueIdentifierData,
  processEntries,
  pdfOptions,
  setPdfIsLoading,
  getBase64ImageFromUrl,
}) {
  useEffect(() => {
    setPdfIsLoading(true);
  }, []);

  const rootGenalogyObject = useMemo(() => {
    const rootGenealogyObject = genealogy.find((genealogyObject) => genealogyObject.parent_id === null);
    return rootGenealogyObject;
  }, [genealogy]);

  return (
    <Document
      title={`${uniqueIdentifierData.identifier} - Production Record - ${moment().format("MM/DD/YY HH:mma")}`}
      onRender={() => setPdfIsLoading(false)}
    >
      <Page size="A4" style={styles.page} wrap>
        <CanvasImage style={styles.companyLogo} url={companyLogoUrl} getBase64ImageFromUrl={getBase64ImageFromUrl} />
        <Text style={styles.title}>{uniqueIdentifierData.identifier}</Text>
        <Text style={styles.subtitle}>{uniqueIdentifierData?.component?.name}</Text>
        <Text
          style={{ marginTop: 12, fontFamily: "Roboto", fontSize: 10 }}
        >{`Report Generated At: ${moment().format("MM/DD/YY HH:mma")}`}</Text>
        <View style={[styles.horizontalLine, { marginTop: 6, marginBottom: 12 }]} />
        <Text style={styles.header}>Unit Information</Text>
        <View style={styles.section}>
          <Text style={styles.unitInfo}>
            <Text style={styles.boldText}>Serial Number: </Text> {uniqueIdentifierData.identifier}
          </Text>
          <Text style={styles.unitInfo}>
            <Text style={styles.boldText}>Component: </Text>
            {uniqueIdentifierData?.component?.name}
          </Text>
          <Text style={styles.unitInfo}>
            <Text style={styles.boldText}>Production Status: </Text>
            {uniqueIdentifierData.status}
          </Text>
          <Text style={styles.unitInfo}>
            <Text style={styles.boldText}>Part Number: </Text>
            {uniqueIdentifierData?.part_number?.pn}
          </Text>
        </View>
        <Text style={styles.header}>Genealogy / Packing List</Text>
        <View style={styles.section}>
          <GenealogyItem genealogyObject={rootGenalogyObject} indentLevel={0} />
        </View>
        <Text style={styles.header}>Production Data</Text>
        {processEntries.map((entry, index) => (
          <View key={index} style={styles.subsection}>
            <View wrap={false}>
              <Text style={styles.subheader}>
                {index + 1}: {entry.process?.name}
              </Text>
              <View style={styles.processDetails} wrap={false}>
                <Text style={styles.process}>
                  <Text style={styles.boldText}>Process Version: </Text>
                  {entry?.process_revision ? "v" + entry?.process_revision : "-"}
                </Text>
                <Text style={styles.process}>
                  <Text style={styles.boldText}>Component: </Text>
                  {entry?.component?.name}
                </Text>
                <Text style={styles.process}>
                  <Text style={styles.boldText}>SN / LOT: </Text>
                  {genealogy.find((familyMember) => familyMember?.id === entry?.unique_identifier_id)?.identifier}
                </Text>
                <Text style={styles.process}>
                  <Text style={styles.boldText}>Timestamp: </Text>
                  {moment(entry?.timestamp).format("MM/DD/YY HH:mma")}
                </Text>
                <Text style={styles.process}>
                  <Text style={styles.boldText}>Station: </Text>
                  {entry?.station?.name}
                </Text>
                <Text style={styles.process}>
                  <Text style={styles.boldText}>Result: </Text>
                  <Text style={[entry?.is_pass === true && styles.processPass, entry?.is_pass === false && styles.processFail]}>
                    {entry?.is_pass === true ? "PASS" : entry?.is_pass === false ? "FAIL" : "N/A"}
                  </Text>
                </Text>
                <Text style={styles.process}>
                  <Text style={styles.boldText}>Operator: </Text>
                  {entry?.operator?.first_name} {entry?.operator?.last_name}
                </Text>
                <View style={styles.horizontalLine} />
              </View>
            </View>
            {entry.linkData?.length > 0 && (
              <View wrap={entry.linkData?.length > 10}>
                <Text style={styles.dataType}>Links:</Text>
                {entry.linkData.map((data, index) => (
                  <Text key={index} style={[styles.unitInfo, styles.dataIndented]}>
                    <Text style={styles.mediumText}>{data?.dataset?.name}: </Text>
                    <Text>
                      {" "}
                      {data?.unique_identifiers?.identifier} ({data?.linked_component_name}){!data?.is_active ? " - REMOVED" : ""}
                    </Text>
                  </Text>
                ))}
              </View>
            )}
            {entry.numericalData?.length > 0 && (
              <View wrap={entry.numericalData?.length > 10}>
                <Text style={styles.dataType}>Numerical:</Text>
                {entry.numericalData.map((data, index) => {
                  let specString = "";
                  if (data?.lsl !== null && data?.lsl !== undefined && data?.usl !== null && data?.usl !== undefined) {
                    specString = `, (Must be between: ${data?.lsl} - ${data?.usl}), `;
                  } else if (data?.lsl) {
                    specString = `, (Must be: >${data?.lsl}), `;
                  } else if (data?.usl) {
                    specString = `, (Must be: <${data?.usl}), `;
                  }
                  return (
                    <Text key={index} style={[styles.unitInfo, styles.dataIndented, { width: 510 }]}>
                      <Text style={styles.mediumText}>{data?.dataset?.name}: </Text>
                      <Text style={data?.value === "PASS" ? styles.dataPass : data?.value === "FAIL" ? styles.dataFail : {}}>
                        {data.value + " "}
                      </Text>
                      {data?.unit || ""}
                      {specString}
                      <Text style={data?.is_pass === true ? styles.dataPass : data?.is_pass === false ? styles.dataFail : {}}>
                        {data?.is_pass === true ? " PASS" : data?.is_pass === false ? " FAIL" : ""}
                      </Text>
                    </Text>
                  );
                })}
              </View>
            )}
            {entry.textData?.length > 0 && (
              <View wrap={entry.textData?.length > 10}>
                <Text style={styles.dataType}>Text:</Text>
                {entry.textData.map((data, index) => {
                  return (
                    <Text key={index} style={[styles.unitInfo, styles.dataIndented, { width: 510 }]}>
                      <Text style={styles.mediumText}>{data?.dataset?.name}: </Text>
                      <Text style={data?.value === "PASS" ? styles.dataPass : data?.value === "FAIL" ? styles.dataFail : {}}>
                        {data.value + " "}
                      </Text>
                    </Text>
                  );
                })}
              </View>
            )}
            {entry.checkboxData?.length > 0 && (
              <View wrap={entry.checkboxData?.length > 10}>
                <Text style={styles.dataType}>Checkboxes:</Text>
                {entry.checkboxData.map((data, index) => (
                  <View key={index} wrap={false} style={[{ flexDirection: "row" }, styles.dataIndented]}>
                    {data.is_checked ? <Checkbox isChecked={true} /> : <Checkbox isChecked={false} />}
                    <Text style={[styles.unitInfo, { width: 480 }]}>{data?.dataset?.name}</Text>
                  </View>
                ))}
              </View>
            )}
            {entry.datetimeData?.length > 0 && (
              <View wrap={entry.datetimeData?.length > 10}>
                <Text style={styles.dataType}>Datetimes:</Text>
                {entry.datetimeData.map((data, index) => {
                  return (
                    <Text key={index} style={[styles.unitInfo, styles.dataIndented, { width: 510 }]}>
                      <Text style={styles.mediumText}>{data?.dataset?.name}: </Text>
                      <Text> {moment(data.value).format("MM/DD/YY") ?? "" + " "}</Text>
                    </Text>
                  );
                })}
              </View>
            )}
            {entry.imageData?.length > 0 && (
              <>
                {/* First Row (with title so no wraping allowed) */}
                <View wrap={false}>
                  <Text style={styles.dataType}>Images:</Text>
                  <View style={styles.imageContainer}>
                    {entry.imageData.slice(0, pdfOptions.imagesPerRow).map((data, index) => (
                      <View key={index} wrap={false}>
                        <CanvasImage
                          key={index}
                          style={[styles.dataImage, { width: 515 / pdfOptions.imagesPerRow }]}
                          url={data?.url}
                          getBase64ImageFromUrl={getBase64ImageFromUrl}
                        />
                        <Text
                          style={[
                            styles.unitInfo,
                            styles.mediumText,
                            styles.dataImageCaption,
                            { width: 515 / pdfOptions.imagesPerRow - 10 * pdfOptions.imagesPerRow },
                          ]}
                        >
                          {data?.dataset?.name}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
                {/* Subsequent rows */}
                {entry.imageData.length > pdfOptions.imagesPerRow && (
                  <View style={styles.imageContainer}>
                    {entry.imageData.slice(pdfOptions.imagesPerRow).map((data, index) => (
                      <View key={index} wrap={false}>
                        <CanvasImage
                          key={index}
                          style={[styles.dataImage, { width: 515 / pdfOptions.imagesPerRow }]}
                          url={data?.url}
                          getBase64ImageFromUrl={getBase64ImageFromUrl}
                        />
                        <Text
                          style={[
                            styles.unitInfo,
                            styles.mediumText,
                            styles.dataImageCaption,
                            { width: 515 / pdfOptions.imagesPerRow - 10 * pdfOptions.imagesPerRow },
                          ]}
                        >
                          {data?.dataset?.name}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}
              </>
            )}
          </View>
        ))}
      </Page>
    </Document>
  );
}

export default SnLookupPrint;
