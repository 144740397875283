import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "./Loader";
import Button from "./primitives/Button";

export interface FileInfo {
  name: string;
  size?: number;
  type?: string;
}

interface FileUploadBoxProps {
  handleUploadFiles: (files: File[]) => void;
  fileInfo: FileInfo | null;
  handleResetFile?: () => void;
  onBrowseFocus?: () => void;
  icon?: any;
  prompt?: string;
  inactive?: boolean;
  allowPaste?: boolean;
  allowMultiple?: boolean;
  className?: string;
  isLoading?: boolean;
}

const FileUploadBox: React.FC<FileUploadBoxProps> = ({
  handleUploadFiles,
  fileInfo,
  handleResetFile,
  onBrowseFocus,
  icon,
  prompt,
  inactive,
  allowPaste,
  className,
  isLoading,
}) => {
  const [dragOver, setDragOver] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
    handleUploadFiles(Array.from(e.dataTransfer.files));
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
  };

  const onPaste = (e: any) => {
    const clipboardData = e.clipboardData;
    const items = clipboardData.items;

    if (!items) return;

    const files: File[] = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        if (file) files.push(file);
      }
    }
    handleUploadFiles(files);
  };

  const handleReset = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (handleResetFile) handleResetFile();
    if (fileInputRef.current) fileInputRef.current.value = null!;
  };

  useEffect(() => {
    const handlePaste = (e: any) => {
      if (allowPaste) {
        onPaste(e);
      }
    };

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [allowPaste]);

  return (
    <div
      className={`m-2 flex min-h-0 min-w-0 flex-grow flex-col items-center rounded-sm text-center ${dragOver && !inactive && "ring-serial-palette-200 ring"} ${className}`}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
        {isLoading ? (
          <Loader styleOverride="h-[50px] w-[50px]" />
        ) : (
          <FontAwesomeIcon icon={icon ? icon : faFileUpload} size="3x" className="text-serial-palette-200 hidden h-[50px] sm:block" />
        )}
        <div className="w-full flex-col justify-start space-y-2 text-xs sm:text-sm">
          <p className="truncate not-italic">{fileInfo ? fileInfo.name : prompt ?? "Drag and drop your file here or"}</p>
          <div className="flex flex-col justify-center gap-1 sm:flex-row">
            <Button
              id="file-upload-box-browse-button"
              onFocus={onBrowseFocus ?? (() => {})}
              disabled={inactive}
              tabIndex={inactive ? -1 : 0}
              variant="outline"
              onClick={(e) => {
                e.preventDefault();
                fileInputRef.current?.click();
              }}
            >
              {fileInfo ? "Change File" : "Browse Files"}
            </Button>
            {fileInfo && handleResetFile && (
              <Button disabled={inactive} tabIndex={-1} variant="outline" onClick={(e) => handleReset(e)}>
                Reset
              </Button>
            )}
          </div>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.files) handleUploadFiles(Array.from(e.target.files));
          }}
        />
      </div>
    </div>
  );
};

export default FileUploadBox;
