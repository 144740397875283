import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { renderableExtensions } from "@shared/constants/fileHandling";
import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { ParsedResponse } from "@shared/types/apiTypes";
import { CHUNK_SIZE } from "@shared/constants/supabase";

export const fetchComponentInstancesByIdentifierSubstring = async (
  identifierSubstring: string,
  componentId: string,
  maxOptions: number,
  showDefective: boolean = false,
): Promise<UniqueIdentifier[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let query = supabase.from("unique_identifiers").select("*, component:component_id(*)");

  if (componentId) {
    query = query.eq("component_id", componentId);
  }

  query = query.ilike("identifier", `%${identifierSubstring}%`).order("last_updated_at", { ascending: false }).neq("is_archived", true);
  if (!showDefective) {
    query = query.neq("status", UniqueIdentifierStatus.Defective);
  }

  query = query.limit(maxOptions);

  const { data: linkOptions, error: linkOptionsError } = await query;
  if (linkOptionsError) {
    console.error(linkOptionsError);
    console.error("Error getting link options");
    return [];
  }

  return linkOptions;
};

/** Sends a blob to the to the user's downloads folder.
 * If the file is browser renderable, it will also be opened in a new tab.
 * TODO: Move this function to a more appropriate location. (SER-915)
 * TODO: run a find on "document.createElement('a')" to get all locations where this function should be used instead (SER-915)
 *
 * @param {string} url - Blob URL for the file.
 * @param {string} fileName - Name of the file.
 */
export const sentBlobToDownloadsFolder = (url: string, fileName: string, openInBrowser: boolean) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  const extension = fileName.split(".").pop() ?? "txt";
  if (renderableExtensions.includes(extension.toLowerCase()) && openInBrowser) {
    window.open(url, "_blank");
  }
};

/**
 * Fetches a file stored in a Supabase storage bucket and returns a blob URL for the file.
 * Optionally, the file can be reduced in size and / or automatically downloaded to the user's downloads folder.
 * TODO: This function should probably be renamed to something better at some point.
 *
 * @param {string} bucketName - Name of the Supabase storage bucket.
 * @param {string} fileId - Identifier for the file within the bucket.
 * @param {string} companyId - Identifier for the company owning the file.
 * @param {boolean} [reduceSize=false] - Whether to reduce the file size with specified quality and dimensions.
 * @param {boolean} [sendToDownloadsFolder=false] - Whether to automatically trigger a download of the file.
 * @param {string} [fileName] - Optional name to be used when downloading the file.
 *
 * @returns {Promise<string|null>} - Returns a blob URL for the file if successful, or null if an error occurs.
 *
 * @async
 */
export const fetchDownloadUrl = async (
  bucketName: string,
  fileId: string,
  companyId: string,
  reduceSize?: boolean,
  sendToDownloadsFolder?: boolean,
  fileName?: string,
  imageQuality?: "high" | "medium" | "low", // New parameter for specifying image quality
): Promise<string | null> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let options = {};
  if (imageQuality !== "high") {
    switch (imageQuality) {
      case "low":
        options = { transform: { width: 400, resize: "contain" } };
        break;
      case "medium":
        options = { transform: { width: 700, resize: "contain" } };
        break;
    }
  } else if (reduceSize) {
    options = { transform: { width: 1000, resize: "contain" } };
  }

  const { data, error } = await supabase.storage.from(bucketName).download(`${companyId}/${fileId}`, options);

  // Handle errors
  if (error) {
    console.error(error);
    return null;
  }

  // Create a blob url from the data
  const blob = new Blob([data], { type: data.type });
  const url = URL.createObjectURL(blob);

  // Download the file if sendToDownloadsFolder is true
  if (sendToDownloadsFolder) {
    sentBlobToDownloadsFolder(url, fileName ?? fileId, false);
  }

  return url;
};

export const fetchFileSize = async (fileId: string, companyId: string, bucketName: string): Promise<number | null> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);
  const { data, error } = await supabase
    .schema("storage")
    .from("objects")
    .select("metadata")
    .eq("bucket_id", bucketName)
    .eq("name", `${companyId}/${fileId}`);
  if (error || data[0]?.metadata?.size === undefined) {
    console.error(error ?? "Error fetching file size", data);
    return null;
  }
  if (data.length !== 1) {
    console.error("Unexpected number of results from fetchFileSize", data);
    return null;
  }
  return data[0].metadata.size;
};

// ------------- Generic refresh function -------------

export interface SupabaseModifier {
  modifier: "eq" | "gt" | "lt" | "in"; // add more modifiers as needed
  key: string;
  value: string | string[];
}

// Refreshes data from a supabase table that may have more than 5000 rows.
export const fetchLargeTable = async <T>(
  tableName: string,
  pKey: string,
  selectString: string,
  modifiers: SupabaseModifier[],
  allowRetries: boolean = true,
) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  // Get count
  let query = supabase.from(tableName).select(selectString, { count: "exact", head: true });
  for (const modifier of modifiers) {
    switch (modifier.modifier) {
      case "eq":
        query = query.eq(modifier.key, modifier.value);
        break;
      case "gt":
        query = query.gt(modifier.key, modifier.value);
        break;
      case "lt":
        query = query.lt(modifier.key, modifier.value);
        break;
      case "in":
        if (!Array.isArray(modifier.value)) {
          throw new Error("Value for 'in' modifier must be an array");
        }
        if (modifier.value.length > 100) {
          console.error("'in' modifier value has more than 100 items. High risk of '429 URI too long' issues.");
        }
        query = query.in(modifier.key, modifier.value);
        break;
    }
  }
  const { count: totalCount, error: countError } = await query;
  if (countError || !totalCount) {
    if (countError) console.error(countError);
    return [];
  }

  const allData: T[] = [];

  // Get the data in chunks of CHUNK_SIZE. Query limit is 5000 rows per query so use CHUNK_SIZE=4500 to be safe.
  // This could lead to some data being missed if the table is updated while the data is being fetched.
  // It could also lead to duplicate data if the table is updated while the data is being fetched.
  for (let offset = 0; offset < totalCount; offset += CHUNK_SIZE) {
    let dataQuery;
    dataQuery = supabase.from(tableName).select(selectString);
    for (const modifier of modifiers) {
      switch (modifier.modifier) {
        case "eq":
          dataQuery = dataQuery.eq(modifier.key, modifier.value);
          break;
        case "gt":
          dataQuery = dataQuery.gt(modifier.key, modifier.value);
          break;
        case "lt":
          dataQuery = dataQuery.lt(modifier.key, modifier.value);
          break;
        case "in":
          if (!Array.isArray(modifier.value)) {
            throw new Error("Value for 'in' modifier must be an array");
          }
          if (modifier.value.length > 100) {
            console.error("'in' modifier value has more than 100 items. This may cause performance issues.");
          }
          dataQuery = dataQuery.in(modifier.key, modifier.value);
          break;
      }
    }
    dataQuery = dataQuery
      .order(pKey, { ascending: true })
      .range(offset, offset + CHUNK_SIZE - 1)
      .returns<T[]>();
    const { data, error } = await dataQuery;
    if (!error && data && data.length > 0) {
      allData.push(...data);
    } else {
      console.log(error);
    }
  }

  // Check uniqueness of the IDs in the data
  const allIdsUnique = allData.length === new Set(allData.map((item: any) => item[pKey])).size;

  // if the ids are not unique, load the data again
  if (!allIdsUnique && allowRetries) {
    console.log(`Duplicate IDs found in ${tableName}. Refreshing data...`);
    const newAllData: T[] = await fetchLargeTable(tableName, pKey, selectString, modifiers, false);
    return newAllData;
  }

  return allData;
};

// Sometimes the rpc calls fail with "An invalid response was received from the upstream server"
// We wrap get calls to RPC functions in this function to retry them a few times before giving up
export const getRpcWithRetries = async <T>(
  rpcName: string,
  params?: Record<string, unknown>,
  maxRetries: number = 3,
  delay: number = 250,
): Promise<ParsedResponse<T>> => {
  let attempt = 0;
  let latestErrorMessage: string | undefined;
  const supabase = getSupabase();
  while (attempt < maxRetries) {
    const { data, error } = await supabase.rpc(rpcName, params).returns<T>();
    if (!error) {
      return { data, error: undefined };
    }
    latestErrorMessage = error.message;
    attempt++;
    console.log(`Retry ${attempt}/${maxRetries} for ${rpcName} failed:`, error.message);
    await new Promise((resolve) => setTimeout(resolve, delay));
    delay *= 2; // Double the delay for the next retry
  }
  console.error(`Failed to get ${rpcName} after ${maxRetries} retries: ${latestErrorMessage}`);
  return { data: undefined, error: latestErrorMessage };
};
