import { useContext, useEffect, useMemo } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import ProcessBuilderStep from "./ProcessBuilderStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { StepsTruthTable } from "../hooks/useStepsTruthTable";

interface ProcessBuilderPreviewProps {
  stepsTruthTable: StepsTruthTable;
}

const ProcessBuilderPreview: React.FC<ProcessBuilderPreviewProps> = ({ stepsTruthTable }) => {
  const { draftProcess, selectedStepIndex, setSelectedStepIndex, enablePreviewMode, previewPartNumber } = useContext(ProcessBuilderContext);

  const stepVisibilityFlags = useMemo(() => {
    if (!previewPartNumber) {
      return draftProcess?.process_steps.map((step) => !step.is_hidden) ?? [];
    }
    return stepsTruthTable[previewPartNumber.id]; // note: the stepsTruthTable already filters out hidden steps
  }, [previewPartNumber, stepsTruthTable]);

  // set the selected step index to the first visible step if it is not visible
  useEffect(() => {
    if (selectedStepIndex === null || !stepVisibilityFlags[selectedStepIndex]) {
      setSelectedStepIndex(stepVisibilityFlags.findIndex((flag) => flag) ?? 0);
    }
  }, [selectedStepIndex, stepVisibilityFlags]);

  const { previewModeProcessStep, previewModeStepIndex, totalSteps, percentComplete } = useMemo(() => {
    // if critical data is missing, return
    if (!draftProcess || !enablePreviewMode || selectedStepIndex === null) {
      return {
        previewModeProcessStep: null,
        previewModeStepIndex: null,
        totalSteps: null,
        percentComplete: null,
      };
    }
    // remove steps that are not visible based on the step filters
    const filteredSteps = draftProcess.process_steps.filter((_step, index) => stepVisibilityFlags[index]);
    const selectedStep = draftProcess.process_steps[selectedStepIndex];
    // the selected step index relative to the visible steps (aka: number of steps before the selected step that are visible)
    const relativeSelectedStepIndex = filteredSteps.slice(0, selectedStepIndex).filter((_step, index) => stepVisibilityFlags[index]).length;
    return {
      previewModeProcessStep: selectedStep,
      previewModeStepIndex: relativeSelectedStepIndex,
      totalSteps: filteredSteps.length,
      percentComplete: Math.floor((100 * relativeSelectedStepIndex) / filteredSteps.length + 3),
    };
  }, [draftProcess, enablePreviewMode, selectedStepIndex, stepVisibilityFlags]);

  const findPreviousStep = (currStepIndex: number) => {
    // if the current step is the first step, return the current step
    if (currStepIndex === 0) return currStepIndex;
    // return the previous visible step
    const prevVisibleStepIndex = stepVisibilityFlags.slice(0, currStepIndex).lastIndexOf(true);
    // if there is no previous visible step, return the current step
    if (prevVisibleStepIndex === -1) return currStepIndex;
    return prevVisibleStepIndex;
  };

  const findNextStep = (currStepIndex: number) => {
    // if the current step is the last step, return the current step
    if (currStepIndex === stepVisibilityFlags.length - 1) return currStepIndex;
    // return the next visible step
    const nextVisibleStepIndex = stepVisibilityFlags.slice(currStepIndex + 1).indexOf(true);
    // if there is no next visible step, return the current step
    if (nextVisibleStepIndex === -1) return currStepIndex;
    return nextVisibleStepIndex + currStepIndex + 1;
  };

  return (
    <>
      {previewModeProcessStep && <ProcessBuilderStep step={previewModeProcessStep} stepIndex={selectedStepIndex} previewMode={true} />}
      {previewModeProcessStep && (
        <div className="flex h-10 w-full shrink-0 items-center justify-between border-t bg-white px-3">
          <div />
          <div className="flex w-1/3 items-center gap-x-3">
            {/* Progress bar with width of 100% divided by number of steps */}
            <div className="h-4 w-full overflow-hidden rounded-full border">
              <div className="h-full bg-green-500 transition-all" style={{ width: `${percentComplete}%` }} />
            </div>
            <div className="whitespace-nowrap">{`Step ${previewModeStepIndex + 1} of ${totalSteps}`}</div>
          </div>
          <div className="flex items-center gap-x-1.5">
            <button
              onClick={() => setSelectedStepIndex(findPreviousStep(selectedStepIndex))}
              className={`btn btn-sm serial-btn-light h-7 w-24 gap-x-1 ${previewModeStepIndex == 0 && "opacity-50"}`}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
              <span className="hidden md:inline">Previous</span>
            </button>
            <button
              onClick={() => setSelectedStepIndex(findNextStep(selectedStepIndex))}
              className="btn btn-sm serial-btn-success h-7 w-24 gap-x-1"
            >
              <span className="hidden md:inline">{previewModeStepIndex === totalSteps - 1 ? "Submit" : "Next"}</span>
              {previewModeStepIndex !== totalSteps - 1 && <FontAwesomeIcon icon={faCaretRight} />}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProcessBuilderPreview;
