import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthState } from "./types";
import AuthSignIn from "./components/AuthSignIn";
import AuthForgotPassword from "./components/AuthForgotPassword";
import AuthResetPassword from "./components/AuthResetPassword";
import Logo from "@images/serial_primary_black.png";

function Auth() {
  const location = useLocation();
  const [authState, setAuthState] = useState<AuthState>(AuthState.SignIn);

  useEffect(() => {
    const pathname = location.pathname;
    switch (pathname) {
      case "/auth/signin":
        setAuthState(AuthState.SignIn);
        break;
      case "/auth/forgotpassword":
        setAuthState(AuthState.ForgotPassword);
        break;
      case "/auth/resetpassword":
        setAuthState(AuthState.ResetPassword);
        break;
      case "/auth/createpassword":
        // These are secretly the same page with different text renderings
        setAuthState(AuthState.ResetPassword);
        break;
      default:
        setAuthState(AuthState.SignIn);
    }
  }, [location]);

  return (
    <div className="light bg-serial-palette-900 flex h-screen items-center justify-center text-black">
      <div className="flex h-full w-full flex-col items-center bg-white pb-10 sm:h-auto sm:w-[400px] sm:rounded-md">
        <img src={Logo} alt="Serial Logo White" width="110" className="pb-5 pt-10" />
        {authState === AuthState.SignIn && <AuthSignIn />}
        {authState === AuthState.ForgotPassword && <AuthForgotPassword />}
        {authState === AuthState.ResetPassword && <AuthResetPassword />}
        {/*
        {authState === AuthState.TwoFactor && <AuthTwoFactor setAuthState={setAuthState} handleSignIn={handleSignIn}/>} 
        {authState === AuthState.PasswordChanged && <AuthPasswordChanged setAuthState={setAuthState}/>}
        */}
      </div>
    </div>
  );
}

export default Auth;
