import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import GraphBuilderViews from "./components/GraphBuilderViews";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import GraphBuilderGraph from "./components/GraphBuilderGraph";
import { ReportTemplateType } from "@shared/types/databaseEnums";
import { ReportTemplateGraphBuilderView } from "@shared/types/databaseTypes";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { checkIsUuid } from "@shared/utils/helpers";

const defaultBreadcrumbs = [
  {
    label: "Graph Builder",
    link: "/graphs",
  },
];

const GraphBuilder = () => {
  const currentUser = useCurrentUser();

  const dbIsLoaded = useSelector((state: RootState) => state.db.isLoaded);
  const graphBuilderViews: ReportTemplateGraphBuilderView[] = useSelector((state: RootState) => state.db.reportTemplates).filter((r) => {
    if (!currentUser) return false;
    return (
      r.type === ReportTemplateType.GraphBuilderView &&
      (r.created_by === currentUser.supabase_uid || Object.keys(r.shared_with).includes(currentUser.supabase_uid) || r.is_public)
    );
  }) as ReportTemplateGraphBuilderView[];
  const location = useLocation();
  const navigate = useNavigate();

  const activeGraphBuilderView = useMemo(() => {
    const viewId = location.pathname.split("/")[3];
    const view = graphBuilderViews.find((view) => view.id === viewId);
    if (!view && checkIsUuid(viewId) && dbIsLoaded) {
      navigate("/graphs");
    }
    return view;
  }, [graphBuilderViews, location.pathname]);

  const breadcrumbs: Breadcrumb[] = useMemo(() => {
    if (activeGraphBuilderView) {
      return [
        ...defaultBreadcrumbs,
        {
          label: activeGraphBuilderView.name,
          link: `/graphs/views/${activeGraphBuilderView.id}`,
        },
      ];
    } else if (location.pathname.includes("/graphs/views")) {
      return [
        ...defaultBreadcrumbs,
        {
          label: "Views",
          link: "/graphs/views",
        },
      ];
    } else {
      return defaultBreadcrumbs;
    }
  }, [activeGraphBuilderView, location.pathname]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <Header breadcrumbs={breadcrumbs} />
        {!activeGraphBuilderView && location.pathname.includes("/graphs/views") ? (
          <GraphBuilderViews views={graphBuilderViews} />
        ) : (
          <GraphBuilderGraph view={activeGraphBuilderView} />
        )}
      </div>
    </div>
  );
};

export default GraphBuilder;
