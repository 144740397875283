import { tailwindConfig } from "@shared/utils/helpers";
import { DefaultColors } from "tailwindcss/types/generated/colors";

export const defaultContext = {
  selectedDataset: null,
  setSelectedDataset: () => {},
  data: [],
  datasetEditModalOpen: false,
  setDatasetEditModalOpen: () => {},
  selectedDateRange: { from: undefined, to: undefined },
  setSelectedDateRange: () => {},
  isLoading: false,
};

const BASE_COLORS = [
  "orange",
  "blue",
  "pink",
  "yellow",
  "indigo",
  "teal",
  "cyan",
  "purple",
  "emerald",
  "amber",
  "lime",
  "fuchsia",
  "sky",
  "rose",
];
const BASE_SATURATION_LEVELS = [300, 200, 100, 400, 500, 600, 700];
export const DEFAULT_DONUT_CHART_COLORS = BASE_SATURATION_LEVELS.map((level) =>
  BASE_COLORS.map((color) => {
    const colors = tailwindConfig().theme?.colors as DefaultColors;
    const colorLevels = colors[color as keyof DefaultColors] as unknown as { [key: number]: string };
    return colorLevels?.[level];
  }),
).flat();

export const CHART_DEBOUNCE_DELAY = 400;
