import React, { useContext, useEffect, useState } from "react";
import { linkAllProcesses, updateStation } from "../connections/supabase";
import { StationType } from "@shared/types/databaseEnums";
import { Loader } from "@shared/components/Loader";
import { StationWithLinksAndProcessEntries } from "../types";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface StationDetailsSettingsProps {
  selectedStation: StationWithLinksAndProcessEntries | null;
  reloadData: () => Promise<StationWithLinksAndProcessEntries[] | null>;
}

// regex to check if the station name is the same as the format used for new stations
// example name: 'New Station 2021-09-09 10:00:00'
const isNewStationRegex = /^New Station \d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

const StationDetailsSettings: React.FC<StationDetailsSettingsProps> = ({ selectedStation, reloadData }) => {
  const [stationLocalCopy, setStationLocalCopy] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const observe = useContext(ObservabilityContext);

  // save the station settings
  const handleSave = async () => {
    observe.track("station_save-settings");
    if (stationLocalCopy && selectedStation) {
      setIsLoading(true);
      // update only specific columns
      await updateStation(selectedStation.id, {
        name: stationLocalCopy.name,
        location: stationLocalCopy.location,
        type: stationLocalCopy.type,
        use_api: stationLocalCopy.use_api,
      });
      // if the station type is set to universal, link all processes
      if (stationLocalCopy.type === StationType.Universal) {
        await linkAllProcesses(selectedStation.id);
      }
      // reload the data
      await reloadData();
      setIsLoading(false);
    }
  };

  // set the local copy of the station when the station changes
  useEffect(() => {
    setStationLocalCopy(selectedStation);
  }, [selectedStation]);

  return (
    <div className="relative flex flex-col">
      {stationLocalCopy && (
        <>
          {/* Name and Location */}
          <div className="flex items-center justify-between gap-4 py-3">
            <div className="flex w-1/2 flex-col gap-y-1">
              <div className="text-serial-palette-500 font-bold">Name</div>
              <input
                type="text"
                className={`form-input w-full ${isNewStationRegex.test(selectedStation?.name ?? "") && "ring ring-amber-200"}`}
                onChange={(e) => setStationLocalCopy({ ...stationLocalCopy, name: e.target.value })}
                value={stationLocalCopy.name || ""}
              />
            </div>
            <div className="flex w-1/2 flex-col gap-y-1">
              <div className="text-serial-palette-500 font-bold">Location</div>
              <input
                type="text"
                className="form-input w-full"
                onChange={(e) => setStationLocalCopy({ ...stationLocalCopy, location: e.target.value })}
                value={stationLocalCopy.location || ""}
              />
            </div>
          </div>
          {/* API Toggle */}
          <div className="flex items-center justify-between py-3">
            <div>
              <div className="text-serial-palette-500 font-bold">API Station</div>
              <div className="text-sm">Does this stations upload data using the Serial API?</div>
            </div>
            <div
              className="ml-4 flex items-center"
              onClick={() => setStationLocalCopy({ ...stationLocalCopy, use_api: !stationLocalCopy.use_api })}
            >
              <div className="mr-2 text-sm italic">{stationLocalCopy.use_api ? "On" : "Off"}</div>
              <div className="form-switch">
                <input type="checkbox" id="comments" className="sr-only" checked={stationLocalCopy.use_api} onChange={() => {}} />
                <label className="bg-serial-palette-200" htmlFor={"switch-company-user-pins"}>
                  <span className="bg-white " aria-hidden="true"></span>
                  <span className="sr-only">Switch label</span>
                </label>
              </div>
            </div>
          </div>
          {/* Show the station ID if using the API */}
          {selectedStation?.id && stationLocalCopy.use_api && (
            <div className="flex flex-col py-3">
              <div className="text-serial-palette-500 pb font-bold">Station ID</div>
              <div className="text-sm">
                Use this string in your script if you would like to identify the device being used to upload data
              </div>
              <div className="tems-center mb-5 mt-2 flex w-full flex-wrap">
                <span className="bg-serial-palette-600 text-serial-palette-200 rounded px-2 font-medium">
                  <pre>{selectedStation?.id}</pre>
                </span>
                <CopyToClipboard text={selectedStation.id} />
              </div>
            </div>
          )}
          {/* Station Mode Toggle */}
          {!stationLocalCopy.use_api && (
            <div className="flex justify-between py-3">
              <div>
                <div className="text-serial-palette-500 font-bold">Mode</div>
                <div className="text-sm">
                  A dedicated workstation can upload data for specific processes only. A Universal workstation can upload data for any
                  process.
                </div>
              </div>
              <div className="ml-8 mt-6 flex flex-wrap items-center lg:flex-nowrap">
                <button
                  className={`btn h-10 px-4 ${stationLocalCopy.type === StationType.Dedicated ? "bg-serial-palette-800 text-white" : "text-serial-palette-600 bg-white"} hover:bg-serial-palette-800 border-serial-palette-200 rounded-none first:rounded-l-md first:border-r-transparent last:rounded-r-md hover:text-white`}
                  onClick={() => setStationLocalCopy({ ...stationLocalCopy, type: StationType.Dedicated })}
                >
                  Dedicated
                </button>
                <button
                  className={`btn h-10 px-4 ${stationLocalCopy.type === StationType.Universal ? "bg-serial-palette-800 text-white" : "text-serial-palette-600 bg-white"} hover:bg-serial-palette-800 border-serial-palette-200 rounded-none first:rounded-l-md first:border-r-transparent last:rounded-r-md hover:text-white`}
                  onClick={() => setStationLocalCopy({ ...stationLocalCopy, type: StationType.Universal })}
                >
                  Universal
                </button>
              </div>
            </div>
          )}
          {/* Save Button */}
          <div className="flex w-full justify-center">
            {JSON.stringify(stationLocalCopy) !== JSON.stringify(selectedStation) && (
              <>
                {isLoading ? (
                  <Loader styleOverride="mt-4 h-6 w-6" />
                ) : (
                  <button
                    className={`btn-sm relative z-10 mb-4 mt-2 h-9 w-28 whitespace-nowrap border border-amber-600 bg-amber-100 text-amber-600`}
                    onClick={() => handleSave()}
                  >
                    <span className="absolute flex h-9 w-[90px]">
                      <span className="absolute left-4 top-2 z-20 flex h-5 w-[60px] shrink-0 animate-ping rounded opacity-75 ring-4 ring-amber-600"></span>
                    </span>
                    <span className="z-30">Save Settings</span>
                  </button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StationDetailsSettings;
