import { createSlice } from "@reduxjs/toolkit";

export interface AuthReduxState {
  token: string | null;
  supabase_uid: string | null;
  role: string | null;
  company_id: string | null;
}

const initialState: AuthReduxState = {
  token: null,
  supabase_uid: null,
  role: null,
  company_id: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setSupabaseUid: (state, action) => {
      state.supabase_uid = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setCompanyId: (state, action) => {
      state.company_id = action.payload;
    },
    reduxAuthReset: () => initialState,
  },
});

export const { setToken, setSupabaseUid, setRole, setCompanyId, reduxAuthReset } = authSlice.actions;

export default authSlice.reducer;
