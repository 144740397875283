import { ComponentType, DataType } from "@shared/types/databaseEnums";
import { Component, Dataset, Field, FieldType, ProcessWithReferences } from "@shared/types/databaseTypes";

export const validateField = (field: Field): { isValid: boolean; message?: string } => {
  // field must have a prompt
  if (!field.prompt) {
    return { isValid: false, message: "Field must have a prompt." };
  }
  // field must have a group_name
  if (!field.group_name) {
    console.error("Field must have a group name."); // this should never happen
    return { isValid: false, message: "Field must have a group name." };
  }
  // field must have a dataset_id
  if (!field.dataset_id) {
    console.error("Field must have a dataset id."); // this should never happen
    return { isValid: false, message: "Field must have a dataset id." };
  }
  return { isValid: true };
};

export const validateDataset = (dataset: Dataset, existingDatasets: Dataset[]): { isValid: boolean; message?: string } => {
  const existingDataset = existingDatasets.find((d) => d.id === dataset.id);
  // --- CHECK #1 --- : Dataset types cannot be changed by process builder
  if (existingDataset && existingDataset.data_type !== dataset.data_type) {
    console.error("Dataset types cannot be changed by process builder."); // this should never happen
    return { isValid: false, message: "Dataset types cannot be changed by process builder." };
  }
  // --- CHECK #2 --- : Only numerical datasets can have a usl, lsl, or unit
  if (dataset.data_type !== DataType.ParametricQuantitative && (dataset.usl !== null || dataset.lsl !== null || dataset.unit !== null)) {
    console.error("Only numerical datasets can have a usl, lsl, or unit."); // this should never happen
    return { isValid: false, message: "Only numerical datasets can have a usl, lsl, or unit." };
  }
  // --- CHECK #3 --- : usl and lsl must be numerical values
  if (isNaN(Number(dataset.usl)) || isNaN(Number(dataset.lsl))) {
    return { isValid: false, message: "LSL and USL must be numerical values." };
  }
  // --- CHECK #3 --- : Only certain datasets can have an expected value property [BOOLEAN, NUMERICAL, TEXT]
  if (
    dataset.expected_value !== null &&
    ![DataType.ParametricQuantitative, DataType.ParametricQualitative, DataType.Checkbox, DataType.PassFail].includes(dataset.data_type)
  ) {
    console.error("Only numerical, text, and boolean datasets can have an expected value."); // this should never happen
    return { isValid: false, message: "Only numerical, text, and boolean datasets can have an expected value." };
  }
  // --- CHECK #4 --- : Link datasets must have a child_component_id
  if (dataset.data_type === DataType.Link && !dataset.child_component_id) {
    return { isValid: false, message: "Link fields must have a child component defined." };
  }
  return { isValid: true };
};

export const validateProcess = (process: ProcessWithReferences, components: Component[]): { isValid: boolean; message?: string } => {
  // --- CHECK #1 --- : Non api process must have at least one step
  if (!process.process_steps.length && !process.use_api) {
    return { isValid: false, message: "Process must have at least one step." };
  }
  // --- CHECK #2 --- : API processes must have no steps
  if (process.process_steps.length && process.use_api) {
    return { isValid: false, message: "API processes must have no steps." };
  }
  // --- CHECK #3 --- : Process name must not be empty
  if (!process.name) {
    return { isValid: false, message: "Process must have a name." };
  }
  // --- CHECK #4 --- : Batch processes are not allowed if the process has any SN link fields
  const linkFields = process.process_steps
    .map((step) => step.fields)
    .flat()
    .filter((field) => field.type === FieldType.Link);
  const linkedComponentTypes = linkFields.map((field) => {
    const component = components.find((c) => c.id === field.dataset?.child_component_id);
    return component?.component_type;
  });
  if (linkedComponentTypes.includes(undefined)) {
    console.error("Could not find component for link field.");
    return { isValid: false, message: "Could not find component for link field." };
  }
  if (process.allow_batch && linkedComponentTypes.some((type) => type === ComponentType.Sn)) {
    return { isValid: false, message: "Batch processes are not allowed if the process has any links to serialized components." };
  }
  // --- CHECK #5 --- : Batch processes may not have label printing fields
  const labelPrintingFields = process.process_steps
    .map((step) => step.fields)
    .flat()
    .filter((field) => field.type === FieldType.Label);
  if (process.allow_batch && labelPrintingFields.length) {
    return { isValid: false, message: "Batch processes may not have label printing fields." };
  }
  return { isValid: true };
};
