import React from "react";
import TypeTag from "@shared/components/TypeTag";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { ToastContext } from "@shared/context/ToastProvider";
import { deleteComponentInstanceLink } from "../connections/api";
import { useSelector } from "react-redux";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { filterConditionOperatorStyle } from "@shared/components/filters/types";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

function SnLookUpGenealogyTableItem({ genealogyTree, level, loadData }) {
  genealogyTree.sort((a, b) => a.component_name.localeCompare(b.component_name));

  const navigate = useNavigate();
  const location = useLocation();
  const uid = new URLSearchParams(location.search).get("uid");
  const { triggerConfirmation } = useContext(ToastContext);
  const observe = useContext(ObservabilityContext);
  const components = useSelector((state) => state.db.components);
  const componentInstances = useSelector((state) => state.db.componentInstances);

  const confirmUnlink = (level, part) => {
    const parentIdentifier = componentInstances.find((u) => u.id === part.parent_id);
    const parentComponent = components.find((c) => c.id === parentIdentifier.component_id);
    triggerConfirmation(
      "Confirm Unlink",
      <div>
        <div>Are you sure you want to unlink: </div>
        <div>
          {part.identifier}({part.component_name}) from {parentIdentifier.identifier}({parentComponent.name})?
        </div>
      </div>,
      () => handleUnlink(level, part),
      undefined,
      "Confirm",
    );
  };

  const handleUnlink = async (level, part) => {
    await deleteComponentInstanceLink(part.parent_id, part.id);
    loadData();
  };

  return (
    <div className={`${level === 0 && "px-3"} ${level > 0 && "pl-3"}  `}>
      {genealogyTree.map((part, index) => {
        const component = components.find((c) => c.id === part.component_id);
        return (
          <div key={index}>
            <div className="col flex">
              {level != 0 && (
                <div
                  className={`my-1 rounded border-l-[4px] ${part?.component_type === "SN" ? "border-serial-palette-900" : "border-serial-palette-400"}`}
                ></div>
              )}

              <div className={`group flex w-full items-center justify-between rounded ${part.id != uid && "hover:bg-serial-palette-100"} `}>
                <div className="pl-2">
                  <ComponentAvatar component={component} size="sm" />
                </div>
                <div
                  className={`px-2 py-2 text-left ${part.id != uid && "cursor-pointer"} w-full flex-col rounded`}
                  onClick={() => {
                    navigate("/snlookup?uid=" + part.id), observe.track("sn-lookup_genealogy-nav");
                  }}
                >
                  <div className="flex flex-col flex-wrap">
                    <div className="no-wrap text-left text-xs font-semibold">{part?.component_name}</div>
                    <div className="flex items-center gap-2">
                      <div className="max-w-[300px] truncate">{part?.identifier}</div>
                      <TypeTag type={part?.status} className="h-4 w-7 text-[10px]" hideText />
                    </div>
                  </div>
                </div>
                {level !== 0 && uid !== part.id && (
                  <div className="hidden cursor-pointer group-hover:block" onClick={() => confirmUnlink(level, part)}>
                    <TypeTag type={"UNLINKED"} hideText />
                  </div>
                )}
              </div>
            </div>
            {part.children && <SnLookUpGenealogyTableItem genealogyTree={part.children} loadData={loadData} level={level + 1} />}
          </div>
        );
      })}
    </div>
  );
}

export default SnLookUpGenealogyTableItem;
