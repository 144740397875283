import React from "react";
import { ProcessEntry } from "@shared/types/databaseTypes";
import StationDetailsSnTableRow from "./StationDetailsSnTableRow";

interface StationDetailsSnTableProps {
  processEntries: ProcessEntry[];
}

const StationDetailsSnTable: React.FC<StationDetailsSnTableProps> = ({ processEntries }) => {
  return (
    <div className={`scrollbar-hide flex h-full w-full grow-0 flex-col overflow-scroll rounded-md border`}>
      {/* Tile content */}
      <table className="w-full table-fixed">
        {/* Table header */}
        <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 sticky top-0 rounded-md border-b text-xs font-semibold uppercase">
          <tr>
            <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Identifier</div>
            </th>
            <th className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 xl:block">
              <div className="text-left font-semibold">Component</div>
            </th>
            <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-center font-semibold">Result</div>
            </th>
            <th className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 lg:block">
              <div className="text-left font-semibold">Latest Process</div>
            </th>
            <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Timestamp</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="divide-serial-palette-200 divide-y text-sm">
          {processEntries?.map((entry: ProcessEntry, index) => <StationDetailsSnTableRow key={index} processEntry={entry} index={index} />)}
          {!processEntries ||
            (processEntries.length === 0 && (
              <tr>
                <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5" colSpan={5}>
                  <div className="text-serial-palette-400 text-center italic">No data available</div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default StationDetailsSnTable;
