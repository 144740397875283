import React, { useContext, useEffect, useMemo, useState } from "react";
import { ProductionContext } from "../ProductionProvider";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProcessType, UserRole } from "@shared/types/databaseEnums";
import { ProcessEntry, WorkOrder } from "@shared/types/databaseTypes";
import { fetchProcessEntriesByComponentInstanceId } from "../connections/supabase";
import { faCaretRight, faCheckCircle, faCode, faExclamationCircle, faTimes, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import ProductionSidebar from "./ProductionSidebar";
import ProductionWorkOrderSummary from "./ProductionWorkOrderSummary";
import { InstantiationModalMode, WorkOrderWithCounts } from "../types";
import { WorkOrderStatus } from "@shared/types/databaseEnums";
import { CountsByStatus } from "../types";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { FilteredComponent } from "../types";
import { Component } from "@shared/types/databaseTypes";
import { StationType } from "@shared/types/databaseEnums";
import Button from "@shared/components/primitives/Button";
import { useTranslation } from "react-i18next";

const ProductionProcessSelectorUniversal: React.FC = () => {
  const [focusedProcess, setFocusedProcess] = useState<number>(-1);
  const [processEntries, setProcessEntries] = useState<ProcessEntry[]>([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrderWithCounts | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const {
    db,
    componentInstances,
    user,
    setInstantiationModalOpen,
    setInstantiationModalMode,
    identifiers,
    handleUpdateIdentifiers,
    station,
    stationModalOpen,
    operatorModalOpen,
    setInstantiationIdentifiers,
    setInstantiationComponentId,
    instantiationModalOpen,
    setIdentifiersValidation,
  } = useContext(ProductionContext);

  const navigate = useNavigate();

  const componentInstance = useMemo(() => {
    return componentInstances?.[0];
  }, [componentInstances]);

  const { workOrdersAvailable, partNumbersAvailable } = useMemo(() => {
    const workOrdersAvailable = db.workOrders.filter((workOrder) => workOrder.component_id === componentInstance?.component_id)?.length > 0;
    const partNumbersAvailable =
      db.partNumbers.filter((partNumber) => partNumber.component_id === componentInstance?.component_id)?.length > 0;
    return { workOrdersAvailable, partNumbersAvailable };
  }, [db.workOrders, db.partNumbers, componentInstance]);

  const processes = useMemo(() => {
    return db.componentProcessLinks
      .filter((link) => {
        return link.component_id === componentInstance?.component_id && link.process?.type === ProcessType.Production && link.is_active;
      })
      .sort((a, b) => {
        if (a.order === null && b.order === null) return 0;
        if (a.order === null) return 1;
        if (b.order === null) return -1;
        return a.order - b.order;
      })
      .map((link) => link.process);
  }, [db.componentProcessLinks, componentInstance]);

  // if no modals are open, and it's a universal station, auto focus on input on keydown capture event
  useEffect(() => {
    const handleKeyDownCapture = () => {
      searchInputRef.current?.focus();
    };
    if (!operatorModalOpen && !stationModalOpen && !instantiationModalOpen && station?.type === StationType.Universal) {
      document.addEventListener("keydown", handleKeyDownCapture, true);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDownCapture, true);
    };
  }, [operatorModalOpen, stationModalOpen, instantiationModalOpen]);

  useEffect(() => {
    if (componentInstance?.id) {
      fetchProcessEntriesByComponentInstanceId(componentInstance?.id).then((response) => {
        if (response.error || !response.data) {
          console.error(response.error);
          return;
        }
        setProcessEntries(response.data);
      });
    }
  }, [componentInstance?.id]);

  const numProcessesCompleted = useMemo(() => {
    // Count number of completed processes
    const completedProcesses = processes.map((process, index) => {
      const processEntryExists = processEntries.some((processEntry) => processEntry?.process_id === process?.id);
      const latestProcessEntryIsFail =
        processEntries
          .filter((processEntry) => processEntry?.process_id === process?.id)
          .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())[0]?.is_pass === false;
      if (processEntryExists && !latestProcessEntryIsFail) {
        setFocusedProcess(index + 1);
      }
      return processEntryExists && !latestProcessEntryIsFail;
    });
    return completedProcesses.filter(Boolean).length;
  }, [componentInstance, db.processes, processEntries]);

  const handleOpenInstantiationModal = (identifier: string, componentId: string, mode: InstantiationModalMode) => {
    setInstantiationComponentId(componentId);
    setInstantiationIdentifiers([identifier]);
    setInstantiationModalMode(mode);
    setInstantiationModalOpen(true);
  };

  // run handleUpdateIdentifiers when page first loads
  useEffect(() => {
    if (searchInputRef.current?.value) handleUpdateIdentifiers([searchInputRef.current.value]);
  }, []);

  // add counts if WO is selected on identifier process sequence list
  const handleSelectedWorkOrder = (newWorkOrder: WorkOrderWithCounts | WorkOrder) => {
    if (!sidebarOpen) {
      setSidebarOpen(true);
    } else if (selectedWorkOrder?.id === newWorkOrder.id) {
      setSidebarOpen(false);
    }
    if ("counts_by_status" in newWorkOrder) {
      setSelectedWorkOrder(newWorkOrder);
    } else {
      let statusCounts = countIdentifierStatusesByWorkOrderId(newWorkOrder);
      setSelectedWorkOrder({ ...newWorkOrder, counts_by_status: statusCounts });
    }
  };

  const handleComponentClick = (component: Component, process_id: string) => {
    handleUpdateIdentifiers([]),
      setIdentifiersValidation(null),
      setInstantiationComponentId(component.id),
      navigate(`/production/${station?.id}/${process_id}`);
  };

  // Filter & sort work orders
  const filteredWorkOrders = useMemo<WorkOrderWithCounts[]>(() => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const relevantWorkOrders = db.workOrders.filter((workOrder) => {
      const lastUpdated = workOrder.last_edited_at ? new Date(workOrder.last_edited_at) : null;
      return (
        workOrder.status === WorkOrderStatus.Production ||
        workOrder.status === WorkOrderStatus.Review ||
        (workOrder.status === WorkOrderStatus.Complete && lastUpdated && lastUpdated >= sevenDaysAgo)
      );
    });

    relevantWorkOrders.sort((a, b) => {
      const order = [WorkOrderStatus.Production, WorkOrderStatus.Review, WorkOrderStatus.Complete];
      const statusComparison = order.indexOf(a.status) - order.indexOf(b.status);

      if (statusComparison === 0) {
        const dateA = a.last_edited_at ? new Date(a.last_edited_at) : new Date(0);
        const dateB = b.last_edited_at ? new Date(b.last_edited_at) : new Date(0);

        return dateA.getTime() - dateB.getTime();
      }
      return statusComparison;
    });

    // Append identifier statuses to each work order
    const updatedWorkOrders = relevantWorkOrders.map((workOrder) => {
      const statusCounts = countIdentifierStatusesByWorkOrderId(workOrder);
      return { ...workOrder, counts_by_status: statusCounts };
    });

    return updatedWorkOrders;
  }, [db.workOrders, db.componentInstances]);

  function countIdentifierStatusesByWorkOrderId(workOrder: WorkOrder): CountsByStatus {
    const statusCounts: CountsByStatus = {
      [UniqueIdentifierStatus.Planned]: 0,
      [UniqueIdentifierStatus.Wip]: 0,
      [UniqueIdentifierStatus.Complete]: 0,
      [UniqueIdentifierStatus.Defective]: 0,
    };

    // Filter instances by the given work_order_id
    const filteredInstances = db.componentInstances.filter((instance) => instance.work_order_id === workOrder.id);

    // Count the occurrences of each status
    filteredInstances.forEach((instance) => {
      if (instance.status !== null && statusCounts.hasOwnProperty(instance.status)) {
        statusCounts[instance.status]++;
      }
    });
    return statusCounts;
  }

  const filteredComponents: FilteredComponent[] = useMemo(() => {
    const productionProcesses = db.processes.filter((process) => process.type === "PRODUCTION" && process.use_api === false);
    return db.components
      .filter((component) => component.is_active === true)
      .map((component) => {
        const componentLinkedProcessIds = db.componentProcessLinks
          .filter((link) => link.component_id === component.id && link.is_active === true)
          .map((link) => ({ id: link.process_id, order: link.order }));

        const linkedProductionProcesses = productionProcesses.filter((process) =>
          componentLinkedProcessIds.some((link) => link.id === process.id),
        );

        if (linkedProductionProcesses.length === 0) {
          return null;
        }

        const sortedLinkedProcesses = linkedProductionProcesses.sort((a, b) => {
          const orderA = componentLinkedProcessIds.find((link) => link.id === a.id)?.order ?? Number.MAX_VALUE;
          const orderB = componentLinkedProcessIds.find((link) => link.id === b.id)?.order ?? Number.MAX_VALUE;
          return orderA - orderB;
        });

        const firstProductionProcessWithLowestOrder = sortedLinkedProcesses[0];
        return {
          component,
          firstProductionProcessWithLowestOrder,
        };
      })
      .filter((item): item is FilteredComponent => item !== null); // Correctly filters out null values and assures TypeScript of the type
  }, [db]);

  return (
    <div className="relative flex h-full w-full overflow-hidden">
      <div
        className={`flex h-screen min-h-0 min-w-0 flex-col items-center overflow-auto transition-all ${!sidebarOpen ? "w-full translate-x-0" : "w-3/5 translate-x-0"} `}
      >
        <div className="flex w-full max-w-[1200px] flex-col space-y-4 px-4 md:px-8">
          <div>
            <h2 className="text-serial-palette-800 mb-3 mt-20 text-2xl font-bold">Collect Production Data</h2>
            <div className="border-serial-palette-200 rounded border bg-white">
              <div className="relative">
                <input
                  className="placeholder-serial-palette-400 w-full appearance-none rounded border-0 bg-white py-3 pl-10 pr-4 focus:ring-transparent"
                  placeholder="Enter any serial numbers or lot code…"
                  onChange={(e) => handleUpdateIdentifiers(e.target.value.split(","))}
                  onBlur={(e) => handleUpdateIdentifiers(e.target.value.split(",").map((identifier) => identifier.trim()))}
                  value={identifiers[0] ?? ""}
                  type="search"
                  ref={searchInputRef}
                />
                <button tabIndex={-1} className="group absolute inset-0 right-auto">
                  <svg
                    className="text-serial-palette-400 group-hover:text-serial-palette-500 ml-4 mr-2 h-4 w-4 shrink-0 fill-current"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                    <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                  </svg>
                </button>
                {identifiers[0] && (
                  <button className="text-serial-palette-400 btn absolute right-1 h-full" onClick={() => handleUpdateIdentifiers([])}>
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                  </button>
                )}
              </div>
              {componentInstance && (
                <div className="flex w-full flex-col gap-y-3 border-t p-5 pt-2">
                  <div className="flex w-full items-center gap-x-2">
                    <div className="font-bold">Component:</div>
                    <div className="font-light">{componentInstance?.component?.name}</div>
                  </div>
                  {workOrdersAvailable && (
                    <div className="group flex w-full items-center gap-x-2">
                      <div className="font-bold">{t("workOrder")}:</div>
                      <div
                        onClick={() => {
                          if (componentInstance?.work_order !== undefined) handleSelectedWorkOrder(componentInstance?.work_order);
                        }}
                        className={`font-light ${componentInstance?.work_order?.name ? "cursor-pointer hover:underline" : "text-serial-palette-500 italic"}`}
                      >
                        {componentInstance?.work_order?.name ?? "None"}
                      </div>
                      {user?.role === UserRole.Admin && (
                        <Button
                          className="hidden group-hover:flex"
                          size="xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInstantiationModal(identifiers[0], componentInstance.component_id, InstantiationModalMode.Update);
                          }}
                        >
                          Set {t("workOrder")}
                        </Button>
                      )}
                    </div>
                  )}
                  {partNumbersAvailable && (
                    <div className="group flex w-full items-center gap-x-2">
                      <div className="font-bold">Part Number:</div>
                      <div className={`font-light ${componentInstance?.part_number?.pn ? "" : "text-serial-palette-500 italic"}`}>
                        {componentInstance?.part_number?.pn ?? "None"}
                      </div>
                      {user?.role === UserRole.Admin && (
                        <Button
                          className="hidden group-hover:flex"
                          size="xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenInstantiationModal(identifiers[0], componentInstance.component_id, InstantiationModalMode.Update);
                          }}
                        >
                          Set Part Number
                        </Button>
                      )}
                    </div>
                  )}
                  <div className="font-bold">
                    Process
                    <span className="px-2 font-normal">|</span>
                    {processes.length ? (
                      <span className="font-light">{Math.floor(100 * (numProcessesCompleted / processes.length))}% completed:</span>
                    ) : (
                      <span className="font-light">No Processes</span>
                    )}
                  </div>
                  <div className="flex flex-col">
                    {processes.map((process, index) => {
                      // sort process entries by timestamp to ensure the latest entry is first
                      const latestProcessEntry = processEntries
                        ?.filter((processEntry) => processEntry?.process_id === process?.id)
                        .sort((a, b) => Number(new Date(b?.timestamp)) - Number(new Date(a?.timestamp)))[0];

                      // default icon and color
                      let icon = faCircle;
                      let iconClassName = "text-serial-palette-300";

                      // if latest process entry has upload error, show upload error icon
                      if (latestProcessEntry?.upload_error) {
                        icon = faExclamationCircle;
                        iconClassName = "text-yellow-500";
                      }
                      // if latest process entry is fail, show fail icon
                      else if (latestProcessEntry?.is_pass === false) {
                        icon = faXmarkCircle;
                        iconClassName = "text-red-600";
                      }
                      // if latest process entry is pass, show pass icon
                      else if (latestProcessEntry?.id) {
                        icon = faCheckCircle;
                        iconClassName = "text-green-600";
                      }
                      // if process is mandatory, show mandatory icon
                      else if (process?.is_mandatory) {
                        icon = faCircle;
                        iconClassName = "text-red-300";
                      }

                      return (
                        <button
                          tabIndex={focusedProcess === index ? 0 : -1}
                          disabled={process?.use_api}
                          onClick={() => navigate(`/production/${station?.id}/${process?.id}`)}
                          onMouseEnter={() => setFocusedProcess(index)}
                          key={index}
                          className={`flex items-center justify-start rounded-lg border-2 px-3 py-2 text-left ${focusedProcess === index ? (process?.use_api ? "cursor-not-allowed border-neutral-100" : "border-sky-100") : "border-white"}`}
                        >
                          <FontAwesomeIcon className={iconClassName} size="lg" icon={icon} />
                          <div className="ml-3 w-7 shrink-0 font-bold">{index + 1}.</div>
                          <div className="text-md w-full">{process?.name}</div>
                          {process?.use_api && (
                            <div className="text-serial-palette-500 flex items-center whitespace-nowrap font-medium">
                              API Only
                              <FontAwesomeIcon className="ml-1" icon={faCode} />
                            </div>
                          )}
                          {focusedProcess === index && !process?.use_api && (
                            <div className="text-serial-palette-500 flex items-center whitespace-nowrap font-medium">
                              Run Process
                              <FontAwesomeIcon className="ml-1" icon={faCaretRight} />
                            </div>
                          )}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="hidden md:block">
            {!componentInstance && (
              <ProductionWorkOrderSummary
                workOrders={filteredWorkOrders}
                handleSelectedWorkOrder={handleSelectedWorkOrder}
                selectedWorkOrder={selectedWorkOrder}
                sidebarOpen={sidebarOpen}
              />
            )}
          </div>

          {/* Create new unit buttons */}
          <div className="flex w-full flex-col">
            <h2 className="text-serial-palette-800 mb-3 text-2xl font-bold">Create New Unit</h2>
            {/* Grid of buttons: */}
            <div className="flex grow-0 flex-wrap gap-2 pb-24">
              {filteredComponents.map(({ component, firstProductionProcessWithLowestOrder }, index) => (
                <button
                  key={index}
                  className="btn border-serial-palette-200 hover:border-serial-palette-300 flex h-[3.5rem] gap-2 border bg-white px-5"
                  onClick={() => handleComponentClick(component, firstProductionProcessWithLowestOrder?.id)}
                >
                  {component.url && <ComponentAvatar component={component} size="sm" />}
                  <div className="text-serial-palette-500 whitespace-nowrap font-semibold">{component.name}</div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 top-0 flex h-full w-2/5 shrink-0 flex-col border-l bg-white shadow transition-all duration-300 ease-in-out ${sidebarOpen ? "translate-x-0 " : "translate-x-full opacity-0"}`}
      >
        <ProductionSidebar workOrder={selectedWorkOrder} setSidebarOpen={setSidebarOpen} />
      </div>
    </div>
  );
};

export default ProductionProcessSelectorUniversal;
