import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@shared/utils/tailwind";

interface LoaderProps {
  size: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
  className?: string;
}

const sizeClasses = {
  xs: "text-[14px]",
  sm: "text-[20px]",
  md: "text-[28px]",
  lg: "text-[40px]",
  xl: "text-[60px]",
  "2xl": "text-[90px]",
  "3xl": "text-[130px]",
  "4xl": "text-[180px]",
};

const Loader = ({ size, className }: LoaderProps) => {
  return (
    <div className={cn("flex h-fit items-center justify-center", sizeClasses[size], className)}>
      <FontAwesomeIcon icon={faCircleNotch} className="text-serial-palette-400 animate-spin" />
    </div>
  );
};

export default Loader;
