import {
  Checkbox,
  DatetimeData,
  File,
  Image,
  Operator,
  ParametricQualitative,
  ParametricQuantitative,
  PartNumber,
  ProcessEntry,
  Station,
  TimestamptzString,
  UniqueIdentifier,
  UniqueIdentifierLink,
  WorkOrder,
} from "@shared/types/databaseTypes";

export enum LinkStatus {
  UnLinked = "UNLINKED",
  Linked = "LINKED",
}

export interface GenealogyByLinkedComponent {
  uniqueIdentifiers: UniqueIdentifier[];
  uniqueIdentifierLinks: UniqueIdentifierLink[];
}

export enum MeasureType {
  Identifier = "IDENTIFIER",
  Status = "STATUS",
  LinkStatus = "LINK_STATUS",
  PartNumber = "PART_NUMBER",
  Station = "STATION",
  Operator = "OPERATOR",
  CycleTime = "CYCLE_TIME",
  Timestamp = "TIMESTAMP",
  ProcessResult = "PROCESS_RESULT",
  WorkOrder = "WORK_ORDER",
  WorkOrderPhase = "WORK_ORDER_PHASE",
  ParametricQualitative = "PARAMETRIC_QUALITATIVE",
  ParametricQuantitative = "PARAMETRIC_QUANTITATIVE",
  Checkbox = "CHECKBOX",
  PassFail = "PASSFAIL",
  Image = "IMAGE",
  File = "FILE",
  Link = "LINK",
  Datetime = "DATETIME",
}

export interface MeasureKey {
  type: MeasureType;
  component_id: string; // snake_case used here since MeasureKeys may sometimes be stored in the database
  process_id?: string;
  dataset_id?: string;
  aggregation_operator?: MeasureAggregationOperator;
  formatting?: MeasureKeyFormatting;
  time_operator?: MeasureTimeOperator;
}

export interface MeasureKeyFormatting {
  relative_time?: boolean;
}

interface MeasureValueBase {
  uniqueIdentifierId: string;
  timestamp: TimestamptzString;
  data: any;
  processEntry?: ProcessEntry;
}

export interface MeasureValueIdentifier extends MeasureValueBase {
  type: MeasureType.Identifier;
  data: UniqueIdentifier;
}

export interface MeasureValueIdentifierStatus extends MeasureValueBase {
  type: MeasureType.Status;
  data: UniqueIdentifier;
}

export interface MeasureValueLinkStatus extends MeasureValueBase {
  type: MeasureType.LinkStatus;
  data: UniqueIdentifierLink[];
}

export interface MeasureValuePartNumber extends MeasureValueBase {
  type: MeasureType.PartNumber;
  data: PartNumber;
}

export interface MeasureValueStation extends MeasureValueBase {
  type: MeasureType.Station;
  processEntry: ProcessEntry;
  data: Station;
}

export interface MeasureValueOperator extends MeasureValueBase {
  type: MeasureType.Operator;
  processEntry: ProcessEntry;
  data: Operator;
}

export interface MeasureValueCycleTime extends MeasureValueBase {
  type: MeasureType.CycleTime;
  processEntry: ProcessEntry;
  data: ProcessEntry;
}

export interface MeasureValueTimestamp extends MeasureValueBase {
  type: MeasureType.Timestamp;
  processEntry: ProcessEntry;
  data: ProcessEntry;
}

export interface MeasureValueProcessResult extends MeasureValueBase {
  type: MeasureType.ProcessResult;
  processEntry: ProcessEntry;
  data: ProcessEntry;
}

export interface MeasureValueWorkOrder extends MeasureValueBase {
  type: MeasureType.WorkOrder;
  data: WorkOrder;
}

export interface MeasureValueWorkOrderPhase extends MeasureValueBase {
  type: MeasureType.WorkOrderPhase;
  data: WorkOrder;
}

export interface MeasureValueParametricQualitative extends MeasureValueBase {
  type: MeasureType.ParametricQualitative;
  processEntry: ProcessEntry;
  data: ParametricQualitative;
}

export interface MeasureValueParametricQuantitative extends MeasureValueBase {
  type: MeasureType.ParametricQuantitative;
  processEntry: ProcessEntry;
  data: ParametricQuantitative;
}

export interface MeasureValueCheckbox extends MeasureValueBase {
  type: MeasureType.Checkbox;
  processEntry: ProcessEntry;
  data: Checkbox;
}

export interface MeasureValuePassFail extends MeasureValueBase {
  type: MeasureType.PassFail;
  processEntry: ProcessEntry;
  data: ParametricQualitative;
}

export interface MeasureValueImage extends MeasureValueBase {
  type: MeasureType.Image;
  processEntry: ProcessEntry;
  data: Image;
}

export interface MeasureValueFile extends MeasureValueBase {
  type: MeasureType.File;
  processEntry: ProcessEntry;
  data: File;
}

export interface MeasureValueLink extends MeasureValueBase {
  type: MeasureType.Link;
  processEntry: ProcessEntry;
  data: UniqueIdentifierLink;
}

export interface MeasureValueDatetime extends MeasureValueBase {
  type: MeasureType.Datetime;
  processEntry: ProcessEntry;
  data: DatetimeData;
}

export type MeasureValue =
  | MeasureValueIdentifier
  | MeasureValueIdentifierStatus
  | MeasureValueLinkStatus
  | MeasureValuePartNumber
  | MeasureValueStation
  | MeasureValueOperator
  | MeasureValueCycleTime
  | MeasureValueTimestamp
  | MeasureValueProcessResult
  | MeasureValueWorkOrder
  | MeasureValueWorkOrderPhase
  | MeasureValueParametricQualitative
  | MeasureValueParametricQuantitative
  | MeasureValueCheckbox
  | MeasureValuePassFail
  | MeasureValueImage
  | MeasureValueFile
  | MeasureValueLink
  | MeasureValueDatetime;

export enum FilterConditionOperator {
  Equals = "EQUALS",
  NotEquals = "NOT_EQUALS",
  GreaterThan = "GREATER_THAN",
  LessThan = "LESS_THAN",
  GreaterThanOrEqualTo = "GREATER_THAN_OR_EQUAL_TO",
  LessThanOrEqualTo = "LESS_THAN_OR_EQUAL_TO",
  Contains = "CONTAINS",
  DoesNotContain = "DOES_NOT_CONTAIN",
  In = "IN",
  NotIn = "NOTIN",
}

export enum FilterJoinOperator {
  And = "AND",
  Or = "OR",
}

export enum GroupAggregationOperator {
  Sum = "SUM",
  Average = "AVERAGE",
  Count = "COUNT",
  Min = "MIN",
  Max = "MAX",
}

export enum MeasureAggregationOperator {
  Latest = "LATEST",
  First = "FIRST",
  Count = "COUNT",
  Sum = "SUM",
  Average = "AVERAGE",
  Min = "MIN",
  Max = "MAX",
}

export interface UniqueIdentifierWithMeasures extends UniqueIdentifier {
  genealogy: GenealogyByLinkedComponent;
  measures: {
    key: MeasureKey;
    valuesByIdentifier: {
      [uniqueIdentifierId: string]: MeasureValuesWithAggregation;
    };
  }[];
}

export interface MeasureValuesWithAggregation {
  values: MeasureValue[];
  aggregation: MeasureValueSummary;
}

export interface MeasureTimeOperator {
  // MeasureKey here can either be a timestamp, datetime value or link
  // Links are specially handled to pull the timestamp when this child and parent
  // were most recently linked, not necessarily the most recent link
  since: TimestamptzString | MeasureKey | null;
  until: TimestamptzString | MeasureKey | null;
}

export interface TimeRangeOption {
  label: string;
  type: "from" | "to";
}

export interface MeasureValueSummary {
  value: string;
  formattedValue: string;
  tag: string | null;
  numValues?: number;
  fileId?: string;
  bucketName?: string;
  href?: string;
}

export interface MeasureGroup {
  name: string;
  commonMeasures: {
    key: MeasureKey;
    value: MeasureValue;
  }[];
  aggregatedMeasures: {
    key: MeasureKey;
    value: MeasureValue;
  }[];
}

export interface MeasureFilterCondition {
  key: MeasureKey;
  operator: FilterConditionOperator;
  value: string;
}

export interface MeasureSortCondition {
  key: MeasureKey;
  ascending: boolean;
}

export const filterConditionOperatorStyle = {
  [FilterConditionOperator.Equals]: { label: "Equals", symbol: "=", explanation: "The data must be equal to the value." },
  [FilterConditionOperator.NotEquals]: { label: "Not Equals", symbol: "!=", explanation: "The data must not be equal to the value." },
  [FilterConditionOperator.GreaterThan]: { label: "Greater Than", symbol: ">", explanation: "The data must be greater than the value." },
  [FilterConditionOperator.LessThan]: { label: "Less Than", symbol: "<", explanation: "The data must be less than the value." },
  [FilterConditionOperator.GreaterThanOrEqualTo]: {
    label: "Greater Than or Equal To",
    symbol: ">=",
    explanation: "The data must be greater than or equal to the value.",
  },
  [FilterConditionOperator.LessThanOrEqualTo]: {
    label: "Less Than or Equal To",
    symbol: "<=",
    explanation: "The data must be less than or equal to the value.",
  },
  [FilterConditionOperator.Contains]: { label: "Contains", symbol: "=~", explanation: "The string must contain the entered substring." },
  [FilterConditionOperator.DoesNotContain]: {
    label: "Does Not Contain",
    symbol: "!~",
    explanation: "The string must not contain the entered substring.",
  },
  [FilterConditionOperator.In]: {
    label: "One of a List of Values",
    symbol: "in",
    explanation: "The data must be among one of the selected strings",
  },
  [FilterConditionOperator.NotIn]: {
    label: "Not One of a List of Values",
    symbol: "!in",
    explanation: "The data must be not among one of the selected strings",
  },
};

export const numericalRangeOperators = [
  FilterConditionOperator.LessThan,
  FilterConditionOperator.LessThanOrEqualTo,
  FilterConditionOperator.GreaterThan,
  FilterConditionOperator.GreaterThanOrEqualTo,
];

export const allowedFilterConditionOperators: Record<MeasureType, FilterConditionOperator[]> = {
  [MeasureType.Identifier]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.Link]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.ParametricQualitative]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.ParametricQuantitative]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [MeasureType.Image]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.File]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.Checkbox]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [MeasureType.PassFail]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [MeasureType.Status]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [MeasureType.LinkStatus]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [MeasureType.PartNumber]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.Station]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.Operator]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
  ],
  [MeasureType.CycleTime]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [MeasureType.Datetime]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [MeasureType.Timestamp]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [MeasureType.ProcessResult]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [MeasureType.WorkOrder]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [MeasureType.WorkOrderPhase]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
};

export const allowedMeasureAggregationOperators: Record<MeasureType, MeasureAggregationOperator[]> = {
  [MeasureType.Identifier]: [MeasureAggregationOperator.Latest],
  [MeasureType.Status]: [MeasureAggregationOperator.Latest],
  [MeasureType.LinkStatus]: [MeasureAggregationOperator.Latest],
  [MeasureType.PartNumber]: [MeasureAggregationOperator.Latest],
  [MeasureType.WorkOrder]: [MeasureAggregationOperator.Latest],
  [MeasureType.WorkOrderPhase]: [MeasureAggregationOperator.Latest],
  [MeasureType.ParametricQualitative]: [
    MeasureAggregationOperator.Count,
    MeasureAggregationOperator.First,
    MeasureAggregationOperator.Latest,
  ],
  [MeasureType.Link]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.Image]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.File]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.Checkbox]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.PassFail]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.Station]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.Operator]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.Datetime]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.Timestamp]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.ProcessResult]: [MeasureAggregationOperator.Latest, MeasureAggregationOperator.Count, MeasureAggregationOperator.First],
  [MeasureType.ParametricQuantitative]: [
    MeasureAggregationOperator.Latest,
    MeasureAggregationOperator.Average,
    MeasureAggregationOperator.Count,
    MeasureAggregationOperator.First,
    MeasureAggregationOperator.Max,
    MeasureAggregationOperator.Min,
    MeasureAggregationOperator.Sum,
  ],
  [MeasureType.CycleTime]: [
    MeasureAggregationOperator.Latest,
    MeasureAggregationOperator.Average,
    MeasureAggregationOperator.Count,
    MeasureAggregationOperator.First,
    MeasureAggregationOperator.Max,
    MeasureAggregationOperator.Min,
    MeasureAggregationOperator.Sum,
  ],
};

export const allowedGroupAggregationOperators: Record<MeasureType, GroupAggregationOperator[]> = {
  [MeasureType.Identifier]: [GroupAggregationOperator.Count],
  [MeasureType.Status]: [GroupAggregationOperator.Count],
  [MeasureType.LinkStatus]: [GroupAggregationOperator.Count],
  [MeasureType.PartNumber]: [GroupAggregationOperator.Count],
  [MeasureType.WorkOrder]: [GroupAggregationOperator.Count],
  [MeasureType.WorkOrderPhase]: [GroupAggregationOperator.Count],
  [MeasureType.ParametricQualitative]: [GroupAggregationOperator.Count],
  [MeasureType.Link]: [GroupAggregationOperator.Count],
  [MeasureType.Image]: [GroupAggregationOperator.Count],
  [MeasureType.File]: [GroupAggregationOperator.Count],
  [MeasureType.Checkbox]: [GroupAggregationOperator.Count],
  [MeasureType.PassFail]: [GroupAggregationOperator.Count],
  [MeasureType.Station]: [GroupAggregationOperator.Count],
  [MeasureType.Operator]: [GroupAggregationOperator.Count],
  [MeasureType.Datetime]: [GroupAggregationOperator.Count],
  [MeasureType.Timestamp]: [GroupAggregationOperator.Count],
  [MeasureType.ProcessResult]: [GroupAggregationOperator.Count],
  [MeasureType.ParametricQuantitative]: [
    GroupAggregationOperator.Count,
    GroupAggregationOperator.Average,
    GroupAggregationOperator.Max,
    GroupAggregationOperator.Min,
    GroupAggregationOperator.Sum,
  ],
  [MeasureType.CycleTime]: [
    GroupAggregationOperator.Count,
    GroupAggregationOperator.Average,
    GroupAggregationOperator.Max,
    GroupAggregationOperator.Min,
    GroupAggregationOperator.Sum,
  ],
};
