import { Marker } from "react-map-gl";
import ComponentAvatar from "@shared/components/primitives/ComponentAvatar";
import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { useTracking } from "../TrackingProvider";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";
import { GeoLocation } from "../types";

const TrackingMarker = ({ uniqueIdentifier }: { uniqueIdentifier: UniqueIdentifier }) => {
  const { setSelectedUniqueIdentifier, setSelectedUniqueIdentifierLocationIndex, uniqueIdentifierLocations } = useTracking();
  const components = useSelector((state: RootState) => state.db.components);
  const locations: GeoLocation[] | undefined = uniqueIdentifierLocations[uniqueIdentifier.id];

  if (!location) return null;

  return (
    <>
      {locations?.map((location, index) => (
        <Marker
          key={index}
          longitude={location.longitude}
          latitude={location.latitude}
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setSelectedUniqueIdentifier(uniqueIdentifier);
            setSelectedUniqueIdentifierLocationIndex(index);
          }}
        >
          <ComponentAvatar
            component={components.find((component) => component.id === uniqueIdentifier.component_id)!}
            size="xs"
            className="border-serial-palette-400 rounded border"
          />
        </Marker>
      ))}
    </>
  );
};

export default TrackingMarker;
