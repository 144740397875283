import React, { createContext, useContext } from "react";
import Tooltip from "./Tooltip";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faSquare,
  faClone,
  faUser,
  faRuler,
  faLink,
  faExchange,
  faQuestion,
  faCheck,
  faX,
  faBarcode,
  faMinus,
  faCog,
  faList,
  faLock,
  faBan,
  faTrashAlt,
  faLinkSlash,
  faDesktop,
  faPencil,
  faHashtag,
  faTag,
  faStopwatch,
  faCheckDouble,
  faTimes,
  faStamp,
  faCalendar,
  faClipboard,
  faCheckCircle,
  faSortAlphaDown,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faFile, faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@shared/utils/tailwind";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import i18next from "@shared/i18n/config";

type TagBasicConfig = {
  [key: string]: {
    name: string;
    colorClass: string;
    faIcon: IconProp | null;
    tooltipText?: string;
  };
};

export const tagVariants: TagBasicConfig = {
  SN: {
    name: "SN",
    colorClass: "bg-serial-palette-900 text-serial-palette-100 ",
    faIcon: faSquare,
    tooltipText: "Field for selecting the serial number for which you want to add data",
  },
  LOT: {
    name: "LOT",
    colorClass: "bg-serial-palette-400 text-serial-palette-100",
    faIcon: faClone,
    tooltipText: "Field for selecting the lot code for which you want to add data",
  },
  STATUS: {
    name: "STATUS",
    colorClass: "bg-amber-100 text-amber-500",
    faIcon: faCheckDouble,
    tooltipText: "",
  },
  UID: {
    name: "UID",
    colorClass: "bg-serial-palette-400 text-serial-palette-100",
    faIcon: faBarcode,
    tooltipText: "Field for selecting the serial number or lot code for which you want to add data",
  },
  IDENTIFIER: {
    name: "IDENTIFIER",
    colorClass: "bg-serial-palette-400 text-serial-palette-100",
    faIcon: faBarcode,
    tooltipText: "Field for selecting the serial number or lot code for which you want to add data",
  },
  PART_NUMBER: {
    name: "PART NUMBER",
    colorClass: "bg-yellow-100 text-yellow-500",
    faIcon: faTag,
    tooltipText: "Field for selecting the serial number or lot code for which you want to add data",
  },
  LINK: {
    name: "LINK",
    colorClass: "bg-serial-palette-100 text-serial-palette-500",
    faIcon: faLink,
    tooltipText: "Use link groups to connect serial numbers or lot codes to each other",
  },
  LINK_STATUS: {
    name: "LINK STATUS",
    colorClass: "bg-serial-palette-100 text-serial-palette-500",
    faIcon: faLink,
    tooltipText: "Use link groups to connect serial numbers or lot codes to each other",
  },
  LINKED: {
    name: "LINKED",
    colorClass: "bg-serial-palette-100 text-serial-palette-500",
    faIcon: faLink,
    tooltipText: "Use link groups to connect serial numbers or lot codes to each other",
  },
  UNLINKED: {
    name: "UNLINKED",
    colorClass: "bg-serial-palette-100 text-red-500",
    faIcon: faLinkSlash,
  },
  TIMESTAMP: {
    name: "TIMESTAMP",
    colorClass: "bg-lime-100 text-lime-600",
    faIcon: faClock,
    tooltipText: "Date and time is automatially collected to every process",
  },
  CYCLE_TIME: {
    name: "CYCLE TIME",
    colorClass: "bg-emerald-100 text-emerald-500",
    faIcon: faStopwatch,
  },
  OPERATOR: {
    name: "OPERATOR",
    colorClass: "bg-indigo-100 text-indigo-600",
    faIcon: faUser,
  },
  PASSFAIL: {
    name: "PASSFAIL",
    colorClass: "bg-orange-100 text-orange-600",
    faIcon: faExchange,
    tooltipText: "Use pass/fail groups to indicate whether a serial number or lot code has passed or failed a test",
  },
  PROCESS_RESULT: {
    name: "PROCESS RESULT",
    colorClass: "bg-amber-100 text-amber-500",
    faIcon: faCheckCircle,
  },
  WORK_ORDER: {
    name: i18next.t("workOrder").toUpperCase(),
    colorClass: "bg-serial-palette-300 text-serial-palette-700",
    faIcon: faClipboard,
  },
  WORK_ORDER_PHASE: {
    name: "PHASE",
    colorClass: "bg-serial-palette-300 text-serial-palette-800",
    faIcon: faSortAlphaDown,
  },
  IMAGE: {
    name: "IMAGE",
    colorClass: "bg-amber-100 text-amber-600",
    faIcon: faImage,
    tooltipText: "Use image groups to attach photos or take live photos",
  },
  FILE: {
    name: "FILE",
    colorClass: "bg-fuchsia-100 text-fuchsia-600",
    faIcon: faFile,
    tooltipText: "Use file upload groups to attach documents or reports",
  },
  CHECKBOX: {
    name: "CHECKBOX",
    colorClass: "bg-teal-100 text-teal-500",
    faIcon: faList,
    tooltipText: "Use checkbox groups to create an checklist",
  },
  PARAMETRIC_QUALITATIVE: {
    name: "TEXT",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faPencil,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  PARAMETRIC_QUANTITATIVE: {
    name: "NUMERICAL",
    colorClass: "bg-blue-100 text-blue-500",
    faIcon: faHashtag,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  PARAMETRIC: {
    name: "PARAMETRIC",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faRuler,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  MANUAL_ENTRY: {
    name: "PARAMETRIC",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faRuler,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  TEXT: {
    name: "TEXT",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faPencil,
  },
  NUMERICAL: {
    name: "NUMERICAL",
    colorClass: "bg-blue-100 text-blue-500",
    faIcon: faHashtag,
  },
  DATETIME: {
    name: "DATETIME",
    colorClass: "bg-violet-100 text-violet-600",
    faIcon: faClock,
    tooltipText: "If you want to collect date and time data beyond when the process was run, use the datetime group",
  },
  ADHOC: {
    name: "ADHOC",
    colorClass: "bg-serial-palette-100 text-serial-palette-600 ",
    faIcon: faQuestion,
  },
  PASS: {
    name: "PASS",
    colorClass: "bg-white text-green-600 border border-green-600",
    faIcon: faCheck,
  },
  OVERRIDE_PASS: {
    name: "OVERRIDE",
    colorClass: "bg-white text-yellow-600 border border-yellow-600",
    faIcon: faCheck,
  },
  OVERRIDE_FAIL: {
    name: "OVERRIDE",
    colorClass: "bg-white text-yellow-600 border border-yellow-600",
    faIcon: faX,
  },
  RETEST: {
    name: "RETEST",
    colorClass: "bg-white text-amber-600 border border-amber-600",
    faIcon: faRedoAlt,
  },
  FAIL: {
    name: "FAIL",
    colorClass: "bg-white text-red-600 border border-red-600",
    faIcon: faX,
  },
  DEFECTIVE: {
    name: "DEFECTIVE",
    colorClass: "bg-red-600 text-white border border-red-600",
    faIcon: faX,
  },
  COMPLETE: {
    name: "COMPLETE",
    colorClass: "bg-green-600 text-white border border-green-600",
    faIcon: faCheck,
  },
  WIP: {
    name: "WIP",
    colorClass: "bg-sky-600 text-white border border-sky-600",
    faIcon: faCog,
  },
  PLANNED: {
    name: "PLANNED",
    colorClass: "bg-gray-400 text-white border border-gray-400",
    faIcon: faCalendar,
  },
  NA: {
    name: "NA",
    colorClass: "bg-white text-gray-400 border border-gray-400",
    faIcon: faMinus,
  },
  ACTIVE: {
    name: "ACTIVE",
    colorClass: "bg-green-600 text-white border border-green-600",
    faIcon: null,
  },
  INACTIVE: {
    name: "INACTIVE",
    colorClass: "bg-red-600 text-white border border-red-600",
    faIcon: null,
  },
  LOCKED: {
    name: "LOCKED",
    colorClass: "bg-amber-600 text-white border border-amber-600",
    faIcon: faLock,
  },
  RETIRED: {
    name: "RETIRED",
    colorClass: "bg-red-600 text-white border border-red-600",
    faIcon: faBan,
  },
  ARCHIVED: {
    name: "ARCHIVED",
    colorClass: "bg-red-700 text-white border border-red-700",
    faIcon: faTrashAlt,
  },
  STATION: {
    name: "STATION",
    colorClass: "bg-slate-100 text-slate-500",
    faIcon: faDesktop,
  },
  APPROVED: {
    name: "APPROVED",
    colorClass: "bg-green-600 text-white border border-green-600",
    faIcon: faCheck,
  },
  REJECTED: {
    name: "REJECTED",
    colorClass: "bg-red-600 text-white border border-red-600",
    faIcon: faTimes,
  },
  PENDING: {
    name: "PENDING",
    colorClass: "bg-amber-600 text-white border border-amber-600",
    faIcon: faStamp,
  },
};

function updateTranslations() {
  tagVariants.WORK_ORDER.name = i18next.t("workOrder").toUpperCase();
}

// Initial translation if i18next is ready
if (i18next.isInitialized) {
  updateTranslations();
}

// Update translations on language change
i18next.on("languageChanged", () => {
  updateTranslations();
});

// Update translations when translations are loaded
i18next.on("loaded", () => {
  updateTranslations();
});

interface TagBasicProps {
  variant: string;
  size: "xs" | "sm" | "md" | "lg";
  count?: number;
  className?: string;
  tooltip?: boolean | string;
  children?: React.ReactNode;
}

const TagBasicContext = createContext<{
  variant: string;
  size: "xs" | "sm" | "md" | "lg";
  count?: number;
}>({
  variant: "NA",
  size: "md",
  count: undefined,
});

const sizeClasses = {
  xs: { base: "h-[16px] text-[8px] px-2", iconOnly: "w-[24px]" },
  sm: { base: "h-[18px] text-[10px] px-3", iconOnly: "w-[34px]" },
  md: { base: "h-[22px] text-[12px] px-4", iconOnly: "w-[44px]" },
  lg: { base: "h-[28px] text-[14px] px-5", iconOnly: "w-[54px]" },
};

const TagBasicRoot: React.FC<TagBasicProps> = ({ variant, size, count, tooltip, className, children }) => {
  if (!tagVariants[variant]) {
    console.error(`TagBasic: variant ${variant} does not exist`);
    return null;
  }

  const contents = (
    <div
      className={cn(
        "flex w-min items-center justify-center gap-1.5 rounded-full",
        tagVariants[variant].colorClass,
        sizeClasses[size].base,
        !children && count === undefined ? sizeClasses[size].iconOnly : "",
        className,
      )}
    >
      {children ? children : <TagBasicIcon />}
      {count && <TagBasicCount />}
    </div>
  );

  return (
    <TagBasicContext.Provider value={{ variant, size, count }}>
      {tooltip ? (
        <Tooltip.Root>
          <Tooltip.Trigger>{contents}</Tooltip.Trigger>
          <Tooltip.Content>
            {typeof tooltip === "string" ? tooltip : tooltip ? screamingSnakeCaseToTitleCase(variant) : null}
          </Tooltip.Content>
        </Tooltip.Root>
      ) : (
        contents
      )}
    </TagBasicContext.Provider>
  );
};

const TagBasicIcon = () => {
  const { variant } = useContext(TagBasicContext);
  const icon = tagVariants[variant].faIcon;
  if (!icon) return null;
  return <FontAwesomeIcon icon={icon} />;
};

const TagBasicName = ({ className, children }: { className?: string; children?: React.ReactNode }) => {
  const { variant } = useContext(TagBasicContext);
  return <div className={cn("whitespace-nowrap font-medium", className)}>{children ? children : tagVariants[variant].name}</div>;
};

const TagBasicCount = ({ className, children }: { className?: string; children?: React.ReactNode }) => {
  const { count } = useContext(TagBasicContext);
  return <div className={cn("font-base", className)}>{children ? children : count ?? 0}</div>;
};

const TagBasic = Object.assign(TagBasicRoot, {
  Root: TagBasicRoot,
  Icon: TagBasicIcon,
  Name: TagBasicName,
  Count: TagBasicCount,
});

export default TagBasic;
