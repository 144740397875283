import React, { useContext, useEffect, useMemo, useState } from "react";
import { ProductionContext, ProductionState } from "../ProductionProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { AlertType, ProcessType } from "@shared/types/databaseEnums";
import { createAlert } from "@shared/connections/supabaseAlerts";
import Banner2 from "@shared/components/Banner2";
import { useNavigate } from "react-router-dom";

const ProductionSubmissionResponse: React.FC = () => {
  const {
    db,
    singleSubmissionMode,
    submissionResult,
    submissionFailedDatasets,
    station,
    identifiers,
    process,
    operator,
    component,
    handleReset,
    setProductionState,
    handleUpdateIdentifiers,
  } = useContext(ProductionContext);
  const [showOverrideRequestBanner, setShowOverrideRequestBanner] = useState(false);

  const navigate = useNavigate();

  const handleContinue = () => {
    if (singleSubmissionMode) {
      window.close();
      return;
    }
    handleReset();
    // If the process is a batch process and more then one identifier is present, do not persist the identifiers when returning to the process selector
    if (process?.allow_batch && identifiers.length > 1) {
      handleUpdateIdentifiers([]);
    }
    setProductionState(ProductionState.ProcessSelector);
    navigate(`/production/${station?.id}`);
  };

  const handleRepeat = () => {
    handleReset();
    setProductionState(ProductionState.Steps);
  };

  const handleNext = () => {
    handleReset();
    if (!component || !station) {
      console.error("Could not find component id");
      setProductionState(ProductionState.ProcessSelector);
      return;
    }
    const processes = db.componentProcessLinks
      .filter((link) => {
        return link.component_id === component.id && link.process?.type === ProcessType.Production && link.is_active;
      })
      .sort((a, b) => {
        if (a.order === null && b.order === null) return 0;
        if (a.order === null) return 1;
        if (b.order === null) return -1;
        return a.order - b.order;
      })
      .map((link) => link.process);
    const currIndex = processes.findIndex((p) => p?.id === process?.id);
    const nextProcess = processes[currIndex + 1];
    // reset the identifiers if the current process is a batch process and the next process is not
    if (process?.allow_batch && !nextProcess?.allow_batch) {
      handleUpdateIdentifiers([]);
    }
    if (nextProcess) {
      navigate(`/production/${station.id}/${nextProcess.id}`);
      setProductionState(ProductionState.Steps);
    } else {
      setProductionState(ProductionState.ProcessSelector);
    }
  };

  const handleRequestOverride = async () => {
    const message = `Override requested for ${identifiers[0]} at process ${process?.name}${operator && " by "}${operator?.first_name || ""} ${operator?.last_name || ""}.`;
    await createAlert(message, AlertType.Override, `/snlookup?uid=${processEntry?.unique_identifier_id}&entry=${processEntry?.id}`, [
      processEntry?.id,
    ]);
    setShowOverrideRequestBanner(true);
  };

  const processEntry = useMemo(() => {
    return submissionResult[0] ?? null;
  }, [submissionResult]);

  // add keydown event listener to continue on enter
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" || e.key === "B" || e.key === "b") {
        e.preventDefault();
        handleContinue();
        return;
      }
      if (e.key === "R" || e.key === "r") {
        e.preventDefault();
        handleRepeat();
        return;
      }
      if (e.key === "N" || e.key === "n") {
        e.preventDefault();
        handleNext();
        return;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow items-center justify-center">
      <div className="mx-5 flex min-h-[60vh] w-full flex-col items-center justify-center gap-6 rounded-md bg-white p-10 md:w-1/2 ">
        <div className="inline-flex w-full flex-wrap justify-center gap-2 p-6 font-medium">
          {identifiers.map((identifier, index) => (
            <pre key={index}>{identifier}, </pre>
          ))}
        </div>
        <div>
          Upload completed <span className="font-bold text-green-500">successfully</span>.
        </div>
        <div className="w-2/3 border-t pb-6" />
        {db.company?.config?.show_pass_fail_result_in_production_app && (
          <div className="flex w-2/3 flex-col items-center justify-center py-10">
            {processEntry?.is_pass === false ? (
              <div className="flex w-full flex-col items-center gap-y-4 pb-6">
                <div className="text-rose-600">
                  <FontAwesomeIcon size="8x" icon={faXmarkCircle} />
                </div>
                <div className="text-2xl font-medium">
                  Process result: <span className="font-extrabold text-rose-600">FAIL</span>
                </div>
                <div className="flex w-full flex-col rounded-md border p-3 text-sm lg:w-2/3">
                  <div className="mb-1 font-bold">Failing Data</div>
                  {submissionFailedDatasets.length > 0 &&
                    submissionFailedDatasets.map((failedDataset) => {
                      const dataset = db.datasets.find((d) => d.id === failedDataset.datasetId);
                      if (!dataset) return null;
                      return (
                        <div key={dataset.id} className="flex w-full justify-between gap-x-2">
                          <div>{dataset.name}</div>
                          <div className="font-bold text-red-600">{failedDataset.value}</div>
                        </div>
                      );
                    })}
                  {!showOverrideRequestBanner && identifiers.length === 1 && (
                    <button
                      className="btn text-serial-palette-600 bg-serial-palette-200 hover:bg-serial-palette-300 mt-3 whitespace-nowrap"
                      onClick={() => handleRequestOverride()}
                    >
                      Request Override
                    </button>
                  )}
                </div>
                <Banner2 className="w-full" open={showOverrideRequestBanner} hideX type="warning">
                  An override request has been submitted. Contact a Serial administrator to approve.
                </Banner2>
              </div>
            ) : (
              <div className="flex w-3/4 flex-col items-center gap-y-3">
                <div className="text-emerald-600">
                  <FontAwesomeIcon size="8x" icon={faCircleCheck} />
                </div>
                <div className="p-3 text-2xl font-medium">
                  Process result: <span className="font-extrabold text-green-500">PASS</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mb-10 flex flex-col gap-y-2">
          {!singleSubmissionMode && (
            <div className="flex gap-x-2">
              <button
                className="btn text-serial-palette-600 bg-serial-palette-200 hover:bg-serial-palette-300 min-w-[100px] gap-x-2"
                onClick={() => handleRepeat()}
              >
                Repeat
                <span className="border-serial-palette-400 shadow-serial-palette-400 w-[22px] rounded border opacity-50 shadow-sm">R</span>
              </button>
              <button
                className="btn text-serial-palette-600 bg-serial-palette-200 hover:bg-serial-palette-300 min-w-[100px] gap-x-2"
                onClick={() => handleNext()}
              >
                Next
                <span className="border-serial-palette-400 shadow-serial-palette-400 w-[22px] rounded border opacity-50 shadow-sm">N</span>
              </button>
            </div>
          )}
          <button className="btn serial-btn-dark group relative gap-x-2" onClick={() => handleContinue()}>
            {singleSubmissionMode ? "Back to Serial" : "Back to Selector"}
            <span className="shadow-serial-palette-400 w-[22px] rounded border opacity-50 shadow-sm">B</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductionSubmissionResponse;
