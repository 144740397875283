import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import Modal from "@shared/components/primitives/Modal";
import useMeasures from "../MeasuresProvider";
import { isValidDate } from "@shared/utils/time";
import { MeasureKey } from "@shared/measures/types";
import useComponentGenealogy, { getDescendentComponentLinks } from "@shared/hooks/useComponentGenealogy";
import { getParentIds } from "@shared/measures/helpers/filters";
import { MeasureType, MeasureTimeOperator } from "@shared/measures/types";
import DatePicker from "@shared/components/DatePicker";
import { getMeasureKeyTimeOperatorDisplayName, getMeasureName } from "@shared/measures/helpers/naming";
import Select from "@shared/components/primitives/Select";
import { hashMeasureKey } from "../helpers/measureKeys";
import TagBasic from "@shared/components/primitives/TagBasic";
import Button from "@shared/components/primitives/Button";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const DEFAULTDISPLAYNAME = "Please select a parent event's timestamp";

interface MeasureKeyTimeOperatorModalProps {
  timeOperatorModalOpen: boolean;
  setTimeOperatorModalOpen: (open: boolean) => void;
  parentMeasureKey: MeasureKey;
  selectedKey?: MeasureKey | null;
}

const MeasureKeyTimeOperatorModal: React.FC<MeasureKeyTimeOperatorModalProps> = ({
  timeOperatorModalOpen,
  setTimeOperatorModalOpen,
  parentMeasureKey,
  selectedKey,
}) => {
  const { componentId, selectedMeasureKeys, handleUpdateKeyTimeOperator, handleRemoveKeyTimeOperator } = useMeasures();
  const [selectedTimeRangeOption, setSelectedTimeRangeOption] = React.useState<"since" | "until">("since");
  const [showUntilDatePicker, setShowUntilDatePicker] = React.useState<boolean>(false);
  const [showSinceDatePicker, setShowSinceDatePicker] = React.useState<boolean>(false);
  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const genealogy = useComponentGenealogy(componentId ?? "");
  const descendantComponentLinks = useMemo(() => getDescendentComponentLinks(genealogy), [genealogy]);

  const componentIds = useMemo(() => {
    return getParentIds(parentMeasureKey.component_id ?? "", descendantComponentLinks);
  }, [parentMeasureKey.component_id, descendantComponentLinks]);

  const filteredMeasureKeys = useMemo(() => {
    const filteredKeysByType = selectedMeasureKeys.filter((key) =>
      [MeasureType.Link, MeasureType.Timestamp, MeasureType.Datetime].includes(key.type),
    );
    return filteredKeysByType.filter((key) => componentIds.includes(key.component_id));
  }, [selectedMeasureKeys, componentIds]);

  const parentEventDisplayName = useMemo(() => {
    const timeOperator = parentMeasureKey.time_operator;
    if (!timeOperator) return DEFAULTDISPLAYNAME;
    if (selectedTimeRangeOption === "since") {
      return getMeasureKeyTimeOperatorDisplayName(timeOperator.since, processes, datasets, DEFAULTDISPLAYNAME);
    }
    if (selectedTimeRangeOption === "until") {
      return getMeasureKeyTimeOperatorDisplayName(timeOperator.until, processes, datasets, DEFAULTDISPLAYNAME);
    }
    return DEFAULTDISPLAYNAME;
  }, [parentMeasureKey.time_operator, selectedTimeRangeOption]);

  const selectedTimeOperatorKey = useMemo(() => {
    if (!selectedKey || !selectedTimeRangeOption) return null;
    const key = selectedKey.time_operator?.[selectedTimeRangeOption];
    if (typeof key !== "object") return null;
    return key as MeasureKey;
  }, [selectedKey, selectedTimeRangeOption]);

  const handleTimestampSelected = (newDate: string | undefined) => {
    let timeOperator: MeasureTimeOperator = selectedKey?.time_operator ?? { since: null, until: null };
    if (!newDate) {
      console.error("Invalid date selected");
      return;
    }
    if (selectedTimeRangeOption !== "since" && selectedTimeRangeOption !== "until") {
      console.error(`Invalid selected time range option ${selectedTimeRangeOption}`);
      return;
    }
    if (!selectedKey) {
      console.error("No key selected");
      return;
    }
    timeOperator[selectedTimeRangeOption] = newDate;
    handleUpdateKeyTimeOperator(selectedKey, timeOperator);
  };

  const handleMeasureKeySelected = (keyHash: string) => {
    const key = filteredMeasureKeys.find((key) => hashMeasureKey(key) === keyHash);
    if (!key) {
      console.error(`Invalid key hash ${keyHash}`);
      return;
    }
    let timeOperator: MeasureTimeOperator = selectedKey?.time_operator ? { ...selectedKey?.time_operator } : { since: null, until: null };
    if (selectedTimeRangeOption !== "since" && selectedTimeRangeOption !== "until") {
      console.error(`Invalid selected time range option ${selectedTimeRangeOption}`);
      return;
    }
    if (!selectedKey) {
      console.error("No key selected");
      return;
    }
    timeOperator[selectedTimeRangeOption] = key;
    handleUpdateKeyTimeOperator(selectedKey, timeOperator);
  };

  const handleSelectTimeRangeOption = (option: "since" | "until") => {
    setSelectedTimeRangeOption(option);
  };

  return (
    <Modal.Root open={timeOperatorModalOpen} onOpenChange={(open) => setTimeOperatorModalOpen(open)}>
      <Modal.Title className="hidden">Set Time Operator</Modal.Title>
      <Modal.Content>
        <Modal.Header className="pl-0">
          <div className="flex h-full">
            {["since", "until"].map((option, index) => {
              // show only the settings tab for new components
              return (
                <div
                  key={index}
                  onClick={() => handleSelectTimeRangeOption(option as "since" | "until")}
                  className={`text-serial-palette-800 flex h-full items-center justify-center border-b-[3.5px] px-4 ${selectedTimeRangeOption === option ? "border-serial-palette-600 font-semibold" : "hover:border-serial-palette-400 border-white font-medium"} cursor-pointer`}
                >
                  <span className={`truncate ${selectedTimeRangeOption !== option && "opacity-60"}`}>
                    {screamingSnakeCaseToTitleCase(option)}
                  </span>
                </div>
              );
            })}
          </div>
          <Modal.HeaderClose />
        </Modal.Header>
        <Modal.Main className="gap-y-3 p-6">
          <div>
            Aggregate values in this column <span className="font-bold">{selectedTimeRangeOption?.toLocaleLowerCase()}</span>:
          </div>
          <div className="flex gap-2">
            <div className="flex w-full flex-col gap-1">
              <Select.Root
                value={selectedTimeOperatorKey ? hashMeasureKey(selectedTimeOperatorKey) : ""}
                onValueChange={(value) => {
                  if (value === "date") {
                    if (selectedTimeRangeOption === "since") setShowSinceDatePicker(true);
                    if (selectedTimeRangeOption === "until") setShowUntilDatePicker(true);
                    return;
                  } else {
                    if (selectedTimeRangeOption === "since") setShowSinceDatePicker(false);
                    if (selectedTimeRangeOption === "until") setShowUntilDatePicker(false);
                    handleMeasureKeySelected(value);
                  }
                }}
              >
                <Select.Trigger className="w-full">
                  {/* check if selectedKey?.time_operator?.from is an object */}
                  {selectedTimeOperatorKey ? (
                    <div className="flex items-center gap-x-0.5">
                      <TagBasic variant={selectedTimeOperatorKey.type} size="xs" className="mr-2" />
                      {selectedKey?.time_operator?.since && selectedTimeRangeOption === "since" && (
                        <span>
                          {getMeasureName(selectedTimeOperatorKey, {
                            components,
                            processes,
                            datasets,
                          })}
                        </span>
                      )}
                      {selectedKey?.time_operator?.until && selectedTimeRangeOption === "until" && (
                        <span>
                          {getMeasureName(selectedTimeOperatorKey, {
                            components,
                            processes,
                            datasets,
                          })}
                        </span>
                      )}
                    </div>
                  ) : (
                    <span className="text-serial-palette-400">
                      {parentEventDisplayName !== DEFAULTDISPLAYNAME ? parentEventDisplayName : "Select a timestamp, link or date"}
                    </span>
                  )}
                </Select.Trigger>
                <Select.Content>
                  {filteredMeasureKeys.map((key) => (
                    <Select.Item key={hashMeasureKey(key)} value={hashMeasureKey(key)}>
                      <div className="flex items-center gap-x-0.5">
                        <TagBasic variant={key.type} size="xs" className="mr-2" />
                        <span>{getMeasureName(key, { components, processes, datasets })}</span>
                      </div>
                    </Select.Item>
                  ))}
                  <Select.Item key="date" value="date">
                    <div className="flex items-center gap-x-0.5">
                      <TagBasic variant={"PLANNED"} size="xs" className="mr-2" />
                      <span>Custom Date</span>
                    </div>
                  </Select.Item>
                </Select.Content>
              </Select.Root>
              {selectedTimeRangeOption === "since" && showSinceDatePicker && (
                <DatePicker
                  className="h-full w-full"
                  date={isValidDate(new Date(parentEventDisplayName)) ? new Date(parentEventDisplayName) : undefined}
                  onDateChange={(newValue) => handleTimestampSelected(newValue?.toISOString())}
                />
              )}
              {selectedTimeRangeOption === "until" && showUntilDatePicker && (
                <DatePicker
                  className="h-full w-full"
                  date={isValidDate(new Date(parentEventDisplayName)) ? new Date(parentEventDisplayName) : undefined}
                  onDateChange={(newValue) => handleTimestampSelected(newValue?.toISOString())}
                />
              )}
            </div>
            {selectedTimeOperatorKey && (
              <Button
                onClick={() => {
                  if (selectedKey) handleRemoveKeyTimeOperator(selectedKey, selectedTimeRangeOption);
                  setShowSinceDatePicker(false);
                  setShowUntilDatePicker(false);
                }}
                variant="outline"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
          </div>
        </Modal.Main>
      </Modal.Content>
    </Modal.Root>
  );
};

export default MeasureKeyTimeOperatorModal;
