import React, { ReactElement } from "react";
import Transition from "@shared/components/Transition";

interface ConfirmationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string | ReactElement;
  message: string | ReactElement;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText: string | ReactElement;
  cancelText: string | ReactElement;
  hideCancel: boolean;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  open,
  setOpen,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  hideCancel,
}) => {
  if (typeof onConfirm !== "function" || typeof onCancel !== "function") {
    console.error("onConfirm and onCancel must be functions");
    console.log("onConfirm", onConfirm);
    console.log("onCancel", onCancel);
    return null;
  }

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={open}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        appear={true}
      />
      {/* Modal dialog */}
      <Transition
        id="confirmation-modal"
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        show={open}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
        appear={true}
      >
        <div className="flex max-h-full w-full max-w-md flex-col items-center justify-center gap-y-1.5 rounded-md border bg-white px-4 py-10">
          <h1 className="text-center text-xl font-semibold">{title}</h1>
          <div className="text-center">{message}</div>
          <div className="flex w-full justify-center gap-x-3 pt-2">
            <button
              onClick={() => {
                onConfirm();
                setOpen(false);
              }}
              className="btn serial-btn-dark"
            >
              {confirmText ?? "Confirm"}
            </button>
            {!hideCancel && (
              <button
                onClick={() => {
                  onCancel();
                  setOpen(false);
                }}
                className="btn serial-btn-light"
              >
                {cancelText ?? "Cancel"}
              </button>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default Confirmation;
