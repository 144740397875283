import React, { useContext } from "react";
import TypeTag from "@shared/components/TypeTag";
import { Link } from "react-router-dom";
import { GenealogyTreeItem } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { SnLookupContext } from "../SnLookupProvider";
import SnLookUpComponentInstanceProcessData from "./SnLookUpComponentInstanceProcessData";
import { formatTimeAgo } from "@shared/utils/time";

interface SnLookUpComponentInstanceSummaryProps {
  genealogyTree: GenealogyTreeItem[];
  genealogyTreeDiff: { addedIds: string[]; removedIds: string[] };
  root: boolean;
}

const SnLookUpComponentInstanceSummary: React.FC<SnLookUpComponentInstanceSummaryProps> = ({ genealogyTree, genealogyTreeDiff, root }) => {
  const { fetchComponentInstanceProcessData } = useContext(SnLookupContext);

  const components = useSelector((state: RootState) => state.db.components);
  const urlParams = new URLSearchParams(location.search);
  const urlId = urlParams.get("uid");

  return (
    <div className={` w-full pt-4 ${!root && "pl-6"} `}>
      {genealogyTree.map((part: GenealogyTreeItem, index: number) => {
        const processData = fetchComponentInstanceProcessData(part.component_id, part.id!);
        const component = components.find((component) => component.id === part.component_id);
        return (
          <div key={index}>
            <div
              className={`col border-serial-palette-200 flex w-full rounded-md border ${!part.status ? "bg-serial-palette-50 opacity-60" : " bg-white "}`}
            >
              {/* Content */}
              <div className="w-full flex-col overflow-auto rounded ">
                <div className="bg-serial-palette-50 relative flex items-center justify-between border-b  px-3 py-2">
                  {genealogyTreeDiff.addedIds.includes(part.id!) && (
                    <div className="absolute left-1.5 top-2 z-20 flex h-5 w-5 items-center justify-center rounded-full bg-green-400 text-sm font-extrabold text-white">
                      +
                    </div>
                  )}
                  <div className="flex h-12 flex-wrap items-center justify-start space-x-3 sm:flex-nowrap">
                    <ComponentAvatar component={component} size="sm" />
                    <div className="flex flex-col pr-3 text-left">
                      <div className="inline-flex items-center space-x-2">
                        <Link
                          to={`/component/${part.component_id}`}
                          className="no-wrap cursor-pointer text-left text-xs font-semibold hover:underline"
                        >
                          {part?.component_name}
                        </Link>
                      </div>
                      <div className="inline-flex items-center space-x-2">
                        <Link className="cursor-pointer text-sm hover:underline" to={`/snlookup?uid=${part.id}`}>
                          {part?.identifier ? part?.identifier : "No Part Linked"}
                        </Link>
                        <TypeTag type={part.status != null ? part.status : "NA"} className="h-4 w-7 text-[10px]" hideText />
                      </div>
                    </div>
                  </div>
                  {part.id != urlId && part.created_at && (
                    <div className="flex h-12 flex-col items-center pt-1">
                      <div className="no-wrap w-full text-right text-xs font-semibold">Linked</div>
                      <div className="text-right text-sm">{formatTimeAgo(part.created_at)}</div>
                    </div>
                  )}
                </div>
                <SnLookUpComponentInstanceProcessData processesWithEntries={processData} identifier={part.identifier} />
              </div>
            </div>

            {part?.children && (
              <SnLookUpComponentInstanceSummary genealogyTree={part?.children} genealogyTreeDiff={genealogyTreeDiff} root={false} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SnLookUpComponentInstanceSummary;
