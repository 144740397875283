import { useEffect, useMemo, useState } from "react";
import { GenealogyFamilyMember, GenealogyTreeItem } from "../types";
import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { TIMESTAMP_TOLERANCE } from "../constants";
import { ComponentGenealogyFamilyMember } from "@shared/hooks/useComponentGenealogy";

export interface HardwareRevision {
  name: string;
  timestamp: string;
  genealogyTree: GenealogyTreeItem[];
  genealogyTreeWithPlaceholders: GenealogyTreeItem[];
  addedIds: string[];
  removedIds: string[];
}

interface useSnLookupHardwareRevisionsProps {
  componentGenealogy: ComponentGenealogyFamilyMember[];
  genealogy: GenealogyFamilyMember[];
  uniqueIdentifier: UniqueIdentifier | null;
}

// function to transform genealogy array into a tree
const transformGenealogyToTree = (currGenealogy: GenealogyFamilyMember[], parent_id: string | null = null): GenealogyTreeItem[] => {
  let tree: GenealogyTreeItem[] = [];

  currGenealogy
    .filter((item: GenealogyFamilyMember) => item.parent_id === parent_id)
    .forEach((item: GenealogyFamilyMember) => {
      let children = transformGenealogyToTree(currGenealogy, item.id);
      let newNode = {
        id: item.id,
        identifier: item.identifier,
        component_id: item.component_id,
        component_type: item.component_type,
        component_name: item.component_name,
        status: item.status,
        part_number: item?.part_number,
        created_at: item.link?.created_at,
        children: [],
      } as GenealogyTreeItem;
      if (children.length) {
        newNode.children = children;
      }
      tree.push(newNode);
    });

  tree.sort((a, b) => a.component_name.localeCompare(b.component_name));

  return tree;
};

// filter genealogy based on selected timestamp
const filteredGenealogyByTimestamp = (genealogy: GenealogyFamilyMember[], selectedTimestamp: string): GenealogyFamilyMember[] => {
  if (genealogy?.length === 0 || !selectedTimestamp) return genealogy;
  // for each object in the genealogy array:
  // - if the created_at timestamp is greater than the selected timestamp, remove the entire object from the array
  // - if the removed_at timestamp is greater than the selected timestamp, remove only the removed_at timestamp and set is_active to true
  let filteredGenealogy = genealogy
    .map((originalGenealogyItem) => {
      // make a deep copy of the object to avoid mutating the original
      const item = JSON.parse(JSON.stringify(originalGenealogyItem));
      if (item.link?.created_at && item.link.created_at > selectedTimestamp) {
        return null;
      } else if (item.link?.removed_at && item.link.removed_at > selectedTimestamp) {
        return {
          ...item,
          link: {
            ...item.link,
            removed_at: null,
            is_active: true,
          },
        };
      } else {
        return item;
      }
    })
    .filter((item) => item !== null);
  // filter out all inactive links
  filteredGenealogy = filteredGenealogy?.filter((member) => member.link?.is_active !== false);
  return filteredGenealogy;
};

const diffGenealogyTrees = (tree1: GenealogyTreeItem[], tree2: GenealogyTreeItem[]): { addedIds: string[]; removedIds: string[] } => {
  let addedIds: string[] = [];
  let removedIds: string[] = [];
  // for each child of tree1, check if it exists in tree2. If it doesn't, add it to the removedIds array
  tree1.forEach((child) => {
    const matchingChild = tree2.find((c) => c.id === child.id);
    if (!matchingChild && child.id) {
      removedIds.push(child.id);
    }
  });
  // for each child of tree2, check if it exists in tree1. If it doesn't, add it to the addedIds array
  tree2.forEach((child) => {
    const matchingChild = tree1.find((c) => c.id === child.id);
    if (!matchingChild && child.id) {
      addedIds.push(child.id);
    }
  });
  // for each matching child, recursively call this function on the children
  tree1.forEach((child) => {
    const matchingChild = tree2.find((c) => c.id === child.id);
    if (matchingChild) {
      const { addedIds: child_addedIds, removedIds: child_removedIds } = diffGenealogyTrees(child.children, matchingChild.children);
      addedIds = [...addedIds, ...child_addedIds];
      removedIds = [...removedIds, ...child_removedIds];
    }
  });
  return { addedIds, removedIds };
};

const fillGenealogyTreeWithPlaceholders = (
  tree: GenealogyTreeItem[],
  componentGenealogy: ComponentGenealogyFamilyMember[],
): GenealogyTreeItem[] => {
  let treeCopy = JSON.parse(JSON.stringify(tree));
  let addedComponentInstanceIds = new Set();

  const mergeNode = (node: GenealogyTreeItem | undefined, componentNode: ComponentGenealogyFamilyMember): GenealogyTreeItem => {
    if (node && !addedComponentInstanceIds.has(node.id)) {
      node.children = fillGenealogyTreeWithPlaceholders(node.children, componentNode.children);
      addedComponentInstanceIds.add(node.id);
    } else if (!node) {
      node = {
        component_id: componentNode.id,
        component_type: componentNode.component_type,
        component_name: componentNode.name,
        status: null,
        part_number: undefined,
        children: fillGenealogyTreeWithPlaceholders([], componentNode.children),
      };
    }
    return node;
  };

  return componentGenealogy.map((componentNode) => {
    const node = treeCopy.find((n: GenealogyTreeItem) => n.component_id === componentNode.id && !addedComponentInstanceIds.has(n.id));
    return mergeNode(node, componentNode);
  });
};

const useSnLookupHardwareRevisions = ({ componentGenealogy, genealogy, uniqueIdentifier }: useSnLookupHardwareRevisionsProps) => {
  const [hwRevisions, setHwRevisions] = useState<HardwareRevision[]>([]);

  const firstHardwareRevision: HardwareRevision | null = useMemo(() => {
    if (!uniqueIdentifier) return null;

    const initialGenealogyTreeItem = {
      id: uniqueIdentifier.id,
      identifier: uniqueIdentifier.identifier,
      component_id: uniqueIdentifier.component_id,
      component_type: uniqueIdentifier.component?.component_type,
      component_name: uniqueIdentifier.component?.name,
      status: uniqueIdentifier.status,
      part_number: uniqueIdentifier.part_number,
      children: [],
    } as GenealogyTreeItem;

    return {
      name: "",
      timestamp: uniqueIdentifier.created_at || "",
      genealogyTree: [initialGenealogyTreeItem],
      genealogyTreeWithPlaceholders: fillGenealogyTreeWithPlaceholders([initialGenealogyTreeItem], componentGenealogy),
      addedIds: [],
      removedIds: [],
    };
  }, [uniqueIdentifier, componentGenealogy]);

  // Generate list of hardware revisions from genealogy.
  // There should be one HW revision per created_at timestamp and one per removed_at timestamp in the genealogy array.
  // Do not allow duplicate timestamps.
  useEffect(() => {
    const timestampSet = new Set<string>();
    let genealogyWithCleanedTimestamps = JSON.parse(JSON.stringify(genealogy)) as GenealogyFamilyMember[];
    genealogyWithCleanedTimestamps.forEach((item) => {
      // if the created_at or removed_at timestamps match any timestamps already in the set (within 1 second), set the timestamp to the matching timestamp from the set
      // there is room for some optimization here to avoid iterating through the entire set for each item but will save that as a TODO for later
      if (item.link?.created_at) {
        let matchFound = false;
        timestampSet.forEach((timestamp) => {
          if (
            item.link?.created_at &&
            Math.abs(new Date(item.link.created_at).getTime() - new Date(timestamp).getTime()) < TIMESTAMP_TOLERANCE
          ) {
            item.link.created_at = timestamp;
          }
        });
        if (!matchFound) {
          timestampSet.add(item.link.created_at);
        }
      }
      if (item.link?.removed_at) {
        let matchFound = false;
        timestampSet.forEach((timestamp) => {
          if (
            item.link?.removed_at &&
            Math.abs(new Date(item.link.removed_at).getTime() - new Date(timestamp).getTime()) < TIMESTAMP_TOLERANCE
          ) {
            item.link.removed_at = timestamp;
          }
        });
        if (!matchFound) {
          timestampSet.add(item.link.removed_at);
        }
      }
    });
    // sort timestamps in ascending order
    const uniqueTimestamps = Array.from(timestampSet);
    uniqueTimestamps.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    // create hardware revision objects
    let hardwareRevisions: HardwareRevision[] = uniqueTimestamps.map((timestamp) => {
      const filteredGenealogy = filteredGenealogyByTimestamp(genealogyWithCleanedTimestamps, timestamp);
      const tree = transformGenealogyToTree(filteredGenealogy, null);
      const genealogyTreeWithPlaceholders = fillGenealogyTreeWithPlaceholders(tree, componentGenealogy);
      return {
        name: "",
        timestamp,
        genealogyTree: tree,
        genealogyTreeWithPlaceholders: genealogyTreeWithPlaceholders,
        addedIds: [],
        removedIds: [],
      };
    });
    if (firstHardwareRevision) {
      hardwareRevisions = [firstHardwareRevision, ...hardwareRevisions];
    }
    // run the diff again to add addedIds and removedIds to each hardware revision
    hardwareRevisions = hardwareRevisions.map((hwRevision, index) => {
      if (index === 0) return hwRevision;
      const prevHwRevision = hardwareRevisions[index - 1];
      const { addedIds, removedIds } = diffGenealogyTrees(prevHwRevision.genealogyTree, hwRevision.genealogyTree);
      return {
        ...hwRevision,
        addedIds,
        removedIds,
      };
    });
    // filter out any adjacent hardware revisions that have the same genealogy tree
    hardwareRevisions = hardwareRevisions.filter((hwRevision, index) => {
      if (index === 0) return true; // Keep the first revision
      const prevHwRevision = hardwareRevisions[index - 1];
      const diff = diffGenealogyTrees(prevHwRevision.genealogyTree, hwRevision.genealogyTree);
      return diff.addedIds.length > 0 || diff.removedIds.length > 0;
    });
    // add the name to each hardware revision
    hardwareRevisions = hardwareRevisions.map((hwRevision, index) => {
      return {
        ...hwRevision,
        name: `Assembly v${index + 1}`,
      };
    });
    setHwRevisions(hardwareRevisions);
  }, [genealogy, componentGenealogy]);

  return hwRevisions;
};

export default useSnLookupHardwareRevisions;
