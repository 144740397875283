import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import HomeComponentCountsByStatus from "./components/HomeComponentCountsByStatus";
import { useNavigate } from "react-router-dom";
import HomeAlerts from "./components/HomeAlerts";
import HomeLatestActivity from "./components/HomeLatestActivity";
import HomeYield from "./components/HomeYield";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { getSupabase } from "@shared/connections/supabaseAuth";

const dateFilterOptions = [
  {
    label: "7 days",
    days: 7,
  },
  {
    label: "30 days",
    days: 30,
  },
  {
    label: "6 months",
    days: 182,
  },
  {
    label: "1 year",
    days: 365,
  },
];

function Home() {
  const breadcrumbs = [
    {
      label: "Home",
      link: "/home",
    },
  ];

  const [selectedDateFilter, setSelectedDateFilter] = useState<{ label: string; days: number }>(dateFilterOptions[1]);
  const [lastUpdatedEpochTimestamp, setLastUpdatedEpochTimestamp] = useState<Number>(new Date().getTime());
  const company = useSelector((state: RootState) => state.db.company);
  const token = useSelector((state: RootState) => state.auth.token);
  const user = useCurrentUser();
  const navigate = useNavigate();
  const realtimeChannel = useRef<any>(null);

  const handleSetLastUpdatedTimestamp = (payload: any) => {
    setLastUpdatedEpochTimestamp(new Date(payload.new?.last_updated_at).getTime());
  };

  useEffect(() => {
    const supabase = getSupabase();
    realtimeChannel.current = supabase
      .channel("any")
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "unique_identifiers" }, (payload: any) =>
        handleSetLastUpdatedTimestamp(payload),
      )
      .on("postgres_changes", { event: "INSERT", schema: "public", table: "unique_identifiers" }, (payload: any) =>
        handleSetLastUpdatedTimestamp(payload),
      )
      .subscribe((status: string) => {
        if (status === "SUBSCRIBED") {
          console.log("Successfully subscribed to homepage DB changes.");
        }
      });

    // Cleanup function to unsubscribe when component unmounts
    return () => {
      if (realtimeChannel.current) {
        supabase.removeChannel(realtimeChannel.current);
      }
    };
  }, [token]); // Re-run effect on authToken change

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="relative flex h-screen min-h-0 min-w-0 flex-grow flex-col overflow-hidden">
        <Header breadcrumbs={breadcrumbs} />
        <div className="flex min-h-0 min-w-0 flex-grow justify-center px-4 md:px-8">
          <div className="scrollbar-hide flex w-full max-w-[1400px] flex-col overflow-auto 2xl:w-11/12">
            <div className="my-4 grid grid-cols-1 gap-3 md:my-8 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:my-16">
              <div className="col-span-full mb-4 flex w-full justify-between rounded-md border bg-white px-2 py-5">
                <div className="flex h-full flex-col justify-center px-3">
                  <h1 className="text-2xl font-extrabold">{company?.name}</h1>
                  <p className="text-serial-palette-500 text-lg">{`Welcome back, ${user ? user?.first_name : ""}`}</p>
                </div>
                {company?.light_logo && (
                  <button
                    className="hidden justify-center overflow-hidden rounded pr-2 md:flex"
                    onClick={() => navigate("/settings/account")}
                  >
                    <img src={company.light_logo} className="h-14 max-w-44 rounded object-contain" />
                  </button>
                )}
              </div>
              <div className="col-span-full flex w-full items-center gap-x-2">
                <DropdownCustom
                  align="left"
                  buttonContents={
                    <div className="btn-sm serial-btn-light whitespace-nowrap text-sm">
                      {selectedDateFilter.label}
                      <FontAwesomeIcon icon={faChevronDown} className="mx-2" size="sm" />
                    </div>
                  }
                  className=""
                  dropdownClassName="w-24"
                >
                  {dateFilterOptions.map((option, index) => {
                    return (
                      <div
                        key={index}
                        className={`hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center px-4 py-1 last:mb-1.5 `}
                        onClick={() => setSelectedDateFilter(option)}
                      >
                        <div className="text-serial-palette-600 flex min-w-0 flex-grow text-sm font-medium">{option.label}</div>
                      </div>
                    );
                  })}
                </DropdownCustom>
                <div className="text-light text-serial-palette-500 hidden text-sm md:flex">{`Completed component counts and yield statistics are for the past ${selectedDateFilter.label}`}</div>
              </div>
              <div className="flex h-80 w-full rounded-md border bg-white md:h-96">
                <HomeComponentCountsByStatus
                  days={selectedDateFilter.days}
                  label={selectedDateFilter.label}
                  lastUpdatedEpochTimestamp={lastUpdatedEpochTimestamp}
                />
              </div>
              <div className="flex h-80 w-full rounded-md border bg-white md:h-96">
                <HomeYield days={selectedDateFilter.days} lastUpdatedEpochTimestamp={lastUpdatedEpochTimestamp} />
              </div>
              <div className="flex h-80 w-full rounded-md border bg-white md:h-96">
                <HomeAlerts />
              </div>
              <div className="flex h-fit w-full rounded-md border bg-white md:h-96 xl:col-span-full xl:h-fit">
                <HomeLatestActivity />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
