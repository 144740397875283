import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useRef, useState } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { FieldType, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import { fieldOptions, workInstructionBlockOptions } from "../constants";
import { defaultGroupNames } from "../helpers";

interface DropdownOption<T> {
  type: T;
  label: string;
  icon: IconDefinition;
}

interface ProcessBuilderAddDropdownProps<T> {
  stepIndex: number;
  tileIndex: number;
  options: DropdownOption<T>[];
  selectHandler: (stepIndex: number, tileIndex: number, type: any) => void;
  buttonContents?: React.ReactNode;
}

const DROPDOWN_OPTION_HEIGHT = 30;

const ProcessBuilderAddDropdown: React.FC<ProcessBuilderAddDropdownProps<WorkInstructionBlockType | FieldType>> = ({
  stepIndex,
  tileIndex,
  options,
  selectHandler,
  buttonContents,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOpenDropdown = () => {
    if (!buttonRef.current) return;
    const { x, y } = buttonRef.current.getBoundingClientRect();
    const dropdownHeight = options.length * DROPDOWN_OPTION_HEIGHT + 16;
    if (y > window.innerHeight - dropdownHeight - 50) {
      setDropdownPosition({ x, y: y - dropdownHeight });
    } else {
      setDropdownPosition({ x, y: y + 32 });
    }
    setDropdownOpen(true);
  };

  const handleSelect = (type: WorkInstructionBlockType | FieldType) => {
    selectHandler(stepIndex, tileIndex, type as any);
    setDropdownOpen(false);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: any) => {
      if (!dropdownRef.current || !buttonRef.current) return;
      if (!dropdownOpen || dropdownRef.current.contains(target) || buttonRef.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  return (
    <div className="flex">
      <button ref={buttonRef} className="z-10 flex h-full" onClick={() => handleOpenDropdown()} tabIndex={-1}>
        {buttonContents ?? (
          <FontAwesomeIcon className="text-serial-palette-400 hover:text-serial-palette-600 hidden group-hover:flex" icon={faPlus} />
        )}
      </button>
      {dropdownOpen && (
        <div
          ref={dropdownRef}
          className="border-serial-palette-200 z-20 flex w-40 min-w-36 flex-col overflow-hidden rounded border bg-white py-1.5 shadow-lg"
          style={{ position: "fixed", left: `${dropdownPosition.x}px`, top: `${dropdownPosition.y}px` }}
        >
          {options.map((option, index) => {
            return (
              <button
                key={index}
                className="text-serial-palette-600 hover:bg-serial-palette-50 flex h-[30px] w-full items-center gap-2 px-2 py-1 text-sm font-medium"
                onClick={() => handleSelect(option.type)}
              >
                <div className="flex w-5 justify-center">
                  <FontAwesomeIcon icon={option.icon} className="text-serial-palette-500" />
                </div>
                <div className="">{option.label}</div>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

interface ProcessBuilderAddDropdownPropsPublic {
  stepIndex: number;
  tileIndex: number;
  buttonContents?: React.ReactNode;
}

export const ProcessBuilderAddWorkInstructionBlockDropdown: React.FC<ProcessBuilderAddDropdownPropsPublic> = ({
  stepIndex,
  tileIndex,
  buttonContents,
}) => {
  const { handleAddNewWorkInstructionBlock } = useContext(ProcessBuilderContext);

  return (
    <ProcessBuilderAddDropdown
      stepIndex={stepIndex}
      tileIndex={tileIndex}
      options={workInstructionBlockOptions}
      selectHandler={handleAddNewWorkInstructionBlock}
      buttonContents={buttonContents}
    />
  );
};

export const ProcessBuilderAddFieldDropdown: React.FC<ProcessBuilderAddDropdownPropsPublic> = ({
  stepIndex,
  tileIndex,
  buttonContents,
}) => {
  const { handleAddNewField, draftProcess } = useContext(ProcessBuilderContext);

  const selectHandler = (stepIndex: number, tileIndex: number, type: FieldType) => {
    const previousField = draftProcess?.process_steps?.[stepIndex]?.fields?.[tileIndex - 1];
    // check if the new group name will be the same as the previous field's group name
    if (
      previousField?.type !== FieldType.Image &&
      previousField?.type !== FieldType.File &&
      previousField?.type !== FieldType.Signature &&
      previousField?.type !== FieldType.Label &&
      type === previousField?.type &&
      previousField?.group_name.startsWith(defaultGroupNames[type])
    ) {
      // get group number which should be the characters after the last space in the name (ex: "Checkbox 12" --> 12)
      const lastSpaceIndex = previousField.group_name.lastIndexOf(" ");
      let groupNumber = parseInt(previousField.group_name.substring(lastSpaceIndex + 1));
      groupNumber = isNaN(groupNumber) ? 1 : groupNumber;
      const newGroupName = `${defaultGroupNames[type]} ${groupNumber + 1}`;
      handleAddNewField(stepIndex, tileIndex, type, newGroupName);
    } else {
      handleAddNewField(stepIndex, tileIndex, type);
    }
  };

  return (
    <ProcessBuilderAddDropdown
      stepIndex={stepIndex}
      tileIndex={tileIndex}
      options={fieldOptions}
      selectHandler={selectHandler}
      buttonContents={buttonContents}
    />
  );
};
