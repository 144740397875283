import React, { useState, useRef, useEffect } from "react";
import Transition from "../Transition";
import TypeTag from "../TypeTag";

function DropdownTags({ options, selected, setSelected, className }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex w-36">
      <button
        ref={trigger}
        className={
          "btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-500 hover:text-serial-palette-600 min-w-44 justify-between bg-white" +
          " " +
          className
        }
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <TypeTag type={options.find((o) => o.id === selected)?.id} className="w-24 text-xs" />
        <svg className="text-serial-palette-400 ml-1 shrink-0 fill-current" width="11" height="7" viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="border-serial-palette-200 absolute left-0 top-full z-10 mt-1 w-full overflow-hidden rounded border bg-white  py-1.5 shadow"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="text-serial-palette-600 text-sm font-medium"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.map((option, index) => {
            return (
              <button
                key={index}
                tabIndex="0"
                className={`hover:bg-serial-palette-50 flex w-full cursor-pointer items-center justify-center px-3 py-1 ${option.id === selected && "text-serial-palette-500"}`}
                onClick={() => {
                  setSelected(option.id);
                  setDropdownOpen(false);
                }}
              >
                <TypeTag type={option.id} className="w-24 text-xs" />
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownTags;
