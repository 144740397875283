import React, { useState } from "react";
import { Component } from "@shared/types/databaseTypes";
import { useNavigate } from "react-router-dom";

type ComponentAvatarProps = {
  component: Component | undefined;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  tooltip?: boolean;
  hyperlinked?: boolean;
};

const sizeMapping = {
  xs: { w: "w-6", h: "h-6", text: "text-xs" },
  sm: { w: "w-8", h: "h-8", text: "text-sm" },
  md: { w: "w-16", h: "h-16", text: "text-base" },
  lg: { w: "w-24", h: "h-24", text: "text-2xl" },
  xl: { w: "w-48", h: "h-48", text: "text-4xl" },
};

const ComponentAvatar: React.FC<ComponentAvatarProps> = ({ component, size, tooltip, hyperlinked }) => {
  if (!component) {
    return null;
  }

  const [isHovered, setIsHovered] = useState(false); // Add state for hover
  const abbreviation = component?.name?.substring(0, 2).toUpperCase() || "";
  const navigate = useNavigate();

  return (
    <div
      className={`relative flex ${hyperlinked ? "cursor-pointer" : "cursor-default"}`}
      onMouseEnter={() => setIsHovered(true)} // Set hover state to true when mouse enters
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false when mouse leaves
      onClick={() => {
        if (hyperlinked) navigate(`/component/${component?.id}`);
      }} // Navigate to component page if hyperlinked
    >
      {/* Show tooltip if hovered and tooltip is enabled */}
      {tooltip && isHovered && (
        <div className="bg-serial-palette-700 absolute bottom-full left-1/2 z-10 mb-2 -translate-x-1/2 transform whitespace-nowrap rounded px-2 py-1 text-sm text-white">
          {component?.name}
        </div>
      )}

      {/* Render component image if it exists, else use abbreviation */}
      <div
        className={`rounded-md ${sizeMapping[size].w} ${sizeMapping[size].h} ${hyperlinked && "ring-serial-palette-200 hover:ring-1"} flex items-center justify-center overflow-hidden bg-gray-200`}
      >
        {component?.url ? (
          <img className="max-w-64 h-full max-h-64 w-full object-cover" src={component?.url} alt="Avatar" />
        ) : (
          <span className={`text-black ${sizeMapping[size].text} font-semibold`}>{abbreviation}</span>
        )}
      </div>
    </div>
  );
};

export default ComponentAvatar;
