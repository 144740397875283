import { ParsedResponse } from "@shared/types/apiTypes";
import { makeFetchRequest } from "./helpers";

const apiUrl = import.meta.env.VITE_APP_SERIAL_API_URL;

export const createOrUpdateComponentInstance = async (
  componentId: string,
  identifier: string,
  operatorId: string | null,
  stationId: string | null,
  partNumberId?: string | null,
  workOrderId?: string | null,
): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/components/instances`,
    {
      method: "PUT",
      body: JSON.stringify({
        identifier: identifier,
        component_id: componentId,
        operator_id: operatorId,
        station_id: stationId,
        part_number_id: partNumberId,
        work_order_id: workOrderId,
      }),
    },
    "Could not create component instance",
  );
};
