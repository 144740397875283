import { getSupabase } from "@shared/connections/supabaseAuth";
import store from "@shared/redux/store";
import { ParsedResponse } from "@shared/types/apiTypes";
import { ReportTemplateDashboard } from "@shared/types/databaseTypes";
import { ReportTemplateType } from "@shared/types/databaseEnums";

// ------------------- WRITE FUNCTIONS ------------------- //

export const insertDashboard = async (dashboard: Partial<ReportTemplateDashboard>): Promise<ParsedResponse<ReportTemplateDashboard>> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const payload = {
    ...dashboard,
    company_id: state.db.company?.id,
    type: ReportTemplateType.Dashboard,
    created_by: state.auth.supabase_uid,
    last_edited_by: state.auth.supabase_uid,
  };

  const { data, error } = await supabase
    .from("report_templates")
    .insert(payload as any)
    .select()
    .returns<ReportTemplateDashboard[]>();

  if (error || !data?.[0]) {
    console.log(error);
    return { data: undefined, error: error?.message ?? "Error creating dashboard" };
  }

  return { data: data[0], error: undefined };
};

export const updateDashboard = async (dashboard: Partial<ReportTemplateDashboard>): Promise<ParsedResponse<ReportTemplateDashboard>> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const payload = {
    ...dashboard,
    company_id: state.db.company?.id,
    type: ReportTemplateType.Dashboard,
    created_by: dashboard.created_by ?? state.auth.supabase_uid,
    last_edited_by: state.auth.supabase_uid,
  };

  const { data, error } = await supabase
    .from("report_templates")
    .update(payload as any)
    .eq("id", dashboard.id)
    .eq("revision", dashboard.revision)
    .select()
    .returns<ReportTemplateDashboard[]>();

  if (error || !data?.[0]) {
    console.log(error);
    return { data: undefined, error: error?.message ?? "Error updating dashboard" };
  }

  return { data: data[0], error: undefined };
};

export const deleteDashboard = async (dashboardId: string): Promise<ParsedResponse<undefined>> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { error } = (await supabase.from("report_templates").delete().eq("id", dashboardId)) as any;

  if (error) {
    console.log(error);
    return { data: undefined, error: error?.message ?? "Error deleting dashboard" };
  }

  return { data: undefined, error: undefined };
};
