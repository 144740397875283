import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { WorkOrder } from "@shared/types/databaseTypes";
import { WorkOrderContext } from "../WorkOrders";
import { Loader } from "@shared/components/Loader";
import WorkOrderActivityItem from "./WorkOrderActivityItem";
import { UserRole } from "@shared/types/databaseEnums";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface WorkOrderActivityCardProps {
  workOrder: WorkOrder;
}

const WorkOrderActivityCard: React.FC<WorkOrderActivityCardProps> = ({ workOrder }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [commentInputFocus, setCommentInputFocus] = useState<boolean>(false);
  const role = useSelector((state: RootState) => state.auth.role);

  const activityLogRef = React.useRef<HTMLDivElement>(null);

  const context = useContext(WorkOrderContext);
  const observe = useContext(ObservabilityContext);

  const handleAddComment = async () => {
    setIsLoading(true);
    await context?.handleAddComment(workOrder.id, comment);
    setIsLoading(false);
    setComment("");
    observe.track("work-order_comment-added");
  };

  // add comment on enter key press
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter" && !e.shiftKey && commentInputFocus) {
        e.preventDefault();
        handleAddComment();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleAddComment, commentInputFocus]);

  // scroll to bottom on new activity
  useEffect(() => {
    if (activityLogRef.current) {
      activityLogRef.current.scrollTop = activityLogRef.current.scrollHeight;
    }
  }, [workOrder.activity_log]);

  return (
    <div className="border-serial-palette-200 flex h-full w-full flex-col rounded-md border bg-white">
      <header className="flex justify-between border-b px-4 py-2.5">
        <h2 className="text-serial-palette-800 pr-2 font-semibold">Activity</h2>
        <div className="flex h-full flex-nowrap items-center gap-x-2">
          <p className="text-serial-palette-500 pt-0.5 text-sm">Subscribe</p>
          <div className="form-switch-sm">
            <input
              type="checkbox"
              disabled
              id="switch"
              className="sr-only"
              checked={subscribed}
              onChange={() => setSubscribed(!subscribed)}
            />
            <label className="bg-serial-palette-200" htmlFor="switch">
              <span className="bg-white " aria-hidden="true"></span>
              <span className="sr-only">Switch label</span>
            </label>
          </div>
        </div>
      </header>

      <div className="flex h-full grow-0 flex-col justify-between overflow-hidden">
        {/* A list of activities: [UserAvatar] [User] [Action] [Date (formatted as "X time ago")] */}
        <div className="flex flex-col overflow-y-auto px-4 py-3" ref={activityLogRef}>
          {workOrder.activity_log.map((activity, index) => {
            return <WorkOrderActivityItem key={index} activity={activity} isLast={index === workOrder.activity_log.length - 1} />;
          })}
        </div>
        {/* Option to leave a comment: */}
        {(role == UserRole.Admin || role == UserRole.Member) && (
          <div className="relative flex w-full flex-col px-4 pb-4">
            <div className="flex w-full items-center gap-y-0.5 rounded border pb-1 pl-2 pr-3 pt-0.5">
              <input
                className="form-input m-0 w-full border-none p-0.5 pt-1.5 text-sm"
                placeholder="Leave a comment..."
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                onFocus={() => setCommentInputFocus(true)}
                onBlur={() => setCommentInputFocus(false)}
              />
              {isLoading ? (
                <Loader styleOverride="w-[16px] h-[16px]" />
              ) : (
                <FontAwesomeIcon icon={faPaperPlane} className="text-sm opacity-50 hover:opacity-70" onClick={() => handleAddComment()} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkOrderActivityCard;
