import store from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { supabaseSingleOverride } from "@shared/connections/supabaseDataModifiers";
import { UniqueIdentifierWithLatestProcessEntryAndProcess } from "@shared/types/sharedComponentTypes";
import { UniqueIdentifier } from "@shared/types/databaseTypes";

// ------------------- READ FUNCTIONS ------------------- //

export const appendLatestProcessEntry = async (
  componentInstances: UniqueIdentifier[],
): Promise<UniqueIdentifierWithLatestProcessEntryAndProcess[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const componentInstancesWithLatestProcessEntry = await Promise.all(
    componentInstances.map(async (componentInstance) => {
      const { data: processEntry, error: processEntryError } = supabaseSingleOverride(
        await supabase
          .from("process_entries")
          .select("*, process:processes!inner(*)") // use !inner to make the .eq and .neq filters work on the joined tables
          .eq("unique_identifier_id", componentInstance.id)
          .eq("processes.type", "PRODUCTION")
          .order("timestamp", { ascending: false })
          .limit(1),
      );

      if (processEntryError) {
        console.log("processEntryError", processEntryError);
      }

      return { ...componentInstance, latest_process_entry: processEntry };
    }),
  );
  return componentInstancesWithLatestProcessEntry;
};
