import Button from "@shared/components/primitives/Button";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import { NumberInput, TextInput } from "@shared/components/primitives/Input";

const FrontendPrimitivesTextInput = () => {
  return (
    <FrontendPrimitivesCard title="Text Input">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Sizes - Note that the button heights match input heights</div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-x-4">
            <TextInput size="xs" placeholder="xs" className="w-48" />
            <Button size="xs">Extra Small Input</Button>
          </div>
          <div className="flex items-center gap-x-4">
            <TextInput size="sm" placeholder="sm" className="w-48" />
            <Button size="sm">Small Input</Button>
          </div>
          <div className="flex items-center gap-x-4">
            <TextInput size="md" placeholder="md" className="w-48" />
            <Button size="md">Medium Input</Button>
          </div>
          <div className="flex items-center gap-x-4">
            <TextInput size="lg" placeholder="lg" className="w-48" />
            <Button size="lg">Large Input</Button>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <div className="text-lg font-medium">Number vs Text Input</div>
          <div className="flex flex-col gap-2">
            <TextInput placeholder="Text" className="w-48" />
            <NumberInput placeholder="Number" className="w-48" />
          </div>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesTextInput;
