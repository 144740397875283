import { LabelElement, LabelFormat } from "@shared/types/databaseTypes";
import { LabelElementType, LabelSizeUnit } from "@shared/types/databaseEnums";
import { LabelFormatWithContents } from "../types";
import { v4 as uuidv4 } from "uuid";

export const formatLabelSize = (label: LabelFormat) => {
  // compute the size string to display
  // round to 1 decimal place for mm and 2 decimal places for in
  const precision = label.displayed_size_unit === LabelSizeUnit.mm ? 1 : 2;
  const width = label.displayed_size_unit === LabelSizeUnit.mm ? label.width : label.width / 25.4;
  const height = label.displayed_size_unit === LabelSizeUnit.mm ? label.height : label.height / 25.4;
  const formattedSize = `${width.toFixed(precision)} x ${height.toFixed(precision)} ${label.displayed_size_unit.toLocaleLowerCase()}`;
  return formattedSize;
};

// recursive function to find the element with the given id within a label and return a reference to it
// returns null if no element with the given id is found
export const findElementById = (elementsArray: LabelElement[], id: string): LabelElement | null => {
  for (const element of elementsArray) {
    if (element.id === id) {
      return element;
    }
  }
  return null;
};

interface LabelFormatWithUsage extends LabelFormat {
  used: boolean;
}

export const generateBlankLabel = (companyId: string, componentId: string, userId: string): LabelFormatWithUsage => {
  const blankLabel: LabelFormatWithUsage= {
    id: uuidv4(),
    component_id: componentId,
    company_id: companyId,
    name: "New Label",
    width: 2.5 * 25.4, // 2.5 inches
    height: 1.5 * 25.4, // 1.5 inches
    displayed_size_unit: LabelSizeUnit.in,
    last_edited_at: new Date().toISOString(),
    last_edited_by_user_id: userId,
    created_at: new Date().toISOString(),
    created_by_user_id: userId,
    xml: null,
    xml_identifier: null,
    used: true,
  };
  return blankLabel;
};

export const generateBlankLabelWithContents = (companyId: string, componentId: string, userId: string): LabelFormatWithContents => {
  const blankLabel = generateBlankLabel(companyId, componentId, userId);
  const blankLabelWithContents = {
    ...blankLabel,
    contents: [],
  };
  return blankLabelWithContents;
};

export const generateBlankElement = (type: LabelElementType): LabelElement => {
  let blankElement = {
    id: uuidv4(),
    type,
  } as LabelElement;
  switch (blankElement.type) {
    case LabelElementType.Text:
      blankElement.text = "Static Text";
      blankElement.font_size = 6;
      break;
    case LabelElementType.Image:
      blankElement.file_id = "";
      blankElement.width = 10;
      break;
    case LabelElementType.Barcode:
      blankElement.width = 10;
      blankElement.height = 10;
      break;
    case LabelElementType.QRCode:
      blankElement.width = 10;
      break;
    case LabelElementType.Identifier:
      blankElement.font_size = 6;
      break;
  }
  return blankElement;
};

export const findXmlIdentifier = (xmlString: string): string => {
  const xmlIdentifierRegex = /<DataString>(.*?)<\/DataString>/;
  const match = xmlString.match(xmlIdentifierRegex);
  if (match) {
    return match[1];
  } else {
    return "";
  }
};

export const getXmlHeight = (xmlString: string): number => {
  const xmlHeightRegex = /<Height>(.*?)<\/Height>/;
  const match = xmlString.match(xmlHeightRegex);
  if (match) {
    return parseFloat(match[1]);
  } else {
    return 0;
  }
};

export const getXmlWidth = (xmlString: string): number => {
  const xmlWidthRegex = /<Width>(.*?)<\/Width>/;
  const match = xmlString.match(xmlWidthRegex);
  if (match) {
    return parseFloat(match[1]);
  } else {
    return 0;
  }
};
