import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faTimes } from "@fortawesome/free-solid-svg-icons";
import Banner2 from "./Banner2";

enum NotificationsTab {
  Notifications = "NOTIFICATIONS",
  Archived = "ARCHIVED",
}

interface HeaderNotificationsProps {
  closeDropdown: () => void;
}

const HeaderNotifications: React.FC<HeaderNotificationsProps> = ({ closeDropdown }) => {
  const navigate = useNavigate();
  const [notificationsTab, setNotificationsTab] = useState<NotificationsTab>(NotificationsTab.Notifications);

  return (
    <div className="flex flex-col divide-y">
      <div className="flex h-10 w-full items-center justify-between pl-1 pr-3">
        <div className="flex h-full">
          <button
            onClick={() => setNotificationsTab(NotificationsTab.Notifications)}
            className={`border-b-3 h-full px-2 ${notificationsTab === NotificationsTab.Notifications ? "border-serial-palette-700" : "text-serial-palette-400 border-white"}`}
          >
            <div className="text-sm font-bold">Notifications</div>
          </button>
          <button
            onClick={() => setNotificationsTab(NotificationsTab.Archived)}
            className={`border-b-3 h-full px-2 ${notificationsTab === NotificationsTab.Archived ? "border-serial-palette-700" : "text-serial-palette-400 border-white"}`}
          >
            <div className="text-sm font-bold">Archived</div>
          </button>
        </div>
        <div className="flex gap-2">
          <button onClick={() => navigate("/settings/notifications")}>
            <FontAwesomeIcon icon={faCogs} size="sm" />
          </button>
          <button onClick={() => closeDropdown()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
      <div className="flex h-[50vh] flex-col justify-between gap-2 p-3">
        <Banner2 type="info" open={true} hideX>
          No notifications
        </Banner2>
      </div>
      <div className="flex w-full gap-2 p-2">
        <button className="btn-xs serial-btn-light w-full text-sm">Mark All As Read</button>
        <button className="btn-xs serial-btn-light w-full text-sm">Archive All Read</button>
      </div>
    </div>
  );
};

export default HeaderNotifications;
