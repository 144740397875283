import React, { useState, useRef, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ICellRendererParams } from "ag-grid-community";
import { getInstatiationFileById, uploadInstantiationFile } from "@shared/connections/supabasePartNumbers";
import { Loader } from "@shared/components/Loader";
import { validatePartNumberFileUpload } from "@shared/connections/supabasePartNumberValidation";
import { DataType } from "@shared/types/databaseEnums";
import { ErrorBanner } from "@shared/components/error/types";
import { RowState } from "./PartNumberGrid";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

const renderableExtensions = [
  "html",
  "htm",
  "css",
  "js",
  "json",
  "xml",
  "png",
  "jpeg",
  "jpg",
  "gif",
  "svg",
  "pdf",
  "bmp",
  "ico",
  "tiff",
  "tif",
  "webp",
  "mp4",
  "m4v",
  "webm",
  "ogg",
  "ogv",
  "avi",
  "mov",
];

interface PartNumberFileUploadCellProps extends ICellRendererParams {
  addFileData: (datasetId: string, partNumberId: string, fileName: string | null, fileId: string | null) => void;
  removeFileData: (datasetId: string, partNumberId: string, state: RowState) => void;
  datasetId: string;
  allowPaste?: boolean;
  columnDataType: DataType;
  setError: (error: ErrorBanner) => void;
}

const PartNumberFileUploadCell: React.FC<PartNumberFileUploadCellProps> = ({
  addFileData,
  removeFileData,
  datasetId,
  allowPaste,
  columnDataType,
  setError,
  data,
}) => {
  const [dragOver, setDragOver] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const browseRef = useRef<HTMLInputElement>(null);

  const observe = useContext(ObservabilityContext);

  const handleFileUpload = async (file: File) => {
    observe.track("part-number_file-upload");
    setIsLoading(true);
    const fileValidation = validatePartNumberFileUpload(file, columnDataType);
    if (!fileValidation.isValid) {
      setError(fileValidation);
      setIsLoading(false);
    } else {
      const localStorageUrl = URL.createObjectURL(file);
      const fileId = await uploadInstantiationFile(localStorageUrl);
      if (fileId) {
        addFileData(datasetId, data.id, file.name, fileId);
      }
    }
  };

  const handleDownload = () => {
    if (!fileUrl || !fileName) return;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName ?? "Serial Part Number File";
    link.click();
    const extension = fileName.split(".").pop() ?? "";
    if (renderableExtensions.includes(extension.toLowerCase())) {
      window.open(fileUrl, "_blank");
    }
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    setDragOver(false);
    if (data.id) handleFileUpload(e.dataTransfer.files[0]);
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
    if (data.id) setDragOver(true);
  };

  const onDragLeave = (e: any) => {
    e.preventDefault();
    if (data.id) setDragOver(false);
  };

  const onBrowse = (e: any) => {
    e.preventDefault();
    if (data.id) browseRef.current?.click();
  };

  const onFileSelected = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.target.files[0]);
  };

  // copy paste event handler
  const onPaste = (e: any) => {
    const clipboardData = e.clipboardData;
    const items = clipboardData.items;

    if (!items) return;

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        handleFileUpload(file);
        break;
      }
    }
  };

  const loadFile = async (fileId: string) => {
    if (fileId) {
      const newFileUrl = await getInstatiationFileById(fileId);
      if (newFileUrl) {
        setFileUrl(newFileUrl);
      }
    }
    setIsLoading(false);
  };

  // copy paste event listener
  useEffect(() => {
    const handlePaste = (e: any) => {
      if (allowPaste) {
        onPaste(e);
      }
    };
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [allowPaste]);

  // get file url and name based on file id
  useEffect(() => {
    const fileId = data?.[datasetId]?.file_id ?? null;
    const fileName = data?.[datasetId]?.file_name ?? null;
    loadFile(fileId);
    setFileName(fileName);
  }, [data, datasetId]);

  return (
    <div className={`flex h-full w-full ${!data.id && "opacity-30"}`} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
      {dragOver ? (
        <div className="border-serial-palette-400 flex w-full items-center justify-center rounded-md border border-dashed py-1.5">
          <FontAwesomeIcon icon={faFileUpload} className="text-serial-palette-400" />
        </div>
      ) : (
        <>
          {isLoading && (
            <div className="flex w-full items-center justify-center">
              <Loader styleOverride="w-6 h-6" />
            </div>
          )}
          {!isLoading &&
            (fileUrl ? (
              <div className="flex w-full items-center gap-2">
                <div>
                  <button
                    tabIndex={-1}
                    className="border-serial-palette-200 btn text-serial-palette-600 hover:text-serial-palette-800 h-[25px] w-8 whitespace-nowrap rounded-r-none border border-r-0 bg-white"
                    onClick={() => handleDownload()}
                  >
                    <FontAwesomeIcon size="sm" icon={faEye} />
                  </button>
                  <button
                    tabIndex={-1}
                    className="border-serial-palette-200 btn h-[25px] w-8 whitespace-nowrap rounded-l-none border bg-white text-red-500 hover:text-red-700"
                    onClick={() => removeFileData(datasetId, data.id, data.state)}
                  >
                    <FontAwesomeIcon size="sm" icon={faTimes} />
                  </button>
                </div>
                <p className="truncate">{fileName ?? "File"}</p>
              </div>
            ) : (
              <div className="flex w-full items-center gap-2">
                <button
                  tabIndex={-1}
                  className="border-serial-palette-200 btn-xs h-[25px] w-16 whitespace-nowrap border bg-white text-xs"
                  onClick={(e) => onBrowse(e)}
                >
                  Browse
                </button>
                <p className="truncate font-light italic opacity-70">or drag drop</p>
              </div>
            ))}
        </>
      )}
      <input type="file" disabled={!data.id} ref={browseRef} className="hidden" onChange={(e: any) => onFileSelected(e)} />
    </div>
  );
};

export default PartNumberFileUploadCell;
