import { useEffect } from "react";
import { Image as PdfImage, Page, Text, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import moment from "moment";

import dataTagImage from "@images/pdf/dataTagImage.png";
import dataTagFile from "@images/pdf/dataTagFile.png";
import dataTagLink from "@images/pdf/dataTagLink.png";
import dataTagParametric from "@images/pdf/dataTagParametric.png";
import dataTagPassFail from "@images/pdf/dataTagPassFail.png";
import dataTagCheckbox from "@images/pdf/dataTagCheckbox.png";
import dataTagDatetime from "@images/pdf/dataTagDateTime.png";
import dataTagSignature from "@images/pdf/dataTagSignature.png";

import RobotoThin from "@styles/fonts/roboto/Roboto-Thin.ttf";
import RobotoThinItalic from "@styles/fonts/roboto/Roboto-ThinItalic.ttf";
import RobotoLight from "@styles/fonts/roboto/Roboto-Light.ttf";
import RobotoLightItalic from "@styles/fonts/roboto/Roboto-LightItalic.ttf";
import RobotoRegular from "@styles/fonts/roboto/Roboto-Regular.ttf";
import RobotoItalic from "@styles/fonts/roboto/Roboto-Italic.ttf";
import RobotoMedium from "@styles/fonts/roboto/Roboto-Medium.ttf";
import RobotoMediumItalic from "@styles/fonts/roboto/Roboto-MediumItalic.ttf";
import RobotoBold from "@styles/fonts/roboto/Roboto-Bold.ttf";
import RobotoBoldItalic from "@styles/fonts/roboto/Roboto-BoldItalic.ttf";
import RobotoBlack from "@styles/fonts/roboto/Roboto-Black.ttf";
import RobotoBlackItalic from "@styles/fonts/roboto/Roboto-BlackItalic.ttf";
import { getBase64ImageFromUrl } from "@shared/utils/helpers";
import Table from "@shared/components/react-pdf/Table";
import WorkInstruction from "@shared/components/react-pdf/WorkInstruction";
import { Company, Component, FieldType, Process, ProcessWithReferences, User } from "@shared/types/databaseTypes";
import useProcessMediaNumbering from "@shared/hooks/useProcessMediaNumbering";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoThin, fontWeight: 100 },
    { src: RobotoThinItalic, fontWeight: 100, fontStyle: "italic" },
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoLightItalic, fontWeight: 300, fontStyle: "italic" },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoItalic, fontWeight: 400, fontStyle: "italic" },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoMediumItalic, fontWeight: 500, fontStyle: "italic" },
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBoldItalic, fontWeight: 700, fontStyle: "italic" },
    { src: RobotoBlack, fontWeight: 900 },
    { src: RobotoBlackItalic, fontWeight: 900, fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 20,
    paddingBottom: 40,
    position: "relative",
  },
  section: {
    paddingVertical: 5,
  },
  subsection: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    textAlign: "left",
    fontWeight: "black",
    marginBottom: 4,
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    fontStyle: "italic",
    fontWeight: "medium",
  },
  header: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "left",
    fontWeight: "black",
    marginBottom: 4,
    marginTop: 12,
  },
  subheader: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: 1,
  },
  footer: {
    fontFamily: "Roboto",
    fontSize: 8,
    position: "absolute",
    width: "100%",
    textAlign: "center",
    bottom: 10,
    left: 0,
  },
  unitInfo: {
    fontFamily: "Roboto",
    fontSize: 12,
    paddingTop: 3, // this will add space above the component
    paddingBottom: 3, // this will add space below the component
  },
  dataIndented: {
    paddingLeft: 20,
  },
  doubleDataIndented: {
    paddingLeft: 40,
  },
  groupName: {
    paddingLeft: 10,
    marginBottom: 5,
    fontFamily: "Roboto",
    fontWeight: "medium",
    fontSize: 12,
    underline: true,
  },
  prompt: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: "light",
  },
  boldText: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  mediumText: {
    fontFamily: "Roboto",
    fontWeight: "medium",
  },
  lightText: {
    fontFamily: "Roboto",
    fontWeight: "thin",
  },
  companyLogo: {
    width: 60,
    height: "auto",
    position: "absolute",
    top: 5,
    right: 5,
  },
  componentAvatarContainer: {
    width: 30,
    height: 30,
    borderRadius: 8,
    marginRight: 10,
    overflow: "hidden",
  },
  componentAvatar: {
    width: "100%",
    height: "100%",
  },
  headerContainer: {
    flexDirection: "row",
  },
  horizontalLine: {
    borderBottom: "1 solid black",
    width: "100%",
    marginBottom: 5,
  },
  typeTag: {
    width: 15,
    marginRight: 5,
  },
  checkbox: {
    width: 12,
    height: 12,
    borderWidth: 1,
    borderColor: "black",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  inlineFlexCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
});

const PageHeader = ({ company, component, process, stepName }: { company: any; component: any; process: any; stepName?: string }) => {
  const processName = `${process?.name} - v${process?.revision}`;

  return (
    <View style={{ flexDirection: "column" }}>
      <View style={styles.headerContainer}>
        {company?.logo && <PdfImage style={styles.companyLogo} src={getBase64ImageFromUrl(company?.logo)} />}
        {component?.url && (
          <View style={styles.componentAvatarContainer}>
            <PdfImage style={styles.componentAvatarContainer} src={getBase64ImageFromUrl(component?.url)} />
          </View>
        )}
        <View style={{ flex: 1, marginRight: company?.logo ? 70 : 0 }}>
          <Text style={[styles.subheader]}>{component?.name}</Text>
          <Text style={{ fontSize: 14, fontWeight: "normal", flexWrap: "wrap", marginBottom: 2 }}>{`${processName}`}</Text>
          <Text style={[styles.subtitle, { fontSize: 12, fontWeight: "normal" }]}>{`${stepName ? `${stepName}` : "Summary"}`}</Text>
        </View>
      </View>
      <View style={[styles.horizontalLine, { marginTop: 4, marginBottom: 8 }]} />
    </View>
  );
};

const ProcessBuilderPdf = ({
  process,
  processRevisions,
  component,
  company,
  users,
  options,
  setPdfIsLoading,
}: {
  process: ProcessWithReferences;
  processRevisions: Process[];
  component: Component;
  company: Company;
  users: User[];
  options: {
    showRevisions: boolean;
    showSummary: boolean;
    showDatasets: boolean;
    fileType: "Work Instruction" | "Test Procedure";
    imageSize: "sm" | "md" | "lg";
    imageQuality: "low" | "medium" | "high";
  };
  setPdfIsLoading: (isLoading: boolean) => void;
}) => {
  useEffect(() => {
    setPdfIsLoading(true);
  }, []);
  const { fileNumber, imageNumber, pdfNumber } = useProcessMediaNumbering(process);

  return (
    <Document
      title={`${component?.name} - ${process?.name} v${process?.revision} - ${moment().format("YYYY-MM-DD HH-mm-ss-a")}.pdf`}
      onRender={() => setPdfIsLoading(false)}
    >
      <Page size="A4" style={styles.page}>
        <Text fixed style={styles.footer}>
          Proprietary & Strictly Confidential - NOT FOR EXTERNAL RELEASE
        </Text>
        {(options.showRevisions || options.showSummary) && <PageHeader company={company} component={component} process={process} />}
        {options.showRevisions && (
          <View style={styles.section}>
            <Text style={styles.subheader}>Revision History</Text>
            <Table
              data={[
                ["Revision", "Date", "User", "Description"],
                ...processRevisions.map((process) => [
                  String(process.revision),
                  moment(process.created_at).format("MM/DD/YY HH:mma"),
                  users.find((user) => user.supabase_uid === process.created_by_user_id)?.first_name ?? "Unknown User",
                  process.ai_revision_description ?? process.revision_description ?? "No Change Description Provided",
                ]),
              ]}
              columnWidths={["10%", "10%", "10%", "70%"]}
              headerRow={true}
            />
          </View>
        )}

        {process.process_steps?.map((process_step, index) => {
          return (
            <View key={index} break={index !== 0 || options.showRevisions}>
              <PageHeader company={company} component={component} process={process} stepName={process_step.name} />
              <Text style={styles.subheader}>{options.fileType}</Text>
              <View style={{ marginLeft: 10, marginRight: 4 }}>
                <WorkInstruction
                  process={process}
                  blocks={process_step.work_instruction_blocks ?? []}
                  imageSize={options.imageSize}
                  imageQuality={options.imageQuality}
                  fileNumber={fileNumber}
                  imageNumber={imageNumber}
                  pdfNumber={pdfNumber}
                />
              </View>
              {options.showDatasets && <Text style={[styles.subheader, { paddingTop: 10 }]}>Data Collection</Text>}
              {options.showDatasets &&
                process_step.fields?.map((field, index) => {
                  let lastGroupName = "";
                  if (index !== 0) {
                    lastGroupName = process_step.fields?.[index - 1].group_name ?? "";
                  }
                  switch (field.type) {
                    case FieldType.Link:
                      return (
                        <View key={index}>
                          {field.group_name !== lastGroupName && <Text style={styles.groupName}> {field.group_name}</Text>}
                          <View style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagLink} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                          </View>
                        </View>
                      );
                    case FieldType.ManualEntry:
                      let specString = "";
                      if (
                        field.dataset?.lsl !== null &&
                        field.dataset?.lsl !== undefined &&
                        field.dataset?.usl !== null &&
                        field.dataset?.usl !== undefined
                      ) {
                        specString = `Must be between: ${field.dataset?.lsl} - ${field.dataset?.usl}`;
                      } else if (field.dataset?.lsl) {
                        specString = `Must be: >${field.dataset?.lsl}`;
                      } else if (field.dataset?.usl) {
                        specString = `Must be: <${field.dataset?.usl}`;
                      }
                      return (
                        <View key={index}>
                          {field.group_name !== lastGroupName && (
                            <Text style={[styles.groupName, styles.dataIndented]}>Parametric: {field.group_name}</Text>
                          )}
                          <View wrap style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagParametric} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                            <Text style={styles.mediumText}> Type: </Text>
                            {field.data_validation},
                            {specString !== "" && (
                              <Text>
                                <Text style={styles.mediumText}> Spec: </Text>
                                {specString},{" "}
                              </Text>
                            )}
                            {field.dataset?.unit && (
                              <Text>
                                <Text style={styles.mediumText}> Unit: </Text>
                                {field.dataset?.unit},{" "}
                              </Text>
                            )}
                          </View>
                        </View>
                      );
                    case FieldType.Checkbox:
                      return (
                        <View key={index}>
                          {field.group_name !== lastGroupName && (
                            <Text style={[styles.groupName, styles.dataIndented]}>Checkbox: {field.group_name}</Text>
                          )}
                          <View style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagCheckbox} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                          </View>
                        </View>
                      );
                    case FieldType.PassFail:
                      return (
                        <View key={index}>
                          {field.group_name !== lastGroupName && (
                            <Text style={[styles.groupName, styles.dataIndented]}>{field.group_name}</Text>
                          )}
                          <View style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagPassFail} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                          </View>
                        </View>
                      );
                    case FieldType.Datetime:
                      return (
                        <View key={index}>
                          {field.group_name !== lastGroupName && (
                            <Text style={[styles.groupName, styles.dataIndented]}>{field.group_name}</Text>
                          )}
                          <View style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagDatetime} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                          </View>
                        </View>
                      );
                    case FieldType.Image:
                      return (
                        <View key={index}>
                          <Text style={[styles.groupName, styles.dataIndented]}>Image: </Text>
                          <View style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagImage} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                            <Text style={styles.mediumText}> Method: </Text>
                            {field.method}
                          </View>
                        </View>
                      );
                    case FieldType.File:
                      return (
                        <View key={index}>
                          <Text style={[styles.groupName, styles.dataIndented]}>File:</Text>
                          <View style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagFile} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                          </View>
                        </View>
                      );
                    case FieldType.Signature:
                      return (
                        <View key={index}>
                          <Text style={[styles.groupName, styles.dataIndented]}>File:</Text>
                          <View style={[styles.unitInfo, styles.doubleDataIndented, styles.inlineFlexCenter]}>
                            <PdfImage style={styles.typeTag} src={dataTagSignature} />
                            <Text style={styles.prompt}> "{field?.prompt}"</Text>
                          </View>
                        </View>
                      );
                  }
                })}
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export default ProcessBuilderPdf;
