import React, { useCallback, useContext, useMemo, useState } from "react";
import { ProductionContext } from "../ProductionProvider";
import { ProcessType } from "@shared/types/databaseEnums";
import { useNavigate } from "react-router-dom";
import { createStationProcessLink, deleteStationProcessLink } from "../connections/supabase";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { Loader } from "@shared/components/Loader";
import { Process } from "@shared/types/databaseTypes";

const ProductionProcessSelectorDedicated: React.FC = () => {
  const [linksPendingChanges, setLinksPendingChanges] = useState<{ processId: string; componentId: string }[]>([]);
  const { db, station, configureStation, handleUpdateStation } = useContext(ProductionContext);
  const { triggerToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const componentsWithProcesses = useMemo(() => {
    return db.components.map((component) => {
      // filter and sort the process links for this component
      const filteredProcesses = db.componentProcessLinks
        ?.filter((link) => {
          return link.component_id === component.id && link.process?.type === ProcessType.Production && link.is_active;
        })
        ?.sort((a, b) => {
          return (a.order ?? 0) - (b.order ?? 0);
        })
        ?.map((link) => {
          return db.processes?.find((process) => process.id === link.process_id);
        });
      return { ...component, processes: filteredProcesses };
    });
  }, [db]);

  const handleCreateStationProcessLink = useCallback(
    async (process: Process, componentId: string) => {
      if (!station) {
        console.error("No station to add links to");
        return;
      }
      setLinksPendingChanges([...linksPendingChanges, { processId: process.id, componentId }]);
      const { error } = await createStationProcessLink(station, process.id, process.revision, componentId);
      await handleUpdateStation();
      setLinksPendingChanges((prevLinksPendingChanges) =>
        prevLinksPendingChanges.filter((link) => link.processId !== process.id && link.componentId !== componentId),
      );
      if (error) {
        console.error(error);
        triggerToast(ToastType.Error, "Error activating process", error);
      }
    },
    [station, linksPendingChanges],
  );

  const handleDeleteStationProcessLink = useCallback(
    async (processId: string, componentId: string) => {
      let stationProcessLinkToDelete = station?.station_process_links?.find(
        (link) => link.process_id === processId && link.component_id === componentId,
      );
      if (!stationProcessLinkToDelete) {
        console.error("Could not find station process link to update");
        return;
      }
      setLinksPendingChanges([...linksPendingChanges, { processId, componentId }]);
      const { error } = await deleteStationProcessLink(stationProcessLinkToDelete.id);
      await handleUpdateStation();
      setLinksPendingChanges((prevLinksPendingChanges) =>
        prevLinksPendingChanges.filter((link) => link.processId !== processId && link.componentId !== componentId),
      );
      if (error) {
        console.error(error);
        triggerToast(ToastType.Error, "Error deactivating process", error);
      }
    },
    [station, handleUpdateStation, linksPendingChanges],
  );

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col">
      <div className="flex min-h-0 min-w-0 flex-grow flex-col overflow-y-auto">
        {componentsWithProcesses
          .sort((a, b) => b.processes.length - a.processes.length)
          .map((component, index) => {
            const numEnabledProcesses = station?.station_process_links?.filter((obj) => obj.component_id === component.id).length ?? 0;
            return (
              <div key={index} className="">
                {((numEnabledProcesses > 0 && component.processes.length > 0) || configureStation) && (
                  <h2 className="text-serial-palette-800 mx-8 mb-2 mt-8 text-2xl font-bold leading-snug md:mx-24">{component.name}</h2>
                )}
                <div className="mx-8 grid grid-cols-12 gap-4 md:mx-24">
                  {component.processes.length > 0
                    ? component.processes.map((process, index) => {
                        if (process === undefined) return null;
                        const isActive =
                          station?.station_process_links?.find(
                            (stationLink) => stationLink.process_id === process.id && stationLink.component_id === component.id,
                          ) !== undefined;
                        const isLoading = linksPendingChanges.find(
                          (link) => link.processId === process.id && link.componentId === component.id,
                        );
                        // If the the station selector isn't in config mode
                        if (!configureStation && !isActive) return null;
                        return (
                          <button
                            key={index}
                            className={`tile hover:border-serial-palette-300 col-span-full rounded-md border sm:col-span-6 xl:col-span-3 ${isActive ? "border-serial-palette-200 text-serial-palette-800 bg-white" : "bg-serial-palette-50 border-serial-palette-200 text-serial-palette-400"} h-20 md:h-24`}
                            onClick={() =>
                              configureStation
                                ? isActive
                                  ? handleDeleteStationProcessLink(process.id, component.id)
                                  : handleCreateStationProcessLink(process, component.id)
                                : navigate(`/production/${station?.id}/${process.id}`)
                            }
                          >
                            <div className="text-md flex h-full items-center justify-center text-center">
                              <h3 className="mb-1 pr-2 font-bold">{index + 1}. </h3>
                              <h3 className="mb-1 font-semibold">{process.name}</h3>
                              {isLoading && <Loader styleOverride="ml-2 w-[18px] h-[18px]" />}
                            </div>
                          </button>
                        );
                      })
                    : configureStation && (
                        <p className="col-span-12 italic">
                          No processes found, use the component configuration tool to setup new processes for {component.name}
                        </p>
                      )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductionProcessSelectorDedicated;
