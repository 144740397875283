import { TIMESTAMP_TOLERANCE } from "./constants";
import { GenealogyFamilyMember } from "./types";

// When a process is rerun with the same links, links created in the previous process entry will be marked as removed and the new links will be created.
// This creates a confusing timeline view where the same link keeps getting added and removed.
// The squashGenealogy function will recursively combine a pair of links that have the same parent/child and where one link's created_at timestamp comes right after the other link's removed_at timestamp (within a tolerance).

const findSquashableLinkPair = (
  genealogy: GenealogyFamilyMember[],
): { createdLink: GenealogyFamilyMember; removedLink: GenealogyFamilyMember } | null => {
  const removedMembers = genealogy.filter((member) => member.link?.is_active === false && member.link?.removed_at);
  for (const removedMember of removedMembers) {
    // For each removed link, check if an identical link was subsequently created within the tolerance time
    const createdMember = genealogy.find((createdMember) => {
      return (
        createdMember.link?.has_child_of_id === removedMember.link?.has_child_of_id && // same child
        createdMember.link?.unique_identifier_id === removedMember.link?.unique_identifier_id && // same parent
        createdMember.link?.id !== removedMember.link?.id && // not the same link
        createdMember.link?.created_at &&
        removedMember.link?.removed_at && // both have timestamps
        new Date(createdMember.link.created_at).getTime() - new Date(removedMember.link.removed_at).getTime() < TIMESTAMP_TOLERANCE && // created_at is within tolerance of removed_at
        new Date(createdMember.link.created_at).getTime() >= new Date(removedMember.link.removed_at).getTime()
      ); // created_at is after removed_at
    });
    // If so, return the link pair
    if (createdMember) {
      return { createdLink: createdMember, removedLink: removedMember };
    }
  }
  return null;
};

export const squashGenealogy = (genealogy: GenealogyFamilyMember[]): GenealogyFamilyMember[] => {
  const squashableLinkPair = findSquashableLinkPair(genealogy);
  if (squashableLinkPair) {
    let newGenealogy: GenealogyFamilyMember[] = JSON.parse(JSON.stringify(genealogy)); // create a deep copy of the genealogy array
    // Remove the shquashable link pair from the genealogy array
    newGenealogy = newGenealogy.filter(
      (member) =>
        member.link?.id !== squashableLinkPair.createdLink.link?.id && member.link?.id !== squashableLinkPair.removedLink.link?.id,
    );
    // Create a new genealogy member that takes the removal reason, removal timestamp and is_active status from the created link and puts it on the removed link
    const newGenealogyMember = JSON.parse(JSON.stringify(squashableLinkPair.removedLink));
    newGenealogyMember.link.is_active = squashableLinkPair.createdLink.link?.is_active;
    newGenealogyMember.link.removed_at = squashableLinkPair.createdLink.link?.removed_at;
    newGenealogyMember.link.removal_reason = squashableLinkPair.createdLink.link?.removal_reason;
    // Add the new genealogy member to the genealogy array
    newGenealogy.push(newGenealogyMember);
    return squashGenealogy(newGenealogy);
  }
  return genealogy;
};

// Function to check if a member in the genealogy array has an active link all the way up to the root
export const hasActiveLinkToRoot = (genealogy: GenealogyFamilyMember[], member: GenealogyFamilyMember): boolean => {
  if (member.link?.is_active === false) {
    return false;
  }
  if (member.parent_id === null) {
    return true;
  }
  const parent = genealogy.find((parent) => parent.id === member.parent_id);
  if (parent) {
    return hasActiveLinkToRoot(genealogy, parent);
  }
  return false;
};
