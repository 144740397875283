import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";

// ------------------- READ FUNCTIONS ------------------- //

// Fetch all alerts data from supabase
export const fetchAlerts = async () => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { data: alertData, error: alertError } = (await supabase.from("alerts").select("*")) as any;
    // Handle database error
    if (alertError) {
      console.log(alertError);
    }
    return alertData;
  } catch (error) {
    // Handle request error
    console.error(error);
  }
};

// Fetch all alerts data from supabase
export const fetchAlertsByRelevantIds = async (relevantIds: string[]) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { data: alertData, error: alertError } = (await supabase
      .from("alerts")
      .select("*")
      .containedBy("relevant_ids", relevantIds)) as any;

    // Handle database error
    if (alertError || !alertData) {
      console.error(alertError);
      return [];
    }
    return alertData;
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};

// ------------------- WRITE FUNCTIONS ------------------ //

// Create new alert supabase
export const createAlert = async (message: string, type: string, link: string, relevantIds: string[]) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);
  console.log(relevantIds);
  // New alert payload
  const newAlert = {
    company_id: state.db.company.id,
    message: message,
    type: type,
    link: link,
    relevant_ids: relevantIds,
    // id, is_active, created_at are all set automatically
  };

  try {
    const { data: alertData, error: alertError } = (await supabase.from("alerts").insert([newAlert]).select().single()) as any;
    // Handle database error
    if (alertError) {
      console.log(alertError);
    }
    return alertData;
  } catch (error) {
    // Handle request error
    console.error(error);
  }
};

// Delete all alerts with matching relevant_ids
export const deleteAlertsByRelevantIds = async (relevantIds: string[]) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { error: alertError } = (await supabase.from("alerts").delete().containedBy("relevant_ids", relevantIds)) as any;

    // Handle database error
    if (alertError) {
      console.error(alertError);
    }
  } catch (error) {
    // Handle request error
    console.error(error);
  }
};

// Delete all alerts with matching relevant_ids
export const deactivateAlertsByRelevantIds = async (relevantIds: string[]) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { error: alertError } = (await supabase
      .from("alerts")
      .update({ is_active: false })
      .containedBy("relevant_ids", relevantIds)) as any;

    // Handle database error
    if (alertError) {
      console.error(alertError);
    }
  } catch (error) {
    // Handle request error
    console.error(error);
  }
};

// Delete all alerts with matching id
export const deactivateAlertsById = async (alertId: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { error: alertError } = (await supabase.from("alerts").update({ is_active: false }).eq("id", alertId)) as any;

    // Handle database error
    if (alertError) {
      console.error(alertError);
    }
  } catch (error) {
    // Handle request error
    console.error(error);
  }
};
