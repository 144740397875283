import Table from "@shared/components/primitives/Table";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import Button from "@shared/components/primitives/Button";

const FrontendPrimitivesTables = () => {
  return (
    <FrontendPrimitivesCard title="Tables">
      {/* Basic Table */}
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Basic Table</div>
        <Table.Root>
          <Table.Title title="Invoices" count={3}>
            <Button size="md">Table Action Button</Button>
          </Table.Title>
          <Table.Contents>
            <Table.Header>
              <Table.Row>
                <Table.Head className="w-[100px]">Invoice</Table.Head>
                <Table.Head>Status</Table.Head>
                <Table.Head>Method</Table.Head>
                <Table.Head className="text-right">Amount</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell className="font-medium">INV001</Table.Cell>
                <Table.Cell>Paid</Table.Cell>
                <Table.Cell>Credit Card</Table.Cell>
                <Table.Cell className="text-right">$250.00</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="font-medium">INV001</Table.Cell>
                <Table.Cell>Paid</Table.Cell>
                <Table.Cell>Credit Card</Table.Cell>
                <Table.Cell className="text-right">$234.00</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="font-medium">INV003</Table.Cell>
                <Table.Cell>Paid</Table.Cell>
                <Table.Cell>Credit Card</Table.Cell>
                <Table.Cell className="text-right">$150.00</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Contents>
        </Table.Root>
      </div>

      {/* Wth No Data */}
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">No Data</div>
        <Table.Root>
          <Table.Title title="Invoices" count={0} />
          <Table.Contents>
            <Table.Header>
              <Table.Row>
                <Table.Head className="w-[100px]">Invoice</Table.Head>
                <Table.Head>Status</Table.Head>
                <Table.Head>Method</Table.Head>
                <Table.Head className="text-right">Amount</Table.Head>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.NoData>No data available</Table.NoData>
            </Table.Body>
          </Table.Contents>
        </Table.Root>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesTables;
