import React, { useContext, useEffect, useMemo } from "react";
import { ProductionContext } from "../ProductionProvider";
import ProductionFormGroup from "./production-form-fields/ProductionFormGroup";
import useFieldGroups from "../hooks/useFieldGroups";
import { FieldType } from "@shared/types/databaseTypes";
import Banner2 from "@shared/components/Banner2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

const AUTO_ADVANCING_FIELD_TYPES = [FieldType.ManualEntry, FieldType.Link];

interface ProductionStepFormProps {
  identifierInputRef: React.RefObject<HTMLInputElement>;
}

const ProductionStepForm: React.FC<ProductionStepFormProps> = ({ identifierInputRef }) => {
  const {
    process,
    submissionError,
    filteredSteps,
    selectedStepIndex,
    operatorModalOpen,
    stationModalOpen,
    instantiationModalOpen,
    focusedFieldId,
    setWorkInstructionsOpen,
    handleSetFocusedField,
  } = useContext(ProductionContext);
  const fields = useMemo(() => filteredSteps[selectedStepIndex]?.fields ?? [], [filteredSteps, selectedStepIndex]);
  const fieldGroups = useFieldGroups({ fields });

  const hasWorkInstructions = useMemo(() => {
    return filteredSteps[selectedStepIndex]?.work_instruction_blocks?.length > 0;
  }, [filteredSteps, selectedStepIndex]);

  // auto-advance to next field on enter key press if no modals are open (only for certain field types)
  useEffect(() => {
    const handleEnterKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        const focusedFieldIndex = fields.findIndex((field) => field.id === focusedFieldId);
        const nextFieldId = focusedFieldIndex !== undefined ? fields[focusedFieldIndex + 1]?.id : undefined;
        if (nextFieldId) handleSetFocusedField(nextFieldId);
      }
    };
    const fieldType = fields.find((field) => field.id === focusedFieldId)?.type;
    const isAutoAdvancingField = fieldType ? AUTO_ADVANCING_FIELD_TYPES.includes(fieldType) : false;
    const isIdentifierFieldAndBatchAllowed = process?.allow_batch && identifierInputRef.current === document.activeElement;
    const modalsOpen = operatorModalOpen || stationModalOpen || instantiationModalOpen;
    if (!modalsOpen && isAutoAdvancingField && !isIdentifierFieldAndBatchAllowed) document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [operatorModalOpen, focusedFieldId, process]);

  return (
    <div className="flex h-full w-full justify-center">
      <div
        className={`flex h-full w-full flex-col items-center gap-y-3 p-4 ${hasWorkInstructions ? "w-full max-w-[600px]" : "w-full md:w-2/3 lg:w-[500px] xl:w-[550px] 2xl:w-[600px]"}`}
      >
        {submissionError && (
          <Banner2 className="w-full px-2" type="error" open hideX>
            {submissionError}
          </Banner2>
        )}
        <div className="flex w-full min-w-0 flex-grow flex-col items-center gap-y-3 lg:flex-grow-0 lg:px-2">
          {hasWorkInstructions && (
            <div className="flex w-full justify-center lg:hidden">
              <button className="btn serial-btn-dark z-10 w-full gap-x-2 shadow-lg" onClick={() => setWorkInstructionsOpen(true)}>
                <FontAwesomeIcon icon={faBook} />
                Work Instructions
              </button>
            </div>
          )}
          {fieldGroups.map((group, index) => {
            return <ProductionFormGroup key={index} group={group} index={index} />;
          })}
          {fieldGroups.length === 0 && (
            <button className="text-serial-palette-300 absolute flex w-full justify-center text-lg font-light italic">
              No data collection
            </button>
          )}
          <div className="h-1 shrink-0" />
        </div>
      </div>
    </div>
  );
};

export default ProductionStepForm;
