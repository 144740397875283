import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";

interface DebugFileIntegrityProps {}

const DebugFileIntegrity: React.FC<DebugFileIntegrityProps> = ({}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const supabase = getSupabase(token);
  const company = useSelector((state: RootState) => state.db.company);

  const [missingFiles, setMissingFiles] = useState<any[]>([]);

  function flattenJson(data: any, parentKey: string = "", result: any = {}) {
    for (let key in data) {
      let newKey = parentKey ? `${parentKey}_${key}` : key;
      if (typeof data[key] === "object" && data[key] !== null && !Array.isArray(data[key])) {
        flattenJson(data[key], newKey, result);
      } else {
        result[newKey] = data[key];
      }
    }
    return result;
  }

  const loadData = async () => {
    const buckets = ["images", "files"];
    let missingFilesCombined: any[] = [];

    for (const bucketType of buckets) {
      // get total file count
      const { count: fileCount, error: fileCountError } = await supabase.from(bucketType).select("*", { count: "exact", head: true });

      if (fileCountError || !fileCount) return;

      let filesMissingData: any[] = [];
      let allFilesDatabase: any[] = [];
      let allFilesStorage: any[] = [];

      // iterate through files 500 at a time
      // for each file, check if it exists in the storage bucket
      // if it does not, add it to a list of files missing data

      for (let offset = 0; offset < fileCount; offset += 500) {
        const { data: filesDatabase, error: filesDatabaseError } = await supabase
          .from(bucketType)
          .select("*")
          .order("created_at", { ascending: true })
          .range(offset, offset + 500);
        if (filesDatabaseError) console.log(filesDatabaseError);

        if (filesDatabase && filesDatabase.length > 0) {
          allFilesDatabase = [...allFilesDatabase, ...filesDatabase];
        }

        const { data: filesStorage, error: filesStorageError } = await supabase.storage.from(`data-${bucketType}`).list(company.id, {
          limit: 500,
          offset: offset,
        });
        if (filesStorageError) console.log(filesStorageError);

        if (filesStorage && filesStorage.length > 0) {
          allFilesStorage = [...allFilesStorage, ...filesStorage];
        }
      }

      // print the first 10 files from each list
      console.log("allFilesDatabase", allFilesDatabase.length);
      console.log("allFilesStorage", allFilesStorage.length);

      // iterate through files in database
      // for each file, check if it exists in the storage bucket
      // if it does not, add it to a list of files missing data

      for (let i = 0; i < allFilesDatabase.length; i++) {
        const fileDatabase = allFilesDatabase[i];
        const fileId = fileDatabase.file_id ?? fileDatabase.id;
        if (fileDatabase.bucket_name !== `data-${bucketType}`) continue;

        const fileStorage = allFilesStorage.find((file) => file.name === fileId);
        if (!fileStorage) {
          filesMissingData.push(fileDatabase);
        }
      }

      // for each missing data file, join get the processEntry and join it onto the file

      for (let i = 0; i < filesMissingData.length; i++) {
        const file = filesMissingData[i];
        const { data: processEntry, error: processEntryError }: any = await supabase
          .from("process_entries")
          .select("*, process:processes(*), operator:operator_id(*), station:station_id(*), unique_identifier:unique_identifier_id(*)")
          .eq("id", file.process_entry_id)
          .single();
        if (processEntryError) console.log(processEntryError);

        const { data: component, error: componentError } = await supabase
          .from("components")
          .select("*")
          .eq("id", processEntry?.unique_identifier?.component_id)
          .single();
        if (processEntryError) console.log(processEntryError);
        if (componentError) console.log(componentError);

        filesMissingData[i] = flattenJson({
          ...file,
          process_entry: processEntry,
          process_entry_component: component,
        });
      }
      missingFilesCombined = [...missingFilesCombined, ...filesMissingData];
      // filter out all process_entries that have upload_error = true
      missingFilesCombined = missingFilesCombined.filter((file) => file?.process_entry_upload_error === false);
    }

    setMissingFiles(missingFilesCombined);
    console.log("filesMissingData", missingFilesCombined);
  };

  function convertJsonToCsv(jsonArray: any, keysToInclude: any) {
    const header = keysToInclude.join(",");
    const rows = jsonArray.map((obj: any) => {
      return keysToInclude.map((key: any) => obj[key] || "").join(",");
    });
    return [header, ...rows].join("\n");
  }

  const handleDownload = () => {
    const fileName = "missing.csv";
    const keysToInclude = [
      "file_name",
      "id",
      "process_entry_id",
      "process_entry_unique_identifier_identifier",
      "process_entry_component_name",
      "bucket_name",
      "process_entry_process_name",
      "process_entry_timestamp",
      "process_entry_operator_id",
      "process_entry_operator_first_name",
    ]; // Specify the keys to include in the CSV
    const csv = convertJsonToCsv(missingFiles, keysToInclude);
    console.log(missingFiles);
    const blob = new Blob([csv], { type: "text/csv" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex w-full items-center justify-between">
      <h1 className="text-lg">
        <span className="font-bold">Storage Audit:</span> Export list of files missing from storage but recoreded in databse
      </h1>
      <button className="btn w-36 bg-black text-white" onClick={loadData}>
        Load Data
      </button>
      {missingFiles.length > 0 && (
        <button className="btn w-36 bg-black text-white" onClick={handleDownload}>
          Download CSV
        </button>
      )}
    </div>
  );
};

export default DebugFileIntegrity;
