import React, { useEffect, useState } from "react";
import ErrorBoundary from "@shared/components/error/ErrorBoundary";
import StationsGraphEntries from "./StationsGraphEntries";
import { StationEntriesGraphData, StationEntriesGraphDataTimeRange } from "../types";
import { hexToRGB } from "@shared/utils/helpers";

interface StationDetailsChartProps {
  chartGraphData: StationEntriesGraphData | null;
}

const StationDetailsChart: React.FC<StationDetailsChartProps> = ({ chartGraphData }) => {
  const [showChart, setShowChart] = useState<boolean>(true); // boolean used to toggle the chart to force a re-render

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      // Green line (completed identifiers)
      {
        data: [],
        name: "Pass",
        fill: true,
        backgroundColor: `rgba(${hexToRGB("#22c55e")}, 0.08)`, // tailwind green-500
        borderColor: "#22c55e", // tailwind green-500
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: "#22c55e", // tailwind green-500
        clip: 20,
      },
      // Red line (defective identifiers)
      {
        data: [],
        name: "Fail",
        borderColor: "#ef4444", // tailwind red-500
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: "#ef4444", // tailwind red-500
        clip: 20,
      },
    ],
  });

  // Update the chart data when the data changes
  useEffect(() => {
    setShowChart(false);
    updateChartData();
  }, [chartGraphData]);

  // Function to parse the data from the database
  function parseDatabaseGraphData(timeRanges: StationEntriesGraphDataTimeRange[]) {
    const labels: Date[] = [];
    const passCounts: number[] = [];
    const failCounts: number[] = [];

    timeRanges.forEach((range) => {
      const start = new Date(range.start);
      labels.push(start);
      passCounts.push(range.pass_count);
      failCounts.push(range.fail_count);
    });

    return { labels, passCounts, failCounts };
  }

  // Function to update the chart data after prop change
  const updateChartData = async () => {
    if (!chartGraphData) return;
    // Parse data
    const { labels, passCounts, failCounts } = parseDatabaseGraphData(chartGraphData.time_ranges);
    let newChartData: any = { ...chartData };
    newChartData.datasets[0].data = passCounts;
    newChartData.datasets[1].data = failCounts;
    newChartData.labels = labels;
    // Update state
    setChartData({ ...newChartData });
    setShowChart(true);
  };

  return (
    <div className="shrink-1 flex grow">
      {showChart && (
        <ErrorBoundary>
          <StationsGraphEntries data={chartData} width={100} height={128} />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default StationDetailsChart;
