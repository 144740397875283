import React from "react";
import { UserRolePrettyNames } from "@shared/constants/serial";
import UserAvatar from "@shared/components/UserAvatar";

function UserTableItem({ user, index, company, handleUserEdit }) {
  return (
    <tr className={`${!user.is_active && "opacity-30"}`}>
      <td className="flex justify-center whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
        <UserAvatar user={user} size="md" />
      </td>
      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">{user.first_name + " " + user.last_name}</td>
      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">{user.title}</td>
      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">{user.email}</td>
      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">{company}</td>
      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">
        {UserRolePrettyNames.find((role) => role.id === user.role)?.pretty_name}
      </td>
      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">{user.is_active ? "Active" : "Inactive"}</td>
      <td className="w-px whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
        <div className="m-1.5">
          <button
            className="btn border-serial-palette-200 hover:bg-serial-palette-200"
            onClick={(e) => {
              e.stopPropagation();
              handleUserEdit(user.supabase_uid);
            }}
          >
            <svg className="text-serial-palette-500 h-4 w-4 shrink-0 fill-current" viewBox="0 0 16 16">
              <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
            </svg>
          </button>
        </div>
      </td>
    </tr>
  );
}
export default UserTableItem;
