import { CollectionType, FilterConditionOperator } from "@shared/types/databaseTypes";

export interface FilterTestData {
  [filterId: string]: any;
}

export interface FilterConditionValueOption {
  label: string;
  value: string;
}

export const filterConditionOperatorStyle = {
  [FilterConditionOperator.Equals]: { label: "Equals", symbol: "=", explanation: "The data must be equal to the value." },
  [FilterConditionOperator.NotEquals]: { label: "Not Equals", symbol: "!=", explanation: "The data must not be equal to the value." },
  [FilterConditionOperator.GreaterThan]: { label: "Greater Than", symbol: ">", explanation: "The data must be greater than the value." },
  [FilterConditionOperator.LessThan]: { label: "Less Than", symbol: "<", explanation: "The data must be less than the value." },
  [FilterConditionOperator.GreaterThanOrEqualTo]: {
    label: "Greater Than or Equal To",
    symbol: ">=",
    explanation: "The data must be greater than or equal to the value.",
  },
  [FilterConditionOperator.LessThanOrEqualTo]: {
    label: "Less Than or Equal To",
    symbol: "<=",
    explanation: "The data must be less than or equal to the value.",
  },
  [FilterConditionOperator.Contains]: { label: "Contains", symbol: "=~", explanation: "The string must contain the entered substring." },
  [FilterConditionOperator.DoesNotContain]: {
    label: "Does Not Contain",
    symbol: "!~",
    explanation: "The string must not contain the entered substring.",
  },
  [FilterConditionOperator.In]: {
    label: "One of List of Values",
    symbol: "in",
    explanation: "The entered string is among the avaiable strings",
  },
  [FilterConditionOperator.NotIn]: {
    label: "Not One of List of Values",
    symbol: "!in",
    explanation: "The entered string is not among the avaiable strings",
  },
};

export const numericalRangeOperators = [
  FilterConditionOperator.LessThan,
  FilterConditionOperator.LessThanOrEqualTo,
  FilterConditionOperator.GreaterThan,
  FilterConditionOperator.GreaterThanOrEqualTo,
];

export const allowedFilterConditionOperators: Record<CollectionType, FilterConditionOperator[]> = {
  [CollectionType.Identifier]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.Link]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.ParametricQualitative]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.ParametricQuantitative]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [CollectionType.Image]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.File]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.Checkbox]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [CollectionType.PassFail]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [CollectionType.Status]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [CollectionType.LinkStatus]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [CollectionType.PartNumber]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.Station]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.Operator]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.Contains,
    FilterConditionOperator.DoesNotContain,
    FilterConditionOperator.In,
    FilterConditionOperator.NotIn,
  ],
  [CollectionType.CycleTime]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [CollectionType.Timestamp]: [
    FilterConditionOperator.Equals,
    FilterConditionOperator.NotEquals,
    FilterConditionOperator.GreaterThan,
    FilterConditionOperator.LessThan,
    FilterConditionOperator.GreaterThanOrEqualTo,
    FilterConditionOperator.LessThanOrEqualTo,
  ],
  [CollectionType.ProcessResult]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [CollectionType.WorkOrderName]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
  [CollectionType.WorkOrderPhase]: [FilterConditionOperator.Equals, FilterConditionOperator.NotEquals],
};
