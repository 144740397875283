import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { PropsWithChildren } from "react";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TypeTag from "../TypeTag";

export interface DropdownListItem {
  id: any;
  label: string;
  icon?: IconProp;
  tag?: string;
}

interface DropdownProps {
  align?: "left" | "right";
  size?: "sm" | "md" | "lg";
  open?: boolean;
  items: DropdownListItem[];
  onSelect?: (id: any) => void;
  closeOnClick?: boolean;
  closeOnMouseLeave?: boolean;
}

const DropdownList: React.FC<PropsWithChildren<DropdownProps>> = ({
  size = "sm",
  align = "left",
  items,
  open,
  onSelect,
  closeOnClick,
  closeOnMouseLeave,
  children,
}) => {
  return (
    <Dropdown
      size={size}
      align={align}
      open={open}
      closeOnClick={closeOnClick}
      closeOnMouseLeave={closeOnMouseLeave}
      contents={
        <div className="flex w-full flex-col py-1.5">
          {items.map((item, index) => (
            <button
              key={index}
              className="hover:bg-serial-palette-50 flex h-[30px] w-full items-center gap-2 px-2 py-1"
              onClick={() => onSelect && onSelect(item.id)}
            >
              {item.tag && <TypeTag type={item.tag} hideText className="mr-2 h-5 w-8 text-[10px]" />}
              {item.icon && (
                <div className="flex w-5 justify-center">
                  <FontAwesomeIcon icon={item.icon} className="text-serial-palette-500" />
                </div>
              )}
              <div className="text-serial-palette-600 truncate text-sm font-medium">{item.label}</div>
            </button>
          ))}
        </div>
      }
    >
      {children}
    </Dropdown>
  );
};

export default DropdownList;
