import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import GridBuilderViews from "./components/GridBuilderViews";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import GridBuilderGrid from "./components/GridBuilderGrid";
import { ReportTemplateType } from "@shared/types/databaseEnums";
import { ReportTemplateGridBuilderView } from "@shared/types/databaseTypes";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { checkIsUuid } from "@shared/utils/helpers";

const defaultBreadcrumbs = [
  {
    label: "Grid Builder",
    link: "/gridbuilder",
  },
];

const GridBuilder = () => {
  const currentUser = useCurrentUser();

  const dbIsLoaded = useSelector((state: RootState) => state.db.isLoaded);
  const gridBuilderViews: ReportTemplateGridBuilderView[] = useSelector((state: RootState) => state.db.reportTemplates).filter((r) => {
    if (!currentUser) return false;
    return (
      r.type === ReportTemplateType.GridBuilderView &&
      (r.created_by === currentUser.supabase_uid || Object.keys(r.shared_with).includes(currentUser.supabase_uid) || r.is_public)
    );
  }) as ReportTemplateGridBuilderView[];
  const location = useLocation();
  const navigate = useNavigate();

  const activeGridBuilderView = useMemo(() => {
    const viewId = location.pathname.split("/")[3];
    const view = gridBuilderViews.find((view) => view.id === viewId);
    if (!view && checkIsUuid(viewId) && dbIsLoaded) {
      navigate("/gridbuilder");
    }
    return view;
  }, [gridBuilderViews, location.pathname]);

  const breadcrumbs: Breadcrumb[] = useMemo(() => {
    if (activeGridBuilderView) {
      return [
        ...defaultBreadcrumbs,
        {
          label: activeGridBuilderView.name,
          link: `/gridbuilder/views/${activeGridBuilderView.id}`,
        },
      ];
    } else if (location.pathname.includes("/gridbuilder/views")) {
      return [
        ...defaultBreadcrumbs,
        {
          label: "Views",
          link: "/gridbuilder/views",
        },
      ];
    } else {
      return defaultBreadcrumbs;
    }
  }, [activeGridBuilderView, location.pathname]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <Header breadcrumbs={breadcrumbs} />
        {!activeGridBuilderView && location.pathname.includes("/gridbuilder/views") ? (
          <GridBuilderViews views={gridBuilderViews} />
        ) : (
          <GridBuilderGrid view={activeGridBuilderView} />
        )}
      </div>
    </div>
  );
};

export default GridBuilder;
