import React, { createContext, useContext } from "react";
import { Component } from "@shared/types/databaseTypes";
import { useNavigate } from "react-router-dom";
import Tooltip from "./Tooltip";
import { cn } from "@shared/utils/tailwind";

interface ComponentAvatarProps {
  component: Component;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  tooltip?: boolean | string;
  hyperlinked?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const ComponentAvatarContext = createContext<{
  size: "xs" | "sm" | "md" | "lg" | "xl";
  component?: Component;
  hyperlinked?: boolean;
}>({
  component: undefined,
  size: "md",
  hyperlinked: undefined,
});

const sizeClasses = {
  xs: { w: "w-6", h: "h-6", fallbackText: "text-xs", nameText: "text-sm" },
  sm: { w: "w-8", h: "h-8", fallbackText: "text-sm", nameText: "text-md" },
  md: { w: "w-16", h: "h-16", fallbackText: "text-base", nameText: "text-lg" },
  lg: { w: "w-24", h: "h-24", fallbackText: "text-2xl", nameText: "text-3xl" },
  xl: { w: "w-48", h: "h-48", fallbackText: "text-4xl", nameText: "text-5xl" },
};

const ComponentAvatarRoot: React.FC<ComponentAvatarProps> = ({ component, size, tooltip, hyperlinked, children, className }) => {
  const navigate = useNavigate();

  const contents = (
    <div
      className={`gap-2" relative flex h-fit items-center ${hyperlinked ? "cursor-pointer" : "cursor-default"}`}
      onClick={() => {
        if (hyperlinked) navigate(`/component/${component?.id}`);
      }}
    >
      {children ? children : <ComponentAvatarImage className={className} />}
    </div>
  );

  return (
    <ComponentAvatarContext.Provider value={{ component, size, hyperlinked }}>
      {tooltip ? (
        <Tooltip.Root>
          <Tooltip.Trigger>{contents}</Tooltip.Trigger>
          <Tooltip.Content>{typeof tooltip === "string" ? tooltip : tooltip ? component?.name : null}</Tooltip.Content>
        </Tooltip.Root>
      ) : (
        contents
      )}
    </ComponentAvatarContext.Provider>
  );
};

const ComponentAvatarName = () => {
  const { component, size } = useContext(ComponentAvatarContext);
  return <div className={`font-medium ${sizeClasses[size].nameText}`}>{component?.name}</div>;
};

const ComponentAvatarImage = ({ className }: { className?: string }) => {
  const { component, size, hyperlinked } = useContext(ComponentAvatarContext);
  const abbreviation = component?.name?.substring(0, 2).toUpperCase() || "";

  return (
    <div
      className={cn(
        `rounded-md ${sizeClasses[size].w} ${sizeClasses[size].h} ${hyperlinked && "ring-serial-palette-200 ring-offset-1 hover:ring-1"} flex items-center justify-center overflow-hidden bg-gray-200`,
        className,
      )}
    >
      {component?.url ? (
        <img className="max-w-64 h-full max-h-64 w-full object-cover" src={component?.url} alt="Avatar" />
      ) : (
        <span className={`text-black ${sizeClasses[size].fallbackText} font-semibold`}>{abbreviation}</span>
      )}
    </div>
  );
};

const ComponentAvatar = Object.assign(ComponentAvatarRoot, {
  Root: ComponentAvatarRoot,
  Name: ComponentAvatarName,
  Image: ComponentAvatarImage,
});

export default ComponentAvatar;
