import React, { useEffect, useMemo } from "react";
import Select from "@shared/components/primitives/Select";
import "react18-json-view/src/style.css";
import { SnLookupContext } from "../../SnLookupProvider";
import DataAnalysisModalDatasetSelector from "./DataAnalysisModalDatasetsSelector";
import { Dataset, Image, File } from "@shared/types/databaseTypes";
import FileViewer from "@shared/components/FileViewer";
import { DataType } from "@shared/types/databaseEnums";
import DataOverTime from "../charts/DataOverTime";
import DataByPopulation from "../charts/DataByPopulation";
import DataAnalysisModalDatasetsRetestTable from "./DataAnalysisModalDatasetsRetestTable";
import moment from "moment";
import useWindowSize from "@shared/hooks/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";

interface FileWithIndex extends File {
  index?: number;
}

interface ImageWithIndex extends Image {
  index?: number;
}

const DataAnalysisModalDatasets: React.FC = () => {
  const { selectedProcess, dataAnalysisModalOpen, allImages, allFiles, genealogy } = React.useContext(SnLookupContext);

  const [selectedDataset, setSelectedDataset] = React.useState<Dataset | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | Image | null>(null);
  const [chartYAxisBounds, setChartYAxisBounds] = React.useState<[number | undefined, number | undefined]>([0, 0]);

  const { height: windowHeight } = useWindowSize();

  // Reset states to null on modal close
  useEffect(() => {
    if (!dataAnalysisModalOpen) {
      setSelectedDataset(null);
      setSelectedFile(null);
    }
  }, [dataAnalysisModalOpen]);

  // Reset chart bounds on selected dataset change
  useEffect(() => {
    setChartYAxisBounds([undefined, undefined]);
  }, [selectedDataset]);

  const processEntryIndexMap = useMemo(() => {
    return genealogy.find((g) => g.id === selectedProcess?.entries[0].unique_identifier_id)?.process_entry_index_map ?? {};
  }, [selectedProcess, genealogy]);

  const filteredImages: ImageWithIndex[] = useMemo(() => {
    if (!selectedDataset) return [];
    const allFilteredImages = allImages.filter(
      (image) => image.dataset_id === selectedDataset.id && image.unique_identifier_id === selectedProcess?.entries[0].unique_identifier_id,
    );
    if (!processEntryIndexMap) return allFilteredImages;
    return allFilteredImages.map((image) => {
      const index = processEntryIndexMap[image.process_entry_id]?.index;
      return { ...image, index };
    });
  }, [selectedDataset, allImages, processEntryIndexMap]);

  const filteredFiles: FileWithIndex[] = useMemo(() => {
    if (!selectedDataset) return [];
    const allFilteredFiles = allFiles.filter(
      (file) => file.dataset_id === selectedDataset.id && file.unique_identifier_id === selectedProcess?.entries[0].unique_identifier_id,
    );
    if (!processEntryIndexMap) return allFilteredFiles;
    return allFilteredFiles.map((file) => {
      const index = processEntryIndexMap[file.process_entry_id]?.index;
      return { ...file, index };
    });
  }, [selectedDataset, allFiles, processEntryIndexMap]);

  const handleFileChange = (fileId: string) => {
    const selected = filteredFiles.find((file) => file?.id === fileId);
    setSelectedFile(selected !== undefined ? selected : null);
  };

  const graphContainerHeight = (windowHeight * 0.8) / 2 - 50;

  return (
    <>
      <div className="h-[50vh] w-full shrink-0 overflow-auto md:h-full md:w-[360px]">
        <DataAnalysisModalDatasetSelector
          selectedDataset={selectedDataset}
          setSelectedDataset={setSelectedDataset}
          setSelectedFile={setSelectedFile}
        />
      </div>
      <div className="bg-serial-palette-50 scrollbar-hide h-full min-w-0 flex-grow flex-col items-center justify-center overflow-auto">
        {selectedDataset?.data_type != DataType.File && selectedDataset?.data_type != DataType.Image && (
          <div className="h-full">
            <DataAnalysisModalDatasetsRetestTable selectedDataset={selectedDataset} />
            {selectedDataset?.data_type === DataType.ParametricQuantitative && (
              <div className="mx-4 flex flex-col gap-4 xl:flex-row" style={{ height: `${graphContainerHeight}px` }}>
                <div className="w-full xl:w-2/3">
                  <DataOverTime
                    chartYAxisBounds={chartYAxisBounds}
                    setChartYAxisBounds={setChartYAxisBounds}
                    selectedDataset={selectedDataset}
                  />
                </div>
                <div className="min-w-0 flex-grow">
                  <DataByPopulation
                    chartYAxisBounds={chartYAxisBounds}
                    setChartYAxisBounds={setChartYAxisBounds}
                    selectedDataset={selectedDataset}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {selectedDataset?.data_type === DataType.File && selectedFile != null && (
          <div className="flex h-full w-full flex-col justify-start overflow-hidden bg-white">
            <div className="bg-serial-palette-50 flex w-full items-center justify-between gap-2 overflow-hidden border-b px-3 py-2">
              <div className="flex min-w-0 flex-grow flex-col overflow-hidden">
                <span className="text-sm font-bold">Entry #{filteredFiles.findIndex((file) => file?.id === selectedFile?.id) + 1}</span>
                <span className="min-w-36 truncate whitespace-nowrap font-mono text-xs">{selectedFile.file_name}</span>
              </div>
              <div className="flex gap-1.5">
                <Select.Root value={selectedFile?.id || ""} onValueChange={(value) => handleFileChange(value)}>
                  <Select.Trigger className="h-8 truncate">
                    <Select.Value />
                  </Select.Trigger>
                  <Select.Content>
                    {filteredFiles
                      .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
                      .map((file, index) => (
                        <Select.Item key={index} value={file?.id} disabled={!file}>
                          Entry #{file.index !== undefined ? file.index + 1 : "Unknown"}
                          {file ? ` (${moment(file.process_entry?.timestamp).format("MM/DD/YY HH:mm:ss")})` : "(Not Submitted)"}
                        </Select.Item>
                      ))}
                  </Select.Content>
                </Select.Root>

                <button
                  className="serial-btn-dark btn btn-sm h-8 gap-1.5 text-sm"
                  onClick={() =>
                    fetchDownloadUrl(
                      selectedFile.bucket_name,
                      selectedFile.file_id ?? selectedFile.id,
                      selectedFile.company_id,
                      false,
                      true,
                      selectedFile.file_name,
                    )
                  }
                >
                  <span>Download</span>
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
            </div>
            <FileViewer className="h-[80vh]" file={selectedFile as File} />
          </div>
        )}
        {selectedDataset?.data_type === DataType.Image && filteredImages.length > 0 && (
          <div className="grid w-full grid-cols-1 gap-4 overflow-auto p-4 md:grid-cols-2 xl:grid-cols-2">
            {filteredImages.map((image, index) => (
              <div key={index} className="bg-serial-palette-100 group relative h-[30vh] overflow-hidden rounded border shadow-sm">
                <div className="absolute inset-0">
                  {image ? (
                    <img src={image.url!} alt={`File: ${image.file_name}`} className="h-full w-full" style={{ objectFit: "cover" }} />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center pb-8 italic">No image submitted on this test</div>
                  )}
                </div>
                <div className="absolute bottom-0 flex w-full flex-col truncate border-t bg-white px-2 py-1 text-sm">
                  <div className="font-bold">{`Entry #${image.index !== undefined ? image.index + 1 : ""}`}</div>
                  <div className="truncate font-mono text-xs">{image ? image.file_name : "-"}</div>
                </div>
                {image && (
                  <button
                    className="serial-btn-dark btn btn-xs absolute right-1.5 top-1.5 hidden gap-1.5 text-xs group-hover:flex"
                    onClick={() =>
                      fetchDownloadUrl(image.bucket_name, image.file_id ?? image.id, image.company_id, false, true, image.file_name)
                    }
                  >
                    <span>Download</span>
                    <FontAwesomeIcon icon={faDownload} />
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DataAnalysisModalDatasets;
