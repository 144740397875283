import { useEffect, useMemo } from "react";
import { MeasureAggregationOperator, MeasureKey, MeasureType, MeasureValueSummary } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";
import { Loader } from "@shared/components/Loader";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import TagBasic from "@shared/components/primitives/TagBasic";
import Checkbox from "@shared/components/primitives/Checkbox";
import useMeasures from "@shared/measures/MeasuresProvider";
import Button from "@shared/components/primitives/Button";
import moment from "moment";
import { cn } from "@shared/utils/tailwind";

const MeasuresGridBodyCellContent = ({
  measureKey,
  summary,
  openPreviewModal,
  size = "sm",
}: {
  measureKey: MeasureKey;
  summary: MeasureValueSummary;
  openPreviewModal?: () => void;
  size?: "sm" | "xs";
}) => {
  const company = useSelector((state: RootState) => state.db.company);

  const { fileUrls, setFileUrls } = useMeasures();

  useEffect(() => {
    const fileId = summary.fileId;
    if (measureKey.type === MeasureType.Image && fileId && !fileUrls[fileId]) {
      fetchDownloadUrl(summary.bucketName ?? "data-images", fileId, company.id, true).then((url) => {
        if (url) {
          setFileUrls((draft) => {
            draft[fileId] = url;
          });
        }
      });
    }
  }, [summary.fileId]);

  const content = useMemo(() => {
    switch (measureKey.type) {
      // Text with Tag
      case MeasureType.LinkStatus:
        return (
          <>
            <span>{summary.value}</span>
            {summary.tag && <TagBasic variant={summary.tag} size="xs" />}
          </>
        );
      case MeasureType.Identifier:
      case MeasureType.ParametricQuantitative:
        return (
          <>
            <span>{summary.formattedValue}</span>
            {summary.tag && <TagBasic variant={summary.tag} size="xs" />}
          </>
        );
      // Tag only
      case MeasureType.Status:
        return (
          <>
            {summary.tag && (
              <TagBasic.Root variant={summary.tag} size="xs" className="w-20">
                <TagBasic.Icon />
                <TagBasic.Name>{summary.tag}</TagBasic.Name>
              </TagBasic.Root>
            )}
          </>
        );
      case MeasureType.ProcessResult:
      case MeasureType.PassFail:
        return (
          <>
            {summary.tag && (
              <TagBasic.Root variant={summary.tag} size="xs" className="w-16">
                <TagBasic.Icon />
                <TagBasic.Name>{summary.tag}</TagBasic.Name>
              </TagBasic.Root>
            )}
          </>
        );
      // Text only
      case MeasureType.WorkOrder:
      case MeasureType.PartNumber:
      case MeasureType.Station:
      case MeasureType.Operator:
      case MeasureType.CycleTime:
      case MeasureType.WorkOrderPhase:
      case MeasureType.Link:
      case MeasureType.ParametricQualitative:
        return <span>{summary.formattedValue}</span>;
      // Special Cases
      case MeasureType.Timestamp:
      case MeasureType.Datetime:
        const aggregationOperator = measureKey.aggregation_operator ?? MeasureAggregationOperator.Latest;
        if ([MeasureAggregationOperator.First, MeasureAggregationOperator.Latest].includes(aggregationOperator)) {
          return (
            <span>
              {!measureKey.formatting?.relative_time && summary.formattedValue}
              {measureKey.formatting?.relative_time && moment(summary.value).fromNow()}
            </span>
          );
        }
        return <span>{summary.formattedValue}</span>;
      case MeasureType.Image:
        return (
          <>
            {summary.fileId && (
              <button
                tabIndex={-1}
                className={cn(
                  "border-serial-palette-200 text-serial-palette-600 hover:text-serial-palette-800 shrink-0 overflow-hidden whitespace-nowrap border bg-white",
                  size === "xs" ? "h-5 w-5 rounded" : "h-7 w-7 rounded-md",
                )}
                onClick={() => openPreviewModal && openPreviewModal()}
              >
                {fileUrls[summary.fileId] ? (
                  <img className="h-full w-full object-cover" src={fileUrls[summary.fileId] ?? ""} />
                ) : (
                  <Loader styleOverride="w-full h-[16px]" />
                )}
              </button>
            )}
            <div className="truncate">{summary.formattedValue}</div>
          </>
        );
      case MeasureType.File:
        return (
          <>
            {summary.fileId && (
              <Button
                tabIndex={-1}
                variant="outline"
                size={size}
                className={size === "xs" ? "h-5 w-5" : "h-7 w-7"}
                onClick={() => openPreviewModal && openPreviewModal()}
              >
                <FontAwesomeIcon size="sm" icon={faEye} />
              </Button>
            )}
            <div className="truncate">{summary.formattedValue}</div>
          </>
        );
      case MeasureType.Checkbox:
        return (
          <>
            <Checkbox disabled checked={Boolean(summary.value)} className="!cursor-default !opacity-100" />
            {summary.tag && <TagBasic variant={summary.tag} size="xs" />}
          </>
        );
      default:
        return <span>{summary.formattedValue}</span>;
    }
  }, [summary, measureKey, company.id, fileUrls]);

  return content;
};

export default MeasuresGridBodyCellContent;
