import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import SettingsSidebar from "./components/SettingsSidebar";
import SettingsProductionApp from "./components/SettingsProductionApp";
import SettingsAccount from "./components/SettingsAccount";
import { useLocation } from "react-router-dom";
import SettingsApi from "./components/SettingsApi";
import SettingsTeam from "./components/SettingsTeam";
import SettingsOther from "./components/SettingsOther";
import SettingsIntegrations from "./components/SettingsIntegrations";

function Settings() {
  const location = useLocation();
  const { pathname } = location;

  const breadcrumbs = [
    {
      label: "Settings",
      link: "/settings/account",
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden ">
      <Sidebar />
      <div className="relative flex flex-1 flex-col">
        <Header breadcrumbs={breadcrumbs} />
        <div className="flex min-h-0 min-w-0 flex-grow overflow-auto p-8">
          <div className="flex h-fit w-full flex-col rounded-md border bg-white md:-mr-px md:flex-row">
            <SettingsSidebar />
            {pathname.includes("/settings/prodapp") && <SettingsProductionApp />}
            {pathname.includes("/settings/account") && <SettingsAccount />}
            {pathname.includes("/settings/api") && <SettingsApi />}
            {pathname.includes("/settings/team") && <SettingsTeam />}
            {pathname.includes("/settings/integrations") && <SettingsIntegrations />}
            {pathname.includes("/settings/other") && <SettingsOther />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
