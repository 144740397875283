import React, { useEffect, useState } from "react";
import { LabelFormatterLabelElementProps, QrCodeElement } from "../../types";
import QRCode from "qrcode";
import { PX_PER_MM } from "../../constants";

const LabelElementQRCode: React.FC<LabelFormatterLabelElementProps<QrCodeElement>> = ({ element, identifier }) => {
  const [qrCodeImg, setQrCodeImg] = useState<string | null>(null);

  useEffect(() => {
    if (!identifier) {
      setQrCodeImg(null);
      return;
    }
    QRCode.toDataURL(
      identifier,
      {
        width: element.width * PX_PER_MM,
        margin: 1,
      },
      (err: any, url: string) => {
        if (err) console.error(err);
        setQrCodeImg(url ?? "");
      },
    );
  }, [element, identifier]);

  return <div className="pointer-events-none select-none">{qrCodeImg && <img src={qrCodeImg} />}</div>;
};

export default LabelElementQRCode;
