import React from "react";
import { useReactFlow, getRectOfNodes, getTransformForBounds } from "reactflow";
import { toPng } from "html-to-image";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

function downloadImage(dataUrl, filename = "process-sequence.png") {
  const a = document.createElement("a");
  a.setAttribute("download", filename);
  a.setAttribute("href", dataUrl);
  a.click();
}

const IMAGE_WIDTH = 4096;
const IMAGE_HEIGHT = 2160;

function ComponentFlowchartDownload({ component }) {
  const { getNodes } = useReactFlow();
  const handleGenerateAndDownload = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(nodesBounds, IMAGE_WIDTH, IMAGE_HEIGHT, 0.5, 2);
    const filename = `${component?.name} - Process Sequence - ${moment().format("MM/DD/YY HH:mma")}`;

    toPng(document.querySelector(".react-flow__viewport"), {
      backgroundColor: "#FFFFFF", // white
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
      style: {
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then((dataUrl) => downloadImage(dataUrl, filename));
  };

  return (
    <button className=" btn bg-serial-palette-800 hover:bg-serial-palette-600 h-8 text-white" onClick={() => handleGenerateAndDownload()}>
      <FontAwesomeIcon icon={faDownload} />
      <span className="ml-2 hidden whitespace-nowrap md:block">Flowchart</span>
    </button>
  );
}

export default ComponentFlowchartDownload;
