import React, { useMemo } from "react";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import UserAvatar from "@shared/components/UserAvatar";
import { WorkOrder } from "@shared/types/databaseTypes";
import { useTranslation } from "react-i18next";

interface WorkOrderSummaryTableRowProps {
  workOrder: WorkOrder;
}

const WorkOrderSummaryTableRow: React.FC<WorkOrderSummaryTableRowProps> = ({ workOrder }) => {
  const components = useSelector((state: RootState) => state.db.components);
  const users = useSelector((state: RootState) => state.db.users);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const component = useMemo(() => {
    return components.find((component) => component.id === workOrder.component_id);
  }, [components, workOrder.component_id]);

  return (
    <tr className="hover:bg-serial-palette-50 cursor-pointer" onClick={() => navigate("/workorder/" + workOrder.id)}>
      <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
        <div className="text-serial-palette-500 text-center font-medium">
          {t("workOrderPrefix")}-{workOrder.wo_number}
        </div>
      </td>
      <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
        <div className="text-left">{workOrder.name}</div>
      </td>
      <td className="whitespace-nowrap px-2 py-0.5 first:pl-5 last:pr-5">
        <div className="flex items-center justify-end gap-x-2">
          <ComponentAvatar component={component} size="sm" />
          <p>{component?.name}</p>
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-2 py-0.5 first:pl-5 last:pr-5 sm:table-cell">
        <div className="flex items-center justify-center">
          <UserAvatar user={users.find((u) => u.supabase_uid === workOrder.assignee_user_id)} size="sm" enableToolTip />{" "}
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
        <div className="text-center">{workOrder.quantity}</div>
      </td>
    </tr>
  );
};

export default WorkOrderSummaryTableRow;
