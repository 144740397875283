import { useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "chart.js";
import { tailwindConfig } from "@shared/utils/helpers";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { TimestamptzString, UniqueIdentifier } from "@shared/types/databaseTypes";
import { Loader } from "@shared/components/Loader";
import { fetchLargeTable } from "@shared/connections/supabaseGeneral";
import moment from "moment";

interface ChartingCanvasElement extends HTMLCanvasElement {
  chart?: Chart;
}

const CHART_DEBOUNCE_DELAY = 500;

interface YieldDatapoint {
  date: Date;
  defective: number;
  complete: number;
  total?: number;
  yield?: number;
}

interface HomeYieldProps {
  days: number;
  lastUpdatedEpochTimestamp: Number;
}

const HomeYield: React.FC<HomeYieldProps> = ({ days, lastUpdatedEpochTimestamp }) => {
  const canvas = useRef<ChartingCanvasElement>(null);
  const [_chart, setChart] = useState<Chart | null>(null);
  const [completedComponentInstances, setCompletedComponentInstances] = useState<Partial<UniqueIdentifier>[]>([]);
  const [defectiveComponentInstances, setDefectiveComponentInstances] = useState<Partial<UniqueIdentifier>[]>([]);
  const [completedInstancesLoading, setCompletedInstancesLoading] = useState<boolean>(false);
  const [defectiveInstancesLoading, setDefectiveInstancesLoading] = useState<boolean>(false);
  const [yeildByDay, setYeildByDay] = useState<YieldDatapoint[]>([]);

  useEffect(() => {
    setCompletedInstancesLoading(true);
    setDefectiveInstancesLoading(true);
    let daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - days);
    // Fetch all component instances completed in the last 30 days
    fetchLargeTable<UniqueIdentifier>("unique_identifiers", "id", "id, status, completed_at", [
      { modifier: "eq", key: "status", value: UniqueIdentifierStatus.Complete },
      { modifier: "gt", key: "completed_at", value: daysAgo.toISOString() },
    ]).then((componentInstances) => {
      setCompletedComponentInstances(componentInstances);
      setCompletedInstancesLoading(false);
    });
    // Fetch all defective component instances updated in the last 30 days
    fetchLargeTable<UniqueIdentifier>("unique_identifiers", "id", "id, status, last_updated_at", [
      { modifier: "eq", key: "status", value: UniqueIdentifierStatus.Defective },
      { modifier: "gt", key: "last_updated_at", value: daysAgo.toISOString() },
    ]).then((componentInstances) => {
      setDefectiveComponentInstances(componentInstances);
      setDefectiveInstancesLoading(false);
    });
  }, [days, lastUpdatedEpochTimestamp]);

  useEffect(() => {
    if (completedInstancesLoading || defectiveInstancesLoading) return;
    // Initialize newYieldByDay with default values for each day in the timeframe
    let newYeildByDay: YieldDatapoint[] = [];
    for (let i = 0; i < days; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      date.setHours(0, 0, 0, 0);
      newYeildByDay.push({
        date: date,
        defective: 0,
        complete: 0,
      });
    }
    // tally completed instances
    for (const completedInstance of completedComponentInstances) {
      let date = new Date(completedInstance.completed_at as string); // completed component instances will always have a completed_at timestamp
      date.setHours(0, 0, 0, 0);
      let datapoint = newYeildByDay.find((datapoint) => datapoint.date.getTime() === date.getTime());
      if (datapoint) {
        datapoint.complete += 1;
      } else {
        newYeildByDay.push({
          date: date,
          defective: 0,
          complete: 1,
        });
      }
    }
    // tally defective instances
    for (const defectiveInstance of defectiveComponentInstances) {
      let date = new Date(defectiveInstance.last_updated_at as string); // component instances will always have a last_updated_at timestamp
      date.setHours(0, 0, 0, 0);
      let datapoint = newYeildByDay.find((datapoint) => datapoint.date.getTime() === date.getTime());
      if (datapoint) {
        datapoint.defective += 1;
      } else {
        newYeildByDay.push({
          date: date,
          defective: 1,
          complete: 0,
        });
      }
    }
    // calculate total and yield
    for (const datapoint of newYeildByDay) {
      datapoint.total = datapoint.complete + datapoint.defective;
      datapoint.yield = datapoint.complete / datapoint.total;
    }
    // sort by date and return
    setYeildByDay(newYeildByDay.sort((a, b) => a.date.getTime() - b.date.getTime()));
  }, [completedInstancesLoading, defectiveInstancesLoading]);

  const chartData = useMemo(() => {
    // create variables for chart data
    let x: TimestamptzString[] = [];
    let complete: (number | null)[] = [];
    let defective: (number | null)[] = [];
    // iterate through data and push values to chart data variables
    yeildByDay.forEach((datapoint) => {
      x.push(datapoint.date.toISOString());
      complete.push(datapoint.complete);
      defective.push(datapoint.defective);
    });
    // return
    return { x, complete, defective };
  }, [yeildByDay]);

  useEffect(() => {
    let chart: Chart | null = null;
    try {
      let debounceTimer: NodeJS.Timeout;
      debounceTimer = setTimeout(() => {
        if (canvas.current === null) return;
        chart = new Chart(canvas.current, {
          type: "line",
          data: {
            labels: chartData.x,
            datasets: [
              {
                label: "Defective",
                data: chartData.defective,
                borderColor: (tailwindConfig().theme?.colors?.red as any)?.[500],
                backgroundColor: (tailwindConfig().theme?.colors?.red as any)?.[100],
                pointRadius: 0,
                borderWidth: 1,
                fill: "origin",
              },
              {
                label: "Complete",
                data: chartData.complete,
                borderColor: (tailwindConfig().theme?.colors?.green as any)?.[500],
                backgroundColor: (tailwindConfig().theme?.colors?.green as any)?.[100],
                pointRadius: 0,
                borderWidth: 1,
                fill: "origin",
              },
            ],
          },
          options: {
            layout: {
              padding: 0,
            },
            scales: {
              y: {
                display: true,
                beginAtZero: false,
                ticks: {
                  maxTicksLimit: 10,
                  precision: 0,
                },
                min: 0,
                type: "linear",
              },
              x: {
                display: true,
                grid: {
                  tickWidth: 1,
                  lineWidth: 0,
                },
                ticks: {
                  maxTicksLimit: 5,
                },
                type: "time",
                time: {
                  parser: "YYYY-MM-DD HH:mm:ss",
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: (context) => moment(context[0].parsed.x).format("MM/DD/YY"),
                  label: (context) => {
                    const label = context.dataset.label;
                    const value = context.parsed.y;

                    if (label === "Complete") {
                      return `Completed: ${value}`;
                    } else if (label === "Defective") {
                      return `Defective: ${value}`;
                    } else {
                      return ""; // Ensure a string is always returned, even if it's empty
                    }
                  },
                  afterBody: (context) => {
                    // Extract date from the current hover context
                    const hoveredDate = new Date(context[0].parsed.x);
                    hoveredDate.setHours(0, 0, 0, 0);
                    // Find the corresponding data point from yeildByDay array using the hoveredDate
                    const correspondingData = yeildByDay.find((datapoint) => datapoint.date.getTime() === hoveredDate.getTime());
                    if (correspondingData) {
                      const yieldVal = (correspondingData.yield! * 100).toFixed(2); // Using "!" as yield is optional in the type definition
                      return `Yield: ${yieldVal}%`;
                    } else {
                      return "";
                    }
                  },
                },
              },
              legend: {
                display: false,
                position: "bottom",
                labels: {
                  boxHeight: 15,
                  boxWidth: 15,
                },
              },
            },
            interaction: {
              intersect: false,
              mode: "nearest",
            },
            maintainAspectRatio: false,
            resizeDelay: 200,
          },
        });
        setChart(chart);
      }, CHART_DEBOUNCE_DELAY);
      return () => {
        clearTimeout(debounceTimer);
        if (chart) {
          chart.destroy();
        }
      };
    } catch (error) {
      console.error("Chart initialization failed:", error);
    }
  }, [chartData]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-2 border-b p-3">
        <div className="flex min-w-0 flex-grow gap-2">
          <h2 className="text-serial-palette-800 truncate font-semibold">Yield</h2>
          {(completedInstancesLoading || defectiveInstancesLoading) && <Loader styleOverride="h-6 w-6" />}
        </div>
      </div>
      <div className="scrollbar-hide flex min-h-0 min-w-0 flex-grow flex-col gap-y-1.5 overflow-auto p-3">
        <canvas ref={canvas}></canvas>
      </div>
      <div className="flex w-full justify-center gap-x-2 border-t py-1.5">
        {[
          { color: "bg-green-200", label: "Complete" },
          { color: "bg-red-200", label: "Defective" },
        ].map((category, index) => {
          return (
            <div key={index} className="flex items-center gap-x-1">
              <div className={`h-3 w-3 ${category.color}`} />
              <div className="text-xs font-light">{category.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeYield;
