import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import { useState } from "react";
import Modal from "@shared/components/primitives/Modal";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCube, faPalette, faSearch } from "@fortawesome/free-solid-svg-icons";
import Command from "@shared/components/primitives/Command";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

const FrontendPrimitivesCommand = () => {
  const [open, setOpen] = useState<boolean>(false);

  const components = useSelector((state: RootState) => state.db.components);

  return (
    <FrontendPrimitivesCard title="Command">
      <Modal.Root open={open} onOpenChange={setOpen}>
        <Modal.Trigger asChild>
          <Button className="w-36">
            <FontAwesomeIcon className="text-gray-9" icon={faSearch} />
            Search
          </Button>
        </Modal.Trigger>
        <Modal.Content className="w-full max-w-[95%] overflow-hidden p-0 md:max-w-2xl">
          <Command.Root>
            <Command.Input placeholder="Ask me anything..." />
            <Command.List>
              <Command.Empty>No results found.</Command.Empty>
              <Command.Group heading="Actions">
                <Command.Item>
                  <FontAwesomeIcon className="text-serial-palette-600" icon={faCog} />
                  <span>Settings</span>
                  <Command.Shortcut>⇧⌘P</Command.Shortcut>
                </Command.Item>
                <Command.Item>
                  <FontAwesomeIcon className="text-serial-palette-600" icon={faPalette} />
                  <span>Theme</span>
                  <Command.Shortcut>⇧⌘D</Command.Shortcut>
                </Command.Item>
              </Command.Group>
              <Command.Separator />
              <Command.Group heading="Articles">
                {components.map((component) => (
                  <Command.Item key={component.id} value={component.name}>
                    <FontAwesomeIcon className="text-serial-palette-600" icon={faCube} />
                    <span>{component.name}</span>
                  </Command.Item>
                ))}
              </Command.Group>
            </Command.List>
          </Command.Root>
        </Modal.Content>
      </Modal.Root>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesCommand;
