import React from "react";
import { useTracking } from "../TrackingProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ComponentType } from "@shared/types/databaseEnums";
import Button from "@shared/components/primitives/Button";
import TrackingSidebarContents from "./TrackingSidebarContents";

const TrackingSidebar: React.FC = () => {
  const { sidebarOpen, setSidebarOpen, selectedComponent } = useTracking();

  return (
    <>
      <div className={`shrink-0 transition-all duration-200 ${sidebarOpen ? "w-[400px]" : "w-[0px]"}`} />
      <div
        className={`group/sidebar absolute bottom-0 right-0 flex h-full w-[400px] transition-all duration-200 ${sidebarOpen ? "translate-x-0" : "translate-x-full"}`}
      >
        <div className="bg-serial-palette-50 relative flex min-h-0 min-w-0 flex-grow flex-col border-l shadow-lg">
          <Button
            variant="ghost"
            symmetric
            className="absolute right-2 top-2 z-10 hidden group-hover/sidebar:flex"
            onClick={() => setSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} size="xl" className="text-serial-palette-500" />
          </Button>
          <TrackingSidebarContents />
          {!selectedComponent && (
            <div className="flex h-full w-full items-center justify-center">
              <span className="text-serial-palette-400 italic">Select a component to view details</span>
            </div>
          )}
          {!sidebarOpen && (
            <button
              className="btn serial-btn-light absolute -left-[94px] top-1/2 flex h-8 w-40 -rotate-90 gap-x-2 rounded-b-none"
              onClick={() => setSidebarOpen(true)}
            >
              <span>{selectedComponent?.component_type === ComponentType.Lot ? "Lot Codes" : "Serial Numbers"}</span>
              <FontAwesomeIcon icon={faList} size="xs" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default TrackingSidebar;
