import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";
import { DataType } from "@shared/types/databaseEnums";
import { ErrorBanner } from "@shared/components/error/types";
import { Dataset, PartNumber } from "@shared/types/databaseTypes";

// ------------------- VALIDATION FUNCTIONS ------------------- //

export const validatePartNumberFileUpload = (file: File, dataType: DataType): ErrorBanner => {
  // --- CHECK #1 --- : If it uses the upload method, check if the file type is an image
  if (dataType === DataType.Image && !["jpeg", "jpg", "png", "tiff", "tif", "heic"].includes(file.type.split("/")[1]?.toLowerCase())) {
    return { isValid: false, type: "error", message: "Incorrect file format. Must be .jpeg, .png, .heic, .tif or .tiff" };
  }

  // --- CHECK #2 --- : Check if the file size is too large
  if (file.size >= 52428800) {
    // 50MB
    return {
      isValid: false,
      type: "error",
      message:
        "File size too large. Serial supports files up to 50MB. Please convert your file to a smaller size and try again. Contact Serial support if this file is mission critical.",
    };
  }

  return { isValid: true, type: "success", message: "File is valid" };
};

// Function to check if the pin has already been taken by another user in the company. Ensures uniquenes
const checkPnIsAvailable = async (partNumber: PartNumber) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { data: currPartNumbers, error: currPartNumbersErrror } = await supabase.from("part_numbers").select("*").eq("pn", partNumber.pn);
    // Return invalid if there was an error
    if (currPartNumbersErrror || !currPartNumbers) {
      console.log(currPartNumbersErrror);
      return false;
    }
    // Return invalid if there are more than one items with that pn
    if (currPartNumbers.length > 1) {
      return false;
    }
    // Return invalid if there is one item with that pn that is not itself
    else if (currPartNumbers.length === 1 && partNumber.id !== currPartNumbers[0].id) {
      return false;
    }
    // Return valid otherwise
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Function to handle validation of part numbers. Returns and object with {isValid: bool, message: string}. If isValid is true but there's a non null message then the message is a warning
export const validatePartNumber = async (partNumber: PartNumber): Promise<ErrorBanner> => {
  // --- CHECK 1 --- : Part number is not empty
  if (partNumber.pn === "" || partNumber.pn == null || partNumber.pn == undefined) {
    return { isValid: false, type: "error", message: "Part number cannot be blank" };
  }

  // --- CHECK 2 --- : Part number already exists
  const pnIsValid = await checkPnIsAvailable(partNumber);
  if (!pnIsValid) {
    return { isValid: false, type: "error", message: `${partNumber.pn} has already been assigned to another component` };
  }

  return { isValid: true, type: "success", message: "" };
};

export const validateGridDatasets = (datasets: Dataset[]): ErrorBanner => {
  // --- CHECK 1 --- : Check if there are any duplicate datasets
  const datasetIds = datasets.map((dataset) => dataset.id);
  const uniqueDatasetIds = [...new Set(datasetIds)];
  if (datasetIds.length !== uniqueDatasetIds.length) {
    return { isValid: false, type: "error", message: "Duplicate datasets found" };
  }

  // --- CHECK 2 --- : Check if there are any sets with empty names or types or ids
  const emptyDatasets = datasets.filter(
    (dataset) =>
      dataset.name === "" ||
      dataset.name == null ||
      dataset.name == undefined ||
      (dataset.data_type as string) === "" ||
      dataset.data_type == null ||
      dataset.data_type == undefined ||
      dataset.id === "" ||
      dataset.id == null ||
      dataset.id == undefined,
  );
  if (emptyDatasets.length > 0) {
    return { isValid: false, type: "error", message: "All columns must have names" };
  }

  return { isValid: true, type: "success", message: "" };
};
