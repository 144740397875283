import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ProcessBuilderProps } from "./ProcessBuilder";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import {
  Dataset,
  Field,
  FieldType,
  FilterCondition,
  FilterJoinOperator,
  HeadingBlock,
  ProcessStep,
  ProcessStepWithReferences,
  ProcessWithReferences,
  WorkInstructionBlock,
  WorkInstructionBlockContent,
  WorkInstructionBlockType,
} from "@shared/types/databaseTypes";
import { Component, ComponentProcessLink, PartNumber, LogFileSchema, LogFileSchemaDatasetLink } from "@shared/types/databaseTypes";
import { generateBlankField, generateBlankProcessStep, generateBlankWorkInstructionBlock, generateTemplateProcess } from "./helpers";
import { DataType, UserRole } from "@shared/types/databaseEnums";
import { validateDataset, validateField, validateProcess } from "./connections/validation";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { getProcessWithReferences } from "@shared/connections/supabaseProcess";
import { ProcessStepWithNullableArrays, ProcessWithOptionalCreatedAt } from "./types";
import { save } from "./connections/save";
import { ToastContext } from "@shared/context/ToastProvider";
import {
  deleteProcess,
  uploadWorkInstructionFile,
  fetchLogFileSchemasByDatasetIds,
  fetchLogFileSchemaDatasetLinksBySchemaIds,
} from "./connections/supabase";
import { allowedFileExtensionsByBlockType } from "./constants";
import { ToastType } from "@shared/components/Toast";
import { v4 as uuidv4 } from "uuid";
import { generateVerboseProcessRevisionDescription } from "./connections/revisionDescription";
import { LabelFormatWithContents } from "@shared/labels/types";
import { getLabels } from "@shared/labels/connections/supabase";

const CHANGE_LOG_PERIOD_MS = 500; // ms

interface ProcessBuilderBackup {
  [processId: string]: ProcessWithReferences;
}

interface ProcessBuilderContextProps {
  // state
  hasChanged: boolean;
  readOnly: boolean;
  isLoading?: boolean;
  isNewProcess?: boolean;
  processDatasets: Dataset[];
  componentDatasets: Dataset[];
  parseableDatasets?: Dataset[];
  component: Component | null;
  componentProcessLink: ComponentProcessLink | null;
  datasetsValidation?: { [datasetId: string]: { isValid: boolean; message?: string } };
  fieldsValidation?: { [fieldId: string]: { isValid: boolean; message?: string } };
  // state with setters
  draftProcess?: ProcessWithReferences;
  setDraftProcess: (value: ProcessWithReferences | undefined) => void;
  processBuilderOpen: boolean;
  setProcessBuilderOpen: (value: boolean) => void;
  revisionHistoryOpen: boolean;
  setRevisionHistoryOpen: (value: boolean) => void;
  processSettingsOpen: boolean;
  setProcessSettingsOpen: (value: boolean) => void;
  importSidebarOpen: boolean;
  setImportSidebarOpen: (value: boolean) => void;
  enablePreviewMode: boolean;
  setEnablePreviewMode: (value: boolean) => void;
  enableExportMode: boolean;
  setEnableExportMode: (value: boolean) => void;
  schemaDesignerOpen: boolean;
  setSchemaDesignerOpen: (value: boolean) => void;
  selectedSchemaId: string | null;
  setSelectedSchemaId: (value: string | null) => void;
  selectedSourceDatasetId: string | null;
  setSelectedSourceDatasetId: (value: string | null) => void;
  existingSchemas: LogFileSchema[];
  setExistingSchemas: (value: LogFileSchema[]) => void;
  draftSchemas: LogFileSchema[];
  setDraftSchemas: (value: LogFileSchema[]) => void;
  existingSchemaDatasetLinks: LogFileSchemaDatasetLink[];
  setExistingSchemaDatasetLinks: (value: LogFileSchemaDatasetLink[]) => void;
  draftSchemaDatasetLinks: LogFileSchemaDatasetLink[];
  setDraftSchemaDatasetLinks: (value: LogFileSchemaDatasetLink[]) => void;
  editingSchema: boolean;
  setEditingSchema: (value: boolean) => void;
  selectedStepIndex: number;
  setSelectedStepIndex: (value: number) => void;
  errorBanner: { message: string | null; type: string };
  setErrorBanner: (value: { message: string | null; type: string }) => void;
  hoveredWorkInstructionBlockId: string | null;
  setHoveredWorkInstructionBlockId: (value: string | null) => void;
  hoveredFieldId: string | null;
  setHoveredFieldId: (value: string | null) => void;
  hoveredFieldGroupIndex: number | null;
  setHoveredFieldGroupIndex: (value: number | null) => void;
  hoveredStepIndex: number | null;
  setHoveredStepIndex: (value: number | null) => void;
  previewPartNumber: PartNumber | null;
  setPreviewPartNumber: (value: PartNumber | null) => void;
  pulseWorkInstructions: boolean;
  setPulseWorkInstructions: (value: boolean) => void;
  labels: LabelFormatWithContents[];
  setLabels: (value: LabelFormatWithContents[]) => void;
  // event handlers
  handleClose: () => void;
  handleSave: () => void;
  handleDelete: () => void;
  handleUpdateDraftProcess: (key: string, value: any) => void;
  handleMoveWorkInstructionBlock: (draggedBlockId: string, hoveredStepIndex: number, hoveredBlockIndex: number) => void;
  handleAddNewStep: (stepName?: string, noPlaceholders?: boolean) => void;
  handleMoveStep: (draggedStepIndex: number, hoveredStepIndex: number) => void;
  handleUpdateStep: (stepIndex: number, key: keyof ProcessStep, value: any) => void;
  handleDeleteStep: (stepIndex: number) => void;
  handleMoveFields: (draggedFieldIds: string[], hoveredStepIndex: number, hoveredFieldIndex: number, newGroupName?: string) => void;
  handleUpdateFields: (stepIndex: number, fieldIds: string[], key: keyof Field, value: any) => void;
  handleUpdateFieldDataset: (stepIndex: number, fieldId: string, key: keyof Dataset, value: any) => void;
  handleUpdateFilter: (stepIndex: number, filterConditions: FilterCondition[], joinOperator?: FilterJoinOperator) => void;
  handleUpdateWorkInstructionBlocks: (
    stepIndex: number,
    workInstructionBlockIds: string[],
    key: string,
    value: any,
    isContents: boolean,
  ) => void;
  handleUploadFile: (
    file: File,
    type: WorkInstructionBlockType.File | WorkInstructionBlockType.Video | WorkInstructionBlockType.Image | WorkInstructionBlockType.PDF,
  ) => Promise<string | void>;
  handleAddNewField: (
    stepIndex: number,
    fieldIndex: number,
    type: FieldType,
    groupName?: string,
    prompt?: string,
    isOptional?: boolean,
    datasetType?: DataType,
  ) => void;
  handleAddNewWorkInstructionBlock: (
    stepIndex: number,
    workInstructionBlockIndex: number,
    type: WorkInstructionBlockType,
    focus?: boolean,
    content?: WorkInstructionBlockContent,
  ) => string | void;
  handleDeleteField: (stepIndex: number, fieldIndex: number) => void;
  handleDeleteWorkInstructionBlock: (stepIndex: number, workInstructionBlockIndex: number) => void;
  handleSetHoveredWorkInstructionBlock: (stepIndex: number, workInstructionBlockIndex: number) => void;
}

// Create a default context value for ProcessBuilderContext to avoid having to initialize the context with undefined
const defaultContext: ProcessBuilderContextProps = {
  // state
  hasChanged: false,
  readOnly: false,
  isLoading: false,
  isNewProcess: false,
  parseableDatasets: [],
  componentDatasets: [],
  processDatasets: [],
  component: null,
  componentProcessLink: null,
  datasetsValidation: {},
  fieldsValidation: {},
  // state with setters
  draftProcess: undefined,
  setDraftProcess: () => {},
  processBuilderOpen: false,
  setProcessBuilderOpen: () => {},
  revisionHistoryOpen: false,
  setRevisionHistoryOpen: () => {},
  processSettingsOpen: false,
  setProcessSettingsOpen: () => {},
  importSidebarOpen: false,
  setImportSidebarOpen: () => {},
  enablePreviewMode: false,
  setEnablePreviewMode: () => {},
  enableExportMode: false,
  setEnableExportMode: () => {},
  schemaDesignerOpen: false,
  setSchemaDesignerOpen: () => {},
  selectedSchemaId: null,
  setSelectedSchemaId: () => {},
  selectedSourceDatasetId: null,
  setSelectedSourceDatasetId: () => {},
  existingSchemas: [],
  setExistingSchemas: () => {},
  draftSchemas: [],
  setDraftSchemas: () => {},
  existingSchemaDatasetLinks: [],
  setExistingSchemaDatasetLinks: () => {},
  draftSchemaDatasetLinks: [],
  setDraftSchemaDatasetLinks: () => {},
  editingSchema: false,
  setEditingSchema: () => {},
  selectedStepIndex: 0,
  setSelectedStepIndex: () => {},
  errorBanner: { message: null, type: "success" },
  setErrorBanner: () => {},
  hoveredWorkInstructionBlockId: null,
  setHoveredWorkInstructionBlockId: () => {},
  hoveredFieldId: null,
  setHoveredFieldId: () => {},
  hoveredFieldGroupIndex: null,
  setHoveredFieldGroupIndex: () => {},
  hoveredStepIndex: null,
  setHoveredStepIndex: () => {},
  previewPartNumber: null,
  setPreviewPartNumber: () => {},
  pulseWorkInstructions: false,
  setPulseWorkInstructions: () => {},
  labels: [],
  setLabels: () => {},
  // event handlers
  handleClose: () => {},
  handleSave: () => {},
  handleDelete: () => {},
  handleUpdateDraftProcess: () => {},
  handleAddNewStep: () => {},
  handleMoveStep: () => {},
  handleUpdateStep: () => {},
  handleDeleteStep: () => {},
  handleMoveWorkInstructionBlock: () => {},
  handleMoveFields: () => {},
  handleUpdateFields: () => {},
  handleUpdateFieldDataset: () => {},
  handleUpdateFilter: () => {},
  handleUpdateWorkInstructionBlocks: () => {},
  handleUploadFile: async () => {},
  handleAddNewField: () => {},
  handleAddNewWorkInstructionBlock: () => {},
  handleDeleteField: () => {},
  handleDeleteWorkInstructionBlock: () => {},
  handleSetHoveredWorkInstructionBlock: () => {},
};

const removeCreatedAt = (process: ProcessWithReferences): ProcessWithOptionalCreatedAt => {
  // Remove created_at timestamps from work_instruction_blocks, process_steps, filter_conditions and fields
  let processWithoutTimestamps = { ...process } as ProcessWithOptionalCreatedAt;
  delete processWithoutTimestamps.created_at;
  processWithoutTimestamps.process_steps.forEach((step) => {
    step.work_instruction_blocks.forEach((block) => {
      delete block.created_at;
    });
    step.fields.forEach((field) => {
      delete field.created_at;
    });
    step.filter_conditions.forEach((condition) => {
      delete condition.created_at;
    });
    delete step.created_at;
  });
  return processWithoutTimestamps;
};

const cleanupSteps = (steps: ProcessStepWithNullableArrays[]): ProcessStepWithReferences[] => {
  // 1) remove null values from the work instructions and fields arrays
  let cleanedSteps = steps.map((step) => {
    return {
      ...step,
      work_instruction_blocks: step.work_instruction_blocks.filter((block) => block !== null),
      fields: step.fields.filter((field) => field !== null),
      filter_conditions: step.filter_conditions.filter((condition) => condition !== null),
    } as ProcessStepWithReferences;
  });
  // 2) if a step has no work instructions, and no fields, remove it
  cleanedSteps = cleanedSteps.filter((step) => step.work_instruction_blocks.length > 0 || step.fields.length > 0);
  // 3) set the name of step to the first heading block's text if a heading exists
  cleanedSteps = cleanedSteps.map((step) => {
    const headingBlock = step.work_instruction_blocks.find((block) => block?.type === WorkInstructionBlockType.Heading);
    if (headingBlock) {
      step.name = (headingBlock as HeadingBlock).content.text;
    }
    return step;
  });
  // 4) set the order property of each block, field and step
  cleanedSteps = cleanedSteps.map((step, stepIndex) => {
    step.work_instruction_blocks = step.work_instruction_blocks.map((block, blockIndex) => {
      if (block) {
        block.order = blockIndex;
      }
      return block;
    });
    step.fields = step.fields.map((field, fieldIndex) => {
      if (field) {
        field.order = fieldIndex;
      }
      return field;
    });
    step.order = stepIndex;
    return step;
  });
  // return the cleaned steps
  return cleanedSteps;
};

const saveBackup = (draft: ProcessWithReferences) => {
  const processBuilderBackupString = localStorage.getItem("processBuilderBackup");
  let processBuilderBackup = processBuilderBackupString ? JSON.parse(processBuilderBackupString) : ({} as ProcessBuilderBackup);
  processBuilderBackup[draft.id] = draft;
  localStorage.setItem("processBuilderBackup", JSON.stringify(processBuilderBackup));
};

const dropBackup = (processId: string) => {
  const processBuilderBackupString = localStorage.getItem("processBuilderBackup");
  let processBuilderBackup = processBuilderBackupString ? JSON.parse(processBuilderBackupString) : ({} as ProcessBuilderBackup);
  delete processBuilderBackup[processId];
  localStorage.setItem("processBuilderBackup", JSON.stringify(processBuilderBackup));
};

function updateField<T extends keyof Field>(field: Field, key: T, value: Field[T]) {
  field[key] = value;
}

function updateFieldDataset<T extends keyof Dataset>(field: Field, key: T, value: Dataset[T]) {
  if (!field.dataset) {
    return;
  }
  field.dataset[key] = value;
}

function updateWorkInstructionBlock<T extends keyof WorkInstructionBlock>(
  workInstructionBlock: WorkInstructionBlock,
  key: T,
  value: WorkInstructionBlock[T],
) {
  // delete the property if the value is undefined
  if (value === undefined) {
    delete workInstructionBlock[key];
  } else {
    workInstructionBlock[key] = value;
  }
}

function updateWorkInstructionBlockContents<T extends keyof WorkInstructionBlockContent>(
  workInstructionBlock: WorkInstructionBlock & { content: WorkInstructionBlockContent },
  key: T,
  value: WorkInstructionBlockContent[T],
) {
  if (!workInstructionBlock.content) {
    workInstructionBlock.content = {};
  }
  // delete the property if the value is undefined
  if (value === undefined) {
    if (workInstructionBlock.content.hasOwnProperty(key)) {
      delete workInstructionBlock.content[key];
    }
  } else {
    workInstructionBlock.content[key] = value as (typeof workInstructionBlock.content)[T];
  }
}

export const ProcessBuilderContext = createContext<ProcessBuilderContextProps>(defaultContext);

const ProcessBuilderProvider: React.FunctionComponent<React.PropsWithChildren<ProcessBuilderProps>> = ({
  processBuilderOpen,
  setProcessBuilderOpen,
  processId,
  componentId,
  children,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [revisionHistoryOpen, setRevisionHistoryOpen] = useState<boolean>(false);
  const [processSettingsOpen, setProcessSettingsOpen] = useState<boolean>(false);
  const [importSidebarOpen, setImportSidebarOpen] = useState<boolean>(false);
  const [enablePreviewMode, setEnablePreviewMode] = useState<boolean>(false);
  const [enableExportMode, setEnableExportMode] = useState<boolean>(false);
  const [schemaDesignerOpen, setSchemaDesignerOpen] = useState<boolean>(false);
  const [selectedSchemaId, setSelectedSchemaId] = useState<string | null>(null);
  const [selectedSourceDatasetId, setSelectedSourceDatasetId] = useState<string | null>(null);
  const [existingSchemas, setExistingSchemas] = useState<LogFileSchema[]>([]);
  const [draftSchemas, setDraftSchemas] = useImmer<LogFileSchema[]>([]);
  const [editingSchema, setEditingSchema] = useState<boolean>(false);
  const [existingSchemaDatasetLinks, setExistingSchemaDatasetLinks] = useState<LogFileSchemaDatasetLink[]>([]);
  const [draftSchemaDatasetLinks, setDraftSchemaDatasetLinks] = useImmer<LogFileSchemaDatasetLink[]>([]);
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0);
  const [errorBanner, setErrorBanner] = useState<{ message: string | null; type: string }>({ message: null, type: "success" });
  const [draftProcess, setDraftProcess] = useState<ProcessWithReferences | undefined>(undefined);
  const [hoveredWorkInstructionBlockId, setHoveredWorkInstructionBlockId] = useState<string | null>(null);
  const [hoveredFieldId, setHoveredFieldId] = useState<string | null>(null);
  const [hoveredFieldGroupIndex, setHoveredFieldGroupIndex] = useState<number | null>(null);
  const [hoveredStepIndex, setHoveredStepIndex] = useState<number | null>(null);
  const [previewPartNumber, setPreviewPartNumber] = useState<PartNumber | null>(null);
  const [datasetsValidation, setDatasetsValidation] = useState<{ [datasetId: string]: { isValid: boolean; message?: string } }>({});
  const [fieldsValidation, setFieldsValidation] = useState<{ [fieldId: string]: { isValid: boolean; message?: string } }>({});
  const [pulseWorkInstructions, setPulseWorkInstructions] = useState<boolean>(false);
  const [labels, setLabels] = useState<LabelFormatWithContents[]>([]);

  const components = useSelector((state: RootState) => state.db.components);
  const component = useSelector((state: RootState) => state.db.components.find((component) => component.id === componentId)) ?? null;
  const componentProcessLinksByComponent = useSelector((state: RootState) =>
    state.db.componentProcessLinks.filter((link) => link.component_id === componentId),
  );
  const componentProcessLink =
    useSelector((state: RootState) =>
      state.db.componentProcessLinks.find(
        (componentProcessLink) => componentProcessLink.component_id === componentId && componentProcessLink.process_id === processId,
      ),
    ) ?? null;
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const company = useSelector((state: RootState) => state.db.company);
  const companyId = useSelector((state: RootState) => state.auth.company_id);

  const currUser = useCurrentUser();
  const readOnly = useSelector((state: RootState) => state.auth.role) !== UserRole.Admin;
  const { triggerConfirmation, triggerToast } = useContext(ToastContext);

  // put the change log stuff in refs to avoid closure hell
  const changeLogLastUpdatedAt = useRef<number>(Date.now());
  const changeLog = useRef<ProcessWithReferences[]>([]);
  const changeLogPointer = useRef<number>(0);

  const parseableDatasets = useMemo(() => {
    return datasets.filter(
      (dataset) => dataset?.data_type === DataType.File && draftProcess?.id === dataset.process_id && dataset.is_active,
    ) as Dataset[];
  }, [draftProcess, datasets]);

  const componentDatasets = useMemo(() => {
    const relatedDatasets = datasets.filter((dataset) =>
      componentProcessLinksByComponent.find(
        (link) => link.process_id === dataset.process_id && dataset.is_active && dataset.process_revision === link.process_revision,
      ),
    );
    // Filter out datasets that are not parametric
    return relatedDatasets.filter(
      (dataset) => dataset.data_type === DataType.ParametricQualitative || dataset.data_type === DataType.ParametricQuantitative,
    );
  }, [datasets, componentId]);

  const processDatasets = useMemo(() => {
    const allDatasets = datasets.filter((dataset) => dataset.process_id === draftProcess?.id && dataset.data_type !== DataType.Uid);
    // Filter out the datasets that are auto generated by schemas
    return allDatasets.filter((dataset) => !existingSchemaDatasetLinks.find((link) => link.dataset_id === dataset.id));
  }, [draftProcess, datasets, existingSchemaDatasetLinks]);

  useEffect(() => {
    // Fetch the existing schemas for the parseable datasets
    const fetchSchemas = async () => {
      if (parseableDatasets.length > 0) {
        const schemas = (await fetchLogFileSchemasByDatasetIds(parseableDatasets.map((dataset) => dataset.id))).data ?? [];
        const latestSchemas = schemas.reduce((acc: LogFileSchema[], schema) => {
          const existingSchema = acc.find((accSchema) => accSchema.source_dataset_id === schema.source_dataset_id);
          if (!existingSchema || existingSchema.revision < schema.revision) {
            return [...acc.filter((accSchema) => accSchema.source_dataset_id !== schema.source_dataset_id), schema];
          }
          return acc;
        }, []);
        setExistingSchemas(latestSchemas);

        const links = (await fetchLogFileSchemaDatasetLinksBySchemaIds(schemas.map((schema) => schema.id))).data ?? [];
        // Make sure we only get the links from the maximum revision of the schema
        const maxRevisionBySchemaId = schemas.reduce((acc: { [schemaId: string]: number }, schema) => {
          if (!acc[schema.id] || acc[schema.id] < schema.revision) {
            acc[schema.id] = schema.revision;
          }
          return acc;
        }, {});
        setExistingSchemaDatasetLinks(links.filter((link) => link.schema_revision === maxRevisionBySchemaId[link.schema_id]));
      }
    };
    fetchSchemas();
  }, [parseableDatasets]);

  const isNewProcess = useMemo(() => {
    return processId === null;
  }, [processBuilderOpen]);

  const hasChanged = useMemo(() => {
    if (!draftProcess) return false;
    return (
      draftSchemas.length > 0 || draftSchemaDatasetLinks.length > 0 || JSON.stringify(draftProcess) !== JSON.stringify(changeLog.current[0])
    );
  }, [draftProcess, draftSchemas, draftSchemaDatasetLinks]);

  // ------------------ Undo / Redo and Backups ------------------ //

  const updateChangeLogAndSaveBackup = (updatedDraftProcess: ProcessWithReferences) => {
    // save a backup
    saveBackup(updatedDraftProcess);
    // some modifications to the draft process will occur in very quick succession (e.g. when adding a mention 6 different operations will be performed)
    // only add a change to the change log if the last change was made more than 0.5 seconds ago
    const now = Date.now();
    if (now - changeLogLastUpdatedAt.current < CHANGE_LOG_PERIOD_MS) return;
    changeLogLastUpdatedAt.current = now;
    // concatenate a deep copy of all the changes up to and including the pointer with the updated draft process
    const updatedChangeLog = [
      ...JSON.parse(JSON.stringify(changeLog.current.slice(0, changeLogPointer.current + 1))),
      JSON.parse(JSON.stringify(updatedDraftProcess)),
    ];
    changeLog.current = updatedChangeLog;
    changeLogPointer.current = updatedChangeLog.length - 1;
  };

  const handleRedo = useCallback(() => {
    const nextPointerPosition = Math.min(changeLogPointer.current + 1, changeLog.current.length - 1);
    changeLogPointer.current = nextPointerPosition;
    setDraftProcess(JSON.parse(JSON.stringify(changeLog.current[nextPointerPosition])));
  }, [changeLog, changeLogPointer]);

  const handleUndo = useCallback(() => {
    const nextPointerPosition = Math.max(changeLogPointer.current - 1, 0);
    changeLogPointer.current = nextPointerPosition;
    setDraftProcess(JSON.parse(JSON.stringify(changeLog.current[nextPointerPosition])));
  }, [changeLog, changeLogPointer]);

  // Bind command+z and command+shift+z to undo and redo while the process builder is open
  useEffect(() => {
    const handleUndoRedo = (e: KeyboardEvent) => {
      if (e.key === "z" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        if (e.shiftKey) {
          handleRedo();
        } else {
          handleUndo();
        }
      }
    };
    if (processBuilderOpen) {
      window.addEventListener("keydown", handleUndoRedo);
    } else {
      window.removeEventListener("keydown", handleUndoRedo);
    }
    return () => {
      window.removeEventListener("keydown", handleUndoRedo);
    };
  }, [processBuilderOpen, handleRedo, handleUndo]);

  // ------------------ Do stuff when process builder opens ------------------ //

  const loadProcessDataWithReferences = async (processIdToLoad: string) => {
    setIsLoading(true);
    const { data, error } = await getProcessWithReferences(processIdToLoad, undefined, true);
    if (error || !data) {
      console.error(`Failed to retrieve process with id ${processIdToLoad}: ${error}`);
      setErrorBanner({ message: `Failed to retrieve process: ${error}`, type: "error" });
      setIsLoading(false);
      return;
    }
    setDraftProcess(data);
    changeLog.current = [JSON.parse(JSON.stringify(data))]; // deep copy the data to avoid referencing the state directly
    changeLogPointer.current = 0;
    setIsLoading(false);
  };

  // This effect will run every time the process builder is opened
  useEffect(() => {
    if (!companyId) console.error("Company ID is not defined in process Builder");
    if (!processBuilderOpen || !companyId) return;
    setProcessSettingsOpen(false);
    setRevisionHistoryOpen(false);
    setEnableExportMode(false);
    if (!processId) {
      const template = generateTemplateProcess(companyId, currUser?.supabase_uid);
      setDraftProcess(template);
      changeLog.current = [JSON.parse(JSON.stringify(template))]; // deep copy the data to avoid referencing the state directly
      changeLogPointer.current = 0;
    } else {
      const processBuilderBackupString = localStorage.getItem("processBuilderBackup");
      const processBuilderBackup = processBuilderBackupString ? (JSON.parse(processBuilderBackupString) as ProcessBuilderBackup) : {};
      if (processBuilderBackup[processId] && !readOnly) {
        triggerConfirmation(
          "Draft Submission Found",
          <p>
            You have an un-saved draft{processBuilderBackup[processId] ? ` of "${processBuilderBackup[processId].name}"` : "."}
            <br />
            Would you like to reload it?
          </p>,
          () => {
            setDraftProcess(processBuilderBackup[processId]);
            changeLog.current = [JSON.parse(JSON.stringify(processBuilderBackup[processId]))]; // deep copy the data to avoid referencing the state directly
            changeLogPointer.current = 0;
          },
          () => {
            loadProcessDataWithReferences(processId);
            dropBackup(processId);
          },
          "Reload",
          "Discard",
        );
      } else {
        loadProcessDataWithReferences(processId);
      }
    }
  }, [processId, processBuilderOpen]);

  useEffect(() => {
    if (component) {
      getLabels(component.id).then(setLabels);
    }
  }, [component]);

  // ------------------ Event Handlers ------------------ //

  const handleClose = () => {
    setProcessBuilderOpen(false);
    setErrorBanner({ message: null, type: "success" });
    setDraftProcess(undefined);
    setImportSidebarOpen(false);
    setSchemaDesignerOpen(false);
    setSelectedSchemaId(null);
    setEditingSchema(false);
    setSelectedSourceDatasetId(null);
    setExistingSchemas([]);
    setDraftSchemas([]);
    setPreviewPartNumber(null);
    setEnablePreviewMode(false);
    setExistingSchemaDatasetLinks([]);
    setDraftSchemaDatasetLinks([]);
    changeLog.current = [];
    changeLogPointer.current = 0;
  };

  const handleValidateProcessDraft = (): {
    isValid: boolean;
    validatedDatasets?: Dataset[];
    validatedProcess?: ProcessWithReferences;
  } => {
    if (!draftProcess) {
      console.error("Draft process is not defined");
      setErrorBanner({ message: `Failed to validate process: Draft process is not defined`, type: "error" });
      return { isValid: false };
    }
    // Validate datasets
    const validatedDatasets =
      draftProcess?.process_steps.flatMap((step) => {
        return step.fields.map((field) => field?.dataset).filter((dataset): dataset is Dataset => dataset !== undefined) as Dataset[];
      }) ?? [];
    const newDatasetValidation: { [datasetId: string]: { isValid: boolean; message?: string } } = {};
    validatedDatasets.forEach((validatedDataset) => {
      newDatasetValidation[validatedDataset.id] = validateDataset(validatedDataset, datasets);
    });
    setDatasetsValidation(newDatasetValidation);
    if (Object.values(newDatasetValidation).some((validation) => !validation.isValid)) {
      const errorMessages = Object.values(newDatasetValidation)
        .filter((validation) => !validation.isValid)
        .map((validation) => validation.message);
      setErrorBanner({ message: `Some data collection fields are incomplete or invalid: ${errorMessages.join(", ")}`, type: "error" });
      return { isValid: false };
    }
    // Validate process
    const validatedProcess: ProcessWithReferences = { ...draftProcess };
    const allFields = validatedProcess.process_steps.flatMap((step) => step.fields);
    const newFieldValidation: { [fieldId: string]: { isValid: boolean; message?: string } } = {};
    allFields.forEach((field) => {
      newFieldValidation[field.id] = validateField(field);
    });
    setFieldsValidation(newFieldValidation);
    if (Object.values(newFieldValidation).some((validation) => !validation.isValid)) {
      const errorMessages = Object.values(newFieldValidation)
        .filter((validation) => !validation.isValid)
        .map((validation) => validation.message);
      setErrorBanner({ message: `Some data collection fields are incomplete or invalid: ${errorMessages.join(", ")}`, type: "error" });
      return { isValid: false };
    }
    const processValidation = validateProcess(validatedProcess, components);
    if (!processValidation.isValid) {
      setErrorBanner({ message: `Process validation failed: ${processValidation.message}`, type: "error" });
      return { isValid: false };
    }
    return { isValid: true, validatedDatasets: validatedDatasets, validatedProcess: validatedProcess };
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (!draftProcess || !companyId || !componentId || !currUser) {
      console.error("Missing required information to save process: ", draftProcess, companyId, componentId, currUser);
      setErrorBanner({ message: "Failed to save process: Missing required information", type: "error" });
      setIsLoading(false);
      return;
    }
    if (currUser.role !== UserRole.Admin) {
      setErrorBanner({ message: "Only admin users have permission to update processes", type: "error" });
      setIsLoading(false);
      return;
    }
    const { isValid, validatedDatasets, validatedProcess } = handleValidateProcessDraft();
    if (!isValid || !validatedDatasets || !validatedProcess) {
      setIsLoading(false);
      return;
    }
    // Update the created by user id
    validatedProcess.created_by_user_id = currUser.supabase_uid;
    validatedProcess.revision_description =
      validatedProcess.revision === 0 ? "Created new process" : await generateVerboseProcessRevisionDescription(validatedProcess);
    validatedProcess.ai_revision_description = "";
    const { error } = await save(
      validatedDatasets,
      removeCreatedAt(validatedProcess),
      componentId,
      company.config.allow_ai ?? false,
      draftSchemas,
      draftSchemaDatasetLinks,
    );
    if (error) {
      setErrorBanner({ message: `Failed to save process: ${error}`, type: "error" });
      setIsLoading(false);
      return;
    }
    // remove draft from backup
    dropBackup(validatedProcess.id);
    // close the modal
    handleClose();
    setIsLoading(false);
  };

  const handleDelete = async () => {
    if (!draftProcess) return;
    triggerConfirmation(
      "Are you sure you want to delete?",
      "This action cannot be undone.",
      () => {
        deleteProcess(draftProcess.id).then((response) => {
          if (response.error) {
            console.error(`Failed to delete process with id ${draftProcess.id}: ${response.error}`);
            setErrorBanner({ message: "Failed to delete process, please try again or contact Serial support", type: "error" });
            return;
          }
          handleClose();
        });
      },
      undefined,
      "Delete",
    );
  };

  const handleUpdateDraftProcess = (key: string, value: any) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      const updatedDraft = {
        ...currDraftProcess,
        [key]: value,
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleMoveWorkInstructionBlock = useCallback(
    (draggedBlockId: string, hoveredStepIndex: number, hoveredBlockIndex: number) => {
      if (!companyId) return;
      setDraftProcess((currDraftProcess) => {
        if (!currDraftProcess) return currDraftProcess;
        let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
        // retrieve the dragged block
        const draggedBlock = currDraftProcess.process_steps
          .find((step) => {
            return step.work_instruction_blocks.find((block) => block.id === draggedBlockId);
          })
          ?.work_instruction_blocks.find((block) => block.id === draggedBlockId);
        if (!draggedBlock) {
          console.error(`Could not find dragged block with id ${draggedBlockId}`);
          return currDraftProcess;
        }
        // remove the dragged block from their original position by overwriting the the originals with null
        // this will preserve the indexes of the remaining blocks so that we can insert the dragged block at the correct position
        // null values will be filtered out by the cleanupSteps function
        updatedSteps = updatedSteps.map((step) => {
          return {
            ...step,
            work_instruction_blocks: step.work_instruction_blocks.map((block) => (block?.id === draggedBlockId ? null : block)) ?? [],
          };
        });
        // move the dragged block to its new position note that the step index and / or block index may not exist
        if (!updatedSteps[hoveredStepIndex]) {
          const newProcessStep = generateBlankProcessStep(companyId, currDraftProcess.id, currDraftProcess.revision, updatedSteps.length);
          updatedSteps.push({
            ...newProcessStep,
            work_instruction_blocks: [draggedBlock],
          });
        } else {
          updatedSteps[hoveredStepIndex].work_instruction_blocks.splice(hoveredBlockIndex + 1, 0, draggedBlock);
        }
        // update the draft process
        const updatedDraft = {
          ...currDraftProcess,
          process_steps: cleanupSteps(updatedSteps),
        };
        updateChangeLogAndSaveBackup(updatedDraft);
        return updatedDraft;
      });
    },
    [companyId],
  );

  const handleMoveFields = useCallback(
    (draggedFieldIds: string[], hoveredStepIndex: number, hoveredFieldIndex: number, newGroupName?: string) => {
      if (!companyId) return;
      setDraftProcess((currDraftProcess) => {
        if (!currDraftProcess) return currDraftProcess;
        let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
        // retrieve the dragged fields
        const draggedFields = currDraftProcess.process_steps.flatMap((step) => {
          return step.fields.filter((field) => draggedFieldIds.includes(field.id));
        });
        // update the group name of the dragged fields if a new group name was provided
        if (newGroupName) {
          draggedFields.forEach((field) => {
            field.group_name = newGroupName;
          });
        }
        if (!draggedFields || draggedFields.length === 0) {
          console.error(`Could not find dragged fields with ids ${draggedFieldIds}`);
          return currDraftProcess;
        }
        // remove the dragged fields from their original position by overwriting the the originals with null
        // this will preserve the indexes of the remaining fields so that we can insert the dragged fields at the correct position
        // null values will be filtered out by the cleanupSteps function
        updatedSteps = updatedSteps.map((step) => {
          return {
            ...step,
            fields: step?.fields.map((field) => (field?.id && draggedFieldIds.includes(field.id) ? null : field)) ?? [],
          };
        });
        // move the dragged fields to their new position note that the step index and / or field index may not exist
        const newProcessStep = generateBlankProcessStep(companyId, currDraftProcess.id, currDraftProcess.revision, updatedSteps.length);
        if (!updatedSteps[hoveredStepIndex]) {
          updatedSteps.push({
            ...newProcessStep,
            fields: draggedFields,
          });
        } else {
          updatedSteps[hoveredStepIndex].fields.splice(hoveredFieldIndex + 1, 0, ...draggedFields);
        }
        // update the draft process
        const updatedDraft = {
          ...currDraftProcess,
          process_steps: cleanupSteps(updatedSteps),
        };
        updateChangeLogAndSaveBackup(updatedDraft);
        return updatedDraft;
      });
    },
    [companyId],
  );

  const handleUpdateFields = (stepIndex: number, fieldIds: string[], key: keyof Field, value: any) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
      // update the fields
      updatedSteps[stepIndex].fields = updatedSteps[stepIndex].fields.map((field) => {
        if (field && fieldIds.includes(field.id)) {
          // if we're updating a single field's prompt, update the dataset name as well
          // only do this when the field prompt and dataset name are in sync (if you have already changed the dataset name, changing the field prompt will not update the dataset name)
          if (key === "prompt" && fieldIds.length === 1 && field.dataset?.name === field.prompt) {
            updateFieldDataset(field, "name", value);
          }
          // if we're updating a single field's data validation, update the dataset type as well
          // note that this should not be possible after a dataset has been created (enforce by disabling the button + a check by the API)
          // it's somewhat hack to have the data_validation also trigger changes to the dataset but going with this until we make a better way to manage datasets in the process builder
          if (key === "data_validation" && fieldIds.length === 1) {
            const datasetType = value === "STRING" ? DataType.ParametricQualitative : DataType.ParametricQuantitative;
            updateFieldDataset(field, "data_type", datasetType);
          }
          // update the field itself
          updateField(field, key, value);
        }
        return field;
      });
      // update the draft process
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleUpdateFieldDataset = (stepIndex: number, fieldId: string, key: keyof Dataset, value: any) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
      // update the fields
      updatedSteps[stepIndex].fields = updatedSteps[stepIndex].fields.map((field) => {
        if (field && fieldId === field.id && field.dataset) {
          field.dataset = {
            ...field.dataset,
            [key]: value,
          };
        }
        return field;
      });
      // update the draft process
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleUpdateWorkInstructionBlocks = (
    stepIndex: number,
    workInstructionBlockIds: string[],
    key: string,
    value: any,
    isContents: boolean,
  ) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
      let workInstructionBlockDidUpdate = false;
      // update the work instruction blocks
      updatedSteps[stepIndex].work_instruction_blocks = updatedSteps[stepIndex].work_instruction_blocks.map((block) => {
        if (block && workInstructionBlockIds.includes(block.id)) {
          if (isContents) {
            updateWorkInstructionBlockContents(block, key as keyof WorkInstructionBlockContent, value);
          } else {
            updateWorkInstructionBlock(block, key as keyof WorkInstructionBlock, value);
          }
          workInstructionBlockDidUpdate = true;
        }
        return block;
      });
      if (!workInstructionBlockDidUpdate) {
        console.warn(
          `Work instruction block ID(s) ${workInstructionBlockIds.map((id) => `"${id}"`).join(", ")} not found in step ${stepIndex}: `,
          JSON.parse(JSON.stringify(updatedSteps)),
        );
      }
      // update the draft process
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleUploadFile = async (
    file: File,
    type: WorkInstructionBlockType.File | WorkInstructionBlockType.Video | WorkInstructionBlockType.Image | WorkInstructionBlockType.PDF,
  ): Promise<string | void> => {
    if (!companyId) return;
    const allowedExtensions = allowedFileExtensionsByBlockType[type];
    // basic validation
    if (file.size >= 52428800) {
      // 50MB
      triggerToast(ToastType.Error, "File Too Large", "Files must be less than 50MB.");
      return;
    }
    if (!allowedExtensions.includes("*") && !allowedExtensions.includes(file.type.split("/")?.[1]?.toLowerCase())) {
      triggerToast(
        ToastType.Error,
        "Unsupported File Type",
        `Serial supports the following file types for work instructions: ${allowedExtensions.join(", ")}. Please convert your file to one of these types and try again.`,
      );
      return;
    }
    // create a local url for preview an set it to the state
    const fileId = uuidv4();
    const { error } = await uploadWorkInstructionFile(fileId, file, companyId);
    if (error) {
      triggerToast(ToastType.Error, "Upload Error", "There was an error uploading the file.");
      console.error("File upload failed");
      return;
    }
    return fileId;
  };

  const handleUpdateFilter = (
    stepIndex: number,
    updatedFilterConditions: FilterCondition[],
    updatedFilterJoinOperator?: FilterJoinOperator,
  ) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
      updatedSteps[stepIndex].filter_conditions = updatedFilterConditions;
      if (updatedFilterJoinOperator) {
        updatedSteps[stepIndex].filter_join_operator = updatedFilterJoinOperator;
      }
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleAddNewStep = useCallback(
    (stepName?: string, noPlaceholders?: boolean) => {
      if (!companyId) return;
      setDraftProcess((currDraftProcess) => {
        if (!currDraftProcess) return currDraftProcess;
        let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
        const newProcessStep = generateBlankProcessStep(
          companyId,
          currDraftProcess.id,
          currDraftProcess.revision,
          updatedSteps.length,
          stepName,
        );
        const blankBlock = generateBlankWorkInstructionBlock(WorkInstructionBlockType.Heading, companyId, newProcessStep.id, 0);
        updatedSteps.push({
          ...newProcessStep,
          work_instruction_blocks: noPlaceholders ? [] : [blankBlock],
        });
        const updatedDraft = {
          ...currDraftProcess,
          process_steps: cleanupSteps(updatedSteps),
        };
        updateChangeLogAndSaveBackup(updatedDraft);
        return updatedDraft;
      });
    },
    [companyId],
  );

  const handleMoveStep = (draggedStepIndex: number, hoveredStepIndex: number) => {
    if (!companyId) return;
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      const updatedSteps: (ProcessStep | null)[] = [...currDraftProcess.process_steps];
      const draggedStep = JSON.parse(JSON.stringify(updatedSteps[draggedStepIndex]));
      // remove the dragged step from its original position by overwriting the the originals with null
      // this will preserve the indexes of the remaining steps so that we can insert the dragged step at the correct position
      // null values will be filtered out before saving
      updatedSteps[draggedStepIndex] = null;
      // move the dragged step to its new position note that the step index may not exist
      updatedSteps.splice(hoveredStepIndex + 1, 0, draggedStep);
      // cleanup the steps
      const filteredSteps = updatedSteps.filter((step) => step !== null) as ProcessStepWithReferences[];
      // update the draft process
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(filteredSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleUpdateStep = (stepIndex: number, key: keyof ProcessStep, value: any) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      const updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        [key]: value,
      };
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleDeleteStep = (stepIndex: number) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      const updatedSteps = [...currDraftProcess.process_steps];
      // delete the step
      delete updatedSteps[stepIndex];
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleAddNewField = useCallback(
    (
      stepIndex: number,
      fieldIndex: number,
      fieldType: FieldType,
      groupName?: string,
      prompt?: string,
      isOptional?: boolean,
      datasetType?: DataType,
    ) => {
      if (!companyId) return;
      setDraftProcess((currDraftProcess) => {
        if (!currDraftProcess) return currDraftProcess;
        let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
        if (!updatedSteps[stepIndex]) {
          const newProcessStep = generateBlankProcessStep(companyId, currDraftProcess.id, currDraftProcess.revision, updatedSteps.length);
          updatedSteps.push({
            ...newProcessStep,
            work_instruction_blocks: [],
            fields: [
              generateBlankField(
                fieldType,
                companyId,
                newProcessStep.id,
                currDraftProcess.id,
                currDraftProcess.revision,
                groupName,
                prompt,
                isOptional,
                datasetType,
              ),
            ],
          });
        } else {
          updatedSteps[stepIndex].fields.splice(
            fieldIndex + 1,
            0,
            generateBlankField(
              fieldType,
              companyId,
              updatedSteps[stepIndex].id,
              currDraftProcess.id,
              currDraftProcess.revision,
              groupName,
              prompt,
              isOptional,
              datasetType,
            ),
          );
        }
        const updatedDraft = {
          ...currDraftProcess,
          process_steps: cleanupSteps(updatedSteps),
        };
        updateChangeLogAndSaveBackup(updatedDraft);
        return updatedDraft;
      });
    },
    [companyId],
  );

  const handleAddNewWorkInstructionBlock = (
    stepIndex: number,
    workInstructionBlockIndex: number,
    workInstructionBlockType: WorkInstructionBlockType,
    focus?: boolean,
    content?: WorkInstructionBlockContent,
  ): string | void => {
    // Instantiate the block id here so that we can return it to the caller
    const newBlockId = uuidv4();
    if (!companyId) return;
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      let updatedSteps: ProcessStepWithNullableArrays[] = JSON.parse(JSON.stringify(currDraftProcess.process_steps));
      if (!updatedSteps[stepIndex]) {
        const newProcessStep = generateBlankProcessStep(companyId, currDraftProcess.id, currDraftProcess.revision, updatedSteps.length);
        const blankBlock = generateBlankWorkInstructionBlock(workInstructionBlockType, companyId, newProcessStep.id, 0, content);
        blankBlock.id = newBlockId;
        updatedSteps.push({
          ...newProcessStep,
          work_instruction_blocks: [blankBlock],
        });
      } else {
        const blankBlock = generateBlankWorkInstructionBlock(
          workInstructionBlockType,
          companyId,
          updatedSteps[stepIndex].id,
          workInstructionBlockIndex + 1,
          content,
        );
        blankBlock.id = newBlockId;
        // note: this will invalidate the order properties of the subsequent blocks
        // this is okay since the order properties are not used on the frontend, the order is determined by the index of the block in the array
        updatedSteps[stepIndex].work_instruction_blocks.splice(workInstructionBlockIndex + 1, 0, blankBlock);
      }
      // hack way to set focus on the new block
      // use set timeout to push action to end of event loop
      if (focus) {
        setTimeout(() => {
          document.getElementById(newBlockId)?.click();
        }, 0);
      }
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
    return newBlockId;
  };

  const handleDeleteField = (stepIndex: number, fieldIndex: number) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
      updatedSteps[stepIndex].fields.splice(fieldIndex, 1);
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleDeleteWorkInstructionBlock = (stepIndex: number, workInstructionBlockIndex: number) => {
    setDraftProcess((currDraftProcess) => {
      if (!currDraftProcess) return currDraftProcess;
      let updatedSteps: ProcessStepWithNullableArrays[] = [...currDraftProcess.process_steps];
      updatedSteps[stepIndex].work_instruction_blocks.splice(workInstructionBlockIndex, 1);
      // hack way to set focus on the previous block
      // use set timeout to push action to end of event loop
      const prevBlock = updatedSteps[stepIndex].work_instruction_blocks[workInstructionBlockIndex - 1];
      if (prevBlock) {
        setTimeout(() => {
          document.getElementById(prevBlock.id)?.click();
        }, 0);
      }
      const updatedDraft = {
        ...currDraftProcess,
        process_steps: cleanupSteps(updatedSteps),
      };
      updateChangeLogAndSaveBackup(updatedDraft);
      return updatedDraft;
    });
  };

  const handleSetHoveredWorkInstructionBlock = useCallback(
    (stepIndex: number, workInstructionBlockIndex: number) => {
      if (draftProcess) {
        const block = draftProcess.process_steps[stepIndex]?.work_instruction_blocks[workInstructionBlockIndex];
        if (block) {
          setHoveredWorkInstructionBlockId(block.id);
        } else if (workInstructionBlockIndex === draftProcess.process_steps[stepIndex]?.work_instruction_blocks.length) {
          setHoveredWorkInstructionBlockId(`STEP_${stepIndex}_PLACEHOLDER`);
        } else {
          setHoveredWorkInstructionBlockId(null);
        }
      }
    },
    [draftProcess],
  );

  return (
    <ProcessBuilderContext.Provider
      value={{
        // state
        hasChanged,
        readOnly,
        isLoading,
        isNewProcess,
        parseableDatasets,
        componentDatasets,
        processDatasets,
        component,
        componentProcessLink,
        datasetsValidation,
        fieldsValidation,
        // state with setters
        draftProcess,
        setDraftProcess,
        processBuilderOpen,
        setProcessBuilderOpen,
        revisionHistoryOpen,
        setRevisionHistoryOpen,
        processSettingsOpen,
        setProcessSettingsOpen,
        importSidebarOpen,
        setImportSidebarOpen,
        enablePreviewMode,
        setEnablePreviewMode,
        enableExportMode,
        setEnableExportMode,
        schemaDesignerOpen,
        setSchemaDesignerOpen,
        selectedSchemaId,
        setSelectedSchemaId,
        selectedSourceDatasetId,
        setSelectedSourceDatasetId,
        existingSchemas,
        setExistingSchemas,
        draftSchemas,
        setDraftSchemas,
        existingSchemaDatasetLinks,
        setExistingSchemaDatasetLinks,
        draftSchemaDatasetLinks,
        setDraftSchemaDatasetLinks,
        editingSchema,
        setEditingSchema,
        selectedStepIndex,
        setSelectedStepIndex,
        errorBanner,
        setErrorBanner,
        hoveredWorkInstructionBlockId,
        setHoveredWorkInstructionBlockId,
        hoveredFieldId,
        setHoveredFieldId,
        hoveredFieldGroupIndex,
        setHoveredFieldGroupIndex,
        hoveredStepIndex,
        setHoveredStepIndex,
        previewPartNumber,
        setPreviewPartNumber,
        pulseWorkInstructions,
        setPulseWorkInstructions,
        labels,
        setLabels,
        // event handlers
        handleClose,
        handleSave,
        handleDelete,
        handleUpdateDraftProcess,
        handleMoveWorkInstructionBlock,
        handleMoveFields,
        handleUpdateFields,
        handleUpdateFieldDataset,
        handleUpdateFilter,
        handleUpdateWorkInstructionBlocks,
        handleUploadFile,
        handleAddNewStep,
        handleMoveStep,
        handleUpdateStep,
        handleDeleteStep,
        handleAddNewField,
        handleAddNewWorkInstructionBlock,
        handleDeleteField,
        handleDeleteWorkInstructionBlock,
        handleSetHoveredWorkInstructionBlock,
      }}
    >
      {children}
    </ProcessBuilderContext.Provider>
  );
};

export default ProcessBuilderProvider;
