import { useEffect, useState } from "react";
import ProcessBuilderApiSampleCode from "./ProcessBuilderApiSampleCode";
import ProcessBuilderApiChat from "./ProcessBuilderApiChat";
import ProcessBuilderApiInfo from "./ProcessBuilderApiInfo";

const ProcessBuilderApi: React.FC = () => {
  const [sourceCode, setSourceCode] = useState<string>("");
  const [showChat, setShowChat] = useState<boolean>(true);

  // There's a strange CSS bug where defaulting showChat to false causes the chat push the entire modal contents to the left
  // To solve this issue we default showChat to true but make it invisible to start
  // Then we slide the chat out of the screen and make it visible after a short delay (incredibly hacky)
  // Would be great to eventually remove the useState and useEffect logic here
  const [chatInvisible, setChatInvisible] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setShowChat(false);
    }, 100);
    setTimeout(() => {
      setChatInvisible(false);
    }, 400);
  }, []);

  // end of hacky code

  // load the sample code from serial-py
  useEffect(() => {
    fetch("https://raw.githubusercontent.com/serialmfg/serial-py/main/examples/quickstart_barebones.py")
      .then((response) => response.text())
      .then((text) => setSourceCode(text));
  }, []);

  return (
    <div className="relative flex h-full w-full">
      <ProcessBuilderApiSampleCode sourceCode={sourceCode} setSourceCode={setSourceCode} showChat={showChat} setShowChat={setShowChat} />
      <ProcessBuilderApiInfo />
      <div
        className={`bg-serial-palette-100 absolute right-0 top-0  h-full w-2/5 transition-all duration-300 ${chatInvisible && "opacity-0"} ${showChat ? "" : "translate-x-full"}`}
      >
        <ProcessBuilderApiChat sourceCode={sourceCode} setSourceCode={setSourceCode} />
      </div>
    </div>
  );
};

export default ProcessBuilderApi;
