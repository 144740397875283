import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@shared/components/Tooltip";
import {
  faCaretLeft,
  faCaretRight,
  faTrashRestoreAlt,
  faTrashAlt,
  faPlus,
  faTimes,
  faLocationArrow,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { fetchNextAndPrevUniqueIdentifiers, setUniqueIdentifierArchiveFlag } from "@shared/connections/supabaseSnLookup";
import TypeTag from "@shared/components/TypeTag";
import { Dataset, ParametricQuantitative, ParametricQualitative, UniqueIdentifier } from "@shared/types/databaseTypes";
import { RootState } from "@shared/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserRole } from "@shared/types/databaseEnums";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import {
  createNewProperty,
  getComponentInstanceProperties,
  getComponentProperties,
  deactivateProperty,
  updatePropertyValue,
  updatePropertyName,
  updateLocation,
} from "../connections/api";
import { ToastType } from "@shared/components/Toast";
import { ToastContext } from "@shared/context/ToastProvider";
import { SnLookupContext } from "../SnLookupProvider";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import Popover from "@shared/components/primitives/Popover";
import MapLocationSelector from "@shared/components/MapLocationSelector";
import Button from "@shared/components/primitives/Button";
import Loader from "@shared/components/primitives/Loader";
import { useTranslation } from "react-i18next";

type ParametricData = ParametricQuantitative | ParametricQualitative;

const SnLookUpUnitDetails: React.FC = () => {
  const { uniqueIdentifier, setUniqueIdentifier, processTableIsLoading } = useContext(SnLookupContext);
  const { t } = useTranslation();

  // General state
  const components = useSelector((state: RootState) => state.db.components);
  const role = useSelector((state: RootState) => state.auth.role);
  const workOrders = useSelector((state: RootState) => state.db.workOrders);
  const company = useSelector((state: RootState) => state.db.company);
  const [componentURL, setComponentURL] = useState<string | null>(null);
  const [showCopyButton, setShowCopyButton] = useState<boolean>(false);
  const [prevUniqueIdentifier, setPrevUniqueIdentifier] = useState<UniqueIdentifier | null>(null);
  const [nextUniqueIdentifier, setNextUniqueIdentifier] = useState<UniqueIdentifier | null>(null);

  // Properties states
  const [uniqueIdentifierProperties, setUniqueIdentifierProperties] = useState<Partial<ParametricData>[]>([]);
  const [componentProperties, setComponentProperties] = useState<Dataset[]>([]);
  const [propertiesSearchQuery, setPropertiesSearchQuery] = useState<string>("");
  const [filteredProperties, setFilteredProperties] = useState<Dataset[]>([]);
  const [valueSearchQuery, setValueSearchQuery] = useState<string>("");
  const [propertyName, setPropertyName] = useState<string>("");
  const [filteredValues, setFilteredValues] = useState<string[]>([]);
  const [highlitProperty, setHighlitProperty] = useState<Dataset | null>(null);
  const addPropertyInputRef = useRef<HTMLInputElement>(null);
  const addValueInputRef = useRef<HTMLInputElement>(null);
  const { triggerToast, triggerConfirmation } = useContext(ToastContext);

  // Maps states
  const [draftLocation, setDraftLocation] = useState<{ latitude: number; longitude: number } | undefined>(undefined);
  const [mapPopoverOpen, setMapPopoverOpen] = useState<boolean>(false);
  const [locationsIsSaving, setLocationsIsSaving] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();

  // Context
  const observe = useContext(ObservabilityContext);

  useEffect(() => {
    const fetchProperties = async () => {
      if (!uniqueIdentifier?.id) return;
      try {
        const properties = await getComponentInstanceProperties(uniqueIdentifier?.id ?? "");
        setUniqueIdentifierProperties(properties.data ?? []);
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      }
    };

    fetchProperties();
  }, [uniqueIdentifier?.id]);

  // Fetch all properties when the component loads or identifier changes
  useEffect(() => {
    const fetchProperties = async () => {
      if (!uniqueIdentifier?.component_id) return;
      try {
        const properties = await getComponentProperties(uniqueIdentifier?.component_id ?? "");
        setComponentProperties(properties.data);
      } catch (error) {
        console.error("Error fetching component properties:", error);
      }
    };

    fetchProperties();
  }, [uniqueIdentifier?.component_id]);

  useEffect(() => {
    // Filter properties whenever the search query changes
    const filterProperties = async () => {
      if (!componentProperties || componentProperties.length === 0) return;
      if (!propertiesSearchQuery) {
        setFilteredProperties(componentProperties.slice(0, 5));
        return;
      }
      setFilteredProperties(
        componentProperties
          .filter((property: Dataset) => property.name.toLowerCase().includes(propertiesSearchQuery.toLowerCase()))
          .slice(0, 5),
      );
    };

    filterProperties();
  }, [propertiesSearchQuery, componentProperties]);

  useEffect(() => {
    // Filter values whenever the search query changes
    const filterValues = async () => {
      if (highlitProperty?.prior_values?.length === 0) return;
      if (!valueSearchQuery) {
        setFilteredValues(highlitProperty?.prior_values ?? []);
        return;
      }
      setFilteredValues(
        (highlitProperty?.prior_values ?? []).filter((value: string) => value.toLowerCase().includes(valueSearchQuery.toLowerCase())),
      );
    };

    filterValues();
  }, [valueSearchQuery, highlitProperty]);

  useEffect(() => {
    if (components.length > 0 && uniqueIdentifier?.component_id) {
      const component = components.find((component) => component.id === uniqueIdentifier.component_id);
      setComponentURL(component?.url ?? null);
    }
  }, [components, uniqueIdentifier]);

  useEffect(() => {
    if (uniqueIdentifier?.component_id) {
      loadNextPrevIdentifiers();
    }
  }, [uniqueIdentifier?.identifier]);

  useEffect(() => {
    if (uniqueIdentifier?.latitude && uniqueIdentifier?.longitude) {
      setDraftLocation({ latitude: uniqueIdentifier.latitude, longitude: uniqueIdentifier.longitude });
    } else {
      setDraftLocation(undefined);
    }
  }, [uniqueIdentifier?.latitude, uniqueIdentifier?.longitude]);

  const workOrder = useMemo(() => {
    if (uniqueIdentifier?.work_order_id) {
      return workOrders.find((workOrder) => workOrder.id === uniqueIdentifier.work_order_id);
    }
    return undefined;
  }, [uniqueIdentifier?.work_order_id, workOrders]);

  const loadNextPrevIdentifiers = async () => {
    if (!uniqueIdentifier) return;
    const identifiers = await fetchNextAndPrevUniqueIdentifiers(uniqueIdentifier);
    if (identifiers) {
      const [newPrevUniqueIdentifier, newNextUniqueIdentifier] = identifiers as UniqueIdentifier[];
      setPrevUniqueIdentifier(newPrevUniqueIdentifier);
      setNextUniqueIdentifier(newNextUniqueIdentifier);
    }
  };

  const handleNextPrev = async (uniqueIdentifier: UniqueIdentifier | null) => {
    if (uniqueIdentifier?.id) {
      navigate("/snlookup?uid=" + uniqueIdentifier.id);
    }
  };

  const handleArchive = async (isArchived: boolean) => {
    if (!uniqueIdentifier) return;
    const { data: newUniqueIdentiferData, error: newUniqueIdentiferError } = await setUniqueIdentifierArchiveFlag(
      uniqueIdentifier.id,
      isArchived,
    );
    if (newUniqueIdentiferError || newUniqueIdentiferData === undefined) return;
    setUniqueIdentifier({ ...uniqueIdentifier, is_archived: newUniqueIdentiferData.is_archived });
  };

  const handleSaveMapLocation = async () => {
    setLocationsIsSaving(true);
    if (!uniqueIdentifier || !draftLocation) return;
    const { error } = await updateLocation(uniqueIdentifier.id, draftLocation.latitude, draftLocation.longitude);
    if (error) {
      console.error("Failed to save location:", error);
      triggerToast(ToastType.Error, "Failed to save location", "An error occurred while saving the location.");
    }
    setLocationsIsSaving(false);
    setMapPopoverOpen(false);
  };

  const handleDelete = (propertyId: string | undefined) => async () => {
    if (!propertyId) return;
    triggerConfirmation(
      "Delete Property",
      `Are you sure you want to delete this property? It will be deleted from all components of type ${uniqueIdentifier?.component?.name}.`,
      async () => {
        await deactivateProperty(propertyId);
        setUniqueIdentifierProperties(
          uniqueIdentifierProperties.filter((property: Partial<ParametricData>) => property.dataset?.id !== propertyId),
        );
      },
    );
  };

  const handleRemovePropertyValue = (propertyId: string | undefined) => async () => {
    if (!propertyId) return;
    await updatePropertyValue(propertyId, "", uniqueIdentifier?.id ?? "");
    setUniqueIdentifierProperties(
      uniqueIdentifierProperties.filter((property: Partial<ParametricData>) => property.dataset?.id !== propertyId),
    );
  };

  const handleClickProperty = (propertyId: string | undefined) => async () => {
    if (!propertyId) return;
    if (uniqueIdentifierProperties.find((property: Partial<ParametricData>) => property.dataset?.id === propertyId)) {
      triggerToast(ToastType.Error, "Property already added", "A component instance can only have one value for a given property.");
      return;
    }
    setUniqueIdentifierProperties([
      ...uniqueIdentifierProperties,
      { dataset: componentProperties.find((property) => property.id === propertyId), value: "" },
    ]);
  };

  const handleCreateNewProperty = (name: string | undefined) => async () => {
    if (!name) return;
    if (uniqueIdentifierProperties.find((property: Partial<ParametricData>) => property.dataset?.name === name)) {
      triggerToast(ToastType.Error, "Property already added", "A component instance can only have one value for a given property.");
      return;
    }
    const id = uuidv4();
    // Beware the new dataset object is not complete. It is missin several fields that are not used in this context.
    setUniqueIdentifierProperties([...uniqueIdentifierProperties, { value: "", dataset: { id, name } as Dataset }]);
    await createNewProperty(uniqueIdentifier?.component_id ?? "", name, "TEXT", id);
  };

  const handleUpdatePropertyValue = (propertyId: string | undefined, value: string | undefined) => async () => {
    if (!propertyId || !value) return;
    setUniqueIdentifierProperties(
      uniqueIdentifierProperties.map((property: Partial<ParametricData>) => {
        if (property.dataset?.id === propertyId) {
          property.value = value;
          property.dataset.prior_values = [...(property.dataset.prior_values ?? []), value];
        }
        return property;
      }),
    );
    await updatePropertyValue(propertyId, value, uniqueIdentifier?.id ?? "");
  };

  const handleUpdatePropertyName = (propertyId: string | undefined, name: string | undefined) => async () => {
    if (!propertyId || !name) return;
    const newComponentProperties = componentProperties.map((property: Dataset) => {
      if (property.id === propertyId) {
        property.name = name;
      }
      return property;
    });
    setFilteredProperties(newComponentProperties);
    setUniqueIdentifierProperties(
      uniqueIdentifierProperties.map((property: Partial<ParametricData>) => {
        if (property.dataset?.id === propertyId) {
          property.dataset.name = name;
        }
        return property;
      }),
    );
    await updatePropertyName(propertyId, name ?? "");
  };

  return (
    <div onMouseEnter={() => setShowCopyButton(true)} onMouseLeave={() => setShowCopyButton(false)} className="flex w-full flex-col">
      <div className="border-serial-palette-200 relative rounded-md border bg-white ">
        <header className="flex justify-between border-b px-5 py-4">
          <h2 className="text-serial-palette-800 pr-2 font-semibold">Unit Info</h2>
          {processTableIsLoading && <Loader size="sm" />}
          {showCopyButton && !processTableIsLoading && (
            <div className="flex gap-x-2">
              <div
                onClick={() => (
                  handleNextPrev(prevUniqueIdentifier), observe.track("Navigate to SnLookup", { "UI Source": "SNLookup - Previous Unit" })
                )}
              >
                <Tooltip
                  position="top"
                  size="md"
                  className="w-fit py-1"
                  content={
                    <span className="whitespace-nowrap text-xs">
                      <span className="font-bold">Previous Unit: </span>
                      {prevUniqueIdentifier?.identifier || "None"}
                    </span>
                  }
                >
                  <FontAwesomeIcon icon={faCaretLeft} className={prevUniqueIdentifier?.identifier ? "" : "text-serial-palette-300"} />
                </Tooltip>
              </div>
              <div
                onClick={() => (
                  handleNextPrev(nextUniqueIdentifier), observe.track("Navigate to SnLookup", { "UI Source": "SNLookup - Next Unit" })
                )}
              >
                <Tooltip
                  position="top"
                  size="md"
                  className="w-fit py-1"
                  content={
                    <span className="whitespace-nowrap text-xs">
                      <span className="font-bold">Next Unit: </span>
                      {nextUniqueIdentifier?.identifier || "None"}
                    </span>
                  }
                >
                  <FontAwesomeIcon icon={faCaretRight} className={nextUniqueIdentifier?.identifier ? "" : "text-serial-palette-300"} />
                </Tooltip>
              </div>
            </div>
          )}
        </header>
        <div>
          <div className="flex items-center justify-center space-x-4 px-4">
            {componentURL && (
              <div className="my-4 h-32">
                <img src={componentURL} alt="" className="max-h-32 max-w-32 rounded" />
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-3 px-5 py-5">
            {uniqueIdentifier?.status && (
              <div className="items-left flex w-full flex-col">
                <div className="text-serial-palette-400 text-sm font-bold">Status</div>
                <div className="flex w-full items-center justify-between pt-1">
                  {!uniqueIdentifier?.is_archived && <TypeTag type={uniqueIdentifier.status} className="h-6 w-28 text-xs" />}
                  {uniqueIdentifier?.is_archived && <TypeTag type="ARCHIVED" className="h-6 w-28 text-xs" />}
                  {showCopyButton && role === UserRole.Admin && (
                    <div className="cursor-pointer items-center">
                      {uniqueIdentifier?.id && !uniqueIdentifier.is_archived && (
                        <div
                          onClick={() => (handleArchive(!uniqueIdentifier.is_archived), observe.track("Archive Part"))}
                          className=" mr-2 rounded hover:text-red-700"
                        >
                          <Tooltip
                            position="top"
                            size="md"
                            className="text-serial-palette-800 w-fit whitespace-nowrap py-1"
                            content="Archive Unit"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Tooltip>
                        </div>
                      )}
                      {uniqueIdentifier?.id && uniqueIdentifier.is_archived && (
                        <div
                          onClick={() => (handleArchive(!uniqueIdentifier.is_archived), observe.track("Unarchive Part"))}
                          className="text-serial-palette-500 hover:text-serial-palette-700 mr-2"
                        >
                          <Tooltip
                            position="top"
                            size="md"
                            className="text-serial-palette-800 w-fit whitespace-nowrap py-1"
                            content="Unarchive Unit"
                          >
                            <FontAwesomeIcon icon={faTrashRestoreAlt} />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {uniqueIdentifier?.component && (
              <div className="items-left flex w-full flex-col">
                <div className="text-serial-palette-400 text-sm font-bold">Identifier</div>
                <div className="flex w-full justify-between truncate">
                  {uniqueIdentifier.identifier}
                  <CopyToClipboard text={uniqueIdentifier.identifier} className={`${showCopyButton ? "" : "text-white"}`} noTooltip />
                </div>
              </div>
            )}

            {uniqueIdentifier?.component && (
              <div className="items-left flex w-full flex-col">
                <div className="text-serial-palette-400 text-sm font-bold">Component</div>
                <Link
                  to={"/component/" + uniqueIdentifier?.component?.id}
                  className="flex w-3/5 cursor-pointer rounded hover:underline"
                  onClick={() => observe.track("sn-lookup_nav-to-component")}
                >
                  {uniqueIdentifier.component.name}
                </Link>
              </div>
            )}

            {uniqueIdentifier?.part_number?.pn && (
              <div className="items-left flex w-full flex-col">
                <div className="text-serial-palette-400 text-sm font-bold">Part Number</div>
                <Link
                  to={"/component/" + uniqueIdentifier?.component?.id}
                  className="flex w-3/5 min-w-0 cursor-pointer rounded hover:underline"
                  onClick={() => navigate("/component/" + uniqueIdentifier?.component?.id)}
                >
                  {uniqueIdentifier.part_number?.pn}
                </Link>
              </div>
            )}

            {workOrder && (
              <div className="items-left flex w-full flex-col">
                <div className="text-serial-palette-400 text-sm font-bold">{t("workOrder")}</div>
                <Link to={`/workorder/${workOrder.id}`} className="flex min-w-0 flex-grow cursor-pointer rounded hover:underline">
                  {t("workOrderPrefix")}-{workOrder.name}
                </Link>
              </div>
            )}

            {company.config?.enable_location_tracking && (
              <div className="items-left flex w-full flex-col">
                <div className="text-serial-palette-400 text-sm font-bold">Location</div>
                <div className="flex w-full justify-between">
                  {uniqueIdentifier?.latitude && uniqueIdentifier?.longitude ? (
                    <span className="truncate">{`${uniqueIdentifier?.latitude.toFixed(5)}, ${uniqueIdentifier?.longitude.toFixed(5)}`}</span>
                  ) : (
                    <span className="text-serial-palette-400 italic">Not Set</span>
                  )}
                  {role === UserRole.Admin && (
                    <Popover.Root open={mapPopoverOpen} onOpenChange={setMapPopoverOpen}>
                      <Popover.Trigger asChild>
                        <button className={`${showCopyButton ? "opacity-100" : "opacity-0"}`}>
                          <FontAwesomeIcon icon={faLocationArrow} className="text-serial-palette-800" />
                        </button>
                      </Popover.Trigger>
                      <Popover.Content align="start" className="relative h-[300px] w-[300px] overflow-hidden rounded-md border shadow-lg">
                        <MapLocationSelector location={draftLocation} onLocationChange={setDraftLocation} />
                        <Button
                          className="absolute right-[8px] top-[10px] h-[36px] w-[36px]"
                          symmetric
                          disabled={locationsIsSaving}
                          onClick={() => handleSaveMapLocation()}
                        >
                          {locationsIsSaving ? <Loader size="xs" /> : <FontAwesomeIcon icon={faCheck} />}
                        </Button>
                      </Popover.Content>
                    </Popover.Root>
                  )}
                </div>
              </div>
            )}

            {uniqueIdentifierProperties.map((property: Partial<ParametricData>) => (
              <div
                key={property.dataset?.name}
                className="items-left flex w-full flex-col "
                onClick={() => setHighlitProperty(property.dataset ?? null)}
              >
                <div className="w-full">
                  <DropdownCustom
                    align={"left"}
                    className="hover:bg-serial-palette-100 text-serial-palette-400 rounded text-sm font-bold"
                    dropdownClassName="w-[20vh]"
                    keepOpen={true}
                    buttonContents={property.dataset?.name}
                  >
                    <div className="mx-2 mb-1.5">
                      <input
                        className="form-input w-full"
                        defaultValue={property.dataset?.name}
                        onChange={(e) => setPropertyName(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            triggerConfirmation(
                              "Update Property Name",
                              `Are you sure you want to update this property name? It will be updated for all components of type ${uniqueIdentifier?.component?.name}.`,
                              async () => {
                                await handleUpdatePropertyName(property.dataset?.id, propertyName)();
                                setPropertyName("");
                              },
                            );
                          }
                        }}
                      ></input>
                    </div>
                    <div className="hover:bg-serial-palette-100 mb-1.5 inline-flex w-full items-center justify-between space-x-2 rounded px-2 py-1 text-sm">
                      <div>Type</div>
                      <TypeTag type="TEXT" className="text-xs" />
                    </div>
                    {role === UserRole.Admin && (
                      <button
                        className="hover:bg-serial-palette-100 mb-1.5 inline-flex w-full cursor-pointer items-center space-x-2 rounded px-2 py-1 text-sm text-red-600"
                        onClick={handleDelete(property.dataset?.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <div>Delete</div>
                      </button>
                    )}
                  </DropdownCustom>
                </div>

                <div className="inline-flex w-full justify-between">
                  <DropdownCustom
                    // @ts-ignore
                    inputRef={addValueInputRef}
                    align={"left"}
                    className={`hover:bg-serial-palette-100 flex h-full w-auto rounded bg-white text-left`}
                    dropdownClassName="w-[20vh]"
                    keepOpen={true}
                    buttonContents={property.value || "None"}
                  >
                    <div className="mb-2">
                      <div className="mx-2 mb-1.5">
                        <input
                          ref={addValueInputRef}
                          className="form-input w-full"
                          placeholder="Set property value"
                          value={valueSearchQuery}
                          onChange={(e) => setValueSearchQuery(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleUpdatePropertyValue(property.dataset?.id, valueSearchQuery)();
                              setValueSearchQuery("");
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                    {filteredValues.map((value, index) => (
                      <button
                        key={index}
                        className="btn-sm focus:bg-serial-palette-100 hover:bg-serial-palette-100 flex w-full cursor-pointer justify-start bg-white px-3 font-normal focus:outline-none"
                        onClick={() => {
                          property.value = value;
                          setUniqueIdentifierProperties([...uniqueIdentifierProperties]);
                          handleUpdatePropertyValue(property.dataset?.id, value)();
                        }}
                      >
                        {value}
                      </button>
                    ))}
                  </DropdownCustom>
                  {showCopyButton && (
                    <button
                      className="cursor-pointer rounded px-2 hover:text-red-700"
                      onClick={handleRemovePropertyValue(property.dataset?.id)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </div>
              </div>
            ))}

            {/* Add Property Button */}
            <div>
              <DropdownCustom
                align={"left"}
                className="text-serial-palette-500 relative w-fit rounded py-1 pt-3 focus:outline-none"
                dropdownClassName="w-[20vh]"
                keepOpen={true}
                // @ts-ignore
                inputRef={addPropertyInputRef}
                buttonContents={
                  <div className="btn-sm serial-btn-dark inline-flex items-center space-x-2">
                    <FontAwesomeIcon icon={faPlus} />
                    <div>Add Property</div>
                  </div>
                }
              >
                {/* Wrap all children in a single div */}
                <div>
                  <div className="mb-2">
                    <div className="mx-2 mb-1.5">
                      <input
                        ref={addPropertyInputRef}
                        className="form-input w-full"
                        placeholder="Add new property"
                        value={propertiesSearchQuery}
                        onChange={(e) => setPropertiesSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleCreateNewProperty(propertiesSearchQuery)();
                            setPropertiesSearchQuery("");
                          }
                        }}
                      />
                    </div>
                  </div>
                  {filteredProperties.map((property, index) => (
                    <button
                      key={index}
                      className="btn-sm focus:bg-serial-palette-100 hover:bg-serial-palette-100 flex w-full cursor-pointer justify-start bg-white px-3 font-normal focus:outline-none"
                      onClick={handleClickProperty(property.id)}
                    >
                      {property.name}
                    </button>
                  ))}
                </div>
              </DropdownCustom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnLookUpUnitDetails;
