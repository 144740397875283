import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent } from "ag-grid-community";
import { Loader } from "@shared/components/Loader";
import useDarkMode from "@shared/hooks/useDarkMode";
import useMeasures from "../../MeasuresProvider";
import Popover from "@shared/components/primitives/Popover";
import MeasureKeySelector from "../MeasureKeySelector";
import { MeasureGridCellCoordinate, MeasuresGridChildColDef, MeasuresGridGroupColDef, MeasuresGridRowData, columnTypes } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "@shared/components/primitives/Button";
import { cn } from "@shared/utils/tailwind";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import MeasuresGridHeaderGroupCell from "./MeasuresGridHeaderGroupCell";
import { MeasureKey, MeasureType } from "@shared/measures/types";
import MeasuresGridHeaderCell from "./MeasuresGridHeaderCell";
import MeasuresGridBodyCell from "./MeasuresGridBodyCell";
import { hashMeasureKey } from "@shared/measures/helpers/measureKeys";
import { getMeasureName } from "../../helpers/naming";
import { Component, Dataset, Process, ProcessEntry } from "@shared/types/databaseTypes";
import { fetchLargeTable } from "@shared/connections/supabaseGeneral";
import { fetchAllProcessRevisionsForProcessIdSet } from "@shared/measures/connections/supabase";
import { filterRootToLeafPathsRelevantToMeasureKeys, findRootToLeafPaths } from "@shared/measures/helpers/genealogy";
import MeasuresGridPreviewModal from "./MeasuresGridPreviewModal";

const defaultColDef = {
  filter: true,
  resizable: true,
};

const generateColDef = (
  key: MeasureKey,
  gridContainerRef: React.RefObject<HTMLDivElement>,
  setPreviewObject: (value: MeasureGridCellCoordinate | null) => void,
  relevantProcessEntries: ProcessEntry[],
  relevantProcessRevisions: Process[],
  db: {
    components: Component[];
    processes: Process[];
    datasets: Dataset[];
  },
): MeasuresGridChildColDef => {
  return {
    headerName: getMeasureName(key, db),
    field: hashMeasureKey(key),
    type: key.type,
    headerComponent: MeasuresGridHeaderCell,
    headerComponentParams: {
      measureKey: key,
    },
    cellRenderer: MeasuresGridBodyCell,
    cellRendererParams: {
      measureKey: key,
      gridContainerRef,
      setPreviewObject,
      processEntries: relevantProcessEntries,
      processRevisions: relevantProcessRevisions,
    },
  };
};

const MeasuresGrid = ({ className, configurable }: { className?: string; configurable?: boolean }) => {
  const {
    setGridApi,
    handleAddSelectedKey,
    filterBy,
    isLoading,
    selectedMeasureKeys,
    identifierMeasureKeys,
    uniqueIdentifiers,
    componentId,
    filterMapping,
    sortMapping,
  } = useMeasures();
  const { darkMode } = useDarkMode();

  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const [relevantProcessRevisions, setRelevantProcessRevisions] = useState<Process[]>([]);
  const [relevantProcessEntries, setRelevantProcessEntries] = useState<ProcessEntry[]>([]);
  const [previewObject, setPreviewObject] = useState<MeasureGridCellCoordinate | null>(null);

  const gridContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const relevantProcessIds = componentProcessLinks.filter((link) => link.component_id === componentId).map((link) => link.process_id);
    // TODO: Both these queries will fail for large sets of process ids. Need to implement batching
    fetchLargeTable<ProcessEntry>("process_entries", "id", "*", [{ modifier: "in", key: "process_id", value: relevantProcessIds }]).then(
      (processEntries) => {
        setRelevantProcessEntries(processEntries);
      },
    );
    fetchAllProcessRevisionsForProcessIdSet(relevantProcessIds).then((processRevisions) => {
      setRelevantProcessRevisions(processRevisions);
    });
  }, [componentId]);

  // Row Data with Filters
  const rowData: MeasuresGridRowData[] = useMemo(() => {
    const newRowData: MeasuresGridRowData[] = [];
    if (!componentId) return newRowData;
    uniqueIdentifiers.map((uniqueIdentifier) => {
      // Find all root to leaf paths for this unique identifier
      let rootToLeafPaths = findRootToLeafPaths(uniqueIdentifier.genealogy.uniqueIdentifierLinks, uniqueIdentifier);
      rootToLeafPaths = filterRootToLeafPathsRelevantToMeasureKeys(rootToLeafPaths, componentId, selectedMeasureKeys);
      // Each remaining path now corresponds to a row in the grid
      rootToLeafPaths.forEach((rootToLeafPath, index) => {
        // Construct the base row data
        const newRow: MeasuresGridRowData = {
          ...uniqueIdentifier,
          rowUniqueIdentifierIds: rootToLeafPath.map((segment) => segment.uniqueIdentifierId),
          measuresValuesByHash: {},
        };
        // Populate the measuresValuesByHash by iterating over each measure and finding the data for the linked unique identifier
        uniqueIdentifier.measures.forEach((measure) => {
          // Determine which linked unique identifier to use for this measure
          const targetComponentId = measure.key.component_id;
          const linkedUniqueIdentifierId = rootToLeafPath.find((segment) => segment.componentId === targetComponentId)?.uniqueIdentifierId;
          // If the row above has the same linked unique identifier, then this measure should also use the same as the row above
          const sameAsAbove = rootToLeafPaths[index - 1]?.find(
            (segment) => segment.componentId === targetComponentId && segment.uniqueIdentifierId === linkedUniqueIdentifierId,
          )
            ? true
            : false;
          // the row is not applicable if the root to left path contains no component ids of the target component but the target component is linked somewhere in the tree
          const allLinkedComponentIds = uniqueIdentifier.genealogy.uniqueIdentifiers.map(
            (uniqueIdentifier) => uniqueIdentifier.component_id,
          );
          const notApplicable =
            !rootToLeafPath.find((segment) => segment.componentId === targetComponentId) &&
            allLinkedComponentIds.includes(targetComponentId);
          // Get the measure values and aggregation for the linked unique identifier
          const measureValues = linkedUniqueIdentifierId ? measure.valuesByIdentifier[linkedUniqueIdentifierId]?.values ?? [] : [];
          const measureAggregation = linkedUniqueIdentifierId ? measure.valuesByIdentifier[linkedUniqueIdentifierId]?.aggregation : null;
          // Add the measure values to the row data
          if (measureValues && measureAggregation && measureValues.length > 0) {
            newRow.measuresValuesByHash[hashMeasureKey(measure.key)] = {
              measureValues,
              measureAggregation,
              sameAsAbove,
              notApplicable: false,
            };
          } else {
            newRow.measuresValuesByHash[hashMeasureKey(measure.key)] = {
              measureValues: null,
              measureAggregation: null,
              sameAsAbove: false,
              notApplicable,
            };
          }
        });
        newRowData.push(newRow);
      });
    });
    return newRowData;
  }, [uniqueIdentifiers, selectedMeasureKeys, componentId]);

  const filteredSortedRowData: MeasuresGridRowData[] = useMemo(() => {
    const filteredRowData = rowData
      .filter((uniqueIdentifierRow) => filterMapping[uniqueIdentifierRow.id] === true)
      .sort((a, b) => {
        const aSort = sortMapping[a.id] ?? 0;
        const bSort = sortMapping[b.id] ?? 0;
        return aSort - bSort;
      });
    // alternate row shading but group by unique identifier id
    let bgShaded = true;
    return filteredRowData.map((row, index) => {
      const prevRowUniqueIdentifierId = filteredRowData[index - 1]?.id;
      if (prevRowUniqueIdentifierId !== row.id) {
        bgShaded = !bgShaded;
      }
      const newRow: MeasuresGridRowData = {
        ...row,
        bgShaded,
      };
      return newRow;
    });
  }, [rowData, filterMapping, sortMapping]);

  // Column Definitions
  const columnDefs: MeasuresGridGroupColDef[] = useMemo(() => {
    const newColumnDefs: MeasuresGridGroupColDef[] = [];
    if (!componentId) return newColumnDefs;
    const rootMeasureKey: MeasureKey = {
      type: MeasureType.Identifier,
      component_id: componentId,
    };
    for (const key of [rootMeasureKey, ...selectedMeasureKeys]) {
      // If the component group doesn't exist. Add a header group
      if (!newColumnDefs.find((col) => col.componentId === key.component_id)) {
        const component = components.find((c) => c.id === key.component_id);
        if (component) {
          // auto add an identifier key if the component is the root component
          const identifierKey =
            key.component_id === componentId ? identifierMeasureKeys.find((ik) => ik.component_id === key.component_id) : null;
          newColumnDefs.push({
            componentId: key.component_id,
            headerName: component.name,
            headerGroupComponent: MeasuresGridHeaderGroupCell,
            openByDefault: true,
            headerGroupComponentParams: {
              component,
            },
            children: identifierKey
              ? [generateColDef(identifierKey, gridContainerRef, setPreviewObject, [], [], { components, processes, datasets })]
              : [],
          });
        }
      }
      const filteredProcessRevisions = key.process_id ? relevantProcessRevisions.filter((pr) => pr.id === key.process_id) : [];
      const filteredProcessEntries = key.process_id ? relevantProcessEntries.filter((pe) => pe.process_id === key.process_id) : [];
      const componentGroup = newColumnDefs.find((col) => col.componentId === key.component_id);
      if (componentGroup && !(key.type === MeasureType.Identifier && key.component_id === componentId))
        componentGroup.children.push(
          generateColDef(key, gridContainerRef, setPreviewObject, filteredProcessEntries, filteredProcessRevisions, {
            components,
            processes,
            datasets,
          }),
        );
    }
    return newColumnDefs;
  }, [
    selectedMeasureKeys,
    components,
    processes,
    datasets,
    identifierMeasureKeys,
    filterBy,
    relevantProcessRevisions,
    relevantProcessEntries,
    componentId,
  ]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const rowStyle = { backgroundColor: "var(--color-tailwind-white)" };
  const getRowStyle = (params: any) => {
    if (params.data.bgShaded) {
      return { backgroundColor: "var(--color-serial-palette-50)" };
    }
  };

  return (
    <div
      ref={gridContainerRef}
      className={cn("relative z-0 h-full w-full rounded", darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine", className)}
    >
      <AgGridReact
        rowData={filteredSortedRowData}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        defaultColDef={defaultColDef}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        rowHeight={40}
        headerHeight={42}
        groupHeaderHeight={48}
        onGridReady={onGridReady}
        suppressCellFocus={true}
        suppressRowHoverHighlight={true}
        className="grid-builder-ag-grid"
      />
      {configurable && (
        <div className="absolute right-2 top-1.5">
          <Popover.Root>
            <Popover.Trigger asChild>
              <Button variant="outline" className="h-[30px] w-[34px]">
                {isLoading ? (
                  <Loader styleOverride="w-[14px] h-[14px]" />
                ) : (
                  <FontAwesomeIcon icon={faPlus} size="lg" className="text-serial-palette-500" />
                )}
              </Button>
            </Popover.Trigger>
            <Popover.Content align="end" className="w-[560px] rounded-md border shadow-lg">
              <MeasureKeySelector onSelect={handleAddSelectedKey} />
            </Popover.Content>
          </Popover.Root>
        </div>
      )}
      {isLoading && (
        <div className="bg-serial-palette-100 absolute inset-0 flex items-center justify-center opacity-70">
          <Loader styleOverride="w-[60px] h-[60px] mt-36" />
        </div>
      )}
      <MeasuresGridPreviewModal previewObject={previewObject} setPreviewObject={setPreviewObject} rowData={filteredSortedRowData} />
    </div>
  );
};

export default MeasuresGrid;
