import { ProcessStepWithReferences } from "@shared/types/databaseTypes";
import useFieldGroups from "../hooks/useFieldGroups";
import ProcessBuilderWorkInstructionBlock from "./ProcessBuilderWorkInstructionBlock";
import ProcessBuilderFieldGroup from "./ProcessBuilderFieldGroup";
import { useContext, useMemo, useRef } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import ProcessBuilderStepFilter from "./ProcessBuilderStepFilter";
import ProcessBuilderPreviewIdentifierField from "./ProcessBuilderPreviewIdentifierField";
import ProcessBuilderWorkInstructionBlockPlaceholder from "./ProcessBuilderWorkInstructionBlockPlaceholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ProcessBuilderAddFieldDropdown } from "./ProcessBuilderAddDropdown";

interface ProcessBuilderStepProps {
  step?: ProcessStepWithReferences;
  stepIndex: number;
  isPlaceholder?: boolean;
  previewMode?: boolean;
  handleCreateRef?: (index: number) => (ref: HTMLDivElement) => void;
}

const ProcessBuilderStep: React.FC<ProcessBuilderStepProps> = ({ step, stepIndex, isPlaceholder, previewMode, handleCreateRef }) => {
  const { readOnly, draftProcess, enablePreviewMode, handleUpdateStep } = useContext(ProcessBuilderContext);
  const fieldGroups = useFieldGroups({ fields: step?.fields ?? [] });
  const placeholderInputRef = useRef<HTMLInputElement>(null);

  const numPriorHiddenSteps = useMemo(() => {
    return draftProcess?.process_steps.slice(0, stepIndex).filter((step) => step.is_hidden).length ?? 0;
  }, [draftProcess, stepIndex]);

  return (
    <div
      className={`relative flex ${isPlaceholder || previewMode ? "min-h-0 min-w-0 flex-grow" : "w-full border-b border-dashed"} ${isPlaceholder && "min-h-[85vh]"}`}
      ref={handleCreateRef ? handleCreateRef(stepIndex) : undefined}
    >
      {!previewMode && (
        <div className="bg-serial-palette-300 absolute left-3 top-0 flex gap-x-1.5 rounded-b px-3 text-xs text-white">
          {!isPlaceholder && (
            <span className="font-bold">{step?.is_hidden ? "Hidden Step: " : `Step ${stepIndex - numPriorHiddenSteps + 1}: `}</span>
          )}
          {step?.name !== "" && <span>{step?.name ?? "Create New Step"}</span>}
        </div>
      )}
      <div
        className={`flex min-h-[450px] w-1/2 shrink-0 flex-col overflow-auto border-r bg-white px-4 py-8 xl:w-2/3 ${isPlaceholder && "overflow-hidden"}`}
      >
        {step && !previewMode && !readOnly && (
          <div className="-mt-4 mb-1 flex w-full justify-end gap-1 pr-10">
            <button
              className={`btn-sm h-7 ${step.is_hidden ? "serial-btn-dark" : "serial-btn-light"} items-center gap-1`}
              onClick={() => handleUpdateStep(stepIndex, "is_hidden", !step.is_hidden)}
            >
              <FontAwesomeIcon icon={faEyeSlash} />
            </button>
            <ProcessBuilderStepFilter step={step} stepIndex={stepIndex} align="right" />
          </div>
        )}
        {step?.work_instruction_blocks.map((workInstructionBlock, index) => {
          return (
            <ProcessBuilderWorkInstructionBlock
              key={index}
              workInstructionBlock={workInstructionBlock}
              stepIndex={stepIndex}
              workInstructionBlockIndex={index}
              placeholderInputRef={placeholderInputRef}
            />
          );
        })}
        {!readOnly && (
          <ProcessBuilderWorkInstructionBlockPlaceholder
            stepIndex={stepIndex}
            workInstructionBlockIndex={step?.work_instruction_blocks.length ?? 0}
            placeholderInputRef={placeholderInputRef}
          />
        )}
      </div>
      <div className={`flex min-h-0 min-w-0 flex-grow flex-col ${isPlaceholder && "overflow-hidden"}`}>
        {enablePreviewMode && <ProcessBuilderPreviewIdentifierField />}
        <div className="flex h-full w-full flex-col gap-y-2 overflow-auto px-2 py-4">
          {fieldGroups.map((group, index) => {
            return (
              <ProcessBuilderFieldGroup
                key={index}
                allFields={step?.fields ?? []}
                fieldGroup={group}
                stepIndex={stepIndex}
                fieldGroupIndex={index}
              />
            );
          })}
          {(isPlaceholder || fieldGroups.length === 0) && (
            <ProcessBuilderFieldGroup
              allFields={step?.fields ?? []}
              isPlaceholder={true}
              stepIndex={stepIndex}
              fieldGroupIndex={fieldGroups.length}
            />
          )}
          {!readOnly && (
            <div className="mt-2 flex w-full justify-center">
              <ProcessBuilderAddFieldDropdown
                stepIndex={stepIndex}
                tileIndex={fieldGroups.flatMap((group) => group.fields).length}
                buttonContents={
                  <div className="btn-sm serial-btn-dark gap-1.5 px-4">
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Add Field Group</span>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProcessBuilderStep;
