import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import LineChart, { LineChartSeries } from "@shared/charts/LineChart";
import ScatterChart, { ScatterChartDatapoint, ScatterChartSeries } from "@shared/charts/ScatterChart";
import BarChart, { BarChartDatapoint, BarChartSeries } from "@shared/charts/BarChart";
import DonutChart, { DonutChartDataPoint } from "@shared/charts/DonutChart";

// ---------- Linear Data ----------

const linearData = Array.from({ length: 10 }, (_, i) => {
  const x = i * 10;
  const y = Math.random() * 100;
  return {
    x,
    y: { value1: { value: y, title: `Value ${i}`, label: `(x: ${x.toFixed(2)}, y: ${y.toFixed(2)})` } },
  };
});

const timeData = Array.from({ length: 10 }, (_, i) => {
  const x = `2024-06-24T${String(i + 10).padStart(2, "0")}:00:00`;
  const y = Math.random() * 100;
  return {
    x,
    y: { value1: { value: y, title: `Value ${i}`, label: `(x: ${x}, y: ${y.toFixed(2)})` } },
  };
});

const categoryData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((month, i) => {
  const x = month;
  const y = Math.random() * 100;
  return {
    x,
    y: { value1: { value: y, title: `Value ${i}`, label: `(x: ${x}, y: ${y.toFixed(2)})` } },
  };
});

const series: LineChartSeries[] = [
  {
    id: "value1",
    label: "Random Series",
    color: "#4ADE80",
    style: "solid",
  },
];

// ---------- Scatter Data ----------

const scatterData: ScatterChartDatapoint[] = Array.from({ length: 100 }, (_, i) => {
  const x = Math.random() * 100;
  const y = Math.random() * 100;
  return {
    title: `Data Point ${i}`,
    label: `(${x.toFixed(2)}, ${y.toFixed(2)})`,
    x,
    y,
  };
});

const scatterSeries: ScatterChartSeries[] = [
  {
    label: "Random Data",
    color: "#4ADE80",
    data: scatterData,
  },
];

// ---------- Bar Data ----------

const barData1: BarChartDatapoint[] = Array.from({ length: 10 }, (_, i) => {
  const x = `Category ${i + 1}`;
  const y = Math.random() * 50;
  return {
    title: `Dataset 1, Point ${i}`,
    label: `(${x}, ${y.toFixed(2)})`,
    x,
    y,
  };
});

const barData2: BarChartDatapoint[] = Array.from({ length: 10 }, (_, i) => {
  const x = `Category ${i + 1}`;
  const y = Math.random() * 50;
  return {
    title: `Dataset 2, Point ${i}`,
    label: `(${x}, ${y.toFixed(2)})`,
    x,
    y,
  };
});

const barSeries: BarChartSeries[] = [
  {
    label: "Random Bar Data 1",
    color: "#4ADE80",
    data: barData1,
  },
  {
    label: "Random Bar Data 2",
    color: "#F87171",
    data: barData2,
  },
];

// ---------- Donut Data ----------

const donutData: DonutChartDataPoint[] = [
  { id: "1", label: "Category 1", value: 30, color: "#4ADE80" },
  { id: "2", label: "Category 2", value: 20, color: "#F87171" },
  { id: "3", label: "Category 3", value: 50, color: "#60A5FA" },
];

const FrontendPrimitivesCharts = () => {
  return (
    <FrontendPrimitivesCard title="Charts">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Line Charts</div>
        <div className="flex h-[300px] w-full gap-2">
          <LineChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={series}
            data={linearData}
            legend={true}
            grid={true}
            zoom={true}
          />
          <LineChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={series}
            data={categoryData}
            legend={true}
            grid={false}
            zoom={true}
          />
          <LineChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={series}
            data={timeData}
            legend={false}
            grid={true}
            zoom={false}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Scatter Charts</div>
        <div className="flex h-[300px] w-full gap-2">
          <ScatterChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={scatterSeries}
            legend={true}
            grid={true}
            zoom={true}
          />
          <ScatterChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label", min: -50 }}
            yAxis={{ label: "Y Axis Label" }}
            series={scatterSeries}
            legend={true}
            grid={false}
            zoom={true}
          />
          <ScatterChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={scatterSeries}
            legend={false}
            grid={true}
            zoom={false}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Bar Charts</div>
        <div className="flex h-[300px] w-full gap-2">
          <BarChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={[barSeries[0]]}
            legend={true}
            grid={false}
            zoom={true}
          />
          <BarChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={[barSeries[1]]}
            legend={true}
            grid={true}
            zoom={true}
          />
          <BarChart
            className="h-full w-1/3"
            xAxis={{ label: "X Axis Label" }}
            yAxis={{ label: "Y Axis Label" }}
            series={barSeries}
            legend={false}
            grid={true}
            zoom={false}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Donut Chart</div>
        <div className="flex h-[300px] w-full gap-2">
          <DonutChart className="h-full w-1/3" data={donutData} legend={true} tooltip={true} />
          <DonutChart className="h-full w-1/3" data={donutData} legend={true} tooltip={false} />
          <DonutChart className="h-full w-1/3" data={donutData} legend={false} tooltip={true} />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Sizing</div>
        <div className="flex h-[300px] w-full gap-2">
          <DonutChart className="h-full w-1/3" size="sm" data={donutData} legend={true} tooltip={true} />
          <DonutChart className="h-full w-1/3" size="md" data={donutData} legend={true} tooltip={true} />
          <DonutChart className="h-full w-1/3" size="lg" data={donutData} legend={true} tooltip={true} />
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesCharts;
