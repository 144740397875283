/**
 * This file is responsible for loading a lightweight local copy of the tenant's section of the database.
 * The imported data is lightweight, meaning it doesn't pull down any of the large data tables such as component
 * instances images or any other instance data.
 *
 * The data is stored in our Redux store to provide global access throughout the app. This allows any component
 * to access and interact with the tenant's data without making redundant database calls.
 */

import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";
import {
  Company,
  Component,
  ComponentCounts,
  ComponentLink,
  ComponentProcessLink,
  Dataset,
  Field,
  Operator,
  PartNumber,
  Process,
  ReportTemplate,
  Station,
  UniqueIdentifier,
  UniqueIdentifierActivityLog,
  User,
  Version,
  WorkOrder,
} from "@shared/types/databaseTypes";
import {
  setCompany,
  setOperators,
  setComponentLinks,
  setComponentProcessLinks,
  setComponents,
  setDatasets,
  setPartNumbers,
  setProcesses,
  setStations,
  setUsers,
  setVersions,
  setFields,
  setIsLoaded,
  setWorkOrders,
  setReportTemplates,
  setComponentInstances,
  setUniqueIdentifierActivityLog,
} from "@shared/redux/db";
import { getRpcWithRetries } from "./supabaseGeneral";
import { CHUNK_SIZE } from "@shared/constants/supabase";

// ------------- Generic refresh function -------------

const refreshData = async <T>(
  tableName: string,
  selectString: string,
  allowRetries: boolean = true,
  latestRevisionOnly: boolean = false,
): Promise<T[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  // Get count
  let countQuery = supabase.from(tableName).select("*", { count: "exact", head: true });
  if (latestRevisionOnly) countQuery = countQuery.eq("is_latest_revision", true);

  const { count: totalCount, error: countError } = await countQuery;
  if (countError || !totalCount) return [];

  const allData: T[] = [];

  // Get the data in chunks of CHUNK_SIZE. Query limit is 5000 rows per query so use CHUNK_SIZE=4500 to be safe.
  // This could lead to some data being missed if the table is updated while the data is being fetched.
  // It could also lead to duplicate data if the table is updated while the data is being fetched.
  for (let offset = 0; offset < totalCount; offset += CHUNK_SIZE) {
    let query = supabase
      .from(tableName)
      .select(selectString)
      .order("created_at", { ascending: true })
      .range(offset, offset + CHUNK_SIZE - 1);

    if (latestRevisionOnly) query = query.eq("is_latest_revision", true);

    const { data, error } = await query.returns<T[]>();
    if (!error && data.length > 0) {
      allData.push(...data);
    } else {
      console.log(error);
    }
  }

  // Check uniqueness of the IDs in the data
  const pkey = tableName === "users" ? "supabase_uid" : "id";
  const secondaryPkey = tableName === "processes" ? "revision" : "";
  const allIdsUnique =
    allData.length ===
    new Set(
      allData.map((item: any) => {
        return `${item[pkey]}${secondaryPkey ? `-${item[secondaryPkey]}` : ""}`;
      }),
    ).size;

  // if the ids are not unique, load the data again
  if (!allIdsUnique && allowRetries) {
    console.log(`Duplicate IDs found in ${tableName}. Refreshing data...`);
    return await refreshData<T>(tableName, selectString, false);
  }

  return allData;
};

// ------------- Foreign Key Joins -------------
// NOTE: Adding foreign key joins here will cause more expensive refreshes of the data since we will need to refresh the entire table
//       if there is an update to the foreign table. Only add foreign key joins here if absolutely needed for the UI.

const foreignKeyJoinsByTable: {
  [tableName: string]: {
    foreignTableName: string;
    foreignKey: string;
    propertyName: string;
    selectString: string;
  }[];
} = {
  companies: [],
  components: [
    { foreignTableName: "users", foreignKey: "last_edited_user_id", propertyName: "user", selectString: "user:last_edited_user_id(*)" },
  ],
  users: [],
  processes: [
    { foreignTableName: "users", foreignKey: "created_by_user_id", propertyName: "user", selectString: "user:created_by_user_id(*)" },
  ],
  fields: [],
  component_links: [
    {
      foreignTableName: "components",
      foreignKey: "has_child_of_id",
      propertyName: "child_component",
      selectString: "child_component:has_child_of_id(*)",
    },
    {
      foreignTableName: "components",
      foreignKey: "component_id",
      propertyName: "child_component",
      selectString: "parent_component:component_id(*)",
    },
  ],
  component_process_links: [
    { foreignTableName: "components", foreignKey: "component_id", propertyName: "component", selectString: "component:component_id(*)" },
    { foreignTableName: "processes", foreignKey: "process_id", propertyName: "process", selectString: "process:processes(*)" },
  ],
  datasets: [{ foreignTableName: "processes", foreignKey: "process_id", propertyName: "process", selectString: "process:processes(*)" }],
  stations: [],
  operators: [],
  part_numbers: [
    { foreignTableName: "components", foreignKey: "component_id", propertyName: "component", selectString: "component:component_id(*)" },
    { foreignTableName: "users", foreignKey: "last_edited_user_id", propertyName: "user", selectString: "user:last_edited_user_id(*)" },
  ],
  work_orders: [],
  versions: [],
  report_templates: [],
  unique_identifiers: [
    { foreignTableName: "components", foreignKey: "component_id", propertyName: "component", selectString: "component:component_id(*)" },
    { foreignTableName: "process_entries", foreignKey: "latest_process_entry_id", propertyName: "latest_process_entry", selectString: "latest_process_entry:latest_process_entry_id(*)" },
  ],
};

const generateSelectString = (tableName: string): string => {
  const joins = foreignKeyJoinsByTable[tableName];
  if (!joins || joins.length === 0) return "*";
  return `*, ${joins.map((join) => join.selectString).join(", ")}`;
};

const getPropertiesToUpdateOnForeignKeyChange = (foreignTableName: string): { tableName: string; propertyName: string }[] => {
  const propertiesToUpdate: { tableName: string; propertyName: string }[] = [];
  Object.keys(foreignKeyJoinsByTable).forEach((tableName) => {
    foreignKeyJoinsByTable[tableName].forEach((join) => {
      if (join.foreignTableName === foreignTableName) {
        propertiesToUpdate.push({ tableName: tableName, propertyName: join.propertyName });
      }
    });
  });
  return propertiesToUpdate;
};

// ------------- 'companies' Table -------------

const refreshCompany = async (): Promise<void> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: company, error: companyError }: { data: Company | null; error: any } = await supabase
    .from("companies")
    .select(generateSelectString("companies"))
    .single();

  // Handle errors
  if (!company || companyError) {
    console.log(companyError);
    return;
  }

  // Get list of available files to download to see if logo exists
  const { data: companyBrandingList } = await supabase.storage.from("company-branding").list(company.id);
  // If logo exists, download it and create a url for it
  if (
    companyBrandingList?.map((fileList) => fileList.name).includes("logo-light") ||
    companyBrandingList?.map((fileList) => fileList.name).includes("logo-dark")
  ) {
    const { data: lightImageData, error: lightErrorImage } = await supabase.storage
      .from("company-branding")
      .download(`${company.id}/logo-light`);
    const { data: darkImageData, error: darkErrorImage } = await supabase.storage
      .from("company-branding")
      .download(`${company.id}/logo-dark`);
    if (!lightErrorImage) {
      company.light_logo = URL.createObjectURL(lightImageData);
    }
    if (!darkErrorImage) {
      company.dark_logo = URL.createObjectURL(darkImageData);
    }
  }
  // do the same for icons
  if (
    companyBrandingList?.map((fileList) => fileList.name).includes("icon-light") ||
    companyBrandingList?.map((fileList) => fileList.name).includes("icon-dark")
  ) {
    const { data: lightImageData, error: errorLightImage } = await supabase.storage
      .from("company-branding")
      .download(`${company.id}/icon-light`);
    const { data: darkImageData, error: errorDarkImage } = await supabase.storage
      .from("company-branding")
      .download(`${company.id}/icon-dark`);
    if (!errorLightImage) {
      company.light_icon = URL.createObjectURL(lightImageData);
    }
    if (!errorDarkImage) {
      company.dark_icon = URL.createObjectURL(darkImageData);
    }
  }

  // Write to redux
  store.dispatch(setCompany(company));
  return Promise.resolve();
};

// ------------- 'components' Table -------------

interface ComponentCountsRpc {
  [componentId: string]: {
    counts: ComponentCounts;
    part_number_counts: ComponentCounts[];
  };
}

const refreshComponents = async (): Promise<void> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  if (!state.auth.company_id) return;

  // Query
  let { data: components, error: componentsError } = await supabase
    .from("components")
    .select(generateSelectString("components"))
    .returns<Component[]>();

  // Handle errors
  if (!components || componentsError) {
    console.error(componentsError);
    return;
  }

  // Get list of available files to download
  const { data: componentImageList } = await supabase.storage.from("component-avatars").list(state.auth.company_id);

  // Iterate through components and get image for each
  await Promise.all(
    components.map(async (component: Component, index) => {
      if (componentImageList?.map((listItem) => listItem.name).includes(component.id)) {
        const { data: imageData, error: errorImage } = await supabase.storage
          .from("component-avatars")
          .download(`${state.auth.company_id}/${component.id}`, { transform: { quality: 50, width: 300, resize: "contain" } });
        if (errorImage) {
          console.log(errorImage);
        } else {
          if (components && components[index]) components[index].url = URL.createObjectURL(imageData);
        }
      }
    }),
  );

  // Write to redux (to allow faster load time of component data before the counts are added)
  store.dispatch(setComponents(components));

  // Add summary data to each component
  const { data: countsData, error: countsError } = await getRpcWithRetries<ComponentCountsRpc>("component_counts");
  if (countsError || !countsData) {
    console.error(countsError);
  } else {
    // make deep copy of components since we don't want to modify the redux state directly
    components = JSON.parse(JSON.stringify(components)) as Component[];
    // write the same loop as above but with for of to avoid the typescript error
    for (const component of components) {
      const summaryStats = countsData?.[component.id];
      if (summaryStats) {
        component.counts = summaryStats.counts;
        component.part_number_counts = summaryStats.part_number_counts ?? [];
      }
    }
  }

  // Write to redux
  store.dispatch(setComponents(components));

  return Promise.resolve();
};

// ------------- 'users' Table -------------

const refreshUsers = async (): Promise<void> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);
  if (!state.auth.company_id) return;

  // Query
  let { data: users, error: usersError } = await supabase.from("users").select(generateSelectString("users")).returns<User[]>();

  // Handle errors
  if (!users || usersError) {
    console.error(usersError);
    return;
  }

  // Get list of available files to download
  const { data: userImageList } = await supabase.storage.from("user-avatars").list(state.auth.company_id);

  // Iterate through components and get image for each
  await Promise.all(
    users.map(async (user: User, index) => {
      if (user.supabase_uid && userImageList?.map((listItem) => listItem.name).includes(user.supabase_uid)) {
        const { data: imageData, error: errorImage } = await supabase.storage
          .from("user-avatars")
          .download(`${state.auth.company_id}/${user.supabase_uid}`, { transform: { quality: 50, width: 300, resize: "contain" } });
        if (errorImage) {
          console.log(errorImage);
        } else {
          if (users && users[index]) users[index].url = URL.createObjectURL(imageData);
        }
      }
    }),
  );

  // sort users by first name
  users = users.sort((a, b) => {
    if (a.first_name && b.first_name) {
      return a.first_name.localeCompare(b.first_name);
    } else {
      return 0;
    }
  });

  // Write to redux
  store.dispatch(setUsers(users));
  return Promise.resolve();
};

const refreshVersions = async (): Promise<void> => {
  const versions = await refreshData<Version>("versions", generateSelectString("versions"));
  store.dispatch(setVersions(versions));
  return Promise.resolve();
};

// ------------- 'processes' Table -------------

const refreshProcesses = async () => {
  const processes = await refreshData<Process>("processes", generateSelectString("processes"), true, true);
  store.dispatch(setProcesses(processes));
  return Promise.resolve();
};

// ------------- 'fields' Table -------------

const refreshFields = async () => {
  const fields = await refreshData<Field>("fields", generateSelectString("fields"));
  store.dispatch(setFields(fields));
  return Promise.resolve();
};

// ------------- 'componentLinks' Table -------------

const refreshComponentLinks = async () => {
  const componentProcessLinks = await refreshData<ComponentLink>("component_links", generateSelectString("component_links"));
  store.dispatch(setComponentLinks(componentProcessLinks));
  return Promise.resolve();
};

// ------------- 'componentProcessLinks' Table -------------

const refreshComponentProcessLinks = async () => {
  const componentProcessLinks = await refreshData<ComponentProcessLink>(
    "component_process_links",
    generateSelectString("component_process_links"),
  );
  store.dispatch(setComponentProcessLinks(componentProcessLinks));
  return Promise.resolve();
};

// ------------- 'datasets' Table -------------

const refreshDatasets = async () => {
  const datasets = await refreshData<Dataset>("datasets", generateSelectString("datasets"));
  store.dispatch(setDatasets(datasets));
  return Promise.resolve();
};

// ------------- 'stations' Table -------------

const refreshStations = async () => {
  const stations = await refreshData<Station>("stations", generateSelectString("stations"));
  store.dispatch(setStations(stations));
  return Promise.resolve();
};

// ------------- 'operators' Table -------------

const refreshOperators = async () => {
  const operators = await refreshData<Operator>("operators", generateSelectString("operators"));
  store.dispatch(setOperators(operators));
  return Promise.resolve();
};

// ------------- 'partNumbers' Table -------------

const refreshPartNumbers = async () => {
  const partNumbers = await refreshData<PartNumber>("part_numbers", generateSelectString("part_numbers"));
  store.dispatch(setPartNumbers(partNumbers));
  return Promise.resolve();
};

// ------------- 'workOrders' Table -------------

const refreshWorkOrders = async () => {
  const workOrders = await refreshData<WorkOrder>("work_orders", generateSelectString("work_orders"));
  store.dispatch(setWorkOrders(workOrders));
  return Promise.resolve();
};

// ------------- 'activityLog' Table -------------

const refreshUniqueIdentifierActivityLogs = async () => {
  const uniqueIdentifierActivityLogs = await refreshData<UniqueIdentifierActivityLog>(
    "unique_identifier_activity_log",
    generateSelectString("unique_identifier_activity_log"),
  );
  store.dispatch(setUniqueIdentifierActivityLog(uniqueIdentifierActivityLogs));
  return Promise.resolve();
};

// ------------- 'reportTemplates' Table -------------

const refreshReportTemplates = async () => {
  const reportTemplates = await refreshData<ReportTemplate>("report_templates", generateSelectString("report_templates"), true, true);
  store.dispatch(setReportTemplates(reportTemplates));
  return Promise.resolve();
};

// ------------- 'componentInstances' Table -------------

const refreshComponentInstances = async () => {
  const componentInstances = await refreshData<UniqueIdentifier>("unique_identifiers", generateSelectString("unique_identifiers"));
  store.dispatch(setComponentInstances(componentInstances));
  return Promise.resolve();
};

// ------------- Function for calling multiple refresh functions in parallel -------------

const batchRefresh = async (refreshFunctions: (() => Promise<void>)[]): Promise<void> => {
  store.dispatch(setIsLoaded(false));

  // Create a list of promises from the array of functions.
  // This .map operation applies the function (fn) to each function in the refreshFunctions array.
  const promises = refreshFunctions.map((fn) =>
    // Each function is invoked with fn(). If the function throws an error,
    // the .catch block will handle the error, preventing it from propagating further.
    fn().catch((e) => console.error("Error in batchRefresh:", e)),
  );

  await Promise.all(promises);

  // Set isLoaded flag
  store.dispatch(setIsLoaded(true));
  return Promise.resolve();
};

// ------------- Refresh all data function -------------

let isRefreshingAllData = false;

const refreshAllData = async (): Promise<void> => {
  if (isRefreshingAllData) return;
  isRefreshingAllData = true;
  // Create an array of all refresh functions
  const allRefreshFunctions = [
    refreshCompany,
    refreshComponents,
    refreshProcesses,
    refreshFields,
    refreshComponentLinks,
    refreshComponentProcessLinks,
    refreshDatasets,
    refreshStations,
    refreshOperators,
    refreshPartNumbers,
    refreshUsers,
    refreshWorkOrders,
    refreshUniqueIdentifierActivityLogs,
    refreshReportTemplates,
    refreshComponentInstances,
    refreshVersions,
  ];

  // Run batchRefresh
  await batchRefresh(allRefreshFunctions);
  isRefreshingAllData = false;
  return Promise.resolve();
};

// ------------- Refresh critical data function -------------

const refreshFunctionMap: { [tableName: string]: () => Promise<void> } = {
  companies: refreshCompany,
  components: refreshComponents,
  processes: refreshProcesses,
  fields: refreshFields,
  component_links: refreshComponentLinks,
  component_process_links: refreshComponentProcessLinks,
  datasets: refreshDatasets,
  stations: refreshStations,
  operators: refreshOperators,
  part_numbers: refreshPartNumbers,
  users: refreshUsers,
  work_orders: refreshWorkOrders,
  activity_logs: refreshUniqueIdentifierActivityLogs,
  report_templates: refreshReportTemplates,
  unique_identifiers: refreshComponentInstances,
  versions: refreshVersions,
};

const pathRefreshFunctionMap: Record<string, (() => Promise<void>)[]> = {
  "/home": [refreshComponentInstances, refreshVersions],
  "/auth": [],
  "/users": [refreshUsers, refreshOperators, refreshVersions],
  "/componentslist": [refreshComponents, refreshVersions],
  "/componentslist/component": [
    refreshComponents,
    refreshComponentInstances,
    refreshComponentProcessLinks,
    refreshComponentLinks,
    refreshProcesses,
    refreshDatasets,
    refreshFields,
    refreshPartNumbers,
    refreshVersions,
  ],
  "/snlookup": [refreshAllData],
  "/gridbuilder": [refreshAllData],
  "/dashboards": [refreshAllData],
  "/workorder": [
    refreshComponents,
    refreshUsers,
    refreshFields,
    refreshComponentProcessLinks,
    refreshComponentLinks,
    refreshProcesses,
    refreshPartNumbers,
    refreshFields,
    refreshWorkOrders,
    refreshUniqueIdentifierActivityLogs,
    refreshVersions,
  ],
  "/stations": [refreshStations, refreshVersions],
  "/production": [refreshAllData],
  "/debug": [refreshAllData],
  "/settings": [refreshCompany, refreshVersions],
  "/datasets": [refreshVersions],
  "/": [refreshAllData],
};

/**
 * Refreshes all critical data for the current path.
 * @param path - The current path. Should be passed using useLocation().pathname
 */
const refreshCriticalData = async (path: string): Promise<void> => {
  const state = store.getState();
  if (!state.db.isLoaded) return;
  store.dispatch(setIsLoaded(false));

  // Make sure the path is valid
  const verifiedPath = Object.keys(pathRefreshFunctionMap).find((knownPaths) => knownPaths.includes(path)) ?? "/";

  // Create an array of functions to refresh each table.
  const refreshFunctions = pathRefreshFunctionMap[verifiedPath];

  // Run batchRefresh
  await batchRefresh(refreshFunctions);
  return Promise.resolve();
};

// Exports

const db = {
  refreshAllData,
  refreshCriticalData,
  refreshCompany,
  refreshComponents,
  refreshProcesses,
  refreshComponentLinks,
  refreshComponentProcessLinks,
  refreshDatasets,
  refreshStations,
  refreshOperators,
  refreshPartNumbers,
  refreshWorkOrders,
  refreshUniqueIdentifierActivityLogs,
  refreshReportTemplates,
  refreshUsers,
  refreshComponentInstances,
  refreshVersions,
  getPropertiesToUpdateOnForeignKeyChange,
  refreshFunctionMap,
};

export default db;
