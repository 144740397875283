import { useMemo } from "react";
import { MeasureGridCellCoordinate, MeasuresGridRowData } from "./types";
import Button from "@shared/components/primitives/Button";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { MeasureAggregationOperator, MeasureValueFile, MeasureValueImage } from "@shared/measures/types";
import { hashMeasureKey } from "@shared/measures/helpers/measureKeys";
import Modal from "@shared/components/primitives/Modal";
import FileViewer from "@shared/components/FileViewer";
import { File, Image } from "@shared/types/databaseTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";

const MeasuresGridPreviewModal = ({
  previewObject,
  setPreviewObject,
  rowData,
}: {
  previewObject: MeasureGridCellCoordinate | null;
  setPreviewObject: React.Dispatch<React.SetStateAction<MeasureGridCellCoordinate | null>>;
  rowData: MeasuresGridRowData[];
}) => {
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const file: File | Image | null = useMemo(() => {
    if (previewObject === null) return null;
    const aggregationOperator = previewObject.measureKey.aggregation_operator ?? MeasureAggregationOperator.Latest;
    if (![MeasureAggregationOperator.First, MeasureAggregationOperator.Latest].includes(aggregationOperator)) {
      console.error("Unsupported aggregation operator for file preview modal", aggregationOperator);
      return null;
    }
    const measureValues =
      rowData[previewObject?.rowIndex].measuresValuesByHash[hashMeasureKey(previewObject.measureKey)].measureValues ?? [];
    const sortedMeasureValues = [...measureValues].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    const displayedMeasureValue = (
      aggregationOperator === MeasureAggregationOperator.Latest
        ? sortedMeasureValues[0]
        : sortedMeasureValues[sortedMeasureValues.length - 1]
    ) as MeasureValueImage | MeasureValueFile;
    return displayedMeasureValue?.data ?? null;
  }, [previewObject, rowData]);

  const dataset = useMemo(() => {
    return datasets.find((dataset) => dataset.id === file?.dataset_id);
  }, [file, datasets]);

  const identifier = useMemo(() => {
    if (previewObject === null) return null;
    return rowData[previewObject?.rowIndex].identifier;
  }, [previewObject, rowData]);

  const handleNextPrev = (direction: "next" | "prev") => {
    if (previewObject === null) return;
    const { rowIndex, measureKey } = previewObject;
    let newRowIndex = direction === "next" ? rowIndex + 1 : rowIndex - 1;
    if (newRowIndex < 0) newRowIndex = rowData.length - 1;
    if (newRowIndex >= rowData.length) newRowIndex = 0;
    setPreviewObject({ rowIndex: newRowIndex, measureKey });
  };

  return (
    <Modal.Root open={previewObject !== null} onOpenChange={(open) => open || setPreviewObject(null)}>
      <Modal.Overlay />
      {previewObject && (
        <Modal.Content className="h-[80vh] max-w-[70vw]">
          <Modal.Header>
            <Modal.Title className="flex gap-2">
              {identifier}
              <span className="text-serial-palette-400 font-normal">{dataset?.name}</span>
            </Modal.Title>
            <Modal.HeaderClose />
          </Modal.Header>
          <Modal.Main className="items-center justify-center p-0">
            {file ? (
              <FileViewer file={file} className="justify-center p-0" />
            ) : (
              <span className="text-serial-palette-400 italic">No File / Image</span>
            )}
          </Modal.Main>
          <Modal.Footer className="flex flex-row justify-between px-4">
            <div className="flex max-w-[70%] items-center gap-2">
              {file && (
                <Button
                  size="sm"
                  className="w-8"
                  onClick={() => fetchDownloadUrl(file.bucket_name, file.file_id ?? file.id, file.company_id, false, true, file.file_name)}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              )}
              <span className="truncate">{file?.file_name}</span>
            </div>
            <div className="flex">
              <Button size="sm" variant="ghost" className="w-6" tooltip="Previous File / Image" onClick={() => handleNextPrev("prev")}>
                <FontAwesomeIcon icon={faCaretLeft} />
              </Button>
              <Button size="sm" variant="ghost" className="w-6" tooltip="Next File / Image" onClick={() => handleNextPrev("next")}>
                <FontAwesomeIcon icon={faCaretRight} />
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      )}
    </Modal.Root>
  );
};

export default MeasuresGridPreviewModal;
