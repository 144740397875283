import { useState, useMemo, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { Component } from "@shared/types/databaseTypes";
import { MeasureKey, MeasureType } from "../types";
import useMeasures from "../MeasuresProvider";
import useMeasureKeysWithReferences from "../hooks/useMeasureKeysWithReferences";
import MeasureKeySelectorAccordionByComponent from "./MeasureKeySelectorAccordionByComponent";
import MeasureKeySelectorAccordionByType from "./MeasureKeySelectorAccordionByType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ButtonGroup from "@shared/components/primitives/ButtonGroup";
import DropdownMenu from "@shared/components/primitives/DropdownMenu";
import Button from "@shared/components/primitives/Button";

enum MeasureKeySelectorGrouping {
  DataType = "DATA_TYPE",
  Component = "COMPONENT",
}

const MeasureKeySelector = ({ onSelect, keyTypes }: { onSelect: (key: MeasureKey) => void; keyTypes?: MeasureType[] }) => {
  const { availableMeasureKeys } = useMeasures();
  const availableMeasureKeysWithRefs = useMeasureKeysWithReferences(availableMeasureKeys);
  const components = useSelector((state: RootState) => state.db.components);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [grouping, setGrouping] = useState<MeasureKeySelectorGrouping>(MeasureKeySelectorGrouping.Component);
  const [showInactiveKeys, setShowInactiveKeys] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  // Filter inactive datasets
  const filteredMeasureKeys = useMemo(() => {
    let tempKeys = [...availableMeasureKeysWithRefs];
    if (!showInactiveKeys) {
      tempKeys = tempKeys.filter((key) => key.isActive === true);
    }
    if (searchTerm !== "") {
      tempKeys = tempKeys.filter((key) => {
        const filterString = `${key.dataset?.name} ${key.component?.name} ${key.process?.name}`;
        return filterString.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }
    if (keyTypes) {
      tempKeys = tempKeys.filter((key) => keyTypes.includes(key.type));
    }
    return tempKeys;
  }, [availableMeasureKeys, showInactiveKeys, searchTerm]);

  // focus on input when component mounts
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="flex w-full flex-col overflow-hidden">
      <div className="flex h-12 w-full shrink-0 items-center gap-2 px-2">
        <div className="flex min-w-0 flex-grow items-center pl-2">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-serial-palette-400" />
          <input
            ref={inputRef}
            className="form-input w-full border-none"
            placeholder="Search keys..."
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm || ""}
          />
        </div>
        <ButtonGroup.Root size="xs">
          <ButtonGroup.Button
            className="h-8"
            variant={grouping === MeasureKeySelectorGrouping.DataType ? "default" : "outline"}
            onClick={() => setGrouping(MeasureKeySelectorGrouping.DataType)}
          >
            Data Type
          </ButtonGroup.Button>
          <ButtonGroup.Button
            className="h-8"
            variant={grouping === MeasureKeySelectorGrouping.Component ? "default" : "outline"}
            onClick={() => setGrouping(MeasureKeySelectorGrouping.Component)}
          >
            Component
          </ButtonGroup.Button>
        </ButtonGroup.Root>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <Button className="h-8 w-8">
              <FontAwesomeIcon icon={faEllipsis} size="lg" />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className="w-36" align="end">
            <DropdownMenu.CheckboxItem checked={showInactiveKeys} onCheckedChange={setShowInactiveKeys}>
              Show Inactive
            </DropdownMenu.CheckboxItem>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
      <div className="flex max-h-[50vh] min-h-0 flex-grow flex-col gap-2 overflow-y-auto border-t p-2">
        {/* Group by grid builder key */}
        {grouping === MeasureKeySelectorGrouping.DataType &&
          Object.values(MeasureType).map((keyType: MeasureType, index: number) => {
            const measureKeys = filteredMeasureKeys
              .filter((measureKey) => measureKey.type === keyType)
              .sort((a, b) => {
                if (a.component?.name === b.component?.name) {
                  if (a.processOrder === b.processOrder) return 0;
                  if (a.processOrder === null || a.processOrder === undefined) return 1;
                  if (b.processOrder === null || b.processOrder === undefined) return -1;
                  return a.processOrder - b.processOrder;
                }
                return (a.componentOrder ?? 0) < (b.componentOrder ?? 0) ? -1 : 1;
              });
            // if there are no data for this data type, don't show it
            if (measureKeys.length === 0) return null;
            return (
              <MeasureKeySelectorAccordionByType
                key={index}
                forceOpen={(searchTerm.trim() ?? "").length > 0}
                measureKeys={measureKeys}
                measureType={keyType}
                onSelect={onSelect}
              />
            );
          })}
        {/* Group by Component */}
        {grouping === MeasureKeySelectorGrouping.Component &&
          components.map((component: Component, index: number) => {
            const measureKeys = filteredMeasureKeys
              .filter((measureKey) => measureKey.component?.id === component.id)
              .sort((a, b) => {
                if (a.processOrder === b.processOrder) {
                  if (a.dataset?.name === b.dataset?.name) return 0;
                  if (!a.dataset?.name) return 1;
                  if (!b.dataset?.name) return -1;
                  return a.dataset?.name < b.dataset?.name ? -1 : 1;
                }
                if (a.processOrder === null || a.processOrder === undefined) return 1;
                if (b.processOrder === null || b.processOrder === undefined) return -1;
                return a.processOrder - b.processOrder;
              });
            // if there are no data for this component, don't show it
            if (measureKeys.length === 0) return null;
            return (
              <MeasureKeySelectorAccordionByComponent
                key={index}
                forceOpen={(searchTerm.trim() ?? "").length > 0}
                component={component}
                measureKeys={measureKeys}
                onSelect={onSelect}
              />
            );
          })}
        {filteredMeasureKeys.length === 0 && (
          <div className="flex h-full min-h-[200px] w-full items-center justify-center">
            <p className="text-serial-palette-400 text-center">No keys found.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeasureKeySelector;
