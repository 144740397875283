import { cn } from "@shared/utils/tailwind";
import React from "react";

const MeasuresGraphConfigSectionRoot = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={cn("border-serial-palette-200 border-b-1 flex w-full flex-col gap-2.5 px-3 py-4", className)}>
        {children}
      </div>
    );
  },
);

const MeasuresGraphConfigSectionTitle = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={cn("text-sm font-bold", className)}>
        {children}
      </div>
    );
  },
);

const MeasuresGraphConfigSectionOption = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={cn("flex w-full flex-col justify-between gap-0.5", className)}>
        {children}
      </div>
    );
  },
);

const MeasuresGraphConfigSectionLabel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={cn("font-regular text-sm", className)}>
        {children}
      </div>
    );
  },
);

const MeasuresGraphConfigSection = Object.assign(MeasuresGraphConfigSectionRoot, {
  Root: MeasuresGraphConfigSectionRoot,
  Title: MeasuresGraphConfigSectionTitle,
  Option: MeasuresGraphConfigSectionOption,
  Label: MeasuresGraphConfigSectionLabel,
});

export default MeasuresGraphConfigSection;
