import React, { useContext, useEffect, useRef, useState } from "react";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import { ImageMarkupElement, ImageMarkupElementType } from "@shared/types/databaseTypes";
import ImageMarkupElementText from "./ImageMarkupElementText";
import ImageMarkupElementRectangle from "./ImageMarkupElementRectangle";
import ImageMarkupElementCircle from "./ImageMarkupElementCircle";
import ImageMarkupElementArrow from "./ImageMarkupElementArrow";
import ImageMarkupElementMagnify from "./ImageMarkupElementMagnify";
import ImageMarkupElementFocus from "./ImageMarkupElementFocus";

interface ResizeCorner {
  bottom: boolean;
  right: boolean;
}

interface Coordinate {
  x: number;
  y: number;
}

export interface ImageMarkupLabelElementProps<T> {
  element: T;
  index: number;
}

const ImageMarkupElementContainer: React.FC<ImageMarkupLabelElementProps<ImageMarkupElement>> = ({ index, element }) => {
  const {
    editing,
    focusedElementIndex,
    setFocusedElementIndex,
    imageHeight,
    imageWidth,
    imageAspectRatio,
    handleEditElement,
    handleDeleteElement,
    focusedElementConfig,
  } = useContext(ImageMarkupContext);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [tempPosition, setTempPosition] = useState<Coordinate | null>(null);

  const elementRef = useRef<HTMLDivElement>(null);
  const mouseClickPositionRef = useRef<Coordinate>({ x: 0, y: 0 });
  const resizeCornerRef = useRef<ResizeCorner | null>(null);

  const handleDragMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!editing) return;
    setFocusedElementIndex(index);
    mouseClickPositionRef.current = { x: e.clientX, y: e.clientY };
    setIsDragging(true);
    setTempPosition({
      x: element.x ?? 0,
      y: element.y ?? 0,
    });
    window.addEventListener("mousemove", handleDragMouseMove);
    window.addEventListener("mouseup", handleDragMouseUp);
  };

  const calculateNewPosition = (e: MouseEvent): Coordinate => {
    const dx = e.clientX - mouseClickPositionRef.current.x;
    const dy = e.clientY - mouseClickPositionRef.current.y;
    return {
      x: (element.x ?? 0) + dx / imageWidth,
      y: (element.y ?? 0) + dy / imageHeight,
    };
  };

  const handleDragMouseMove = (e: MouseEvent) => {
    setTempPosition(calculateNewPosition(e));
  };

  const handleDragMouseUp = (e: MouseEvent) => {
    if (!editing) return;
    setIsDragging(false);
    window.removeEventListener("mousemove", handleDragMouseMove);
    window.removeEventListener("mouseup", handleDragMouseUp);
    const finalPosition = calculateNewPosition(e);
    handleEditElement(index, { x: finalPosition.x, y: finalPosition.y });
    setTempPosition(null);
  };

  const handleResizeMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, resizeCorner: ResizeCorner) => {
    if (!editing) return;
    e.stopPropagation(); // prevent the element from also being dragged while resizing
    mouseClickPositionRef.current = { x: e.clientX, y: e.clientY };
    resizeCornerRef.current = resizeCorner;
    window.addEventListener("mousemove", handleResizeMouseMove);
    window.addEventListener("mouseup", handleResizeMouseUp);
  };

  const handleResizeMouseMove = (e: MouseEvent) => {
    if (!editing) return;
    const updatedTempPosition: Coordinate = {
      x: tempPosition?.x ?? element.x ?? 0,
      y: tempPosition?.y ?? element.y ?? 0,
    };
    // Width
    const dx = e.clientX - mouseClickPositionRef.current.x;
    const newWidth = (element.width ?? 0) + (resizeCornerRef.current?.right ? dx : -dx) / imageWidth;
    handleEditElement(index, { width: newWidth });
    updatedTempPosition.x = (element.x ?? 0) + (resizeCornerRef.current?.right ? 0 : dx / imageWidth);
    // Height
    const dy = e.clientY - mouseClickPositionRef.current.y;
    const newHeight = (element.height ?? 0) + (resizeCornerRef.current?.bottom ? dy : -dy) / imageHeight;
    handleEditElement(index, { height: e.shiftKey ? newWidth * imageAspectRatio : newHeight }); // if shift is pressed, enforce square aspect ratio
    updatedTempPosition.y = (element.y ?? 0) + (resizeCornerRef.current?.bottom ? 0 : dy / imageHeight);

    setTempPosition(updatedTempPosition);
  };

  // delete the element if it's focused and the delete key is pressed
  useEffect(() => {
    const handleDelete = (e: KeyboardEvent) => {
      if (focusedElementIndex === index && (e.key === "Delete" || e.key === "Backspace")) {
        handleDeleteElement(index);
      }
    };
    window.addEventListener("keydown", handleDelete);
    return () => window.removeEventListener("keydown", handleDelete);
  }, [focusedElementIndex, index, handleDeleteElement]);

  const handleResizeMouseUp = (e: MouseEvent) => {
    if (!editing) return;
    window.removeEventListener("mousemove", handleResizeMouseMove);
    window.removeEventListener("mouseup", handleResizeMouseUp);
    const dx = e.clientX - mouseClickPositionRef.current.x;
    const dy = e.clientY - mouseClickPositionRef.current.y;
    const finalPosition = {
      x: element.x ?? 0,
      y: element.y ?? 0,
    };
    if ("width" in element) {
      finalPosition.x += resizeCornerRef.current?.right ? 0 : dx / imageWidth;
    }
    if ("height" in element || "font_size" in element) {
      finalPosition.y += resizeCornerRef.current?.bottom ? 0 : dy / imageHeight;
    }
    handleEditElement(index, { x: finalPosition.x, y: finalPosition.y });
    setTempPosition(null);
    resizeCornerRef.current = null;
  };

  return (
    <div
      ref={elementRef}
      className={`group/handles flex flex-col items-center justify-center ${focusedElementConfig?.showBoundingBoxOnFocus && (focusedElementIndex === index || isDragging) && "outline outline-sky-500"} outline-1 `}
      style={{
        position: "absolute",
        top: ((tempPosition !== null ? tempPosition.y : (element.y ?? 0)) + (element.height < 0 ? element.height : 0)) * imageHeight,
        left: ((tempPosition !== null ? tempPosition.x : (element.x ?? 0)) + (element.width < 0 ? element.width : 0)) * imageWidth,
        cursor: isDragging ? "grabbing" : editing ? "grab" : "default",
      }}
      onMouseDown={(e) => handleDragMouseDown(e)}
      onClick={() => editing && setFocusedElementIndex(index)}
    >
      {element.type === ImageMarkupElementType.Text && <ImageMarkupElementText element={element} index={index} />}
      {element.type === ImageMarkupElementType.Rectangle && <ImageMarkupElementRectangle element={element} index={index} />}
      {element.type === ImageMarkupElementType.Circle && <ImageMarkupElementCircle element={element} index={index} />}
      {element.type === ImageMarkupElementType.Arrow && <ImageMarkupElementArrow element={element} index={index} />}
      {element.type === ImageMarkupElementType.Magnify && <ImageMarkupElementMagnify element={element} index={index} />}
      {element.type === ImageMarkupElementType.Focus && <ImageMarkupElementFocus element={element} index={index} />}
      {editing && (
        <>
          <div
            onMouseDown={(e) => handleResizeMouseDown(e, { bottom: false, right: false })}
            className={`${focusedElementIndex === index || isDragging ? "" : "hidden"} absolute -left-1 -top-1 z-20 h-2 w-2 cursor-nw-resize items-center justify-center bg-sky-500 p-0 group-hover/handles:flex`}
          />
          <div
            onMouseDown={(e) => handleResizeMouseDown(e, { bottom: false, right: true })}
            className={`${focusedElementIndex === index || isDragging ? "" : "hidden"} absolute -right-1 -top-1 z-20 h-2 w-2 cursor-ne-resize items-center justify-center bg-sky-500 p-0 group-hover/handles:flex`}
          />
          <div
            onMouseDown={(e) => handleResizeMouseDown(e, { bottom: true, right: true })}
            className={`${focusedElementIndex === index || isDragging ? "" : "hidden"} absolute -bottom-1 -right-1 z-20 h-2 w-2 cursor-se-resize items-center justify-center bg-sky-500 p-0 group-hover/handles:flex`}
          />
          <div
            onMouseDown={(e) => handleResizeMouseDown(e, { bottom: true, right: false })}
            className={`${focusedElementIndex === index || isDragging ? "" : "hidden"} absolute -bottom-1 -left-1 z-20 h-2 w-2 cursor-sw-resize items-center justify-center bg-sky-500 p-0 group-hover/handles:flex`}
          />
        </>
      )}
    </div>
  );
};

export default ImageMarkupElementContainer;
