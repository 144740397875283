import React, { useContext, useEffect, useState } from "react";
import { UserRolePrettyNames } from "@shared/constants/serial";
import { validateNewUser, validateUpdateUser } from "@shared/connections/supabaseUsersValidation";
import { updateUser, inviteUser } from "@shared/connections/api/users";
import { createOperatorBasedOnUser, updateOperatorBasedOnUser } from "@shared/connections/supabaseUsers";
import { ToastContext } from "@shared/context/ToastProvider";
import Modal from "@shared/components/primitives/Modal";
import Select from "@shared/components/primitives/Select";
import Tooltip from "@shared/components/primitives/Tooltip";
import db from "@shared/connections/supabaseReduxDatabase";
import { handleForgotPassword } from "@shared/connections/supabaseAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { TextInput } from "@shared/components/primitives/Input";
import Switch from "@shared/components/primitives/Switch";
import Button from "@shared/components/primitives/Button";
import { User } from "@shared/types/databaseTypes";
import { ToastType } from "@shared/components/Toast";
import { UserRole } from "@shared/types/databaseEnums";
import Banner from "@shared/components/primitives/Banner";

interface UserModalProps {
  title: string;
  isNewUser: boolean;
  userData: User;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}

const UserModal: React.FC<UserModalProps> = ({ title, isNewUser, userData, modalOpen, setModalOpen }) => {
  const observe = useContext(ObservabilityContext);
  const toastContext = useContext(ToastContext);

  const [tempUserData, setTempUserData] = useState<User>(userData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ isValid: boolean; messageType: "error" | "warning" | "info"; message: string } | null>(null);
  const [resetPasswordIsLoading, setResetPasswordIsLoading] = useState(false);

  useEffect(() => {
    setTempUserData(userData);
    setError(null);
  }, [modalOpen]);

  const handleResetPassword = async () => {
    setResetPasswordIsLoading(true);
    const error = await handleForgotPassword(tempUserData?.email);
    if (error) {
      setError({ isValid: false, messageType: "error", message: error.message });
      toastContext.triggerToast(ToastType.Error, "Error", error.message);
    } else {
      toastContext.triggerToast(ToastType.Success, "Password reset initiated", `Password reset email sent to ${tempUserData?.email}`);
    }
    setResetPasswordIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);

    const validation = isNewUser ? await validateNewUser(tempUserData) : await validateUpdateUser(tempUserData);

    if (validation.isValid) {
      const changedUser = isNewUser ? await inviteUser(tempUserData) : await updateUser(tempUserData);
      if (changedUser == null) {
        setError({ isValid: false, messageType: "error", message: "An error has occurred, contact Serial support" });
      } else {
        const updateOperator = isNewUser ? createOperatorBasedOnUser : updateOperatorBasedOnUser;
        await updateOperator(changedUser);
        setModalOpen(false);
        observe.track(isNewUser ? "user_create" : "user_edit");
      }
    } else {
      setError(validation as { isValid: boolean; messageType: "error" | "warning" | "info"; message: string });
    }
    db.refreshUsers();
    setIsLoading(false);
  };

  return (
    <Modal open={modalOpen} onOpenChange={setModalOpen}>
      <Modal.Content className="w-full max-w-lg overflow-auto rounded border bg-white">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Modal.HeaderClose onClick={() => setModalOpen(false)} />
        </Modal.Header>
        <Modal.Main className="gap-y-3 py-5">
          {error && <Banner variant={error.messageType}> {error.message}</Banner>}
          <div className="grid w-full grid-cols-2 gap-x-4">
            <div>
              <label className="mb-1 block text-sm font-medium" htmlFor="first_name">
                First Name <span className="text-rose-500">*</span>
              </label>
              <TextInput
                value={tempUserData?.first_name ?? ""}
                onValueChange={(value) => setTempUserData({ ...tempUserData, first_name: value })}
                placeholder="Jo"
                required
              />
            </div>
            <div>
              <label className="mb-1 block text-sm font-medium" htmlFor="last_name">
                Last Name <span className="text-rose-500">*</span>
              </label>
              <TextInput
                value={tempUserData?.last_name ?? ""}
                onValueChange={(value) => setTempUserData({ ...tempUserData, last_name: value })}
                placeholder="Smith"
                required
              />
            </div>
          </div>
          <div>
            <label className="mb-1 block text-sm font-medium" htmlFor="email">
              Email <span className="text-rose-500">*</span>
            </label>
            {isNewUser ? (
              <TextInput
                name="email"
                value={tempUserData?.email}
                onValueChange={(value) => setTempUserData({ ...tempUserData, email: value.trim() })}
                placeholder="jsmith@company.com"
                required
              />
            ) : (
              <TextInput name="email" value={tempUserData?.email} disabled className="bg-serial-palette-50 text-serial-palette-400" />
            )}
          </div>
          <div>
            <div className="flex flex-col">
              <label className="mb-1 flex items-center text-sm font-medium">
                User Type <span className="pl-1 pr-2 text-rose-500">*</span>
                <Tooltip.Root>
                  <Tooltip.Trigger tabIndex={-1}>
                    <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
                  </Tooltip.Trigger>
                  <Tooltip.Content variant="light">
                    <a
                      href="https://docs.serial.io/setup/users"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700"
                    >
                      Read more about user roles
                    </a>
                  </Tooltip.Content>
                </Tooltip.Root>
              </label>
              <Select.Root
                value={tempUserData?.role}
                onValueChange={(value) => setTempUserData({ ...tempUserData, role: value as UserRole })}
              >
                <Select.Trigger className="px-3">
                  <Select.Value placeholder="-" />
                </Select.Trigger>
                <Select.Content>
                  {UserRolePrettyNames.map((role) => {
                    if (role.id !== "DEMO") {
                      return (
                        <Select.Item key={role.id} value={role.id}>
                          {role.pretty_name}
                        </Select.Item>
                      );
                    }
                    return null;
                  })}
                </Select.Content>
              </Select.Root>
            </div>
          </div>
          <div>
            <label className="mb-1 block text-sm font-medium" htmlFor="title">
              Title <span className="text-rose-500"></span>
            </label>
            <TextInput
              name="title"
              value={tempUserData?.title ?? ""}
              onValueChange={(value) => setTempUserData({ ...tempUserData, title: value })}
              placeholder="Manufacturing Engineer"
              required
            />
          </div>
          <div>
            <label className="mb-1 block text-sm font-medium" htmlFor="role">
              Account Status <span className="text-rose-500">*</span>
            </label>
            <div className="flex items-center">
              <Switch
                checked={tempUserData.is_active ?? true}
                onCheckedChange={(checked) => setTempUserData({ ...tempUserData, is_active: checked })}
              />
              <div className="text-serial-palette-400 ml-2 text-sm italic">{tempUserData.is_active ? "Active" : "Inactive"}</div>
            </div>
          </div>
        </Modal.Main>
        <Modal.Footer>
          <div className="flex gap-x-2">
            <Button size="sm" variant="outline" type="button" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button isLoading={isLoading} size="sm" onClick={handleSave}>
              Save
            </Button>
          </div>
          <div className="flex gap-x-2">
            {!isNewUser && (
              <Button size="sm" isLoading={resetPasswordIsLoading} onClick={handleResetPassword}>
                Reset Password
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default UserModal;
