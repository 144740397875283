import ComponentInstanceStatusNode from "./ComponentInstanceStatusNode";
import ComponentNode from "./ComponentNode";
import ParentNode from "./ParentNode";
import ProcessNode from "./ProcessNode";

export enum NodeType {
  Process = "PROCESS",
  Component = "COMPONENT",
  ComponentInstanceStatus = "STATUS",
  Parent = "PARENT",
}

export const nodeTypes = {
  [NodeType.Process]: ProcessNode,
  [NodeType.Component]: ComponentNode,
  [NodeType.ComponentInstanceStatus]: ComponentInstanceStatusNode,
  [NodeType.Parent]: ParentNode,
};

export const COLLAPSED_NODE_PROCESS_COUNT_THRESHOLD = 6;

// ComponentInstanceStatusNode
export const COMPONENT_INSTANCE_STATUS_WIDTH = 80;
export const COMPONENT_INSTANCE_STATUS_HEIGHT = 16;
export const COMPONENT_INSTANCE_STATUS_WIDTH_CLASS_NAME = "w-[80px]";
export const COMPONENT_INSTANCE_STATUS_HEIGHT_CLASS_NAME = "h-[16px]";
export const COMPONENT_INSTANCE_STATUS_PITCH_Y = 50;
export const DESCENDENT_COMPONENT_INSTANCE_STATUS_PITCH_Y = 40;

// ProcessNode
export const PROCESS_NODE_PITCH_Y = 110;
export const DESCENDENT_PROCESS_NODE_PITCH_Y = 80;
export const PROCESS_NODE_WIDTH = 320;
export const PROCESS_NODE_HEIGHT = 56;
export const PROCESS_NODE_WIDTH_CLASS_NAME = "w-[320px]";
export const PROCESS_NODE_HEIGHT_CLASS_NAME = "h-[56px]";

// ComponentNode
export const COMPONENT_BODY_PADDING = 10;
export const COMPONENT_BODY_PADDING_TOP = 15;
export const COMPONENT_NODE_HEADER_HEIGHT = 52;
export const COMPONENT_NODE_HEADER_HEIGHT_CLASS_NAME = "h-[52px]";
export const COMPONENT_NODE_PITCH_X = 500;
export const COMPONENT_NODE_PITCH_Y = 30;
export const COMPONENT_NODE_WIDTH = PROCESS_NODE_WIDTH + COMPONENT_BODY_PADDING * 2;

// ParentNode
export const PARENT_NODE_PITCH_Y = 70;
export const PARENT_NODE_WIDTH = 250;
export const PARENT_NODE_HEIGHT = 52;
