import { DataType } from "@shared/types/databaseEnums";
import { MeasureType } from "../types";

export const datasetTypeToMeasureType = {
  [DataType.Uid]: MeasureType.Identifier,
  [DataType.Checkbox]: MeasureType.Checkbox,
  [DataType.File]: MeasureType.File,
  [DataType.Image]: MeasureType.Image,
  [DataType.Link]: MeasureType.Link,
  [DataType.ParametricQualitative]: MeasureType.ParametricQualitative,
  [DataType.ParametricQuantitative]: MeasureType.ParametricQuantitative,
  [DataType.PassFail]: MeasureType.PassFail,
  [DataType.Datetime]: MeasureType.Datetime,
};
