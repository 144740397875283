import React, { useMemo } from "react";
import { HeadingBlock } from "@shared/types/databaseTypes";
import { ProductionWorkInstructionBlockContentProps } from "../../types";

const ProductionWorkInstructionBlockHeading: React.FC<ProductionWorkInstructionBlockContentProps<HeadingBlock>> = ({ block }) => {
  const sizeClass = useMemo(() => {
    switch (block.content.level) {
      case 1:
        return "text-3xl";
      case 2:
        return "text-2xl";
      case 3:
        return "text-xl";
    }
  }, [block.content.level]);

  return (
    <div className="flex w-full">
      <div className={`w-full font-bold ${sizeClass}`}>{block.content.text}</div>
    </div>
  );
};

export default ProductionWorkInstructionBlockHeading;
