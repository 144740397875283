import { DateRange } from "react-day-picker";
import { ToastType } from "@shared/components/Toast";
import { DashboardInsightType } from "@shared/types/databaseEnums";
import { ReportTemplateCustomInsight, ReportTemplateCustomInsightConfig, ReportTemplateDashboard } from "@shared/types/databaseTypes";
import { WorkOrder } from "@shared/types/databaseTypes";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { ChartDataset } from "chart.js";

export interface DashboardToast {
  message: string;
  type: ToastType;
  title: string;
  open: boolean;
}

export interface InsightSelectionOption {
  type: DashboardInsightType;
  defaultProperties: DashboardInsightDefaultProperties;
  config?: any;
}

export interface DashboardFilters {
  componentId: string | null;
  dateRange: DateRange | undefined;
  selectedProcessId?: string | null;
  filteredDataSize?: number;
  filteredComponentInstanceIds?: string[];
  workOrderId?: string | null;
}

export interface DashboardContextProps {
  dashboards: ReportTemplateDashboard[];
  customInsights: ReportTemplateCustomInsight[];
  selectedDashboardId: string | null;
  insightModalOpen: boolean;
  dashboardEditModalOpen: boolean;
  targetDashboardRow: number | null;
  isNewDashboard: boolean;
  setSelectedDashboardId: (dashboardId: string | null) => void;
  setInsightModalOpen: (insightModalOpen: boolean) => void;
  setDashboardEditModalOpen: (dashboardEditModalOpen: boolean) => void;
  setTargetDashboardRow: (rowIndex: number | null) => void;
  handleCreateDashboard: () => Promise<void>;
  handleDuplicateDashboard: (dashboardId: string) => Promise<void>;
  handleUpdateDashboard: (updatedDashboard: ReportTemplateDashboard) => Promise<void>;
  handleDeleteDashboard: (dashboardId: string) => Promise<void>;
  handleAddInsightToSelectedDashboard: (newInsightType: DashboardInsightType, config: any) => Promise<void>;
  handleRemoveInsightFromSelectedDashboard: (rowIndex: number, insightIndex: number) => Promise<void>;
  handleCreateCustomInsight: () => void;
  handleUpdateCustomInsight: (updatedInsight: ReportTemplateCustomInsightConfig) => void;
  handleDeleteCustomInsight: (insightId: string) => void;
}

export type DashboardInsightWidth = "sm" | "md" | "lg" | "xl";

export interface DashboardInsightDefaultProperties {
  title: string;
  description?: string;
  subtitle?: string;
  defaultWidth: DashboardInsightWidth;
  minWidth: DashboardInsightWidth;
  allowFullscreen: boolean;
  body: React.FC<any>;
  configSchema: DashboardInsightConfigProperty[];
}

export enum DashboardInsightConfigPropertyType {
  Select = "SELECT",
  MultiSelect = "MULTI_SELECT",
  SelectFromTable = "SELECT_FROM_TABLE",
  MultiSelectFromTable = "MULTI_SELECT_FROM_TABLE",
  Number = "NUMBER",
  Text = "TEXT",
  Boolean = "BOOLEAN",
}

export interface DashboardInsightConfigProperty {
  type: DashboardInsightConfigPropertyType;
  tableRef?: string; // Only used for SELECT_FROM_TABLE and MULTI_SELECT_FROM_TABLE types. Example: type="SELECT" and tableRef="components" would indicate the user needs to choose 1 component from the components table
  options?: Record<string, string>; // Only used for SELECT and MULTI_SELECT types. Example: type="SELECT" and options={"option1": "Option 1", "option2": "Option 2"} would indicate the user needs to choose 1 option from the list of options
  label: string;
  key: string;
  defaultValue?: any;
}

export interface YieldByTimeRPC {
  dates: string[];
  first_pass_yield: number[];
  all_pass_yield: number[];
  total_parts_produced: number;
  average_all_pass_yield: number;
  average_first_pass_yield: number;
  days_of_production: number;
}

export interface CycleTimeByDate {
  dates: string[];
  cycle_times: number[];
  total_entries: number[];
  num_unique_ids: number[];
  retest_rates: number[];
}

export interface ProcessCycleTimeByDate {
  [processName: string]: CycleTimeByDate;
}

export interface ChartCycleTimeMetaData extends ChartDataset<"line"> {
  total_entries: number[];
  retest_rates: number[];
}

export type WorkOrderWithCounts = WorkOrder & {
  counts_by_status: CountsByStatus;
};

export interface CountsByStatus {
  [UniqueIdentifierStatus.Wip]: number;
  [UniqueIdentifierStatus.Complete]: number;
  [UniqueIdentifierStatus.Defective]: number;
  [UniqueIdentifierStatus.Planned]: number;
}
