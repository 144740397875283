import { Operator, Process, FieldType, ProcessEntry, ProcessStepWithReferences, Station } from "@shared/types/databaseTypes";
import { DraftSubmissionData } from "../types";
import { fetchComponentInstancesByIdentifiers } from "./supabase";
import { addProcessEntryData, createComponentInstanceLink, createProcessEntry, updateProcessEntry } from "./api";
import { ParsedResponse, ProcessDataType } from "@shared/types/apiTypes";

export const uploadProcessEntryAndData = async (
  process: Process,
  filteredSteps: ProcessStepWithReferences[],
  station: Station | null,
  operator: Operator,
  draftSubmissionData: DraftSubmissionData,
  identifiers: string[],
  startTimestamp: string,
  onProgress: (progressPercent: number) => void,
): Promise<{
  error?: string;
  processEntries?: ProcessEntry[];
  failedDatasets?: { datasetId: string; value: string | number | boolean | undefined }[];
}> => {
  const allFields = filteredSteps.flatMap((step) => step.fields);
  const numRequests =
    1 + // fetching component instances
    identifiers.length + // creating process entries
    identifiers.length * allFields.length; // adding data
  +identifiers.length; // updating process entries
  let currRequestNumber = 0;
  onProgress(0);

  // Get all component instances
  const { data: componentInstances, error: componentInstancesError } = await fetchComponentInstancesByIdentifiers(identifiers);
  if (componentInstancesError || componentInstances?.length !== identifiers.length) {
    console.error("error fetching component instances: ", componentInstancesError, componentInstances);
    return { error: "Component instances not found for all identifiers." };
  }
  currRequestNumber += 1;
  onProgress((currRequestNumber / numRequests) * 100);

  const processEntries: ProcessEntry[] = [];
  const failedDatasets: { datasetId: string; value: string | number | boolean | undefined }[] = [];
  for (const componentInstance of componentInstances) {
    // Create process entry
    const { data: processEntry, error: processEntryError } = await createProcessEntry(
      componentInstance.id,
      process.id,
      undefined, // allow timestamp to be set by the database
      station?.id,
      operator.id,
    );
    if (processEntryError || !processEntry?.id) {
      console.error("error creating process entry: ", processEntryError, processEntry);
      console.error("Debug 1 (try to check why createProcessEntry failed):", "process: ", process);
      console.error("Debug 2 (try to check why createProcessEntry failed):", "componentInstance: ", componentInstance);
      console.error("Debug 3 (try to check why createProcessEntry failed):", "station: ", station);
      console.error("Debug 4 (try to check why createProcessEntry failed):", "operator: ", operator);
      return { error: "Error creating process entry submission." };
    }
    currRequestNumber += 1;
    onProgress((currRequestNumber / numRequests) * 100);

    let alreadyBrokeLinks: boolean = false;

    // Add data to process entry
    for (const fieldId of Object.keys(draftSubmissionData)) {
      const field = allFields.find((field) => field.id === fieldId);
      if (!field) {
        console.error("field not found: ", fieldId, "process: ", process, "draftSubmissionData: ", draftSubmissionData);
        return { error: "Error finding necessary information to create submission." };
      }
      const fieldData = draftSubmissionData[fieldId];
      if (!fieldData && field.type !== FieldType.Checkbox) continue; // this will be the case for fields that are not required and have no data
      let response: ParsedResponse;
      switch (field.type) {
        case FieldType.Label:
        case FieldType.Checkbox:
          const stringifiedBoolean = fieldData.value === true ? "true" : "false";
          response = await addProcessEntryData(processEntry.id, ProcessDataType.Boolean, field.dataset_id, {
            value: stringifiedBoolean,
            expectedValue: field.dataset?.expected_value,
          });
          break;
        case FieldType.ManualEntry:
          response = await addProcessEntryData(
            processEntry.id,
            field.dataset?.data_type === "PARAMETRIC_QUALITATIVE" ? ProcessDataType.Text : ProcessDataType.Numerical,
            field.dataset_id,
            {
              value: String(fieldData.value),
            },
          );
          break;
        case FieldType.Link:
          const { data: linkedComponentInstance, error: linkedComponentInstanceError } = await fetchComponentInstancesByIdentifiers([
            fieldData.linkedIdentifier ?? "",
          ]);
          if (linkedComponentInstanceError || !linkedComponentInstance?.[0]?.id) {
            console.error("error fetching linked component instance: ", linkedComponentInstanceError, linkedComponentInstance);
            return { error: "Linked component instance not found." };
          }
          response = await createComponentInstanceLink(
            componentInstance.id,
            linkedComponentInstance[0].id,
            processEntry.id,
            field.dataset_id,
            process.break_prior_links === true && alreadyBrokeLinks === false, // only break prior links if this is the first field with data. This is to prevent inadvertently breaking links when there are multiple link fields
          );
          if (response.error) {
            console.error("error creating component instance link: ", response.error, response.data);
            return { error: `Error creating component instance link: ${response.error}` };
          }
          if (process.break_prior_links === true) alreadyBrokeLinks = true;
          break;
        case FieldType.PassFail:
          response = await addProcessEntryData(processEntry.id, ProcessDataType.Text, field.dataset_id, {
            value: fieldData.value === true ? "PASS" : "FAIL",
            expectedValue: "PASS",
          });
          break;
        case FieldType.File:
          if (!fieldData.fileInfo?.name) {
            console.error("file name not found: ", fieldData.fileInfo, fieldData.fileId);
            return { error: "File name not found." };
          }
          response = await addProcessEntryData(processEntry.id, ProcessDataType.File, field.dataset_id, {
            fileId: fieldData.fileId,
            fileName: fieldData.fileInfo?.name ?? "file",
          });
          break;
        case FieldType.Image:
        case FieldType.Signature:
          if (!fieldData.fileInfo?.name) {
            console.error("file name not found: ", fieldData.fileInfo, fieldData.fileId);
            return { error: "File name not found." };
          }
          response = await addProcessEntryData(processEntry.id, ProcessDataType.Image, field.dataset_id, {
            fileId: fieldData.fileId,
            fileName: fieldData.fileInfo?.name ?? "image",
          });
          break;
        case FieldType.Datetime:
          response = await addProcessEntryData(processEntry.id, ProcessDataType.Datetime, field.dataset_id, {
            value: fieldData.value?.toString(),
          });
          break;
        default:
          console.error("unknown field type: ", field);
          response = { data: undefined, error: "An unknown field type was found, could not upload data." };
          break;
      }
      if (response.error) {
        console.error("error adding process entry data: ", response.error, response.data);
        return { error: `Error adding process entry data: ${response.error}` };
      }
      // Add failed datasets to list only on the first component instance (they should all be identical)
      if (componentInstance.id === componentInstances[0]?.id && (response.data?.is_pass === false || response.data?.value === "FAIL")) {
        failedDatasets.push({ datasetId: field.dataset_id, value: response.data?.value });
      }
      currRequestNumber += 1;
      onProgress((currRequestNumber / numRequests) * 100);
    }

    // Update process entry
    const cycleTime = (new Date().getTime() - new Date(startTimestamp).getTime()) / 1000; // convert to seconds
    const { data: completedProcessEntry, error: completedProcessEntryError } = await updateProcessEntry(processEntry.id, true, cycleTime);
    if (completedProcessEntryError || !completedProcessEntry?.id) {
      console.error("error updating process entry: ", completedProcessEntryError, completedProcessEntry);
      return { error: "Error updating process entry." };
    }
    currRequestNumber += 1;
    onProgress((currRequestNumber / numRequests) * 100);
    processEntries.push(completedProcessEntry);
  }
  onProgress(100);
  return { processEntries, failedDatasets };
};
