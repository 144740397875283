import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";

// ------------------- VALIDATION FUNCTIONS ------------------- //

// Regex to validate email format
// This regex checks for the following pattern:
//    1) One or more alphanumeric characters, periods, underscores, percent signs, plus signs, or hyphens
//    2) An at sign (@)
//    3) One or more alphanumeric characters, periods, or hyphens
//    4) A period (.)
//    5) Two or more alphabetic characters

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

// Function to handle validation of users. Returns and object with {isValid: bool, message: string}. If isValid is true but there's a non null message then the message is a warning
export const validateNewUser = async (user) => {
  // --- CHECK 1 --- : Check if first_name is not empty
  if (user.first_name === "" || user.first_name == null || user.first_name == undefined) {
    return { isValid: false, messageType: "error", message: "First name cannot be blank" };
  }

  // --- CHECK 2 --- : Check if last_name is not empty
  if (user.last_name === "" || user.last_name == null || user.last_name == undefined) {
    return { isValid: false, messageType: "error", message: "Last name cannot be blank" };
  }

  // --- CHECK 3 --- : Check if email is not empty
  if (user.email === "" || user.email == null || user.email == undefined) {
    return { isValid: false, messageType: "error", message: "Email name cannot be blank" };
  }

  // --- CHECK 4 --- : Check if role is not empty
  if (user.role === "" || user.role == null || user.role == undefined) {
    return { isValid: false, messageType: "error", message: "User type cannot be blank" };
  }

  // --- CHECK 5 --- : Email format validation
  if (!validateEmail(user.email)) {
    return { isValid: false, messageType: "error", message: "Email address is not valid" };
  }

  return { isValid: true, messageType: "success", message: null };
};

// Function to handle validation of users. Returns and object with {isValid: bool, message: string}. If isValid is true but there's a non null message then the message is a warning
export const validateUpdateUser = async (user) => {
  // --- CHECK 1 --- : Check if first_name is not empty
  if (user.first_name === "" || user.first_name == null || user.first_name == undefined) {
    return { isValid: false, messageType: "error", message: "First name cannot be blank" };
  }

  // --- CHECK 2 --- : Check if last_name is not empty
  if (user.last_name === "" || user.last_name == null || user.last_name == undefined) {
    return { isValid: false, messageType: "error", message: "Last name cannot be blank" };
  }

  // --- CHECK 3 --- : Check if supabase_uid is not empty
  if (user.supabase_uid === "" || user.supabase_uid == null || user.supabase_uid == undefined) {
    console.error("Error generating new user ID");
    return { isValid: false, messageType: "error", message: "Internal error, contact Serial support" };
  }

  // --- CHECK 4 --- : Check if role is not empty
  if (user.role === "" || user.role == null || user.role == undefined) {
    return { isValid: false, messageType: "error", message: "User type cannot be blank" };
  }

  return { isValid: true, messageType: "success", message: null };
};

// Function to check if the pin has already been taken by another user in the company. Ensures uniquenes
const checkPinIsAvailable = async (operator) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    let { data: currOperators, error: currOperatorsErrror } = await supabase.from("operators").select("*").eq("pin", operator.pin);
    // Return invalid if there was an error
    if (currOperatorsErrror) {
      console.log(currOperatorsErrror);
      return false;
    }
    // Return invalid if there are more than one items with that pn
    if (currOperators.length > 1) {
      return false;
    }
    // Return invalid if there is one item with that pn that is not itself
    else if (currOperators.length === 1 && operator.id !== currOperators[0].id) {
      return false;
    }
    // Return valid otherwise
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Function to handle validation of part numbers. Returns and object with {isValid: bool, message: string}. If isValid is true but there's a non null message then the message is a warning
export const validateOperator = async (operator) => {
  // --- CHECK 1 --- : Pin is not empty
  if (operator.pin === "" || operator.pin == null || operator.pin == undefined) {
    return { isValid: false, messageType: "error", message: "Pin cannot be blank" };
  }

  // --- CHECK 2 --- : Part number already exists
  const pnIsValid = await checkPinIsAvailable(operator);
  if (!pnIsValid) {
    return { isValid: false, messageType: "error", message: "This pin has already been assigned to another operator" };
  }

  return { isValid: true, messageType: "success", message: null };
};
