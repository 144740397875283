import { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Table from "@shared/components/react-pdf/Table";
import { Company } from "@shared/types/databaseTypes";
import { SystemFunctionalQcConfig } from "../types";

import RobotoThin from "@styles/fonts/roboto/Roboto-Thin.ttf";
import RobotoThinItalic from "@styles/fonts/roboto/Roboto-ThinItalic.ttf";
import RobotoLight from "@styles/fonts/roboto/Roboto-Light.ttf";
import RobotoLightItalic from "@styles/fonts/roboto/Roboto-LightItalic.ttf";
import RobotoRegular from "@styles/fonts/roboto/Roboto-Regular.ttf";
import RobotoItalic from "@styles/fonts/roboto/Roboto-Italic.ttf";
import RobotoMedium from "@styles/fonts/roboto/Roboto-Medium.ttf";
import RobotoMediumItalic from "@styles/fonts/roboto/Roboto-MediumItalic.ttf";
import RobotoBold from "@styles/fonts/roboto/Roboto-Bold.ttf";
import RobotoBoldItalic from "@styles/fonts/roboto/Roboto-BoldItalic.ttf";
import RobotoBlack from "@styles/fonts/roboto/Roboto-Black.ttf";
import RobotoBlackItalic from "@styles/fonts/roboto/Roboto-BlackItalic.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoThin, fontWeight: 100 },
    { src: RobotoThinItalic, fontWeight: 100, fontStyle: "italic" },
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoLightItalic, fontWeight: 300, fontStyle: "italic" },
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoItalic, fontWeight: 400, fontStyle: "italic" },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoMediumItalic, fontWeight: 500, fontStyle: "italic" },
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBoldItalic, fontWeight: 700, fontStyle: "italic" },
    { src: RobotoBlack, fontWeight: 900 },
    { src: RobotoBlackItalic, fontWeight: 900, fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Roboto",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  companyLogo: {
    width: 60,
    height: "auto",
    position: "absolute",
    top: 4,
    left: 5,
  },
  documentNumber: {
    height: "auto",
    position: "absolute",
    top: 2,
    right: 5,
    fontSize: 10,
    fontWeight: "bold",
  },
  header: {
    fontSize: 10,
    textAlign: "center",
    fontWeight: "bold",
    padding: 2,
  },
  border: {
    borderStyle: "solid",
    borderColor: "#000000",
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
});

type FinalQCProps = {
  company: Company;
  config: SystemFunctionalQcConfig;
  partNumber: string;
};

type HeaderProps = {
  company: Company;
  config: SystemFunctionalQcConfig;
  partNumber: string;
};

const Header: React.FC<HeaderProps> = ({ company, config, partNumber }) => (
  <View>
    {company?.light_logo && <Image style={styles.companyLogo} src={company.light_logo} />}
    <Text style={styles.header}>{partNumber}</Text>
    <Text style={[styles.header, { marginBottom: "10" }]}>{config.documentSubtitle}</Text>
  </View>
);

const FinalQC: React.FC<FinalQCProps> = ({ company, config, partNumber }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Header company={company} config={config} partNumber={partNumber} />

          <Text style={[styles.header, styles.border, { color: "white", backgroundColor: "#434343" }]}>
            {config.generalInformation.title}
          </Text>
          <Table
            data={config.generalInformation.content}
            columnWidths={config.generalInformation.tableWidths}
            headerRow={false}
            fontSize={8}
            boldHeader={true}
            cellPadding={3}
          />

          {config.testData.map((category, index) => (
            <View key={index}>
              <Text style={[styles.header, styles.border, { color: "white", backgroundColor: "#434343" }]}>{category.title}</Text>
              {category.subCategories.map((subCategory, subIndex) => (
                <View key={subIndex}>
                  {subCategory.title && (
                    <Text style={[styles.header, styles.border, { backgroundColor: "darkgray" }]}>{subCategory.title}</Text>
                  )}
                  <Table
                    data={subCategory.requirements}
                    columnWidths={category.tableWidths}
                    headerRow={true}
                    fontSize={8}
                    boldHeader={true}
                    cellPadding={3}
                  />
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default FinalQC;
