import { cn } from "@shared/utils/tailwind";
import MeasuresGraphConfigXAxis from "./MeasuresGraphConfigXAxis";
import MeasuresGraphConfigYAxis from "./MeasuresGraphConfigYAxis";
import MeasuresGraphConfigCategories from "./MeasuresGraphConfigCategories";
import MeasuresGraphConfigOptions from "./MeasuresGraphConfigOptions";
import MeasuresGraphConfigGraphType from "./MeasuresGraphConfigType";
import { GroupAggregationOperator, MeasureKey } from "@shared/measures/types";
import { useImmer } from "use-immer";
import { GraphType, graphConfigurations } from "./types";
import useMeasures from "@shared/measures/MeasuresProvider";
import MeasuresGraphScatter from "./MeasuresGraphScatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import MeasuresGraphLine from "./MeasuresGraphLine";
import Loader from "@shared/components/primitives/Loader";
import { createContext, forwardRef, useContext, useEffect, useState } from "react";

interface MeasuresGraphContextProps {
  graphType: GraphType | null;
  xAxisMeasureKey: MeasureKey | null;
  setXAxisMeasureKey: (measureKey: MeasureKey | null) => void;
  yAxisMeasureKey: MeasureKey | null;
  setYAxisMeasureKey: (measureKey: MeasureKey | null) => void;
  categoriesMeasureKey: MeasureKey | null;
  setCategoriesMeasureKey: (measureKey: MeasureKey | null) => void;
  categoriesGroupAggregationOperator: GroupAggregationOperator | null;
  setCategoriesGroupAggregationOperator: (operator: GroupAggregationOperator | null) => void;
  showRetests: boolean;
  setShowRetests: (showRetests: boolean) => void;
  showLegend: boolean;
  setShowLegend: (showLegend: boolean) => void;
  showGridLines: boolean;
  setShowGridLines: (showGridLines: boolean) => void;
  relative: boolean;
  setRelative: (relative: boolean) => void;
  handleSetGraphType: (graphType: GraphType | null) => void;
}

const defaultContext: MeasuresGraphContextProps = {
  graphType: null,
  xAxisMeasureKey: null,
  setXAxisMeasureKey: () => {},
  yAxisMeasureKey: null,
  setYAxisMeasureKey: () => {},
  categoriesMeasureKey: null,
  setCategoriesMeasureKey: () => {},
  categoriesGroupAggregationOperator: null,
  setCategoriesGroupAggregationOperator: () => {},
  showRetests: false,
  setShowRetests: () => {},
  showLegend: true,
  setShowLegend: () => {},
  showGridLines: true,
  setShowGridLines: () => {},
  relative: false,
  setRelative: () => {},
  handleSetGraphType: () => {},
};

export const MeasuresGraphContext = createContext<MeasuresGraphContextProps>(defaultContext);

const MeasuresGraphProvider = (props: React.PropsWithChildren<{}>) => {
  const { setSelectedMeasureKeys, setGroupAggregationOperator } = useMeasures();

  const [graphType, setGraphType] = useImmer<GraphType | null>(null);
  const [xAxisMeasureKey, setXAxisMeasureKey] = useImmer<MeasureKey | null>(null);
  const [yAxisMeasureKey, setYAxisMeasureKey] = useImmer<MeasureKey | null>(null);
  const [categoriesMeasureKey, setCategoriesMeasureKey] = useImmer<MeasureKey | null>(null);
  const [categoriesGroupAggregationOperator, setCategoriesGroupAggregationOperator] = useState<GroupAggregationOperator | null>(null);
  const [showRetests, setShowRetests] = useState<boolean>(false);
  const [showLegend, setShowLegend] = useState<boolean>(true);
  const [showGridLines, setShowGridLines] = useState<boolean>(true);
  const [relative, setRelative] = useState<boolean>(false);

  const handleSetGraphType = (graphType: GraphType | null) => {
    setGraphType(() => graphType);
  };

  useEffect(() => {
    if (graphType) {
      const selectedMeasureKeys = [];
      if (xAxisMeasureKey) {
        selectedMeasureKeys.push(xAxisMeasureKey);
      }
      if (yAxisMeasureKey) {
        selectedMeasureKeys.push(yAxisMeasureKey);
      }
      if (categoriesMeasureKey) {
        selectedMeasureKeys.push(categoriesMeasureKey);
      }
      setSelectedMeasureKeys(selectedMeasureKeys);
    }
    setGroupAggregationOperator(categoriesGroupAggregationOperator ?? GroupAggregationOperator.Count);
  }, [graphType, xAxisMeasureKey, yAxisMeasureKey, categoriesMeasureKey, setSelectedMeasureKeys]);

  useEffect(() => {
    const processIdsSet = new Set(
      [xAxisMeasureKey?.process_id, categoriesMeasureKey?.process_id, yAxisMeasureKey?.process_id].filter((id) => id) as string[],
    );
    if (processIdsSet.size !== 1) {
      setShowRetests(false);
    }
  }, [xAxisMeasureKey, categoriesMeasureKey, yAxisMeasureKey]);

  return (
    <MeasuresGraphContext.Provider
      value={{
        graphType,
        xAxisMeasureKey,
        setXAxisMeasureKey,
        yAxisMeasureKey,
        setYAxisMeasureKey,
        categoriesMeasureKey,
        setCategoriesMeasureKey,
        categoriesGroupAggregationOperator,
        setCategoriesGroupAggregationOperator,
        showRetests,
        setShowRetests,
        showLegend,
        setShowLegend,
        showGridLines,
        setShowGridLines,
        relative,
        setRelative,
        handleSetGraphType,
      }}
    >
      {props.children}
    </MeasuresGraphContext.Provider>
  );
};

const MeasuresGraphRoot = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  return <div ref={ref} {...props} className={cn("flex min-h-0 min-w-0 flex-grow", className)} />;
});
MeasuresGraphRoot.displayName = "MeasuresGraphRoot";

interface MeasuresGraphConfigProps extends React.HTMLAttributes<HTMLDivElement> {}

const MeasuresGraphConfig = forwardRef<HTMLDivElement, MeasuresGraphConfigProps>(({ className, ...props }, ref) => {
  const { graphType } = useMeasuresGraph();
  const { componentId } = useMeasures();
  return (
    <div ref={ref} {...props} className={cn("flex h-full w-72 flex-col overflow-auto bg-white", className)}>
      {!componentId && (
        <div className="text-serial-palette-500 flex h-full w-full items-center justify-center italic">Select a component...</div>
      )}
      {componentId && <MeasuresGraphConfigGraphType />}
      {componentId && graphType && (
        <>
          {graphConfigurations[graphType].categories && <MeasuresGraphConfigCategories />}
          {graphConfigurations[graphType].xAxis && <MeasuresGraphConfigXAxis />}
          {graphConfigurations[graphType].yAxis && <MeasuresGraphConfigYAxis />}
          <MeasuresGraphConfigOptions />
        </>
      )}
    </div>
  );
});
MeasuresGraphConfig.displayName = "MeasuresGraphConfig";

interface MeasuresGraphContentProps extends React.HTMLAttributes<HTMLDivElement> {
  graphContentRef?: React.RefObject<HTMLDivElement>;
}

const MeasuresGraphContent = forwardRef<HTMLDivElement, MeasuresGraphContentProps>(({ className, graphContentRef, ...props }, ref) => {
  const { graphType } = useMeasuresGraph();
  const { componentId, isLoading } = useMeasures();
  return (
    <div ref={ref} {...props} className={cn("flex min-h-0 min-w-0 flex-grow items-center justify-center p-8", className)}>
      <div className="relative flex h-full w-full rounded-md border bg-white p-4" ref={graphContentRef}>
        {(graphType === null || componentId === null) && !isLoading && (
          <div className="flex w-full items-center justify-center">
            <FontAwesomeIcon icon={faChartLine} className="text-serial-palette-300 text-9xl" />
          </div>
        )}
        {graphType === "scatter" && componentId !== null && <MeasuresGraphScatter />}
        {graphType === "line" && <MeasuresGraphLine />}
        {/*{graphType === "bar" && <MeasuresGraphBar />} */}
        {/*{graphType === "pie" && <MeasuresGraphPie />} */}
        {isLoading && (
          <div className="bg-serial-palette-50 absolute inset-0 flex items-center justify-center rounded-md opacity-70">
            <Loader size="xl" />
          </div>
        )}
      </div>
    </div>
  );
});
MeasuresGraphContent.displayName = "MeasuresGraphContent";

const MeasuresGraph = Object.assign(MeasuresGraphRoot, {
  Provider: MeasuresGraphProvider,
  Root: MeasuresGraphRoot,
  Content: MeasuresGraphContent,
  Config: MeasuresGraphConfig,
});

export default MeasuresGraph;

// Hook for consuming context
export const useMeasuresGraph = () => {
  const context = useContext(MeasuresGraphContext);
  if (!context) {
    throw new Error("useMeasuresGraph must be used within a MeasuresGraphRoot");
  }
  return context;
};
