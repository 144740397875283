import React, { useContext } from "react";
import { ImageMarkupLabelElementProps } from "./ImageMarkupElementContainer";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import { ImageMarkupElementFocus as FocusElement } from "@shared/types/databaseTypes";

const ImageMarkupElementFocus: React.FC<ImageMarkupLabelElementProps<FocusElement>> = ({ element }) => {
  const { imageWidth, imageHeight } = useContext(ImageMarkupContext);

  return (
    <div
      className="shrink-0 rounded-lg"
      style={{
        width: Math.abs(element.width) * imageWidth,
        height: Math.abs(element.height) * imageHeight,
        outline: `${Math.max(imageHeight, imageWidth) * 100}px solid rgba(0, 0, 0, 0.6)`,
      }}
    />
  );
};

export default ImageMarkupElementFocus;
