import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

type SwitchProps = {
  size?: "sm" | "lg";
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Switch: React.FC<SwitchProps> = ({ size, checked, onChange }) => {
  const [id, _setId] = useState<string>(uuidv4());

  return (
    <div className={size === "sm" ? "form-switch-sm" : "form-switch"}>
      <input type="checkbox" id={id} className="sr-only" checked={checked} onChange={onChange} />
      <label className="bg-serial-palette-400" htmlFor={id}>
        <span className="bg-white " aria-hidden="true"></span>
        <span className="sr-only">Switch label</span>
      </label>
    </div>
  );
};

export default Switch;
