import React, { useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { Company } from "@shared/types/databaseTypes";
import { makeFetchRequest } from "@shared/connections/api/helpers";
import { checkIsUuid } from "@shared/utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Switch from "@shared/components/Switch";
import { Loader } from "@shared/components/Loader";

const apiUrl = import.meta.env.VITE_APP_SERIAL_API_URL;
const superApiKeyEnv = import.meta.env.VITE_APP_SUPER_API_KEY;

const DebugCompanySeed: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const company = useSelector((state: RootState) => state.db.company);
  const [seedToApply, setSeedToApply] = useState<any>({});
  const [noDataInSeed, setNoDataInSeed] = useState<boolean>(false);
  const [noStorageInSeed, setNoStorageInSeed] = useState<boolean>(true);
  const [addCompanyNameToEmails, setAddCompanyNameToEmails] = useState<boolean>(true);
  const [hashSeed, setHashSeed] = useState<boolean>(true);
  const [applyingSeed, setApplyingSeed] = useState<boolean>(false);
  const [deletingCompany, setDeletingCompany] = useState<boolean>(false);
  const [gettingSeed, setGettingSeed] = useState<boolean>(false);
  const [seedBlob, setSeedBlob] = useState<Blob | null>();
  const [generatedSeed, setGeneratedSeed] = useState<any>(null);
  const [superApiKey, setSuperApiKey] = useState<string>(superApiKeyEnv ?? "");
  const [targetCompanyId, setTargetCompanyId] = useState<string>("");
  const [newCompanyName, setNewCompanyName] = useState<string>("");
  const [confirmCompanyName, setConfirmCompanyName] = useState<string>("");
  const [defaultPasswords, setDefaultPasswords] = useState<string>("");
  const [newCompany, setNewCompany] = useState<Company | null>(null);
  const { triggerToast } = useContext(ToastContext);

  const handleGetSeed = async (noData: boolean, noStorage: boolean) => {
    setGettingSeed(true);
    const { data: seed, error: seedError } = await makeFetchRequest(
      `${apiUrl}/companies/${company.id}/seed?${noData ? "no_data=true" : ""}${noStorage && noData ? "&" : ""}${noStorage ? "no_storage=true" : ""}`,
      {
        method: "GET",
      },
      "Could not get seed for this company",
      300000, // 5 minutes
      superApiKey,
    );
    setGettingSeed(false);
    if (seedError) {
      console.error(seedError);
      triggerToast(ToastType.Error, "Error getting seed for this company", seedError);
    } else {
      setGeneratedSeed(seed);
      setSeedToApply(seed);
      setSeedBlob(new Blob([JSON.stringify(seed, null, 2)], { type: "application/json" }));
      triggerToast(ToastType.Success, "Successfully generated seed for this company", "You may now copy seed to clipboard");
    }
  };

  const handleDownloadGeneratedSeedAsJson = async () => {
    if (!seedBlob) {
      triggerToast(ToastType.Error, "No seed to download", "Please generate a seed first");
      return;
    }
    const url = URL.createObjectURL(seedBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "seed.json";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleApplySeed = async (addCompanyNameToEmails: boolean, hashSeed: boolean) => {
    // check to make sure seed object is valid
    const seed = seedToApply;
    // check to make sure seed has both a database and a storage object
    if (!seed.database || !seed.storage) {
      triggerToast(ToastType.Error, "Invalid seed", "Seed must have both a database and a storage object");
      return;
    }
    // check to make sure all properties of seed.database are arrays
    if (!Object.values(seed.database).every((value) => Array.isArray(value))) {
      triggerToast(ToastType.Error, "Invalid seed", "seed.database must have only arrays as values");
      return;
    }
    // check to make sure company_id is a valid v4 uuid
    if (!checkIsUuid(targetCompanyId)) {
      triggerToast(ToastType.Error, "Invalid company_id", "company_id must be a valid uuid");
      return;
    }
    let body: any = {
      seed: seed,
    };
    if (defaultPasswords !== "") {
      body = {
        ...body,
        email_confirm: true,
        default_password: defaultPasswords,
        add_company_name_to_emails: addCompanyNameToEmails,
        hash_seed: hashSeed,
      };
    }
    setApplyingSeed(true);
    const { error: applySeedError } = await makeFetchRequest(
      `${apiUrl}/companies/${targetCompanyId}/seed`,
      {
        method: "PUT",
        body: JSON.stringify(body),
      },
      "Could not apply seed to specified company",
      300000, // 5 minutes
      superApiKey,
    );
    if (applySeedError) {
      console.error(applySeedError);
      triggerToast(ToastType.Error, "Error applying seed to specified company", applySeedError);
    } else {
      triggerToast(
        ToastType.Success,
        "Successfully applied seed to specified company",
        defaultPasswords !== ""
          ? "You may now login with your default password"
          : "Reset one of the user passwords in the new company to login",
        15000,
      );
    }
    setApplyingSeed(false);
  };

  const handleCreateCompany = async () => {
    const { data: newCompany, error: newCompanyError } = await makeFetchRequest(
      `${apiUrl}/companies`,
      {
        method: "POST",
        body: JSON.stringify({
          name: newCompanyName,
        }),
      },
      "Could not create new company",
      10000,
      superApiKey,
    );
    if (newCompanyError) {
      console.error(newCompanyError);
      triggerToast(ToastType.Error, "Error creating new company", newCompanyError);
    } else {
      setNewCompany(newCompany);
      setTargetCompanyId(newCompany.id);
      triggerToast(ToastType.Success, "Successfully created new company", "You may now copy company_id to clipboard");
    }
  };

  const handleDeleteCompany = async () => {
    if (!checkIsUuid(targetCompanyId)) {
      triggerToast(ToastType.Error, "Invalid company_id", "company_id must be a valid uuid");
      return;
    }
    // confirm deletion
    const confirmed = confirm(`Are you sure you want to delete company ${confirmCompanyName}? This action cannot be undone`);
    if (!confirmed) return;
    setDeletingCompany(true);
    const { error: deleteCompanyError } = await makeFetchRequest(
      `${apiUrl}/companies/${targetCompanyId}`,
      {
        method: "DELETE",
        body: JSON.stringify({
          name: confirmCompanyName,
        }),
      },
      "Could not delete company",
      10000,
      superApiKey,
    );
    setDeletingCompany(false);
    if (deleteCompanyError) {
      console.error(deleteCompanyError);
      triggerToast(ToastType.Error, "Error deleting company", deleteCompanyError);
    } else {
      triggerToast(ToastType.Success, "Successfully deleted company", "Company Name: " + confirmCompanyName);
    }
  };

  const handleUploadSeed = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const seed = JSON.parse(e.target?.result as string);
      setSeedToApply(seed);
      setSeedBlob(new Blob([JSON.stringify(seed, null, 2)], { type: "application/json" }));
    };
    reader.readAsText(file);
  };

  return (
    <div className="flex flex-col gap-y-5 rounded border border-amber-500 bg-amber-50 p-4">
      <div className="flex w-full items-center justify-between">
        <h1 className="flex flex-col text-lg">
          <span className="font-bold">Super API Key</span> The following actions require a super API Key
        </h1>
        <input
          className="form-input ml-4 w-36"
          type="password"
          value={superApiKey}
          onChange={(e) => setSuperApiKey(e.target.value)}
          placeholder="SUPER_API_KEY"
        />
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <h1 className="flex flex-col text-lg">
          <span className="font-bold">Create A Company</span> Creates a new company with the specified name
        </h1>
        <div className="flex flex-grow" />
        <input
          className="form-input w-36"
          type="text"
          value={newCompanyName}
          onChange={(e) => setNewCompanyName(e.target.value)}
          placeholder="Company Name"
        />
        <button className="btn w-36 bg-black text-white" onClick={() => handleCreateCompany()}>
          Create Company
        </button>
        <div className="flex w-[20%] max-w-[20%] justify-between rounded-lg border bg-purple-100 px-2 py-0.5">
          <pre className="truncate">{newCompany?.id ?? "new_company_id"}</pre>
          <CopyToClipboard text={newCompany?.id ?? ""} />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <h1 className="flex flex-col text-lg">
          <span className="font-bold">{`Generate Seed for This Company (${company.name})`}</span>
          {` Generates a seed for ${company.name}`}
        </h1>
        <div className="flex flex-grow" />
        <div className="whitespace-nowrap">No Data</div>
        <Switch size="sm" checked={noDataInSeed} onChange={(e) => setNoDataInSeed(e.target.checked)} />
        <div className="whitespace-nowrap">No Storage</div>
        <Switch size="sm" checked={noStorageInSeed} onChange={(e) => setNoStorageInSeed(e.target.checked)} />
        {gettingSeed && <Loader styleOverride="w-6 h-6" />}
        {!gettingSeed && (
          <button className="btn w-36 bg-black text-white" onClick={() => handleGetSeed(noDataInSeed, noStorageInSeed)}>
            Generate Seed
          </button>
        )}
        <div className="flex w-[10%] max-w-[10%] justify-between rounded-lg border bg-blue-100 px-2 py-0.5">
          {generatedSeed ? <div>✅</div> : <div>🚫</div>}
          <button onClick={() => handleDownloadGeneratedSeedAsJson()}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
          <CopyToClipboard text={JSON.stringify(generatedSeed, null, 2) ?? ""} />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <h1 className="flex flex-col text-lg">
          <span className="font-bold">Seed a Company</span> Applies a seed to a specified company
        </h1>
        <div className="flex flex-grow" />
        <div className="whitespace-nowrap">Change Email</div>
        <Switch size="sm" checked={addCompanyNameToEmails} onChange={(e) => setAddCompanyNameToEmails(e.target.checked)} />
        <div className="whitespace-nowrap">Hash Seed</div>
        <Switch size="sm" checked={hashSeed} onChange={(e) => setHashSeed(e.target.checked)} />
        <input
          className="form-input ml-4 w-36"
          type="password"
          value={defaultPasswords}
          onChange={(e) => setDefaultPasswords(e.target.value)}
          placeholder="default passwords"
        />
        <input
          className="form-input w-36 bg-purple-100"
          type="text"
          value={targetCompanyId}
          onChange={(e) => setTargetCompanyId(e.target.value)}
          placeholder="company_id"
        />
        <div className="flex flex-col items-center">
          <input type="file" ref={fileInputRef} onChange={(e) => handleUploadSeed(e)} className="hidden" />
          <button className="btn-xs serial-btn-light h-6 whitespace-nowrap" onClick={() => fileInputRef.current?.click()}>
            Choose Seed File
          </button>
          <div className="whitespace-nowrap text-[10px]">{seedBlob ? "✅ Seed added" : "🚫 No seed added"}</div>
        </div>
        {applyingSeed && <Loader styleOverride="w-6 h-6" />}
        {!applyingSeed && (
          <button className="btn w-36 bg-black text-white" onClick={() => handleApplySeed(addCompanyNameToEmails, hashSeed)}>
            Apply Seed
          </button>
        )}
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <h1 className="flex flex-col text-lg">
          <span className="font-bold">Delete</span> Deletes specified company (must be inactivated in supabase first)
        </h1>
        <div className="flex flex-grow" />
        <input
          className="form-input w-36"
          type="text"
          value={confirmCompanyName}
          onChange={(e) => setConfirmCompanyName(e.target.value)}
          placeholder="Company Name"
        />
        <input
          className="form-input w-36 bg-purple-100"
          type="text"
          value={targetCompanyId}
          onChange={(e) => setTargetCompanyId(e.target.value)}
          placeholder="company_id"
        />
        {deletingCompany && <Loader styleOverride="w-6 h-6" />}
        {!deletingCompany && (
          <button className="btn serial-btn-danger w-36 text-white" onClick={() => handleDeleteCompany()}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default DebugCompanySeed;
