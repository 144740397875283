import React, { useEffect } from "react";
import Select from "@shared/components/primitives/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface PaginationFooterProps {
  currentPage: number;
  totalPages: number;
  rowsPerPage: number;
  availableRowsPerPage: number[];
  onNextPage: () => void;
  onPrevPage: () => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  className?: string;
}

const PaginationFooter: React.FC<PaginationFooterProps> = ({
  currentPage,
  totalPages,
  rowsPerPage,
  availableRowsPerPage,
  onNextPage,
  onPrevPage,
  onRowsPerPageChange,
  className,
}) => {
  const handleRowsPerPageChange = (value: string) => {
    const newRowsPerPage = parseInt(value, 10);
    onRowsPerPageChange(newRowsPerPage);
  };

  // Ensure that the current rowsPerPage is available in the list of availableRowsPerPage
  // If not, set the rowsPerPage to the first available option
  useEffect(() => {
    if (!availableRowsPerPage.includes(rowsPerPage)) {
      onRowsPerPageChange(availableRowsPerPage[0]);
    }
  }, [availableRowsPerPage, rowsPerPage, onRowsPerPageChange]);

  return (
    <div className={`w-full items-center px-6 py-2 ${className}`}>
      <div className="text-serial-palette-500 flex w-full items-center  justify-between text-base">
        <div className="inline-flex items-center gap-3">
          <span className="hidden text-sm sm:block">Rows per page</span>
          <Select.Root value={rowsPerPage.toString()} onValueChange={(value) => handleRowsPerPageChange(value)}>
            <Select.Trigger size="sm">
              <Select.Value placeholder="Select rows per page" />
            </Select.Trigger>
            <Select.Content>
              <Select.ScrollUpButton />
              {availableRowsPerPage.map((rows) => (
                <Select.Item key={rows} value={rows.toString()}>
                  {rows}
                </Select.Item>
              ))}
              <Select.ScrollDownButton />
            </Select.Content>
          </Select.Root>
        </div>
        <div className="flex flex-nowrap items-center gap-3">
          <span className="mr-1 text-sm">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={onPrevPage}
            disabled={currentPage === 1}
            className="rounded-m text-serial-palette-700 disabled:text-serial-palette-300 py-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={onNextPage}
            disabled={currentPage === totalPages}
            className="rounded-m text-serial-palette-700 disabled:text-serial-palette-300 py-2"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginationFooter;
