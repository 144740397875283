import { ProcessEntry, ProcessStep, ProcessWithReferences } from "@shared/types/databaseTypes";
import { changeLogPropertiesToDiff, changeLogTablePrettyNames } from "../constants";
import { getProcessWithReferences } from "@shared/connections/supabaseProcess";
import { ParsedResponse } from "@shared/types/apiTypes";
import { makeFetchRequest } from "@shared/connections/api/helpers";

const API_URL = import.meta.env.VITE_APP_SERIAL_API_URL;

interface ProcessStepWithDeletedFlag extends ProcessStep {
  deleted?: boolean;
}

// generates a series of modification messages (bullet points) for any table in changeLogPropertiesToDiff
const listModificationsOfProcessSubTable = (
  originalRecords: any[],
  newRecords: any[],
  tableName: string,
): { processStepId: string | null; modification: string }[] => {
  let modifications: { processStepId: string | null; modification: string }[] = [];
  // Initial validation
  if (newRecords.length === 0 || originalRecords.length === 0) return modifications;
  if (!changeLogPropertiesToDiff[tableName] || !changeLogTablePrettyNames[tableName]) {
    console.error(`No change log properties defined for table ${tableName}`);
    return modifications;
  }
  // Find all ADDED records
  newRecords.forEach((newRecord) => {
    const originalRecord = originalRecords.find((originalRecord) => originalRecord.id === newRecord.id);
    if (!originalRecord) {
      let propertiesOfInterest =
        changeLogPropertiesToDiff[tableName]?.map((property) => {
          return `${property} = ${JSON.stringify(newRecord[property])}`;
        }) ?? [];
      if (tableName === "fields" && newRecord.dataset) {
        const datasetPropertiesOfInterest =
          changeLogPropertiesToDiff["datasets"]?.map((property) => {
            return `dataset ${property} = ${JSON.stringify(newRecord.dataset[property])}`;
          }) ?? [];
        propertiesOfInterest = propertiesOfInterest.concat(datasetPropertiesOfInterest);
      }
      modifications.push({
        processStepId: tableName === "process_steps" ? newRecord.id : newRecord.process_step_id ?? null,
        modification: `Added ${changeLogTablePrettyNames[tableName]} with properties: ${propertiesOfInterest.join(", ")}`,
      });
    }
  });
  // Find all REMOVED records
  originalRecords.forEach((originalRecord) => {
    const newRecord = newRecords.find((newRecord) => newRecord.id === originalRecord.id);
    if (!newRecord) {
      const propertiesOfInterest =
        changeLogPropertiesToDiff[tableName]?.map((property) => {
          return `${property} = ${JSON.stringify(originalRecord[property])}`;
        }) ?? [];
      modifications.push({
        processStepId: tableName === "process_steps" ? originalRecord.id : originalRecord.process_step_id ?? null,
        modification: `Removed ${changeLogTablePrettyNames[tableName]} with properties: ${propertiesOfInterest.join(", ")}`,
      });
    }
  });
  // Find all CHANGED records
  originalRecords.forEach((originalRecord) => {
    const newRecord = newRecords.find((newRecord) => newRecord.id === originalRecord.id);
    if (newRecord) {
      const changes: { processStepId: string | null; modification: string }[] = changeLogPropertiesToDiff[tableName]
        .map((property) => {
          if (JSON.stringify(originalRecord[property]) !== JSON.stringify(newRecord[property])) {
            // if the property is a table, we need to diff the properties of the table
            if (
              typeof originalRecord[property] === "object" &&
              typeof newRecord[property] === "object" &&
              "rows" in originalRecord[property] &&
              "rows" in newRecord[property]
            ) {
              const originalTable = originalRecord[property];
              const newTable = newRecord[property];
              const tableChanges = newTable.rows.flatMap((newRow: any, index: number) => {
                const originalRow = originalTable.rows[index];
                return Object.keys(newRow).map((rowColumn) => {
                  if (JSON.stringify(originalRow?.[rowColumn]) !== JSON.stringify(newRow?.[rowColumn])) {
                    const originalValue = JSON.stringify(originalRow?.[rowColumn]);
                    const newValue = JSON.stringify(newRow?.[rowColumn]);
                    const columnNumber = parseInt(rowColumn) + 1;
                    return {
                      processStepId: tableName === "process_steps" ? newRecord.id : newRecord.process_step_id ?? null,
                      modification: `Changed ${changeLogTablePrettyNames[tableName]} table ${property} row ${index + 1} column ${columnNumber} from ${originalValue ?? "empty"} to ${newValue ?? "empty"}`,
                    };
                  }
                  return null;
                });
              });
              return tableChanges.filter((change: { processStepdId: string | null; modification: string }) => change !== null);
            }
            return {
              processStepId: tableName === "process_steps" ? newRecord.id : newRecord.process_step_id ?? null,
              modification: `Changed ${changeLogTablePrettyNames[tableName]} ${property} from ${JSON.stringify(originalRecord[property])} to ${JSON.stringify(newRecord[property])}`,
            };
          }
          return null;
        })
        .filter((change) => change !== null) as { processStepId: string | null; modification: string }[];
      if (tableName === "fields" && newRecord.dataset) {
        const datasetChanges: { processStepId: string | null; modification: string }[] = changeLogPropertiesToDiff["datasets"]
          .map((property) => {
            if (
              originalRecord.dataset &&
              newRecord.dataset &&
              JSON.stringify(originalRecord.dataset[property]) !== JSON.stringify(newRecord.dataset[property])
            ) {
              if (!newRecord.process_step_id) console.error("Could not find process step id for field", newRecord);
              return {
                processStepId: newRecord.process_step_id ?? null,
                modification: `Changed ${changeLogTablePrettyNames[tableName]} dataset ${property} from ${JSON.stringify(originalRecord.dataset[property])} to ${JSON.stringify(newRecord.dataset[property])}`,
              };
            }
            return null;
          })
          .filter((change) => change !== null) as { processStepId: string | null; modification: string }[];
        modifications = modifications.concat(datasetChanges);
      }
      modifications = modifications.concat(...changes);
    }
  });
  return modifications;
};

export const generateVerboseProcessRevisionDescription = async (process: ProcessWithReferences): Promise<string> => {
  try {
    let revisionDescription: string[] = [];
    // retrieve the original process (the one currently on the database)
    const { data: originalProcess } = await getProcessWithReferences(process.id, undefined, true);
    // prepare the records to diff
    const recordsToDiff = [
      {
        tableName: "process_steps",
        originalRecords: originalProcess?.process_steps ?? [],
        newRecords: process.process_steps,
      },
      {
        tableName: "work_instruction_blocks",
        originalRecords: originalProcess?.process_steps.flatMap((processStep) => processStep.work_instruction_blocks) ?? [],
        newRecords: process.process_steps.flatMap((processStep) => processStep.work_instruction_blocks),
      },
      {
        tableName: "fields",
        originalRecords: originalProcess?.process_steps.flatMap((processStep) => processStep.fields) ?? [],
        newRecords: process.process_steps.flatMap((processStep) => processStep.fields),
      },
      {
        tableName: "filter_conditions",
        originalRecords: originalProcess?.process_steps.flatMap((processStep) => processStep.filter_conditions) ?? [],
        newRecords: process.process_steps.flatMap((processStep) => processStep.filter_conditions),
      },
    ];
    // diff all the records
    const modifications = recordsToDiff.flatMap((recordToDiff) => {
      return listModificationsOfProcessSubTable(recordToDiff.originalRecords, recordToDiff.newRecords, recordToDiff.tableName);
    });
    // list process level modifications
    const processLevelModifications = listModificationsOfProcessSubTable(originalProcess ? [originalProcess] : [], [process], "processes");
    revisionDescription = revisionDescription.concat(processLevelModifications.map((modification) => modification.modification));
    // get combined list of all process steps from old process and new process
    let allProcessSteps: ProcessStepWithDeletedFlag[] = [...process.process_steps];
    originalProcess?.process_steps.forEach((originalProcessStep) => {
      if (!allProcessSteps.find((processStep) => processStep.id === originalProcessStep.id)) {
        allProcessSteps.push({
          ...originalProcessStep,
          deleted: true,
        });
      }
    });
    // sort the process steps by order and the deleted flag
    allProcessSteps = allProcessSteps.sort((a, b) => a.order - b.order).sort((a, b) => (a.deleted === b.deleted ? 0 : a.deleted ? 1 : -1));
    // list modifications for each process step
    const allStepModifications = allProcessSteps.flatMap((processStep) => {
      const stepModificationsHeader: string[] = [
        "",
        `Step ${processStep.order + 1}: ${processStep.name} ${processStep.deleted ? "(deleted)" : ""}`,
      ];
      let stepModifications = modifications
        .filter((modification) => {
          return modification.processStepId === processStep.id;
        })
        .map((modification) => ` - ${modification.modification}`);
      if (stepModifications.length === 0) {
        return [];
      }
      return stepModificationsHeader.concat(stepModifications);
    });
    revisionDescription = revisionDescription.concat(allStepModifications);
    revisionDescription = revisionDescription.filter((description) => description !== "");
    return revisionDescription.join("\n");
  } catch (error) {
    console.error("Failed to generate change log: ", error);
    return "Updated the process";
  }
};

export const updateProcessRevisionDescription = async (
  processId: string,
  processRevision: number,
  revisionDescription: string,
  summarizeWithAi: boolean,
): Promise<ParsedResponse<ProcessEntry>> => {
  return makeFetchRequest(
    `${API_URL}/processes/${processId}/${processRevision}/revision-description`,
    {
      method: "PUT",
      body: JSON.stringify({
        revision_description: revisionDescription,
        summarize_with_ai: summarizeWithAi,
      }),
    },
    "Could not update revision description",
  );
};
