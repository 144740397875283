import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import Banner2 from "@shared/components/Banner2";
import DropdownTags from "@shared/components/dropdowns/DropdownTags";
import { Loader } from "@shared/components/Loader";
import { deactivateAlertsByRelevantIds } from "@shared/connections/supabaseAlerts";
import { updateProcessEntry, updateUniqueIdentifierStatus } from "@shared/connections/supabaseSnLookup";
import Transition from "@shared/components/Transition";
import { SnLookupContext } from "../../SnLookupProvider";

function OverrideResultModal() {
  const { overrideModalOpen, setOverrideModalOpen, overrideModalEntry, setOverrideModalEntry, uniqueIdentifier, loadData } =
    useContext(SnLookupContext);

  const [selectedResult, setSelectedResult] = useState(overrideModalEntry?.is_pass ? "PASS" : "FAIL");
  const [isLoading, setIsLoading] = useState(false);
  const supabase_uid = useSelector((state) => state.auth.supabase_uid);
  const curreOverrideUser = useSelector((state) => state.db.users.find((usr) => usr.supabase_uid === overrideModalEntry?.override_user_id));

  const handleSubmit = async () => {
    setIsLoading(true);
    const isPass = selectedResult === "PASS" ? true : false;
    await updateProcessEntry({ id: overrideModalEntry?.id, is_pass: isPass, override_user_id: supabase_uid });
    await updateUniqueIdentifierStatus(uniqueIdentifier);
    await deactivateAlertsByRelevantIds([overrideModalEntry?.id]);
    await loadData();
    setOverrideModalOpen(false);
    setOverrideModalEntry(null);
    setIsLoading(false);
  };

  const handleClose = () => {
    setOverrideModalOpen(false);
    setOverrideModalEntry(null);
  };

  useEffect(() => {
    setSelectedResult(overrideModalEntry?.is_pass ? "PASS" : "FAIL");
  }, [overrideModalEntry]);

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={overrideModalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id="override-modal"
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={overrideModalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div className="max-h-full w-full max-w-lg overflow-auto rounded border bg-white">
          {/* Modal Formatting */}
          <div className="border-serial-palette-200 space-y-5 border-b py-5">
            {/* Header */}
            <div className="flex items-center justify-between px-5">
              <div className="text-serial-palette-800 font-semibold">Override Process Result</div>
              <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={() => handleClose()}>
                <div className="sr-only">Close</div>
                <svg className="h-4 w-4 fill-current">
                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                </svg>
              </button>
            </div>

            {/* Divider */}
            <div className="border-serial-palette-200 border-t"></div>

            {/* Body */}
            {isLoading ? (
              <div className="flex w-full items-center justify-center py-16">
                <Loader styleOverride="w-16" />
              </div>
            ) : (
              <div className="flex flex-col gap-y-3 px-5">
                <Banner2 type="warning" open={overrideModalEntry?.is_pass !== (selectedResult === "PASS")} hideX>
                  Are you sure you want to override the process result?
                </Banner2>
                {curreOverrideUser && (
                  <div className="flex items-center gap-3">
                    <label className="block text-sm font-medium">Result Overridden By: </label>
                    <div className="text-serial-palette-800 text-sm font-light">
                      {curreOverrideUser?.first_name} {curreOverrideUser?.last_name}
                    </div>
                  </div>
                )}
                <div className="flex items-center gap-3">
                  <label className="block text-sm font-medium">Process Result: </label>
                  <DropdownTags
                    options={[{ id: "PASS" }, { id: "FAIL" }]}
                    selected={selectedResult}
                    setSelected={setSelectedResult}
                    className="border-none shadow-none"
                  />
                </div>
              </div>
            )}

            {/* Divider */}
            <div className="border-serial-palette-200 border-t"></div>

            {/* Footer */}
            <div className="flex justify-end space-x-3 px-5">
              <button className="btn whitespace-nowrap bg-yellow-500 text-white hover:bg-yellow-600" onClick={() => handleSubmit()}>
                Override
              </button>
              <button
                className="btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600"
                type="button"
                onClick={(e) => handleClose()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}

export default OverrideResultModal;
