import React, { useMemo } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { MeasureAggregationOperator, MeasureKey } from "../../types";
import { ProcessEntry, Process } from "@shared/types/databaseTypes";
import useMeasures from "@shared/measures/MeasuresProvider";
import { MeasureGridCellCoordinate, MeasuresGridRowData } from "./types";
import AgGridCellErrorBoundary from "@shared/components/AgGridCellErrorBoundary";
import MeasuresGridBodyCellContent from "./MeasuresGridBodyCellContent";
import MeasuresGridBodyCellSameAsAbove from "./MeasuresGridBodyCellSameAsAbove";
import Tooltip from "@shared/components/primitives/Tooltip";
import { useSelector } from "react-redux";
import { ProcessType } from "@shared/types/databaseEnums";
import { RootState } from "@shared/redux/store";
import TagBasic from "@shared/components/primitives/TagBasic";
import MeasuresGridBodyCellTestHistory from "./MeasuresGridBodyCellTestHistory";
import { cn } from "@shared/utils/tailwind";
import MeasuresGridBodyCellBlank from "./MeasuresGridBodyCellBlank";
import MeasuresGridBodyCellNotApplicable from "./MeasuresGridBodyCellNotApplicable";

export interface MeasureGridBodyCellSubComponentProps<T> {
  measureKey: MeasureKey;
  measureValues: T[];
}

interface MeasuresGridBodyCell extends ICellRendererParams<MeasuresGridRowData> {
  measureKey: MeasureKey;
  gridContainerRef: React.RefObject<HTMLDivElement>;
  setPreviewObject: (value: MeasureGridCellCoordinate | null) => void;
  processRevisions: Process[];
  processEntries: ProcessEntry[];
}

const MeasuresGridBodyCellWithoutErrorBoundary: React.FC<MeasuresGridBodyCell> = (props) => {
  const processes = useSelector((state: RootState) => state.db.processes);

  const { isLoading } = useMeasures();

  const { measureValues, measureAggregation, sameAsAbove, notApplicable } = useMemo(() => {
    const measureKeyHash = props.colDef?.field ?? "";
    return {
      measureValues: props.data?.measuresValuesByHash[measureKeyHash]?.measureValues ?? null,
      measureAggregation: props.data?.measuresValuesByHash[measureKeyHash]?.measureAggregation ?? null,
      sameAsAbove: props.data?.measuresValuesByHash[measureKeyHash]?.sameAsAbove ?? false,
      notApplicable: props.data?.measuresValuesByHash[measureKeyHash]?.notApplicable ?? false,
    };
  }, [props.data, props.colDef]);

  const showRetests = useMemo(() => {
    if (!measureValues) return false;
    if (props.measureKey?.aggregation_operator === MeasureAggregationOperator.Count) return false;
    const count = measureAggregation?.numValues !== undefined ? measureAggregation.numValues : measureValues.length;
    const process = processes.find((process) => process.id === props.measureKey?.process_id);
    return process?.type === ProcessType.Production && count > 1;
  }, [props.measureKey, measureValues, measureAggregation, processes]);

  if (notApplicable) {
    return <MeasuresGridBodyCellNotApplicable />;
  }

  // No data available: handle different types of unavailable data
  if (!measureValues || !measureAggregation || measureValues.length === 0) {
    if (isLoading) {
      return <div className="flex h-full w-full" />;
    }
    return (
      <MeasuresGridBodyCellBlank
        measureKey={props.measureKey}
        measureValues={measureValues}
        processRevisions={props.processRevisions}
        processEntries={props.processEntries}
      />
    );
  }

  if (sameAsAbove) {
    return <MeasuresGridBodyCellSameAsAbove formattedValue={measureAggregation.formattedValue} />;
  }

  return (
    <Tooltip.Root open={showRetests ? undefined : false}>
      <Tooltip.Trigger asChild>
        <a
          target="_blank"
          className={cn("flex h-full w-full items-center gap-2 text-sm", measureAggregation.href && "hover:underline")}
          href={measureAggregation.href}
        >
          <MeasuresGridBodyCellContent
            measureKey={props.measureKey}
            summary={measureAggregation}
            openPreviewModal={() => {
              const rowIndex = props.node.rowIndex;
              if (rowIndex !== null) props.setPreviewObject({ measureKey: props.measureKey, rowIndex: rowIndex });
            }}
          />
          {showRetests && (
            <TagBasic
              size="xs"
              variant="RETEST"
              count={measureAggregation.numValues !== undefined ? measureAggregation.numValues - 1 : measureValues.length - 1}
            />
          )}
        </a>
      </Tooltip.Trigger>
      <Tooltip.Portal container={props.gridContainerRef.current}>
        <Tooltip.Content variant="light" className="p-0">
          <MeasuresGridBodyCellTestHistory measureKey={props.measureKey} measureValues={measureValues} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

const MeasuresGridBodyCell: React.FC<MeasuresGridBodyCell> = (props) => {
  return (
    <AgGridCellErrorBoundary>
      <MeasuresGridBodyCellWithoutErrorBoundary {...props} />
    </AgGridCellErrorBoundary>
  );
};

export default MeasuresGridBodyCell;
