import { CodeBlock } from "@shared/types/databaseTypes";
import { ProductionWorkInstructionBlockContentProps } from "../../types";
import CopyToClipboard from "@shared/components/CopyToClipboard";

const ProductionWorkInstructionBlockCode: React.FC<ProductionWorkInstructionBlockContentProps<CodeBlock>> = ({ block }) => {
  return (
    <div className="relative flex w-full">
      <div className="min-h-[46px] w-full rounded-md bg-black py-3 pl-4 pr-8 font-mono text-sm text-white">{block.content.text}</div>
      <CopyToClipboard text={block.content.text} className="absolute right-2 top-3 text-white" />
    </div>
  );
};

export default ProductionWorkInstructionBlockCode;
