import { useCallback, useEffect, useRef, useContext } from "react";
import { WorkOrder } from "@shared/types/databaseTypes";
import {
  addApprover,
  addComment,
  createWorkOrder,
  deleteWorkOrder,
  deleteApprover,
  approveOrRejectReview,
  updateStatus,
  updateWorkOrder,
  addAttachment,
  deleteAttachment,
} from "../connections/serialApiWorkOrders";
import { ToastType } from "@shared/components/Toast";
import { useNavigate } from "react-router-dom";
import { ApprovalStatus, WorkOrderStatus } from "@shared/types/databaseEnums";
import { WorkOrderAttachmentDraft } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";
import db from "@shared/connections/supabaseReduxDatabase";
import { setWorkOrders } from "@shared/redux/db";
import { ToastContext } from "@shared/context/ToastProvider";
import { useTranslation } from "react-i18next";

export const useWorkOrders = () => {
  // ----------- State ----------- //
  const workOrders = useSelector((state: RootState) => state.db.workOrders);
  const company = useSelector((state: RootState) => state.db.company);
  const token = useSelector((state: RootState) => state.auth.token);
  const { t } = useTranslation();

  const { triggerToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const supabaseRef: any = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    handleRefreshWorkOrders();
  }, []);

  useEffect(() => {
    // Ensure company.id and token are not null
    if (company?.id && token) {
      if (!supabaseRef.current) {
        supabaseRef.current = getSupabase();
      }
      supabaseRef.current
        .channel("any")
        .on("postgres_changes", { event: "UPDATE", schema: "public", table: "work_orders" }, handleRefreshWorkOrders)
        .subscribe((status: string) => {
          if (status === "SUBSCRIBED") {
            console.log("Subscribed to DB changes for work orders");
          }
        });
    }
  }, [company]);

  // ----------- Handlers ----------- //

  const handleRefreshWorkOrders = async () => {
    try {
      db.refreshWorkOrders();
    } catch (error) {
      triggerToast(ToastType.Error, `Error fetching ${t("workOrders")}`, "An error occured, please contact Serial support");
    }
  };

  const handleCreateWorkOrder = useCallback(
    async (workOrder: WorkOrder): Promise<void> => {
      const { data, error } = await createWorkOrder(workOrder);
      await handleRefreshWorkOrders();
      if (error) {
        triggerToast(ToastType.Error, error, `Error creating ${t("workOrder")}`);
      } else {
        navigate(`/workorder/${data.id}`);
      }
    },
    [handleRefreshWorkOrders],
  );

  const handleUpdateWorkOrder = useCallback(
    async (workOrder: WorkOrder): Promise<void> => {
      const { error } = await updateWorkOrder(workOrder);
      if (error) {
        triggerToast(ToastType.Error, `Error updating ${t("workOrder")}`, error);
      } else {
        triggerToast(ToastType.Success, `${t("workOrder")} saved!`, "");
      }
      handleRefreshWorkOrders();
    },
    [handleRefreshWorkOrders],
  );

  const handleDeleteWorkOrder = useCallback(
    async (workOrderId: string): Promise<void> => {
      const { error } = await deleteWorkOrder(workOrderId);
      if (error) {
        triggerToast(ToastType.Error, `Error deleting ${t("workOrder")}`, error);
      } else {
        navigate("/workorder");
      }
      handleRefreshWorkOrders();
    },
    [handleRefreshWorkOrders],
  );

  const handleAddApprover = useCallback(
    async (workOrderId: string, approverId: string): Promise<void> => {
      const { data, error } = await addApprover(workOrderId, approverId);
      if (error) {
        triggerToast(ToastType.Error, "Error adding approver", error);
      } else {
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo.id === workOrderId) {
            return data;
          }
          return wo;
        });
        dispatch(setWorkOrders(updatedWorkOrders));
      }
    },
    [workOrders],
  );

  const handleRemoveApprover = useCallback(
    async (workOrderId: string, approverId: string): Promise<void> => {
      const { data, error } = await deleteApprover(workOrderId, approverId);
      if (error) {
        triggerToast(ToastType.Error, "Error removing approver", error);
      } else {
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo.id === workOrderId) {
            return data;
          }
          return wo;
        });
        dispatch(setWorkOrders(updatedWorkOrders));
      }
    },
    [workOrders],
  );

  const handleSetApproval = useCallback(
    async (workOrderId: string, approverUserId: string, approvalStatus: ApprovalStatus | null, comment?: string): Promise<void> => {
      const { data, error } = await approveOrRejectReview(workOrderId, approverUserId, approvalStatus, comment);
      if (error) {
        triggerToast(ToastType.Error, "Error setting approval", error);
      } else {
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo.id === workOrderId) {
            return data;
          }
          return wo;
        });
        dispatch(setWorkOrders(updatedWorkOrders));
      }
    },
    [workOrders],
  );

  const handleAddComment = useCallback(
    async (workOrderId: string, comment: string): Promise<void> => {
      const { data, error } = await addComment(workOrderId, comment);
      if (error) {
        triggerToast(ToastType.Error, "Error adding comment", error);
      } else {
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo.id === workOrderId) {
            return data;
          }
          return wo;
        });
        dispatch(setWorkOrders(updatedWorkOrders));
      }
    },
    [workOrders],
  );

  const handleSetStatus = useCallback(
    async (workOrderId: string, status: WorkOrderStatus): Promise<void> => {
      const { data, error } = await updateStatus(workOrderId, status);
      if (error) {
        triggerToast(ToastType.Error, "Error setting status", error);
      } else {
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo.id === workOrderId) {
            return data;
          }
          return wo;
        });
        dispatch(setWorkOrders(updatedWorkOrders));
      }
    },
    [workOrders],
  );

  const handleAddAttachment = useCallback(
    async (workOrderId: string, attachmentDraft: WorkOrderAttachmentDraft): Promise<void> => {
      // create blob from attachmentUrl
      let blob: Blob | undefined = undefined;
      if (attachmentDraft.url) {
        blob = await fetch(attachmentDraft.url).then((r) => r.blob());
      }
      const { data, error } = await addAttachment(
        workOrderId,
        attachmentDraft.type,
        blob,
        attachmentDraft.name,
        attachmentDraft.file_name,
        attachmentDraft.value,
      );
      await db.refreshDatasets();
      if (error) {
        triggerToast(ToastType.Error, error, "Error adding attachment");
      } else {
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo.id === workOrderId) {
            return data;
          }
          return wo;
        });
        dispatch(setWorkOrders(updatedWorkOrders));
      }
    },
    [workOrders, db.refreshDatasets],
  );

  const handleDeleteAttachment = useCallback(
    async (workOrderId: string, datasetId: string): Promise<void> => {
      const { data, error } = await deleteAttachment(workOrderId, datasetId);
      if (error) {
        triggerToast(ToastType.Error, error, "Error deleting attachment");
      } else {
        const updatedWorkOrders = workOrders.map((wo) => {
          if (wo.id === workOrderId) {
            return data;
          }
          return wo;
        });
        dispatch(setWorkOrders(updatedWorkOrders));
      }
    },
    [workOrders],
  );

  return {
    workOrders,
    handleCreateWorkOrder,
    handleUpdateWorkOrder,
    handleDeleteWorkOrder,
    handleAddApprover,
    handleRemoveApprover,
    handleSetApproval,
    handleAddComment,
    handleSetStatus,
    handleAddAttachment,
    handleDeleteAttachment,
  };
};
