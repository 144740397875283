import { useSelector } from "react-redux";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { RootState } from "@shared/redux/store";
import { useEffect, useMemo, useState } from "react";
import { Component, ComponentCounts, UniqueIdentifier } from "@shared/types/databaseTypes";
import { Loader } from "@shared/components/Loader";
import { fetchLargeTable } from "@shared/connections/supabaseGeneral";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";

enum SplitBy {
  Status = "STATUS",
  Links = "LINKS",
}

const categories = {
  [SplitBy.Status]: [
    {
      label: "Planned",
      key: "planned",
      color: "bg-neutral-200",
    },
    {
      label: "WIP",
      key: "wip",
      color: "bg-blue-200",
    },
    {
      label: "Complete",
      key: "complete",
      color: "bg-green-200",
    },
    {
      label: "Defective",
      key: "defective",
      color: "bg-red-200",
    },
  ],
  [SplitBy.Links]: [
    {
      label: "Linked",
      key: "linked",
      color: "bg-neutral-600",
    },
    {
      label: "Unlinked",
      key: "unlinked",
      color: "bg-neutral-200",
    },
  ],
};

interface HomeComponentCountsByStatusProps {
  days: number;
  label: string;
  lastUpdatedEpochTimestamp: Number;
}

const HomeComponentCountsByStatus: React.FC<HomeComponentCountsByStatusProps> = ({ days, label, lastUpdatedEpochTimestamp }) => {
  const components = useSelector((state: RootState) => state.db.components);

  const [splitBy, setSplitBy] = useState<SplitBy>(SplitBy.Status);
  const [completedComponentInstances, setCompletedComponentInstances] = useState<Partial<UniqueIdentifier>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    let daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - days);
    // Fetch all component instances completed in the last 30 days
    fetchLargeTable<UniqueIdentifier>("unique_identifiers", "id", "id, status, completed_at, component_id", [
      { modifier: "eq", key: "status", value: UniqueIdentifierStatus.Complete },
      { modifier: "gt", key: "completed_at", value: daysAgo.toISOString() },
    ]).then((componentInstances) => {
      setCompletedComponentInstances(componentInstances);
      setIsLoading(false);
    });
  }, [days, lastUpdatedEpochTimestamp]);

  const sortedComponents = useMemo(() => {
    if (!components?.[0]?.counts || isLoading) return [];
    let sorted = JSON.parse(JSON.stringify(components)) as Component[]; // deep copy. can remove after hack is removed
    // Hack: Since status="PLANNED" isn't yet returned by the database on the counts object, we'll add it here
    // planned = total - WIP - complete - defective
    // TODO: Remove this once the database is updated. Refer to linear SER-976
    sorted.forEach((component: any) => {
      if (!component.counts) return;
      component.counts.planned = component.counts.total - component.counts.wip - component.counts.complete - component.counts.defective;
      // override complete count with the number of completed component instances in the selected timeframe
      component.counts.complete = completedComponentInstances.filter((instance) => instance.component_id === component.id).length;
      if (splitBy === SplitBy.Status) {
        component.counts.total = component.counts.planned + component.counts.wip + component.counts.complete + component.counts.defective;
      }
    });
    // End hack
    return sorted
      .sort((a, b) => {
        if (!a.counts) return 1;
        if (!b.counts) return -1;
        return b.counts?.total - a.counts?.total;
      })
      .filter((component) => {
        return component.is_active;
      });
  }, [components, completedComponentInstances, splitBy]);

  const largestTotalCount = useMemo(() => {
    return sortedComponents[0]?.counts?.total ?? 1;
  }, [sortedComponents]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full justify-between gap-2 border-b p-3">
        <div className="flex min-w-0 flex-grow gap-2">
          <h2 className="text-serial-palette-800 truncate font-semibold">Component Counts</h2>
          {(!components?.[0]?.counts || isLoading) && <Loader styleOverride="h-6 w-6" />}
        </div>
        <div className="flex">
          <button
            className={`btn-xs rounded-r-none border-r-0 ${splitBy === SplitBy.Status ? "serial-btn-dark" : "serial-btn-light"}`}
            onClick={() => setSplitBy(SplitBy.Status)}
          >
            Status
          </button>
          <button
            className={`btn-xs rounded-l-none ${splitBy === SplitBy.Links ? "serial-btn-dark" : "serial-btn-light"}`}
            onClick={() => setSplitBy(SplitBy.Links)}
          >
            Links
          </button>
        </div>
      </div>
      <div className="scrollbar-hide flex min-h-0 min-w-0 flex-grow flex-col gap-y-1.5 overflow-auto py-3">
        {sortedComponents.map((component, index) => {
          return (
            <div key={index} className="group flex w-full items-center gap-x-2 pl-3 pr-6">
              <ComponentAvatar component={components.find((component2) => component2.id === component.id)} size="sm" hyperlinked />
              <div className="scrollbar-hide flex h-8 min-w-0 flex-grow flex-col gap-y-0.5 overflow-x-hidden">
                <div className="transition-height flex h-7 w-full shrink-0 duration-200 group-hover:h-3">
                  {categories[splitBy].map((category, index) => {
                    const count = Number(component?.counts?.[category.key as keyof ComponentCounts] ?? 0);
                    const percent = (100 * count) / largestTotalCount;
                    if (percent !== 0)
                      return (
                        <div
                          key={index}
                          className={`flex h-full shrink-0 first:rounded-l last:rounded-r ${category.color} items-center`}
                          style={{ width: `${percent}%` }}
                        />
                      );
                  })}
                </div>
                <div className="hidden w-full overflow-visible group-hover:flex">
                  {categories[splitBy].map((category, index) => {
                    const count = Number(component?.counts?.[category.key as keyof ComponentCounts] ?? 0);
                    if (count !== 0)
                      return (
                        <div key={index} className="flex items-center gap-x-1">
                          <div className={`flex h-2 w-2 ${category.color}`} />
                          <div className="flex whitespace-nowrap pr-2 text-xs font-light">{count}</div>
                        </div>
                      );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-full flex-wrap justify-center gap-x-2 border-t py-1.5">
        {categories[splitBy].map((category, index) => {
          return (
            <div key={index} className="flex items-center gap-x-1">
              <div className={`h-3 w-3 ${category.color}`} />
              <div className="whitespace-nowrap text-xs font-light">{`${category.label}${category.label === "Complete" ? ` (last ${label})` : ""}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeComponentCountsByStatus;
