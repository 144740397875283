import React, { useContext, useMemo } from "react";

import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@shared/components/Tooltip";
import { checkNonEmpty } from "@shared/utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductionFormGenericFieldProps } from "../../types";
import { ProductionContext } from "../../ProductionProvider";
import { Dataset } from "@shared/types/databaseTypes";

const getResultTooltipBody = (dataset: Dataset) => {
  if (checkNonEmpty([dataset.lsl, dataset.usl])) {
    return (
      <span className="text-sm italic">
        After submission, this unit will be marked as <span className="font-bold text-rose-500">DEFECTIVE</span>. Value must be between{" "}
        <span className="font-bold">{dataset.lsl + (dataset.unit || "")}</span> and{" "}
        <span className="font-bold">{dataset.usl + (dataset.unit || "")}</span>.
      </span>
    );
  } else if (checkNonEmpty([dataset.usl])) {
    return (
      <span className="text-sm italic">
        After submission, this unit will be marked as <span className="font-bold text-rose-500">DEFECTIVE</span>. Value must be {"<"}{" "}
        <span className="font-bold">{dataset.usl + (dataset.unit || "")}</span>.
      </span>
    );
  } else if (checkNonEmpty([dataset.lsl])) {
    return (
      <span className="text-sm italic">
        After submission, this unit will be marked as <span className="font-bold text-rose-500">DEFECTIVE</span>. Value must be {">"}{" "}
        <span className="font-bold">{dataset.lsl + (dataset.unit || "")}</span>.
      </span>
    );
  }
};

const ProductionFormParametric: React.FC<ProductionFormGenericFieldProps> = ({ field, draftData }) => {
  const { db, handleUpdateDraftSubmission, inputRefs, handleSetFocusedField } = useContext(ProductionContext);
  const resultTooltipBody = useMemo(() => (field.dataset ? getResultTooltipBody(field.dataset) : <></>), [field.dataset]);
  const isFail = useMemo(() => {
    return (
      (field.dataset?.lsl && Number(draftData?.value) < field.dataset?.lsl) ||
      (field.dataset?.usl && Number(draftData?.value) > field.dataset?.usl)
    );
  }, [draftData]);

  return (
    <div className="flex w-full flex-col items-center justify-start ">
      <div className="flex w-full pb-1">
        <p className="text-serial-palette-500 w-full text-sm font-medium">
          {!field.is_optional && <span className="pr-1 text-rose-500">*</span>}
          {field.prompt}
          {field.dataset?.unit && " (" + field.dataset?.unit + ")"}:
        </p>
      </div>
      <div className="relative flex h-full w-full shrink-0">
        <input
          ref={(element) => (inputRefs.current[field.id] = element)}
          onFocus={() => handleSetFocusedField(field.id)}
          className={`form-input w-full shadow-none ${draftData?.isValid === true && draftData?.value ? "serial-input-valid" : draftData?.isValid === false ? "serial-input-invalid" : ""}`}
          placeholder="Data"
          value={String(draftData?.value || "")}
          onChange={(e) => handleUpdateDraftSubmission(field.id, e.target.value.trim() === "" ? undefined : { value: e.target.value })}
        />
        {db.company?.config?.show_specs_in_production_app && (
          <div tabIndex={-1} className="absolute right-2 top-[7px]">
            {resultTooltipBody && isFail && (
              <Tooltip
                position="left"
                size="md"
                override={resultTooltipBody && isFail}
                timeout={3500}
                content={
                  <span className="text-sm italic">
                    After submission, this unit will be marked as <span className="font-bold text-rose-500">DEFECTIVE</span>. Value must be{" "}
                    <span className="font-bold">checked</span> to pass.
                  </span>
                }
              >
                <FontAwesomeIcon icon={faXmarkCircle} className="text-[1rem] text-rose-600" />
              </Tooltip>
            )}
            {resultTooltipBody && !isFail && draftData?.isValid && draftData.value !== undefined && (
              <FontAwesomeIcon icon={faCheckCircle} size="xl" className="text-green-600" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductionFormParametric;
