import React, { useRef, useEffect, useState } from "react";
import Transition from "@shared/components/Transition";
import FileUploadBox from "../FileUploadBox";
import { fetchUsers, upsertUserAvatar } from "@shared/connections/supabaseUsers";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import Image from "@images/user-avatar-80.png";
import { Loader } from "../Loader";

function AvatarModal({ id, modalOpen, setModalOpen }) {
  const modalContent = useRef(null);
  const [avatar, setAvatar] = useState(Image);
  const [isLoading, setIsLoading] = useState(false);

  const setSelectedAvatar = (newAvatar) => {
    // sent file URL to redux
    const avatarUrl = URL.createObjectURL(newAvatar);
    const avatarData = {
      url: avatarUrl,
      file_name: newAvatar.name,
      type: newAvatar.type,
    };
    setAvatar(avatarData);
  };

  const handleSubmit = async (e) => {
    e.stopPropagation();
    setIsLoading(true);
    await upsertUserAvatar(avatar.url, id);
    await fetchUsers();
    setIsLoading(false);
    setModalOpen(false);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div ref={modalContent} className="max-h-full w-full max-w-lg overflow-auto rounded border bg-white">
          <div className="border-serial-palette-200 space-y-5 border-b px-5 py-5">
            {/* Header */}
            <div className="flex items-center justify-between">
              <div className="text-serial-palette-800 font-semibold">Change User Avatar</div>
              <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={() => setModalOpen(false)}>
                <div className="sr-only">Close</div>
                <svg className="h-4 w-4 fill-current">
                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                </svg>
              </button>
            </div>

            {isLoading ? (
              <div className="flex w-full items-center justify-center py-16">
                <Loader styleOverride="w-16" />
              </div>
            ) : (
              <>
                {/* File Upload for Component avatar */}
                <div className="flex w-full flex-col items-start">
                  <div className="border-serial-palette-200  text-serial-palette-400 flex h-36 w-full justify-between rounded border font-light italic leading-5">
                    {avatar.url && (
                      <div className="bg-serial-palette-50 border-serial-palette-200 ml-4 mt-4 flex h-28 w-32 shrink-0 items-center justify-center overflow-hidden rounded-md border-2">
                        <img src={avatar.url} alt="" className="h-full w-full object-cover" />
                      </div>
                    )}
                    <FileUploadBox
                      allowPaste
                      setSelectedFile={setSelectedAvatar}
                      file={avatar.url ? avatar : null}
                      fileTypeInPrompt="image"
                      iconOverride={faImage}
                    />
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    className="btn bg-serial-palette-800 hover:bg-serial-palette-600 ml-3 text-white "
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
}

export default AvatarModal;
