import { MeasureKey, MeasureType } from "@shared/measures/types";
import { ComponentType } from "@shared/types/databaseEnums";
import i18next from "@shared/i18n/config";
import { Component, Dataset, Process, TimestamptzString } from "@shared/types/databaseTypes";

export const getMeasureName = (
  key: MeasureKey,
  db: {
    components: Component[];
    processes: Process[];
    datasets: Dataset[];
  },
) => {
  const processName = db.processes.find((p) => p.id === key.process_id)?.name ?? "";
  switch (key.type) {
    case MeasureType.Status:
      return "Status";
    case MeasureType.LinkStatus:
      return "Link Status";
    case MeasureType.WorkOrder:
      return i18next.t("workOrder");
    case MeasureType.WorkOrderPhase:
      return `${i18next.t("workOrder")} Phase`;
    case MeasureType.PartNumber:
      return "Part Number";
    case MeasureType.Identifier:
      return db.components.find((c) => c.id === key.component_id)?.component_type === ComponentType.Sn ? "Serial Number" : "Lot Code";
    case MeasureType.Station:
      return `${processName} Station`;
    case MeasureType.Operator:
      return `${processName} Operator`;
    case MeasureType.Timestamp:
      return `${processName} Timestamp`;
    case MeasureType.CycleTime:
      return `${processName} Cycle Time (s)`;
    case MeasureType.ProcessResult:
      return db.processes.find((p) => p.id === key.process_id)?.name ?? "";
    case MeasureType.Link:
    case MeasureType.ParametricQualitative:
    case MeasureType.Image:
    case MeasureType.File:
    case MeasureType.Checkbox:
    case MeasureType.PassFail:
    case MeasureType.Datetime:
      return db.datasets.find((d) => d.id === key.dataset_id)?.name ?? "";
    case MeasureType.ParametricQuantitative:
      const unit = db.datasets.find((d) => d.id === key.dataset_id)?.unit;
      const datasetName = db.datasets.find((d) => d.id === key.dataset_id)?.name;
      return `${datasetName}${unit ? ` (${unit})` : ""}`;
    default:
      return "";
  }
};

export const getMeasureKeyTimeOperatorDisplayName = (
  measureTimeOperator: TimestamptzString | MeasureKey | null,
  processes: Process[],
  datasets: Dataset[],
  defaultName: string,
): string => {
  if (!measureTimeOperator) return defaultName;
  if (typeof measureTimeOperator === "string") {
    // We're dealing with a timestamp
    return new Date(measureTimeOperator).toISOString();
  }
  switch (measureTimeOperator.type) {
    case MeasureType.Link:
    case MeasureType.Datetime:
      return datasets.find((d) => d.id === measureTimeOperator.dataset_id)?.name ?? "";
    case MeasureType.Timestamp:
      const processName = processes.find((p) => p.id === measureTimeOperator.process_id)?.name;
      return `${processName ?? ""} Timestamp`;
    default:
      return "";
  }
};
