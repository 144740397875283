import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCaretRight,
  faChartLine,
  faClipboardList,
  faCubes,
  faDashboard,
  faFileContract,
  faFlask,
  faHome,
  faLocationArrow,
  faMagnifyingGlass,
  faRocket,
  faSatelliteDish,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";
import serialLogo from "@images/serial_primary_white.png";
import serialIcon from "@images/serial_submark_white.png";
import { UserRole } from "@shared/types/databaseEnums";
import { faWindowMaximize } from "@fortawesome/free-regular-svg-icons";
import { RootState } from "@shared/redux/store";
import SidebarUser from "@shared/components/SidebarUser";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { useCommand } from "@shared/context/CommandProvider";
import { useTranslation } from "react-i18next";

interface NavItem {
  name: string;
  icon: any;
  link: string | null;
  highlightedPath: string;
  observabilityEvent?: string;
  internalOnly?: boolean;
  roles?: UserRole[];
  isNew?: boolean;
}

interface NavGroup {
  groupName: string | null;
  navItems: NavItem[];
}

const navigation: NavGroup[] = [
  {
    groupName: null,
    navItems: [
      {
        name: "Search",
        icon: faMagnifyingGlass,
        link: null,
        highlightedPath: "/snlookup",
      },
    ],
  },
  {
    groupName: null,
    navItems: [
      {
        name: "Home",
        icon: faHome,
        link: "/home",
        highlightedPath: "/home",
      },
    ],
  },
  {
    groupName: null,
    navItems: [
      {
        name: "Production App",
        icon: faRocket,
        link: "/production",
        highlightedPath: "/production",
        observabilityEvent: "Navigate to Production App",
      },
    ],
  },
  {
    groupName: "Analysis",
    navItems: [
      {
        name: "Dashboards",
        icon: faDashboard,
        link: "/dashboards/yield-dashboard",
        highlightedPath: "/dashboards",
        observabilityEvent: "Navigate to Dashboards",
        internalOnly: false,
      },
      {
        name: "Grid Builder",
        icon: faTableCells,
        link: "/gridbuilder",
        highlightedPath: "/gridbuilder",
        observabilityEvent: "Navigate to Grid Builder",
      },
      {
        name: "Graph Builder",
        icon: faChartLine,
        link: "/graphs",
        highlightedPath: "/graphs",
        observabilityEvent: "Navigate to Graph Builder",
      },
      {
        name: "Tracking",
        icon: faLocationArrow,
        link: "/tracking",
        highlightedPath: "/tracking",
        observabilityEvent: "Navigate to Tracking",
      },
      {
        name: "Reports",
        icon: faFileContract,
        link: "/reports",
        highlightedPath: "/reports",
        observabilityEvent: "Navigate to Reports",
      },
    ],
  },
  {
    groupName: "Configuration",
    navItems: [
      {
        name: "Components",
        icon: faCubes,
        link: "/componentslist",
        highlightedPath: "/componentslist",
        observabilityEvent: "Navigate to Components",
      },
      {
        name: "Datasets",
        icon: faFlask,
        link: "/datasets",
        highlightedPath: "/datasets",
        observabilityEvent: "Navigate to Datasets",
        internalOnly: false,
      },
      {
        name: "Work Orders",
        icon: faClipboardList,
        link: "/workorder",
        highlightedPath: "/workorder",
        observabilityEvent: "Navigate to Work Orders",
      },
      {
        name: "Stations",
        icon: faSatelliteDish,
        link: "/stations",
        highlightedPath: "/stations",
        observabilityEvent: "Navigate to Stations",
        roles: [UserRole.Admin],
      },
    ],
  },
];

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const { triggerCommandModal } = useCommand();
  const observe = useContext(ObservabilityContext);
  const currentUser = useCurrentUser();
  const company = useSelector((state: RootState) => state.db.company);
  const role = useSelector((state: RootState) => state.auth.role);
  const { pathname } = useLocation();
  const sidebar = useRef(null);
  const { t } = useTranslation();

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarDesktopExpanded, setSidebarDesktopExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === "true",
  );
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false);
  const [sidebarHeaderHovered, setSidebarHeaderHovered] = useState(false);

  const sidebarExpanded = useMemo(() => {
    return sidebarDesktopExpanded || sidebarMobileOpen;
  }, [sidebarDesktopExpanded, sidebarMobileOpen]);

  const handleNavClick = (event: any, navItem: NavItem) => {
    event.stopPropagation();
    if (navItem.observabilityEvent)
      observe.track(navItem.observabilityEvent, {
        "UI Source": "Sidebar - " + navItem.name,
      });
    if (navItem.link) {
      if (event.metaKey) {
        window.open(navItem.link, "_blank");
        return;
      }
      navigate(navItem.link);
    }
    if (navItem.name === "Search") triggerCommandModal();
  };

  const handleSetSidebarExpanded = () => {
    setSidebarDesktopExpanded(!sidebarDesktopExpanded);
    localStorage.setItem("sidebar-expanded", (!sidebarDesktopExpanded).toString());
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = () => {
      if (!sidebar.current) return;
      setSidebarMobileOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!sidebarMobileOpen || keyCode !== 27) return;
      setSidebarMobileOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="light">
      {/* Sidebar Backdrop (mobile only) */}
      <div
        className={`fixed inset-0 z-40 bg-black transition-opacity duration-200 lg:z-auto lg:hidden ${sidebarMobileOpen ? "opacity-30" : "pointer-events-none opacity-0"}`}
      ></div>
      {/* Sidebar Button (mobile only) */}
      <button
        className="text-serial-palette-500 hover:text-serial-palette-600 absolute left-5 top-3 z-40 lg:hidden"
        onClick={(e) => {
          e.stopPropagation();
          setSidebarMobileOpen(!sidebarMobileOpen);
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      {/* Sidebar */}
      <div
        ref={sidebar}
        className={`border-serial-palette-500 absolute left-0 top-0 z-40 flex h-screen w-56 shrink-0 flex-col justify-between overflow-y-clip border-r bg-black p-3 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:translate-x-0 ${sidebarExpanded ? "lg:!w-56" : "lg:w-[74px]"} ${sidebarMobileOpen ? "translate-x-0" : "-translate-x-56"}`}
      >
        {/* Align Top */}
        <div className="scrollbar-hide flex h-[calc(100%-52px)] flex-col overflow-y-scroll">
          {/* Sidebar Header */}
          <div
            className={`hidden h-12 w-full items-center lg:flex ${sidebarExpanded ? "justify-between pr-2" : "justify-center"}`}
            onMouseEnter={() => setSidebarHeaderHovered(true)}
            onMouseLeave={() => setSidebarHeaderHovered(false)}
          >
            {sidebarExpanded && <img src={company.dark_logo ? company.dark_logo : serialLogo} className="max-h-7 max-w-[120px] pl-2.5" />}
            {!sidebarExpanded && !sidebarHeaderHovered && <img src={company.dark_icon ? company.dark_icon : serialIcon} width="50" />}
            {sidebarHeaderHovered && (
              <button className="group relative z-50 flex items-center justify-center" onClick={() => handleSetSidebarExpanded()}>
                <FontAwesomeIcon
                  className="text-serial-palette-300 group-hover:text-serial-palette-200 rotate-90"
                  size="lg"
                  icon={faWindowMaximize}
                />
                <FontAwesomeIcon
                  className={`text-serial-palette-300 group-hover:text-serial-palette-200 absolute left-1 ${sidebarExpanded && "rotate-180"}`}
                  size="xs"
                  icon={faCaretRight}
                />
              </button>
            )}
          </div>
          {/* Sidebar Header Small */}
          <div
            className="flex h-12 w-full items-center justify-between pr-2 lg:hidden"
            onMouseEnter={() => setSidebarHeaderHovered(true)}
            onMouseLeave={() => setSidebarHeaderHovered(false)}
          >
            <img src={company.dark_logo ? company.dark_logo : serialLogo} width="120" />
            <button className="relative z-50 flex items-center justify-center" onClick={() => setSidebarMobileOpen(false)}>
              <FontAwesomeIcon
                className="text-serial-palette-300 hover:text-serial-palette-200 rotate-90"
                size="lg"
                icon={faWindowMaximize}
              />
              <FontAwesomeIcon
                className="text-serial-palette-300 hover:text-serial-palette-200 absolute left-1 rotate-180"
                size="xs"
                icon={faCaretRight}
              />
            </button>
          </div>

          {/* Sidebar Content */}
          <div className="mt-3 flex flex-col gap-1 text-white">
            {!sidebarExpanded && <div className="border-serial-palette-600 my-2 border-b" />}
            {navigation.map((navGroup, index) => {
              const { groupName, navItems } = navGroup;
              const filteredItems = navItems.filter((navItem) => {
                if (navItem.internalOnly && !currentUser?.email.endsWith("@serial.io")) return false;
                if (!role || (navItem.roles && !navItem.roles.includes(role as UserRole))) return false;
                if (navItem.name === "Dashboards" && !company.config.enable_dashboards) return false;
                if (navItem.name === "Reports" && !company.config.enable_reports) return false;
                if (navItem.name === "Tracking" && !company.config.enable_location_tracking) return false;
                return true;
              });
              return (
                <div hidden={filteredItems.length === 0} key={index} className="flex flex-col gap-1">
                  {groupName &&
                    (sidebarExpanded ? (
                      <div className="text-serial-palette-400 mb-1 mt-6 pl-2 text-[11px] font-bold uppercase">{groupName}</div>
                    ) : (
                      <div className="border-serial-palette-600 my-2 border-b" />
                    ))}
                  {filteredItems.map((navItem, index) => {
                    return (
                      <button
                        key={index}
                        className={`flex items-center justify-between ${sidebarExpanded ? "py-1.5 pr-2" : "py-3"} hover:bg-serial-palette-700 rounded-md ${pathname.includes(navItem.highlightedPath) && "bg-serial-palette-700"}`}
                        onClick={(event) => handleNavClick(event, navItem)}
                      >
                        <div className="flex w-full items-center">
                          <div className={`flex ${sidebarExpanded ? "w-10" : "w-full"} justify-center`}>
                            <FontAwesomeIcon icon={navItem.icon} size={sidebarExpanded ? "sm" : undefined} />
                          </div>
                          {navItem.name === "Work Orders" && sidebarExpanded && <div className="text-sm font-light">{t("workOrders")}</div>}
                          {sidebarExpanded && navItem.name !== "Work Orders" && <div className="text-sm font-light">{navItem.name}</div>}
                        </div>
                        {navItem.isNew && sidebarExpanded && (
                          <div
                            className={`py-0.5 ${pathname.includes(navItem.highlightedPath) ? "" : "opacity-50"} rounded bg-amber-300 px-2 text-xs font-bold text-black`}
                          >
                            NEW
                          </div>
                        )}
                        {navItem.name === "Search" && sidebarExpanded && (
                          <span className="bg-serial-palette-700 rounded px-1 py-0.5 text-xs font-light opacity-70 duration-200">⌘K</span>
                        )}
                      </button>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={`absolute bottom-3 left-3 z-50 transition-all duration-200 ease-in-out ${sidebarMobileOpen ? "translate-x-0" : "-translate-x-56"} lg:translate-x-0`}
      >
        <SidebarUser sidebarExpanded={sidebarExpanded} />
      </div>
    </div>
  );
};

export default Sidebar;
