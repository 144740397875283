import { useState } from "react";
import { useSelector } from "react-redux";
import TypeTag from "@shared/components/TypeTag";
import { useNavigate } from "react-router-dom";
import UserAvatar from "@shared/components/UserAvatar";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { UserRole } from "@shared/types/databaseEnums";
import moment from "moment";
import { Component } from "@shared/types/databaseTypes";
import { RootState } from "@shared/redux/store";

interface ComponentCardProps {
  component: Component;
  handleEdit: (componentId: string) => void;
}

function ComponentCard({ component, handleEdit }: ComponentCardProps) {
  const [showEdit, setShowEdit] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const navigate = useNavigate();
  const role = useSelector((state: RootState) => state.auth.role);
  const users = useSelector((state: RootState) => state.db.users);
  const lastEditedUser = users.find((user) => user.supabase_uid === component.last_edited_user_id);

  return (
    <button
      className={`border-serial-palette-200 hover:border-serial-palette-300 relative col-span-full flex flex-col justify-between rounded-md border bg-white  px-3 py-3  sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 ${!component.is_active && "opacity-60"}`}
      onClick={() => {
        navigate(`/component/${component.id}`);
      }}
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
    >
      <div className="flex h-full w-full pl-1 ">
        {/* Left Text */}
        <div className="flex h-full w-3/5 flex-col">
          {/* Part Name */}
          <div className="text-serial-palette-500 text-left text-lg font-semibold">{component.name}</div>
          {!component.is_active && <span className="text-left text-sm font-light italic text-red-300">Inactive</span>}
          {/* Data Tag */}
          <div className="mt-1 w-12 rounded-full text-center font-medium">
            <TypeTag type={component.component_type} className="text-[10px]"></TypeTag>
          </div>
        </div>

        <div className="mr-1 mt-1 flex w-2/5 justify-end ">
          <ComponentAvatar component={component} size="lg" />
        </div>
      </div>

      <div className="mt-2 inline-flex space-x-2 pl-1 ">
        <p className="mt-1 text-left text-sm">Last Edited By </p>
        <div className="relative" onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
          {tooltipOpen && (
            <div className="bg-serial-palette-700 absolute bottom-full left-1/2 z-10 mb-2 -translate-x-1/2 transform whitespace-nowrap rounded px-2 py-1 text-left text-sm text-white">
              <div>
                {lastEditedUser?.first_name} {lastEditedUser?.last_name}
              </div>
              <div>{moment(component.last_edited_at).format("MM/DD/YY HH:mma")}</div>
            </div>
          )}
          <UserAvatar user={lastEditedUser} size="sm" />
        </div>
      </div>
      {role === UserRole.Admin && showEdit && (
        <div
          className="btn bg-serial-palette-50 border-serial-palette-200 hover:border-serial-palette-300 absolute bottom-4 right-4 h-10 w-10 rounded-full hover:border-2"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleEdit(component.id);
          }}
        >
          <svg className=" text-serial-palette-500 h-4 w-4 shrink-0 fill-current" viewBox="0 0 16 16">
            <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
          </svg>
        </div>
      )}
    </button>
  );
}

export default ComponentCard;
