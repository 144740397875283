import { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import Header from "@shared/components/Header";
import Sidebar from "@shared/components/Sidebar";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import Dashboard from "./components/Dashboard";
import { DashboardContextProps } from "./types";
import { defaultContext } from "./constants";
import useDashboards from "./hooks/useDashboards";
import DashboardEditModal from "./components/DashboardEditModal";
import DashboardList from "./components/DashboardsList";

const defaultBreadcrumbs = [
  {
    label: "Dashboards",
    link: "/dashboards/yield-dashboard",
  },
];

enum DashboardsMode {
  List = "LIST",
  Dashboard = "DASHBOARD",
}

export const DashboardContext = createContext<DashboardContextProps>(defaultContext);

function Dashboards() {
  const location = useLocation();

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(defaultBreadcrumbs);
  const [mode, setMode] = useState<DashboardsMode>(DashboardsMode.List);

  const {
    dashboards,
    selectedDashboardId,
    setSelectedDashboardId,
    handleCreateDashboard,
    handleDuplicateDashboard,
    handleUpdateDashboard,
    handleDeleteDashboard,
    handleAddInsightToSelectedDashboard,
    handleRemoveInsightFromSelectedDashboard,
    customInsights,
    targetDashboardRow,
    setTargetDashboardRow,
    insightModalOpen,
    setInsightModalOpen,
    dashboardEditModalOpen,
    setDashboardEditModalOpen,
    handleCreateCustomInsight,
    handleUpdateCustomInsight,
    handleDeleteCustomInsight,
    isNewDashboard,
  } = useDashboards();

  // set dashboard page state to details view if the URL contains '/workorder/:id' or '/workorder/new'
  useEffect(() => {
    if (location.pathname.includes("/dashboards/")) {
      const dashboardId = location.pathname.split("/dashboards/")[1];
      const newSelectedDashboard = dashboards.find((dashboard) => dashboard.id === dashboardId);
      setSelectedDashboardId(newSelectedDashboard?.id ?? null);
      // Update breadcrumbs if the dashboard exists
      if (newSelectedDashboard) {
        const newBreadcrumbs = [
          ...defaultBreadcrumbs,
          {
            label: newSelectedDashboard.name ?? "New Dashboard",
            link: `/dashboards/${dashboardId}`,
          },
        ];
        setBreadcrumbs(newBreadcrumbs);
        setMode(DashboardsMode.Dashboard);
      }
    } else {
      setBreadcrumbs(defaultBreadcrumbs);
      setSelectedDashboardId(null);
      setMode(DashboardsMode.List);
    }
  }, [location.pathname, dashboards]);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar />
      {/* Content area */}
      <div className="scrollbar-hide relative flex h-full flex-1 flex-col overflow-x-hidden">
        {/*  Site header */}
        <Header breadcrumbs={breadcrumbs} />
        {/* Dashboard Body */}
        <DashboardContext.Provider
          value={{
            dashboards,
            selectedDashboardId,
            setSelectedDashboardId,
            handleCreateDashboard,
            handleDuplicateDashboard,
            handleUpdateDashboard,
            handleDeleteDashboard,
            handleAddInsightToSelectedDashboard,
            handleRemoveInsightFromSelectedDashboard,
            customInsights,
            targetDashboardRow,
            setTargetDashboardRow,
            insightModalOpen,
            setInsightModalOpen,
            dashboardEditModalOpen,
            setDashboardEditModalOpen,
            handleCreateCustomInsight,
            handleUpdateCustomInsight,
            handleDeleteCustomInsight,
            isNewDashboard,
          }}
        >
          {mode === DashboardsMode.Dashboard && <Dashboard />}
          {mode === DashboardsMode.List && <DashboardList />}
          <DashboardEditModal />
        </DashboardContext.Provider>
      </div>
    </div>
  );
}

export default Dashboards;
