import useMeasures, { MeasuresProvider } from "@shared/measures/MeasuresProvider";
import MeasuresGrid from "@shared/measures/components/grid/MeasuresGrid";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useEffect, useMemo, useState } from "react";
import { faBinoculars, faFileCsv, faRefresh, faSave } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { MeasuresGridRowData } from "@shared/measures/components/grid/types";
import { MeasureKey } from "@shared/measures/types";
import { getMeasureName } from "@shared/measures/helpers/naming";
import { ReportTemplateGridBuilderView } from "@shared/types/databaseTypes";
import { useNavigate } from "react-router-dom";
import ReportTemplateEditModal from "@shared/components/modals/ReportTemplateEditModal";
import { v4 as uuidv4 } from "uuid";
import { ReportTemplateType } from "@shared/types/databaseEnums";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import MeasuresToolbar, { MeasuresToolbarDropdownOption } from "@shared/measures/components/MeasuresToolbar";

function GridBuilderGrid({ view }: { view?: ReportTemplateGridBuilderView }) {
  const [selectedComponentId, setSelectedComponentId] = useState<string | undefined>(undefined);
  return (
    <MeasuresProvider componentId={selectedComponentId}>
      <GridBuilderGridWithContext selectedComponentId={selectedComponentId} setSelectedComponentId={setSelectedComponentId} view={view} />
    </MeasuresProvider>
  );
}

function GridBuilderGridWithContext({
  selectedComponentId,
  setSelectedComponentId,
  view,
}: {
  selectedComponentId: string | undefined;
  setSelectedComponentId: (value: string | undefined) => void;
  view?: ReportTemplateGridBuilderView;
}) {
  const { gridApi } = useMeasures();
  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const company = useSelector((state: RootState) => state.db.company);
  const { handleReset, handleLoadFromReportTemplateConfig, reportTemplateConfig } = useMeasures();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [reportTemplateModalOpen, setReportTemplateModalOpen] = useState<boolean>(false);

  const selectedComponent = useMemo(() => {
    return components.find((component) => component.id === selectedComponentId);
  }, [components, selectedComponentId]);

  const handleExport = () => {
    gridApi?.exportDataAsCsv({
      fileName: `Grid Builder Export (${selectedComponent?.name}) ${moment().format("YYYY-MM-DD_HH-mm-ss")}`,
      processCellCallback: ({ column, node }) => {
        const colDef = column.getColDef();
        const data: MeasuresGridRowData = node?.data;
        const measureKeyHash = colDef?.field ?? "";
        return data?.measuresValuesByHash[measureKeyHash]?.measureAggregation?.formattedValue ?? "";
      },
      processHeaderCallback: (params) => {
        const measureKey: MeasureKey = params.column.getColDef().cellRendererParams.measureKey;
        const columnName = getMeasureName(measureKey, { components, processes, datasets });
        return columnName;
      },
    });
  };

  const draftSavedView: ReportTemplateGridBuilderView | null = useMemo(() => {
    if (!currentUser || !company || !reportTemplateConfig) return null;
    if (view) {
      return {
        ...view,
        config: reportTemplateConfig,
      };
    }
    return {
      id: uuidv4(),
      revision: 1,
      company_id: company.id,
      type: ReportTemplateType.GridBuilderView,
      name: "New Grid View",
      created_at: moment().toISOString(),
      created_by: currentUser.supabase_uid,
      is_public: false,
      shared_with: {},
      is_latest_revision: true,
      config: reportTemplateConfig,
    };
  }, [reportTemplateConfig, view]);

  const dropdownOptions: MeasuresToolbarDropdownOption[] = useMemo(() => {
    const options: MeasuresToolbarDropdownOption[] = [
      {
        label: "Export",
        icon: faFileCsv,
        callback: () => {
          handleExport();
        },
      },
      {
        label: "Views",
        icon: faBinoculars,
        callback: () => {
          navigate("/gridbuilder/views");
        },
      },
      {
        label: "Save View",
        icon: faSave,
        callback: () => {
          setReportTemplateModalOpen(true);
        },
      },
      {
        label: "Reset",
        icon: faRefresh,
        callback: () => {
          handleReset();
          setSelectedComponentId(undefined);
          navigate("/gridbuilder");
        },
        className: "!text-red-500",
      },
    ];
    return options;
  }, [handleExport, handleReset]);

  useEffect(() => {
    if (view) {
      setSelectedComponentId(view.config.component_id);
      // setting the selected component id resets the grid, therefore wait until the changes propagate before loading the view
      setTimeout(() => {
        handleLoadFromReportTemplateConfig(view.config);
      }, 0);
    }
  }, [view?.id]);

  return (
    <div className="flex h-full w-full flex-col">
      {draftSavedView && (
        <ReportTemplateEditModal
          open={reportTemplateModalOpen}
          setOpen={setReportTemplateModalOpen}
          reportTemplate={draftSavedView}
          onSave={() => navigate(`/gridbuilder/view/${draftSavedView.id}`)}
          title={view ? "Update View" : "Create New View"}
        />
      )}
      <MeasuresToolbar
        selectedComponentId={selectedComponentId}
        setSelectedComponentId={setSelectedComponentId}
        dropdownOptions={dropdownOptions}
      />
      <div className="flex min-h-0 min-w-0 flex-grow">
        <MeasuresGrid className="overflow-hidden border-t" configurable={true} />
      </div>
    </div>
  );
}

export default GridBuilderGrid;
