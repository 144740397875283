import React, { useEffect, useMemo, useState } from "react";
import WorkOrderOverview from "./WorkOrderOverview";
import WorkOrderActivity from "./WorkOrderActivity";
import WorkOrderConfiguration from "./WorkOrderConfiguration";
import WorkOrderApprovals from "./WorkOrderApprovals";
import WorkOrderStatusBar from "./WorkOrderStatusBar";
import { UniqueIdentifier, WorkOrder } from "@shared/types/databaseTypes";
import { fetchWorkOrderProcessEntries } from "../connections/supabaseWorkOrders";

interface WorkOrderDetailsProps {
  workOrder: WorkOrder;
}

const WorkOrderDetails: React.FC<WorkOrderDetailsProps> = ({ workOrder }) => {
  const [uniqueIdentifiers, setUniqueIdentifiers] = useState<UniqueIdentifier[]>([]);

  const isNew = useMemo(() => {
    return location.pathname.includes("/workorder/new");
  }, [location.pathname]);

  useEffect(() => {
    const fetchIdentifiers = async () => {
      const identifierList = await fetchWorkOrderProcessEntries(workOrder.id);
      setUniqueIdentifiers(identifierList);
    };
    if (!isNew) {
      fetchIdentifiers();
    }
  }, [workOrder]);

  return (
    <div className="flex h-fit w-full flex-col p-8">
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-5 lg:gap-4">
        <div className="lg:col-span-5">
          <WorkOrderStatusBar workOrder={workOrder} unitList={uniqueIdentifiers} />
        </div>
        <div className="lg:col-span-3 ">
          <WorkOrderOverview workOrder={workOrder} unitList={uniqueIdentifiers} />
        </div>
        <div className={`lg:col-span-2 lg:row-span-2 lg:max-h-[598px] ${isNew && "opacity-40"}`}>
          <WorkOrderActivity workOrder={workOrder} />
        </div>
        <div className={`lg:col-span-3 ${isNew && "opacity-40"}`}>
          <WorkOrderApprovals workOrder={workOrder} />
        </div>
        <div className={`lg:col-span-5 ${isNew && "opacity-40"}`}>
          <WorkOrderConfiguration workOrder={workOrder} unitList={uniqueIdentifiers} />
        </div>
      </div>
    </div>
  );
};

export default WorkOrderDetails;
