import { useContext, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { DashboardInsightContext } from "../DashboardInsightCard";
import { fetchYieldByTime, DailyData } from "../../connections/supabaseYield";
import { calculateYields, PerProcessYields } from "../../utils";
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import { tailwindConfig } from "@shared/utils/helpers";
import moment from "moment";

Chart.register(LineController, LinearScale, LineElement, Filler, Tooltip, PointElement, TimeScale);

interface ChartData {
  dates: string[];
  perProcessData: PerProcessYields;
}

const YieldByProcess: React.FC<void> = () => {
  const colors = [
    "red",
    "orange",
    "amber",
    "yellow",
    "lime",
    "emerald",
    "teal",
    "cyan",
    "sky",
    "indigo",
    "violet",
    "purple",
    "fuschia",
    "pink",
    "rose",
  ];

  const canvas = useRef(null);

  {
    /* Get component from context */
  }
  const context = useContext(DashboardInsightContext);
  const componentId = context.filters.componentId;
  const workOrderId = context.filters.workOrderId ?? null;
  const startDate = context.filters.dateRange?.from;
  const endDate = context.filters.dateRange?.to;
  const processes = useSelector((state: RootState) => state.db.processes);

  const [chartData, setChartData] = useState<ChartData>({ dates: [], perProcessData: {} });

  useEffect(() => {
    const fetchData = async () => {
      context?.setIsLoading(true);

      const yieldData: DailyData[] = (await fetchYieldByTime(componentId, workOrderId, startDate, endDate))
        .map((yieldData) => {
          const processId = yieldData.process_id;
          const process = processes.find((p) => p.id === processId);
          if (process && process.is_mandatory) {
            return {
              ...yieldData,
              processName: process.name,
              isMandatory: process.is_mandatory,
            };
          }
          return yieldData;
        })
        .filter((yieldData) => yieldData.isMandatory);

      const { perProcessYields } = calculateYields(yieldData);

      Object.keys(perProcessYields).forEach((processId) => {
        if (perProcessYields[processId].length === 0) {
          delete perProcessYields[processId];
        }
      });

      const uniqueDates = [...new Set(yieldData.map((d) => d.date))];

      setChartData({
        dates: uniqueDates,
        perProcessData: perProcessYields,
      });
      context?.setIsLoading(false);
    };

    if (componentId && startDate && endDate) {
      fetchData();
    }
  }, [componentId, startDate, endDate, workOrderId]);

  useEffect(() => {
    let datasets = [];
    for (const processId in chartData.perProcessData) {
      const color = colors[Math.floor(Math.random() * colors.length)];
      const process = processes.find((p) => p.id === processId);
      const processData = chartData.perProcessData[processId];
      if (process) {
        const colorShade500 = (tailwindConfig().theme?.colors as any)[color]?.[500];
        const colorShade300 = (tailwindConfig().theme?.colors as any)[color]?.[300];
        datasets.push({
          label: `${process.name} All Pass Yield`,
          data: processData.map((d) => {
            return { x: d.date, y: d.all_pass_yield };
          }),
          borderColor: colorShade500,
          backgroundColor: colorShade500,
          pointRadius: 3,
          borderWidth: 2,
        });
        datasets.push({
          label: `${process.name} First Pass Yield`,
          data: processData.map((d) => {
            return { x: d.date, y: d.first_pass_yield };
          }),
          borderColor: colorShade300,
          backgroundColor: colorShade300,
          pointRadius: 3,
          borderWidth: 2,
        });
      }
    }

    const ctx = canvas.current;

    if (ctx === null) {
      return;
    }

    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: chartData.dates,
        datasets: datasets,
      },
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 50,
          },
        },
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 10,
              format: {
                style: "percent",
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
            type: "time",
            time: {
              parser: "YYYY-MM-DD",
              unit: "day",
            },
            display: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => moment(context[0].label).format("DD MMMM YYYY"),
              label: (context) => {
                const yieldLabel = context.dataset.label + ": " + `${(context.parsed.y * 100).toFixed(2)}%`;
                return [yieldLabel];
              },
            },
          },
          legend: {
            display: true,
            position: "right",
            align: "start",
            labels: {
              usePointStyle: true,
              boxWidth: 10,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
      },
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, processes]);

  return (
    <div className="border-serial-palette-200 col-span-full flex h-full flex-col">
      <div className="mb-10 h-full w-full grow">
        {componentId === null && startDate === null && endDate === null ? (
          <div className="flex h-full w-full items-center justify-center text-base">
            Select a component and time range to generate a yield graph
          </div>
        ) : (
          <div className="h-[440px] px-5 py-1">
            <canvas ref={canvas}></canvas>
          </div>
        )}
      </div>
    </div>
  );
};

export default YieldByProcess;
