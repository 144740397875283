import React, { useState } from "react";
import TypeTag from "@shared/components/TypeTag";
import { StationWithLinksAndProcessEntries } from "../types";
import { formatTimeAgo } from "@shared/utils/time";

interface StationSelectorProps {
  station: StationWithLinksAndProcessEntries;
  selectedStation: StationWithLinksAndProcessEntries | null;
}

const StationSelector: React.FC<StationSelectorProps> = ({ station, selectedStation }) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  return (
    <div
      className={`border-t-1 border-serial-palette-100 relative flex w-full flex-col px-6 py-4 ${(selectedStation?.id === station?.id || mouseOver) && "bg-serial-palette-50"}`}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {(selectedStation?.id === station?.id || mouseOver) && (
        <div className="bg-serial-palette-500 absolute left-0 top-1/4 h-1/2 w-[6px] rounded-r" />
      )}
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-3">
          <span className="text-left font-bold">{station?.name}</span>
        </div>
        <TypeTag
          type={station?.is_active ? (station?.is_locked ? "LOCKED" : "ACTIVE") : "RETIRED"}
          className={`w-24 text-xs ${!station?.is_locked && "pr-4"}`}
        />
      </div>
      <div className="mt-1 flex w-full">
        <span className="text-serial-palette-400 text-sm">
          {`${station.latest_process_entries?.length ? `Last submission ${formatTimeAgo(station.latest_process_entries[0]?.timestamp)}` : "No submissions"}`}
        </span>
      </div>
    </div>
  );
};

export default StationSelector;
