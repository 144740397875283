import { useContext, useRef, useState } from "react";
import { ProcessBuilderContext } from "./ProcessBuilderProvider";
import Transition from "@shared/components/Transition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAltH,
  faEllipsis,
  faFileExport,
  faGear,
  faHistory,
  faMagicWandSparkles,
  faUpload,
  faStream,
} from "@fortawesome/free-solid-svg-icons";
import ProcessBuilderBody from "./components/ProcessBuilderBody";
import ProcessBuilderRevisionHistory from "./components/ProcessBuilderRevisionHistory";
import ProcessBuilderSettings from "./components/ProcessBuilderSettings";
import ProcessBuilderApi from "./components/ProcessBuilderApi";
import Banner2 from "@shared/components/Banner2";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import ProcessBuilderWorkInstructionImport from "./components/ProcessBuilderWorkInstructionImport";
import ProcessBuilderExport from "./components/ProcessBuilderExport";
import { ProcessWithReferences } from "@shared/types/databaseTypes";
import { getProcessWithReferences } from "@shared/connections/supabaseProcess";
import ProcessBuilderWorkInstructionImportVideoAi from "./components/ProcessBuilderWorkInstructionImportVideoAi";
import { ImportType } from "./types";
import VersionBlock from "@shared/components/VersionBlock";
import ProcessBuilderAutoParsing from "./components/ProcessBuilderAutoParsing";
import Button from "@shared/components/primitives/Button";
import CopyToClipboard from "@shared/components/CopyToClipboard";

const ProcessBuilderModal: React.FC = () => {
  const {
    hasChanged,
    readOnly,
    isLoading,
    processBuilderOpen,
    draftProcess,
    revisionHistoryOpen,
    setRevisionHistoryOpen,
    processSettingsOpen,
    setProcessSettingsOpen,
    importSidebarOpen,
    setImportSidebarOpen,
    enablePreviewMode,
    setEnablePreviewMode,
    enableExportMode,
    setEnableExportMode,
    schemaDesignerOpen,
    setSchemaDesignerOpen,
    handleClose,
    handleSave,
    errorBanner,
    setErrorBanner,
  } = useContext(ProcessBuilderContext);

  const settingsRef = useRef<HTMLDivElement>(null);
  const revisionHistoryRef = useRef<HTMLDivElement>(null);

  const [latestSavedProcess, setLatestSavedProcess] = useState<ProcessWithReferences | null>(null);
  const [importType, setImportType] = useState<ImportType>(ImportType.Markdown);

  const handleCloseSettingsAndRevisionHistory = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // only close revision history if the click is not on the revision history div
    if (!revisionHistoryRef.current?.contains(e.target as Node)) {
      setRevisionHistoryOpen(false);
    }
    // only close settings if the click is not on the settings div
    if (!settingsRef.current?.contains(e.target as Node)) {
      setProcessSettingsOpen(false);
    }
  };

  const handleExportProcess = async () => {
    if (!draftProcess) return;
    // get last process saved
    const { data, error } = await getProcessWithReferences(draftProcess.id, undefined, true);
    if (error || !data) {
      setErrorBanner({ message: error ?? "Could not load latest saved process", type: "error" });
      return;
    } else {
      setErrorBanner({
        message: "Note: If you have unsaved changes, you must save the process before your changes are displayed in the PDF",
        type: "warning",
      });
      setTimeout(() => setErrorBanner({ message: null, type: "success" }), 5000); // turn off warning after 5 seconds
    }
    setLatestSavedProcess(data);
    setEnableExportMode(true);
  };

  return (
    <div className="flex h-screen min-h-0 min-w-0 flex-grow flex-col overflow-hidden">
      {/* Modal backdrop */}
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={processBuilderOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        appear={null}
      />
      {/* Modal dialog */}
      <Transition
        id="process-builder-modal"
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        show={processBuilderOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
        appear={null}
      >
        <div className="flex h-full max-h-full w-full flex-col overflow-hidden rounded-lg border bg-white lg:h-[100%] 2xl:max-w-[85vw]">
          <div className="flex h-14 shrink-0 items-center justify-between border-b bg-white px-3">
            <div className="flex w-2/3 items-center gap-x-2 pl-2">
              <div
                className="text-serial-palette-800 cursor-pointer truncate font-bold"
                onClick={() => {
                  setProcessSettingsOpen(!processSettingsOpen);
                  setRevisionHistoryOpen(false);
                }}
              >
                {draftProcess?.name ?? "New Process"}
              </div>
              <button onClick={() => setRevisionHistoryOpen(!revisionHistoryOpen)}>
                <VersionBlock
                  version={draftProcess?.revision.toString() ?? "0"}
                  size="sm"
                  color={draftProcess?.approved ? "green" : "grey"}
                />
              </button>
            </div>
            <div className="hidden items-center justify-center gap-x-0.5 lg:flex">
              {!importSidebarOpen && !draftProcess?.use_api && (
                <div className="mr-1.5 flex h-full flex-nowrap items-center gap-x-2">
                  <p className="text-serial-palette-500 pt-0.5 text-sm">Preview Mode</p>
                  <div className="form-switch">
                    <input
                      type="checkbox"
                      id="process-builder-switch-enable-preview"
                      className="sr-only"
                      checked={enablePreviewMode}
                      onChange={() => setEnablePreviewMode(!enablePreviewMode)}
                    />
                    <label className="bg-serial-palette-200" htmlFor="process-builder-switch-enable-preview">
                      <span className="bg-white " aria-hidden="true"></span>
                      <span className="sr-only">Switch label</span>
                    </label>
                  </div>
                </div>
              )}
              {!readOnly && (
                <DropdownCustom
                  align="right"
                  className="flex h-full"
                  dropdownClassName="pb-1.5 z-30 w-[160px]"
                  keepOpen={true}
                  closeOnMouseLeave={true}
                  buttonContents={
                    <div className="btn serial-btn-light hover:border-serial-palette-200 w-8 border-white">
                      <FontAwesomeIcon icon={faEllipsis} size="lg" />
                    </div>
                  }
                >
                  <button
                    className="text-serial-palette-600 hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                    onClick={() => {
                      setProcessSettingsOpen(!processSettingsOpen);
                      setRevisionHistoryOpen(false);
                      setImportSidebarOpen(false);
                      setSchemaDesignerOpen(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faGear} className="text-serial-palette-500" />
                    <div className="">Settings</div>
                  </button>
                  <button
                    className="text-serial-palette-600 hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                    onClick={() => {
                      setRevisionHistoryOpen(!revisionHistoryOpen);
                      setProcessSettingsOpen(false);
                      setImportSidebarOpen(false);
                      setSchemaDesignerOpen(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faHistory} className="text-serial-palette-500" />
                    <div className="">History</div>
                  </button>
                  {!draftProcess?.use_api && (
                    <button
                      className="text-serial-palette-600 hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                      onClick={() => {
                        setImportSidebarOpen(true);
                        setImportType(ImportType.Markdown);
                        setProcessSettingsOpen(false);
                        setRevisionHistoryOpen(false);
                        setEnablePreviewMode(false);
                        setSchemaDesignerOpen(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faUpload} className="text-serial-palette-500" />
                      <div className="">Import Notion</div>
                    </button>
                  )}
                  {!draftProcess?.use_api && (
                    <button
                      className="text-serial-palette-600 hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                      onClick={() => {
                        setImportSidebarOpen(true);
                        setImportType(ImportType.Video);
                        setProcessSettingsOpen(false);
                        setRevisionHistoryOpen(false);
                        setEnablePreviewMode(false);
                        setSchemaDesignerOpen(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faMagicWandSparkles} className="text-serial-palette-500" />
                      <div className="">Convert Video</div>
                    </button>
                  )}
                  {!draftProcess?.use_api && (
                    <button
                      className="text-serial-palette-600 hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                      onClick={() => {
                        setImportSidebarOpen(false);
                        setProcessSettingsOpen(false);
                        setRevisionHistoryOpen(false);
                        setEnablePreviewMode(false);
                        handleExportProcess();
                        setSchemaDesignerOpen(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faFileExport} className="text-serial-palette-500" />
                      <div className="">Export</div>
                    </button>
                  )}
                  <button
                    className="text-serial-palette-600 hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                    onClick={() => {
                      setSchemaDesignerOpen(true);
                      setImportSidebarOpen(false);
                      setProcessSettingsOpen(false);
                      setRevisionHistoryOpen(false);
                      setEnablePreviewMode(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faStream} className="text-serial-palette-500" />
                    <div className="">Auto Parsing</div>
                  </button>
                </DropdownCustom>
              )}
            </div>
          </div>
          {errorBanner.message && (
            <div className="bg-serial-palette-100 flex w-full border-b p-2">
              <Banner2
                className="w-full"
                type={errorBanner.type}
                open={errorBanner.message !== null}
                setOpen={() => setErrorBanner({ message: null, type: "success" })}
              >
                {errorBanner.message}
              </Banner2>
            </div>
          )}
          {readOnly && (
            <div className="bg-serial-palette-100 flex w-full border-b p-2">
              <Banner2 className="w-full" type={"info"} open hideX>
                Member users of Serial cannot edit processes. Please contact an admin to make changes.
              </Banner2>
            </div>
          )}
          <div
            className="bg-serial-palette-50 relative hidden min-h-0 min-w-0 flex-grow lg:flex"
            onClick={(e) => handleCloseSettingsAndRevisionHistory(e)}
          >
            {draftProcess?.use_api && !enableExportMode && <ProcessBuilderApi />}
            {!draftProcess?.use_api && !enableExportMode && <ProcessBuilderBody />}
            {enableExportMode && <ProcessBuilderExport process={latestSavedProcess} />}
            <div
              className={`light absolute z-10 h-full w-full bg-black ${!isLoading && "transition-opacity duration-500"} ${processSettingsOpen || revisionHistoryOpen || isLoading || schemaDesignerOpen ? "visible opacity-60" : "invisible opacity-0"}`}
            />
            {processSettingsOpen && (
              <div ref={settingsRef} className="absolute left-2 top-2 z-20 h-4/5 w-full max-w-sm rounded-md border bg-white p-3 shadow">
                <ProcessBuilderSettings />
              </div>
            )}
            <div
              ref={revisionHistoryRef}
              className={`absolute right-0 top-0 z-20 h-full w-2/3 border-l bg-white transition-transform duration-200 ease-in-out ${!revisionHistoryOpen && "translate-x-full"}`}
            >
              <ProcessBuilderRevisionHistory />
            </div>
            <div
              className={`bg-serial-palette-50 absolute left-0 top-0 z-20 h-full w-5/6 transition-transform duration-200 ease-in-out ${!schemaDesignerOpen && "-translate-x-full"}`}
            >
              <ProcessBuilderAutoParsing />
            </div>
            <div
              className={`absolute right-0 top-0 z-10 h-full w-[calc(50%-110px)] border-l bg-white transition-transform duration-200 ease-in-out xl:w-[calc(33%-65px)] ${!importSidebarOpen && "translate-x-full"}`}
            >
              {importType === ImportType.Video && <ProcessBuilderWorkInstructionImportVideoAi />}
              {importType === ImportType.Markdown && <ProcessBuilderWorkInstructionImport />}
            </div>
          </div>
          <div className="flex min-h-0 min-w-0 flex-grow flex-col items-center justify-center gap-y-3 bg-white lg:hidden">
            <div className="max-w-md px-4 text-center">
              The process builder works best on larger screens.
              <br />
              Please open this process on a desktop browser or maximize the window to edit.
            </div>
            <FontAwesomeIcon icon={faArrowsAltH} size="3x" />
          </div>
          <div className="z-10 flex h-10 shrink-0 items-center justify-between border-t px-2">
            <div className="text-serial-palette-300 flex text-xs">
              <span className="pl-2 pr-1.5 font-medium">Process ID:</span>
              <span>{draftProcess?.id}</span>
              <CopyToClipboard text={draftProcess?.id ?? ""} noTooltip />
            </div>
            <div className="flex items-center gap-x-1.5">
              <Button variant="outline" size="sm" onClick={() => handleClose()}>
                {!readOnly ? "Cancel" : "Close"}
              </Button>
              {!readOnly && (
                <Button disabled={!hasChanged} isLoading={isLoading} size="sm" onClick={() => handleSave()}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ProcessBuilderModal;
