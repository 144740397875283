import React, { useRef, useEffect, useMemo } from "react";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  TooltipCallbacks,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { tailwindConfig } from "@shared/utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { Chart } from "react-chartjs-2";
import { cn } from "@shared/utils/tailwind";
import { ChartAxis } from "./types";
import Button from "@shared/components/primitives/Button";
import { withDefaultSizing } from "./helpers";

ChartJS.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip, Legend, zoomPlugin);

export interface LineChartDatapoint {
  x: string | number;
  y: {
    [seriesId: string]: {
      value: number;
      title: string;
      label: string;
    };
  };
}

export interface LineChartSeries {
  id: string;
  label: string;
  color?: string;
  style?: "solid" | "dashed" | "dotted";
}

export interface LineChartProps {
  series: LineChartSeries[];
  data: LineChartDatapoint[];
  size?: "sm" | "md" | "lg";
  xAxis?: ChartAxis;
  yAxis?: ChartAxis;
  legend?: boolean;
  grid?: boolean;
  zoom?: boolean;
  tooltip?: boolean | Partial<TooltipCallbacks<"line">>;
  focusedDatapointIndex?: number | null;
  setFocusedDatapointIndex?: React.Dispatch<React.SetStateAction<number | null>>;
  className?: string;
}

const LineChart: React.FC<LineChartProps> = ({
  series,
  data,
  size = "sm",
  xAxis,
  yAxis,
  legend = true,
  grid = true,
  zoom = true,
  tooltip = true,
  focusedDatapointIndex,
  setFocusedDatapointIndex,
  className,
}) => {
  const chartRef = useRef<ChartJS<"line">>(null);

  // set focused index on hover
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;
    const chartTooltip = tooltip ? chart.tooltip : null;
    const chartArea = chart.chartArea;
    if (focusedDatapointIndex !== null && focusedDatapointIndex !== undefined) {
      chartTooltip?.setActiveElements([{ datasetIndex: 0, index: focusedDatapointIndex }], {
        x: chartArea.left + chartArea.width / 2,
        y: chartArea.top + chartArea.height / 2,
      });
      chart.setActiveElements([{ datasetIndex: 0, index: focusedDatapointIndex }]);
    } else {
      chartTooltip?.setActiveElements([], { x: 0, y: 0 });
      chart.setActiveElements([]);
    }
    chart.update();
  }, [focusedDatapointIndex]);

  const { chartData, chartOptions } = useMemo(() => {
    const chartData: ChartData<"line"> = {
      labels: data.map((datapoint) => datapoint.x),
      datasets: series.map((seriesItem) => ({
        label: seriesItem.label,
        data: data.map((datapoint) => datapoint.y[seriesItem.id].value),
        borderColor: seriesItem.color ?? tailwindConfig().theme?.colors?.blue?.[500],
        backgroundColor: "transparent",
        pointHoverRadius: 6,
        pointHoverBorderWidth: 3,
        borderDash: seriesItem.style === "dashed" ? [2, 3] : seriesItem.style === "dotted" ? [8, 3] : undefined,
      })),
    };
    const chartOptions: ChartOptions<"line"> = {
      layout: {
        padding: 20,
      },
      scales: {
        y: {
          display: true,
          grid: {
            display: grid,
          },
          min: yAxis?.min,
          max: yAxis?.max,
          title: {
            display: yAxis?.label !== undefined,
            text: yAxis?.label,
          },
          beginAtZero: false,
          ticks: {
            maxTicksLimit: 10,
          },
          type: yAxis?.type ?? "linear",
        },
        x: {
          grid: {
            display: false,
          },
          min: xAxis?.min,
          max: xAxis?.max,
          title: {
            display: xAxis?.label !== undefined,
            text: xAxis?.label,
          },
          type: xAxis?.type ?? "linear",
          display: true,
        },
      },
      plugins: {
        tooltip: {
          enabled: tooltip !== false && tooltip !== undefined,
          callbacks:
            tooltip === false
              ? undefined
              : tooltip === true
                ? {
                    title: (context) => data[context[0].dataIndex].y[series[context[0].datasetIndex].id].title,
                    label: (context) => data[context.dataIndex].y[series[context.datasetIndex].id].label,
                  }
                : tooltip,
        },
        legend: {
          display: legend,
          position: "bottom",
          labels: {
            boxHeight: 15,
            boxWidth: 15,
          },
        },
        zoom: {
          zoom: {
            drag: {
              enabled: zoom,
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: "nearest",
      },
      maintainAspectRatio: false,
      resizeDelay: 200,
      onHover: (_event, chartElement) => {
        setFocusedDatapointIndex && setFocusedDatapointIndex(chartElement?.[0]?.index ?? null);
      },
    };
    return { chartData, chartOptions: withDefaultSizing("line", chartOptions, size) };
  }, [data, series]);

  return (
    <div className={cn("relative min-h-0 min-w-0 flex-grow", className)}>
      {zoom && (
        <Button variant="outline" size={size} symmetric className="absolute right-4 top-4" onClick={() => chartRef.current?.resetZoom()}>
          <FontAwesomeIcon icon={faExpand} />
        </Button>
      )}
      <Chart ref={chartRef} type="line" data={chartData} options={chartOptions} />
    </div>
  );
};

export default LineChart;
