import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import HeaderNotifications from "./HeaderNotifications";
import HeaderHelp from "./HeaderHelp";
import { faMoon, faPrint, faSun } from "@fortawesome/free-solid-svg-icons";
import useDarkMode from "@shared/hooks/useDarkMode";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import HeaderLabelPrinters from "./HeaderLabelPrinters";
import { PrinterType, useLabelPrinter } from "@shared/context/LabelPrinterProvider";
import { cn } from "@shared/utils/tailwind";
import Popover from "./primitives/Popover";
import Button from "./primitives/Button";

enum RightDropdownContents {
  Notifications = "NOTIFICATIONS",
  Help = "HELP",
  LabelPrinters = "LABEL_PRINTERS",
}

interface HeaderProps {
  breadcrumbs?: Breadcrumb[];
}

const Header: React.FC<HeaderProps> = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  const company = useSelector((state: RootState) => state.db.company);
  const [rightDropdownContent, setRightDropdownContent] = useState<RightDropdownContents | null>(null);
  const rightDropdownRef = React.useRef<HTMLDivElement>(null);
  const { darkMode, toggleDarkMode } = useDarkMode();
  const { printers } = useLabelPrinter();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (rightDropdownRef.current && !rightDropdownRef.current.contains(event.target as Node)) {
        setRightDropdownContent(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const printerStatusColor = useMemo(() => {
    let printerStatusBooleans = [];
    if (company?.config?.enable_dymo_integration) {
      printerStatusBooleans.push(...Object.values(printers[PrinterType.Dymo].status));
    }
    if (company?.config?.enable_zebra_integration) {
      printerStatusBooleans.push(...Object.values(printers[PrinterType.Zebra].status));
    }
    if (printerStatusBooleans.every((status) => status)) {
      return "bg-green-600";
    }
    if (printerStatusBooleans.some((status) => status)) {
      return "bg-yellow-500";
    }
    return "bg-red-600";
  }, [company?.config?.enable_dymo_integration, company?.config?.enable_zebra_integration, printers]);

  return (
    <div className="border-serial-palette-200 sticky top-0 h-12 shrink-0 border-b bg-white">
      <div className="flex h-full w-full items-center justify-between px-5">
        <div className="flex text-sm">
          {breadcrumbs &&
            breadcrumbs.map((breadcrumb, index) => {
              const isLastItem = index === breadcrumbs.length - 1;
              return (
                <React.Fragment key={index}>
                  <button
                    onClick={() => navigate(breadcrumb.link)}
                    className={`font-regular text-serial-palette-500 hover:text-serial-palette-400 hidden lg:block ${isLastItem ? "text-serial-palette-900 font-bold" : ""}`}
                    key={index}
                  >
                    {breadcrumb.label}
                  </button>
                  {!isLastItem && <span className="text-serial-palette-500 mx-2 hidden lg:block"> &gt; </span>}
                </React.Fragment>
              );
            })}
        </div>
        <Popover.Root open={rightDropdownContent !== null} onOpenChange={(isOpen) => !isOpen && setRightDropdownContent(null)}>
          <Popover.Trigger asChild>
            <div className="flex gap-1.5">
              <Button
                variant="outline"
                className="!h-7 !w-7"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDarkMode();
                }}
              >
                <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
              </Button>
              {(company?.config?.enable_dymo_integration || company?.config?.enable_zebra_integration) && (
                <Button
                  variant="outline"
                  className="relative !h-7 !w-7"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRightDropdownContent(RightDropdownContents.LabelPrinters);
                  }}
                >
                  <div
                    className={cn("absolute right-[6px] top-[6px] h-1.5 w-1.5 rounded-full border border-white", printerStatusColor)}
                  ></div>
                  <FontAwesomeIcon icon={faPrint} />
                </Button>
              )}
              <Button
                variant="outline"
                className="relative !h-7 !w-7"
                onClick={(e) => {
                  e.stopPropagation();
                  setRightDropdownContent(RightDropdownContents.Notifications);
                }}
              >
                {/* Add notification red dot when there are new notifications (uncomment below) */}
                {/* <div className="absolute right-[6px] top-[6px] h-1.5 w-1.5 rounded-full border border-white bg-red-600"></div> */}
                <FontAwesomeIcon icon={faBell} />
              </Button>
              <Button
                variant="outline"
                className="!h-7 px-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setRightDropdownContent(RightDropdownContents.Help);
                }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span className="text-sm font-light">Help</span>
              </Button>
            </div>
          </Popover.Trigger>
          <Popover.Content align="end" className="flex w-96 flex-col p-0" sideOffset={16}>
            {rightDropdownContent === null && <div className="flex h-96 shrink-0"></div>}
            {rightDropdownContent === RightDropdownContents.Notifications && (
              <HeaderNotifications closeDropdown={() => setRightDropdownContent(null)} />
            )}
            {rightDropdownContent === RightDropdownContents.Help && <HeaderHelp closeDropdown={() => setRightDropdownContent(null)} />}
            {rightDropdownContent === RightDropdownContents.LabelPrinters && (
              <HeaderLabelPrinters closeDropdown={() => setRightDropdownContent(null)} />
            )}
          </Popover.Content>
        </Popover.Root>
      </div>
    </div>
  );
};

export default Header;
