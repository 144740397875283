import React, { useContext, useEffect, useState } from "react";
import { DatasetsContext } from "../Datasets";
import { updateDataset } from "@shared/connections/api/datasets";
import Banner2 from "@shared/components/Banner2";
import { Dataset } from "@shared/types/databaseTypes";
import Modal from "@shared/components/primitives/Modal";
import Switch from "@shared/components/primitives/Switch";
import Button from "@shared/components/primitives/Button";
import { TextInput, NumberInput } from "@shared/components/primitives/Input";

const DatasetEditModal: React.FC = () => {
  const context = useContext(DatasetsContext);
  const [tempDataset, setTempDataset] = useState<Dataset | undefined>(undefined);
  const [error, setError] = useState<{ type: "error" | "warning" | "success"; message: string; isOpen: boolean }>({
    type: "error",
    message: "",
    isOpen: false,
  });

  const handleSave = async () => {
    if (!context.selectedDataset) {
      setError({ type: "error", message: "No dataset selected", isOpen: true });
      return;
    }
    const { error } = await updateDataset(context.selectedDataset.id, {
      name: tempDataset?.name ?? "",
      is_active: tempDataset?.is_active ?? false,
    });
    if (error) {
      setError({ type: "error", message: error, isOpen: true });
    } else {
      context.setDatasetEditModalOpen(false);
    }
  };

  useEffect(() => {
    if (context.selectedDataset) {
      setTempDataset({ ...context.selectedDataset });
    }
  }, [context.selectedDataset]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!context.datasetEditModalOpen || keyCode !== 27) return;
      context.setDatasetEditModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <Modal.Root open={context.datasetEditModalOpen} onOpenChange={(value) => context.setDatasetEditModalOpen(value)}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Dataset</Modal.Title>
          <Modal.HeaderClose />
        </Modal.Header>
        <Modal.Main className="gap-y-4">
          <Banner2 type={error.type} open={error.isOpen} setOpen={(value: boolean) => setError({ ...error, isOpen: value })}>
            {error.message}
          </Banner2>

          {/* Name */}
          <div>
            <label className="mb-1 block text-sm font-medium">
              Name <span className="text-red-600">*</span>
            </label>
            <TextInput
              value={tempDataset?.name}
              onValueChange={(value) => tempDataset && setTempDataset({ ...tempDataset, name: value })}
              placeholder="Dataset Name"
            />
          </div>

          {/* Unit */}
          <div>
            <label className="mb-1 block text-sm font-medium">Unit</label>
            <TextInput
              value={tempDataset?.unit ?? ""}
              onValueChange={(value) => tempDataset && setTempDataset({ ...tempDataset, unit: value })}
              placeholder="Unit"
              disabled
            />
          </div>

          {/* Sepcs */}
          <div className="flex justify-between">
            <div>
              <label className="mb-1 block text-sm font-medium">LSL</label>
              <NumberInput
                type="number"
                value={tempDataset?.lsl ?? ""}
                onValueChange={(value) => tempDataset && setTempDataset({ ...tempDataset, lsl: value })}
                placeholder="Lower Spec Limit"
                disabled
              />
            </div>
            <div>
              <label className="mb-1 block text-sm font-medium">USL</label>
              <NumberInput
                type="number"
                value={tempDataset?.usl ?? ""}
                onValueChange={(value) => tempDataset && setTempDataset({ ...tempDataset, usl: value })}
                placeholder="Upper Spec Limit"
                disabled
              />
            </div>
          </div>

          {/* Is Active */}
          <div>
            <label className="mb-1 block text-sm font-medium">Active</label>
            <Switch
              checked={tempDataset?.is_active}
              onCheckedChange={(value) => tempDataset && setTempDataset({ ...tempDataset, is_active: value })}
            />
          </div>
        </Modal.Main>
        <Modal.Footer className="sm:justify-start">
          <div className="flex gap-2">
            <Modal.Close asChild>
              <Button size="sm" variant="outline">
                Close
              </Button>
            </Modal.Close>
            <Button size="sm" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DatasetEditModal;
