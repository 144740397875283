import React, { useContext, useState, useMemo } from "react";
import { Component, Process } from "@shared/types/databaseTypes";
import { ProcessType, UserRole } from "@shared/types/databaseEnums";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ComponentContext } from "../ComponentProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { swapProcessOrders } from "../connections/supabase";
import UserAvatar from "@shared/components/UserAvatar";
import db from "@shared/connections/supabaseReduxDatabase";
import { ComponentPageModal } from "../types";
import useDataTypeCount from "@shared/hooks/useDataTypeCount";
import VersionBlock from "@shared/components/VersionBlock";

interface ComponentProcessSequenceTableRowProps {
  processes: ProcessWithIsActiveAndOrder[];
  index: number;
  component: Component;
  reorderingInProgress: boolean;
  handleReorder: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number, direction: "next" | "prev") => void;
  handleEditProcess: (processId: string, componentId?: string) => void;
}

const ComponentProcessSequenceTableRow = ({
  processes,
  index,
  component,
  reorderingInProgress,
  handleReorder,
  handleEditProcess,
}: ComponentProcessSequenceTableRowProps) => {
  const process = useMemo(() => processes[index], [processes, index]);
  const { approvedProcessRevisions } = useContext(ComponentContext);

  const order = useMemo(() => {
    if (process.is_active) {
      const priorInactiveProcesses = processes.slice(0, index).filter((process) => !process.is_active);
      return index - priorInactiveProcesses.length + 1;
    }
    return null;
  }, [process, processes, index]);

  const role = useSelector((state: RootState) => state.auth.role);
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const users = useSelector((state: RootState) => state.db.users);

  const { tags } = useDataTypeCount({ datasets: datasets.filter((dataset) => dataset.process_id === process.id) });

  return (
    <tr onClick={() => handleEditProcess(process.id, component.id)} className="hover:bg-serial-palette-100 group h-14 cursor-pointer">
      <td className="px-2">
        <div className="relative flex w-full justify-center">
          {role === UserRole.Admin && (
            <div className="absolute right-0 top-0 hidden h-full w-full flex-col items-center justify-center gap-y-0.5 group-hover:flex">
              <button
                disabled={index === 0 || reorderingInProgress}
                onClick={(e) => handleReorder(e, index, "prev")}
                className={`btn-xs serial-btn-light w-8  ${index === 0 ? "bg-serial-palette-50 text-serial-palette-300" : "bg-serial-palette-100 hover:bg-serial-palette-200"}`}
              >
                <FontAwesomeIcon icon={faCaretUp} />
              </button>
              <button
                disabled={index === processes.length - 1 || reorderingInProgress}
                onClick={(e) => {
                  handleReorder(e, index, "next");
                }}
                className={`btn-xs serial-btn-light w-8  ${index === processes.length - 1 ? "bg-serial-palette-50 text-serial-palette-300" : "bg-serial-palette-100 hover:bg-serial-palette-200"}`}
              >
                <FontAwesomeIcon icon={faCaretDown} />
              </button>
            </div>
          )}
          <div className="w-full text-center group-hover:hidden">{order ?? "-"}</div>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5">
        <div className="flex max-w-[200px] flex-col pb-1 2xl:max-w-[300px]">
          <div className="truncate text-left text-sm">
            {process?.name}
            {process?.is_mandatory && <span className="ml-0.5 font-bold text-red-600">*</span>}
          </div>
          {tags}
        </div>
      </td>
      <td className="hidden px-2 2xl:table-cell">
        <div className="flex w-full justify-center">
          <UserAvatar user={users.find((user) => user.supabase_uid === process?.created_by_user_id)} size="sm" />
        </div>
      </td>
      <td className="hidden px-2 py-3 sm:table-cell">
        <div className="flex justify-center">
          <VersionBlock
            version={
              approvedProcessRevisions[process.id] !== undefined
                ? approvedProcessRevisions[process.id].toString()
                : process?.revision.toString() || "0"
            }
            size="sm"
            color={approvedProcessRevisions[process.id] !== undefined ? "green" : "black"}
          />
        </div>
      </td>
    </tr>
  );
};

interface ComponentProcessSequenceTableProps {
  component: Component;
}

interface ProcessWithIsActiveAndOrder extends Process {
  is_active: boolean;
  order: number;
}

export const ComponentProcessSequenceTable: React.FC<ComponentProcessSequenceTableProps> = ({ component }) => {
  const { showInactiveProcesses, handleSetFocusedProcess, handleSetFocusedComponent, setActiveModal } = useContext(ComponentContext);

  const [reorderingInProgress, setReorderingInProgress] = useState<boolean>(false);
  const [tempReorderedProcesses, setTempReorderedProcesses] = useState<ProcessWithIsActiveAndOrder[] | null>(null);

  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);

  const processes: ProcessWithIsActiveAndOrder[] = useMemo(() => {
    if (tempReorderedProcesses) return tempReorderedProcesses;
    let filteredComponentProcessLinks = componentProcessLinks.filter(
      (componentProcessLink) =>
        componentProcessLink.component_id === component.id && componentProcessLink.process?.type === ProcessType.Production,
    );
    if (!showInactiveProcesses)
      filteredComponentProcessLinks = filteredComponentProcessLinks.filter((componentProcessLink) => componentProcessLink.is_active);
    const sortedComponentProcessLinks = filteredComponentProcessLinks.sort((a, b) => {
      // null goes to the front
      if (a.order === null) return -1;
      if (b.order === null) return 1;
      return a.order - b.order;
    });
    return sortedComponentProcessLinks.map((componentProcessLink) => {
      return {
        ...(componentProcessLink.process as Process),
        is_active: componentProcessLink.is_active,
        order: componentProcessLink.order ?? 0,
      };
    });
  }, [componentProcessLinks, component, showInactiveProcesses, tempReorderedProcesses]);

  const handleReorder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number, direction: "next" | "prev") => {
    e.stopPropagation();
    setReorderingInProgress(true);
    // Determine process indices
    const process2Index = index + (direction === "next" ? 1 : -1);
    // Update temp state
    let updatedProcesses = JSON.parse(JSON.stringify(processes)) as ProcessWithIsActiveAndOrder[];
    const process1Order = updatedProcesses[index].order;
    const process2Order = updatedProcesses[process2Index].order;
    updatedProcesses[index].order = process2Order;
    updatedProcesses[process2Index].order = process1Order;
    updatedProcesses = updatedProcesses.sort((a, b) => a.order - b.order);
    setTempReorderedProcesses(updatedProcesses);
    // Update supabase
    const process1Id = processes[index].id;
    const process2Id = processes[process2Index].id;
    await swapProcessOrders(process1Id, process2Id, component?.id);
    await db.refreshComponentProcessLinks();

    setReorderingInProgress(false);
    setTempReorderedProcesses(null);
  };

  const handleEditProcess = (processId: string, componentId?: string) => {
    handleSetFocusedProcess(processId);
    handleSetFocusedComponent(componentId ?? null);
    setActiveModal(ComponentPageModal.ProcessBuilder);
  };

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col bg-white">
      {processes?.length === 0 && (
        <div className="w-full space-y-2 border-t py-6">
          <div className="text-center italic">No instances of this component yet</div>
        </div>
      )}

      {processes?.length > 0 && (
        <div className="flex h-full flex-col justify-between">
          <div className="scrollbar-hide flex w-full overflow-y-auto border-t">
            <table className="table w-full">
              <thead className="text-serial-palette-500 bg-serial-palette-50 sticky top-0 z-10 rounded-md border-b text-xs font-semibold uppercase">
                <tr>
                  <th className="whitespace-nowrap px-2 py-3">
                    <div className="text-center font-semibold">Order</div>
                  </th>
                  <th className="whitespace-nowrap px-2 py-3">
                    <div className="text-left font-semibold">Process Name</div>
                  </th>
                  <th className="hidden whitespace-nowrap px-2 py-3 2xl:table-cell">
                    <div className="text-center font-semibold">Last Editor</div>
                  </th>
                  <th className="hidden whitespace-nowrap px-2 py-3 sm:table-cell">
                    <div className="text-center font-semibold">Revision</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-serial-palette-200 border-serial-palette-200 divide-y border-b text-sm">
                {processes.map((process, index) => {
                  return (
                    <ComponentProcessSequenceTableRow
                      key={process.id}
                      processes={processes}
                      index={index}
                      component={component}
                      reorderingInProgress={reorderingInProgress}
                      handleReorder={handleReorder}
                      handleEditProcess={handleEditProcess}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComponentProcessSequenceTable;
