import Tooltip from "@shared/components/primitives/Tooltip";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";

const FrontendPrimitivesTooltips = () => {
  return (
    <FrontendPrimitivesCard title="Tooltips">
      <div className="flex flex-col gap-y-2">
        <div className="inline">
          <span>You can add a </span>
          <Tooltip.Root>
            <Tooltip.Content>Tooltip!</Tooltip.Content>
            <Tooltip.Trigger>
              <span className="font-bold">tooltip</span>
            </Tooltip.Trigger>
          </Tooltip.Root>
          <span> to any element</span>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesTooltips;
