import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { fetchUniqueIdentifierProcessData, ProcessEntryWithData } from "../connections/supabase";
import { pdf } from "@react-pdf/renderer";
import SystemFunctionalQc from "../documents/SystemFunctionalQc";
import { User, UniqueIdentifier, ProcessEntry, ParametricQuantitative } from "@shared/types/databaseTypes";
import moment from "moment";
import { GeneralInformation, SystemFunctionalQcConfig, TestData } from "../types";
import { ReportsContext } from "../ReportsProvider";

const updateTestData = (tableData: string[][], processData: ProcessEntryWithData) => {
  const performOperation = (operation: string, values: number[], postOperation?: string): number => {
    let result;
    switch (operation) {
      case "max":
        result = Math.max(...values);
        break;
      default:
        result = values[0];
    }

    if (postOperation) {
      const [op, opValue] = postOperation.split("/");
      switch (op) {
        case "":
          return result / parseFloat(opValue);
        default:
          return result;
      }
    }

    return result;
  };

  return tableData.map((row, index) => {
    if (index === 0) return row;
    const newRow: string[] = [...row];
    const datasetIdPrefix = "dataset_id=";
    const processResultPrefix = "process_result=";
    const datasetIdIndex = 3;

    try {
      if (newRow[datasetIdIndex].startsWith(processResultPrefix)) {
        const processId = newRow[datasetIdIndex].substring(processResultPrefix.length);
        const processEntry = processData.processEntries.find((e) => e.process_id === processId);
        newRow[datasetIdIndex] = processEntry ? (processEntry.is_pass ? "Pass" : "Fail") : "-";
        newRow[datasetIdIndex + 1] = processEntry ? (processEntry.is_pass ? "Pass" : "Fail") : "-";
      } else if (newRow[datasetIdIndex] && newRow[datasetIdIndex].includes(datasetIdPrefix)) {
        const operationRegex = /(\w+)\(([^)]+)\)(\/\d+)?/;
        const match = newRow[datasetIdIndex].match(operationRegex);
        if (match) {
          const operation = match[1];
          const datasetIdsString = match[2];
          const postOperation = match[3];
          const datasetIds = datasetIdsString.split("/").map((id: string) => id.trim().split(datasetIdPrefix)[1]);
          const values: number[] = [];
          let unit: string | null = "";
          let allPassed = true;

          datasetIds.forEach((datasetId: string) => {
            const entry = processData.data.find((e: ParametricQuantitative) => e.dataset_id === datasetId);
            if (entry && entry.value !== undefined) {
              values.push(entry.value);
              unit = entry?.unit;
              if (entry.is_pass !== undefined && !entry.is_pass) {
                allPassed = false;
              }
            } else {
              values.push(0);
              allPassed = false;
            }
          });

          const resultValue = performOperation(operation, values, postOperation);
          if (unit === "mN") {
            unit = "N";
          }
          newRow[datasetIdIndex] = resultValue.toFixed(2).toString() + " " + unit;
          newRow[datasetIdIndex + 1] = allPassed ? "Pass" : "Fail";
        } else {
          const datasetId = newRow[datasetIdIndex].split(datasetIdPrefix)[1];
          const entry = processData.data.find((e: ParametricQuantitative) => e.dataset_id === datasetId);
          if (entry && entry.value !== undefined) {
            newRow[datasetIdIndex] = entry.value.toString();
            // Include the is_pass check here as well
            newRow[datasetIdIndex + 1] = entry.is_pass ? "Pass" : "Fail";
          } else {
            newRow[datasetIdIndex] = "-"; // Or some other placeholder if no entry is found
            newRow[datasetIdIndex + 1] = "-"; // Or the appropriate Fail status
          }
        }
      } else {
        newRow[datasetIdIndex] = "-";
        newRow[datasetIdIndex + 1] = "-";
      }
    } catch (error) {
      console.error("Error processing row:", newRow, error);
    }
    return newRow;
  });
};

const updateGeneralInformation = (
  generalInformation: GeneralInformation,
  entryData: ProcessEntry,
  identifier: UniqueIdentifier,
  user: User,
  partNumber: string,
) => {
  const updatedContent = generalInformation.content.map((row) => {
    return row.map((cell) => {
      if (cell === "{identifier}") {
        return identifier.identifier;
      } else if (cell === "{operator}") {
        return user ? user.first_name + " " + user.last_name : "-";
      } else if (cell === "{title}") {
        return user ? user.title : "-";
      } else if (cell === "{timestamp}") {
        return entryData.timestamp ? moment(entryData.timestamp).format("MM/DD/YY HH:mma") : "-";
      } else if (cell === "{part_number}") {
        return partNumber.substring(0, partNumber.indexOf(":"));
      } else {
        return cell;
      }
    });
  });
  return { ...generalInformation, content: updatedContent };
};

const extractProcessIds = (config: SystemFunctionalQcConfig) => {
  const processIds: string[] = [];
  processIds.push(...config.generalInformation.process_id);
  config.testData.forEach((testData) => {
    processIds.push(...testData.process_id);
  });
  return processIds;
};

const extractDatasetIds = (config: SystemFunctionalQcConfig) => {
  const extractDatasetIds: string[] = [];
  config.testData.forEach((testData) => {
    extractDatasetIds.push(...testData.dataset_ids);
  });
  return extractDatasetIds;
};

interface ReportResult {
  uniqueIdentifierId: string;
  result: "pass" | "fail";
  failureMessage?: string;
  blobUrl?: string | null;
}

export const useSystemFunctionalQc = (): ReportResult[] => {
  const { selectedReport, uniqueIdentifiersToExport } = useContext(ReportsContext);
  const [reportResults, setReportResults] = useState<ReportResult[]>([]);

  const company = useSelector((state: RootState) => state.db.company);
  const users = useSelector((state: RootState) => state.db.users);
  const operators = useSelector((state: RootState) => state.db.operators);
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const partNumbers = useSelector((state: RootState) => state.db.partNumbers);

  useEffect(() => {
    if (selectedReport && selectedReport?.config?.testData?.length > 0 && uniqueIdentifiersToExport.length > 0) {
      // extract all process ids from config
      const processIds = [...new Set(extractProcessIds(selectedReport.config))];
      const datasetIds = [...new Set(extractDatasetIds(selectedReport.config))];

      const buildConfig = async (componentInstanceId: string): Promise<ReportResult> => {
        // get complete componentInstance
        const componentInstance = componentInstances.find((componentInstance) => componentInstance.id === componentInstanceId);
        if (!componentInstance) {
          return { uniqueIdentifierId: componentInstanceId, result: "fail", failureMessage: "Cannot find component instance" };
        }

        // fetch all process data for uniqueIdentifierId
        const processData = await fetchUniqueIdentifierProcessData(processIds, componentInstanceId);
        const missingProcessIds = processIds.filter(
          (processId) => !processData.processEntries.find((entry) => entry.process_id === processId),
        );
        if (missingProcessIds.length > 0) {
          return { uniqueIdentifierId: componentInstanceId, result: "fail", failureMessage: "missing process entry:" };
        }

        // check if all datasetIds are present in processData.data, return error if missing
        const missingDatasetIds = datasetIds.filter((datasetId) => !processData.data.find((entry) => entry.dataset_id === datasetId));
        if (missingDatasetIds.length > 0) {
          return {
            uniqueIdentifierId: componentInstanceId,
            result: "fail",
            failureMessage: `missing datasets: ${missingDatasetIds.join(", ")}`,
          };
        }

        const updatedTestData = selectedReport.config.testData.map((test: TestData) => {
          const updatedSubCategories = test.subCategories.map((subCategory) => {
            const updatedRequirements = updateTestData(subCategory.requirements, processData);
            return { ...subCategory, requirements: updatedRequirements };
          });
          return { ...test, subCategories: updatedSubCategories };
        });

        const generalInformationProcessEntry = processData.processEntries.find(
          (entry) => entry.process_id === selectedReport.config.generalInformation.process_id[0],
        );
        const operator = operators.find((operator) => operator.id === generalInformationProcessEntry?.operator_id);
        const user = users.find((user) => user.supabase_uid === operator?.user_id);
        if (!user) {
          return {
            uniqueIdentifierId: componentInstanceId,
            result: "fail",
            failureMessage: `user not defined: ${operator?.first_name} ${operator?.last_name}`,
          };
        }
        if (user.title === null) {
          return {
            uniqueIdentifierId: componentInstanceId,
            result: "fail",
            failureMessage: `user title not defined: ${operator?.first_name} ${operator?.last_name}`,
          };
        }

        const partNumber = partNumbers.find((partNumber) => partNumber.id === componentInstance.part_number_id);

        if (!partNumber) {
          return { uniqueIdentifierId: componentInstanceId, result: "fail", failureMessage: `part number not defined` };
        }

        const updatedGeneralInfo = updateGeneralInformation(
          selectedReport.config.generalInformation,
          generalInformationProcessEntry!,
          componentInstance,
          user || operator || null,
          partNumber.pn,
        );

        const updatedConfig = { ...selectedReport.config, testData: updatedTestData, generalInformation: updatedGeneralInfo };

        const pdfComponent = <SystemFunctionalQc company={company} config={updatedConfig} partNumber={partNumber.pn} />;
        const blob = await pdf(pdfComponent).toBlob();
        let url = URL.createObjectURL(blob);

        return {
          uniqueIdentifierId: componentInstanceId,
          result: "pass",
          blobUrl: url,
        };
      };

      if (selectedReport && uniqueIdentifiersToExport.length > 0) {
        const uniqueIdentifierIds = uniqueIdentifiersToExport.map((uniqueIdentifier) => uniqueIdentifier.id);
        Promise.all(uniqueIdentifierIds.map(buildConfig)).then(setReportResults);
      }
    }
  }, [selectedReport, uniqueIdentifiersToExport, users, operators]);
  return reportResults;
};
