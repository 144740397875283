import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { DateRange } from "react-day-picker";
import Calendar from "@shared/components/primitives/Calendar";
import Popover from "@shared/components/primitives/Popover";
import ButtonGroup from "./primitives/ButtonGroup";
import { faTimes, faCalendar as faCalendarSolid } from "@fortawesome/free-solid-svg-icons";

interface DateRangePickerProps {
  dateRange?: DateRange;
  onDateRangeChange?: (date: DateRange | undefined) => void;
  className?: string;
  clearButton?: boolean;
  showDateRange?: boolean;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  className,
  dateRange,
  clearButton,
  onDateRangeChange,
  showDateRange = true,
}) => {
  return (
    <Popover>
      <ButtonGroup.Root variant="outline">
        <Popover.Trigger asChild>
          <ButtonGroup.Button className={className}>
            <FontAwesomeIcon icon={dateRange ? faCalendarSolid : faCalendar} className="h-4 w-4" />
            {showDateRange &&
              (dateRange?.from ? (
                dateRange?.to ? (
                  <>
                    {format(dateRange.from, "LLL dd, y")} - {format(dateRange.to, "LLL dd, y")}
                  </>
                ) : (
                  format(dateRange.from, "LLL dd, y")
                )
              ) : (
                <span>Select Dates</span>
              ))}
          </ButtonGroup.Button>
        </Popover.Trigger>
        {dateRange && clearButton && (
          <ButtonGroup.Button symmetric className={className} onClick={() => onDateRangeChange?.(undefined)}>
            <FontAwesomeIcon icon={faTimes} />
          </ButtonGroup.Button>
        )}
      </ButtonGroup.Root>
      <Popover.Content className="w-auto p-0" align="start">
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={dateRange?.from}
          selected={dateRange}
          onSelect={onDateRangeChange}
          numberOfMonths={2}
        />
      </Popover.Content>
    </Popover>
  );
};

export default DateRangePicker;
