import Switch from "@shared/components/primitives/Switch";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";

const FrontendPrimitivesSwitch = () => {
  return (
    <FrontendPrimitivesCard title="Switch">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Alignment</div>
        <div className="flex flex-col gap-2">
          <div className="flex w-48 items-center justify-between space-x-2">
            <Switch />
            <label>Large Switch</label>
          </div>
          <div className="flex w-48 items-center justify-between space-x-2">
            <Switch size="sm" />
            <label>Small Switch</label>
          </div>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesSwitch;
