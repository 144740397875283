import useCurrentUser from "@shared/hooks/useCurrentUser";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import useCurrentStation from "./hooks/useCurrentStation";
import {
  Component,
  FieldType,
  FileBlock,
  ImageBlock,
  Operator,
  PartNumber,
  PdfBlock,
  ProcessEntry,
  ProcessStepWithReferences,
  ProcessWithReferences,
  Station,
  UniqueIdentifier,
  User,
  VideoBlock,
} from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import useProductionAppLocalDb, { ProductionAppLocalDb } from "./hooks/useProductionAppDb";
import useCurrentOperator from "./hooks/useCurrentOperator";
import { ParsedResponse } from "@shared/types/apiTypes";
import { fetchComponentInstancesByIdentifiers, fetchLatestLink, fetchParametricDataByDatasetId } from "./connections/supabase";
import { useLocation } from "react-router-dom";
import {
  DraftSubmissionData,
  DraftSubmissionDatapoint,
  DraftSubmissionValidationData,
  IdentifierValidationDatapoint,
  BatchValidationData,
  InstantiationModalMode,
  ProductionAppBackup,
} from "./types";
import { v4 as uuidv4 } from "uuid";
import { validateFieldData, validateIdentifiers } from "./connections/validation";
import { ToastContext } from "@shared/context/ToastProvider";
import { uploadProcessEntryAndData } from "./connections/upload";
import { ToastType } from "@shared/components/Toast";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";
import useFilteredSteps from "./hooks/useFilteredSteps";
import { getProcessWithReferences } from "@shared/connections/supabaseProcess";
import { LabelFormatWithContents } from "@shared/labels/types";
import { getLabels } from "@shared/labels/connections/supabase";

export const MAX_BATCH_SIZE = 100;

export enum ProductionState {
  Blank = "BLANK",
  Loading = "LOADING",
  ProcessSelector = "PROCESS_SELECTOR",
  Steps = "STEPS",
  SubmissionResponse = "SUBMISSION_RESPONSE",
}

interface ProductionContextProps {
  // state
  db: ProductionAppLocalDb;
  user: User | undefined;
  station: Station | null;
  operator: Operator | null;
  process: ProcessWithReferences | null;
  filteredSteps: ProcessStepWithReferences[];
  componentInstances: UniqueIdentifier[] | null;
  partNumber: PartNumber | null;
  workInstructionUrls: { [fileId: string]: string };
  draftSubmission: DraftSubmissionData;
  focusedFieldId: string | null;
  inputRefs: React.MutableRefObject<{ [key: string]: HTMLInputElement | null }>;
  identifiers: string[];
  identifiersValidation: BatchValidationData | null;
  setIdentifiersValidation: React.Dispatch<React.SetStateAction<BatchValidationData | null>>;
  submissionResult: ProcessEntry[];
  submissionFailedDatasets: { datasetId: string; value: string | number | boolean | undefined }[];
  submissionError: string | null;
  submissionProgress: number;
  singleSubmissionMode: boolean;
  // state with setters
  instantiationIdentifiers: string[];
  setInstantiationIdentifiers: React.Dispatch<React.SetStateAction<string[]>>;
  instantiationComponentId: string | null;
  setInstantiationComponentId: React.Dispatch<React.SetStateAction<string | null>>;
  instantiationWorkOrderId: string | null;
  setInstantiationWorkOrderId: React.Dispatch<React.SetStateAction<string | null>>;
  instantiationModalMode: InstantiationModalMode;
  setInstantiationModalMode: React.Dispatch<React.SetStateAction<InstantiationModalMode>>;
  component: Component | null;
  setComponent: React.Dispatch<React.SetStateAction<Component | null>>;
  labels: LabelFormatWithContents[];
  setLabels: React.Dispatch<React.SetStateAction<LabelFormatWithContents[]>>;
  selectedStepIndex: number;
  setSelectedStepIndex: React.Dispatch<React.SetStateAction<number>>;
  productionState: ProductionState;
  setProductionState: React.Dispatch<React.SetStateAction<ProductionState>>;
  configureStation: boolean;
  setConfigureStation: React.Dispatch<React.SetStateAction<boolean>>;
  stationModalOpen: boolean;
  setStationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  operatorModalOpen: boolean;
  setOperatorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  instantiationModalOpen: boolean;
  setInstantiationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  workInstructionsOpen: boolean;
  setWorkInstructionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  datasetDataById: { [datasetId: string]: any };
  setDatasetDataById: React.Dispatch<React.SetStateAction<{ [datasetId: string]: any }>>;
  // event handlers
  handleLoadOperatorByPin: (pin: string) => Promise<void>;
  handleUpdateStation: (data?: Partial<Station>) => Promise<ParsedResponse>;
  handleUpdateDraftSubmission: (fieldId: string, data: DraftSubmissionDatapoint | undefined, doValidation?: boolean) => void;
  handleSetFocusedField: (fieldId: string) => void;
  handleUpdateIdentifiers: (updatedIdentifiers: string[]) => void;
  handleOverwriteDraftSubmission: (data: DraftSubmissionData) => void;
  handleValidateField: (
    fieldId: string,
    updatedDraftSubmissionData?: DraftSubmissionData,
    updatedIdentifiers?: string[],
  ) => Promise<IdentifierValidationDatapoint | undefined>;
  handleSubmit: () => void;
  handleReset: () => void;
}

// Create a default context value for ProductionContext to avoid having to initialize the context with undefined
const defaultContext: ProductionContextProps = {
  // state without setters
  db: {
    isLoaded: false,
    company: undefined,
    components: [],
    processes: [],
    componentProcessLinks: [],
    componentInstances: [],
    workOrders: [],
    partNumbers: [],
    stations: [],
    datasets: [],
  },
  user: undefined,
  station: null,
  operator: null,
  process: null,
  filteredSteps: [],
  componentInstances: [],
  partNumber: null,
  workInstructionUrls: {},
  draftSubmission: {},
  focusedFieldId: null,
  inputRefs: { current: {} },
  identifiers: [],
  identifiersValidation: null,
  setIdentifiersValidation: () => {},
  submissionResult: [],
  submissionFailedDatasets: [],
  submissionError: null,
  submissionProgress: 0,
  singleSubmissionMode: false,
  // state with setters
  instantiationIdentifiers: [],
  setInstantiationIdentifiers: () => {},
  instantiationComponentId: null,
  setInstantiationComponentId: () => {},
  instantiationWorkOrderId: null,
  setInstantiationWorkOrderId: () => {},
  instantiationModalMode: InstantiationModalMode.Create,
  setInstantiationModalMode: () => {},
  component: null,
  setComponent: () => {},
  labels: [],
  setLabels: () => {},
  selectedStepIndex: 0,
  setSelectedStepIndex: () => {},
  productionState: ProductionState.Blank,
  setProductionState: () => {},
  configureStation: false,
  setConfigureStation: () => {},
  stationModalOpen: false,
  setStationModalOpen: () => {},
  operatorModalOpen: false,
  setOperatorModalOpen: () => {},
  instantiationModalOpen: false,
  setInstantiationModalOpen: () => {},
  workInstructionsOpen: true,
  setWorkInstructionsOpen: () => {},
  datasetDataById: {},
  setDatasetDataById: () => {},
  // event handlers
  handleLoadOperatorByPin: async (_pin) => {},
  handleUpdateStation: async () => {
    return { data: undefined, error: undefined };
  },
  handleUpdateDraftSubmission: () => {},
  handleSetFocusedField: () => {},
  handleUpdateIdentifiers: () => {},
  handleOverwriteDraftSubmission: () => {},
  handleValidateField: async () => {
    return undefined;
  },
  handleSubmit: () => {},
  handleReset: () => {},
};

export const ProductionContext = createContext<ProductionContextProps>(defaultContext);

const ProductionProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const [process, setProcess] = useState<ProcessWithReferences | null>(null);
  const [workInstructionUrls, setWorkInstructionUrls] = useState<{ [fileId: string]: string }>({});
  const [instantiationIdentifiers, setInstantiationIdentifiers] = useState<string[]>([]);
  const [instantiationComponentId, setInstantiationComponentId] = useState<string | null>(null);
  const [instantiationWorkOrderId, setInstantiationWorkOrderId] = useState<string | null>(null);
  const [instantiationModalMode, setInstantiationModalMode] = useState<InstantiationModalMode>(InstantiationModalMode.Create);
  const [componentInstances, setComponentInstances] = useState<UniqueIdentifier[] | null>(null);
  const [identifiers, setIdentifiers] = useState<string[]>([]);
  const [identifiersValidation, setIdentifiersValidation] = useState<BatchValidationData | null>(null);
  const [draftSubmissionData, setDraftSubmissionData] = useState<DraftSubmissionData>({});
  const [draftSubmissionValidation, setDraftSubmissionValidation] = useState<DraftSubmissionValidationData>({});
  const [component, setComponent] = useState<Component | null>(null);
  const [labels, setLabels] = useState<LabelFormatWithContents[]>([]);
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0);
  const [productionState, setProductionState] = useState<ProductionState>(ProductionState.Loading);
  const [configureStation, setConfigureStation] = useState<boolean>(false);
  const [stationModalOpen, setStationModalOpen] = useState<boolean>(false);
  const [operatorModalOpen, setOperatorModalOpen] = useState<boolean>(false);
  const [instantiationModalOpen, setInstantiationModalOpen] = useState<boolean>(false);
  const [workInstructionsOpen, setWorkInstructionsOpen] = useState<boolean>(false);
  const [datasetDataById, setDatasetDataById] = useState<{ [datasetId: string]: string | number }>({});
  const [focusedFieldId, setFocusedFieldId] = useState<string | null>(null);
  const [startTimestamp, setStartTimestamp] = useState<string | null>(null);
  const [submissionResult, setSubmissionResult] = useState<ProcessEntry[]>([]);
  const [submissionFailedDatasets, setSubmissionFailedDatasets] = useState<
    { datasetId: string; value: string | number | boolean | undefined }[]
  >([]);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [submissionProgress, setSubmissionProgress] = useState<number>(0);
  const [singleSubmissionMode, setSingleSubmissionMode] = useState<boolean>(false);
  const [freezeProductionDb, setFreezeProductionDb] = useState<boolean>(false);

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

  const location = useLocation();
  const db = useProductionAppLocalDb(freezeProductionDb);
  const { datasets } = db;
  const componentProcessLinksByComponent = useSelector((state: RootState) => state.db.componentProcessLinks).filter(cpl => cpl.component_id === component?.id);
  const user = useCurrentUser();
  const { company } = db;
  const { station, handleUpdateStation } = useCurrentStation();
  const { operator, handleLoadOperatorByPin } = useCurrentOperator({
    enablePins: db.company?.config?.use_operator_pins === true,
    user,
    setOperatorModalOpen,
  });
  const { triggerConfirmation, triggerToast } = useContext(ToastContext);

  const partNumber = useMemo(() => {
    const partNumberIds = [...new Set(componentInstances?.map((componentInstance) => componentInstance.part_number_id))];
    const allFilterConditions = process?.process_steps.map((step) => step.filter_conditions).flat() ?? [];
    if (allFilterConditions.length > 0 && partNumberIds.length > 1) {
      console.error("Filter conditions are not all for the same part number");
    }
    return db.partNumbers.find((partNumber) => partNumber.id === partNumberIds[0]) ?? null;
  }, [componentInstances, process]);

  const componentDatasets = useMemo(() => {
    const relatedDatasets = datasets.filter((dataset) => componentProcessLinksByComponent.find((link) => link.process_id === dataset.process_id && dataset.is_active && dataset.process_revision === link.process_revision));
    // Filter out datasets that are not parametric
    return relatedDatasets.filter((dataset) => dataset.data_type === DataType.ParametricQualitative || dataset.data_type === DataType.ParametricQuantitative);
  }, [datasets, component]); 

  const filteredSteps = useFilteredSteps(process?.process_steps ?? [], partNumber);

  // route to production app state based on url
  useEffect(() => {
    if (!db.isLoaded) return;
    const pathSegments = location.pathname.split("/");
    const processId = pathSegments?.[3];
    if (productionState === ProductionState.SubmissionResponse) return;
    else if (processId) {
      setProductionState(ProductionState.Steps);
    } else if (station) {
      setProductionState(ProductionState.ProcessSelector);
    } else {
      setProductionState(ProductionState.ProcessSelector);
    }
  }, [location, db.isLoaded, station?.id]);


  useEffect(() => {
    const fetchData = async () => {
      const response: ParsedResponse<{ [datasetId: string]: string | number }> = await fetchParametricDataByDatasetId(componentDatasets.map((dataset) => dataset.id));
      if (response.error) {
        console.error(response.error);
      } else {
        setDatasetDataById(response.data ?? {});
      }
    };

    fetchData();
  }, [componentDatasets]);

  // set single submission mode based on url
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("single-submission") === "true") {
      setSingleSubmissionMode(true);
    }
  }, [location.search]);

  // load relevant data based on production app state
  useEffect(() => {
    switch (productionState) {
      case ProductionState.Steps:
        // load process
        const processId = location.pathname.split("/")?.[3];
        handleLoadProcess(processId);
        // set focused field to identifier field if it exists
        document.getElementById("production-app-identifier-input")?.focus();
        // set start timestamp
        setStartTimestamp(new Date().toISOString());
        break;
      case ProductionState.ProcessSelector:
        // reset the state when we navigate back to the process selector
        // it's necessary to do this here in the event of hitting the browser back button from a process
        // purposely don't use the handleReset function because we don't wan to clear the local storage backup
        setWorkInstructionsOpen(false);
        setProcess(null);
        setDraftSubmissionData({});
        setDraftSubmissionValidation({});
        setFocusedFieldId(null);
        setSubmissionProgress(0);
        setSelectedStepIndex(0);
        setSubmissionError(null);
        break;
      default:
        break;
    }
  }, [productionState]);

  // validate identifier field and attempt to load draft from local storage when process changes
  useEffect(() => {
    const loadComponentLinksAndBackup = async () => {
      if (!process) return;

      // Validate the identifier field if it exists
      if (identifiers.length > 0) {
        await handleValidateIdentifiers(identifiers);
      }

      // Load the latest links for the component instances
      if (!componentInstances || componentInstances.length === 0) return;

      for (const step of filteredSteps) {
        for (const field of step.fields) {
          if (field.type === FieldType.Link) {
            const latestLink = await fetchLatestLink(componentInstances[0].id, field.dataset_id);
            if (latestLink) {
              handleUpdateDraftSubmission(field.id, { linkedIdentifier: latestLink?.child?.identifier });
            }
          }
        }
      }

      // Attempt to load the draft submission from local storage
      // Note: this intentionally overwrites the previous links
      loadBackupFromLocalStorage(process.id);
    };

    loadComponentLinksAndBackup();
  }, [process?.id]);

  useEffect(() => {
    if (component) {
      getLabels(component.id).then((labels) => {
        setLabels(labels);
      });
    }
  }, [component]);

  const validationAbortControllers = useRef<Record<string, { requestId: string; abortController: AbortController }>>({});
  const identifiersValidationAbortController = useRef<AbortController | null>(null);
  const componentInstancesAbortController = useRef<AbortController | null>(null);

  const handleLoadWorkInstructionFile = useCallback(
    async (fileId: string) => {
      if (!db.company?.id) return;
      const url = await fetchDownloadUrl("work-instructions", fileId, db.company.id);
      if (url) {
        setWorkInstructionUrls((prev) => {
          return {
            ...prev,
            [fileId]: url,
          };
        });
      }
    },
    [db.company?.id],
  );

  const handleLoadProcess = useCallback(
    async (processId: string) => {
      const { data: process, error: processError } = await getProcessWithReferences(processId);
      if (processError || !process) {
        console.error(`Error loading process ${processId}: ${processError}`);
        return;
      }
      // load work instructions
      const workInstructionFileIds =
        process.process_steps
          .flatMap((step) =>
            step.work_instruction_blocks.map((block) => {
              if (block.file_id !== null) return (block as VideoBlock | ImageBlock | FileBlock | PdfBlock).file_id;
              return "";
            }),
          )
          .filter((id) => id !== "") ?? [];
      workInstructionFileIds.forEach((id) => {
        if (!workInstructionUrls[id]) handleLoadWorkInstructionFile(id);
      });
      // load component if there is only one component linked to this process
      const componentIds = db.componentProcessLinks.filter((cpl) => cpl.process_id === processId).map((cpl) => cpl.component_id);
      if (componentIds.length === 1) {
        const component = db.components.find((c) => c.id === componentIds[0]);
        setComponent(component ?? null);
      }
      // set process
      setProcess(process ?? null);
    },
    [db, handleLoadWorkInstructionFile],
  );

  const handleValidateField = useCallback(
    async (
      fieldId: string,
      updatedDraftSubmissionData?: DraftSubmissionData,
      updatedIdentifiers?: string[],
    ): Promise<IdentifierValidationDatapoint | undefined> => {
      if (!db.company?.id || !process) return;
      // abort any previous validation requests for this field and create a new abort controller
      validationAbortControllers.current[fieldId]?.abortController?.abort();
      const requestId = uuidv4();
      validationAbortControllers.current[fieldId] = {
        requestId,
        abortController: new AbortController(),
      };
      const fieldValidation = await validateFieldData(
        db.company,
        process,
        fieldId,
        updatedDraftSubmissionData ?? draftSubmissionData,
        updatedIdentifiers ?? identifiers ?? [],
        validationAbortControllers.current[fieldId].abortController.signal,
      );
      // don't update the validation if the request was aborted
      if (validationAbortControllers.current[fieldId].requestId !== requestId) return;
      setDraftSubmissionValidation((prev) => {
        return {
          ...prev,
          [fieldId]: fieldValidation,
        };
      });
      return fieldValidation;
    },
    [db.company, process, identifiers, draftSubmissionData],
  );
  const handleValidateIdentifiers = useCallback(
    async (identifiers: string[]) => {
      if (!process) return;
      identifiersValidationAbortController.current?.abort();
      identifiersValidationAbortController.current = new AbortController();
      const newIdentifiersValidation = await validateIdentifiers(
        process,
        station,
        identifiers,
        identifiersValidationAbortController.current.signal,
      );
      if (identifiersValidationAbortController.current.signal.aborted === false) {
        setIdentifiersValidation(newIdentifiersValidation);
      }
    },
    [process, station],
  );

  const handleUpdateIdentifiers = useCallback(
    async (updatedIdentifiers: string[]) => {
      setIdentifiers(updatedIdentifiers);
      handleValidateIdentifiers(updatedIdentifiers);
      componentInstancesAbortController.current?.abort();
      componentInstancesAbortController.current = new AbortController();
      const { data: updatedComponentInstances } = await fetchComponentInstancesByIdentifiers(
        updatedIdentifiers,
        componentInstancesAbortController.current.signal,
      );
      // If component instances were found and the abort controller was not aborted, update the component instances
      if (componentInstancesAbortController.current.signal.aborted === false) {
        setComponentInstances(updatedComponentInstances ?? []);
      }
    },
    [handleValidateIdentifiers, component],
  );

  const handleUpdateDraftSubmission = useCallback(
    (fieldId: string, data: DraftSubmissionDatapoint | undefined, doValidation: boolean = true) => {
      setDraftSubmissionData((prevDraftSubmission) => {
        let newDraftSubmission = { ...prevDraftSubmission };
        if (data === undefined) {
          delete newDraftSubmission[fieldId];
        } else {
          newDraftSubmission[fieldId] = data;
        }
        if (doValidation) handleValidateField(fieldId, newDraftSubmission, undefined);
        localStorage.setItem(
          "productionAppBackup",
          JSON.stringify({
            data: newDraftSubmission,
            identifiers,
            startTime: startTimestamp,
            processId: process?.id,
          } as ProductionAppBackup),
        );
        return newDraftSubmission;
      });
    },
    [handleValidateField, identifiers, process],
  );

  const handleSetFocusedField = (fieldId: string) => {
    setFocusedFieldId(fieldId);
    inputRefs.current[fieldId]?.focus();
  };

  const handleOverwriteDraftSubmission = useCallback(
    (data: DraftSubmissionData, identifiers?: string[]) => {
      setDraftSubmissionData(data);
      // trigger validation for each field
      for (const fieldId of Object.keys(data)) {
        handleValidateField(fieldId, data);
      }
      if (identifiers) {
        handleUpdateIdentifiers(identifiers);
      }
    },
    [handleValidateField],
  );

  const handleSubmit = useCallback(async () => {
    // Make sure all required data is available
    if (!station && company?.config?.require_station) {
      setStationModalOpen(true);
      return;
    }
    if (!process || !operator || !startTimestamp) {
      console.error("Insufficient data to create submission submit");
      triggerToast(
        ToastType.Error,
        "Submission error. Data was not submitted",
        "Insufficient data to create submission submit. Please try again or contact Serial support.",
        9000,
      );
      setSubmissionError("Insufficient data to create submission. Please try again or contact Serial support.");
      return;
    }
    // Make sure at least one identifier has been entered
    if (identifiers.length === 0) {
      triggerToast(
        ToastType.Error,
        "Data was not submitted",
        "An identifier is required to submit data. Please check the form and try again.",
        9000,
      );
      setSubmissionError("An identifier is required to submit data. Please enter an identifier and try again.");
      setProductionState(ProductionState.Steps);
      return;
    }
    // Make sure all identifiers are valid
    if (identifiersValidation) {
      const hasInvalidIdentifier = Object.values(identifiersValidation).some((datapoints) =>
        datapoints?.some((datapoint) => datapoint.isValid === false),
      );
      if (hasInvalidIdentifier) {
        triggerToast(
          ToastType.Error,
          "Data was not submitted",
          "Identifier validation failed. Please check the identifiers and try again.",
          9000,
        );
        setSubmissionError("Identifier validation failed. Please check the identifiers and try again.");
        setProductionState(ProductionState.Steps);
        return;
      }
    }
    // Setup submission
    setProductionState(ProductionState.Loading);
    setFreezeProductionDb(true);
    // re-validate all fields
    const allFields = filteredSteps.flatMap((step) => step.fields);
    const validation = await Promise.all(
      allFields.map((field) => {
        return handleValidateField(field.id);
      }),
    );
    const allFieldsValid = validation.every((fieldValidation) => fieldValidation?.isValid === true);
    if (!allFieldsValid) {
      triggerToast(ToastType.Error, "Data was not submitted", "Some fields are invalid. Please check the form and try again.", 9000);
      setSubmissionError("Some fields are invalid. Please check the form and try again.");
      setProductionState(ProductionState.Steps);
      setFreezeProductionDb(false);
      return;
    }
    // Upload submission
    const { error, processEntries, failedDatasets } = await uploadProcessEntryAndData(
      process,
      filteredSteps,
      station,
      operator,
      draftSubmissionData,
      identifiers,
      startTimestamp,
      (percent) => setSubmissionProgress(percent),
    );
    // Handle submission error
    if (error || !processEntries) {
      triggerToast(
        ToastType.Error,
        "Submission error. Data was not submitted",
        `${error} Please try again or contact Serial support.`,
        9000,
      );
      setSubmissionError(`${error} Please try again or contact Serial support.`);
      setProductionState(ProductionState.Steps);
      setFreezeProductionDb(false);
      return;
    }
    // Show submission result
    setSubmissionResult(processEntries);
    setSubmissionFailedDatasets(failedDatasets ?? []);
    setProductionState(ProductionState.SubmissionResponse);
    setSubmissionError(null);
    setFreezeProductionDb(false);
    localStorage.removeItem("productionAppBackup");
  }, [
    process,
    filteredSteps,
    station,
    operator,
    draftSubmissionData,
    identifiers,
    startTimestamp,
    setProductionState,
    handleValidateField,
    identifiersValidation,
  ]);

  const handleReset = () => {
    setDraftSubmissionData({});
    setDraftSubmissionValidation({});
    setComponentInstances([]);
    setFocusedFieldId(null);
    setSubmissionProgress(0);
    setSelectedStepIndex(0);
    setSubmissionError(null);
    localStorage.removeItem("productionAppBackup");
    if (identifiers.length > 0) handleUpdateIdentifiers(identifiers);
  };

  const loadBackupFromLocalStorage = useCallback(
    (processId: string) => {
      const savedDraftSubmission = localStorage.getItem("productionAppBackup");
      if (!savedDraftSubmission) return;
      const { data, identifiers, startTime, processId: backupProcessId } = JSON.parse(savedDraftSubmission) as ProductionAppBackup;
      if (savedDraftSubmission && processId === backupProcessId) {
        triggerConfirmation(
          "Draft Submission Found",
          <p>
            You have an un-submitted draft{identifiers[0] ? ` for "${identifiers[0]}"` : "."}
            <br />
            Would you like to reload it?
          </p>,
          () => {
            handleOverwriteDraftSubmission(data, identifiers);
            setStartTimestamp(startTime);
          },
          () => {
            localStorage.removeItem("productionAppBackup");
          },
          "Reload",
          "Discard",
        );
      }
    },
    [process, handleOverwriteDraftSubmission, setIdentifiers],
  );

  const draftSubmission = useMemo(() => {
    let newDraftSubmission: DraftSubmissionData = {};
    let fieldIdsUpdated: string[] = [];
    // Combine the draft submission data and validation data for each field starting with the draftSubmission data
    for (const fieldId of Object.keys(draftSubmissionData)) {
      const fieldValidation = draftSubmissionValidation[fieldId] ?? {};
      const fieldData = draftSubmissionData[fieldId] ?? {};
      newDraftSubmission[fieldId] = {
        ...fieldData,
        ...fieldValidation,
      };
      fieldIdsUpdated.push(fieldId);
    }
    // Then check the draftSubmissionValidation for any fields that were not in the draftSubmissionData
    for (const fieldId of Object.keys(draftSubmissionValidation)) {
      if (fieldIdsUpdated.includes(fieldId)) continue;
      const fieldValidation = draftSubmissionValidation[fieldId] ?? {};
      const fieldData = draftSubmissionData[fieldId] ?? {};
      newDraftSubmission[fieldId] = {
        ...fieldData,
        ...fieldValidation,
      };
    }
    return newDraftSubmission;
  }, [draftSubmissionData, draftSubmissionValidation]);

  return (
    <ProductionContext.Provider
      value={{
        // state
        db,
        user,
        station,
        operator: operator ?? null,
        process,
        filteredSteps,
        componentInstances,
        partNumber,
        workInstructionUrls,
        focusedFieldId,
        inputRefs,
        identifiers,
        identifiersValidation,
        setIdentifiersValidation,
        submissionResult,
        submissionFailedDatasets,
        submissionError,
        submissionProgress,
        singleSubmissionMode,
        // state with setters
        instantiationIdentifiers,
        setInstantiationIdentifiers,
        instantiationComponentId,
        setInstantiationComponentId,
        instantiationWorkOrderId,
        setInstantiationWorkOrderId,
        instantiationModalMode,
        setInstantiationModalMode,
        draftSubmission,
        component,
        setComponent,
        labels,
        setLabels,
        selectedStepIndex,
        setSelectedStepIndex,
        productionState,
        setProductionState,
        configureStation,
        setConfigureStation,
        stationModalOpen,
        setStationModalOpen,
        operatorModalOpen,
        setOperatorModalOpen,
        instantiationModalOpen,
        setInstantiationModalOpen,
        workInstructionsOpen,
        setWorkInstructionsOpen,
        datasetDataById,
        setDatasetDataById,
        // event handlers
        handleLoadOperatorByPin,
        handleUpdateStation,
        handleUpdateDraftSubmission,
        handleSetFocusedField,
        handleUpdateIdentifiers,
        handleOverwriteDraftSubmission,
        handleValidateField,
        handleSubmit,
        handleReset,
      }}
    >
      {children}
    </ProductionContext.Provider>
  );
};

export default ProductionProvider;
