import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GridApi, ColDef, GridReadyEvent, CellEditingStoppedEvent, CellFocusedEvent, Column } from "ag-grid-community";
import { Dataset, PartNumber } from "@shared/types/databaseTypes";
import PartNumberCustomHeader from "./PartNumberCustomHeader";
import PartNumberFileUploadCell from "./PartNumberFileUploadCell";
import { DataType } from "@shared/types/databaseEnums";
import { ErrorBanner } from "@shared/components/error/types";
import { v4 as uuidv4 } from "uuid";
import PartNumberDeleteCell from "./PartNumberDeleteCell";
import { deletePartNumber, setPartNumberActivity } from "./connections/supabase";
import useDarkMode from "@shared/hooks/useDarkMode";

export interface PartNumberWithState extends PartNumber {
  state?: RowState;
}

interface PartNumberGridProps {
  partNumbers: PartNumberWithState[];
  setPartNumbers: (partNumbers: PartNumberWithState[]) => void;
  loadGridPartNumbers: () => void;
  gridDatasets: Dataset[];
  setGridDatasets: (gridDatasets: Dataset[]) => void;
  filteredPartNumberIds: string[];
  setError: (error: ErrorBanner) => void;
  gridApi: GridApi | null;
  setGridApi: (api: GridApi) => void;
}

interface RowData {
  [key: string]: any;
  pn: string;
  description: string | null;
  state?: RowState;
  id?: string;
}

export enum RowState {
  Blank = "BLANK", // a blank row that has not been made into a part number yet
  New = "NEW", // a new part number that has not been saved yet
  Edited = "EDITED", // an existing part number that has been edited
  Existing = "EXISTING", // an existing part number that has not been edited
}

const PartNumberGrid: React.FC<PartNumberGridProps> = ({
  partNumbers,
  setPartNumbers,
  loadGridPartNumbers,
  gridDatasets,
  setGridDatasets,
  filteredPartNumberIds,
  setError,
  gridApi,
  setGridApi,
}) => {
  const [rowData, setRowData] = useState<RowData[]>([{ pn: "", description: "" }]);
  const [filteredRowData, setFilteredRowData] = useState<RowData[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const partNumbersRef = useRef<PartNumberWithState[]>([]); // use ref to avoid issues with javascript closures
  const gridDatasetsRef = useRef<Dataset[]>([]); // use ref to avoid issues with javascript closures

  const { darkMode } = useDarkMode();

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true,
      singleClickEdit: true,
    }),
    [],
  );

  // ------------------ Helper Functions ------------------ //

  const handleDeletePartNumber = async (partNumberId: string) => {
    const { data: deletedId, error: deletedError } = await deletePartNumber(partNumberId);
    // If the component cannot be deleted, inactivate it and set the warning message
    if (deletedId !== partNumberId || deletedError) {
      await setPartNumberActivity(partNumberId, false);
      setError({
        hide: false,
        isValid: false,
        message:
          "This part number cannot be deleted because your database already has records associated to it. The part number has been deactivated instead.",
        type: "warning",
      });
    }
    loadGridPartNumbers();
  };

  const handleActivatePartNumber = async (partNumberId: string) => {
    const { error: activationError } = await setPartNumberActivity(partNumberId, true);
    if (activationError) {
      setError({
        hide: false,
        isValid: false,
        message: "Error activating part number. Please try again or contact support.",
        type: "error",
      });
      console.error(activationError);
    }
    loadGridPartNumbers();
  };

  // Maps the partNumber object into a row data for ag-grid
  const generateRowDataFromPartNumbers = () => {
    // Default row data
    let newRowData: RowData[] = [];
    // Unpack each part number into the new row data
    partNumbersRef.current.forEach((partNumber) => {
      let newRow: RowData = {
        id: partNumber.id,
        state: partNumber.state ?? RowState.Existing,
        is_active: partNumber.is_active,
        pn: partNumber.pn,
        description: partNumber.description,
      };
      // Unpack the metadata keys from all part numbers
      for (var datasetId in partNumber.metadata) {
        const dataset = gridDatasetsRef.current.find((dataset) => dataset.id === datasetId);
        // handle file and image data types seperately
        if (dataset?.data_type === DataType.File || dataset?.data_type === DataType.Image) {
          newRow[datasetId] = {
            file_id: partNumber.metadata[datasetId].value,
            file_name: partNumber.metadata[datasetId].file_name,
          };
        }
        // default to the value of the metadata
        else {
          newRow[datasetId] = partNumber.metadata[datasetId].value;
        }
      }
      newRowData.push(newRow);
    });
    // If there are no part numbers add one new one
    if (newRowData.length === 0) {
      let blankPartNumber = createBlankPartNumber();
      setPartNumbers([...partNumbersRef.current, blankPartNumber]);
      return;
    }
    // If there is more than one pn or there is only one part number and it is not blank add a blank row to the end of the row data
    else if (newRowData.length > 1 || (newRowData.length === 1 && newRowData[0].pn !== "")) {
      // Add a blank row to the end of the row data
      const blankRow = {
        state: RowState.Blank,
        pn: "",
        description: "",
      };
      newRowData.push(blankRow);
    }
    // Reset the row data only if they have changed
    const keyOrder = [
      "id",
      "state",
      "is_active",
      "pn",
      "description",
      ...gridDatasetsRef.current.map((dataset) => dataset.id),
      "file_id",
      "file_name",
    ]; // file_id and file_name must be included in order to ensure the sub keys of the metadata object are compared
    if (JSON.stringify(rowData, keyOrder) !== JSON.stringify(newRowData, keyOrder)) {
      setRowData(newRowData);
    }
  };

  // Maps the partNumber object into a column definition for ag-grid
  const generateColumnDefsFromPartNumbers = () => {
    // Default column definitions
    let newColumnDefs: ColDef[] = [
      {
        headerName: "",
        field: "delete",
        cellRenderer: PartNumberDeleteCell,
        cellRendererParams: { handleDeletePartNumber, handleActivatePartNumber },
        filter: false,
        resizable: false,
        width: 70,
        editable: false,
      },
      { headerName: "Part Number", field: "pn" },
      { headerName: "Description", field: "description" },
    ];
    // Unpack the metadata keys into column definitions
    gridDatasetsRef.current.forEach((dataset) => {
      let newColumnDef: ColDef = {
        headerName: dataset?.name || "",
        field: dataset.id,
        headerComponent: PartNumberCustomHeader,
        headerComponentParams: {
          handleSetColumnName: handleSetColumnName,
          dataset: dataset,
          handleDeleteColumn: handleDeleteColumn,
        },
      };
      // configure column as a file upload cell if the dataset is a file or image
      if (dataset?.data_type === DataType.File || dataset?.data_type === DataType.Image) {
        newColumnDef.cellRenderer = PartNumberFileUploadCell;
        newColumnDef.cellRendererParams = {
          addFileData: handleAddFileToPartNumber,
          removeFileData: handleRemoveFileFromPartNumber,
          setError: setError,
          datasetId: dataset.id,
          columnDataType: dataset?.data_type,
        };
        newColumnDef.editable = false;
      }
      newColumnDefs.push(newColumnDef);
    });
    // Reset the column definitions only if they have changed
    if (JSON.stringify(columnDefs) !== JSON.stringify(newColumnDefs)) {
      setColumnDefs(newColumnDefs);
    }
  };

  // Sets the column name by adjusting dataset.name in the corresponding part number metadata
  const handleSetColumnName = (dataset: Dataset, newName: string) => {
    let updatedDatasets = [...gridDatasetsRef.current];
    const datasetIndex = updatedDatasets.findIndex((key) => key.id === dataset.id);
    updatedDatasets[datasetIndex].name = newName;
    setGridDatasets(updatedDatasets);
  };

  // Deletes the column by removing the dataset from the grid datasets
  const handleDeleteColumn = (dataset: Dataset) => {
    let updatedDatasets = [...gridDatasetsRef.current];
    const datasetIndex = updatedDatasets.findIndex((key) => key.id === dataset.id);
    updatedDatasets.splice(datasetIndex, 1);
    setGridDatasets(updatedDatasets);
  };

  // Creates a blank part number object
  const createBlankPartNumber = () => {
    // add new part number to part numbers
    const blankPartNumber: PartNumberWithState = {
      // ------ to be set on save event by modal component >>>>>>
      id: uuidv4(),
      company_id: "",
      is_active: true,
      created_at: "",
      last_edited_at: "",
      last_edited_user_id: "",
      component_id: "",
      // <<<<<<<< to be set on save event by modal component ------
      pn: "",
      description: "",
      metadata: {},
      state: RowState.New,
    };
    return blankPartNumber;
  };

  // Adds a file or image to a part number's metadata
  const handleAddFileToPartNumber = (datasetId: string, partNumberId: string, fileName: string, fileId: string) => {
    let updatedPartNumbers = [...partNumbersRef.current];
    // find the part number index to be modified
    let partNumberIndex = updatedPartNumbers.findIndex((pn) => pn.id === partNumberId);
    if (partNumberIndex !== -1) {
      const updatedPartNumber = { ...partNumbersRef.current[partNumberIndex] };
      updatedPartNumber.metadata[datasetId] = { value: fileId, file_name: fileName };
      updatedPartNumbers[partNumberIndex] = updatedPartNumber;
      // If the part number is a new part number, set the state to new, otherwise set the state to edited
      updatedPartNumbers[partNumberIndex].state =
        updatedPartNumbers[partNumberIndex].state === RowState.New ? RowState.New : RowState.Edited;
      setPartNumbers(updatedPartNumbers);
    }
  };

  const handleRemoveFileFromPartNumber = (datasetId: string, partNumberId: string) => {
    let updatedPartNumbers = [...partNumbersRef.current];
    // find the part number index to be modified
    let partNumberIndex = updatedPartNumbers.findIndex((pn) => pn.id === partNumberId);
    if (partNumberIndex !== -1) {
      const state = updatedPartNumbers[partNumberIndex].state === RowState.New ? RowState.New : RowState.Edited;
      updatedPartNumbers[partNumberIndex] = removeMetadataFromPartNumber(updatedPartNumbers[partNumberIndex], datasetId, state);
      setPartNumbers(updatedPartNumbers);
    }
  };

  const removeMetadataFromPartNumber = (partNumber: PartNumberWithState, datasetId: string, state: RowState) => {
    let updatedPartNumber = { ...partNumber };
    updatedPartNumber.state = state;
    if (updatedPartNumber.metadata[datasetId]) {
      delete updatedPartNumber.metadata[datasetId];
    }
    return updatedPartNumber;
  };

  // Adds new data into an existing part number object by matching the column field to the appropriate key in the part number object
  const insertDataIntoPartNumber = (partNumber: PartNumberWithState, field: string, newValue: string, state: RowState) => {
    let updatedPartNumber = { ...partNumber };
    updatedPartNumber.state = state;
    if (field === "pn") {
      updatedPartNumber.pn = newValue.trim();
    } else if (field === "description") {
      updatedPartNumber.description = newValue;
    } else {
      updatedPartNumber.metadata[field] = { value: newValue };
    }
    return updatedPartNumber;
  };

  // Add a new row to the grid without adding a new part number to the part numbers array
  const addNewRow = () => {
    const newRow = {
      state: RowState.Blank,
      pn: "",
      description: "",
    };
    setRowData((prevRowData) => [...prevRowData, newRow]);
  };

  // ------------------------- Event Handlers ------------------------- //

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  // Function that gets called after a cell is edited
  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    if (!event.valueChanged) return;
    if (!event.colDef.field) return;

    if (event.colDef.field === "pn" && event.data.state !== RowState.Blank && event.data.state !== RowState.New) {
      const confirmation = window.confirm(
        "⚠️ Warning: \nAre you sure you want to change the part number? This will change the part number on all serial numbers or lot codes that have been using this part number.",
      );
      if (!confirmation) {
        // Reset the part number to the original value
        event.node.setDataValue(event.colDef.field, event.oldValue);
        return;
      }
    }

    // Update the part numbers with the new value
    let updatedPartNumbers = [...partNumbersRef.current];

    // If the cell is not a file or image cell
    if (event.colDef.cellRenderer !== PartNumberFileUploadCell) {
      // If the cell's row is a new row, add a new blank part number to the end of the part numbers array and add a new row to the grid
      if (event.data.state === RowState.Blank) {
        // Create a new blank part number
        let blankPartNumber = createBlankPartNumber();
        blankPartNumber = insertDataIntoPartNumber(blankPartNumber, event.colDef.field, event.value, RowState.New);

        // Add new part number ID to the row data
        setRowData((prevRowData) => {
          const newRowData = [...prevRowData];
          newRowData[newRowData.length - 1].id = blankPartNumber.id;
          newRowData[newRowData.length - 1].state = RowState.New;
          return newRowData;
        });

        // Add the new part number to the part numbers array
        updatedPartNumbers.push(blankPartNumber);
        setPartNumbers(updatedPartNumbers);
        addNewRow();
      }
      // If the cell's row is an existing row, update the part number in the part numbers array by matching the id
      else {
        let partNumberIndex = updatedPartNumbers.findIndex((updatedPn) => updatedPn.id === event.data.id);
        if (partNumberIndex !== -1) {
          const state = updatedPartNumbers[partNumberIndex].state === RowState.New ? RowState.New : RowState.Edited;
          if (event.value === "" && event.colDef.field !== "description" && event.colDef.field !== "pn") {
            updatedPartNumbers[partNumberIndex] = removeMetadataFromPartNumber(
              updatedPartNumbers[partNumberIndex],
              event.colDef.field,
              state,
            );
          } else {
            updatedPartNumbers[partNumberIndex] = insertDataIntoPartNumber(
              updatedPartNumbers[partNumberIndex],
              event.colDef.field,
              event.value,
              state,
            );
          }
        }
        setPartNumbers(updatedPartNumbers);
      }
    }
  };

  // Ensure that the next cell which gets focused is set to edit mode
  const onCellFocused = (event: CellFocusedEvent) => {
    const focusedCellColumn = event.column as Column;
    const focusedCellRow = event.rowIndex as number;
    if (!focusedCellColumn || !focusedCellRow) return;
    // Add this action to the end of the call stack
    setTimeout(() => {
      gridApi?.startEditingCell({
        rowIndex: focusedCellRow,
        colKey: focusedCellColumn.getColId(),
      });
    }, 0);
  };

  const rowClassRules = {
    "highlight-row-edited": function (params: any) {
      return params.data.state === RowState.Edited;
    },
  };

  // ------------------------- Effects ------------------------- //

  // Set the column definitions and row data based on updated partNumbers and gridDatasets
  useEffect(() => {
    // use refs to avoid issues with javascript closures
    partNumbersRef.current = partNumbers;
    gridDatasetsRef.current = gridDatasets;
    generateColumnDefsFromPartNumbers();
    generateRowDataFromPartNumbers();
  }, [partNumbers, gridDatasets]);

  // Update the filtered row data when the part numbers or the filtered part number ids change
  useEffect(() => {
    let newFilteredRowData = rowData;
    newFilteredRowData = rowData.filter((row) => row.state !== RowState.Existing || (row.id && filteredPartNumberIds.includes(row.id)));

    // Reset the row data only if they have changed
    if (JSON.stringify(filteredRowData) !== JSON.stringify(newFilteredRowData)) {
      setFilteredRowData(newFilteredRowData);
    }
  }, [rowData, filteredPartNumberIds]);

  // ------------------------- Render ------------------------- //

  return (
    <div className={`part-number-ag-grid relative z-0 h-[99%] w-full ${darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}`}>
      <AgGridReact
        animateRows={true}
        rowData={filteredRowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onCellEditingStopped={onCellEditingStopped}
        onCellFocused={onCellFocused}
        rowClassRules={rowClassRules}
      />
    </div>
  );
};

export default PartNumberGrid;
