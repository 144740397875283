import React, { useRef, useEffect } from "react";

import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import "chartjs-adapter-moment";

// Import utilities
import { tailwindConfig, formatValue } from "@shared/utils/helpers";

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function StationsGraphEntries({ data, width, height }) {
  const canvas = useRef(null);

  const convertToDateTimeString = (epochDate) => {
    const date = new Date(epochDate);
    // return MM/DD HHa-HHa where the second HHa is the next hour and a represents am/pm, ex: 12am-1am, use the 12 hour clock
    const currentHourString = `${date.getHours() % 12}${date.getHours() < 12 ? "am" : "pm"}`;
    const nextHourString = `${(date.getHours() + 1) % 12}${date.getHours() + 1 < 12 ? "am" : "pm"}`;
    return `${date.getMonth() + 1}/${date.getDate()} ${currentHourString}-${nextHourString}`;
  };

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const chart = new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        chartArea: {
          backgroundColor: tailwindConfig().theme.colors.white,
        },
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            ticks: {
              precision: 0,
            },
          },
          x: {
            type: "time",
            time: {
              unit: "hour", // set the unit of the time axis to hours
            },
            display: false,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                const date = convertToDateTimeString(context[0].parsed.x);
                return date;
              },
              label: (context) => {
                const label = context.dataset.name;
                const value = context.parsed.y;
                return `${label}: ${value}`;
              },
            },
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <canvas ref={canvas} width={width} height={height}></canvas>;
}

export default StationsGraphEntries;
