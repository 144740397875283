import { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import Header from "@shared/components/Header";
import Sidebar from "@shared/components/Sidebar";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import { DatasetsContextProps } from "./types";
import useDatasets from "./hooks/useDatasets";
import { useSelector } from "react-redux";
import { defaultContext } from "./constants";
import DatasetSelector from "./components/DatasetSelector";
import DatasetSummary from "./components/DatasetSummary";
import DatasetAnalytics from "./components/DatasetAnalytics";
import { RootState } from "@shared/redux/store";
import { truncateString } from "@shared/utils/helpers";
import DatasetEditModal from "./components/DatasetEditModal";

const defaultBreadcrumbs = [
  {
    label: "Datasets",
    link: "/datasets",
  },
];

export const DatasetsContext = createContext<DatasetsContextProps>(defaultContext);

function Datasets() {
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(defaultBreadcrumbs);

  const {
    selectedDataset,
    setSelectedDataset,
    data,
    datasetEditModalOpen,
    setDatasetEditModalOpen,
    selectedDateRange,
    setSelectedDateRange,
    isLoading,
  } = useDatasets();

  useEffect(() => {
    if (location.pathname.includes("/datasets/") && datasets) {
      const datasetId = location.pathname.split("/datasets/")[1];
      const newSelectedDataset = datasets.find((dataset: any) => dataset.id === datasetId);
      if (newSelectedDataset?.id === selectedDataset?.id) return;
      setSelectedDataset(newSelectedDataset ?? null);
      // Update breadcrumbs if the dashboard exists
      if (newSelectedDataset) {
        const newBreadcrumbs = [
          ...defaultBreadcrumbs,
          {
            label: truncateString(newSelectedDataset.name, 50),
            link: `/datasets/${newSelectedDataset.id}`,
          },
        ];
        setBreadcrumbs(newBreadcrumbs);
      }
    } else {
      setBreadcrumbs(defaultBreadcrumbs);
    }
  }, [location.pathname, datasets]);

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="relative flex h-screen min-h-0 min-w-0 flex-grow flex-col overflow-hidden">
        <Header breadcrumbs={breadcrumbs} />
        <DatasetsContext.Provider
          value={{
            selectedDataset,
            setSelectedDataset,
            datasetEditModalOpen,
            setDatasetEditModalOpen,
            selectedDateRange,
            setSelectedDateRange,
            data,
            isLoading,
          }}
        >
          <div className="flex min-h-0 min-w-0 flex-grow">
            <div className={`flex w-full md:w-1/4 ${selectedDataset !== null && "hidden md:flex"} h-full min-w-[330px]`}>
              <DatasetSelector />
            </div>
            {selectedDataset ? (
              <div className="flex w-full flex-col gap-4 overflow-hidden p-6">
                <DatasetSummary />
                <DatasetAnalytics />
              </div>
            ) : (
              <div className="flex h-full w-full items-center justify-center italic">Select a dataset</div>
            )}
          </div>
          <DatasetEditModal />
        </DatasetsContext.Provider>
      </div>
    </div>
  );
}

export default Datasets;
