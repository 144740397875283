import React, { useContext, useMemo } from "react";
import { ProductionContext } from "../../ProductionProvider";
import { ProductionFormGenericFieldProps } from "../../types";
import DatePicker from "@shared/components/DatePicker";

const ProductionFormDatetime: React.FC<ProductionFormGenericFieldProps> = ({ field }) => {
  const { draftSubmission, handleUpdateDraftSubmission } = useContext(ProductionContext);

  const computeDate = useMemo(() => {
    const value = draftSubmission?.[field.id]?.value;
    if (value && typeof value === "string" && !isNaN(Date.parse(value))) {
      return new Date(value);
    }
    return undefined;
  }, [draftSubmission, field.id]);

  return (
    <DatePicker
      className="w-full"
      date={computeDate}
      onDateChange={(newValue) => {
        handleUpdateDraftSubmission(field.id, { value: newValue?.toISOString() });
      }}
    />
  );
};
export default ProductionFormDatetime;
