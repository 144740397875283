import React, { useContext, useMemo } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { ComponentLink, LinkField } from "@shared/types/databaseTypes";
import Select from "@shared/components/primitives/Select";
import { ProcessBuilderFieldContentsProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { Component } from "@shared/types/databaseTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

const getAncestorsRecursive = (componentId: string, componentLinks: ComponentLink[]): string[] => {
  const ancestorComponentIds = componentLinks.filter((link) => link.has_child_of_id === componentId).map((link) => link.component_id);
  const ancestorAncestorComponentIds = ancestorComponentIds.map((componentId) => getAncestorsRecursive(componentId, componentLinks)).flat();
  return [...ancestorComponentIds, ...ancestorAncestorComponentIds];
};

const ProcessBuilderFieldLink: React.FC<ProcessBuilderFieldContentsProps<LinkField>> = ({ field, stepIndex }) => {
  const { component, handleUpdateFieldDataset, handleUpdateFields } = useContext(ProcessBuilderContext);
  const componentLinks = useSelector((state: RootState) => state.db.componentLinks);
  const components = useSelector((state: RootState) => state.db.components);

  // get all components that are not ancestors of the current component and are not the current component
  const linkOptions: Component[] = useMemo(() => {
    const ancestorComponentIds = getAncestorsRecursive(component?.id ?? "", componentLinks);
    return components.filter((c) => ![component?.id, ...ancestorComponentIds].includes(c.id) && c.is_active);
  }, [component]);

  const handleSelectComponent = (componentId: string) => {
    const component = linkOptions.find((component) => component.id === componentId);
    handleUpdateFieldDataset(stepIndex, field.id, "child_component_id", component?.id ?? null);
    handleUpdateFields(stepIndex, [field.id], "prompt", component?.name ?? "Invalid Component");
  };

  return (
    <div className="mt-0.5 flex w-full flex-col gap-y-2">
      <div className="flex">
        {!field.is_optional && <div className="pr-1 text-rose-500">*</div>}
        <Select.Root value={field.dataset?.child_component_id ?? ""} onValueChange={(value) => handleSelectComponent(value)}>
          <Select.Trigger
            className={`h-7 w-full border-none px-1.5 shadow-none ${field.dataset?.child_component_id ? "text-serial-palette-600" : "text-serial-palette-400"}`}
          >
            <Select.Value placeholder="Component to link..." />
          </Select.Trigger>
          <Select.Content>
            <Select.ScrollUpButton />
            {linkOptions.map((component, index) => (
              <Select.Item key={index} value={component.id}>
                {component.name}
              </Select.Item>
            ))}
            <Select.ScrollDownButton />
          </Select.Content>
        </Select.Root>
      </div>
      <div className="border-serial-palette-200 text-serial-palette-400 flex w-full shrink-0 justify-between rounded border px-3 py-2 font-light italic  leading-5">
        <div>ABC123XYZ</div>
        <FontAwesomeIcon icon={faBarcode} />
      </div>
    </div>
  );
};

export default ProcessBuilderFieldLink;
