import { useMemo } from "react";
import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import TrackingProvider from "./TrackingProvider";
import TrackingMap from "./components/TrackingMap";
import TrackingSidebar from "./components/TrackingSidebar";
import { MapProvider } from "react-map-gl";
import { MeasuresProvider } from "@shared/measures/MeasuresProvider";
import { useLocation } from "react-router-dom";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";

function Tracking() {
  const components = useSelector((state: RootState) => state.db.components);
  const location = useLocation();

  const componentId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentId = searchParams.get("component_id");
    const component = components.find((component) => component.id === selectedComponentId) ?? null;
    return component?.id;
  }, [location, components]);

  return (
    <MapProvider>
      <MeasuresProvider componentId={componentId}>
        <TrackingProvider>
          <TrackingWithContext />
        </TrackingProvider>
      </MeasuresProvider>
    </MapProvider>
  );
}

function TrackingWithContext() {
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: "Tracking",
        link: "/tracking",
      },
    ];
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <Header breadcrumbs={breadcrumbs} />
        <div className="relative flex h-full w-full bg-white">
          <TrackingMap />
          <TrackingSidebar />
        </div>
      </div>
    </div>
  );
}

export default Tracking;
