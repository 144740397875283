import { useEffect, useRef, useState, useContext } from "react";
import { BrowserPrint } from "@shared/packages/BrowserPrint-3.1.250.min.js";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { PrinterStatus } from "@shared/context/LabelPrinterProvider";

const DEBUG = false;

export interface ZebraPrinterStatus {
  communicationSettings: string;
  paperOut: boolean;
  pause: boolean;
  labelLength: number;
  formatsInBuffer: number;
  bufferFull: boolean;
  diagnosticMode: boolean;
  partialFormat: boolean;
  unused: string;
  corruptRAM: boolean;
  underTemperature: boolean;
  overTemperature: boolean;
  readyToPrint: boolean;
}

// Parse the printer status string
// based on ZPL II Programming Guide page 191
// https://www.servopack.de/support/zebra/ZPLII-Prog.pdf
const parsePrinterStatus = (statusStr: string): ZebraPrinterStatus | null => {
  // read only the first line and remove special characters
  const specialCharacters = ["\u0003", "\r", "\u0002"];
  let cleanedStatus = statusStr.split("\n")[0];
  specialCharacters.forEach((character) => {
    cleanedStatus = cleanedStatus.replace(character, "");
  });

  // Split the string by commas
  const parts = cleanedStatus.split(",");

  // Make sure the status is in the correct length
  if (parts.length < 12) return null;

  // Make sure each part is the correct length for the first 12 parts
  const expectedPartsCharLengths = [3, 1, 1, 4, 3, 1, 1, 1, 3, 1, 1, 1];
  const correctParts = expectedPartsCharLengths.map((length, index) => {
    const part = parts[index];
    return part.length === length;
  });
  if (!correctParts.every((part) => part)) return null;

  // Parse each part according to the spec
  const status = {
    communicationSettings: parts[0],
    paperOut: parts[1] === "1",
    pause: parts[2] === "1",
    labelLength: parseInt(parts[3], 10),
    formatsInBuffer: parseInt(parts[4], 10),
    bufferFull: parts[5] === "1",
    diagnosticMode: parts[6] === "1",
    partialFormat: parts[7] === "1",
    unused: parts[8],
    corruptRAM: parts[9] === "1",
    underTemperature: parts[10] === "1",
    overTemperature: parts[11] === "1",
    readyToPrint: false,
  };

  // check if status is ready to print
  if (
    !status.bufferFull &&
    !status.corruptRAM &&
    !status.overTemperature &&
    !status.underTemperature &&
    !status.paperOut &&
    !status.pause
  ) {
    status.readyToPrint = true;
  }

  return status;
};

const useZebraPrinter = ({
  enabled,
  autoStatusCheckInterval = 60000,
}: {
  enabled: boolean;
  autoStatusCheckInterval?: number;
}): {
  status: PrinterStatus;
  getStatus: () => void;
  printLabel: (labelImageUrl: string) => Promise<boolean>;
} => {
  const defaultDevice = useRef<any>(null);
  const [status, setStatus] = useState<PrinterStatus>({ driverInstalled: false, printerConnected: false, readyToPrint: false });
  const [displayStatusToasts, setDisplayStatusToasts] = useState<boolean>(true);
  const { triggerToast } = useContext(ToastContext);

  // try to load browser print default device every 2 seconds until it's found
  useEffect(() => {
    if (!enabled) return;
    const interval = setInterval(() => {
      // @ts-ignore // Need to somehow get BrowserPrint to work with Typescript
      if (BrowserPrint) {
        // @ts-ignore
        BrowserPrint?.getDefaultDevice("printer", (device: any) => {
          if (device) {
            defaultDevice.current = device;
            setTimeout(() => {
              getStatus();
            }, 1000);
          } else {
            console.log("No default printer found");
          }
        });
        clearInterval(interval);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [enabled]);

  // get the printer status every autoStatusCheckInterval
  useEffect(() => {
    if (!enabled) return;
    if (DEBUG) runDiagnostics();
    const interval = setInterval(() => {
      getStatus();
    }, autoStatusCheckInterval);
    return () => clearInterval(interval);
  }, [enabled]);

  const runDiagnostics = async (device?: any) => {
    const status = await getStatus(device);
    console.log("Status:", status);
    const configuration = await getConfiguration(device);
    console.log("Configuration:", configuration);
    const info = await getInfo(device);
    console.log("Info:", info);
  };

  const getStatus = async (device?: any) => {
    try {
      const statusString = await sendAndRead("~hs", device);
      if (!statusString) return null;
      const parsedStatus = parsePrinterStatus(statusString);
      if (!parsedStatus) {
        if (displayStatusToasts) triggerToast(ToastType.Warning, "Printer status not available", "Please check the printer status");
        setStatus({ driverInstalled: true, printerConnected: true, readyToPrint: false });
        setDisplayStatusToasts(false);
        return;
      }
      if (!parsedStatus.readyToPrint) {
        if (displayStatusToasts) triggerToast(ToastType.Warning, "Printer not ready to print", "Please check the printer status");
        setDisplayStatusToasts(false);
        setStatus({ driverInstalled: true, printerConnected: true, readyToPrint: false });
        return;
      }
      setStatus({ driverInstalled: true, printerConnected: true, readyToPrint: true });
      setDisplayStatusToasts(true);
    } catch (error) {
      setStatus({ driverInstalled: false, printerConnected: false, readyToPrint: false });
      return null;
    }
  };

  const getConfiguration = async (device?: any): Promise<string | null> => {
    try {
      const configuration = await sendAndRead("^XA^HH^XZ", device);
      return configuration;
    } catch (error) {
      console.warn("Error getting configuration:", error);
      return null;
    }
  };

  const getInfo = async (device?: any): Promise<string | null> => {
    try {
      const info = await sendAndRead("~hi\r\n", device);
      return info;
    } catch (error) {
      console.warn("Error getting info:", error);
      return null;
    }
  };

  const sendAndRead = async (command: string, device?: any): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      if (!device && !defaultDevice.current) {
        reject("No device available");
        return;
      }
      (device ?? defaultDevice.current).sendThenRead(
        command,
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          console.warn(error);
          reject(error);
        },
      );
    });
  };

  const printLabel = async (labelImageUrl: string) => {
    try {
      if (!enabled) throw new Error("Zebra printer integration not enabled");
      if (!defaultDevice.current) throw new Error("No device found");
      defaultDevice.current.convertAndSendFile(
        labelImageUrl ?? "",
        () => {},
        (error: any) => {
          console.error("Could not print label", error);
          throw new Error("Could not print label");
        },
        {
          toFormat: "zpl",
          fromFormat: "png",
          action: "print",
        },
      );
      return true;
    } catch (error) {
      console.error("Error printing label", error);
      return false;
    }
  };

  return {
    status,
    getStatus,
    printLabel,
  };
};

export default useZebraPrinter;
