import { RootState } from "@shared/redux/store";
import { Component, Process } from "@shared/types/databaseTypes";
import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ComponentPageModal, ComponentPageSidebar } from "./types";
import { getLatestApprovedRevisionByProcessId } from "@shared/connections/supabaseProcess";

interface ComponentContextProps {
  // state
  topLevelComponent: Component | null;
  focusedComponent: Component | null;
  focusedProcess: Process | null;
  approvedProcessRevisions: { [processId: string]: number };
  // state with setters
  activeModal: ComponentPageModal | null;
  setActiveModal: (modal: ComponentPageModal | null) => void;
  activeSidebar: ComponentPageSidebar | null;
  setActiveSidebar: (sidebar: ComponentPageSidebar | null) => void;
  showInactiveProcesses: boolean;
  setShowInactiveProcesses: (show: boolean) => void;
  // event handlers
  handleSetFocusedComponent: (componentId: string | null) => void;
  handleSetFocusedProcess: (processId: string | null) => void;
}

// Create a default context value for ComponentContext to avoid having to initialize the context with undefined
const defaultContext: ComponentContextProps = {
  // state
  topLevelComponent: null,
  focusedComponent: null,
  focusedProcess: null,
  approvedProcessRevisions: {},
  // state with setters
  activeModal: null,
  setActiveModal: () => {},
  activeSidebar: null,
  setActiveSidebar: () => {},
  showInactiveProcesses: false,
  setShowInactiveProcesses: () => {},
  // event handlers
  handleSetFocusedComponent: () => {},
  handleSetFocusedProcess: () => {},
};

export const ComponentContext = createContext<ComponentContextProps>(defaultContext);

const ComponentProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const [topLevelComponent, setTopLevelComponent] = useState<Component | null>(null);
  const [focusedComponent, setFocusedComponent] = useState<Component | null>(null);
  const [focusedProcess, setFocusedProcess] = useState<Process | null>(null);
  const [activeModal, setActiveModal] = useState<ComponentPageModal | null>(null);
  const [activeSidebar, setActiveSidebar] = useState<ComponentPageSidebar | null>(null);
  const [showInactiveProcesses, setShowInactiveProcesses] = useState<boolean>(false);
  const [approvedProcessRevisions, setApprovedProcessRevisions] = useState<{ [processId: string]: number }>({});

  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/");
    const componentId = path[path.length - 1];
    if (componentId !== topLevelComponent?.id) {
      setTopLevelComponent(components.find((c) => c.id === componentId) ?? null);
      setFocusedComponent(null);
      setFocusedProcess(null);
      setActiveSidebar(null);
    }
  }, [location, components]);

  useEffect(() => {
    if (!activeSidebar) {
      setFocusedComponent(null);
    }
  }, [activeSidebar]);

  const handleSetFocusedComponent = (componentId: string | null) => {
    if (componentId === null) {
      setFocusedProcess(null);
      return;
    }
    setFocusedComponent(components.find((c) => c.id === componentId) ?? null);
  };

  const handleSetFocusedProcess = (processId: string | null) => {
    if (processId === null) {
      setFocusedProcess(null);
      return;
    }
    setFocusedProcess(processes.find((p) => p.id === processId) ?? null);
  };

  useEffect(() => {
    const fetchLatestApprovedProcess = async () => {
      setApprovedProcessRevisions(await getLatestApprovedRevisionByProcessId());
    };
    fetchLatestApprovedProcess();
  }, [processes]);

  return (
    <ComponentContext.Provider
      value={{
        topLevelComponent,
        focusedComponent,
        focusedProcess,
        approvedProcessRevisions,
        activeModal,
        setActiveModal,
        activeSidebar,
        setActiveSidebar,
        showInactiveProcesses,
        setShowInactiveProcesses,
        handleSetFocusedComponent,
        handleSetFocusedProcess,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export default ComponentProvider;
