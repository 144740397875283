import { UniqueIdentifier, UniqueIdentifierLink } from "@shared/types/databaseTypes";
import { MeasureKey } from "../types";

export interface RootToLeafPathSegment {
  uniqueIdentifierId: string;
  componentId: string;
}

export const findRootToLeafPaths = (
  uniqueIdentifierLinks: UniqueIdentifierLink[],
  rootUniqueIdentifier: UniqueIdentifier,
  existingPathSegments: RootToLeafPathSegment[] = [],
): RootToLeafPathSegment[][] => {
  const rootUniqueIdentifierId = rootUniqueIdentifier.id;
  const pathSegments: RootToLeafPathSegment[] = existingPathSegments.length
    ? existingPathSegments
    : [
        {
          uniqueIdentifierId: rootUniqueIdentifier.id,
          componentId: rootUniqueIdentifier.component_id,
        },
      ];
  const children = uniqueIdentifierLinks
    .filter((link) => link.unique_identifier_id === rootUniqueIdentifierId && link.child)
    .map((link) => link.child) as UniqueIdentifier[];
  const newPathBranches = children.flatMap((child) => {
    const newPathSegments: RootToLeafPathSegment[] = [...pathSegments, { uniqueIdentifierId: child.id, componentId: child.component_id }];
    return findRootToLeafPaths(uniqueIdentifierLinks, child, newPathSegments);
  });
  return newPathBranches.length ? newPathBranches : [pathSegments];
};

export const hashRootToLeafPath = (path: RootToLeafPathSegment[]): string => {
  return path.map((segment) => `${segment.uniqueIdentifierId}_${segment.componentId}`).join(">");
};

export const removeDuplicatePaths = (paths: RootToLeafPathSegment[][]): RootToLeafPathSegment[][] => {
  const pathHashes = paths.map(hashRootToLeafPath);
  const uniquePathHashes = Array.from(new Set(pathHashes));
  return uniquePathHashes.map((hash) => paths[pathHashes.indexOf(hash)]);
};

export const filterRootToLeafPathsRelevantToMeasureKeys = (
  rootToLeafPaths: RootToLeafPathSegment[][],
  rootComponentId: string,
  measureKeys: MeasureKey[],
): RootToLeafPathSegment[][] => {
  let filteredRootToLeafPaths: RootToLeafPathSegment[][] = [...rootToLeafPaths];
  // Find all component ids in the set of selected measure keys
  const componentIdsInMeasures = measureKeys.map((key) => key.component_id);
  // Filter out paths segments of components not in the grid
  filteredRootToLeafPaths = filteredRootToLeafPaths.map((path) =>
    path.filter((node) => [rootComponentId, ...componentIdsInMeasures].includes(node.componentId)),
  );
  // Remove any paths that are starting subsets of other paths
  filteredRootToLeafPaths = filteredRootToLeafPaths.filter((path, index) => {
    const otherPathHashes = filteredRootToLeafPaths.filter((_, i) => i !== index).map((path) => hashRootToLeafPath(path));
    return !otherPathHashes.some((pathHash) => pathHash.startsWith(hashRootToLeafPath(path)) && pathHash !== hashRootToLeafPath(path));
  });
  // Remove any duplicate paths
  filteredRootToLeafPaths = removeDuplicatePaths(filteredRootToLeafPaths);
  return filteredRootToLeafPaths;
};

export const findAssociatedUniqueIdentifierIdByComponentId = (
  rootToLeafPaths: RootToLeafPathSegment[][],
  uniqueIdentifierId: string,
  componentId: string,
): string | null => {
  for (let path of rootToLeafPaths) {
    let targetUniqueIdentifier = path.find((item) => item.uniqueIdentifierId === uniqueIdentifierId);
    if (targetUniqueIdentifier) {
      let associatedUniqueIdentifier = path.find((item) => item.componentId === componentId);
      if (associatedUniqueIdentifier) {
        return associatedUniqueIdentifier.uniqueIdentifierId;
      }
    }
  }
  return null; // Return null if no association is found
};
