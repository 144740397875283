/**
 * Interfaces for Supabase Database
 * ======================================
 * This file contains type definitions corresponding to the tables present in our Supabase Postgres database.
 *
 * Each interface represents a table, with properties mapping directly to the columns in each table.
 * The types assigned to each property correspond to the data types of their respective columns in the database.
 *
 * Make sure to update these type definitions whenever changes are made to the structure of the database tables.
 */

import {
  GroupAggregationOperator,
  MeasureFilterCondition,
  MeasureKey,
  MeasureSortCondition,
  FilterJoinOperator as MeasuresFilterJoinOperator,
} from "@shared/measures/types";
import {
  AlertType,
  ApprovalStatus,
  ComponentType,
  DashboardInsightType,
  DataType,
  ProcessType,
  RemovalReason,
  UniqueIdentifierActivityType,
  UniqueIdentifierStatus,
  UserRole,
  WorkOrderActivityType,
  WorkOrderStatus,
  ReportTemplateType,
  LabelSizeUnit,
  LabelElementType,
} from "./databaseEnums";
import { DateRange } from "react-day-picker";
import { GraphType } from "@shared/measures/components/graph/types";

export type TimestamptzString = string;
export type UserMap = Record<string, boolean>; // creates a list of users where the string is a supabase_uid. Boolean to indicate if user is part of the list (true if user is selected)

export interface Approval {
  approver_user_id: string;
  requested_by_user_id: string;
  requested_at: string;
  status: ApprovalStatus | null;
  last_updated_at: string | null;
}

export interface Approval {
  approver_user_id: string;
  requested_by_user_id: string;
  requested_at: string;
  status: ApprovalStatus | null;
  last_updated_at: string | null;
}

// ------------- 'alerts' Table -------------

export interface Alert {
  id: string;
  relevant_ids: string[] | null;
  company_id: string;
  type: AlertType;
  message: string;
  link: string | null; // url path (without base)
  is_active: boolean;
  created_at: TimestamptzString;
  // Foreign key joins
  company?: Company;
}

// ------------- 'checkboxes' Table -------------

export interface Checkbox {
  id: string;
  company_id: string;
  process_entry_id: string;
  unique_identifier_id: string;
  dataset_id: string;
  is_pass: boolean | null;
  prompt: string;
  is_checked: boolean;
  created_at: TimestamptzString;
  // foreign key joins
  company?: Company;
  process_entry?: ProcessEntry;
  unique_identifier?: UniqueIdentifier;
  dataset?: Dataset;
}

// ------------- 'companies' Table -------------

export interface CompanyConfig {
  use_operator_pins?: boolean;
  show_pass_fail_result_in_production_app?: boolean;
  show_specs_in_production_app?: boolean;
  allow_wip_linking?: boolean;
  allow_defective_linking?: boolean;
  show_link_suggestions?: boolean;
  allow_ai?: boolean;
  work_instructions_copilot?: boolean;
  enable_location_tracking?: boolean;
  require_station?: boolean;
  image_compression_size_bytes?: number; // in bytes
  // Integration flags
  enable_dymo_integration?: boolean;
  enable_zebra_integration?: boolean;
  // The following are inaccessable to the client
  enable_dashboards?: boolean;
  enable_reports?: boolean;
}

export interface Company {
  id: string;
  created_at: TimestamptzString;
  name: string;
  is_active: boolean;
  config: CompanyConfig;
  default_language: string;
  i18n_overrides: Record<string, string>;
  // other
  light_logo?: string | null; // light logo url
  dark_logo?: string | null; // dark logo url
  light_icon?: string | null; // light icon url
  dark_icon?: string | null; // dark icon url
}

// ------------- 'component_links' Table -------------

export interface ComponentLink {
  id: string;
  company_id: string;
  component_id: string;
  has_child_of_id: string;
  process_id: string;
  process_revision: number;
  dataset_id: string | null;
  created_at: TimestamptzString;
  is_active: boolean;
  // foreign key joins
  company?: Company;
  parent_component?: Component;
  child_component?: Component;
  process?: Process;
}

// ------------- 'component_process_links' Table -------------

export interface ComponentProcessLink {
  id: string;
  company_id: string;
  component_id: string;
  process_id: string;
  process_revision: number;
  order: number | null;
  is_active: boolean;
  created_at: TimestamptzString;
  // foreign key joins
  component?: Component;
  process?: Process;
  company?: Company;
}

// ------------- 'components' Table -------------

export interface Component {
  id: string;
  name: string;
  is_active: boolean;
  company_id: string;
  created_at: TimestamptzString;
  component_type: ComponentType;
  last_edited_at: TimestamptzString;
  last_edited_user_id: string | null;
  pn_metadata_dataset_ids: string[];
  // foreign key joins
  company?: Company;
  user?: User;
  // other
  url?: string | null; // avatar url
  counts?: ComponentCounts;
  part_number_counts?: ComponentCounts[];
}

export interface ComponentCounts {
  pn?: string; // part number (for part number counts only)
  total: number; // total number of component instances
  wip: number; // number of component instances in WIP
  complete: number; // number of component instances that are COMPLETE
  defective: number; // number of component instances that are DEFECTIVE
  linked: number; // number of component instances that are LINKED
  unlinked: number; // number of component instances that are UNLINKED
}

// ------------- 'datasets' Table -------------

export interface Dataset {
  id: string;
  name: string;
  process_id: string;
  process_revision: number;
  data_type: DataType;
  company_id: string;
  child_component_id: string | null;
  order: number | null;
  created_at: TimestamptzString;
  lsl: number | null;
  usl: number | null;
  expected_value: string | null;
  unit: string | null;
  is_active: boolean;
  prior_values: string[] | null;
  // foreign key joins
  process?: Process;
  company?: Company;
  child_component?: Component;
}

// ------------- 'fields' Table -------------

export type Field =
  | ManualEntryField
  | LinkField
  | ImageField
  | FileField
  | CheckboxField
  | PassFailField
  | DatetimeField
  | SignatureField
  | LabelField;

export interface BaseField {
  id: string;
  company_id: string;
  is_optional: boolean;
  process_step_id: string;
  type: FieldType;
  group_name: string;
  prompt: string;
  order: number;
  created_at: TimestamptzString;
  dataset_id: string;
  method: string | null;
  data_validation: string | null;
  label_format_id: string | null;
  // foreign key joins
  company?: Company;
  process_step?: ProcessStep;
  dataset?: Dataset;
  LabelFormat?: LabelFormat;
}

export interface LinkField extends BaseField {
  type: FieldType.Link;
}

export interface ManualEntryField extends BaseField {
  type: FieldType.ManualEntry;
  data_validation: "STRING" | "INTEGER" | "NUMBER";
}

export interface ImageField extends BaseField {
  type: FieldType.Image;
  method: "UPLOAD" | "CAMERA";
}

export interface FileField extends BaseField {
  type: FieldType.File;
}

export interface CheckboxField extends BaseField {
  type: FieldType.Checkbox;
}

export interface LabelField extends BaseField {
  type: FieldType.Label;
  label_format_id: string;
}

export interface PassFailField extends BaseField {
  type: FieldType.PassFail;
}

export interface DatetimeField extends BaseField {
  type: FieldType.Datetime;
}

export interface SignatureField extends BaseField {
  type: FieldType.Signature;
}

// TODO: move this to shared/types/databaseEnums.ts
export enum FieldType {
  Link = "LINK",
  ManualEntry = "MANUAL_ENTRY",
  Checkbox = "CHECKBOX",
  PassFail = "PASSFAIL",
  Image = "IMAGE",
  File = "FILE",
  Datetime = "DATETIME",
  Signature = "SIGNATURE",
  Label = "LABEL",
}

// ------------- 'files' Table -------------

export interface File {
  id: string;
  company_id: string;
  process_entry_id: string;
  unique_identifier_id: string;
  format: string;
  file_name: string;
  dataset_id: string;
  created_at: TimestamptzString;
  bucket_name: string;
  file_id: string | null;
  // foreign key joins
  company?: Company;
  process_entry?: ProcessEntry;
  unique_identifier?: UniqueIdentifier;
  dataset?: Dataset;
  // download url
  url?: string | null;
}

// ------------- 'filter_conditions' Table -------------

export interface FilterCondition {
  id: string;
  company_id: string;
  process_step_id: string | null;
  name: string;
  type: CollectionType;
  dataset_id?: string;
  operator: FilterConditionOperator;
  created_at: TimestamptzString;
  value: string;
  label: string; // consider not including in database
  // foreign key joins
  step?: ProcessStep;
  company?: Company;
}

// TODO: move this to shared/types/databaseEnums.ts
export enum FilterConditionOperator {
  Equals = "EQUALS",
  NotEquals = "NOT_EQUALS",
  GreaterThan = "GREATER_THAN",
  LessThan = "LESS_THAN",
  GreaterThanOrEqualTo = "GREATER_THAN_OR_EQUAL_TO",
  LessThanOrEqualTo = "LESS_THAN_OR_EQUAL_TO",
  Contains = "CONTAINS",
  DoesNotContain = "DOES_NOT_CONTAIN",
  In = "IN",
  NotIn = "NOTIN",
}

// TODO: move this to shared/types/databaseEnums.ts
export enum FilterJoinOperator {
  And = "AND",
  Or = "OR",
}

// TODO: move this to shared/types/databaseEnums.ts
export enum CollectionType {
  ParametricQualitative = "PARAMETRIC_QUALITATIVE",
  ParametricQuantitative = "PARAMETRIC_QUANTITATIVE",
  Checkbox = "CHECKBOX",
  PassFail = "PASSFAIL",
  Image = "IMAGE",
  File = "FILE",
  Link = "LINK",
  Identifier = "IDENTIFIER",
  Status = "STATUS",
  LinkStatus = "LINK_STATUS",
  PartNumber = "PART_NUMBER",
  Station = "STATION",
  Operator = "OPERATOR",
  CycleTime = "CYCLE_TIME",
  Timestamp = "TIMESTAMP",
  ProcessResult = "PROCESS_RESULT",
  WorkOrderName = "WORK_ORDER_NAME",
  WorkOrderPhase = "WORK_ORDER_PHASE",
}

// ------------- 'images' Table -------------

export interface Image {
  id: string;
  company_id: string;
  process_entry_id: string;
  unique_identifier_id: string;
  file_name: string;
  dataset_id: string;
  created_at: TimestamptzString;
  bucket_name: string;
  file_id: string | null;
  // foreign key joins
  company?: Company;
  process_entry?: ProcessEntry;
  unique_identifier?: UniqueIdentifier;
  dataset?: Dataset;
  // download url
  url?: string | null;
}

// ------------- 'operators' Table -------------

export interface Operator {
  id: string;
  first_name: string;
  last_name: string | null;
  pin: string | null;
  user_id: string | null;
  created_at: TimestamptzString;
  company_id: string;
  is_active: boolean;
  // foreign key joins
  company?: Company;
}

// ------------- 'parametric_qualitative' Table -------------

export interface ParametricQualitative {
  id: string;
  company_id: string;
  process_entry_id: string;
  unique_identifier_id: string;
  value: string;
  dataset_id: string;
  created_at: TimestamptzString;
  // foreign key joins
  dataset?: Dataset;
  company?: Company;
  process_entry?: ProcessEntry;
  unique_identifier?: UniqueIdentifier;
}

// ------------- 'parametric_quantitative' Table -------------

export interface ParametricQuantitative {
  id: string;
  company_id: string;
  process_entry_id: string;
  unique_identifier_id: string;
  value: number;
  dataset_id: string;
  created_at: TimestamptzString;
  is_discrete: boolean;
  is_pass: boolean | null;
  lsl: number | null;
  usl: number | null;
  unit: string | null;
  // foreign key joins
  dataset?: Dataset;
  company?: Company;
  process_entry?: ProcessEntry;
  unique_identifier?: UniqueIdentifier;
}

// ------------- 'part_numbers' Table -------------

export interface PartNumber {
  id: string;
  company_id: string;
  pn: string;
  is_active: boolean;
  created_at: TimestamptzString;
  last_edited_at: TimestamptzString;
  last_edited_user_id: string | null;
  description: string | null;
  component_id: string;
  metadata: PartNumberMetadata;
  // foreign key joins
  company?: Company;
  component?: Component;
  user?: User;
}

export interface PartNumberMetadata {
  [dataset_id: string]: PartNumberMetadataItem;
}

export interface PartNumberMetadataItem {
  value: string; // value is the file_id if the data type is file or image
  file_name?: string | null; // only for file and image data types
}

// ------------- 'process_entries' Table -------------

export interface ProcessEntry {
  id: string;
  is_pass: boolean | null;
  timestamp: TimestamptzString;
  cycle_time: number | null;
  company_id: string;
  process_id: string;
  station_id: string | null;
  operator_id: string | null;
  override_user_id: string | null;
  process_revision: number;
  unique_identifier_id: string;
  upload_error: boolean;
  created_at: TimestamptzString;
  // foreign key joins
  station?: Station;
  operator?: Operator;
  override_user?: User;
  unique_identifier?: UniqueIdentifier;
  component?: Component;
  process?: Process;
  part_number?: PartNumber;
}

// ------------- 'processes' Table -------------

export interface Process {
  id: string;
  revision: number; // Updated automatically by database trigger
  name: string;
  use_api: boolean;
  company_id: string;
  created_at: TimestamptzString;
  created_by_user_id: string | null;
  is_mandatory: boolean;
  record_operator: boolean;
  break_prior_links: boolean;
  dependent_process_ids: any[];
  type: ProcessType;
  revision_description: string;
  ai_revision_description: string;
  is_latest_revision: boolean;
  carbon_copy?: ProcessCarbonCopy | null;
  allow_batch: boolean;
  approved: boolean;
  approved_by_user_id: string | null;
  approved_at: TimestamptzString | null;
  show_media_numbering: boolean;
  // foreign key joins
  company?: Company;
  user?: User;
  // foreign reference joins
  process_steps?: ProcessStep[];
}

export interface ProcessCarbonCopy {
  fields: Field[];
  processes: Process[];
}

export interface ProcessWithReferences extends Process {
  process_steps: ProcessStepWithReferences[]; // Guarantees the process to have a process_steps array
}

// ------------- 'process_steps' Table -------------

export interface ProcessStep {
  id: string;
  company_id: string;
  order: number;
  name: string;
  process_id: string;
  process_revision: number;
  filter_join_operator: FilterJoinOperator;
  created_at: TimestamptzString;
  is_hidden: boolean;
  // foreign key joins
  process?: Process;
  company?: Company;
  // foreign reference joins
  work_instruction_blocks?: WorkInstructionBlock[];
  fields?: Field[];
  filter_conditions?: FilterCondition[];
}

export interface ProcessStepWithReferences extends ProcessStep {
  work_instruction_blocks: WorkInstructionBlock[]; // Guarantees the process step to have a work_instructions_blocks array
  fields: Field[]; // Guarantees the process step to have a fields array
  filter_conditions: FilterCondition[]; // Guarantees the process step to have a filter_conditions array
}

export interface ProcessCarbonCopy {
  fields: Field[];
  processes: Process[];
}
// ------------- 'report_templates' Table -------------

export interface ReportTemplate {
  id: string;
  revision: number; // Updated automatically by database trigger
  company_id: string;
  type: ReportTemplateType;
  name: string;
  description?: string;
  created_at: TimestamptzString;
  created_by: string;
  last_edited_at?: TimestamptzString; // Updated automatically by database trigger
  last_edited_by?: string;
  is_public: boolean;
  shared_with: UserMap;
  is_latest_revision: boolean; // Updated automatically by database trigger
  config: any;
  // foreign key joins
  company?: Company;
  last_updated_user?: User;
  created_user?: User;
}

export interface DateRangeTimestamptz {
  from: TimestamptzString | null;
  to: TimestamptzString | null;
}

// |-----> SN Lookup PDF Report Template

export interface ReportTemplateSnLookupPdfConfig {}

export interface ReportTemplateSnLookupPdf extends ReportTemplate {
  type: ReportTemplateType.SnLookupPdfTemplate;
  config: ReportTemplateSnLookupPdfConfig;
}

// |-----> Dashboard Page Report Template

export interface ReportTemplateDashboard extends ReportTemplate {
  type: ReportTemplateType.Dashboard;
  config: ReportTemplateDashboardConfig;
}

export interface ReportTemplateDashboardConfig {
  is_prebuilt: boolean;
  icon?: JSX.Element; // icon is only displayed if is_prebuilt is true
  rows: DashboardRow[];
}

export interface DashboardRow {
  height: number;
  insights: DashboardInsight[];
}

export interface DashboardInsight {
  type: DashboardInsightType;
  config: any;
}

// |-----> Dashboard Custom Insight Report Template

export interface ReportTemplateCustomInsightConfig {}

export interface ReportTemplateCustomInsight extends ReportTemplate {
  type: ReportTemplateType.CustomInsight;
  config: ReportTemplateCustomInsightConfig;
}

// |-----> Measures Report Templates (multiple report templates may extend this measure config)

export interface ReportTemplateMeasuresConfig {
  component_id: string;
  selected_measure_keys: MeasureKey[];
  filter_by: MeasureFilterCondition[];
  group_by: MeasureKey[];
  sort_by: MeasureSortCondition[];
  filter_join_operator: MeasuresFilterJoinOperator;
  group_aggregation_operator: GroupAggregationOperator;
  last_updated_date_range_filter?: DateRange;
}

// |-----> Grid Builder Report Template

export interface ReportTemplateGridBuilderViewConfig
  extends ReportTemplateMeasuresConfig {}

export interface ReportTemplateGridBuilderView extends ReportTemplate {
  type: ReportTemplateType.GridBuilderView;
  config: ReportTemplateGridBuilderViewConfig;
}

// |-----> Graph Builder Report Template

export interface ReportTemplateGraphBuilderViewConfig
  extends ReportTemplateMeasuresConfig {
  graph_type: GraphType | null;
  x_axis_measure_key: MeasureKey | null;
  y_axis_measure_key: MeasureKey | null;
  categories_measure_key: MeasureKey | null;
  show_retests: boolean;
  show_legend: boolean;
  show_grid_lines: boolean;
  relative: boolean;
}

export interface ReportTemplateGraphBuilderView extends ReportTemplate {
  type: ReportTemplateType.GraphBuilderView;
  config: ReportTemplateGraphBuilderViewConfig;
}

// ------------- 'station_process_links' Table -------------

export interface StationProcessLink {
  id: string;
  company_id: string;
  process_id: string;
  process_revision: number;
  station_id: string;
  component_id: string;
  created_at: TimestamptzString;
  // foreign key joins
  company?: Company;
  station?: Station;
  component?: Component;
  process?: Process;
}

// ------------- 'stations' Table -------------

export interface Station {
  id: string;
  name: string | null;
  type: string;
  use_api: boolean;
  location: string | null;
  device_id: string | null;
  is_active: boolean;
  is_locked: boolean;
  company_id: string;
  created_at: TimestamptzString;
  // foreign key joins
  company?: Company;
  // foreign reference joins
  station_process_links?: StationProcessLink[];
}

// ------------- 'unique_identifier_links' Table -------------

export interface UniqueIdentifierLink {
  id: string;
  company_id: string;
  unique_identifier_id: string;
  has_child_of_id: string;
  process_entry_id: string;
  is_active: boolean;
  dataset_id: string;
  created_at: TimestamptzString;
  removed_at: TimestamptzString | null; // set automatically by database trigger when is_active is set to false
  removed_by_user_id: string | null;
  removal_reason: RemovalReason | null;
  // foreign key joins
  company?: Company;
  unique_identifiers?: UniqueIdentifier;
  child?: UniqueIdentifier;
  process_entry?: ProcessEntry;
  dataset?: Dataset;
  // sometimes added on the client side
  linked_component_name?: string;
}

// ------------- 'unique_identifiers' Table -------------

export interface UniqueIdentifier {
  id: string;
  status: UniqueIdentifierStatus | null;
  company_id: string;
  created_at: TimestamptzString;
  identifier: string;
  completed_at: TimestamptzString | null;
  component_id: string;
  part_number_id: string | null;
  last_updated_at: TimestamptzString;
  is_archived: boolean;
  work_order_id: string | null;
  latitude: number | null;
  longitude: number | null;
  latest_process_entry_id: string | null;
  // foreign key joins
  company?: Company;
  component?: Component;
  part_number?: PartNumber;
  work_order?: WorkOrder;
  latest_process_entry?: ProcessEntry;
}

// ------------- 'unique_identifier_activity_log' Table -------------

export interface UniqueIdentifierActivityLog {
  id: string;
  company_id: string;
  unique_identifier_id: string;
  type: UniqueIdentifierActivityType;
  comment: string | null;
  created_at: TimestamptzString;
  user_id: string | null;
}

// ------------- 'users' Table -------------

export interface User {
  company_id: string;
  created_at: TimestamptzString;
  is_active: boolean;
  email: string;
  first_name: string | null;
  last_name: string | null;
  role: UserRole;
  title: string | null;
  supabase_uid: string;
  default_station_id: string | null;
  // foreign key joins
  company?: Company;
  station?: Station;
  // other
  url?: string | null; // avatar url
}

// ------------- 'versions' Table -------------

export interface Version {
  id: string;
  app_version: string;
  release_date: string;
  environment: string;
  git_commit: string;
  notes: string | null;
  created_at: TimestamptzString;
}

// ------------- 'work_instruction_blocks' Table -------------

export type WorkInstructionBlock =
  | ImageBlock
  | TableBlock
  | HeadingBlock
  | CalloutBlock
  | VideoBlock
  | PdfBlock
  | FileBlock
  | ListBlock
  | TextBlock
  | CodeBlock
  | DelimiterBlock;

export interface BaseWorkInstructionBlock {
  id: string;
  company_id: string;
  process_step_id: string;
  order: number;
  type: WorkInstructionBlockType;
  file_id: string | null;
  created_at: TimestamptzString;
  dataset_ref_1: string | null;
  dataset_ref_2: string | null;
  dataset_ref_3: string | null;
  dataset_ref_4: string | null;
  dataset_ref_5: string | null;
  content: WorkInstructionBlockContent;
  // foreign key joins
  company?: Company;
  process_step?: ProcessStep;
}

export interface WorkInstructionBlockContent {
  text?: string;
  level?: 1 | 2 | 3; // e.g. H1, H2, H3
  rows?: string[][];
  header_row?: boolean;
  header_column?: boolean;
  icon?: "INFO" | "WARNING" | "DANGER";
  file_name?: string;
  height?: number;
  caption?: string;
  items?: string[];
  column_widths?: string[];
  listType?: "BULLETED" | "NUMBERED";
}

export interface TableBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Table;
  content: {
    header_row: boolean;
    header_column: boolean;
    rows: string[][];
    column_widths?: string[];
  };
}

export interface HeadingBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Heading;
  content: {
    text: string;
    level: 1 | 2 | 3; // e.g. H1, H2, H3
  };
}

export interface CalloutBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Callout;
  content: {
    icon: "INFO" | "WARNING" | "DANGER";
    text: string;
  };
}

export interface VideoBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Video;
  file_id: string;
  content: {
    file_name: string;
    height: number;
    caption?: string;
  };
}

export interface PdfBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.PDF;
  file_id: string;
  content: {
    file_name: string;
    height: number;
    caption?: string;
  };
}

export interface FileBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.File;
  file_id: string;
  content: {
    file_name: string;
    caption?: string;
  };
}

export interface ImageBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Image;
  file_id: string;
  content: {
    file_name: string;
    height: number;
    caption?: string;
    markup?: ImageMarkupElement[];
  };
}

export interface ListBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.List;
  content: {
    items: string[];
    listType: "BULLETED" | "NUMBERED";
  };
}

export interface TextBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Text;
  content: {
    text: string;
  };
}

export interface CodeBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Code;
  content: {
    text: string;
  };
}

export interface DelimiterBlock extends BaseWorkInstructionBlock {
  type: WorkInstructionBlockType.Delimiter;
  content: Record<string, never>;
}

// TODO: move this to shared/types/databaseEnums.ts
export enum WorkInstructionBlockType {
  Image = "IMAGE",
  Table = "TABLE",
  Heading = "HEADING",
  Delimiter = "DELIMITER",
  Callout = "CALLOUT",
  Video = "VIDEO",
  PDF = "PDF",
  File = "FILE",
  List = "LIST",
  Text = "TEXT",
  Code = "CODE",
}

export enum ImageMarkupElementType {
  Rectangle = "RECTANGLE",
  Circle = "CIRCLE",
  Text = "TEXT",
  Arrow = "ARROW",
  Magnify = "MAGNIFY",
  Focus = "FOCUS",
}

export interface ImageMarkupElementBase {
  type: ImageMarkupElementType;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ImageMarkupElementRectangle extends ImageMarkupElementBase {
  type: ImageMarkupElementType.Rectangle;
  color: string;
  fill: string | null;
  thickness: number;
}

export interface ImageMarkupElementCircle extends ImageMarkupElementBase {
  type: ImageMarkupElementType.Circle;
  color: string;
  fill: string | null;
  thickness: number;
}

export interface ImageMarkupElementText extends ImageMarkupElementBase {
  type: ImageMarkupElementType.Text;
  text: string;
  color: string;
  fill: string | null;
  bold: boolean;
  italic: boolean;
  underline: boolean;
}

export interface ImageMarkupElementArrow extends ImageMarkupElementBase {
  type: ImageMarkupElementType.Arrow;
  color: string;
  thickness: number;
}

export interface ImageMarkupElementMagnify extends ImageMarkupElementBase {
  type: ImageMarkupElementType.Magnify;
}

export interface ImageMarkupElementFocus extends ImageMarkupElementBase {
  type: ImageMarkupElementType.Focus;
}

export type ImageMarkupElement =
  | ImageMarkupElementRectangle
  | ImageMarkupElementCircle
  | ImageMarkupElementText
  | ImageMarkupElementArrow
  | ImageMarkupElementMagnify
  | ImageMarkupElementFocus;

// ------------- 'work_orders' Table -------------

export interface WorkOrder {
  id: string;
  company_id: string;
  wo_number: number;
  name: string;
  description: string | null;
  component_id: string;
  part_number_id: string | null;
  created_at: TimestamptzString;
  created_by_user_id: string;
  status: WorkOrderStatus;
  phase: string | null;
  quantity: number;
  approvals: Record<string, Approval>; // approval_id: Approval
  assignee_user_id: string | null;
  deadline: TimestamptzString | null;
  activity_log: WorkOrderActivity[];
  last_edited_at: TimestamptzString | null;
  attachments: WorkOrderAttachment[];
  // foreign key joins
  company?: Company;
  component?: Component;
  part_number?: PartNumber;
  assignee?: User;
}

export interface WorkOrderAttachment {
  dataset_id: string;
  value: string; // value is the file_id if the data type is file or image
  file_name?: string | null; // only for file and image data types
  added_at: TimestamptzString;
  added_by_user_id: string;
  is_deleted: boolean;
}

export interface WorkOrderActivity {
  id: string;
  message: string | null;
  added_at: string;
  added_by_user_id: string;
  type: WorkOrderActivityType;
  data: WorkOrderActivityData;
}

export interface WorkOrderActivityData {
  status?: WorkOrderStatus;
  approval_status?: ApprovalStatus;
  approver_user_id?: string;
  change_log?: WorkOrderChange[];
  attachement_name?: string;
}

export interface WorkOrderChange {
  column_name: string;
  old_value: any;
  new_value: any;
}

// ------------- 'datetime_data' Table -------------
export interface DatetimeData {
  id: string;
  company_id: string;
  process_entry_id: string;
  unique_identifier_id: string;
  value: TimestamptzString;
  dataset_id: string;
  created_at: TimestamptzString;
  // foreign key joins
  dataset?: Dataset;
  company?: Company;
  process_entry?: ProcessEntry;
  unique_identifier?: UniqueIdentifier;
}

// ------------- 'log_file_schemas' Table -------------

export interface LogFileSchema {
  id: string;
  revision: number;
  company_id: string;
  sample_file_data: Record<string, any> | null;
  sample_file_name: string;
  sample_file_id: string | null;
  created_at: TimestamptzString;
  source_dataset_id: string;
  // foreign key joins
  company?: Company;
  source_dataset?: Dataset;
}

// ------------- 'log_file_schema_dataset_links' Table -------------

export interface LogFileSchemaDatasetLinkPathKeyItem {
  key: string | number;
  type: "KEY" | "INDEX";
}

export interface LogFileSchemaDatasetLink {
  id: string;
  company_id: string;
  schema_id: string;
  dataset_id: string;
  path_key: LogFileSchemaDatasetLinkPathKeyItem[];
  created_at: TimestamptzString;
  is_active: boolean;
  schema_revision: number;
  // foreign key joins
  company?: Company;
  schema?: LogFileSchema;
  dataset?: Dataset;
}

// ------------- 'label_formats' Table -------------

export interface LabelFormat {
  id: string;
  company_id: string;
  component_id: string;
  name: string;
  width: number; // mm
  height: number; // mm
  displayed_size_unit: LabelSizeUnit;
  last_edited_at: string;
  last_edited_by_user_id: string;
  created_at: string;
  created_by_user_id: string;
  xml: string | null;
  xml_identifier: string | null;
  // foreign key joins
  company?: Company;
  component?: Component;
}

// ------------- 'label_elements' Table -------------

export interface LabelElement {
  id: string;
  type: LabelElementType;
  company_id: string;
  label_format_id: string;
  x: number | null; // mm (absolute position only)
  y: number | null; // mm (absolute position only)
  text: string | null; // for TextElement
  font_size: number | null; // for (TextElement and IdentifierElement)
  bold: boolean | null; // for TextElement
  italic: boolean | null; // for TextElement
  underline: boolean | null; // for TextElement
  file_id: string | null; // for ImageElement
  width: number | null; // mm (for ImageElement, BarcodeElement, and QRCodeElement)
  height: number | null; // mm (for ImageElement, BarcodeElement, and QRCodeElement)
  // foreign key joins
  company?: Company;
  label_format?: LabelFormat;
}

// ------------- 'webhooks' Table -------------

export interface Webhook {
  id: string;
  company_id: string;
  trigger_process_id: string | null;
  trigger_process_revision: number | null;
  url: string;
  metadata: Record<string, string>;
  created_at: TimestamptzString;
  // foreign key joins
  company?: Company;
  process?: Process;
}
