import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { ReactElement, useMemo } from "react";

type TableProps = {
  data: (string | ReactElement)[][];
  columnWidths?: (number[] | string)[];
  headerRow?: boolean;
  headerColumn?: boolean;
  fontSize?: number;
  boldHeader?: boolean;
  cellPadding?: number;
};

const styles = StyleSheet.create({
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#000000",
    borderWidth: 1,
  },
  tableRow: {
    flexDirection: "row",
    borderStyle: "solid",
    borderColor: "#000000",
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
});

const Table: React.FC<TableProps> = ({ data, columnWidths, headerRow, headerColumn, fontSize, boldHeader, cellPadding }) => {
  const dynamicStyles = StyleSheet.create({
    cellContainer: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: fontSize || 10,
    },
    tableHeaderCell: {
      backgroundColor: "#ccc",
      fontWeight: boldHeader ? "bold" : "normal",
    },
    tableCell: {
      borderStyle: "solid",
      borderColor: "#000000",
      borderLeftWidth: 1,
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      padding: cellPadding ? cellPadding : 5,
    },
  });

  const widths = useMemo(() => {
    if (columnWidths) return columnWidths;

    // If not all rows are string arrays, return undefined
    if (!data.every((row) => row.every((cell) => typeof cell === "string"))) return undefined;

    // Calculate widths based on the longest string in each column
    const calculatedWidths = data[0].map((_, colIndex) => {
      let maxWidth = 0;
      data.forEach((row) => {
        const cell = row[colIndex];
        if (typeof cell === "string") {
          maxWidth = Math.max(maxWidth, cell.length);
        }
      });
      // Convert maxWidth to a percentage
      return `${(maxWidth / data.reduce((max, row) => Math.max(max, (row[colIndex] as string).length), 1)) * 100}%`;
    });

    return calculatedWidths;
  }, [columnWidths, data]);

  return (
    <View style={styles.table}>
      {data.map((row, rowIndex) => {
        let rowStyle: any[] = [styles.tableRow];
        if (rowIndex === 0) rowStyle.push({ borderTopWidth: 0 });
        if (rowIndex === data.length - 1) rowStyle.push({ borderBottomWidth: 0 });
        return (
          <View key={rowIndex} style={rowStyle} wrap={false}>
            {row.map((cell, colIndex) => {
              let colStyle: any[] = [dynamicStyles.tableCell];
              if ((headerRow && rowIndex === 0) || (headerColumn && colIndex === 0)) colStyle.push(dynamicStyles.tableHeaderCell);
              if (colIndex === 0) colStyle.push({ borderLeftWidth: 0 });
              if (widths?.[colIndex]) colStyle.push({ width: widths[colIndex] });
              return (
                <View key={colIndex} style={colStyle}>
                  <Text style={dynamicStyles.cellContainer}>{cell}</Text>
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

export default Table;
