import React from "react";
import ProcessBuilderAutoParsingSelector from "./ProcessBuilderAutoParsingSelector";
import ProcessBuilderAutoParsingJson from "./ProcessBuilderAutoParsingJson";

const ProcessBuilderAutoParsing: React.FC = () => {
  return (
    <div className="relative flex h-full overflow-hidden text-left">
      <ProcessBuilderAutoParsingSelector />
      <ProcessBuilderAutoParsingJson />
    </div>
  );
};

export default ProcessBuilderAutoParsing;
