import { ColDef, ColGroupDef } from "ag-grid-community";
import CustomCellHeaderGroup from "./MeasuresGridHeaderGroupCell";
import { Component, Process, ProcessEntry } from "@shared/types/databaseTypes";
import { MeasureKey, MeasureType, MeasureValue, MeasureValueSummary, UniqueIdentifierWithMeasures } from "@shared/measures/types";

export const columnTypes: Record<MeasureType, ColDef> = {
  [MeasureType.Identifier]: {},
  [MeasureType.Status]: {
    columnGroupShow: "open",
  },
  [MeasureType.LinkStatus]: {
    columnGroupShow: "open",
  },
  [MeasureType.Checkbox]: {
    columnGroupShow: "open",
  },
  [MeasureType.Image]: {
    columnGroupShow: "open",
  },
  [MeasureType.File]: {
    columnGroupShow: "open",
  },
  [MeasureType.ParametricQuantitative]: {
    columnGroupShow: "open",
  },
  [MeasureType.ParametricQualitative]: {
    columnGroupShow: "open",
  },
  [MeasureType.WorkOrder]: {
    columnGroupShow: "open",
  },
  [MeasureType.WorkOrderPhase]: {
    columnGroupShow: "open",
  },
  [MeasureType.PartNumber]: {
    columnGroupShow: "open",
  },
  [MeasureType.Station]: {
    columnGroupShow: "open",
  },
  [MeasureType.Operator]: {
    columnGroupShow: "open",
  },
  [MeasureType.PassFail]: {
    columnGroupShow: "open",
  },
  [MeasureType.ProcessResult]: {
    columnGroupShow: "open",
  },
  [MeasureType.Datetime]: {
    columnGroupShow: "open",
  },
  [MeasureType.CycleTime]: {
    columnGroupShow: "open",
  },
  [MeasureType.Timestamp]: {
    columnGroupShow: "open",
  },
  [MeasureType.Link]: {
    columnGroupShow: "open",
  },
};

// The ag-grid ColDef, defining the column properties (this object is specific to column groups)
export interface MeasuresGridGroupColDef extends ColGroupDef {
  componentId: string;
  headerName: string;
  headerGroupComponent: typeof CustomCellHeaderGroup;
  headerGroupComponentParams: {
    component: Component;
  };
  children: MeasuresGridChildColDef[];
}

// The ag-grid ColDef, defining the column properties (this object is the actual columns)
export interface MeasuresGridChildColDef extends ColDef {
  headerName: string;
  field: string;
  type: MeasureType;
  headerComponentParams: {
    measureKey: MeasureKey;
  };
  cellRendererParams: {
    measureKey: MeasureKey;
    gridContainerRef: React.RefObject<HTMLDivElement>;
    setPreviewObject: (value: MeasureGridCellCoordinate | null) => void;
    processRevisions: Process[]; // all the process revisions for the particular process of this column. Used for computing the blank data reason
    processEntries: ProcessEntry[]; // all the process entries for the particular process of this column. Used for computing the blank data reason
  };
}

interface MeasuresValuesByHash {
  [measureKeyHash: string]: {
    measureValues: MeasureValue[] | null;
    measureAggregation: MeasureValueSummary | null;
    sameAsAbove: boolean;
    notApplicable: boolean;
  };
}

export interface MeasuresGridRowData extends UniqueIdentifierWithMeasures {
  rowUniqueIdentifierIds: string[]; // The unique identifier ids that are part of this row
  measuresValuesByHash: MeasuresValuesByHash;
  bgShaded?: boolean;
}

export interface MeasureGridCellCoordinate {
  measureKey: MeasureKey;
  rowIndex: number;
}

export enum BlankDataReason {
  OptionalData = "OPTIONAL_DATA", // The data is optional and was not collected for this unit
  InProgress = "IN_PROGRESS", // The unit has not completed this process yet so the data is not available
  DifferentVersion = "DIFFERENT_VERSION", // The unit was built with a different version of the process
  NotSupported = "NOT_SUPPORTED", // This feature was added after the data was collected
  NotRecorded = "NOT_RECORDED", // Default contion
}

export const blankDataReasonStyle = {
  [BlankDataReason.OptionalData]: { label: "Optional Data", explanation: "The data field is optional and may not be collected." },
  [BlankDataReason.InProgress]: {
    label: "In Progress",
    explanation: "The unit is still in progress. Data may be available once the unit is complete.",
  },
  [BlankDataReason.DifferentVersion]: {
    label: "Different Version",
    explanation: "The unit was run with a version of the process that did not capture this data.",
  },
  [BlankDataReason.NotSupported]: {
    label: "Not Supported",
    explanation: "The feature was not supported by the version of Serial running when the data was captured.",
  },
  [BlankDataReason.NotRecorded]: {
    label: "Not Recorded",
    explanation: "The data was not recorded for some other reason. Contact Serial for more information.",
  },
};

export const featureImplementationTimestamps: { [key in MeasureType]: string | null } = {
  [MeasureType.CycleTime]: "2023-06-24T00:55:07.000000+00:00",
  [MeasureType.Status]: null,
  [MeasureType.LinkStatus]: null,
  [MeasureType.PartNumber]: null,
  [MeasureType.Station]: null,
  [MeasureType.Operator]: null,
  [MeasureType.Timestamp]: null,
  [MeasureType.ProcessResult]: null,
  [MeasureType.WorkOrder]: null,
  [MeasureType.WorkOrderPhase]: null,
  [MeasureType.Identifier]: null,
  [MeasureType.Link]: null,
  [MeasureType.ParametricQualitative]: null,
  [MeasureType.ParametricQuantitative]: null,
  [MeasureType.Datetime]: null,
  [MeasureType.Image]: null,
  [MeasureType.File]: null,
  [MeasureType.Checkbox]: null,
  [MeasureType.PassFail]: null,
};
