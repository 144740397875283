import { TextBlock } from "@shared/types/databaseTypes";
import { ProductionWorkInstructionBlockContentProps } from "../../types";
import { useMentions } from "@shared/hooks/useMentions";
import { ProductionContext } from "../../ProductionProvider";
import { useContext } from "react";

const ProductionWorkInstructionBlockText: React.FC<ProductionWorkInstructionBlockContentProps<TextBlock>> = ({ block }) => {
  const { partNumber, datasetDataById } = useContext(ProductionContext);
  const { DecodedContent } = useMentions({ encodedContent: block.content.text, partNumber: partNumber ?? undefined, datasetDataById });

  return <div className="w-full">{DecodedContent}</div>;
};

export default ProductionWorkInstructionBlockText;
