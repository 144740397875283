import React, { useContext, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import FileUploadBox from "@shared/components/FileUploadBox";
import Banner2 from "@shared/components/Banner2";
import Select from "@shared/components/primitives/Select";
import { ErrorBanner } from "@shared/components/error/types";
import { DataType } from "@shared/types/databaseEnums";
import { Dataset } from "@shared/types/databaseTypes";
import { useEffect } from "react";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";
import { PartNumberWithState } from "./PartNumbers";
import { RowState } from "./PartNumberGrid";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface PartNumberSidebarCsvUploadProps {
  setSidebarOpen: (open: boolean) => void;
  addNewColumn: (type: DataType, name?: string) => string; // adds a dataset to the gridDatasets and returns the dataset id
  gridDatasets: Dataset[];
  partNumbers: PartNumberWithState[];
  setPartNumbers: (partNumbers: PartNumberWithState[]) => void;
}

interface CsvColumn {
  header: string;
  data: string[];
  associatedDatasetId: string | null;
}

const PartNumberSidebarCsvUpload: React.FC<PartNumberSidebarCsvUploadProps> = ({
  setSidebarOpen,
  addNewColumn,
  gridDatasets,
  partNumbers,
  setPartNumbers,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<ErrorBanner>({ hide: true, isValid: true, message: "", type: "info" });
  const [csvData, setCsvData] = useState<CsvColumn[]>([]);

  const partNumbersRef = useRef<PartNumberWithState[]>([]);

  const observe = useContext(ObservabilityContext);

  const handleNewFile = (file: File) => {
    observe.track("part-number_csv-import");

    // check to make sure the file is a CSV
    if (file.type !== "text/csv") {
      setError({ hide: false, isValid: false, message: "Please upload a CSV file", type: "error" });
      return;
    }
    setError({ hide: true, isValid: true, message: "", type: "info" });
    setSelectedFile(file);
  };

  // Set the associated dataset for a column and remove from all other columns
  const handleSelectAssociatedDataset = (columnIndex: number, datasetId: string) => {
    const newCsvData = csvData.map((column, index) => {
      if (index !== columnIndex && column.associatedDatasetId === datasetId && datasetId !== "NEW") {
        return { ...column, associatedDatasetId: null };
      } else if (index === columnIndex) {
        return { ...column, associatedDatasetId: datasetId };
      }
      return column;
    });
    setCsvData(newCsvData);
  };

  const insertColumnDataIntoPartNumbersObject = (
    currPartNumbers: PartNumberWithState[],
    pnColumn: string[],
    dataColumn: string[],
    datasetId: string,
  ) => {
    const newPartNumbers = [...currPartNumbers];
    // iterate over data in the column
    dataColumn.forEach((data, index) => {
      // find the part number that matches the row
      const partNumbersIndex = newPartNumbers.findIndex((partNumber) => partNumber.pn === pnColumn[index]);
      // if we're out of bounds for part numbers, create new ones
      if (partNumbersIndex === -1) {
        const newPartNumber = {
          // ------ to be set on save event by modal component >>>>>>
          id: uuidv4(),
          company_id: "",
          is_active: true,
          created_at: "",
          last_edited_at: "",
          last_edited_user_id: "",
          component_id: "",
          // <<<<<<<< to be set on save event by modal component ------
          pn: "",
          description: "",
          metadata: {},
          state: RowState.New,
        };
        // set the data for the new part number depending on the dataset
        if (datasetId === "pn") {
          newPartNumber.pn = data;
        } else if (datasetId === "description") {
          newPartNumber.description = data;
        } else {
          newPartNumber.metadata = {
            [datasetId as string]: { value: data },
          };
        }
        newPartNumbers.push(newPartNumber);
      } else {
        // set the data for the existing part number depending on the dataset
        if (datasetId === "pn") {
          newPartNumbers[partNumbersIndex].pn = data;
        } else if (datasetId === "description") {
          newPartNumbers[partNumbersIndex].description = data;
        } else {
          newPartNumbers[partNumbersIndex].metadata = {
            ...newPartNumbers[partNumbersIndex].metadata,
            [datasetId as string]: { value: data },
          };
        }
      }
    });
    return newPartNumbers;
  };

  // Upload the data to the part numbers object in the parten component
  const handleUploadData = () => {
    // Make sure there is a column that maps to the Part Number
    const pnColumn = csvData.find((column) => column.associatedDatasetId === "pn");
    if (!pnColumn) {
      setError({
        hide: false,
        isValid: false,
        message: "No column is mapped to the Part Number. Please assign a column to the Part Number.",
        type: "error",
      });
      return;
    }

    let newPartNumbers = [...partNumbersRef.current];

    // update the PN column first:
    newPartNumbers = insertColumnDataIntoPartNumbersObject(newPartNumbers, pnColumn.data, pnColumn.data, "pn");

    for (const column of csvData) {
      // if associatedDataset is "NEW", add a new column
      let datasetId = column.associatedDatasetId;
      if (datasetId === "NEW") {
        datasetId = addNewColumn(DataType.ParametricQualitative, column.header);
      }
      // if no dataset or if the dataset is "pn", skip this column because it's already been handled
      if (!datasetId || datasetId === "" || datasetId === "pn") continue;

      newPartNumbers = insertColumnDataIntoPartNumbersObject(newPartNumbers, pnColumn.data, column.data, datasetId);
    }

    // remove any part numbers that don't have a pn description or any metadata value
    newPartNumbers = newPartNumbers.filter((partNumber) => partNumber.pn !== "" || partNumber.description !== "");

    setPartNumbers(newPartNumbers);
    setSidebarOpen(false);
  };

  // Parse the CSV file when it changes
  useEffect(() => {
    if (selectedFile) {
      Papa.parse(selectedFile, {
        header: true,
        complete: function (results) {
          // Map the result to your expected CsvColumn format
          let headers = results.meta.fields;
          if (!headers) return;
          let csvData = headers.map((header) => {
            // try and find a column header that matches a dataset name
            let associatedDatasetId = [{ id: "pn", name: "Part Number" }, { id: "description", name: "Description" }, ...gridDatasets].find(
              (dataset) => dataset.name.toLowerCase() === header.toLowerCase(),
            )?.id;
            return {
              header,
              data: results.data.map((row: any) => row[header]),
              associatedDatasetId: associatedDatasetId || null,
            };
          });
          setCsvData(csvData);
        },
        error: function (err) {
          setError({ hide: false, isValid: false, message: `Error parsing CSV file: ${err}`, type: "error" });
        },
      });
    }
  }, [selectedFile]);

  // Update the part numbers when they change
  useEffect(() => {
    partNumbersRef.current = partNumbers;
  }, [partNumbers]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="bg-serial-palette-100 relative p-4 py-5">
        <button onClick={() => setSidebarOpen(false)} className={`btn text-serial-palette-500 absolute right-4 top-5 shadow-none`}>
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
        <h3 className="mb-2 text-xl font-semibold">Upload From CSV</h3>
        {!error.hide && (
          <div className="pb-3">
            <Banner2
              id="part-number-error"
              clickXHandler={() => {}}
              hideX={false}
              className="w-full"
              type={error.type}
              open={!error.hide}
              setOpen={() => setError({ ...error, hide: true })}
            >
              {error.message}
            </Banner2>
          </div>
        )}
        {selectedFile ? (
          <p className="px-0.5">Upload a CSV file with your part number data.</p>
        ) : (
          <p className="px-0.5">Match the columns in your CSV file to columns in the part number table: </p>
        )}
      </div>
      <div className="flex h-full w-full p-4 pb-5">
        {selectedFile ? (
          <div className="flex h-full w-full flex-col">
            <div className="mb-4 flex w-full border-b pb-3 ">
              <div className="w-1/2 font-medium">CSV Column Name</div>
              <span className="hidden whitespace-nowrap text-sm opacity-30 sm:inline">
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              <div className="ml-3 w-1/2 whitespace-nowrap font-medium">Serial Column Name</div>
            </div>
            <div className="flex h-full w-full flex-col gap-y-3 overflow-auto">
              {csvData.map((column, index) => {
                return (
                  <div key={index} className="flex w-full items-center justify-between text-sm">
                    <div className="w-1/2">{column.header}</div>
                    <span className="hidden whitespace-nowrap sm:inline">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <Select.Root
                      value={column.associatedDatasetId ?? ""}
                      onValueChange={(value) => handleSelectAssociatedDataset(index, value)}
                    >
                      <Select.Trigger className="ml-3 w-1/2">
                        <Select.Value placeholder="-" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.ScrollUpButton />
                        <Select.Item value="-">-</Select.Item>
                        {[
                          { id: "pn", name: "Part Number" },
                          { id: "description", name: "Description" },
                          ...gridDatasets.filter((dataset) => dataset.data_type === DataType.ParametricQualitative),
                        ].map((dataset, index) => (
                          <Select.Item key={index} value={dataset.id}>
                            {dataset.name}
                          </Select.Item>
                        ))}
                        <Select.Item value="NEW">(New Column)</Select.Item>
                        <Select.ScrollDownButton />
                      </Select.Content>
                    </Select.Root>
                  </div>
                );
              })}
            </div>
            <div className="flex w-full justify-between gap-3">
              <button
                onClick={() => setSelectedFile(null)}
                className="btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600 w-1/2"
              >
                Change File
              </button>
              <button onClick={() => handleUploadData()} className="btn bg-serial-palette-800 hover:bg-serial-palette-600 w-1/2 text-white">
                Upload Data
              </button>
            </div>
          </div>
        ) : (
          <FileUploadBox
            className="mx-auto h-full py-3"
            setSelectedFile={handleNewFile}
            allowPaste
            file={selectedFile}
            inactive={false}
            fileTypeInPrompt={null}
            iconOverride={null}
            resetFile={() => setSelectedFile(null)}
          />
        )}
      </div>
    </div>
  );
};

export default PartNumberSidebarCsvUpload;
