import React from "react";
import Modal from "@shared/components/primitives/Modal";
import "react18-json-view/src/style.css";
import ErrorBoundary from "@shared/components/error/ErrorBoundary";
import { SnLookupContext } from "../../SnLookupProvider";
import DataAnalysisModalDatasets from "./DataAnalysisModalDatasets";
import DataAnalysisModalProcessEntries from "./DataAnalysisModalProcessEntries";

enum DataAnalysisModalTab {
  Datasets = "DATASETS",
  ProcessEntries = "PROCESS_ENTRIES",
}

const tabLabels = {
  [DataAnalysisModalTab.Datasets]: "By Dataset",
  [DataAnalysisModalTab.ProcessEntries]: "By Process Entry",
};

const DataAnalysisModal: React.FC = () => {
  const { dataAnalysisModalOpen, setDataAnalysisModalOpen } = React.useContext(SnLookupContext);

  const [selectedTab, setSelectedTab] = React.useState(DataAnalysisModalTab.Datasets);

  return (
    <ErrorBoundary>
      <div className="flex w-full flex-col">
        <Modal open={dataAnalysisModalOpen} onOpenChange={setDataAnalysisModalOpen}>
          <Modal.Title hidden>Data Analysis</Modal.Title>
          <Modal.Content className="flex h-[95vh] max-w-[80vw] overflow-hidden ">
            <Modal.Header className="pl-0">
              <div className="flex h-full">
                {Object.values(DataAnalysisModalTab).map((tab) => (
                  <div
                    key={tab}
                    className={`hover:border-serial-palette-400 flex h-full cursor-pointer items-center border-b-[3.5px] px-4 font-semibold ${
                      selectedTab === tab ? "border-serial-palette-600" : "border-white"
                    }`}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {tabLabels[tab]}
                  </div>
                ))}
              </div>
              <Modal.HeaderClose />
            </Modal.Header>
            <div className="scrollbar-hide flex h-full flex-col overflow-auto md:flex-row">
              {selectedTab === DataAnalysisModalTab.Datasets && <DataAnalysisModalDatasets />}
              {selectedTab === DataAnalysisModalTab.ProcessEntries && <DataAnalysisModalProcessEntries />}
            </div>
          </Modal.Content>
        </Modal>
      </div>
    </ErrorBoundary>
  );
};

export default DataAnalysisModal;
