import { ComponentType } from "@shared/types/databaseEnums";
import { Component } from "@shared/types/databaseTypes";
import { v4 as uuidv4 } from "uuid";

export const generateBlankComponent = (companyId: string, userId: string): Component => {
  return {
    id: uuidv4(),
    name: "",
    is_active: true,
    company_id: companyId,
    created_at: new Date().toISOString(),
    component_type: ComponentType.Sn,
    last_edited_at: new Date().toISOString(),
    last_edited_user_id: userId,
    pn_metadata_dataset_ids: [],
  };
};
