import { useContext, useEffect, useState } from "react";
import { ToastType } from "@shared/components/Toast";
import { DashboardInsight, ReportTemplateCustomInsight, ReportTemplateDashboard } from "@shared/types/databaseTypes";
import { preBuiltDashboards } from "../constants";
import { DashboardInsightType, ReportTemplateType } from "@shared/types/databaseEnums";
import { deleteDashboard, updateDashboard, insertDashboard } from "../connections/supabaseDashboards";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ToastContext } from "@shared/context/ToastProvider";

export const useDashboards = () => {
  const reportTemplates = useSelector((state: RootState) => state.db.reportTemplates);
  const { triggerToast } = useContext(ToastContext);

  // ----------- State ----------- //
  const [dashboards, setDashboards] = useState<ReportTemplateDashboard[]>([...preBuiltDashboards]);
  const [selectedDashboardId, setSelectedDashboardId] = useState<string | null>(null);
  const [customInsights] = useState<ReportTemplateCustomInsight[]>([]);
  const [targetDashboardRow, setTargetDashboardRow] = useState<number | null>(null);
  const [insightModalOpen, setInsightModalOpen] = useState<boolean>(false);
  const [dashboardEditModalOpen, setDashboardEditModalOpen] = useState<boolean>(false);
  const [isNewDashboard, setIsNewDashboard] = useState<boolean>(false);

  const currUserId = useSelector((state: RootState) => state.auth.supabase_uid);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currUserId) return;
    const filteredDashboards = reportTemplates
      .filter((d) => d.type === ReportTemplateType.Dashboard)
      .filter((d) => d.created_by === currUserId || d.is_public || d.shared_with[currUserId]) as ReportTemplateDashboard[];
    setDashboards([...preBuiltDashboards, ...filteredDashboards]);
  }, [currUserId, reportTemplates]);

  // ----------- Handlers ----------- //

  const handleCreateDashboard = async () => {
    const newDashboard: Partial<ReportTemplateDashboard> = {
      id: uuidv4(),
      name: "",
      description: "",
      is_public: false,
      shared_with: {},
      config: {
        is_prebuilt: false,
        rows: [],
      },
    };

    const { data, error } = await insertDashboard(newDashboard);
    if (error || !data) {
      triggerToast(ToastType.Error, "Error creating dashboard", error ?? "Could not create dashboard");
      return;
    }

    setDashboards([...dashboards, data]);
    setSelectedDashboardId(data.id);
    navigate("/dashboards/" + data.id);
    setDashboardEditModalOpen(true);
    setIsNewDashboard(true);
  };

  const handleDuplicateDashboard = async (dashboardId: string) => {
    const dashboardToDuplicate = dashboards.find((d) => d.id === dashboardId);
    if (!dashboardToDuplicate) {
      triggerToast(ToastType.Error, "Dashboard Error", "Error duplicating dashboard");
      return;
    }

    const newDashboard: Partial<ReportTemplateDashboard> = {
      id: uuidv4(),
      name: dashboardToDuplicate.name + " (Copy)",
      description: dashboardToDuplicate.description,
      is_public: dashboardToDuplicate.is_public,
      shared_with: dashboardToDuplicate.shared_with,
      config: dashboardToDuplicate.config,
    };

    const { data, error } = await insertDashboard(newDashboard);
    if (error || !data) {
      triggerToast(ToastType.Error, "Error duplicating dashboard", error ?? "Could not duplicate dashboard");
      return;
    }

    setDashboards([...dashboards, data]);
    setSelectedDashboardId(data.id);
    navigate("/dashboards/" + data.id);
  };

  const handleUpdateDashboard = async (updatedDashboard: ReportTemplateDashboard) => {
    const originalDashboards = [...dashboards];

    // optimistic update:
    let updatedDashboards = dashboards.map((dashboard) => {
      if (dashboard.id === selectedDashboardId) {
        return { ...updatedDashboard };
      }
      return dashboard;
    });
    setDashboards([...updatedDashboards]);

    // update from the database for sanity and revert if there is an error:
    const { data, error } = await updateDashboard(updatedDashboard);
    if (error || !data) {
      triggerToast(ToastType.Error, "Error updating dashboard", error ?? "Could not update dashboard");
      setDashboards([...originalDashboards]); // revert to original state
      return;
    }
    updatedDashboards = originalDashboards.map((dashboard) => {
      if (dashboard.id === selectedDashboardId) {
        return { ...data };
      }
      return dashboard;
    });
    setDashboards(updatedDashboards);

    setIsNewDashboard(false);
  };

  const handleAddInsightToSelectedDashboard = async (newInsightType: DashboardInsightType, config: any) => {
    const currDashbaord = dashboards.find((d) => d.id === selectedDashboardId);
    if (!currDashbaord) {
      triggerToast(ToastType.Error, "Dashboard Error", "Error adding insight to dashboard");
      return;
    }
    const updatedDashboard = JSON.parse(JSON.stringify(currDashbaord)); // make deep copy of dashboard to avoid mutating redux state;

    if (!updatedDashboard.config || targetDashboardRow === null || !newInsightType) {
      triggerToast(ToastType.Error, "Dashboard Error", "Error adding insight to dashboard");
      return;
    }

    if (updatedDashboard.config.rows.length <= targetDashboardRow) {
      updatedDashboard.config.rows.push({
        height: config.defaultHeight ?? 500,
        insights: [
          {
            type: newInsightType,
            config: config ?? {},
          },
        ] as DashboardInsight[],
      });
    } else {
      updatedDashboard.config.rows[targetDashboardRow as number].insights.push({
        type: newInsightType,
        config: config ?? {},
      } as DashboardInsight);
    }

    handleUpdateDashboard(updatedDashboard);
    setInsightModalOpen(false);
  };

  const handleRemoveInsightFromSelectedDashboard = async (rowIndex: number, insightIndex: number) => {
    const currDashbaord = dashboards.find((d) => d.id === selectedDashboardId);
    if (!currDashbaord) {
      triggerToast(ToastType.Error, "Dashboard Error", "Error removing insight from dashboard");
      return;
    }

    const updatedDashboard = JSON.parse(JSON.stringify(currDashbaord)); // make deep copy of dashboard to avoid mutating redux state;

    if (updatedDashboard.config?.rows.length <= rowIndex || updatedDashboard.config?.rows[rowIndex].insights.length <= insightIndex) {
      triggerToast(ToastType.Error, "Dashboard Error", "Error removing insight from dashboard");
      return;
    }

    updatedDashboard.config.rows[rowIndex].insights.splice(insightIndex, 1);

    // if the updated row has no insights, remove the row
    if (updatedDashboard.config.rows[rowIndex].insights.length === 0) {
      updatedDashboard.config.rows.splice(rowIndex, 1);
    }

    handleUpdateDashboard(updatedDashboard);
  };

  const handleDeleteDashboard = async (dashboardId: string) => {
    navigate("/dashboards");
    const { error } = await deleteDashboard(dashboardId);
    if (error) {
      triggerToast(ToastType.Error, "Error deleting dashboard", error ?? "Could not delete dashboard");
      return;
    }
    setDashboards(dashboards.filter((d) => d.id !== dashboardId));
  };

  const handleCreateCustomInsight = async () => {};

  const handleUpdateCustomInsight = async () => {};

  const handleDeleteCustomInsight = async () => {};

  return {
    dashboards,
    selectedDashboardId,
    setSelectedDashboardId,
    handleCreateDashboard,
    handleDuplicateDashboard,
    handleUpdateDashboard,
    handleDeleteDashboard,
    handleAddInsightToSelectedDashboard,
    handleRemoveInsightFromSelectedDashboard,
    customInsights,
    targetDashboardRow,
    setTargetDashboardRow,
    insightModalOpen,
    setInsightModalOpen,
    dashboardEditModalOpen,
    setDashboardEditModalOpen,
    handleCreateCustomInsight,
    handleUpdateCustomInsight,
    handleDeleteCustomInsight,
    isNewDashboard,
  };
};

export default useDashboards;
