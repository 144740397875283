import React, { createRef, useContext, useRef, useState, useEffect } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { TableBlock } from "@shared/types/databaseTypes";
import { ProcessBuilderWorkInstructionBlockContentProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ContentEditable from "@shared/components/ContentEditable";

const ProcessBuilderWorkInstructionBlockTable: React.FC<ProcessBuilderWorkInstructionBlockContentProps<TableBlock>> = ({
  block,
  stepIndex,
}) => {
  const { readOnly, handleUpdateWorkInstructionBlocks } = useContext(ProcessBuilderContext);
  const tableRefs = useRef<HTMLTableElement | null>(null);
  const tableParentRef = useRef<HTMLDivElement | null>(null);
  const [mouseOver, setMouseOver] = useState(false);
  const [dragging, setDragging] = useState<{ colIndex: number; startX: number; startWidth: number } | null>(null);
  const contentRefs = useRef<React.RefObject<HTMLDivElement>[][]>(block.content.rows.map((row) => row.map(() => createRef())));

  contentRefs.current = block.content.rows.map((row, rowIndex) =>
    row.map((_cell, colIndex) => contentRefs.current?.[rowIndex]?.[colIndex] ?? createRef()),
  );

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!dragging) return;
      const { colIndex, startX, startWidth } = dragging;
      const newWidth = Math.max(50, startWidth + (event.clientX - startX));
      const newWidths = [...(block.content.column_widths || [])];
      newWidths[colIndex] = `${((newWidth / (tableParentRef.current?.offsetWidth || 1)) * 100).toFixed(2)}%`;
      handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "column_widths", newWidths, true);
    };

    const handleMouseUp = () => {
      setDragging(null);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, block.content.column_widths, handleUpdateWorkInstructionBlocks, stepIndex, block.id]);

  const handleUpdateCell = (rowIndex: number, colIndex: number, content: string) => {
    if (content === block.content.rows[rowIndex][colIndex]) return;
    const newRows = [...block.content.rows];
    newRows[rowIndex][colIndex] = content;
    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "rows", newRows, true);
  };

  const handleAddRemoveRows = (qty: number) => {
    const newRows = [...block.content.rows];
    if (qty > 0) {
      for (let i = 0; i < qty; i++) {
        newRows.push(newRows[0].map(() => ""));
      }
    } else {
      newRows.splice(newRows.length + qty, Math.abs(qty));
    }
    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "rows", newRows, true);
  };

  const handleAddRemoveColumns = (qty: number) => {
    const newRows = [...block.content.rows];
    const newWidths = [...(block.content.column_widths || [])];
    if (qty > 0) {
      for (let i = 0; i < newRows.length; i++) {
        newRows[i].push("");
      }
      for (let j = 0; j < qty; j++) {
        newWidths.push("auto");
      }
    } else {
      for (let i = 0; i < newRows.length; i++) {
        newRows[i].splice(newRows[i].length + qty, Math.abs(qty));
      }
      newWidths.splice(newWidths.length + qty, Math.abs(qty));
    }
    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "column_widths", newWidths, true);
    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "rows", newRows, true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, rowIndex: number, colIndex: number) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (rowIndex === block.content.rows.length - 1) return;
      const nextRow = contentRefs?.current?.[rowIndex + 1];
      if (!nextRow) return;
      const nextCell = nextRow?.[colIndex]?.current;
      if (!nextCell) return;
      nextCell?.focus();
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (rowIndex === 0) return;
      const prevRow = contentRefs?.current?.[rowIndex - 1];
      if (!prevRow) return;
      const prevCell = prevRow?.[colIndex]?.current;
      if (!prevCell) return;
      prevCell?.focus();
    }
    if (event.key === "ArrowRight") {
      const div = event.currentTarget as HTMLDivElement;
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const position = range?.endOffset;
      if (div.innerText.length !== position) return;
      if (colIndex === block.content.rows[rowIndex].length - 1) return;
      event.preventDefault();
      const nextCell = contentRefs?.current[rowIndex]?.[colIndex + 1]?.current;
      if (!nextCell) return;
      nextCell?.focus();
    }
    if (event.key === "ArrowLeft") {
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const position = range?.startOffset;
      if (position !== 0) return;
      if (colIndex === 0) return;
      event.preventDefault();
      const prevCell = contentRefs?.current?.[rowIndex]?.[colIndex - 1]?.current;
      if (!prevCell) return;
      prevCell?.focus();
    }
  };

  const handleBlockClick = () => {
    if (contentRefs?.current?.some((row) => row.some((cell) => cell.current === document.activeElement))) return;
    contentRefs?.current?.[0]?.[0]?.current?.focus();
  };

  const startDragging = (event: React.MouseEvent, colIndex: number) => {
    const startX = event.clientX;
    const startWidth = contentRefs.current[0][colIndex].current?.offsetWidth || 0;
    setDragging({ colIndex, startX, startWidth });
  };

  const calculateColumnWidth = (colIndex: number, offsetWidth: number): string => {
    if (block.content.column_widths?.[colIndex] === "auto") {
      return "auto";
    } else if (block.content.column_widths?.[colIndex]) {
      return `${(parseFloat(block.content.column_widths?.[colIndex] || "10")  / 100) * (offsetWidth || 1)}px`;
    }
    return "auto";
  };

  return (
    <div
      className="group relative w-full"
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      id={block.id}
      onClick={() => handleBlockClick()}
    >
      <div
        className="border-serial-palette-400 container mx-auto flex w-full overflow-clip rounded-md border"
        ref={tableParentRef}
        onClick={(e) => e.stopPropagation()}
      >
        <table ref={tableRefs} className="w-full table-fixed border-collapse">
          <colgroup>
            {block.content.rows[0].map((_, colIndex) => (
              <col
                key={colIndex}
                style={{ width: calculateColumnWidth(colIndex, tableParentRef.current?.offsetWidth || 1) }}
              />
            ))}
          </colgroup>
          <tbody>
            {block.content.rows.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`border-serial-palette-400 border-b last:border-b-0 ${rowIndex === 0 && block.content.header_row && "bg-serial-palette-100 font-bold"}`}
              >
                {row.map((_cell, colIndex) => (
                  <td
                    key={colIndex}
                    className={`border-serial-palette-400 relative border-r px-2.5 py-1.5 last:border-r-0 ${colIndex === 0 && block.content.header_column && "bg-serial-palette-100 font-bold"}`}
                  >
                    <ContentEditable
                      contentRef={contentRefs?.current?.[rowIndex]?.[colIndex]}
                      onInput={(e) => handleUpdateCell(rowIndex, colIndex, e.currentTarget.innerText)}
                      onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                      className="min-h-[24px] w-full outline-0"
                      placeholder={mouseOver ? "text" : undefined}
                      content={block.content.rows[rowIndex][colIndex]}
                      readOnly={readOnly}
                      style={{ minWidth: `${((50 / (tableParentRef.current?.offsetWidth || 1)) * 100).toFixed(2)}%` }}
                    />
                    <div
                      className="absolute bottom-0 right-0 top-0 w-2 cursor-col-resize z-50"
                      style={{ width: "5px", transform: "translateX(50%)"}} // Center the divider over the dividing line
                      onMouseDown={(e) => startDragging(e, colIndex)}
                      onMouseUp={() => setDragging(null)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!readOnly && (
          <div className="absolute -bottom-3 hidden w-full justify-center group-hover:flex">
            <div className="flex">
              <button
                tabIndex={-1}
                className="btn-xs serial-btn-light h-5 rounded-r-none border-r-0"
                onClick={() => handleAddRemoveRows(1)}
              >
                <FontAwesomeIcon icon={faPlus} size="xs" />
              </button>
              <button tabIndex={-1} className="btn-xs serial-btn-light h-5 rounded-l-none" onClick={() => handleAddRemoveRows(-1)}>
                <FontAwesomeIcon icon={faMinus} size="xs" />
              </button>
            </div>
          </div>
        )}
        {!readOnly && (
          <div className="absolute -right-3 hidden h-full items-center group-hover:flex">
            <div className="flex flex-col">
              <button
                tabIndex={-1}
                className="btn-xs serial-btn-light w-5 rounded-b-none border-b-0 py-1.5"
                onClick={() => handleAddRemoveColumns(1)}
              >
                <FontAwesomeIcon icon={faPlus} size="xs" />
              </button>
              <button
                tabIndex={-1}
                className="btn-xs serial-btn-light w-5 rounded-t-none py-1.5"
                onClick={() => handleAddRemoveColumns(-1)}
              >
                <FontAwesomeIcon icon={faMinus} size="xs" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlockTable;
