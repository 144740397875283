import React, { useContext } from "react";
import { ImageMarkupLabelElementProps } from "./ImageMarkupElementContainer";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import { ImageMarkupElementRectangle as RectangleElement } from "@shared/types/databaseTypes";

const ImageMarkupElementRectangle: React.FC<ImageMarkupLabelElementProps<RectangleElement>> = ({ element }) => {
  const { imageWidth, imageHeight } = useContext(ImageMarkupContext);

  return (
    <div
      className="shrink-0 rounded-lg"
      style={{
        width: Math.abs(element.width) * imageWidth,
        height: Math.abs(element.height) * imageHeight,
        border: `${element.thickness}px solid ${element.color}`,
        backgroundColor: element.fill ? element.fill : "transparent",
      }}
    />
  );
};

export default ImageMarkupElementRectangle;
