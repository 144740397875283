import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";

// ------------------- WRITE FUNCTIONS ------------------- //

export const updateCompanyConfig = async (newConfig: any) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  if (!state.db.company) return false;
  const { error } = await supabase.from("companies").update({ config: newConfig }).eq("id", state.db.company.id).select().single();

  // Handle database error else, write to redux
  if (error) {
    console.log(error);
    return false;
  }
  return true;
};
