import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Banner2 from "@shared/components/Banner2";
import { updateCompanyConfig } from "@shared/connections/supabaseCompany";
import { RootState } from "@shared/redux/store";

const SettingsOther: React.FC = () => {
  const [allowAi, setAllowAi] = useState(false);
  const [workInstructionsCopilot, setWorkInstructionsCopilot] = useState(false);
  const [enableLocationTracking, setEnableLocationTracking] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const company = useSelector((state: RootState) => state.db.company);

  const handleSave = async () => {
    const newConfig = {
      ...company.config,
      allow_ai: allowAi,
      work_instructions_copilot: workInstructionsCopilot,
      enable_location_tracking: enableLocationTracking,
    };
    const updateSuccessful = await updateCompanyConfig(newConfig);
    setShowBanner(updateSuccessful);
  };

  const handleToggleAi = (aiIsEnabled: boolean) => {
    setAllowAi(aiIsEnabled);
    if (!aiIsEnabled) {
      setWorkInstructionsCopilot(false);
    }
  };

  // Update the state when the company changes
  useEffect(() => {
    if (company) {
      setAllowAi(company?.config?.allow_ai === true);
      setWorkInstructionsCopilot(company?.config?.work_instructions_copilot === true);
      setEnableLocationTracking(company?.config?.enable_location_tracking === true);
    }
  }, [company]);

  // A timer to dismiss the banner
  useEffect(() => {
    if (showBanner) {
      setTimeout(() => {
        setShowBanner(false);
      }, 1500);
    }
  }, [showBanner]);

  return (
    <div className="flex grow flex-col justify-between">
      {/* Panel body */}
      <div className="flex flex-col space-y-6 p-6">
        {/* <h2 className="text-2xl font-bold mb-5">Production App</h2> */}
        <Banner2
          className="w-full text-left"
          children="Settings updated successfully"
          type="success"
          open={showBanner}
          setOpen={setShowBanner}
        />

        {/* General */}
        <section>
          <h3 className="mb-1 text-xl font-bold leading-snug">AI Assistant</h3>
          <ul>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="font-semibold">Enable AI</div>
                <div className="text-sm">
                  We use AI to help enhance various parts of the Serial user experience. By checking this box you agree to have selective
                  Serial content shared with the Open AI API.
                </div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center">
                <div className="mr-2 text-sm italic">{allowAi ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="switch-allow_ai"
                    className="sr-only"
                    checked={allowAi}
                    onChange={() => handleToggleAi(!allowAi)}
                  />
                  <label className="bg-serial-palette-200" htmlFor={"switch-allow_ai"}>
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="flex items-center gap-2 font-semibold">
                  Work Instructions Assistant<div className={`rounded bg-amber-300 px-2 py-0.5 text-[10px] font-bold text-black`}>BETA</div>
                </div>
                <div className="text-sm">Use AI to help you draft work instructions.</div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center">
                <div className="mr-2 text-sm italic">{workInstructionsCopilot ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="switch-work-instructions-copilot"
                    className="sr-only"
                    checked={workInstructionsCopilot}
                    onChange={() => setWorkInstructionsCopilot(!workInstructionsCopilot)}
                  />
                  <label className="bg-serial-palette-200" htmlFor={"switch-work-instructions-copilot"}>
                    <span className="bg-white" aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="flex items-center gap-2 font-semibold">
                  Location Tracking<div className={`rounded bg-amber-300 px-2 py-0.5 text-[10px] font-bold text-black`}>BETA</div>
                </div>
                <div className="text-sm">Allows users to assign a location to units and visualize in the map view</div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center">
                <div className="mr-2 text-sm italic">{enableLocationTracking ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="switch-enable-location-tracking"
                    className="sr-only"
                    checked={enableLocationTracking}
                    onChange={() => setEnableLocationTracking(!enableLocationTracking)}
                  />
                  <label className="bg-serial-palette-200" htmlFor={"switch-enable-location-tracking"}>
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="border- neutral-200 flex flex-col border-t px-6 py-5">
          <div className="flex self-end">
            <button onClick={() => handleSave()} className="btn bg-serial-palette-800 hover:bg-serial-palette-600 ml-3 text-white">
              Save Changes
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SettingsOther;
