import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ProductionFormLoadHistoryButton from "./ProductionFormLoadHistoryButton";
import { MAX_BATCH_SIZE, ProductionContext } from "../../ProductionProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ComponentType } from "@shared/types/databaseEnums";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { IdentifierValidationResultType, InstantiationModalMode } from "../../types";
import Banner2 from "@shared/components/Banner2";
import TagKeyValue from "@shared/components/primitives/TagKeyValue";
import { useDebounceCallback } from "usehooks-ts";
import { VALIDATION_DEBOUNCE } from "../../constants";
import { useTranslation } from "react-i18next";

interface ProductionFormIdentifierProps {
  identifierInputRef: React.RefObject<HTMLInputElement>;
}

const validationResultFormatting: {
  [resultType in IdentifierValidationResultType]: {
    title: string;
    isWarning?: boolean;
  };
} = {
  [IdentifierValidationResultType.NeedInitialization]: {
    title: "Need Initialization",
  },
  [IdentifierValidationResultType.DifferentComponent]: {
    title: "Identifier is a Different Component",
  },
  [IdentifierValidationResultType.InvalidStation]: {
    title: "Invalid Station",
  },
  [IdentifierValidationResultType.MissingPriorProcess]: {
    title: "Missing Prior Process",
  },
  [IdentifierValidationResultType.DefectiveOnRetestProcess]: {
    title: "Failed Previous Processes. Retests Okay",
    isWarning: true,
  },
  [IdentifierValidationResultType.DefectiveOnNewProcess]: {
    title: "Failed Previous Processes",
  },
  [IdentifierValidationResultType.UploadErrorWarning]: {
    title: "Prior Submission had Errors",
    isWarning: true,
  },
  [IdentifierValidationResultType.ResubmissionWarning]: {
    title: "Resubmitting this Process",
    isWarning: true,
  },
  [IdentifierValidationResultType.Ok]: {
    title: "Valid",
  },
};

const getValidationFormatting = (resultTypeString: string) => {
  return validationResultFormatting[resultTypeString as IdentifierValidationResultType];
};

const ProductionFormIdentifier: React.FC<ProductionFormIdentifierProps> = ({ identifierInputRef }) => {
  const {
    db,
    identifiers,
    componentInstances,
    process,
    component,
    handleUpdateIdentifiers,
    setInstantiationModalOpen,
    setInstantiationModalMode,
    setInstantiationComponentId,
    setInstantiationIdentifiers,
    identifiersValidation,
  } = useContext(ProductionContext);

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>("");
  const { triggerToast } = useContext(ToastContext);

  useEffect(() => {
    if (process && process?.allow_batch === false) {
      setInputValue(identifiers[0] || "");
    }
  }, [process, identifiers]);

  const commitNewIdentifier = useCallback(
    (identifier: string) => {
      if (identifiers.length >= MAX_BATCH_SIZE) {
        triggerToast(ToastType.Error, "Batch Size Limit Exceeded", `Batch size limit is ${MAX_BATCH_SIZE}`);
        return;
      }
      if (identifier !== "" && !identifiers.includes(identifier)) {
        let newIdentifiers = [...identifiers];

        if (process?.allow_batch) {
          newIdentifiers.push(identifier);
          setInputValue("");
        } else {
          newIdentifiers = [identifier];
        }
        handleUpdateIdentifiers(newIdentifiers);
      } else if (identifier === "" && !process?.allow_batch) {
        handleUpdateIdentifiers([]);
      } else if (identifiers.includes(identifier) && process?.allow_batch) {
        setInputValue("");
        triggerToast(ToastType.Warning, "Duplicate Identifier", "This identifier is already in the batch");
      }
    },
    [identifiers, process, handleUpdateIdentifiers, triggerToast],
  );

  const debouncedCommitInputValue = useDebounceCallback(commitNewIdentifier, VALIDATION_DEBOUNCE);

  const handleIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!process?.allow_batch) debouncedCommitInputValue(e.target.value);
  };

  const handleRemoveFromBatch = (identifier: string) => {
    const newIdentifiers = identifiers.filter((id) => id !== identifier);
    handleUpdateIdentifiers(newIdentifiers);
  };

  const handleOpenInstantiationModal = () => {
    const identifiersToInitialize =
      identifiersValidation?.[IdentifierValidationResultType.NeedInitialization]
        ?.map((validation) => validation.identifier)
        .filter((identifier): identifier is string => !!identifier) || [];
    let instantiationComponentId = component?.id;
    if (!instantiationComponentId) {
      const linkedComponent = db.componentProcessLinks.find(
        (componentProcessLink) => componentProcessLink.process_id === process?.id,
      )?.component;
      if (linkedComponent) instantiationComponentId = linkedComponent.id;
      else {
        triggerToast(ToastType.Error, "Error Initializing Component", "No component found for this process");
        return;
      }
    }
    setInstantiationComponentId(instantiationComponentId);
    setInstantiationIdentifiers(identifiersToInitialize);
    setInstantiationModalMode(InstantiationModalMode.Create);
    setInstantiationModalOpen(true);
  };

  // pressing enter will trigger add to batch if batch is allowed
  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && identifierInputRef.current === document.activeElement) {
        commitNewIdentifier(inputValue);
        setInputValue("");
        identifierInputRef.current?.focus();
      }
    };
    if (process?.allow_batch) {
      document.addEventListener("keydown", handleEnter);
    }
    return () => document.removeEventListener("keydown", handleEnter);
  }, [process?.allow_batch, identifiers, commitNewIdentifier]);

  const inputValidationClass = useMemo(() => {
    if (process?.allow_batch) {
      return "";
    } else if (identifiersValidation) {
      const firstKey = Object.keys(identifiersValidation || {})[0] as IdentifierValidationResultType | undefined;
      if (!firstKey) {
        // Return a default or neutral class when there are no validation results
        return "serial-input-default";
      }
      const isValid = identifiersValidation[firstKey]?.[0]?.isValid;
      const warning = getValidationFormatting(firstKey)?.isWarning;
      if (warning) {
        return "serial-input-warning";
      } else {
        return isValid ? "serial-input-valid" : "serial-input-invalid";
      }
    }
  }, [identifiersValidation, process?.allow_batch]);

  // update input and commit if identifier query param is present on the first render
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const identifier = urlParams.get("identifier");
    if (identifier) {
      handleUpdateIdentifiers([identifier]);
    }
  }, []);

  return (
    <div className="flex w-full max-w-xl flex-col gap-y-2.5">
      <div className="text-md font-bold">{component?.component_type === ComponentType.Sn ? "Scan Serial Number" : "Scan Lot Code"}</div>
      {!process?.allow_batch &&
        identifiersValidation &&
        identifiers.length > 0 &&
        !(IdentifierValidationResultType.Ok in identifiersValidation) && (
          <Banner2
            className="w-full"
            type={getValidationFormatting(Object.keys(identifiersValidation)[0])?.isWarning ? "warning" : "error"}
            hideX
            open
          >
            {identifiersValidation[Object.keys(identifiersValidation)[0] as IdentifierValidationResultType]?.[0].message}
          </Banner2>
        )}
      <div className="flex w-full gap-x-2">
        <input
          value={inputValue}
          onChange={handleIdentifierChange}
          onBlur={() => commitNewIdentifier(inputValue)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              commitNewIdentifier(inputValue);
              e.preventDefault();
            }
          }}
          className={`form-input w-full ${inputValidationClass}`}
          placeholder={process?.allow_batch ? "Batch Identifier" : "Single Identifier"}
        />
        {identifiersValidation && (identifiersValidation?.[IdentifierValidationResultType.NeedInitialization] ?? [])?.length > 0 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleOpenInstantiationModal();
            }}
            className="btn border-amber-600 bg-amber-600 font-bold text-white hover:bg-amber-500"
          >
            Initialize
          </button>
        )}
        {!process?.allow_batch && componentInstances?.[0] && <ProductionFormLoadHistoryButton />}
        {process?.allow_batch && (
          <button
            tabIndex={-1}
            onClick={() => commitNewIdentifier(inputValue)}
            className="btn border-green-600 bg-green-600 font-bold text-white hover:bg-green-500"
          >
            <span className="whitespace-nowrap">Add To Batch</span>
          </button>
        )}
      </div>
      <div className="flex w-full gap-2">
        {!process?.allow_batch && componentInstances?.[0]?.part_number && (
          <TagKeyValue.Root tooltip={componentInstances?.[0].part_number.pn}>
            <TagKeyValue.Key>Part Number</TagKeyValue.Key>
            <TagKeyValue.Value>{componentInstances?.[0].part_number.pn}</TagKeyValue.Value>
          </TagKeyValue.Root>
        )}
        {!process?.allow_batch && componentInstances?.[0]?.work_order && (
          <TagKeyValue.Root tooltip={componentInstances?.[0].work_order.name}>
            <TagKeyValue.Key>{t("workOrder")}</TagKeyValue.Key>
            <TagKeyValue.Value>{componentInstances?.[0].work_order.name}</TagKeyValue.Value>
          </TagKeyValue.Root>
        )}
      </div>

      {identifiers.length > 0 && process?.allow_batch && (
        <div className="flex flex-col gap-y-2 rounded-md border py-2">
          {identifiersValidation && (
            <div className="flex flex-col gap-2 overflow-auto px-3">
              {/* Render "Valid Identifiers" first if it exists */}
              {identifiersValidation[IdentifierValidationResultType.Ok] && (
                <div className="flex flex-col">
                  <div className="mb-1 flex gap-1.5 text-sm">
                    <span className="font-semibold">Valid Identifiers</span>
                    <span className="font-light">{identifiersValidation[IdentifierValidationResultType.Ok].length}</span>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {identifiersValidation[IdentifierValidationResultType.Ok].map((validationResult, index) => (
                      <div key={index} className={`bg-serial-palette-100 flex justify-between gap-2 rounded px-2 py-0.5`}>
                        <div>{validationResult.identifier}</div>
                        <button tabIndex={-1} onClick={() => handleRemoveFromBatch(validationResult.identifier!)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Now render the rest of the errors, excluding "Valid Identifiers" */}
              {Object.entries(identifiersValidation).map(([resultType, validationResults], groupIndex) => {
                if (resultType === IdentifierValidationResultType.Ok) return null; // Skip valid identifiers since it's already rendered
                return (
                  <div key={groupIndex} className="flex flex-col">
                    <div className="mb-1 flex gap-1.5 text-sm">
                      <span className="font-semibold">{getValidationFormatting(resultType)?.title}</span>
                      <span className="font-light">{validationResults.length}</span>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {validationResults.map((validationResult, index) => {
                        const warning = getValidationFormatting(resultType)?.isWarning;
                        const tagColor = warning ? "border-yellow-500 bg-yellow-100" : "border-red-500 bg-red-100";
                        return (
                          <div key={index} className={`flex justify-between gap-2 rounded border px-2 py-0.5 ${tagColor}`}>
                            <div>{validationResult.identifier}</div>
                            <button tabIndex={-1} onClick={() => handleRemoveFromBatch(validationResult.identifier!)}>
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductionFormIdentifier;
