import React, { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { DatasetsContext } from "../Datasets";
import TypeTag from "@shared/components/TypeTag";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataType } from "@shared/types/databaseEnums";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface DatasetAnalyticsPerformanceSnListProps {
  focusedIndex: number | null;
  setFocusedIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const DatasetAnalyticsPerformanceSnList: React.FC<DatasetAnalyticsPerformanceSnListProps> = ({ setFocusedIndex, focusedIndex }) => {
  const context = useContext(DatasetsContext);
  const observe = useContext(ObservabilityContext);

  return (
    <div className="flex w-full flex-col overflow-hidden rounded-md border">
      <div className="flex h-12 w-full shrink-0 items-center gap-1 border-b px-3 font-semibold">
        <span>Data Summary</span>
        {context.data.length > 0 && <span className="text-serial-palette-400 font-medium">{context.data.length}</span>}
      </div>
      <div className="flex w-full overflow-y-auto">
        <table className="table w-full">
          <thead className="text-serial-palette-500 bg-serial-palette-50 sticky top-0 z-10 rounded-md border-b text-xs font-semibold uppercase">
            <tr>
              <th className="whitespace-nowrap px-2 py-3 pl-3">
                <div className="text-left font-semibold">Identifier</div>
              </th>
              {context.data[0] && "value" in context.data[0] && (
                <th className="whitespace-nowrap px-2 py-3 pr-3">
                  <div className="text-center font-semibold">Value</div>
                </th>
              )}
              {context.data[0] && "file_name" in context.data[0] && (
                <th className="whitespace-nowrap px-2 py-3 pr-3">
                  <div className="text-left font-semibold">File Name</div>
                </th>
              )}
              {context.data[0] && "is_checked" in context.data[0] && (
                <th className="whitespace-nowrap px-2 py-3 pr-3">
                  <div className="text-center font-semibold">Checked</div>
                </th>
              )}
              {context.data[0] && "file_name" in context.data[0] && (
                <th className="whitespace-nowrap px-2 py-3 pr-3">
                  <div className="text-center font-semibold">Download</div>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-serial-palette-200 border-serial-palette-200 divide-y border-b text-sm">
            {context.data.map((datapoint, index) => {
              return (
                <tr
                  key={index}
                  onMouseEnter={() => {
                    if (context.selectedDataset?.data_type !== DataType.File) setFocusedIndex(index);
                  }}
                  onClick={() => {
                    if (context.selectedDataset?.data_type === DataType.File) setFocusedIndex(index);
                  }}
                  onMouseLeave={() => {
                    if (context.selectedDataset?.data_type !== DataType.File && context.selectedDataset?.data_type !== DataType.Image)
                      setFocusedIndex(null);
                  }}
                  className={`${focusedIndex === index && "bg-serial-palette-50"} cursor-pointer`}
                >
                  <td className="cursor-pointer whitespace-nowrap px-2 py-2 pl-3">
                    <div className="flex max-w-[200px]">
                      <Link
                        to={`/snlookup?uid=${datapoint?.unique_identifier_id}`}
                        onClick={() => observe.track("Navigate to SnLookup", { "UI Source": "Dataset - Identifier List" })}
                        className="truncate text-left font-medium hover:underline"
                      >
                        {datapoint?.unique_identifier?.identifier}
                      </Link>
                      <CopyToClipboard
                        text={datapoint?.unique_identifier?.identifier ?? ""}
                        className={`${focusedIndex === index ? "" : "text-white"}`}
                      />
                    </div>
                    <div className="flex gap-1">
                      <div className="text-xs">{moment(datapoint?.process_entry?.timestamp).format("MM/DD/YY HH:mma")}</div>
                      {datapoint.retestNumber > 0 && (
                        <TypeTag type="RETEST" hideText quantity={datapoint.retestNumber} className="h-[10px] w-8 text-[8px]" />
                      )}
                    </div>
                  </td>
                  {"value" in datapoint && context.selectedDataset?.data_type === DataType.ParametricQuantitative && (
                    <td className="px-2 py-3 pr-3">
                      <div className="flex w-full items-center justify-center gap-1">
                        <div>{Number(datapoint?.value).toFixed(4)}</div>
                        {"is_pass" in datapoint && (
                          <TypeTag
                            className="h-[10px] w-8 text-[8px]"
                            hideText
                            type={datapoint.is_pass === true ? "PASS" : datapoint.is_pass === false ? "FAIL" : "NA"}
                          />
                        )}
                      </div>
                    </td>
                  )}
                  {"value" in datapoint && context.selectedDataset?.data_type === DataType.ParametricQualitative && (
                    <td className="px-2 py-3 pr-3">
                      <div className="text-center">{datapoint?.value}</div>
                    </td>
                  )}
                  {"value" in datapoint && context.selectedDataset?.data_type === DataType.PassFail && (
                    <td className="px-2 py-3 pr-3">
                      <div className="text-center">{datapoint?.value}</div>
                    </td>
                  )}
                  {"value" in datapoint && context.selectedDataset?.data_type === DataType.Datetime && (
                    <td className="px-2 py-3 pr-3">
                      <div className="text-center">{moment(datapoint?.value).format("MM/DD/YY HH:mma")}</div>
                    </td>
                  )}
                  {"file_name" in datapoint && (
                    <td className="px-2 py-3 pr-3">
                      <div className="w-[300px] truncate text-left">{datapoint?.file_name}</div>
                    </td>
                  )}
                  {"is_checked" in datapoint && (
                    <td className="px-2 py-3 pr-3">
                      <div className="text-center">
                        <input type="checkbox" className="form-checkbox h-4 w-4" checked={datapoint?.is_checked} readOnly />
                      </div>
                    </td>
                  )}
                  {"file_name" in datapoint && (
                    <td className="whitespace-nowrap px-2 py-3 pr-3">
                      <div className="text-center">
                        <button
                          tabIndex={-1}
                          className="border-serial-palette-200 btn text-serial-palette-600 hover:text-serial-palette-800 whitespace-nowrap rounded-md border bg-white"
                          onClick={() =>
                            fetchDownloadUrl(
                              datapoint.bucket_name,
                              datapoint.file_id ?? datapoint.id,
                              datapoint.company_id,
                              false,
                              true,
                              datapoint.file_name,
                            )
                          }
                        >
                          <FontAwesomeIcon size="sm" icon={faFileDownload} />
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
            {context.data.length === 0 && (
              <tr>
                <td className="whitespace-nowrap px-3 py-2" colSpan={5}>
                  <div className="text-serial-palette-400 text-center italic">No data available</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DatasetAnalyticsPerformanceSnList;
