import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TypeTag from "@shared/components/TypeTag";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { UniqueIdentifier, WorkOrder } from "@shared/types/databaseTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import UserAvatar from "@shared/components/UserAvatar";
import { formatTimeAgo } from "@shared/utils/time";
import { appendLatestProcessEntry } from "@shared/connections/supabaseLatestProcessEntry";
import { UniqueIdentifierWithLatestProcessEntryAndProcess } from "@shared/types/sharedComponentTypes";
import { Operator } from "@shared/types/databaseTypes";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface WorkOrderUnitListProps {
  workOrder: WorkOrder;
  unitList: UniqueIdentifier[];
}

const WorkOrderUnitList: React.FC<WorkOrderUnitListProps> = ({ workOrder, unitList }) => {
  const [workOrderIdentifiers, setWorkOrderIdentifiers] = useState<UniqueIdentifierWithLatestProcessEntryAndProcess[]>([]);
  const [hoverIdentiferIndex, setHoverIdentiferIndex] = useState<number | null>(null);
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const operators = useSelector((state: RootState) => state.db.operators);
  const users = useSelector((state: RootState) => state.db.users);
  const navigate = useNavigate();
  const observe = useContext(ObservabilityContext);

  const parseDateOrOld = (dateString: string) => (dateString ? new Date(dateString) : new Date(-8640000000000000));

  useEffect(() => {
    const fetchWorkOrderIdentifiers = async () => {
      const filteredComponentInstances = componentInstances.filter((instance) => instance.work_order_id === workOrder.id);
      const identifiersWithProcessEntry = await appendLatestProcessEntry(filteredComponentInstances);
      identifiersWithProcessEntry.sort((a, b) => {
        const dateA: Date = a.latest_process_entry ? parseDateOrOld(a.latest_process_entry.timestamp) : parseDateOrOld(a.last_updated_at);
        const dateB: Date = b.latest_process_entry ? parseDateOrOld(b.latest_process_entry.timestamp) : parseDateOrOld(b.last_updated_at);
        return dateB.getTime() - dateA.getTime(); // Sort in descending order; swap dateB and dateA for ascending
      });
      setWorkOrderIdentifiers(identifiersWithProcessEntry);
    };
    fetchWorkOrderIdentifiers();
  }, [componentInstances, workOrder.id, appendLatestProcessEntry]);

  return (
    <div className={`scrollbar-hide flex h-full w-full grow-0 flex-col overflow-scroll`}>
      {/* Tile content */}
      <table className="w-full table-fixed">
        {/* Table header */}
        <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 sticky top-0 border-b text-xs font-semibold uppercase">
          <tr>
            <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Identifier</div>
            </th>
            <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Last Process Entry</div>
            </th>
            <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Last Operator</div>
            </th>
            <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Last Updated</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="divide-serial-palette-200 divide-y text-sm">
          {workOrderIdentifiers?.map((componentInstance: UniqueIdentifierWithLatestProcessEntryAndProcess, index) => {
            let operator: Operator | null = null; // Declare outside the if block
            let user = null; // Declare outside the if block
            if (componentInstance?.latest_process_entry) {
              operator = operators.find((op) => op.id === componentInstance.latest_process_entry.operator_id) || null;
              user = users.find((u) => u.supabase_uid === operator?.user_id);
            }
            return (
              <tr
                key={index}
                onMouseEnter={() => setHoverIdentiferIndex(index)}
                onMouseLeave={() => setHoverIdentiferIndex(null)}
                className="hover:bg-serial-palette-100 h-[40px] cursor-pointer"
                onClick={() => (
                  navigate("/snlookup?uid=" + componentInstance?.id),
                  observe.track("Navigate to SnLookup", { "UI Source": "Work Order - Identifier List" })
                )}
              >
                <td className="cursor-pointer whitespace-nowrap px-2 py-1 first:pl-5 last:pr-5">
                  <div className="inline-flex items-center space-x-2">
                    <TypeTag className="h-[20px] w-auto text-[10px]" type={componentInstance.status ?? "DefaultStatus"} hideText={true} />
                    <div className="text-left">{componentInstance?.identifier}</div>
                    <CopyToClipboard
                      text={componentInstance?.identifier ?? ""}
                      className={`${hoverIdentiferIndex === index ? "" : "text-white"}`}
                    />
                  </div>
                </td>
                <td className="whitespace-nowrap px-2 py-1 first:pl-5  last:pr-5">
                  <div className="text-left">{componentInstance?.latest_process_entry?.process?.name ?? "No Processes Logged"}</div>
                </td>
                <td className="px-2 py-1 first:pl-5  last:pr-5">
                  {componentInstance?.latest_process_entry ? (
                    <div className="inline-flex items-center space-x-3">
                      {operator ? <UserAvatar user={user ?? operator} size="xs" /> : ""}{" "}
                      {/* Prefer user since users have avatars (operators do not) */}
                      <div className="flex">{operator ? `${operator.first_name} ${operator.last_name}` : "Not recorded"}</div>
                    </div>
                  ) : (
                    <div>Not recorded</div>
                  )}
                </td>
                <td className="px-2 py-1 first:pl-5 last:pr-5">
                  <div className="text-left">
                    {componentInstance?.latest_process_entry
                      ? formatTimeAgo(componentInstance?.latest_process_entry.timestamp)
                      : formatTimeAgo(componentInstance.last_updated_at)}
                  </div>
                </td>
              </tr>
            );
          })}
          {(!unitList || unitList.length === 0) && (
            <tr>
              <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5" colSpan={3}>
                <div className="text-serial-palette-400 text-center italic">No units yet</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default WorkOrderUnitList;
