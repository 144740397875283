type VersionBlockProps = {
  version: string;
  size?: "xs" | "sm" | "md";
  color: "green" | "grey" | "black";
  className?: string;
};

const sizeMapping = {
  xs: "h-4 text-[10px] px-1.5",
  sm: "h-6",
  md: "h-8 w-10 md:text-base",
};

const VersionBlock: React.FC<VersionBlockProps> = ({ version, size = "md", color, className = "" }) => {
  // Mapping color to CSS classes
  const colorMapping = {
    green: "bg-green-500",
    grey: "bg-serial-palette-400",
    black: "bg-serial-palette-600",
  };

  const blockStyling = `flex items-center justify-center text-center text-white px-2 rounded 
  ${sizeMapping[size]}
  ${colorMapping[color]}`;

  return <div className={`${className} ${blockStyling}`}>v{version}</div>;
};

export default VersionBlock;
