import * as React from "react";

import { cn } from "@shared/utils/tailwind";
const SidebarSelectorContext = React.createContext<{
  value?: string | null;
  onValueChange?: (value: string) => void;
}>({
  value: null,
  onValueChange: () => {},
});

interface SidebarSelectorRootProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: string | null;
  onValueChange?: (value: string) => void;
}

const SidebarSelectorRoot = React.forwardRef<HTMLDivElement, SidebarSelectorRootProps>(
  ({ className, value, onValueChange, ...props }, ref) => (
    <SidebarSelectorContext.Provider value={{ value, onValueChange }}>
      <div ref={ref} className={cn("flex h-full w-56 shrink-0 flex-col border-r bg-white", className)} {...props} />
    </SidebarSelectorContext.Provider>
  ),
);

const SidebarSelectorHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex w-full flex-col items-center gap-2 border-b bg-white p-3", className)} {...props} />
));

const SidebarSelectorList = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("scrollbar-hide flex min-h-0 min-w-0 flex-grow flex-col overflow-y-auto", className)} {...props} />
));

interface SidebarSelectorItemProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  children: React.ReactNode;
}

const SidebarSelectorItem = React.forwardRef<HTMLDivElement, SidebarSelectorItemProps>(({ className, value, ...props }, ref) => {
  const { value: selectedValue, onValueChange } = React.useContext(SidebarSelectorContext);
  const isSelected = React.useMemo(() => selectedValue === value, [selectedValue, value]);

  return (
    <div
      ref={ref}
      className={cn(
        "group relative flex w-full shrink-0 cursor-pointer flex-col gap-0.5 border-b bg-white px-4 py-3",
        isSelected ? "bg-serial-palette-100" : "hover:bg-serial-palette-100 bg-white",
        className,
      )}
      onClick={() => onValueChange?.(value)}
      {...props}
    >
      <div
        className={cn(
          isSelected ? "absolute" : "hidden group-hover:block",
          "bg-serial-palette-500 absolute left-0 top-[17%] h-2/3 w-[7px] rounded-r",
        )}
      />
      {props.children}
    </div>
  );
});

const SidebarSelector = Object.assign(SidebarSelectorRoot, {
  Root: SidebarSelectorRoot,
  Header: SidebarSelectorHeader,
  List: SidebarSelectorList,
  Item: SidebarSelectorItem,
});

export default SidebarSelector;
