import { cn } from "@shared/utils/tailwind";
import * as React from "react";
import Button, { ButtonSize, ButtonVariant } from "./Button";

interface ButtonGroupContextProps {
  variant: ButtonVariant;
  size?: ButtonSize;
}

const defaultContext: ButtonGroupContextProps = {
  variant: "default",
  size: "md",
};

export const ButtonGroupContext = React.createContext<ButtonGroupContextProps>(defaultContext);

interface ButtonGroupRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
}

const ButtonGroupRoot = React.forwardRef<HTMLDivElement, ButtonGroupRootProps>(({ className, variant, size, ...props }, ref) => {
  return (
    <ButtonGroupContext.Provider value={{ variant: variant ?? "default", size: size ?? "md" }}>
      <div ref={ref} {...props} className={cn("ml-[1px] flex", className)} />
    </ButtonGroupContext.Provider>
  );
});
ButtonGroupRoot.displayName = "ButtonGroupRoot";

const borderClasses = {
  default: "border-x-white first:border-l-black last:border-r-black hover:border-serial-palette-600",
  danger: "border-x-white first:border-l-red-500 last:border-r-red-500 hover:border-red-600",
  success: "border-x-white first:border-l-green-500 last:border-r-green-500 hover:border-green-600",
  warning: "border-x-white first:border-l-amber-500 last:border-r-amber-500 hover:border-amber-600",
  outline: "",
  ghost: "!border-none",
  link: "!border-none",
};

const ButtonGroupButton = React.forwardRef<React.ElementRef<typeof Button>, React.ComponentPropsWithoutRef<typeof Button>>(
  ({ className, variant, ...props }, ref) => {
    const { variant: groupVariant, size } = React.useContext(ButtonGroupContext);
    return (
      <Button
        size={size}
        variant={variant ?? groupVariant}
        className={cn(
          borderClasses[variant ?? groupVariant ?? "default"],
          variant !== "ghost" && groupVariant !== "ghost" && "rounded-none first:rounded-l last:rounded-r",
          "z-0 -ml-[1px] hover:z-10",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
ButtonGroupButton.displayName = Button.displayName;

const ButtonGroup = Object.assign(ButtonGroupRoot, {
  Root: ButtonGroupRoot,
  Button: ButtonGroupButton,
});

export default ButtonGroup;
