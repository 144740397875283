import React, { useContext } from "react";
import { faCircleCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkNonEmpty } from "@shared/utils/helpers";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { FieldType } from "@shared/types/databaseTypes";

const ProcessBuilderSpecSummary: React.FunctionComponent = () => {
  const { draftProcess } = useContext(ProcessBuilderContext);

  const fields = draftProcess?.process_steps.map((step) => step.fields).flat() ?? [];
  const numFieldsWithSpecs =
    fields.filter(
      (field) => field.type === FieldType.ManualEntry && (checkNonEmpty([field?.dataset?.usl]) || checkNonEmpty([field?.dataset?.lsl])),
    ).length +
    fields.filter((field) => field.type === FieldType.Checkbox && field?.dataset?.expected_value).length +
    fields.filter((field) => field.type === FieldType.PassFail).length;

  return (
    <div
      className={`flex max-h-72 min-h-[200px] w-full flex-col overflow-hidden border border-yellow-400 bg-yellow-50 p-3 ${numFieldsWithSpecs === 0 ? "hidden" : ""}`}
    >
      <div className="flex w-full justify-between ">
        <div className="font-medium">Spec Summary</div>
        <div className="flex gap-x-1 font-black">
          <span className="text-emerald-600">
            <FontAwesomeIcon icon={faCircleCheck} />
          </span>
          <span>/</span>
          <span className="text-rose-500">
            <FontAwesomeIcon icon={faXmarkCircle} />
          </span>
        </div>
      </div>
      <div className="w-full py-3 text-sm">
        All of the following specs must be satisfied in order for a process to <span className="font-medium text-green-500">PASS</span>:
      </div>
      <div className="flex flex-col overflow-y-auto">
        {fields.map((field, index) => {
          switch (field.type) {
            case FieldType.ManualEntry:
              if (checkNonEmpty([field?.dataset?.lsl, field?.dataset?.usl])) {
                return (
                  <div key={index} className="mb-2 flex gap-x-1 pl-2">
                    <div>•</div>
                    <div className="flex flex-col">
                      <span className="text-sm font-medium">{field.prompt}</span>
                      <span className="text-sm italic">
                        Must be between <span className="font-bold">{field?.dataset?.lsl + (field?.dataset?.unit || "")}</span> and{" "}
                        <span className="font-bold">{field?.dataset?.usl + (field?.dataset?.unit || "")}</span>
                      </span>
                    </div>
                  </div>
                );
              } else if (checkNonEmpty([field?.dataset?.usl])) {
                return (
                  <div key={index} className="mb-2 flex gap-x-1 pl-2">
                    <div>•</div>
                    <div className="flex flex-col">
                      <span className="text-sm font-medium">{field.prompt}</span>
                      <span className="text-sm italic">
                        Must be {"<"} <span className="font-bold">{field?.dataset?.usl + (field?.dataset?.unit || "")}</span>
                      </span>
                    </div>
                  </div>
                );
              } else if (checkNonEmpty([field?.dataset?.lsl])) {
                return (
                  <div key={index} className="mb-2 flex gap-x-1 pl-2">
                    <div>•</div>
                    <div className="flex flex-col">
                      <span className="text-sm font-medium">{field.prompt}</span>
                      <span className="text-sm italic">
                        Must be {">"} <span className="font-bold">{field?.dataset?.lsl + (field?.dataset?.unit || "")}</span>
                      </span>
                    </div>
                  </div>
                );
              }
              break;
            case FieldType.PassFail:
              return (
                <div key={index} className="mb-2 flex gap-x-1 pl-2">
                  <div>•</div>
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">{field.prompt}</span>
                    <span className="text-sm italic">
                      Must be marked as <span className="font-bold">PASS</span>
                    </span>
                  </div>
                </div>
              );
            case FieldType.Checkbox:
              if (field?.dataset?.expected_value === "true") {
                return (
                  <div key={index} className="mb-2 flex gap-x-1 pl-2">
                    <div>•</div>
                    <div className="flex flex-col">
                      <span className="text-sm font-medium">{field.prompt}</span>
                      <span className="text-sm italic">
                        Must be <span className="font-bold">checked off</span>
                      </span>
                    </div>
                  </div>
                );
              }
              break;
          }
          return <span key={index}></span>;
        })}
      </div>
    </div>
  );
};

export default ProcessBuilderSpecSummary;
