import React from "react";
import store from "@shared/redux/store";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { Provider } from "react-redux";
import { ToastProvider } from "@shared/context/ToastProvider";
import { ObservabilityProvider } from "@shared/context/ObservabilityProvider";
import { CommandProvider } from "@shared/context/CommandProvider";
import ErrorBoundaryFullPage from "@shared/components/error/ErrorBoundaryFullPage";
import { LabelPrinterProvider } from "@shared/context/LabelPrinterProvider";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ToastProvider>
          <ObservabilityProvider>
            <ErrorBoundaryFullPage>
              <LabelPrinterProvider>
                <CommandProvider>
                  <App />
                </CommandProvider>
              </LabelPrinterProvider>
            </ErrorBoundaryFullPage>
          </ObservabilityProvider>
        </ToastProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
