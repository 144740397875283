import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";

// ------------------- READ FUNCTIONS ------------------- //

// Fetch fields components data from backend
export const fetchProcessRevisions = async (processId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let { data: processRevisions, error } = await supabase
    .from("processes")
    .select("*")
    .eq("id", processId)
    .order("revision", { ascending: false });

  // Handle database error else, map fields
  if (error) {
    console.log("No revisions present for this process");
    console.log(error);
    return [];
  }
  return processRevisions;
};

export const setProcessesInactive = async (processIds, componentId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    await Promise.all(
      processIds.map((processId) =>
        supabase
          .from("component_process_links")
          .update({ is_active: false })
          .eq("component_id", componentId)
          .eq("process_id", processId)
          .select()
          .single(),
      ),
    );
  } catch (error) {
    // Handle request error
    console.error(error);
  }
};

export const setProcessesActive = async (processIds, componentId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    await Promise.all(
      processIds.map(async (processId) =>
        supabase
          .from("component_process_links")
          .update({ is_active: true })
          .eq("component_id", componentId)
          .eq("process_id", processId)
          .select()
          .single(),
      ),
    );
  } catch (error) {
    // Handle request error
    console.error(error);
  }
};
