import React, { useContext, createContext, useMemo } from "react";
import { ToastContext } from "@shared/context/ToastProvider";
import { LabelFormatWithContents } from "@shared/labels/types";
import { ToastType } from "@shared/components/Toast";
import { generateLabelPreviewImageUrl } from "@shared/labels/helpers/preview";
import useZebraPrinter from "@shared/hooks/useZebraPrinter";
import useDymoPrinter from "@shared/hooks/useDymoPrinter";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

const AUTO_STATUS_CHECK_INTERVAL = 60000; // 1 minute

export interface PrinterStatus {
  driverInstalled: boolean;
  printerConnected: boolean;
  readyToPrint: boolean;
}

export enum PrinterType {
  Dymo = "DYMO",
  Zebra = "ZEBRA",
}

interface LabelPrinterContextProps {
  printers: {
    [printerType in PrinterType]: {
      status: PrinterStatus;
      getStatus: () => void;
      printLabel: (labelFormat: LabelFormatWithContents, labelIdentifier: string) => Promise<boolean>;
      getPreviewUrl: (labelFormat: LabelFormatWithContents, labelIdentifier: string) => Promise<string | undefined>;
    };
  };
}

const defaultContext: LabelPrinterContextProps = {
  printers: {
    [PrinterType.Dymo]: {
      status: {
        driverInstalled: false,
        printerConnected: false,
        readyToPrint: false,
      },
      getStatus: () => {},
      printLabel: async () => false,
      getPreviewUrl: async () => undefined,
    },
    [PrinterType.Zebra]: {
      status: {
        driverInstalled: false,
        printerConnected: false,
        readyToPrint: false,
      },
      getStatus: () => {},
      printLabel: async () => false,
      getPreviewUrl: async () => undefined,
    },
  },
};

const LabelPrinterContext = createContext<LabelPrinterContextProps>(defaultContext);

export const LabelPrinterProvider = ({ children }: { children: React.ReactNode }) => {
  const company = useSelector((state: RootState) => state.db.company);
  const { triggerToast } = useContext(ToastContext);

  const {
    status: dymoStatus,
    getStatus: dymoGetStatus,
    printLabel: dymoPrintLabel,
    getPreviewUrl: dymoGetPreviewUrl,
  } = useDymoPrinter({ enabled: company.config.enable_dymo_integration ?? false });

  const {
    status: zebraStatus,
    getStatus: zebraGetStatus,
    printLabel: zebraPrintLabel,
  } = useZebraPrinter({
    enabled: company.config.enable_zebra_integration ?? false,
    autoStatusCheckInterval: AUTO_STATUS_CHECK_INTERVAL,
  });

  const zebraPrinter = useMemo(() => {
    return {
      status: zebraStatus,
      getStatus: zebraGetStatus,
      printLabel: async (labelFormat: LabelFormatWithContents, labelIdentifier: string) => {
        const labelImageUrl = await generateLabelPreviewImageUrl(labelFormat, labelIdentifier);
        if (!labelImageUrl) {
          triggerToast(ToastType.Error, "Error generating label", "Could not generate label image URL");
          return false;
        }
        return zebraPrintLabel(labelImageUrl);
      },
      getPreviewUrl: generateLabelPreviewImageUrl,
    };
  }, [zebraStatus, zebraPrintLabel]);

  const dymoPrinter = useMemo(() => {
    return {
      status: dymoStatus,
      getStatus: dymoGetStatus,
      printLabel: async (labelFormat: LabelFormatWithContents, labelIdentifier: string) => {
        if (!labelFormat.xml || !labelFormat.xml_identifier) {
          triggerToast(ToastType.Error, "Invalid label format", "Label format is missing XML or XML identifier");
          return false;
        }
        const labelXml = labelFormat.xml.replaceAll(labelFormat.xml_identifier, labelIdentifier);
        return dymoPrintLabel(labelXml);
      },
      getPreviewUrl: async (labelFormat: LabelFormatWithContents, labelIdentifier: string) => {
        if (!labelFormat.xml || !labelFormat.xml_identifier) {
          triggerToast(ToastType.Error, "Invalid label format", "Label format is missing XML or XML identifier");
          return undefined;
        }
        const labelXml = labelFormat.xml.replaceAll(labelFormat.xml_identifier, labelIdentifier);
        return dymoGetPreviewUrl(labelXml);
      },
    };
  }, [dymoStatus, dymoPrintLabel, triggerToast, dymoGetPreviewUrl]);

  return (
    <LabelPrinterContext.Provider
      value={{
        printers: {
          [PrinterType.Dymo]: dymoPrinter,
          [PrinterType.Zebra]: zebraPrinter,
        },
      }}
    >
      {children}
    </LabelPrinterContext.Provider>
  );
};

export const useLabelPrinter = () => {
  const context = useContext(LabelPrinterContext);
  if (!context) {
    throw new Error("useLabelPrinter must be used within a LabelPrinterProvider");
  }
  return context;
};
