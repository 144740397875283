import React, { useContext, useRef, useMemo } from "react";
import { ProductionContext } from "../../ProductionProvider";
import { FileBlock, ImageBlock, PdfBlock, VideoBlock, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import { ProductionWorkInstructionBlockContentProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { sentBlobToDownloadsFolder } from "@shared/connections/supabaseGeneral";
import ImageMarkup from "@shared/image-markup/ImageMarkup";
import useProcessMediaNumbering from "@shared/hooks/useProcessMediaNumbering";

const ProductionWorkInstructionBlockFileGeneric: React.FC<
  ProductionWorkInstructionBlockContentProps<FileBlock | ImageBlock | VideoBlock | PdfBlock>
> = ({ block }) => {
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const { workInstructionUrls, process } = useContext(ProductionContext);
  const { imageNumber, fileNumber, videoNumber, pdfNumber } = useProcessMediaNumbering(process ?? undefined);
  const preCaptionNumbering = useMemo(() => {
    if (block.type === WorkInstructionBlockType.File) return `File ${fileNumber[block.id]}:`;
    if (block.type === WorkInstructionBlockType.Image) return `Image ${imageNumber[block.id]}:`;
    if (block.type === WorkInstructionBlockType.Video) return `Video ${videoNumber[block.id]}:`;
    if (block.type === WorkInstructionBlockType.PDF) return `PDF ${pdfNumber[block.id]}:`;
    return null;
  }, [block.type, fileNumber, imageNumber, videoNumber, pdfNumber, block.id]);

  return (
    <div className="bg-serial-palette-50 relative flex w-full flex-col rounded-md px-4 pb-2 pt-0.5">
      {workInstructionUrls[block.file_id] && (
        <div className="flex w-full justify-center" ref={pdfContainerRef}>
          {block.type === WorkInstructionBlockType.Image && (
            <div className="flex justify-center" style={{ height: `${block.content?.height}px` }}>
              <ImageMarkup imageUrl={workInstructionUrls[block.file_id]} markup={block.content.markup ?? []} editing={false} />
            </div>
          )}
          {block.type === WorkInstructionBlockType.Video && (
            <video
              src={workInstructionUrls[block.file_id]}
              className="my-2 h-64 w-fit rounded-md object-contain"
              style={{ height: `${block.content.height}px` }}
              controls
            />
          )}
          {block.type === WorkInstructionBlockType.PDF && (
            <div className="h-64 w-full overflow-hidden rounded-md py-2" style={{ height: `${block.content.height}px` }}>
              <iframe src={workInstructionUrls[block.file_id]} width="100%" height="100%" />
            </div>
          )}
          {block.type === WorkInstructionBlockType.File && (
            <div className="flex w-full justify-between py-2">
              <div className="font-semibold">{block.content.file_name}</div>
              <button
                className="btn-xs serial-btn-light"
                onClick={() => sentBlobToDownloadsFolder(workInstructionUrls[block.file_id], block.content.file_name, true)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
            </div>
          )}
        </div>
      )}
      {block.content.caption !== undefined && (
        <div className="flex justify-center gap-x-1">
          {process?.show_media_numbering && <span className="text-right text-sm font-bold">{preCaptionNumbering}</span>}
          <div className="whitespace-fill w-fit text-left text-sm font-medium">{block.content.caption}</div>
        </div>
      )}
    </div>
  );
};

export default ProductionWorkInstructionBlockFileGeneric;
