import { useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ReportsContext } from "../ReportsProvider";
import { ReportTemplate } from "@shared/types/databaseTypes";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import JSZip from "jszip";
import { useSystemFunctionalQc } from "../hooks/useSystemFunctionalQc";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import Loader from "@shared/components/primitives/Loader";
import ModalBasic from "@shared/components/modals/ModalBasic";
import DropdownClassic from "@shared/components/dropdowns/DropdownClassic";
import { useTranslation } from "react-i18next";

const Report: React.FC = ({}) => {
  const {
    selectedReport,
    setSelectedReport,
    reports,
    uniqueIdentifiersToExport,
    component,
    componentWorkOrders,
    selectedWorkOrder,
    setSelectedWorkOrder,
  } = useContext(ReportsContext);
  const [openFailureModal, setOpenFailureModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const reportResults = useSystemFunctionalQc();
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const { t } = useTranslation();

  const pdfUrls = useMemo(() => {
    if (uniqueIdentifiersToExport.length === 0) return [];
    return reportResults.filter((result) => result.blobUrl !== undefined);
  }, [reportResults, uniqueIdentifiersToExport]);

  const urlToRender = useMemo(() => {
    if (pdfUrls.length > 0) {
      return pdfUrls[0].blobUrl;
    }
  }, [pdfUrls]);

  const uniqueIdentifierErrors = useMemo(() => {
    return reportResults
      .filter((result) => result.blobUrl === undefined)
      .map((result) => {
        const uniqueIdentifierObject = componentInstances.find((instance) => instance.id === result.uniqueIdentifierId);
        return {
          ...result, // Spread all properties of the result object
          uniqueIdentifierId: uniqueIdentifierObject, // Override the uniqueIdentifierId with the object
          // You can include the failureMessage here if you want it to be part of the returned object
          failureMessage: result.failureMessage ?? "missing data",
        };
      });
  }, [reportResults, componentInstances]); // Ensure componentInstances is also a dependency

  const changeReport = (report: ReportTemplate) => {
    setSelectedReport(report);
    navigate(`/reports/${report.name}`);
  };

  const handleDownloadClick = async () => {
    const zip = new JSZip();

    const pdfBlobPromises = pdfUrls.map(async (result, index) => {
      if (!result.blobUrl) {
        console.error("blobUrl is null or undefined for index", index);
        return;
      }

      const response = await fetch(result.blobUrl);
      const blob = await response.blob();

      const uniqueIdentifier = componentInstances.find((instance) => instance.id === result.uniqueIdentifierId);

      if (!uniqueIdentifier) {
        console.error("Unique identifier not found for result.uniqueIdentifierId:", result.uniqueIdentifierId);
        return;
      }

      zip.file(`${uniqueIdentifier.identifier}_System_Functional_QC_Report.pdf`, blob);
    });

    await Promise.all(pdfBlobPromises);

    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = window.URL.createObjectURL(content);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "System_Functional_QC_Reports.zip";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div className="flex h-full w-full flex-col gap-6 px-12 py-8">
      {/* Page header */}
      <div className="flex h-full flex-col">
        <div className="mb-4 flex w-full space-x-8 pr-6 sm:mb-0">
          {reports.map((report, index) => (
            <div
              key={index}
              className={`text-lg font-semibold ${(selectedReport && selectedReport.id) === report.id ? "text-serial-palette-800 border-b-3 border-serial-palette-800 pb-3 " : "text-gray-400"} hover:text-serial-palette-800 cursor-pointer`}
              onClick={() => changeReport(report)}
            >
              {report?.name}
            </div>
          ))}
        </div>
        <div className="flex h-full w-full pt-4">
          <div className="w-1/3 space-y-3 pr-3">
            <DropdownClassic
              className="w-full"
              options={[component ? { id: component.id, label: component.name } : null].filter(Boolean)}
              selected={selectedReport?.config.component_id || null}
              setSelected={() => {}}
            />
            <DropdownClassic
              className="w-full"
              options={[
                { id: null, label: `Select ${t("workOrder").toLowerCase()}...` },
                ...componentWorkOrders.map((wo) => ({ id: wo.id, label: wo.name })),
              ]}
              selected={selectedWorkOrder ? selectedWorkOrder.id : null}
              setSelected={(selectedId: string) => {
                const selectedWorkOrderObject = componentWorkOrders.find((wo) => wo.id === selectedId) || null;
                setSelectedWorkOrder(selectedWorkOrderObject);
              }}
            />
            <div className="flex w-full justify-between">
              <div>
                <div>Reports to Generate: {uniqueIdentifiersToExport.length}</div>
                <div>Successful Reports: {pdfUrls.length}</div>
                <div>Failed Reports: {uniqueIdentifierErrors.length}</div>
              </div>
              <div className="flex flex-col space-y-2">
                <Button size="md" onClick={handleDownloadClick}>
                  <FontAwesomeIcon icon={faDownload} />
                  Download
                </Button>
                <Button size="md" onClick={(e) => (setOpenFailureModal(true), e.stopPropagation())}>
                  View Failed Reports
                </Button>
              </div>
              <ModalBasic
                id="instantiation-modal"
                title="Report Generation Failing Indentifiers"
                modalOpen={openFailureModal}
                setModalOpen={setOpenFailureModal}
                classOverride="w-full md:w-[1000px] h-[60vh] overflow-auto"
              >
                {uniqueIdentifierErrors.map((reportResult, index) => (
                  <div key={index} className="px-5 py-1">
                    {reportResult?.uniqueIdentifierId?.identifier}, {reportResult.failureMessage}
                  </div>
                ))}
              </ModalBasic>
            </div>
          </div>
          {uniqueIdentifiersToExport.length === 0 ? (
            <div className="flex h-full w-2/3 items-center justify-center">
              <p>No {component ? component.name + " instances" : "component instances"} meet filter requirements.</p>
            </div>
          ) : urlToRender ? (
            <div className="w-2/3">
              <iframe title="Report PDF" src={urlToRender} width="100%" height="100%" />
            </div>
          ) : (
            <div className="flex h-full w-2/3 items-center justify-center">
              <Loader size="xl" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;
