import { getSupabase } from "@shared/connections/supabaseAuth";
import { ParsedResponse } from "@shared/types/apiTypes";
import {
  Dataset,
  LogFileSchema,
  LogFileSchemaDatasetLink,
  Process,
  ProcessWithReferences,
  UniqueIdentifier,
} from "@shared/types/databaseTypes";
import { ProcessWithOptionalCreatedAt } from "../types";

export const fetchProcessRevisions = async (processId: string): Promise<ParsedResponse<Process[]>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("processes")
    .select("*")
    .eq("id", processId)
    .order("revision", { ascending: false })
    .returns<Process[]>();
  return { data: data ?? undefined, error: error?.message };
};

export const uploadWorkInstructionFile = async (file_id: string, file: File, companyId: string): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase.storage.from("work-instructions").upload(`${companyId}/${file_id}`, file);
  return { data: data, error: error?.message };
};

export const fetchComponentInstanceByIdentifier = async (
  identifier: string,
  abortSignal?: AbortSignal,
): Promise<ParsedResponse<UniqueIdentifier>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("unique_identifiers")
    .select("*, component:component_id(*), part_number:part_number_id(*), work_order:work_order_id(*)")
    .eq("identifier", identifier)
    .abortSignal(abortSignal ?? new AbortController().signal)
    .returns<UniqueIdentifier[]>();
  return { data: data?.[0] ?? undefined, error: error?.message ?? undefined };
};

export const updateInactiveDatasets = async (processId: string, activeLinkDatasetIds: string[]): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("datasets")
    .update({ is_active: false })
    .eq("process_id", processId)
    .not("id", "in", `(${activeLinkDatasetIds.join(",")})`);
  return { data: data, error: error?.message };
};

export const insertSchemaDatasetLinks = async (datasetLinks: LogFileSchemaDatasetLink[]): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("datasets")
    .upsert(datasetLinks.map((datasetLink) => datasetLink.dataset))
    .select("*");
  if (error) {
    return { error: error.message, data: undefined };
  }

  const { error: pathKeyError } = await supabase.from("log_file_schema_dataset_links").insert(
    datasetLinks.map((datasetLink) => {
      return {
        schema_id: datasetLink.schema_id,
        schema_revision: datasetLink.schema_revision,
        dataset_id: datasetLink.dataset_id,
        company_id: datasetLink.company_id,
        is_active: datasetLink.is_active,
        path_key: datasetLink.path_key,
      };
    }),
  );
  if (pathKeyError) {
    return { error: pathKeyError.message, data: undefined };
  }
  return { data: data, error: undefined };
};

export const upsertDatasets = async (datasets: Dataset[]): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase.from("datasets").upsert(datasets);
  return { data: data, error: error?.message };
};

export const updateInactiveComponentLinks = async (
  componentId: string,
  processId: string,
  activeLinkDatasetIds: string[],
): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("component_links")
    .update({ is_active: false })
    .eq("component_id", componentId)
    .eq("process_id", processId)
    .not("dataset_id", "in", `(${activeLinkDatasetIds.join(",")})`);
  return { data: data, error: error?.message };
};

export const upsertComponentLinks = async (datasets: Dataset[], componentId: string): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  // check to make sure all datasets have a child component id
  if (datasets.some((dataset) => !dataset.child_component_id)) {
    return { error: "All link datasets must have a child component id", data: undefined };
  }
  const newComponentLinks = datasets.map((dataset) => {
    return {
      company_id: dataset.company_id,
      component_id: componentId,
      has_child_of_id: dataset.child_component_id,
      dataset_id: dataset.id,
      process_id: dataset.process_id,
      process_revision: dataset.process_revision,
    };
  });
  const { data, error } = await supabase
    .from("component_links")
    .upsert(newComponentLinks, { onConflict: "component_id,has_child_of_id,process_id,dataset_id", ignoreDuplicates: true });
  return { data: data, error: error?.message };
};

export const insertProcessWithReferences = async (
  process: ProcessWithReferences | ProcessWithOptionalCreatedAt,
): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const processToBeInserted = { process_data: process };
  const { error: insertError } = await supabase.rpc("insert_process_with_references", processToBeInserted);
  if (insertError) return { error: insertError.message, data: undefined };
  const { data, error } = await supabase
    .from("processes")
    .select("*")
    .eq("id", process.id)
    .eq("is_latest_revision", true) // database sets the revision so don't trust the one in the request
    .single();
  return { data: data, error: error?.message };
};

export const insertProcessRevisionZero = async (process: ProcessWithReferences | ProcessWithOptionalCreatedAt): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const revisionZeroProcess = {
    ...process,
    revision: -1,
    process_steps: undefined,
  };
  const { data, error } = await supabase.from("processes").insert(revisionZeroProcess);
  return { data: data, error: error?.message };
};

export const insertComponentProcessLink = async (newLink: {
  company_id: string;
  component_id: string;
  process_id: string;
  process_revision: number;
}): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase.from("component_process_links").insert(newLink);
  return { data: data, error: error?.message };
};

export const deleteProcess = async (processId: string): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase.rpc("delete_process", {
    p_process_id: processId,
  });
  return { data: data, error: error?.message };
};

export const setProcessIsActive = async (processId: string, componentId: string, isActive: boolean) => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("component_process_links")
    .update({ is_active: isActive })
    .eq("component_id", componentId)
    .eq("process_id", processId);
  return { data: data, error: error?.message };
};

export const checkAllowDeleteProcess = async (processId: string): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase.from("process_entries").select("process_id").eq("process_id", processId).limit(1);
  const allowDelete = !error && data?.length === 0;
  return { data: allowDelete, error: error?.message };
};

export const setComponentProcessLinksIsActive = async (processId: string, componentId: string, isActive: boolean) => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("component_process_links")
    .update({ is_active: isActive })
    .eq("component_id", componentId)
    .eq("process_id", processId)
    .select();
  return { data: data, error: error?.message };
};

export const fetchLogFileSchemasById = async (schemaId: string): Promise<ParsedResponse<LogFileSchema[]>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("log_file_schemas")
    .select("*")
    .eq("id", schemaId)
    .order("revision", { ascending: true })
    .returns<LogFileSchema[]>();
  return { data: data ?? undefined, error: error?.message };
};

export const fetchLogFileSchemaDatasetLinksBySchemaIds = async (
  schemaIds: string[],
): Promise<ParsedResponse<LogFileSchemaDatasetLink[]>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("log_file_schema_dataset_links")
    .select("*, dataset:dataset_id(*)")
    .in("schema_id", schemaIds)
    .returns<LogFileSchemaDatasetLink[]>();
  return { data: data ?? [], error: error?.message };
};

export const fetchLogFileSchemasByDatasetIds = async (datasetIds: string[]): Promise<ParsedResponse<LogFileSchema[]>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("log_file_schemas")
    .select("*")
    .in("source_dataset_id", datasetIds)
    .returns<LogFileSchema[]>();
  return { data: data ?? [], error: error?.message };
};

export const upsertSchemas = async (schemas: LogFileSchema[]): Promise<ParsedResponse> => {
  const supabase = getSupabase();
  const { data, error } = await supabase.from("log_file_schemas").upsert(schemas);
  return { data: data, error: error?.message };
};
