import { getSupabase } from "@shared/connections/supabaseAuth";
import { ParsedResponse } from "@shared/types/apiTypes";
import { PartNumber } from "@shared/types/databaseTypes";

export const getComponentPartNumbers = async (componentId: string): Promise<ParsedResponse<PartNumber[]>> => {
  const supabase = getSupabase();
  const { data: partNumbers, error: partNumbersError } = await supabase
    .from("part_numbers")
    .select("*, component:component_id(*), user:last_edited_user_id(*)")
    .eq("component_id", componentId);
  if (partNumbersError) {
    return { data: undefined, error: partNumbersError.message };
  }
  return { data: partNumbers, error: undefined };
};

const checkAllowDeletePartNumber = async (partNumberId: string): Promise<boolean> => {
  const supabase = getSupabase();
  const { data: firstOccurance } = await supabase.from("unique_identifiers").select("*").eq("part_number_id", partNumberId).limit(1);

  if (firstOccurance?.length === 0) {
    return true;
  }
  return false;
};

export const deletePartNumber = async (partNumberId: string): Promise<ParsedResponse<string>> => {
  const supabase = getSupabase();
  const allowDeletePartNumber = await checkAllowDeletePartNumber(partNumberId);
  if (allowDeletePartNumber) {
    const { error: deletePartNumberError } = await supabase.from("part_numbers").delete().eq("id", partNumberId);
    if (deletePartNumberError) {
      return { data: undefined, error: deletePartNumberError.message };
    }
    return { data: partNumberId, error: undefined };
  }
  return { data: undefined, error: "Part number is in use and cannot be deleted." };
};

export const setPartNumberActivity = async (partNumberId: string, isActive: boolean): Promise<ParsedResponse<PartNumber>> => {
  const supabase = getSupabase();
  const { data: updatedPartNumber, error: updatedPartNumberError } = await supabase
    .from("part_numbers")
    .update({ is_active: isActive })
    .eq("id", partNumberId)
    .select("*");
  if (updatedPartNumberError) {
    return { data: undefined, error: updatedPartNumberError.message };
  }
  return { data: updatedPartNumber[0], error: undefined };
};
