import React, { useEffect, useState } from "react";
import { useRecordWebcam } from "react-record-webcam";
import DropdownList from "./dropdowns/DropdownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPause, faPlay, faRefresh, faStop, faVideoCamera } from "@fortawesome/free-solid-svg-icons";

interface WebcamBoxProps {
  handleVideoCaptured: (videoFile: File | null) => void;
}

const WebcamVideoCapture: React.FC<WebcamBoxProps> = ({ handleVideoCaptured }) => {
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string | undefined>(undefined);
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<string | undefined>(undefined);

  const recordingRef = React.useRef<any>(null);

  const {
    activeRecordings,
    createRecording,
    openCamera,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    devicesByType,
    cancelRecording,
  } = useRecordWebcam();

  // automatically start a new recording when the selected video or audio device changes
  useEffect(() => {
    if (recordingRef.current) {
      handleReset();
    } else {
      handleCreateRecording();
    }
  }, [selectedVideoDeviceId, selectedAudioDeviceId]);

  // automatically set the selected video and audio devices to the first available devices if they are not already set
  useEffect(() => {
    if (!selectedVideoDeviceId && (devicesByType?.video.length ?? 0 > 0)) {
      setSelectedVideoDeviceId(devicesByType?.video[0].deviceId);
    }
    if (!selectedAudioDeviceId && (devicesByType?.audio.length ?? 0 > 0)) {
      setSelectedAudioDeviceId(devicesByType?.audio[0].deviceId);
    }
  }, [devicesByType]);

  const handleCreateRecording = async () => {
    try {
      if (recordingRef.current) return;
      const recording = await createRecording(selectedVideoDeviceId, selectedAudioDeviceId);
      if (!recording) {
        console.error("Failed to create recording");
        return;
      }
      recordingRef.current = recording;
      await openCamera(recording.id);
    } catch (error) {
      console.error({ error });
    }
  };

  const handleStartRecording = async () => {
    try {
      await startRecording(activeRecordings[0].id);
    } catch (error) {
      console.error({ error });
    }
  };

  const handlePauseRecording = async () => {
    try {
      await pauseRecording(activeRecordings[0].id);
    } catch (error) {
      console.error({ error });
    }
  };

  const handleResumeRecording = async () => {
    try {
      await resumeRecording(activeRecordings[0].id);
    } catch (error) {
      console.error({ error });
    }
  };

  const handleStopRecording = async () => {
    try {
      const completedRecording = await stopRecording(activeRecordings[0].id);
      if (!completedRecording) {
        console.error("Failed to stop recording");
        return;
      }
      const videoBlobUrl = completedRecording.objectURL;
      if (!videoBlobUrl) {
        console.error("Failed to get video blob url");
        return;
      }
      const response = await fetch(videoBlobUrl);
      const videoBlob = await response.blob();
      const videoFile = new File([videoBlob], `${completedRecording.fileName}.${activeRecordings[0].fileType}`, {
        type: activeRecordings[0].mimeType,
      });
      handleVideoCaptured(videoFile);
    } catch (error) {
      console.error({ error });
      handleVideoCaptured(null);
    }
  };

  const handleReset = async () => {
    try {
      // cancel all recordings
      await Promise.all(
        activeRecordings.map(async (recording) => {
          await cancelRecording(recording.id);
        }),
      );
      recordingRef.current = null;
      handleVideoCaptured(null);
      handleCreateRecording();
    } catch (error) {
      console.error({ error });
    }
  };

  return (
    <div className="relative flex min-h-[100px] w-full shrink-0 flex-col justify-center overflow-hidden rounded-lg border bg-white">
      <video
        ref={activeRecordings?.[0]?.webcamRef ?? null}
        autoPlay
        muted
        className="w-full"
        hidden={activeRecordings?.[0]?.status === "STOPPED"}
      />
      {activeRecordings?.[0] && (
        <video
          ref={activeRecordings?.[0]?.previewRef ?? null}
          autoPlay
          controls
          loop
          className="w-full"
          hidden={activeRecordings?.[0]?.status !== "STOPPED"}
        />
      )}
      <div className="absolute top-2 flex w-full justify-between px-2">
        <div className="flex flex-wrap gap-1">
          <DropdownList
            size="sm"
            items={devicesByType?.video.map((device) => ({ id: device.deviceId, label: device.label })) ?? []}
            onSelect={(id) => setSelectedVideoDeviceId(id)}
            closeOnClick
          >
            <div className="btn-xs serial-btn-light flex w-48 gap-1.5">
              <FontAwesomeIcon icon={faChevronDown} />
              <div className="truncate">
                {selectedVideoDeviceId
                  ? devicesByType?.video.find((device) => device.deviceId === selectedVideoDeviceId)?.label
                  : "Select Video Device"}
              </div>
            </div>
          </DropdownList>
          <DropdownList
            size="sm"
            items={devicesByType?.audio.map((device) => ({ id: device.deviceId, label: device.label })) ?? []}
            onSelect={(id) => setSelectedAudioDeviceId(id)}
            closeOnClick
          >
            <div className="btn-xs serial-btn-light flex w-48 gap-1.5">
              <FontAwesomeIcon icon={faChevronDown} />
              <div className="truncate">
                {selectedAudioDeviceId
                  ? devicesByType?.audio.find((device) => device.deviceId === selectedAudioDeviceId)?.label
                  : "Select Audio Device"}
              </div>
            </div>
          </DropdownList>
        </div>
        {activeRecordings?.[0]?.status === "STOPPED" && (
          <button className="btn-xs serial-btn-danger flex h-fit gap-1.5" onClick={() => handleReset()}>
            <FontAwesomeIcon icon={faRefresh} />
            <span>Reset</span>
          </button>
        )}
      </div>
      <div className="absolute bottom-3 flex w-full justify-center gap-3">
        {activeRecordings?.[0]?.status === "OPEN" && (
          <button className="h-10 w-10 rounded-full ring-2 ring-white hover:ring-4" onClick={() => handleStartRecording()}>
            <FontAwesomeIcon className="text-white" icon={faVideoCamera} size="lg" />
          </button>
        )}
        {activeRecordings?.[0]?.status === "RECORDING" && (
          <button className="h-10 w-10 rounded-full ring-2 ring-white hover:ring-4" onClick={() => handlePauseRecording()}>
            <FontAwesomeIcon className="text-white" icon={faPause} size="lg" />
          </button>
        )}
        {activeRecordings?.[0]?.status === "PAUSED" && (
          <button className="h-10 w-10 rounded-full ring-2 ring-white hover:ring-4" onClick={() => handleResumeRecording()}>
            <FontAwesomeIcon className="text-white" icon={faPlay} size="lg" />
          </button>
        )}
        {(activeRecordings?.[0]?.status === "RECORDING" || activeRecordings?.[0]?.status === "PAUSED") && (
          <button className="h-10 w-10 rounded-full text-red-500 ring-2 ring-red-500 hover:ring-4" onClick={() => handleStopRecording()}>
            <FontAwesomeIcon icon={faStop} size="lg" />
          </button>
        )}
      </div>
    </div>
  );
};

export default WebcamVideoCapture;
