import { useEffect, useMemo, useRef, useState } from "react";
import {
  FilterConditionOperator,
  FilterJoinOperator,
  GroupAggregationOperator,
  MeasureAggregationOperator,
  MeasureTimeOperator,
  MeasureFilterCondition,
  MeasureGroup,
  MeasureKey,
  MeasureKeyFormatting,
  MeasureSortCondition,
  MeasureType,
  MeasureValuesWithAggregation,
  UniqueIdentifierWithMeasures,
} from "./types";
import { createContext, useContext, ReactNode } from "react";
import { Updater, useImmer } from "use-immer";
import { getAvailableMeasureKeys } from "./helpers/availableKeys";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { hashMeasureKey } from "./helpers/measureKeys";
import {
  fetchAllMeasureValuesMappedByUniqueIdentifier,
  aggregateMeasureValues,
  filterMeasureValuesByTimeOperator,
} from "./helpers/measureValues";
import { GridApi } from "ag-grid-community";
import { fetchUniqueIdentifiersWithGenealogies } from "./connections/supabase";
import { testFilter } from "./helpers/filters";
import { DateRange } from "react-day-picker";
import { ReportTemplateMeasuresConfig } from "@shared/types/databaseTypes";

interface MeasuresInterface {
  // General shared state
  gridApi: GridApi | null;
  setGridApi: (gridApi: GridApi) => void;
  fileUrls: { [fileId: string]: string };
  setFileUrls: Updater<{ [fileId: string]: string }>;
  // Input state
  componentId: string | null;
  setComponentId: (componentId: string | null) => void;
  selectedMeasureKeys: MeasureKey[];
  setSelectedMeasureKeys: Updater<MeasureKey[]>;
  filterBy: MeasureFilterCondition[];
  setFilterBy: Updater<MeasureFilterCondition[]>;
  groupBy: MeasureKey[];
  setGroupBy: Updater<MeasureKey[]>;
  sortBy: MeasureSortCondition[];
  setSortBy: Updater<MeasureSortCondition[]>;
  filterJoinOperator: string;
  setFilterJoinOperator: (filterJoinOperator: FilterJoinOperator) => void;
  groupAggregationOperator: GroupAggregationOperator;
  setGroupAggregationOperator: (groupAggregationOperator: GroupAggregationOperator) => void;
  lastUpdatedDateRangeFilter: DateRange | undefined;
  setLastUpdatedDateRangeFilter: (dateRange: DateRange | undefined) => void;
  // Output state
  isLoading: boolean;
  availableMeasureKeys: MeasureKey[];
  identifierMeasureKeys: MeasureKey[];
  uniqueIdentifiers: UniqueIdentifierWithMeasures[];
  groups: MeasureGroup[];
  filterMapping: { [uniqueIdentifierId: string]: boolean };
  sortMapping: { [rootUniqueIdentifierId: string]: number };
  groupMapping: { [rootUniqueIdentifierId: string]: string };
  numFullyDefinedFilters: number;
  numFilteredUniqueIdentifiers: number;
  reportTemplateConfig: ReportTemplateMeasuresConfig | null;
  // Methods
  handleReset: () => void;
  handleAddSelectedKey: (key: MeasureKey) => void;
  handleRemoveSelectedKey: (key: MeasureKey) => void;
  handleUpdateKeyAggregationOperator: (key: MeasureKey, operator: MeasureAggregationOperator) => void;
  handleUpdateKeyFormatting: (key: MeasureKey, formatting: Partial<MeasureKeyFormatting>) => void;
  handleUpdateKeyTimeOperator: (key: MeasureKey, timeOperator: MeasureTimeOperator) => void;
  handleRemoveKeyTimeOperator: (key: MeasureKey, type?: "since" | "until") => void;
  handleSetFilterConditionValue: (index: number, value: string) => void;
  handleSetFilterConditionOperator: (index: number, operator: FilterConditionOperator) => void;
  handleRemoveFilterCondition: (index: number) => void;
  handleAddFilterCondition: (key: MeasureKey) => void;
  handlePromoteSortCondition: (key: MeasureKey, ascending: boolean) => void;
  handleRemoveSortCondition: (key: MeasureKey) => void;
  handleLoadFromReportTemplateConfig: (reportTemplateConfig: ReportTemplateMeasuresConfig) => void;
}

const defaultContext: MeasuresInterface = {
  // General shared state
  gridApi: null,
  setGridApi: () => {},
  fileUrls: {},
  setFileUrls: () => {},
  // Input state
  componentId: null,
  setComponentId: () => {},
  selectedMeasureKeys: [],
  setSelectedMeasureKeys: () => {},
  filterBy: [],
  setFilterBy: () => {},
  groupBy: [],
  setGroupBy: () => {},
  sortBy: [],
  setSortBy: () => {},
  filterJoinOperator: "",
  setFilterJoinOperator: () => {},
  groupAggregationOperator: GroupAggregationOperator.Count,
  setGroupAggregationOperator: () => {},
  lastUpdatedDateRangeFilter: undefined,
  setLastUpdatedDateRangeFilter: () => {},
  // Output state
  isLoading: false,
  availableMeasureKeys: [],
  identifierMeasureKeys: [],
  uniqueIdentifiers: [],
  groups: [],
  filterMapping: {},
  sortMapping: {},
  groupMapping: {},
  numFullyDefinedFilters: 0,
  numFilteredUniqueIdentifiers: 0,
  reportTemplateConfig: null,
  // Methods
  handleReset: () => {},
  handleAddSelectedKey: () => {},
  handleRemoveSelectedKey: () => {},
  handleUpdateKeyAggregationOperator: () => {},
  handleUpdateKeyFormatting: () => {},
  handleUpdateKeyTimeOperator: () => {},
  handleRemoveKeyTimeOperator: () => {},
  handleSetFilterConditionValue: () => {},
  handleSetFilterConditionOperator: () => {},
  handleRemoveFilterCondition: () => {},
  handleAddFilterCondition: () => {},
  handlePromoteSortCondition: () => {},
  handleRemoveSortCondition: () => {},
  handleLoadFromReportTemplateConfig: () => {},
};

const MeasuresContext = createContext<MeasuresInterface>(defaultContext);

export const MeasuresProvider = (props: {
  componentId?: string;
  selectedMeasureKeys?: MeasureKey[];
  filterBy?: MeasureFilterCondition[];
  groupBy?: MeasureKey[];
  sortBy?: MeasureSortCondition[];
  filterJoinOperator?: FilterJoinOperator;
  groupAggregationOperator?: GroupAggregationOperator;
  children: ReactNode;
}) => {
  const componentLinks = useSelector((state: RootState) => state.db.componentLinks);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const partNumbers = useSelector((state: RootState) => state.db.partNumbers);
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const workOrders = useSelector((state: RootState) => state.db.workOrders);

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [fileUrls, setFileUrls] = useImmer<{ [fileId: string]: string }>({});

  const [componentId, setComponentId] = useState<string | null>(null);
  const [selectedMeasureKeys, setSelectedMeasureKeys] = useImmer<MeasureKey[]>([]);
  const [filterBy, setFilterBy] = useImmer<MeasureFilterCondition[]>([]);
  const [groupBy, setGroupBy] = useImmer<MeasureKey[]>([]);
  const [sortBy, setSortBy] = useImmer<MeasureSortCondition[]>([]);
  const [filterJoinOperator, setFilterJoinOperator] = useState<FilterJoinOperator>(FilterJoinOperator.And);
  const [groupAggregationOperator, setGroupAggregationOperator] = useState<GroupAggregationOperator>(GroupAggregationOperator.Count);
  const [lastUpdatedDateRangeFilter, setLastUpdatedDateRangeFilter] = useState<DateRange | undefined>(undefined);

  const [uniqueIdentifierGenealogiesLoaded, setUniqueIdentifierGenealogiesLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uniqueIdentifiersWithMeasures, setUniqueIdentifiersWithMeasures] = useImmer<UniqueIdentifierWithMeasures[]>([]);
  const addedMeasureKeyHashes = useRef<string[]>([]);

  // ------ Methods ------

  const handleAddSelectedKey = (key: MeasureKey) => {
    const measureKeyHash = hashMeasureKey(key);
    if (!selectedMeasureKeys.find((k) => hashMeasureKey(k) === measureKeyHash)) {
      const identifierKey: MeasureKey = { component_id: key.component_id, type: MeasureType.Identifier };
      setSelectedMeasureKeys((draft) => {
        draft.push(key);
        // automatically add an identifier key for the new measure if it is not already selected
        if (!draft.find((k) => hashMeasureKey(k) === hashMeasureKey(identifierKey))) draft.push(identifierKey);
      });
    }
  };

  const handleRemoveSelectedKey = (key: MeasureKey) => {
    const hash = hashMeasureKey(key);
    setSelectedMeasureKeys((draft) => {
      return draft.filter((k) => hashMeasureKey(k) !== hash);
    });
  };

  const handleUpdateKeyAggregationOperator = (key: MeasureKey, operator: MeasureAggregationOperator) => {
    setSelectedMeasureKeys((draft) => {
      const index = draft.findIndex((k) => hashMeasureKey(k) === hashMeasureKey(key));
      if (index !== -1) draft[index].aggregation_operator = operator;
    });
  };

  const handleUpdateKeyFormatting = (key: MeasureKey, formatting: Partial<MeasureKeyFormatting>) => {
    setSelectedMeasureKeys((draft) => {
      const index = draft.findIndex((k) => hashMeasureKey(k) === hashMeasureKey(key));
      if (index !== -1) draft[index].formatting = { ...draft[index].formatting, ...formatting };
    });
  };

  const handleUpdateKeyTimeOperator = (key: MeasureKey, timeOperator: MeasureTimeOperator) => {
    setSelectedMeasureKeys((draft) => {
      const index = draft.findIndex((k) => hashMeasureKey(k) === hashMeasureKey(key));
      if (index !== -1) draft[index].time_operator = timeOperator;
    });
  };

  const handleRemoveKeyTimeOperator = (key: MeasureKey, type?: "since" | "until") => {
    setSelectedMeasureKeys((draft: MeasureKey[]) => {
      const index = draft.findIndex((k) => hashMeasureKey(k) === hashMeasureKey(key));
      if (index !== -1) {
        const timeOperator = draft[index].time_operator;

        if (type && timeOperator?.[type] !== undefined) {
          draft[index].time_operator = {
            ...timeOperator,
            [type]: null,
          };
        } else if (timeOperator) {
          delete draft[index].time_operator;
        }
      }
    });
  };

  const handleSetFilterConditionValue = (index: number, value: string) => {
    setFilterBy((prev) => {
      const newFilter = [...prev];
      newFilter[index].value = value;
    });
  };

  const handleSetFilterConditionOperator = (index: number, operator: FilterConditionOperator) => {
    setFilterBy((prev) => {
      const newFilter = [...prev];
      newFilter[index].operator = operator;
    });
  };

  const handleRemoveFilterCondition = (index: number) => {
    setFilterBy((prev) => {
      const newFilter = [...prev];
      newFilter.splice(index, 1);
      return newFilter;
    });
  };

  const handleAddFilterCondition = (key: MeasureKey) => {
    setFilterBy((prev) => {
      return [
        ...prev,
        {
          key: key,
          operator: FilterConditionOperator.Equals, // default operator (can be changed later)
          value: "",
        },
      ];
    });
  };

  const handlePromoteSortCondition = (key: MeasureKey, ascending: boolean) => {
    if (key.component_id !== componentId) {
      console.error("Sorting can only be done on measures of the root component");
      return;
    }
    setSortBy((prev) => {
      // remove any existing sort conditions for this key
      const newSort = prev.filter((sort) => hashMeasureKey(sort.key) !== hashMeasureKey(key));
      // add the new sort condition
      newSort.unshift({
        key,
        ascending,
      });
      // limit the number of sort conditions to 3
      if (newSort.length > 3) newSort.pop();
      return newSort;
    });
  };

  const handleRemoveSortCondition = (key: MeasureKey) => {
    setSortBy((prev) => {
      return prev.filter((sort) => hashMeasureKey(sort.key) !== hashMeasureKey(key));
    });
  };

  const handleReset = () => {
    setSelectedMeasureKeys([]);
    setFilterBy([]);
    setGroupBy([]);
    setSortBy([]);
    setFilterJoinOperator(FilterJoinOperator.And);
    setGroupAggregationOperator(GroupAggregationOperator.Count);
    addedMeasureKeyHashes.current = [];
  };

  const handleLoadFromReportTemplateConfig = (reportTemplateConfig: ReportTemplateMeasuresConfig) => {
    handleReset();
    setComponentId(reportTemplateConfig.component_id);
    setSelectedMeasureKeys(reportTemplateConfig.selected_measure_keys ?? []);
    setFilterBy(reportTemplateConfig.filter_by ?? []);
    setGroupBy(reportTemplateConfig.group_by ?? []);
    setSortBy(reportTemplateConfig.sort_by ?? []);
    setFilterJoinOperator(reportTemplateConfig.filter_join_operator ?? FilterJoinOperator.And);
    setGroupAggregationOperator(reportTemplateConfig.group_aggregation_operator ?? GroupAggregationOperator.Count);
    if (
      reportTemplateConfig.last_updated_date_range_filter &&
      typeof reportTemplateConfig.last_updated_date_range_filter.from === "string" &&
      typeof reportTemplateConfig.last_updated_date_range_filter.to === "string"
    ) {
      const dateRange: DateRange = {
        from: new Date(reportTemplateConfig.last_updated_date_range_filter.from),
        to: new Date(reportTemplateConfig.last_updated_date_range_filter.to),
      };
      setLastUpdatedDateRangeFilter(dateRange);
    }
  };

  // ------ Data Fetching ------

  const addMeasuresData = async (keys: MeasureKey[]) => {
    // Create a list of mappings for each new measure key to add
    // Mapping takes the form of { uniqueIdentifierId: MeasureValue[] }
    // Note that the uniqueIdentifierId can also be any descendant unique identifier of any root unique identifier
    setIsLoading(true);
    const newMeasureValuesSets = await Promise.all(
      keys.map(async (key) => {
        const newValues = await fetchAllMeasureValuesMappedByUniqueIdentifier(key);
        return newValues;
      }),
    );
    if (keys.length === 0 || newMeasureValuesSets.length === 0) {
      setIsLoading(false);
      return;
    }
    setUniqueIdentifiersWithMeasures((uniqueIdentifiers) => {
      // First iterate through each unique identifier and find all linked unique identifiers in the genealogy
      uniqueIdentifiers.forEach((uniqueIdentifier) => {
        // then iterate through the sets of new measure values and group together the values for each linked unique identifier
        newMeasureValuesSets.forEach((newMeasureValues, index) => {
          const key = keys[index];
          const valuesByIdentifier: { [uniqueIdentifierId: string]: MeasureValuesWithAggregation } = {};
          uniqueIdentifier.genealogy.uniqueIdentifiers.forEach((linkedUniqueIdentifier) => {
            const values = (newMeasureValues[linkedUniqueIdentifier.id] ?? []).sort(
              (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
            );
            if (values.length !== 0) {
              const aggregation = aggregateMeasureValues(values, key, key.aggregation_operator ?? MeasureAggregationOperator.Latest);
              valuesByIdentifier[linkedUniqueIdentifier.id] = {
                values,
                aggregation,
              };
            }
          });
          uniqueIdentifier.measures.push({
            key,
            valuesByIdentifier,
          });
        });
        newMeasureValuesSets.forEach((newMeasureValues, index) => {
          // We need to do a quick recalculation of the aggregation for each measure value set with a time operator
          // This is because the time operator relies on having all the values present when doing the filtration
          const key = keys[index];
          if (key.time_operator) {
            const valuesByIdentifier: { [uniqueIdentifierId: string]: MeasureValuesWithAggregation } = {};
            uniqueIdentifier.genealogy.uniqueIdentifiers.forEach((linkedUniqueIdentifier) => {
              const values = newMeasureValues[linkedUniqueIdentifier.id] ?? [];
              if (values.length !== 0) {
                const timeFilteredValues = filterMeasureValuesByTimeOperator(values, key.time_operator, uniqueIdentifiers);
                const aggregation = aggregateMeasureValues(
                  timeFilteredValues,
                  key,
                  key.aggregation_operator ?? MeasureAggregationOperator.Latest,
                );
                valuesByIdentifier[linkedUniqueIdentifier.id] = {
                  values,
                  aggregation,
                };
              }
            });
            uniqueIdentifier.measures.push({
              key,
              valuesByIdentifier,
            });
          }
        });
      });
    });
    setIsLoading(false);
  };

  // ------ Memoized Derived State ------

  const availableMeasureKeys: MeasureKey[] = useMemo(() => {
    if (!componentId) return [];
    return getAvailableMeasureKeys(componentId, {
      componentLinks,
      componentProcessLinks,
      partNumbers,
      datasets,
      workOrders,
    });
  }, [componentId, componentLinks, componentProcessLinks, partNumbers, datasets, workOrders]);

  const identifierMeasureKeys = useMemo(() => {
    const newIdentifierKeys: MeasureKey[] = [];
    if (!componentId) return newIdentifierKeys;

    // define recursive function
    const addIdentifierKeysForComponentChildren = (componentId: string) => {
      const componentChildren = componentLinks.filter((link) => link.component_id === componentId);
      componentChildren.forEach((link) => {
        newIdentifierKeys.push({
          type: MeasureType.Identifier,
          component_id: link.has_child_of_id,
        });
        addIdentifierKeysForComponentChildren(link.has_child_of_id);
      });
    };

    // run recursion
    newIdentifierKeys.push({
      type: MeasureType.Identifier,
      component_id: componentId,
    });
    addIdentifierKeysForComponentChildren(componentId);
    return newIdentifierKeys;
  }, [componentId, componentLinks]);

  const groups: MeasureGroup[] = useMemo(() => {
    return [];
  }, []);

  const filterMapping: { [rootUniqueIdentifierId: string]: boolean } = useMemo(() => {
    const fullyDefinedFilters = filterBy.filter((f) => f.value !== "");
    // iterate through uniqueIdentifiers and use testFilter() to determine if each unique identifier passes the filter conditions
    return uniqueIdentifiersWithMeasures.reduce<{ [rootUniqueIdentifierId: string]: boolean }>((acc, uniqueIdentifier) => {
      // general filter
      const generalFilterResult = testFilter(fullyDefinedFilters, filterJoinOperator, uniqueIdentifier);
      // last updated at date range filter
      const lastUpdatedTime = new Date(uniqueIdentifier.last_updated_at).getTime();
      const dateRangeFrom = lastUpdatedDateRangeFilter?.from?.getTime();
      const dateRangeTo = lastUpdatedDateRangeFilter?.to?.getTime();
      const dateRangeFilterResult =
        lastUpdatedTime && dateRangeFrom && dateRangeTo ? lastUpdatedTime >= dateRangeFrom && lastUpdatedTime <= dateRangeTo : true;
      // combine general and date range filter results
      acc[uniqueIdentifier.id] = generalFilterResult && dateRangeFilterResult;
      return acc;
    }, {});
  }, [uniqueIdentifiersWithMeasures, filterBy, filterJoinOperator, lastUpdatedDateRangeFilter]);

  const sortMapping: { [rootUniqueIdentifierId: string]: number } = useMemo(() => {
    const sortedUniqueIdentifiers = [...uniqueIdentifiersWithMeasures].sort((a, b) => {
      for (const sort of sortBy) {
        const key = sort.key;
        const aValue = a.measures.find((m) => hashMeasureKey(m.key) === hashMeasureKey(key))?.valuesByIdentifier?.[a.id]?.aggregation
          ?.value;
        const bValue = b.measures.find((m) => hashMeasureKey(m.key) === hashMeasureKey(key))?.valuesByIdentifier?.[b.id]?.aggregation
          ?.value;
        if (!bValue) return -1;
        if (!aValue || !bValue) continue;
        if (aValue === bValue) continue;
        if ([MeasureType.CycleTime, MeasureType.ParametricQuantitative].includes(key.type)) {
          if (sort.ascending) {
            return parseFloat(aValue) - parseFloat(bValue);
          } else {
            return parseFloat(bValue) - parseFloat(aValue);
          }
        }
        if (sort.ascending) {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      }
      return 0;
    });
    return sortedUniqueIdentifiers.reduce<{ [rootUniqueIdentifierId: string]: number }>((acc, uniqueIdentifier, index) => {
      acc[uniqueIdentifier.id] = index;
      return acc;
    }, {});
  }, [uniqueIdentifiersWithMeasures, sortBy]);

  const groupMapping: { [rootUniqueIdentifierId: string]: string } = useMemo(() => {
    return {};
  }, []);

  const numFullyDefinedFilters = useMemo(() => {
    return filterBy.filter((filter) => filter.value !== "").length;
  }, [filterBy]);

  const numFilteredUniqueIdentifiers = useMemo(() => {
    return Object.values(filterMapping).filter((result) => result).length;
  }, [filterMapping]);

  const reportTemplateConfig = useMemo(() => {
    if (!componentId) return null;
    const reportTemplateConfig: ReportTemplateMeasuresConfig = {
      component_id: componentId,
      selected_measure_keys: selectedMeasureKeys,
      filter_by: filterBy,
      group_by: groupBy,
      sort_by: sortBy,
      filter_join_operator: filterJoinOperator,
      group_aggregation_operator: groupAggregationOperator,
      last_updated_date_range_filter: lastUpdatedDateRangeFilter,
    };
    return reportTemplateConfig;
  }, [
    componentId,
    selectedMeasureKeys,
    filterBy,
    groupBy,
    sortBy,
    filterJoinOperator,
    groupAggregationOperator,
    lastUpdatedDateRangeFilter,
  ]);

  // ------ Effects ------

  useEffect(() => {
    if (!componentId) return;
    setUniqueIdentifierGenealogiesLoaded(false);
    handleReset();
    setIsLoading(true);
    fetchUniqueIdentifiersWithGenealogies(componentId).then((uniqueIdentifiers) => {
      setUniqueIdentifiersWithMeasures(uniqueIdentifiers);
      setIsLoading(false);
      setUniqueIdentifierGenealogiesLoaded(true);
    });
  }, [componentId]);

  useEffect(() => {
    if (!uniqueIdentifierGenealogiesLoaded) return;
    const measureKeysToAdd = [
      ...identifierMeasureKeys,
      ...selectedMeasureKeys,
      ...filterBy.map((f) => f.key),
      ...sortBy.map((s) => s.key),
      ...groupBy,
    ].filter((measureKey) => {
      const hash = hashMeasureKey(measureKey);
      return !addedMeasureKeyHashes.current.includes(hash);
    });
    addMeasuresData(measureKeysToAdd);
    addedMeasureKeyHashes.current.push(...measureKeysToAdd.map(hashMeasureKey));
  }, [selectedMeasureKeys, filterBy, sortBy, groupBy, identifierMeasureKeys, uniqueIdentifierGenealogiesLoaded]);

  useEffect(() => {
    setComponentId(props.componentId ?? null);
    if (props.filterBy) setFilterBy(props.filterBy);
    if (props.groupBy) setGroupBy(props.groupBy);
    if (props.sortBy) setSortBy(props.sortBy);
    if (props.filterJoinOperator) setFilterJoinOperator(props.filterJoinOperator);
    if (props.groupAggregationOperator) setGroupAggregationOperator(props.groupAggregationOperator);
  }, [props]);

  // update aggregation if aggregation operator changes
  useEffect(() => {
    if (selectedMeasureKeys.length === 0) return;
    const measureKeysToUpdate = selectedMeasureKeys.filter((key) => key.aggregation_operator);
    setUniqueIdentifiersWithMeasures((uniqueIdentifiers) => {
      measureKeysToUpdate.forEach((key) => {
        uniqueIdentifiers.forEach((uniqueIdentifier) => {
          uniqueIdentifier.measures.forEach((measure) => {
            if (hashMeasureKey(measure.key) === hashMeasureKey(key)) {
              Object.values(measure.valuesByIdentifier).forEach((valuesWithAgg) => {
                if (valuesWithAgg.values.length === 0) return;
                const timeFilteredValues = filterMeasureValuesByTimeOperator(valuesWithAgg.values, key.time_operator, uniqueIdentifiers);
                valuesWithAgg.aggregation = aggregateMeasureValues(
                  timeFilteredValues,
                  key,
                  key.aggregation_operator ?? MeasureAggregationOperator.Latest,
                );
              });
            }
          });
        });
      });
    });
  }, [selectedMeasureKeys]);

  return (
    <MeasuresContext.Provider
      value={{
        // General shared state
        gridApi,
        setGridApi,
        fileUrls,
        setFileUrls,
        // Input state
        componentId,
        setComponentId,
        selectedMeasureKeys,
        setSelectedMeasureKeys,
        filterBy,
        setFilterBy,
        groupBy,
        setGroupBy,
        sortBy,
        setSortBy,
        filterJoinOperator,
        setFilterJoinOperator,
        groupAggregationOperator,
        setGroupAggregationOperator,
        lastUpdatedDateRangeFilter,
        setLastUpdatedDateRangeFilter,
        // Output state
        isLoading,
        availableMeasureKeys,
        identifierMeasureKeys,
        uniqueIdentifiers: uniqueIdentifiersWithMeasures,
        groups,
        filterMapping,
        sortMapping,
        groupMapping,
        numFullyDefinedFilters,
        numFilteredUniqueIdentifiers,
        reportTemplateConfig,
        // Methods
        handleReset,
        handleAddSelectedKey,
        handleRemoveSelectedKey,
        handleUpdateKeyAggregationOperator,
        handleUpdateKeyFormatting,
        handleUpdateKeyTimeOperator,
        handleRemoveKeyTimeOperator,
        handleSetFilterConditionValue,
        handleSetFilterConditionOperator,
        handleRemoveFilterCondition,
        handleAddFilterCondition,
        handlePromoteSortCondition,
        handleRemoveSortCondition,
        handleLoadFromReportTemplateConfig,
      }}
    >
      {props.children}
    </MeasuresContext.Provider>
  );
};

// Hook for consuming context
export const useMeasures = () => {
  const context = useContext(MeasuresContext);
  if (!context) {
    throw new Error("useMeasures must be used within a MeasuresProvider");
  }
  return context;
};

export default useMeasures;
