import { Dataset, Process } from "@shared/types/databaseTypes";

export enum ComponentPageSidebar {
  SnList = "SN_LIST",
  ProcessSequence = "PROCESS_SEQUENCE",
}

export enum ComponentPageModal {
  ComponentSettings = "SETTINGS",
  ProcessBuilder = "PROCESS_BUILDER",
  PartNumbers = "PART_NUMBERS",
  Labels = "LABELS",
}

export interface ProcessWithOrderActivityDatasets extends Process {
  order: number;
  is_active: boolean;
  datasets: Dataset[];
}
