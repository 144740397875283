import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { Dataset } from "@shared/types/databaseTypes";

interface DebugSupabaseInProps {}

const DebugSupabaseIn: React.FC<DebugSupabaseInProps> = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const db = useSelector((state: RootState) => state.db);
  const [arrayLength, setArrayLength] = React.useState(100);
  const { triggerToast } = useContext(ToastContext);

  const handleRunSanityCheck = async () => {
    const supabase = getSupabase(token);
    const datasetIds = db.datasets.map((dataset: Dataset) => dataset.id);
    const { data: datasets, error: datasetsError } = await supabase.from("datasets").select("*").in("id", datasetIds.slice(0, arrayLength));
    if (datasetsError) {
      console.log(datasetsError);
      triggerToast(
        ToastType.Error,
        "Debug Page",
        "Error running running supabase query containing .in() with an array of length n. Check console for details.",
      );
    } else {
      console.log(datasets);
      triggerToast(
        ToastType.Success,
        "Debug Page",
        "Success running running supabase query containing .in() with an array of length n. Check console for details.",
      );
    }
  };

  return (
    <div className="flex w-full items-center justify-between">
      <h1 className="text-lg">
        <span className="font-bold">{`Supabase .in() Sanity Check`}</span>
        {` Runs a supabase query containing .in() with an array of length n: `}
      </h1>
      <input className="form-input w-36" type="number" defaultValue={100} onChange={(e) => setArrayLength(parseInt(e.target.value))} />
      <button className="btn w-36 bg-black text-white" onClick={() => handleRunSanityCheck()}>
        Run Query
      </button>
    </div>
  );
};

export default DebugSupabaseIn;
