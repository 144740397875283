import { ProcessType } from "@shared/types/databaseEnums";
import { ComponentLink, ComponentProcessLink, Dataset, PartNumber, WorkOrder } from "@shared/types/databaseTypes";
import { MeasureKey, MeasureType } from "../types";
import { datasetTypeToMeasureType } from "../constants/enumMapping";

export interface ComponentFamilyMember {
  id: string;
  ancestryLevel: number; // 0 is the root component, 1 is the first child, etc.
}

// A helper function that recursively finds all descendants of a given root component.
export function getComponentDescendents(
  componentLinks: ComponentLink[],
  rootComponentId: string,
  ancestryLevel: number = 0,
): ComponentFamilyMember[] {
  let familyMembers: ComponentFamilyMember[] = [];
  const childrenLinks = componentLinks.filter((link) => link.component_id === rootComponentId);

  childrenLinks.forEach((link) => {
    familyMembers.push({ id: link.has_child_of_id, ancestryLevel: ancestryLevel + 1 });
    familyMembers = familyMembers.concat(getComponentDescendents(componentLinks, link.has_child_of_id, ancestryLevel + 1));
  });

  return familyMembers;
}

// A helper function that checks if a component has a parent
export function componentHasParent(componentLinks: ComponentLink[], componentId: string): boolean {
  const parentLinks = componentLinks.filter((link) => link.has_child_of_id === componentId);

  // If the component has parent links, return true.
  if (parentLinks.length > 0) {
    return true;
  }

  // If no parent links are found, return false.
  return false;
}

export const getAvailableMeasureKeys = (
  rootComponentId: string,
  db: {
    componentLinks: ComponentLink[];
    componentProcessLinks: ComponentProcessLink[];
    partNumbers: PartNumber[];
    datasets: Dataset[];
    workOrders: WorkOrder[];
  },
): MeasureKey[] => {
  // Find all componentProcessLinks related to the selected component
  const descendantComponents: ComponentFamilyMember[] = getComponentDescendents(db.componentLinks, rootComponentId);
  const descendantComponentIds: string[] = [...new Set([...descendantComponents.map((descendant) => descendant.id), rootComponentId])];
  const relatedComponentProcessLinks = db.componentProcessLinks.filter((link) => descendantComponentIds.includes(link.component_id));

  // Setup array to store all relevant measure keys
  const measureKeys: MeasureKey[] = [];

  // Loop through all related datasets and add them to the array if they related to any of the componentProcessLinks
  for (const dataset of db.datasets) {
    for (const link of relatedComponentProcessLinks) {
      if (dataset.process_id === link.process_id && link.component && link.process) {
        measureKeys.push({
          component_id: link.component_id,
          process_id: link.process_id,
          dataset_id: dataset.id,
          type: datasetTypeToMeasureType[dataset.data_type],
        });
      }
    }
  }

  // Loop through all related processes and add station, operator, timestamp, cycle time
  for (const link of relatedComponentProcessLinks) {
    if (link.process?.type === ProcessType.Production && link.process && link.component) {
      for (const keyType of [MeasureType.Station, MeasureType.Operator, MeasureType.Timestamp, MeasureType.CycleTime]) {
        measureKeys.push({
          process_id: link.process_id,
          component_id: link.component_id,
          dataset_id: undefined,
          type: keyType,
        });
      }
      // Add process result key
      measureKeys.push({
        process_id: link.process_id,
        component_id: link.component_id,
        dataset_id: undefined,
        type: MeasureType.ProcessResult,
      });
    }
  }

  // Loop through all related components to add part numbers and status
  for (const descendantComponentId of descendantComponentIds) {
    // Part Numbers
    if (db.partNumbers.some((partNumber) => partNumber.component_id === descendantComponentId)) {
      // find instantiation process for this component
      const instantiationProcess = db.componentProcessLinks.find(
        (link) => link.component_id === link.component?.id && link.process?.type === ProcessType.Instantiation,
      )?.process;
      measureKeys.push({
        component_id: descendantComponentId,
        process_id: instantiationProcess?.id,
        dataset_id: undefined,
        type: MeasureType.PartNumber,
      });
    }
    // Status
    measureKeys.push({
      component_id: descendantComponentId,
      process_id: undefined,
      dataset_id: undefined,
      type: MeasureType.Status,
    });
    // Identifier
    if (descendantComponentId !== rootComponentId) {
      measureKeys.push({
        component_id: descendantComponentId,
        process_id: undefined,
        dataset_id: undefined,
        type: MeasureType.Identifier,
      });
    }
  }

  // Add Link Status key for the root component only
  if (componentHasParent(db.componentLinks, rootComponentId)) {
    measureKeys.push({
      component_id: rootComponentId,
      process_id: undefined,
      dataset_id: undefined,
      type: MeasureType.LinkStatus,
    });
  }

  // Check if any work orders are assigned to the component, if so add work order name and phase keys
  if (db.workOrders.some((workOrder) => workOrder.component_id === rootComponentId)) {
    measureKeys.push({
      component_id: rootComponentId,
      process_id: undefined,
      dataset_id: undefined,
      type: MeasureType.WorkOrder,
    });
    measureKeys.push({
      component_id: rootComponentId,
      process_id: undefined,
      dataset_id: undefined,
      type: MeasureType.WorkOrderPhase,
    });
  }

  return measureKeys;
};
