import React, { useState } from "react";
import { Image } from "@shared/types/databaseTypes";
import ModalBasic from "@shared/components/modals/ModalBasic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareRight, faCaretSquareLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";

interface ImageGalleryProps {
  images: Image[];
}

const SnLookUpImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0); // key is image id, value is image url

  const openModal = (index: number) => {
    setActiveImageIndex(index);
    setModalOpen(true);
  };

  const handleNextImage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveImageIndex((activeImageIndex + 1) % images.length);
  };

  const handlePreviousImage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveImageIndex((activeImageIndex - 1 + images.length) % images.length);
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-4 border-t p-4 md:grid-cols-2 xl:grid-cols-3">
        {images
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
          .map((image, index) => (
            <div
              key={index}
              className="bg-serial-palette-100 group relative overflow-hidden rounded border shadow-sm"
              style={{ height: "300px" }}
            >
              <div className="absolute inset-0">
                <img
                  src={image.url ?? undefined}
                  alt={image.file_name}
                  className="h-full w-full hover:cursor-pointer"
                  style={{ objectFit: "cover" }}
                  onClick={(event) => (openModal(index), event.stopPropagation())}
                />{" "}
              </div>
              <div className="absolute bottom-0 flex w-full flex-col truncate border-t bg-white px-2 py-1 text-sm">
                <div className="truncate font-medium">{image.dataset?.name}</div>
                <div className="truncate font-mono text-xs">{image.file_name}</div>
              </div>

              <button
                className="serial-btn-light btn btn-xs absolute right-1.5 top-1.5 hidden gap-1.5 text-xs group-hover:flex"
                onClick={() =>
                  fetchDownloadUrl(image.bucket_name, image.file_id ?? image.id, image.company_id, false, true, image.file_name)
                }
              >
                <span>Download</span>
                <FontAwesomeIcon icon={faDownload} />
              </button>
            </div>
          ))}

        <ModalBasic
          id="imageGalleryModal"
          classOverride="flex flex-col overflow-hidden w-[60vw] h-[80vh] relative"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={
            <span>
              {images[activeImageIndex] && (
                <span className="text-serial-palette-600 pl-2 font-normal">{images[activeImageIndex].file_name}</span>
              )}
            </span>
          }
        >
          <div className="relative h-full w-full overflow-hidden rounded border-b">
            {images && (
              <img
                src={images[activeImageIndex].url || ""}
                alt={images[activeImageIndex].file_name || ""}
                className="h-full w-full object-contain" // Changed to object-contain
                style={{ maxHeight: "80vh", maxWidth: "100%" }} // Ensures the image does not exceed the modal's dimensions
              />
            )}
            <button
              className="text-serial-palette-200 hover:text-serial-palette-300 absolute left-0 top-1/2 z-10 mx-4 -translate-y-1/2 transform"
              onClick={(e) => handlePreviousImage(e)}
            >
              <FontAwesomeIcon icon={faCaretSquareLeft} size="3x" />
            </button>
            <button
              className="text-serial-palette-200 hover:text-serial-palette-300 absolute right-0 top-1/2 z-10 mx-3 -translate-y-1/2 transform"
              onClick={(e) => handleNextImage(e)}
            >
              <FontAwesomeIcon icon={faCaretSquareRight} size="3x" />
            </button>
          </div>
        </ModalBasic>
      </div>
    </>
  );
};

export default SnLookUpImageGallery;
