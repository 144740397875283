import React, { useRef, useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions, TooltipCallbacks } from "chart.js";
import { Chart } from "react-chartjs-2";
import { tailwindConfig } from "@shared/utils/helpers";
import { cn } from "@shared/utils/tailwind";
import { withDefaultSizing } from "./helpers";

ChartJS.register(ArcElement, Tooltip, Legend);

export interface DonutChartDataPoint {
  id: string;
  label: string;
  value: number;
  color?: string;
}

export interface DonutChartProps {
  data: DonutChartDataPoint[];
  size?: "sm" | "md" | "lg";
  legend?: boolean;
  tooltip?: boolean | Partial<TooltipCallbacks<"doughnut">>;
  className?: string;
}

const DonutChart: React.FC<DonutChartProps> = ({ data, size = "sm", legend = true, tooltip = true, className }) => {
  const chartRef = useRef<ChartJS<"doughnut">>(null);

  const { chartData, chartOptions } = useMemo(() => {
    const chartData: ChartData<"doughnut"> = {
      labels: data.map((item) => item.label),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: data.map((item) => item.color ?? tailwindConfig().theme?.colors?.blue?.[500]),
          hoverBackgroundColor: data.map((item) => item.color ?? tailwindConfig().theme?.colors?.blue?.[700]),
        },
      ],
    };

    const chartOptions: ChartOptions<"doughnut"> = {
      plugins: {
        tooltip: {
          enabled: tooltip !== false && tooltip !== undefined,
          callbacks:
            tooltip === false
              ? undefined
              : tooltip === true
                ? {
                    title: (context) => data[context[0].dataIndex].label,
                    label: (context) => data[context.dataIndex].value.toFixed(1),
                  }
                : tooltip,
        },
        legend: {
          display: legend,
          position: "bottom",
          labels: {
            boxHeight: 15,
            boxWidth: 15,
          },
        },
      },
      maintainAspectRatio: false,
      cutout: "70%",
    };

    return { chartData, chartOptions: withDefaultSizing("doughnut", chartOptions, size) };
  }, [data, legend, tooltip]);

  return (
    <div className={cn("relative min-h-0 min-w-0 flex-grow", className)}>
      <Chart ref={chartRef} type="doughnut" data={chartData} options={chartOptions} />
    </div>
  );
};

export default DonutChart;
