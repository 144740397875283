import Popover from "@shared/components/primitives/Popover";
import Section from "./MeasuresGraphConfigSection";
import Button from "@shared/components/primitives/Button";
import MeasureKeySelector from "../MeasureKeySelector";
import { useMeasuresGraph } from "./MeasuresGraph";
import Select from "@shared/components/primitives/Select";
import {
  GroupAggregationOperator,
  MeasureAggregationOperator,
  allowedGroupAggregationOperators,
  allowedMeasureAggregationOperators,
} from "@shared/measures/types";
import TagBasic from "@shared/components/primitives/TagBasic";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { getMeasureName } from "@shared/measures/helpers/naming";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import useMeasures from "@shared/measures/MeasuresProvider";
import { graphConfigurations } from "./types";
import { faChevronDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@shared/components/primitives/Tooltip";
import MeasuresGraphConfigRetestAggregationTooltip from "./MeasuresGraphConfigRetestAggregationTooltip.tsx";

const MeasuresGraphConfigCategories = () => {
  const [measuresPopoverOpen, setMeasuresPopoverOpen] = useState<boolean>(false);

  const { setCategoriesMeasureKey, categoriesMeasureKey, graphType, showRetests } = useMeasuresGraph();
  const { groupAggregationOperator, setGroupAggregationOperator } = useMeasures();
  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const selectedMeasureName = useMemo(() => {
    if (!categoriesMeasureKey) {
      return "";
    }
    return getMeasureName(categoriesMeasureKey, {
      components,
      processes,
      datasets,
    });
  }, [categoriesMeasureKey, components, processes, datasets]);

  useEffect(() => {
    if (!groupAggregationOperator) {
      setGroupAggregationOperator(GroupAggregationOperator.Count);
    }
  }, [groupAggregationOperator, setGroupAggregationOperator]);

  return (
    <Section.Root>
      <Section.Title>Categories</Section.Title>
      <Section.Option>
        <Section.Label>Measure</Section.Label>
        <Popover.Root open={measuresPopoverOpen} onOpenChange={setMeasuresPopoverOpen}>
          <Popover.Trigger asChild>
            <Button variant="outline" className="justify-between pl-2.5" onClick={() => setMeasuresPopoverOpen(true)}>
              {categoriesMeasureKey ? (
                <>
                  <div className="flex items-center justify-start gap-1.5 overflow-hidden">
                    <TagBasic variant={categoriesMeasureKey.type} size="xs" />
                    <span className="truncate">{selectedMeasureName}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </>
              ) : (
                <span className="text-serial-palette-400 font-light">Select Measure...</span>
              )}
            </Button>
          </Popover.Trigger>
          <Popover.Content align="start" className="w-[560px] rounded-md border shadow-lg">
            {graphType && (
              <MeasureKeySelector
                onSelect={(measureKey) => {
                  setCategoriesMeasureKey({ ...measureKey, aggregation_operator: MeasureAggregationOperator.Latest });
                  setMeasuresPopoverOpen(false);
                }}
                keyTypes={graphConfigurations[graphType].categoriesAllowedMeasures ?? undefined}
              />
            )}
          </Popover.Content>
        </Popover.Root>
      </Section.Option>
      {!showRetests && (
        <Section.Option>
          <Section.Label className="flex items-center gap-1">
            <span>Retest Aggregation</span>
            <Tooltip.Root>
              <Tooltip.Trigger>
                <FontAwesomeIcon icon={faInfoCircle} className="text-gray-500" />
              </Tooltip.Trigger>
              <Tooltip.Content variant="light" align="start" className="flex w-72 flex-col gap-1">
                <MeasuresGraphConfigRetestAggregationTooltip />
              </Tooltip.Content>
            </Tooltip.Root>
          </Section.Label>
          <Select.Root
            value={categoriesMeasureKey?.aggregation_operator}
            onValueChange={(aggregationOperator) =>
              categoriesMeasureKey &&
              setCategoriesMeasureKey({
                ...categoriesMeasureKey,
                aggregation_operator: aggregationOperator as MeasureAggregationOperator,
              })
            }
          >
            <Select.Trigger disabled={!categoriesMeasureKey}>
              {screamingSnakeCaseToTitleCase(categoriesMeasureKey?.aggregation_operator ?? "None")}
            </Select.Trigger>
            <Select.Content>
              {categoriesMeasureKey &&
                allowedMeasureAggregationOperators[categoriesMeasureKey?.type]?.map((operator, index) => (
                  <Select.Item key={index} value={operator}>
                    {screamingSnakeCaseToTitleCase(operator)}
                  </Select.Item>
                ))}
            </Select.Content>
          </Select.Root>
        </Section.Option>
      )}
      <Section.Option>
        <Section.Label>Group Aggregation</Section.Label>
        <Select.Root
          value={groupAggregationOperator}
          onValueChange={(aggregationOperator) => setGroupAggregationOperator(aggregationOperator as GroupAggregationOperator)}
        >
          <Select.Trigger disabled={!categoriesMeasureKey}>
            {screamingSnakeCaseToTitleCase(groupAggregationOperator ?? "None")}
          </Select.Trigger>
          <Select.Content>
            {categoriesMeasureKey &&
              allowedGroupAggregationOperators[categoriesMeasureKey?.type]?.map((operator, index) => (
                <Select.Item key={index} value={operator}>
                  {screamingSnakeCaseToTitleCase(operator)}
                </Select.Item>
              ))}
          </Select.Content>
        </Select.Root>
      </Section.Option>
    </Section.Root>
  );
};
export default MeasuresGraphConfigCategories;
