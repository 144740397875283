import React, { useEffect, useState, useMemo, useContext } from "react";
import Transition from "@shared/components/Transition";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import SnLookupPrint from "./SnLookupPrint";
import JSZip from "jszip";
import { Loader } from "@shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { getBase64ImageFromUrl } from "@shared/utils/helpers";
import useObserve from "@shared/hooks/useObserve";
import { useSelector } from "react-redux";
import Select from "@shared/components/primitives/Select";
import { SnLookupContext } from "../../SnLookupProvider";

function ExportDataModal() {
  const {
    printModalOpen,
    setPrintModalOpen,
    genealogy,
    uniqueIdentifier,
    company,
    loadAllFilesAndImagesHighQuality,
    processTableIsLoading,
    processEntries,
  } = useContext(SnLookupContext);

  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const [pdfOptions, setPdfOptions] = useState({ imagesPerRow: 3 });
  const [processFilter, setProcessFilter] = useState("lastOnly");
  const [zipIsLoading, setZipIsLoading] = useState(false);
  const [pdfDocumentUrl, setPdfDocumentUrl] = useState(null);
  const observe = useObserve();
  const role = useSelector((state) => state.auth.role);

  const updateFilteredProcessEntries = (processEntries, processFilter) => {
    // Remove part number data for read only users
    if (role === "READONLY") {
      processEntries = processEntries.filter((entry) => entry.process?.type !== "INSTANTIATION");
    }

    if (processEntries.length > 0) {
      if (processFilter === "allData") {
        return processEntries;
      }

      // Sort the array by timestamp and get first and last occurrences of each process_id
      const sortedData = processEntries.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      const firstOccurrences = {};
      const lastOccurrences = {};
      sortedData.forEach((item) => {
        // Create a composite key of process_id and unique_identifier_id to enable checking for duplicate runs of the same process on the same unit
        const compositeKey = `${item.process_id}_${item.unique_identifier_id}`;
        if (!lastOccurrences.hasOwnProperty(compositeKey)) {
          lastOccurrences[compositeKey] = item;
        }
        firstOccurrences[compositeKey] = item;
      });
      // Convert the firstOccurrences and lastOccurrences objects to arrays
      const firstOccurrencesArray = Object.values(firstOccurrences);
      const lastOccurrencesArray = Object.values(lastOccurrences);

      // Set the filteredProcessEntries based on the filter options
      if (processFilter === "firstOnly") {
        return firstOccurrencesArray;
      } else if (processFilter === "lastOnly") {
        return lastOccurrencesArray;
      }
    }
  };

  // filter the processEntries array based on the processFilter such that the pdf renders properly
  const filteredProcessEntries = useMemo(() => {
    return updateFilteredProcessEntries(processEntries, processFilter);
  }, [processEntries, processFilter]);

  const generatePdf = async () => {
    setPdfIsLoading(true);
    const pdfComponent = (
      <SnLookupPrint
        genealogy={genealogy}
        uniqueIdentifierData={uniqueIdentifier}
        processEntries={filteredProcessEntries}
        company={company}
        pdfOptions={pdfOptions}
        setPdfIsLoading={setPdfIsLoading}
        getBase64ImageFromUrl={getBase64ImageFromUrl}
      />
    );
    const blob = await pdf(pdfComponent).toBlob();
    let url = URL.createObjectURL(blob);
    setPdfIsLoading(false);
    return url;
  };

  useEffect(() => {
    if (!zipIsLoading && !processTableIsLoading && printModalOpen) {
      generatePdf().then((url) => {
        setPdfDocumentUrl(url);
      });
    }
  }, [zipIsLoading, processTableIsLoading, printModalOpen, filteredProcessEntries, pdfOptions, uniqueIdentifier, genealogy]);

  const getFilesList = (filteredProcessEntries) => {
    const files = filteredProcessEntries
      .map((item) => {
        let imageAndFileUrls = [];
        // Map images data
        if (item.imageData) {
          for (let image of item.imageData) {
            imageAndFileUrls.push({ url: image.url, file_name: image.file_name, timestamp: item.timestamp });
          }
        }
        // Map files data
        if (item.fileData) {
          for (let file of item.fileData) {
            imageAndFileUrls.push({ url: file.url, file_name: file.file_name });
          }
        }
        // filter out undefined values
        imageAndFileUrls = imageAndFileUrls.filter((item) => item.url);
        return imageAndFileUrls;
      })
      .flat();
    return files;
  };

  const createZipFile = async (blobData) => {
    const zip = new JSZip();
    // Fetch the content of each blob URL and add it to the zip
    const addBlobToZip = async (data, index) => {
      try {
        const response = await fetch(data.url);
        if (!response.ok) {
          console.error(`Error fetching file: ${data.url}. Status: ${response.status}`);
          return;
        }
        const blob = await response.blob();
        // Check if the fetched file is not an HTML file
        if (blob.type !== "text/html") {
          const timestamp = moment(data.timestamp).format("YYYY-MM-DD_HH-mm-ss");
          const fileName = data.file_name.split(".")[0]; // remove file extension
          const extension = data.file_name.split(".")[1]; // get file extension
          zip.file(`${(index + 1).toString().padStart(3, "0")}_${fileName}_${timestamp}.${extension}`, blob);
        } else {
          console.error(`Fetched file seems to be an HTML file: ${data.url}`);
        }
      } catch (error) {
        console.error(`Error fetching file: ${data.url}.`, error);
      }
    };

    // Wait for all the blob URLs to be fetched and added to the zip
    await Promise.all(blobData.map(addBlobToZip));

    // Generate the final zip blob
    const zipBlob = await zip.generateAsync({ type: "blob" });

    return zipBlob;
  };

  const handleDownloadFiles = async () => {
    setZipIsLoading(true);
    const processEntriesWithAllFiles = await loadAllFilesAndImagesHighQuality();
    const filteredProcessEntriesWithAllFiles = updateFilteredProcessEntries(processEntriesWithAllFiles, processFilter);
    const filesList = getFilesList(filteredProcessEntriesWithAllFiles);
    try {
      const blob = await createZipFile(filesList);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Production Files (${uniqueIdentifier?.identifier}) ${moment().format("YYYY-MM-DD_HH-mm-ss")}.zip`;
      link.click();
      setZipIsLoading(false);
    } catch (error) {
      console.error("Error creating zip file:", error);
      setZipIsLoading(false);
    }
  };

  const handleClose = () => {
    setPrintModalOpen(false);
  };

  const handleDownloadPdf = () => {
    observe.track("sn-lookup_download_pdf");
    const link = document.createElement("a");
    link.href = pdfDocumentUrl;
    link.download = `Production Record (${uniqueIdentifier?.identifier}) ${moment().format("YYYY-MM-DD_HH-mm-ss")}.pdf`;
    link.click();
  };

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={printModalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id="override-modal"
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={printModalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div className="max-w-8xl h-[95%] max-h-full w-full overflow-auto rounded border bg-white">
          {/* Modal Formatting */}
          <div className="border-serial-palette-200 h-full border-b">
            {/* Header */}
            <div className="flex h-12 items-center justify-between px-5 py-3">
              <div className="text-serial-palette-800 font-semibold">Export Data</div>
              <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={() => handleClose()}>
                <div className="sr-only">Close</div>
                <svg className="h-4 w-4 fill-current">
                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                </svg>
              </button>
            </div>

            {/* Divider */}
            <div className="border-serial-palette-200 border-t"></div>

            {/* Body */}
            <div className="flex h-[calc(100%-116px)] w-full flex-col py-5 lg:flex-row">
              <div className="flex w-full flex-col gap-4 px-5 pb-6 lg:w-2/5">
                <div className="inline-flex w-auto items-center space-x-3">
                  <label className="mb-1 block text-sm font-medium" htmlFor="first_name">
                    {" "}
                    Images Per Row{" "}
                  </label>
                  <Select.Root
                    value={pdfOptions.imagesPerRow.toString()}
                    onValueChange={(value) => setPdfOptions({ ...pdfOptions, imagesPerRow: value })}
                  >
                    <Select.Trigger id="data_type">
                      <Select.Value />
                    </Select.Trigger>
                    <Select.Content>
                      <Select.Item key="1" value="1">
                        1
                      </Select.Item>
                      <Select.Item key="2" value="2">
                        2
                      </Select.Item>
                      <Select.Item key="3" value="3">
                        3
                      </Select.Item>
                      <Select.Item key="4" value="4">
                        4
                      </Select.Item>
                    </Select.Content>
                  </Select.Root>
                </div>

                <div className="inline-flex w-auto items-center space-x-3">
                  <label className="mb-1 block text-sm font-medium" htmlFor="first_name">
                    {" "}
                    Process Entry Filter{" "}
                  </label>
                  <div className="flex flex-nowrap -space-x-px">
                    {/*

                    <button
                      className={`btn h-10 whitespace-nowrap px-3 ${processFilter === "allData" ? "bg-serial-palette-800 text-white" : "text-serial-palette-600 bg-white"} hover:bg-serial-palette-800 border-serial-palette-200 rounded-none first:rounded-l-md first:border-r-transparent last:rounded-r-md hover:text-white`}
                      onClick={() => setProcessFilter("allData")}
                    >
                      All Data
                    </button>
                    <button
                      className={`btn h-10 whitespace-nowrap px-3 ${processFilter === "firstOnly" ? "bg-serial-palette-800 text-white" : "text-serial-palette-600 bg-white"} hover:bg-serial-palette-800 border-serial-palette-200 rounded-none first:rounded-l-md first:border-r-transparent last:rounded-r-md hover:text-white`}
                      onClick={() => setProcessFilter("firstOnly")}
                    >
                      First Only
                    </button>
                    */}
                    <button
                      className={`btn h-10 whitespace-nowrap px-3 ${processFilter === "lastOnly" ? "bg-serial-palette-800 text-white" : "text-serial-palette-600 bg-white"} hover:bg-serial-palette-800 border-serial-palette-200 rounded-none first:rounded-l-md first:border-r-transparent last:rounded-r-md hover:text-white`}
                      onClick={() => setProcessFilter("lastOnly")}
                    >
                      Last Only
                    </button>
                  </div>
                </div>
                {pdfIsLoading && (
                  <div className="flex items-center pt-4">
                    <Loader styleOverride="ml-2 mr-4 w-[24px] h-[24px]" />
                    <div className="flex w-full flex-col">
                      <p className="font-medium">Your PDF is being rendered, please wait. </p>
                      <p className="text-sm">This may take a some time for large datasets</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex h-full w-full px-5 lg:w-3/5">
                {pdfDocumentUrl && <iframe src={pdfDocumentUrl} width="100%" height="100%" />}
              </div>
            </div>

            {/* Divider */}
            <div className="border-serial-palette-200 border-t"></div>

            {/* Footer */}
            <div className="flex h-16 items-center justify-end gap-x-3 px-4">
              <button
                className="btn bg-serial-palette-800 hover:bg-serial-palette-600 h-10 whitespace-nowrap text-white"
                onClick={(e) => (
                  handleDownloadPdf(),
                  observe.track("Download Data", { "UI Source": "SnLookup - PDF", ...pdfOptions, processFilter: processFilter })
                )}
              >
                <FontAwesomeIcon icon={faDownload} />
                <span className="ml-2 hidden sm:block">Download PDF Summary</span>
              </button>

              <button
                className="btn bg-serial-palette-800 hover:bg-serial-palette-600 h-10 whitespace-nowrap text-white"
                onClick={(e) => (handleDownloadFiles(), observe.track("Download Data", { "UI Source": "SnLookup - Image & Files" }))}
              >
                {zipIsLoading ? (
                  <Loader styleOverride="mx-2 w-[18px] h-[18px] text-white" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faFileArchive} />
                    <span className="ml-2 hidden sm:block">Download Images & Files Only</span>
                  </>
                )}
              </button>

              <button
                className="btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600 h-10 whitespace-nowrap"
                onClick={(e) => handleClose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}

export default ExportDataModal;
