import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faUpload } from "@fortawesome/free-solid-svg-icons";
import DropdownFilter from "@shared/components/dropdowns/DropdownFilter";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { DataType } from "@shared/types/databaseEnums";
import TypeTag from "@shared/components/TypeTag";
import { PartNumberWithState } from "./PartNumbers";

interface PartNumberGridTopBarProps {
  addNewColumn: (type: DataType, name?: string) => string;
  handleShowCsvUpload: () => void;
  partNumbers: PartNumberWithState[];
  setFilteredPartNumberIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PartNumberDataOptions = [
  {
    name: "Parametric",
    type: DataType.ParametricQualitative,
  },
  {
    name: "File",
    type: DataType.File,
  },
  {
    name: "Image",
    type: DataType.Image,
  },
];

interface FilterOptions {
  [key: string]: boolean;
}

const PartNumberGridTopBar: React.FC<PartNumberGridTopBarProps> = ({
  addNewColumn,
  handleShowCsvUpload,
  partNumbers,
  setFilteredPartNumberIds,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({ Active: true, Inactive: false });

  const toggleFilterOption = (option: string) => {
    const modifiedOptions = { ...filterOptions };
    modifiedOptions[option] = !filterOptions[option];
    setFilterOptions(modifiedOptions);
  };

  // set filtered part number ids based on search term and filter options
  useEffect(() => {
    const filteredPartNumberIds = partNumbers
      .filter((partNumber) => (filterOptions["Active"] && partNumber.is_active) || (filterOptions["Inactive"] && !partNumber.is_active))
      .filter((partNumber) => {
        const partNumberMetadataFilterString = Object.keys(partNumber.metadata)
          .map((key) => partNumber.metadata[key].file_name ?? partNumber.metadata[key].value)
          .join(" ");
        const filterString = [partNumber.pn, partNumber.description, partNumberMetadataFilterString].join(" ");
        return filterString.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .map((partNumber) => partNumber.id);
    setFilteredPartNumberIds(filteredPartNumberIds);
  }, [searchTerm, filterOptions, partNumbers]);

  return (
    <div className="flex w-full gap-2">
      <div className="relative flex w-full gap-2">
        <input
          className="form-input w-full pl-8"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm || ""}
        ></input>
        <FontAwesomeIcon icon={faMagnifyingGlass} className="text-serial-palette-400 absolute left-3 top-1/2 -translate-y-1/2 transform" />
      </div>
      <DropdownFilter align="left" filterOptions={filterOptions} toggleFilterOption={toggleFilterOption} classOverride="" />
      <DropdownCustom
        align="left"
        buttonContents={
          <div className=" btn bg-serial-palette-800 hover:bg-serial-palette-600 right-2 top-2 z-10 h-10 whitespace-nowrap text-white">
            <svg className="h-4 w-4 shrink-0 fill-current" viewBox="0 0 16 16">
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="xs:block ml-2 hidden">Add Column</span>
          </div>
        }
        className=""
        dropdownClassName=""
      >
        {PartNumberDataOptions.map((option) => {
          return (
            <div
              key={option.name}
              className={`hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center px-4 py-1 last:mb-1.5 `}
              onClick={() => addNewColumn(option.type)}
            >
              <TypeTag className={"mr-2 w-10 text-xs"} hideText={true} type={option.type} />
              <span className="text-serial-palette-600 text-sm font-medium">{option.name}</span>
            </div>
          );
        })}
      </DropdownCustom>
      <button
        className=" btn bg-serial-palette-800 hover:bg-serial-palette-600 right-2 top-2 z-10 h-10 whitespace-nowrap text-white"
        onClick={() => handleShowCsvUpload()}
      >
        <FontAwesomeIcon icon={faUpload} />
        <span className="xs:block ml-2 hidden">Upload From CSV</span>
      </button>
    </div>
  );
};

export default PartNumberGridTopBar;
