import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faCalendar, faCode, faCogs, faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";

interface HeaderHelpProps {
  closeDropdown: () => void;
}

const HeaderHelp: React.FC<HeaderHelpProps> = ({ closeDropdown }) => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full flex-col divide-y">
      <div className="flex h-10 w-full items-center justify-between px-3">
        <div className="text-sm font-bold">Help & Support</div>
        <div className="flex gap-2">
          <button onClick={() => closeDropdown()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
      <div className="flex h-full flex-col gap-2 p-3">
        <p className="text-sm font-medium">Contact Us</p>
        <p className="text-xs">
          We're here to help and love hearing from our users! <br />
          Don't hesitate to reach out
        </p>
        <button
          className="btn serial-btn-dark flex w-full justify-start gap-2 text-sm"
          onClick={() => window.open("https://calendly.com/serial-founders/serial-discussion", "_blank")}
        >
          <FontAwesomeIcon icon={faCalendar} className="w-8" />
          <span>Book a Meeting</span>
        </button>
        <button
          className="btn serial-btn-dark flex w-full justify-start gap-2 text-sm"
          onClick={() => window.open("mailto:support@serial.io", "_blank")}
        >
          <FontAwesomeIcon icon={faEnvelope} className="w-8" />
          <span>Email Support</span>
        </button>
      </div>
      <div className="flex h-full flex-col gap-2 p-3">
        <p className="text-sm font-medium">Reference</p>
        <button
          className="btn serial-btn-light flex w-full justify-start gap-2 text-sm"
          onClick={() => window.open("https://docs.serial.io", "_blank")}
        >
          <FontAwesomeIcon icon={faBookOpen} className="w-8" />
          <span>Documentation</span>
        </button>
        <button
          className="btn serial-btn-light flex w-full justify-start gap-2 text-sm"
          onClick={() => window.open("https://docs.serial.io/python/quickstart", "_blank")}
        >
          <FontAwesomeIcon icon={faCode} className="w-8" />
          <span>Developer</span>
        </button>
        <button className="btn serial-btn-light flex w-full justify-start gap-2 text-sm" onClick={() => navigate("/settings/account")}>
          <FontAwesomeIcon icon={faCogs} className="w-8" />
          <span>Settings</span>
        </button>
      </div>
    </div>
  );
};

export default HeaderHelp;
