import { DashboardRow } from "@shared/types/databaseTypes";
import { dashboardInsightDefaultProperties } from "../constants";
import { DashboardInsightWidth } from "../types";

interface DashboardRowLogicProps {
  row: DashboardRow;
}

const gridColsFromWidth = (width: DashboardInsightWidth) => {
  switch (width) {
    case "sm":
      return 2;
    case "md":
      return 3;
    case "lg":
      return 4;
    case "xl":
      return 6;
    default:
      return 6;
  }
};

const sumGridCols = (widths: DashboardInsightWidth[]) => {
  return widths.reduce((acc, width) => acc + gridColsFromWidth(width), 0);
};

const getSmallerWidth = (width: DashboardInsightWidth) => {
  switch (width) {
    case "sm":
      return "sm";
    case "md":
      return "sm";
    case "lg":
      return "md";
    case "xl":
      return "lg";
    default:
      return "sm";
  }
};

export const useDashboardRowLogic = ({ row }: DashboardRowLogicProps) => {
  const numInsights = row?.insights?.length;

  // Create a list of objects for each insight with the index, minWidth, and width
  let widthDetails = row?.insights?.map((insight, index) => {
    const defaultConfig = dashboardInsightDefaultProperties[insight.type];
    const width = insight.config?.width || "xl";
    const minWidth = defaultConfig.minWidth;
    const defaultWidth = defaultConfig.defaultWidth;
    return { index, minWidth, width, defaultWidth };
  });

  // While the total grid columns is greater than 6:
  //    - Sort the insights first by default width, then by minWidth then by difference between width and minWidth
  //    - Exception if width is already at minWidth, then the insight is sorted to the end of the list
  //    - Reduce the width of the first insight in this sorted list by 1 size
  //    - Repeat (with a max of 12 iterations)
  let loopCounter = 0;
  while (sumGridCols(widthDetails.map((w) => w.width)) > 6 && loopCounter < 12) {
    const sortedWidthDetails = [...widthDetails].sort((a, b) => {
      // If current width of 'a' is already at minWidth, sort it to the end
      if (a.width === a.minWidth) return 1;
      // If current width of 'b' is already at minWidth, sort it to the start
      if (b.width === b.minWidth) return -1;

      // Calculate difference between current width and minWidth for 'a' and 'b'
      const aDifference = gridColsFromWidth(a.width) - gridColsFromWidth(a.minWidth);
      const bDifference = gridColsFromWidth(b.width) - gridColsFromWidth(b.minWidth);

      // If the current defaultWidth are not equal, sort by ascending defaultWidth
      if (a.defaultWidth !== b.defaultWidth) return gridColsFromWidth(a.defaultWidth) - gridColsFromWidth(b.defaultWidth);
      // If the minWidths are not equal, sort by descending minWidth
      if (a.minWidth !== b.minWidth) return gridColsFromWidth(b.minWidth) - gridColsFromWidth(a.minWidth);

      // If both the defaultWidth and minWidth are the same for 'a' and 'b',
      // sort by the difference between the current width and minWidth (descending)
      return bDifference - aDifference;
    });

    const updatedIndex = sortedWidthDetails[0].index;
    const updatedWidth = getSmallerWidth(sortedWidthDetails[0].width);
    widthDetails[updatedIndex] = { ...widthDetails[updatedIndex], width: updatedWidth };
    loopCounter++;
  }

  let widths = widthDetails.map((w) => w.width);

  // If the total grid columns is less than 6 when minWidths are used, then allow the user to add an insight
  const allowAddInsight = sumGridCols(widthDetails.map((w) => w.minWidth)) < 6;

  return {
    numInsights,
    widths,
    allowAddInsight,
  };
};
