import { fetchFile } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { ParsedResponse } from "@shared/types/apiTypes";

export interface Screenshot {
  time: number;
  base64: string;
  file: File;
  fileName: string;
}

// `getVideoDuration` asynchronously determines a video file's duration using a temporary video element to load its metadata.
// It handles edge cases where duration is Infinity by seeking, then cleans up by revoking the created URL.
// Errors in loading metadata result in Promise rejection.
export const getVideoDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(file);
    videoElement.preload = "metadata";

    const onLoadedMetadata = () => {
      if (videoElement.duration === Infinity) {
        videoElement.currentTime = Number.MAX_SAFE_INTEGER;
        videoElement.ontimeupdate = () => {
          videoElement.ontimeupdate = null;
          resolve(videoElement.duration);
          videoElement.currentTime = 0; // Reset to start
          URL.revokeObjectURL(videoElement.src);
        };
      } else {
        resolve(videoElement.duration);
        URL.revokeObjectURL(videoElement.src);
      }
    };

    videoElement.addEventListener("loadedmetadata", onLoadedMetadata);

    videoElement.addEventListener("error", () => {
      reject(new Error("Error loading video metadata"));
    });

    videoElement.load();
  });
};

export const arrayBufferToBase64 = (buffer: Uint8Array): string => {
  let binary = "";
  const len = buffer.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(buffer[i]);
  }
  return window.btoa(binary);
};

export const createScreenshots = async (
  videoFile: File,
  interval: number,
  ffmpeg: FFmpeg,
  width: number = 512,
): Promise<ParsedResponse<Screenshot[]>> => {
  try {
    const duration = await getVideoDuration(videoFile);
    const screenshotCount = Math.floor(duration / interval);
    const screenshots: Screenshot[] = [];

    await ffmpeg.writeFile("input.mp4", await fetchFile(videoFile));

    for (let i = 0; i < screenshotCount; i++) {
      const timestamp = (interval * i).toFixed(2);
      const outputFileName = `screenshot_${String(i).padStart(4, "0")}.png`;

      // Using FFmpeg to extract screenshots from a video:
      // '-ss', `${timestamp}`: Seeks to the specified timestamp in the video before starting processing, setting the start point.
      // '-i', 'input.mp4': Specifies 'input.mp4' as the input file for processing.
      // '-frames:v', '1': Extracts only one frame from the video - useful for creating thumbnails or previews.
      // '-vf', `scale=${width}:-1`: Applies a video filter to scale the extracted frame.
      //     `${width}` sets the desired width, and '-1' maintains aspect ratio by calculating height automatically.
      // outputFileName: The name for the output file where the processed frame will be saved.
      await ffmpeg.exec(["-ss", `${timestamp}`, "-i", "input.mp4", "-frames:v", "1", "-vf", `scale=${width}:-1`, outputFileName]);

      const screenshotFile = await ffmpeg.readFile(outputFileName);

      // Check if screenshotFile is a string or Uint8Array
      let buffer: Uint8Array;
      if (typeof screenshotFile === "string") {
        buffer = new Uint8Array(screenshotFile.split("").map((char) => char.charCodeAt(0)));
      } else {
        buffer = screenshotFile;
      }

      // OpenAI requires base64 encoded images for the vision model
      const screenshotBase64 = `data:image/png;base64,${arrayBufferToBase64(buffer)}`;
      const screenshotOutputFile = new File([buffer], outputFileName, { type: "image/png" });
      screenshots.push({
        time: Number(timestamp),
        base64: screenshotBase64,
        file: screenshotOutputFile,
        fileName: `screenshot_${String(interval * i).padStart(4, "0")}_seconds.png`,
      });
    }
    return { data: screenshots, error: undefined };
  } catch (error) {
    return { data: undefined, error: String(error) };
  }
};

export const extractAudioFromVideo = async (videoFile: File, ffmpeg: FFmpeg): Promise<ParsedResponse<File>> => {
  try {
    await ffmpeg.writeFile("input.mp4", await fetchFile(videoFile));
    // Using FFmpeg to extract audio from a video:
    // '-i', 'input.mp4': Specifies 'input.mp4' as the input video file.
    // '-q:a', '0': Sets the audio quality level. '0' represents the highest quality.
    // '-map', 'a': Maps only the audio streams from the video file for processing.
    // 'output.mp3': Specifies 'output.mp3' as the output file, extracting the audio into this file.
    await ffmpeg.exec(["-i", "input.mp4", "-q:a", "0", "-map", "a", "output.mp3"]);
    const audioData = await ffmpeg.readFile("output.mp3");
    const audioFile = new File([audioData], "output.mp3", { type: "audio/mpeg" });
    return { data: audioFile, error: undefined };
  } catch (error) {
    return { data: undefined, error: String(error) };
  }
};

export const loadFFmpeg = async (ffmpeg: FFmpeg, logging: boolean = false): Promise<boolean> => {
  const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.4/dist/esm";

  if (logging) ffmpeg.on("log", ({ message }) => console.log(message));

  await ffmpeg.load({
    coreURL: `${baseURL}/ffmpeg-core.js`,
    wasmURL: `${baseURL}/ffmpeg-core.wasm`,
  });

  return true;
};

export const base64ToBlob = (base64: string, contentType: string): Blob => {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
