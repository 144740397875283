import { getSupabase } from "@shared/connections/supabaseAuth";
import { Process, UniqueIdentifier, UniqueIdentifierLink } from "@shared/types/databaseTypes";
import { CHUNK_SIZE } from "@shared/constants/supabase";
import { GenealogyByLinkedComponent, UniqueIdentifierWithMeasures } from "../types";
import { SupabaseModifier, fetchLargeTable } from "@shared/connections/supabaseGeneral";

// TODO: implement supabase RPC that returns the unique identifier links + laditude & longitude for each component instance
// Then change this function to parse accordingly and produce the genealogy data
export const fetchUniqueIdentifiersWithGenealogies = async (componentId: string): Promise<UniqueIdentifierWithMeasures[]> => {
  const supabase = getSupabase();

  const { data: uniqueIdentifiersWithLinks, error: uniqueIdentifiersWithLinksError } = await supabase
    .rpc("component_instance_genealogies", { p_component_id: componentId })
    .returns<{ unique_identifier: UniqueIdentifier; unique_identifier_links: UniqueIdentifierLink[] }[]>();

  if (uniqueIdentifiersWithLinksError || !uniqueIdentifiersWithLinks) {
    console.error(uniqueIdentifiersWithLinksError);
    return [];
  }

  const uniqueIdentifiers: UniqueIdentifierWithMeasures[] = uniqueIdentifiersWithLinks
    .filter((uniqueIdentifiersWithLinks) => !uniqueIdentifiersWithLinks.unique_identifier.is_archived)
    .map((uniqueIdentifierWithLinks) => {
      const rootUniqueIdentifier: UniqueIdentifier = { ...uniqueIdentifierWithLinks.unique_identifier };
      const genealogy: GenealogyByLinkedComponent = {
        uniqueIdentifierLinks: uniqueIdentifierWithLinks.unique_identifier_links ?? [],
        uniqueIdentifiers: [
          rootUniqueIdentifier,
          ...((uniqueIdentifierWithLinks.unique_identifier_links ?? [])
            .map((link) => link.child)
            .filter((child) => child?.id) as UniqueIdentifier[]),
        ],
      };
      return {
        ...rootUniqueIdentifier,
        genealogy,
        measures: [],
      };
    });

  return uniqueIdentifiers;
};

export const fetchDataByTableNameAndProcessId = async <T>(tableName: string, processId: string, datasetId: string | null = null) => {
  if (tableName === "process_entries") {
    return await fetchLargeTable<T>(tableName, "id", "*, operator:operator_id(*), station:station_id(*)", [
      { modifier: "eq", key: "process_id", value: processId },
    ]);
  } else if (tableName === "unique_identifier_links") {
    const modifiers: SupabaseModifier[] = [{ modifier: "eq", key: "process_entry.process_id", value: processId }];
    if (datasetId) {
      modifiers.push({ modifier: "eq", key: "dataset_id", value: datasetId });
    }
    return await fetchLargeTable<T>(tableName, "id", "*, process_entry:process_entry_id!inner(*), child:has_child_of_id(*)", modifiers);
  } else {
    const modifiers: SupabaseModifier[] = [{ modifier: "eq", key: "process_entry.process_id", value: processId }];
    if (datasetId) {
      modifiers.push({ modifier: "eq", key: "dataset_id", value: datasetId });
    }
    return await fetchLargeTable<T>(tableName, "id", "*, process_entry:process_entry_id!inner(*)", modifiers);
  }
};

export const fetchAllUniqueIdentifierDataByComponentId = async (componentId: string) => {
  return fetchLargeTable<UniqueIdentifier>("unique_identifiers", "id", "*, part_number:part_number_id(*), work_order:work_order_id(*)", [
    { modifier: "eq", key: "component_id", value: componentId },
  ]);
};

export const fetchAllUniqueIdentifierLinkDataByComponentId = async (componentId: string, allowRetries: boolean = true) => {
  return fetchLargeTable<UniqueIdentifierLink>(
    "unique_identifier_links",
    "id",
    "*, child:has_child_of_id!inner(*, component:component_id(*))",
    [{ modifier: "eq", key: "child.component_id", value: componentId }],
    allowRetries,
  );
};

export const fetchAllProcessRevisionsForProcessIdSet = async (processIds: string[], allowRetries: boolean = true): Promise<Process[]> => {
  const supabase = getSupabase();

  // Get count
  const { count: totalCount, error: countError } = await supabase
    .from("processes")
    .select("*", { count: "exact", head: true })
    .in("id", processIds);

  if (countError || !totalCount) {
    if (countError) console.log(countError);
    return [];
  }

  const allProcesses: Process[] = [];
  for (let offset = 0; offset < totalCount; offset += CHUNK_SIZE) {
    const { data, error } = await supabase
      .from("processes")
      .select("*")
      .in("id", processIds)
      .range(offset, offset + CHUNK_SIZE - 1)
      .returns<Process[]>();

    if (!error && data.length > 0) {
      allProcesses.push(...data);
    } else {
      console.log(error);
    }
  }

  // Check uniqueness of the IDs in the data
  const allIdsUnique = allProcesses.length === new Set(allProcesses.map((process) => `${process["id"]}_${process["revision"]}`)).size;

  // if the ids are not unique, load the data again
  if (!allIdsUnique && allowRetries) {
    console.log("Duplicate IDs found in processes. Refreshing data...");
    const newAllProcesses: Process[] = await fetchAllProcessRevisionsForProcessIdSet(processIds, false);
    return newAllProcesses;
  }

  return allProcesses;
};
