import React, { useContext, useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import DropdownClassic from "@shared/components/dropdowns/DropdownClassic";
import { getApiKeys } from "@shared/connections/api/auth";
import { ApiKey } from "@shared/types/apiTypes";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { transformDatasetsInSourceCode } from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { Station } from "@shared/types/databaseTypes";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { UserRole } from "@shared/types/databaseEnums";

interface ProcessBuilderApiSampleCodeProps {
  sourceCode: string;
  setSourceCode: React.Dispatch<React.SetStateAction<string>>;
  showChat: boolean;
  setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProcessBuilderApiSampleCode: React.FC<ProcessBuilderApiSampleCodeProps> = ({ sourceCode, setSourceCode, showChat, setShowChat }) => {
  const { draftProcess, component, processDatasets } = useContext(ProcessBuilderContext);

  const stations = useSelector((state: RootState) => state.db.stations);

  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null);
  const [selectedStation, setSelectedStation] = useState<Station | null>(null);
  const [displayedSourceCode, setDisplayedSourceCode] = useState<string>(sourceCode);

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser?.role !== UserRole.Admin) return;
    getApiKeys().then((keys) => {
      if (keys.length > 0) setApiKeys(keys);
    });
  }, []);

  useEffect(() => {
    setDisplayedSourceCode(() => {
      let updatedSourceCode = sourceCode;

      // Set the process ID
      const processIdRegex = /process_id=["']([^ ]*)["']/g;
      if (draftProcess?.id) {
        updatedSourceCode = updatedSourceCode.replace(processIdRegex, `process_id='${draftProcess.id}'`);
      } else {
        updatedSourceCode = updatedSourceCode.replace(processIdRegex, `process_id='YOUR_PROCESS_ID'`);
      }

      // Set the component name
      const componentNameRegex = /component_name=["']([^"']*)["']/g;
      if (component?.name) {
        updatedSourceCode = updatedSourceCode.replace(componentNameRegex, `component_name='${component.name}'`);
      } else {
        updatedSourceCode = updatedSourceCode.replace(componentNameRegex, `component_name='YOUR_COMPONENT_NAME'`);
      }

      // Set the station ID
      const stationIdRegex = /set_station_id\((.*)\)/g;
      if (selectedStation) {
        updatedSourceCode = updatedSourceCode.replace(stationIdRegex, `set_station_id('${selectedStation.id}')`);
      } else {
        updatedSourceCode = updatedSourceCode.replace(stationIdRegex, `set_station_id('YOUR_STATION_ID')`);
      }

      // Set the API key
      const apiKeyRegex = /set_api_key\((.*)\)/g;
      if (selectedApiKey) {
        updatedSourceCode = updatedSourceCode.replace(apiKeyRegex, `set_api_key('${selectedApiKey.key}')`);
      } else {
        updatedSourceCode = updatedSourceCode.replace(apiKeyRegex, `set_api_key('YOUR_API_KEY')`);
      }

      return transformDatasetsInSourceCode(
        processDatasets.filter((dataset) => dataset.is_active),
        updatedSourceCode,
      );
    });
  }, [selectedStation, selectedApiKey, draftProcess, processDatasets, component, sourceCode, displayedSourceCode]);

  return (
    <div className="flex h-full w-3/5 shrink-0 flex-col border-r bg-[#1E1E1E]">
      <div className="bg-serial-palette-50 flex h-12 w-full items-center justify-between border-b pl-5 pr-3">
        <div className="w-1/2 truncate font-mono ">{`${draftProcess?.name.toLocaleLowerCase().replace(/ /g, "_")}_example.py`}</div>
        <div className="flex gap-2">
          <DropdownClassic
            className="flex h-[30px] w-32 text-sm"
            options={[
              { id: null, label: "Station ID" },
              ...stations.map((station) => {
                return { label: station.name, id: station.id };
              }),
            ]}
            selected={selectedStation?.id ?? null}
            setSelected={(id: string) => setSelectedStation(stations.find((station) => station.id === id) ?? null)}
          />
          {currentUser?.role === UserRole.Admin && (
            <DropdownClassic
              className="flex h-[30px] w-32 text-sm"
              options={[
                { id: null, label: "API Key" },
                ...apiKeys.map((key) => {
                  return { label: key.pretty_name, id: key.name };
                }),
              ]}
              selected={selectedApiKey?.name ?? null}
              setSelected={(name: string) => setSelectedApiKey(apiKeys.find((key) => key.name === name) ?? null)}
            />
          )}
        </div>
      </div>
      <div className="relative flex min-h-0 min-w-0 flex-grow pt-2">
        <Editor
          height="100%"
          theme="vs-dark"
          defaultLanguage="python"
          className="mt-2"
          options={{
            fontSize: 14,
            minimap: {
              enabled: false,
            },
            readOnly: !showChat,
          }}
          onChange={(value) => setSourceCode(value ?? "")}
          value={displayedSourceCode}
        />
        <button
          className="btn absolute bottom-3.5 right-3 h-10 gap-2 rounded-full bg-violet-500 text-white hover:bg-violet-600"
          onClick={() => setShowChat(!showChat)}
        >
          <FontAwesomeIcon icon={faMagicWandSparkles} />
          {showChat ? "Hide Assistant" : "Show Assistant"}
        </button>

        <div className="btn-xs serial-btn-light absolute right-3 top-3.5 w-8">
          <CopyToClipboard className="text-serial-palette-800 !px-0 !py-0" text={sourceCode} noTooltip />
        </div>
      </div>
    </div>
  );
};

export default ProcessBuilderApiSampleCode;
