import React from "react";
import { useSelector } from "react-redux";
import { ProcessWithEntries, ProcessEntryAllData } from "../types";
import TypeTag from "@shared/components/TypeTag";
import { SnLookupContext } from "../SnLookupProvider";
import { formatTimeAgo } from "@shared/utils/time";
import { RootState } from "@shared/redux/store";
import UserAvatar from "@shared/components/UserAvatar";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { UserRole } from "@shared/types/databaseEnums";

const PROD_APP_POPUP_W = 1200;
const PROD_APP_POPUP_H = 800;

interface SnLookUpComponentInstanceProcessDataProps {
  processesWithEntries: ProcessWithEntries[];
  identifier?: string;
}

const SnLookUpComponentInstanceProcessData: React.FC<SnLookUpComponentInstanceProcessDataProps> = ({
  processesWithEntries,
  identifier,
}) => {
  const { setOverrideModalEntry, setSelectedProcess, setDataAnalysisModalOpen } = React.useContext(SnLookupContext);

  const operators = useSelector((state: RootState) => state.db.operators);
  const users = useSelector((state: RootState) => state.db.users);
  const currUser = useCurrentUser();

  const countTotalParametricData = (processEntry: ProcessEntryAllData) => {
    const textDataLength = processEntry?.textData?.length || 0;
    const passFailLength = processEntry?.textData?.filter((entry) => entry.dataset?.data_type === "PASSFAIL").length || 0;
    const numericalDataLength = processEntry?.numericalData?.length || 0;
    return textDataLength - passFailLength + numericalDataLength;
  };

  const handleRunProductionAppPopup = (processId: string, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const cookies = document.cookie.split("; ").find((row) => row.startsWith("stationId="));
    const stationIdByCookie = cookies ? cookies.split("=")[1] : null;
    const stationIdByLocalStorage = localStorage.getItem("stationId");
    const stationId = stationIdByCookie ?? stationIdByLocalStorage ?? currUser?.default_station_id;

    const windowFeatures = `width=${PROD_APP_POPUP_W},height=${PROD_APP_POPUP_H},popup=yes,resizable=yes`;
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/production/${stationId}/${processId}?single-submission=true&identifier=${identifier}`;
    window.open(url, "Serial Production App", windowFeatures);
  };

  return (
    <div className="overflow-x-auto">
      {processesWithEntries.map((process: ProcessWithEntries, index: number) => {
        const latestEntry = process.entries.reduce(
          (prev, current) => {
            if (!prev) return current;
            return prev.timestamp > current.timestamp ? prev : current;
          },
          null as ProcessEntryAllData | null,
        );

        const operator = operators.find((operator) => operator.id === latestEntry?.operator_id);
        const user = users.find((user) => user.supabase_uid === operator?.user_id);
        const allowRunNow = !process?.use_api && currUser?.role !== UserRole.ReadOnly;

        let totalParametricData = 0;
        if (latestEntry) {
          totalParametricData = countTotalParametricData(latestEntry);
        }

        return (
          <div key={index} className="group">
            {/* This is the new container div */}
            <div
              className={`items flex w-full justify-between ${!latestEntry ? "bg-serial-palette-100" : "hover:bg-serial-palette-100 hover:cursor-pointer"} space-x-2 px-3 py-1.5`}
              onClick={(e) => latestEntry && (setSelectedProcess(process), setDataAnalysisModalOpen(true), e.stopPropagation())}
            >
              <div className="flex w-[50px] shrink-0 items-center">
                {latestEntry && (
                  <div
                    onClick={(e) => {
                      setOverrideModalEntry(latestEntry);
                      e.stopPropagation();
                    }}
                    className="flex cursor-pointer justify-center rounded-full font-medium"
                  >
                    {latestEntry?.override_user_id ? (
                      <>
                        {latestEntry?.is_pass === true && <TypeTag type="OVERRIDE_PASS" className="h-5 text-xs" hideText />}
                        {latestEntry?.is_pass === false && <TypeTag type="OVERRIDE_FAIL" className="h-5 text-xs" hideText />}
                      </>
                    ) : (
                      <>
                        {latestEntry?.is_pass === true && <TypeTag type="PASS" className="h-5 text-xs" hideText />}
                        {latestEntry?.is_pass === false && <TypeTag type="FAIL" className="h-5 text-xs" hideText />}
                        {latestEntry?.is_pass === null && <TypeTag type="NA" className="h-5 text-xs" hideText />}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className={`${!latestEntry && "opacity-50"} w-1/3 shrink-0`}>
                <div className="flex items-center">
                  <div className="bg-serial-palette-500 mx-2 flex h-5 items-center rounded px-1.5 text-xs text-white">
                    <span>v{process?.revision || "0"}</span>
                  </div>
                  <div className="truncate pr-2 text-sm">{process?.name}</div>
                  {latestEntry && latestEntry.testCount > 1 && (
                    <TypeTag className="h-4 w-fit text-[10px]" hideText type="RETEST" quantity={latestEntry.testCount - 1} />
                  )}
                </div>
              </div>
              <div className={`${!latestEntry && "opacity-50"} flex flex-grow items-center overflow-hidden`}>
                <div className="flex space-x-2 rounded-full text-center font-medium">
                  {latestEntry ? (
                    <>
                      {latestEntry?.fileData && latestEntry?.fileData?.length > 0 && (
                        <TypeTag type="FILE" hideText quantity={latestEntry?.fileData?.length} className="h-4 text-[10px]" />
                      )}
                      {latestEntry?.imageData && latestEntry?.imageData?.length > 0 && (
                        <TypeTag type="IMAGE" hideText quantity={latestEntry?.imageData?.length} className="h-4 text-[10px]" />
                      )}
                      {totalParametricData > 0 && (
                        <TypeTag type="PARAMETRIC" hideText quantity={totalParametricData} className="h-4 text-[10px]" />
                      )}
                      {latestEntry?.linkData && latestEntry?.linkData?.length > 0 && (
                        <TypeTag type="LINK" hideText quantity={latestEntry?.linkData?.length} className="h-4 text-[10px]" />
                      )}
                      {latestEntry?.checkboxData && latestEntry?.checkboxData?.length > 0 && (
                        <TypeTag type="CHECKBOX" hideText quantity={latestEntry?.checkboxData?.length} className="h-4 text-[10px]" />
                      )}
                      {latestEntry?.datetimeData && latestEntry?.datetimeData?.length > 0 && (
                        <TypeTag type="DATETIME" hideText quantity={latestEntry?.datetimeData?.length} className="h-4 text-[10px]" />
                      )}
                      {latestEntry?.textData &&
                        latestEntry?.textData.some(
                          (entry) => (entry.value === "PASS" || entry.value === "FAIL") && entry.dataset?.data_type === "PASSFAIL",
                        ) && (
                          <TypeTag
                            type="PASSFAIL"
                            hideText
                            quantity={latestEntry?.textData?.filter((entry) => entry.dataset?.data_type === "PASSFAIL").length}
                            className="h-4 text-[10px]"
                          />
                        )}
                    </>
                  ) : (
                    <div className="text-sm italic">Process not logged</div>
                  )}
                </div>
              </div>
              <div className={`${!latestEntry && "opacity-50"} flex w-min items-center space-x-2 2xl:w-[200px]`}>
                {operator ? <UserAvatar user={user ?? operator} size="xs" /> : "-"}{" "}
                {/* Prefer user since users have avatars (operators do not) */}
                <div className="text-serial-palette-800 hidden truncate whitespace-nowrap text-sm font-light 2xl:block">
                  {operator ? `${operator.first_name} ${operator.last_name}` : ""}
                </div>
              </div>
              <div className="flex w-[150px] shrink-0 justify-end text-sm">
                <span className={`${allowRunNow && "group-hover:hidden"}`}>{latestEntry ? formatTimeAgo(latestEntry.timestamp) : ""}</span>
                <Button
                  onClick={(e) => {
                    handleRunProductionAppPopup(process.id, e);
                  }}
                  className={`hidden ${allowRunNow && "group-hover:flex"}`}
                  variant="success"
                  size="xs"
                >
                  <FontAwesomeIcon icon={faPlay} />
                  Run Now
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SnLookUpComponentInstanceProcessData;
