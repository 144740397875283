import React, { useState, useCallback, useEffect, useContext } from "react";
import { maxRowHeight, minRowHeight } from "../constants";
import { DashboardRow } from "@shared/types/databaseTypes";
import { DashboardContext } from "../Dashboards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface DashboardInsightsRowProps {
  children: React.ReactNode;
  row: DashboardRow;
  rowIndex: number;
  handleNewInsight: (rowIndex: number) => void;
  allowAddInsight?: boolean;
  allowResize?: boolean;
}

const DashboardInsightsRow: React.FC<DashboardInsightsRowProps> = ({
  children,
  row,
  rowIndex,
  allowAddInsight,
  handleNewInsight,
  allowResize,
}) => {
  const [localHeight, setLocalHeight] = useState<number>(row.height);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [startY, setStartY] = useState<number>(0);
  const [hover, setHover] = useState<boolean>(false);

  const context = useContext(DashboardContext);

  useEffect(() => {
    setLocalHeight(row.height);
  }, [row.height]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setIsResizing(true);
    setStartY(e.clientY);
  }, []);

  const handleMouseUp = () => {
    setIsResizing(false);
    let updatedDashboard = context.dashboards.find((dashboard) => dashboard.id === context.selectedDashboardId);
    // make deep copy of dashboard to avoid mutating redux state
    updatedDashboard = JSON.parse(JSON.stringify(updatedDashboard));
    if (updatedDashboard) {
      updatedDashboard.config.rows[rowIndex].height = localHeight;
      context.handleUpdateDashboard(updatedDashboard);
    }
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isResizing) return;
      const newHeight = localHeight + (e.clientY - startY);
      if (newHeight < minRowHeight || newHeight > maxRowHeight) return;
      setLocalHeight(newHeight);
      setStartY(e.clientY);
    },
    [isResizing, startY, localHeight],
  );

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove, handleMouseUp]);

  return (
    <div className="relative flex w-full" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {/* Fixed Height Content */}

      <div className="grid w-full grid-cols-6 gap-6 overflow-hidden" style={{ height: `${localHeight}px` }}>
        {children}
      </div>
      {allowAddInsight && (
        <div
          className={`absolute -right-10 flex h-full w-8 cursor-pointer items-center justify-center rounded hover:border hover:bg-white`}
          onClick={() => handleNewInsight(rowIndex)}
        >
          {hover && <FontAwesomeIcon icon={faPlus} className="text-serial-palette-500" />}
        </div>
      )}
      {/* Draggable Border */}
      {allowResize && (
        <div
          className={`absolute inset-x-0 -bottom-6 z-10 flex h-6 cursor-ns-resize items-center transition-opacity duration-100 ${isResizing ? "opacity-100" : "opacity-0"} hover:opacity-100`}
          onMouseDown={handleMouseDown}
        >
          <div className="bg-serial-palette-200 h-1 w-full rounded-full" />
        </div>
      )}
    </div>
  );
};

export default DashboardInsightsRow;
