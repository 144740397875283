import React, { useState, useRef, useEffect } from "react";
import Transition from "../Transition";

function DropdownHeading({ options, selected, setSelected, className, dropdownClassName = "" }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex w-full">
      <button
        ref={trigger}
        className={`btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-500 hover:text-serial-palette-600 min-w-44 justify-between bg-white ${className}`}
        aria-label="Select date range"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex w-full items-center overflow-hidden">
          <span className={`truncate ${selected === null && "text-serial-palette-400 font-light"}`}>
            {options.find((o) => o.id === selected)?.label}
          </span>
        </span>
        <svg className="text-serial-palette-400 ml-1 shrink-0 fill-current" width="11" height="7" viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`border-serial-palette-200 absolute left-0 top-full z-10 mt-1 min-w-full overflow-hidden rounded border bg-white py-1.5 shadow ${dropdownClassName}`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="text-serial-palette-600 text-sm font-medium"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.map((option, index) => {
            return (
              <button
                key={index}
                tabIndex="0"
                className={`hover:bg-serial-palette-50 flex w-full cursor-pointer items-center px-3 py-1 ${option.id === selected && "text-serial-palette-500"}`}
                onClick={() => {
                  setSelected(option.id);
                  setDropdownOpen(false);
                }}
              >
                <svg
                  className={`mr-2 shrink-0 fill-current text-indigo-500 ${option.id !== selected && "invisible"}`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
                <span className="whitespace-nowrap">{option.label}</span>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownHeading;
