import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import UserAvatar from "@shared/components/primitives/UserAvatar";
import useCurrentUser from "@shared/hooks/useCurrentUser";

const FrontendPrimitivesUserAvatars = () => {
  const user = useCurrentUser();
  return (
    <FrontendPrimitivesCard title="User Avatars">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Basic User Avatar</div>
        <div className="flex gap-2">
          <UserAvatar user={user} size="md" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">User Avatar Sizes</div>
        <div className="flex gap-2">
          <UserAvatar user={user} size="xs" />
          <UserAvatar user={user} size="sm" />
          <UserAvatar user={user} size="md" />
          <UserAvatar user={user} size="lg" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">User Avatar with Tooltip or Custom Tooltip</div>
        <div className="flex gap-2">
          <UserAvatar user={user} size="md" tooltip />
          <UserAvatar user={user} size="md" tooltip="Custom Tooltip!" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">User Avatar with User Name</div>
        <div className="flex gap-2">
          <UserAvatar.Root user={user} size="sm">
            <UserAvatar.Image />
            <UserAvatar.Name />
          </UserAvatar.Root>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesUserAvatars;
