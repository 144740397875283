import React, { useContext, useRef, useState } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEyeSlash, faFilter, faMinusCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import ProcessBuilderStepFilter from "./ProcessBuilderStepFilter";
import { StepsTruthTable } from "../hooks/useStepsTruthTable";
import { countDataTypes } from "@shared/hooks/useDataTypeCount";
import { Dataset, ProcessStep, ProcessStepWithReferences } from "@shared/types/databaseTypes";
import TypeTag from "@shared/components/TypeTag";
import { ProcessBuilderDraggableType } from "../types";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import { getYHemisphere } from "@shared/utils/window";

interface ProcessBuilderStepsSummaryDraggableStepProps {
  step: ProcessStepWithReferences;
  stepIndex: number;
  nextSelectedStepIndex: number | null;
  showTruthTable: boolean;
  stepsTruthTable: StepsTruthTable;
  setShowTruthTable: (show: boolean) => void;
  handleSelectStep: (index: number) => void;
}

const ProcessBuilderStepsSummaryDraggableStep: React.FC<ProcessBuilderStepsSummaryDraggableStepProps> = ({
  step,
  stepIndex,
  nextSelectedStepIndex,
  showTruthTable,
  stepsTruthTable,
  setShowTruthTable,
  handleSelectStep,
}) => {
  const {
    readOnly,
    draftProcess,
    enablePreviewMode,
    hoveredStepIndex,
    setHoveredStepIndex,
    handleMoveStep,
    previewPartNumber,
    selectedStepIndex,
  } = useContext(ProcessBuilderContext);

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<ProcessStep, void, { handlerId: Identifier | null }>({
    accept: ProcessBuilderDraggableType.WorkInstructionBlock,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: ProcessStep, monitor) {
      const clientOffset = monitor.getClientOffset();
      if (!ref.current || !clientOffset) return;
      const hemisphere = getYHemisphere(ref, clientOffset);
      const draggedStepIndex = item.order;
      handleMoveStep(draggedStepIndex, stepIndex - (hemisphere === "top" ? 1 : 0));
    },
    hover(_item: ProcessStep, monitor) {
      const clientOffset = monitor.getClientOffset();
      if (!ref.current || !clientOffset) return;
      const hemisphere = getYHemisphere(ref, clientOffset);
      setHoveredStepIndex(hemisphere === "top" ? stepIndex - 1 : stepIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ProcessBuilderDraggableType.WorkInstructionBlock,
    item: () => {
      return { ...step };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setHoveredStepIndex(null);
    },
  });

  drop(ref);

  // all these in component computations are not super efficient. consider memoizing later
  const isVisible = !enablePreviewMode || !previewPartNumber || showTruthTable || stepsTruthTable[previewPartNumber.id][stepIndex];
  const isTransparent = !showTruthTable || !previewPartNumber ? true : stepsTruthTable[previewPartNumber.id]?.[stepIndex] ?? false;
  const dataTypeCounts = countDataTypes((step.fields?.map((field) => field?.dataset).filter((dataset) => dataset) || []) as Dataset[]);
  const numPriorHiddenSteps = draftProcess?.process_steps.slice(0, stepIndex).filter((step) => step.is_hidden).length ?? 0;
  if (!isVisible) return null;

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      onDragLeave={() => setHoveredStepIndex(null)}
      key={stepIndex}
      className={`relative flex w-full border-b bg-white ${isDragging && "opacity-40"} ${showTruthTable && "h-16 shrink-0"}`}
    >
      <div
        className={`border-serial-palette-200 absolute top-0 z-50 w-full border-b-4 transition-opacity ${stepIndex === 0 && hoveredStepIndex === -1 ? "opacity-100" : "opacity-0"}`}
      />
      <div
        className={`border-serial-palette-200 absolute -bottom-0.5 z-50 w-full border-b-4 transition-opacity ${stepIndex === hoveredStepIndex ? "opacity-100" : "opacity-0"}`}
      />
      <div
        ref={showTruthTable ? null : drag}
        onMouseDownCapture={(e) => {
          e.stopPropagation();
          if (isTransparent) handleSelectStep(stepIndex);
        }}
        className={`group relative flex w-full shrink-0 flex-col gap-0.5 px-4 py-2 ${!showTruthTable && "cursor-grab"} ${!isTransparent ? "opacity-20" : "cursor-pointer"} ${(nextSelectedStepIndex ? nextSelectedStepIndex : selectedStepIndex) === stepIndex ? "bg-serial-palette-100" : "hover:bg-serial-palette-100 bg-white"}`}
      >
        <div
          className={`${(nextSelectedStepIndex ? nextSelectedStepIndex : selectedStepIndex) === stepIndex ? "absolute" : "hidden group-hover:block"} bg-serial-palette-500 absolute left-0 top-[17%] h-2/3 w-[7px] rounded-r`}
        />
        <div className="flex justify-between">
          <div className="text-serial-palette-400 flex text-xs font-bold">
            {step.is_hidden ? "Hidden Step" : `Step ${stepIndex - numPriorHiddenSteps + 1}:`}
          </div>
          <div className="flex items-center gap-1">
            {!showTruthTable && step.is_hidden && <FontAwesomeIcon className="text-serial-palette-500" size="xs" icon={faEyeSlash} />}
            {step.filter_conditions.length > 0 && !showTruthTable && !readOnly && (
              <div
                className="bg-serial-palette-400 -mr-1.5 flex cursor-pointer items-center gap-0.5 rounded-full px-2 text-[10px] font-medium text-white"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowTruthTable(!showTruthTable);
                }}
              >
                <FontAwesomeIcon icon={faFilter} />
                <div>({step.filter_conditions.length})</div>
              </div>
            )}
          </div>
        </div>
        <div className={`text-medium text-left text-sm ${showTruthTable && "truncate"}`}>{step.name}</div>
        {!showTruthTable && (
          <div className="flex flex-wrap items-center gap-0.5">
            {dataTypeCounts["FILE"] && (
              <TypeTag type="FILE" hideText className="h-[16px] w-8 text-[8px]" quantity={dataTypeCounts["FILE"]} />
            )}
            {dataTypeCounts["IMAGE"] && (
              <TypeTag type="IMAGE" hideText className="h-[16px] w-8 text-[8px]" quantity={dataTypeCounts["IMAGE"]} />
            )}
            {(dataTypeCounts["PARAMETRIC_QUALITATIVE"] || dataTypeCounts["PARAMETRIC_QUANTITATIVE"]) && (
              <TypeTag
                type="PARAMETRIC"
                hideText
                className="h-[16px] w-8 text-[8px]"
                quantity={(dataTypeCounts["PARAMETRIC_QUALITATIVE"] ?? 0) + (dataTypeCounts["PARAMETRIC_QUANTITATIVE"] ?? 0)}
              />
            )}
            {dataTypeCounts["DATETIME"] && (
              <TypeTag type="DATETIME" hideText className="h-[16px] w-8 text-[8px]" quantity={dataTypeCounts["DATETIME"]} />
            )}
            {dataTypeCounts["LINK"] && (
              <TypeTag type="LINK" hideText className="h-[16px] w-8 text-[8px]" quantity={dataTypeCounts["LINK"]} />
            )}
            {dataTypeCounts["PASSFAIL"] && (
              <TypeTag type="PASSFAIL" hideText className="h-[16px] w-8 text-[8px]" quantity={dataTypeCounts["PASSFAIL"]} />
            )}
            {dataTypeCounts["CHECKBOX"] && (
              <TypeTag type="CHECKBOX" hideText className="h-[16px] w-8 text-[8px]" quantity={dataTypeCounts["CHECKBOX"]} />
            )}
            <TypeTag type="TIMESTAMP" hideText className="h-[16px] w-8 text-[8px]" />
          </div>
        )}
        <div className={`absolute right-1 top-1 transition-opacity delay-150 duration-300 ${!showTruthTable && "invisible opacity-0"}`}>
          <ProcessBuilderStepFilter
            step={step}
            stepIndex={stepIndex}
            align="left"
            buttonContents={
              <div className={`btn-xs mb-6 h-5 p-1.5 ${step.filter_conditions.length > 0 ? "serial-btn-dark" : "serial-btn-light"}`}>
                <FontAwesomeIcon size="xs" icon={faFilter} />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

interface ProcessBuilderStepsSummaryProps {
  stepsTruthTable: StepsTruthTable;
  handleScrollToStep: (index: number) => void;
}

const ProcessBuilderStepsSummary: React.FC<ProcessBuilderStepsSummaryProps> = ({ stepsTruthTable, handleScrollToStep }) => {
  const { readOnly, draftProcess, previewPartNumber, setPreviewPartNumber, setSelectedStepIndex, component, handleAddNewStep } =
    useContext(ProcessBuilderContext);
  const partNumbers = useSelector((state: RootState) => state.db.partNumbers).filter(
    (partNumber) => partNumber.component_id === component?.id,
  );

  const headerColRef = useRef<HTMLDivElement>(null);
  const bodyColRef = useRef<HTMLDivElement>(null);

  const [showTruthTable, setShowTruthTable] = useState<boolean>(false);
  const [nextSelectedStepIndex, setNextSelectedStepIndex] = useState<number | null>(null);

  const handleSelectStep = (index: number) => {
    setNextSelectedStepIndex(index);
    setSelectedStepIndex(index);
    handleScrollToStep(index);
    setTimeout(() => setNextSelectedStepIndex(null), 1000);
  };

  const handleAddStep = () => {
    handleAddNewStep();
    setTimeout(() => {
      handleSelectStep(draftProcess?.process_steps.length ?? 0);
    }, 0);
  };

  return (
    <React.Fragment>
      <div className="w-56 shrink-0" />
      <div
        className={`absolute z-10 h-full w-full bg-black transition-opacity ${showTruthTable ? "visible opacity-30" : "invisible opacity-0"}`}
        onClick={() => setShowTruthTable(false)}
      />
      <div
        className={`bg-serial-palette-50 absolute z-10 flex flex-col overflow-hidden transition-all duration-500 ease-in-out ${showTruthTable ? `left-0 top-0 h-full w-1/2 border-l` : `h-full w-56 shrink-0 border-r`}`}
        onClick={() => {
          if (showTruthTable) setPreviewPartNumber(null);
        }}
      >
        <div
          className={`flex h-28 w-full border-b bg-white transition-all duration-500 ${showTruthTable ? "opacity-100" : "invisible -mt-28 opacity-0"}`}
        >
          <div className="text-serial-palette-800 flex h-full w-56 shrink-0 items-end border-r px-4 py-3 font-semibold">
            Visibility by Filter
          </div>
          <div ref={headerColRef} className="flex min-w-0 flex-grow overflow-hidden">
            {partNumbers.map((partNumber) => {
              return (
                <div key={`header-column-${partNumber.id}`} className="relative flex h-28 w-16 shrink-0 items-center justify-center">
                  <div className="absolute -left-0 bottom-12 w-24 -rotate-[70deg] truncate text-xs font-bold">{partNumber.pn}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="min flex min-h-0 min-w-0 flex-grow">
          <div
            className="scrollbar-hide flex h-full w-56 shrink-0 flex-col overflow-y-auto border-r"
            onScroll={(e) => {
              if (bodyColRef.current) bodyColRef.current.scrollTop = e.currentTarget.scrollTop;
            }}
          >
            {draftProcess?.process_steps.map((step, stepIndex) => (
              <ProcessBuilderStepsSummaryDraggableStep
                key={stepIndex}
                step={step}
                stepIndex={stepIndex}
                nextSelectedStepIndex={nextSelectedStepIndex}
                showTruthTable={showTruthTable}
                stepsTruthTable={stepsTruthTable}
                setShowTruthTable={setShowTruthTable}
                handleSelectStep={handleSelectStep}
              />
            ))}
            {!showTruthTable && !readOnly && (
              <div className="flex w-full justify-center py-2">
                <button
                  tabIndex={-1}
                  className="btn btn-xs serial-btn-dark m-0 mr-0.5 h-[18px] gap-x-1 whitespace-nowrap rounded-md text-xs"
                  onClick={() => handleAddStep()}
                >
                  <FontAwesomeIcon icon={faPlus} size="xs" />
                  Add Step
                </button>
              </div>
            )}
            <div className="h-12 shrink-0" />
          </div>
          <div
            ref={bodyColRef}
            className="flex h-full min-w-0 flex-grow overflow-y-hidden overflow-x-scroll"
            onScroll={(e) => {
              if (headerColRef.current) headerColRef.current.scrollLeft = e.currentTarget.scrollLeft;
            }}
          >
            {partNumbers.map((partNumber) => {
              return (
                <div className="flex h-fit flex-col" key={`body-column-${partNumber.id}`}>
                  {draftProcess?.process_steps.map((_step, stepIndex) => {
                    const conditionResult = stepsTruthTable[partNumber.id][stepIndex] ?? false;
                    const isTransparent =
                      !showTruthTable || !previewPartNumber ? true : stepsTruthTable[previewPartNumber.id]?.[stepIndex] ?? false;
                    return (
                      <div
                        key={stepIndex}
                        className={`flex h-16 w-16 shrink-0 cursor-pointer items-center justify-center border-b border-r transition-opacity delay-150 duration-300 ${!isTransparent && "opacity-50"} ${partNumber.id === previewPartNumber?.id ? "bg-sky-50" : "bg-white"} ${!showTruthTable && "invisible opacity-0"}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPreviewPartNumber(partNumber);
                        }}
                      >
                        {conditionResult && <FontAwesomeIcon size="lg" className="text-green-500" icon={faCheckCircle} />}
                        {!conditionResult && <FontAwesomeIcon size="lg" className="text-serial-palette-500" icon={faMinusCircle} />}
                      </div>
                    );
                  })}
                  <div className="h-12 shrink-0" />
                </div>
              );
            })}
            <div className="flex h-fit min-w-0 flex-grow flex-col">
              {draftProcess?.process_steps.map((_step, stepIndex) => (
                <div
                  key={stepIndex}
                  className={`flex h-16 min-w-0 shrink-0 flex-grow bg-white border-b${!showTruthTable && "invisible opacity-0"}`}
                />
              ))}
            </div>
          </div>
        </div>
        {!readOnly && (
          <div className="absolute bottom-0 flex min-h-0 w-full flex-grow flex-nowrap items-end justify-start gap-2 border-t bg-white px-4 py-2">
            <p className="text-serial-palette-500 pt-0.5 text-sm">Show Filters</p>
            <div className="form-switch-sm">
              <input
                type="checkbox"
                id="process-builder-switch-enable-truth-table"
                className="sr-only"
                checked={showTruthTable}
                onChange={() => setShowTruthTable(!showTruthTable)}
              />
              <label className="bg-serial-palette-200" htmlFor="process-builder-switch-enable-truth-table">
                <span className="bg-white " aria-hidden="true"></span>
                <span className="sr-only">Switch label</span>
              </label>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProcessBuilderStepsSummary;
