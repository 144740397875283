import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import ComponentCard from "./components/ComponentCard";
import { useNavigate } from "react-router-dom";
import DropdownFilter from "@shared/components/dropdowns/DropdownFilter";
import ComponentModal from "../component/components/ComponentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faGripHorizontal, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ComponentTable from "./components/ComponentTable";
import { UserRole } from "@shared/types/databaseEnums";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { ComponentPageModal } from "../component/types";
import { RootState } from "@shared/redux/store";
import { Component } from "@shared/types/databaseTypes";

enum Tab {
  table = "TABLE",
  tiles = "TILES",
}

function Components() {
  const navigate = useNavigate();
  const role = useSelector((state: RootState) => state.auth.role);
  const components = useSelector((state: RootState) => state.db.components);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredComponentIds, setFilteredComponentIds] = useState<string[]>([]);
  const [filterOptions, setFilterOptions] = useState<{ [key: string]: boolean }>({ Active: true, Inactive: false });
  const [activeModal, setActiveModal] = useState<ComponentPageModal | null>(null);
  const [componentToEdit, setComponentToEdit] = useState<Component | null>(null);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.tiles);

  const searchRef = useRef<HTMLInputElement>(null);

  const observe = useContext(ObservabilityContext);

  const toggleFilterOption = (option: string) => {
    var modifiedOptions = { ...filterOptions };
    modifiedOptions[option] = !filterOptions[option];
    setFilterOptions(modifiedOptions);
  };

  const breadcrumbs = [
    {
      label: "Components",
      link: "/componentslist",
    },
  ];

  const handleEdit = (componentId: string) => {
    const selectedComponent = components.find((comp) => comp.id === componentId) ?? null;
    setComponentToEdit(selectedComponent);
    if (selectedComponent) {
      setActiveModal(ComponentPageModal.ComponentSettings);
    }
    observe.track("Edit Component", { "UI Source": "Components List" });
  };

  // update filtered components based on search term
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredComponentIds(components.map((comp) => comp.id));
    } else {
      const filteredComponents = components.filter((comp) => comp.name.toLowerCase().includes(searchTerm.toLowerCase()));
      setFilteredComponentIds(filteredComponents.map((comp) => comp.id));
    }
  }, [components, searchTerm]);

  // fucus on search input on page load
  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="flex h-screen w-full flex-col overflow-hidden">
        {/*  Site header */}
        <Header breadcrumbs={breadcrumbs} />

        <main className="flex min-h-0 min-w-0 flex-grow overflow-auto">
          <div className="w-full px-8 py-8">
            {/* Page header */}
            <div className="mb-8 gap-8 sm:flex sm:items-center sm:justify-between ">
              {/* Left: Title */}
              <div className="mb-4 flex w-full sm:mb-0">
                <div className="relative flex w-full cursor-pointer gap-2 overflow-hidden rounded border transition-all">
                  <input
                    ref={searchRef}
                    className="form-input w-full border-none pl-9"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm || ""}
                  ></input>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="text-serial-palette-400 absolute left-3 top-1/2 -translate-y-1/2 transform"
                  />
                </div>
                {/* <h1 className="text-2xl md:text-3xl text-serial-palette-800 font-bold">Components</h1> */}
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                {/* Table View */}
                <div className="flex flex-wrap -space-x-px">
                  <button
                    className={`btn h-10 px-4 ${selectedTab === Tab.tiles ? "bg-serial-palette-800 text-white" : "text-serial-palette-600 bg-white"} hover:bg-serial-palette-800 border-serial-palette-200 rounded-none first:rounded-l-md first:border-r-transparent last:rounded-r-md hover:text-white`}
                    onClick={() => setSelectedTab(Tab.tiles)}
                  >
                    <FontAwesomeIcon icon={faGripHorizontal} size="lg" />
                  </button>
                  <button
                    className={`btn h-10 px-4 ${selectedTab === Tab.table ? "bg-serial-palette-800 text-white" : "text-serial-palette-600 bg-white"} hover:bg-serial-palette-800 border-serial-palette-200 rounded-none first:rounded-l-md first:border-r-transparent last:rounded-r-md hover:text-white`}
                    onClick={() => setSelectedTab(Tab.table)}
                  >
                    <FontAwesomeIcon icon={faBars} size="lg" />
                  </button>
                </div>
                {/* Inactive Filter */}
                <DropdownFilter
                  classOverride=""
                  align={role === UserRole.Admin ? "left" : "right"}
                  filterOptions={filterOptions}
                  toggleFilterOption={toggleFilterOption}
                />
                {/* Add New Component Button */}
                {role === UserRole.Admin && (
                  <button
                    className="btn bg-serial-palette-800 hover:bg-serial-palette-600 text-white"
                    onClick={() => navigate("/component/new")}
                  >
                    <svg className="h-4 w-4 shrink-0 fill-current" viewBox="0 0 16 16">
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="xs:block ml-2 hidden">Add Component</span>
                  </button>
                )}
              </div>
            </div>

            {/* Cards */}
            {selectedTab === Tab.tiles && (
              <div className="grid grid-cols-12 gap-6">
                {components
                  .filter(
                    (component) => (component.is_active && filterOptions["Active"]) || (!component.is_active && filterOptions["Inactive"]),
                  )
                  .filter((component) => filteredComponentIds.includes(component.id))
                  .map((component) => {
                    return <ComponentCard key={component.id} component={component} handleEdit={handleEdit} />;
                  })}
              </div>
            )}

            {/* Table */}
            {selectedTab === Tab.table && (
              <div className="shadow-xs w-full overflow-hidden rounded-lg">
                <ComponentTable
                  components={components
                    .filter(
                      (component) =>
                        (component.is_active && filterOptions["Active"]) || (!component.is_active && filterOptions["Inactive"]),
                    )
                    .filter((component) => filteredComponentIds.includes(component.id))}
                />
              </div>
            )}

            <ComponentModal activeModal={activeModal} setActiveModal={setActiveModal} selectedComponent={componentToEdit} />
          </div>
        </main>
      </div>
    </div>
  );
}

export default Components;
