import { MeasureKey, MeasureValue, MeasureType, MeasureValueLink } from "../types";
import { DatetimeData } from "@shared/types/databaseTypes";

export const isSameMeasureKey = (a: MeasureKey, b: MeasureKey): boolean => {
  return a.type === b.type && a.component_id === b.component_id && a.process_id === b.process_id && a.dataset_id === b.dataset_id;
};

export const hashMeasureKey = (measureKey: MeasureKey): string => {
  return `${measureKey.type}-${measureKey.component_id}-${measureKey.process_id}-${measureKey.dataset_id}`;
};

export const extractTimestamp = (measureValues: MeasureValue[]): string => {
  if (!measureValues || measureValues.length === 0) return "";
  switch (measureValues[0].type) {
    case MeasureType.Link:
      return getFirstTimestampForLatestHasChildOfId(measureValues as MeasureValueLink[]) ?? "";
    case MeasureType.Datetime:
      return (measureValues[measureValues.length - 1].data as DatetimeData).value;
    case MeasureType.Timestamp:
      return measureValues[measureValues.length - 1].timestamp;
    default:
      return "";
  }
};

function getFirstTimestampForLatestHasChildOfId(data: MeasureValueLink[]) {
  if (!data || data.length === 0) return null;

  data.sort((a, b) => a.timestamp.localeCompare(b.timestamp));

  // Get the latest has_child_of_id
  const latestHasChildOfId = data[data.length - 1].data.has_child_of_id;

  // Find the index of the first instance of the last sequence of the latest has_child_of_id
  let firstTimestamp = null;
  let foundSequence = false;
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i].data.has_child_of_id !== latestHasChildOfId) {
      if (foundSequence) {
        // We found the end of the sequence, now we get the timestamp of the first instance in this sequence
        for (let j = i + 1; j < data.length; j++) {
          if (data[j].data.has_child_of_id === latestHasChildOfId) {
            firstTimestamp = data[j].data.process_entry?.timestamp;
            break;
          }
        }
        break;
      }
    } else {
      foundSequence = true;
    }
  }

  // If the entire array consists of the latest has_child_of_id, return the first instance
  if (!firstTimestamp && foundSequence) {
    firstTimestamp = data[0].timestamp;
  }

  return firstTimestamp ?? "";
}
