import React, { useContext, useMemo } from "react";
import { ProductionContext } from "../ProductionProvider";
import ModalBasic from "@shared/components/modals/ModalBasic";
import { WorkInstructionBlock, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import ProductionWorkInstructionBlockHeading from "./production-work-instruction-blocks/ProductionWorkInstructionBlockHeading";
import ProductionWorkInstructionBlockText from "./production-work-instruction-blocks/ProductionWorkInstructionBlockText";
import ProductionWorkInstructionBlockList from "./production-work-instruction-blocks/ProductionWorkInstructionBlockList";
import ProductionWorkInstructionBlockCallout from "./production-work-instruction-blocks/ProductionWorkInstructionBlockCallout";
import ProductionWorkInstructionBlockCode from "./production-work-instruction-blocks/ProductionWorkInstructionBlockCode";
import ProductionWorkInstructionBlockDelimiter from "./production-work-instruction-blocks/ProductionWorkInstructionBlockDelimiter";
import ProductionWorkInstructionBlockFileGeneric from "./production-work-instruction-blocks/ProductionWorkInstructionBlockFile";
import ProductionWorkInstructionBlockTable from "./production-work-instruction-blocks/ProductionWorkInstructionBlockTable";

interface ProductionFormWorkInstructionBodyProps {
  workInstructionBlocks: WorkInstructionBlock[];
}

const ProductionFormWorkInstructionBody: React.FC<ProductionFormWorkInstructionBodyProps> = ({ workInstructionBlocks }) => {
  return (
    <div className="lg: flex h-full w-full flex-col gap-y-3 overflow-auto p-6 py-10 lg:px-16">
      {workInstructionBlocks.map((workInstructionBlock, index) => {
        return (
          <React.Fragment key={index}>
            {workInstructionBlock.type === WorkInstructionBlockType.Heading && (
              <ProductionWorkInstructionBlockHeading block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.Text && (
              <ProductionWorkInstructionBlockText block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.List && (
              <ProductionWorkInstructionBlockList block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.Callout && (
              <ProductionWorkInstructionBlockCallout block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.Code && (
              <ProductionWorkInstructionBlockCode block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.Delimiter && (
              <ProductionWorkInstructionBlockDelimiter block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.Image && (
              <ProductionWorkInstructionBlockFileGeneric block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.PDF && (
              <ProductionWorkInstructionBlockFileGeneric block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.Video && (
              <ProductionWorkInstructionBlockFileGeneric block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.File && (
              <ProductionWorkInstructionBlockFileGeneric block={workInstructionBlock} />
            )}
            {workInstructionBlock.type === WorkInstructionBlockType.Table && (
              <ProductionWorkInstructionBlockTable block={workInstructionBlock} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
const ProductionStepWorkInstruction: React.FC = () => {
  const { workInstructionsOpen, setWorkInstructionsOpen, selectedStepIndex, filteredSteps } = useContext(ProductionContext);

  const workInstructionBlocks = useMemo(() => {
    return filteredSteps[selectedStepIndex]?.work_instruction_blocks ?? [];
  }, [filteredSteps, selectedStepIndex]);

  return (
    <>
      {/* Sidebar (xl+) */}
      <div className="hidden w-full border-r bg-white lg:flex">
        <ProductionFormWorkInstructionBody workInstructionBlocks={workInstructionBlocks} />
      </div>
      {/* Modal (xs / sm / md / lg) */}
      <div className="lg:hidden">
        <ModalBasic
          id="work-instruction-modal"
          title="Work Instructions"
          modalOpen={workInstructionsOpen}
          setModalOpen={setWorkInstructionsOpen}
          classOverride="w-full h-full"
          closeOnClickOutside={false}
        >
          <ProductionFormWorkInstructionBody workInstructionBlocks={workInstructionBlocks} />
        </ModalBasic>
      </div>
    </>
  );
};

export default ProductionStepWorkInstruction;
