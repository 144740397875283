import store from "@shared/redux/store";
import { ParsedResponse } from "@shared/types/apiTypes";
export const makeFetchRequest = async (
  url: string,
  options: RequestInit,
  defaultErrorMsg: string,
  // TODO: Bring the timeout value down to a reasonable value
  timeout: number = 30000,  // Default timeout of 30000ms (30 seconds)
  token?: string,
): Promise<ParsedResponse> => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeout);

  const state = store.getState();

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,  // Attach the signal to the fetch options
      headers: {
        Authorization: `Bearer ${token ?? state.auth.token}`,
        ...(options.headers || {}),
      },
    });
    clearTimeout(timeoutId); // Clear the timeout if the request succeeds

    if (response.ok) {
      const data = await response.json();
      return { data, error: "" };
    }
    const errorMessage = (await response.text()) || defaultErrorMsg;
    console.error(errorMessage, response.status, response.statusText);
    return { data: null, error: errorMessage };
  } catch (error) {
    clearTimeout(timeoutId); // Clear the timeout in case of an error

    let errorMessage: string;

    if (error instanceof Error) {
      // If the error is an instance of the Error object, you can access the message
      errorMessage = error.message;
    } else {
      // If the error is not an instance of Error, you can use a fallback message
      errorMessage = defaultErrorMsg;
    }

    console.error(errorMessage);
    return { data: null, error: errorMessage };
  }
};

