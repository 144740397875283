import { v4 as uuidv4 } from "uuid";
import { createRoot } from "react-dom/client";
import { toPng } from "html-to-image";
import { PX_PER_MM } from "@shared/labels/constants";
import { LabelFormatWithContents } from "../types";
import LabelFormatterLabelElement from "../components/LabelFormatterLabelElement";

export const generateLabelPreviewImageUrl = async (
  labelFormat: LabelFormatWithContents,
  labelIdentifier: string,
): Promise<string | undefined> => {
  try {
    const labelId = uuidv4();
    const container = document.createElement("div");
    // hide the container off-screen
    container.style.position = "absolute";
    container.style.top = "-9999px";
    document.body.appendChild(container);
    const root = createRoot(container);
    root.render(
      <div
        id={labelId}
        className="relative flex shrink-0 overflow-hidden bg-white"
        style={{
          width: `${labelFormat.width * PX_PER_MM}px`,
          height: `${labelFormat.height * PX_PER_MM}px`,
        }}
      >
        {labelFormat.contents?.map((element, index) => (
          <LabelFormatterLabelElement key={index} element={element} label={labelFormat} identifier={labelIdentifier} />
        ))}
      </div>,
    );
    // Wait for the label to render. Must be non-zero to allow the QR code time to generate and render (since it's an image)
    await new Promise((resolve) => setTimeout(resolve, 50));
    // generate the image
    const label = document.getElementById(labelId);
    if (!label) throw new Error("Could not find label element");
    const dataUrl = await toPng(label, { fontEmbedCSS: "" });
    // remove the label element from the DOM
    root.unmount();
    document.body.removeChild(container);
    return dataUrl;
  } catch (error) {
    console.error("Error generating label preview image URL", error);
    return undefined;
  }
};
