import store from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { UniqueIdentifier } from "@shared/types/databaseTypes";

export const fetchWorkOrderProcessEntries = async (work_order_id: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);
  const { data, error } = (await supabase.from("unique_identifiers").select("*").eq("work_order_id", work_order_id)) as any;
  if (!error) {
    return data as UniqueIdentifier[];
  }
  console.log(error);
  return [];
};
