import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";

// ------------------- WRITE FUNCTIONS ------------------ //

// update component data
export const updateComponent = async (componentData, newComponentAvatar) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // New component payload
    const componentChanges = {
      name: componentData.name.trim(),
      component_type: componentData.component_type,
      last_edited_user_id: state.auth.supabase_uid,
      last_edited_at: new Date().toISOString(),
      is_active: componentData.is_active,
      // id, created_at, should never be updated
    };

    const { data: updatedComponent, error } = await supabase
      .from("components")
      .update(componentChanges)
      .eq("id", componentData.id)
      .select()
      .single();

    // update avatar if one exists
    if (newComponentAvatar) {
      await upsertComponentAvatar(newComponentAvatar, updatedComponent.id);
    }

    // Handle database error, else refresh components in redux and return inserted component
    if (error) {
      console.log(error);
      return null;
    } else {
      return updatedComponent;
    }
  } catch (error) {
    // Handle request error
    console.error(error);
    return null;
  }
};

// upsert component avatar
export const upsertComponentAvatar = async (avatarUrl, componentId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const response = await fetch(avatarUrl);
    const blob = await response.blob();
    const { error: errorFileUpload } = await supabase.storage
      .from("component-avatars")
      .upload(`${state.db.company.id}/${componentId}`, blob, { cacheControl: 0, upsert: true });
    if (errorFileUpload) {
      console.error(errorFileUpload);
    }
  } catch (error) {
    // Handle request error
    console.error(error);
    return null;
  }
};

export const deleteComponent = async (componentId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const { error: deleteError } = await supabase.rpc("delete_component", { p_component_id: componentId });
    // supabase will return an error if there ar foreign keys associated with this component
    if (deleteError) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
