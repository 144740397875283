import Loader from "@shared/components/primitives/Loader";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";

const FrontendPrimitivesLoaders = () => {
  return (
    <FrontendPrimitivesCard title="Loaders">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Loader Sizes</div>
        <div className="flex gap-2">
          <Loader size="xs" />
          <Loader size="sm" />
          <Loader size="md" />
          <Loader size="lg" />
          <Loader size="xl" />
          <Loader size="2xl" />
          <Loader size="3xl" />
          <Loader size="4xl" />
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesLoaders;
