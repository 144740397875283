import TypeTag from "@shared/components/TypeTag";
import React, { useMemo } from "react";
import { Handle, Position } from "reactflow";
import { Component, Dataset, Process } from "@shared/types/databaseTypes";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { COMPONENT_NODE_HEADER_HEIGHT_CLASS_NAME } from "./constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faArrowDown, faArrowUp, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDataTypeCount from "@shared/hooks/useDataTypeCount";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { UserRole } from "@shared/types/databaseEnums";

interface ComponentNodeData {
  component: Component;
  processes?: Process[];
  datasets?: Dataset[];
  hideSourceHandle?: boolean;
  focused?: boolean;
  collapsedComponents?: { [componentId: string]: boolean };
  setCollapsedComponents?: React.Dispatch<React.SetStateAction<{ [componentId: string]: boolean }>>;
  handleFocusComponent?: (componentId?: string) => void;
  handleEditComponent?: (componentId?: string) => void;
  handleAddProcess?: (componentId?: string) => void;
}

const ComponentNode: React.FC<{ data: ComponentNodeData }> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const { tags, dataTypeCounts } = useDataTypeCount({ datasets: data.datasets ?? [] });

  const { manualProcessCount, apiProcessCount } = useMemo(() => {
    return {
      manualProcessCount: data.processes?.filter((process) => !process.use_api).length,
      apiProcessCount: data.processes?.filter((process) => process.use_api).length,
    };
  }, [data.processes]);

  const handleSetCollapsed = (expanded: boolean) => {
    if (data.collapsedComponents && data.setCollapsedComponents) {
      data.setCollapsedComponents((prevExpandedComponents) => {
        const newExpandedComponents = { ...prevExpandedComponents };
        newExpandedComponents[data.component.id] = expanded;
        return newExpandedComponents;
      });
    }
  };

  return (
    <>
      <div
        className={`border-serial-palette-300 relative flex h-full w-full flex-col overflow-hidden rounded-md border-2 hover:shadow-lg ${data.focused && "drop-shadow-2xl"}`}
      >
        <div
          onClick={() => data.handleFocusComponent && data.handleFocusComponent(data.component.id)}
          className={`border-serial-palette-300 component-node-drag-handle group relative flex w-full shrink-0 cursor-pointer items-center gap-2 rounded-t-md border-b-2 bg-white px-2 ${COMPONENT_NODE_HEADER_HEIGHT_CLASS_NAME}`}
        >
          <ComponentAvatar component={data.component} size="sm" />
          <div className="flex flex-col overflow-hidden pb-1">
            <div
              className={`truncate text-sm font-bold ${!location.pathname.includes(data.component.id) && "cursor-pointer hover:underline"}`}
              onMouseDownCapture={() => navigate(`/component/${data.component.id}`)}
            >
              {data.component.name}
            </div>
            <TypeTag type={data.component.component_type} className="h-[14px] w-12 text-[8px]" />
          </div>
          {data.handleEditComponent && (
            <div
              className="btn bg-serial-palette-50 border-serial-palette-200 hover:border-serial-palette-300 absolute bottom-1.5 right-1.5 hidden h-7 w-7 cursor-pointer rounded-full hover:border-2 group-hover:flex"
              onMouseDownCapture={() => data.handleEditComponent && data.handleEditComponent(data.component.id)} // use onMouseDownCapture to prevent the process builder failing to open when clicking and dragging slightly
            >
              <FontAwesomeIcon icon={faEdit} size="xs" className="text-serial-palette-500" />
            </div>
          )}
        </div>
        <div className="relative flex h-full w-full cursor-default justify-start rounded-b-md">
          <div
            className={`absolute -z-10 h-full w-full opacity-80 ${data.collapsedComponents?.[data.component.id] ? "bg-white" : "bg-serial-palette-100"}`}
          />
          <div className="absolute right-2.5 top-1.5">
            <button
              className="btn-xs serial-btn-dark h-[18px] gap-1 text-[8px]"
              onClick={() => handleSetCollapsed(!data.collapsedComponents?.[data.component.id])}
            >
              <FontAwesomeIcon icon={data.collapsedComponents?.[data.component.id] ? faArrowDown : faArrowUp} />
              <span>{data.collapsedComponents?.[data.component.id] ? "Expand" : "Collapse"}</span>
            </button>
          </div>
          {data.collapsedComponents?.[data.component.id] && (
            <div className="flex flex-col gap-1 px-2.5 py-2">
              <div
                className="text-sm font-bold hover:cursor-pointer hover:underline"
                onClick={() => handleSetCollapsed(true)}
              >{`${data.processes?.length ?? 0} Total Processes`}</div>
              <div className="flex gap-0.5">
                <div className="flex w-fit items-center overflow-clip rounded-md border bg-white text-[10px]">
                  <div className="bg-serial-palette-100 flex h-full items-center px-2 font-semibold">Manual</div>
                  <div className="text-serial-palette-600 px-2 font-medium">{manualProcessCount}</div>
                </div>
                <div className="flex w-fit items-center overflow-clip rounded-md border bg-white text-[10px]">
                  <div className="bg-serial-palette-100 flex h-full items-center px-2 font-semibold">API</div>
                  <div className="text-serial-palette-600 px-2 font-medium">{apiProcessCount}</div>
                </div>
              </div>
              <Link
                to={`/datasets?component_id=${data.component.id}`}
                className="pt-2 text-sm font-bold hover:cursor-pointer hover:underline"
              >{`${Object.values(dataTypeCounts).reduce((acc, count) => acc + count, 0)} Total Datasets`}</Link>
              {tags}
            </div>
          )}
        </div>
        {!data.hideSourceHandle && <Handle type="source" position={Position.Left} isConnectable={false} />}
        {data.collapsedComponents?.[data.component.id] && (
          <Handle id="linkhandle" type="target" position={Position.Right} isConnectable={false} />
        )}
      </div>
      {data.handleAddProcess && !data.collapsedComponents?.[data.component.id] && currentUser?.role === UserRole.Admin && (
        <div className="group absolute -bottom-4 flex w-full cursor-default justify-center py-2">
          <button
            tabIndex={-1}
            className="btn btn-xs serial-btn-dark m-0 mr-0.5 hidden h-[15px] w-fit gap-x-1 whitespace-nowrap rounded text-[8px] group-hover:flex"
            onClick={() => data.handleAddProcess && data.handleAddProcess(data.component.id)}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Process
          </button>
          <div className="serial-btn-dark group -bottom-4 flex justify-center rounded-full p-0.5 text-[8px] group-hover:hidden">
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
      )}
    </>
  );
};

export default ComponentNode;
