import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import AvatarModal from "@shared/components/modals/AvatarModal";
import UserAvatar from "@shared/components/UserAvatar";
import { Loader } from "@shared/components/Loader";
import { ToastContext } from "@shared/context/ToastProvider";
import { handleForgotPassword } from "@shared/connections/supabaseAuth";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { useNavigate } from "react-router-dom";

function Account() {
  const defaultUser = {
    id: undefined,
    company_id: undefined,
    first_name: undefined,
    last_name: undefined,
    email: undefined,
  };

  const [avatarModal, setAvatarModal] = useState(false);
  const supabase_uid = useSelector((state) => state.auth.supabase_uid);
  const user = useSelector((state) => state.db.users.find((usr) => usr.supabase_uid === supabase_uid) || defaultUser);
  const toastContext = useContext(ToastContext);
  const observe = useContext(ObservabilityContext);
  const navigate = useNavigate();

  return (
    <div className="flex grow flex-col justify-between">
      {/* Panel body */}
      <div className="flex flex-col space-y-6 p-6">
        {/* <h2 className="text-2xl font-bold mb-5">My Account</h2> */}
        {/* Picture */}
        <section>
          <div className="flex items-center">
            <div className="mr-4">{user.supabase_uid && <UserAvatar user={user} size="xl" />}</div>
            <button
              className="btn-sm bg-serial-palette-800 hover:bg-serial-palette-600 text-white"
              onClick={(e) => {
                e.stopPropagation();
                setAvatarModal(true);
                observe.track("Upload Avatar", { "UI Source": "User" });
              }}
            >
              Change
            </button>
          </div>
        </section>
        {/* Business Profile */}
        <section>
          <h2 className="text-serial-palette-800 mb-1 text-xl font-bold leading-snug">Profile</h2>
          <div className="mt-5 space-y-4 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
            <div className="sm:w-1/3">
              <label className="mb-1 block font-semibold" htmlFor="name">
                First Name
              </label>
              <input
                id="first_name"
                disabled
                className="form-input bg-serial-palette-50 w-full"
                type="text"
                defaultValue={user && user.first_name}
              />
            </div>
            <div className="sm:w-1/3">
              <label className="mb-1 block font-semibold" htmlFor="business-id">
                Last Name
              </label>
              <input
                id="last_name"
                disabled
                className="form-input bg-serial-palette-50 w-full"
                type="text"
                defaultValue={user && user.last_name}
              />
            </div>
          </div>
          <div className="mt-5 space-y-4 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
            <div className="sm:w-1/3">
              <label className="mb-1 block font-semibold" htmlFor="location">
                Email Address
              </label>
              <input
                id="email_address"
                disabled
                className="form-input bg-serial-palette-50 w-full"
                type="text"
                defaultValue={user && user.email}
              />
            </div>
          </div>
        </section>
        {/* Password */}
        <section>
          <h2 className="text-serial-palette-800 mb-1 text-xl font-bold leading-snug">Password</h2>
          <div className="mt-5">
            <button className="btn border-serial-palette-200 text-serial-palette-800  h-10" onClick={() => navigate("/auth/resetpassword")}>
              Change Password
            </button>
          </div>
        </section>
      </div>
      {/* Panel footer */}
      <footer className="hidden">
        <div className="border-serial-palette-200 flex flex-col border-t px-6 py-5">
          <div className="flex self-end">
            <button className="btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600">Cancel</button>
            <button className="btn bg-serial-palette-800 hover:bg-serial-palette-600 ml-3 text-white">Save Changes</button>
          </div>
        </div>
      </footer>
      <AvatarModal id={user.supabase_uid} modalOpen={avatarModal} setModalOpen={setAvatarModal} />
    </div>
  );
}

export default Account;
