import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ReportsProvider, { ReportsContext } from "./ReportsProvider";
import Sidebar from "../../shared/components/Sidebar";
import Header from "../../shared/components/Header";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import Report from "./components/Report";

const defaultBreadcrumbs = [
  {
    label: "Reports",
    link: "/reports",
  },
];

function Reports() {
  return (
    <ReportsProvider>
      <ReportsWithContext />
    </ReportsProvider>
  );
}

function ReportsWithContext() {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(defaultBreadcrumbs);

  const { reports, selectedReport, setSelectedReport } = useContext(ReportsContext);

  useEffect(() => {
    if (location.pathname.includes("/reports/")) {
      const reportId = location.pathname.split("/reports/")[1];
      const newSelectedReport = reports.find((report) => report.id === reportId);
      setSelectedReport(newSelectedReport ?? null);
      // Update breadcrumbs if the dashboard exists
      if (newSelectedReport) {
        const newBreadcrumbs = [
          ...defaultBreadcrumbs,
          {
            label: newSelectedReport.name ?? "New Dashboard",
            link: `/reports/${newSelectedReport.name}`,
          },
        ];
        setBreadcrumbs(newBreadcrumbs);
      }
    } else {
      setBreadcrumbs(defaultBreadcrumbs);
    }
  }, [location.pathname, selectedReport]);

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="scrollbar-hide relative flex h-full flex-1 flex-col overflow-x-hidden">
        <Header breadcrumbs={breadcrumbs} />
        <Report />
      </div>
    </div>
  );
}

export default Reports;
