import { ParsedResponse } from "@shared/types/apiTypes";
import { Process, ProcessWithReferences } from "@shared/types/databaseTypes";
import { getRpcWithRetries } from "./supabaseGeneral";
import store from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";

export const getProcessWithReferences = async (
  processId: string,
  processRevision?: number,
  forceLatestRevision?: boolean,
): Promise<ParsedResponse<ProcessWithReferences>> => {
  const args: {
    process_id: string;
    process_revision?: number;
    force_latest_revision?: boolean;
  } = {
    process_id: processId,
  };
  if (processRevision) {
    args["process_revision"] = processRevision;
  }
  if (forceLatestRevision) {
    args["force_latest_revision"] = forceLatestRevision;
  }
  return await getRpcWithRetries<ProcessWithReferences>("get_process_with_references", args);
};

export const getLatestApprovedRevisionByProcessId = async (): Promise<{ [processId: string]: number }> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: processes, error: processesError } = await supabase
    .from("processes")
    .select("*")
    .eq("approved", true)
    .order("revision", { ascending: true })
    .returns<Process[]>();

  if (processesError || !processes) {
    console.error(processesError);
    return {};
  }

  const latestApprovedRevisions: { [processId: string]: number } = {};
  processes.forEach((process) => {
    latestApprovedRevisions[process.id] = process.revision;
  });

  return latestApprovedRevisions;
};
