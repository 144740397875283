import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import UserAvatar from "@shared/components/UserAvatar";
import TypeTag from "@shared/components/TypeTag";
import DropdownClassic from "@shared/components/dropdowns/DropdownClassic";
import moment from "moment";
import { ApprovalStatus, WorkOrderStatus } from "@shared/types/databaseEnums";
import { Approval, User, WorkOrder } from "@shared/types/databaseTypes";
import { WorkOrderContext } from "../WorkOrders";
import { Loader } from "@shared/components/Loader";
import { UserRole } from "@shared/types/databaseEnums";
import Tooltip from "@shared/components/Tooltip";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface WorkOrderApprovalsProps {
  workOrder: WorkOrder;
}

const blankApproval: Approval = {
  approver_user_id: "",
  requested_by_user_id: "",
  requested_at: "",
  status: null,
  last_updated_at: null,
};

const WorkOrderApprovals: React.FC<WorkOrderApprovalsProps> = ({ workOrder }) => {
  const [requestIsLoading, setRequestIsLoading] = useState<number | null>(null); // index of approver being removed
  const [removeIsLoading, setRemoveIsLoading] = useState<number | null>(null); // index of approver being removed
  const [newApprovers, setNewApprovers] = useState<Approval[]>([]);
  const users = useSelector((state: RootState) => state.db.users);
  const currUserId = useSelector((state: RootState) => state.auth.supabase_uid);

  const context = useContext(WorkOrderContext);
  const observe = useContext(ObservabilityContext);
  const role = useSelector((state: RootState) => state.auth.role);

  const handleSelectUser = (supabaseUid: string, index: number) => {
    const newApproval = { ...newApprovers[index] };
    newApproval.approver_user_id = supabaseUid;
    setNewApprovers([...newApprovers.slice(0, index), newApproval, ...newApprovers.slice(index + 1)]);
  };

  const handleRequestApproval = async (index: number) => {
    setRequestIsLoading(index);
    await context?.handleAddApprover(workOrder.id, newApprovers[index].approver_user_id);
    setNewApprovers([...newApprovers.slice(0, index), ...newApprovers.slice(index + 1)]);
    setRequestIsLoading(null);
    observe.track("work-order_approval-requested");
  };

  const handleRemoveApprover = async (approverUserId: string, index: number) => {
    setRemoveIsLoading(index);
    await context?.handleRemoveApprover(workOrder.id, approverUserId);
    setRemoveIsLoading(null);
    observe.track("work-order_approver-removed");
  };

  const handleSetApprovalStatus = async (approverUserId: string, status: ApprovalStatus | null, index: number) => {
    if (status === null) {
      setRemoveIsLoading(index);
    } else {
      setRequestIsLoading(index);
    }
    await context?.handleSetApproval(workOrder.id, approverUserId, status);
    setRemoveIsLoading(null);
    setRequestIsLoading(null);
    observe.track("work-order_approval-status-changed", { approvalStatus: status });
  };

  // reset new approvers when work order status changes
  useEffect(() => {
    setNewApprovers([]);
  }, [workOrder.status]);

  // automatically handleRequestApproval(index) when new approver is added
  // this is a little bit hack. There is still a button to request approval, but it is automatically clicked and changes to a loading state immediately
  useEffect(() => {
    for (let i = 0; i < newApprovers.length; i++) {
      if (newApprovers[i].approver_user_id !== "") {
        handleRequestApproval(i);
        break;
      }
    }
  }, [newApprovers]);

  return (
    <div className="border-serial-palette-200 flex h-[182px] w-full flex-col rounded-md border bg-white">
      <header className="flex items-center justify-between border-b py-2.5 pl-4 pr-2">
        <div className="inline-flex items-center">
          <h2 className="text-serial-palette-800 pr-2 font-semibold">Approvals</h2>
          <Tooltip position="right" size="md" content="Only serial administrators or members can be approvers">
            <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
          </Tooltip>
        </div>
        <div className="flex h-full flex-nowrap items-center gap-x-2">
          {role === UserRole.Admin && (workOrder.status === WorkOrderStatus.Draft || workOrder.status === WorkOrderStatus.Review) && (
            <button
              className="btn-sm bg-serial-palette-800 hover:bg-serial-palette-600 text-white"
              onClick={() => setNewApprovers([...newApprovers, blankApproval])}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="xs:block ml-2 hidden">Add Approver</span>
            </button>
          )}
        </div>
      </header>
      <div className="bg-serial-palette-50 flex h-full w-full gap-x-2 rounded-b-md p-3">
        {Object.values(workOrder.approvals)
          .sort((a, b) => new Date(a.requested_at).getTime() - new Date(b.requested_at).getTime())
          .map((approvalData: Approval, index: number) => {
            const user = users.find((u) => u.supabase_uid === approvalData.approver_user_id);
            return (
              <div key={index} className={`flex h-full w-44 flex-col justify-between rounded border bg-white p-3`}>
                <div className="flex w-full items-center gap-2">
                  <UserAvatar user={user} size="xs" />
                  <p className="text-serial-palette-700 text-sm font-bold">
                    {user?.first_name} {user?.last_name}
                  </p>
                </div>
                {workOrder.status !== WorkOrderStatus.Draft && (
                  <div className="flex w-full flex-col">
                    {approvalData.status ? (
                      <>
                        <p className="text-serial-palette-500 pb-1 text-[11px]">
                          {moment(approvalData.last_updated_at).format("MM/DD/YY HH:mma")}
                        </p>
                        <div className="flex justify-between">
                          <TypeTag type={approvalData.status} className="w-28 text-[11px]" />
                          {(role === UserRole.Admin || role === UserRole.Member) &&
                            currUserId === approvalData.approver_user_id &&
                            workOrder.status === WorkOrderStatus.Review && (
                              <button
                                className="btn-sm border-serial-palette-200 hover:border-serial-palette-300 h-7 w-7"
                                onClick={() => handleSetApprovalStatus(approvalData.approver_user_id, null, index)}
                              >
                                {removeIsLoading === index ? (
                                  <Loader styleOverride="w-[10px] h-[12px]" />
                                ) : (
                                  <FontAwesomeIcon icon={faTimes} className="text-red-600" />
                                )}
                              </button>
                            )}
                        </div>
                      </>
                    ) : (
                      <>
                        {currUserId === approvalData.approver_user_id ? (
                          <div className="flex gap-2">
                            {(role === UserRole.Admin || role === UserRole.Member) && requestIsLoading !== index && (
                              <button
                                className="btn-xs h-7 w-1/2 bg-green-600 text-white hover:bg-green-500"
                                onClick={() => handleSetApprovalStatus(approvalData.approver_user_id, ApprovalStatus.Approved, index)}
                              >
                                Approve
                              </button>
                            )}
                            {(role === UserRole.Admin || role === UserRole.Member) && requestIsLoading !== index && (
                              <button
                                className="btn-xs h-7 w-1/2 bg-red-600 text-white hover:bg-red-500"
                                onClick={() => handleSetApprovalStatus(approvalData.approver_user_id, ApprovalStatus.Rejected, index)}
                              >
                                Reject
                              </button>
                            )}
                            {(role === UserRole.Admin || role === UserRole.Member) && requestIsLoading === index && (
                              <div className="flex w-full items-center justify-center pb-2">
                                <Loader styleOverride="w-[18px] h-[18px]" />
                              </div>
                            )}
                          </div>
                        ) : (
                          <TypeTag type="PENDING" className="w-28 animate-pulse text-[11px]" />
                        )}
                      </>
                    )}
                  </div>
                )}
                {role === UserRole.Admin && workOrder.status === WorkOrderStatus.Draft && (
                  <div className="flex w-full justify-end">
                    <button
                      className="btn-sm border-serial-palette-200 hover:border-serial-palette-300 h-7 w-7"
                      onClick={() => handleRemoveApprover(approvalData.approver_user_id, index)}
                    >
                      {removeIsLoading === index ? (
                        <Loader styleOverride="w-[10px] h-[12px]" />
                      ) : (
                        <FontAwesomeIcon icon={faTimes} className="text-red-600" />
                      )}
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        {newApprovers?.map((approvalData: Approval, index: number) => {
          const user = users.find((u) => u.supabase_uid === approvalData.approver_user_id);
          return (
            <div key={index} className={`flex h-full w-44 flex-col justify-between rounded border bg-white p-3`}>
              <div className="flex w-full items-center justify-between gap-1">
                {user && <UserAvatar user={user} size="xs" />}
                <div className={`-ml-1 flex ${user ? "w-[118px]" : "w-full pl-2"}`}>
                  <DropdownClassic
                    className={` ${user ? "min-w-[118px]" : "min-w-full"} h-[32px] overflow-hidden truncate`}
                    dropdownClassName="w-[200px]"
                    options={[
                      { id: null, label: "User..." },
                      ...users
                        .filter((u: User) => u.role === (UserRole.Admin || u.role === UserRole.Member) && u.is_active)
                        .map((u: User) => ({ id: u.supabase_uid, label: `${u.first_name} ${u.last_name}` })),
                    ]}
                    selected={approvalData.approver_user_id || null}
                    setSelected={(supabaseUid: string) => handleSelectUser(supabaseUid, index)}
                  />
                </div>
              </div>
              <div className="flex w-full justify-end gap-1">
                {user && (
                  <button className="btn-xs h-7 w-full text-white" onClick={() => handleRequestApproval(index)}>
                    {requestIsLoading === index ? (
                      <Loader styleOverride="w-[14px] h-[14px]" />
                    ) : (
                      <span className="whitespace-nowrap text-xs">Request Approval</span>
                    )}
                  </button>
                )}
                <button
                  className="btn-sm border-serial-palette-200 hover:border-serial-palette-300 h-7 w-7"
                  onClick={() => setNewApprovers([...newApprovers.slice(0, index), ...newApprovers.slice(index + 1)])}
                >
                  {removeIsLoading === index ? (
                    <Loader styleOverride="w-[10px] h-[12px]" />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} className="text-red-600" />
                  )}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkOrderApprovals;
