import { useEffect, useState, useRef, useContext, useMemo } from "react";
import { Chart, LineController, LineElement, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import { Dataset } from "@shared/types/databaseTypes";
import { fetchRestestNumericalDatasetValuesWithTimestamp } from "../../connections/supabaseDataset";
import { Loader } from "@shared/components/Loader";
import useWindowSize from "@shared/hooks/useWindowSize";
import { faSortAmountDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { SnLookupContext } from "../../SnLookupProvider";

Chart.register(LineController, LineElement, PointElement, LinearScale, TimeScale, Tooltip);

enum XAxisFormat {
  Time = "TIME",
  Linear = "LINEAR",
}

interface DataOverTimeProps {
  selectedDataset: Dataset;
  chartYAxisBounds: [number | undefined, number | undefined];
  setChartYAxisBounds: React.Dispatch<React.SetStateAction<[number | undefined, number | undefined]>>;
}

const DataOverTime: React.FC<DataOverTimeProps> = ({ selectedDataset, chartYAxisBounds }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null); // Ref to store the chart instance
  const [retestValues, setRetestValues] = useState<{
    dates: string[];
    testNumbers: number[];
    values: number[];
    lsl: (number | null)[];
    usl: (number | null)[];
    unit: string[];
  }>({
    dates: [],
    testNumbers: [],
    values: [],
    lsl: [],
    usl: [],
    unit: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [xAxisFormat, setXAxisFormat] = useState<XAxisFormat>(XAxisFormat.Linear);
  const { height: windowHeight } = useWindowSize();
  const { selectedProcess, genealogy } = useContext(SnLookupContext);

  const processEntryIndexMap = useMemo(() => {
    return genealogy.find((g) => g.id === selectedProcess?.entries[0].unique_identifier_id)?.process_entry_index_map ?? {};
  }, [selectedProcess, genealogy]);

  useEffect(() => {
    const uniqueIdentifierId = selectedProcess?.entries[0]?.unique_identifier_id;
    if (!uniqueIdentifierId) return;
    const fetchData = async () => {
      setIsLoading(true);
      const result = await fetchRestestNumericalDatasetValuesWithTimestamp(selectedDataset.id, uniqueIdentifierId, processEntryIndexMap);
      setRetestValues(result);
      setIsLoading(false);
    };

    fetchData();
  }, [selectedDataset, selectedProcess]);

  useEffect(() => {
    // Make sure the canvas is available and the context is not null
    if (retestValues.values.length > 0 && canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");

      if (ctx) {
        // Ensure ctx is not null
        // Destroy existing chart instance if it exists to avoid "Canvas is already in use" error
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        if (isLoading) {
          return;
        }

        // Prepare datasets for the chart, including LSL and USL lines if they exist
        const datasets = [
          {
            label: "Test Values",
            data: retestValues.values,
            borderColor: "blue",
            backgroundColor: "blue",
            pointRadius: 3,
            borderWidth: 2,
            fill: false,
          },
          {
            label: "LSL",
            data: retestValues.lsl,
            borderColor: "red",
            backgroundColor: "transparent",
            pointRadius: 0,
            borderDash: [2, 3],
            borderWidth: 1,
            fill: false,
          },
          {
            label: "USL",
            data: retestValues.usl,
            borderColor: "red",
            backgroundColor: "transparent",
            pointRadius: 0,
            borderDash: [8, 3],
            borderWidth: 1,
            fill: false,
          },
        ];

        // Create a new chart instance with the datasets
        chartInstanceRef.current = new Chart(ctx, {
          type: "line",
          data: {
            labels: xAxisFormat === XAxisFormat.Time ? retestValues.dates : retestValues.testNumbers.map((num) => num.toString()),
            datasets,
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x:
                xAxisFormat === XAxisFormat.Time
                  ? {
                      type: "time",
                      time: {
                        parser: "YYYY-MM-DD HH:mm:ss",
                      },
                      position: "bottom",
                    }
                  : {
                      type: "linear",
                      position: "bottom",
                      ticks: {
                        stepSize: 1,
                      },
                      min: 1,
                      max: Math.max(Math.max(...retestValues.testNumbers), 2),
                    },
              y: {
                title: {
                  display: true,
                  text: `${selectedDataset.name} (${selectedDataset?.unit ?? retestValues.unit[0]})`,
                },
                beginAtZero: false,
                min: chartYAxisBounds[0],
                max: chartYAxisBounds[1],
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: function (context) {
                    if (context?.[0]?.dataset.label === "LSL" || context?.[0]?.dataset.label === "USL") {
                      return context[0].dataset.label;
                    }
                    return xAxisFormat === XAxisFormat.Time
                      ? moment(context[0].parsed.x).format("MM/DD/YY HH:mm:ss")
                      : `Test #: ${context[0].parsed.x}`;
                  },
                  label: function (context) {
                    return `${context.parsed.y.toFixed(2)}`;
                  },
                },
              },
              legend: {
                display: true,
                position: "bottom",
              },
            },
          },
        });
      }
    }

    // Cleanup function to destroy the chart instance when the component unmounts or before a new chart is created
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [retestValues, windowHeight, xAxisFormat, chartYAxisBounds]);

  return (
    <div className="border-serial-palette-200 flex h-full w-full flex-col overflow-hidden rounded-md border bg-white">
      <div className="border-serial-palette-200 flex h-12 items-center justify-between space-x-2 border-b pl-3 pr-2">
        <div className="flex gap-2">
          <div className="text-serial-palette-800 font-semibold">
            {xAxisFormat === XAxisFormat.Time ? "Values Over Time" : "Values By Process Entry #"}
          </div>
          {isLoading && <Loader styleOverride="h-6 w-6" />}
        </div>
        <div className="flex">
          <button
            className={`btn h-7 w-9 rounded-r-none ${xAxisFormat === XAxisFormat.Linear ? "serial-btn-dark" : "serial-btn-light"}`}
            onClick={() => setXAxisFormat(XAxisFormat.Linear)}
          >
            <FontAwesomeIcon icon={faSortAmountDesc} className="-rotate-90" />
          </button>
          <button
            className={`btn h-7 w-9 rounded-l-none ${xAxisFormat === XAxisFormat.Time ? "serial-btn-dark" : "serial-btn-light"}`}
            onClick={() => setXAxisFormat(XAxisFormat.Time)}
          >
            <FontAwesomeIcon icon={faClock} />
          </button>
        </div>
      </div>
      {!isLoading && chartYAxisBounds[0] !== undefined && chartYAxisBounds[1] !== undefined && (
        <div className="flex-grow">
          <canvas ref={canvasRef} className="p-3"></canvas>
        </div>
      )}
    </div>
  );
};

export default DataOverTime;
