import { useContext, useEffect, useState } from "react";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { PrinterStatus } from "@shared/context/LabelPrinterProvider";

interface DymoEnvironmentStatus {
  isBrowserSupported: boolean;
  isFrameworkInstalled: boolean;
  isWebServicePresent: boolean;
  errorDetails: string;
}

interface DymoPrinterStatus {
  printerType: string;
  name: string;
  modelName: string;
  isConnected: boolean;
  isLocal: boolean;
  printerUri: string;
  originalPrinterName: string;
  isTwinTurbo: boolean;
}

const useDymoPrinter = ({
  enabled,
}: {
  enabled: boolean;
}): {
  status: PrinterStatus;
  getStatus: () => void;
  printLabel: (labelXml: string) => Promise<boolean>;
  getPreviewUrl: (labelXml: string) => Promise<string | undefined>;
} => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [printers, setPrinters] = useState<DymoPrinterStatus[]>([]);
  const [status, setStatus] = useState<PrinterStatus>({ driverInstalled: false, printerConnected: false, readyToPrint: false });
  const [displayStatusToasts, setDisplayStatusToasts] = useState<boolean>(false);
  const [previousPreviewUrls, setPreviousPreviewUrls] = useState<{ [xml: string]: string }>({});
  const { triggerToast } = useContext(ToastContext);

  const initialize = () => {
    if (!initialized) {
      window.dymo?.label.framework.init();
      setInitialized(true);
    }
    setTimeout(() => {
      getStatus();
    }, 1000);
  };

  const getStatus = () => {
    try {
      const checkEnvironmentResponse: DymoEnvironmentStatus = window.dymo?.label.framework.checkEnvironment();
      const printers: DymoPrinterStatus[] = window.dymo?.label.framework.getPrinters();
      setPrinters(printers);
      // Check Environment
      if (checkEnvironmentResponse.isBrowserSupported === false) {
        if (displayStatusToasts)
          triggerToast(ToastType.Warning, "Browser not supported for Dymo printing", checkEnvironmentResponse.errorDetails);
        setDisplayStatusToasts(false);
        setStatus((prev) => {
          return { ...prev, driverInstalled: false, readyToPrint: false };
        });
        return;
      } else if (checkEnvironmentResponse.isFrameworkInstalled === false) {
        if (displayStatusToasts) triggerToast(ToastType.Warning, "Dymo framework not installed", checkEnvironmentResponse.errorDetails);
        setDisplayStatusToasts(false);
        setStatus((prev) => {
          return { ...prev, driverInstalled: false, readyToPrint: false };
        });
        return;
      } else if (checkEnvironmentResponse.isWebServicePresent === false) {
        if (displayStatusToasts) triggerToast(ToastType.Warning, "Dymo web service not present", checkEnvironmentResponse.errorDetails);
        setDisplayStatusToasts(false);
        setStatus((prev) => {
          return { ...prev, driverInstalled: false, readyToPrint: false };
        });
        return;
      }
      // Check Printers
      if (printers.length === 0) {
        if (displayStatusToasts)
          triggerToast(ToastType.Warning, "No Dymo printers found", "Please connect a Dymo printer to your computer");
        setDisplayStatusToasts(false);
        setStatus({ driverInstalled: true, printerConnected: false, readyToPrint: false });
        return;
      } else if (printers.length > 1) {
        if (displayStatusToasts) triggerToast(ToastType.Warning, "Multiple Dymo printers found", "Please connect one printer at a time");
        setDisplayStatusToasts(false);
        setStatus({ driverInstalled: true, printerConnected: false, readyToPrint: false });
        return;
      } else if (printers[0]?.isConnected !== true) {
        if (displayStatusToasts)
          triggerToast(ToastType.Error, "Dymo printer not connected", "Please connect the Dymo printer to your computer");
        setDisplayStatusToasts(false);
        setStatus({ driverInstalled: true, printerConnected: false, readyToPrint: false });
        return;
      }
      setStatus({ driverInstalled: true, printerConnected: true, readyToPrint: true });
      setDisplayStatusToasts(true);
    } catch (error) {
      console.warn("Error checking dymo printer status", error);
      setInitialized(false);
      setStatus({ driverInstalled: false, printerConnected: false, readyToPrint: false });
    }
  };

  useEffect(() => {
    if (!enabled) return;
    initialize();
  }, [enabled]);

  const getPreviewUrl = async (labelXml: string): Promise<string | undefined> => {
    try {
      if (!enabled) {
        triggerToast(ToastType.Error, "Error getting label preview", "Dymo integration is not enabled");
        return undefined;
      }
      if (!initialized) {
        return undefined;
      }
      if (!status.driverInstalled) {
        return undefined;
      }
      if (previousPreviewUrls[labelXml]) {
        return previousPreviewUrls[labelXml];
      }
      const base64Encoding = window.dymo?.label.framework.renderLabel(labelXml, "", ""); // renderParamsXml and printerName are intentionally empty strings
      const base64Png = `data:image/png;base64,${base64Encoding}`;
      setPreviousPreviewUrls({ ...previousPreviewUrls, [labelXml]: base64Png });
      return base64Png;
    } catch (error) {
      console.error("Error getting dymo label preview", error);
      triggerToast(ToastType.Error, "Error getting label preview", "An error occurred while getting the label preview");
      return undefined;
    }
  };

  const printLabel = async (labelXml: string): Promise<boolean> => {
    if (!enabled) {
      triggerToast(ToastType.Error, "Error getting label preview", "Dymo integration is not enabled");
      return false;
    }
    if (!status.readyToPrint) {
      triggerToast(ToastType.Error, "Cannot print label", "Printer is not ready to print");
      return false;
    }
    if (!printers[0]?.isConnected || !printers[0]?.name) {
      triggerToast(ToastType.Error, "Cannot print label", "Printer is not connected");
      return false;
    }
    try {
      window.dymo?.label.framework.printLabel(printers[0].name, "", labelXml, ""); // printParamsXml and labelSetXml are intentionally empty strings
      // wait for 1 second before returning to allow the print job to be sent to the printer and errors to be caught
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return true;
    } catch (error) {
      console.error("Error printing dymo label", error);
      triggerToast(ToastType.Error, "Error printing label", "An error occurred while printing the label");
      return false;
    }
  };

  return {
    status,
    getStatus,
    printLabel,
    getPreviewUrl,
  };
};

export default useDymoPrinter;
