import React, { useContext } from "react";
import ContentEditable from "@shared/components/ContentEditable";
import { ImageMarkupLabelElementProps } from "./ImageMarkupElementContainer";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import { ImageMarkupElementText as TextElement } from "@shared/types/databaseTypes";

const ImageMarkupElementText: React.FC<ImageMarkupLabelElementProps<TextElement>> = ({ element, index }) => {
  const { handleEditElement, editing, imageHeight } = useContext(ImageMarkupContext);

  const contentRef = React.useRef<HTMLDivElement>(null);

  const text = element.text ?? "";

  if (editing)
    return (
      <ContentEditable
        contentRef={contentRef}
        content={element.text}
        onInput={(e: React.FormEvent<HTMLDivElement>) => handleEditElement(index, { text: e.currentTarget.innerText })}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          // if content length is non zero, stop propagation to prevent deleting element
          if (e.key === "Backspace" && text.length > 0) e.stopPropagation();
        }}
        className="whitespace-nowrap rounded outline-none"
        style={{
          fontSize: `${Math.abs(element.height) * imageHeight}px`,
          fontWeight: element.bold ? "bold" : "normal",
          fontStyle: element.italic ? "italic" : "normal",
          textDecoration: element.underline ? "underline" : "none",
          color: element.color,
          paddingRight: `${0.15 * element.height * imageHeight}px`,
          paddingLeft: `${0.15 * element.height * imageHeight}px`,
          backgroundColor: element.fill ? element.fill : "transparent",
        }}
        placeholder="Text"
      />
    );

  return (
    <div
      className="whitespace-nowrap "
      style={{
        fontSize: `${Math.abs(element.height) * imageHeight}px`,
        fontWeight: element.bold ? "bold" : "normal",
        fontStyle: element.italic ? "italic" : "normal",
        textDecoration: element.underline ? "underline" : "none",
        color: element.color,
        paddingRight: `${0.15 * element.height * imageHeight}px`,
        paddingLeft: `${0.15 * element.height * imageHeight}px`,
        backgroundColor: element.fill ? element.fill : "transparent",
      }}
    >
      {element.text}
    </div>
  );
};

export default ImageMarkupElementText;
