// The supabase single() and maybeSingle() modifieres don't work as desired as of Feb 2023
// They throw an error which cannot be caught instead of returning the error
// Wrap supabase read requests in this function to return an error for non existant rows instead of throwing one
export const supabaseSingleOverride = (response, allowZero = false) => {
  let { data, error } = response;
  if (data?.length > 0) {
    return { data: data[0], error: error };
  } else if (data?.length === 0 && !allowZero) {
    return { data: null, error: "no such row found in database" };
  }
  return { data, error };
};

export const snLookupTestCount = (processArray) => {
  const result = {
    numPass: 0,
    numFail: 0,
    numNull: 0,
  };
  if (processArray.length > 0) {
    processArray.map((process) => {
      if (process.is_pass === true) {
        result.numPass++;
      } else if (process.is_pass === false) {
        result.numFail++;
      } else {
        result.numNull++;
      }
    });
  }

  return result;
};

// Group fields by group name and type
export const groupFieldIndices = (fields, showInactive) => {
  if (showInactive === undefined) showInactive = false;
  let result = []; // initialize empty result array
  let group = []; // initialize empty group array to hold fields belonging to the current group

  for (let i = 0; i < fields.length; i++) {
    const currField = fields[i];
    const prevField = fields[i - 1];
    if (!showInactive && !currField.is_active) continue; // skip inactive fields (if showInactive is false
    if (currField.type !== "FILE" && currField.type !== "IMAGE") {
      if (currField?.group_name === prevField?.group_name && currField?.type === prevField?.type) {
        group = [...group, i];
      } else {
        if (group.length > 0) {
          result = [...result, group];
        }
        group = [i];
      }
    } else {
      if (group.length > 0) {
        result = [...result, group];
      }
      result = [...result, [i]];
      group = [];
    }
  }

  // if there are any remaining fields in the group array add them to the result array
  if (group.length > 0) {
    result = [...result, group];
  }

  return result;
};
