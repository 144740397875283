import { ProcessWithReferences, WorkInstructionBlockType } from "@shared/types/databaseTypes";

interface MediaNumbering {
  imageNumber: { [workInstructionBlockId: string]: number };
  fileNumber: { [workInstructionBlockId: string]: number };
  videoNumber: { [workInstructionBlockId: string]: number };
  pdfNumber: { [workInstructionBlockId: string]: number };
};

const useProcessMediaNumbering = (process: ProcessWithReferences | undefined): MediaNumbering => {
  if (!process) {
    return {
      imageNumber: {},
      fileNumber: {},
      videoNumber: {},
      pdfNumber: {},
    };
  }
  const imageNumber: { [blockId: string]: number } = {};
  const fileNumber: { [blockId: string]: number } = {};
  const videoNumber: { [blockId: string]: number } = {};
  const pdfNumber: { [blockId: string]: number } = {};

  let imageOrdinal = 1;
  let fileOrdinal = 1;
  let videoOrdinal = 1;
  let pdfOrdinal = 1;

  process.process_steps.forEach((step) => {
    step.work_instruction_blocks.forEach((block) => {
      if (block?.type === WorkInstructionBlockType.Image) {
        imageNumber[block.id] = imageOrdinal++;
      } else if (block?.type === WorkInstructionBlockType.File) {
        fileNumber[block.id] = fileOrdinal++;
      } else if (block?.type === WorkInstructionBlockType.Video) {
        videoNumber[block.id] = videoOrdinal++;
      } else if (block?.type === WorkInstructionBlockType.PDF) {
        pdfNumber[block.id] = pdfOrdinal++;
      }
    });
  });

  return { imageNumber, fileNumber, videoNumber, pdfNumber };
};

export default useProcessMediaNumbering;
