import { getSupabase } from "@shared/connections/supabaseAuth";
import store from "@shared/redux/store";
import { CHUNK_SIZE } from "@shared/constants/supabase";

// ------------------- READ FUNCTIONS ------------------- //

// helper function to fetch data from supabase based on table name and process id
export async function fetchDataByTableNameAndDatasetId<ReturnedTable>(
  tableName: string,
  datasetId: string,
  allowRetries: boolean = true,
): Promise<ReturnedTable[]> {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { count: totalCount, error: countError } = await supabase
    .from(tableName)
    .select("*, process_entry:process_entry_id!inner(*)", { count: "exact", head: true })
    .eq("dataset_id", datasetId)
    .eq("process_entry.upload_error", false);

  if (countError || !totalCount) {
    if (countError) console.error(countError);
    return [];
  }

  const allData: any[] = [];

  // Get the data in chunks of CHUNK_SIZE. Query limit is 5000 rows per query so use CHUNK_SIZE=4500 to be safe.
  // This could lead to some data being missed if the table is updated while the data is being fetched.
  // It could also lead to duplicate data if the table is updated while the data is being fetched.
  for (let offset = 0; offset < totalCount; offset += CHUNK_SIZE) {
    const { data, error } = await supabase
      .from(tableName)
      .select("*, unique_identifier:unique_identifier_id(*), process_entry:process_entry_id!inner(*)")
      .eq("dataset_id", datasetId)
      .eq("process_entry.upload_error", false)
      .order("id", { ascending: true })
      .range(offset, offset + CHUNK_SIZE - 1)
      .returns<ReturnedTable[]>();

    if (!error && data.length > 0) {
      allData.push(...data);
    } else {
      console.log(error);
    }
  }

  // Check uniqueness of the IDs in the data
  const allIdsUnique = allData.length === new Set(allData.map((item: any) => item["id"])).size;

  // if the ids are not unique, load the data again
  if (!allIdsUnique && allowRetries) {
    console.log(`Duplicate IDs found in ${tableName}. Refreshing data...`);
    const newAllData: any[] = await fetchDataByTableNameAndDatasetId(tableName, datasetId, false);
    return newAllData;
  }

  // return the data sorted by process_entry.timestamp
  return allData.sort((a, b) => new Date(a.process_entry.timestamp).getTime() - new Date(b.process_entry.timestamp).getTime());
}
