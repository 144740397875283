import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { useEffect, useState } from "react";
import {
  GenealogyFamilyMember,
  ProcessEntryAllData,
  SnLookupTimeline,
  SnLookupTimelineCreation,
  SnLookupTimelineType,
  SnLookupTimelineProperty,
  SnLookupTimelineComment,
} from "../types";
import { ProcessType } from "@shared/types/databaseEnums";
import { fetchPropertyData } from "../connections/supabaseProperty";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

interface useSnLookupTimelineLogProps {
  uniqueIdentifier: UniqueIdentifier | null;
  processEntries: ProcessEntryAllData[];
  genealogy: GenealogyFamilyMember[];
}

const useSnLookupTimelineLog = ({ processEntries, uniqueIdentifier, genealogy }: useSnLookupTimelineLogProps) => {
  const [activityLog, setActivityLog] = useState<SnLookupTimeline[]>([]);
  const comments = useSelector((state: RootState) => state.db.uniqueIdentifierActivityLog);

  useEffect(() => {
    const loadActivityLog = async () => {
      // ----- Creation -----
      const creationTimeline: SnLookupTimelineCreation[] = uniqueIdentifier
        ? [
            {
              type: SnLookupTimelineType.Creation,
              componentId: uniqueIdentifier?.component_id,
              timestamp: uniqueIdentifier?.created_at as string,
              uniqueIdentifier: uniqueIdentifier,
            },
          ]
        : [];
      // ----- Property -----
      const propertyPromises = processEntries
        .filter((entry) => entry.process?.type === ProcessType.Property && entry.unique_identifier_id === uniqueIdentifier?.id)
        .map((entry) => fetchPropertyData(entry.id));

      const propertyEntries = await Promise.all(propertyPromises);

      const propertyTimeline: SnLookupTimelineProperty[] = propertyEntries
        .map((property, index) => {
          if (!property || property.name === null || property.value === null) {
            return undefined;
          }

          const entry = processEntries[index];
          return {
            type: SnLookupTimelineType.Property,
            timestamp: entry.timestamp,
            operatorId: entry?.operator_id,
            userId: entry?.operator?.user_id ?? undefined,
            key: property.name,
            value: property.value,
          };
        })
        .filter((entry) => entry !== undefined) as SnLookupTimelineProperty[];
      // ----- Comments -----

      const commentTimeline: SnLookupTimelineComment[] = comments
        .filter((comment) => comment.unique_identifier_id === uniqueIdentifier?.id)
        .map((comment) => {
          return {
            type: SnLookupTimelineType.Comment,
            comment: comment.comment || "",
            timestamp: comment.created_at,
            userId: comment.user_id === null ? undefined : comment.user_id,
          };
        });

      // ----- Combine and Sort -----
      const sortedActivities = [...creationTimeline, ...propertyTimeline, ...commentTimeline].sort((a, b) => {
        const timestampA = new Date(a.timestamp).getTime();
        const timestampB = new Date(b.timestamp).getTime();

        return timestampB - timestampA;
      });
      setActivityLog(sortedActivities);
    };

    loadActivityLog();
  }, [processEntries, uniqueIdentifier, genealogy, comments]);

  return activityLog;
};

export default useSnLookupTimelineLog;
