import Modal from "@shared/components/primitives/Modal";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import Button from "@shared/components/primitives/Button";

const FrontendPrimitivesModals = () => {
  return (
    <FrontendPrimitivesCard title="Modals">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Basic Modal</div>
        <div className="flex gap-2">
          <Modal.Root>
            <Modal.Overlay />
            <Modal.Trigger asChild>
              <Button>Open Modal</Button>
            </Modal.Trigger>
            <Modal.Content>
              <Modal.Main>
                <div className="flex h-48 items-center justify-center">Modal content goes here</div>
              </Modal.Main>
            </Modal.Content>
          </Modal.Root>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Modal with Header & Footer</div>
        <div className="flex gap-2">
          <Modal.Root>
            <Modal.Overlay />
            <Modal.Trigger asChild>
              <Button>Open Modal</Button>
            </Modal.Trigger>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Share link</Modal.Title>
                <Modal.HeaderClose />
              </Modal.Header>
              <Modal.Main>
                <div className="flex h-48 items-center justify-center">Modal content goes here</div>
              </Modal.Main>
              <Modal.Footer className="sm:justify-start">
                <div className="flex gap-2">
                  <Button size="sm">Save</Button>
                  <Modal.Close asChild>
                    <Button size="sm" type="button" variant="outline">
                      Close
                    </Button>
                  </Modal.Close>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Root>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesModals;
