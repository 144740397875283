import { getSupabase } from "@shared/connections/supabaseAuth";
import { supabaseSingleOverride } from "@shared/connections/supabaseDataModifiers";
import { ParsedResponse } from "@shared/types/apiTypes";
import { ProcessType } from "@shared/types/databaseEnums";
import {
  Checkbox,
  File,
  Image,
  Operator,
  ParametricQualitative,
  ParametricQuantitative,
  Process,
  ProcessEntry,
  Station,
  StationProcessLink,
  UniqueIdentifier,
  UniqueIdentifierLink,
  DatetimeData,
} from "@shared/types/databaseTypes";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";

// NOTE: Eventually the goal is to make the production app use only the Serial API for getting / setting data.
//       This will allow us to have more sophisticated authentication logic than provided by supabase RSL.
//       For now this file may contain functions that access redux or supabase directly.
//       Functions should be refactored after all production app APIs have been implemented

export const fetchStationById = async (stationId: string): Promise<ParsedResponse<Station>> => {
  // TODO: This fetch should eventually use the serial API to get the station by id, not supabase client library
  const supabase = getSupabase();
  let { data: station, error: stationError } = supabaseSingleOverride(await supabase.from("stations").select("*").eq("id", stationId));
  const { data: stationProcessLinks, error: stationProcessLinksError } = await supabase
    .from("station_process_links")
    .select("*")
    .eq("station_id", stationId);
  station = { ...station, station_process_links: stationProcessLinks };
  return { data: station, error: stationError || stationProcessLinksError };
};

export const fetchOperatorByUserId = async (userId: string): Promise<ParsedResponse<Operator>> => {
  const supabase = getSupabase();
  let { data: operator, error } = supabaseSingleOverride(await supabase.from("operators").select("*").eq("user_id", userId));
  return { data: operator, error: error };
};

export const fetchOperatorByPin = async (pin: string): Promise<ParsedResponse<Operator>> => {
  const supabase = getSupabase();
  let { data: operator, error } = supabaseSingleOverride(await supabase.from("operators").select("*").eq("pin", pin));
  if (error === "no such row found in database") {
    error = "Invalid PIN";
  }
  return { data: operator, error: error };
};

export const fetchProcessEntriesByComponentInstanceId = async (componentInstanceId: string): Promise<ParsedResponse<ProcessEntry[]>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase.from("process_entries").select("*").eq("unique_identifier_id", componentInstanceId);
  return { data: data ?? undefined, error: error?.message ?? undefined };
};

export const fetchComponentInstancesByIdentifiers = async (
  identifiers: string[],
  abortSignal?: AbortSignal,
): Promise<ParsedResponse<UniqueIdentifier[]>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("unique_identifiers")
    .select("*, component:component_id(*), part_number:part_number_id(*), work_order:work_order_id(*)")
    .in("identifier", identifiers)
    .abortSignal(abortSignal ?? new AbortController().signal)
    .returns<UniqueIdentifier[]>();
  return { data: data ?? undefined, error: error?.message ?? undefined };
};

export const fetchActiveProcessesByComponentId = async (componentId: string): Promise<ParsedResponse<Process[]>> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("component_process_links")
    .select("process:processes!inner(*)")
    .eq("component_id", componentId)
    .eq("is_active", true)
    .eq("process.type", ProcessType.Production)
    .order("order", { ascending: true })
    .returns<Partial<ProcessEntry[]>>();
  const processes = data?.map((processEntry) => processEntry?.process).filter((process) => process !== undefined) as Process[];
  return { data: processes ?? undefined, error: error?.message ?? undefined };
};

export const fetchParentsOfIdentifiers = async (identifierIds: string[]): Promise<Record<string, UniqueIdentifier>> => {
  const supabase = getSupabase();
  if (identifierIds.length > 100) console.error("WARNING: .in() queries should not exceed 100 items.");
  const { data: parentIdentifiers, error: parentIdentifiersError } = await supabase
    .from("unique_identifier_links")
    .select("*, parent:unique_identifier_id(*)")
    .in("has_child_of_id", identifierIds)
    .eq("is_active", true);
  if (parentIdentifiersError) {
    console.error(parentIdentifiersError);
    console.error("Error getting parent identifiers");
    return {};
  }
  const parentIdentifierMap: Record<string, UniqueIdentifier> = {};
  parentIdentifiers.forEach((parentIdentifier) => {
    parentIdentifierMap[parentIdentifier.has_child_of_id] = parentIdentifier.parent;
  });
  return parentIdentifierMap;
};

export const fetchLatestProcessEntry = async (componentInstanceId: string, processId: string) => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("process_entries")
    .select("*")
    .eq("unique_identifier_id", componentInstanceId)
    .eq("process_id", processId)
    .order("timestamp", { ascending: false })
    .limit(1)
    .returns<ProcessEntry[]>();
  return { data: data?.[0] ?? undefined, error: error?.message ?? undefined };
};

export const fetchProcessEntryData = async (
  processEntryId: string,
): Promise<(Image | File | ParametricQualitative | ParametricQuantitative | Checkbox | UniqueIdentifierLink | DatetimeData)[]> => {
  const tables = [
    {
      name: "images",
      selectString: "*",
    },
    {
      name: "files",
      selectString: "*",
    },
    {
      name: "parametric_qualitative",
      selectString: "*",
    },
    {
      name: "parametric_quantitative",
      selectString: "*",
    },
    {
      name: "checkboxes",
      selectString: "*",
    },
    {
      name: "unique_identifier_links",
      selectString: "*, child:has_child_of_id(*)",
    },
    {
      name: "datetime_data",
      selectString: "*",
    },
  ];
  const supabase = getSupabase();
  const responses = (await Promise.all(
    tables.map(async (table) => supabase.from(table.name).select(table.selectString).eq("process_entry_id", processEntryId)),
  )) as any[];
  return responses.flatMap((response) => {
    if (!response.data) {
      return [];
    }
    return response.data.map((datapoint: any) => {
      return datapoint;
    });
  });
};

export const fetchLatestLink = async (componentInstanceId: string, datasetId: string): Promise<UniqueIdentifierLink | undefined> => {
  const supabase = getSupabase();
  const { data, error } = await supabase
    .from("unique_identifier_links")
    .select("*, child:has_child_of_id(*)")
    .eq("unique_identifier_id", componentInstanceId)
    .eq("dataset_id", datasetId)
    .eq("is_active", true)
    .limit(1)
    .returns<UniqueIdentifierLink[]>();
  if (error) {
    console.error(error);
    console.error("Error getting latest link");
    return undefined;
  }
  return data?.[0];
};

export const updateStation = async (stationId: string, station: Partial<Station>): Promise<ParsedResponse<Station>> => {
  const supabase = getSupabase();
  const updatedStationPayload = {
    type: station.type,
    name: station.name,
    is_active: station.is_active,
    is_locked: station.is_locked,
    location: station.location,
  };
  return supabaseSingleOverride(await supabase.from("stations").update(updatedStationPayload).eq("id", stationId).select("*"));
};

export const createStationProcessLink = async (
  station: Station,
  processId: string,
  processRevision: number,
  componentId: string,
): Promise<ParsedResponse<StationProcessLink>> => {
  const supabase = getSupabase();
  const newStationProcessLinkPayload = {
    company_id: station.company_id,
    station_id: station.id,
    process_id: processId,
    process_revision: processRevision,
    component_id: componentId,
  };
  return supabaseSingleOverride(await supabase.from("station_process_links").insert(newStationProcessLinkPayload).select("*"));
};

export const deleteStationProcessLink = async (stationProcessLinkId: string): Promise<ParsedResponse<any>> => {
  const supabase = getSupabase();
  return supabaseSingleOverride(await supabase.from("station_process_links").delete().eq("id", stationProcessLinkId));
};

export const createStation = async (stationName: string, companyId: string): Promise<ParsedResponse<Station>> => {
  const supabase = getSupabase();
  const newStation = {
    company_id: companyId,
    name: stationName,
    use_api: false,
    // is_active is set automatically
  };
  const response = await supabase.from("stations").insert([newStation]).select().returns<Station>().single();
  return { data: response.data ?? undefined, error: response.error?.message ?? undefined };
};

interface IdentifierCountsByProcess {
  process_names: string[];
  wip_counts: number[];
  defective_counts: number[];
}

export const fetchIdentifierCounts = async (
  componentId: string | null,
  workOrderId: string | null,
): Promise<IdentifierCountsByProcess> => {
  if (componentId === null || workOrderId === null) {
    return {
      process_names: [],
      wip_counts: [],
      defective_counts: [],
    };
  };
  const supabase = getSupabase();
  const { data: uniqueIdentifiers, error: uniqueIdentifiersError } = await supabase
    .from("unique_identifiers")
    .select("*, latest_process_entry:latest_process_entry_id(*, process:processes(*))")
    .eq("component_id", componentId)
    .eq("work_order_id", workOrderId)
    .eq("is_archived", false);
  if (uniqueIdentifiersError) {
    console.error("Error getting unique identifiers", uniqueIdentifiersError);
    return {
      process_names: [],
      wip_counts: [],
      defective_counts: [],
    };
  };
  const processCounts: Record<string, { wip: number; defective: number }> = {};
  uniqueIdentifiers.forEach((identifier) => {
    const processName = identifier.latest_process_entry?.process?.name;
    if (processName) {
      if (!processCounts[processName]) {
        processCounts[processName] = { wip: 0, defective: 0 };
      }
      if (identifier.status === UniqueIdentifierStatus.Defective) {
        processCounts[processName].defective += 1;
      } else if (identifier.status === UniqueIdentifierStatus.Wip) {
        processCounts[processName].wip += 1;
      }
    }
  });
  const processNames = Object.keys(processCounts);
  const wipCounts = processNames.map((processName) => processCounts[processName].wip);
  const defectiveCounts = processNames.map((processName) => processCounts[processName].defective);
  return { process_names: processNames, wip_counts: wipCounts, defective_counts: defectiveCounts };
};

export const fetchParametricDataByDatasetId = async (datasetIds: string[]): Promise<ParsedResponse<{ [datasetId: string]: string | number }>> => {
  const supabase = getSupabase();

  const fetchQualitativeData = supabase
    .from("parametric_qualitative")
    .select("*")
    .in("dataset_id", datasetIds)
    .order("created_at", { ascending: true });

  const fetchQuantitativeData = supabase
    .from("parametric_quantitative")
    .select("*")
    .in("dataset_id", datasetIds)
    .order("created_at", { ascending: true });

  const [qualitativeResponse, quantitativeResponse] = await Promise.all([fetchQualitativeData, fetchQuantitativeData]);

  const { data: parametricQualitative, error: parametricQualitativeError } = qualitativeResponse;
  const { data: parametricQuantitative, error: parametricQuantitativeError } = quantitativeResponse;

  if (parametricQualitativeError) {
    console.error(parametricQualitativeError);
    console.error("Error getting parametric qualitative data");
    return { data: {}, error: parametricQualitativeError.message };
  }

  if (parametricQuantitativeError) {
    console.error(parametricQuantitativeError);
    console.error("Error getting parametric quantitative data");
    return { data: {}, error: parametricQuantitativeError.message };
  }

  const parametricDataMap: Record<string, string | number> = {};

  parametricQualitative.forEach((parametricData) => {
    parametricDataMap[parametricData.dataset_id] = parametricData.value;
  });

  parametricQuantitative.forEach((parametricData) => {
    parametricDataMap[parametricData.dataset_id] = parametricData.value;
  });

  return { data: parametricDataMap, error: undefined };
};

