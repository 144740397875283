import React, { useMemo, useContext, useEffect, useRef } from "react";
import { SnLookupTimelineGroup } from "../types";
import SnLookUpTableTimelineMonth from "./SnLookUpTimelineMonth";
import { SnLookupContext } from "../SnLookupProvider";
import { addCommentToComponentInstance } from "../connections/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Button from "@shared/components/primitives/Button";

const SnLookUpTimeline: React.FC = () => {
  const { activityLog, uniqueIdentifier } = useContext(SnLookupContext);
  const internalRef = useRef<HTMLTextAreaElement>(null);

  const groupedTimeline = useMemo(() => {
    return activityLog.reduce<SnLookupTimelineGroup[]>((acc, activity) => {
      if (!activity.timestamp) {
        activity.timestamp = new Date().toISOString();
      }
      const date = new Date(activity.timestamp);
      const year = date.getFullYear().toString();
      const month = date.toLocaleString("en-US", { month: "long" });

      let yearGroup = acc.find((group) => group.year === year);
      if (!yearGroup) {
        yearGroup = { year, months: [] };
        acc.push(yearGroup);
      }

      let monthGroup = yearGroup.months.find((m) => m.month === month);
      if (!monthGroup) {
        monthGroup = { month, activity: [] };
        yearGroup.months.push(monthGroup);
      }

      monthGroup.activity.push(activity);

      return acc;
    }, []);
  }, [activityLog]);

  const autoResize = () => {
    const textarea = (internalRef as React.RefObject<HTMLTextAreaElement>).current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    const textarea = (internalRef as React.RefObject<HTMLTextAreaElement>).current;
    if (!textarea) return;

    autoResize();

    textarea.addEventListener("input", autoResize);

    return () => textarea.removeEventListener("input", autoResize);
  }, [internalRef]);

  const addComment = async () => {
    const textarea = internalRef.current;
    if (textarea && textarea.value && uniqueIdentifier?.id) {
      await addCommentToComponentInstance(textarea.value, uniqueIdentifier.id);
      textarea.value = "";
    }
  };

  return (
    <div className="flex h-full flex-col gap-y-3 rounded-b-md border-t bg-white px-5 pb-4 pt-2">
      <div className="w-full">
        <div className="relative flex-1">
          <textarea
            ref={internalRef as React.Ref<HTMLTextAreaElement>}
            className={"form-textarea w-full resize-none overflow-hidden rounded-md border border-gray-300 pr-16"}
          />
          <Button symmetric className="absolute bottom-4 right-2" onClick={addComment}>
            <FontAwesomeIcon icon={faArrowUp} />
          </Button>
        </div>
      </div>
      {groupedTimeline.map((yearGroup) => (
        <div key={yearGroup.year} className="flex flex-col gap-y-3">
          <p className="text-serial-palette-700 z-10 bg-white font-semibold">{yearGroup.year}</p>
          {yearGroup.months.map((monthGroup) => (
            <SnLookUpTableTimelineMonth
              key={monthGroup.month}
              monthGroup={monthGroup}
              oldestTimestamp={activityLog[activityLog.length - 1]?.timestamp}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SnLookUpTimeline;
