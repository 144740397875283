import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Transition from "@shared/components/Transition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DashboardContext } from "../Dashboards";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import DropdownSearch from "@shared/components/dropdowns/DropdownSearch";
import UserAvatar from "@shared/components/UserAvatar";
import { Loader } from "@shared/components/Loader";

interface DashboardEditModalProps {}

const DashboardEditModal: React.FC<DashboardEditModalProps> = ({}) => {
  const [unshareIsLoading, setUnshareIsLoading] = useState<string | null>(null);
  const [shareIsLoading, setShareIsLoading] = useState<string | null>(null);
  const [dashboardName, setDashboardName] = useState<string>("");
  const [dashboardDescription, setDashboardDescription] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const db = useSelector((state: RootState) => state.db);
  const currUserId = useSelector((state: RootState) => state.auth.supabase_uid);
  const context = useContext(DashboardContext);
  const modalContent = useRef<HTMLDivElement>(null);

  const dashboard = useMemo(() => {
    return context.dashboards?.find((dashboard) => dashboard.id === context.selectedDashboardId) ?? null;
  }, [context.dashboards, context.selectedDashboardId]);

  const handleClose = async () => {
    context.setDashboardEditModalOpen(false);
  };

  const handleSetDashboardName = async () => {
    if (dashboard) {
      let updatedDashboard = JSON.parse(JSON.stringify(dashboard)); // make deep copy of dashboard to avoid mutating redux state
      updatedDashboard.name = dashboardName;
      await context.handleUpdateDashboard(updatedDashboard);
    }
  };

  const handleSetDashboardDescription = async () => {
    if (dashboard) {
      let updatedDashboard = JSON.parse(JSON.stringify(dashboard)); // make deep copy of dashboard to avoid mutating redux state;
      updatedDashboard.description = dashboardDescription;
      await context.handleUpdateDashboard(updatedDashboard);
    }
  };

  const handleUnshare = async (userId: string) => {
    if (dashboard) {
      setUnshareIsLoading(userId);
      let updatedDashboard = JSON.parse(JSON.stringify(dashboard)); // make deep copy of dashboard to avoid mutating redux state;
      updatedDashboard.shared_with[userId] = false;
      await context.handleUpdateDashboard(updatedDashboard);
      setUnshareIsLoading(null);
    }
  };

  const handleShare = async () => {
    if (dashboard && selectedUserId) {
      setShareIsLoading(selectedUserId);
      let updatedDashboard = JSON.parse(JSON.stringify(dashboard)); // make deep copy of dashboard to avoid mutating redux state;
      updatedDashboard.shared_with[selectedUserId] = true;
      await context.handleUpdateDashboard(updatedDashboard);
      setSelectedUserId(null);
      setShareIsLoading(null);
    }
  };

  const handleTogglePublic = async () => {
    if (dashboard) {
      let updatedDashboard = JSON.parse(JSON.stringify(dashboard)); // make deep copy of dashboard to avoid mutating redux state;
      updatedDashboard.is_public = !updatedDashboard.is_public;
      await context.handleUpdateDashboard(updatedDashboard);
    }
  };

  const sharedWithUserIds = useMemo(() => {
    return [
      dashboard?.created_by,
      ...Object.entries(dashboard?.shared_with ?? {}).map(([userId, isShared]) => {
        return isShared ? userId : null;
      }),
    ].filter((userId) => userId !== null && userId !== undefined) as string[];
  }, [dashboard]);

  const currUserIsOwner = useMemo(() => {
    return dashboard?.created_by === currUserId;
  }, [dashboard, currUserId]);

  useEffect(() => {
    setDashboardDescription(dashboard?.description ?? "");
    setDashboardName(dashboard?.name ?? "");
  }, [dashboard]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!context.dashboardEditModalOpen || keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // automatically share when the selected user is changed
  useEffect(() => {
    if (selectedUserId) {
      handleShare();
    }
  }, [selectedUserId]);

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={context.dashboardEditModalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
        appear={true}
      />
      {/* Modal dialog */}
      <Transition
        id="part-number-modal"
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={context.dashboardEditModalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
        appear={true}
      >
        <div ref={modalContent} className={`flex max-h-full w-full max-w-md flex-col rounded-md border bg-white`}>
          {/* Header */}
          <div className="flex h-14 shrink-0 items-center justify-between border-b px-5">
            <div className="text-serial-palette-800 font-semibold">
              <span>{`${context.isNewDashboard ? "New" : "Edit"} Dashboard`}</span>
              <span className="text-serial-palette-600 font-light"></span>
            </div>
            <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={() => handleClose()}>
              <FontAwesomeIcon icon={faTimes} className="h-4 w-4" />
            </button>
          </div>

          {/* Body */}
          <div className="flex w-full flex-col px-5 pb-6 pt-4">
            {/* Dashboard Name */}
            {currUserIsOwner && (
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium">
                  Name <span className="text-red-600">*</span>
                </label>
                <input
                  name="serial-number"
                  onChange={(e) => setDashboardName(e.target.value)}
                  onBlur={() => handleSetDashboardName()}
                  className="form-input w-full bg-white"
                  type="text"
                  value={dashboardName}
                  placeholder="Dashboard Name"
                />
              </div>
            )}

            {/* Description Description */}
            {currUserIsOwner && (
              <div className="mb-2">
                <label className="mb-1 block text-sm font-medium">Description</label>
                <textarea
                  name="description"
                  onChange={(e) => setDashboardDescription(e.target.value)}
                  onBlur={() => handleSetDashboardDescription()}
                  className="form-input w-full bg-white"
                  value={dashboardDescription}
                  placeholder="Description"
                />
              </div>
            )}

            {/* Make Public */}
            {currUserIsOwner && (
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium">
                  Public <span className="text-serial-palette-500 font-normal">{`(Everyone at ${db.company.name} can see)`}</span>
                </label>
                <div className="flex items-center">
                  <div className="form-switch">
                    <input
                      name="is_active"
                      type="checkbox"
                      id="switch-1"
                      className="sr-only"
                      checked={dashboard?.is_public ?? false}
                      onChange={() => handleTogglePublic()}
                    />
                    <label className="bg-serial-palette-400" htmlFor="switch-1">
                      <span className="bg-white " aria-hidden="true"></span>
                      <span className="sr-only">Switch label</span>
                    </label>
                  </div>
                  <div className="text-serial-palette-400 ml-2 text-sm italic">{dashboard?.is_public ? "Public" : "Private"}</div>
                </div>
              </div>
            )}

            {/* Share */}
            {!dashboard?.is_public && (
              <div className="flex flex-col">
                <label className="block text-sm font-medium">
                  Shared With{" "}
                  <span className="text-serial-palette-500 font-normal">
                    {sharedWithUserIds.length > 1 && `(${sharedWithUserIds.length - 1})`}
                  </span>
                </label>
                {db.users.filter((user) => !sharedWithUserIds.includes(user.supabase_uid)).length > 0 && (
                  <div className="mt-1 flex justify-between gap-2 whitespace-nowrap">
                    <DropdownSearch
                      options={db.users
                        .filter((user) => !sharedWithUserIds.includes(user.supabase_uid))
                        .map((user) => ({ id: user.supabase_uid, label: `${user.first_name} ${user.last_name}` }))}
                      selected={selectedUserId}
                      setSelected={setSelectedUserId}
                      className=""
                      placeholder="User Name"
                    />
                    {selectedUserId && (
                      <button className="btn-sm serial-btn-dark" onClick={() => handleShare()}>
                        {shareIsLoading ? <Loader styleOverride="w-[10px] h-[12px]" /> : "Share"}
                      </button>
                    )}
                  </div>
                )}
                <div className="scrollbar-hide mt-2 max-h-64 overflow-scroll">
                  {sharedWithUserIds.map((userId) => {
                    const user = db.users.find((user) => user.supabase_uid === userId);
                    const isOwner = dashboard?.created_by === userId;
                    if (!user) return null;
                    return (
                      <div key={userId} className="bg-serial-palette-100 mb-2 flex items-center justify-between rounded-md p-3 pr-4">
                        <div className="flex items-center gap-2">
                          <UserAvatar user={user} size="md" />
                          <div className="flex flex-col">
                            <p className="text-sm">
                              {user.first_name} {user.last_name} {isOwner && <span className="text-serial-palette-500">(Owner)</span>}
                            </p>
                            <p className="text-serial-palette-500 text-sm">{user.email}</p>
                          </div>
                        </div>
                        {!isOwner && (
                          <button className="btn-sm serial-btn-light h-7 w-7" onClick={() => handleUnshare(userId)}>
                            {unshareIsLoading === user.supabase_uid ? (
                              <Loader styleOverride="w-[10px] h-[12px]" />
                            ) : (
                              <FontAwesomeIcon icon={faTimes} className="text-red-600" />
                            )}
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="flex h-14 shrink-0 items-center justify-end border-t px-3">
            <button className="btn serial-btn-dark h-8" onClick={() => handleClose()}>
              Save
            </button>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default DashboardEditModal;
