import React, { useContext, useMemo, useState, useEffect } from "react";
import { ComponentPageModal } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PartNumbers from "../../part-number/PartNumbers";
import Modal from "@shared/components/modals/Modal";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import ComponentSettings from "./ComponentSettings";
import { ToastContext } from "@shared/context/ToastProvider";
import { Component } from "@shared/types/databaseTypes";
import LabelFormatter from "@shared/labels/LabelFormatter";

interface ComponentModalProps {
  activeModal: ComponentPageModal | null;
  setActiveModal: (modal: ComponentPageModal | null) => void;
  selectedComponent: Component | null;
}

const ComponentModal: React.FC<ComponentModalProps> = ({ activeModal, setActiveModal, selectedComponent }) => {
  const { triggerConfirmation } = useContext(ToastContext);

  const [unsavedChanges, setUnsavedChanges] = useState<{ [key: string]: boolean }>({
    [ComponentPageModal.ComponentSettings]: false,
    [ComponentPageModal.PartNumbers]: false,
    [ComponentPageModal.Labels]: false,
  });

  const handleSelectTab = (tab: ComponentPageModal) => {
    // check if there are unsaved changes
    if (activeModal && unsavedChanges[activeModal]) {
      triggerConfirmation(
        "Unsaved Changes",
        <span>
          You have unsaved changes.
          <br />
          Are you sure you want to leave this tab?
        </span>,
        () => {
          setActiveModal(tab);
          setUnsavedChanges((prev) => ({ ...prev, [activeModal]: false }));
        },
        () => {},
        "Leave",
        "Stay",
      );
      return;
    } else {
      setActiveModal(tab);
    }
  };

  const sizeClass = useMemo(() => {
    if (activeModal === ComponentPageModal.ComponentSettings) {
      return "h-[580px] w-full md:w-[500px]";
    }
    if (activeModal === ComponentPageModal.PartNumbers) {
      return "h-[95vh] w-full md:w-11/12 max-w-10xl";
    }
    if (activeModal === ComponentPageModal.Labels) {
      return "h-[95vh] w-full md:w-9/12 max-w-10xl";
    }
    return "";
  }, [activeModal]);

  useEffect(() => {
    if (!activeModal) {
      setUnsavedChanges({ [ComponentPageModal.ComponentSettings]: false, [ComponentPageModal.PartNumbers]: false });
    }
  }, [activeModal]);

  return (
    <Modal
      show={
        activeModal === ComponentPageModal.ComponentSettings ||
        activeModal === ComponentPageModal.PartNumbers ||
        activeModal === ComponentPageModal.Labels
      }
      setShow={(show: boolean) => !show && setActiveModal(null)}
    >
      <div className={`flex max-h-full flex-col overflow-auto rounded border bg-white transition-all duration-500 ${sizeClass}`}>
        <div className="flex w-full items-center justify-between border-b pr-4">
          <div className="flex w-full overflow-x-auto pt-4">
            {Object.values(ComponentPageModal)
              .filter((option) => option !== ComponentPageModal.ProcessBuilder)
              .map((option, index) => {
                // show only the settings tab for new components
                if (!selectedComponent && option !== ComponentPageModal.ComponentSettings) return null;
                return (
                  <div key={index} className="flex justify-center">
                    <h2
                      onClick={() => handleSelectTab(option)}
                      className={`text-serial-palette-800 flex max-w-[120px] pb-[13px] md:max-w-none ${index === 0 ? "pl-5" : "pl-3"} border-b-[3.5px] pr-3 ${activeModal === option ? "border-serial-palette-600 font-semibold" : "hover:border-serial-palette-400 border-white font-medium"} cursor-pointer`}
                    >
                      <span className={`truncate ${activeModal !== option && "opacity-60"}`}>{screamingSnakeCaseToTitleCase(option)}</span>
                    </h2>
                  </div>
                );
              })}
          </div>
          <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={() => setActiveModal(null)}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>
        {activeModal === ComponentPageModal.ComponentSettings && (
          <ComponentSettings
            selectedComponent={selectedComponent}
            setModalOpen={(isOpen: boolean) => setActiveModal(isOpen ? ComponentPageModal.ComponentSettings : null)}
            setUnsavedChanges={(hasUnsavedChanges: boolean) =>
              setUnsavedChanges({ ...unsavedChanges, [ComponentPageModal.ComponentSettings]: hasUnsavedChanges })
            }
          />
        )}
        {activeModal === ComponentPageModal.PartNumbers && selectedComponent && (
          <PartNumbers
            selectedComponent={selectedComponent}
            setModalOpen={(isOpen: boolean) => setActiveModal(isOpen ? ComponentPageModal.PartNumbers : null)}
            setUnsavedChanges={(hasUnsavedChanges: boolean) =>
              setUnsavedChanges({ ...unsavedChanges, [ComponentPageModal.PartNumbers]: hasUnsavedChanges })
            }
          />
        )}
        {activeModal === ComponentPageModal.Labels && selectedComponent && (
          <LabelFormatter
            componentId={selectedComponent.id}
            setModalOpen={(isOpen: boolean) => setActiveModal(isOpen ? ComponentPageModal.PartNumbers : null)}
            setUnsavedChanges={(hasUnsavedChanges: boolean) =>
              setUnsavedChanges({ ...unsavedChanges, [ComponentPageModal.Labels]: hasUnsavedChanges })
            }
          />
        )}
      </div>
    </Modal>
  );
};

export default ComponentModal;
