import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { UserRole } from "@shared/types/databaseEnums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faGears, faNetworkWired, faRocket, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { RootState } from "@shared/redux/store";

function SettingsSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const role = useSelector((state: RootState) => state.auth.role);

  return (
    <div className="scrollbar-hide border-serial-palette-200 flex min-w-60 flex-nowrap overflow-x-scroll border-b py-3 md:block md:space-y-3 md:overflow-auto md:border-b-0 md:border-r md:py-4">
      <div className="flex flex-row flex-wrap gap-x-2 md:flex-col">
        {/* User Account Preferences */}
        <div className="mb-2 hidden px-3 text-xs font-semibold uppercase md:flex">My Account</div>
        <div className="flex flex-row flex-nowrap gap-x-2 md:mr-0 md:block md:flex-col">
          <NavLink
            end
            to="/settings/account"
            className={`flex items-center whitespace-nowrap py-2 pl-0.5 pr-2.5 ${pathname.includes("/settings/account") ? "text-serial-palette-700 bg-serial-palette-100 font-medium" : "hover:text-serial-palette-700 font-normal"}`}
          >
            <FontAwesomeIcon
              className={`${pathname.includes("/settings/account") ? "text-serial-palette-500" : "text-serial-palette-400"} w-9`}
              icon={faUser}
            />
            <span
              className={`text-sm ${pathname.includes("/settings/account") ? "text-serial-palette-700" : "hover:text-serial-palette-700"}`}
            >
              My Account
            </span>
          </NavLink>
        </div>
        {/* Company Wide Preferences */}
        {role === UserRole.Admin && <div className="mb-2 mt-6 hidden px-3 text-xs font-semibold uppercase md:flex">My Company</div>}
        {role === UserRole.Admin && (
          <div className="flex flex-row flex-nowrap gap-x-2 md:mr-0 md:block md:flex-col">
            <NavLink
              end
              to="/settings/team"
              className={`flex items-center whitespace-nowrap py-2 pl-0.5 pr-2.5 ${pathname.includes("/settings/team") ? "text-serial-palette-700 bg-serial-palette-100 font-medium" : "hover:text-serial-palette-700 font-normal"}`}
            >
              <FontAwesomeIcon
                className={`${pathname.includes("/settings/team") ? "text-serial-palette-500" : "text-serial-palette-400"} w-9`}
                icon={faUsers}
              />
              <span className={`text-sm`}>Team</span>
            </NavLink>
            <NavLink
              end
              to="/settings/prodapp"
              className={`flex items-center whitespace-nowrap py-2 pl-0.5 pr-2.5 ${pathname.includes("/settings/prodapp") ? "text-serial-palette-700 bg-serial-palette-100 font-medium" : "hover:text-serial-palette-700 font-normal"}`}
            >
              <FontAwesomeIcon
                className={`${pathname.includes("/settings/prodapp") ? "text-serial-palette-500" : "text-serial-palette-400"} w-9`}
                icon={faRocket}
              />
              <span
                className={`text-sm ${pathname.includes("/settings/prodapp") ? "text-serial-palette-700" : "hover:text-serial-palette-700"}`}
              >
                Production App
              </span>
            </NavLink>
            <NavLink
              end
              to="/settings/integrations"
              className={`flex items-center whitespace-nowrap py-2 pl-0.5 pr-2.5 ${pathname.includes("/settings/integrations") ? "text-serial-palette-700 bg-serial-palette-100 font-medium" : "hover:text-serial-palette-700 font-normal"}`}
            >
              <FontAwesomeIcon
                className={`${pathname.includes("/settings/integrations") ? "text-serial-palette-500" : "text-serial-palette-400"} w-9`}
                icon={faNetworkWired}
              />
              <span className={`text-sm`}>Integrations</span>
            </NavLink>
            <NavLink
              end
              to="/settings/api"
              className={`flex items-center whitespace-nowrap py-2 pl-0.5 pr-2.5 ${pathname.includes("/settings/api") ? "text-serial-palette-700 bg-serial-palette-100 font-medium" : "hover:text-serial-palette-700 font-normal"}`}
            >
              <FontAwesomeIcon
                className={`${pathname.includes("/settings/api") ? "text-serial-palette-500" : "text-serial-palette-400"} w-9`}
                icon={faCode}
              />
              <span className={`text-sm`}>Serial API</span>
            </NavLink>
            <NavLink
              end
              to="/settings/other"
              className={`flex items-center whitespace-nowrap py-2 pl-0.5 pr-2.5 ${pathname.includes("/settings/other") ? "text-serial-palette-700 bg-serial-palette-100 font-medium" : "hover:text-serial-palette-700 font-normal"}`}
            >
              <FontAwesomeIcon
                className={`${pathname.includes("/settings/other") ? "text-serial-palette-500" : "text-serial-palette-400"} w-9`}
                icon={faGears}
              />
              <span className={`text-sm`}>Other</span>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default SettingsSidebar;
