import { useContext } from "react";
import LabelFormatterProvider, { LabelFormatterContext } from "./LabelFormatterProvider";
import LabelFormatterSelector from "./components/LabelFormatterSelector";
import LabelFormatterEditor from "./components/LabelFormatterEditor";
import LabelFormatterPalette from "./components/LabelFormatterPalette";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Switch from "@shared/components/primitives/Switch";

export interface LabelFormatterProps {
  componentId: string | null;
  setModalOpen: (modalOpen: boolean) => void;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

const LabelFormatter: React.FC<LabelFormatterProps> = ({ componentId, setModalOpen, setUnsavedChanges }) => {
  return (
    <LabelFormatterProvider componentId={componentId} setModalOpen={setModalOpen} setUnsavedChanges={setUnsavedChanges}>
      <LabelFormatterWithContext />
    </LabelFormatterProvider>
  );
};

function LabelFormatterWithContext() {
  const { handleSetModalOpen, handleSave, showInactiveLabels, setShowInactiveLabels } = useContext(LabelFormatterContext);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="flex min-h-0 min-w-0 flex-grow">
        <LabelFormatterSelector />
        <DndProvider backend={HTML5Backend}>
          <LabelFormatterEditor />
          <LabelFormatterPalette />
        </DndProvider>
      </div>

      <div className="z-10 flex h-12 shrink-0 items-center justify-end border-t px-3">
        <div className="mr-auto flex items-center gap-x-2">
          <Switch size="sm" checked={showInactiveLabels} onClick={() => setShowInactiveLabels(!showInactiveLabels)} />
          <span className="text-serial-palette-600 text-sm">Show Inactive Labels</span>
        </div>
        <div className="flex items-center gap-x-1.5">
          <button
            className="btn-xs border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600 h-7"
            onClick={() => handleSetModalOpen(false)}
          >
            Cancel
          </button>
          <button className="btn-xs bg-serial-palette-800 hover:bg-serial-palette-600 h-7 text-white lg:flex" onClick={() => handleSave()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default LabelFormatter;
