import { Component, ErrorInfo, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class AgGridCellErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    console.error(error);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="flex h-full w-full items-center justify-start gap-2 text-red-500">
          <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
          <div className="flex flex-col text-xs">
            <p>Oops, you've found a bug with this cell.</p>
            <p>The Serial team has been notified.</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default AgGridCellErrorBoundary;
