import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { PartNumber, WorkOrder } from "@shared/types/databaseTypes";
import { ComponentType, WorkOrderStatus } from "@shared/types/databaseEnums";
import { ErrorBanner } from "@shared/components/error/types";
import Banner2 from "@shared/components/Banner2";
import { createOrUpdateComponentInstance } from "@shared/connections/api/componentInstances";
import { Loader } from "@shared/components/Loader";
import DropdownSearch from "@shared/components/dropdowns/DropdownSearch";
import ModalBasic from "@shared/components/modals/ModalBasic";
import { ProductionContext } from "../ProductionProvider";
import { fetchComponentInstancesByIdentifiers } from "../connections/supabase";
import { InstantiationModalMode } from "../types";
import { useTranslation } from "react-i18next";

interface ProductionModalInstantiationProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductionModalInstantiation: React.FC<ProductionModalInstantiationProps> = ({ modalOpen, setModalOpen }) => {
  const {
    db,
    operator,
    station,
    componentInstances,
    identifiers,
    handleUpdateIdentifiers,
    instantiationIdentifiers,
    setInstantiationIdentifiers,
    instantiationComponentId,
    instantiationWorkOrderId,
    setInstantiationWorkOrderId,
    setInstantiationComponentId,
    instantiationModalMode,
  } = useContext(ProductionContext);

  const [selectedPartNumberId, setSelectedPartNumberId] = useState<string | null>(null);
  const [partNumberSetByWorkOrder, setPartNumberSetByWorkOrder] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorBanner[]>([]); // Changed from a single object to an array
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const { company } = db;
  const component = useMemo(() => {
    return db.components.find((component: any) => component.id === instantiationComponentId);
  }, [instantiationComponentId]);

  const componentInstance = useMemo(() => {
    return componentInstances?.[0];
  }, [componentInstances]);

  const componentWordOrders = useMemo(() => {
    return db.workOrders
      .filter((workOrders: WorkOrder) => workOrders.component_id === instantiationComponentId)
      .filter((workOrder: WorkOrder) => workOrder.status === WorkOrderStatus.Production);
  }, [instantiationComponentId]);

  const componentPartNumbers = useMemo(() => {
    return db.partNumbers
      .filter((partNumber: PartNumber) => partNumber.component_id === instantiationComponentId)
      .filter((partNumber: PartNumber) => partNumber.is_active);
  }, [instantiationComponentId]);

  useEffect(() => {
    const partNumberId = componentWordOrders.find((workOrder: WorkOrder) => workOrder.id === instantiationWorkOrderId)?.part_number_id;
    if (partNumberId) {
      setSelectedPartNumberId(partNumberId);
      setPartNumberSetByWorkOrder(true);
    } else {
      setPartNumberSetByWorkOrder(false);
    }
  }, [instantiationWorkOrderId]);

  useEffect(() => {
    // Reset modal when it closes
    if (!modalOpen) {
      setInstantiationIdentifiers([]);
      setInstantiationComponentId(null);
      setSelectedPartNumberId(null);
      setInstantiationWorkOrderId(null);
      setErrors([]);
    }
    // If it's an update and the modal opens with a new identifier, set the part number and work order
    if (modalOpen && instantiationModalMode === InstantiationModalMode.Update) {
      setSelectedPartNumberId(componentInstance?.part_number_id ?? null);
      setInstantiationWorkOrderId(componentInstance?.work_order_id ?? null);
    }
    if (modalOpen && instantiationModalMode === InstantiationModalMode.Create) {
      inputRef.current?.focus();
    }
  }, [modalOpen]);

  const handleInstantiation = async () => {
    if (instantiationIdentifiers.length === 0) {
      setErrors([{ hide: false, isValid: false, message: "At least one new serial number or lot code is required", type: "error" }]);
      return;
    }
    if (!station && company?.config?.require_station) {
      setErrors([
        {
          hide: false,
          isValid: false,
          message: "Station is required by your company. You can set a station by clicking the button 'Select Station'",
          type: "error",
        },
      ]);
      console.error("Station is required");
      return;
    }
    if (!instantiationComponentId || !operator) {
      setErrors([
        {
          hide: false,
          isValid: false,
          message: "Component or operator not set properly. Try refreshing page or contact Serial support",
          type: "error",
        },
      ]);
      console.error("Component or operator not set properly. Component ID: ", instantiationComponentId, "Operator: ", operator);
      return;
    }
    setIsLoading(true);

    const newErrors: ErrorBanner[] = []; // Temporarily store new errors here
    for (const identifier of instantiationIdentifiers) {
      if (instantiationModalMode === InstantiationModalMode.Create) {
        // check if the identifier already exists if we're creating a new one
        const { data: existingComponentInstance, error: fetchError } = await fetchComponentInstancesByIdentifiers([identifier]);
        if (fetchError || (existingComponentInstance?.length ?? 0) > 0) {
          newErrors.push({ hide: false, isValid: false, message: `${identifier} already exists or could not be verified`, type: "error" });
          continue;
        }
      }

      const { error: errorCreatingComponentInstance } = await createOrUpdateComponentInstance(
        instantiationComponentId,
        identifier,
        operator.id,
        station?.id ?? null,
        selectedPartNumberId,
        instantiationWorkOrderId,
      );
      if (errorCreatingComponentInstance) {
        newErrors.push({
          hide: false,
          isValid: false,
          message: `Error ${instantiationModalMode === InstantiationModalMode.Create ? "creating" : "updating"} ${identifier}: ${errorCreatingComponentInstance}`,
          type: "error",
        });
      }
    }
    handleUpdateIdentifiers(identifiers);
    setIsLoading(false);

    if (newErrors.length > 0) {
      setErrors(newErrors);
    } else {
      setModalOpen(false);
      if (instantiationModalMode === InstantiationModalMode.Update) {
        handleUpdateIdentifiers(instantiationIdentifiers);
      }
    }
  };

  return (
    <ModalBasic
      id="instantiation-modal"
      title={instantiationModalMode === InstantiationModalMode.Update ? `Update ${instantiationIdentifiers}` : `New ${component?.name}`}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      classOverride="w-full md:w-[600px]"
    >
      <div className="border-serial-palette-200 border-b">
        {/* Render multiple error banners */}
        {errors.map((error, index) => (
          <div key={index} className="flex justify-center px-5 pt-2">
            <Banner2
              clickXHandler={() => {
                // Update click handler to remove a specific error from the array
                setErrors((currentErrors) => currentErrors.filter((_, i) => i !== index));
              }}
              hideX
              className="w-full"
              type={error.type}
              open={!error.hide}
            >
              {error.message}
            </Banner2>
          </div>
        ))}

        <div className="flex justify-center py-3">{component && <ComponentAvatar component={component} size="xl" />}</div>

        <div className="flex w-full flex-col space-y-5 px-5 pb-6">
          {/* Serial Number Input */}
          <div>
            <label className="mb-1 block text-sm font-medium">
              {" "}
              {component?.component_type === ComponentType.Sn ? "Serial Number" : "Lot Code"} <span className="text-red-600">*</span>
            </label>
            <input
              name="serial-number"
              ref={inputRef}
              disabled={true} // permanently disabled since SNs are entered in a process with the "Initialize" button
              className="form-input bg-serial-palette-100 text-serial-palette-400 w-full"
              type="text"
              value={instantiationIdentifiers ?? ""}
              placeholder={component?.component_type === ComponentType.Sn ? "Enter new serial number" : "Enter new lot code"}
            />
          </div>

          {/* Work Order Dropdown */}
          {componentWordOrders.length > 0 && (
            <div>
              <label className="mb-1 block text-sm font-medium">{t("workOrder")}</label>
              <div className="flex justify-between gap-2 whitespace-nowrap">
                <DropdownSearch
                  options={[{ id: null, label: "None" }, ...componentWordOrders.map((wo) => ({ id: wo.id, label: wo.name }))]}
                  selected={instantiationWorkOrderId ?? ""}
                  setSelected={setInstantiationWorkOrderId}
                  className=""
                  placeholder={`Select ${t("workOrder")}`}
                />
              </div>
            </div>
          )}

          {/* Part Number Dropdown */}
          {componentPartNumbers.length > 0 && (
            <div>
              <label className="mb-1 block text-sm font-medium">Part Number</label>
              <div className="flex justify-between gap-2 whitespace-nowrap">
                <DropdownSearch
                  options={[{ id: null, label: "None" }, ...componentPartNumbers.map((pn) => ({ id: pn.id, label: pn.pn }))]}
                  selected={selectedPartNumberId ?? ""}
                  setSelected={setSelectedPartNumberId}
                  className={partNumberSetByWorkOrder ? "!bg-serial-palette-100 pointer-events-none" : ""}
                  placeholder="Select part number"
                />
              </div>
            </div>
          )}
        </div>

        {/* Divider */}
        <div className="border-serial-palette-200 border-t" />

        {/* Footer */}
        <div className="flex justify-between px-5 py-3 pl-6">
          <div className="flex justify-end gap-x-2">
            <button
              className="btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setModalOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={() => handleInstantiation()}
              className="btn bg-serial-palette-800 hover:bg-serial-palette-600 gap-x-2 text-white"
            >
              {isLoading ? (
                <Loader styleOverride="w-[16px] h-[16px]" />
              ) : instantiationModalMode === InstantiationModalMode.Update ? (
                "Update"
              ) : (
                "Create"
              )}
            </button>
          </div>
        </div>
      </div>
    </ModalBasic>
  );
};

export default ProductionModalInstantiation;
