import { useMemo } from "react";
import { ProcessWithOrderActivityDatasets } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ProcessType } from "@shared/types/databaseEnums";
import { Dataset } from "@shared/types/databaseTypes";

interface UseComponentProcessesAllDataProps {
  componentIds: string[];
}

/**
 * Custom hook to fetch and organize all linked processes (and related data) for a list of component IDs.
 *
 * The hook fetches data related to processes, revisions, datasets, and fields for each component ID.
 * It then organizes this data into an object, where each key is a component ID and the value is an array
 * of ProcessAllData, which includes all the relevant data for that component's processes.
 *
 * @param {Object} args - The arguments object.
 * @param {string[]} args.componentIds - An array of component IDs for which to fetch and organize the data.
 *
 * @returns An object where each key is a component ID and the value is an array of ProcessAllData.
 */

const useComponentProcessesAllData = ({
  componentIds,
}: UseComponentProcessesAllDataProps): Record<string, ProcessWithOrderActivityDatasets[]> => {
  const allComponentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const allProcesses = useSelector((state: RootState) => state.db.processes);
  const allDatasets = useSelector((state: RootState) => state.db.datasets);
  const allFields = useSelector((state: RootState) => state.db.fields);

  const processesAllDataMap = useMemo(() => {
    const map: Record<string, ProcessWithOrderActivityDatasets[]> = {};
    componentIds.forEach((componentId) => {
      const processesAllData = allComponentProcessLinks
        .filter((link) => {
          return link.component_id === componentId && link.process?.type === ProcessType.Production;
        })
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .map((link, index) => {
          const process = allProcesses.find((process) => process.id === link.process_id && process.is_latest_revision);
          const order = index;
          const is_active = link.is_active;
          const datasets = allDatasets.filter((dataset: Dataset) => dataset.process_id === link.process_id && dataset.is_active);
          return { ...process, order, is_active, datasets };
        });
      //
      if (!processesAllData.some((process) => !process?.id)) {
        map[componentId] = processesAllData as ProcessWithOrderActivityDatasets[];
      } else {
        map[componentId] = [];
      }
    });

    return map;
  }, [componentIds, allComponentProcessLinks, allProcesses, allDatasets, allFields]);

  return processesAllDataMap;
};

export default useComponentProcessesAllData;
