import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { MeasureKey, MeasureType } from "../types";
import { MeasureKeyWithReferences } from "../hooks/useMeasureKeysWithReferences";
import TagBasic from "@shared/components/primitives/TagBasic";

interface MeasureKeySelectorAccordionByTypeProps {
  forceOpen: boolean;
  measureKeys: MeasureKeyWithReferences[];
  measureType: MeasureType;
  onSelect: (key: MeasureKey) => void;
}

const MeasureKeySelectorAccordionByType: React.FC<MeasureKeySelectorAccordionByTypeProps> = ({
  forceOpen,
  measureKeys,
  measureType,
  onSelect,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="flex w-full flex-col rounded border">
      <button
        className="bg-serial-palette-50 flex w-full items-center justify-between rounded border-b pb-2 pl-1 pr-3 pt-2 text-sm last:border-b-0"
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <div className="flex h-full items-center">
          <FontAwesomeIcon className="text-serial-palette-500 w-6" icon={accordionOpen ? faCaretDown : faCaretRight} />
          <TagBasic.Root size="sm" variant={measureType}>
            <TagBasic.Icon />
            <TagBasic.Name />
          </TagBasic.Root>
        </div>
        <div className="flex gap-2">
          <span className="text-serial-palette-500 font-semibold">{measureKeys.filter((k) => k.isActive).length} Active</span>
          <span className="text-serial-palette-400">{measureKeys.filter((k) => !k.isActive).length} Inactive</span>
        </div>
      </button>
      {(accordionOpen || forceOpen) && (
        <div className="my-1 flex flex-col gap-y-1">
          {measureKeys.map((key, index) => {
            return (
              <button
                key={index}
                className="hover:bg-serial-palette-100 flex w-full items-center gap-x-2 px-3 py-1 text-sm"
                onClick={() => onSelect(key)}
              >
                <div className={`flex w-full items-center gap-x-2 ${!key.isActive && "opacity-60"}`}>
                  {key.label && <p className="text-serial-palette-600 truncate font-bold">{key.label}</p>}
                  {key.label && <FontAwesomeIcon icon={faCircle} className="text-[6px]" />}
                  <p className="text-serial-palette-500 truncate font-semibold">{key.component?.name}</p>
                  {key.process?.name && <FontAwesomeIcon icon={faCircle} className="text-[6px]" />}
                  <p className="text-serial-palette-400 truncate">{key.process?.name}</p>
                </div>
                {!key.isActive && (
                  <div className="flex h-5 items-center justify-center rounded-full bg-rose-200 px-2 text-[9px] text-rose-600">
                    INACTIVE
                  </div>
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MeasureKeySelectorAccordionByType;
