import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChartBar, faChartLine, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { MeasureType } from "@shared/measures/types";

export type GraphType = "scatter" | "line" | "bar" | "area" | "pie" | "donut";

export const graphTypeGroups: { graphTypes: GraphType[]; label: string; icon: IconProp }[] = [
  {
    label: "Scatter / Line",
    graphTypes: ["scatter", "line"],
    icon: faChartLine,
  },
  {
    label: "Bar / Area",
    graphTypes: ["bar", "area"],
    icon: faChartBar,
  },
  {
    label: "Pie / Donut",
    graphTypes: ["pie", "donut"],
    icon: faChartPie,
  },
];

export const graphConfigurations: Record<
  GraphType,
  {
    xAxis: boolean;
    xAxisAllowedMeasures?: MeasureType[]; // Allow all if not specified
    yAxis: boolean;
    yAxisAllowedMeasures?: MeasureType[]; // Allow all if not specified
    categories: boolean;
    categoriesAllowedMeasures?: MeasureType[]; // Allow all if not specified
  }
> = {
  scatter: {
    xAxis: true,
    xAxisAllowedMeasures: [MeasureType.ParametricQuantitative, MeasureType.Datetime, MeasureType.Timestamp, MeasureType.CycleTime],
    yAxis: true,
    yAxisAllowedMeasures: [MeasureType.ParametricQuantitative, MeasureType.CycleTime],
    categories: false,
  },
  line: {
    xAxis: true,
    xAxisAllowedMeasures: [MeasureType.ParametricQuantitative, MeasureType.Datetime, MeasureType.Timestamp, MeasureType.CycleTime],
    yAxis: true,
    yAxisAllowedMeasures: [MeasureType.ParametricQuantitative, MeasureType.CycleTime],
    categories: false,
  },
  bar: {
    xAxis: false,
    yAxis: true,
    categories: true,
  },
  area: {
    xAxis: false,
    yAxis: true,
    categories: true,
  },
  pie: {
    xAxis: false,
    yAxis: false,
    categories: true,
  },
  donut: {
    xAxis: false,
    yAxis: false,
    categories: true,
  },
};
