import React, { useEffect } from "react";
import { BarcodeElement, LabelFormatterLabelElementProps } from "../../types";
import JsBarcode from "jsbarcode";
import { PX_PER_MM } from "../../constants";

const LabelElementBarcode: React.FC<LabelFormatterLabelElementProps<BarcodeElement>> = ({ element, label, identifier }) => {
  const barcodeId = `barcode-${element.id}`;

  useEffect(() => {
    // check to make sure the barcode svg has been rendered
    if (!document.getElementById(barcodeId) || !identifier) return;
    JsBarcode(`#${barcodeId}`, identifier, {
      format: "code128",
      height: element.height * PX_PER_MM * 4,
      width: 4,
      displayValue: false,
    });
  }, [label, identifier]);

  return (
    <div
      className="my-1 flex items-center justify-center overflow-hidden"
      style={{ width: `${element.width * PX_PER_MM}px`, height: `${element.height * PX_PER_MM}px` }}
    >
      <svg className="w-full" id={barcodeId} />
    </div>
  );
};

export default LabelElementBarcode;
