/**
 * Enums for Supabase Database
 * ======================================
 * This file contains TypeScript enums that represent the enums in Supabase.
 *
 * Auto-generating JavaScript versions:
 * ------------------------------------
 * To auto-generate the JavaScript versions of these enums, run the following command:
 *
 *   node convertEnums.js databaseEnums.ts
 *
 * This command will generate:
 *   - enums.js: The JavaScript version of this TypeScript file.
 *
 * After running the command, you can import the enums in your JavaScript files using
 * the `import` statement (for ES modules).
 */

export enum ApprovalStatus {
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

// ------------- 'alerts' Table -------------

export enum AlertType {
  Override = "OVERRIDE",
}

// ------------- 'components' Table -------------

export enum ComponentType {
  Sn = "SN",
  Lot = "LOT",
}

// ------------- 'datasets' Table -------------

export enum DataType {
  Uid = "UID",
  Link = "LINK",
  ParametricQuantitative = "PARAMETRIC_QUANTITATIVE",
  ParametricQualitative = "PARAMETRIC_QUALITATIVE",
  Image = "IMAGE",
  File = "FILE",
  Checkbox = "CHECKBOX",
  PassFail = "PASSFAIL",
  Datetime = "DATETIME",
}

// ------------- 'files' Table -------------

export enum FileFormat {
  Other = "OTHER",
  Csv = "CSV",
  Json = "JSON",
}

// ------------- 'fields' Table -------------

export enum FieldType {
  Uid = "UID",
  Link = "LINK",
  Parametric = "PARAMETRIC",
  Checkbox = "CHECKBOX",
  PassFail = "PASSFAIL",
  Image = "IMAGE",
  File = "FILE",
}

export enum UidFieldMethod {
  Scan = "SCAN",
}

export enum ParametricFieldType {
  Integer = "INTEGER",
  Number = "NUMBER",
  String = "STRING",
}

export enum ImageFieldMethod {
  Upload = "UPLOAD",
  Camera = "CAMERA",
}

// ------------- 'processes' Table -------------

export enum ProcessType {
  Production = "PRODUCTION",
  Instantiation = "INSTANTIATION", // need to rename to ASSIGN_PART_NUMBER
  AssignWorkOrder = "ASSIGN_WORK_ORDER",
  Repair = "REPAIR",
  Field = "FIELD",
  Property = "PROPERTY",
}

// ------------- Dashboard insight types -------------

export enum DashboardInsightType {
  Custom = "CUSTOM",
  StatusCountBarGraph = "STATUS_COUNT_BAR_GRAPH",
  WipTracker = "WIP_TRACKER",
  WipByProcessChart = "WIP_BY_PROCESS_CHART",
  BuildTracker = "BUILD_TRACKER",
  LatestActivity = "LATEST_ACTIVITY",
  YieldGraph = "YIELD_GRAPH",
  CycleTimeByComponent = "CYCLE_TIME_BY_COMPONENT",
  CycleTimeByProcess = "CYCLE_TIME_BY_PROCESS",
  YieldComponentInstances = "YIELD_COMPONENT_INSTANCES",
  YieldFailureByProcess = "YIELD_FAILURE_BY_PROCESS",
  YieldByComponent = "YIELD_BY_COMPONENT",
  YieldByProcess = "YIELD_BY_PROCESS",
  Alerts = "ALERTS",
}

// ------------- 'report_templates' Table -------------

export enum ReportTemplateType {
  GridBuilderView = "GRID_BUILDER_VIEW",
  GraphBuilderView = "GRAPH_BUILDER_VIEW",
  SnLookupPdfTemplate = "SN_LOOKUP_PDF_TEMPLATE",
  Dashboard = "DASHBOARD",
  CustomInsight = "CUSTOM_INSIGHT",
  Datasheet = "DATASHEET",
}

// ------------- 'stations' Table -------------

export enum StationType {
  Dedicated = "DEDICATED",
  Universal = "UNIVERSAL",
}

// ------------- 'users' Table -------------

export enum UserRole {
  Admin = "ADMIN",
  Member = "MEMBER",
  Operations = "OPERATIONS",
  ReadOnly = "READONLY",
}

// ------------- 'unique_identifiers' Table -------------

export enum UniqueIdentifierStatus {
  Complete = "COMPLETE",
  Planned = "PLANNED",
  Defective = "DEFECTIVE",
  Wip = "WIP",
}

// ------------- 'unique_identifier_activity_log' Table -------------

export enum UniqueIdentifierActivityType {
  Comment = "COMMENT",
}

// ------------- 'unique_identifier_links' Table -------------

export enum RemovalReason {
  BreakPriorLink = "BREAK_PRIOR_LINK",
  ExplicitUnlink = "EXPLICIT_UNLINK",
  NewParent = "NEW_PARENT",
}

// ------------- 'work_instructions' Table -------------

export enum WorkInstructionType {
  Image = "IMAGE",
  Pdf = "PDF",
  Video = "VIDEO",
  Unsupported = "UNSUPPORTED",
}

// ------------- 'work_orders' Table -------------

export enum WorkOrderStatus {
  Draft = "DRAFT",
  Review = "REVIEW",
  Production = "PRODUCTION",
  Complete = "COMPLETE",
  Canceled = "CANCELED",
  Archived = "ARCHIVED",
}

export enum WorkOrderActivityType {
  Comment = "COMMENT",
  Create = "CREATE",
  SetStatus = "SET_STATUS",
  UpdateWODetails = "UPDATE_WO_DETAILS",
  SetApproval = "SET_APPROVAL",
  RequestApproval = "UPDATE_APPROVERS",
  RemoveApproval = "REMOVE_APPROVAL",
  AddAttachment = "ADD_ATTACHMENT",
  RemoveAttachment = "REMOVE_ATTACHMENT",
}

// ------------- 'label_formats' Table -------------

export enum LabelSizeUnit {
  mm = "MM",
  in = "IN",
}

// ------------- 'label_elements' Table -------------

export enum LabelElementType {
  Barcode = "BARCODE",
  QRCode = "QR_CODE",
  Identifier = "IDENTIFIER",
  Text = "TEXT",
  Image = "IMAGE",
}

// ------------- Aliases -------------

// In certain places we refer to PARAMETRIC_QUALITATIVE PARAMETRIC_QUANTITATIVE, and it other places we use TEXT and NUMERICAL.
// At some point we want to migrate entirely over to TEXT and NUMERICAL but for now we have this alias interface to support it.
export const aliases: Record<string, string[]> = {
  [DataType.ParametricQualitative]: ["PARAMETRIC_QUALITATIVE", "TEXT"],
  [DataType.ParametricQuantitative]: ["PARAMETRIC_QUANTITATIVE", "NUMERICAL"],
};

export const mapAliasToOfficial = (alias: string): string => {
  for (const [key, value] of Object.entries(aliases)) {
    if (value.includes(alias)) return key;
  }
  return alias;
};
