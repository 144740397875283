import Combobox from "@shared/components/primitives/Combobox";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import { useMemo, useState } from "react";

const frameworks = [
  {
    value: "next.js",
    label: "Next.js",
  },
  {
    value: "sveltekit",
    label: "SvelteKit",
  },
  {
    value: "nuxt.js",
    label: "Nuxt.js",
  },
  {
    value: "remix",
    label: "Remix",
  },
  {
    value: "astro",
    label: "Astro",
  },
];

const FrontendPrimitivesCombobox = () => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const selected = useMemo(() => {
    return frameworks.find((framework) => framework.value === selectedValue) ?? null;
  }, [selectedValue]);

  return (
    <FrontendPrimitivesCard title="Combobox">
      <Combobox.Root value={selectedValue} onValueChange={setSelectedValue}>
        <Combobox.Trigger>{selected?.label}</Combobox.Trigger>
        <Combobox.Content>
          {frameworks.map((framework) => (
            <Combobox.Item key={framework.value} value={framework.value}>
              {framework.label}
            </Combobox.Item>
          ))}
        </Combobox.Content>
      </Combobox.Root>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesCombobox;
