import React, { useState } from "react";
import MeasuresFilterBuilderConditionBlock from "./MeasuresFilterBuilderConditionBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FilterJoinOperator } from "@shared/types/databaseTypes";
import useMeasures from "@shared/measures/MeasuresProvider";
import MeasureKeySelector from "../MeasureKeySelector";
import Button from "@shared/components/primitives/Button";
import MeasuresFilterBuilderOperatorSelector from "./MeasuresFilterBuilderOperatorSelector";
import MeasuresFilterBuilderValueSelector from "./MeasuresFilterBuilderValueSelector";

enum DropdownMode {
  Filters = "FILTERS",
  Values = "VALUES",
  Operators = "OPERATORS",
}

const MeasuresFilterBuilder = () => {
  const { filterBy, handleAddFilterCondition, handleRemoveFilterCondition, filterJoinOperator, setFilterJoinOperator } = useMeasures();

  const [subDropdownMode, setSubDropdownMode] = useState<DropdownMode | null>(null);
  const [focusedFilterConditionIndex, setFocusedFilterConditionIndex] = useState<number | null>(null);

  const handleSetSubDropdown = (mode: DropdownMode, filterConditionIndex: number | null) => {
    setSubDropdownMode(mode);
    setFocusedFilterConditionIndex(filterConditionIndex);
  };

  return (
    <div className="flex w-full flex-col overflow-hidden bg-white">
      <div
        className={`bg-serial-palette-50 z-10 flex flex-wrap gap-1 ${subDropdownMode !== null && "border-b"} p-2 ${filterBy.length === 0 && "justify-end"}`}
      >
        {filterBy.map((filterCondition, index) => {
          return (
            <React.Fragment key={index}>
              <MeasuresFilterBuilderConditionBlock
                filterCondition={filterCondition}
                onTriggerOperatorSelector={() => handleSetSubDropdown(DropdownMode.Operators, index)}
                onTriggerValueSelector={() => handleSetSubDropdown(DropdownMode.Values, index)}
                onRemoveFilterCondition={() => {
                  handleRemoveFilterCondition(index);
                  setFocusedFilterConditionIndex(null);
                  setSubDropdownMode(null);
                }}
              />
              {index < filterBy.length - 1 && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-8 px-2"
                  onClick={() =>
                    setFilterJoinOperator(filterJoinOperator === FilterJoinOperator.And ? FilterJoinOperator.Or : FilterJoinOperator.And)
                  }
                >
                  {filterJoinOperator === FilterJoinOperator.And ? "and" : "or"}
                </Button>
              )}
            </React.Fragment>
          );
        })}
        <Button
          variant="ghost"
          size="sm"
          symmetric
          onClick={() => handleSetSubDropdown(DropdownMode.Filters, null)}
          disabled={subDropdownMode === DropdownMode.Filters}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      <div
        className={`scrollbar-hide flex w-full flex-col overflow-auto transition-all ${subDropdownMode === DropdownMode.Filters ? "h-96" : subDropdownMode !== null ? "h-48" : "h-0"}`}
      >
        {/* Add New Filter */}
        {subDropdownMode === DropdownMode.Filters && <MeasureKeySelector onSelect={(measureKey) => handleAddFilterCondition(measureKey)} />}
        {/* Select Operator */}
        {subDropdownMode === DropdownMode.Operators && focusedFilterConditionIndex !== null && (
          <MeasuresFilterBuilderOperatorSelector
            focusedFilterConditionIndex={focusedFilterConditionIndex}
            setSubDropdownMode={setSubDropdownMode}
          />
        )}
        {/* Select Value */}
        {subDropdownMode === DropdownMode.Values && focusedFilterConditionIndex !== null && (
          <MeasuresFilterBuilderValueSelector
            focusedFilterConditionIndex={focusedFilterConditionIndex}
            setSubDropdownMode={setSubDropdownMode}
          />
        )}
      </div>
    </div>
  );
};

export default MeasuresFilterBuilder;
