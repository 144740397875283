import store from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { ParametricQualitative } from "@shared/types/databaseTypes";

// ------------------- READ FUNCTIONS ------------------- //

export const fetchPropertyData = async (process_entry_id: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: propertyData, error: propertyDataError } = await supabase
    .from("parametric_qualitative")
    .select("value, dataset:dataset_id(name)")
    .eq("process_entry_id", process_entry_id)
    .returns<ParametricQualitative[]>()
    .single();

  if (propertyDataError) {
    console.error(propertyDataError);
    return null;
  }

  // Transform the data to the simpler structure
  if (propertyData && propertyData.dataset) {
    const simpleStructure = {
      name: propertyData.dataset.name,
      value: propertyData.value,
    };
    return simpleStructure;
  } else {
    return { name: null, value: null };
  }
};
