import React, { useState, useEffect, useContext, createContext, useMemo } from "react";
import { useSelector } from "react-redux";
import Command from "../components/primitives/Command";
import Modal from "../components/primitives/Modal";
import { RootState } from "@shared/redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCube,
  faClipboardList,
  faCubes,
  faFlask,
  faRocket,
  faSatelliteDish,
  faTableCells,
  faCogs,
  faPlus,
  faUsers,
  faArrowRight,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useDebounceValue } from "usehooks-ts";
import { ToastContext } from "./ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { ComponentProcessLink, Process } from "@shared/types/databaseTypes";

interface Command {
  id: string;
  label: string;
  type: "action" | "component" | "dataset" | "work_order" | "station" | "component_instance" | "process";
  callback?: () => void;
  icon?: IconProp;
}

interface CommandContextProps {
  triggerCommandModal: () => void;
}

const defaultContext: CommandContextProps = {
  triggerCommandModal: () => {},
};

interface ProcessWithComponentProcessLinks extends Process {
  component_process_links: ComponentProcessLink[];
}

const CommandContext = createContext<CommandContextProps>(defaultContext);

export const CommandProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebounceValue("", 500);

  const components = useSelector((state: RootState) => state.db.components);
  const workOrders = useSelector((state: RootState) => state.db.workOrders);
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const processes = useSelector((state: RootState) => state.db.processes);
  const stations = useSelector((state: RootState) => state.db.stations);
  const location = useLocation();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { triggerToast } = useContext(ToastContext);

  // listen for command K or control K to open search modal
  useEffect(() => {
    if (location.pathname.startsWith("/production")) {
      return;
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setOpen(true);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [location.pathname]);

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    setDebouncedSearchTerm(value);
  };

  const handleSelect = (command: Command) => {
    if (command.callback) {
      command.callback();
    }
    switch (command.type) {
      case "action":
        break;
      case "component":
        navigate(`/component/${command.id}`);
        break;
      case "dataset":
        navigate(`/datasets/${command.id}`);
        break;
      case "work_order":
        navigate(`/workorder/${command.id}`);
        break;
      case "component_instance":
        navigate(`/snlookup?uid=${command.id}`);
        break;
      case "station":
        navigate(`/stations/${command.id}`);
        break;
      case "process":
        const componentId = componentProcessLinks.find((link) => link.process_id === command.id)?.component_id;
        if (!componentId) {
          triggerToast(
            ToastType.Warning,
            "Process Not Linked",
            "This process is not linked to a component. Please contact Serial support for assistance.",
          );
          return;
        }
        navigate(`/component/${componentId}`);
        break;
    }

    setOpen(false);
  };

  const actions: Command[] = useMemo(
    () => [
      { id: "", label: "Settings", type: "action", callback: () => navigate("/settings/account"), icon: faCogs },
      { id: "", label: "Team", type: "action", callback: () => navigate("/settings/team"), icon: faUsers },
      { id: "", label: "Datasets", type: "action", callback: () => navigate("/datasets"), icon: faFlask },
      { id: "", label: "Stations", type: "action", callback: () => navigate("/stations"), icon: faSatelliteDish },
      { id: "", label: t("workOrders"), type: "action", callback: () => navigate("/workorder"), icon: faClipboardList },
      { id: "", label: "Components", type: "action", callback: () => navigate("/componentslist"), icon: faCubes },
      { id: "", label: "Grid Builder", type: "action", callback: () => navigate("/gridbuilder"), icon: faTableCells },
      { id: "", label: "Production", type: "action", callback: () => navigate("/production"), icon: faRocket },
      { id: "", label: "New Component", type: "action", callback: () => navigate("/component/new"), icon: faPlus },
    ],
    [],
  );

  const { filteredComponentInstances, filteredDatasets } = useMemo(() => {
    if (debouncedSearchTerm === "") return { filteredComponentInstances: [], filteredDatasets: [] };
    const filteredComponentInstances = componentInstances.filter((componentInstance) => {
      return !componentInstance.is_archived && componentInstance.identifier.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
    const filteredDatasets = datasets.filter((dataset) => {
      return dataset.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return { filteredComponentInstances, filteredDatasets };
  }, [debouncedSearchTerm]);

  const processesWithComponentProcessLinks = useMemo(() => {
    return processes.map((process) => {
      return {
        ...process,
        component_process_links: componentProcessLinks.filter((link) => link.process_id === process.id),
      };
    });
  }, [processes, componentProcessLinks]);

  const { filteredActions, filteredWorkOrders, filteredComponents, filteredProcesses, filteredStations } = useMemo(() => {
    if (searchTerm === "")
      return {
        filteredActions: actions,
        filteredWorkOrders: [],
        filteredComponents: [],
        filteredDatasets: [],
        filteredProcesses: [],
        filteredStations: [],
      };
    const filteredActions = actions.filter((action) => {
      return action.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
    const filteredWorkOrders = workOrders.filter((workOrder) => {
      return workOrder.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    const filteredComponents = components.filter((component) => {
      return component.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    const filteredProcesses: ProcessWithComponentProcessLinks[] = processesWithComponentProcessLinks.filter((process) => {
      return process.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    const filteredStations = stations.filter((station) => {
      return station.name?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return { filteredActions, filteredWorkOrders, filteredComponents, filteredDatasets, filteredProcesses, filteredStations };
  }, [searchTerm]);

  return (
    <CommandContext.Provider value={{ triggerCommandModal: () => setOpen(true) }}>
      {children}
      <Modal.Root open={open} onOpenChange={setOpen}>
        <Modal.Title className="hidden">Command</Modal.Title>
        <Modal.Content>
          <Command.Root shouldFilter={false}>
            <Command.Input
              value={searchTerm}
              onValueChange={(value) => handleSearchTermChange(value)}
              placeholder="Type a command or search..."
              isLoading={debouncedSearchTerm !== searchTerm}
            />
            <Command.List>
              <Command.Empty>No results found</Command.Empty>
              {filteredActions.length > 0 && (
                <Command.Group heading="Actions">
                  {filteredActions.map((action, index) => (
                    <Command.Item key={index} onSelect={() => handleSelect(action)}>
                      <span className="text-serial-palette-500 mr-1 flex w-5 items-center justify-center text-sm">
                        <FontAwesomeIcon icon={action.icon ?? faArrowRight} />
                      </span>
                      <span>{action.label}</span>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
              <Command.Separator />
              {debouncedSearchTerm === searchTerm && filteredComponentInstances.length > 0 && (
                <Command.Group heading="Serial Numbers">
                  {filteredComponentInstances.map((componentInstance, index) => (
                    <Command.Item
                      key={index}
                      onSelect={() =>
                        handleSelect({ id: componentInstance.id, label: componentInstance.identifier, type: "component_instance" })
                      }
                    >
                      <span className="text-serial-palette-500 mr-1 flex w-5 items-center justify-center text-sm">
                        <FontAwesomeIcon icon={faCube} />
                      </span>
                      <span>{componentInstance.identifier}</span>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
              <Command.Separator />
              {filteredComponents.length > 0 && (
                <Command.Group heading="Components">
                  {filteredComponents.map((component, index) => (
                    <Command.Item key={index} onSelect={() => handleSelect({ id: component.id, label: component.name, type: "component" })}>
                      <span className="text-serial-palette-500 mr-1 flex w-5 items-center justify-center text-sm">
                        <FontAwesomeIcon icon={faCubes} />
                      </span>
                      <span>{component.name}</span>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
              <Command.Separator />
              {filteredDatasets.length > 0 && (
                <Command.Group heading="Datasets">
                  {filteredDatasets.map((dataset, index) => (
                    <Command.Item key={index} onSelect={() => handleSelect({ id: dataset.id, label: dataset.name, type: "dataset" })}>
                      <span className="text-serial-palette-500 mr-1 flex w-5 items-center justify-center text-sm">
                        <FontAwesomeIcon icon={faFlask} />
                      </span>
                      <span>{dataset.name}</span>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
              <Command.Separator />
              {filteredWorkOrders.length > 0 && (
                <Command.Group heading={t("workOrders")}>
                  {filteredWorkOrders.map((workOrder, index) => (
                    <Command.Item
                      key={index}
                      onSelect={() => handleSelect({ id: workOrder.id, label: workOrder.name, type: "work_order" })}
                    >
                      <span className="text-serial-palette-500 mr-1 flex w-5 items-center justify-center text-sm">
                        <FontAwesomeIcon icon={faClipboardList} />
                      </span>
                      <span>{workOrder.name}</span>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
              {filteredProcesses.length > 0 && (
                <Command.Group heading="Processes">
                  {filteredProcesses.map((process, index) => (
                    <Command.Item key={index} onSelect={() => handleSelect({ id: process.id, label: process.name, type: "process" })}>
                      <span className="text-serial-palette-500 mr-1 flex w-5 items-center justify-center text-sm">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>
                      <span>{process.name}</span>
                      <span className="text-serial-palette-400">{process.component_process_links?.[0]?.component?.name}</span>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
              {filteredStations.length > 0 && (
                <Command.Group heading="Stations">
                  {filteredStations.map((station, index) => (
                    <Command.Item key={index} onSelect={() => handleSelect({ id: station.id, label: station.name ?? "", type: "station" })}>
                      <span className="text-serial-palette-500 mr-1 flex w-5 items-center justify-center text-sm">
                        <FontAwesomeIcon icon={faSatelliteDish} />
                      </span>
                      <span>{station.name}</span>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
            </Command.List>
          </Command.Root>
        </Modal.Content>
      </Modal.Root>
    </CommandContext.Provider>
  );
};

export const useCommand = () => {
  const context = useContext(CommandContext);
  if (!context) {
    throw new Error("useCommand must be used within a CommandProvider");
  }
  return context;
};
