import Switch from "@shared/components/primitives/Switch";
import Section from "./MeasuresGraphConfigSection";
import ButtonGroup from "@shared/components/primitives/ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faInfoCircle, faPercentage } from "@fortawesome/free-solid-svg-icons";
import { useMeasuresGraph } from "./MeasuresGraph";
import { useMemo } from "react";
import Tooltip from "@shared/components/primitives/Tooltip";

const MeasuresGraphConfigOptions = () => {
  const {
    graphType,
    showRetests,
    setShowRetests,
    showGridLines,
    setShowGridLines,
    showLegend,
    setShowLegend,
    relative,
    setRelative,
    xAxisMeasureKey,
    categoriesMeasureKey,
    yAxisMeasureKey,
  } = useMeasuresGraph();

  const allowShowRetests = useMemo(() => {
    const processIdsSet = new Set(
      [xAxisMeasureKey?.process_id, categoriesMeasureKey?.process_id, yAxisMeasureKey?.process_id].filter((id) => id) as string[],
    );
    return processIdsSet.size === 1;
  }, [xAxisMeasureKey, categoriesMeasureKey, yAxisMeasureKey]);

  return (
    <Section.Root>
      <Section.Title>Options</Section.Title>
      <Section.Option className="h-7 flex-row items-center">
        <Section.Label className="flex items-center gap-1">
          <span>Include Retests</span>
          <Tooltip.Root>
            <Tooltip.Trigger>
              <FontAwesomeIcon icon={faInfoCircle} className="text-gray-500" />
            </Tooltip.Trigger>
            <Tooltip.Content variant="light" align="start" className="flex w-72 flex-col gap-1">
              <span className="font-bold">Shows Repeated Process Entries</span>
              <span>
                If a process has been submitted more than once, you can optionally display each entry as a separate data point in the graph.
                Note that this is only allowed if all variables in the graph are collected from the same process.
              </span>
            </Tooltip.Content>
          </Tooltip.Root>
        </Section.Label>
        <Switch disabled={!allowShowRetests} checked={showRetests} onCheckedChange={(checked) => setShowRetests(checked)} />
      </Section.Option>
      <Section.Option className="h-7 flex-row items-center">
        <Section.Label>Show Legend</Section.Label>
        <Switch checked={showLegend} onCheckedChange={setShowLegend} />
      </Section.Option>
      <Section.Option className="h-7 flex-row items-center">
        <Section.Label>Show Grid Lines</Section.Label>
        <Switch checked={showGridLines} onCheckedChange={setShowGridLines} />
      </Section.Option>
      {graphType && ["bar", "pie"].includes(graphType) && (
        <Section.Option className="h-7 flex-row items-center">
          <Section.Label>Relative vs Absolute</Section.Label>
          <ButtonGroup.Root size="sm" variant="outline">
            <ButtonGroup.Button tooltip="Relative (%)" onClick={() => setRelative(true)} variant={relative ? "default" : "outline"}>
              <FontAwesomeIcon icon={faPercentage} />
            </ButtonGroup.Button>
            <ButtonGroup.Button tooltip="Absolute" onClick={() => setRelative(false)} variant={!relative ? "default" : "outline"}>
              <FontAwesomeIcon icon={faCalculator} />
            </ButtonGroup.Button>
          </ButtonGroup.Root>
        </Section.Option>
      )}
    </Section.Root>
  );
};
export default MeasuresGraphConfigOptions;
