import React, { useRef, useEffect, useMemo, useContext } from "react";
import { Chart, Filler, Tooltip, Legend, DoughnutController, ArcElement } from "chart.js";
import { tailwindConfig, truncateString } from "@shared/utils/helpers";
import { DatasetsContext } from "../Datasets";
import { DataType } from "@shared/types/databaseEnums";
import { CHART_DEBOUNCE_DELAY, DEFAULT_DONUT_CHART_COLORS } from "../constants";

interface ChartingCanvasElement extends HTMLCanvasElement {
  chart?: Chart;
}

Chart.register(DoughnutController, ArcElement, Filler, Tooltip, Legend);

const DatasetAnalyticsPerformanceDonutChart: React.FC = () => {
  const canvas = useRef<ChartingCanvasElement>(null);
  const context = useContext(DatasetsContext);

  const chartData = useMemo(() => {
    const categoryCounts: Record<string, number> = context.data.reduce(
      (acc, datapoint) => {
        const value = String((datapoint as any)[context.selectedDataset?.data_type === DataType.Checkbox ? "is_checked" : "value"]);
        if (acc[value] === undefined) {
          acc[value] = 1;
        } else {
          acc[value] += 1;
        }
        return acc;
      },
      {} as Record<string, number>,
    );
    return { categoryCounts };
  }, [context.data]);

  useEffect(() => {
    let chart: Chart<"doughnut", number[], string> | null = null;
    let backgroundColors: string[] = DEFAULT_DONUT_CHART_COLORS;
    let labels = Object.keys(chartData.categoryCounts);
    if (context.selectedDataset?.data_type === DataType.Checkbox) {
      backgroundColors = [(tailwindConfig().theme?.colors?.neutral as any)[800], (tailwindConfig().theme?.colors?.neutral as any)[300]];
      labels = ["Checked", "Unchecked"];
    } else if (context.selectedDataset?.data_type === DataType.PassFail) {
      backgroundColors = [(tailwindConfig().theme?.colors?.green as any)[500], (tailwindConfig().theme?.colors?.red as any)[500]];
      labels = ["Pass", "Fail"];
    }
    try {
      let debounceTimer: NodeJS.Timeout;
      debounceTimer = setTimeout(() => {
        if (canvas.current === null) return;
        chart = new Chart(canvas.current, {
          type: "doughnut",
          data: {
            labels: labels,
            datasets: [
              {
                label: context.selectedDataset?.name,
                data: Object.values(chartData.categoryCounts),
                backgroundColor: backgroundColors,
              },
            ],
          },
          options: {
            layout: {
              padding: 20,
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: (context) => truncateString(context[0].dataset.label ?? "", 25),
                },
              },
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  boxHeight: 15,
                  boxWidth: 15,
                },
              },
            },
            interaction: {
              intersect: true,
              mode: "nearest",
            },
            maintainAspectRatio: false,
            resizeDelay: 200,
          },
        });
      }, CHART_DEBOUNCE_DELAY);
      return () => {
        clearTimeout(debounceTimer);
        if (chart) {
          chart.destroy();
        }
      };
    } catch (error) {
      console.error("Chart initialization failed:", error);
    }
  }, [chartData]);

  return (
    <div className="relative h-full w-full">
      <canvas ref={canvas}></canvas>
    </div>
  );
};

export default DatasetAnalyticsPerformanceDonutChart;
