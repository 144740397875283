import { RootState } from "@shared/redux/store";
import { Component } from "@shared/types/databaseTypes";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useTopLevelComponents = (): Component[] => {
  const components = useSelector((state: RootState) => state.db.components);
  const componentLinks = useSelector((state: RootState) => state.db.componentLinks);

  // top level components are components with no parent in the links table and at least one child
  const topLevelComponents = useMemo(() => {
    return components.filter((component) => {
      const hasChildren = componentLinks.some((link) => link.component_id === component.id);
      const hasParent = componentLinks.some((link) => link.has_child_of_id === component.id);
      return hasChildren && !hasParent;
    });
  }, []);

  return topLevelComponents;
};

export default useTopLevelComponents;
