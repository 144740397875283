import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";

function OperatorTableItem({ number, rowData, onSave, handleDelete, handleActivate }) {
  const [isEditing, setIsEditing] = useState(rowData.id === "new");
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [pin, setPin] = useState(null);
  const nameInputRef = useRef(null);

  const handleSave = async () => {
    const editedRowData = { ...rowData, pin: pin.trim(), first_name: firstName.trim(), last_name: lastName.trim() };
    const saveResult = await onSave(editedRowData);
    setIsEditing(!saveResult);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleSave();
    }
  };

  useEffect(() => {
    setFirstName(rowData.first_name);
    setLastName(rowData.last_name);
    setPin(rowData.pin);
  }, [rowData]);

  useEffect(() => {
    if (isEditing) {
      nameInputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <tr className={`${!rowData.is_active && "opacity-70"}`}>
      <td className="px-2 py-3 first:pl-5 last:pr-5">{number}</td>
      <td className="px-2 py-3 first:pl-5 last:pr-5">
        {isEditing ? (
          <input
            type="text"
            className="focus:border-serial-palette-300 focus:ring-serial-palette-300 block w-full rounded-md border-gray-300  p-1 px-2 focus:ring-opacity-50"
            value={firstName || ""}
            onChange={(event) => setFirstName(event.target.value)}
            onKeyDown={handleKeyDown}
            ref={nameInputRef}
          />
        ) : (
          rowData.first_name
        )}
      </td>
      <td className="px-2 py-3 first:pl-5 last:pr-5">
        {isEditing ? (
          <input
            type="text"
            className="focus:border-serial-palette-300 focus:ring-serial-palette-300 block w-full rounded-md border-gray-300  p-1 px-2 focus:ring-opacity-50"
            value={lastName || ""}
            onChange={(event) => setLastName(event.target.value)}
            onKeyDown={handleKeyDown}
          />
        ) : (
          rowData.last_name
        )}
      </td>
      <td className="px-2 py-3 first:pl-5 last:pr-5">
        {isEditing ? (
          <input
            type="text"
            className="focus:border-serial-palette-300 focus:ring-serial-palette-300 block w-full rounded-md border-gray-300  p-1 px-2 focus:ring-opacity-50"
            value={pin || ""}
            onChange={(event) => setPin(event.target.value)}
            onKeyDown={handleKeyDown}
          />
        ) : (
          rowData.pin
        )}
      </td>
      <td className="w-16 whitespace-nowrap px-2 py-3 text-center first:pl-5 last:pr-5">
        {isEditing ? (
          <>
            <button className="btn border-serial-palette-200 hover:border-serial-palette-300" onClick={handleSave}>
              <svg className="h-3 w-3 shrink-0 fill-current text-green-500" viewBox="0 0 16 16">
                <path d="M5.5 13L1 8.5L2.5 7L5.5 10L13.5 2L15 3.5L5.5 13Z" />
              </svg>
            </button>

            {!rowData.user_id && (
              <button
                className="btn border-serial-palette-200 hover:border-serial-palette-300 ml-2"
                onClick={() => {
                  handleDelete(rowData.id);
                  setIsEditing(false);
                }}
              >
                <svg className="h-3 w-3 shrink-0 fill-current text-red-500" viewBox="0 0 16 16">
                  <path d="M9.41421 8L12.7071 4.70711L11.2929 3.29289L8 6.58579L4.70711 3.29289L3.29289 4.70711L6.58579 8L3.29289 11.2929L4.70711 12.7071L8 9.41421L11.2929 12.7071L12.7071 11.2929L9.41421 8Z" />
                </svg>
              </button>
            )}
          </>
        ) : rowData.is_active ? (
          <button className="btn border-serial-palette-200 hover:border-serial-palette-300" onClick={() => setIsEditing(true)}>
            <svg className="text-serial-palette-500 h-3 w-3 shrink-0 fill-current" viewBox="0 0 16 16">
              <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
            </svg>
          </button>
        ) : (
          <button
            className="btn border-serial-palette-200 hover:border-serial-palette-300 text-xs text-green-500"
            onClick={() => handleActivate(rowData.id)}
          >
            <FontAwesomeIcon icon={faPowerOff} />
          </button>
        )}
      </td>
    </tr>
  );
}

export default OperatorTableItem;
