import React, { useContext, useEffect, useMemo } from "react";
import DatasetAnalyticsPerformanceSnList from "./DatasetAnalyticsPerformanceSnList";
import { DatasetsContext } from "../Datasets";
import DatasetAnalyticsPerformanceLineChart from "./DatasetAnalyticsPerformanceLineChart";
import { DataType } from "@shared/types/databaseEnums";
import DatasetAnalyticsPerformanceDonutChart from "./DatasetAnalyticsPerformanceDonutChart";
import ImageCarousel from "@shared/components/ImageCarousel";
import { Image, File } from "@shared/types/databaseTypes";
import FileViewer from "@shared/components/FileViewer";
import DatasetAnalyticsPerformanceHistogram from "./DatasetAnalyticsPerformanceHistogram";

const DatasetAnalyticsPerformance: React.FC = () => {
  const context = useContext(DatasetsContext);
  const [focusedIndex, setFocusedIndex] = React.useState<number | null>(null);

  const widthClassName = useMemo(() => {
    switch (context.selectedDataset?.data_type) {
      case DataType.Image:
        return "w-1/2";
      case DataType.File:
        return "w-1/2";
      default:
        return "w-1/3";
    }
  }, [context.selectedDataset]);

  // Default focused index to be 0 for images
  useEffect(() => {
    if (
      (context.selectedDataset?.data_type === DataType.Image || context.selectedDataset?.data_type === DataType.File) &&
      focusedIndex === null &&
      context.data.length > 0
    ) {
      setFocusedIndex(0);
    }
  }, [context.selectedDataset]);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow gap-3 p-3">
      <div className={`flex h-full ${widthClassName}`}>
        <DatasetAnalyticsPerformanceSnList focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} />
      </div>
      {/* Numerical Data */}
      {context.selectedDataset?.data_type === DataType.ParametricQuantitative && (
        <div className="h-full min-w-0 flex-grow flex-col space-y-2">
          {/* Histogram */}
          <div className="flex h-[calc(50%-0.25rem)] w-full flex-col overflow-clip rounded-md border">
            <div className="text-serial-palette-800 flex h-12 shrink-0 items-center border-b px-3 font-semibold">
              <span className="truncate">{`Histogram of ${context.selectedDataset?.name}`}</span>
            </div>
            <div className="flex min-h-0 min-w-0 flex-grow">
              {context.data.length > 0 && <DatasetAnalyticsPerformanceHistogram focusedIndex={focusedIndex} />}
            </div>
          </div>

          {/* Value over Time */}
          <div className="flex h-[calc(50%-0.25rem)] w-full flex-col overflow-clip rounded-md border">
            <div className="text-serial-palette-800 flex h-12 shrink-0 items-center border-b px-3 font-semibold">
              <span className="truncate">{`${context.selectedDataset?.name} over Time`}</span>
            </div>
            <div className="flex min-h-0 min-w-0 flex-grow">
              {context.data.length > 0 && (
                <DatasetAnalyticsPerformanceLineChart focusedIndex={focusedIndex} setFocusedIndex={setFocusedIndex} />
              )}
            </div>
          </div>
        </div>
      )}

      {/* Text Data and Boolean Data */}
      {context.selectedDataset?.data_type &&
        [DataType.ParametricQualitative, DataType.Checkbox, DataType.PassFail].includes(context.selectedDataset.data_type) && (
          <div className="flex h-1/2 min-w-0 flex-grow flex-col overflow-clip rounded-md border">
            <div className="text-serial-palette-800 flex h-12 shrink-0 items-center border-b px-3 font-semibold">
              <span className="truncate">{context.selectedDataset?.name}</span>
            </div>
            <div className="flex min-h-0 min-w-0 flex-grow">{context.data.length > 0 && <DatasetAnalyticsPerformanceDonutChart />}</div>
          </div>
        )}
      {/* Image data */}
      {context.selectedDataset?.data_type === DataType.Image && focusedIndex !== null && (
        <div className="flex w-full flex-col rounded-md border ">
          <div className="text-serial-palette-800 flex h-12 shrink-0 items-center border-b px-3 font-semibold">
            Images
            <span className="text-serial-palette-800 ml-2 truncate font-light">
              {(context.data[focusedIndex] as Image)?.file_name ?? ""}
            </span>
          </div>
          <ImageCarousel
            images={context.data as Image[]}
            activeImageIndex={focusedIndex}
            setActiveImageIndex={(index: number) => setFocusedIndex(index)}
            quicklookSubtitle={(context.data[focusedIndex] as Image)?.file_name ?? ""}
          />
        </div>
      )}
      {/* File Data */}
      {context.selectedDataset?.data_type === DataType.File && focusedIndex !== null && (
        <div className="flex w-full flex-col overflow-clip rounded-md border">
          <div className="text-serial-palette-800 flex h-12 shrink-0 items-center border-b px-3 font-semibold">
            File
            <span className="text-serial-palette-800 ml-2 truncate font-light">
              {(context.data[focusedIndex] as File)?.file_name ?? ""}
            </span>
          </div>
          <FileViewer file={context.data[focusedIndex] as File} />
        </div>
      )}
    </div>
  );
};

export default DatasetAnalyticsPerformance;
