import React, { useEffect, useRef, useContext } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as faCircleRegular } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faCircleSolid } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { HardwareRevision } from "../hooks/useSnLookupHardwareRevisions";
import { SnLookupContext } from "../SnLookupProvider";
import SnLookUpComponentInstanceData from "./SnLookUpComponentInstanceSummary";

const SnLookUpAssembly: React.FC = () => {
  const { hwRevisions } = useContext(SnLookupContext);

  const [selectedHwRevision, setSelectedHwRevision] = useState<HardwareRevision | null>(null);
  const hwRevisionContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hwRevisions.length > 0) {
      setSelectedHwRevision(hwRevisions[hwRevisions.length - 1]);
    }
  }, [hwRevisions]);

  return (
    <div className="flex w-full flex-col">
      <div ref={hwRevisionContainerRef} className="bg-serial-palette-50 scrollbar-hide flex w-full overflow-x-auto border-t p-3">
        <div className="mx-auto flex w-full gap-5 px-3">
          {hwRevisions
            .slice()
            .reverse()
            .map((hwRevision, index) => {
              return (
                <div
                  className={`relative flex cursor-pointer flex-col items-center ${selectedHwRevision?.name !== hwRevision.name && "opacity-70"}`}
                  onClick={() => setSelectedHwRevision(hwRevision)}
                  key={index}
                >
                  <div
                    className={`border-serial-palette-400 absolute left-1/2 top-[7px] z-0 w-[110%] border-b ${index === hwRevisions.length - 1 && "hidden"}`}
                  />
                  <FontAwesomeIcon
                    icon={selectedHwRevision?.name === hwRevision.name ? faCircleSolid : faCircleRegular}
                    className="bg-serial-palette-50 z-10 px-1 pb-2"
                  />
                  <div className="whitespace-nowrap text-sm font-medium">{hwRevision.name}</div>
                  <div className="whitespace-nowrap text-xs">{moment(hwRevision.timestamp).format("MM/DD/YY")}</div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="divide-serial-palette-200 flex w-full divide-y rounded-b-md border-t px-4 pb-4 pt-2">
        {selectedHwRevision?.genealogyTree && (
          <SnLookUpComponentInstanceData
            genealogyTree={selectedHwRevision.genealogyTree}
            genealogyTreeDiff={{ addedIds: selectedHwRevision.addedIds, removedIds: selectedHwRevision.removedIds }}
            root={true}
          />
        )}
      </div>
    </div>
  );
};

export default SnLookUpAssembly;
