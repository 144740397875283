import { useContext, useEffect, useState, useRef } from "react";
import { DashboardInsightContext } from "../DashboardInsightCard";
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import moment from "moment";
import { fetchCycleTimeByProcessEdgeFunction } from "../../connections/supabaseCycleTime";
import { ProcessCycleTimeByDate } from "../../types";
import { ChartCycleTimeMetaData } from "../../types";

Chart.register(LineController, LinearScale, LineElement, Filler, Tooltip, PointElement, TimeScale);

type DateCycleTimeMap = {
  [date: string]: number[];
};

const CycleTimeByProcess: React.FC<void> = () => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const [chartData, setChartData] = useState<ProcessCycleTimeByDate>({});
  const context = useContext(DashboardInsightContext);
  const component_id = context.filters.componentId;
  const start_date = context.filters.dateRange?.from;
  const end_date = context.filters.dateRange?.to;

  useEffect(() => {
    const fetchData = async () => {
      if (component_id && start_date && end_date) {
        context?.setIsLoading(true);
        const cycle_time_data = await fetchCycleTimeByProcessEdgeFunction(component_id, start_date, end_date);
        setChartData(cycle_time_data);
        context?.setIsLoading(false);
      }
    };
    fetchData();
  }, [component_id, start_date, end_date]);

  useEffect(() => {
    const ctx = canvas.current?.getContext("2d");

    if (!ctx || Object.keys(chartData).length === 0) {
      return;
    }

    let allDates: string[] = [];
    let dateCycleTimesMap: DateCycleTimeMap = {};

    Object.entries(chartData).forEach(([_processName, processData]) => {
      processData.dates.forEach((date, index) => {
        dateCycleTimesMap[date] = dateCycleTimesMap[date] || [];
        dateCycleTimesMap[date].push(processData.cycle_times[index]);
      });
      allDates = allDates.concat(processData.dates);
    });

    // Define an array of colors for the datasets
    const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#4D5360", "#C9CBCF", "#7BC043", "#F7464A"];

    const datasets: ChartCycleTimeMetaData[] = Object.entries(chartData).map(([processName, processData], index) => {
      const color = colors[index % colors.length];
      const cleanedProcessName = processName.replace(/^\{+|\}+$/g, "");

      return {
        label: cleanedProcessName,
        data: processData.cycle_times,
        borderColor: color,
        backgroundColor: color,
        pointRadius: 3,
        pointBackgroundColor: color,
        borderWidth: 2,
        fill: false,
        spanGaps: true,
        total_entries: processData.total_entries,
        retest_rates: processData.retest_rates,
      };
    });

    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: allDates,
        datasets,
      },
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 50,
          },
        },
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 10,
            },
          },
          x: {
            grid: {
              display: false,
            },
            type: "time",
            time: {
              parser: "YYYY-MM-DD",
              unit: "day",
            },
            display: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                if (context.length > 0 && context[0].label) {
                  return moment(context[0].label).format("DD MMMM YYYY");
                }
                return "";
              },
              label: (context) => {
                const dataset = context.dataset as ChartCycleTimeMetaData;
                const datasetLabel = dataset.label ?? "";
                const value = context.parsed.y !== undefined ? context.parsed.y.toFixed(1) + "s" : "N/A";
                const dataIndex = context.dataIndex;
                const totalEntries =
                  dataset.total_entries && dataset.total_entries[dataIndex] !== undefined ? dataset.total_entries[dataIndex] : "N/A";
                const retestRate =
                  dataset.retest_rates && dataset.retest_rates[dataIndex] !== undefined
                    ? dataset.retest_rates[dataIndex].toFixed(1)
                    : "N/A";

                // Return an array of strings for multiline tooltips
                return [`${datasetLabel}`, `Avg Cycle Time: ${value}`, `Total Input: ${totalEntries}`, `Retest Rate: ${retestRate}%`];
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              boxWidth: 10,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
      },
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  return (
    <div className="mb-10 h-full w-full grow">
      {component_id === null && start_date === null && end_date === null ? (
        <div className="flex h-full w-full items-center justify-center text-base">
          Select a component and time range to generate a yield graph
        </div>
      ) : (
        <div className="h-[440px] px-5 py-1">
          <canvas ref={canvas}></canvas>
        </div>
      )}
    </div>
  );
};

export default CycleTimeByProcess;
