import useMeasures, { MeasuresProvider } from "@shared/measures/MeasuresProvider";
import MeasuresGraph, { useMeasuresGraph } from "@shared/measures/components/graph/MeasuresGraph";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useEffect, useMemo, useRef, useState } from "react";
import { faBinoculars, faFileImage, faRefresh, faSave } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ReportTemplateGraphBuilderView, ReportTemplateGraphBuilderViewConfig } from "@shared/types/databaseTypes";
import { useNavigate } from "react-router-dom";
import ReportTemplateEditModal from "@shared/components/modals/ReportTemplateEditModal";
import { v4 as uuidv4 } from "uuid";
import { ReportTemplateType } from "@shared/types/databaseEnums";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import MeasuresToolbar, { MeasuresToolbarDropdownOption } from "@shared/measures/components/MeasuresToolbar";
import { toPng } from "html-to-image";

function GraphBuilderGraph({ view }: { view?: ReportTemplateGraphBuilderView }) {
  const [selectedComponentId, setSelectedComponentId] = useState<string | undefined>(undefined);
  return (
    <MeasuresProvider componentId={selectedComponentId}>
      <MeasuresGraph.Provider>
        <GraphBuilderGraphWithContext
          selectedComponentId={selectedComponentId}
          setSelectedComponentId={setSelectedComponentId}
          view={view}
        />
      </MeasuresGraph.Provider>
    </MeasuresProvider>
  );
}

function GraphBuilderGraphWithContext({
  selectedComponentId,
  setSelectedComponentId,
  view,
}: {
  selectedComponentId: string | undefined;
  setSelectedComponentId: (value: string | undefined) => void;
  view?: ReportTemplateGraphBuilderView;
}) {
  const company = useSelector((state: RootState) => state.db.company);
  const components = useSelector((state: RootState) => state.db.components);
  const { componentId, handleReset, handleLoadFromReportTemplateConfig, reportTemplateConfig } = useMeasures();
  const {
    graphType,
    xAxisMeasureKey,
    yAxisMeasureKey,
    categoriesMeasureKey,
    showRetests,
    showLegend,
    showGridLines,
    relative,
    handleSetGraphType,
    setXAxisMeasureKey,
    setYAxisMeasureKey,
    setCategoriesMeasureKey,
    setShowRetests,
    setShowLegend,
    setShowGridLines,
    setRelative,
  } = useMeasuresGraph();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const graphContentRef = useRef<HTMLDivElement>(null);

  const [reportTemplateModalOpen, setReportTemplateModalOpen] = useState<boolean>(false);

  const draftSavedView: ReportTemplateGraphBuilderView | null = useMemo(() => {
    if (!currentUser || !company || !reportTemplateConfig) return null;
    const config: ReportTemplateGraphBuilderViewConfig = {
      ...reportTemplateConfig,
      graph_type: graphType,
      x_axis_measure_key: xAxisMeasureKey,
      y_axis_measure_key: yAxisMeasureKey,
      categories_measure_key: categoriesMeasureKey,
      show_retests: showRetests,
      show_legend: showLegend,
      show_grid_lines: showGridLines,
      relative: relative,
    };
    if (view) {
      return {
        ...view,
        config,
      };
    }
    return {
      id: uuidv4(),
      revision: 1,
      company_id: company.id,
      type: ReportTemplateType.GraphBuilderView,
      name: "New Graph View",
      created_at: moment().toISOString(),
      created_by: currentUser.supabase_uid,
      is_public: false,
      shared_with: {},
      is_latest_revision: true,
      config,
    };
  }, [reportTemplateConfig, view]);

  useEffect(() => {
    if (view) {
      setSelectedComponentId(view.config.component_id);
      // setting the selected component id resets the grid, therefore wait until the changes propagate before loading the view
      setTimeout(() => {
        handleLoadFromReportTemplateConfig(view.config);
        handleSetGraphType(view.config.graph_type);
        setSelectedComponentId(view.config.component_id);
        setXAxisMeasureKey(view.config.x_axis_measure_key);
        setYAxisMeasureKey(view.config.y_axis_measure_key);
        setCategoriesMeasureKey(view.config.categories_measure_key);
        setShowRetests(view.config.show_retests);
        setShowLegend(view.config.show_legend);
        setShowGridLines(view.config.show_grid_lines);
        setRelative(view.config.relative);
      }, 0);
    }
  }, [view?.id]);

  const dropdownOptions: MeasuresToolbarDropdownOption[] = useMemo(() => {
    const options: MeasuresToolbarDropdownOption[] = [
      {
        label: "Export",
        icon: faFileImage,
        callback: () => {
          handleExport();
        },
      },
      {
        label: "Views",
        icon: faBinoculars,
        callback: () => {
          navigate("/graphs/views");
        },
      },
      {
        label: "Save View",
        icon: faSave,
        callback: () => {
          setReportTemplateModalOpen(true);
        },
      },
      {
        label: "Reset",
        icon: faRefresh,
        callback: () => {
          handleReset();
          setSelectedComponentId(undefined);
          navigate("/gridbuilder");
        },
        className: "!text-red-500",
      },
    ];
    return options;
  }, [handleReset]);

  const handleExport = () => {
    if (!graphContentRef.current) return;
    const component = components.find((c) => c.id === componentId);
    const filename = `${component?.name} - Graph Builder - ${moment().format("MM/DD/YY HH:mma")}`;
    toPng(graphContentRef.current, {
      fontEmbedCSS: "",
    }).then((dataUrl) => {
      const a = document.createElement("a");
      a.setAttribute("download", filename);
      a.setAttribute("href", dataUrl);
      a.click();
    });
  };

  return (
    <div className="flex h-full w-full flex-col">
      {draftSavedView && (
        <ReportTemplateEditModal
          open={reportTemplateModalOpen}
          setOpen={setReportTemplateModalOpen}
          reportTemplate={draftSavedView}
          onSave={() => navigate(`/graphs/view/${draftSavedView.id}`)}
          title={view ? "Update View" : "Create New View"}
        />
      )}
      <MeasuresToolbar
        selectedComponentId={selectedComponentId}
        setSelectedComponentId={setSelectedComponentId}
        dropdownOptions={dropdownOptions}
      />
      <div className="flex min-h-0 min-w-0 flex-grow">
        <MeasuresGraph.Root className="border-t">
          <MeasuresGraph.Config className="border-r" />
          <MeasuresGraph.Content graphContentRef={graphContentRef} />
        </MeasuresGraph.Root>
      </div>
    </div>
  );
}

export default GraphBuilderGraph;
