import React from "react";
import { ImageElement, LabelFormatterLabelElementProps } from "../../types";
import serialIcon from "@images/serial_submark_black.png";
import { PX_PER_MM } from "../../constants";

const LabelElementImage: React.FC<LabelFormatterLabelElementProps<ImageElement>> = ({ element }) => {
  return (
    <div className="pointer-events-none select-none">
      <img src={serialIcon} width={element.width * PX_PER_MM} />
    </div>
  );
};

export default LabelElementImage;
