import { faCaretLeft, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Component, ReportTemplateGridBuilderView } from "@shared/types/databaseTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import moment from "moment";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import Table from "@shared/components/primitives/Table";
import Button from "@shared/components/primitives/Button";
import ReportTemplateEditModal from "@shared/components/modals/ReportTemplateEditModal";
import UserAvatar from "@shared/components/primitives/UserAvatar";

const GridBuilderViews = ({ views }: { views: ReportTemplateGridBuilderView[] }) => {
  const observe = useContext(ObservabilityContext);

  const components = useSelector((state: RootState) => state.db.components);
  const users = useSelector((state: RootState) => state.db.users);

  const navigate = useNavigate();

  const handleSelectView = (viewId: string) => {
    observe.track("grid-builder_load-view");
    navigate(`/gridbuilder/view/${viewId}`);
  };

  return (
    <div className="flex w-full flex-col p-8">
      <div className="flex w-full"></div>
      <Table.Root>
        <Table.Title title="Views" count={views.length}>
          <Button size="md" onClick={() => navigate("/gridbuilder")}>
            <FontAwesomeIcon icon={faCaretLeft} />
            <span className="xs:block ml-2 hidden whitespace-nowrap">Back to Grid Builder</span>
          </Button>
        </Table.Title>
        <Table.Contents>
          <Table.Header>
            <Table.Row>
              <Table.Head>View Name</Table.Head>
              <Table.Head>Description</Table.Head>
              <Table.Head>Component</Table.Head>
              <Table.Head className="text-center">Created By</Table.Head>
              <Table.Head>Last Edited At</Table.Head>
              <Table.Head className="text-center">Edit</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {views
              .sort((a, b) => new Date(b.last_edited_at ?? b.created_at).getTime() - new Date(a.last_edited_at ?? a.created_at).getTime())
              .map((view, index) => {
                return (
                  <Table.Row key={index} className="cursor-pointer hover:bg-gray-100">
                    <Table.Cell onClick={() => handleSelectView(view.id)}>{view.name}</Table.Cell>
                    <Table.Cell className="max-w-[20vw]" onClick={() => handleSelectView(view.id)}>
                      <span className="line-clamp-3">{view.description}</span>
                    </Table.Cell>
                    <Table.Cell onClick={() => handleSelectView(view.id)}>
                      <div className="text-serial-palette-500 flex items-center gap-2 font-medium">
                        <ComponentAvatar component={components.find((c) => c.id === view.config.component_id)} size="sm" />
                        <p>{components?.find((component: Component) => component.id === view.config.component_id)?.name}</p>
                      </div>
                    </Table.Cell>
                    <Table.Cell onClick={() => handleSelectView(view.id)}>
                      <div className="flex items-center justify-center">
                        <UserAvatar user={users.find((u) => u.supabase_uid === view.created_by)} size="sm" />
                      </div>
                    </Table.Cell>
                    <Table.Cell onClick={() => handleSelectView(view.id)}>
                      <div>{moment(view.last_edited_at ?? view.created_at).format("MM/DD/YY HH:mma")}</div>
                    </Table.Cell>
                    <Table.Cell className="text-center">
                      <ReportTemplateEditModal
                        reportTemplate={view}
                        trigger={
                          <Button variant="outline" symmetric id="edit-view">
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        }
                        title="Edit View"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            {views.length === 0 && (
              <Table.NoData>No saved views. Click "Save View" in the grid builder options to refer back to a particular view</Table.NoData>
            )}
          </Table.Body>
        </Table.Contents>
      </Table.Root>
    </div>
  );
};

export default GridBuilderViews;
