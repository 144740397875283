const envName = import.meta.env.VITE_APP_ENV_NAME;
const maintenance = import.meta.env.VITE_APP_MAINTENANCE_MODE;
const refreshInterval = import.meta.env.VITE_APP_REFRESH_INTERVAL;

import { useEffect, useRef, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import "@shared/i18n/config";

// Styles
import "@shared/charts/ChartjsConfig";
import "@styles/style.css";

// Pages
import ComponentsList from "./features/components-list/ComponentsList";
import Component from "./features/component/Component";
import PageNotFound from "./features/utility/PageNotFound";
import SnLookup from "./features/sn-lookup/SnLookup";
import GraphBuilder from "./features/graph-builder/GraphBuilder";
import GridBuilder from "./features/grid-builder/GridBuilder";
import Home from "./features/home/Home";
import Dashboards from "./features/dashboards/Dashboards";
import Stations from "./features/stations/Stations";
import Datasets from "./features/datasets/Datasets";
import WorkOrders from "./features/work-orders/WorkOrders";
import Production from "./features/production-app/Production";
import Debug from "./features/debug/Debug";
import Settings from "./features/settings/Settings";
import VerifyEmail from "./features/utility/VerifyEmail";
import SetupStation from "./features/utility/SetupStation";
import InsufficientPrivileges from "./features/utility/InsufficientPrivileges";
import Maintenance from "./features/utility/Maintenance";
import Reports from "./features/reports/Reports";

// Auth
import { AuthGuard } from "./features/auth/AuthGuard";
import { UserRole } from "@shared/types/databaseEnums";
import Auth from "./features/auth/Auth";

// Other
import { Loader } from "@shared/components/Loader";

// Custom Hooks
import useRealtime from "@shared/hooks/useRealtime";

// Context
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

// Redux
import store, { RootState } from "@shared/redux/store";
import db from "@shared/connections/supabaseReduxDatabase";
import { setToken, setSupabaseUid, setRole, setCompanyId, reduxAuthReset } from "@shared/redux/auth";
import { reduxDbReset } from "@shared/redux/db";

// Supabase
import { getSupabase } from "@shared/connections/supabaseAuth";
import SupabaseClient from "@supabase/supabase-js/dist/module/SupabaseClient";

// Versioning
import { version } from "./version.mjs";
import { ToastContext } from "@shared/context/ToastProvider";
import { Session } from "@supabase/supabase-js";
import FrontendPrimitives from "./features/frontend-primitives/FrontendPrimitives";
import Tracking from "./features/tracking/Tracking";

// i18n
import i18next from "@shared/i18n/config";

const exportReduxDbAndRealtime = (session: Session | null, supabase: SupabaseClient, realtime: ReturnType<typeof useRealtime>) => {
  store.dispatch(setToken(session?.access_token));
  store.dispatch(setSupabaseUid(session?.user?.id));
  store.dispatch(setRole(session?.user?.app_metadata?.serial_role));
  store.dispatch(setCompanyId(session?.user?.app_metadata?.serial_company_id));
  if (session !== null) db.refreshAllData();
  if (!realtime.isActive) {
    realtime.subscribe(supabase);
  }
};

export const App = () => {
  const { trackPageView } = useContext(ObservabilityContext);
  const { triggerConfirmation } = useContext(ToastContext);
  const users = useSelector((state: RootState) => state.db.users);
  const company = useSelector((state: RootState) => state.db.company);
  const role = useSelector((state: RootState) => state.auth.role);
  const dbVersion = useSelector((state: RootState) => state.db.versions);
  const supabaseUid = useSelector((state: RootState) => state.auth.supabase_uid);
  const realtime = useRealtime();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<Session | null>(null);
  const supabase = getSupabase();
  const isFirstSignInEvent = useRef(true);

  useEffect(() => {
    trackPageView();
  }, [location.pathname]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
      exportReduxDbAndRealtime(session, supabase, realtime);
    });

    supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      setLoading(false);
      // Supabase sends a SIGNED_IN event on actual sign in but also on every tab switch + return to the serial tab.
      // We only want to refresh the redux db and realtime on the first SIGNED_IN event.
      // Note that as of Sept 2021, this is still an open issue with Supabase. Supabase support claims that they're considering changing this behavior.
      // Use a ref flag here to avoid the javascript closure (onAuthStateChange seems like it's not designed to be called more than once on a dependency change)
      if (event === "SIGNED_IN") {
        if (!isFirstSignInEvent.current) return;
        isFirstSignInEvent.current = false;
      } else if (event === "SIGNED_OUT") {
        store.dispatch(reduxDbReset());
        store.dispatch(reduxAuthReset());
        isFirstSignInEvent.current = true;
        return;
      }
      console.log(`${event} resetting auth redux and realtime`);
      exportReduxDbAndRealtime(session, supabase, realtime);
    });
  }, []);

  // set dark mode if found in local storage
  useEffect(() => {
    const darkMode = localStorage.getItem("darkMode");
    if (darkMode === "true") {
      document.body.classList.add("dark");
    }
  }, []);

  useEffect(() => {
    const userFromDb = users.find((user) => user.supabase_uid === supabaseUid);
    const supabase = getSupabase();
    if (userFromDb && userFromDb?.role !== role) {
      supabase.auth.refreshSession();
      console.log("Session refreshed due to role mismatch");
    }
    if (userFromDb?.is_active === false) {
      supabase.auth.signOut();
      window.alert("Your account has been deactivated. Please contact your Serial admin for more information.");
      console.log("Session signed out due to user being inactive");
    }
  }, [users]);

  useEffect(() => {
    const supabase = getSupabase();
    if (company.id && company?.is_active === false) {
      supabase.auth.signOut();
      window.alert("Your company's account has been deactivated. Please contact Serial for more information.");
      console.log("Session signed out due to company being inactive");
    }
    if (company.i18n_overrides) {
      console.log(company.i18n_overrides);
      const resourceName = `${company.default_language}-CUSTOM`;
      i18next.addResourceBundle(resourceName, "translation", company.i18n_overrides, true, true);
      i18next.changeLanguage(resourceName);
    } else {
      i18next.changeLanguage(company.default_language);
    }
  }, [company]);

  useEffect(() => {
    const versionEnvName = envName === "production" ? "main" : "staging";
    const interval = setInterval(() => {
      if (!dbVersion) return;

      const versions = dbVersion.filter((version) => version.environment === versionEnvName);
      if (versions.length === 0) return;

      const latestVersion = versions.reduce((latest, current) => {
        return new Date(latest.created_at) > new Date(current.created_at) ? latest : current;
      });

      const now = new Date();
      const nowUtcTimestamp = now.getTime();
      const newVersionUtcTimestamp = new Date(latestVersion.created_at).getTime();
      const timeDiff = nowUtcTimestamp - newVersionUtcTimestamp;

      if (timeDiff < refreshInterval) return;

      const shouldRefresh = !window.location.host.includes("localhost");
      if (latestVersion.app_version !== version && shouldRefresh) {
        triggerConfirmation(
          "Serial has been updated",
          `Serial has been updated to version ${latestVersion.app_version}. Your browser may be using an old version of Serial. Please refresh your browser to get the latest version.`,
          () => window.location.reload(),
          undefined,
          "Refresh",
          undefined,
          true,
        );
      }
    }, refreshInterval);
    return () => {
      clearInterval(interval);
    };
  }, [dbVersion, envName, version]);

  if (maintenance === "true") {
    return <Maintenance />;
  }
  if (loading || (session && !role)) {
    return (
      <div className="light text-serial-palette-100 bg-serial-palette-900 flex h-screen w-screen items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!session) {
    return <Auth />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthGuard
            component={role === UserRole.Operations ? Production : Home}
            componentName={role === UserRole.Operations ? "Production" : "Homepage"}
          />
        }
      />
      <Route path="/home" element={<AuthGuard component={Home} componentName="Homepage" />} />
      {company.config.enable_dashboards && (
        <Route path="/dashboards/*" element={<AuthGuard component={Dashboards} componentName="Dashboards" />} />
      )}
      {company.config.enable_reports && <Route path="/reports/*" element={<AuthGuard component={Reports} componentName="Reports" />} />}
      <Route path="/auth/*" element={<AuthGuard component={Auth} componentName="Auth" />} />
      <Route path="/componentslist" element={<AuthGuard component={ComponentsList} componentName="ComponentsList" />} />
      <Route path="/component/*" element={<AuthGuard component={Component} componentName="Component" />} />
      <Route path="/snlookup" element={<AuthGuard component={SnLookup} componentName="SnLookup" />} />
      <Route path="/gridbuilder/*" element={<AuthGuard component={GridBuilder} componentName="GridBuilder" />} />
      <Route path="/graphs/*" element={<AuthGuard component={GraphBuilder} componentName="GraphBuilder" />} />
      <Route path="/workorder/*" element={<AuthGuard component={WorkOrders} componentName="WorkOrders" />} />
      <Route path="/stations/*" element={<AuthGuard component={Stations} componentName="Stations" />} />
      <Route path="/tracking" element={<AuthGuard component={Tracking} componentName="Tracking" />} />
      <Route path="/datasets/*" element={<AuthGuard component={Datasets} componentName="Datasets" />} />
      <Route path="/production/*" element={<AuthGuard component={Production} componentName="Production" />} />
      <Route path="/utility/404" element={<AuthGuard component={PageNotFound} componentName="Utility" />} />
      <Route path="/utility/verifyemail" element={<AuthGuard component={VerifyEmail} componentName="Utility" />} />
      <Route path="/utility/insufficientprivileges" element={<AuthGuard component={InsufficientPrivileges} componentName="Utility" />} />
      <Route path="/utility/setupstation" element={<AuthGuard component={SetupStation} componentName="Utility" />} />
      <Route path="/settings/account" element={<AuthGuard component={Settings} componentName="Settings" />} />
      <Route path="/settings/*" element={<AuthGuard component={Settings} componentName="SettingsAdmin" />} />
      <Route path="*" element={<AuthGuard component={PageNotFound} componentName="Utility" />} />
      {(envName === "development" || envName === "staging" || session?.user?.email?.endsWith("@serial.io")) && (
        <>
          <Route path="/debug" element={<AuthGuard component={Debug} componentName="Debug" />} />
          <Route path="/frontend-primitives" element={<AuthGuard component={FrontendPrimitives} componentName="FrontendPrimitives" />} />
        </>
      )}
    </Routes>
  );
};
