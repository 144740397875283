import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { PrinterStatus, PrinterType, useLabelPrinter } from "@shared/context/LabelPrinterProvider";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { cn } from "@shared/utils/tailwind";
import TagBasic from "./primitives/TagBasic";
import Button from "./primitives/Button";

interface HeaderLabelPrintersProps {
  closeDropdown: () => void;
}

enum PrinterStatusSummary {
  Ok = "OK",
  Partial = "PARTIAL",
  Disconnected = "DISCONNECTED",
}

const printerStatusConfig = {
  [PrinterStatusSummary.Ok]: {
    color: "bg-green-500",
  },
  [PrinterStatusSummary.Partial]: {
    color: "bg-yellow-500",
  },
  [PrinterStatusSummary.Disconnected]: {
    color: "bg-red-500",
  },
};

export const getLabelPrinterStatusSummary = (status: PrinterStatus): PrinterStatusSummary => {
  if (!status.driverInstalled && !status.printerConnected && !status.readyToPrint) return PrinterStatusSummary.Disconnected;
  if (status.readyToPrint && status.driverInstalled && status.printerConnected) return PrinterStatusSummary.Ok;
  return PrinterStatusSummary.Partial;
};

const HeaderLabelPrinters: React.FC<HeaderLabelPrintersProps> = ({ closeDropdown }) => {
  const [printersRefreshing, setPrintersRefreshing] = useState<PrinterType[]>([]);
  const company = useSelector((state: RootState) => state.db.company);
  const { printers } = useLabelPrinter();

  const handleRefreshPrinter = (printerType: PrinterType) => {
    setPrintersRefreshing((prevState) => [...prevState, printerType]);
    printers[printerType].getStatus();
    setTimeout(() => {
      setPrintersRefreshing((prevState) => prevState.filter((printer) => printer !== printerType));
    }, 2000);
  };

  return (
    <div className="flex h-full flex-col divide-y">
      <div className="flex h-10 w-full items-center justify-between px-3">
        <div className="text-sm font-bold">Label Printers</div>
        <div className="flex gap-2">
          <button onClick={() => closeDropdown()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
      {Object.entries(printers).map(([printerType, printerData]) => {
        if (printerType === PrinterType.Dymo && !company?.config?.enable_dymo_integration) return null;
        if (printerType === PrinterType.Zebra && !company?.config?.enable_zebra_integration) return null;
        const statusSummary = getLabelPrinterStatusSummary(printerData.status);
        return (
          <div className="flex h-full flex-col gap-2 p-3" key={printerType}>
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium">{screamingSnakeCaseToTitleCase(printerType)} Printer</span>
                <div className="relative flex h-3 w-3 items-center justify-center">
                  <span className={cn("relative inline-flex h-2 w-2 rounded-full", printerStatusConfig[statusSummary].color)} />
                  {statusSummary === PrinterStatusSummary.Ok && (
                    <span
                      className={cn(
                        "absolute inline-flex h-full w-full animate-ping rounded-full opacity-75",
                        printerStatusConfig[statusSummary].color,
                      )}
                    />
                  )}
                </div>
              </div>
              <Button
                size="xs"
                onClick={() => handleRefreshPrinter(printerType as PrinterType)}
                isLoading={printersRefreshing.includes(printerType as PrinterType)}
              >
                Refresh
              </Button>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-between">
                <span className="text-xs">Driver Installed</span>
                <TagBasic variant={printerData.status.driverInstalled ? "PASS" : "FAIL"} size="xs" />
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs">Printer Connected</span>
                <TagBasic variant={printerData.status.printerConnected ? "PASS" : "FAIL"} size="xs" />
              </div>
              <div className="flex items-center justify-between">
                <span className="text-xs">Ready to Print</span>
                <TagBasic variant={printerData.status.readyToPrint ? "PASS" : "FAIL"} size="xs" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderLabelPrinters;
