import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserAvatar from "./UserAvatar";
import { UserRolePrettyNames } from "@shared/constants/serial";
import { handleLogout } from "@shared/connections/supabaseAuth";
import { RootState } from "@shared/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronUp, faCogs, faSignOut, faUsers } from "@fortawesome/free-solid-svg-icons";
import useCurrentUser from "@shared/hooks/useCurrentUser";

interface SidebarUserProps {
  sidebarExpanded: boolean;
}

const SidebarUser: React.FC<SidebarUserProps> = ({ sidebarExpanded }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = useCurrentUser();
  const company = useSelector((state: RootState) => state.db.company);

  const dropdown = useRef(null);
  const navigate = useNavigate();

  // close on click outside
  useEffect(() => {
    const clickHandler = () => {
      if (!dropdown.current) return;
      if (!dropdownOpen) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative flex">
      {user && (
        <button
          cypress-id="sidebar-user-dropdown-button"
          className={`bg-serial-palette-700 flex items-center justify-between gap-2 rounded px-2 py-2 transition-all duration-200 ease-in-out ${sidebarExpanded ? "w-[199px]" : "w-[49px]"} shrink-0}`}
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
        >
          <UserAvatar user={user} size="sm" enableToolTip={false} />
          {sidebarExpanded && (
            <div className="flex items-center gap-1 truncate">
              <span cypress-id="sidebar-user-username" className="mx-0.5 hidden truncate text-sm font-medium text-white sm:block">
                {user.first_name} {user.last_name}
              </span>
              <FontAwesomeIcon icon={dropdownOpen ? faChevronUp : faChevronLeft} className="text-serial-palette-400 w-3 text-sm" />
            </div>
          )}
        </button>
      )}
      {user && dropdownOpen && (
        <div
          ref={dropdown}
          className="border-serial-palette-200 absolute bottom-full left-0 mb-1.5 w-[199px] origin-bottom rounded border bg-white py-1.5 shadow"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="border-serial-palette-200 mb-1 border-b px-3 pb-2 pt-0.5">
            <div className="text-serial-palette-800 font-medium">{company.name}</div>
            <div className="text-serial-palette-500 text-sm italic">{UserRolePrettyNames.find((r) => r.id === user.role)?.pretty_name}</div>
          </div>
          <div className="text-serial-palette-800 flex flex-col text-sm font-medium">
            <button
              className="hover:bg-serial-palette-100 flex items-center gap-x-2 px-2 py-1"
              onClick={() => navigate("/settings/account")}
            >
              <div className="w-5">
                <FontAwesomeIcon icon={faCogs} />
              </div>
              <span>Settings</span>
            </button>
            <button className="hover:bg-serial-palette-100 flex items-center gap-x-2 px-2 py-1" onClick={() => navigate("/settings/team")}>
              <div className="w-5">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              <span>Team</span>
            </button>
            <button
              cypress-id="sidebar-user-sign-out"
              className="hover:bg-serial-palette-100 flex items-center gap-x-2 px-2 py-1"
              onClick={() => handleLogout()}
            >
              <div className="w-5">
                <FontAwesomeIcon icon={faSignOut} />
              </div>
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarUser;
