import { useMemo } from "react";
import { DataType } from "@shared/types/databaseEnums";
import TypeTag from "@shared/components/TypeTag";
import { Dataset, ProcessWithReferences } from "@shared/types/databaseTypes";

interface UseDataTypeCountProps {
  process?: ProcessWithReferences;
  datasets?: Dataset[];
  className?: string;
}

interface DataTypeCount {
  [DataType.Uid]?: number;
  [DataType.File]?: number;
  [DataType.Image]?: number;
  [DataType.ParametricQualitative]?: number;
  [DataType.ParametricQuantitative]?: number;
  [DataType.Link]?: number;
  [DataType.Checkbox]?: number;
  [DataType.PassFail]?: number;
  [DataType.Datetime]?: number;
}

export const countDataTypes = (datasets: Dataset[]): DataTypeCount => {
  let counts: DataTypeCount = {};
  // Count all the data types based on whether the process is using the API or not
  for (const dataset of datasets) {
    if (!dataset) {
      continue;
    }
    const dataType = dataset.data_type as DataType;
    if (dataType === DataType.Uid) continue;
    if (!counts[dataType]) {
      counts[dataType] = 1;
    } else {
      // @ts-ignore will exist by definition of the if statement above
      counts[dataType] += 1;
    }
  }
  return counts;
};

const useDataTypeCount = ({ datasets, className }: UseDataTypeCountProps): { dataTypeCounts: DataTypeCount; tags: JSX.Element } => {
  if (datasets) {
    const dataTypeCounts = useMemo(() => {
      return countDataTypes(datasets);
    }, [datasets]);

    const tags = useMemo(() => {
      return (
        <div className="flex flex-wrap items-center gap-x-0.5">
          {dataTypeCounts[DataType.File] && (
            <TypeTag
              type={DataType.File}
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={dataTypeCounts[DataType.File]}
            />
          )}
          {dataTypeCounts[DataType.Image] && (
            <TypeTag
              type={DataType.Image}
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={dataTypeCounts[DataType.Image]}
            />
          )}
          {dataTypeCounts[DataType.Uid] && (
            <TypeTag
              type={DataType.Uid}
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={dataTypeCounts[DataType.Uid]}
            />
          )}
          {(dataTypeCounts[DataType.ParametricQualitative] || dataTypeCounts[DataType.ParametricQuantitative]) && (
            <TypeTag
              type="PARAMETRIC"
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={(dataTypeCounts[DataType.ParametricQualitative] ?? 0) + (dataTypeCounts[DataType.ParametricQuantitative] ?? 0)}
            />
          )}
          {dataTypeCounts[DataType.Link] && (
            <TypeTag
              type={DataType.Link}
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={dataTypeCounts[DataType.Link]}
            />
          )}
          {dataTypeCounts[DataType.PassFail] && (
            <TypeTag
              type={DataType.PassFail}
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={dataTypeCounts[DataType.PassFail]}
            />
          )}
          {dataTypeCounts[DataType.Checkbox] && (
            <TypeTag
              type={DataType.Checkbox}
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={dataTypeCounts[DataType.Checkbox]}
            />
          )}
          {dataTypeCounts[DataType.Datetime] && (
            <TypeTag
              type={DataType.Datetime}
              hideText
              className={className ?? "h-[16px] w-8 text-[8px]"}
              quantity={dataTypeCounts[DataType.Datetime]}
            />
          )}
          <TypeTag type="TIMESTAMP" hideText className={className ?? "h-[16px] w-8 text-[8px]"} />
        </div>
      );
    }, [dataTypeCounts]);

    return { dataTypeCounts, tags };
  }
  return { dataTypeCounts: {}, tags: <></> };
};

export default useDataTypeCount;
