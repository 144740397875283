import React, { useContext, useMemo } from "react";
import { DashboardContext } from "../Dashboards";
import DashboardListCard from "./DashboardsListCard";
import CustomDashboardTable from "./CustomDashboardsTable";

interface DashboardListProps {}

const DashboardList: React.FC<DashboardListProps> = ({}) => {
  const context = useContext(DashboardContext);

  const dashboardTemplates = useMemo(() => {
    return context.dashboards.filter((dashboard) => dashboard.config.is_prebuilt);
  }, [context.dashboards]);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col gap-4 overflow-auto p-8">
      <div className="flex gap-4 gap-y-3">
        {dashboardTemplates.map((dashboard, index) => (
          <DashboardListCard key={index} dashboard={dashboard} />
        ))}
      </div>
      <CustomDashboardTable />
    </div>
  );
};

export default DashboardList;
