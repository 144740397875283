import * as React from "react";
import { cn } from "@shared/utils/tailwind";

const TableRoot = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className="relative w-full rounded-md border bg-white" {...props}></div>
));
TableRoot.displayName = "TableRoot";

const TableContents = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto">
    <table ref={ref} className={cn("w-full text-sm", className)} {...props} />
  </div>
));
TableContents.displayName = "TableContents";

interface TableTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  count?: number;
}

const TableTitle = React.forwardRef<HTMLDivElement, TableTitleProps>(({ className, title, count, children, ...props }, ref) => (
  <div ref={ref} className="relative flex h-14 w-full items-center justify-between border-b pl-4 pr-2" {...props}>
    <div className="flex gap-x-1.5">
      <div ref={ref} className="text-serial-palette-800 truncate font-semibold">
        {title}
      </div>
      <div className="text-serial-palette-400 font-medium">{count}</div>
    </div>
    {children}
  </div>
));
TableTitle.displayName = "TableTitle";

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <thead
      ref={ref}
      className={cn(
        "text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 h-9 text-left text-xs font-semibold uppercase [&_tr]:border-b ",
        className,
      )}
      {...props}
    />
  ),
);
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tbody ref={ref} className={cn("[&_tr:last-child]:border-0", className)} {...props} />,
);
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tfoot ref={ref} className={cn("h-9 border-t [&>tr]:last:border-b-0", className)} {...props} />,
);
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(({ className, ...props }, ref) => (
  <tr ref={ref} className={cn("border-b", className)} {...props} />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(({ className, ...props }, ref) => (
  <th ref={ref} className={cn("px-4 [&:has([role=checkbox])]:pr-0", className)} {...props} />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(({ className, ...props }, ref) => (
  <td ref={ref} className={cn("px-4 py-3 align-middle [&:has([role=checkbox])]:pr-0", className)} {...props} />
));
TableCell.displayName = "TableCell";

const TableNoData = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, children, ...props }, ref) => (
    <tr ref={ref} className={cn("text-center", className)} {...props}>
      <td colSpan={5} className="py-4 italic">
        {children ?? "No data available"}
      </td>
    </tr>
  ),
);
TableNoData.displayName = "TableNoData";

const Table = Object.assign(TableRoot, {
  Root: TableRoot,
  Contents: TableContents,
  Title: TableTitle,
  Header: TableHeader,
  Body: TableBody,
  Footer: TableFooter,
  Row: TableRow,
  Head: TableHead,
  Cell: TableCell,
  NoData: TableNoData,
});

export default Table;
