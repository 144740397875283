import React, { useContext, useState } from "react";
import TypeTag from "@shared/components/TypeTag";
import { DatasetsContext } from "../Datasets";
import { Dataset } from "@shared/types/databaseTypes";

interface StationSelectorProps {
  dataset: Dataset;
}

const StationSelector: React.FC<StationSelectorProps> = ({ dataset }) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const context = useContext(DatasetsContext);

  return (
    <div
      className={`border-serial-palette-100 relative flex w-full flex-col gap-0.5 px-5 py-3 ${(context.selectedDataset?.id === dataset?.id || mouseOver) && "bg-serial-palette-50"}`}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {(context.selectedDataset?.id === dataset?.id || mouseOver) && (
        <div className="bg-serial-palette-500 absolute left-0 top-1/4 h-1/2 w-[6px] rounded-r" />
      )}
      <div className="flex w-full items-center justify-between gap-2">
        <div className="truncate text-left text-sm font-bold">{dataset?.name}</div>
        <TypeTag type={dataset.data_type} className={`w-24 shrink-0 text-[10px]`} />
      </div>
      <div className="flex w-full items-end justify-between">
        <div className="text-serial-palette-400 text-left text-xs">{dataset.process?.name}</div>
      </div>
    </div>
  );
};

export default StationSelector;
