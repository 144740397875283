import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { Component } from "@shared/types/databaseTypes";

function ComponentTableItem({ component }: { component: Component }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const observe = useContext(ObservabilityContext);

  return (
    <>
      <tr className={`${component.part_number_counts?.length !== 0 && "cursor-pointer"}`} onClick={() => setIsExpanded(!isExpanded)}>
        <td className="whitespace-nowrap px-2 py-2 first:pl-5 last:pr-5">
          <div className="flex items-center gap-x-2 text-left">
            <ComponentAvatar component={component} size="sm" />
            <FontAwesomeIcon
              icon={faCaretRight}
              className={`ml-2 mr-3 ${component.part_number_counts?.length === 0 ? "opacity-20" : isExpanded && "rotate-90"}`}
            />
            <Link
              className="cursor-pointer hover:underline"
              to={`/component/${component.id}`}
              onClick={() => observe.track("Navigate to Component", { "UI Source": "Component Table Item" })}
            >
              {component.name}
            </Link>
          </div>
        </td>
        <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
          <div className="w-[75px] text-center font-medium">{component.counts?.total}</div>
        </td>
        <td className="whitespace-nowrap px-2 py-3 text-right first:pl-5 last:pr-5">
          <div className="inline-block w-[75px] text-center font-medium">{component.counts?.linked}</div>
        </td>
        <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
          <div className="w-[75px] text-center">{component.counts?.unlinked}</div>
        </td>
        <td className="whitespace-nowrap px-2 py-3 text-right first:pl-5 last:pr-5">
          <div className="inline-block w-[75px] text-center font-medium">{component.counts?.wip}</div>
        </td>
        <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
          <div className="min-w-[75px] text-center font-medium">{component.counts?.complete}</div>
        </td>
        <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
          <div className="w-[75px] text-center font-medium">{component.counts?.defective}</div>
        </td>
        <td className="w-px whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
          {/* View Button */}
          <div className="m-1.5">
            <div className="cursor-pointer hover:font-medium" onClick={() => navigate("/component/" + component.id)}>
              View<span className="hidden sm:inline"> -&gt;</span>
            </div>
          </div>
        </td>
      </tr>
      {isExpanded &&
        component.part_number_counts?.map((partNumber, index) => (
          <tr key={index} className="bg-serial-palette-50 text-serial-palette-600">
            <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="ml-5 flex items-center gap-x-2 text-left">
                <div className="border-serial-palette-400 mb-[10px] h-[10px] w-[14px] border-b-2 border-l-2" />
                <span>{partNumber.pn}</span>
              </div>
            </td>
            <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="w-[75px] text-center">{partNumber.total}</div>
            </td>
            <td className="whitespace-nowrap px-2 py-3 text-right first:pl-5 last:pr-5">
              <div className="inline-block w-[75px] text-center">{partNumber.linked}</div>
            </td>
            <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="w-[75px] text-center">{partNumber.unlinked}</div>
            </td>
            <td className="whitespace-nowrap px-2 py-3 text-right first:pl-5 last:pr-5">
              <div className="inline-block w-[75px] text-center">{partNumber.wip}</div>
            </td>
            <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="min-w-[75px] text-center">{partNumber.complete}</div>
            </td>
            <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="w-[75px] text-center">{partNumber.defective}</div>
            </td>
            <td className="w-px whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
              <div className="m-1.5">{/* Nothing under view button */}</div>
            </td>
          </tr>
        ))}
    </>
  );
}

export default ComponentTableItem;
