import { useContext, useEffect, useRef, useState } from "react";
import ImageMarkupProvider, { ImageMarkupContext } from "./ImageMarkupProvider";
import { cn } from "@shared/utils/tailwind";
import ImageMarkupElementContainer from "./components/ImageMarkupElementContainer";
import ImageMarkupPalette from "./components/ImageMarkupPalette";
import Popover from "@shared/components/primitives/Popover";
import { ImageMarkupProps } from "./types";
import { ImageMarkupElement } from "@shared/types/databaseTypes";
import { v4 as uuidv4 } from "uuid";
import { createRoot } from "react-dom/client";
import { toPng } from "html-to-image";

export const generatePngWithMarkup = async (imageUrl: string, markup: ImageMarkupElement[]) => {
  const domId = uuidv4();
  const container = document.createElement("div");
  // Hide the container off-screen
  container.style.position = "absolute";
  container.style.top = "-9999px";
  document.body.appendChild(container);
  const root = createRoot(container);
  root.render(
    <div id={domId} className="flex grow">
      <ImageMarkup imageUrl={imageUrl} markup={markup} editing={false} />
    </div>,
  );

  // Wait for the markup image to render
  await new Promise((resolve) => setTimeout(resolve, 50));
  const label = document.getElementById(domId);
  if (!label) throw new Error("Could not find label element");
  const dataUrl = await toPng(label, { fontEmbedCSS: "" });

  // remove the markup image element from the DOM
  document.body.removeChild(container);
  root.unmount();

  return dataUrl;
};

const ImageMarkup: React.FC<ImageMarkupProps> = ({ imageUrl, markup, onMarkupChange, editing, onEditingChange, className, cover }) => {
  return (
    <ImageMarkupProvider
      imageUrl={imageUrl}
      markup={markup}
      onMarkupChange={onMarkupChange}
      editing={editing}
      onEditingChange={onEditingChange}
    >
      <ImageMarkupWithContext className={className} cover={cover} />
    </ImageMarkupProvider>
  );
};

function ImageMarkupWithContext({ className, cover }: { className?: string; cover?: boolean }) {
  const {
    imageUrl,
    setImageHeight,
    setImageWidth,
    imageAspectRatio,
    setImageAspectRatio,
    markup,
    editing,
    imageRef,
    setFocusedElementIndex,
  } = useContext(ImageMarkupContext);

  const [containerAspectRatio, setParentAspectRatio] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  // determine the image aspect ratio on image load
  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setImageHeight(e.currentTarget.height);
    setImageWidth(e.currentTarget.width);
    setImageAspectRatio(e.currentTarget.naturalWidth / e.currentTarget.naturalHeight);
  };

  // on resize, update the container aspect ratio an the image dimensions
  useEffect(() => {
    const handleContainerResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setParentAspectRatio(width / height);
      }
    };

    const handleImageResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setImageHeight(height);
        setImageWidth(width);
      }
    };

    const containerResizeObserver = new ResizeObserver(handleContainerResize);
    const imageResizeObserver = new ResizeObserver(handleImageResize);

    if (containerRef.current) {
      containerResizeObserver.observe(containerRef.current);
    }
    if (imageRef?.current) {
      imageResizeObserver.observe(imageRef.current);
    }

    return () => {
      if (containerRef.current) {
        containerResizeObserver.unobserve(containerRef.current);
      }
      if (imageRef?.current) {
        imageResizeObserver.unobserve(imageRef.current);
      }
    };
  }, []);

  // drop focus on mouse down if there's a click on the imageRef directly
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (imageRef?.current && imageRef.current.contains(e.target as Node)) {
        setFocusedElementIndex(null);
      }
    };
    window.addEventListener("mousedown", handleClick);
    return () => window.removeEventListener("mousedown", handleClick);
  }, [editing, setFocusedElementIndex]);

  return (
    <div ref={containerRef} className="flex h-full w-full items-center justify-center">
      <Popover.Root open={editing}>
        {cover && editing && <div className="fixed left-0 top-0 z-20 h-screen w-screen bg-black opacity-50" />}
        <Popover.Trigger asChild>
          <div
            className={cn(
              "!relative flex max-h-full max-w-full overflow-hidden rounded-md",
              containerAspectRatio < imageAspectRatio ? "w-full" : "h-full",
              editing && "shadow-2xl",
              editing && cover && "z-30",
              className,
            )}
            style={{ aspectRatio: imageAspectRatio }}
          >
            {imageUrl && (
              <img
                ref={imageRef}
                src={imageUrl}
                onLoad={handleImageLoad}
                className="select-none object-cover"
                onDragStart={(e) => e.preventDefault()}
              />
            )}
            {markup.map((element, index) => {
              return <ImageMarkupElementContainer key={index} index={index} element={element} />;
            })}
          </div>
        </Popover.Trigger>
        <Popover.Content side="top" className="w-fit" sideOffset={8} onOpenAutoFocus={(event) => event.preventDefault()}>
          <ImageMarkupPalette />
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}

export default ImageMarkup;
