import {
  Checkbox as CheckboxData,
  DatetimeData,
  File,
  Image,
  ParametricQualitative,
  ParametricQuantitative,
  ProcessEntry,
  UniqueIdentifierLink,
} from "@shared/types/databaseTypes";
import { useContext, useEffect, useState } from "react";
import { SnLookupContext } from "../../SnLookupProvider";
import {
  fetchCheckboxDataBulk,
  fetchDatetimeDataBulk,
  fetchFileDataBulk,
  fetchImageDataBulk,
  fetchLinkDataBulk,
  fetchParametricQualitativeDataBulk,
  fetchParametricQuantitativeDataBulk,
} from "@shared/connections/supabaseSnLookupTable";
import Loader from "@shared/components/primitives/Loader";
import Table from "@shared/components/primitives/Table";
import TagBasic from "@shared/components/primitives/TagBasic";
import Checkbox from "@shared/components/primitives/Checkbox";
import moment from "moment";
import { Link } from "react-router-dom";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import TagKeyValue from "@shared/components/primitives/TagKeyValue";

const sortByDatasetOrder = (
  a: ParametricQualitative | ParametricQuantitative | CheckboxData | DatetimeData | UniqueIdentifierLink,
  b: ParametricQualitative | ParametricQuantitative | CheckboxData | DatetimeData | UniqueIdentifierLink,
) => {
  if (!a.dataset || !b.dataset) return 0;
  if ((a.dataset.order === undefined || a.dataset.order === null) && (b.dataset.order === undefined || b.dataset.order === null))
    return a.dataset?.name.localeCompare(b.dataset.name); // sort alphabetically if both are undefined
  if (a.dataset.order === undefined || a.dataset.order === null) return 1;
  if (b.dataset.order === undefined || b.dataset.order === null) return -1;
  return a.dataset.order - b.dataset.order;
};

const DataAnalysisModalProcessEntriesData = ({ selectedProcessEntry }: { selectedProcessEntry?: ProcessEntry }) => {
  const { selectedProcess, allImages } = useContext(SnLookupContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkData, setLinkData] = useState<UniqueIdentifierLink[]>([]);
  const [textData, setTextData] = useState<ParametricQualitative[]>([]);
  const [checkboxData, setCheckboxData] = useState<CheckboxData[]>([]);
  const [datetimeData, setDatetimeData] = useState<DatetimeData[]>([]);
  const [numericalData, setNumericalData] = useState<ParametricQuantitative[]>([]);
  const [imageData, setImageData] = useState<Image[]>([]);
  const [fileData, setFileData] = useState<File[]>([]);

  const loadData = async () => {
    if (!selectedProcessEntry || !selectedProcess?.entries[0].unique_identifier_id) {
      setLinkData([]);
      setNumericalData([]);
      setTextData([]);
      setCheckboxData([]);
      setDatetimeData([]);
      setImageData([]);
      setFileData([]);
      return;
    }
    setIsLoading(true);
    const uniqueIdentifierIds = [selectedProcess.entries[0].unique_identifier_id];
    const processEntryIds = [selectedProcessEntry.id];
    const [linkData, numericalData, textData, checkboxData, datetimeData, imageData, fileData] = await Promise.all([
      fetchLinkDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchParametricQuantitativeDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchParametricQualitativeDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchCheckboxDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchDatetimeDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchImageDataBulk(uniqueIdentifierIds, processEntryIds),
      fetchFileDataBulk(uniqueIdentifierIds, processEntryIds),
    ]);
    setLinkData(linkData);
    setNumericalData(numericalData);
    setTextData(textData);
    setCheckboxData(checkboxData);
    setDatetimeData(datetimeData);
    setImageData(imageData);
    setFileData(fileData);
    setIsLoading(false);
  };

  const singularDatasetCount = linkData.length + numericalData.length + textData.length + checkboxData.length + datetimeData.length;

  useEffect(() => {
    loadData();
  }, [selectedProcessEntry]);

  return (
    <div className="bg-serial-palette-50 flex w-full flex-col">
      <div className="flex w-full justify-between border-b bg-white px-4 py-3">
        <div className="flex flex-wrap gap-1.5">
          <TagKeyValue.Root variant="TIMESTAMP">
            <TagKeyValue.Key>Timestamp</TagKeyValue.Key>
            <TagKeyValue.Value>{moment(selectedProcessEntry?.timestamp).format("MM/DD/YY HH:mma")}</TagKeyValue.Value>
          </TagKeyValue.Root>
          <TagKeyValue.Root variant="CYCLE_TIME">
            <TagKeyValue.Key>Cycle Time</TagKeyValue.Key>
            <TagKeyValue.Value>
              {selectedProcessEntry?.cycle_time ? `${selectedProcessEntry.cycle_time?.toFixed(2)}s` : "Not Recorded"}
            </TagKeyValue.Value>
          </TagKeyValue.Root>
          <TagKeyValue.Root variant="STATION">
            <TagKeyValue.Key>Station</TagKeyValue.Key>
            <TagKeyValue.Value>{selectedProcessEntry?.station ? selectedProcessEntry.station.name : "Not Recorded"}</TagKeyValue.Value>
          </TagKeyValue.Root>
          <TagKeyValue.Root variant="OPERATOR">
            <TagKeyValue.Key>Operator</TagKeyValue.Key>
            <TagKeyValue.Value>
              {selectedProcessEntry?.operator
                ? `${selectedProcessEntry.operator.first_name} ${selectedProcessEntry.operator.last_name}`
                : "Not Recorded"}
            </TagKeyValue.Value>
          </TagKeyValue.Root>
          <TagKeyValue.Root>
            <TagKeyValue.Key>Entry ID</TagKeyValue.Key>
            <TagKeyValue.Value>{selectedProcessEntry?.id ?? "Not Recorded"}</TagKeyValue.Value>
          </TagKeyValue.Root>
        </div>

        {isLoading && <Loader size="sm" />}
      </div>
      {!isLoading && (
        <div className="flex min-h-0 min-w-0 flex-grow flex-col gap-4 overflow-auto p-4">
          {singularDatasetCount !== 0 && (
            <Table.Root>
              <Table.Title title="Singular Datasets" count={singularDatasetCount} />
              <Table.Contents>
                <Table.Header>
                  <Table.Row>
                    <Table.Head className="w-[120px] text-center">Type</Table.Head>
                    <Table.Head>Dataset</Table.Head>
                    <Table.Head>Value</Table.Head>
                    <Table.Head className="text-center">Unit</Table.Head>
                    <Table.Head className="text-center">Result</Table.Head>
                    <Table.Head className="text-center">USL</Table.Head>
                    <Table.Head className="text-center">LSL</Table.Head>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {numericalData
                    .sort((a, b) => sortByDatasetOrder(a, b))
                    .map((data) => (
                      <Table.Row key={data.id}>
                        <Table.Cell className="flex w-[120px] justify-center">
                          <TagBasic.Root size="sm" variant={data.dataset!.data_type}>
                            <TagBasic.Icon />
                            <TagBasic.Name />
                          </TagBasic.Root>
                        </Table.Cell>
                        <Table.Cell>{data.dataset?.name}</Table.Cell>
                        <Table.Cell>{data.value.toFixed(2)}</Table.Cell>
                        <Table.Cell className="text-center">{data.unit ?? <span className="text-serial-palette-400">-</span>}</Table.Cell>
                        <Table.Cell className="flex justify-center">
                          <TagBasic size="sm" variant={data.is_pass === true ? "PASS" : data.is_pass === false ? "FAIL" : "NA"} />
                        </Table.Cell>
                        <Table.Cell className="text-center">{data.usl ?? <span className="text-serial-palette-400">-</span>}</Table.Cell>
                        <Table.Cell className="text-center">{data.lsl ?? <span className="text-serial-palette-400">-</span>}</Table.Cell>
                      </Table.Row>
                    ))}
                  {textData
                    .sort((a, b) => sortByDatasetOrder(a, b))
                    .map((data) => (
                      <Table.Row key={data.id}>
                        <Table.Cell className="flex w-[120px] justify-center">
                          <TagBasic.Root size="sm" variant={data.dataset!.data_type}>
                            <TagBasic.Icon />
                            <TagBasic.Name />
                          </TagBasic.Root>
                        </Table.Cell>
                        <Table.Cell>{data.dataset?.name}</Table.Cell>
                        <Table.Cell>{data.value}</Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="flex justify-center">
                          <TagBasic size="sm" variant={data.value === "PASS" ? "PASS" : data.value === "FAIL" ? "FAIL" : "NA"} />
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  {checkboxData
                    .sort((a, b) => sortByDatasetOrder(a, b))
                    .map((data) => (
                      <Table.Row key={data.id}>
                        <Table.Cell className="flex w-[120px] justify-center">
                          <TagBasic.Root size="sm" variant={data.dataset!.data_type}>
                            <TagBasic.Icon />
                            <TagBasic.Name />
                          </TagBasic.Root>
                        </Table.Cell>
                        <Table.Cell>{data.dataset?.name}</Table.Cell>
                        <Table.Cell>
                          <Checkbox checked={data.is_checked} />
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="flex justify-center">
                          <TagBasic size="sm" variant={data.is_pass === true ? "PASS" : data.is_pass === false ? "FAIL" : "NA"} />
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  {datetimeData
                    .sort((a, b) => sortByDatasetOrder(a, b))
                    .map((data) => (
                      <Table.Row key={data.id}>
                        <Table.Cell className="flex w-[120px] justify-center">
                          <TagBasic.Root size="sm" variant={data.dataset!.data_type}>
                            <TagBasic.Icon />
                            <TagBasic.Name />
                          </TagBasic.Root>
                        </Table.Cell>
                        <Table.Cell>{data.dataset?.name}</Table.Cell>
                        <Table.Cell>{moment(data.value).format("MM/DD/YY HH:mma")}</Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="flex justify-center">
                          <TagBasic size="sm" variant="NA" />
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  {linkData
                    .sort((a, b) => sortByDatasetOrder(a, b))
                    .map((data) => (
                      <Table.Row key={data.id}>
                        <Table.Cell className="flex w-[120px] justify-center">
                          <TagBasic.Root size="sm" variant={data.dataset!.data_type}>
                            <TagBasic.Icon />
                            <TagBasic.Name />
                          </TagBasic.Root>
                        </Table.Cell>
                        <Table.Cell>{data.dataset?.name}</Table.Cell>
                        <Table.Cell>
                          <Link className="hover:underline" to={`/sn-lookup?uid=${data.has_child_of_id}`}>
                            {data.unique_identifiers?.identifier}
                          </Link>
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="flex justify-center">
                          <TagBasic size="sm" variant="NA" />
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                        <Table.Cell className="text-center">
                          <span className="text-serial-palette-400">-</span>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table.Contents>
            </Table.Root>
          )}
          {imageData.length > 0 && (
            <div className="grid grid-cols-3 gap-4">
              {imageData.map((image, index) => (
                <div key={index} className="bg-serial-palette-100 group relative h-[30vh] overflow-hidden rounded-md border">
                  <div className="absolute inset-0">
                    <img
                      src={allImages.find((img) => img.id === image.id)?.url ?? undefined}
                      alt={`File: ${image.file_name}`}
                      className="h-full w-full"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="absolute bottom-0 flex w-full flex-col gap-1 truncate border-t bg-white px-2.5 py-1.5">
                    <div className="flex items-center gap-1.5">
                      <TagBasic size="sm" variant="IMAGE" />
                      <span className="font-bold">{image.dataset!.name}</span>
                    </div>
                    <div className="truncate font-mono text-xs">{image ? image.file_name : "-"}</div>
                  </div>
                  {image && (
                    <Button
                      size="xs"
                      className="absolute right-1.5 top-1.5 hidden group-hover:flex"
                      onClick={() =>
                        fetchDownloadUrl(image.bucket_name, image.file_id ?? image.id, image.company_id, false, true, image.file_name)
                      }
                    >
                      <span>Download</span>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  )}
                </div>
              ))}
            </div>
          )}
          {fileData.length > 0 && (
            <div className="grid grid-cols-3 gap-4">
              {fileData.map((image, index) => (
                <div key={index} className="group flex h-fit flex-col gap-1 rounded-md border bg-white px-2.5 py-1.5">
                  <div className="flex w-full justify-between">
                    <div className="flex items-center gap-1.5">
                      <TagBasic size="sm" variant="FILE" />
                      <span className="font-bold">{image.dataset!.name}</span>
                    </div>
                    <Button
                      size="xs"
                      className="hidden group-hover:flex"
                      onClick={() =>
                        fetchDownloadUrl(image.bucket_name, image.file_id ?? image.id, image.company_id, false, true, image.file_name)
                      }
                    >
                      <span>Download</span>
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </div>
                  <div className="truncate font-mono text-xs">{image ? image.file_name : "-"}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DataAnalysisModalProcessEntriesData;
