import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@shared/components/Loader";
import { ToastType } from "@shared/components/Toast";
import Banner2 from "@shared/components/Banner2";
import { ToastContext } from "@shared/context/ToastProvider";

const AuthResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [password, setPassword] = useState<string>("");
  const [reEnteredPassword, setReEnteredPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toastContext = useContext(ToastContext);

  // Password validation checks
  const isLongEnough = password.length >= 8;
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
  const passwordsMatch = password === reEnteredPassword;

  const handlePasswordReset = async () => {
    setIsLoading(true);
    const supabase = getSupabase();
    if (isLongEnough && hasLowerCase && hasUpperCase && hasSpecialCharacter && passwordsMatch) {
      const { error } = await supabase.auth.updateUser({ password });
      if (error) {
        setErrorMessage(error.message);
      } else {
        toastContext.triggerToast(ToastType.Success, "Authentication", "Password successfully changed");
        navigate("/auth/signin");
      }
    }
    setIsLoading(false);
  };

  // Trigger handlePasswordReset() on enter key press
  useEffect(() => {
    const handleEnterKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handlePasswordReset();
      }
    };
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [password, reEnteredPassword]);

  // Parse error message from URL: /auth/resetpassword#error=unauthorized_client&error_code=401&error_description=Email+link+is+invalid+or+has+expired
  const urlErrorMessage = useMemo(() => {
    const hashParams = new URLSearchParams(location.hash.slice(1)); // slice(1) removes the '#'
    const errorDescription = hashParams.get("error_description");
    if (errorDescription === "Error confirming user")
      return "Unauthorized user. Your account may have been disabled. Please contact your serial administrator.";
    return errorDescription;
  }, [location.hash]);

  return (
    <div className="flex h-full w-full flex-col items-center px-10">
      <div className="pb-2 text-2xl font-medium">
        {location.pathname === "/auth/createpassword" ? "Create Your Password" : "Change Your Password"}
      </div>
      <div className="pb-6">Enter a new password below</div>
      {urlErrorMessage && (
        <Banner2 className="w-full pb-4" open={true} hideX type="error">
          <p>{urlErrorMessage}</p>
          <a className="underline" href="/auth/forgotpassword">
            Request a new link
          </a>
        </Banner2>
      )}
      <input
        type="password"
        placeholder="New password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="form-input focus:ring-serial-palette-600 focus:border-serial-palette-600 mb-4 w-full text-lg focus:ring-1"
      />
      <input
        type="password"
        placeholder="Re-enter new password"
        value={reEnteredPassword}
        onChange={(e) => setReEnteredPassword(e.target.value)}
        className="form-input focus:ring-serial-palette-600 focus:border-serial-palette-600 mb-4 w-full text-lg focus:ring-1"
      />
      {!passwordsMatch && reEnteredPassword.length > 0 && (
        <div className="mt-1 inline-flex w-full space-x-2 pb-4 text-left">
          <FontAwesomeIcon icon={faExclamationCircle} className="pt-[2px] text-red-500" />
          <div className="text-sm text-red-500">Passwords do not match</div>
        </div>
      )}
      {errorMessage && (
        <div className="mt-1 inline-flex w-full space-x-2 pb-4 text-left">
          <FontAwesomeIcon icon={faExclamationCircle} className="pt-[2px] text-red-500" />
          <div className="text-sm text-red-500">{errorMessage}</div>
        </div>
      )}
      <div className="form-input flex w-full flex-col gap-y-1 text-sm">
        <p>Your password must contain:</p>
        <div className={`ml-3 flex items-center ${isLongEnough ? "text-green-500" : "text-red-500"}`}>
          <div className="bg-serial-palette-800 mr-2 h-1 w-1 rounded-full"></div>
          <div>At least 8 characters</div>
        </div>
        <div className={`ml-3 flex items-center ${hasLowerCase ? "text-green-500" : "text-red-500"}`}>
          <div className="bg-serial-palette-800 mr-2 h-1 w-1 rounded-full"></div>
          <div>At least one lowercase character</div>
        </div>
        <div className={`ml-3 flex items-center ${hasUpperCase ? "text-green-500" : "text-red-500"}`}>
          <div className="bg-serial-palette-800 mr-2 h-1 w-1 rounded-full"></div>
          <div>At least one uppercase character</div>
        </div>
        <div className={`ml-3 flex items-center ${hasSpecialCharacter ? "text-green-500" : "text-red-500"}`}>
          <div className="bg-serial-palette-800 mr-2 h-1 w-1 rounded-full"></div>
          <div>At least one special character</div>
        </div>
      </div>
      <button
        className="btn bg-serial-palette-900 mt-4 h-12 w-full justify-center rounded-md text-white hover:cursor-pointer"
        onClick={() => handlePasswordReset()}
      >
        {isLoading ? (
          <Loader styleOverride="w-6 h-6" />
        ) : location.pathname === "/auth/createpassword" ? (
          "Create password"
        ) : (
          "Reset Password"
        )}
      </button>
      <div className="w-full pt-4 text-center text-sm font-bold hover:cursor-pointer" onClick={() => navigate("/auth/signin")}>
        Back to Sign in
      </div>
    </div>
  );
};

export default AuthResetPassword;
