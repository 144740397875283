import { LabelFormat, LabelElement } from "@shared/types/databaseTypes";
import { LabelElementType } from "@shared/types/databaseEnums";

export interface LabelFormatWithContents extends LabelFormat {
  contents: LabelElement[];
  used: boolean;
}

export enum LabelDraggableType {
  NewElement = "NEW_ELEMENT",
  ExistingElement = "EXISTING_ELEMENT",
}

export interface LabelFormatterLabelElementProps<T> {
  element: T;
  label: LabelFormat;
  identifier: string;
}

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type LabelElementKeys = KeysOfUnion<TextElement | ImageElement | BarcodeElement | QrCodeElement | IdentifierElement>;

// TODO: Fix the repetition between LabelElementBase and LabelElement
interface LabelElementBase {
  id: string;
  type: LabelElementType;
  x: number | null; // mm (absolute position only)
  y: number | null; // mm (absolute position only)
}

export interface TextElement extends LabelElementBase {
  type: LabelElementType.Text;
  text: string;
  font_size: number;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  width: null;
  height: null;
}

export interface ImageElement extends LabelElementBase {
  type: LabelElementType.Image;
  file_id: string;
  width: number; // mm
  height: number; // mm
  text: null;
  font_size: null;
  bold: null;
  italic: null;
  underline: null;
}

export interface BarcodeElement extends LabelElementBase {
  type: LabelElementType.Barcode;
  width: number; // mm
  height: number; // mm
  text: null;
  font_size: null;
  bold: null;
  italic: null;
  underline: null;
}

export interface QrCodeElement extends LabelElementBase {
  type: LabelElementType.QRCode;
  width: number; // mm
  height: number; // mm
  text: null;
  font_size: null;
  bold: null;
  italic: null;
  underline: null;
}

export interface IdentifierElement extends LabelElementBase {
  type: LabelElementType.Identifier;
  font_size: number;
}
