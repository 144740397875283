import React from "react";
import { ListBlock } from "@shared/types/databaseTypes";
import { ProductionWorkInstructionBlockContentProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const ProductionWorkInstructionBlockList: React.FC<ProductionWorkInstructionBlockContentProps<ListBlock>> = ({ block }) => {
  return (
    <div className="flex w-full flex-col gap-y-0.5 pl-5">
      {block.content.items.map((item, index) => {
        return (
          <div className="flex gap-x-1.5" key={index}>
            {block.content.listType === "NUMBERED" && <div className="flex w-5 shrink-0 font-medium">{index + 1}.</div>}
            {block.content.listType === "BULLETED" && (
              <div className="flex w-3 shrink-0 pt-2 text-[6px]">
                <FontAwesomeIcon icon={faCircle} />
              </div>
            )}
            <div>{item}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductionWorkInstructionBlockList;
