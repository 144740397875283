import React, { useContext } from "react";
import { LabelFormatterLabelElementProps, TextElement } from "../../types";
import { PX_PER_MM } from "../../constants";
import ContentEditable from "@shared/components/ContentEditable";
import { LabelFormatterContext } from "../../LabelFormatterProvider";

function isEmptyFunction(func: Function) {
  return func.toString().replace(/\s/g, "") === "()=>{}";
}

const LabelElementText: React.FC<LabelFormatterLabelElementProps<TextElement>> = ({ element }) => {
  const { handleEditElement } = useContext(LabelFormatterContext);

  const contentRef = React.useRef<HTMLDivElement>(null);

  if (!isEmptyFunction(handleEditElement))
    return (
      <ContentEditable
        contentRef={contentRef}
        content={element.text}
        onInput={(e: React.FormEvent<HTMLDivElement>) => handleEditElement(element.id, "text", e.currentTarget.innerText)}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          // if content length is non zero, stop propagation to prevent deleting element
          if (e.key === "Backspace" && element.text.length > 0) e.stopPropagation();
        }}
        className="whitespace-nowrap outline-none"
        style={{
          fontSize: `${element.font_size * PX_PER_MM}px`,
          fontWeight: element.bold ? "bold" : "normal",
          fontStyle: element.italic ? "italic" : "normal",
          textDecoration: element.underline ? "underline" : "none",
        }}
        placeholder="Text"
      />
    );

  // if handleEditElement is not defined, we aren't in the context of the LabelFormatter so render a static div
  return (
    <div
      className="whitespace-nowrap"
      style={{
        fontSize: `${element.font_size * PX_PER_MM}px`,
        fontWeight: element.bold ? "bold" : "normal",
        fontStyle: element.italic ? "italic" : "normal",
        textDecoration: element.underline ? "underline" : "none",
      }}
    >
      {element.text}
    </div>
  );
};

export default LabelElementText;
