import { useEffect, useState } from "react";
import OperatorTableItem from "./OperatorTableItem";
import { deleteOperator, setOperatorActivity, upsertOperator } from "@shared/connections/supabaseUsers";
import { validateOperator } from "@shared/connections/supabaseUsersValidation";
import Banner2 from "@shared/components/Banner2";
import Tooltip from "@shared/components/Tooltip";
import DropdownFilter from "@shared/components/dropdowns/DropdownFilter.jsx";
import { fetchOperators } from "@shared/connections/supabaseUsers";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function OperatorTable() {
  const operatorsRedux = useSelector((state) => state.db.operators);
  const [operators, setOperators] = useState([]);
  const [errorBannerOpen, setErrorBannerOpen] = useState(false);
  const [error, setError] = useState(null);
  const [filterOptions, setFilterOptions] = useState({ Active: true, Inactive: false });

  useEffect(() => {
    setOperators(operatorsRedux);
  }, [operatorsRedux]);

  const toggleFilterOption = (option) => {
    var modifiedOptions = { ...filterOptions };
    modifiedOptions[option] = !filterOptions[option];
    setFilterOptions(modifiedOptions);
  };

  const handleRowSave = async (newRowData) => {
    const validation = await validateOperator(newRowData);
    if (validation.isValid) {
      setErrorBannerOpen(false);
      await upsertOperator(newRowData);
      fetchOperators();
      return true;
    } else {
      setError(validation);
      setErrorBannerOpen(true);
      return false;
    }
  };

  const handleDelete = async (operatorId) => {
    if (operatorId === "new") {
      setOperators(operators.filter((row) => row.id !== "new"));
    } else {
      const successfulDelete = await deleteOperator(operatorId);

      // If the component cannot be deleted, inactivate it and set the warning message
      if (successfulDelete) {
        setOperators(operators.filter((row) => row.id !== operatorId));
      } else {
        await setOperatorActivity(operatorId, false);
        setError({
          isValid: false,
          message:
            "This part number cannot be deleted because your database already has records associated to it. The part number has been deactivated instead.",
          messageType: "warning",
        });
        setErrorBannerOpen(true);
      }
      fetchOperators();
    }
  };

  const handleActivate = async (operatorId) => {
    await setOperatorActivity(operatorId, true);
    fetchOperators();
  };

  const handleNewRow = () => {
    if (!operators.find((row) => row.id === "new")) {
      setOperators([...operators, { id: "new", pn: "", description: "", is_active: true }]);
    }
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  return (
    <div className="flex w-full flex-col lg:w-2/3 xl:w-1/2">
      <div className="border-serial-palette-200  relative rounded-md border bg-white">
        <div className="flex w-full justify-between">
          <header className="flex items-center justify-between px-5 py-4">
            <h2 className="text-serial-palette-800 font-semibold">
              Operators
              <span className="text-serial-palette-400 pl-1 pr-2 font-medium">{operators.filter((pn) => pn.is_active)?.length}</span>
            </h2>
            <Tooltip
              position="right"
              size="lg"
              content={
                <div className="text-sm">
                  <div className="pb-3">Track multiple operators without having to create them accounts.</div>
                  <div className="pb-3">
                    This also allow CMs to maintain operator PINs without allowing them to create users in your organization &#x1F64C;{" "}
                  </div>
                  <a
                    href="https://docs.serial.io/features/productionTemplate#what-is-a-part-number"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    Read more
                  </a>
                </div>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
            </Tooltip>
          </header>
          <div className="mx-3 flex items-center space-x-3">
            <DropdownFilter classOverride="h-8" align="left" filterOptions={filterOptions} toggleFilterOption={toggleFilterOption} />
            <button className=" btn bg-serial-palette-800 hover:bg-serial-palette-600 h-8 text-white" onClick={() => handleNewRow()}>
              <svg className="h-4 w-4 shrink-0 fill-current" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="ml-2 hidden whitespace-nowrap sm:block">Add Operator</span>
            </button>
          </div>
        </div>

        {error && (
          <Banner2 className="px-4 pb-4" type={error.messageType} open={errorBannerOpen} setOpen={setErrorBannerOpen}>
            {error.message}
          </Banner2>
        )}

        <table className="w-full table-auto border-b">
          <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b border-t text-xs font-semibold uppercase">
            <tr className="text-left font-bold">
              <th className="w-10 px-2 py-3 text-left first:pl-5 last:pr-5">#</th>
              <th className="px-2 py-3 text-left first:pl-5 last:pr-5">First Name</th>
              <th className="px-2 py-3 text-left first:pl-5 last:pr-5">Last Name</th>
              <th className="px-2 py-3 text-left first:pl-5 last:pr-5">PIN</th>
              <th className="w-16 px-2 py-3 text-center first:pl-5 last:pr-5">Edit</th>
            </tr>
          </thead>
          <tbody className="divide-serial-palette-200 divide-y text-left text-sm">
            {(() => {
              let counter = 0;
              return operators.map((operator, index) => {
                if ((operator.is_active && filterOptions["Active"]) || (!operator.is_active && filterOptions["Inactive"])) {
                  if (operator.is_active) counter++;
                  return (
                    <OperatorTableItem
                      number={operator.is_active ? counter : "-"}
                      key={index}
                      rowData={operator}
                      onSave={handleRowSave}
                      handleDelete={handleDelete}
                      handleActivate={handleActivate}
                    />
                  );
                }
              });
            })()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OperatorTable;
