import { useContext, useRef } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { CodeBlock } from "@shared/types/databaseTypes";
import { ProcessBuilderWorkInstructionBlockContentProps } from "../../types";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import ContentEditable from "@shared/components/ContentEditable";

const ProcessBuilderWorkInstructionBlockCode: React.FC<ProcessBuilderWorkInstructionBlockContentProps<CodeBlock>> = ({
  block,
  stepIndex,
  blockIndex,
}) => {
  const { readOnly, handleUpdateWorkInstructionBlocks, handleDeleteWorkInstructionBlock } = useContext(ProcessBuilderContext);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Backspace" && contentRef.current?.innerText === "") {
      handleDeleteWorkInstructionBlock(stepIndex, blockIndex);
    }
  };

  return (
    <div className="relative flex w-full" id={block.id} onClick={() => contentRef.current?.focus()}>
      <ContentEditable
        contentRef={contentRef}
        onKeyDown={handleKeyDown}
        onInput={() => handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "text", contentRef.current?.innerText, true)}
        content={block.content.text}
        className="w-full rounded-md bg-black py-3 pl-4 pr-8 font-mono text-sm text-white outline-0"
        placeholder="code snippet here..."
        readOnly={readOnly}
      />
      {!readOnly && <CopyToClipboard text={block.content.text} className="absolute right-2 top-3 text-white" />}
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlockCode;
