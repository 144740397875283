import React, { useState, useMemo, useEffect } from "react";
import PaginationFooter from "@shared/components/PaginationFooter";
import {
  Checkbox as CheckboxType,
  Dataset,
  DatetimeData,
  Operator,
  ParametricQualitative,
  ParametricQuantitative,
  ProcessEntry,
  Station,
  UniqueIdentifierLink,
} from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import { SnLookupContext } from "../../SnLookupProvider";
import TypeTag from "@shared/components/TypeTag";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@shared/components/primitives/Checkbox";
import { fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId } from "../../connections/supabaseDataset";
import {
  ParametricQualitativeWithProcessEntry,
  ParametricQuantitativeWithProcessEntry,
  UniqueIdentifierLinkWithProcessEntry,
  CheckboxWithProcessEntry,
  DateTimeWithProcessEntry,
} from "../../types";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";

interface DataAnalysisModalDatasetsRetestTableProps {
  selectedDataset: Dataset | null;
}

interface RetestTableEntry {
  testNumber: number;
  processEntry?: ProcessEntry;
  datapoint?: ParametricQualitative | ParametricQuantitative | UniqueIdentifierLink | CheckboxType;
  resultTag?: string;
  value?: number | string | React.ReactElement;
}

const DataAnalysisModalDatasetsRetestTable: React.FC<DataAnalysisModalDatasetsRetestTableProps> = ({ selectedDataset }) => {
  const { selectedProcess, uniqueIdentifier, genealogy } = React.useContext(SnLookupContext);
  const operators = useSelector((state: RootState) => state.db.operators);
  const stations = useSelector((state: RootState) => state.db.stations);

  const [selectedDatasetRetestData, setSelectedDatasetRetestData] = React.useState<
    | ParametricQuantitativeWithProcessEntry[]
    | ParametricQualitativeWithProcessEntry[]
    | UniqueIdentifierLinkWithProcessEntry[]
    | CheckboxWithProcessEntry[]
    | DateTimeWithProcessEntry[]
    | []
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(6);
  const [totalPages, setTotalPages] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    loadProcessEntryData();
  }, [selectedDataset, uniqueIdentifier]);

  const loadProcessEntryData = async () => {
    if (!selectedDataset || !uniqueIdentifier || !selectedProcess) return [];

    let fetchedData:
      | ParametricQuantitativeWithProcessEntry[]
      | ParametricQualitativeWithProcessEntry[]
      | UniqueIdentifierLinkWithProcessEntry[]
      | CheckboxWithProcessEntry[]
      | DateTimeWithProcessEntry[] = [];
    switch (selectedDataset?.data_type) {
      case DataType.Checkbox:
        fetchedData = await fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId<CheckboxWithProcessEntry>(
          "checkboxes",
          selectedDataset.id,
          selectedProcess.entries[0].unique_identifier_id,
        );
        break;
      case DataType.ParametricQualitative:
        fetchedData = await fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId<ParametricQualitativeWithProcessEntry>(
          "parametric_qualitative",
          selectedDataset.id,
          selectedProcess.entries[0].unique_identifier_id,
        );
        break;
      case DataType.ParametricQuantitative:
        fetchedData = await fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId<ParametricQuantitativeWithProcessEntry>(
          "parametric_quantitative",
          selectedDataset.id,
          selectedProcess.entries[0].unique_identifier_id,
        );
        break;
      case DataType.PassFail:
        fetchedData = await fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId<ParametricQualitativeWithProcessEntry>(
          "parametric_qualitative",
          selectedDataset.id,
          selectedProcess.entries[0].unique_identifier_id,
        );
        break;
      case DataType.Datetime:
        fetchedData = await fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId<DateTimeWithProcessEntry>(
          "datetime_data",
          selectedDataset.id,
          selectedProcess.entries[0].unique_identifier_id,
        );
        break;
      case DataType.Link:
        fetchedData = await fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId<UniqueIdentifierLinkWithProcessEntry>(
          "unique_identifier_links",
          selectedDataset.id,
          selectedProcess.entries[0].unique_identifier_id,
        );
        break;
      default:
        break;
    }
    setSelectedDatasetRetestData(fetchedData);
  };

  const allRetests = useMemo<RetestTableEntry[]>(() => {
    if (!selectedDatasetRetestData) return [];

    if (selectedDataset?.data_type === DataType.File || selectedDataset?.data_type === DataType.Image) {
      return [];
    }

    let processEntryIndexMap =
      genealogy.find((g) => g.id === selectedProcess?.entries[0].unique_identifier_id)?.process_entry_index_map ?? {};

    const filteredEntries = Object.entries(processEntryIndexMap).filter(([_entryId, entryData]) => {
      return entryData.process_id === selectedProcess?.id;
    });
    const entries = filteredEntries.map(([entryId]) => {
      const datapoint = selectedDatasetRetestData.find((data) => data.process_entry_id === entryId);

      if (!datapoint) {
        return {
          testNumber: (processEntryIndexMap[entryId]?.index ?? 0) + 1,
          resultTag: "NA",
          datapoint: undefined,
          value: undefined,
        };
      }

      let value;
      let resultTag = "NA";

      if (selectedDataset?.data_type === DataType.ParametricQuantitative) {
        value = (datapoint as ParametricQuantitative).value;
        resultTag =
          (datapoint as ParametricQuantitative).is_pass === true
            ? "PASS"
            : (datapoint as ParametricQuantitative).is_pass === false
              ? "FAIL"
              : "NA";
      }

      if (selectedDataset?.data_type === DataType.ParametricQualitative || selectedDataset?.data_type === DataType.PassFail) {
        value = (datapoint as ParametricQualitative).value;
        resultTag =
          (datapoint as ParametricQualitative).value === "PASS"
            ? "PASS"
            : (datapoint as ParametricQualitative).value === "FAIL"
              ? "FAIL"
              : "NA";
      }

      if (selectedDataset?.data_type === DataType.Link) {
        value = (datapoint as UniqueIdentifierLink)?.child?.identifier;
      }

      if (selectedDataset?.data_type === DataType.Checkbox) {
        value = <Checkbox checked={(datapoint as CheckboxType).is_checked ?? false} className="pointer-events-none" />;
        resultTag = (datapoint as CheckboxType).is_pass === true ? "PASS" : (datapoint as CheckboxType).is_pass === false ? "FAIL" : "NA";
      }

      if (selectedDataset?.data_type === DataType.Datetime) {
        value = moment((datapoint as DatetimeData).value).format("MM/DD/YY") ?? "";
      }

      return {
        testNumber: (processEntryIndexMap[entryId]?.index ?? 0) + 1,
        resultTag: resultTag,
        datapoint: datapoint,
        value: value,
        processEntry: datapoint.process_entry,
      };
    });

    setTotalPages(Math.ceil(entries.length / rowsPerPage));
    entries.sort((a, b) => b.testNumber - a.testNumber);
    return entries;
  }, [selectedDatasetRetestData, selectedDataset, selectedProcess, rowsPerPage]);

  const filteredRetests = useMemo(() => {
    const firstIndex = (currentPage - 1) * rowsPerPage;
    const lastIndex = firstIndex + rowsPerPage;
    return allRetests?.slice(firstIndex, lastIndex);
  }, [currentPage, rowsPerPage, allRetests]);

  return (
    <div className="border-serial-palette-200 m-4 overflow-hidden rounded-md border bg-white">
      <div className="border-serial-palette-200 inline-flex h-12 w-full items-center justify-between border-b pl-3 pr-2">
        <div className="text-serial-palette-800 font-semibold">Process Entry History</div>
        {selectedDataset !== null && (
          <button className="btn-sm btn serial-btn-dark" onClick={() => navigate(`/datasets/${selectedDataset?.id}`)}>
            <FontAwesomeIcon icon={faFlask} className="mr-1.5" />
            View Dataset
          </button>
        )}
      </div>
      <table className="w-full border-b">
        <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
          <tr>
            <th className="w-20 whitespace-nowrap px-4 py-2 text-center">Entry #</th>
            <th className="w-[80px] px-4 py-2 text-center">Result</th>
            {selectedDataset !== null && (
              <th className="px-4 py-2 text-left">Value {selectedDataset && selectedDataset.unit ? `(${selectedDataset.unit})` : ""}</th>
            )}
            <th className="px-4 py-2 text-left">Timestamp</th>
            <th className="hidden px-4 py-2 text-left md:table-cell">Operator</th>
            <th className="hidden px-4 py-2 text-left xl:table-cell">Station</th>
          </tr>
        </thead>
        <tbody className="divide-serial-palette-200 divide-y text-sm">
          {filteredRetests?.map((retest, index) => {
            const operator = operators.find((op: Operator) => op.id === retest.processEntry?.operator_id);
            const station = stations.find((st: Station) => st.id === retest.processEntry?.station_id);
            return (
              <tr key={index} className={retest.datapoint === undefined && selectedDataset !== null ? "text-serial-palette-500" : ""}>
                <td className="px-4 py-2 text-center">{retest.testNumber}</td>
                <td className="flex w-[80px] items-center justify-center px-4 py-2">
                  <TypeTag type={retest?.resultTag ?? "NA"} hideText className="h-5 w-8" />
                </td>
                {selectedDataset !== null &&
                  (retest.datapoint === undefined ? (
                    <td className="px-4 py-2 text-left italic">Not Collected</td>
                  ) : selectedDataset?.data_type === DataType.ParametricQuantitative ? (
                    <td className="px-4 py-2 text-left">{typeof retest.value === "number" ? retest.value.toFixed(2) : "-"}</td>
                  ) : (
                    <td className="px-4 py-2 text-left">{retest.value}</td>
                  ))}
                <td className="px-4 py-2 text-left">
                  {retest.processEntry?.timestamp ? moment(retest.processEntry.timestamp).format("MM/DD/YY HH:mma") : "-"}
                </td>
                <td className="hidden px-4 py-2 text-left md:table-cell">
                  {operator ? `${operator.first_name} ${operator.last_name}` : "-"}
                </td>
                <td className="hidden px-4 py-2 text-left xl:table-cell">{station?.name ?? "-"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="-m-1">
        <PaginationFooter
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          availableRowsPerPage={[6, 12, 24, 32]}
          onNextPage={() => setCurrentPage((current) => Math.min(current + 1, totalPages))}
          onPrevPage={() => setCurrentPage((current) => Math.max(current - 1, 1))}
          onRowsPerPageChange={(newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage);
            setCurrentPage(1); // Reset to first page
          }}
        />
      </div>
    </div>
  );
};

export default DataAnalysisModalDatasetsRetestTable;
