import { ParsedResponse } from "@shared/types/apiTypes";
import { makeFetchRequest } from "./helpers";
import { Component } from "@shared/types/databaseTypes";

const apiUrl = import.meta.env.VITE_APP_SERIAL_API_URL;

export const createNewComponent = async (component: Component): Promise<ParsedResponse<Component>> => {
  return makeFetchRequest(
    `${apiUrl}/components`,
    {
      method: "POST",
      body: JSON.stringify({
        name: component.name.trim(),
        component_type: component.component_type,
      }),
    },
    "Could not create component",
  );
};
