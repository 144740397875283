import React, { useState, useContext, useEffect, useMemo } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { LabelField } from "@shared/types/databaseTypes";
import { ProcessBuilderFieldContentsProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Select from "@shared/components/primitives/Select";
import Banner from "@shared/components/primitives/Banner";
import { PLACEHOLDER_IDENTIFIER } from "@shared/labels/constants";
import Loader from "@shared/components/primitives/Loader";
import { PrinterType, useLabelPrinter } from "@shared/context/LabelPrinterProvider";

const ProcessBuilderFieldLabel: React.FC<ProcessBuilderFieldContentsProps<LabelField>> = ({ field, stepIndex }) => {
  const [selectedLabelId, setSelectedLabelId] = useState<string | undefined>(undefined);
  const [labelPreviewUrl, setLabelPreviewUrl] = useState<string | undefined>(undefined);
  const { handleUpdateFields, labels } = useContext(ProcessBuilderContext);

  const selectedLabel = useMemo(() => labels.find((label) => label.id === selectedLabelId), [labels, selectedLabelId]);
  const { printers } = useLabelPrinter();
  const { status, getPreviewUrl, printLabel } = printers[selectedLabel?.xml ? PrinterType.Dymo : PrinterType.Zebra];

  useEffect(() => {
    if (field.label_format_id && labels.length > 0 && !selectedLabelId) {
      setSelectedLabelId(field.label_format_id);
    } else if (!selectedLabelId && labels.length > 0) {
      const defaultLabelId = labels[0].id;
      setSelectedLabelId(defaultLabelId);
      handleUpdateFields(stepIndex, [field.id], "label_format_id", defaultLabelId);
    }
  }, [labels, selectedLabelId, field.label_format_id, handleUpdateFields, stepIndex, field.id]);

  useEffect(() => {
    if (selectedLabel) {
      getPreviewUrl(selectedLabel, PLACEHOLDER_IDENTIFIER).then((url) => setLabelPreviewUrl(url));
    }
  }, [selectedLabel?.id, status]);

  const handleSelectChange = (value: string) => {
    setSelectedLabelId(value);
    handleUpdateFields(stepIndex, [field.id], "label_format_id", value);
  };

  const handlePrint = async () => {
    if (selectedLabel) {
      printLabel(selectedLabel, PLACEHOLDER_IDENTIFIER);
    }
  };

  return (
    <div className="flex w-full flex-col items-center gap-y-2">
      <div className="relative flex w-full">
        {!field.is_optional && <div className="absolute left-1.5 top-1.5 pr-1 text-rose-500">*</div>}
        {labels.length > 0 ? (
          <Select.Root value={selectedLabelId} onValueChange={handleSelectChange}>
            <Select.Trigger className="w-full">
              <Select.Value placeholder="Select a label" />
            </Select.Trigger>
            <Select.Content>
              {labels.map((label) => (
                <Select.Item key={label.id} value={label.id}>
                  {label.name}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        ) : (
          <Banner variant="warning">No labels found. Go to the components page to create a label.</Banner>
        )}
      </div>
      {labelPreviewUrl ? (
        <img className="max-h-[250px] w-fit rounded border object-contain" src={labelPreviewUrl} alt="Label" />
      ) : (
        <div className="flex h-32 w-48 items-center justify-center">
          <Loader size="md" />
        </div>
      )}
      {selectedLabelId && (
        <button className="btn serial-btn-dark h-9 gap-2 p-3" onClick={handlePrint}>
          <div className="relative">
            <FontAwesomeIcon icon={faPrint} />
            <div className={`absolute -right-0.5 top-0 h-1 w-1 rounded-full ${status.readyToPrint ? "bg-green-500" : "bg-red-500"}`} />
            {status.readyToPrint && (
              <div className="absolute -right-1 -top-0.5 h-2 w-2 animate-pulse rounded-full bg-green-500 opacity-10" />
            )}
          </div>
          <span>Test Print</span>
        </button>
      )}
    </div>
  );
};

export default ProcessBuilderFieldLabel;
