import { useContext, useEffect, useState } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import Tooltip from "@shared/components/Tooltip";
import ProcessBuilderSettingsDependencies from "./ProcessBuilderSettingsDependencies";
import ProcessBuilderSpecSummary from "./ProcessBuilderSpecSummary";
import { checkAllowDeleteProcess, setComponentProcessLinksIsActive } from "../connections/supabase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ProcessBuilderSettings: React.FC = () => {
  const { draftProcess, componentProcessLink, handleUpdateDraftProcess, handleDeleteStep, isNewProcess, handleDelete, processBuilderOpen } =
    useContext(ProcessBuilderContext);

  const [allowDelete, setAllowDelete] = useState<boolean>(false);

  useEffect(() => {
    if (processBuilderOpen && draftProcess && !isNewProcess) {
      checkAllowDeleteProcess(draftProcess.id).then((response) => {
        setAllowDelete(response.data);
      });
    }
    setAllowDelete(false);
  }, [processBuilderOpen]);

  const handleSetApiProcess = (useApi: boolean) => {
    handleUpdateDraftProcess("use_api", useApi);
    // if switching to API, restore manual UI settings to defaults and delete all process steps
    if (useApi) {
      handleUpdateDraftProcess("allow_batch", false); // API does not use this setting but the default is false so we will restore it as such
      handleUpdateDraftProcess("break_prior_links", true); // API does not use this setting but the default is true so we will restore it as such
      for (let i = (draftProcess?.process_steps?.length ?? 0) - 1; i >= 0; i--) {
        // handleDeleteStep takes an index, so we delete in reverse order
        handleDeleteStep(i);
      }
    }
  };

  return (
    <div className="flex h-full flex-col justify-between overflow-auto">
      <div className="relative flex h-full w-full flex-col gap-y-3 pb-3">
        <div className="text-serial-palette-800 bg-white font-semibold">Process Settings</div>
        {/* Process name input */}
        <div className="flex flex-col">
          <label className="mb-2 block text-sm font-medium" htmlFor="mandatory">
            Process Name <span className="text-rose-500">*</span>
          </label>
          <input
            onChange={(e) => handleUpdateDraftProcess("name", e.target.value)}
            id="mandatory"
            className="form-input w-full"
            type="text"
            placeholder="My Process 123"
            value={draftProcess?.name ?? ""}
            required
          />
        </div>
        {/* Select UI or API */}
        {isNewProcess && (
          <div className="flex flex-col justify-self-end lg:w-full lg:flex-row lg:items-center lg:justify-between">
            <div className="mr-5 flex items-center space-x-1">
              <label className="flex text-sm font-medium">
                Process Type<span className="pl-1 text-rose-500">*</span>
              </label>
              <Tooltip
                position="right"
                size="lg"
                content={
                  <div className="text-sm">
                    <div className="whitespace-nowrap pb-3"> Select "User Interface" if your technicians will manually entering data </div>
                    <div className="pb-3"> Select "API" if your machines will automatically upload data </div>

                    <a
                      href="http://localhost:3000/features/processBuilder#user-interface-or-api"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700"
                    >
                      Read more
                    </a>
                  </div>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
              </Tooltip>
            </div>
            <div className="flex h-auto w-auto -space-x-px pt-2">
              <button
                onClick={() => handleSetApiProcess(false)}
                className={`btn border-serial-palette-200 text-serial-palette-300 whitespace-nowrap rounded-none first:rounded-l last:rounded-r ${!draftProcess?.use_api ? "  text-serial-palette-500 bg-white" : "bg-serial-palette-100 hover:text-serial-palette-500"}`}
              >
                User Interface
              </button>
              <button
                onClick={() => handleSetApiProcess(true)}
                className={`btn border-serial-palette-200 text-serial-palette-300 rounded-none first:rounded-l last:rounded-r ${draftProcess?.use_api ? "  text-serial-palette-500 bg-white" : " bg-serial-palette-100 hover:text-serial-palette-500"}`}
              >
                API
              </button>
            </div>
          </div>
        )}
        {/* Mandatory toggle switch  */}
        <div className="flex flex-col lg:w-full lg:flex-row lg:items-center lg:justify-between">
          <div className="flex items-center space-x-2">
            <label className="lg:text flex h-min text-sm font-medium">Mandatory Process</label>
            <Tooltip
              position="right"
              size="lg"
              content={
                <div className="text-sm">
                  <div className="pb-3">
                    {" "}
                    Enable to enforce that this process must be completed prior before the unit's status changes to complete.{" "}
                  </div>
                  <a href="https://docs.serial.io" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                    Read more
                  </a>
                </div>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
            </Tooltip>
          </div>
          <div className="flex flex-nowrap pt-4 lg:pt-2">
            <div className="text-serial-palette-400 mr-2 text-sm italic">{draftProcess?.is_mandatory ? "On" : "Off"}</div>
            <div className="form-switch">
              <input
                type="checkbox"
                id="process-builder-switch-mandatory"
                className="sr-only"
                checked={draftProcess?.is_mandatory ?? false}
                onChange={() => handleUpdateDraftProcess("is_mandatory", !draftProcess?.is_mandatory)}
              />
              <label className="bg-serial-palette-200" htmlFor="process-builder-switch-mandatory">
                <span className="bg-white " aria-hidden="true"></span>
                <span className="sr-only">Switch label</span>
              </label>
            </div>
          </div>
        </div>

        {/* Allow batch toggle switch  */}
        {!draftProcess?.use_api && (
          <div className="flex flex-col lg:w-full lg:flex-row lg:items-center lg:justify-between">
            <div className="flex items-center space-x-2">
              <label className="lg:text flex h-min text-sm font-medium">Batch Process</label>
              <Tooltip position="right" size="lg" content="Allows multiple units to be run through this process at once.">
                <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
              </Tooltip>
            </div>
            <div className="flex flex-nowrap pt-4 lg:pt-2">
              <div className="text-serial-palette-400 mr-2 text-sm italic">{draftProcess?.allow_batch ? "On" : "Off"}</div>
              <div className="form-switch">
                <input
                  type="checkbox"
                  id="process-builder-allow-batch-switch"
                  className="sr-only"
                  checked={draftProcess?.allow_batch ?? false}
                  onChange={() => handleUpdateDraftProcess("allow_batch", !draftProcess?.allow_batch)}
                />
                <label className="bg-serial-palette-200" htmlFor="process-builder-allow-batch-switch">
                  <span className="bg-white " aria-hidden="true"></span>
                  <span className="sr-only">Switch label</span>
                </label>
              </div>
            </div>
          </div>
        )}

        {/* Show Media Numbering switch */}
        {!draftProcess?.use_api && (
          <div className="flex flex-col lg:w-full lg:flex-row lg:items-center lg:justify-between">
            <div className="flex items-center space-x-2">
              <label className="lg:text flex h-min text-sm font-medium">Show Media Numbering</label>
              <Tooltip position="right" size="lg" content="Automatically caption media (images, files, PDFs & videos).">
                <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
              </Tooltip>
            </div>
            <div className="flex flex-nowrap pt-4 lg:pt-2">
              <div className="text-serial-palette-400 mr-2 text-sm italic">{draftProcess?.show_media_numbering ? "On" : "Off"}</div>
              <div className="form-switch">
                <input
                  type="checkbox"
                  id="process-builder-show-media-numbering-switch"
                  className="sr-only"
                  checked={draftProcess?.show_media_numbering ?? false}
                  onChange={() => handleUpdateDraftProcess("show_media_numbering", !draftProcess?.show_media_numbering)}
                />
                <label className="bg-serial-palette-200" htmlFor="process-builder-show-media-numbering-switch">
                  <span className="bg-white " aria-hidden="true"></span>
                  <span className="sr-only">Switch label</span>
                </label>
              </div>
            </div>
          </div>
        )}

        {/* Break prior links */}
        {!draftProcess?.use_api &&
          draftProcess?.process_steps?.some((step) => {
            return step.fields.some((field) => field.type === "LINK");
          }) && (
            <div className="flex flex-col lg:w-full lg:flex-row lg:items-center lg:justify-between">
              <div className="flex items-center space-x-2">
                <label className="lg:text flex h-min text-sm font-medium">Break Prior Links</label>
                <Tooltip
                  position="right"
                  size="lg"
                  content={
                    <div className="text-sm">
                      <div className="pb-2">
                        {" "}
                        Enabling this option will cause links created in prior runs of this process to be removed. <br />
                        <br /> For example: if this station logged the assembly of a single wheel on a car, breaking prior links would mean
                        that each prior wheel would be unlinked after a new one is installed. In this case break prior links should be
                        turned off.
                      </div>
                      <a
                        href="https://docs.serial.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Read more
                      </a>
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
                </Tooltip>
              </div>
              <div className="flex flex-nowrap pt-2">
                <div className="text-serial-palette-400 mr-2 text-sm italic">{draftProcess?.break_prior_links ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="process-builder-switch-break-prior-links"
                    className="sr-only"
                    checked={draftProcess?.break_prior_links ?? false}
                    onChange={() => handleUpdateDraftProcess("break_prior_links", !draftProcess?.break_prior_links)}
                  />
                  <label className="bg-serial-palette-200" htmlFor="process-builder-switch-break-prior-links">
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </div>
          )}

        {/* Dependencies */}
        <ProcessBuilderSettingsDependencies />

        {/* Disable / Enable Button */}
        {!isNewProcess && componentProcessLink && (
          <div className="flex flex-col justify-self-end lg:w-full lg:flex-row lg:items-center lg:justify-between">
            <div className="mr-5 flex items-center gap-x-2 text-sm">
              <div className="font-medium ">{componentProcessLink?.is_active ? "Disable Process" : "Enable Process"}</div>
              <Tooltip position="right" size="lg" content="Disable a process to remove it from the sequence on the production app">
                <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
              </Tooltip>
            </div>
            <div className="flex h-auto w-auto -space-x-px">
              <button
                onClick={() =>
                  setComponentProcessLinksIsActive(
                    componentProcessLink.process_id,
                    componentProcessLink.component_id,
                    !componentProcessLink.is_active,
                  )
                }
                className={`${componentProcessLink?.is_active ? "serial-btn-warning" : "serial-btn-success"} btn-xs w-16 disabled:cursor-not-allowed disabled:opacity-50`}
              >
                {componentProcessLink?.is_active ? "Disable" : "Enable"}
              </button>
            </div>
          </div>
        )}

        {/* Delete Button */}
        {!isNewProcess && (
          <div className="flex flex-col justify-self-end lg:w-full lg:flex-row lg:items-center lg:justify-between">
            <div className="mr-5 flex items-center gap-x-2 text-sm">
              <div className="font-medium ">Delete Process</div>
              <Tooltip
                position="right"
                size="lg"
                content="A process can only be deleted if it has not yet been run against a serial number in the production app."
              >
                <FontAwesomeIcon icon={faInfoCircle} className="text-serial-palette-400 text-[1rem]" />
              </Tooltip>
            </div>
            <div className="flex h-auto w-auto -space-x-px">
              <button
                disabled={!allowDelete}
                onClick={() => handleDelete()}
                className="btn-xs serial-btn-danger w-16 disabled:cursor-not-allowed disabled:opacity-50"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
      <ProcessBuilderSpecSummary />
    </div>
  );
};

export default ProcessBuilderSettings;
