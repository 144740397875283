import * as tus from "tus-js-client";

const SUPABASE_URL = import.meta.env.VITE_APP_SUPABASE_URL;

export const uploadFile = async (
  token: string,
  bucketName: string,
  path: string,
  file: Blob,
  onError?: (error: tus.DetailedError | Error) => void,
  onSuccess?: () => void,
  onProgress?: (bytesUploaded: number, bytesTotal: number) => void,
) => {
  return new Promise(() => {
    const upload = new tus.Upload(file, {
      endpoint: `${SUPABASE_URL}/storage/v1/upload/resumable`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      headers: {
        authorization: `Bearer ${token}`,
        "x-upsert": "false",
      },
      uploadDataDuringCreation: true,
      removeFingerprintOnSuccess: true, // NOTE: per supabase, this is important if you want to allow re-uploading the same file
      metadata: {
        bucketName: bucketName,
        objectName: path,
        contentType: file.type,
        cacheControl: "3600",
      },
      chunkSize: 6 * 1024 * 1024, // NOTE: per supabase, it must be set to 6MB (for now) do not change it
      onError,
      onProgress,
      onSuccess,
    });

    // Check if there are any previous uploads to continue.
    return upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }
      upload.start();
    });
  });
};
