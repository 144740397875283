import React, { useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";

import NotFoundImage from "@images/404-illustration.svg";

function PageNotFound() {
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header />

        <main>
          <div className="w-full px-8 py-8">
            <div className="m-auto mt-16 max-w-2xl">
              <div className="px-4 text-center">
                <div className="inline-flex">
                  <img src={NotFoundImage} width="400" height="300" alt="404 illustration" />
                </div>
                <div className="mb-6">Hmm...this page doesn’t exist. Try searching for something else!</div>
                <Link to="/" className="btn bg-serial-palette-800 hover:bg-serial-palette-600 text-white">
                  Back To Dashboard
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PageNotFound;
