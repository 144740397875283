import useMeasures from "@shared/measures/MeasuresProvider";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useMemo } from "react";
import Popover from "@shared/components/primitives/Popover";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faFilter } from "@fortawesome/free-solid-svg-icons";
import MeasuresFilterBuilder from "@shared/measures/components/filters/MeasuresFilterBuilder";
import Combobox from "@shared/components/primitives/Combobox";
import DateRangePicker from "@shared/components/DateRangePicker";
import DropdownMenu from "@shared/components/primitives/DropdownMenu";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface MeasuresToolbarDropdownOption {
  label: string;
  icon: IconProp;
  callback: () => void;
  className?: string;
}

const MeasuresToolbar = ({
  selectedComponentId,
  setSelectedComponentId,
  dropdownOptions,
}: {
  selectedComponentId: string | undefined;
  setSelectedComponentId: (value: string | undefined) => void;
  dropdownOptions?: MeasuresToolbarDropdownOption[];
}) => {
  const components = useSelector((state: RootState) => state.db.components);
  const { numFullyDefinedFilters, lastUpdatedDateRangeFilter, setLastUpdatedDateRangeFilter } = useMeasures();

  const selectedComponent = useMemo(() => {
    return components.find((component) => component.id === selectedComponentId);
  }, [components, selectedComponentId]);

  return (
    <div className="flex w-full items-center justify-between gap-x-2 p-2">
      <div className="flex items-center gap-x-2">
        <Combobox.Root value={selectedComponentId ?? null} onValueChange={(value) => setSelectedComponentId(value ?? undefined)}>
          <Combobox.Trigger className="w-[280px]">{selectedComponent?.name ?? "Select Component..."}</Combobox.Trigger>
          <Combobox.Content className="w-[280px]">
            {[...components]
              .filter((component) => component.is_active)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((component, index) => (
                <Combobox.Item key={index} value={component.id} keywords={[component.name]}>
                  {component.name}
                </Combobox.Item>
              ))}
          </Combobox.Content>
        </Combobox.Root>
        <DateRangePicker dateRange={lastUpdatedDateRangeFilter} onDateRangeChange={setLastUpdatedDateRangeFilter} clearButton />
        <Popover.Root>
          <Popover.Trigger asChild>
            <Button variant={numFullyDefinedFilters > 0 ? "default" : "outline"}>
              <FontAwesomeIcon icon={faFilter} />
              <span>Filters</span>
              {numFullyDefinedFilters > 0 && <span>{numFullyDefinedFilters}</span>}
            </Button>
          </Popover.Trigger>
          <Popover.Content align="start" className="w-[500px] overflow-hidden rounded-md border shadow-lg">
            <MeasuresFilterBuilder />
          </Popover.Content>
        </Popover.Root>
      </div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Button className="w-8" variant="outline">
            <FontAwesomeIcon icon={faEllipsis} size="lg" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="w-36" align="end">
          {dropdownOptions?.map((option, index) => {
            return (
              <DropdownMenu.Item key={index} className={option.className} onClick={option.callback}>
                <div className="flex w-4 justify-center">
                  <FontAwesomeIcon icon={option.icon} />
                </div>
                <span>{option.label}</span>
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};

export default MeasuresToolbar;
