import { FieldType, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import { faFile, faFilePdf, faImage, faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsLeftRight,
  faCheckSquare,
  faCode,
  faExclamationTriangle,
  faHeading,
  faLink,
  faListOl,
  faParagraph,
  faRuler,
  faTable,
  faWindowMinimize,
  faClock,
  faSignature,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

export const MAX_WORK_INSTRUCTION_MENTIONS = 5;

export const AI_VIDEO_TO_WI_SCREENSHOT_WIDTH = 512; // Width of the screenshot in pixels. These screenshots sent to openai and also potentially uploaded to Serial for the work instructions
export const AI_VIDEO_TO_WI_SCREENSHOT_INTERVAL = 5; // Interval in seconds between screenshots

export const workInstructionBlockOptions = [
  {
    type: WorkInstructionBlockType.Heading,
    label: "Heading",
    icon: faHeading,
  },
  {
    type: WorkInstructionBlockType.Text,
    label: "Text",
    icon: faParagraph,
  },
  {
    type: WorkInstructionBlockType.List,
    label: "List",
    icon: faListOl,
  },
  {
    type: WorkInstructionBlockType.Callout,
    label: "Callout",
    icon: faExclamationTriangle,
  },
  {
    type: WorkInstructionBlockType.Code,
    label: "Code",
    icon: faCode,
  },
  {
    type: WorkInstructionBlockType.Delimiter,
    label: "Delimiter",
    icon: faWindowMinimize,
  },
  {
    type: WorkInstructionBlockType.Image,
    label: "Image",
    icon: faImage,
  },
  {
    type: WorkInstructionBlockType.Video,
    label: "Video",
    icon: faPlayCircle,
  },
  {
    type: WorkInstructionBlockType.PDF,
    label: "Embedded PDF",
    icon: faFilePdf,
  },
  {
    type: WorkInstructionBlockType.File,
    label: "Reference File",
    icon: faFile,
  },
  {
    type: WorkInstructionBlockType.Table,
    label: "Table",
    icon: faTable,
  },
];

export const allowedFileExtensionsByBlockType = {
  [WorkInstructionBlockType.Image]: ["jpg", "jpeg", "png", "gif", "webp"],
  [WorkInstructionBlockType.Video]: ["mp4", "webm"],
  [WorkInstructionBlockType.PDF]: ["pdf"],
  [WorkInstructionBlockType.File]: ["*"],
};

export const workInstructionBlockAliases = {
  [WorkInstructionBlockType.Heading]: ["heading", "title", "h1", "h2", "h3"],
  [WorkInstructionBlockType.Text]: ["text", "paragraph", "p"],
  [WorkInstructionBlockType.List]: ["list", "ul", "ol", "bullets", "numbers"],
  [WorkInstructionBlockType.Callout]: ["callout", "warning", "danger", "note"],
  [WorkInstructionBlockType.Code]: ["code", "pre", "codeblock"],
  [WorkInstructionBlockType.Delimiter]: ["delimiter", "divider", "line"],
  [WorkInstructionBlockType.Image]: ["image", "img", "picture"],
  [WorkInstructionBlockType.Video]: ["video", "movie", "film"],
  [WorkInstructionBlockType.PDF]: ["pdf", "document", "embedded"],
  [WorkInstructionBlockType.File]: ["file", "reference", "attachment", "document", "download", "embedded"],
  [WorkInstructionBlockType.Table]: ["table"],
};

export const fieldOptions = [
  {
    type: FieldType.Link,
    label: "Link",
    icon: faLink,
  },
  {
    type: FieldType.ManualEntry,
    label: "Manual Entry",
    icon: faRuler,
  },
  {
    type: FieldType.Checkbox,
    label: "Checkbox",
    icon: faCheckSquare,
  },
  {
    type: FieldType.PassFail,
    label: "Pass/Fail Button",
    icon: faArrowsLeftRight,
  },
  {
    type: FieldType.Image,
    label: "Image",
    icon: faImage,
  },
  {
    type: FieldType.File,
    label: "File",
    icon: faFile,
  },
  {
    type: FieldType.Datetime,
    label: "Datetime",
    icon: faClock,
  },
  {
    type: FieldType.Signature,
    label: "Signature",
    icon: faSignature,
  },
  {
    type: FieldType.Label,
    label: "Label",
    icon: faPrint,
  },
];

export const changeLogPropertiesToDiff: { [tableName: string]: string[] } = {
  processes: ["name", "use_api", "is_mandatory", "break_prior_links", "dependent_process_ids", "type", "allow_batch"],
  process_steps: ["name", "order", "filter_join_operator"],
  work_instruction_blocks: ["order", "content"],
  fields: ["is_optional", "group_name", "prompt", "order", "method", "data_validation"],
  datasets: ["unit", "lsl", "usl", "expected_value"],
  filter_conditions: ["name", "operator", "value"],
};

export const changeLogTablePrettyNames: { [tableName: string]: string } = {
  processes: "process",
  process_steps: "process step",
  work_instruction_blocks: "work instruction block",
  fields: "field",
  datasets: "dataset",
  filter_conditions: "filter condition",
};
