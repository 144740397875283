import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import Banner2 from "./Banner2";
import useDarkMode from "@shared/hooks/useDarkMode";

interface CSVViewerProps {
  csvURL: string;
}

type RowData = Record<string, string | number>;

const CSVViewer: React.FC<CSVViewerProps> = ({ csvURL }) => {
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [maxRowsReached, setMaxRowsReached] = useState<boolean>(false);

  const { darkMode } = useDarkMode();

  useEffect(() => {
    fetchCSVData();
  }, [csvURL]);

  const fetchCSVData = async () => {
    try {
      const response = await fetch(csvURL);
      const data = await response.text();
      const rows = data.split("\n");

      if (rows.length > 500) {
        setMaxRowsReached(true);
      } else {
        setMaxRowsReached(false);
      }

      const parsedData = parseCSV(data);
      setRowData(parsedData);
    } catch (error) {
      console.error("Error fetching CSV:", error);
    }
  };

  const parseCSV = (data: string): RowData[] => {
    const rows = data.split("\n").slice(0, 500); // Limit to 500 rows
    const headers = rows[0].split(",");
    const records = rows.slice(1).map((row) => {
      const values = row.split(",");
      return headers.reduce((obj, header, index) => {
        obj[header] = values[index];
        return obj;
      }, {} as RowData);
    });
    return records;
  };

  // Generate column definitions from data
  const columnDefs =
    rowData.length > 0
      ? Object.keys(rowData[0]).map((key) => {
          return { headerName: key, field: key };
        })
      : [];

  return (
    <div
      className={`flex-col space-y-5 ${darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}`}
      style={{ height: maxRowsReached ? "92%" : "100%", width: "100%" }}
    >
      <Banner2 type="warning" children="Limit of 500 rows reached" open={maxRowsReached} hideX={true} />
      <AgGridReact rowData={rowData} columnDefs={columnDefs} />
    </div>
  );
};

export default CSVViewer;
