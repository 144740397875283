import React, { useContext, useRef } from "react";
import { LabelFormatterContext } from "../LabelFormatterProvider";
import { LabelDraggableType, LabelFormatWithContents } from "../types";
import { LabelElementType } from "@shared/types/databaseEnums";
import { PLACEHOLDER_IDENTIFIER, PX_PER_MM } from "../constants";
import { useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import { generateBlankElement } from "../helpers/helpers";
import LabelFormatterLabelElement from "./LabelFormatterLabelElement";
import Loader from "@shared/components/primitives/Loader";

interface LabelFormatterLabelProps {
  label: LabelFormatWithContents;
  previewImageUrl?: string;
}

const LabelFormatterLabel: React.FC<LabelFormatterLabelProps> = ({ label, previewImageUrl }) => {
  const { zoom, handleAddAbsoluteElement } = useContext(LabelFormatterContext);

  const dropRef = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<{ type: LabelElementType }, void, { handlerId: Identifier | null }>({
    accept: LabelDraggableType.NewElement,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(dragElement: { type: LabelElementType }, monitor) {
      const absoluteElement = generateBlankElement(dragElement.type);
      const deltaX = (monitor.getClientOffset()?.x ?? 0) - (dropRef.current?.getBoundingClientRect().left ?? 0);
      const deltaY = (monitor.getClientOffset()?.y ?? 0) - (dropRef.current?.getBoundingClientRect().top ?? 0);
      absoluteElement.x = deltaX / PX_PER_MM;
      absoluteElement.y = deltaY / PX_PER_MM;
      handleAddAbsoluteElement(absoluteElement);
    },
  });

  drop(dropRef);

  return (
    <div
      ref={dropRef}
      data-handler-id={handlerId}
      className="relative flex shrink-0 overflow-hidden rounded-lg border bg-white shadow-md"
      style={{
        width: `${label.width * PX_PER_MM}px`,
        height: `${label.height * PX_PER_MM}px`,
        transform: zoom === 1 ? "unset" : `scale(${zoom})`, // we don't set a transform if we don't have to because it causes the element settings to fail to appear
      }}
    >
      {label.xml && (
        <div className="absolute left-0 top-0 h-full w-full">
          {previewImageUrl ? (
            <img className="h-full w-full" src={previewImageUrl} alt="dymo label preview" />
          ) : (
            <div className="flex h-full w-full items-center justify-center">
              <Loader size="md" />
            </div>
          )}
        </div>
      )}
      {!label.xml &&
        label.contents.map((element, index) => {
          return <LabelFormatterLabelElement key={index} element={element} label={label} identifier={PLACEHOLDER_IDENTIFIER} />;
        })}
    </div>
  );
};

export default LabelFormatterLabel;
