import store from "@shared/redux/store";

// ------------------- VALIDATION FUNCTIONS ------------------- //

export const validateNewComponent = async (component) => {
  const state = store.getState();
  const existingComponents = state.db.components; // assume components have been fetched already

  // --- CHECK 1 --- : Check if the component name is not empty
  if (component.name === "" || component.name == null || component.name == undefined) {
    return { isValid: false, type: "error", message: "The component name was not defined" };
  }

  // --- CHECK 1 --- : Check if the component name already exists
  if (existingComponents?.some((existingComponent) => existingComponent.name.toLowerCase() === component.name.toLowerCase())) {
    return { isValid: false, type: "error", message: "The component name was already taken by another component" };
  }

  return { isValid: true, type: "success", message: null };
};
