import React, { createContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ReportTemplate, Component, WorkOrder, UniqueIdentifier } from "@shared/types/databaseTypes";
import { ReportTemplateType, WorkOrderStatus } from "@shared/types/databaseEnums";
import { fetchUniqueIdentifiers } from "./connections/supabase";

interface ReportsContextProps {
  selectedReport: ReportTemplate | null;
  setSelectedReport: (report: ReportTemplate | null) => void;
  selectedWorkOrder: WorkOrder | null;
  setSelectedWorkOrder: (workOrder: WorkOrder | null) => void;
  component: Component | null;
  componentWorkOrders: WorkOrder[];
  reports: ReportTemplate[];
  uniqueIdentifiersToExport: UniqueIdentifier[];
}

const defaultContext: ReportsContextProps = {
  selectedReport: null,
  setSelectedReport: () => {},
  selectedWorkOrder: null,
  setSelectedWorkOrder: () => {},
  component: null,
  componentWorkOrders: [],
  reports: [],
  uniqueIdentifiersToExport: [],
};

export const ReportsContext = createContext<ReportsContextProps>(defaultContext);

const ReportsProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const navigate = useNavigate();

  const components = useSelector((state: RootState) => state.db.components);
  const reports = useSelector((state: RootState) => state.db.reportTemplates).filter(
    (report) => report.type === ReportTemplateType.Datasheet,
  );
  const workOrders = useSelector((state: RootState) => state.db.workOrders);

  const [selectedReport, setSelectedReport] = useState<ReportTemplate | null>(null);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(null);
  const [uniqueIdentifiersToExport, setuniqueIdentifiersToExport] = useState<UniqueIdentifier[]>([]);

  const component = useMemo(() => {
    if (selectedReport && selectedReport.config && "component_id" in selectedReport.config) {
      return components.find((c) => c.id === selectedReport.config.component_id) || null;
    } else {
      return null;
    }
  }, [selectedReport, components]);

  const componentWorkOrders = useMemo(() => {
    if (component) {
      return workOrders.filter(
        (wo) => wo.component_id === component.id && (wo.status === WorkOrderStatus.Complete || wo.status === WorkOrderStatus.Production),
      );
    } else {
      return [];
    }
  }, [workOrders, component]);

  useEffect(() => {
    const fetchIds = async () => {
      if (component && selectedReport) {
        console.log("selectedWorkOrder", selectedWorkOrder);
        const workOrderId = selectedWorkOrder !== null ? selectedWorkOrder.id : null;
        const uniqueIdentifiers = await fetchUniqueIdentifiers(component.id, workOrderId);
        setuniqueIdentifiersToExport(uniqueIdentifiers);
      }
    };

    fetchIds();
  }, [component, selectedReport, selectedWorkOrder]);

  useEffect(() => {
    if (reports.length > 0 && !selectedReport) {
      setSelectedReport(reports[0]);
      navigate(`/reports/${reports[0].name}`);
    }
  }, [reports]);

  useEffect(() => {
    if (reports.length > 0) {
      setSelectedReport(reports[0]);
    }
  }, [selectedReport]);

  return (
    <ReportsContext.Provider
      value={{
        selectedReport,
        setSelectedReport,
        selectedWorkOrder,
        setSelectedWorkOrder,
        reports,
        component,
        componentWorkOrders,
        uniqueIdentifiersToExport,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsProvider;
