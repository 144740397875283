import React, { useContext, useEffect } from "react";
import ModalBasic from "@shared/components/modals/ModalBasic";
import { ProductionContext } from "../ProductionProvider";
import UserAvatar from "@shared/components/UserAvatar";
import { UserRolePrettyNames } from "@shared/constants/serial";
import { handleLogout } from "@shared/connections/supabaseAuth";

interface ProductionModalOperatorProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductionModalOperator: React.FC<ProductionModalOperatorProps> = ({ modalOpen, setModalOpen }) => {
  const { db, user, operator, handleLoadOperatorByPin } = useContext(ProductionContext);
  const [pinText, setPinText] = React.useState("");

  // reset pin text when modal is closed
  useEffect(() => {
    if (!modalOpen) setPinText("");
  }, [modalOpen]);

  // set pin when enter key is pressed
  useEffect(() => {
    const handleEnterKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleLoadOperatorByPin(pinText);
      }
    };
    if (modalOpen) document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [pinText, modalOpen]);

  if (db.company?.config?.use_operator_pins)
    return (
      <ModalBasic
        id="production-app-set-operator-modal"
        title="Set Operator"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        classOverride="flex flex-col w-96 pb-5"
        keepOpen={operator === null}
      >
        <div className="my-16 flex flex-col">
          {operator && (
            <div className="flex w-full justify-center text-2xl font-semibold">
              {operator?.first_name} {operator?.last_name}
            </div>
          )}
          <div className="text-md text-serial-palette-500 flex justify-center pb-6 italic">
            {!operator ? "Enter PIN..." : "Current Operator"}
          </div>
          <div className="flex w-full items-center justify-center gap-3 px-5">
            <input
              type="text"
              value={pinText}
              className="h-9 w-[150px] rounded-md border-gray-300 p-1 px-2  placeholder:italic focus:border-gray-300 focus:ring-gray-400"
              onChange={(event) => setPinText(event.target.value)}
              placeholder="PIN"
            />
            <button
              className="btn bg-serial-palette-800 hover:bg-serial-palette-600 w-min whitespace-nowrap text-white"
              onClick={() => handleLoadOperatorByPin(pinText)}
            >
              Change Operator
            </button>
          </div>
        </div>
        <div className="text-serial-palette-800 border-t px-5 py-3 font-semibold">Serial Account</div>
        <div className="flex justify-between px-5">
          {user && (
            <div className="flex">
              {user && <UserAvatar user={user} size="lg" />}
              <div className="flex w-full flex-col px-5">
                <div className="text-md flex font-medium">
                  {user.first_name} {user.last_name}
                </div>
                <div className="text-serial-palette-500 flex text-sm italic">
                  {UserRolePrettyNames.find((userRole) => userRole.id === user.role)?.pretty_name}
                </div>
              </div>
            </div>
          )}
          <button className="btn bg-serial-palette-800 hover:bg-serial-palette-600 px-4 text-white" onClick={() => handleLogout()}>
            Sign Out
          </button>
        </div>
      </ModalBasic>
    );

  return (
    <ModalBasic
      id="production-app-user-account-modal"
      title="Serial Account"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      keepOpen={operator === null}
      classOverride="flex flex-col w-96 gap-y-6 pb-8"
    >
      {user && (
        <div className="flex w-full flex-col px-5 pt-2">
          <div className="text-md flex justify-center pb-2 font-medium">
            <UserAvatar user={user} size="xl" />
          </div>
          <div className="flex justify-center text-lg font-medium">
            {user.first_name} {user.last_name}
          </div>
          <div className="text-md text-serial-palette-500 flex justify-center italic">
            {UserRolePrettyNames.find((userRole) => userRole.id === user.role)?.pretty_name}
          </div>
        </div>
      )}
      <div className="flex justify-center space-x-3 px-5">
        <button
          className="btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setModalOpen(false);
          }}
        >
          Cancel
        </button>
        <button className="btn bg-serial-palette-800 hover:bg-serial-palette-600 text-white" onClick={() => handleLogout()}>
          Sign Out
        </button>
      </div>
    </ModalBasic>
  );
};

export default ProductionModalOperator;
