import resolveConfig from "tailwindcss/resolveConfig";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig("./src/css/tailwind.config.js");
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const getDeviceName = async () => {
  const userAgent = navigator.userAgent;
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();
  const ipAddress = data.ip;

  if (/iPhone/i.test(userAgent)) {
    return "iPhone " + ipAddress;
  } else if (/iPad/i.test(userAgent)) {
    return "iPad " + ipAddress;
  } else if (/Android/i.test(userAgent)) {
    return "Android " + ipAddress;
  } else if (/Macintosh|Mac/i.test(userAgent)) {
    return "Mac " + ipAddress;
  } else if (/Windows/i.test(userAgent)) {
    return "Windows " + ipAddress;
  } else {
    return "Unknown " + ipAddress;
  }
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    alert("Text copied to clipboard");
  } catch (error) {
    console.error("Error copying text: ", error);
  }
};

export const dateWasBetweenXAndYDaysAgo = (x, y, timestamptz) => {
  // Get the current timestamp in milliseconds
  const currentTimestamp = Date.now();

  // Get the timestamp of the date X days ago in milliseconds
  const xDaysAgoTimestamp = currentTimestamp - x * 24 * 60 * 60 * 1000;

  // Get the timestamp of the date Y days ago in milliseconds
  const yDaysAgoTimestamp = currentTimestamp - y * 24 * 60 * 60 * 1000;

  // Convert the input timestamp to a Date object
  const inputDate = new Date(timestamptz);

  // Get the timestamp of the input date in milliseconds
  const inputTimestamp = inputDate.getTime();

  // Check if the input date is between X days ago and Y days ago
  if (inputTimestamp <= xDaysAgoTimestamp && inputTimestamp >= yDaysAgoTimestamp) {
    return true;
  } else {
    return false;
  }
};

export const getNumericCharacters = (str) => {
  // Remove all non-numeric characters from the string
  const numericStr = str.replace(/[^0-9.-]/g, "");

  // If the resulting string is empty or only contains a decimal point, return null
  if (numericStr === "") {
    return null;
  }

  // If the resulting string starts with a decimal point, add a leading zero
  if (numericStr.charAt(0) === ".") {
    return "0" + numericStr;
  }

  // Return the resulting string
  return numericStr;
};

export const checkNonEmpty = (varList) => {
  for (let i = 0; i < varList.length; i++) {
    if (varList[i] === null || varList[i] === undefined || varList[i] === "") {
      return false;
    }
  }
  return true;
};

export async function getBase64ImageFromUrl(imageUrl) {
  const res = await fetch(imageUrl);
  const blob = await res.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const base64StringtoFile = (base64String, filename) => {
  let arr = base64String.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const screamingSnakeCaseToTitleCase = (str) => {
  return str
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const truncateString = (str, numChars) => {
  if (str.length <= numChars) {
    return str;
  }
  return str.slice(0, numChars) + "...";
};

export function stringArraysEqual(arr1, arr2) {
  const sorted1 = arr1.slice().sort();
  const sorted2 = arr2.slice().sort();

  return JSON.stringify(sorted1) === JSON.stringify(sorted2);
}

// This function uses the following regular expression to validate the UUID:
// ^[0-9a-fA-F]{8}\\b- checks that the UUID starts with 8 hexadecimal digits followed by a dash.
// [0-9a-fA-F]{4}\\b- checks for another 4 hexadecimal digits followed by a dash.
// 4[0-9a-fA-F]{3}\\b- checks for the version '4' followed by 3 hexadecimal digits and a dash.
// [89abAB][0-9a-fA-F]{3}\\b- checks for one of the hexadecimal digits '8', '9', 'a', 'A', 'b', 'B' followed by 3 hexadecimal digits and a dash (representing the variant).
// [0-9a-fA-F]{12}\\b$ checks for 12 hexadecimal digits at the end.
// 'i' makes the expression case-insensitive.
export const checkIsUuid = (input) => {
  if (input === null || input === undefined) {
    return false;
  }
  const regex = new RegExp("^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-4[0-9a-fA-F]{3}\\b-[89abAB][0-9a-fA-F]{3}\\b-[0-9a-fA-F]{12}\\b$", "i");
  return regex.test(input);
};

export const formatFileSize = (size) => {
  if (size === null || size === undefined) return "";
  if (size < 1024) {
    return size + "B";
  }
  if (size < 1024 * 1024) {
    return (size / 1024).toFixed(1) + "KB";
  }
  if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(1) + "MB";
  }
  return (size / (1024 * 1024 * 1024)).toFixed(1) + "GB";
};
