import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ICellRendererParams } from "ag-grid-community";
import { Loader } from "@shared/components/Loader";

interface PartNumberDeleteCellProps extends ICellRendererParams {
  handleDeletePartNumber: (partNumberId: string) => Promise<void>;
  handleActivatePartNumber: (partNumberId: string) => Promise<void>;
}

const PartNumberDeleteCell: React.FC<PartNumberDeleteCellProps> = ({ handleDeletePartNumber, handleActivatePartNumber, data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (partNumberId: string) => {
    setIsLoading(true);
    if (data.is_active) {
      await handleDeletePartNumber(partNumberId);
    } else {
      await handleActivatePartNumber(partNumberId);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      {data.id && (
        <button tabIndex={-1} className="btn-xs serial-btn-light h-7 w-10" onClick={() => handleClick(data.id)}>
          {isLoading ? (
            <Loader styleOverride="w-[14px] h-[14px]" />
          ) : (
            <FontAwesomeIcon
              className={data.is_active ? "text-red-400 hover:text-red-600" : "text-green-400 hover:text-green-600"}
              size="sm"
              icon={data.is_active ? faTrashAlt : faPowerOff}
            />
          )}
        </button>
      )}
    </div>
  );
};

export default PartNumberDeleteCell;
