import React, { useState, useEffect } from "react";
import moment from "moment/moment";

const ProductionHeaderClock: React.FC = () => {
  const [dateTime, setDateTime] = useState(moment());

  const updateTime = () => {
    setDateTime(moment());
  };

  useEffect(() => {
    const clock = setInterval(updateTime, 1000);
    return function cleanup() {
      clearInterval(clock);
    };
  }, []);

  return (
    <>
      <div className="hidden whitespace-nowrap text-right text-xs sm:block">
        {dateTime.format("MM/DD")} {dateTime.format("HH:mm:ss A")}
      </div>
      {/* <div className='hidden sm:block text-right text-xs whitespace-nowrap'></div> */}
    </>
  );
};

export default ProductionHeaderClock;
