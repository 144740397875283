import React, { useContext, useMemo, useRef, useState } from "react";
import { ProductionContext } from "../ProductionProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "@shared/context/ToastProvider";
import ProductionStepForm from "./ProductionStepForm";
import ProductionStepWorkInstruction from "./ProductionStepWorkInstruction";
import ProductionFormChecklist from "./ProductionFormChecklist";
import ProductionFormIdentifier from "./production-form-fields/ProductionFormIdentifier";
import { Loader } from "@shared/components/Loader";

const ProductionStep: React.FC = () => {
  const {
    draftSubmission,
    filteredSteps,
    selectedStepIndex,
    setSelectedStepIndex,
    station,
    handleSubmit,
    handleReset,
    handleValidateField,
    singleSubmissionMode,
  } = useContext(ProductionContext);
  const { triggerConfirmation } = useContext(ToastContext);
  const [showChecklist, setShowChecklist] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const identifierInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const handleConfirmReset = () => {
    triggerConfirmation(
      "Production App Reset",
      <p>
        Are you sure you want to reset the production app? <br /> All data will be lost.
      </p>,
      () => {
        handleReset();
      },
      undefined,
      "Reset",
    );
  };

  const handleConfirmChangeProcess = () => {
    if (draftSubmission && Object.keys(draftSubmission).length > 0) {
      triggerConfirmation(
        "Change Process",
        <p>
          Are you sure you want to change the process? <br /> All data will be lost.
        </p>,
        () => {
          handleReset();
          navigate(`/production/${station?.id}`);
        },
        undefined,
        "Change Process",
        "Cancel",
      );
    } else {
      navigate(`/production/${station?.id}`);
    }
  };

  const someDraftDataInvalid = useMemo(() => {
    const relevantFields = filteredSteps[selectedStepIndex]?.fields;
    if (!relevantFields) return false;
    return relevantFields.some((field) => draftSubmission?.[field.id]?.isValid === false);
  }, [draftSubmission, selectedStepIndex, filteredSteps]);

  const { totalSteps, percentComplete } = useMemo(() => {
    return {
      totalSteps: filteredSteps.length,
      percentComplete: Math.floor((100 * selectedStepIndex) / filteredSteps.length + 3),
    };
  }, [selectedStepIndex, filteredSteps]);

  const findPreviousStep = (currStepIndex: number) => {
    // if the current step is the first step, return the current step
    if (currStepIndex === 0) return currStepIndex;
    // return the previous visible step
    if (currStepIndex - 1 < 0) return currStepIndex;
    return currStepIndex - 1;
  };

  const findNextStep = (currStepIndex: number) => {
    // if the current step is the last step, return the current step
    if (currStepIndex === filteredSteps.length - 1) return currStepIndex;
    // return the next visible step
    if (currStepIndex + 1 === filteredSteps.length) return currStepIndex;
    return currStepIndex + 1;
  };

  const handleNextOrSubmit = async () => {
    setIsLoading(true);
    if (selectedStepIndex === totalSteps - 1) {
      handleSubmit();
      setIsLoading(false);
      return;
    }
    const fieldValidation = await Promise.all(
      filteredSteps[selectedStepIndex]?.fields.map((field) => {
        return handleValidateField(field.id);
      }),
    );
    const allFieldsValid = fieldValidation.every((validation) => validation?.isValid === true);
    if (allFieldsValid) setSelectedStepIndex(findNextStep(selectedStepIndex));
    setIsLoading(false);
  };

  return (
    <div className="relative flex h-full w-full flex-col overflow-hidden">
      <div className="flex h-full w-full overflow-hidden">
        {filteredSteps[selectedStepIndex]?.work_instruction_blocks?.length > 0 && (
          <div className="flex h-full w-0 shrink-0 overflow-y-auto lg:w-3/5">
            <ProductionStepWorkInstruction />
          </div>
        )}
        <div className="flex min-h-0 min-w-0 flex-grow flex-col overflow-hidden">
          <div className="flex w-full justify-center border-b bg-white p-6">
            <ProductionFormIdentifier identifierInputRef={identifierInputRef} />
          </div>
          <div className="flex h-full w-full flex-grow flex-col overflow-auto">
            <ProductionStepForm identifierInputRef={identifierInputRef} />
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="flex w-full shrink-0 items-center justify-between gap-x-3 border-t bg-white px-2 py-2">
        <div className="flex items-center gap-x-1.5">
          {!singleSubmissionMode && (
            <button className="btn btn-sm serial-btn-light h-7 gap-x-1 lg:h-10" onClick={() => handleConfirmChangeProcess()}>
              Change Process
            </button>
          )}
          <button className="btn btn-sm serial-btn-danger hidden h-7 w-24 gap-x-1 sm:flex lg:h-10" onClick={() => handleConfirmReset()}>
            Reset
          </button>
        </div>
        <div className="hidden w-1/3 items-center justify-center gap-x-3 md:flex">
          {/* Progress bar with width of 100% divided by number of steps */}
          <div className="hidden h-4 w-full overflow-hidden rounded-full border lg:flex">
            <div className="h-full bg-green-500 transition-all" style={{ width: `${percentComplete}%` }} />
          </div>
          <div className="whitespace-nowrap">{`Step ${selectedStepIndex + 1} of ${totalSteps}`}</div>
        </div>
        <div className="flex items-center gap-x-1.5">
          <button
            onClick={() => setSelectedStepIndex(findPreviousStep(selectedStepIndex))}
            className={`btn btn-sm serial-btn-light h-7 w-24 gap-x-1 lg:h-10 ${selectedStepIndex == 0 && "opacity-50"}`}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
            <span className="">Previous</span>
          </button>
          <button
            disabled={someDraftDataInvalid}
            onClick={() => handleNextOrSubmit()}
            className={`btn btn-sm serial-btn-success h-7 w-24 gap-x-1 lg:h-10 ${someDraftDataInvalid && "opacity-50"}`}
          >
            <span className="">{selectedStepIndex === totalSteps - 1 ? "Submit" : "Next"}</span>
            {!isLoading && selectedStepIndex !== totalSteps - 1 && <FontAwesomeIcon icon={faCaretRight} />}
            {isLoading && <Loader styleOverride="text-white w-[16px] h-[16px]" />}
          </button>
          <button onClick={() => setShowChecklist(!showChecklist)} className={`btn serial-btn-light hidden h-7 gap-x-1 sm:flex lg:h-10`}>
            <FontAwesomeIcon icon={faListCheck} />
          </button>
        </div>
      </div>
      <div className={`absolute bottom-14 flex transition-all lg:bottom-[68px] ${showChecklist ? "right-3" : "-right-full"}`}>
        <ProductionFormChecklist showChecklist={showChecklist} setShowChecklist={setShowChecklist} />
      </div>
    </div>
  );
};

export default ProductionStep;
