import React, { useContext, useEffect, useState, useMemo } from "react";
import { Loader } from "@shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faFileExport } from "@fortawesome/free-solid-svg-icons";
import SnLookUpAssembly from "./SnLookUpAssembly";
import { SnLookupNav, SnLookupTabOptions, GenealogyFamilyMember } from "../types";
import SnLookUpTimeline from "./SnLookUpTimeline";
import useSnLookupTimelineLog from "../hooks/useSnLookupTimeline";
import { SnLookupContext } from "../SnLookupProvider";
import { Image } from "@shared/types/databaseTypes";
import SnLookUpImageGallery from "./SnLookUpImageGallery";
import Switch from "@shared/components/Switch";

const SnLookUpPrimaryContent: React.FC = () => {
  const { uniqueIdentifier, processEntries, setPrintModalOpen, isLoading, genealogy, hwRevisions, allImages } = useContext(SnLookupContext);

  const [nav, setNav] = useState<SnLookupNav>({
    [SnLookupTabOptions.ProcessEntries]: { heading: SnLookupTabOptions.ProcessEntries, count: 0 },
    [SnLookupTabOptions.Timeline]: { heading: SnLookupTabOptions.Timeline, count: 0 },
    [SnLookupTabOptions.Images]: { heading: SnLookupTabOptions.Images, count: 0 },
  });
  const [showRetestImages, setShowRetestImages] = useState<boolean>(false);
  const [activeNavOption, setActiveNavOption] = useState<string>(SnLookupTabOptions.ProcessEntries);

  const activityLog = useSnLookupTimelineLog({ processEntries, uniqueIdentifier, genealogy });

  const filteredImages = useMemo(() => {
    const uniqueLatestImages = Object.values(
      allImages.reduce((acc: Record<string, Image>, image: Image) => {
        const key = image.dataset?.id ?? "unknown";
        if (!acc[key] || new Date(acc[key].created_at) < new Date(image.created_at)) {
          acc[key] = image;
        }
        return acc;
      }, {}),
    );
    return uniqueLatestImages;
  }, [allImages]);

  useEffect(() => {
    if (processEntries.length > 0) {
      const countChildren = (member: GenealogyFamilyMember | undefined): number => {
        if (!member) return 0;
        const children = genealogy.filter((m) => m.parent_id === member.id && m.link?.is_active !== false);
        return children.length + children.reduce((acc, child) => acc + countChildren(child), 0);
      };
      const activityCount = activityLog.length;
      const attributesCount = hwRevisions.length;
      const imagesCount = filteredImages.length;
      setNav({
        [SnLookupTabOptions.ProcessEntries]: { heading: SnLookupTabOptions.ProcessEntries, count: attributesCount },
        [SnLookupTabOptions.Timeline]: { heading: SnLookupTabOptions.Timeline, count: activityCount },
        [SnLookupTabOptions.Images]: { heading: SnLookupTabOptions.Images, count: imagesCount },
      });
    }
  }, [processEntries, activityLog, filteredImages]);

  const handleShowRetestImages = (showRetestsImgs: boolean) => {
    setShowRetestImages(showRetestsImgs);
    const imagesCount = showRetestsImgs ? allImages.length : filteredImages.length;
    setNav((prevNav) => ({
      ...prevNav,
      [SnLookupTabOptions.Images]: { heading: SnLookupTabOptions.Images, count: imagesCount },
    }));
  };

  const imagesToShow: Image[] = showRetestImages ? allImages : (filteredImages as Image[]);

  return (
    <div>
      <div className=" border-serial-palette-200 relative mb-4 rounded-md border bg-white">
        <div className="flex w-full justify-between">
          <header className="flex items-center overflow-x-auto pl-[3px] pt-4">
            {Object.values(nav)
              .filter((item) => item.count > 0 || item.heading === SnLookupTabOptions.Timeline)
              .map((option, index) => {
                return (
                  <div key={index} className="flex justify-center">
                    <h2
                      onClick={() => setActiveNavOption(option.heading)}
                      className={`text-serial-palette-800 flex pb-[13px] ${index === 0 ? "pl-5" : "pl-3"} border-b-[3.5px] pr-3 ${activeNavOption === option.heading ? "border-serial-palette-600 font-semibold" : "hover:border-serial-palette-400 border-white font-medium"} cursor-pointer`}
                    >
                      <span className={`${activeNavOption !== option.heading && "opacity-60"}`}>{option.heading}</span>
                      {isLoading && activeNavOption === option.heading ? (
                        <Loader styleOverride="mx-2 w-[24px] h-[24px]" />
                      ) : (
                        <span className="text-serial-palette-400 ml-2 font-medium">{option.count}</span>
                      )}
                    </h2>
                  </div>
                );
              })}
          </header>
          <div className="mx-3 flex items-center gap-x-1.5">
            {nav[activeNavOption].heading === SnLookupTabOptions.Timeline && (
              <button className="btn bg-serial-palette-800 hover:bg-serial-palette-600 hidden h-9 text-white sm:flex" onClick={() => {}}>
                <FontAwesomeIcon icon={faComment} />
              </button>
            )}
            {nav[activeNavOption].heading === SnLookupTabOptions.Images && (
              <div className="mr-1.5 flex items-center gap-1.5">
                <label className="text-serial-palette-600">Show Retests</label>
                <Switch size="sm" onChange={() => handleShowRetestImages(!showRetestImages)} checked={showRetestImages} />
              </div>
            )}
            {!isLoading && (
              <button
                className=" btn bg-serial-palette-800 hover:bg-serial-palette-600 h-9 text-white"
                onClick={() => setPrintModalOpen(true)}
              >
                <FontAwesomeIcon icon={faFileExport} />
                <span className="ml-2 hidden whitespace-nowrap md:block">Export Data</span>
              </button>
            )}
          </div>
        </div>
        {nav[activeNavOption].heading === SnLookupTabOptions.ProcessEntries && <SnLookUpAssembly />}
        {nav[activeNavOption].heading === SnLookupTabOptions.Timeline && <SnLookUpTimeline />}
        {nav[activeNavOption].heading === SnLookupTabOptions.Images && <SnLookUpImageGallery images={imagesToShow} />}
      </div>
    </div>
  );
};

export default SnLookUpPrimaryContent;
