import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { fetchComponentInstancesByIdentifierSubstring } from "@shared/connections/supabaseGeneral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faList, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ComponentType } from "@shared/types/databaseEnums";
import { ProductionFormGenericFieldProps } from "../../types";
import { ProductionContext } from "../../ProductionProvider";
import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { fetchParentsOfIdentifiers } from "../../connections/supabase";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useDebounceCallback } from "usehooks-ts";
import { VALIDATION_DEBOUNCE } from "../../constants";

interface UniqueIdentifierWithParent extends UniqueIdentifier {
  parent?: UniqueIdentifier;
}

const ProductionFormLink: React.FC<ProductionFormGenericFieldProps> = ({ field, draftData }) => {
  const { handleUpdateDraftSubmission, handleValidateField, inputRefs, handleSetFocusedField } = useContext(ProductionContext);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<UniqueIdentifierWithParent[]>([]);
  const [focusedOptionId, setFocusedOptionId] = useState<string | null>(null);

  const trigger = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<Record<string, HTMLButtonElement | null>>({});

  const inputRef = useMemo(() => inputRefs.current[field.id], [inputRefs, field.id]);

  const company = useSelector((state: RootState) => state.db.company);

  // This function returns another function that takes a `ref` parameter.
  // The inner function sets the corresponding option ref in the `optionRefs.current` array.
  // The outer function uses a closure to capture the `index` variable and make it available to the inner function.
  const handleRefs = (index: string) => (ref: HTMLButtonElement) => {
    if (optionRefs.current) {
      optionRefs.current[index] = ref;
    }
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: any) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current?.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // Auto field advancement with tab, enter keys and arrow keys
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (dropdownOpen) {
        // Check if enter was pressed
        if (keyCode === 13) {
          const selectedOption = options.find((o) => o.id === focusedOptionId);
          if (selectedOption) handleSelect(selectedOption);
        }
        // Check if tab was pressed
        else if (keyCode === 9) {
          // If the tab key was pressed, close the dropdown and advance to the next field
          setDropdownOpen(false);
        }
        // Check if up arrow was pressed
        else if (keyCode === 38) {
          // If the up arrow was pressed, set the focused option to the previous option or the last option if the first option was focused
          const nextOptionIndex = (options.findIndex((o) => o.id === focusedOptionId) - 1 + options.length) % options.length;
          setFocusedOptionId(options[nextOptionIndex].id);
        }
        // Check if down arrow was pressed
        else if (keyCode === 40) {
          // If the down arrow was pressed, set the focused option to the next option or the first option if the last option was focused
          const nextOptionIndex = (options.findIndex((o) => o.id === focusedOptionId) + 1) % options.length;
          setFocusedOptionId(options[nextOptionIndex].id);
        }
      }
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [dropdownOpen, options, focusedOptionId]);

  useEffect(() => {
    if (draftData?.linkedIdentifier === "" || draftData?.linkedIdentifier === null || draftData?.linkedIdentifier === undefined) {
      setDropdownOpen(false);
      setOptions([]);
    } else {
      loadData();
    }
  }, [draftData?.linkedIdentifier]);

  const loadData = async () => {
    if (field.dataset?.child_component_id && draftData?.linkedIdentifier) {
      let newLinkOptions = await fetchComponentInstancesByIdentifierSubstring(
        draftData?.linkedIdentifier,
        field.dataset.child_component_id,
        6,
      );

      // Update the dropdown if there are options
      setDropdownOpen(
        newLinkOptions.length > 1 || (newLinkOptions.length > 0 && draftData?.linkedIdentifier !== newLinkOptions[0]?.identifier),
      );

      // If the new link options are the same as the current options, don't update the options
      if (JSON.stringify(newLinkOptions.map((o) => o.id)) === JSON.stringify(options.map((o) => o.id))) return;
      setOptions(newLinkOptions);

      addParentInfoToOptions(newLinkOptions);
    }
  };

  const addParentInfoToOptions = async (newLinkOptions: UniqueIdentifierWithParent[]) => {
    // Update new link options with the current status of all possible links
    // (i.e. if the unit is already used in another assembly)
    if (newLinkOptions.length > 0 && newLinkOptions[0].component?.component_type === ComponentType.Sn) {
      const parentDataMap = await fetchParentsOfIdentifiers(newLinkOptions.map((o) => o.id));
      newLinkOptions = newLinkOptions.map((o) => {
        return {
          ...o,
          parent: parentDataMap[o.id],
        };
      });
      setOptions(newLinkOptions);
    }
  };

  const handleSuggestOptions = async () => {
    if (!field.dataset?.child_component_id) return;
    let newLinkOptions = await fetchComponentInstancesByIdentifierSubstring(
      draftData?.linkedIdentifier ?? "",
      field.dataset.child_component_id,
      6,
    );
    setOptions(newLinkOptions);
    setDropdownOpen(true);
    addParentInfoToOptions(newLinkOptions);
  };

  const handleSelect = (option: UniqueIdentifierWithParent) => {
    setDropdownOpen(false);
    handleUpdateDraftSubmission(field.id, { linkedIdentifier: option.identifier });
  };

  const debouncedHandleValidateField = useDebounceCallback(handleValidateField, VALIDATION_DEBOUNCE);

  useEffect(() => {
    if (!draftData?.linkedIdentifier) return;
    debouncedHandleValidateField(field.id);
  }, [draftData?.linkedIdentifier]);

  return (
    <div className="flex w-full flex-col items-center justify-start ">
      <div className="flex w-full pb-1">
        <p className="text-serial-palette-500 w-full text-sm font-medium">
          {!field.is_optional && <span className="pr-1 text-rose-500">*</span>}
          {field.prompt}:
        </p>
      </div>
      <div className="relative flex h-full w-full shrink-0">
        <div
          ref={trigger}
          className={"text-serial-palette-500 hover:text-serial-palette-600 relative flex w-full justify-between bg-white"}
          aria-label="Select date range"
          aria-haspopup="true"
          aria-expanded={dropdownOpen}
        >
          <input
            ref={(element) => (inputRefs.current[field.id] = element)}
            onFocus={() => handleSetFocusedField(field.id)}
            onBlur={() => handleValidateField(field.id)}
            className={`form-input w-full shadow-none ${draftData?.isValid === true && draftData?.linkedIdentifier ? "serial-input-valid" : draftData?.isValid === false ? "serial-input-invalid" : ""}`}
            placeholder="Serial Number or Lot Code"
            value={draftData?.linkedIdentifier ?? ""}
            onChange={(e) =>
              handleUpdateDraftSubmission(
                field.id,
                e.target.value.trim() === "" ? undefined : { linkedIdentifier: e.target.value.trim() },
                false,
              )
            }
          />
          <div className="absolute right-0 flex h-full justify-center px-2">
            {company.config.show_link_suggestions && (
              <button
                tabIndex={-1}
                className="btn text-serial-palette-400 hover:text-serial-palette-500 w-5"
                onClick={() => handleSuggestOptions()}
              >
                <FontAwesomeIcon icon={faList} />
              </button>
            )}
            {draftData?.linkedIdentifier && (
              <button tabIndex={-1} className="btn text-serial-palette-400 hover:text-serial-palette-500 w-5" onClick={() => {}}>
                <FontAwesomeIcon icon={faTimes} size="lg" onClick={() => handleUpdateDraftSubmission(field.id, { linkedIdentifier: "" })} />
              </button>
            )}
          </div>
        </div>
        {dropdownOpen && company.config.show_link_suggestions && (
          <div className="border-serial-palette-200 absolute left-0 top-full z-10 mt-1 w-full overflow-hidden rounded border bg-white py-1.5 shadow">
            <div
              ref={dropdown}
              className="text-serial-palette-600 scrollbar-hide max-h-56 overflow-y-auto text-sm font-medium"
              onBlur={() => setDropdownOpen(false)}
            >
              <p className="text-serial-palette-400 px-3 pb-1 pt-1.5 text-xs font-semibold uppercase">Suggestions</p>
              {options.map((option, index) => {
                return (
                  <span key={index}>
                    <button
                      ref={handleRefs(option.id)}
                      onMouseEnter={() => setFocusedOptionId(option.id)}
                      onMouseLeave={() => setFocusedOptionId(null)}
                      tabIndex={-1}
                      className={`flex w-full cursor-pointer items-center px-3 py-1 ${option.id === focusedOptionId && "bg-serial-palette-50"}`}
                      onMouseDown={() => handleSelect(option)}
                    >
                      <div className="item-center flex w-full justify-between">
                        <span className="text-left">{option.identifier}</span>
                        {option.parent?.identifier && (
                          <span className="text-right font-light">
                            <FontAwesomeIcon icon={faLink} /> <span className="hidden md:inline"> Currently part of: </span>
                            <span className="font-semibold">{option.parent?.identifier}</span>
                          </span>
                        )}
                      </div>
                    </button>
                    <div
                      id="FocusCatcher"
                      className="h-0"
                      tabIndex={option.id === focusedOptionId ? 0 : -1}
                      onFocus={() => inputRef?.focus()}
                    ></div>
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductionFormLink;
