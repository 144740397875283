import React, { useContext } from "react";
import { ImageMarkupLabelElementProps } from "./ImageMarkupElementContainer";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import { ImageMarkupElementCircle as CircleElement } from "@shared/types/databaseTypes";

const ImageMarkupElementCircle: React.FC<ImageMarkupLabelElementProps<CircleElement>> = ({ element }) => {
  const { imageWidth, imageHeight } = useContext(ImageMarkupContext);

  return (
    <div
      className="shrink-0 rounded-lg"
      style={{
        width: Math.abs(element.width) * imageWidth,
        height: Math.abs(element.height) * imageHeight,
        border: `${element.thickness}px solid ${element.color}`,
        borderRadius: "50%",
        backgroundColor: element.fill ? element.fill : "transparent",
      }}
    />
  );
};

export default ImageMarkupElementCircle;
