import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { ProductionContext } from "../../ProductionProvider";
import { fetchLatestProcessEntry, fetchProcessEntryData, fetchLatestLink } from "../../connections/supabase";
import { DraftSubmissionData, DraftSubmissionDatapoint } from "../../types";
import {
  FieldType,
  Checkbox,
  File,
  Image,
  ParametricQualitative,
  ParametricQuantitative,
  UniqueIdentifierLink,
  DatetimeData,
} from "@shared/types/databaseTypes";
import Button from "@shared/components/primitives/Button";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";

const ProductionFormLoadHistoryButton: React.FC = () => {
  const { handleOverwriteDraftSubmission, process, componentInstances, filteredSteps } = useContext(ProductionContext);
  const { triggerToast } = useContext(ToastContext);

  const [isLoading, setIsLoading] = useState(false);
  const fields = useMemo(() => filteredSteps.flatMap((step) => step.fields), [filteredSteps]);

  const handleLoadHistory = useCallback(async () => {
    if (!process || !componentInstances?.[0] || !fields) return;
    setIsLoading(true);
    const { data: latestProcessEntry } = await fetchLatestProcessEntry(componentInstances[0].id, process.id);
    if (!latestProcessEntry) {
      triggerToast(
        ToastType.Info,
        "No previous submissions found",
        `No data has ever been submitted for ${componentInstances[0].identifier} in this process.`,
      );
      setIsLoading(false);
      return;
    }
    const processEntryData = await fetchProcessEntryData(latestProcessEntry.id);
    let newDraftSubmission: DraftSubmissionData = {};
    for (const field of fields) {
      const fieldData = processEntryData.find((entry) => entry.dataset_id === field.dataset_id);
      if (!fieldData && field.type !== FieldType.Link) continue;
      let draftSubmissionDatapoint: DraftSubmissionDatapoint = {};
      switch (field.type) {
        case FieldType.File:
          draftSubmissionDatapoint = {
            fileId: (fieldData as File).file_id ?? (fieldData as File).id,
            fileInfo: {
              name: (fieldData as File).file_name,
            },
          };
          break;
        case FieldType.Image:
        case FieldType.Signature:
          draftSubmissionDatapoint = {
            fileId: (fieldData as Image).file_id ?? (fieldData as Image).id,
            fileInfo: {
              name: (fieldData as Image).file_name,
            },
          };
          break;
        case FieldType.Link:
          const linkFieldData = await fetchLatestLink(componentInstances[0].id, field.dataset_id);
          if (!linkFieldData) continue;
          draftSubmissionDatapoint = {
            linkedIdentifier: (linkFieldData as UniqueIdentifierLink).child?.identifier,
          };
          break;
        case FieldType.Label:
        case FieldType.Checkbox:
          draftSubmissionDatapoint = {
            value: (fieldData as Checkbox).is_checked,
          };
          break;
        case FieldType.ManualEntry:
          draftSubmissionDatapoint = {
            value: String((fieldData as ParametricQualitative | ParametricQuantitative).value),
          };
          break;
        case FieldType.PassFail:
          draftSubmissionDatapoint = {
            value: (fieldData as ParametricQualitative | ParametricQuantitative).value === "PASS" ? true : false,
          };
          break;
        case FieldType.Datetime:
          draftSubmissionDatapoint = {
            value: (fieldData as DatetimeData).value,
          };
          break;
      }
      newDraftSubmission[field.id] = draftSubmissionDatapoint;
    }
    handleOverwriteDraftSubmission(newDraftSubmission);
    setIsLoading(false);
  }, [handleOverwriteDraftSubmission, process, componentInstances]);

  return (
    <Button
      tabIndex={-1}
      onClick={handleLoadHistory}
      tooltip="Load last submitted data"
      className="h-10"
      variant="outline"
      isLoading={isLoading}
    >
      <FontAwesomeIcon icon={faHistory} />
    </Button>
  );
};

export default ProductionFormLoadHistoryButton;
