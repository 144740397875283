import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex w-full justify-center">
          <div className="p-8">
            <h2 className="font-bold">Oops. Something went wrong.</h2>
            <p className="text-serial-palette-800 pb-2 text-sm">Try refreshing the page or contact Serial support if the issue persists.</p>
            <details className="text-sm" style={{ whiteSpace: "pre-wrap" }}>
              <span className="italic text-red-500">{this.state.error && this.state.error.toString()} </span>
            </details>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
