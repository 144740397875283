import React, { useMemo } from "react";
import { filterConditionOperatorStyle } from "../../types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MeasureFilterCondition } from "@shared/measures/types";
import { getMeasureName } from "@shared/measures/helpers/naming";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";
import ButtonGroup from "@shared/components/primitives/ButtonGroup";
import TagBasic from "@shared/components/primitives/TagBasic";
import { formatSummaryMeasureValue } from "@shared/measures/helpers/measureValues";

interface MeasuresFilterBuilderConditionBlockProps {
  filterCondition: MeasureFilterCondition;
  onTriggerValueSelector: () => void;
  onTriggerOperatorSelector: () => void;
  onRemoveFilterCondition: () => void;
}

const MeasuresFilterBuilderConditionBlock: React.FC<MeasuresFilterBuilderConditionBlockProps> = ({
  filterCondition,
  onTriggerValueSelector,
  onTriggerOperatorSelector,
  onRemoveFilterCondition,
}) => {
  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const filterConditionName = useMemo(() => {
    return getMeasureName(filterCondition.key, { components, processes, datasets });
  }, [filterCondition.key]);

  return (
    <ButtonGroup.Root size="sm" variant="outline">
      <ButtonGroup.Button className="h-8 px-2.5 !opacity-100" disabled>
        <TagBasic variant={filterCondition.key.type} size="xs" />
        <span className="truncate whitespace-nowrap">{filterConditionName}</span>
      </ButtonGroup.Button>
      <ButtonGroup.Button className="h-8" onClick={() => onTriggerOperatorSelector()}>
        {filterCondition.operator && (
          <span className="whitespace-nowrap font-bold">{filterConditionOperatorStyle[filterCondition.operator].symbol}</span>
        )}
      </ButtonGroup.Button>
      <ButtonGroup.Button className="h-8" onClick={() => onTriggerValueSelector()}>
        <span className="truncate">
          {filterCondition.value && filterCondition.value !== "" ? (
            formatSummaryMeasureValue(filterCondition.value, filterCondition.key.type)
          ) : (
            <span className="text-serial-palette-400 italic">value...</span>
          )}
        </span>
      </ButtonGroup.Button>
      <ButtonGroup.Button className="-ml-[2px] h-8 w-8" onClick={() => onRemoveFilterCondition()}>
        <FontAwesomeIcon icon={faTimes} />
      </ButtonGroup.Button>
    </ButtonGroup.Root>
  );
};

export default MeasuresFilterBuilderConditionBlock;
