import React, { useEffect, useState } from "react";
import { deleteStationProcessLink, fetchAllComponentProcessLinks, insertStationProcessLink } from "../connections/supabase";
import { StationWithLinksAndProcessEntries } from "../types";
import { StationProcessLink } from "@shared/types/databaseTypes";

interface StationDetailsEnabledProcessesProps {
  selectedStation: StationWithLinksAndProcessEntries | null;
  reloadData: () => Promise<StationWithLinksAndProcessEntries[] | null>;
}

interface LocalLink {
  componentId: string;
  name: string;
  processes: LocalProcess[];
}

interface LocalProcess {
  stationProcessLinkId: string;
  processId: string;
  name: string;
  order: number | null;
  checked: boolean;
}

const StationDetailsEnabledProcesses: React.FC<StationDetailsEnabledProcessesProps> = ({ selectedStation, reloadData }) => {
  const [localLinks, setLocalLinks] = useState<LocalLink[]>([]);

  // load the data from supabase and create a tree of processes grouped first by component
  const loadData = async () => {
    if (!selectedStation) return;
    const allComponentProcessLinks = await fetchAllComponentProcessLinks();
    // for each new link, create a tree of processes grouped first by component
    const newLocalLinks: LocalLink[] = [];
    for (const link of allComponentProcessLinks) {
      // check if the station_process_link is found in the station's station_process_links
      const checked = selectedStation.station_process_links?.some(
        (stationLink: StationProcessLink) => stationLink.component?.id === link.component_id && stationLink.process?.id === link.process_id,
      );
      const stationProcessLinkId = checked
        ? selectedStation.station_process_links?.find(
            (stationLink: StationProcessLink) =>
              stationLink.component?.id === link.component_id && stationLink.process?.id === link.process_id,
          )?.id
        : null;

      // if the component isn't found in the in the array, create it
      const componentIndex = newLocalLinks.findIndex((newLocalLink: any) => newLocalLink.componentId === link.component_id);
      if (componentIndex === -1) {
        newLocalLinks.push({
          componentId: link.component_id,
          name: link.component?.name ?? "",
          processes: [
            {
              stationProcessLinkId: stationProcessLinkId ?? "",
              processId: link.process_id,
              name: link.process?.name ?? "",
              order: link.order,
              // checked if the station_process_link is found in the station's station_process_links
              checked: checked ?? false,
            },
          ],
        });
      } else {
        newLocalLinks[componentIndex].processes.push({
          stationProcessLinkId: stationProcessLinkId ?? "",
          processId: link.process_id,
          name: link.process?.name ?? "",
          order: link.order,
          checked: checked ?? false,
        });
      }
    }
    setLocalLinks(newLocalLinks);
  };

  let promiseChain = Promise.resolve();

  const handleCheckboxChange = (stationProcessLinkId: string, componentId: string, processId: string, checked: boolean) => {
    if (!selectedStation) return;
    // Add a new link to the promise chain
    promiseChain = promiseChain
      .then(async () => {
        // update the link array
        const newLocalLinks = [...localLinks];
        const componentIndex = newLocalLinks.findIndex((newLink: any) => newLink.componentId === componentId);
        const processIndex = newLocalLinks[componentIndex].processes.findIndex((process: any) => process.processId === processId);
        newLocalLinks[componentIndex].processes[processIndex].checked = checked;
        setLocalLinks(newLocalLinks);
        // update the database
        if (checked) {
          await insertStationProcessLink(selectedStation.id, processId, componentId);
        } else {
          await deleteStationProcessLink(stationProcessLinkId);
        }
      })
      .then(() => {
        // If there are no new promises added to the chain, call reloadData
        if (Promise.resolve() === promiseChain) {
          reloadData();
        }
      });
  };

  useEffect(() => {
    loadData();
  }, [selectedStation]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
      {/* Sort components  by number of processes (descending) */}
      {localLinks
        .sort((a: any, b: any) => b.processes.length - a.processes.length)
        .map((link: LocalLink, index: number) => (
          <div className="pb-3" key={index}>
            <div className="text-serial-palette-500 font-bold">{link.name}</div>
            {link.processes
              .sort((a: any, b: any) => a.order - b.order)
              .map((process: LocalProcess, index: number) => (
                <div className="flex w-full gap-x-2" key={index}>
                  <input
                    type="checkbox"
                    className="form-checkbox bg-serial-palette-100 mt-1"
                    onChange={() =>
                      handleCheckboxChange(process.stationProcessLinkId, link.componentId, process.processId, !process.checked)
                    }
                    checked={process.checked === true}
                  />
                  <div className="truncate text-sm font-light">{process.name}</div>
                </div>
              ))}
          </div>
        ))}
    </div>
  );
};

export default StationDetailsEnabledProcesses;
