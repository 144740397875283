import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { Loader } from "@shared/components/Loader";
import { askSerialAPIProgrammingAssistant } from "../connections/api";
import ProcessBuilderApiChatMessage from "./ProcessBuilderApiChatMessage";
import { SerialProgrammingAssistantChatMessage } from "../types";
import { OpenaiCompletionRole } from "@shared/types/openai";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface ProcessBuilderApiChatProps {
  sourceCode: string;
  setSourceCode: React.Dispatch<React.SetStateAction<string>>;
}

const ProcessBuilderApiChat: React.FC<ProcessBuilderApiChatProps> = ({ setSourceCode }) => {
  const [messages, setMessages] = useState<SerialProgrammingAssistantChatMessage[]>([]);
  const [draftUserMessage, setDraftUserMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { triggerToast } = useContext(ToastContext);
  const observe = useContext(ObservabilityContext);

  const endOfChatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfChatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (isLoading) {
        triggerToast(ToastType.Info, "Serial AI is already at work", "Please wait for code to finish loading.");
        return;
      }
      observe.track("API Assistant Chat Message", { message: draftUserMessage });
      const updatedMessages = [
        ...messages,
        { role: OpenaiCompletionRole.User, content: draftUserMessage, timestamp: new Date().toISOString() },
      ];
      setMessages(updatedMessages);
      setDraftUserMessage("");
      setIsLoading(true);
      const { data: responseData } = await askSerialAPIProgrammingAssistant(
        updatedMessages.map((m) => {
          return { role: m.role as string, content: m.content };
        }),
      );
      if (!responseData?.message) {
        triggerToast(ToastType.Error, "Opps, something went wrong", "Please try again.");
      } else {
        updatedMessages.push({ role: OpenaiCompletionRole.Assistant, content: responseData.message, timestamp: new Date().toISOString() });
        setMessages([...updatedMessages]); // use ... such that the scroll useEffect is triggered
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-full w-full flex-col justify-between">
      <div className="bg-serial-palette-100 scrollbar-hide z-20 flex min-h-0 min-w-0 flex-grow flex-col overflow-auto">
        <ProcessBuilderApiChatMessage
          role={OpenaiCompletionRole.Assistant}
          content="Hi, I'm the Serial assistant, is there anything I can help with? I use AI so my responses can sometimes contain minor issues. Be sure to check things over before running any scripts and please let us know if you have any feedback!"
          actionButtonLabel="Full Documentation"
          handleActionButton={() => window.open("https://docs.serial.io/python/quickstart", "_blank")}
        />
        {messages.map((message, index) => {
          return (
            <ProcessBuilderApiChatMessage
              key={index}
              role={message.role}
              timestamp={message.timestamp}
              content={message.content}
              handleInsertCode={(code) => setSourceCode(code)}
            />
          );
        })}
        <div ref={endOfChatRef} className="h-[10px] w-full shrink-0" />
      </div>
      <div className="flex h-16 w-full shrink-0 items-center border-t bg-white px-3">
        <div className="flex h-12 w-full items-center justify-between gap-3 rounded-full border-2 border-violet-500 bg-violet-100 px-4 py-2 ">
          <div className="flex w-full items-center gap-0.5">
            <FontAwesomeIcon className="text-violet-500" icon={faWandMagicSparkles} />
            <input
              value={draftUserMessage}
              className="w-full border-0 bg-transparent text-violet-600 outline-0 !ring-0 placeholder:text-violet-400"
              placeholder="Ask Serial AI to help refine your script"
              onKeyDown={(e) => handleSendMessage(e)}
              onChange={(e) => setDraftUserMessage(e.target.value)}
            />
          </div>
          {isLoading && <Loader styleOverride="h-[20px] w-[20px] text-violet-500" />}
        </div>
      </div>
    </div>
  );
};

export default ProcessBuilderApiChat;
