import { ChartOptions, ChartType } from "chart.js";

const defaultSizing = {
  sm: 12,
  md: 14,
  lg: 18,
};

export const withDefaultSizingLineBarScatter = (
  options: ChartOptions<"line" | "bar" | "scatter">,
  size: "sm" | "md" | "lg",
): ChartOptions<"line" | "bar" | "scatter"> => {
  const fontSize = defaultSizing[size];

  const setFontSize = (fontOption: any) => {
    if (fontOption && typeof fontOption === "object") {
      return {
        ...fontOption,
        size: fontOption.size ?? fontSize,
      };
    }
    return { size: fontSize };
  };

  const modifiedOptions: ChartOptions<"line" | "bar" | "scatter"> = {
    ...options,
    scales: {
      ...options.scales,
      y: {
        ...options.scales?.y,
        ticks: {
          ...options.scales?.y?.ticks,
          font: setFontSize(options.scales?.y?.ticks?.font),
        },
        title: {
          ...options.scales?.y?.title,
          font: setFontSize({ weight: 500, ...options.scales?.y?.title?.font }),
        },
      },
      x: {
        ...options.scales?.x,
        ticks: {
          ...options.scales?.x?.ticks,
          font: setFontSize(options.scales?.x?.ticks?.font),
        },
        title: {
          ...options.scales?.x?.title,
          font: setFontSize({ weight: 500, ...options.scales?.x?.title?.font }),
        },
      },
    },
    plugins: {
      ...options.plugins,
      legend: {
        ...options.plugins?.legend,
        labels: {
          ...options.plugins?.legend?.labels,
          font: setFontSize(options.plugins?.legend?.labels?.font),
        },
      },
      tooltip: {
        ...options.plugins?.tooltip,
        bodyFont: setFontSize(options.plugins?.tooltip?.bodyFont),
        titleFont: setFontSize(options.plugins?.tooltip?.titleFont),
        footerFont: setFontSize(options.plugins?.tooltip?.footerFont),
      },
    },
  };

  return modifiedOptions;
};

export const withDefaultSizingPie = (options: ChartOptions<"doughnut">, size: "sm" | "md" | "lg"): ChartOptions<"doughnut"> => {
  const fontSize = defaultSizing[size];

  const setFontSize = (fontOption: any) => {
    if (fontOption && typeof fontOption === "object") {
      return {
        ...fontOption,
        size: fontOption.size ?? fontSize,
      };
    }
    return { size: fontSize };
  };

  const modifiedOptions: ChartOptions<"doughnut"> = {
    ...options,
    plugins: {
      ...options.plugins,
      legend: {
        ...options.plugins?.legend,
        labels: {
          ...options.plugins?.legend?.labels,
          font: setFontSize(options.plugins?.legend?.labels?.font),
        },
      },
      tooltip: {
        ...options.plugins?.tooltip,
        bodyFont: setFontSize(options.plugins?.tooltip?.bodyFont),
        titleFont: setFontSize(options.plugins?.tooltip?.titleFont),
        footerFont: setFontSize(options.plugins?.tooltip?.footerFont),
      },
    },
  };

  return modifiedOptions;
};

export const withDefaultSizing = (
  type: ChartType,
  options: ChartOptions<"line" | "bar" | "scatter" | "doughnut">,
  size: "sm" | "md" | "lg",
): ChartOptions<any> => {
  if (["line", "bar", "scatter"].includes(type)) {
    return withDefaultSizingLineBarScatter(options as ChartOptions<"line" | "bar" | "scatter">, size);
  }
  if (type === "doughnut") {
    return withDefaultSizingPie(options as ChartOptions<"doughnut">, size);
  }
  return options;
};
