import React from "react";
import { CountsByStatus } from "src/features/production-app/types";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";

// Type for category key
type CategoryKey = keyof CountsByStatus;

interface identifierStatusTypeFormattingProps {
  label: string;
  key: CategoryKey; // Use CategoryKey here
  color: string;
}

const identifierStatusTypeFormatting: identifierStatusTypeFormattingProps[] = [
  {
    label: "Planned",
    key: UniqueIdentifierStatus.Planned,
    color: "bg-neutral-400",
  },
  {
    label: "WIP",
    key: UniqueIdentifierStatus.Wip,
    color: "bg-sky-600",
  },
  {
    label: "Complete",
    key: UniqueIdentifierStatus.Complete,
    color: "bg-green-600",
  },
  {
    label: "Defective",
    key: UniqueIdentifierStatus.Defective,
    color: "bg-red-600",
  },
];

interface WorkOrderProgressBarProps {
  counts_by_status: CountsByStatus;
  quantity: number;
  showLegend?: "mini" | "full";
}

const WorkOrderProgressBar: React.FC<WorkOrderProgressBarProps> = ({ counts_by_status, quantity, showLegend }) => {
  const totalQty = quantity > 0 ? quantity : 1; // Ensure we don't divide by zero

  // Calculate the width of each segment
  const segments = identifierStatusTypeFormatting.map((identifierStatusTypeFormat) => {
    const value = counts_by_status[identifierStatusTypeFormat.key] ?? 0;
    const width = Math.max((value / totalQty) * 100, 0).toFixed(2) + "%"; // Calculate the width as a percentage
    return { ...identifierStatusTypeFormat, value, width };
  });

  return (
    <>
      <div className={`flex ${!showLegend && "items-start"}`}>
        <div className={`w-14 ${showLegend && "-mt-1"}`}>{(((counts_by_status["COMPLETE"] ?? 0) / totalQty) * 100).toFixed(0)}%</div>
        <div className="w-full flex-col items-center justify-center space-y-2">
          <div className="bg-serial-palette-200 group h-5 flex-grow overflow-hidden rounded-full">
            <div className="flex h-full w-full">
              {segments.map((segment, index) => (
                <div key={index} className={`relative ${segment.color} flex h-full items-center`} style={{ width: segment.width }}></div>
              ))}
            </div>
          </div>
          {showLegend && (
            <div className="inline-flex items-center space-x-5">
              {segments.map((segment, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <div className={`h-4 w-4 ${segment.color} rounded`}></div>
                  <div className="hidden text-sm font-bold xl:block">{segment.label}</div>
                  <span className="text-sm">{segment.value}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkOrderProgressBar;
