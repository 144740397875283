import ProcessBuilderProvider from "./ProcessBuilderProvider";
import ProcessBuilderModal from "./ProcessBuilderModal";

export enum ProcessBuilderMode {
  Modal = "MODAL",
  Inline = "INLINE",
}

export interface ProcessBuilderProps {
  processBuilderOpen: boolean;
  setProcessBuilderOpen: (value: boolean) => void;
  processId: string | null;
  componentId: string | null;
  mode?: ProcessBuilderMode;
}

const ProcessBuilder: React.FC<ProcessBuilderProps> = ({
  processBuilderOpen,
  setProcessBuilderOpen,
  processId,
  componentId,
  mode = ProcessBuilderMode.Modal,
}) => {
  return (
    <ProcessBuilderProvider
      processBuilderOpen={processBuilderOpen}
      setProcessBuilderOpen={setProcessBuilderOpen}
      processId={processId}
      componentId={componentId}
    >
      {mode === ProcessBuilderMode.Modal && <ProcessBuilderModal />}
    </ProcessBuilderProvider>
  );
};

export default ProcessBuilder;
