import useCurrentUser from "@shared/hooks/useCurrentUser";
import { Station } from "@shared/types/databaseTypes";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchStationById, updateStation } from "../connections/supabase";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { ParsedResponse } from "@shared/types/apiTypes";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface UseCurrentStationReturn {
  station: Station | null;
  handleUpdateStation: (data?: Partial<Station>) => Promise<ParsedResponse>;
}

const useCurrentStation = (): UseCurrentStationReturn => {
  const [station, setStation] = useState<Station | null>(null);
  const [processId, setProcessId] = useState<string | undefined>(undefined);

  const observe = useContext(ObservabilityContext);

  const user = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const { triggerToast } = useContext(ToastContext);

  const currentStationId = useMemo(() => {
    // Option 1: get station id from url. Note: URL will be formatted as "serial.io/production/{station_id}/{process_id}"
    const pathSegments = location.pathname.split("/");
    const urlStationId = pathSegments?.[2];
    setProcessId(pathSegments?.[3]);
    if (urlStationId) {
      return urlStationId;
    }

    // Option 2: get station id from cookie
    const cookies = document.cookie.split("; ").find((row) => row.startsWith("stationId="));
    const stationIdByCookie = cookies ? cookies.split("=")[1] : null;
    if (stationIdByCookie) {
      observe.track("factory-ui-station_load", { method: "cookie" });
      console.log("stationId set by cookie");
      return stationIdByCookie;
    }

    // Option 3: get station id from local storage
    const stationIdByLocalStoragge = localStorage.getItem("stationId");
    if (stationIdByLocalStoragge !== null && stationIdByLocalStoragge !== "null") {
      observe.track("factory-ui-station_load", { method: "local-storage" });
      console.log("stationId set by local-storage");
      return stationIdByLocalStoragge;
    }

    // Option 4: get the most recently used station id of the current user
    const stationByUser = user?.default_station_id;
    if (stationByUser) {
      observe.track("factory-ui-station_load", { method: "user-default-station" });
      console.log("stationId set by user-default-station");
      return stationByUser;
    }

    observe.track("factory-ui-station_load", { method: "not-found" });
    console.log("stationId not found");
    return undefined;
  }, [user, location.pathname]);

  const loadStation = async (stationId: string) => {
    if (!currentStationId) return;
    const response = await fetchStationById(stationId);
    if (response.error) {
      // expected behavior for serial users switching to a new tenant so don't log an error
      console.log(response.error);
      return;
    }
    setStation(response.data ?? null);
  };

  useEffect(() => {
    if (currentStationId) {
      loadStation(currentStationId);
      document.cookie = "stationId=" + currentStationId; // set cookie station id
      localStorage.setItem("stationId", currentStationId); // set local storage to station id
      const queryParamsString = new URLSearchParams(location.search).toString();
      navigate(`/production/${currentStationId}${processId ? `/${processId}` : ""}?${queryParamsString}`);
    }
  }, [currentStationId]);

  const handleUpdateStation = useCallback(
    async (data?: Partial<Station>) => {
      if (!station || !currentStationId) {
        console.error("No station ID available to update");
        return { data: undefined, error: "No station ID available to update" };
      }
      // If no data is passed in, just reload the station
      if (!data) {
        await loadStation(currentStationId);
        return { data: station, error: undefined };
      }
      // Set station locally before requests for optimistic UI
      setStation({ ...station, ...data });
      const response = await updateStation(currentStationId, data);
      await loadStation(currentStationId);
      if (response.error) {
        console.error(response.error);
        triggerToast(ToastType.Error, "Production App Error", "Failed to update station type");
      }
      return response;
    },
    [station, currentStationId],
  );

  return { station, handleUpdateStation };
};

export default useCurrentStation;
