import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

// DEPRECATED: use FileInputBox instead

function FileUploadBox({ setSelectedFile, resetFile, allowPaste, iconOverride, file, fileTypeInPrompt, inactive, className }) {
  const [dragOver, setDragOver] = useState(false);
  const fileInputRef = useRef(null);

  const onDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    setSelectedFile(e.dataTransfer.files[0]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const onPaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    if (!items) return;

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        setSelectedFile(file);
        break;
      }
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    resetFile();
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  useEffect(() => {
    const handlePaste = (e) => {
      if (allowPaste) {
        onPaste(e);
      }
    };

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [allowPaste]);

  return (
    <div
      className={`mx-5 flex h-36 w-full flex-col items-center rounded-md text-center ${dragOver && !inactive && "ring ring-offset-2"} ${className}`}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      <div className="flex h-full flex-col items-center justify-center space-y-2">
        <FontAwesomeIcon icon={iconOverride ? iconOverride : faFileUpload} size="3x" className="text-serial-palette-200 hidden sm:block" />
        <div className="w-full flex-col justify-start space-y-2 text-xs sm:text-sm">
          <p className={`not-italic`}>
            {file ? file.file_name : "Drag and drop your " + (fileTypeInPrompt ? fileTypeInPrompt : "file") + " here or"}
          </p>
          <div className="flex flex-col justify-center gap-1 sm:flex-row">
            <button
              id="file-upload-box-button"
              disabled={inactive}
              tabIndex={inactive ? "-1" : "0"}
              className={`btn-xs sm:btn-sm border-serial-palette-200 whitespace-nowrap bg-white text-xs sm:text-sm ${!inactive && "hover:ring"}`}
              onClick={(e) => {
                e.preventDefault();
                fileInputRef.current.click();
              }}
            >
              {file ? "Change File" : "Browse Files"}
            </button>
            {file && resetFile && (
              <button
                disabled={inactive}
                tabIndex={-1}
                className={`btn-xs sm:btn-sm border-serial-palette-200 whitespace-nowrap bg-white text-xs sm:text-sm ${!inactive && "hover:ring"}`}
                onClick={(e) => handleReset(e)}
              >
                Reset
              </button>
            )}
          </div>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelectedFile(e.target.files[0]);
          }}
        />
      </div>
    </div>
  );
}

export default FileUploadBox;
