import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { workOrderStatusStyle } from "../contants";
import { WorkOrderStatus } from "@shared/types/databaseEnums";

interface WorkOrderStatusTagProps {
  status: WorkOrderStatus;
  size?: number;
}

const WorkOrderStatusTag: React.FC<WorkOrderStatusTagProps> = ({ status, size = 24 }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full ${workOrderStatusStyle[status].bgColor} text-white`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <FontAwesomeIcon className="text-white" icon={workOrderStatusStyle[status].icon} style={{ fontSize: `${Math.floor(size / 2)}px` }} />
    </div>
  );
};

export default WorkOrderStatusTag;
