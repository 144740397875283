import React, { useState, useEffect, useMemo, useContext } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { LogFileSchema, LogFileSchemaDatasetLink, LogFileSchemaDatasetLinkPathKeyItem } from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import { cn } from "@shared/utils/tailwind";
import TagBasic from "@shared/components/primitives/TagBasic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@shared/components/primitives/Checkbox";
import { TextInput } from "@shared/components/primitives/Input";
import { findSubPathKeys } from "../helpers";

interface JsonPreviewRowProps {
  pathKey: LogFileSchemaDatasetLinkPathKeyItem[];
  keyName?: string;
  datasetLink?: LogFileSchemaDatasetLink;
  schemaDatasetLinks?: LogFileSchemaDatasetLink[];
  handleUpdateDatasetName: (updatedDataset: { path_key: LogFileSchemaDatasetLinkPathKeyItem[]; newName: string }) => void;
  value: string | number | boolean;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  level: number;
  expandable: boolean;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  editing?: boolean;
}

const JsonPreviewRow: React.FC<JsonPreviewRowProps> = ({
  pathKey,
  keyName,
  datasetLink,
  handleUpdateDatasetName,
  value,
  checked,
  onCheckedChange,
  level,
  expandable,
  expanded,
  setExpanded,
  editing = false,
}) => {
  const datasetType = useMemo(() => {
    if (expandable) return null;
    if (datasetLink) return datasetLink.dataset?.data_type;
    if (typeof value === "string") return DataType.ParametricQualitative;
    if (typeof value === "number") return DataType.ParametricQuantitative;
    if (typeof value === "boolean") return DataType.Checkbox;
    return null;
  }, [value]);

  const datasetName = useMemo(() => {
    if (datasetLink?.dataset?.name !== undefined) return datasetLink.dataset?.name;
    return pathKey.map((key) => String(key.key)).join(".");
  }, [datasetLink?.dataset?.name, pathKey]);

  return (
    <div className={cn("relative flex h-9 w-full items-center justify-between gap-2 px-2", expanded ? "cursor-pointer" : "")}>
      <div
        className="flex min-w-0 flex-grow items-center gap-2 overflow-hidden text-sm"
        style={{ paddingLeft: `${level * 20}px` }}
        onClick={() => {
          if (expandable && setExpanded && expanded !== undefined) setExpanded(!expanded);
          else if (!expandable) onCheckedChange(!checked);
        }}
      >
        <div className="flex w-5 shrink-0 items-center justify-center">
          {expandable && <FontAwesomeIcon icon={faChevronDown} className={`transition-all ${!expanded ? "-rotate-90" : "rotate-0"}`} />}
        </div>
        <Checkbox checked={checked} onCheckedChange={onCheckedChange} onClick={(e) => e.stopPropagation()} disabled={!editing} />
        <div className={cn("font-mono", datasetType ? "font-bold" : "")}>
          {keyName ?? pathKey[pathKey.length - 1]?.key}
          {datasetType && ":"}
        </div>
        {datasetType && <div className="whitespace-nowrap font-mono">{String(value)}</div>}
      </div>
      {datasetType && checked && (
        <div className="flex h-full w-1/2 items-center gap-2.5">
          <FontAwesomeIcon icon={faArrowRight} className="text-serial-palette-400" />
          <TagBasic variant={datasetType} size="sm" />
          <TextInput
            className="focus:border-1 h-7 w-full rounded-sm border-0 px-0.5 py-0 text-sm !ring-0"
            value={datasetName}
            disabled={!editing}
            onValueChange={(newName) => {
              if (handleUpdateDatasetName) {
                const updatedDatasetInfo = { path_key: pathKey, newName: String(newName) };
                handleUpdateDatasetName(updatedDatasetInfo);
              }
            }}
            placeholder="Enter field name"
          />
        </div>
      )}
    </div>
  );
};

interface JsonPreviewCollapsibleProps {
  value: any;
  schemaDatasetLinks: LogFileSchemaDatasetLink[];
  pathKey: LogFileSchemaDatasetLinkPathKeyItem[];
  keyName?: string;
  handleUpdateLinkedDatasets: (pathKey: LogFileSchemaDatasetLinkPathKeyItem[], checked: boolean) => void;
  handleUpdateDatasetName: (updatedDataset: { path_key: LogFileSchemaDatasetLinkPathKeyItem[]; newName: string }) => void;
  level: number;
  className?: string;
  expandAll: boolean;
  setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
  editing?: boolean;
}

const JsonPreviewCollapsible: React.FC<JsonPreviewCollapsibleProps> = ({
  value,
  schemaDatasetLinks,
  handleUpdateLinkedDatasets,
  handleUpdateDatasetName,
  pathKey,
  keyName,
  level,
  className,
  expandAll,
  setExpandAll,
  editing = false,
}) => {
  const [expanded, setExpanded] = useState(true);
  const valueType: "array" | "object" | "primitive" = useMemo(() => {
    if (Array.isArray(value)) return "array";
    if (typeof value === "object") return "object";
    return "primitive";
  }, [value]);

  const checked = useMemo(() => {
    if (valueType === "primitive") {
      return (
        schemaDatasetLinks.find((datasetLink: LogFileSchemaDatasetLink) => JSON.stringify(datasetLink.path_key) === JSON.stringify(pathKey))
          ?.is_active ?? false
      );
    }
    const subPathKeys = findSubPathKeys(pathKey, value);
    if (
      subPathKeys.every(
        (subPathKey) =>
          schemaDatasetLinks.find(
            (datasetLink: LogFileSchemaDatasetLink) => JSON.stringify(datasetLink.path_key) === JSON.stringify(subPathKey),
          )?.is_active ?? false,
      )
    )
      return true;
    return false;
  }, [schemaDatasetLinks, pathKey]);

  useEffect(() => {
    if (expandAll) setExpanded(expandAll);
  }, [expandAll]);

  useEffect(() => {
    if (valueType !== "primitive" && checked) setExpanded(true);
  }, [checked]);

  return (
    <div className={cn("border-serial-palette-100 flex w-full flex-col border-t", className)}>
      <JsonPreviewRow
        pathKey={pathKey}
        keyName={keyName}
        value={value}
        checked={checked}
        onCheckedChange={(checked: boolean) => {
          handleUpdateLinkedDatasets(pathKey, checked);
        }}
        datasetLink={schemaDatasetLinks.find(
          (datasetLink: LogFileSchemaDatasetLink) => JSON.stringify(datasetLink.path_key) === JSON.stringify(pathKey),
        )}
        handleUpdateDatasetName={handleUpdateDatasetName}
        level={level}
        expandable={valueType !== "primitive"}
        expanded={expanded}
        setExpanded={(expanded) => {
          setExpanded(expanded);
          if (!expanded) setExpandAll(false);
        }}
        editing={editing}
      />
      {expanded &&
        valueType === "object" &&
        Object.entries(value ?? {}).map(([key, value]) => (
          <JsonPreviewCollapsible
            key={key}
            value={value ?? ""}
            schemaDatasetLinks={schemaDatasetLinks}
            handleUpdateLinkedDatasets={handleUpdateLinkedDatasets}
            handleUpdateDatasetName={handleUpdateDatasetName}
            pathKey={[...pathKey, { key: key, type: "KEY" }]}
            level={level + 1}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
            editing={editing}
          />
        ))}
      {expanded &&
        valueType === "array" &&
        (value as any[]).map((item, index) => (
          <JsonPreviewCollapsible
            key={index}
            value={item}
            schemaDatasetLinks={schemaDatasetLinks}
            handleUpdateLinkedDatasets={handleUpdateLinkedDatasets}
            handleUpdateDatasetName={handleUpdateDatasetName}
            pathKey={[...pathKey, { key: index, type: "INDEX" }]}
            level={level + 1}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
            editing={editing}
          />
        ))}
    </div>
  );
};

interface ProcessBuilderAutoParsingJsonPreviewProps {
  schemaDatasetLinks: LogFileSchemaDatasetLink[];
  handleUpdateLinkedDatasets: (pathKey: LogFileSchemaDatasetLinkPathKeyItem[], checked: boolean) => void;
  handleUpdateDatasetName: (updatedDataset: { path_key: LogFileSchemaDatasetLinkPathKeyItem[]; newName: string }) => void;
  selectedSchemaId: string;
  expandAll: boolean;
  setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
  editing: boolean;
}

const ProcessBuilderAutoParsingJsonPreview: React.FC<ProcessBuilderAutoParsingJsonPreviewProps> = ({
  schemaDatasetLinks,
  handleUpdateLinkedDatasets,
  handleUpdateDatasetName,
  selectedSchemaId,
  expandAll,
  setExpandAll,
  editing,
}) => {
  const { existingSchemas, draftSchemas } = useContext(ProcessBuilderContext);
  const selectedSchema = useMemo(() => {
    const latestRevision = Math.max(
      ...existingSchemas
        .flat()
        .filter((schema: LogFileSchema) => schema.id === selectedSchemaId)
        .map((schema: LogFileSchema) => schema.revision),
    );
    const latestSchemas = existingSchemas
      .flat()
      .filter((schema: LogFileSchema) => schema.id === selectedSchemaId && schema.revision === latestRevision);
    return draftSchemas.flat().find((schema: LogFileSchema) => schema.id === selectedSchemaId) ?? latestSchemas[0];
  }, [selectedSchemaId, existingSchemas, draftSchemas]);

  return (
    <div className="mb flex min-h-0 min-w-0 flex-grow flex-col overflow-auto bg-white">
      {selectedSchema && (
        <JsonPreviewCollapsible
          value={selectedSchema?.sample_file_data}
          schemaDatasetLinks={schemaDatasetLinks}
          pathKey={[]}
          handleUpdateDatasetName={handleUpdateDatasetName}
          keyName={selectedSchema.sample_file_name}
          handleUpdateLinkedDatasets={handleUpdateLinkedDatasets}
          level={0}
          className="border-t-0"
          expandAll={expandAll}
          setExpandAll={setExpandAll}
          editing={editing}
        />
      )}
    </div>
  );
};

export default ProcessBuilderAutoParsingJsonPreview;
