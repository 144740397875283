import { useContext, useEffect, useRef, useState } from "react";
import { ProcessWithReferences, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import FileInputBox from "@shared/components/FileInputBox";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import Banner2 from "@shared/components/Banner2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCheckCircle, faExclamationCircle, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Screenshot, createScreenshots, extractAudioFromVideo, loadFFmpeg } from "@shared/utils/ffmpeg";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { generateWorkInstructionsFromVideo, transcribeAudio } from "../connections/api";
import { GPTGeneratedStep } from "../types";
import { ParsedResponse } from "@shared/types/apiTypes";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import {
  AI_VIDEO_TO_WI_SCREENSHOT_INTERVAL as SCREENSHOT_INTERVAL,
  AI_VIDEO_TO_WI_SCREENSHOT_WIDTH as SCREENSHOT_WIDTH,
} from "../constants";
import { DataType } from "@shared/types/databaseEnums";
import { ToastContext } from "@shared/context/ToastProvider";
import { updateCompanyConfig } from "@shared/connections/supabaseCompany";
import { OpenaiVerboseTranscript, OpenaiVerboseTranscriptSegment } from "@shared/types/openai";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import WebcamVideoCapture from "@shared/components/WebcamRecordingBox";
import Loader from "@shared/components/primitives/Loader";

const DEBUG = false;

// Converts a transcript from OpenAI's verbose format to a custom multiline string very similar to VTT format
const transcriptToVttIsh = (transcript: OpenaiVerboseTranscriptSegment[]): ParsedResponse<string> => {
  try {
    let text: string = "";
    transcript.forEach((segment) => {
      const startTimestamp = Math.round(segment.start * 10) / 10;
      const endTimestamp = Math.round(segment.end * 10) / 10;
      text += `${startTimestamp} --> ${endTimestamp}\n`;
      text += `${segment.text}\n\n`;
    });
    return { data: text, error: undefined };
  } catch (error) {
    return { data: undefined, error: String(error) };
  }
};

enum ChecklistStatus {
  Loading = "LOADING",
  Success = "SUCCESS",
  Error = "ERROR",
}

const ProcessBuilderWorkInstructionImportVideoAi: React.FC = () => {
  const {
    handleAddNewWorkInstructionBlock,
    draftProcess,
    setDraftProcess,
    setImportSidebarOpen,
    setPulseWorkInstructions,
    importSidebarOpen,
    handleUploadFile,
    handleUpdateWorkInstructionBlocks,
    handleDeleteStep,
    handleAddNewField,
    handleAddNewStep,
  } = useContext(ProcessBuilderContext);

  const componentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fFmpegLoaded, setFFmpegLoaded] = useState<boolean>(false);
  const [error, setError] = useState<{ type: "success" | "error" | "warning"; message: string } | null>(null);
  const [draftProcessCopy, setDraftProcessCopy] = useState<ProcessWithReferences | undefined>(undefined);
  const [checklist, setChecklist] = useState<{ [label: string]: ChecklistStatus }>({});
  const [showHelpDocs, setShowHelpDocs] = useState<boolean>(false);
  const [videoDownloadUrl, setVideoDownloadUrl] = useState<string | null>(null);
  const [aiConversionComplete, setAiConversionComplete] = useState<boolean>(false);
  const [recordInApp, setRecordInApp] = useState<boolean>(false);

  const observe = useContext(ObservabilityContext);
  const company = useSelector((state: RootState) => state.db.company);
  const fFmpegRef = useRef<FFmpeg>(new FFmpeg());
  const { triggerConfirmation } = useContext(ToastContext);

  const handleEnableAi = async () => {
    const newCompanyConfig = {
      ...company.config,
      allow_ai: true,
    };
    const updateSuccessful = await updateCompanyConfig(newCompanyConfig);
    if (updateSuccessful) {
      setError({ type: "success", message: "Settings updated successfully. AI is now enabled for your company." });
    } else {
      setError({
        type: "error",
        message: "There was an error updating your settings. Please try again on the settings page or contact support.",
      });
    }
  };

  useEffect(() => {
    if (!importSidebarOpen) {
      setPulseWorkInstructions(false);
    } else {
      setDraftProcessCopy(JSON.parse(JSON.stringify(draftProcess)));
      loadFFmpeg(fFmpegRef.current).then(() => {
        setFFmpegLoaded(true);
      });
      if (company.config.allow_ai !== true) {
        triggerConfirmation(
          "Use of AI is disabled",
          <span>
            Use of AI is not enabled for your company.
            <br />
            Would you like to enable it?
          </span>,
          () => handleEnableAi(),
          () => setError({ type: "warning", message: "Use of AI is not enabled for your company. Go to Settings > Other to enable it" }),
          "Enable",
          "Cancel",
        );
      }
    }
    setChecklist({});
  }, [importSidebarOpen]);

  const addAiGeneratedSteps = (steps: GPTGeneratedStep[]) => {
    steps.forEach((step, stepIndex) => {
      handleAddNewStep(step.name, true);
      if (step.instruction_blocks) {
        step.instruction_blocks.forEach((workInstruction, workInstructionIndex) => {
          const blockId = handleAddNewWorkInstructionBlock(stepIndex, workInstructionIndex, workInstruction.type, false, workInstruction);
          if (!blockId) throw new Error("Block id is not defined");
          workInstruction.id = blockId;
        });
      }
      if (step.fields) {
        step.fields.forEach((field, fieldIndex) => {
          handleAddNewField(
            stepIndex,
            fieldIndex,
            field.type,
            field.group_name,
            field.prompt,
            field.is_optional,
            field.dataset_type === "NUMBER" ? DataType.ParametricQuantitative : undefined,
          );
        });
      }
    });
  };

  const uploadFilesFromScreenshots = async (steps: GPTGeneratedStep[], screenshots: Screenshot[]) => {
    steps.forEach(async (_step, stepIndex) => {
      steps[stepIndex].instruction_blocks.forEach(async (newBlock) => {
        if ((newBlock.type === WorkInstructionBlockType.Image || newBlock.type === WorkInstructionBlockType.File) && newBlock.file_name) {
          const fileToUpload = screenshots.find((screenshot) => screenshot.fileName === newBlock.file_name)?.file;
          if (fileToUpload && newBlock.id) {
            const fileId = await handleUploadFile(fileToUpload, newBlock.type);
            if (fileId) handleUpdateWorkInstructionBlocks(stepIndex, [newBlock.id], "file_id", fileId, false);
            setChecklist((currChecklist) => {
              return { ...currChecklist, [`Uploading ${newBlock.file_name}`]: fileId ? ChecklistStatus.Success : ChecklistStatus.Error };
            });
          } else {
            setChecklist((currChecklist) => {
              return { ...currChecklist, [`Uploading ${newBlock.file_name}`]: ChecklistStatus.Error };
            });
          }
        }
      });
    });
  };

  async function awaitPromiseAndUpdateChecklist<T>(
    task: () => Promise<ParsedResponse<T>> | ParsedResponse<T>,
    label: string,
    liveChecklist: { [label: string]: ChecklistStatus },
  ): Promise<T> {
    liveChecklist[label] = ChecklistStatus.Loading;
    setChecklist({ ...liveChecklist });
    const { data, error } = await task();
    if (DEBUG) console.log(label, data);
    if (error || !data) {
      liveChecklist[label] = ChecklistStatus.Error;
      setChecklist({ ...liveChecklist });
      throw new Error(`Error ${label}`);
    }
    liveChecklist[label] = ChecklistStatus.Success;
    setChecklist({ ...liveChecklist });
    return data;
  }

  const handleProcessVideoFile = async (files: File[]) => {
    try {
      // check if first file exists
      if (files.length === 0) {
        setError({ type: "error", message: "No files uploaded" });
        return;
      }
      // check if first file has a mime type that starts with video/
      if (!files?.[0]?.type?.startsWith("video/")) {
        setError({ type: "error", message: "File uploaded is not a video" });
        return;
      }
      if (company.config.allow_ai !== true) {
        setError({ type: "error", message: "Use of AI is not enabled for your company. Go to Settings > Other to enable it" });
        return;
      }
      setIsLoading(true);
      observe.track("Generate AI Work Instructions");
      setDraftProcessCopy(JSON.parse(JSON.stringify(draftProcess)));

      const newVideoDownloadUrl = URL.createObjectURL(files[0]);
      setVideoDownloadUrl(newVideoDownloadUrl);

      const liveChecklist: { [label: string]: ChecklistStatus } = {};

      const audioFile = await awaitPromiseAndUpdateChecklist<File>(
        () => extractAudioFromVideo(files[0], fFmpegRef.current),
        "Extracting Audio",
        liveChecklist,
      );
      const screenshots = await awaitPromiseAndUpdateChecklist<Screenshot[]>(
        () => createScreenshots(files[0], SCREENSHOT_INTERVAL, fFmpegRef.current, SCREENSHOT_WIDTH),
        "Analyzing Video",
        liveChecklist,
      );
      const transcript = await awaitPromiseAndUpdateChecklist<OpenaiVerboseTranscript>(
        () => transcribeAudio(audioFile),
        "Transcribing Audio",
        liveChecklist,
      );
      const vttIshTranscript = await awaitPromiseAndUpdateChecklist<string>(
        () => transcriptToVttIsh(transcript.segments),
        "Formatting Transcript",
        liveChecklist,
      );
      let stepsData = await awaitPromiseAndUpdateChecklist<any>(
        () =>
          generateWorkInstructionsFromVideo(
            vttIshTranscript,
            screenshots.map((s) => {
              return { file_name: s.fileName, base64: s.base64, time: s.time };
            }),
          ),
        "Generating Instructions",
        liveChecklist,
      );

      // Auto retry if error is due to safety content policy violation with the safety filter on
      if (
        stepsData.error &&
        (stepsData.error.message.toLowerCase().includes("safety") || stepsData.error.code === "content_policy_violation")
      ) {
        setChecklist((currChecklist) => {
          return { ...currChecklist, [`Generating Instructions`]: ChecklistStatus.Error };
        });
        stepsData = await awaitPromiseAndUpdateChecklist<any>(
          () =>
            generateWorkInstructionsFromVideo(
              vttIshTranscript,
              screenshots.map((s) => {
                return { file_name: s.fileName, base64: s.base64, time: s.time };
              }),
              true,
            ),
          "Generating Instructions (Retry)",
          liveChecklist,
        );
      }

      // check if stepsData.steps is an array and stepsData.error is undefined
      if (!Array.isArray(stepsData.steps) || stepsData.error) {
        const errorMessage = stepsData.error?.message ? stepsData.error.message : "Please try again or contact support";
        setIsLoading(false);
        setError({ type: "error", message: `There was an error generating the work instructions. ${errorMessage}` });
        setChecklist((currChecklist) => {
          return { ...currChecklist, [`Generating Instructions`]: ChecklistStatus.Error };
        });
        console.error("Error: ", stepsData);
        return;
      }

      draftProcess?.process_steps.forEach(() => {
        handleDeleteStep(0);
      });

      addAiGeneratedSteps(stepsData.steps);
      await uploadFilesFromScreenshots(stepsData.steps, screenshots);
      setError(null);
      setIsLoading(false);
      setAiConversionComplete(true);
    } catch (error) {
      console.error("Error: ", error);
      setError({ type: "error", message: "There was an error generating the work instructions. Please try again or contact support" });
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setImportSidebarOpen(false);
    setPulseWorkInstructions(false);
    setAiConversionComplete(false);
    setDraftProcess(draftProcessCopy);
    setRecordInApp(false);
    setChecklist({});
    setError(null);
  };

  const handleReset = () => {
    setVideoDownloadUrl(null);
    setAiConversionComplete(false);
    setDraftProcess(draftProcessCopy);
    setChecklist({});
    setError(null);
  };

  const handleApply = () => {
    setImportSidebarOpen(false);
    setPulseWorkInstructions(false);
    setDraftProcessCopy(undefined);
    setChecklist({});
    setError(null);
  };

  return (
    <div ref={componentRef} className="flex h-full w-full flex-col justify-between">
      <div ref={componentRef} className="flex min-h-0 w-full flex-grow flex-col">
        <div className="bg-serial-palette-100 relative flex flex-col gap-y-1 border-b px-4 pt-4">
          <h3 className="text-xl font-semibold">Video to Work Instructions</h3>
          <div onClick={() => setShowHelpDocs(!showHelpDocs)} className="flex cursor-pointer gap-2 pb-4 text-sm">
            <FontAwesomeIcon className="pt-1" icon={showHelpDocs ? faCaretDown : faCaretRight} />
            {showHelpDocs ? (
              <div className="flex flex-col">
                Record a video of your process, making sure to clearly narrate each step. It also helps if the video is well lit, the camera
                is steady and the operator pauses for a few seconds after each major change so our system can get a clear picture of each
                step. <br /> <br /> When you're done recording, drop the video in the upload box below and watch the magic happen!
              </div>
            ) : (
              <p className="text-md">Help</p>
            )}
          </div>
        </div>
        {error && (
          <div className="p-2">
            <Banner2 className="w-full" type={error.type} open={error !== null} setOpen={() => setError(null)}>
              {error.message}
            </Banner2>
          </div>
        )}
        {Object.keys(checklist).length !== 0 && (
          <div className="flex flex-col gap-y-0.5 overflow-auto p-3">
            <div className="text-md flex font-bold">Generating Work Instructions</div>
            <div className="flex pb-1 text-sm italic">(This may take a few minutes)</div>
            {Object.entries(checklist).map(([label, status], index) => {
              return (
                <div key={index} className="flex gap-x-1.5">
                  {status === ChecklistStatus.Success && <FontAwesomeIcon size="sm" icon={faCheckCircle} className="mt-1 text-green-500" />}
                  {status === ChecklistStatus.Error && (
                    <FontAwesomeIcon size="sm" icon={faExclamationCircle} className="mt-1 text-red-500" />
                  )}
                  {status === ChecklistStatus.Loading && (
                    <div className="flex items-center">
                      <Loader size="xs" />
                    </div>
                  )}
                  <div className="text-sm">{label}</div>
                </div>
              );
            })}
          </div>
        )}
        {!fFmpegLoaded && (
          <div className="flex h-full w-full items-center justify-center">
            <Loader size="lg" />
          </div>
        )}
        {!isLoading && fFmpegLoaded && !videoDownloadUrl && !recordInApp && (
          <FileInputBox
            handleUploadFiles={handleProcessVideoFile}
            fileInfo={null}
            className={`!rounded-lg bg-white py-4`}
            prompt="Drag and drop a video here"
            isLoading={isLoading}
          />
        )}
        {!isLoading && fFmpegLoaded && !videoDownloadUrl && recordInApp && (
          <div className="flex h-full items-end px-3">
            <WebcamVideoCapture
              handleVideoCaptured={(videoFile) => {
                videoFile && handleProcessVideoFile([videoFile]);
              }}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-3 p-3">
        {videoDownloadUrl && (
          <div className="relative flex w-full overflow-hidden rounded">
            <video className="w-full" controls src={videoDownloadUrl} />
            {!isLoading && (
              <button
                tabIndex={-1}
                className="btn-sm serial-btn-danger absolute right-1.5 top-1.5 gap-1 text-xs"
                onClick={() => handleReset()}
              >
                <FontAwesomeIcon icon={faRefresh} />
                <span>Reset</span>
              </button>
            )}
          </div>
        )}
        {!isLoading && (
          <div className="flex w-full gap-x-1.5">
            <button className="btn-sm serial-btn-light w-1/2" onClick={() => handleCancel()}>
              Cancel
            </button>
            {aiConversionComplete && (
              <button className="btn-sm serial-btn-dark w-1/2" onClick={() => handleApply()}>
                Apply
              </button>
            )}
            {!aiConversionComplete && (
              <button className="btn-sm serial-btn-dark w-1/2" onClick={() => setRecordInApp(!recordInApp)}>
                {recordInApp ? "Upload Video" : "Record with Camera"}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessBuilderWorkInstructionImportVideoAi;
