import React, { useContext, useMemo, useState } from "react";
import { ComponentContext } from "../ComponentProvider";
import { ComponentPageModal, ComponentPageSidebar } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import TypeTag from "@shared/components/TypeTag";
import DropdownFilter from "@shared/components/dropdowns/DropdownFilter";
import ComponentInstanceTable from "./ComponentInstanceTable";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { Loader } from "@shared/components/Loader";
import ComponentProcessSequenceTable from "./ComponentProcesSequenceTable";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { ProcessType, UserRole } from "@shared/types/databaseEnums";

const ComponentSidebar: React.FC = () => {
  const { activeSidebar, setActiveSidebar, focusedComponent, setActiveModal, handleSetFocusedProcess, handleSetFocusedComponent } =
    useContext(ComponentContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showArchivedComponentInstances, setShowArchivedComponentInstances] = useState<boolean>(false);
  const [showActiveComponentInstances, setShowActiveComponentInstances] = useState<boolean>(true);

  const currentUser = useCurrentUser();

  const componentInstances = useSelector((state: RootState) => state.db.componentInstances).filter((componentInstance) => {
    if (showArchivedComponentInstances && showActiveComponentInstances) {
      return true; // Show all instances
    } else if (showArchivedComponentInstances) {
      return componentInstance.is_archived === true;
    } else if (showActiveComponentInstances) {
      return componentInstance.is_archived === false;
    }
    return false;
  });

  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);

  const { componentInstanceCount, processCount } = useMemo(() => {
    const componentInstancesCt = componentInstances.filter(
      (componentInstance) => componentInstance.component_id === focusedComponent?.id
    ).length;

    const processCt = componentProcessLinks.filter(
      (componentProcessLink) =>
        componentProcessLink.component_id === focusedComponent?.id && 
        componentProcessLink.process?.type === ProcessType.Production
    ).length;

    return { componentInstanceCount: componentInstancesCt, processCount: processCt };
  }, [componentInstances, focusedComponent, showArchivedComponentInstances, showActiveComponentInstances]);


  const navigation = useMemo(() => {
    return [
      {
        label: "Processes",
        value: ComponentPageSidebar.ProcessSequence,
        count: processCount ?? 0,
      },
      {
        label: focusedComponent?.component_type === "LOT" ? "Lot Codes" : "Serial Numbers",
        value: ComponentPageSidebar.SnList,
        count: componentInstanceCount ?? 0,
      },
    ];
  }, [focusedComponent, componentInstances, processCount]);

  const handleAddProcess = () => {
    if (!focusedComponent) return;
    handleSetFocusedProcess(null);
    handleSetFocusedComponent(focusedComponent.id);
    setActiveModal(ComponentPageModal.ProcessBuilder);
  };

  const filterOptions = useMemo(() => {
    return {
      Active: showActiveComponentInstances,
      Archived: showArchivedComponentInstances,
    };
  }, [showArchivedComponentInstances, showActiveComponentInstances]);

  const toggleFilterOption = (option: string) => {
    switch (option) {
      case "Archived":
        setShowArchivedComponentInstances(!showArchivedComponentInstances);
        break;
      case "Active":
        setShowActiveComponentInstances(!showActiveComponentInstances);
        break;
      default:
        break;
    }
  };


  return (
    <>
      <div className={`transition-all duration-200 ${activeSidebar !== null ? "w-[380px] 2xl:w-1/3" : "w-[0px]"}`} />
      <div
        className={`absolute bottom-0 right-0 flex h-full w-[380px] transition-all duration-200 2xl:w-1/3 ${activeSidebar !== null ? "translate-x-0" : "translate-x-full"}`}
      >
        <div className="bg-serial-palette-50 relative flex min-h-0 min-w-0 flex-grow flex-col border-l shadow-lg">
          {focusedComponent && (
            <div className="group relative flex items-center gap-x-3 border-b bg-white p-3">
              <div className="rounded-md border">
                <ComponentAvatar component={focusedComponent} size="md" />
              </div>
              <div className="flex flex-col">
                <div className="flex items-end gap-2">
                  <span className="text-serial-palette-800 text-xl font-bold">{focusedComponent?.name}</span>
                </div>
                <TypeTag type={focusedComponent?.component_type} className="h-5 w-fit text-xs" />
              </div>
              <div className="btn absolute right-1 top-1 cursor-pointer group-hover:flex" onClick={() => setActiveSidebar(null)}>
                <FontAwesomeIcon icon={faTimes} size="xl" className="text-serial-palette-500" />
              </div>
            </div>
          )}
          {focusedComponent && (
            <div className="relative m-3 flex min-h-0 min-w-0 flex-grow flex-col overflow-hidden rounded-md border bg-white">
              <div className="flex h-14 w-full items-center justify-between">
                <div className="scrollbar-hide flex w-full overflow-x-auto pt-4">
                  {navigation.map((option, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => setActiveSidebar(option.value)}
                        className={`text-serial-palette-800 flex max-w-[120px] gap-2 pb-[13px] md:max-w-none ${index === 0 ? "pl-5" : "pl-3"} border-b-[3.5px] pr-3 ${activeSidebar === option.value ? "border-serial-palette-600 font-semibold" : "hover:border-serial-palette-400 border-white font-medium"} cursor-pointer`}
                      >
                        <span className={`truncate ${activeSidebar !== option.value && "opacity-60"}`}>{option.label}</span>
                        <div className="text-serial-palette-400 font-medium">{option.count} </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex items-center gap-1.5 px-3">
                  {isLoading && <Loader styleOverride="w-[20px] h-[20px]" />}
                  {activeSidebar === ComponentPageSidebar.ProcessSequence && currentUser?.role === UserRole.Admin && (
                    <button className="btn serial-btn-dark flex h-8 gap-2 whitespace-nowrap" onClick={() => handleAddProcess()}>
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="hidden 2xl:block">Process</span>
                    </button>
                  )}
                  {activeSidebar === ComponentPageSidebar.SnList && (
                    <DropdownFilter align="right" classOverride="h-8" filterOptions={filterOptions} toggleFilterOption={toggleFilterOption} />
                  )}
                </div>
              </div>
              {focusedComponent && activeSidebar === ComponentPageSidebar.SnList && (
                <ComponentInstanceTable component={focusedComponent} statusFilter={null} setIsLoading={setIsLoading} showArchivedComponentInstances={showArchivedComponentInstances} showActiveComponentInstances={showActiveComponentInstances}/>
              )}
              {focusedComponent && activeSidebar === ComponentPageSidebar.ProcessSequence && (
                <ComponentProcessSequenceTable component={focusedComponent} />
              )}
            </div>
          )}
          {!focusedComponent && (
            <div className="flex h-full w-full items-center justify-center">
              <span className="text-serial-palette-400 italic">Select a component to view details</span>
            </div>
          )}
          {activeSidebar === null && (
            <button
              className="btn serial-btn-light absolute -left-[105px] top-1/2 flex h-8 w-44 -rotate-90 gap-x-2 rounded-b-none"
              onClick={() => setActiveSidebar(ComponentPageSidebar.SnList)}
            >
              <span>Component Details</span>
              <FontAwesomeIcon icon={faList} size="xs" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ComponentSidebar;
