import React, { useRef } from "react";
import { TableBlock } from "@shared/types/databaseTypes";
import { ProductionWorkInstructionBlockContentProps } from "../../types";

const ProductionWorkInstructionBlockTable: React.FC<ProductionWorkInstructionBlockContentProps<TableBlock>> = ({ block }) => {
  const tableParentRef = useRef<HTMLDivElement>(null);
  const calculateColumnWidth = (colIndex: number, offsetWidth: number): string => {
    if (block.content.column_widths?.[colIndex] === "auto") {
      return "auto";
    } else if (block.content.column_widths?.[colIndex]) {
      return `${(parseFloat(block.content.column_widths?.[colIndex] || "10")  / 100) * (offsetWidth || 1)}px`;
    }
    return "auto";
  };

  return (
    <div className="group relative w-full">
      <div className="border-serial-palette-400 flex w-full overflow-clip rounded-md border" ref={tableParentRef}>
        <table className="w-full table-fixed">
          <colgroup>
            {block.content.rows[0].map((_, colIndex) => (
              <col
                key={colIndex}
                style={{ width: calculateColumnWidth(colIndex, tableParentRef.current?.offsetWidth || 1) }}
              />
            ))}
          </colgroup>
          <tbody>
            {block.content.rows.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`border-serial-palette-400 border-b last:border-b-0 ${rowIndex === 0 && block.content.header_row && "bg-serial-palette-100 font-bold"}`}
              >
                {row.map((cell, colIndex) => (
                  <td
                    key={colIndex}
                    className={`border-serial-palette-400 relative border-r px-2.5 py-1.5 last:border-r-0 ${colIndex === 0 && block.content.header_column && "bg-serial-palette-100 font-bold"}`}
                  >
                    <div
                      className="break-words"
                      style={{ minHeight: "24px" }}
                    >
                      {cell}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductionWorkInstructionBlockTable;
