import { ProcessType } from "@shared/types/databaseEnums";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { supabaseSingleOverride } from "@shared/connections/supabaseDataModifiers";
import { ProcessEntry, UniqueIdentifier } from "@shared/types/databaseTypes";

// ------------------- READ FUNCTIONS ------------------- //

export const fetchLatestProcessEntries = async (componentInstances: UniqueIdentifier[]) => {
  const supabase = getSupabase();

  // Attach the most recent process entry to each unique identifier
  const processEntries = await Promise.all(
    componentInstances.map(async (componentInstance) => {
      const { data: processEntry } = supabaseSingleOverride(
        await supabase
          .from("process_entries")
          .select("*, process:processes!inner(*)") // use !inner to make the .eq and .neq filters work on the joined tables
          .eq("unique_identifier_id", componentInstance.id)
          .neq("process.type", ProcessType.Instantiation)
          .neq("process.type", ProcessType.AssignWorkOrder)
          .order("timestamp", { ascending: false })
          .limit(1),
      );

      return { ...processEntry, unique_identifier: componentInstance } as ProcessEntry;
    }),
  );

  return processEntries;
};
