import {
  Dataset,
  Field,
  FieldType,
  FilterCondition,
  LogFileSchemaDatasetLinkPathKeyItem,
  ProcessStepWithReferences,
  ProcessWithReferences,
  WorkInstructionBlock,
  WorkInstructionBlockType,
} from "@shared/types/databaseTypes";
import { OpenaiCompletionRole } from "@shared/types/openai";

export interface ProcessBuilderFieldContentsProps<T> {
  field: T;
  stepIndex: number;
}

export interface ProcessBuilderWorkInstructionBlockContentProps<T> {
  block: T;
  stepIndex: number;
  blockIndex: number;
  placeholderInputRef?: React.RefObject<HTMLDivElement>;
}

export enum ProcessBuilderDraggableType {
  WorkInstructionBlock = "WORK_INSTRUCTION_BLOCK",
  Field = "FIELD",
  FieldGroup = "FIELD_GROUP",
  Step = "STEP",
}

// Blocks and fields are removed from their original positions by overwriting the the originals with null
// this will preserve the indexes of the remaining blocks so that we can insert the dragged block at the correct position
// null values will be filtered out by the cleanupSteps function, hence the reason for this interface definition
type NullableArray<T> = (T | null)[];

export interface ProcessStepWithNullableArrays
  extends Omit<ProcessStepWithReferences, "work_instruction_blocks" | "fields" | "filter_conditions"> {
  work_instruction_blocks: NullableArray<WorkInstructionBlock>;
  fields: NullableArray<Field>;
  filter_conditions: NullableArray<FilterCondition>;
}

// Create sub type of ProcessStepWithReferences to allow for the removal of the created_at property at the time of save
type TableWithOptionalCreatedAt<T> = Omit<T, "created_at"> & { created_at?: string | null };

export interface ProcessStepWithOptionalCreatedAt
  extends Omit<TableWithOptionalCreatedAt<ProcessStepWithReferences>, "work_instruction_blocks" | "fields" | "filter_conditions"> {
  work_instruction_blocks: TableWithOptionalCreatedAt<WorkInstructionBlock>[];
  fields: TableWithOptionalCreatedAt<Field>[];
  filter_conditions: TableWithOptionalCreatedAt<FilterCondition>[];
}

export interface ProcessWithOptionalCreatedAt extends Omit<TableWithOptionalCreatedAt<ProcessWithReferences>, "process_steps"> {
  process_steps: ProcessStepWithOptionalCreatedAt[];
}

export enum ImportType {
  Markdown = "MARKDOWN",
  Video = "VIDEO",
}

export interface GPTGeneratedWorkInstructionBlock {
  id?: string; // a hacky way to generate a unique id for each block so that files can be uploaded separately to the right block
  type: WorkInstructionBlockType;
  text?: string;
  level?: 1 | 2 | 3;
  rows?: string[][];
  header_row?: boolean;
  header_column?: boolean;
  icon?: "INFO" | "WARNING" | "DANGER";
  file_name?: string;
  height?: number;
  caption?: string;
  items?: string[];
  listType?: "BULLETED" | "NUMBERED";
}

export interface GPTGeneratedField {
  id?: string;
  type: FieldType;
  prompt: string;
  group_name: string;
  is_optional: boolean;
  dataset_type?: "TEXT" | "NUMBER";
}

export interface GPTGeneratedStep {
  name: string;
  instruction_blocks: GPTGeneratedWorkInstructionBlock[];
  fields: GPTGeneratedField[];
}

export interface SerialProgrammingAssistantResponse {
  openai_response: any;
  error?: string;
  code?: string;
  message?: string;
}

export interface SerialProgrammingAssistantChatMessage {
  role: OpenaiCompletionRole;
  content: string;
  timestamp: string;
}

export interface DatasetWithPathKey extends Dataset {
  pathKey: LogFileSchemaDatasetLinkPathKeyItem[];
}
