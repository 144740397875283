import ComponentAvatar from "@shared/components/primitives/ComponentAvatar";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

const FrontendPrimitivesComponentAvatars = () => {
  const components = useSelector((state: RootState) => state.db.components);
  return (
    <FrontendPrimitivesCard title="Component Avatars">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Basic Component Avatar</div>
        <div className="flex gap-2">
          <ComponentAvatar component={components[0]} size="md" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Component Avatar Sizes</div>
        <div className="flex gap-2">
          <ComponentAvatar component={components[0]} size="xs" />
          <ComponentAvatar component={components[0]} size="sm" />
          <ComponentAvatar component={components[0]} size="md" />
          <ComponentAvatar component={components[0]} size="lg" />
          <ComponentAvatar component={components[0]} size="xl" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Hyperlinked Component Avatar</div>
        <div className="flex gap-2">
          <ComponentAvatar component={components[0]} size="md" hyperlinked />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Component Avatar with Tooltip or Custom Tooltip</div>
        <div className="flex gap-2">
          <ComponentAvatar component={components[0]} size="md" tooltip />
          <ComponentAvatar component={components[0]} size="md" tooltip="Custom Tooltip!" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Component Avatar with Component Name</div>
        <div className="flex gap-2">
          <ComponentAvatar.Root component={components[0]} size="sm">
            <ComponentAvatar.Image />
            <ComponentAvatar.Name />
          </ComponentAvatar.Root>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesComponentAvatars;
