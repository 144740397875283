import { useMemo } from "react";
import { CollectionType, ProcessStepWithReferences } from "@shared/types/databaseTypes";
import { PartNumber } from "@shared/types/databaseTypes";
import { FilterTestData } from "@shared/components/filters/types";
import { testFilter } from "@shared/components/filters/helpers";

interface UseStepsTruthTableProps {
  partNumbers: PartNumber[];
  steps: ProcessStepWithReferences[];
}

export interface StepsTruthTable {
  [partNumberId: string]: boolean[];
}

const useStepsTruthTable = ({ partNumbers, steps }: UseStepsTruthTableProps): StepsTruthTable => {
  const stepsTruthTable = useMemo(() => {
    // map part number metadata to key value pairs where key is dataset id and value is datapoint value
    const keyValueMetadataByPartNumber: { [partNumberId: string]: FilterTestData } = {};
    partNumbers.forEach((partNumber) => {
      const filterTestData = Object.entries(partNumber.metadata).map(([datasetId, datapoint]) => {
        return {
          [datasetId]: datapoint?.value,
        };
      });
      keyValueMetadataByPartNumber[partNumber.id] = filterTestData;
    });

    const allFilterConidtions = steps.map((step) => step.filter_conditions).flat();

    // generate a filter test data object for each part number that includes test data for any filter condition
    const allFiltersTestDataByPartNumber: { [partNumberId: string]: FilterTestData } = {};
    partNumbers.forEach((partNumber) => {
      const filterTestData: FilterTestData = {};
      allFilterConidtions.forEach((filterCondition) => {
        switch (filterCondition.type) {
          case CollectionType.PartNumber:
            filterTestData[filterCondition.id] = partNumber.id;
            break;
          case CollectionType.ParametricQualitative:
            filterTestData[filterCondition.id] = partNumber.metadata[filterCondition.dataset_id as string]?.value;
            break;
          case CollectionType.ParametricQuantitative:
            filterTestData[filterCondition.id] = partNumber.metadata[filterCondition.dataset_id as string]?.value;
            break;
          default:
            break;
        }
      });
      allFiltersTestDataByPartNumber[partNumber.id] = filterTestData;
    });

    // map filter test data to truth table
    const stepsTruthTable: StepsTruthTable = {};
    Object.entries(allFiltersTestDataByPartNumber).forEach(([partNumberId, testData]) => {
      const stepVisibility: boolean[] = steps.map((step) => {
        return testFilter(step.filter_conditions, step.filter_join_operator, testData) && !step.is_hidden;
      });
      stepsTruthTable[partNumberId] = stepVisibility;
    });

    return stepsTruthTable;
  }, [partNumbers, steps]);

  return stepsTruthTable;
};

export default useStepsTruthTable;
