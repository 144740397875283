import Banner from "@shared/components/primitives/Banner";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import { useState } from "react";

const FrontendPrimitivesBanners = () => {
  const [bannerDismissed, setBannerDismissed] = useState(false);

  return (
    <FrontendPrimitivesCard title="Banners">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Variants</div>
        <div className="flex flex-col gap-2">
          <Banner variant="info">Info Banner</Banner>
          <Banner variant="warning">Warning Banner</Banner>
          <Banner variant="error">Error Banner</Banner>
          <Banner variant="success">Success Banner</Banner>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">With Dismiss Button</div>
        <div className="flex flex-col gap-2">
          {!bannerDismissed && (
            <Banner.Root variant="warning">
              <Banner.Text>Click the x to dismiss this banner</Banner.Text>
              <Banner.Dismiss handleDismiss={() => setBannerDismissed(true)} />
            </Banner.Root>
          )}
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesBanners;
