import DropdownMenu from "@shared/components/primitives/DropdownMenu";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faCodeBranch, faGear, faPlus, faPlusCircle, faSignOut, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard, faEnvelope, faKeyboard, faMessage, faQuestionCircle, faUser } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";

const FrontendPrimitivesDropdowns = () => {
  const [showStatusBar, setShowStatusBar] = useState<boolean>(true);
  const [showActivityBar, setShowActivityBar] = useState<boolean>(false);
  const [showPanel, setShowPanel] = useState<boolean>(false);

  return (
    <FrontendPrimitivesCard title="Dropdown">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Complex Dropdown Menu</div>
        <div className="flex flex-wrap items-start gap-2">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <Button>Complex Dropdown</Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className="w-56">
              <DropdownMenu.Label>My Account</DropdownMenu.Label>
              <DropdownMenu.Separator />
              <DropdownMenu.Group>
                <DropdownMenu.Item>
                  <div className="flex w-6 justify-center">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <span>Profile</span>
                  <DropdownMenu.Shortcut>⇧⌘P</DropdownMenu.Shortcut>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <div className="flex w-6 justify-center">
                    <FontAwesomeIcon icon={faCreditCard} />
                  </div>
                  <span>Billing</span>
                  <DropdownMenu.Shortcut>⌘B</DropdownMenu.Shortcut>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <div className="flex w-6 justify-center">
                    <FontAwesomeIcon icon={faGear} />
                  </div>
                  <span>Settings</span>
                  <DropdownMenu.Shortcut>⌘S</DropdownMenu.Shortcut>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <div className="flex w-6 justify-center">
                    <FontAwesomeIcon icon={faKeyboard} />
                  </div>
                  <span>Keyboard shortcuts</span>
                  <DropdownMenu.Shortcut>⌘K</DropdownMenu.Shortcut>
                </DropdownMenu.Item>
              </DropdownMenu.Group>
              <DropdownMenu.Separator />
              <DropdownMenu.Group>
                <DropdownMenu.Item>
                  <div className="flex w-6 justify-center">
                    <FontAwesomeIcon icon={faUsers} />
                  </div>
                  <span>Team</span>
                </DropdownMenu.Item>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>
                    <div className="flex w-6 justify-center">
                      <FontAwesomeIcon icon={faUserPlus} />
                    </div>
                    <span>Invite users</span>
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.SubContent>
                      <DropdownMenu.Item>
                        <div className="flex w-6 justify-center">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                        <span>Email</span>
                      </DropdownMenu.Item>
                      <DropdownMenu.Item>
                        <div className="flex w-6 justify-center">
                          <FontAwesomeIcon icon={faMessage} />
                        </div>
                        <span>Message</span>
                      </DropdownMenu.Item>
                      <DropdownMenu.Separator />
                      <DropdownMenu.Item>
                        <div className="flex w-6 justify-center">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </div>
                        <span>More...</span>
                      </DropdownMenu.Item>
                    </DropdownMenu.SubContent>
                  </DropdownMenu.Portal>
                </DropdownMenu.Sub>
                <DropdownMenu.Item>
                  <div className="flex w-6 justify-center">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <span>New Team</span>
                  <DropdownMenu.Shortcut>⌘+T</DropdownMenu.Shortcut>
                </DropdownMenu.Item>
              </DropdownMenu.Group>
              <DropdownMenu.Separator />
              <DropdownMenu.Item>
                <div className="flex w-6 justify-center">
                  <FontAwesomeIcon icon={faCodeBranch} />
                </div>
                <span>GitHub</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item>
                <div className="flex w-6 justify-center">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </div>
                <span>Support</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item disabled>
                <div className="flex w-6 justify-center">
                  <FontAwesomeIcon icon={faCode} />
                </div>
                <span>API</span>
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Item>
                <div className="flex w-6 justify-center">
                  <FontAwesomeIcon icon={faSignOut} />
                </div>
                <span>Log out</span>
                <DropdownMenu.Shortcut>⇧⌘Q</DropdownMenu.Shortcut>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Checkboxes</div>
        <div className="flex flex-wrap items-start gap-2">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <Button>Checkbox Dropdown</Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className="w-56" align="start">
              <DropdownMenu.Label>Appearance</DropdownMenu.Label>
              <DropdownMenu.Separator />
              <DropdownMenu.CheckboxItem checked={showStatusBar} onCheckedChange={setShowStatusBar}>
                Status Bar
              </DropdownMenu.CheckboxItem>
              <DropdownMenu.CheckboxItem checked={showActivityBar} onCheckedChange={setShowActivityBar} disabled>
                Activity Bar
              </DropdownMenu.CheckboxItem>
              <DropdownMenu.CheckboxItem checked={showPanel} onCheckedChange={setShowPanel}>
                Panel
              </DropdownMenu.CheckboxItem>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesDropdowns;
