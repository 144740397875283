export interface OpenaiVerboseTranscriptSegment {
  id: number;
  seek: number;
  start: number;
  end: number;
  text: string;
  tokens: number[];
  temperature: number;
  avg_logprob: number;
  compression_ratio: number;
  no_speech_prob: number;
}

export interface OpenaiVerboseTranscript {
  segments: OpenaiVerboseTranscriptSegment[];
  duration: number;
  language: string;
  task: string;
  text: string;
}

export enum OpenaiCompletionRole {
  User = "user",
  Assistant = "assistant",
  System = "system",
}