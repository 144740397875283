import React, { useState, useRef, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { renderableExtensions } from "@shared/constants/fileHandling";
import { DataType } from "@shared/types/databaseEnums";
import { ToastType } from "./Toast";
import { Loader } from "./Loader";
import { ToastContext } from "@shared/context/ToastProvider";

interface FileUploadCellProps {
  allowPaste?: boolean;
  dataType: DataType;
  fileUrl: string | undefined;
  fileName: string | undefined;
  setFile: (url: string, fileName: string) => Promise<void>;
  disabled?: boolean;
}

const validateFile = (file: File, dataType: DataType) => {
  // --- CHECK #1 --- : If it uses the upload method, check if the file type is an image
  if (dataType === DataType.Image && !["jpeg", "jpg", "png", "tiff", "tif", "heic"].includes(file.type.split("/")[1]?.toLowerCase())) {
    return { isValid: false, type: "error", message: "Incorrect file format. Must be .jpeg, .png, .heic, .tif or .tiff" };
  }
  // --- CHECK #2 --- : Check if the file size is too large. Supabase's storage upload limit is 50MB at the time of implementation. We can increase this if needed.
  if (file.size >= 52428800) {
    // 50MB
    return {
      isValid: false,
      type: "error",
      message:
        "File size too large. Serial supports files up to 50MB. Please convert your file to a smaller size and try again. Contact Serial support if this file is mission critical.",
    };
  }
  return { isValid: true, type: "success", message: "" };
};

const FileUploadCell: React.FC<FileUploadCellProps> = ({ allowPaste, dataType, fileUrl, setFile, fileName, disabled }) => {
  const [dragOver, setDragOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const browseRef = useRef<HTMLInputElement>(null);
  const { triggerToast } = useContext(ToastContext);

  const handleFileUpload = async (file: File) => {
    setIsLoading(true);
    const fileValidation = validateFile(file, dataType);
    if (!fileValidation.isValid) {
      triggerToast(ToastType.Error, "File Upload Error", fileValidation.message);
      setIsLoading(false);
    } else {
      const localStorageUrl = URL.createObjectURL(file);
      await setFile(localStorageUrl, file.name);
      setIsLoading(false);
    }
  };

  // Creates a ghost href element to download the file and then clicks it to trigger a download
  const handleDownload = () => {
    if (!fileUrl || !fileName) return;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.click();
    const extension = fileName.split(".").pop() ?? "";
    if (renderableExtensions.includes(extension.toLowerCase())) {
      window.open(fileUrl, "_blank");
    }
  };

  // Event handler for dropping a file into the file upload cell
  const onDrop = (e: any) => {
    e.preventDefault();
    setDragOver(false);
    handleFileUpload(e.dataTransfer.files[0]);
  };

  // Event handler for dragging a file over the file upload cell
  const onDragOver = (e: any) => {
    e.preventDefault();
    setDragOver(true);
  };

  // Event handler for dragging a file out of the file upload cell
  const onDragLeave = (e: any) => {
    e.preventDefault();
    setDragOver(false);
  };

  // Event handler for clicking the browse button on the file upload cell
  const onBrowse = (e: any) => {
    e.preventDefault();
    browseRef.current?.click();
  };

  // Event handler for selecting a file from the browse button
  const onFileSelected = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.target.files[0]);
  };

  // Copy paste event handler
  const onPaste = (e: any) => {
    const clipboardData = e.clipboardData;
    const items = clipboardData.items;

    if (!items) return;

    // Search through the clipboard items for a file
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        handleFileUpload(file);
        break;
      }
    }
  };

  // Copy paste event listener
  useEffect(() => {
    const handlePaste = (e: any) => {
      if (allowPaste) {
        onPaste(e);
      }
    };
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [allowPaste]);

  return (
    <div className={`flex h-full w-full`} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
      {dragOver ? (
        <div className="border-serial-palette-400 flex w-full items-center justify-center rounded-md border border-dashed py-1.5">
          <FontAwesomeIcon icon={faFileUpload} className="text-serial-palette-400" />
        </div>
      ) : (
        <>
          {isLoading && (
            <div className="flex w-full items-center justify-center">
              <Loader styleOverride="w-6 h-6" />
            </div>
          )}
          {!isLoading &&
            (fileUrl ? (
              <div className="flex w-full items-center gap-3">
                <button
                  tabIndex={-1}
                  className="border-serial-palette-200 btn text-serial-palette-600 hover:text-serial-palette-800 h-10 w-10 whitespace-nowrap border bg-white p-0"
                  onClick={() => handleDownload()}
                >
                  {dataType === DataType.Image ? (
                    <img className="h-full w-full rounded-sm object-cover" src={fileUrl ?? ""} />
                  ) : (
                    <FontAwesomeIcon size="lg" icon={faDownload} />
                  )}
                </button>
                <p className="text-serial-palette-600 truncate text-sm font-light ">{fileName ?? "File"}</p>
              </div>
            ) : (
              <div className="flex w-full items-center gap-2">
                <button
                  tabIndex={-1}
                  className="btn border-serial-palette-200 w-16 whitespace-nowrap border bg-white text-xs"
                  onClick={(e) => onBrowse(e)}
                >
                  Browse
                </button>
                <p className="truncate text-sm font-light italic opacity-70">or drag drop</p>
              </div>
            ))}
        </>
      )}
      <input type="file" disabled={disabled} ref={browseRef} className="hidden" onChange={(e: any) => onFileSelected(e)} />
    </div>
  );
};

export default FileUploadCell;
