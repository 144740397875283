import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { cn } from "@shared/utils/tailwind";
import Button from "@shared/components/primitives/Button";
import Calendar from "@shared/components/primitives/Calendar";
import Popover from "@shared/components/primitives/Popover";

interface DatePickerProps {
  date?: Date;
  onDateChange?: (date: Date | undefined) => void;
  className?: string;
  disabled?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({ date, onDateChange, disabled, className }) => {
  return (
    <Popover>
      <Popover.Trigger asChild>
        <Button disabled={disabled} variant="outline" className={cn("justify-start", className)}>
          <FontAwesomeIcon icon={faCalendar} className="h-4 w-4" />
          {date ? format(date, "PPP") : <span className="text-serial-palette-400">Select Date</span>}
        </Button>
      </Popover.Trigger>
      <Popover.Content className="w-auto p-0">
        <Calendar mode="single" selected={date} onSelect={onDateChange} initialFocus />
      </Popover.Content>
    </Popover>
  );
};

export default DatePicker;
