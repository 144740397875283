import React, { useContext, useMemo, useState } from "react";
import DashboardInsightCard from "./DashboardInsightCard";
import { faClone, faPencil, faShare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardContext } from "../Dashboards";
import { dashboardInsightDefaultProperties } from "../constants";
import DropdownEditMenu from "@shared/components/dropdowns/DropdownEditMenu";
import UserAvatar from "@shared/components/UserAvatar";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import DateRangePicker from "@shared/components/DateRangePicker";
import { DateRange } from "react-day-picker";
import DropdownClassic from "@shared/components/dropdowns/DropdownClassic";
import DashboardInsightsRow from "./DashboardInsightsRow";
import { useDashboardRowLogic } from "../hooks/useDashboardRowLogic";
import { DashboardFilters } from "../types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = ({}) => {
  const [filters, setFilters] = useState<DashboardFilters>({
    componentId: null,
    workOrderId: null,
    dateRange: { from: undefined, to: undefined },
  });
  const userId = useSelector((state: RootState) => state.auth.supabase_uid);
  const users = useSelector((state: RootState) => state.db.users);
  const components = useSelector((state: RootState) => state.db.components);
  const context = useContext(DashboardContext);
  const navigate = useNavigate(); // for URL change
  const workOrders = useSelector((state: RootState) => state.db.workOrders);
  const { t } = useTranslation();

  const dashboard = useMemo(() => {
    return context.dashboards?.find((dashboard) => dashboard.id === context.selectedDashboardId);
  }, [context.dashboards, context.selectedDashboardId]);

  const userIsOwner = useMemo(() => {
    return dashboard?.created_by === userId;
  }, [dashboard, userId]);

  const dashboards = useMemo(() => {
    return context.dashboards.filter((dashboard) => dashboard.config.is_prebuilt);
  }, [context.dashboards]);

  const sortedActiveComponents = useMemo(() => {
    if (!components || components.length === 0) return [];
    return [...components].sort((a, b) => a?.name?.localeCompare(b?.name)).filter((c) => c?.is_active === true);
  }, [components]);

  const availableWorkOrders = useMemo(() => {
    return workOrders.filter((workOrder) => workOrder.component_id === filters.componentId);
  }, [filters.componentId]);

  const setFilterComponentId = (componentId: string | null) => {
    setFilters({ ...filters, componentId });
  };

  const setFilterWorkOrderId = (workOrderId: string) => {
    setFilters({ ...filters, workOrderId });
  };

  const setFilterDateRange = (dateRange: DateRange | undefined) => {
    setFilters({ ...filters, dateRange });
  };

  const handleNewInsight = (rowIndex: number) => {
    context.setTargetDashboardRow(rowIndex);
    context.setInsightModalOpen(true);
  };

  const changeDashboard = (dashboardId: string) => {
    context.setSelectedDashboardId(dashboardId); // Set the selected dashboard ID in context
    navigate(`/dashboards/${dashboardId}`); // Change URL
  };

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col gap-6 overflow-auto px-12 py-8">
      {/* Page header */}
      <div className="gap-4 sm:flex sm:items-center sm:justify-between">
        {/* Left: Title */}
        <div className="mb-4 flex space-x-8 pr-6 sm:mb-0">
          <div className="text-xl font-bold">Dashboards</div>
          {dashboards.map((dashboard, index) => (
            <div
              key={index}
              className={`text-lg font-semibold ${context.selectedDashboardId === dashboard.id ? "text-serial-palette-800 border-b-3 border-serial-palette-800 pb-3 " : "text-gray-400"} hover:text-serial-palette-800 cursor-pointer`}
              onClick={() => changeDashboard(dashboard.id)}
            >
              {dashboard?.name}
            </div>
          ))}
        </div>
        {/* Right: Actions */}
        <div className="flex flex-wrap items-center justify-end gap-2">
          {dashboard?.config?.is_prebuilt === false && !dashboard?.is_public && Object.values(dashboard?.shared_with).length > 0 && (
            <button
              className="text-serial-palette-500 hover:text-serial-palette-600 flex items-center gap-1 pr-2 font-medium hover:font-semibold"
              onClick={() => context.setDashboardEditModalOpen(true)}
            >
              <UserAvatar user={users.find((user) => user.supabase_uid === dashboard?.created_by)} size="sm" />
              <p>{`+${Object.values(dashboard?.shared_with).filter((isShared) => isShared).length}`}</p>
            </button>
          )}
          {dashboard?.id === "wip-tracker" && filters.filteredDataSize && <div>Count: {filters.filteredDataSize}</div>}
          <div className="w-[200px]">
            <DropdownClassic
              className="w-full"
              options={[
                { id: null, label: "Component" },
                ...sortedActiveComponents.map((c) => {
                  return { id: c.id, label: c.name };
                }),
              ]}
              selected={filters.componentId}
              setSelected={setFilterComponentId}
            />
          </div>
          <div className="w-[200px]">
            <DropdownClassic
              className="w-full"
              options={[
                { id: null, label: `${t("workOrder")}` },
                ...availableWorkOrders.map((w) => {
                  return { id: w.id, label: w.name };
                }),
              ]}
              selected={filters.workOrderId ?? null}
              setSelected={setFilterWorkOrderId}
            />
          </div>
          <DateRangePicker dateRange={filters.dateRange} onDateRangeChange={setFilterDateRange} />
          {!dashboard?.config?.is_prebuilt && dashboard && (
            <DropdownEditMenu align="right" size="9" dark>
              {userIsOwner && (
                <li
                  className="text-serial-palette-600 hover:bg-serial-palette-50 flex cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                  onClick={() => context.setDashboardEditModalOpen(true)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                  <p>Edit</p>
                </li>
              )}
              <li
                className="text-serial-palette-600 hover:bg-serial-palette-50 flex cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                onClick={() => context.handleDuplicateDashboard(dashboard.id)}
              >
                <FontAwesomeIcon icon={faClone} />
                <p>Duplicate</p>
              </li>
              <li
                className="text-serial-palette-600 hover:bg-serial-palette-50 flex cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                onClick={() => context.setDashboardEditModalOpen(true)}
              >
                <FontAwesomeIcon icon={faShare} />
                <p>Share</p>
              </li>
              {userIsOwner && (
                <li
                  className="hover:bg-serial-palette-50 flex cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium text-rose-500"
                  onClick={() => context.handleDeleteDashboard(dashboard.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <p>Delete</p>
                </li>
              )}
            </DropdownEditMenu>
          )}
        </div>
      </div>
      {dashboard?.config?.rows?.map((row, rowIndex) => {
        const { widths } = useDashboardRowLogic({ row });
        return (
          <DashboardInsightsRow
            key={rowIndex}
            row={row}
            rowIndex={rowIndex}
            allowAddInsight={false}
            handleNewInsight={handleNewInsight}
            allowResize={false}
          >
            {row?.insights?.map((insight, insightIndex) => {
              const defaultConfig = dashboardInsightDefaultProperties[insight.type];
              return (
                <DashboardInsightCard
                  key={insightIndex}
                  type={insight.type}
                  width={widths[insightIndex]}
                  config={insight.config}
                  allowFullscreen={defaultConfig.allowFullscreen}
                  filters={filters}
                  setFilters={setFilters}
                  rowIndex={rowIndex}
                  insightIndex={insightIndex}
                />
              );
            })}
          </DashboardInsightsRow>
        );
      })}
    </div>
  );
};

export default Dashboard;
