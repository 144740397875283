import { faCircle, faSquare } from "@fortawesome/free-regular-svg-icons";
import { faAdjust, faFont, faLongArrowAltDown, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ImageMarkupElement, ImageMarkupElementType } from "@shared/types/databaseTypes";
import { Updater } from "use-immer";

export interface ImageMarkupElementConfig {
  icon: any;
  tooltip: string;
  variableColor: boolean;
  variableFillColor: boolean;
  variableLineThickness: boolean;
  variableTextStyle: boolean;
  showBoundingBoxOnFocus: boolean;
  squareAspectRatio?: boolean;
  className?: string;
}

export const imageMarkupElementConfig: {
  [key in ImageMarkupElementType]: ImageMarkupElementConfig;
} = {
  [ImageMarkupElementType.Rectangle]: {
    icon: faSquare,
    tooltip: "Rectangle",
    variableColor: true,
    variableFillColor: true,
    variableLineThickness: true,
    variableTextStyle: false,
    showBoundingBoxOnFocus: false,
  },
  [ImageMarkupElementType.Circle]: {
    icon: faCircle,
    tooltip: "Circle",
    variableColor: true,
    variableFillColor: true,
    variableLineThickness: true,
    variableTextStyle: false,
    showBoundingBoxOnFocus: false,
  },
  [ImageMarkupElementType.Text]: {
    icon: faFont,
    tooltip: "Text",
    variableColor: true,
    variableFillColor: true,
    variableLineThickness: false,
    variableTextStyle: true,
    showBoundingBoxOnFocus: true,
  },
  [ImageMarkupElementType.Arrow]: {
    icon: faLongArrowAltDown,
    tooltip: "Arrow",
    variableColor: true,
    variableFillColor: false,
    variableLineThickness: true,
    variableTextStyle: false,
    showBoundingBoxOnFocus: false,
    className: "-rotate-45",
  },
  [ImageMarkupElementType.Magnify]: {
    icon: faMagnifyingGlass,
    tooltip: "Magnify",
    variableColor: false,
    variableFillColor: false,
    variableLineThickness: false,
    variableTextStyle: false,
    showBoundingBoxOnFocus: false,
    squareAspectRatio: true,
  },
  [ImageMarkupElementType.Focus]: {
    icon: faAdjust,
    tooltip: "Focus",
    variableColor: false,
    variableFillColor: false,
    variableLineThickness: false,
    variableTextStyle: false,
    showBoundingBoxOnFocus: true,
  },
};

export interface ImageMarkupProps {
  imageUrl: string | null;
  markup: ImageMarkupElement[];
  onMarkupChange?: Updater<ImageMarkupElement[]>;
  editing: boolean;
  onEditingChange?: (editing: boolean) => void;
  className?: string;
  cover?: boolean;
}
