import React, { createContext, useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { LabelFormatterProps } from "./LabelFormatter";
import { LabelFormat, LabelElement } from "@shared/types/databaseTypes";
import { LabelFormatWithContents, LabelElementKeys } from "./types";
import { getLabels, saveLabels, getUsedLabelFields } from "./connections/supabase";
import { findElementById, generateBlankLabelWithContents } from "./helpers/helpers";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import useCurrentUser from "@shared/hooks/useCurrentUser";

interface LabelFormatterContextProps {
  // state
  componentId: string | null;
  draftLabels: LabelFormatWithContents[];
  // state with setters
  selectedLabelId: string | null;
  setSelectedLabelId: (labelId: string | null) => void;
  focusedElementId: string | null;
  setFocusedElementId: (elementId: string | null) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  showInactiveLabels: boolean;
  setShowInactiveLabels: (showInactiveLabels: boolean) => void;
  // event handlers
  handleSetModalOpen: (modalOpen: boolean) => void;
  handleEditLabel: (key: keyof LabelFormatWithContents, value: any) => void;
  handleNewLabel: () => void;
  handleDeleteLabel: (labelId: string) => void;
  handleAddAbsoluteElement: (element: LabelElement) => void;
  handleEditElement: (elementId: string, key: LabelElementKeys, value: any) => void;
  handleDeleteElement: (elementId: string) => void;
  handleSave: () => void;
}

// Create a default context value for LabelFormatterContext to avoid having to initialize the context with undefined
const defaultContext: LabelFormatterContextProps = {
  // state
  componentId: null,
  // state with setters
  selectedLabelId: null,
  setSelectedLabelId: () => {},
  focusedElementId: null,
  setFocusedElementId: () => {},
  zoom: 1,
  setZoom: () => {},
  showInactiveLabels: false,
  setShowInactiveLabels: () => {},
  draftLabels: [],
  // event handlers
  handleSetModalOpen: () => {},
  handleEditLabel: () => {},
  handleNewLabel: () => {},
  handleDeleteLabel: () => {},
  handleAddAbsoluteElement: () => {},
  handleEditElement: () => {},
  handleDeleteElement: () => {},
  handleSave: () => {},
};

export const LabelFormatterContext = createContext<LabelFormatterContextProps>(defaultContext);

const LabelFormatterProvider: React.FC<React.PropsWithChildren<LabelFormatterProps>> = ({
  componentId,
  setModalOpen,
  setUnsavedChanges,
  children,
}) => {
  const [draftLabels, setDraftLabels] = useImmer<LabelFormatWithContents[]>([]);
  const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);
  const [focusedElementId, setFocusedElementId] = useState<string | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [showInactiveLabels, setShowInactiveLabels] = useState<boolean>(false);

  const { triggerToast } = useContext(ToastContext);

  const company = useSelector((state: RootState) => state.db.company);
  const component = useSelector((state: RootState) => state.db.components.find((component) => component.id === componentId)) ?? null;
  const currentUser = useCurrentUser();

  const loadLabels = async () => {
    if (!componentId) {
      console.error("Cannot load labels without a component id");
      return;
    }
    const labels = await getLabels(componentId ?? "");
    setDraftLabels(labels);
    setSelectedLabelId(labels[0]?.id ?? null);
    setTimeout(() => setUnsavedChanges(false), 0);
  };

  useEffect(() => {
    setUnsavedChanges(true);
  }, [draftLabels]);

  useEffect(() => {
    loadLabels();
  }, []);

  const handleEditLabel = (key: keyof LabelFormatWithContents, value: any) => {
    setDraftLabels((currDraftLabels) => {
      const newDraftLabels = JSON.parse(JSON.stringify(currDraftLabels)) as LabelFormat[];
      const index = newDraftLabels.findIndex((label) => label.id === selectedLabelId);
      const newLabel = {
        ...newDraftLabels[index],
        [key]: value,
      };
      newDraftLabels[index] = newLabel;
      return newDraftLabels;
    });
  };

  const handleNewLabel = () => {
    if (!company || !component || !currentUser) {
      console.error(
        "Cannot create a new schema without a company, component, and user. Company: ",
        company,
        " Component: ",
        component,
        " Current User: ",
        currentUser,
      );
      return;
    }
    const newLabel = generateBlankLabelWithContents(company.id, component.id, currentUser.supabase_uid);
    setDraftLabels((currDraftLabels) => [...currDraftLabels, newLabel]);
    setSelectedLabelId(newLabel.id);
  };

  const handleDeleteLabel = async (labelId: string) => {
    const relatedFields = await getUsedLabelFields([labelId]);
    if (relatedFields.length > 0) {
      triggerToast(
        ToastType.Error,
        "Cannot delete label",
        `Cannot delete label because it is/was used in the following processes: ${relatedFields
          .map((field) => `${field.process_step?.process?.name} - ${field.process_step?.name}`)
          .join(", ")}`,
      );
      return;
    }
    setDraftLabels((currDraftLabels) => {
      const index = currDraftLabels.findIndex((label) => label.id === labelId);
      currDraftLabels.splice(index, 1);
    });
    setSelectedLabelId(null);
  };

  const handleAddAbsoluteElement = (element: LabelElement) => {
    setDraftLabels((currDraftLabels) => {
      const index = currDraftLabels.findIndex((label) => label.id === selectedLabelId);
      // check to make sure the element as both x and y coordinates
      if (element.x === undefined || element.y === undefined) {
        console.error("Cannot add absolute element without x and y coordinates");
        return;
      }
      currDraftLabels[index].contents.push(element);
    });
  };

  const handleEditElement = (elementId: string, key: LabelElementKeys, value: any) => {
    setDraftLabels((currDraftLabels) => {
      const index = currDraftLabels.findIndex((label) => label.id === selectedLabelId);
      if (index === -1) {
        console.error("Cannot find label with id", selectedLabelId);
        return currDraftLabels;
      }
      const element = findElementById(currDraftLabels[index].contents, elementId);
      if (!element) {
        console.error("Cannot find element with id", elementId);
        return;
      }
      // @ts-expect-error - element must have a LabelElementKey
      element[key] = value;
      return;
    });
  };

  const handleDeleteElement = (elementId: string) => {
    setDraftLabels((currDraftLabels) => {
      const index = currDraftLabels.findIndex((label) => label.id === selectedLabelId);
      const elementIndex = currDraftLabels[index].contents.findIndex((element) => element.id === elementId);
      if (elementIndex === -1) {
        console.error("Cannot find element");
        return;
      }
      currDraftLabels[index].contents.splice(elementIndex, 1);
      return;
    });
  };

  const handleSave = async () => {
    try {
      await saveLabels(draftLabels, componentId ?? "");
      triggerToast(ToastType.Success, "Saved", "Your changes have been saved.");
      setUnsavedChanges(false);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        triggerToast(ToastType.Error, "Error", error.message);
      } else {
        triggerToast(ToastType.Error, "Error", String(error));
      }
    }
  };

  return (
    <LabelFormatterContext.Provider
      value={{
        componentId,
        draftLabels,
        selectedLabelId,
        setSelectedLabelId,
        focusedElementId,
        setFocusedElementId,
        zoom,
        setZoom,
        showInactiveLabels,
        setShowInactiveLabels,
        handleSetModalOpen: setModalOpen,
        handleEditLabel,
        handleNewLabel,
        handleDeleteLabel,
        handleAddAbsoluteElement,
        handleEditElement,
        handleDeleteElement,
        handleSave,
      }}
    >
      {children}
    </LabelFormatterContext.Provider>
  );
};

export default LabelFormatterProvider;
