import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquare,
  faClone,
  faUser,
  faRuler,
  faLink,
  faExchange,
  faQuestion,
  faCheck,
  faX,
  faBarcode,
  faMinus,
  faCog,
  faList,
  faLock,
  faBan,
  faTrashAlt,
  faLinkSlash,
  faDesktop,
  faPencil,
  faHashtag,
  faTag,
  faStopwatch,
  faCheckDouble,
  faTimes,
  faStamp,
  faCalendar,
  faClipboard,
  faCheckCircle,
  faSortAlphaDown,
  faRedoAlt,
  faSignature,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faFile, faImage } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import i18next from "@shared/i18n/config";

interface TypeTag {
  type: string;
  colorClass: string;
  faIcon: IconDefinition | null;
  tooltipText?: string;
}

interface TypeTagConfig {
  [key: string]: TypeTag;
}

export const TypeTagsConfig: TypeTagConfig = {
  SN: {
    type: "SN",
    colorClass: "bg-serial-palette-900 text-serial-palette-100 ",
    faIcon: faSquare,
    tooltipText: "Field for selecting the serial number for which you want to add data",
  },
  LOT: {
    type: "LOT",
    colorClass: "bg-serial-palette-400 text-serial-palette-100",
    faIcon: faClone,
    tooltipText: "Field for selecting the lot code for which you want to add data",
  },
  STATUS: {
    type: "STATUS",
    colorClass: "bg-amber-100 text-amber-500",
    faIcon: faCheckDouble,
    tooltipText: "",
  },
  UID: {
    type: "UID",
    colorClass: "bg-serial-palette-400 text-serial-palette-100",
    faIcon: faBarcode,
    tooltipText: "Field for selecting the serial number or lot code for which you want to add data",
  },
  PART_NUMBER: {
    type: "PART NUMBER",
    colorClass: "bg-yellow-100 text-yellow-500",
    faIcon: faTag,
    tooltipText: "Field for selecting the serial number or lot code for which you want to add data",
  },
  LINK: {
    type: "LINK",
    colorClass: "bg-serial-palette-100 text-serial-palette-500",
    faIcon: faLink,
    tooltipText: "Use link groups to connect serial numbers or lot codes to each other",
  },
  LINK_STATUS: {
    type: "LINK STATUS",
    colorClass: "bg-serial-palette-100 text-serial-palette-500",
    faIcon: faLink,
    tooltipText: "Use link groups to connect serial numbers or lot codes to each other",
  },
  LINKED: {
    type: "LINKED",
    colorClass: "bg-serial-palette-100 text-serial-palette-500",
    faIcon: faLink,
    tooltipText: "Use link groups to connect serial numbers or lot codes to each other",
  },
  UNLINKED: {
    type: "UNLINKED",
    colorClass: "bg-serial-palette-100 text-red-500",
    faIcon: faLinkSlash,
  },
  TIMESTAMP: {
    type: "TIMESTAMP",
    colorClass: "bg-lime-100 text-lime-600",
    faIcon: faClock,
    tooltipText: "Date and time is automatially collected to every process",
  },
  DATETIME: {
    type: "DATETIME",
    colorClass: "bg-violet-100 text-violet-600",
    faIcon: faClock,
    tooltipText: "If you want to collect date and time data beyond when the process was run, use the datetime group",
  },
  CYCLE_TIME: {
    type: "CYCLE TIME",
    colorClass: "bg-emerald-100 text-emerald-500",
    faIcon: faStopwatch,
  },
  OPERATOR: {
    type: "OPERATOR",
    colorClass: "bg-indigo-100 text-indigo-600",
    faIcon: faUser,
  },
  PASSFAIL: {
    type: "PASSFAIL",
    colorClass: "bg-orange-100 text-orange-600",
    faIcon: faExchange,
    tooltipText: "Use pass/fail groups to indicate whether a serial number or lot code has passed or failed a test",
  },
  PROCESS_RESULT: {
    type: "PROCESS RESULT",
    colorClass: "bg-amber-100 text-amber-500",
    faIcon: faCheckCircle,
  },
  WORK_ORDER_NAME: {
    type: i18next.t("workOrder").toUpperCase(),
    colorClass: "bg-serial-palette-300 text-serial-palette-700",
    faIcon: faClipboard,
  },
  WORK_ORDER_PHASE: {
    type: "PHASE",
    colorClass: "bg-serial-palette-300 text-serial-palette-800",
    faIcon: faSortAlphaDown,
  },
  IMAGE: {
    type: "IMAGE",
    colorClass: "bg-amber-100 text-amber-600",
    faIcon: faImage,
    tooltipText: "Use image groups to attach photos or take live photos",
  },
  FILE: {
    type: "FILE",
    colorClass: "bg-fuchsia-100 text-fuchsia-600",
    faIcon: faFile,
    tooltipText: "Use file upload groups to attach documents or reports",
  },
  CHECKBOX: {
    type: "CHECKBOX",
    colorClass: "bg-teal-100 text-teal-500",
    faIcon: faList,
    tooltipText: "Use checkbox groups to create an checklist",
  },
  PARAMETRIC_QUALITATIVE: {
    type: "TEXT",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faPencil,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  PARAMETRIC_QUANTITATIVE: {
    type: "NUMERICAL",
    colorClass: "bg-blue-100 text-blue-500",
    faIcon: faHashtag,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  PARAMETRIC: {
    type: "PARAMETRIC",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faRuler,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  MANUAL_ENTRY: {
    type: "PARAMETRIC",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faRuler,
    tooltipText: "Manual data groups can be numerical or text depending on the type of data you want to collect",
  },
  TEXT: {
    type: "TEXT",
    colorClass: "bg-sky-100 text-sky-600",
    faIcon: faPencil,
  },
  NUMERICAL: {
    type: "NUMERICAL",
    colorClass: "bg-blue-100 text-blue-500",
    faIcon: faHashtag,
  },
  ADHOC: {
    type: "ADHOC",
    colorClass: "bg-serial-palette-100 text-serial-palette-600 ",
    faIcon: faQuestion,
  },
  PASS: {
    type: "PASS",
    colorClass: "bg-white text-green-600 border border-green-600",
    faIcon: faCheck,
  },
  OVERRIDE_PASS: {
    type: "OVERRIDE",
    colorClass: "bg-white text-yellow-600 border border-yellow-600",
    faIcon: faCheck,
  },
  OVERRIDE_FAIL: {
    type: "OVERRIDE",
    colorClass: "bg-white text-yellow-600 border border-yellow-600",
    faIcon: faX,
  },
  RETEST: {
    type: "RETEST",
    colorClass: "bg-white text-amber-600 border border-amber-600",
    faIcon: faRedoAlt,
  },
  FAIL: {
    type: "FAIL",
    colorClass: "bg-white text-red-600 border border-red-600",
    faIcon: faX,
  },
  DEFECTIVE: {
    type: "DEFECTIVE",
    colorClass: "bg-red-600 text-white border border-red-600",
    faIcon: faX,
  },
  COMPLETE: {
    type: "COMPLETE",
    colorClass: "bg-green-600 text-white border border-green-600",
    faIcon: faCheck,
  },
  WIP: {
    type: "WIP",
    colorClass: "bg-sky-600 text-white border border-sky-600",
    faIcon: faCog,
  },
  PLANNED: {
    type: "PLANNED",
    colorClass: "bg-gray-400 text-white border border-gray-400",
    faIcon: faCalendar,
  },
  NA: {
    type: "NA",
    colorClass: "bg-white text-gray-400 border border-gray-400",
    faIcon: faMinus,
  },
  ACTIVE: {
    type: "ACTIVE",
    colorClass: "bg-green-600 text-white border border-green-600",
    faIcon: null,
  },
  INACTIVE: {
    type: "INACTIVE",
    colorClass: "bg-red-600 text-white border border-red-600",
    faIcon: null,
  },
  LOCKED: {
    type: "LOCKED",
    colorClass: "bg-amber-600 text-white border border-amber-600",
    faIcon: faLock,
  },
  RETIRED: {
    type: "RETIRED",
    colorClass: "bg-red-600 text-white border border-red-600",
    faIcon: faBan,
  },
  ARCHIVED: {
    type: "ARCHIVED",
    colorClass: "bg-red-700 text-white border border-red-700",
    faIcon: faTrashAlt,
  },
  STATION: {
    type: "STATION",
    colorClass: "bg-slate-100 text-slate-500",
    faIcon: faDesktop,
  },
  APPROVED: {
    type: "APPROVED",
    colorClass: "bg-green-600 text-white border border-green-600",
    faIcon: faCheck,
  },
  REJECTED: {
    type: "REJECTED",
    colorClass: "bg-red-600 text-white border red-green-600",
    faIcon: faTimes,
  },
  PENDING: {
    type: "PENDING",
    colorClass: "bg-amber-600 text-white border border-amber-600",
    faIcon: faStamp,
  },
  SIGNATURE: {
    type: "SIGNATURE",
    colorClass: "bg-amber-100 text-amber-600",
    faIcon: faSignature,
  },
  LABEL: {
    type: "LABEL",
    colorClass: "bg-cyan-100 text-cyan-600",
    faIcon: faPrint,
  },
};

function updateTranslations() {
  TypeTagsConfig.WORK_ORDER_NAME.type = i18next.t("workOrder").toUpperCase();
}

// Initial translation if i18next is ready
if (i18next.isInitialized) {
  updateTranslations();
}

// Update translations on language change
i18next.on("languageChanged", () => {
  updateTranslations();
});

// Update translations when translations are loaded
i18next.on("loaded", () => {
  updateTranslations();
});

type TypeTagProps = {
  type: string;
  hideText?: boolean;
  quantity?: number;
  tooltip?: boolean;
  className?: string;
};

const TypeTag: React.FC<TypeTagProps> = ({ type, hideText, quantity, tooltip, className }) => {
  if (!type) {
    return null;
  }

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const currentConfig = TypeTagsConfig[type];

  if (currentConfig) {
    const { colorClass, type, faIcon, tooltipText } = currentConfig;

    return (
      <div
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
        className={`flex items-center justify-center rounded-full px-3 py-0.5 font-medium ${colorClass} ${hideText ? "py-1.5" : ""} ${className}`}
      >
        {faIcon && <FontAwesomeIcon icon={faIcon} />}
        {!hideText && <div className="pl-2">{type}</div>}
        {quantity && <div className="ml-1">{quantity}</div>}
        {tooltip && tooltipOpen && (
          <div className="border-serial-palette-200 text-serial-palette-600 absolute left-[110%] top-1/2 z-10 w-56 -translate-y-1/2 overflow-hidden rounded border  bg-white p-2 font-light shadow">
            {tooltipText}
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default TypeTag;
