import React, { useState, useEffect } from "react";
import { File, Image } from "@shared/types/databaseTypes";
import { Loader } from "./Loader";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import CSVViewer from "./CsvViewer";
import ErrorBoundary from "@shared/components/error/ErrorBoundary";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";
import { cn } from "@shared/utils/tailwind";

interface FileViewerProps {
  file: File | Image;
  className?: string;
}

enum FileType {
  Json = "JSON",
  PDF = "PDF",
  Image = "IMAGE",
  Video = "VIDEO",
  CSV = "CSV",
  Text = "TEXT",
}

const getFileType = async (file: File | Image, url: string) => {
  // file extension
  const splitFileName = file.file_name.match(/\.([^.]+)$/);
  const extension = splitFileName?.[1]?.toLowerCase();
  // file mime type
  const response = await fetch(url);
  const blob = await response.blob();
  const mimeType = blob.type;

  if (mimeType === "application/json" || extension === "json") {
    return FileType.Json;
  }
  if (mimeType === "application/pdf" || extension === "pdf") {
    return FileType.PDF;
  }
  if (mimeType.includes("image") || (extension && ["jpg", "jpeg", "png", "gif", "webp"].includes(extension))) {
    return FileType.Image;
  }
  if (mimeType.includes("video") || (extension && ["mp4", "avi", "mkv", "webm", "mov"].includes(extension))) {
    return FileType.Video;
  }
  if (mimeType === "text/csv" || extension === "csv") {
    return FileType.CSV;
  }
  return FileType.Text;
};

const FileViewer: React.FC<FileViewerProps> = ({ file, className }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>("null");
  const [fileType, setFileType] = useState<string>(FileType.Text);
  const [jsonContent, setJsonContent] = useState<any | null>(null); // Use 'any' for JSON content
  const [textContent, setTextContent] = useState<string | null>(null);
  const [renderableFile, setRenderableFile] = useState<boolean>(true);

  useEffect(() => {
    const fetchUrl = async () => {
      if (file) {
        try {
          setLoading(true);
          const url = await fetchDownloadUrl(file.bucket_name, file.file_id ?? file.id, file.company_id);
          if (!url) {
            throw new Error("No file url returned");
          }
          setFileUrl(url);

          const currentFileType = await getFileType(file, url);
          setFileType(currentFileType);

          if (currentFileType === FileType.Json) {
            const response = await fetch(url);
            if (response.ok) {
              const jsonContent = await response.json(); // Parse JSON
              setJsonContent(jsonContent);

              // Get the file size from the Content-Length header
              const fileSizeInBytes = parseInt(response.headers.get("Content-Length") || "0", 10);
              const tenMBInBytes = 10 * 1024 * 1024; // 10MB in bytes

              // Update the state
              setRenderableFile(fileSizeInBytes < tenMBInBytes);
            } else {
              console.error("Failed to fetch JSON content:", response.statusText);
            }
          }

          if (currentFileType === FileType.Text) {
            const response = await fetch(url);
            if (response.ok) {
              const text = await response.text();
              setTextContent(text);
            } else {
              console.error("Failed to fetch text content:", response.statusText);
            }
          }
        } catch (error) {
          console.error("Error fetching file url", error);
        }
        setLoading(false);
      }
    };

    fetchUrl();
  }, [file]);

  return (
    <ErrorBoundary>
      {file && (
        <div className={cn("flex h-full w-full flex-col overflow-auto p-5", className)}>
          {loading && (
            <div className="flex h-full items-center justify-center">
              <Loader />
            </div>
          )}
          {!loading && fileType === FileType.Image && (
            <div className="flex h-full w-full items-center justify-center">
              <img src={fileUrl} className="h-full w-full object-contain" />
            </div>
          )}
          {!loading && fileType === FileType.Video && (
            <div className="flex h-full items-center justify-center ">
              <video src={fileUrl} width="100%" height="100%" controls />
            </div>
          )}
          {!loading &&
            fileType === FileType.Json &&
            (renderableFile ? (
              <JsonView src={jsonContent} />
            ) : (
              <div className="flex h-full items-center justify-center"> JSON exceeds 10MB renderable limit. Please download file. </div>
            ))}
          {!loading && fileType === FileType.PDF && <iframe src={fileUrl} width="100%" height="100%" />}
          {!loading && fileType === FileType.CSV && <CSVViewer csvURL={fileUrl} />}
          {!loading && fileType === FileType.Text && <pre>{textContent}</pre>}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default FileViewer;
