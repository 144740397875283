import { useEffect, useMemo, useState } from "react";
import { MeasureKey, MeasureValue } from "../../types";
import MeasuresGridBodyCellContent from "./MeasuresGridBodyCellContent";
import { summarizeMeasureValue } from "@shared/measures/helpers/measureValues";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const PAGINATION_SIZE = 5;

const MeasuresGridBodyCellTestHistory = ({ measureKey, measureValues }: { measureKey: MeasureKey; measureValues: MeasureValue[] }) => {
  const [paginationIndex, setPaginationIndex] = useState<number>(0);

  const displayedMeasureValues = useMemo(() => {
    return [...measureValues]
      .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      .slice(paginationIndex * PAGINATION_SIZE, (paginationIndex + 1) * PAGINATION_SIZE);
  }, [measureValues, paginationIndex]);

  useEffect(() => {
    setPaginationIndex(0);
  }, [measureValues.length]);

  return (
    <div className="flex w-48 flex-col p-0">
      <div className="flex h-10 w-full items-center justify-between border-b pl-3 pr-1.5">
        <div className="flex gap-1.5">
          <span className="font-bold">Test History</span>
          <span className="text-serial-palette-500">{measureValues.length}</span>
        </div>
        {measureValues.length > PAGINATION_SIZE && (
          <div className="flex items-center">
            <Button size="xs" variant="ghost" disabled={paginationIndex === 0} onClick={() => setPaginationIndex(paginationIndex - 1)}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              size="xs"
              variant="ghost"
              disabled={paginationIndex * PAGINATION_SIZE + displayedMeasureValues.length >= measureValues.length}
              onClick={() => setPaginationIndex(paginationIndex + 1)}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </div>
        )}
      </div>
      <div className="h-[120px] w-full flex-col px-3 py-1">
        {displayedMeasureValues.map((measureValue, index) => (
          <div key={index} className="flex w-full items-center justify-between gap-2 overflow-hidden py-0.5 text-xs">
            <div className="w-7 shrink-0 font-bold">{paginationIndex * PAGINATION_SIZE + index + 1}</div>
            <div className="flex min-w-0 flex-grow items-center justify-end gap-1 overflow-hidden">
              <MeasuresGridBodyCellContent measureKey={measureKey} summary={summarizeMeasureValue(measureValue)} size="xs" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MeasuresGridBodyCellTestHistory;
