import React, { useEffect } from "react";
import Transition from "@shared/components/Transition";

interface ModalProps {
  show: boolean;
  setShow: ((show: boolean) => void) | React.Dispatch<React.SetStateAction<boolean>>;
  closeOnEsc?: boolean; // modal closes when esc key is pressed
  closeOnClickOut?: boolean; // modal closes when clicked outside of modal
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({ show, setShow, closeOnEsc, closeOnClickOut, children }) => {
  // Handle the Escape key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (closeOnEsc && event.key === "Escape") {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [show, closeOnEsc, setShow]);

  // Handle clicks outside of the modal
  const handleClickOutside = (event: React.MouseEvent) => {
    if (closeOnClickOut && event.target === event.currentTarget) {
      setShow(false);
    }
  };

  return (
    <>
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={show}
        onClick={handleClickOutside}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
        appear={null}
      />
      <Transition
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={show}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
        appear={null}
      >
        {children}
      </Transition>
    </>
  );
};

export default Modal;
