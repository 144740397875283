import React, { useContext } from "react";
import { LabelFormatterContext } from "../LabelFormatterProvider";
import { LabelElement } from "@shared/types/databaseTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBold, faItalic, faUnderline } from "@fortawesome/free-solid-svg-icons";
import Popover from "@shared/components/primitives/Popover";
import { LabelElementType } from "@shared/types/databaseEnums";

interface LabelFormatterLabelElementSettingsProps {
  element: LabelElement;
  elementRef: React.RefObject<HTMLDivElement>;
}

const ElementsWithSettings = [LabelElementType.Text];

const LabelFormatterLabelElementSettings: React.FC<LabelFormatterLabelElementSettingsProps> = ({ element }) => {
  const { focusedElementId, handleEditElement } = useContext(LabelFormatterContext);
  return (
    <Popover.Root open={focusedElementId === element.id && ElementsWithSettings.includes(element.type)}>
      <Popover.Trigger />
      <Popover.Content className="mt-1">
        {element.type === LabelElementType.Text && (
          <div className="text-serial-palette-600 flex h-8 w-full items-center justify-between gap-2 pl-3 pr-2 text-sm font-medium">
            <div className="">Format</div>
            <div className="flex">
              <button
                className={`btn-sm rounded-r-none ${element.bold ? "serial-btn-dark" : "serial-btn-light"}`}
                onClick={() => handleEditElement(element.id, "bold", element.bold ? false : true)}
              >
                <FontAwesomeIcon icon={faBold} />
              </button>
              <button
                className={`btn-sm rounded-none border-l-0 ${element.italic ? "serial-btn-dark" : "serial-btn-light"}`}
                onClick={() => handleEditElement(element.id, "italic", element.italic ? false : true)}
              >
                <FontAwesomeIcon icon={faItalic} />
              </button>
              <button
                className={`btn-sm rounded-l-none border-l-0 ${element.underline ? "serial-btn-dark" : "serial-btn-light"}`}
                onClick={() => handleEditElement(element.id, "underline", element.underline ? false : true)}
              >
                <FontAwesomeIcon icon={faUnderline} />
              </button>
            </div>
          </div>
        )}
      </Popover.Content>
    </Popover.Root>
  );
};

export default LabelFormatterLabelElementSettings;
