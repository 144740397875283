import React, { useContext, useRef } from "react";
import { ImageMarkupLabelElementProps } from "./ImageMarkupElementContainer";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import { ImageMarkupElementMagnify as MagnifyElement } from "@shared/types/databaseTypes";

const SCALING_FACTOR = 2;

const ImageMarkupElementMagnify: React.FC<ImageMarkupLabelElementProps<MagnifyElement>> = ({ element }) => {
  const { imageUrl, imageWidth, imageHeight, imageRef } = useContext(ImageMarkupContext);

  const elementRef = useRef<HTMLDivElement>(null);

  // Calculate the position of the element relative to the image
  // Cannot use element.x and element.y directly because they aren't updated on drag, only after drag ends
  const liveElementXPositionPx =
    (elementRef.current?.getBoundingClientRect().left ?? 0) - (imageRef?.current?.getBoundingClientRect().left ?? 0);
  const liveElementYPositionPx =
    (elementRef.current?.getBoundingClientRect().top ?? 0) - (imageRef?.current?.getBoundingClientRect().top ?? 0);

  const elementWidthPx = Math.abs(element.width) * imageWidth;
  const elementHeightPx = Math.abs(element.height) * imageHeight;

  return (
    <div
      ref={elementRef}
      className="border-serial-palette-400 relative shrink-0 overflow-hidden rounded-full border-4"
      style={{
        width: elementWidthPx,
        height: elementHeightPx,
      }}
    >
      {imageUrl && (
        <img
          className="absolute"
          src={imageUrl}
          onDragStart={(e) => e.preventDefault()}
          style={{
            width: imageWidth * SCALING_FACTOR,
            height: imageHeight * SCALING_FACTOR,
            maxWidth: "none",
            top: -(liveElementYPositionPx * SCALING_FACTOR + (elementHeightPx * SCALING_FACTOR) / 2 - elementHeightPx / 2),
            left: -(liveElementXPositionPx * SCALING_FACTOR + (elementWidthPx * SCALING_FACTOR) / 2 - elementWidthPx / 2),
          }}
        />
      )}
    </div>
  );
};

export default ImageMarkupElementMagnify;
