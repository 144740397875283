import FrontendPrimitivesCard from "./FrontendPrimitivesCard";
import Checkbox from "@shared/components/primitives/Checkbox";

const FrontendPrimitivesCheckbox = () => {
  return (
    <FrontendPrimitivesCard title="Switch">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Alignment</div>
        <div className="flex flex-col gap-2">
          <div className="flex w-48 items-center gap-x-4">
            <Checkbox />
            <label>Regular Checkbox</label>
          </div>
          <div className="flex w-48 items-center gap-x-4">
            <Checkbox disabled />
            <label>Disabled Checkbox</label>
          </div>
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesCheckbox;
