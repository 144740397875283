import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { DashboardInsightContext } from "../DashboardInsightCard";
import { RootState } from "@shared/redux/store";

const StatusCountBarGraph: React.FC<void> = () => {
  const context = useContext(DashboardInsightContext);
  const status: "complete" | "wip" | "defective" = context?.config?.status?.toLowerCase();
  const components = useSelector((state: RootState) => state.db.components);
  const activeComponents = components.filter((component) => component.is_active === true);

  const componentCounts = useMemo(() => {
    const largestCount = Math.max(...activeComponents.map((component) => component.counts?.[status] ?? 0));

    return activeComponents
      .map(({ counts, name, id }) => {
        const count = counts?.[status] ?? 0;
        let width = count > 0 ? (count / largestCount) * 100 : 0;
        width = width === 100 ? 90 : width;
        return { name, count, id, width };
      })
      .sort((a, b) => b.count - a.count);
  }, [activeComponents, context]);

  // Update title and subtitle in the parent context
  useEffect(() => {
    switch (status) {
      case "complete":
        context?.setTitle("Completed Units");
        break;
      case "wip":
        context?.setTitle("WIP Units");
        break;
      case "defective":
        context?.setTitle("Defective Units");
        break;
    }
    const totalCount = activeComponents.reduce((acc, component) => acc + (component.counts?.[status] ?? 0), 0);
    context?.setSubtitle(`${totalCount} Total`); // Update context here instead
  }, [status, context, components]); // Added components to dependency array as it is being used

  return (
    <div className="col-span-full flex flex-col gap-y-2 p-3 lg:col-span-4">
      {componentCounts.slice(0, 8).map((componentCount, index) => {
        const component = components.find((component) => component.id === componentCount.id);
        return (
          <div key={index} className="flex w-full space-x-2 px-2">
            {component && <ComponentAvatar component={component} size="sm" tooltip={true} />}
            <div className="relative flex w-full items-center">
              <div
                className={`absolute inset-0 ${status === "wip" ? "bg-sky-100" : status === "complete" ? "bg-green-100" : "bg-red-100"}`}
                aria-hidden="true"
                style={{ width: `${componentCount.width}%` }}
              ></div>
              <div className="relative flex w-full justify-between">
                <div className="pl-2">{componentCount.name}</div>
                <div className="font-medium">{componentCount.count}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatusCountBarGraph;
