export const UserRolePrettyNames = [
  {
    id: "ADMIN",
    pretty_name: "Administrator",
  },
  {
    id: "MEMBER",
    pretty_name: "Member",
  },
  {
    id: "OPERATIONS",
    pretty_name: "Operations",
  },
  {
    id: "DEMO",
    pretty_name: "Demo",
  },
  {
    id: "READONLY",
    pretty_name: "Read Only",
  },
];

export const PageAllowedRoles = {
  Homepage: ["ADMIN", "MEMBER", "READONLY"],
  Auth: ["*"],
  Dashboards: ["ADMIN", "MEMBER", "READONLY"],
  Reports: ["ADMIN", "MEMBER", "READONLY"],
  Datasets: ["ADMIN", "MEMBER", "READONLY"],
  Search: [],
  Users: ["ADMIN"],
  ComponentsList: ["ADMIN", "MEMBER", "READONLY"],
  Component: ["ADMIN", "MEMBER", "READONLY"],
  SnLookup: ["ADMIN", "MEMBER", "READONLY"],
  SnFormat: ["ADMIN"],
  GridBuilder: ["ADMIN", "MEMBER", "READONLY"],
  GraphBuilder: ["ADMIN", "MEMBER", "READONLY"],
  Tracking: ["ADMIN", "MEMBER", "READONLY"],
  GridViews: ["ADMIN", "MEMBER", "READONLY"],
  Stations: ["ADMIN"],
  WorkOrders: ["ADMIN", "MEMBER", "READONLY"],
  Production: ["ADMIN", "MEMBER", "OPERATIONS"],
  Debug: ["ADMIN"],
  FrontendPrimitives: ["ADMIN"],
  Settings: ["ADMIN", "MEMBER", "READONLY"],
  SettingsAdmin: ["ADMIN"],
  Utility: ["*"],
};

export const FieldTemplates = {
  UID: {
    dataset_id: null,
    datasets: {
      name: "UniqueIdentifier",
    },
    type: "UID",
    is_optional: false,
    is_active: true,
    prompt: "Scan serial number...",
    config: { method: "SCAN" },
    group_name: "UID",
  },
  LINK: {
    dataset_id: null,
    datasets: {
      name: "",
    },
    type: "LINK",
    is_optional: false,
    is_active: true,
    prompt: null,
    config: { has_child_of_id: undefined },
    group_name: "Link",
  },
  PARAMETRIC: {
    dataset_id: null,
    datasets: {
      name: "",
    },
    type: "PARAMETRIC",
    is_optional: false,
    is_active: true,
    prompt: "",
    config: {
      data_type: "STRING",
      lsl: null,
      nominal: null,
      usl: null,
      unit: "",
    },
    group_name: "Manual Data Entry",
  },
  PASSFAIL: {
    dataset_id: null,
    datasets: {
      name: "",
    },
    type: "PASSFAIL",
    is_optional: false,
    is_active: true,
    prompt: "",
    config: {},
    group_name: "Pass / Fail",
  },
  IMAGE: {
    dataset_id: null,
    datasets: {
      name: "",
    },
    type: "IMAGE",
    is_optional: false,
    is_active: true,
    prompt: "",
    config: { method: "UPLOAD" },
    group_name: "",
  },
  FILE: {
    dataset_id: null,
    datasets: {
      name: "",
    },
    type: "FILE",
    is_optional: false,
    is_active: true,
    prompt: "",
    config: { format: "OTHER" },
    group_name: "",
  },
  CHECKBOX: {
    dataset_id: null,
    datasets: {
      name: "",
    },
    type: "CHECKBOX",
    is_optional: true,
    is_active: true,
    prompt: "",
    config: {
      is_pass_fail_criteria: false,
    },
    group_name: "Checklist",
  },
};
