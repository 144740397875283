import React, { useState, useEffect, useMemo } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import TypeTag from "@shared/components/TypeTag";
import { SnLookupContext } from "../../SnLookupProvider";
import {
  Image,
  ParametricQualitative,
  ParametricQuantitative,
  File,
  Checkbox as CheckboxType,
  DatetimeData,
  UniqueIdentifierLink,
} from "@shared/types/databaseTypes";
import { Dataset } from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import { fetchFileSize } from "@shared/connections/supabaseGeneral";
import { formatFileSize, screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import moment from "moment";
import Checkbox from "@shared/components/primitives/Checkbox";
import { TextInput } from "@shared/components/primitives/Input";
import SidebarSelector from "@shared/components/primitives/SidebarSelector";

interface ModalDatasetSelectorProps {
  selectedDataset: Dataset | null;
  setSelectedDataset: React.Dispatch<React.SetStateAction<Dataset | null>>;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | Image | null>>;
}

const DEPRECATED_DATA_TYPES = [DataType.Uid];
const defaultFilterOptions = Object.values(DataType).reduce(
  (acc, type) => (DEPRECATED_DATA_TYPES.includes(type) ? acc : { ...acc, [type]: true }),
  {},
);

const DatasetSelector: React.FC<ModalDatasetSelectorProps> = ({ selectedDataset, setSelectedDataset, setSelectedFile }) => {
  const { selectedProcess, dataAnalysisModalOpen, processEntries, allFiles } = useContext(SnLookupContext);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterStatusOptions, setFilterOptions] = useState<Record<string, boolean>>(defaultFilterOptions);
  const [showInactiveDatasets, setShowInactiveDatasets] = useState<boolean>(false);
  const [fileSizes, setFileSizes] = useState<Record<string, number | null>>({});

  const datasets = useSelector((state: RootState) => state.db.datasets);

  const datasetIdToLatestDatapointMap = useMemo(() => {
    const map: {
      [key: string]: ParametricQualitative | ParametricQuantitative | CheckboxType | Image | File | DatetimeData | UniqueIdentifierLink;
    } = {};
    processEntries
      .filter((entry) => entry.process_id === selectedProcess?.id)
      .filter((entry) => entry.unique_identifier_id === selectedProcess?.entries[0]?.unique_identifier_id)
      .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      .forEach((entry) => {
        entry.textData.forEach((data) => {
          map[data.dataset_id] = data;
        });
        entry.numericalData.forEach((data) => {
          map[data.dataset_id] = data;
        });
        entry.checkboxData.forEach((data) => {
          map[data.dataset_id] = data;
        });
        entry.fileData.forEach((data) => {
          map[data.dataset_id] = data;
        });
        entry.imageData?.forEach((data) => {
          map[data.dataset_id] = data;
        });
        entry.datetimeData.forEach((data) => {
          map[data.dataset_id] = data;
        });
        entry.linkData.forEach((data) => {
          map[data.dataset_id] = data;
        });
      });
    return map;
  }, [processEntries, selectedProcess]);

  const processDatasets = useMemo(() => {
    const typeOrder = [
      DataType.ParametricQuantitative,
      DataType.ParametricQualitative,
      DataType.Datetime,
      DataType.Link,
      DataType.PassFail,
      DataType.Checkbox,
      DataType.File,
      DataType.Image,
    ];

    return datasets
      .filter((dataset) => dataset.process_id === selectedProcess?.id)
      .sort((a, b) => {
        const typePriorityA = typeOrder.indexOf(a!.data_type);
        const typePriorityB = typeOrder.indexOf(b!.data_type);
        if (typePriorityA !== typePriorityB) {
          return typePriorityA - typePriorityB;
        }
        if (a.order === null && b.order === null) {
          return a.name.localeCompare(b.name);
        }
        if (a.order === null) return 1;
        if (b.order === null) return -1;
        return a.order - b.order;
      });
  }, [selectedProcess, datasets, datasetIdToLatestDatapointMap]);

  const filteredDatasets = useMemo(() => {
    let newFilteredDatasets: Dataset[] = [...processDatasets];
    if (searchTerm) {
      newFilteredDatasets = processDatasets.filter((dataset) => dataset?.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    newFilteredDatasets = newFilteredDatasets.filter((dataset) => {
      return dataset.is_active || (showInactiveDatasets && !dataset.is_active);
    });
    newFilteredDatasets = newFilteredDatasets.filter((dataset) => {
      const dataType = dataset?.data_type;
      return dataType !== undefined && filterStatusOptions[dataType];
    });
    return newFilteredDatasets;
  }, [processDatasets, searchTerm, filterStatusOptions, showInactiveDatasets]);

  const toggleFilterStatusOption = (optionKey: string): void => {
    const modifiedOptions = { ...filterStatusOptions };
    modifiedOptions[optionKey] = !filterStatusOptions[optionKey];
    setFilterOptions(modifiedOptions);
  };

  const handleSelectAllTypes = (checked: boolean) => {
    const modifiedOptions = { ...filterStatusOptions };
    Object.keys(modifiedOptions).forEach((key) => {
      modifiedOptions[key] = checked;
    });
    setFilterOptions(modifiedOptions);
  };

  useEffect(() => {
    if (selectedDataset === null && processDatasets.length > 0 && dataAnalysisModalOpen) {
      setSelectedDataset(processDatasets.filter((dataset) => dataset.is_active)[0] ?? null);
      // If the dataset's data type is File, set the selected file
      if (processDatasets[0]?.data_type === DataType.File) {
        setSelectedFile((datasetIdToLatestDatapointMap[processDatasets[0].id] as File) ?? null);
      }
    }
  }, [selectedDataset, processDatasets, dataAnalysisModalOpen]);

  const handleSelectDataset = (datasetId: string | null) => {
    const dataset = datasets.find((dataset) => dataset.id === datasetId);
    setSelectedDataset(dataset ?? null);
    // If the dataset's data type is File, set the selected file
    if (dataset?.data_type === DataType.File || dataset?.data_type === DataType.Image) {
      const availableFiles = allFiles
        .filter((file) => file.dataset_id === dataset?.id)
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()); // Sort by most recent
      setSelectedFile(availableFiles[0] ?? null);
    } else {
      setSelectedFile(null); // Reset or clear the selected file if not a file type
    }
  };

  useEffect(() => {
    const fetchAllFileSizes = async () => {
      const fileSizePromises = processDatasets.map(async (processDataset) => {
        if (processDataset?.data_type === DataType.File || processDataset?.data_type === DataType.Image) {
          const file = datasetIdToLatestDatapointMap[processDataset.id] as File | Image | null;
          if (!file) {
            return null;
          }
          const size = await fetchFileSize(file?.file_id ?? file?.id, file?.company_id, file?.bucket_name);
          return { id: processDataset.id, size: size };
        }
        return null;
      });

      const sizes = await Promise.all(fileSizePromises);
      const fileSizeMap = sizes.reduce<Record<string, number | null>>((acc, curr) => {
        if (curr) {
          acc[curr.id] = curr.size;
        }
        return acc;
      }, {});

      setFileSizes(fileSizeMap);
    };

    if (processDatasets.length > 0) {
      fetchAllFileSizes();
    }
  }, [processDatasets]); // Depend on processDatasets to re-fetch sizes when it changes

  return (
    <SidebarSelector.Root className="w-full" value={selectedDataset?.id} onValueChange={(value) => handleSelectDataset(value)}>
      <SidebarSelector.Header className="flex w-full flex-row">
        <div className="relative w-full">
          <TextInput placeholder="Search" className="pl-8" value={searchTerm} onValueChange={setSearchTerm} />
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-serial-palette-400 absolute left-2.5 top-2.5" />
        </div>
        <DropdownCustom
          align="right"
          buttonContents={
            <div className="btn serial-btn-light h-9">
              <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                <path d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z" />
              </svg>
            </div>
          }
          className=""
          dropdownClassName="w-48"
          keepOpen={true}
        >
          <div
            className="hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center gap-x-2 px-4 py-1 last:mb-1.5"
            onClick={() => setShowInactiveDatasets(!showInactiveDatasets)}
          >
            <Checkbox checked={showInactiveDatasets} />
            <div className="text-serial-palette-600 flex min-w-0 flex-grow text-sm font-medium">Inactive Datasets</div>
          </div>
          <div className="my-1 w-full border-b" />
          <div
            className="hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center gap-x-2 px-4 py-1 last:mb-1.5"
            onClick={() => handleSelectAllTypes(!Object.values(filterStatusOptions).every((option) => option))}
          >
            <Checkbox checked={Object.values(filterStatusOptions).every((option) => option)} />
            <div className="text-serial-palette-600 flex min-w-0 flex-grow text-sm font-medium">All Dataset Types</div>
          </div>
          {Object.entries(filterStatusOptions).map(([type, checked]) => {
            const labelOverrides = {
              [DataType.ParametricQuantitative as string]: "Numeric",
              [DataType.ParametricQualitative as string]: "Text",
            };
            const label = labelOverrides[type] ?? screamingSnakeCaseToTitleCase(type);
            return (
              <div
                key={type}
                className="hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center px-4 py-1 last:mb-1.5"
                onClick={() => toggleFilterStatusOption(type)}
              >
                <input type="checkbox" className="form-checkbox mr-2 h-4 w-4" checked={checked} readOnly />
                <div className="text-serial-palette-600 flex min-w-0 flex-grow text-sm font-medium">{label}</div>
                <TypeTag className={"w-10 text-xs"} hideText={true} type={type} />
              </div>
            );
          })}
        </DropdownCustom>
      </SidebarSelector.Header>
      <SidebarSelector.List>
        {/* sort Dataset by latest_process_entries most recent submission time */}
        {filteredDatasets.map((processDataset, index) => {
          let datasetResult = "NA";
          if (processDataset.data_type === DataType.ParametricQuantitative) {
            datasetResult =
              (datasetIdToLatestDatapointMap[processDataset.id] as ParametricQuantitative)?.is_pass === true
                ? "PASS"
                : (datasetIdToLatestDatapointMap[processDataset.id] as ParametricQuantitative)?.is_pass === false
                  ? "FAIL"
                  : "NA";
          } else if (processDataset?.data_type === DataType.PassFail) {
            datasetResult =
              (datasetIdToLatestDatapointMap[processDataset.id] as ParametricQualitative)?.value === "PASS"
                ? "PASS"
                : (datasetIdToLatestDatapointMap[processDataset.id] as ParametricQualitative)?.value === "FAIL"
                  ? "FAIL"
                  : "NA";
          }

          return (
            <SidebarSelector.Item key={index} className="py-1.5" value={processDataset?.id}>
              <div className="flex w-full justify-between gap-0.5">
                <div className="flex w-3/5 items-center gap-2">
                  <TypeTag type={processDataset?.data_type ?? "NA"} className="h-4 w-6 shrink-0 text-[10px]" hideText />
                  <div className="truncate text-left text-sm">{processDataset?.name}</div>
                </div>
                <div className="inline-flex w-2/5 items-center justify-end gap-2 text-sm">
                  <div className="flex items-center truncate text-right">
                    {processDataset?.data_type === DataType.Checkbox && (
                      <Checkbox checked={(datasetIdToLatestDatapointMap[processDataset.id] as CheckboxType)?.is_checked ?? false} />
                    )}
                    {processDataset?.data_type === DataType.Datetime &&
                      moment((datasetIdToLatestDatapointMap[processDataset.id] as DatetimeData)?.value).format("MM/DD/YY")}
                    {processDataset?.data_type === DataType.ParametricQuantitative &&
                      `${(datasetIdToLatestDatapointMap[processDataset.id] as ParametricQuantitative)?.value.toFixed(2) ?? ""}`}
                    {((processDataset?.data_type === DataType.ParametricQualitative || processDataset?.data_type === DataType.PassFail) &&
                      (datasetIdToLatestDatapointMap[processDataset.id] as ParametricQualitative)?.value) ??
                      ""}
                    {(processDataset?.data_type === DataType.File || processDataset?.data_type === DataType.Image) &&
                      formatFileSize(fileSizes[processDataset.id])}
                    {(processDataset?.data_type === DataType.Link &&
                      (datasetIdToLatestDatapointMap[processDataset.id] as UniqueIdentifierLink)?.unique_identifiers?.identifier) ??
                      ""}
                  </div>
                  <TypeTag type={datasetResult} className="h-4 w-6 shrink-0 text-[10px]" hideText />
                </div>
              </div>
            </SidebarSelector.Item>
          );
        })}
        {processDatasets.length === 0 && <div className="my-6 text-sm italic">No data collected</div>}
      </SidebarSelector.List>
    </SidebarSelector.Root>
  );
};

export default DatasetSelector;
