import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@shared/utils/tailwind";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "border-serial-palette-300 data-[state=checked]:border-serial-palette-900 focus-visible:ring-serial-palette-600 data-[state=checked]:bg-serial-palette-900 data-[state=checked]:text-serial-palette-50 peer h-4 w-4 shrink-0 rounded-[4px] border ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-white")}>
      <FontAwesomeIcon icon={faCheck} className="h-3 w-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export default Checkbox;
