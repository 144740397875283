import { useContext, useRef } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { CheckboxField } from "@shared/types/databaseTypes";
import { ProcessBuilderFieldContentsProps } from "../../types";
import ContentEditable from "@shared/components/ContentEditable";

const ProcessBuilderFieldCheckbox: React.FC<ProcessBuilderFieldContentsProps<CheckboxField>> = ({ field, stepIndex }) => {
  const { readOnly, handleUpdateFields } = useContext(ProcessBuilderContext);
  const promptRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex w-full items-center gap-x-2">
      <input type="checkbox" className="form-checkbox bg-serial-palette-100" disabled />
      <ContentEditable
        contentRef={promptRef}
        onInput={() => handleUpdateFields(stepIndex, [field.id], "prompt", promptRef.current?.innerText)}
        className="input-box w-full text-sm outline-0"
        placeholder="Checkbox prompt..."
        content={field.prompt}
        readOnly={readOnly}
      />
    </div>
  );
};

export default ProcessBuilderFieldCheckbox;
