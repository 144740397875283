import { WorkInstructionBlock, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import { ProcessBuilderDraggableType } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useContext, useMemo, useRef } from "react";
import type { Identifier } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { v4 as uuidv4 } from "uuid";
import ProcessBuilderWorkInstructionBlockHeading from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockHeading";
import ProcessBuilderWorkInstructionBlockText from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockText";
import ProcessBuilderWorkInstructionBlockList from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockList";
import ProcessBuilderWorkInstructionBlockCallout from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockCallout";
import ProcessBuilderWorkInstructionBlockCode from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockCode";
import ProcessBuilderWorkInstructionBlockDelimiter from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockDelimiter";
import ProcessBuilderWorkInstructionBlockFile from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockFile";
import ProcessBuilderWorkInstructionEditDropdown from "./ProcessBuilderWorkInstructionEditDropdown";
import { ProcessBuilderAddWorkInstructionBlockDropdown } from "./ProcessBuilderAddDropdown";
import ProcessBuilderWorkInstructionBlockTable from "./work-instruction-blocks/ProcessBuilderWorkInstructionBlockTable";
import { getYHemisphere } from "@shared/utils/window";

interface ProcessBuilderWorkInstructionBlockProps {
  workInstructionBlock: WorkInstructionBlock;
  stepIndex: number;
  workInstructionBlockIndex: number;
  placeholderInputRef: React.RefObject<HTMLDivElement>;
}

const ProcessBuilderWorkInstructionBlock: React.FC<ProcessBuilderWorkInstructionBlockProps> = ({
  workInstructionBlock,
  stepIndex,
  workInstructionBlockIndex,
  placeholderInputRef,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const {
    readOnly,
    hoveredWorkInstructionBlockId,
    handleSetHoveredWorkInstructionBlock,
    setHoveredWorkInstructionBlockId,
    handleMoveWorkInstructionBlock,
    handleDeleteWorkInstructionBlock,
    pulseWorkInstructions,
  } = useContext(ProcessBuilderContext);

  const workInstructionBlockId = useMemo(() => workInstructionBlock?.id ?? uuidv4(), [workInstructionBlock]);

  const [{ handlerId }, drop] = useDrop<WorkInstructionBlock, void, { handlerId: Identifier | null }>({
    accept: ProcessBuilderDraggableType.WorkInstructionBlock,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: WorkInstructionBlock, monitor) {
      const clientOffset = monitor.getClientOffset();
      if (!ref.current || !clientOffset) return;
      const hemisphere = getYHemisphere(ref, clientOffset);
      handleMoveWorkInstructionBlock(item.id, stepIndex, workInstructionBlockIndex - (hemisphere === "top" ? 1 : 0));
    },
    hover(_item: WorkInstructionBlock, monitor) {
      const clientOffset = monitor.getClientOffset();
      if (!ref.current || !clientOffset) return;
      const hemisphere = getYHemisphere(ref, clientOffset);
      handleSetHoveredWorkInstructionBlock(stepIndex, workInstructionBlockIndex + (hemisphere === "top" ? 0 : 1));
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ProcessBuilderDraggableType.WorkInstructionBlock,
    item: () => {
      return { ...workInstructionBlock };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setHoveredWorkInstructionBlockId(null);
    },
  });

  drop(ref);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={`group relative flex w-full items-start ${pulseWorkInstructions && "animate-pulse"} ${isDragging && "opacity-40"}`}
      onDragLeave={() => setHoveredWorkInstructionBlockId(null)}
    >
      {!readOnly && (
        <div className="absolute top-1 flex gap-x-1.5">
          <ProcessBuilderAddWorkInstructionBlockDropdown stepIndex={stepIndex} tileIndex={workInstructionBlockIndex} />
          <button tabIndex={-1} ref={drag}>
            <FontAwesomeIcon
              className="text-serial-palette-400 hover:text-serial-palette-600 handle hidden group-hover:flex"
              icon={faGripVertical}
            />
          </button>
        </div>
      )}
      {!readOnly && (
        <div className="absolute right-1 top-1 flex justify-center gap-0.5">
          <ProcessBuilderWorkInstructionEditDropdown
            stepIndex={stepIndex}
            workInstructionBlockIndex={workInstructionBlockIndex}
            workInstructionBlock={workInstructionBlock}
          />
          <button tabIndex={-1} className="-ml-1" onClick={() => handleDeleteWorkInstructionBlock(stepIndex, workInstructionBlockIndex)}>
            <FontAwesomeIcon
              className="text-serial-palette-400 hover:text-serial-palette-600 handle hidden group-hover:flex"
              icon={faTimes}
            />
          </button>
        </div>
      )}
      <div
        ref={preview}
        className={`mx-10 flex min-w-0 flex-grow rounded border border-transparent ${!readOnly && "group-hover:border-serial-palette-100"} p-2`}
      >
        {workInstructionBlock.type === WorkInstructionBlockType.Heading && (
          <ProcessBuilderWorkInstructionBlockHeading
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
            placeholderInputRef={placeholderInputRef}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.Text && (
          <ProcessBuilderWorkInstructionBlockText
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
            placeholderInputRef={placeholderInputRef}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.List && (
          <ProcessBuilderWorkInstructionBlockList
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.Callout && (
          <ProcessBuilderWorkInstructionBlockCallout
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
            placeholderInputRef={placeholderInputRef}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.Code && (
          <ProcessBuilderWorkInstructionBlockCode
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.Delimiter && (
          <ProcessBuilderWorkInstructionBlockDelimiter
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.Image && (
          <ProcessBuilderWorkInstructionBlockFile
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.PDF && (
          <ProcessBuilderWorkInstructionBlockFile
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.Video && (
          <ProcessBuilderWorkInstructionBlockFile
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.File && (
          <ProcessBuilderWorkInstructionBlockFile
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
        {workInstructionBlock.type === WorkInstructionBlockType.Table && (
          <ProcessBuilderWorkInstructionBlockTable
            block={workInstructionBlock}
            stepIndex={stepIndex}
            blockIndex={workInstructionBlockIndex}
          />
        )}
      </div>
      <div
        className={`border-serial-palette-100 absolute -top-0.5 w-full border-b-4 transition-opacity ${workInstructionBlockId === hoveredWorkInstructionBlockId ? "opacity-100" : "opacity-0"}`}
      />
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlock;
