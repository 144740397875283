import { useContext, useMemo, useState } from "react";
import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import DebugFileIntegrity from "./components/DebugFileIntegrity";
import { DeviceUUID } from "device-uuid";
import DebugTokens from "./components/DebugTokens";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import DebugSupabaseIn from "./components/DebugSupabaseIn";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import DebugCompanySeed from "./components/DebugCompanySeed";
import DebugFfmpeg from "./components/DebugFfmpeg";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import DebugDymoPrinter from "./components/DebugDymoPrinter";
import DebugImageMarkup from "./components/DebugImageMarkup";

function Debug() {
  const [triggerCrash, setTriggerCrash] = useState(false);
  const db = useSelector((state: RootState) => state.db);
  const observe = useContext(ObservabilityContext);
  const { triggerToast, triggerConfirmation } = useContext(ToastContext);

  const deviceUuid = useMemo(() => {
    try {
      const newDeviceUuid = new DeviceUUID().get();
      return newDeviceUuid;
    } catch (error) {
      return "null";
    }
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header />

        <div className="flex w-full flex-col gap-y-4 p-8">
          <DebugFileIntegrity />

          <div className="flex w-full items-center justify-between">
            <h1 className="text-lg">
              <span className="font-bold">Device UUID:</span> Generated by device-uuid
            </h1>
            <div className="rounded-lg bg-white px-2 py-0.5">
              <pre>{deviceUuid}</pre>
            </div>
          </div>

          <div className="flex w-full items-center justify-between">
            <h1 className="text-lg">
              <span className="font-bold">Errors Testing (General):</span> Throw and error
            </h1>
            <button
              className="btn w-36 bg-black text-white"
              onClick={() => {
                triggerToast(ToastType.Error, "Debug Page", "Testing error catching");
                throw new Error("Testing error catching");
              }}
            >
              Throw Error
            </button>
          </div>

          <div className="flex w-full items-center justify-between">
            <h1 className="text-lg">
              <span className="font-bold">Errors Testing (Production App Upload):</span> Throw an error as if it were thrown from the
              production app after "Submit"
            </h1>
            <button
              className="btn w-36 bg-black text-white"
              onClick={() => {
                triggerToast(ToastType.Error, "Debug Page", "Error with highlight logging (like production app after Submit");
                observe.track("error-create-process-entry");
                throw new Error("Testing error catching for production app upload");
              }}
            >
              Throw Error
            </button>
          </div>

          <div className="flex w-full items-center justify-between">
            <h1 className="text-lg">
              <span className="font-bold">Confirmation Testing:</span> Triggers the confirmation dialog
            </h1>
            <button
              className="btn w-36 bg-black text-white"
              onClick={() => {
                triggerConfirmation(
                  "Debug Page",
                  "Testing confirmation dialog",
                  () => {
                    console.log("Confirmed");
                  },
                  () => {
                    console.log("Cancelled");
                  },
                  "Gotcha",
                  "Get Me Outta Here",
                );
              }}
            >
              Trigger Confirmation
            </button>
          </div>

          <DebugTokens />

          <div className="flex w-full items-center justify-between">
            <h1 className="text-lg">
              <span className="font-bold">Redux DB:</span> Local copy of the DB in redux. Use{" "}
              <a className="text-blue-500 underline" href={"https://jsonhero.io/"}>
                jsonhero.io
              </a>{" "}
              to parse
            </h1>
            <div className="flex max-w-[50%] rounded-lg bg-white px-2 py-0.5">
              <pre className="truncate">{JSON.stringify(db) ?? ""}</pre>
              <CopyToClipboard text={JSON.stringify(db) ?? ""} />
            </div>
          </div>

          <div className="flex w-full items-center justify-between">
            <h1 className="text-lg">
              <span className="font-bold">Trigger Crash:</span> Creates an error that will cause app to crash and hit error boundary
            </h1>
            <button className="btn w-36 bg-black text-white" onClick={() => setTriggerCrash(true)}>
              Trigger Crash
            </button>
            {/* @ts-ignore */}
            {triggerCrash && <div>{error.error.error}</div>}
          </div>

          <DebugSupabaseIn />

          <DebugCompanySeed />

          <DebugFfmpeg />

          <DebugDymoPrinter />

          <DebugImageMarkup />
        </div>
      </div>
    </div>
  );
}

export default Debug;
