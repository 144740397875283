import React, { useState, useRef, useEffect } from "react";
import Transition from "../Transition";

function DropdownCustom({
  align,
  className,
  buttonContents,
  dropdownClassName,
  children,
  keepOpen = false,
  closeOnMouseLeave = false,
  inputRef = null,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    if (dropdownOpen && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [dropdownOpen, inputRef]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // Functions and effect for closing dropdown on mouse leave
  const handleMouseLeave = () => {
    if (!closeOnMouseLeave) return;
    if (closeOnMouseLeave) document.addEventListener("mousemove", handleMouseMove);
  };

  const handleMouseEnter = () => {
    if (!closeOnMouseLeave) return;
    document.removeEventListener("mousemove", handleMouseMove);
  };

  const handleMouseMove = (e) => {
    if (!closeOnMouseLeave || !dropdown.current) return;
    const bounds = dropdown.current.getBoundingClientRect();
    const outOfBounds =
      e.clientX < bounds.left - 50 || e.clientX > bounds.right + 50 || e.clientY < bounds.top - 50 || e.clientY > bounds.bottom + 50;

    if (outOfBounds) {
      setDropdownOpen(false);
      document.removeEventListener("mousemove", handleMouseMove);
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="relative flex">
      <button ref={trigger} className={className} onClick={() => setDropdownOpen(!dropdownOpen)} tabIndex={-1}>
        {buttonContents}
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`border-serial-palette-200 min-w-36 absolute top-full z-20 mt-1 origin-top-right overflow-hidden rounded border bg-white  pt-1.5 shadow ${align === "right" ? "right-0" : "left-0"} ${dropdownClassName}`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onClick={() => {
            if (!keepOpen) setDropdownOpen(false);
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownCustom;
