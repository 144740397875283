export const renderableExtensions = [
  "html",
  "htm",
  "css",
  "js",
  "json",
  "xml",
  "png",
  "jpeg",
  "jpg",
  "gif",
  "svg",
  "pdf",
  "bmp",
  "ico",
  "tiff",
  "tif",
  "webp",
  "mp4",
  "m4v",
  "webm",
  "ogg",
  "ogv",
  "avi",
  "mov",
];
