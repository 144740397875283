import React, { ReactElement, createContext, useEffect, useState } from "react";
import Toast, { ToastType } from "@shared/components/Toast";
import Confirmation from "@shared/components/Confirmation";

interface ToastContextProps {
  triggerToast: (type: ToastType, title: string | ReactElement, message: string | ReactElement, time?: number) => void;
  triggerConfirmation: (
    title: string | ReactElement,
    message: string | ReactElement,
    onConfirm: () => void,
    onCancel?: () => void,
    confirmText?: string | ReactElement,
    cancelText?: string | ReactElement,
    hideCancel?: boolean,
  ) => void;
}

// Create a default context value for ToastContext to avoid having to initialize the context with undefined
const defaultContext: ToastContextProps = {
  triggerToast: () => {},
  triggerConfirmation: () => {},
};

const DEFAULT_TIMEOUT = 5000;

export const ToastContext = createContext<ToastContextProps>(defaultContext);

export const ToastProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const [title, setTitle] = useState<string | ReactElement>("");
  const [message, setMessage] = useState<string | ReactElement>("");
  const [time, setTime] = useState<number>(DEFAULT_TIMEOUT);

  const [toastType, setToastType] = useState<ToastType>(ToastType.Info);
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [confirmationOnConfirm, setConfirmationOnConfirm] = useState<() => void>(() => () => console.log("onConfirm not set"));
  const [confirmationOnCancel, setConfirmationOnCancel] = useState<() => void>(() => () => console.log("onCancel not set"));
  const [confirmationConfirmText, setConfirmationConfirmText] = useState<string | ReactElement>("");
  const [confirmationCancelText, setConfirmationCancelText] = useState<string | ReactElement>("");
  const [confirmationHideCancel, setConfirmationHideCancel] = useState<boolean>(false);

  const triggerToast = (type: ToastType, title: string | ReactElement, message: string | ReactElement, time?: number) => {
    setToastType(type);
    setTitle(title);
    setMessage(message);
    setTime(time ?? DEFAULT_TIMEOUT);
    setToastOpen(true);
  };

  const triggerConfirmation = (
    title: string | ReactElement,
    message: string | ReactElement,
    onConfirm: () => void,
    onCancel?: () => void,
    confirmText?: string | ReactElement,
    cancelText?: string | ReactElement,
    hideCancel?: boolean,
  ) => {
    setTitle(title);
    setMessage(message);
    setConfirmationOnConfirm(() => onConfirm);
    setConfirmationOnCancel(() => onCancel ?? (() => () => console.log("onCancel not set")));
    setConfirmationConfirmText(confirmText ?? "Confirm");
    setConfirmationCancelText(cancelText ?? "Cancel");
    setConfirmationOpen(true);
    setConfirmationHideCancel(hideCancel ?? false);
  };

  // Reset toast state when toast is closed. Delay 500ms to allow animation to complete
  useEffect(() => {
    if (!toastOpen) {
      setTimeout(() => {
        setMessage("");
        setToastType(ToastType.Info);
        setTitle("");
        setTime(DEFAULT_TIMEOUT);
      }, 500);
    }
  }, [toastOpen]);

  // Reset confirmation state when confirmation is closed. Delay 500ms to allow animation to complete
  useEffect(() => {
    if (!confirmationOpen) {
      setTimeout(() => {
        setMessage("");
        setTitle("");
        setConfirmationOnConfirm(() => () => console.log("onConfirm not set"));
        setConfirmationOnCancel(() => () => console.log("onCancel not set"));
        setConfirmationConfirmText("");
        setConfirmationCancelText("");
      }, 500);
    }
  }, [confirmationOpen]);

  return (
    <ToastContext.Provider value={{ triggerToast, triggerConfirmation }}>
      {children}
      <Toast
        xAlign="right"
        yAlign="bottom"
        type={toastType}
        toggle={toastOpen}
        setToggle={(value: boolean) => setToastOpen(value)}
        title={title}
        message={message}
        timeout={time}
      />
      <Confirmation
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        title={title}
        message={message}
        onConfirm={confirmationOnConfirm}
        onCancel={confirmationOnCancel}
        confirmText={confirmationConfirmText}
        cancelText={confirmationCancelText}
        hideCancel={confirmationHideCancel}
      />
    </ToastContext.Provider>
  );
};
