import store from "@shared/redux/store";
import { makeFetchRequest } from "./helpers";
import { ApiKey, DeletedApiKey } from "@shared/types/apiTypes";

const apiUrl: string = import.meta.env.VITE_APP_SERIAL_API_URL;

export async function getApiKeys(): Promise<ApiKey[]> {
  const auth: string = `Bearer ${store.getState().auth.token}`;
  const options: RequestInit = { method: "GET", headers: { Authorization: auth } };

  const { data, error } = await makeFetchRequest(`${apiUrl}/auth/key`, options, "Error fetching API keys", 10000);
  if (error) {
    console.error(error);
    return [];
  }
  return data;
}

export async function deleteApiKey(name: string): Promise<DeletedApiKey> {
  const auth: string = `Bearer ${store.getState().auth.token}`;
  const body: BodyInit = JSON.stringify({ name: name });
  const options: RequestInit = { method: "DELETE", headers: { Authorization: auth, "Content-Type": "application/json" }, body: body };

  const { data, error } = await makeFetchRequest(`${apiUrl}/auth/key`, options, "Error deleting API key", 10000);
  if (error) {
    console.error(error);
    return { name: "" };
  }
  return data;
}

export async function createApiKey(name: string): Promise<ApiKey> {
  const body: BodyInit = JSON.stringify({ name: name });
  const options: RequestInit = {
    method: "POST",
    headers: { Authorization: `Bearer ${store.getState().auth.token}`, "Content-Type": "application/json" },
    body: body,
  };
  const failedApiKey: ApiKey = {
    key: "",
    name: "",
    permissions: [],
    created_at: "1970-01-01T00:00:00.000Z",
  };

  const { data, error } = await makeFetchRequest(`${apiUrl}/auth/key`, options, "Error creating API key", 10000);
  if (error) {
    console.error(error);
    return failedApiKey;
  }
  return data;
}
