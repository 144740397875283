import { useContext, useState } from "react";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import Button from "@shared/components/primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMinimize as faWindowMinimizeThin } from "@fortawesome/free-regular-svg-icons";
import { faBold, faCheck, faFill, faItalic, faTimes, faTint, faUnderline, faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import DropdownMenu from "@shared/components/primitives/DropdownMenu";
import { imageMarkupElementConfig } from "../types";
import { ImageMarkupElementType } from "@shared/types/databaseTypes";
import ImageMarkupPaletteColorPicker from "./ImageMarkupPalletColorPicker";

const defaultLineThickness = [3, 4, 6, 8];

const ImageMarkupPalette = () => {
  const {
    imageWidth,
    handleAddElement,
    focusedElementIndex,
    focusedElement,
    focusedElementConfig,
    handleEditElement,
    handleCancel,
    handleSave,
  } = useContext(ImageMarkupContext);

  // color is changed optimistically on hover, if no color is selected, fallback to the previous color
  const [fallbackColor, setFallbackColor] = useState<string | null | undefined>(undefined);
  const [fallbackThickness, setFallbackThickness] = useState<number | undefined>(undefined);

  // unpack the specific formatting attributes of the focused element
  const color = focusedElement && "color" in focusedElement ? focusedElement.color : null;
  const fill = focusedElement && "fill" in focusedElement ? focusedElement.fill : null;
  const bold = focusedElement && "bold" in focusedElement ? focusedElement.bold : false;
  const italic = focusedElement && "italic" in focusedElement ? focusedElement.italic : false;
  const underline = focusedElement && "underline" in focusedElement ? focusedElement.underline : false;

  return (
    <div style={{ width: imageWidth }} className="flex h-10 min-w-[500px] items-center justify-between gap-x-1.5 px-1.5">
      <div className="flex items-center gap-x-1.5">
        {Object.entries(imageMarkupElementConfig).map(([elementType, elementConfig]) => (
          <Button
            key={elementType}
            size="sm"
            symmetric
            variant="ghost"
            tooltip={elementConfig.tooltip}
            onClick={() => handleAddElement(elementType as ImageMarkupElementType)}
          >
            <FontAwesomeIcon icon={elementConfig.icon} className={elementConfig.className} />
          </Button>
        ))}
        <div className="h-6 shrink-0 border-l" />
        <ImageMarkupPaletteColorPicker
          disabled={!focusedElementConfig?.variableColor}
          color={color}
          icon={faTint}
          tooltip="Color"
          onOpen={() => setFallbackColor(color ?? "#000000")}
          onColorHover={(newColor) =>
            fallbackColor !== undefined &&
            focusedElementIndex !== null &&
            newColor &&
            handleEditElement(focusedElementIndex, { color: newColor })
          }
          onLeave={() => focusedElementIndex !== null && fallbackColor && handleEditElement(focusedElementIndex, { color: fallbackColor })}
          onColorChange={(newColor) => {
            focusedElementIndex !== null && newColor && handleEditElement(focusedElementIndex, { color: newColor });
            setFallbackColor(undefined);
          }}
          allowTransparent={false}
        />
        <ImageMarkupPaletteColorPicker
          disabled={!focusedElementConfig?.variableFillColor}
          color={fill}
          icon={faFill}
          tooltip="Fill"
          onOpen={() => setFallbackColor(fill ?? "#000000")}
          onColorHover={(newFill) =>
            fallbackColor !== undefined &&
            focusedElementIndex !== null &&
            newFill &&
            handleEditElement(focusedElementIndex, { fill: newFill })
          }
          onLeave={() =>
            focusedElementIndex !== null && fallbackColor !== undefined && handleEditElement(focusedElementIndex, { fill: fallbackColor })
          }
          onColorChange={(newFill) => {
            focusedElementIndex !== null && handleEditElement(focusedElementIndex, { fill: newFill });
            setFallbackColor(undefined);
          }}
          allowTransparent={true}
        />
        <DropdownMenu.Root
          onOpenChange={(open) => focusedElement && "thickness" in focusedElement && open && setFallbackThickness(focusedElement.thickness)}
        >
          <DropdownMenu.Trigger asChild disabled={!focusedElementConfig?.variableLineThickness}>
            <Button
              size="sm"
              symmetric
              variant="ghost"
              disabled={!focusedElementConfig?.variableLineThickness}
              className="relative"
              tooltip="Line Thickness"
            >
              <FontAwesomeIcon icon={faWindowMinimize} className="absolute left-[9px] top-0 rotate-[20deg]" />
              <FontAwesomeIcon icon={faWindowMinimizeThin} className="absolute left-[9px] top-1.5 rotate-[20deg]" />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            className="!w-12 !min-w-0"
            sideOffset={10}
            onMouseLeave={() =>
              focusedElementIndex !== null &&
              fallbackThickness !== undefined &&
              handleEditElement(focusedElementIndex, { thickness: fallbackThickness })
            }
          >
            <DropdownMenu.Group>
              {defaultLineThickness.map((thickness, index) => (
                <DropdownMenu.Item
                  key={index}
                  className="h-7 justify-center"
                  onSelect={() => {
                    focusedElementIndex !== null && handleEditElement(focusedElementIndex, { thickness: thickness });
                    setFallbackThickness(undefined);
                  }}
                  onMouseEnter={() => focusedElementIndex !== null && handleEditElement(focusedElementIndex, { thickness: thickness })}
                >
                  <div className={`w-full bg-black`} style={{ height: `${thickness}px` }} />
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Group>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        <Button
          size="sm"
          symmetric
          variant="ghost"
          disabled={!focusedElementConfig?.variableTextStyle}
          className={bold ? "bg-serial-palette-100" : ""}
          tooltip="Bold"
          onClick={() =>
            focusedElement?.type === ImageMarkupElementType.Text &&
            focusedElementIndex !== null &&
            handleEditElement(focusedElementIndex, { bold: !focusedElement.bold })
          }
        >
          <FontAwesomeIcon icon={faBold} />
        </Button>
        <Button
          size="sm"
          symmetric
          variant="ghost"
          disabled={!focusedElementConfig?.variableTextStyle}
          className={italic ? "bg-serial-palette-100" : ""}
          tooltip="Italic"
          onClick={() =>
            focusedElement?.type === ImageMarkupElementType.Text &&
            focusedElementIndex !== null &&
            handleEditElement(focusedElementIndex, { italic: !focusedElement.italic })
          }
        >
          <FontAwesomeIcon icon={faItalic} />
        </Button>
        <Button
          size="sm"
          symmetric
          variant="ghost"
          disabled={!focusedElementConfig?.variableTextStyle}
          className={underline ? "bg-serial-palette-100" : ""}
          tooltip="Underline"
          onClick={() =>
            focusedElement?.type === ImageMarkupElementType.Text &&
            focusedElementIndex !== null &&
            handleEditElement(focusedElementIndex, { underline: !focusedElement.underline })
          }
        >
          <FontAwesomeIcon icon={faUnderline} />
        </Button>
      </div>
      <div className="flex items-center gap-x-1">
        <Button size="sm" symmetric variant="outline" tooltip="Cancel" onClick={handleCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Button size="sm" symmetric tooltip="Save" onClick={handleSave}>
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </div>
    </div>
  );
};

export default ImageMarkupPalette;
