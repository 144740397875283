import React from "react";
import serialLogo from "@images/serial_primary_black.png";
import { Link } from "react-router-dom";

function VerifyEmail() {
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white">
        <main>
          <div className="bg-serial-palette-900 flex h-screen items-center justify-center">
            <div className="text-serial-palette-800 flex h-2/5 w-96  flex-col items-center justify-between rounded bg-white">
              <div></div>
              <div className="flex flex-col items-center justify-between">
                <img src={serialLogo} alt="" className="h-28" />
                <div className="text-2xl">Welcome to Serial</div>
                <div className="pt-4 text-center text-base">
                  Please check your email for <br /> a verification link
                </div>
              </div>
              <Link
                className="text-serial-palette-300 hover:text-serial-palette-600 flex items-center pb-3 text-sm font-medium"
                to="#0"
                onClick={() => (window.location.href = "mailto:founders@serial.io")}
              >
                Need help?
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default VerifyEmail;
