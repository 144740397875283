import { DashboardContextProps, DashboardInsightConfigPropertyType, DashboardInsightDefaultProperties } from "./types";
import StatusCountBarGraph from "./components/insights/StatusCountBarGraph";
import AlertsClassic from "./components/insights/AlertsClassic";
import LatestActivity from "./components/insights/LatestActivity";
import YieldByComponent from "./components/insights/YieldByComponent";
import BuildTracker from "./components/insights/BuildTracker";
import WipTracker from "./components/insights/WipTracker";
import WipByProcessChart from "./components/insights/WipByProcessChart";
import CycleTimeByComponent from "./components/insights/CycleTimeByComponent";
import CycleTimeByProcess from "./components/insights/CycleTimeByProcess";
import YieldByProcess from "./components/insights/YieldByProcess";
import { ReportTemplateDashboard } from "@shared/types/databaseTypes";
import { DashboardInsightType, UniqueIdentifierStatus, ReportTemplateType } from "@shared/types/databaseEnums";
import { svgBarGraph, svgDashboardPie, svgMap, svgTimePast } from "./svg";
import i18next from "@shared/i18n/config";

export const defaultContext: DashboardContextProps = {
  dashboards: [],
  selectedDashboardId: null,
  isNewDashboard: false,
  setSelectedDashboardId: () => {},
  handleCreateDashboard: () => {
    return new Promise(() => {});
  },
  handleDuplicateDashboard: () => {
    return new Promise(() => {});
  },
  handleUpdateDashboard: () => {
    return new Promise(() => {});
  },
  handleDeleteDashboard: () => {
    return new Promise(() => {});
  },
  handleAddInsightToSelectedDashboard: () => {
    return new Promise(() => {});
  },
  handleRemoveInsightFromSelectedDashboard: () => {
    return new Promise(() => {});
  },
  customInsights: [],
  targetDashboardRow: null,
  setTargetDashboardRow: () => {},
  insightModalOpen: false,
  setInsightModalOpen: () => {},
  handleCreateCustomInsight: () => {},
  handleUpdateCustomInsight: () => {},
  handleDeleteCustomInsight: () => {},
  dashboardEditModalOpen: false,
  setDashboardEditModalOpen: () => {},
};

// -------------- Dashboards Insight Defualts -------------- //

export const dashboardInsightDefaultProperties: Record<string, DashboardInsightDefaultProperties> = {
  [DashboardInsightType.StatusCountBarGraph]: {
    title: "Status Counts",
    description: "Bar graphs counting number of WIP, Complete, and Defective units",
    defaultWidth: "sm",
    minWidth: "sm",
    allowFullscreen: true,
    body: StatusCountBarGraph,
    configSchema: [
      {
        type: DashboardInsightConfigPropertyType.Select,
        options: {
          [UniqueIdentifierStatus.Complete]: "Complete",
          [UniqueIdentifierStatus.Wip]: "WIP",
          [UniqueIdentifierStatus.Defective]: "Defective",
        },
        key: "status",
        label: "Status",
      },
    ],
  },
  [DashboardInsightType.WipTracker]: {
    title: "WIP Tracker",
    description: "Table of active WIP parts",
    defaultWidth: "lg",
    minWidth: "lg",
    allowFullscreen: true,
    body: WipTracker,
    configSchema: [],
  },
  [DashboardInsightType.Alerts]: {
    title: "Alerts",
    description: "Table of active Serial alerts",
    defaultWidth: "sm",
    minWidth: "sm",
    allowFullscreen: true,
    body: AlertsClassic,
    configSchema: [],
  },
  [DashboardInsightType.LatestActivity]: {
    title: "Latest Activity",
    description: "Table of latest activity including most recently completed process",
    defaultWidth: "md",
    minWidth: "sm",
    allowFullscreen: true,
    body: LatestActivity,
    configSchema: [],
  },
  [DashboardInsightType.YieldByProcess]: {
    title: "Process Yield",
    description: "FPY and APY",
    defaultWidth: "lg",
    minWidth: "md",
    allowFullscreen: true,
    body: YieldByProcess,
    configSchema: [],
  },
  [DashboardInsightType.YieldByComponent]: {
    title: "Rolling Cumulative Yield",
    description: "FPY and APY",
    defaultWidth: "lg",
    minWidth: "md",
    allowFullscreen: true,
    body: YieldByComponent,
    configSchema: [],
  },
  [DashboardInsightType.BuildTracker]: {
    title: "Build Tracker",
    description: "Table of active builds",
    defaultWidth: "xl",
    minWidth: "lg",
    allowFullscreen: true,
    body: BuildTracker,
    configSchema: [],
  },
  [DashboardInsightType.WipByProcessChart]: {
    title: "WIP by Process",
    description: "Table of active builds",
    defaultWidth: "lg",
    minWidth: "md",
    allowFullscreen: true,
    body: WipByProcessChart,
    configSchema: [],
  },
  [DashboardInsightType.CycleTimeByComponent]: {
    title: "Cycle Time by Component",
    description: "Line graph of CT by component",
    defaultWidth: "lg",
    minWidth: "md",
    allowFullscreen: true,
    body: CycleTimeByComponent,
    configSchema: [],
  },
  [DashboardInsightType.CycleTimeByProcess]: {
    title: "Cycle Time by Process",
    description: "Line graph of CT by process",
    defaultWidth: "lg",
    minWidth: "md",
    allowFullscreen: true,
    body: CycleTimeByProcess,
    configSchema: [],
  },
};

export const minRowHeight = 300;
export const maxRowHeight = 800;

// -------------- Pre-built Dashboards -------------- //

const preBuiltDashboardNullData: Omit<ReportTemplateDashboard, "id" | "name" | "description" | "config"> = {
  revision: 1,
  company_id: "",
  type: ReportTemplateType.Dashboard,
  created_at: "",
  created_by: "",
  last_edited_at: "",
  last_edited_by: "",
  is_public: true,
  shared_with: {},
  is_latest_revision: true,
};

export const preBuiltDashboards: ReportTemplateDashboard[] = [
  {
    ...preBuiltDashboardNullData,
    id: "yield-dashboard",
    name: "Yield Dashboard",
    description: "Yield breakdown for any given component over any timeframe",
    is_public: false,
    config: {
      is_prebuilt: true,
      icon: svgBarGraph,
      rows: [
        {
          height: 440,
          insights: [
            {
              type: DashboardInsightType.YieldByComponent,
              config: {},
            },
          ],
        },
        {
          height: 440,
          insights: [
            {
              type: DashboardInsightType.YieldByProcess,
              config: {},
            },
          ],
        },
      ],
    },
  },
  {
    ...preBuiltDashboardNullData,
    id: "cycle-time",
    name: "Cycle Time",
    description: "Cycle time breakdown for a given component",
    config: {
      is_prebuilt: true,
      icon: svgTimePast,
      rows: [
        {
          height: 440,
          insights: [
            {
              type: DashboardInsightType.CycleTimeByComponent,
              config: {},
            },
          ],
        },
        {
          height: 440,
          insights: [
            {
              type: DashboardInsightType.CycleTimeByProcess,
              config: {},
            },
          ],
        },
      ],
    },
  },
  {
    ...preBuiltDashboardNullData,
    id: "wip-tracker",
    name: "WIP Tracking",
    description: "Summary of active WIP for a given component",
    config: {
      is_prebuilt: true,
      icon: svgMap,
      rows: [
        {
          height: 1000,
          insights: [
            {
              type: DashboardInsightType.WipTracker,
              config: {},
            },
            {
              type: DashboardInsightType.WipByProcessChart,
              config: {},
            },
          ],
        },
      ],
    },
  },
  {
    ...preBuiltDashboardNullData,
    id: "build-tracker",
    name: "Build Tracker",
    description: `All production, draft and review ${i18next.t("workOrders").toLowerCase()}`,
    config: {
      is_prebuilt: true,
      icon: svgDashboardPie,
      rows: [
        {
          height: 1000,
          insights: [
            {
              type: DashboardInsightType.BuildTracker,
              config: {},
            },
          ],
        },
      ],
    },
  },
];

function updateTranslations() {
  preBuiltDashboards[3].description = `All production, draft and review ${i18next.t("workOrders").toLowerCase()}`;
}

// Initial translation if i18nextext is ready
if (i18next.isInitialized) {
  updateTranslations();
}

// Update translations on language change
i18next.on("languageChanged", () => {
  updateTranslations();
});

// Update translations when translations are loaded
i18next.on("loaded", () => {
  updateTranslations();
});
