import React, { Component } from "react";

class ErrorBoundaryFullPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    console.error(error);
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex h-screen w-screen items-center justify-center">
          <div className="bg-serial-palette-50 border-serial-palette-700 flex w-96 shrink-0 flex-col gap-y-2 rounded border p-8">
            <h2 className="w-full text-center font-bold">Oops. Something went wrong.</h2>
            <p className="text-serial-palette-800 text-center text-sm">
              Try refreshing the page or contact Serial support if the issue persists.
            </p>
            <div className="flex w-full justify-center pb-6 pt-2">
              <button className="btn bg-serial-palette-800 hover:bg-serial-palette-600 text-white" onClick={this.handleRefresh}>
                Refresh Page
              </button>
            </div>
            <details className="text-sm" style={{ whiteSpace: "pre-wrap" }}>
              <span className="italic text-red-500">{this.state.error && this.state.error.toString()} </span>
            </details>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundaryFullPage;
