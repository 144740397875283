import CopyToClipboard from "@shared/components/CopyToClipboard";
import ProcessBuilderApiDatasetTable from "./ProcessBuilderApiDatasetTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { useContext } from "react";

const installation = "pip install serialmfg";

const ProcessBuilderApiInfo: React.FC = () => {
  const { draftProcess } = useContext(ProcessBuilderContext);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col gap-3 overflow-scroll p-3">
      <div className="flex w-full flex-col rounded-md border bg-white p-6">
        {/* ------- Constants and Keys ------- */}
        <div className="mb-3 text-xl font-bold">Uploads with the Serial Python Library</div>

        {/* Installation */}
        <div className="pb-1 font-semibold">Installation</div>
        <div className="mb-3 flex w-full items-center">
          <div className="flex h-10 w-full items-center truncate rounded-l bg-[#1E1E1E] px-4">
            <pre className="flex text-sm text-white">{installation}</pre>
          </div>
          <div className="flex h-10 items-center justify-center rounded-r border-l border-white bg-[#1E1E1E] px-2">
            <CopyToClipboard className="cursor-pointer whitespace-nowrap font-extrabold text-white" text={installation} noTooltip />
          </div>
        </div>

        {/* Process ID */}
        <div className="pb-1 font-semibold">
          Process ID <span className="pl-1 font-light">You'll need this in your script</span>
        </div>
        <div className="mb-6 flex w-full items-center">
          <div className="flex h-10 w-full items-center truncate rounded-l bg-[#1E1E1E] px-4">
            <pre className="flex text-sm text-white">{draftProcess?.id}</pre>
          </div>
          <div className="flex h-10 items-center justify-center rounded-r border-l border-white bg-[#1E1E1E] px-2">
            {draftProcess?.id ? (
              <CopyToClipboard className="cursor-pointer whitespace-nowrap font-extrabold text-white" text={draftProcess.id} noTooltip />
            ) : (
              <button
                onClick={() => {}}
                className="cursor-pointer whitespace-nowrap font-extrabold text-white opacity-80 hover:opacity-100"
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="pl-2">Generate ID</span>
              </button>
            )}
          </div>
        </div>

        {/* Full Documentation */}
        <div className="pb-1 font-bold">Need More Info? </div>
        <div className="font-light">
          Try out our helpful AI powered assistant or consult our{" "}
          <a href="https://docs.serial.io/python/quickstart" target="_blank" className="underline">
            documentation
          </a>
        </div>
      </div>
      <ProcessBuilderApiDatasetTable />
    </div>
  );
};

export default ProcessBuilderApiInfo;
