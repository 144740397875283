import React from "react";
import TypeTag from "@shared/components/TypeTag";
import { filterConditionOperatorStyle } from "./types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterCondition } from "@shared/types/databaseTypes";

interface FilterConditionBlockProps {
  filterCondition: FilterCondition;
  focusedButton: "VALUES" | "OPERATORS" | null;
  handleTriggerValueSelector: (filterConditionId: string) => void;
  handleTriggerOperatorSelector: (filterConditionId: string) => void;
  handleRemoveFilterCondition: (filterConditionId: string) => void;
}

const FilterConditionBlock: React.FC<FilterConditionBlockProps> = ({
  filterCondition,
  focusedButton,
  handleTriggerValueSelector,
  handleTriggerOperatorSelector,
  handleRemoveFilterCondition,
}) => {
  return (
    <div className="flex h-8 max-w-full">
      <div className="btn border-serial-palette-200 text-serial-palette-500 border-r-serial-palette-100 rounded-r-none bg-white px-1.5">
        <TypeTag type={filterCondition.type} className="mr-2 h-5 w-8 text-[10px]" hideText />
        <span className="whitespace-nowrap">{filterCondition.name}</span>
      </div>
      <button
        className={`btn-xs border-serial-palette-200 hover:bg-serial-palette-50 text-serial-palette-500 hover:text-serial-palette-600 w-30 border-r-serial-palette-100 -m-pl h-8 rounded-none border-l-0 bg-white ${focusedButton === "OPERATORS" && "!text-serial-palette-600 !bg-sky-50"}}`}
        onClick={() => handleTriggerOperatorSelector(filterCondition.id)}
      >
        {filterCondition.operator && (
          <span className="whitespace-nowrap font-bold">{filterConditionOperatorStyle[filterCondition.operator].symbol}</span>
        )}
      </button>
      <button
        className={`btn-xs border-serial-palette-200 hover:bg-serial-palette-50 text-serial-palette-500 hover:text-serial-palette-600 border-r-serial-palette-100 -m-pl h-8 justify-start overflow-hidden rounded-none border-l-0 bg-white ${focusedButton === "VALUES" && "!text-serial-palette-600 !bg-sky-50"}}`}
        onClick={() => handleTriggerValueSelector(filterCondition.id)}
      >
        <span className="truncate">
          {filterCondition.label && filterCondition.label !== "" ? (
            filterCondition.label
          ) : (
            <span className="text-serial-palette-400 italic">value...</span>
          )}
        </span>
      </button>
      <button
        className="btn border-serial-palette-200 hover:bg-serial-palette-50 text-serial-palette-800 hover:text-serial-palette-600 w-30 -m-pl rounded-l-none border-l-0 bg-white"
        onClick={() => handleRemoveFilterCondition(filterCondition.id)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default FilterConditionBlock;
