import React, { useContext, useEffect, useState } from "react";
import StationDetailsChart from "./StationDetailsChart";
import EditMenu from "@shared/components/dropdowns/DropdownEditMenu";
import TypeTag from "@shared/components/TypeTag";
import StationDetailsSnTable from "./StationDetailsSnTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faGlobe, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { deleteStation, fetchStationEntriesGraphData, updateStation } from "../connections/supabase";
import StationDetailsSettings from "./StationDetailsSettings";
import { StationType } from "@shared/types/databaseEnums";
import StationDetailsEnabledProcesses from "./StationDetailsEnabledProcesses";
import { StationEntriesGraphData, StationWithLinksAndProcessEntries } from "../types";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { useNavigate } from "react-router-dom";

interface StationDetailsProps {
  selectedStation: StationWithLinksAndProcessEntries | null;
  reloadData: () => Promise<StationWithLinksAndProcessEntries[] | null>;
  lastUpdatedTimestamp: Date | null;
}

enum StationDetailsTab {
  SnList = "SN_LIST",
  Settings = "SETTINGS",
  EnabledProcesses = "ENABLED_PROCESSES",
}

// regex to check if the station name is the same as the format used for new stations
// example name: 'New Station 2021-09-09 10:00:00'
const isNewStationRegex = /^New Station \d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

const StationDetails: React.FC<StationDetailsProps> = ({ selectedStation, reloadData, lastUpdatedTimestamp }) => {
  const [chartHover, setChartHover] = useState<boolean>(false); // used to show/hide the chart label
  const [selectedTab, setSelectedTab] = useState(StationDetailsTab.SnList); // used to switch between tabs
  const [chartGraphData, setChartGraphData] = useState<StationEntriesGraphData | null>(null); // used to store the graph data

  const observe = useContext(ObservabilityContext);
  const navigate = useNavigate();

  // Function to update the chart data
  const loadData = async () => {
    if (selectedStation) {
      const newData: StationEntriesGraphData | null = await fetchStationEntriesGraphData(selectedStation.id);
      if (newData) {
        setChartGraphData(newData);
      }
    }
  };

  // switch to settings tab if the selected station is a new station
  useEffect(() => {
    if (selectedStation) {
      // example name: 'New Station 2021-09-09 10:00:00'
      if (isNewStationRegex.test(selectedStation.name ?? "")) {
        setSelectedTab(StationDetailsTab.Settings);
      }
      loadData();
    }
  }, [selectedStation, lastUpdatedTimestamp]);

  return (
    <div className="scrollbar-hide flex h-full w-full flex-col overflow-auto rounded-md border bg-white py-8">
      {selectedStation === null || selectedStation === undefined ? (
        // Show message if no station is selected
        <div className="flex h-full w-full items-center justify-center italic">
          <p>Select a station</p>
        </div>
      ) : (
        // Main body of station details
        <>
          {/* Chart */}
          <div
            className="relative flex h-[20%] max-h-[20%] w-full px-3"
            onMouseEnter={() => setChartHover(true)}
            onMouseLeave={() => setChartHover(false)}
          >
            {chartHover && (
              <div className="text-serial-palette-400 absolute px-6 text-sm font-semibold uppercase">48 Hr Submission History</div>
            )}
            <StationDetailsChart chartGraphData={chartGraphData} />
          </div>
          {/* Name and Edit Menu */}
          <div className="mt-2 flex justify-between border-t px-8 pt-6">
            <div className="flex items-center gap-3">
              <span className="text-xl font-bold md:text-3xl">{selectedStation.name}</span>
              {/* Flashing Live Pixel */}
              <span className="relative flex h-3 w-3">
                {!selectedStation.is_active ? (
                  <span className="relative inline-flex h-3 w-3 rounded-full bg-red-600"></span>
                ) : (
                  <>
                    {selectedStation.is_locked ? (
                      <span className="relative inline-flex h-3 w-3 rounded-full bg-amber-600"></span>
                    ) : (
                      <>
                        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-600 opacity-75"></span>
                        <span className="relative inline-flex h-3 w-3 rounded-full bg-green-600"></span>
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
            {/* Edit Menu */}
            <EditMenu align="right" className="relative inline-flex" size={10} dark>
              {selectedStation.is_locked && selectedStation.is_active && (
                <li>
                  <button
                    className="text-serial-palette-600 hover:text-serial-palette-800 flex px-3 py-1 text-sm font-medium"
                    onClick={async () => {
                      await updateStation(selectedStation.id, { is_locked: false });
                      reloadData();
                      observe.track("station_unlocked");
                    }}
                  >
                    Unlock Station
                  </button>
                </li>
              )}
              {!selectedStation.is_active && (
                <li>
                  <button
                    className="text-serial-palette-600 hover:text-serial-palette-800 flex px-3 py-1 text-sm font-medium"
                    onClick={async () => {
                      await updateStation(selectedStation.id, { is_active: true });
                      reloadData();
                      observe.track("station_enabled");
                    }}
                  >
                    Enable Station
                  </button>
                </li>
              )}
              {!selectedStation.is_locked && selectedStation.is_active && (
                <li>
                  <button
                    className="text-serial-palette-600 hover:text-serial-palette-800 flex px-3 py-1 text-sm font-medium"
                    onClick={async () => {
                      await updateStation(selectedStation.id, { is_locked: true });
                      reloadData();
                      observe.track("station_locked");
                    }}
                  >
                    Lock Station
                  </button>
                </li>
              )}
              {selectedStation.latest_process_entries?.length !== 0 && selectedStation.is_active && (
                <li>
                  <button
                    className="flex px-3 py-1 text-sm font-medium text-red-600 hover:text-red-800"
                    onClick={async () => {
                      await updateStation(selectedStation.id, { is_locked: true, is_active: false });
                      reloadData();
                      observe.track("station_retired");
                    }}
                  >
                    Retire Station
                  </button>
                </li>
              )}
              {selectedStation.latest_process_entries?.length === 0 && (
                <li>
                  <button
                    className="flex px-3 py-1 text-sm font-bold text-red-700 hover:text-red-900"
                    onClick={async () => {
                      await deleteStation(selectedStation.id);
                      reloadData();
                      navigate("/stations");
                      observe.track("station_deleted");
                    }}
                  >
                    Delete Station
                  </button>
                </li>
              )}
            </EditMenu>
          </div>
          {/* Tag  */}
          <div className="text-serial-palette-400 mt-2 flex w-full flex-wrap items-center gap-x-2 gap-y-1.5 px-8 text-sm font-semibold uppercase">
            <span className="border-serial-palette-400 whitespace-nowrap rounded-full border-2 px-3 py-0.5 text-xs md:text-sm">
              <FontAwesomeIcon icon={selectedStation.type === StationType.Universal ? faGlobe : faCrosshairs} className="mr-2" />
              {selectedStation.type === StationType.Universal ? "Universal Station" : "Dedicated Station"}
            </span>
            {selectedStation.location && (
              <span className="border-serial-palette-400 whitespace-nowrap rounded-full border-2 px-3 py-0.5 text-xs md:text-sm">
                <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                {selectedStation.location}
              </span>
            )}
            <TypeTag
              type={selectedStation?.is_active ? (selectedStation?.is_locked ? "LOCKED" : "ACTIVE") : "RETIRED"}
              className={`text-xs md:text-sm ${!selectedStation.is_locked ? "pr-4" : ""}`}
            />
          </div>
          {/* Tabs */}
          <div className="text-serial-palette-400 z-10 mt-10 flex px-8 text-xs font-bold md:text-base">
            <div
              className={`border-b-3 px-3 pb-3 ${selectedTab === StationDetailsTab.SnList && "border-serial-palette-600 text-serial-palette-600"} hover:border-serial-palette-500 hover:text-serial-palette-500 cursor-pointer whitespace-nowrap`}
              onClick={() => (setSelectedTab(StationDetailsTab.SnList), observe.track("station_sn-list"))}
            >
              Latest Activity
            </div>
            <div
              className={`border-b-3 px-3 pb-3 ${selectedTab === StationDetailsTab.Settings && "border-serial-palette-600 text-serial-palette-600"} hover:border-serial-palette-500 hover:text-serial-palette-500 cursor-pointer whitespace-nowrap`}
              onClick={() => (setSelectedTab(StationDetailsTab.Settings), observe.track("station_settings"))}
            >
              Station Settings
            </div>
            {/* Only show if station is dedicated or uses API */}
            {selectedStation.type === StationType.Dedicated && !selectedStation.use_api && (
              <div
                className={`border-b-3 px-3 pb-3 ${selectedTab === StationDetailsTab.EnabledProcesses && "border-serial-palette-600 text-serial-palette-600"} hover:border-serial-palette-500 hover:text-serial-palette-500 cursor-pointer whitespace-nowrap`}
                onClick={() => (setSelectedTab(StationDetailsTab.EnabledProcesses), observe.track("station_enabled-processes"))}
              >
                Enabled Processes
              </div>
            )}
          </div>
          <div className="border-b-3 z-0 mx-8 -mt-[2.5px] flex"></div>
          {/* Tabls Body */}
          <div className="mt-6 px-8">
            {selectedTab === StationDetailsTab.SnList && (
              <StationDetailsSnTable processEntries={selectedStation?.latest_process_entries ?? []} />
            )}
            {selectedTab === StationDetailsTab.Settings && (
              <StationDetailsSettings selectedStation={selectedStation} reloadData={reloadData} />
            )}
            {/* Only show if station is dedicated or uses API */}
            {selectedStation.type === StationType.Dedicated &&
              !selectedStation.use_api &&
              selectedTab === StationDetailsTab.EnabledProcesses && (
                <StationDetailsEnabledProcesses selectedStation={selectedStation} reloadData={reloadData} />
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default StationDetails;
