import React from "react";

type SummaryNumberProps = {
  title: string;
  value: any;
};

const SummaryNumber: React.FC<SummaryNumberProps> = ({ title, value }) => {
  return (
    <div className="flex items-center py-2">
      <div className="mr-5">
        <div className="flex items-center">
          <div className="text-serial-palette-800 mr-2 text-3xl font-bold">{value}</div>
        </div>
        <div className="text-serial-palette-500 text-sm">{title}</div>
      </div>
      <div className="bg-serial-palette-200 mr-5 hidden h-8 w-px md:block" aria-hidden="true"></div>
    </div>
  );
};

export default SummaryNumber;
