import store from "@shared/redux/store";
import { getSupabase } from "@shared/connections/supabaseAuth";
import { ParametricQuantitative } from "@shared/types/databaseTypes";

// ------------------- READ FUNCTIONS ------------------- //

export const fetchNumericalDatasetValuesRandomX = async (dataset_id: string, unique_identifier_id: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: retestValues, error: retestValuesError } = await supabase
    .from("parametric_quantitative")
    .select("value, usl, lsl, process_entry:process_entry_id!inner(upload_error)")
    .eq("unique_identifier_id", unique_identifier_id)
    .eq("process_entry.upload_error", false)
    .eq("dataset_id", dataset_id);

  const { data: allValues, error: allValuesError } = await supabase
    .from("parametric_quantitative")
    .select("value, usl, lsl")
    .eq("dataset_id", dataset_id);

  if (retestValuesError || allValuesError) return { x: [], y: [] };

  // Function to generate normally distributed numbers between -1 and 1
  const generateNormalBounded = () => {
    let u = 0,
      v = 0,
      s = 0,
      z = 0;
    do {
      u = Math.random() * 2 - 1; // Uniform(-1,1)
      v = Math.random() * 2 - 1; // Uniform(-1,1)
      s = u * u + v * v;
    } while (s >= 1 || s == 0);
    z = u * Math.sqrt((-2.0 * Math.log(s)) / s);
    // Repeat until the generated number is within the desired range
    return z * 0.5; // Scale down to adjust the standard deviation to better fit within -1 to 1
  };

  const retestXArray = retestValues.map(generateNormalBounded);
  const allValuesXArray = allValues.map(generateNormalBounded);

  const retestData = {
    x: retestXArray,
    y: retestValues.map((value) => value.value),
    lsl: retestValues.map((value) => value.lsl),
    usl: retestValues.map((value) => value.usl),
  };
  const allData = { x: allValuesXArray, y: allValues.map((value) => value.value) };

  return { retestData, allData };
};

export const fetchRestestNumericalDatasetValuesWithTimestamp = async (
  dataset_id: string,
  unique_identifier_id: string,
  processEntryIndexMap: { [id: string]: { index: number; process_id: string } },
) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: retestValues, error: retestValuesError } = await supabase
    .from("parametric_quantitative")
    .select("value, lsl, usl, unit, process_entry_id, process_entry:process_entry_id!inner(timestamp, upload_error)")
    .eq("unique_identifier_id", unique_identifier_id)
    .eq("process_entry.upload_error", false)
    .eq("dataset_id", dataset_id)
    .returns<Partial<ParametricQuantitative[]>>();

  if (retestValuesError) {
    console.error(retestValuesError);
    return { dates: [], testNumbers: [], values: [], lsl: [], usl: [], unit: [] };
  }

  // Sort values post query since supabase cannot order on a foreign key timestamp
  const sortedRetestValues = retestValues.sort((a, b) => {
    if (!a?.process_entry || !b?.process_entry) return 0;
    return new Date(a.process_entry.timestamp).getTime() - new Date(b.process_entry.timestamp).getTime();
  });

  const transformed = sortedRetestValues.reduce<{
    dates: string[];
    testNumbers: number[];
    values: number[];
    lsl: (number | null)[];
    usl: (number | null)[];
    unit: string[];
  }>(
    (acc, currentValue) => {
      if (!currentValue?.process_entry) return acc;
      acc.dates.push((currentValue as any).process_entry.timestamp as string);
      acc.testNumbers.push(processEntryIndexMap[currentValue.process_entry_id].index + 1);
      acc.values.push(currentValue.value);
      acc.lsl.push(currentValue.lsl);
      acc.usl.push(currentValue.usl);
      acc.unit.push(currentValue.unit ?? "");
      return acc;
    },
    { dates: [], testNumbers: [], values: [], lsl: [], usl: [], unit: [] },
  );
  return transformed;
};

export const fetchProcessEntryTimestmap = async (process_entry_id: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: timestamp, error: queryError } = await supabase.from("process_entries").select("timestamp").eq("id", process_entry_id);

  if (queryError) return [];

  return timestamp;
};

// helper function to fetch data from supabase based on table name and process id
export async function fetchDataByTableNameAndDatasetIdAndUniqueIdentifierId<ReturnedTable>(
  tableName: string,
  datasetId: string,
  uniqueIdentiferId: string,
): Promise<ReturnedTable[]> {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let query = supabase.from(tableName) as any;
  if (tableName === "unique_identifier_links") {
    query = query.select("*, process_entry:process_entry_id!inner(*), child:has_child_of_id!inner(*)");
  } else {
    query = query.select("*, process_entry:process_entry_id!inner(*)");
  }
  query = query.eq("dataset_id", datasetId).eq("unique_identifier_id", uniqueIdentiferId).eq("process_entry.upload_error", false);

  const { data, error } = await query;

  if (error) {
    console.error(error);
    return [];
  }

  return data;
}
