import { Screenshot, createScreenshots, extractAudioFromVideo, loadFFmpeg } from "@shared/utils/ffmpeg";
import React, { useEffect, useRef, useState } from "react";
import { FFmpeg } from "@ffmpeg/ffmpeg";

const DebugFfmpeg: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [screenshots, setScreenshots] = useState<Screenshot[]>([]);

  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const ffmpegRef = useRef<FFmpeg>(new FFmpeg());

  useEffect(() => {
    loadFFmpeg(ffmpegRef.current).then(() => {
      setLoaded(true);
    });
  }, []);

  const handleVideoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setVideoFile(file);

    if (file) {
      const { data: audioFile } = await extractAudioFromVideo(file, ffmpegRef.current);
      if (audioFile) setAudioUrl(URL.createObjectURL(audioFile));

      const { data: screenshots } = await createScreenshots(file, 5, ffmpegRef.current, 512);
      if (screenshots) setScreenshots(screenshots);
    }
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between">
        <h1 className="text-lg">
          <span className="font-bold">Debug Ffmpeg Utils:</span> Extracts audio and screenshots
        </h1>
        {loaded && <input type="file" accept="video/*" onChange={handleVideoChange} className="mb-4" />}
      </div>
      {videoFile && <video controls src={videoFile ? URL.createObjectURL(videoFile) : undefined} className="mb-4 w-36"></video>}
      {audioUrl && <audio controls src={audioUrl} className="mb-4"></audio>}
      <div className="grid grid-cols-3 gap-4">
        {screenshots.map((screenshot, index) => {
          const downloadUrl = URL.createObjectURL(screenshot.file);
          return <img key={index} src={downloadUrl} alt={`Screenshot ${index}`} className="w-full" />;
        })}
      </div>
    </div>
  );
};

export default DebugFfmpeg;
