import React, { useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "./primitives/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface SignatureBoxProps {
  handleCaptureSignature: (dataUrl: string) => void;
  handleResetSignature: () => void;
  signatureUrl: string | null;
}

const SignatureBox: React.FC<SignatureBoxProps> = ({ handleCaptureSignature, handleResetSignature, signatureUrl }) => {
  const sigCanvasRef = useRef<any>({});

  const clearSignature = () => {
    handleResetSignature();
  };

  const saveSignature = () => {
    const dataUrl = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
    handleCaptureSignature(dataUrl);
  };

  useEffect(() => {
    const handleResize = () => {
      if (!sigCanvasRef.current) return;
      const canvas = sigCanvasRef.current.getCanvas();
      const parent = canvas.parentNode;
      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
      sigCanvasRef.current.clear();
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex h-full w-full flex-col items-center rounded-md text-center">
      {signatureUrl ? (
        <div className="relative h-full w-full">
          <img src={signatureUrl} className="h-full w-full rounded-md" />
          <Button variant="danger" size="xs" className="absolute right-2 top-2" onClick={clearSignature}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </Button>
        </div>
      ) : (
        <div className="relative flex h-full w-full flex-col items-center justify-center pb-2">
          <div className="signature-canvas-wrapper relative h-full w-full">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                className: "signature-canvas absolute top-0 left-0 w-full h-full",
              }}
              ref={sigCanvasRef}
            />
          </div>
          <Button variant="default" size="xs" className="absolute right-2 top-2" onClick={saveSignature}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default SignatureBox;
