import { ComponentType } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { PageAllowedRoles } from "@shared/constants/serial";
import InsufficientPrivileges from "../utility/InsufficientPrivileges";
import { UserRole } from "@shared/types/databaseEnums";
import { useNavigate } from "react-router-dom";

interface AuthGuardProps {
  component: ComponentType<any>;
  componentName: keyof typeof PageAllowedRoles;
}

export const AuthGuard = ({ component: Component, componentName }: AuthGuardProps) => {
  const role = useSelector((state: RootState) => state.auth.role);
  const navigate = useNavigate();

  if (role !== null) {
    if (role === UserRole.Operations && !location.pathname.includes("production") && !location.pathname.includes("auth")) {
      navigate("/production");
    }
    const roles: (string | null)[] = PageAllowedRoles[componentName as keyof typeof PageAllowedRoles];

    if (Array.isArray(roles)) {
      if (!(roles.includes(role) || roles.includes("*"))) {
        return <InsufficientPrivileges />;
      }
    }
  }

  return <Component />;
};
