import React, { useEffect, useMemo, useState } from "react";
import UserAvatar from "@shared/components/UserAvatar";
import TypeTag from "@shared/components/TypeTag";
import { DataType } from "@shared/types/databaseEnums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { WorkOrderAttachment } from "@shared/types/databaseTypes";
import { WorkOrderAttachmentDraft } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import moment from "moment";
import { Loader } from "@shared/components/Loader";
import FileUploadCell from "@shared/components/FileUploadCell";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";

interface WorkOrderAttachmentsRowProps {
  attachment: WorkOrderAttachmentDraft | WorkOrderAttachment;
  index: number;
  isDraft: boolean;
  handleDelete: (index?: number, datasetId?: string) => Promise<void>;
  handleSave?: (index: number) => Promise<void>;
  handleUpdateAttachment?: (
    index: number,
    key: "url" | "name" | "value" | "file_name",
    value: string,
    attachment?: WorkOrderAttachmentDraft,
  ) => WorkOrderAttachmentDraft;
  editableIndex?: number | null;
  setEditableIndex?: React.Dispatch<React.SetStateAction<number | null>>;
}

function formatUrl(url: string) {
  // If there's no scheme, prepend "http://".
  if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
}

function isUrl(string: any) {
  if (typeof string !== "string") {
    return false;
  }

  // The regex pattern for URLs
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator

  return !!pattern.test(string);
}

const WorkOrderAttachmentsRow: React.FC<WorkOrderAttachmentsRowProps> = ({
  attachment,
  index,
  isDraft,
  handleSave,
  handleDelete,
  handleUpdateAttachment,
  editableIndex,
  setEditableIndex,
}) => {
  const users = useSelector((state: RootState) => state.db.users);
  const datasets = useSelector((state: RootState) => state.db.datasets);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string | undefined>(undefined);

  const company = useSelector((state: RootState) => state.db.company);

  const handleUploadedFile = async (fileUrl: string, fileName: string) => {
    if (handleUpdateAttachment) {
      const updatedAttachment = handleUpdateAttachment(index, "url", fileUrl);
      handleUpdateAttachment(index, "file_name", fileName, updatedAttachment);
    }
  };

  const dataset = useMemo(() => {
    if ("dataset_id" in attachment) {
      return datasets.find((k) => k.id === attachment.dataset_id) ?? null;
    }
    return null;
  }, [attachment, datasets]);

  const user = useMemo(() => {
    // check if object has added_by_user_id
    if ("added_by_user_id" in attachment) {
      return users.find((u) => u.supabase_uid === attachment.added_by_user_id) ?? null;
    }
    return null;
  }, [attachment, users]);

  const type = useMemo(() => {
    if ("type" in attachment) {
      return attachment.type;
    }
    return dataset?.data_type ?? null;
  }, [attachment]);

  const name = useMemo(() => {
    if ("name" in attachment) {
      return attachment.name;
    }
    return dataset?.name ?? "";
  }, [attachment, dataset]);

  useEffect(() => {
    if ("url" in attachment && attachment.url) {
      setUrl(attachment.url);
    } else if (attachment.value && type && [DataType.Image, DataType.File].includes(type)) {
      handleFetchAttachmentUrl(attachment.value);
    } else {
      setUrl(undefined);
    }
  }, [attachment, dataset]);

  const handleFetchAttachmentUrl = async (fileId: string) => {
    if (company && fileId) {
      setUrl((await fetchDownloadUrl("data-instantiation-files", fileId, company.id)) ?? undefined);
    }
  };

  return (
    <tr className="border-b" onClick={() => setEditableIndex && setEditableIndex(index)}>
      {/* Data Type*/}
      <td className="max-w-[50px] whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
        <div className="flex justify-center">{type && <TypeTag className="h-6 w-20 text-xs" hideText type={type} />}</div>
      </td>

      {/* Title */}
      <td className="whitespace-normal px-3">
        <input
          type="text"
          className={`form-input text-serial-palette-600 w-full text-left font-light ${editableIndex === index ? "border-serial-palette-200" : "border-white hover:border-white"}`}
          value={name}
          disabled={!isDraft}
          placeholder="Attachment name..."
          onChange={(e) => handleUpdateAttachment && handleUpdateAttachment(index, "name", e.target.value)}
        />
      </td>

      {/* Content */}
      <td className="whitespace-normal px-2 first:pl-5 last:pr-5">
        {type === DataType.ParametricQualitative ? (
          <div className="w-full max-w-[20vw] overflow-clip">
            {isDraft ? (
              <input
                type="text"
                className={`form-input text-serial-palette-600 w-full text-left font-light ${editableIndex === index ? "border-serial-palette-200" : "border-white hover:border-white"}`}
                value={attachment.value}
                disabled={!isDraft}
                placeholder="Attachment body..."
                onChange={(e) => handleUpdateAttachment && handleUpdateAttachment(index, "value", e.target.value)}
              />
            ) : (
              <p className="text-serial-palette-600 truncate pl-3 text-left text-sm font-light">
                {isUrl(attachment.value) ? (
                  <a className="underline" href={formatUrl(attachment.value ?? "")} target="_blank" rel="noopener noreferrer">
                    {attachment.value}
                  </a>
                ) : (
                  attachment.value
                )}
              </p>
            )}
          </div>
        ) : (
          <div className="flex h-full w-full items-center pl-3">
            {(type === DataType.Image || type === DataType.File) && (
              <FileUploadCell
                allowPaste
                dataType={type}
                fileUrl={url}
                setFile={handleUploadedFile}
                fileName={attachment.file_name ?? undefined}
                disabled={!isDraft}
              />
            )}
          </div>
        )}
      </td>

      {/* Added By */}
      <td className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 md:table-cell">
        <div className="flex w-full justify-center">{user && <UserAvatar user={user} size="sm" enableToolTip />}</div>
      </td>

      {/* Added At */}
      <td className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 md:table-cell">
        {!isDraft && (
          <div className="text-serial-palette-600 text-left text-sm font-light">
            {moment((attachment as WorkOrderAttachment).added_at).format("MM/DD/YY HH:mma")}
          </div>
        )}
      </td>

      {/* Delete */}
      <td className="whitespace-normal px-2 py-3 text-center first:pl-5 last:pr-5">
        {name && (url || attachment.value) && handleSave && (
          <button
            className="btn serial-btn-dark mr-2 h-8"
            onClick={async () => {
              setSaveIsLoading(true);
              await handleSave(index);
              setSaveIsLoading(false);
            }}
          >
            <span className="absolute flex h-9 w-[90px]">
              {!saveIsLoading && (
                <span className="ring-serial-palette-300 absolute left-8 top-2 z-20 flex h-5 w-6 shrink-0 animate-ping rounded opacity-75 ring-4"></span>
              )}
            </span>
            {saveIsLoading ? (
              <Loader styleOverride="h-[12px] w-[12px] text-white" />
            ) : (
              <FontAwesomeIcon icon={faUpload} className="text-white" />
            )}
          </button>
        )}
        {
          <button
            className="btn border-serial-palette-200 hover:border-serial-palette-300 h-8"
            onClick={async () => {
              setDeleteIsLoading(true);
              await handleDelete(index, dataset?.id);
              setDeleteIsLoading(false);
            }}
          >
            {deleteIsLoading ? (
              <Loader styleOverride="h-[12px] w-[12px] text-serial-palette-500" />
            ) : (
              <FontAwesomeIcon icon={faTimes} className="text-red-600" />
            )}
          </button>
        }
      </td>
    </tr>
  );
};

export default WorkOrderAttachmentsRow;
