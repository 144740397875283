import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";
import { v4 as uuidv4 } from "uuid";
import { Component, Dataset, PartNumber } from "@shared/types/databaseTypes";
import { ProcessType } from "@shared/types/databaseEnums";

// ------------------- READ FUNCTIONS ------------------- //

export const getInstatiationFileById = async (fileId: string): Promise<string | null> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  if (!state.db.company) return null;

  // Download file from Storage
  const { data: fileDownloadData, error: fileDownloadError } = await supabase.storage
    .from("data-instantiation-files")
    .download(`${state.db.company.id}/${fileId}`);
  if (fileDownloadError) {
    console.log(fileDownloadError);
    return null;
  }

  return URL.createObjectURL(fileDownloadData);
};

export const fetchPartNumberDatasets = async (dataset_ids: string[]) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: partNumberDatasets, error: partNumberDatasetsError } = await supabase.from("datasets").select("*").in("id", dataset_ids);

  // Handle database error else, map fields
  if (partNumberDatasetsError) {
    console.error(partNumberDatasetsError);
    return [];
  }
  return partNumberDatasets as Dataset[];
};

// ------------------- WRITE FUNCTIONS ------------------- //

export const uploadInstantiationFile = async (fileUrl: string) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  if (!state.db.company) return null;

  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const fileId = uuidv4();
    const { error: errorFileUpload } = await supabase.storage
      .from("data-instantiation-files")
      .upload(`${state.db.company.id}/${fileId}`, blob);
    if (errorFileUpload) {
      throw errorFileUpload;
    }
    return fileId;
  } catch (error) {
    // Handle upload error
    console.error(error);
    return null;
  }
};

export const upsertPartNumbers = async (partNumbers: PartNumber[], gridDatasets: Dataset[], component: Component): Promise<boolean> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  if (!state.db.company?.id || !state.db.company || !state.auth.supabase_uid) {
    console.log("No company id, company, or supabase_uid");
    return false;
  }

  // Get instantiation process
  const { data: instantiationProcess, error: instantiationProcessError } = await supabase
    .from("component_process_links")
    .select("*, process:processes!inner(*)") // use !inner to make the .eq and .neq filters work on the joined tables
    .eq("process.type", ProcessType.Instantiation)
    .eq("component_id", component.id)
    .single();

  if (instantiationProcessError || !instantiationProcess) {
    console.log("Could not find instantiation process for component");
    return false;
  }

  // Upsert datasets first
  const datasetPayloads = gridDatasets.map((dataset: Dataset) => {
    return {
      id: dataset.id,
      name: dataset.name,
      order: dataset.order,
      data_type: dataset.data_type,
      process_id: instantiationProcess.process_id,
      process_revision: instantiationProcess.process_revision,
      company_id: state.db.company.id,
    };
  });
  const { data: upsertedDatasets, error: upsertedDatasetsError } = await supabase.from("datasets").upsert(datasetPayloads).select();
  if (upsertedDatasetsError) {
    console.error(upsertedDatasetsError);
    return false;
  }

  // update the component's pn_metadata_dataset_ids array with the new dataset ids
  const newDatasetIds = upsertedDatasets?.map((dataset: Dataset) => dataset.id);
  const { error: updatedComponentError } = await supabase
    .from("components")
    .update({ pn_metadata_dataset_ids: newDatasetIds })
    .eq("id", component.id)
    .single();
  if (updatedComponentError) {
    console.log(updatedComponentError);
    return false;
  }

  // Upsert part numbers
  const partNumberPayloads = partNumbers.map((partNumber) => {
    return {
      id: partNumber.id,
      pn: partNumber.pn,
      description: partNumber.description,
      component_id: component.id,
      company_id: state.db.company.id,
      last_edited_user_id: state.auth.supabase_uid as string, // must be string since we check for null above
      last_edited_at: new Date().toISOString(),
      metadata: partNumber.metadata as any,
      // is_active, created_at, are all set automatically
    };
  });
  const { error: upsertedPartNumberError } = await supabase.from("part_numbers").upsert(partNumberPayloads).select();
  if (upsertedPartNumberError) {
    console.log(upsertedPartNumberError);
    return false;
  }

  return true;
};
