import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Banner2 from "@shared/components/Banner2";
import { useNavigate } from "react-router-dom";
import { deactivateAlertsById, fetchAlerts } from "@shared/connections/supabaseAlerts";
import { AlertType } from "@shared/types/databaseEnums";
import { RootState } from "@shared/redux/store";
import { Alert } from "@shared/types/databaseTypes";

const AlertsClassic: React.FC<void> = () => {
  const company = useSelector((state: RootState) => state.db.company);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const navigate = useNavigate();

  const loadData = async () => {
    const alerts = await fetchAlerts();
    setAlerts(alerts);
  };

  const handleDismissAlert = async (alertId: string) => {
    const confirmation = confirm("Are you sure you want to dismiss this alert?");
    if (confirmation) {
      await deactivateAlertsById(alertId);
      loadData();
    }
  };

  useEffect(() => {
    if (company !== null) {
      loadData();
    }
  }, [company]);

  return (
    <div className="col-span-full flex flex-col gap-y-2 p-3 lg:col-span-4">
      {alerts
        ?.filter((alert) => alert.is_active)
        .map((alert, index) => {
          let bannerType = "error";
          switch (alert.type) {
            case AlertType.Override:
              bannerType = "warning";
              break;
          }
          return (
            <div key={index} className="cursor-pointer text-left" onClick={() => navigate(alert.link ?? "/")}>
              <Banner2 type={bannerType} open={true} clickXHandler={handleDismissAlert} id={alert.id}>
                {alert.message}
              </Banner2>
            </div>
          );
        })}
      {alerts?.filter((alert) => alert.is_active)?.length === 0 && (
        <Banner2 type="success" open={true} hideX>
          You have no more active warnings or alerts. Have a great day!
        </Banner2>
      )}
    </div>
  );
};

export default AlertsClassic;
