import { ParsedResponse } from "@shared/types/apiTypes";
import { makeFetchRequest } from "./helpers";
import { DataType } from "@shared/types/databaseEnums";

const apiUrl = import.meta.env.VITE_APP_SERIAL_API_URL;

export const updateDataset = async (
  datasetId: string,
  dataset: { name?: string; usl?: number; lsl?: number; is_active?: boolean },
): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/datasets/${datasetId}`,
    {
      method: "PATCH",
      body: JSON.stringify(dataset),
    },
    "Could not update dataset",
  );
};

export const createDataset = async (dataset: {
  type: DataType;
  name: string;
  process_id: string;
  usl?: number;
  lsl?: number;
  unit?: string;
}): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${apiUrl}/datasets`,
    {
      method: "PUT",
      body: JSON.stringify(dataset),
    },
    "Could not create dataset",
  );
};
