import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { formatTimeAgo } from "@shared/utils/time";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { DashboardInsightContext } from "../DashboardInsightCard";
import { appendLatestProcessEntry } from "@shared/connections/supabaseProcessEntries";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { UniqueIdentifierWithLatestProcessEntryAndProcess } from "@shared/types/sharedComponentTypes";
import { Component, Operator } from "@shared/types/databaseTypes";
import UserAvatar from "@shared/components/UserAvatar";

const WipTracker: React.FC<void> = () => {
  const [displayComponentInstances, setDisplayComponentInstances] = useState<UniqueIdentifierWithLatestProcessEntryAndProcess[]>([]);
  const [filteredComponentInstancesWithProcessEntries, setFilteredComponentInstancesWithProcessEntries] = useState<
    UniqueIdentifierWithLatestProcessEntryAndProcess[]
  >([]);

  const navigate = useNavigate();
  const context = useContext(DashboardInsightContext);
  const components = useSelector((state: RootState) => state.db.components);
  const componentLinks = useSelector((state: RootState) => state.db.componentLinks);
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const operators = useSelector((state: RootState) => state.db.operators);
  const users = useSelector((state: RootState) => state.db.users);
  const selectedComponentId = context.filters.componentId;
  const selectedProcessId = context.filters.selectedProcessId;
  const startDate = context.filters.dateRange?.from;
  const endDate = context.filters.dateRange?.to;
  const setFilters = context.setFilters;
  const filters = context.filters;

  // Find the component with the most children
  const componentWithMostLinks = useMemo<Component | null>(() => {
    let maxLinks = 0;
    let componentWithMaxLinks = null;

    components.forEach((component) => {
      // Count the links for each component
      const linkCount = componentLinks.filter((link) => link.component_id === component.id && link.child_component !== null).length;

      // Update the component with the most links
      if (linkCount > maxLinks) {
        maxLinks = linkCount;
        componentWithMaxLinks = component;
      }
    });

    return componentWithMaxLinks;
  }, [components, componentLinks]);

  // If no component is selected, then automatically select the component with the most children
  useEffect(() => {
    if (selectedComponentId === null && componentWithMostLinks) {
      setFilters({
        componentId: componentWithMostLinks.id,
        dateRange: { from: startDate, to: endDate },
        filteredDataSize: displayComponentInstances.length,
      });
    } else {
      setFilters({ ...filters, filteredDataSize: displayComponentInstances.length });
    }
  }, [selectedComponentId, componentWithMostLinks, setFilters, displayComponentInstances]);

  // Filter component instances by selectedComponentId, WIP status, and date range then append latest process entry for each instance
  useEffect(() => {
    const fetchLatestProcessEntries = async () => {
      const componentInstancesWithProcessEntry = await appendLatestProcessEntry(filteredComponentInstances);
      setFilteredComponentInstancesWithProcessEntries(componentInstancesWithProcessEntry);
    };

    // Determine which component ID to use for filtering
    const componentIdToFilter = selectedComponentId ?? componentWithMostLinks?.id;

    const filteredComponentInstances = componentInstances
      .filter((componentInstance) => {
        // Filter by selectedComponentId or componentWithMostLinks
        return componentIdToFilter === undefined || componentInstance.component_id === componentIdToFilter;
      })
      .filter((componentInstance) => {
        // Apply WIP status filter
        return componentInstance.status === UniqueIdentifierStatus.Wip;
      })
      .filter((componentInstance) => {
        // Apply filter for created_at within startDate and endDate only if both dates are defined
        if (startDate && endDate) {
          const createdAt = new Date(componentInstance.created_at);
          return createdAt >= startDate && createdAt <= endDate;
        }
        return true; // If dates are not defined, include all instances
      });

    fetchLatestProcessEntries();
  }, [componentInstances, componentWithMostLinks, selectedComponentId, startDate, endDate]);

  // Filter by processId if selected
  useEffect(() => {
    if (selectedProcessId && selectedProcessId !== null) {
      setDisplayComponentInstances(
        filteredComponentInstancesWithProcessEntries.filter(
          (componentInstance) => componentInstance.latest_process_entry?.process_id === selectedProcessId,
        ),
      );
    } else {
      setDisplayComponentInstances(filteredComponentInstancesWithProcessEntries);
    }
  }, [filteredComponentInstancesWithProcessEntries, selectedProcessId]);

  return (
    <div className="col-span-full flex flex-col lg:col-span-4">
      <table className="w-full table-auto overflow-auto">
        {/* Table header */}
        <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
          <tr>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Identifier</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Last Process Entry</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Last Edited By</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">WIP Since</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="divide-serial-palette-200 divide-y text-sm ">
          {displayComponentInstances.map((componentInstance: UniqueIdentifierWithLatestProcessEntryAndProcess, index: number) => {
            let component = components.find((component) => component.id === componentInstance.component_id);
            let operator: Operator | null = null; // Declare outside the if block
            let user = null; // Declare outside the if block
            if (componentInstance?.latest_process_entry) {
              operator = operators.find((op) => op.id === componentInstance.latest_process_entry.operator_id) || null;
              user = users.find((u) => u.supabase_uid === operator?.user_id);
            }
            return (
              <tr
                key={index}
                className="hover:bg-serial-palette-100 cursor-pointer"
                onClick={() => navigate("/snlookup?uid=" + componentInstance.id)}
              >
                <td className="whitespace-nowrap px-2 py-1 first:pl-5 last:pr-5">
                  <div className="mt-1 inline-flex items-center space-x-3 ">
                    <ComponentAvatar component={component} size="xs" />
                    <div className="truncate text-sm">{componentInstance.identifier}</div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-2 py-1 first:pl-5 last:pr-5">
                  <div className="text-left">{componentInstance?.latest_process_entry?.process?.name ?? "No Processes Logged"}</div>
                </td>
                <td className="whitespace-normal px-2 py-1 first:pl-5 last:pr-5 ">
                  {componentInstance?.latest_process_entry ? (
                    <div className="mt-1 inline-flex items-center space-x-3">
                      {operator ? <UserAvatar user={user ?? operator} size="sm" /> : ""}{" "}
                      {/* Prefer user since users have avatars (operators do not) */}
                      <div className="flex">{operator ? `${operator.first_name} ${operator.last_name}` : "Not recorded"}</div>
                    </div>
                  ) : (
                    <div>Not recorded</div>
                  )}
                </td>
                <td className="px-2 py-1 first:pl-5 last:pr-5">
                  <div className="text-left">{formatTimeAgo(componentInstance.created_at)}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WipTracker;
