import React, { useContext } from "react";
import { ImageMarkupLabelElementProps } from "./ImageMarkupElementContainer";
import { ImageMarkupContext } from "../ImageMarkupProvider";
import { ImageMarkupElementArrow as ArrowElement } from "@shared/types/databaseTypes";

const ImageMarkupElementArrow: React.FC<ImageMarkupLabelElementProps<ArrowElement>> = ({ element }) => {
  const { imageWidth, imageHeight } = useContext(ImageMarkupContext);

  const arrowLength = Math.sqrt((element.width * imageWidth) ** 2 + (element.height * imageHeight) ** 2);
  const angle = (Math.atan2(element.height * imageHeight, element.width * imageWidth) * 180) / Math.PI;
  const arrowLineWidth = element.thickness;
  const arrowPointLength = 2 * arrowLineWidth;
  const arrowPointWidth = 2 * arrowLineWidth;
  const arrowLineLength = arrowLength - arrowPointLength;

  return (
    <div
      className="relative shrink-0"
      style={{
        width: Math.abs(element.width) * imageWidth,
        height: Math.abs(element.height) * imageHeight,
      }}
    >
      <div
        className="absolute"
        style={{
          transform: `rotate(${-angle}deg)`,
          top: (Math.abs(element.height) * imageHeight) / 2 - arrowPointWidth,
          left: (Math.abs(element.width) * imageWidth) / 2 - arrowLength / 2,
        }}
      >
        <div
          className="flex items-center"
          style={{
            width: arrowLength,
          }}
        >
          {/* Line */}
          <div
            style={{
              width: arrowLineLength,
              height: arrowLineWidth,
              backgroundColor: element.color,
            }}
          />
          {/* Point */}
          <div
            style={{
              width: 0,
              height: 0,
              borderTop: `${arrowPointLength}px solid transparent`,
              borderBottom: `${arrowPointLength}px solid transparent`,
              borderLeft: `${arrowPointLength * 2}px solid ${element.color}`,
              float: "right",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageMarkupElementArrow;
