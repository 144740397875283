import React, { useState, useRef, useEffect } from "react";
import Transition from "../Transition";

function DropdownOptions({
  align,
  options,
  selected,
  toggle,
  specialOptions,
  handleSelectSpecialOptions,
  classOverride,
  title,
  subtitle,
  numbered,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const allIsChecked = options.every((option) => selected.includes(option.id));

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={
          "btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-500 hover:text-serial-palette-600 bg-white" +
          " " +
          classOverride
        }
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="">{title}</span>
        <wbr />
        {/* <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
          <path d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z" />
        </svg> */}
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`border-serial-palette-200 absolute top-full z-10 mt-2 min-w-[300px] origin-top-right overflow-hidden rounded border bg-white  pt-1.5 shadow ${align === "right" ? "right-0" : "left-0"}`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div className="max-h-[300px] overflow-auto" ref={dropdown}>
          {subtitle && <div className="text-serial-palette-400 px-4 pb-2 pt-1.5 text-xs font-semibold uppercase">{subtitle}</div>}
          <ul className="mb-4">
            {specialOptions.map((option, index) => {
              return (
                <li key={index} className="px-3 py-1">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      checked={option.checked}
                      onChange={() => handleSelectSpecialOptions(option.name, !option.checked)}
                    />
                    <span className="ml-2 text-sm font-medium">{option.name}</span>
                  </label>
                </li>
              );
            })}
            <div className="mx-3 mb-2 flex items-center border-b pb-2" />
            {options
              .sort((a, b) => {
                return a.order < b.order;
              })
              .map((option, index) => {
                return (
                  <li key={index} className="px-3 py-1">
                    <label className={`flex ${option.notApplicable && "opacity-50"}`}>
                      <input
                        type="checkbox"
                        className="form-checkbox mt-1"
                        checked={selected.includes(option.id)}
                        onChange={() => toggle(option.id)}
                      />
                      <span className="pl-2 text-sm font-semibold" hidden={!numbered}>
                        {index + 1}.
                      </span>
                      <span className="ml-2 text-sm font-medium">
                        {option.name}
                        {option.notApplicable && <span className="pl-2 text-sm text-red-500">(No longer Applicable)</span>}
                      </span>
                    </label>
                  </li>
                );
              })}
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownOptions;
