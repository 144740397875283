import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Banner2 from "@shared/components/Banner2";
import { updateCompanyConfig } from "@shared/connections/supabaseCompany";

function ProductionApp() {
  const [operatorPins, setOperatorPins] = useState(true);
  const [showPassFailResult, setShowPassFailResult] = useState(true);
  const [showSpecs, setShowSpecs] = useState(true);
  const [allowWipLinking, setAllowWipLinking] = useState(false);
  const [allowDefectiveLinking, setAllowDefectiveLinking] = useState(false);
  const [showLinkSuggestions, setShowLinkSuggestions] = useState(false);
  const [requireStation, setRequireStation] = useState(true);

  const [showBanner, setShowBanner] = useState(false);
  const company = useSelector((state) => state.db.company);

  const handlSave = async () => {
    const newConfig = {
      ...company.config,
      use_operator_pins: operatorPins,
      show_pass_fail_result_in_production_app: showPassFailResult,
      show_specs_in_production_app: showSpecs,
      allow_wip_linking: allowWipLinking,
      allow_defective_linking: allowDefectiveLinking,
      show_link_suggestions: showLinkSuggestions,
      require_station: requireStation,
    };
    const updateSuccessful = await updateCompanyConfig(newConfig);
    setShowBanner(updateSuccessful);
  };

  // Update the state when the company changes
  useEffect(() => {
    if (company) {
      setOperatorPins(company?.config?.use_operator_pins === true);
      setShowPassFailResult(company?.config?.show_pass_fail_result_in_production_app === true);
      setShowSpecs(company?.config?.show_specs_in_production_app === true);
      setAllowWipLinking(company?.config?.allow_wip_linking === true);
      setAllowDefectiveLinking(company?.config?.allow_defective_linking === true);
      setShowLinkSuggestions(company?.config?.show_link_suggestions === true);
      setRequireStation(company?.config?.require_station === true);
    }
  }, [company]);

  // A timer to dismiss the banner
  useEffect(() => {
    if (showBanner) {
      setTimeout(() => {
        setShowBanner(false);
      }, 1500);
    }
  }, [showBanner]);

  return (
    <div className="flex grow flex-col justify-between">
      {/* Panel body */}
      <div className="flex flex-col space-y-6 p-6">
        {/* <h2 className="text-2xl font-bold mb-5">Production App</h2> */}
        <Banner2
          className="w-full text-left"
          children="Settings updated successfully"
          type="success"
          open={showBanner}
          setOpen={setShowBanner}
        />

        {/* General */}
        <section>
          <h3 className="mb-1 text-xl font-bold leading-snug">Pass / Fail Behaviour</h3>
          <ul>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="font-semibold">Show Specs In Production App</div>
                <div className="text-sm">
                  Show pass/fail specs in the production app and warn users before submitting a value that will cause the unit to be marked
                  as defective. We recommend disableing this setting if you're worried about operators lying about results just to make it
                  pass.{" "}
                </div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center" onClick={() => setShowSpecs(!showSpecs)}>
                <div className="mr-2 text-sm italic">{showSpecs ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input type="checkbox" id="comments" className="sr-only" checked={showSpecs} onChange={() => {}} />
                  <label className="bg-serial-palette-200" htmlFor={"switch-company-user-pins"}>
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="font-semibold">Show Result After Submission</div>
                <div className="text-sm">Show pass/fail result to the user after the data has been submitted.</div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center" onClick={() => setShowPassFailResult(!showPassFailResult)}>
                <div className="mr-2 text-sm italic">{showPassFailResult ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input type="checkbox" id="comments" className="sr-only" checked={showPassFailResult} onChange={() => {}} />
                  <label className="bg-serial-palette-200" htmlFor={"switch-company-user-pins"}>
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>

        {/* Operator Tracking */}
        <section>
          <h3 className="mb-1 text-xl font-bold leading-snug">Operator Tracking</h3>
          <ul>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="font-semibold">Use Operator PINs</div>
                <div className="text-sm">
                  Track multiple operators without having to create them accounts. This also allow CMs to maintain operator PINs without
                  allowing them to create users in your organization.
                </div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center" onClick={() => setOperatorPins(!operatorPins)}>
                <div className="mr-2 text-sm italic">{operatorPins ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input type="checkbox" id="comments" className="sr-only" checked={operatorPins} onChange={() => {}} />
                  <label className="bg-serial-palette-200" htmlFor={"switch-company-user-pins"}>
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>

        {/* Component Links */}
        <section>
          <h3 className="mb-1 text-xl font-bold leading-snug">Links</h3>
          <ul>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="font-semibold">Allow WIP Linking</div>
                <div className="text-sm">
                  Allow components to be linked while they are still "work in progress". If turned off, Serial will force units to finish
                  all mandatory processes before being linked.
                </div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center" onClick={() => setAllowWipLinking(!allowWipLinking)}>
                <div className="mr-2 text-sm italic">{allowWipLinking ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input type="checkbox" id="comments" className="sr-only" checked={allowWipLinking} onChange={() => {}} />
                  <label className="bg-serial-palette-200" htmlFor={"switch-wip-linking"}>
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="flex items-center gap-2 font-semibold">Allow Defective Links</div>
                <div className="text-sm">
                  Allow components to be linked while they are defective. If turned off, Serial will not allow defective units to be linked.
                </div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center">
                <div className="mr-2 text-sm italic">{allowDefectiveLinking ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    id="switch-allow-defective-links"
                    className="sr-only"
                    checked={allowDefectiveLinking}
                    onChange={() => setAllowDefectiveLinking(!allowDefectiveLinking)}
                  />
                  <label className="bg-serial-palette-200" htmlFor={"switch-allow-defective-links"}>
                    <span className="bg-white" aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="font-semibold">Show Link Suggestions</div>
                <div className="text-sm">Show a list of suggested Serial Numbers or LOT codes that could be linked.</div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center" onClick={() => setShowLinkSuggestions(!showLinkSuggestions)}>
                <div className="mr-2 text-sm italic">{showLinkSuggestions ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input type="checkbox" id="comments" className="sr-only" checked={showLinkSuggestions} onChange={() => {}} />
                  <label className="bg-serial-palette-200" htmlFor={"switch-link-suggestions"}>
                    <span className="bg-white " aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>

        {/* Station Options */}
        <section>
          <h3 className="mb-1 text-xl font-bold leading-snug">Stations</h3>
          <ul>
            <li className="neutral-200 flex items-center justify-between py-3">
              {/* Left */}
              <div>
                <div className="font-semibold">Require Station</div>
                <div className="text-sm">
                  Require the use of stations in the production app. If turned off, operators will be able to skip the station selection.
                </div>
              </div>
              {/* Right */}
              <div className="ml-4 flex items-center" onClick={() => setRequireStation(!requireStation)}>
                <div className="mr-2 text-sm italic">{requireStation ? "On" : "Off"}</div>
                <div className="form-switch">
                  <input type="checkbox" id="comments" className="sr-only" checked={requireStation} onChange={() => {}} />
                  <label className="bg-serial-palette-200" htmlFor={"switch-require-stations"}>
                    <span className="bg-white" aria-hidden="true"></span>
                    <span className="sr-only">Switch label</span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>

      {/* Panel footer */}
      <footer>
        <div className="border- neutral-200 flex flex-col border-t px-6 py-5">
          <div className="flex self-end">
            <button onClick={() => handlSave()} className="btn bg-serial-palette-800 hover:bg-serial-palette-600 ml-3 text-white">
              Save Changes
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default ProductionApp;
