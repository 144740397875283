import Button from "@shared/components/primitives/Button";
import ImageMarkup, { generatePngWithMarkup } from "@shared/image-markup/ImageMarkup";
import { ImageMarkupElement } from "@shared/types/databaseTypes";
import { useState } from "react";
import { useImmer } from "use-immer";

const sampleImageUrl = "https://upload.wikimedia.org/wikipedia/en/2/27/Bliss_%28Windows_XP%29.png";

const DebugImageMarkup = () => {
  const [markup, setMarkup] = useImmer<ImageMarkupElement[]>([]);
  const [editing, setEditing] = useState<boolean>(false);

  const handleDownload = async () => {
    const markedupPng = await generatePngWithMarkup(sampleImageUrl, markup);
    const link = document.createElement("a");
    link.href = markedupPng;
    link.download = "markedup.png";
    link.click();
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex w-full items-center justify-between">
        <span className="text-lg font-bold">Image markup tool:</span>
        <div className="flex gap-1.5">
          <Button onClick={() => setEditing(true)}>Edit</Button>
          <Button onClick={() => handleDownload()}>Download</Button>
        </div>
      </div>
      <div className="flex w-full justify-center py-2" style={{ height: `500px` }}>
        <ImageMarkup imageUrl={sampleImageUrl} markup={markup} onMarkupChange={setMarkup} editing={editing} onEditingChange={setEditing} />
      </div>
    </div>
  );
};

export default DebugImageMarkup;
