import { RootState } from "@shared/redux/store";
import React, { createContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { H } from "highlight.run";
import mixpanel from "mixpanel-browser";
import versionInfo from "../../../version.json";

const env = import.meta.env.VITE_APP_ENV_NAME as string;
const mixpanelToken = import.meta.env.VITE_APP_MIXPANEL_TOKEN as string;
const highlightToken = import.meta.env.VITE_APP_HIGHLIGHT_TOKEN as string;
const version = versionInfo.version;

interface ObservabilityContextProps {
  track: (eventName: string, metaData?: Record<string, any>) => Promise<void>;
  trackPageView: (metaData?: Record<string, any>) => Promise<void>;
}

// Create a default context value for ObservabilityContext to avoid having to initialize the context with undefined
const defaultContext: ObservabilityContextProps = {
  track: () => Promise.resolve(),
  trackPageView: () => Promise.resolve(),
};

export const ObservabilityContext = createContext<ObservabilityContextProps>(defaultContext);

export const ObservabilityProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const company = useSelector((state: RootState) => state.db.company);
  const users = useSelector((state: RootState) => state.db.users);
  const supabase_uid = useSelector((state: RootState) => state.auth.supabase_uid);
  const user = users?.find((user) => user.supabase_uid === supabase_uid);

  // use refs to avoid issues with closures in the init and identify calls below
  const initializedRef = useRef(false);
  const identifiedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      init();
    }
    if (initializedRef.current && !identifiedRef.current) {
      identify();
    }
  }, [company, users]);

  const init = async () => {
    // Initialize Mixpanel
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, { track_pageview: false, persistence: "localStorage" });
    }

    // Initialize Highlight. Don't initialize with localhost or else sourcemaps will not work in console
    if (location.hostname !== "localhost" && env !== "development") {
      H.init(highlightToken, {
        environment: env,
        version: version,
        tracingOrigins: ["localhost", "serial-staging-837b9e8.zuplo.app", "serial-main-fa089b1.zuplo.app", "api.serial.io"],
        reportConsoleErrors: true,
        networkRecording: {
          enabled: true,
          recordHeadersAndBody: true,
        },
        integrations: {
          mixpanel: {
            projectToken: mixpanelToken,
          },
        },
      });
    }

    initializedRef.current = true;
  };

  const identify = async () => {
    if (!user?.supabase_uid || !company?.id) return;

    H.identify(user.email, {
      name: `${user.first_name ?? ""} ${user.last_name ?? ""}`,
      role: user.role,
      company: company.name,
      company_id: company.id,
      id: user.supabase_uid,
      supabase_id: user.supabase_uid ?? "",
    });

    // Do not record events from serial support accounts in prod
    if (env === "production" && user.email.includes("@serial.io")) {
      return;
    }

    // set user properties
    if (mixpanelToken) {
      mixpanel.identify(user.email);
      mixpanel.people.set({
        email: user.email,
        name: (user.first_name ?? "") + " " + (user.last_name ?? ""),
        account: user.role,
        company: company.name,
      });
    }

    identifiedRef.current = true;
  };

  const track = async (eventName: string, metaData?: Record<string, any>) => {
    if (!initializedRef.current) return;

    // Do not record events from serial support accounts in prod
    if (env === "production" && user?.email.includes("@serial.io")) {
      return;
    }

    H.track(eventName, metaData);
  };

  const trackPageView = async (metaData?: Record<string, any>) => {
    if (!initializedRef.current) return;

    // Do not record events from serial support accounts in prod
    if (env === "production" && user?.email.includes("@serial.io")) {
      return;
    }

    mixpanel.track_pageview(metaData);
  };

  return (
    <ObservabilityContext.Provider
      value={{
        track,
        trackPageView,
      }}
    >
      {children}
    </ObservabilityContext.Provider>
  );
};
