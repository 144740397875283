import ScatterChart, { ScatterChartDatapoint, ScatterChartSeries } from "@shared/charts/ScatterChart";
import useMeasures from "@shared/measures/MeasuresProvider";
import { useMeasuresGraph } from "@shared/measures/components/graph/MeasuresGraph";
import { filterRootToLeafPathsRelevantToMeasureKeys, findRootToLeafPaths } from "@shared/measures/helpers/genealogy";
import { hashMeasureKey } from "@shared/measures/helpers/measureKeys";
import { summarizeMeasureValue } from "@shared/measures/helpers/measureValues";
import { getMeasureName } from "@shared/measures/helpers/naming";
import { MeasureType } from "@shared/measures/types";
import { RootState } from "@shared/redux/store";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const MeasuresGraphScatter = () => {
  const { uniqueIdentifiers, componentId, selectedMeasureKeys, filterMapping } = useMeasures();
  const { showLegend, showGridLines, xAxisMeasureKey, yAxisMeasureKey, showRetests } = useMeasuresGraph();
  const components = useSelector((state: RootState) => state.db.components);
  const processes = useSelector((state: RootState) => state.db.processes);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const scatterSeries: ScatterChartSeries[] = useMemo(() => {
    if (!xAxisMeasureKey || !yAxisMeasureKey) {
      return [];
    }

    const datapoints: ScatterChartDatapoint[] = uniqueIdentifiers
      .filter((uniqueIdentifier) => filterMapping[uniqueIdentifier.id] === true)
      .reduce((acc: ScatterChartDatapoint[], uniqueIdentifier) => {
        if (!componentId) {
          return acc;
        }
        // Find the x and y measures for this unique identifier
        const x = uniqueIdentifier.measures.find((measure) => hashMeasureKey(measure.key) === hashMeasureKey(xAxisMeasureKey));
        const y = uniqueIdentifier.measures.find((measure) => hashMeasureKey(measure.key) === hashMeasureKey(yAxisMeasureKey));
        if (!x || !y) {
          return acc;
        }
        // Find all root to leaf paths for this unique identifier
        let rootToLeafPaths = findRootToLeafPaths(uniqueIdentifier.genealogy.uniqueIdentifierLinks, uniqueIdentifier);
        rootToLeafPaths = filterRootToLeafPathsRelevantToMeasureKeys(rootToLeafPaths, componentId, selectedMeasureKeys);
        // Each remaining path now corresponds to new data point in the scatter plot
        rootToLeafPaths.forEach((rootToLeafPath) => {
          // Determine which linked unique identifier to use for this measure
          const targetXComponentId = xAxisMeasureKey.component_id;
          const targetYComponentId = yAxisMeasureKey.component_id;
          const linkedXUniqueIdentifierId = rootToLeafPath.find(
            (segment) => segment.componentId === targetXComponentId,
          )?.uniqueIdentifierId;
          const linkedYUniqueIdentifierId = rootToLeafPath.find(
            (segment) => segment.componentId === targetYComponentId,
          )?.uniqueIdentifierId;
          if (!linkedXUniqueIdentifierId || !linkedYUniqueIdentifierId) {
            return;
          }
          // if both measures come from the same process and showRetests is true, push a data point for each retest
          if (x.key.process_id && x.key.process_id === y.key.process_id && showRetests) {
            const numRetests = x.valuesByIdentifier[linkedXUniqueIdentifierId]?.values.length;
            for (let i = 0; i < numRetests; i++) {
              // make sure the timestamps match
              if (
                x.valuesByIdentifier[linkedXUniqueIdentifierId]?.values[i]?.timestamp !==
                y.valuesByIdentifier[linkedYUniqueIdentifierId]?.values[i]?.timestamp
              ) {
                continue;
              }
              const xSummarizedValue = summarizeMeasureValue(x.valuesByIdentifier[linkedXUniqueIdentifierId]?.values[i]);
              const ySummarizedValue = summarizeMeasureValue(y.valuesByIdentifier[linkedYUniqueIdentifierId]?.values[i]);
              const xValue =
                x.key.type === MeasureType.Timestamp ? new Date(xSummarizedValue.value).getTime() : Number(xSummarizedValue.value);
              const yValue =
                y.key.type === MeasureType.Timestamp ? new Date(ySummarizedValue.value).getTime() : Number(ySummarizedValue.value);
              // make sure the values are numbers
              if (xValue === undefined || yValue === undefined || isNaN(xValue) || isNaN(yValue)) {
                continue;
              }
              acc.push({
                label: `${uniqueIdentifier.identifier} (Process Entry #${i + 1})`,
                title: `${xSummarizedValue.formattedValue}, ${ySummarizedValue.formattedValue}`,
                x: xValue,
                y: yValue,
              });
            }
          } else {
            if (
              !x.valuesByIdentifier[linkedXUniqueIdentifierId]?.aggregation ||
              !y.valuesByIdentifier[linkedYUniqueIdentifierId]?.aggregation
            ) {
              return acc;
            }
            acc.push({
              label: uniqueIdentifier.identifier,
              title: `${x.valuesByIdentifier[linkedXUniqueIdentifierId]?.aggregation?.formattedValue}, ${y.valuesByIdentifier[linkedYUniqueIdentifierId]?.aggregation?.formattedValue}`,
              x:
                x.key.type === MeasureType.Timestamp
                  ? new Date(x.valuesByIdentifier[linkedXUniqueIdentifierId]?.aggregation?.value).getTime()
                  : Number(x.valuesByIdentifier[linkedXUniqueIdentifierId]?.aggregation?.value),
              y:
                y.key.type === MeasureType.Timestamp
                  ? new Date(y.valuesByIdentifier[linkedYUniqueIdentifierId]?.aggregation?.value).getTime()
                  : Number(y.valuesByIdentifier[linkedYUniqueIdentifierId]?.aggregation?.value),
            });
          }
        });
        return acc;
      }, []);

    return [
      {
        id: `${hashMeasureKey(xAxisMeasureKey)}-vs-${hashMeasureKey(yAxisMeasureKey)}`,
        label: `${getMeasureName(xAxisMeasureKey, {
          components,
          processes,
          datasets,
        })} vs ${getMeasureName(yAxisMeasureKey, {
          components,
          processes,
          datasets,
        })}`,
        data: datapoints,
      },
    ];
  }, [
    uniqueIdentifiers,
    xAxisMeasureKey,
    yAxisMeasureKey,
    showRetests,
    components,
    processes,
    datasets,
    componentId,
    selectedMeasureKeys,
    filterMapping,
  ]);

  const { xAxisLabel, yAxisLabel } = useMemo(() => {
    if (!xAxisMeasureKey || !yAxisMeasureKey) {
      return { xAxisLabel: "", yAxisLabel: "" };
    }
    return {
      xAxisLabel: getMeasureName(xAxisMeasureKey, { components, processes, datasets }),
      yAxisLabel: getMeasureName(yAxisMeasureKey, { components, processes, datasets }),
    };
  }, [xAxisMeasureKey, yAxisMeasureKey, components, processes, datasets]);

  return (
    <ScatterChart
      size="md"
      className="h-full w-1/3"
      tooltip={{
        title: (context) => (context[0].raw as ScatterChartDatapoint)?.title ?? "",
        label: (context) => (context.raw as ScatterChartDatapoint)?.label ?? "",
      }}
      xAxis={xAxisMeasureKey ? { label: xAxisLabel, type: xAxisMeasureKey.type === MeasureType.Timestamp ? "time" : "linear" } : undefined}
      yAxis={yAxisMeasureKey ? { label: yAxisLabel, type: yAxisMeasureKey.type === MeasureType.Timestamp ? "time" : "linear" } : undefined}
      series={scatterSeries}
      legend={showLegend}
      grid={showGridLines}
      zoom={true}
    />
  );
};

export default MeasuresGraphScatter;
