import { DataType, ProcessType } from "@shared/types/databaseEnums";
import {
  Dataset,
  Field,
  FieldType,
  FilterJoinOperator,
  LogFileSchema,
  LogFileSchemaDatasetLink,
  LogFileSchemaDatasetLinkPathKeyItem,
  ProcessWithReferences,
  ProcessStepWithReferences,
  WorkInstructionBlock,
  WorkInstructionBlockContent,
  WorkInstructionBlockType,
} from "@shared/types/databaseTypes";
import { v4 as uuidv4 } from "uuid";

const mapFieldTypeToDefaultDataType = (type: FieldType): DataType => {
  switch (type) {
    case FieldType.Link:
      return DataType.Link;
    case FieldType.ManualEntry:
      return DataType.ParametricQualitative; // default to string
    case FieldType.Checkbox:
      return DataType.Checkbox;
    case FieldType.PassFail:
      return DataType.PassFail;
    case FieldType.File:
      return DataType.File;
    case FieldType.Image:
      return DataType.Image;
    case FieldType.Signature:
      return DataType.Image;
    case FieldType.Datetime:
      return DataType.Datetime;
    case FieldType.Label:
      return DataType.Checkbox;
    default:
      return DataType.ParametricQualitative;
  }
};

export const defaultGroupNames = {
  [FieldType.Link]: "Link",
  [FieldType.ManualEntry]: "Manual Entry",
  [FieldType.Checkbox]: "Checkbox",
  [FieldType.PassFail]: "Pass / Fail",
  [FieldType.File]: "File",
  [FieldType.Image]: "Image",
  [FieldType.Signature]: "Signature",
  [FieldType.Datetime]: "Datetime",
  [FieldType.Label]: "Label",
};

export const generateBlankField = (
  type: FieldType,
  companyId: string,
  stepId: string,
  processId: string,
  processRevision: number,
  groupName?: string,
  prompt?: string,
  isOptional?: boolean,
  datasetType?: DataType,
): Field => {
  // create a blank dataset for the field
  // only allow dataset type to be overridden if the field type is manual entry and the dataset type is parametric
  let blankDatasetType = mapFieldTypeToDefaultDataType(type);
  if (
    type === FieldType.ManualEntry &&
    datasetType &&
    [DataType.ParametricQualitative, DataType.ParametricQuantitative].includes(datasetType)
  ) {
    blankDatasetType = datasetType;
  }
  const dataset = generateBlankDataset(companyId, processId, processRevision, prompt ?? "", blankDatasetType);
  const newField: Field = {
    id: uuidv4(),
    company_id: companyId,
    process_step_id: stepId,
    type: type,
    group_name: groupName ?? defaultGroupNames[type] ?? "",
    prompt: prompt ?? "",
    is_optional: isOptional ?? false,
    data_validation: null,
    method: null,
    order: 0,
    created_at: new Date().toISOString(),
    dataset_id: dataset.id,
    dataset: dataset,
  } as Field;
  switch (newField.type) {
    case FieldType.Link:
      break;
    case FieldType.ManualEntry:
      newField.data_validation = "STRING";
      break;
    case FieldType.Checkbox:
      newField.is_optional = true;
      break;
    case FieldType.PassFail:
      break;
    case FieldType.File:
      newField.group_name = groupName ?? "";
      newField.prompt = groupName ?? "";
      break;
    case FieldType.Image:
      newField.group_name = groupName ?? "";
      newField.prompt = groupName ?? "";
      newField.method = "UPLOAD";
      break;
    case FieldType.Label:
      newField.is_optional = true;
      newField.group_name = groupName ?? "";
      newField.prompt = groupName ?? "";
      break;
    case FieldType.Signature:
      newField.group_name = groupName ?? "";
      newField.prompt = groupName ?? "";
      break;
    default:
      break;
  }
  return newField;
};

export const generateBlankDataset = (
  companyId: string,
  processId: string,
  processRevision: number,
  name: string,
  type: DataType,
): Dataset => {
  const newDataset: Dataset = {
    id: uuidv4(),
    company_id: companyId,
    data_type: type,
    name: name ?? "",
    process_id: processId,
    process_revision: processRevision,
    usl: null,
    lsl: null,
    unit: null,
    expected_value: null,
    created_at: new Date().toISOString(),
    child_component_id: null,
    order: 0,
    is_active: true,
    prior_values: [],
  };
  return newDataset;
};

export const generateBlankWorkInstructionBlock = (
  type: WorkInstructionBlockType,
  companyId: string,
  stepId: string,
  order: number,
  initContent?: WorkInstructionBlockContent,
): WorkInstructionBlock => {
  const content: WorkInstructionBlockContent = {};
  switch (type) {
    case WorkInstructionBlockType.Heading:
      content.level = 1;
      content.text = "";
      break;
    case WorkInstructionBlockType.Text:
      content.text = "";
      break;
    case WorkInstructionBlockType.Table:
      content.rows = [
        ["", ""],
        ["", ""],
      ];
      content.header_row = false;
      content.header_column = false;
      content.column_widths = ["50%", "50%"];
      break;
    case WorkInstructionBlockType.Delimiter:
      break;
    case WorkInstructionBlockType.Callout:
      content.text = "";
      content.icon = "INFO";
      break;
    case WorkInstructionBlockType.Video:
      content.file_name = "";
      content.height = 400;
      break;
    case WorkInstructionBlockType.Image:
      content.file_name = "";
      content.height = 400;
      break;
    case WorkInstructionBlockType.PDF:
      content.file_name = "";
      content.height = 400;
      break;
    case WorkInstructionBlockType.File:
      content.file_name = "";
      content.caption = "";
      break;
    case WorkInstructionBlockType.List:
      content.items = [""];
      content.listType = "BULLETED";
      break;
    case WorkInstructionBlockType.Code:
      content.text = "";
      break;
  }
  const newWorkInstructionBlock: WorkInstructionBlock = {
    id: uuidv4(),
    company_id: companyId,
    process_step_id: stepId,
    order: order,
    type: type,
    content: {
      ...content,
      ...initContent,
    },
    file_id: null,
    dataset_ref_1: null,
    dataset_ref_2: null,
    dataset_ref_3: null,
    dataset_ref_4: null,
    dataset_ref_5: null,
  } as WorkInstructionBlock;
  return newWorkInstructionBlock;
};

export const generateBlankProcess = (companyId: string, userId?: string): ProcessWithReferences => {
  const newProcess: ProcessWithReferences = {
    id: uuidv4(),
    revision: 0,
    revision_description: "Created new process",
    ai_revision_description: "",
    is_latest_revision: true,
    name: "New Process",
    use_api: false,
    company_id: companyId,
    created_at: new Date().toISOString(),
    created_by_user_id: userId ?? null,
    is_mandatory: true,
    record_operator: true,
    break_prior_links: true,
    allow_batch: false,
    dependent_process_ids: [],
    type: ProcessType.Production,
    process_steps: [],
    approved: false,
    approved_at: null,
    approved_by_user_id: null,
    show_media_numbering: true,
  };
  return newProcess;
};

export const generateBlankProcessStep = (
  companyId: string,
  processId: string,
  processRevision: number,
  order: number,
  name?: string,
): ProcessStepWithReferences => {
  const newProcessStep: ProcessStepWithReferences = {
    id: uuidv4(),
    company_id: companyId,
    process_id: processId,
    process_revision: processRevision,
    order: order,
    name: name ?? "",
    created_at: new Date().toISOString(),
    is_hidden: false,
    work_instruction_blocks: [],
    fields: [],
    filter_conditions: [],
    filter_join_operator: FilterJoinOperator.And,
  };
  return newProcessStep;
};

export const generateBlankLogFileSchema = (logFileSchema: Partial<LogFileSchema>): LogFileSchema => {
  const newLogFileSchema: LogFileSchema = {
    id: uuidv4(),
    revision: 0,
    company_id: "",
    sample_file_data: {},
    sample_file_name: "",
    sample_file_id: "",
    created_at: new Date().toISOString(),
    source_dataset_id: "",
    ...logFileSchema,
  };
  return newLogFileSchema;
};

export const generateBlankSchemaDatasetLink = (schemaDatasetLink: Partial<LogFileSchemaDatasetLink>): LogFileSchemaDatasetLink => {
  const newSchemaDataset: LogFileSchemaDatasetLink = {
    id: uuidv4(),
    company_id: "",
    schema_id: "",
    schema_revision: 0,
    dataset_id: "",
    path_key: [],
    is_active: true,
    created_at: new Date().toISOString(),
    ...schemaDatasetLink,
  };
  return newSchemaDataset;
};

export const generateTemplateProcess = (companyId: string, userId?: string): ProcessWithReferences => {
  const newProcess = generateBlankProcess(companyId, userId);
  const firstProcessStep = generateBlankProcessStep(companyId, newProcess.id, newProcess.revision, 0);
  newProcess.process_steps.push(firstProcessStep);
  newProcess.process_steps[0].work_instruction_blocks = [
    {
      ...generateBlankWorkInstructionBlock(WorkInstructionBlockType.Heading, companyId, firstProcessStep.id, 0),
      type: WorkInstructionBlockType.Heading,
      content: {
        text: "Step 1",
        level: 1,
      },
    },
    {
      ...generateBlankWorkInstructionBlock(WorkInstructionBlockType.Text, companyId, firstProcessStep.id, 1),
      type: WorkInstructionBlockType.Text,
      content: {
        text: "Add some information about the process step here",
      },
    },
    {
      ...generateBlankWorkInstructionBlock(WorkInstructionBlockType.Image, companyId, firstProcessStep.id, 2),
      type: WorkInstructionBlockType.Image,
      file_id: "",
      content: {
        caption: "Add an image of the process here",
        file_name: "",
        height: 200,
      },
    },
  ];
  return newProcess;
};

export const workInstructionBlocksToMarkdown = (blocks: WorkInstructionBlock[]): string => {
  return blocks
    .map((block) => {
      switch (block.type) {
        case WorkInstructionBlockType.Heading:
          return `${"#".repeat(block.content.level)} ${block.content.text}`;
        case WorkInstructionBlockType.Text:
          return block.content.text ?? "";
        case WorkInstructionBlockType.List:
          return block.content.items
            ? block.content.items.map((item) => `${block.content.listType === "BULLETED" ? "-" : "1."} ${item}`).join("\n")
            : "";
        case WorkInstructionBlockType.Table:
          return block.content.rows.map((row) => `| ${row.join(" | ")} |`).join("\n");
        case WorkInstructionBlockType.Callout:
          return `> ${block.content.icon}: ${block.content.text}`;
        case WorkInstructionBlockType.Code:
          return `\`\`\`\n${block.content.text}\n\`\`\``;
        case WorkInstructionBlockType.Delimiter:
          return "---";
        case WorkInstructionBlockType.Image:
        case WorkInstructionBlockType.Video:
        case WorkInstructionBlockType.PDF:
        case WorkInstructionBlockType.File:
          // Skipping these types
          return "";
        default:
          return "";
      }
    })
    .join("\n\n");
};

export const getValueInObjectByPathKey = (
  object: any,
  pathKey: LogFileSchemaDatasetLinkPathKeyItem[],
): string | boolean | number | null => {
  let value = object;
  try {
    pathKey.forEach((pathParam) => {
      value = value[pathParam.key];
    });
  } catch (error) {
    console.error("Error getting value in object by path key", error);
    return null;
  }
  return value;
};

export const findSubPathKeys = (pathKey: LogFileSchemaDatasetLinkPathKeyItem[], value: any): LogFileSchemaDatasetLinkPathKeyItem[][] => {
  if (value === null || value === undefined) {
    return [[...pathKey]];
  } else if (Array.isArray(value)) {
    return value.flatMap((item, index) => findSubPathKeys([...pathKey, { key: index, type: "INDEX" }], item));
  } else if (typeof value === "object") {
    return Object.entries(value).flatMap(([subKey, subValue]) => findSubPathKeys([...pathKey, { key: subKey, type: "KEY" }], subValue));
  } else {
    return [[...pathKey]];
  }
};

export const validateJSON = (text: string): object | null => {
  try {
    return JSON.parse(text);
  } catch (error) {
    console.error("Error parsing JSON", error);
    return null;
  }
};

export const transformDatasetsInSourceCode = (datasets: Dataset[], updatedSourceCode: string) => {
  const dataTypeMap = {
    [DataType.ParametricQualitative]: ["my_process_entry.add_text", "'Default Value'"],
    [DataType.ParametricQuantitative]: ["my_process_entry.add_number", "0"],
    [DataType.File]: ["my_process_entry.add_file", "file_name.csv"],
    [DataType.Image]: ["my_process_entry.add_image", "image_name.png"],
    [DataType.Checkbox]: ["my_process_entry.add_boolean", "True"],
    [DataType.Link]: ["my_process_entry.add_link", "child identifier 123"],
    [DataType.Uid]: ["my_process_entry.add_uid", "uid_value"],
    [DataType.Datetime]: ["my_process_entry.add_datetime", "datetime_value"],
    [DataType.PassFail]: ["my_process_entry.add_pass_fail", "True"],
  };

  const startMarker = "my_process_entry = serial.ProcessEntries.create";
  const endMarker = "# Submitting a process entry";

  const replacementString = datasets
    .map((dataset) => {
      const method = dataTypeMap[dataset.data_type][0];
      const value = dataTypeMap[dataset.data_type][1];

      switch (dataset.data_type) {
        case DataType.File:
        case DataType.Image:
          return `${method}(dataset_name="${dataset.name}", path="/path/to/${dataset.data_type.toLowerCase()}", file_name="${value}")`;
        case DataType.Link:
          return `${method}(dataset_name="${dataset.name}", child_identifier="${value}")`;
        case DataType.Checkbox:
          return `${method}(dataset_name="${dataset.name}", value=${value}, expected_value=${value})`;
        default:
          return `${method}(dataset_name="${dataset.name}", value=${value})`;
      }
    })
    .join("\n");

  const startIndex = updatedSourceCode.indexOf(startMarker);
  const endIndex = updatedSourceCode.indexOf(endMarker);

  if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
    const createEntryEndIndex = updatedSourceCode.indexOf(")", startIndex) + 1;
    const before = updatedSourceCode.substring(0, createEntryEndIndex);
    const after = updatedSourceCode.substring(endIndex);
    updatedSourceCode = `${before}\n${replacementString}\n\n${after}`;
  }

  return updatedSourceCode;
};
