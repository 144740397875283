import React, { createContext, useEffect, useState } from "react";
import {
  ImageMarkupElement,
  ImageMarkupElementArrow,
  ImageMarkupElementCircle,
  ImageMarkupElementRectangle,
  ImageMarkupElementText,
  ImageMarkupElementType,
} from "@shared/types/databaseTypes";
import { imageMarkupElementConfig, ImageMarkupElementConfig, ImageMarkupProps } from "./types";

interface ImageMarkupContextProps {
  // state
  imageUrl: string | null;
  markup: ImageMarkupElement[];
  imageRef: React.RefObject<HTMLImageElement> | null;
  focusedElement: ImageMarkupElement | null;
  focusedElementConfig: ImageMarkupElementConfig | null;
  // state with setters
  editing: boolean;
  setEditing: (editing: boolean) => void;
  focusedElementIndex: number | null;
  setFocusedElementIndex: (index: number | null) => void;
  imageHeight: number;
  setImageHeight: (height: number) => void;
  imageWidth: number;
  setImageWidth: (width: number) => void;
  imageAspectRatio: number;
  setImageAspectRatio: (aspectRatio: number) => void;
  // event handlers
  handleAddElement: (elementType: ImageMarkupElementType) => void;
  handleEditElement: (index: number, partial: Partial<ImageMarkupElement>) => void;
  handleDeleteElement: (index: number) => void;
  handleSave: () => void;
  handleCancel: () => void;
}

// Create a default context value for ImageMarkupContext to avoid having to initialize the context with undefined
const defaultContext: ImageMarkupContextProps = {
  // state
  imageUrl: null,
  markup: [],
  imageRef: null,
  focusedElement: null,
  focusedElementConfig: null,
  // state with setters
  editing: false,
  setEditing: () => {},
  focusedElementIndex: null,
  setFocusedElementIndex: () => {},
  imageHeight: 0,
  setImageHeight: () => {},
  imageWidth: 0,
  setImageWidth: () => {},
  imageAspectRatio: 1,
  setImageAspectRatio: () => {},
  // event handlers
  handleAddElement: () => {},
  handleEditElement: () => {},
  handleDeleteElement: () => {},
  handleSave: () => {},
  handleCancel: () => {},
};

export const ImageMarkupContext = createContext<ImageMarkupContextProps>(defaultContext);

const ImageMarkupProvider: React.FC<React.PropsWithChildren<ImageMarkupProps>> = ({
  imageUrl,
  markup,
  onMarkupChange = () => {},
  editing,
  onEditingChange = () => {},
  children,
}) => {
  const [fallbackMarkup, setFallbackMarkup] = useState<ImageMarkupElement[]>([]);
  const [focusedElementIndex, setFocusedElementIndex] = useState<number | null>(null);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageAspectRatio, setImageAspectRatio] = useState<number>(1);
  const imageRef = React.useRef<HTMLImageElement>(null);

  const handleAddElement = (elementType: ImageMarkupElementType) => {
    onMarkupChange((draft) => {
      let newElementBase = {
        type: elementType as ImageMarkupElementType,
        x: 0.5,
        y: 0.5,
        width: 0.1,
        height: 0.1 * imageAspectRatio,
      } as ImageMarkupElement;
      switch (elementType) {
        case ImageMarkupElementType.Rectangle:
          newElementBase = { ...newElementBase, color: "#000000", fill: null, thickness: 3 } as ImageMarkupElementRectangle;
          break;
        case ImageMarkupElementType.Circle:
          newElementBase = { ...newElementBase, color: "#000000", fill: null, thickness: 3 } as ImageMarkupElementCircle;
          break;
        case ImageMarkupElementType.Text:
          newElementBase = { ...newElementBase, height: 0.05, text: "Text", color: "#000000", fill: null } as ImageMarkupElementText;
          break;
        case ImageMarkupElementType.Arrow:
          newElementBase = { ...newElementBase, color: "#000000", thickness: 3 } as ImageMarkupElementArrow;
          break;
        case ImageMarkupElementType.Magnify:
          break;
        case ImageMarkupElementType.Focus:
          break;
      }
      setFocusedElementIndex(draft.length);
      draft.push(newElementBase);
    });
  };

  const handleEditElement = (index: number, partial: Partial<ImageMarkupElement>) => {
    onMarkupChange((prev) => {
      prev[index] = { ...prev[index], ...partial } as ImageMarkupElement;
    });
  };

  const handleDeleteElement = (index: number) => {
    onMarkupChange((prev) => {
      if (prev[index]) prev.splice(index, 1);
    });
    setFocusedElementIndex(null);
  };

  useEffect(() => {
    if (editing) {
      setFallbackMarkup(JSON.parse(JSON.stringify(markup)));
    }
  }, [editing]);

  const handleSave = () => {
    setFallbackMarkup([]);
    setFocusedElementIndex(null);
    onEditingChange(false);
  };

  const handleCancel = () => {
    onMarkupChange(fallbackMarkup);
    setFallbackMarkup([]);
    onEditingChange(false);
  };

  const focusedElement = focusedElementIndex !== null ? markup[focusedElementIndex] ?? null : null;
  const focusedElementConfig = focusedElement ? imageMarkupElementConfig[focusedElement.type] : null;

  return (
    <ImageMarkupContext.Provider
      value={{
        // state
        imageUrl,
        markup,
        imageRef,
        focusedElement,
        focusedElementConfig,
        // state with setters
        editing,
        setEditing: onEditingChange,
        focusedElementIndex,
        setFocusedElementIndex,
        imageAspectRatio,
        setImageAspectRatio,
        imageHeight,
        setImageHeight,
        imageWidth,
        setImageWidth,
        // event handlers
        handleAddElement,
        handleEditElement,
        handleDeleteElement,
        handleSave,
        handleCancel,
      }}
    >
      {children}
    </ImageMarkupContext.Provider>
  );
};

export default ImageMarkupProvider;
