import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { fetchLatestProcessEntries } from "../../connections/supabaseLatestActivity";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import TypeTag from "@shared/components/TypeTag";
import { RootState } from "@shared/redux/store";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

const HomepageLatestActivity: React.FC<void> = () => {
  // Using an any array as the fetch returns a custom object
  const [lastProcessEntries, setLastProcessEntries] = useState<any[]>([]);
  const [hoverIdentiferIndex, setHoverIdentiferIndex] = useState<number>(0);

  const components = useSelector((state: RootState) => state.db.components);
  const observe = useContext(ObservabilityContext);

  const loadData = async () => {
    setLastProcessEntries(await fetchLatestProcessEntries(5));
  };

  // load data from backend on page load
  useEffect(() => {
    if (lastProcessEntries.length === 0) {
      loadData();
    }
  }, []);

  return (
    <div className="col-span-full flex flex-col lg:col-span-4">
      <table className="w-full table-auto">
        {/* Table header */}
        <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
          <tr>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-left font-semibold">Identifier</div>
            </th>
            <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 sm:table-cell ">
              <div className="text-left font-semibold">Latest Entry</div>
            </th>
            <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
              <div className="text-center font-semibold">Result</div>
            </th>
          </tr>
        </thead>
        {/* Table body */}
        <tbody className="divide-serial-palette-200 divide-y text-sm ">
          {lastProcessEntries.map((entry: any, index: number) => {
            return (
              <tr key={index} onMouseEnter={() => setHoverIdentiferIndex(index)} onMouseLeave={() => setHoverIdentiferIndex(0)}>
                <td className="max-w-[120px] cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                  <div className="flex items-center space-x-3 ">
                    <ComponentAvatar
                      component={components.find((component) => component.id === entry.components?.id)}
                      size="sm"
                      tooltip={true}
                    />
                    <div className="flex flex-col overflow-hidden">
                      <Link
                        to={`/component/${entry.components.id}`}
                        onClick={() => observe.track("Navigate to Component", { "UI Source": "Dashboard Latest Activity" })}
                        className="truncate text-left text-sm font-bold hover:underline"
                      >
                        {entry.components.name ?? "-"}
                      </Link>
                      <div className="inline-flex">
                        <Link
                          to={`/snlookup?uid=${entry.unique_identifiers.id}`}
                          onClick={() => observe.track("Navigate to SnLookup", { "UI Source": "Dashboard - Latest Activity" })}
                          className="truncate text-left hover:underline"
                        >
                          {entry.unique_identifiers.identifier}
                        </Link>
                        <CopyToClipboard
                          text={entry?.unique_identifiers?.identifier}
                          className={`${hoverIdentiferIndex === index ? "" : "text-white"}`}
                          noTooltip
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td className="hidden max-w-[100px] whitespace-normal px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
                  <div className="flex-col">
                    <div className="truncate text-left text-sm font-bold">{entry.processes?.name ?? "-"}</div>
                    <div className="truncate text-left">{moment(entry?.timestamp).format("MM/DD/YY HH:mma")}</div>
                  </div>
                </td>
                <td className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                  <div className="flex justify-center text-[10px]">
                    {entry.is_pass === null || entry.is_pass === undefined ? (
                      <TypeTag hideText className="w-8" type={"NA"} />
                    ) : entry.is_pass ? (
                      <TypeTag hideText className="w-8" type={"PASS"} />
                    ) : (
                      <TypeTag hideText className="w-8" type={"FAIL"} />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HomepageLatestActivity;
