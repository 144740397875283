import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "@shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { handleForgotPassword } from "@shared/connections/supabaseAuth";

interface AuthForgotPasswordProps {}

const AuthForgotPassword: React.FC<AuthForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailIsSent, setEmailIsSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClickForgotPassword = async () => {
    setIsLoading(true);
    const error = await handleForgotPassword(email);
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(null);
      setEmailIsSent(true);
    }
    setIsLoading(false);
  };

  // Trigger handleClickForgotPassword() on enter key press
  useEffect(() => {
    const handleEnterKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleClickForgotPassword();
      }
    };
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [email]);

  if (emailIsSent) {
    return (
      <div className="flex h-full w-full flex-col items-center px-10">
        <div className="border-3 border-serial-palette-800 -mt-2 mb-6 flex h-16 w-16 items-center justify-center rounded-full">
          <FontAwesomeIcon icon={faEnvelope} size="2x" className="text-serial-palette-800" />
        </div>
        <div className="pb-2 text-2xl font-medium">Check Your Email</div>
        <div className="pb-6 text-center">Please check the email address: {email} for instructions to reset your password.</div>
        {errorMessage && (
          <div className="inline-flex w-full space-x-2 pb-4 text-left">
            <FontAwesomeIcon icon={faExclamationCircle} className="pt-[2px] text-red-500" />
            <div className="text-sm text-red-500">{errorMessage}</div>
          </div>
        )}
        <button
          className="btn bg-serial-palette-900 h-12 w-full justify-center rounded-md text-white hover:cursor-pointer"
          onClick={() => handleClickForgotPassword()}
        >
          {isLoading ? <Loader styleOverride="w-6 h-6" /> : "Resend Email"}
        </button>
        <div className="w-full pt-4 text-center text-sm font-bold hover:cursor-pointer" onClick={() => navigate("/auth/signin")}>
          Back to Sign in
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col items-center px-10">
      <div className="pb-2 text-2xl font-medium">Forgot Your Password?</div>
      <div className="pb-6 text-center">Enter your email address to reset your password</div>
      <input
        type="email"
        placeholder="Email Address"
        className="form-input focus:ring-serial-palette-600 focus:border-serial-palette-600 mb-4 w-full text-lg focus:ring-1"
        onChange={(event) => setEmail(event.target.value)}
      />
      {errorMessage && (
        <div className="mt-1 inline-flex w-full space-x-2 pb-4 text-left">
          <FontAwesomeIcon icon={faExclamationCircle} className="pt-[2px] text-red-500" />
          <div className="text-sm text-red-500">{errorMessage}</div>
        </div>
      )}
      <button
        className="btn bg-serial-palette-900 mb-4 h-12 w-full justify-center rounded-md text-white hover:cursor-pointer"
        onClick={() => handleClickForgotPassword()}
      >
        {isLoading ? <Loader styleOverride="w-6 h-6" /> : "Continue"}
      </button>
      <div className="w-full text-center text-sm font-bold hover:cursor-pointer" onClick={() => navigate("/auth/signin")}>
        Back to Sign in
      </div>
    </div>
  );
};

export default AuthForgotPassword;
