import store from "@shared/redux/store";
import { setSupabaseUid } from "@shared/redux/auth";
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { Database } from "@shared/types/databaseTypesAuto";

const supabaseUrl = import.meta.env.VITE_APP_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_APP_SUPABASE_ANON_KEY;
let supabaseInstance: SupabaseClient;

// Function that returns a supabase client
export const getSupabase = (token: string | null = null) => {
  if (supabaseInstance) return supabaseInstance;
  const options: any = {};

  if (token) {
    options.global = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  supabaseInstance = createClient<Database>(supabaseUrl, supabaseAnonKey, options);
  return supabaseInstance;
};

export const handleForgotPassword = async (email: string): Promise<Error | null> => {
  const supabase = getSupabase();
  const currentUrl: URL = new URL(window.location.href);
  currentUrl.pathname = "/auth/resetpassword";
  const redirectUrl: string = currentUrl.toString();
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: redirectUrl,
  });
  if (error) {
    return error;
  } else {
    return null;
  }
};

export const handleLogout = async () => {
  const supabase = getSupabase();
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error(error);
  }
};

// Extract supabase UID to redux
export const handleSetSupabaseUid = async (uid: any) => {
  store.dispatch(setSupabaseUid(uid));
};
