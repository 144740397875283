import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import imageCompression from "browser-image-compression";
import FileInputBox, { FileInfo } from "@shared/components/FileInputBox";
import { ProductionFormGenericFieldProps } from "../../types";
import { ProductionContext } from "../../ProductionProvider";
import { uploadFile } from "../../connections/storage";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";
import WebcamBox from "@shared/components/WebcamBox";
import SignatureBox from "@shared/components/SignatureBox";
import { v4 as uuidv4 } from "uuid";
import { fetchDownloadUrl, fetchFileSize } from "@shared/connections/supabaseGeneral";
import { FieldType } from "@shared/types/databaseTypes";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { base64StringtoFile } from "@shared/utils/helpers";

const ProductionFormFileOrImage: React.FC<ProductionFormGenericFieldProps> = ({ field, draftData }) => {
  const { db, focusedFieldId, handleSetFocusedField, handleUpdateDraftSubmission, handleValidateField } = useContext(ProductionContext);
  const [uploadSuccessful, setUploadSuccessful] = React.useState<boolean | null>(null);
  const [percentComplete, setPercentComplete] = React.useState<number | null>(null);
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);
  const { company } = db;

  const token = useSelector((state: RootState) => state.auth.token);

  const { triggerToast } = useContext(ToastContext);

  const webcamRef = useRef<any>(null);

  const verifyFileInStorage = useCallback(
    async (fileInfo: FileInfo, fileId: string) => {
      // attempt to download the file to make sure it was uploaded successfully
      if (!company?.id) {
        triggerToast(ToastType.Error, "Upload Error", `There was an error uploading or retrieving the file: ${fileInfo.name}.`);
        handleValidateField(field.id, {});
        console.error("File upload failed, no company id found");
        setPercentComplete(null);
        return;
      }
      // check that the size of the blob pointed to by the url matches the size of the file that was uploaded
      const fileSize = await fetchFileSize(
        fileId,
        company?.id,
        field?.type === FieldType.Image || field?.type === FieldType.Signature ? "data-images" : "data-files",
      );
      if (fileInfo.size && fileSize !== fileInfo.size) {
        triggerToast(ToastType.Error, "Upload Error", `There was an error uploading or retrieving the file: ${fileInfo.name}.`);
        handleValidateField(field.id, {});
        console.error("File upload failed, downloaded file size does not match uploaded file size");
        setPercentComplete(null);
        return;
      }
      setUploadSuccessful(true);
      handleUpdateDraftSubmission(field.id, { fileId: fileId, fileInfo: fileInfo});
      setPercentComplete(null);
      if (field?.type !== FieldType.Image && field?.type !== FieldType.Signature) return;
      const downloadedFileUrl = await fetchDownloadUrl(
        field?.type === FieldType.Image || field?.type === FieldType.Signature ? "data-images" : "data-files",
        fileId,
        company?.id,
        true,
        undefined,
        undefined,
        "low"
      );
      if (!downloadedFileUrl) {
        triggerToast(ToastType.Error, "Upload Error", `There was an error uploading or retrieving the file: ${fileInfo.name}.`);
        handleValidateField(field.id, {});
        console.error("File upload failed, could not download file");
        setPercentComplete(null);
        return;
      }
      setImageUrl(downloadedFileUrl);
    },
    [db.company?.id, field.id, field.type, handleUpdateDraftSubmission, triggerToast],
  );

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      if (!files || files.length === 0) return;
      let file = files[0];
      if (!token || !db.company?.id) {
        triggerToast(ToastType.Error, "Token Error", "You must be logged in to upload files.");
        console.error("No token found at file upload");
        return;
      }
      if (file.size >= 52428800) {
        // 50MB
        triggerToast(ToastType.Error, "File Too Large", "Files must be less than 50MB.");
        console.error("File too large");
        return;
      }
      // Downscale image if it is an image
      if (file.type.startsWith("image/") && company?.config.image_compression_size_bytes && company.config.image_compression_size_bytes < file.size) {
        try {
          const options = {
            maxSizeMB: (company.config.image_compression_size_bytes / 1024 / 1024),
            maxWidthOrHeight: 1024,
            useWebWorker: true,
          };
          file = await imageCompression(file, options);
        } catch (error) {
          console.error(error);
          triggerToast(ToastType.Error, "Compression Error", "There was an error compressing the image.");
          return;
        }
      }
      const fileInfo = {
        name: file.name,
        size: file.size,
        type: file.type,
      };
      const fileId = uuidv4();
      // set the fileId in the draft submission so that we can verify it later
      handleUpdateDraftSubmission(field.id, { fileId: fileId });
      uploadFile(
        token,
        field?.type === FieldType.Image || field?.type === FieldType.Signature ? "data-images" : "data-files",
        `${db.company.id}/${fileId}`,
        file,
        (error) => {
          console.error(error);
          triggerToast(ToastType.Error, "Upload Error", "There was an error uploading the file.");
          setPercentComplete(null);
          setUploadSuccessful(false);
        },
        () => {
          verifyFileInStorage(fileInfo, fileId);
        },
        (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
          setPercentComplete(Number(percentage));
        },
      );
    },
    [db.company, token, triggerToast, verifyFileInStorage],
  );

  const handleCaptureImage = () => {
    if (!webcamRef.current) return;
    const newScreenshot = webcamRef.current.getScreenshot();
    if (newScreenshot) {
      const webcamImage = base64StringtoFile(newScreenshot, "image.png");
      handleUploadFiles([webcamImage]);
    }
  };

  const handleCaptureSignature = (dataUrl: string) => {
    const signatureImage = base64StringtoFile(dataUrl, "signature.png");
    handleUploadFiles([signatureImage]);
  };

  const handleReset = useCallback(() => {
    handleUpdateDraftSubmission(field.id, undefined);
    setImageUrl(null);
  }, [field.id, handleUpdateDraftSubmission]);

  useEffect(() => {
    if (!draftData?.fileId) {
      setImageUrl(null);
      return;
    }
    if (draftData?.fileId && draftData?.fileInfo && !uploadSuccessful) {
      verifyFileInStorage(draftData?.fileInfo, draftData?.fileId);
    }
  }, [draftData?.fileId, draftData?.fileInfo, uploadSuccessful]);

  const isValid = useMemo(() => {
    if (draftData?.isValid && !draftData.showInvalidAsWarning === false) return false;
    if (uploadSuccessful === false) return false;
    if (uploadSuccessful === true && draftData?.fileInfo) return true;
    return null;
  }, [draftData?.isValid, uploadSuccessful, draftData?.fileInfo]);

  return (
    <div className="flex w-full flex-col gap-y-2">
      <div
        className={`text-serial-palette-400 flex w-full justify-between rounded border font-light italic ${field?.type === FieldType.Image && field.method == "CAMERA" ? "border-0" : "h-36"} ${isValid === true ? "serial-input-valid" : isValid === false ? "serial-input-invalid" : ""}`}
      >
        {field?.type === FieldType.Image && field.method == "UPLOAD" && imageUrl && (
          <div className="bg-serial-palette-50 border-serial-palette-200 ml-4 mt-4 flex h-28 w-32 shrink-0 items-center justify-center overflow-hidden rounded-md border-2">
            <img src={imageUrl} alt="" className="h-full w-full object-cover" />
          </div>
        )}
        {((field?.type === FieldType.Image && field.method == "UPLOAD") || field?.type === FieldType.File) && (
          <FileInputBox
            allowPaste={focusedFieldId === field.id}
            handleUploadFiles={handleUploadFiles}
            handleResetFile={handleReset}
            icon={field?.type === FieldType.Image ? faImage : undefined}
            onBrowseFocus={() => handleSetFocusedField(field.id)}
            fileInfo={draftData?.fileInfo ?? null}
          />
        )}
        {field?.type === FieldType.Image && field.method == "CAMERA" && (
          <WebcamBox handleResetImage={handleReset} handleCaptureImage={handleCaptureImage} imageUrl={imageUrl} webcamRef={webcamRef} />
        )}
        {field?.type === FieldType.Signature && (
          <SignatureBox handleResetSignature={handleReset} handleCaptureSignature={handleCaptureSignature} signatureUrl={imageUrl} />
        )}
      </div>
      {percentComplete !== null && (
        <div className="h-3 w-full overflow-clip rounded-full border bg-white">
          <div className="h-full bg-green-500 transition-all duration-500 ease-in-out" style={{ width: `${percentComplete}%` }} />
        </div>
      )}
    </div>
  );
};

export default ProductionFormFileOrImage;
