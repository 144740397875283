import { useContext, useRef } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { PassFailField } from "@shared/types/databaseTypes";
import { ProcessBuilderFieldContentsProps } from "../../types";
import ContentEditable from "@shared/components/ContentEditable";

const ProcessBuilderFieldPassFail: React.FC<ProcessBuilderFieldContentsProps<PassFailField>> = ({ field, stepIndex }) => {
  const { readOnly, handleUpdateFields } = useContext(ProcessBuilderContext);
  const promptRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex w-full gap-x-2">
      <div className="flex min-w-0 flex-grow">
        {!field.is_optional && <div className="pr-1 text-rose-500">*</div>}
        <ContentEditable
          contentRef={promptRef}
          onInput={() => handleUpdateFields(stepIndex, [field.id], "prompt", promptRef.current?.innerText)}
          className="input-box w-full text-sm outline-0"
          placeholder="Pass / fail prompt..."
          content={field.prompt}
          readOnly={readOnly}
        />
      </div>
      <div className="border-serial-palette-200 ml-2 flex flex-wrap justify-end gap-x-2 gap-y-1.5 xl:flex-nowrap">
        <button tabIndex={-1} disabled className="btn border-serial-palette-200 h-8 w-16 text-base text-green-500">
          Pass
        </button>
        <button tabIndex={-1} disabled className="btn border-serial-palette-200 h-8 w-16 text-base text-rose-500">
          Fail
        </button>
      </div>
    </div>
  );
};

export default ProcessBuilderFieldPassFail;
