import React, { useContext, useEffect, useState } from "react";
import { WorkOrder, WorkOrderAttachment } from "@shared/types/databaseTypes";
import { WorkOrderAttachmentDraft } from "../types";
import { WorkOrderContext } from "../WorkOrders";
import WorkOrderAttachmentsRow from "./WorkOrderAttachmentsRow";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

interface WorkOrderAttachmentsProps {
  workOrder: WorkOrder;
  newAttachments: WorkOrderAttachmentDraft[];
  setNewAttachments: React.Dispatch<React.SetStateAction<WorkOrderAttachmentDraft[]>>;
}

const WorkOrderAttachments: React.FC<WorkOrderAttachmentsProps> = ({ workOrder, newAttachments, setNewAttachments }) => {
  const [editableIndex, setEditableIndex] = useState<number | null>(null);
  const context = useContext(WorkOrderContext);
  const observe = useContext(ObservabilityContext);

  // Handle delete can be used to delete either a draft attachment or an existing attachment depending on whether datasetId or index is passed
  const handleDelete = async (index?: number, datasetId?: string) => {
    if (datasetId) {
      await context?.handleDeleteAttachment(workOrder.id, datasetId);
      observe.track("work-order_delete-attachment");
    } else if (index !== undefined) {
      setNewAttachments([...newAttachments.slice(0, index), ...newAttachments.slice(index + 1)]);
    }
  };

  const handleSave = async (index: number) => {
    await context?.handleAddAttachment(workOrder.id, newAttachments[index]);
    handleDelete(index); // delete the draft attachment after it's been saved
  };

  const handleUpdateAttachment = (
    index: number,
    key: "url" | "name" | "value" | "file_name",
    value: string,
    attachment?: WorkOrderAttachmentDraft,
  ): WorkOrderAttachmentDraft => {
    let contents = attachment ?? { ...newAttachments[index] };
    contents[key] = value;
    setNewAttachments([...newAttachments.slice(0, index), contents, ...newAttachments.slice(index + 1)]);
    return contents;
  };

  // reset new approvers when work order status changes
  useEffect(() => {
    setNewAttachments([]);
  }, [workOrder.status]);

  return (
    <table className="w-full table-auto">
      {/* Table header */}
      <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
        <tr>
          <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 ">
            <div className="text-center font-semibold">Type</div>
          </th>
          <th className="whitespace-normal px-2 py-3 pl-6 first:pl-5 last:pr-5">
            <div className="text-left font-semibold">Title</div>
          </th>
          <th className="whitespace-normal px-2 py-3 pl-5 first:pl-5 last:pr-5">
            <div className="text-left font-semibold">Attachment</div>
          </th>
          <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 md:table-cell ">
            <div className="text-center font-semibold">Added By</div>
          </th>
          <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 md:table-cell">
            <div className="text-left font-semibold">Added At</div>
          </th>
          <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
            <div className="text-center font-semibold">Upload / Delete</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {workOrder.attachments
          ?.filter((a) => !a.is_deleted)
          .map((attachment: WorkOrderAttachment, index: number) => {
            return (
              <WorkOrderAttachmentsRow key={index} attachment={attachment} index={index} isDraft={false} handleDelete={handleDelete} />
            );
          })}
        {newAttachments.map((attachment: WorkOrderAttachmentDraft, index: number) => {
          return (
            <WorkOrderAttachmentsRow
              key={index}
              attachment={attachment}
              index={index}
              isDraft={true}
              handleDelete={handleDelete}
              handleSave={handleSave}
              handleUpdateAttachment={handleUpdateAttachment}
              editableIndex={editableIndex}
              setEditableIndex={setEditableIndex}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default WorkOrderAttachments;
