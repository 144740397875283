import TypeTag from "@shared/components/TypeTag";
import { useContext } from "react";
import Banner2 from "@shared/components/Banner2";
import { FieldGroup } from "../../hooks/useFieldGroups";
import { ProductionContext } from "../../ProductionProvider";
import ProductionFormLink from "./ProductionFormLink";
import ProductionFormParametric from "./ProductionFormParametric";
import ProductionFormPassFail from "./ProductionFormPassFail";
import ProductionFormCheckbox from "./ProductionFormCheckbox";
import { FieldType } from "@shared/types/databaseTypes";
import ProductionFormFileOrImage from "./ProductionFormFileOrImage";
import ProductionFormDatetime from "./ProductionFormDatetime";
import ProductionFormLabel from "./ProductionFormLabel";

interface ProductionFormGroupProps {
  group: FieldGroup;
  index: number;
}

const ProductionFormGroup: React.FC<ProductionFormGroupProps> = ({ group, index }) => {
  const { draftSubmission, focusedFieldId, handleSetFocusedField } = useContext(ProductionContext);

  const groupIsFocused = group.fields.some((field) => field.id === focusedFieldId);

  return (
    <div className="flex w-full items-end gap-x-1.5" onClick={() => handleSetFocusedField(group.fields?.[0]?.id)}>
      <div className="bg-serial-palette-100 flex h-full w-10 shrink-0 items-center justify-center rounded border font-bold">
        {index + 1}
      </div>
      <div
        className={`relative flex min-w-0 flex-grow flex-col justify-center rounded border bg-white px-5 pb-5 pt-3 ${groupIsFocused && "ring-serial-palette-200 ring-2"}`}
      >
        <div className="mb-3 flex w-full items-center text-sm font-medium">
          <TypeTag className={"mr-2 pt-1"} hideText={true} type={group.type} />
          <span>{group.name}</span>
          {/* Show required star here only for file, images & signatures */}
          {!group.fields[0]?.is_optional && [FieldType.Image, FieldType.File, FieldType.Signature].includes(group.type) && (
            <span className="pl-1 text-rose-500">*</span>
          )}
        </div>
        <div className="flex w-full flex-col">
          {group.fields.map((field, index) => {
            const draftData = draftSubmission[field.id] ?? null;
            return (
              <div
                key={index}
                className="w-full pb-4 last:pb-0"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSetFocusedField(field.id);
                }}
              >
                {draftData?.message && (
                  <Banner2 className="w-full pb-2" type={draftData.isValid === false && !draftData.showInvalidAsWarning ? "error" : "warning"} hideX open>
                    {draftData.message}
                  </Banner2>
                )}
                {field.type == FieldType.File && <ProductionFormFileOrImage field={field} draftData={draftData} />}
                {field.type == FieldType.Image && <ProductionFormFileOrImage field={field} draftData={draftData} />}
                {field.type == FieldType.Link && <ProductionFormLink field={field} draftData={draftData} />}
                {field.type == FieldType.Signature && <ProductionFormFileOrImage field={field} draftData={draftData} />}
                {field.type == FieldType.ManualEntry && <ProductionFormParametric field={field} draftData={draftData} />}
                {field.type == FieldType.PassFail && <ProductionFormPassFail field={field} draftData={draftData} />}
                {field.type == FieldType.Checkbox && <ProductionFormCheckbox field={field} draftData={draftData} />}
                {field.type == FieldType.Datetime && <ProductionFormDatetime field={field} draftData={draftData} />}
                {field.type == FieldType.Label && <ProductionFormLabel field={field} draftData={draftData} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductionFormGroup;
