import { useContext, useRef } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { DatetimeField } from "@shared/types/databaseTypes";
import { ProcessBuilderFieldContentsProps } from "../../types";
import ContentEditable from "@shared/components/ContentEditable";
import DatePicker from "@shared/components/DatePicker";

const ProcessBuilderFieldDatetime: React.FC<ProcessBuilderFieldContentsProps<DatetimeField>> = ({ field, stepIndex }) => {
  const { readOnly, handleUpdateFields } = useContext(ProcessBuilderContext);
  const promptRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex w-full flex-col gap-y-1">
      <div className="flex">
        {!field.is_optional && <div className="pr-1 text-rose-500">*</div>}
        <ContentEditable
          contentRef={promptRef}
          onInput={() => handleUpdateFields(stepIndex, [field.id], "prompt", promptRef.current?.innerText)}
          className="input-box w-full text-sm outline-0"
          placeholder="Prompt..."
          content={field.prompt}
          readOnly={readOnly}
        />
      </div>
      <DatePicker
        className="border-serial-palette-200 text-serial-palette-400 w-full px-3 py-2 font-light italic !opacity-100"
        disabled={true}
      />
    </div>
  );
};

export default ProcessBuilderFieldDatetime;
