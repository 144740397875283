import * as React from "react";
import Popover from "./Popover";
import Button from "./Button";
import Command from "./Command";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { cn } from "@shared/utils/tailwind";
import { checkIsUuid } from "@shared/utils/helpers";

interface ComboboxContextProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: string | null;
  onValueChange: (value: string | null) => void;
}

const defaultContext: ComboboxContextProps = {
  open: false,
  setOpen: () => {},
  value: null,
  onValueChange: () => {},
};

export const ComboboxContext = React.createContext<ComboboxContextProps>(defaultContext);

const ComboboxTrigger = React.forwardRef<React.ElementRef<typeof Button>, React.ComponentPropsWithoutRef<typeof Button>>(
  ({ className, children, ...props }, ref) => {
    const { value: selectedValue } = React.useContext(ComboboxContext);
    return (
      <Popover.Trigger asChild>
        <Button
          variant="outline"
          className={cn("justify-between", !selectedValue && "text-serial-palette-400 font-light", className)}
          ref={ref}
          {...props}
        >
          <span className="truncate">{children ?? selectedValue ?? "Select..."}</span>
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      </Popover.Trigger>
    );
  },
);
ComboboxTrigger.displayName = Button.displayName;

const ComboboxContent = React.forwardRef<React.ElementRef<typeof Popover.Content>, React.ComponentPropsWithoutRef<typeof Popover.Content>>(
  ({ className, children, ...props }, ref) => {
    return (
      <Popover.Content className={cn("w-full p-0", className)} ref={ref} {...props} align="start">
        <Command.Root
          filter={(value, search, keywords) => {
            const extendValue = (checkIsUuid(value) ? "" : value) + " " + keywords?.join(" ");
            if (extendValue.trim().toLocaleLowerCase().includes(search.trim().toLowerCase())) return 1;
            return 0;
          }}
        >
          <Command.Input placeholder="Search..." className="h-8" />
          <Command.List>
            <Command.Empty>
              <span className="text-serial-palette-400 italic">No Results found.</span>
            </Command.Empty>
            <Command.Group>{children}</Command.Group>
          </Command.List>
        </Command.Root>
      </Popover.Content>
    );
  },
);
ComboboxContent.displayName = Popover.Content.displayName;

const ComboboxItem = React.forwardRef<React.ElementRef<typeof Command.Item>, React.ComponentPropsWithoutRef<typeof Command.Item>>(
  ({ className, value, onSelect, children, ...props }, ref) => {
    const { value: selectedValue, onValueChange, setOpen } = React.useContext(ComboboxContext);
    return (
      <Command.Item
        ref={ref}
        value={value}
        onSelect={(currentValue) => {
          onValueChange(currentValue === selectedValue ? null : currentValue);
          setOpen(false);
        }}
        className={cn("h-8", className)}
        {...props}
      >
        <span className="truncate">{children}</span>
        {selectedValue === value && <FontAwesomeIcon icon={faCheck} className="ml-auto pr-0.5" />}
      </Command.Item>
    );
  },
);
ComboboxItem.displayName = Command.Item.displayName;

const ComboboxRoot = ({
  children,
  value,
  onValueChange,
}: {
  children: React.ReactNode;
  value: string | null;
  onValueChange: (value: string | null) => void;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <ComboboxContext.Provider value={{ open, setOpen, value, onValueChange }}>
      <Popover.Root open={open} onOpenChange={setOpen}>
        {children}
      </Popover.Root>
    </ComboboxContext.Provider>
  );
};
ComboboxRoot.displayName = Popover.Root.displayName;

const Combobox = Object.assign(ComboboxRoot, {
  Root: ComboboxRoot,
  Trigger: ComboboxTrigger,
  Content: ComboboxContent,
  Item: ComboboxItem,
});

export default Combobox;
