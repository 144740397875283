import { useContext, useEffect, useMemo, useState } from "react";
import "react18-json-view/src/style.css";
import { SnLookupContext } from "../../SnLookupProvider";
import { ProcessEntry } from "@shared/types/databaseTypes";
import { fetchAllProcessEntries } from "../../connections/supabaseProcessEntries";
import DataAnalysisModalProcessEntriesSidebar from "./DataAnalysisModalProcessEntriesSelector";
import DataAnalysisModalProcessEntriesData from "./DataAnalysisModalProcessEntriesData";

const DataAnalysisModalProcessEntries = () => {
  const { selectedProcess } = useContext(SnLookupContext);

  const [allProcessEntries, setAllProcessEntries] = useState<ProcessEntry[]>([]);
  const [selectedProcessEntryId, setSelectedProcessEntryId] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedProcess?.id || !selectedProcess?.entries[0].unique_identifier_id) {
      setAllProcessEntries([]);
      return;
    }
    fetchAllProcessEntries(selectedProcess.id, selectedProcess.entries[0].unique_identifier_id).then((processEntries) => {
      setAllProcessEntries(processEntries);
      setSelectedProcessEntryId(processEntries[0]?.id);
    });
  }, [selectedProcess?.id, selectedProcess?.entries[0].unique_identifier_id]);

  const selectedProcessEntry = useMemo(
    () => allProcessEntries.find((entry) => entry.id === selectedProcessEntryId),
    [allProcessEntries, selectedProcessEntryId],
  );

  return (
    <div className="flex min-h-0 min-w-0 flex-grow">
      <DataAnalysisModalProcessEntriesSidebar
        allProcessEntries={allProcessEntries}
        selectedProcessEntryId={selectedProcessEntryId}
        setSelectedProcessEntryId={setSelectedProcessEntryId}
      />
      <DataAnalysisModalProcessEntriesData selectedProcessEntry={selectedProcessEntry} />
    </div>
  );
};

export default DataAnalysisModalProcessEntries;
