import React, { useContext } from "react";
import { LabelFormatterContext } from "../LabelFormatterProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatLabelSize } from "../helpers/helpers";
import ContentEditable from "@shared/components/ContentEditable";

const LabelFormatterSelector: React.FC = () => {
  const { draftLabels, selectedLabelId, setSelectedLabelId, handleNewLabel, handleEditLabel, handleDeleteLabel, showInactiveLabels } =
    useContext(LabelFormatterContext);
  const contentRefs = draftLabels.map(() => React.createRef<HTMLDivElement>());
  const filteredDraftLabels = draftLabels.filter((label) => showInactiveLabels || label.used);

  return (
    <div className="scrollbar-hide flex h-full w-56 shrink-0 flex-col overflow-y-auto border-r">
      {filteredDraftLabels.map((label, index) => {
        const isSelected = label.id === selectedLabelId;
        const formattedLabelSize = formatLabelSize(label);
        return (
          <div
            key={index}
            className={`group relative flex w-full shrink-0 cursor-pointer flex-col gap-0.5 border-b px-4 py-2 ${isSelected ? "bg-serial-palette-100" : "hover:bg-serial-palette-100 bg-white"}`}
            onClick={() => setSelectedLabelId(label.id)}
          >
            <div
              className={`${isSelected ? "absolute" : "hidden group-hover:block"} bg-serial-palette-500 absolute left-0 top-[17%] h-2/3 w-[7px] rounded-r`}
            />
            <div className="flex w-full items-center justify-between gap-1 overflow-hidden text-sm">
              <div className="flex min-w-0 flex-grow">
                <ContentEditable
                  className="truncate font-semibold outline-none"
                  contentRef={contentRefs[index]}
                  placeholder="Schema Name"
                  content={label.name ?? ""}
                  onInput={(e) => handleEditLabel("name", e.currentTarget.innerText ?? "")}
                />
              </div>
              <button
                className="hidden group-hover:flex"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteLabel(label.id);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className={`text-sm`}>{formattedLabelSize}</div>
          </div>
        );
      })}
      <div className="flex w-full justify-center py-4">
        <button
          tabIndex={-1}
          className="btn btn-xs serial-btn-dark m-0 mr-0.5 h-[18px] gap-x-1 whitespace-nowrap rounded-md text-xs"
          onClick={() => handleNewLabel()}
        >
          <FontAwesomeIcon icon={faPlus} size="xs" />
          Add Label
        </button>
      </div>
    </div>
  );
};

export default LabelFormatterSelector;
