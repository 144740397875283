import { faArchive, faCheck, faFeather, faGear, faStamp, faStop } from "@fortawesome/free-solid-svg-icons";
import { WorkOrderStatus } from "@shared/types/databaseEnums";
import { WorkOrder } from "@shared/types/databaseTypes";
import i18next from "@shared/i18n/config";

export const workOrderStatusStyle = {
  [WorkOrderStatus.Draft]: {
    name: "Draft",
    icon: faFeather,
    bgColor: "bg-amber-600",
    textColor: "text-amber-600",
    transitionLabel: "Set to Draft",
  },
  [WorkOrderStatus.Review]: {
    name: "Review",
    icon: faStamp,
    bgColor: "bg-yellow-500",
    textColor: "text-yellow-500",
    transitionLabel: "Submit for Review",
  },
  [WorkOrderStatus.Production]: {
    name: "Build",
    icon: faGear,
    bgColor: "bg-sky-600",
    textColor: "text-sky-600",
    transitionLabel: "Start Build",
  },
  [WorkOrderStatus.Complete]: {
    name: "Completed",
    icon: faCheck,
    bgColor: "bg-green-500",
    textColor: "text-green-500",
    transitionLabel: "Mark as Complete",
  },
  [WorkOrderStatus.Canceled]: {
    name: "Canceled",
    icon: faStop,
    bgColor: "bg-red-500",
    textColor: "text-red-500",
    transitionLabel: `Cancel ${i18next.t("workOrder")}`,
  },
  [WorkOrderStatus.Archived]: {
    name: "Archived",
    icon: faArchive,
    bgColor: "bg-slate-700",
    textColor: "text-slate-700",
    transitionLabel: "Archive",
  },
};
function updateTranslations() {
  workOrderStatusStyle[WorkOrderStatus.Draft].transitionLabel = `Cancel ${i18next.t("workOrder")}`;
}

// Initial translation if i18next is ready
if (i18next.isInitialized) {
  updateTranslations();
}

// Update translations on language change
i18next.on("languageChanged", () => {
  updateTranslations();
});

// Update translations when translations are loaded
i18next.on("loaded", () => {
  updateTranslations();
});

export const blankWorkOrder = {
  id: "",
  company_id: "",
  name: "",
  description: "",
  component_id: "",
  part_number_id: null,
  status: WorkOrderStatus.Draft,
  phase: "",
  quantity: 1,
  created_by_user_id: "",
  created_at: "",
  last_edited_at: null,
  deadline: null,
  approvals: {},
  wo_number: 0,
  assignee_user_id: null,
  activity_log: [],
  attachments: [],
} as WorkOrder;
