import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { DashboardInsightContext } from "../DashboardInsightCard";
import { tailwindConfig } from "@shared/utils/helpers";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController } from "chart.js";
import { RootState } from "@shared/redux/store";
import { UniqueIdentifierWithLatestProcessEntryAndProcess } from "@shared/types/sharedComponentTypes";
import { appendLatestProcessEntry } from "@shared/connections/supabaseProcessEntries";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController);

export const ComponentWipGraph: React.FC<void> = () => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const context = useContext(DashboardInsightContext);

  const [componentInstancesWithProcessEntry, setComponentInstancesWithProcessEntry] = useState<
    UniqueIdentifierWithLatestProcessEntryAndProcess[]
  >([]);
  const [chartData, setChartData] = useState<{ processes: string[]; counts: number[] }>({ processes: [], counts: [] });

  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const processes = useSelector((state: RootState) => state.db.processes);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const selectedComponentId = context.filters.componentId;
  const selectedProcessId = context.filters.selectedProcessId;
  const startDate = context.filters.dateRange?.from;
  const endDate = context.filters.dateRange?.to;
  const setFilters = context.setFilters;
  const filters = context.filters;

  const handleSelectedProcess = (processId: string | null) => {
    setFilters({ ...filters, selectedProcessId: processId });
  };

  // Same as BuildTracker, eventually can run calculation once
  useEffect(() => {
    const fetchLatestProcessEntries = async () => {
      const componentInstancesWithProcessEntryTemp = await appendLatestProcessEntry(filteredComponentInstances);
      setComponentInstancesWithProcessEntry(componentInstancesWithProcessEntryTemp);
    };

    const filteredComponentInstances = componentInstances
      .filter((componentInstance) => componentInstance.component_id === (selectedComponentId ?? undefined))
      .filter((componentInstance) => componentInstance.status === UniqueIdentifierStatus.Wip)
      .filter((componentInstance) => {
        if (startDate && endDate) {
          const createdAt = new Date(componentInstance.created_at);
          return createdAt >= startDate && createdAt <= endDate;
        }
        return true;
      });

    fetchLatestProcessEntries();
  }, [componentInstances, selectedComponentId, startDate, endDate]);

  // Then calculate the counts for each process
  useEffect(() => {
    const selectedComponentProcesses = componentProcessLinks
      .filter((link) => link.component_id === selectedComponentId)
      .map((link) => link.process_id);

    if (componentInstancesWithProcessEntry) {
      const processCounts = processes
        .filter((process) => selectedComponentProcesses.includes(process.id))
        .map((process) => {
          const count = componentInstancesWithProcessEntry.filter(
            (instance) => instance.latest_process_entry?.process_id === process.id,
          ).length;
          return { name: process.name, count };
        });

      // Transform processCounts into the expected shape
      const chartData = {
        processes: processCounts.map((item) => item.name),
        counts: processCounts.map((item) => item.count),
      };

      setChartData(chartData);
    }
  }, [componentInstancesWithProcessEntry, componentProcessLinks, processes, selectedComponentId, selectedProcessId]);

  // Handle graph formatting
  useEffect(() => {
    const ctx = canvas.current;

    if (ctx === null) {
      return;
    }

    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: chartData.processes,
        datasets: [
          {
            data: chartData.counts,
            backgroundColor: chartData.processes.map((_, index) => {
              const process = processes.find((p) => p.name === chartData.processes[index]);
              return process && process.id === selectedProcessId
                ? (tailwindConfig().theme?.colors?.blue as any)?.[400] // Highlight color
                : (tailwindConfig().theme?.colors?.blue as any)?.[200]; // Default color
            }),
          },
        ],
      },
      options: {
        indexAxis: "y",
        layout: {
          padding: {
            top: 30,
            bottom: 60,
          },
        },
        scales: {
          x: {
            // Previously 'y'
            display: true,
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
            type: "linear",
          },
          y: {
            // Previously 'x'
            grid: {
              display: false,
            },
            display: true,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
        onHover: (event, chartElement) => {
          if (event.native) {
            const target = event.native.target as HTMLElement | null; // Type assertion
            if (target) {
              target.style.cursor = chartElement[0] ? "pointer" : "default";
            }
          }
        },
        onClick: (event, elements) => {
          if (elements.length > 0 && event.native) {
            const chartEvent = event.native;
            const firstElement = chart.getElementsAtEventForMode(chartEvent, "nearest", { intersect: true }, false)[0];
            if (firstElement) {
              const index = firstElement.index;
              const selectedProcessName = chartData.processes[index];
              const selectedProcess = processes.find((p) => p.name === selectedProcessName);

              if (selectedProcess) {
                const newProcessId = selectedProcessId === selectedProcess.id ? null : selectedProcess.id;
                handleSelectedProcess(newProcessId);
              }
            }
          }
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
        resizeDelay: 100,
      },
    });

    return () => chart.destroy();
  }, [chartData]);

  return (
    <div className="h-full px-5 py-1">
      <canvas ref={canvas}></canvas>
    </div>
  );
};

export default ComponentWipGraph;
