import React, { useRef, useEffect, useMemo, useContext, useState } from "react";
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip, Legend } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { tailwindConfig, truncateString } from "@shared/utils/helpers";
import { DatasetsContext } from "../Datasets";
import { ParametricQuantitative, TimestamptzString } from "@shared/types/databaseTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { CHART_DEBOUNCE_DELAY } from "../constants";

interface ChartingCanvasElement extends HTMLCanvasElement {
  chart?: Chart;
}

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip, Legend, zoomPlugin);

interface DatasetAnalyticsPerformanceChartProps {
  focusedIndex: number | null;
  setFocusedIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const DatasetAnalyticsPerformanceLineChart: React.FC<DatasetAnalyticsPerformanceChartProps> = ({ focusedIndex, setFocusedIndex }) => {
  const canvas = useRef<ChartingCanvasElement>(null);
  const context = useContext(DatasetsContext);
  const [chart, setChart] = useState<Chart | null>(null);

  useEffect(() => {
    if (!chart) return;
    const tooltip = chart.tooltip;
    const chartArea = chart.chartArea;
    if (focusedIndex !== null) {
      tooltip?.setActiveElements([{ datasetIndex: 0, index: focusedIndex }], {
        x: chartArea.left + chartArea.width / 2,
        y: chartArea.top + chartArea.height / 2,
      });
      chart.setActiveElements([{ datasetIndex: 0, index: focusedIndex }]);
    } else {
      tooltip?.setActiveElements([], { x: 0, y: 0 });
      chart.setActiveElements([]);
    }
    chart.update();
  }, [focusedIndex]);

  const chartData = useMemo(() => {
    // create variables for chart data
    let identifiers: string[] = [];
    let x: TimestamptzString[] = [];
    let y: number[] = [];
    let usl: (number | null)[] = [];
    let lsl: (number | null)[] = [];
    // iterate through data and push values to chart data variables
    context.data.forEach((datapoint) => {
      identifiers.push(datapoint.unique_identifier?.identifier ?? "");
      x.push(datapoint.process_entry?.timestamp ?? "");
      y.push(Number((datapoint as ParametricQuantitative).value));
      usl.push((datapoint as ParametricQuantitative).usl !== null ? Number((datapoint as ParametricQuantitative).usl) : null);
      lsl.push((datapoint as ParametricQuantitative).lsl !== null ? Number((datapoint as ParametricQuantitative).lsl) : null);
    });
    // return
    return { identifiers, x, y, usl, lsl };
  }, [context.data]);

  useEffect(() => {
    let chart: Chart | null = null;
    try {
      let debounceTimer: NodeJS.Timeout;
      debounceTimer = setTimeout(() => {
        if (canvas.current === null) return;
        chart = new Chart(canvas.current, {
          type: "line",
          data: {
            labels: chartData.x,
            datasets: [
              {
                label: context.selectedDataset?.name ?? "",
                data: chartData.y,
                borderColor: (tailwindConfig().theme?.colors?.blue as any)?.[500],
                backgroundColor: "transparent",
                pointHoverRadius: 6,
                pointHoverBorderWidth: 3,
              },
              {
                label: "LSL",
                data: chartData.lsl,
                borderColor: (tailwindConfig().theme?.colors?.red as any)?.[500],
                backgroundColor: "transparent",
                borderWidth: 1,
                borderDash: [2, 3],
                pointRadius: 0,
              },
              {
                label: "USL",
                data: chartData.usl,
                borderColor: (tailwindConfig().theme?.colors?.red as any)?.[500],
                backgroundColor: "transparent",
                borderWidth: 1,
                borderDash: [8, 3],
                pointRadius: 0,
              },
            ],
          },
          options: {
            layout: {
              padding: 20,
            },
            scales: {
              y: {
                display: true,
                beginAtZero: false,
                ticks: {
                  maxTicksLimit: 10,
                },
                type: "linear",
              },
              x: {
                grid: {
                  display: false,
                },
                type: "time",
                time: {
                  parser: "YYYY-MM-DD HH:mm:ss",
                },
                display: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: (context) => truncateString(context[0].dataset.label ?? "", 25),
                  label: (context) => chartData.identifiers?.[context.dataIndex] + ": " + context.parsed.y.toFixed(2),
                },
              },
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  boxHeight: 15,
                  boxWidth: 15,
                },
              },
              zoom: {
                zoom: {
                  drag: {
                    enabled: true,
                  },
                },
              },
            },
            interaction: {
              intersect: false,
              mode: "nearest",
            },
            maintainAspectRatio: false,
            resizeDelay: 200,
            onHover: (_event, chartElement) => {
              setFocusedIndex(chartElement?.[0]?.index ?? null);
            },
          },
        });
        setChart(chart);
      }, CHART_DEBOUNCE_DELAY);
      return () => {
        clearTimeout(debounceTimer);
        if (chart) {
          chart.destroy();
        }
      };
    } catch (error) {
      console.error("Chart initialization failed:", error);
    }
  }, [chartData]);

  return (
    <div className="relative h-full w-full">
      <button
        className="btn-xs border-serial-palette-200 absolute right-4 top-4 rounded border bg-white bg-opacity-60 p-1"
        onClick={() => chart?.resetZoom()}
      >
        <FontAwesomeIcon icon={faExpand} />
      </button>
      <canvas ref={canvas} onMouseLeave={() => setFocusedIndex(null)}></canvas>
    </div>
  );
};

export default DatasetAnalyticsPerformanceLineChart;
