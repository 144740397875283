import { faCheckCircle, faExclamationCircle, faInfoCircle, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect, useRef, useState } from "react";

interface ToastProps {
  timeout?: number;
  type?: ToastType;
  color?: string;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>> | ((toggle: boolean) => void);
  title: string | ReactElement;
  message?: string | ReactElement;
  children?: React.ReactNode;
  xAlign: "left" | "right";
  yAlign: "top" | "bottom";
  yOffset?: string;
  faIcon?: FontAwesomeIconProps["icon"];
}

export enum ToastType {
  Info = "info",
  Success = "success",
  Error = "error",
  Warning = "warning",
}

const ToastStyle = {
  [ToastType.Info]: { color: "sky", faIcon: faInfoCircle },
  [ToastType.Success]: { color: "green", faIcon: faCheckCircle },
  [ToastType.Error]: { color: "red", faIcon: faTimesCircle },
  [ToastType.Warning]: { color: "amber", faIcon: faExclamationCircle },
};

const Toast = ({ timeout, type, color, toggle, setToggle, title, message, children, xAlign, yAlign, yOffset, faIcon }: ToastProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [animate, setAnimate] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);

  if (type) {
    color = ToastStyle[type].color;
    faIcon = ToastStyle[type].faIcon;
  }

  useEffect(() => {
    if (toggle) {
      setAnimate(true);
      setReset(false);
    }
    if (timeout) {
      timeoutRef.current = setTimeout(() => {
        setAnimate(false);
        setToggle(false);
        setTimeout(() => setReset(true), 100);
      }, timeout);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [toggle]);

  const handleClose = () => {
    setToggle(false);
    setAnimate(false);
    setTimeout(() => setReset(true), 100);
  };

  return (
    <div
      style={{ position: "fixed", [xAlign]: "1.25rem", [yAlign]: yOffset || "1.25rem" }}
      className={`z-60 fixed flex w-[330px] transform flex-col rounded-lg border bg-white shadow-lg transition-transform duration-300 ease-in-out sm:w-[400px] ${toggle ? "translate-x-0" : xAlign === "right" ? "translate-x-[500px]" : "-translate-x-[500px]"}`}
    >
      <button className="absolute right-3 top-2" onClick={() => handleClose()}>
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className="flex gap-x-5">
        <div className="flex min-h-full items-center justify-between pl-5">
          <FontAwesomeIcon icon={faIcon || faInfoCircle} size="2xl" className={`text-${color}-600`} />
        </div>
        <div className="flex w-full flex-col gap-y-1 py-3 pr-3">
          <h1 className={`text-serial-palette-800 text-lg font-bold`}>{title}</h1>
          {message && <div className={`text-${color}-900`}>{message}</div>}
          {children}
        </div>
      </div>
      <div
        className={`h-1.5 bg-${color}-600 overflow-hidden rounded-b-lg rounded-r-none ${animate ? "animate-width" : ""}`}
        style={{
          transition: `width ${reset ? 0 : timeout}ms linear`,
          width: animate ? "0%" : "100%",
        }}
      />
    </div>
  );
};

export default Toast;
