import React, { useState, useEffect, useContext } from "react";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import WorkOrderStatusTag from "../../work-orders/components/WorkOrderStatusTag";
import { WorkOrder } from "@shared/types/databaseTypes";
import { formatTimeAgo } from "@shared/utils/time";
import WorkOrderProgressBar from "../../work-orders/components/WorkOrderProgressBar";
import { ProductionContext } from "../ProductionProvider";
import { WorkOrderWithCounts, WorkOrderWithCountsAndLatestTimestamp } from "../types";
import { appendLatestProcessEntry } from "@shared/connections/supabaseLatestProcessEntry";
import { useTranslation } from "react-i18next";

interface ProductionWorkOrderSummaryProps {
  workOrders: WorkOrderWithCounts[];
  handleSelectedWorkOrder: (workOrder: WorkOrder) => void;
  selectedWorkOrder: WorkOrderWithCounts | null;
  sidebarOpen: boolean;
}

const ProductionWorkOrderSummary: React.FC<ProductionWorkOrderSummaryProps> = ({
  workOrders,
  handleSelectedWorkOrder,
  selectedWorkOrder,
  sidebarOpen,
}) => {
  let { db } = useContext(ProductionContext);
  const { t } = useTranslation();
  const [workOrdersWithLatestTimestamp, setWorkOrdersWithLatestTimestamp] = useState<WorkOrderWithCountsAndLatestTimestamp[]>([]);

  const associateComponentInstances = async () => {
    let workOrdersWithComponentInstances = await Promise.all(
      workOrders.map(async (workOrder) => {
        // Filter the component instances for the current work order
        const filteredComponentInstances = db.componentInstances.filter((ci) => ci.work_order_id === workOrder.id);

        // Process the array of component instances
        const updatedComponentInstances = await appendLatestProcessEntry(filteredComponentInstances);

        // Determine the most recent timestamp from the updated component instances
        const latestTimestamp = updatedComponentInstances.reduce(
          (accumulator, ci) => {
            const timestamp = ci.latest_process_entry?.timestamp;
            const numericTimestamp = timestamp ? new Date(timestamp).getTime() : 0;

            // Check if the current numeric timestamp is greater than the accumulated one
            if (numericTimestamp > accumulator.latestNumericTimestamp) {
              return {
                latestNumericTimestamp: numericTimestamp,
                latestTimestampString: timestamp,
              };
            } else {
              return accumulator;
            }
          },
          { latestNumericTimestamp: 0, latestTimestampString: "" },
        ).latestTimestampString;

        return { ...workOrder, latestTimestamp };
      }),
    );

    return workOrdersWithComponentInstances;
  };

  useEffect(() => {
    associateComponentInstances().then(setWorkOrdersWithLatestTimestamp);
  }, [workOrders, db.componentInstances]);

  return (
    <>
      {workOrdersWithLatestTimestamp.length > 0 && (
        <div className="col-span-full flex flex-col lg:col-span-4  ">
          <div className="inline-flex w-full items-center space-x-2 pb-4 text-xl">
            <div className="text-serial-palette-800 text-2xl font-bold ">Active {t("workOrders")}</div>
            <div className="text-serial-palette-500 text-2xl">{workOrdersWithLatestTimestamp.length}</div>
          </div>
          <div className="border-serial-palette-200 overflow-hidden rounded-md border bg-white">
            <table className="border-md w-full table-auto">
              {/* Table header */}
              <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
                <tr>
                  <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold">{t("workOrder")}</div>
                  </th>
                  <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold">Component</div>
                  </th>
                  <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 lg:table-cell">
                    <div className="text-left font-semibold">Last Updated</div>
                  </th>
                  <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5 ">
                    <div className="text-center font-semibold">Quantity</div>
                  </th>
                  <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold">Progress</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-serial-palette-200 divide-y text-sm">
                {workOrdersWithLatestTimestamp.map((workOrder: WorkOrderWithCountsAndLatestTimestamp, index: number) => {
                  let component = db.components.find((component) => component.id === workOrder.component_id);
                  return (
                    <tr
                      key={index}
                      onClick={() => handleSelectedWorkOrder(workOrder)}
                      className={`hover:bg-serial-palette-100 cursor-pointer ${workOrder.id === selectedWorkOrder?.id && sidebarOpen && "bg-serial-palette-100"}`}
                    >
                      <td className="max-w-[150px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div className="inline-flex w-full items-center space-x-3 overflow-hidden">
                          <div className="">
                            <WorkOrderStatusTag status={workOrder.status} />
                          </div>
                          <div className="truncate text-sm">{workOrder.name}</div>
                        </div>
                      </td>
                      <td className="max-w-[120px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div className="flex items-center space-x-3">
                          <ComponentAvatar component={component} size="xs" tooltip={true} />
                          <div className="truncate text-sm">{component?.name}</div>
                        </div>
                      </td>
                      <td className="w-[130px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        {workOrder?.latestTimestamp
                          ? formatTimeAgo(workOrder.latestTimestamp)
                          : workOrder?.last_edited_at
                            ? formatTimeAgo(workOrder.last_edited_at)
                            : "N/A"}
                      </td>
                      <td className="max-w-[100px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div className="truncat text-center text-sm">{workOrder.quantity}</div>
                      </td>
                      <td className="min-w-[150px] whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                        <div className="truncate text-sm">
                          <WorkOrderProgressBar counts_by_status={workOrder.counts_by_status} quantity={workOrder.quantity} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductionWorkOrderSummary;
