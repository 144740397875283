import { ToastType } from "@shared/components/Toast";
import TypeTag from "@shared/components/TypeTag";
import UserAvatar from "@shared/components/UserAvatar";
import { ToastContext } from "@shared/context/ToastProvider";
import { RootState } from "@shared/redux/store";
import { Dataset, PartNumber, User } from "@shared/types/databaseTypes";
import { useState, useEffect, useMemo, useCallback, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import ContentEditable from "../components/ContentEditable";
import { ParsedResponse } from "@shared/types/apiTypes";

interface Coordinates {
  x: number;
  y: number;
}

export enum MentionType {
  User = "USER",
  Dataset = "DATASET",
}

export interface BaseMentionOption {
  type: MentionType;
  id: string;
  name: string;
}

export interface UserMentionOption extends BaseMentionOption {
  type: MentionType.User;
  data: User;
}

export interface DatasetMentionOption extends BaseMentionOption {
  type: MentionType.Dataset;
  data: Dataset;
}

export type MentionOption = UserMentionOption | DatasetMentionOption;

const setCursorToEnd = (element: HTMLElement) => {
  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  selection?.removeAllRanges();
  selection?.addRange(range);
};

interface UseMentionsProps {
  encodedContent: string;
  partNumber?: PartNumber;
  datasetDataById?: Record<string, string | number>;
  types?: MentionType[];
  onInput?: (encodedContent: string, mentions: BaseMentionOption[]) => void;
  options?: MentionOption[];
  className?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  maxMentions?: number;
  copilot?: boolean;
  handleAcceptCopilotSuggestion?: (newContent: string) => void;
  getCopilotSuggestion?: (newContent: string) => Promise<ParsedResponse>;
}

export const useMentions = ({
  encodedContent,
  partNumber,
  datasetDataById,
  types,
  onInput,
  onKeyDown,
  options,
  className,
  maxMentions,
  copilot,
  handleAcceptCopilotSuggestion,
  getCopilotSuggestion,
}: UseMentionsProps) => {
  const [mentionInput, setMentionInput] = useState<string>("");
  const [showMentionList, setShowMentionList] = useState<boolean>(false);
  const [caretPosition, setCaretPosition] = useState<Coordinates>({ x: 0, y: 0 });
  const [focusedMentionIndex, setFocusedMentionIndex] = useState<number>(0);
  const [activeMentions, setActiveMentions] = useState<BaseMentionOption[]>([]);

  const { triggerToast } = useContext(ToastContext);

  const contentRef = useRef<HTMLDivElement>(null);

  const users = useSelector((state: RootState) => state.db.users);
  const datasets = useSelector((state: RootState) => state.db.datasets).filter(
    (dataset) => dataset.data_type !== "UID" && dataset.data_type !== "LINK",
  ); // TODO: remove this filter when UID is removed from datasets

  const userMentionOptions: MentionOption[] = useMemo(() => {
    return users
      .filter((user) => user.is_active)
      .map((user) => ({
        type: MentionType.User,
        id: user.supabase_uid,
        name: user.first_name + " " + user.last_name,
        data: user,
      }));
  }, [users]);

  const datasetMentionOptions: MentionOption[] = useMemo(() => {
    return datasets.map((dataset) => ({
      type: MentionType.Dataset,
      id: dataset.id,
      name: dataset.name,
      data: dataset,
    }));
  }, [datasets]);

  // update mention options when mentionInput change
  const filteredMentionOptions = useMemo(() => {
    if (!types) return [];
    let mentionOptions: MentionOption[] = options ?? [];
    if (!options) {
      // default options if none are provided
      if (types.includes(MentionType.User)) {
        mentionOptions = [...mentionOptions, ...userMentionOptions];
      }
      if (types.includes(MentionType.Dataset)) {
        mentionOptions = [...mentionOptions, ...datasetMentionOptions];
      }
    }
    return mentionOptions
      .filter((mentionOption) => {
        // compare lower case strings with spaces removed
        const input = mentionInput.slice(1).toLowerCase().replace(" ", "");
        const name = mentionOption.name.toLowerCase().replace(" ", "");
        return name.includes(input);
      })
      .slice(0, 5);
  }, [mentionInput, types, userMentionOptions, datasetMentionOptions]);

  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (!selection?.rangeCount) return;

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    return { x: rect.left, y: rect.top };
  };

  const selectMention = useCallback(
    (mention: MentionOption) => {
      // replace the mentionInput in the encodedContent with the mention
      const encodedContentWithoutMentionInput = encodedContent.slice(0, -mentionInput.length);
      const encodedContentWithMention = encodedContentWithoutMentionInput + `@{${mention.type}:${mention.id}}`;
      if (onInput) onInput(encodedContentWithMention, activeMentions);
      // close the mention list
      setShowMentionList(false);
    },
    [encodedContent, mentionInput, onInput, activeMentions],
  );

  const encodeContent = (htmlContent: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const mentions = doc.querySelectorAll(".mention");

    mentions.forEach((mention) => {
      const mentionId = mention.getAttribute("mention-id");
      const mentionType = mention.getAttribute("mention-type");
      if (mentionId) {
        const textNode = document.createTextNode(`@{${mentionType}:${mentionId}}`);
        mention.parentNode?.replaceChild(textNode, mention);
      }
    });
    return doc.body.innerText;
  };

  const decodeContent = useCallback(
    (encodedContent: string): string => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(encodedContent, "text/html");
      let text = doc.body.innerText;
      // This regular expression matches mentions formatted as "@{TYPE:UUID}".
      // "TYPE" consists of any combination of letters and underscores,
      // and "UUID" can be any combination of word characters and hyphens.
      // and any trailing whitespace
      const mentions = text.match(/@\{([a-zA-Z_]+:[\w-]+)\}\s*/g) || [];
      const newActiveMentions: BaseMentionOption[] = [];

      mentions.forEach((mention) => {
        const [mentionType, mentionId] = mention.trim().slice(2, -1).split(":");
        let mentionName = "";
        switch (mentionType) {
          case MentionType.User:
            const user = users.find((user) => user.supabase_uid === mentionId);
            if (user) {
              mentionName = user.first_name + " " + user.last_name;
            }
            break;
          case MentionType.Dataset:
            const dataset = datasets.find((dataset) => dataset.id === mentionId);
            if (dataset) {
              mentionName = dataset.name;
            }
            break;
        }
        newActiveMentions.push({
          type: mentionType as MentionType,
          id: mentionId,
          name: mentionName,
        });

        // For datasets, if a PN is available to preview the value to replace the mention with, show that instead of the mention name
        let mentionText = null;
        if (mentionType === MentionType.Dataset) {
          const partNumberData = partNumber?.metadata[mentionId];
          mentionText = partNumberData ? partNumberData.value : null;
          // After looking for a part number, we look at the dataset data
        }

        const mentionEl = document.createElement("span");
        mentionEl.className = "mention border px-1 py-0.5 rounded-md border-sky-200 bg-sky-100 text-serial-palette-600";
        mentionEl.setAttribute("mention-id", mentionId);
        mentionEl.setAttribute("mention-type", mentionType);
        mentionEl.textContent = mentionText ?? `@${mentionName}`;
        // append non breaking space after mention to prevent the cursor from being placed inside the mention
        text = text.replace(mention, mentionEl.outerHTML + "\u00A0");
      });

      setActiveMentions(newActiveMentions);
      onInput?.(encodedContent, newActiveMentions);
      return text;
    },
    [users, datasets, encodedContent, onInput, partNumber],
  );

  const handleInput = useCallback(() => {
    const text = contentRef?.current?.innerText || "";
    const lastWord =
      text
        .replace(/\u00A0/g, " ")
        .split(" ")
        .pop() || "";
    if (lastWord.startsWith("@")) {
      if (maxMentions && activeMentions.length >= maxMentions) {
        triggerToast(
          ToastType.Warning,
          "Too many mentions",
          `You can only mention a maximum of ${maxMentions} datasets in a text block`,
          5000,
        );
        return;
      }
      const position = getCaretPosition();
      if (position) {
        setCaretPosition(position);
      }
      setMentionInput(lastWord); // remove the @ from the last word
      setShowMentionList(true);
    } else {
      setShowMentionList(false);
    }

    // sync the encoded content using the onInput callback
    const newEncodedContent = encodeContent(contentRef?.current?.innerHTML || "");
    if (onInput) onInput(newEncodedContent, activeMentions);
  }, [contentRef, onInput, activeMentions]);

  // update the contents of the div if the encodedContent changes
  useEffect(() => {
    if (contentRef?.current) {
      if (contentRef.current.innerText === decodeContent(encodedContent)) return;
      contentRef.current.innerHTML = decodeContent(encodedContent);
      setCursorToEnd(contentRef.current);
    }
  }, [encodedContent, partNumber]);

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.addEventListener("input", handleInput);
    }
    return () => {
      if (contentRef?.current) {
        contentRef.current.removeEventListener("input", handleInput);
      }
    };
  }, [contentRef, handleInput]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyDown?.(e);
    // if backspace is pressed and the last word is a mention, remove the mention
    if (
      e.key === "Backspace" &&
      encodedContent
        .trim()
        .replace(/\u00A0/g, " ")
        .split(" ")
        .pop()
        ?.startsWith("@")
    ) {
      e.preventDefault();
      const encodedContentElements = encodedContent
        .trim()
        .replace(/\u00A0/g, " ")
        .split(" ");
      const encodedContentWithoutMention = encodedContentElements.slice(0, -1).join(" ");
      if (onInput) onInput(encodedContentWithoutMention, activeMentions);
      setShowMentionList(false);
      return;
    }
    if (showMentionList) {
      // up arrow
      if (e.key === "ArrowUp") {
        // If the up arrow was pressed, set the focused option to the previous option or the last option if the first option was focused
        const nextOptionIndex = (focusedMentionIndex - 1 + filteredMentionOptions.length) % filteredMentionOptions.length;
        setFocusedMentionIndex(nextOptionIndex);
        return;
      }
      if (e.key === "ArrowDown") {
        // If the down arrow was pressed, set the focused option to the next option or the first option if the last option was focused
        const nextOptionIndex = (focusedMentionIndex + 1) % filteredMentionOptions.length;
        setFocusedMentionIndex(nextOptionIndex);
        return;
      }
      if (e.key === "Enter") {
        // If the enter key was pressed, select the focused option
        e.preventDefault();
        selectMention(filteredMentionOptions[focusedMentionIndex]);
        return;
      }
    }
  };

  // split the encodedContent into an array of objects with { text: string, isMention: boolean }
  // if isMention is true, the text should lookup the mention data from the database
  const decodedContentElements: { text: string; isMention: boolean }[] = useMemo(() => {
    const elements: { text: string; isMention: boolean }[] = [];
    const splitContent = encodedContent.split(/(@\{[a-zA-Z_]+:[\w-]+\})/g);

    for (const content of splitContent) {
      if (content.startsWith("@")) {
        const mentionMatch = content.match(/^@\{([a-zA-Z_]+):([\w-]+)\}/);
        if (mentionMatch) {
          const mentionType = mentionMatch[1];
          const mentionId = mentionMatch[2];
          let mentionText = "";

          if (mentionType === MentionType.User) {
            const user = users.find((u) => u.supabase_uid === mentionId);
            mentionText = user ? user.first_name + " " + user.last_name : "Unknown User";
          } else if (mentionType === MentionType.Dataset) {
            const partNumberData = partNumber?.metadata[mentionId];
            let datasetData = datasetDataById?.[mentionId];
            if (typeof datasetData === "number") datasetData = datasetData.toString();
            mentionText = partNumberData ? partNumberData.value : datasetData ?? "No data available";
          }

          elements.push({ text: mentionText, isMention: true });
        } else {
          elements.push({ text: content, isMention: false });
        }
      } else {
        elements.push({ text: content, isMention: false });
      }
    }

    return elements;
  }, [encodedContent, users, partNumber, datasetDataById]);

  const MentionList = showMentionList ? (
    <div
      className="z-20 flex flex-col rounded-md border bg-white py-1 shadow-sm"
      style={{ position: "fixed", left: `${caretPosition.x - 18}px`, top: `${caretPosition.y + 24}px` }}
    >
      {filteredMentionOptions.map((mentionOption, index) => (
        <button
          key={index}
          id={`mention-option-${index}`}
          onMouseEnter={() => setFocusedMentionIndex(index)}
          className={`flex cursor-pointer items-center gap-x-2 px-3 py-0.5 text-sm ${index === focusedMentionIndex && "bg-serial-palette-100"}`}
          onClick={() => selectMention(mentionOption)}
        >
          {mentionOption.type === MentionType.Dataset && (
            <TypeTag type={mentionOption.data.data_type} hideText className="h-4 w-7 text-[9px]" />
          )}
          {mentionOption.type === MentionType.User && <UserAvatar user={mentionOption.data} size="xs" />}
          {mentionOption.name}
        </button>
      ))}
      {filteredMentionOptions.length === 0 && <div className="text-serial-palette-400 px-3 italic">No results found</div>}
    </div>
  ) : null;

  const DecodedContent = (
    <div>
      {decodedContentElements.map((element, index) => {
        return (
          <span
            key={index}
            className={
              element.isMention
                ? "mention text-serial-palette-600 rounded-md border border-sky-200 bg-sky-100 px-1 py-0.5 font-semibold"
                : ""
            }
          >
            {element.text}
          </span>
        );
      })}
    </div>
  );

  const EditableContent = (
    <ContentEditable
      contentRef={contentRef}
      className={className}
      onKeyDown={handleKeyDown}
      onFocus={() => {
        if (contentRef.current) setCursorToEnd(contentRef.current);
      }}
      placeholder="Text"
      copilot={copilot}
      handleAcceptCopilotSuggestion={handleAcceptCopilotSuggestion}
      getCopilotSuggestion={getCopilotSuggestion}
    />
  );

  return { MentionList, DecodedContent, EditableContent, contentRef };
};
