import { Field, FieldType } from "@shared/types/databaseTypes";
import { ProcessBuilderDraggableType } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useContext, useMemo, useRef } from "react";
import type { Identifier } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { FieldGroup } from "../hooks/useFieldGroups";
import ProcessBuilderField from "./ProcessBuilderField";
import { v4 as uuidv4 } from "uuid";
import TypeTag from "@shared/components/TypeTag";
import { ProcessBuilderAddFieldDropdown } from "./ProcessBuilderAddDropdown";
import { getYHemisphere } from "@shared/utils/window";
import Banner2 from "@shared/components/Banner2";
import ContentEditable from "../../../shared/components/ContentEditable";

interface ProcessBuilderFieldGroupProps {
  allFields: Field[];
  fieldGroup?: FieldGroup;
  isPlaceholder?: boolean;
  stepIndex: number;
  fieldGroupIndex: number;
}

const ProcessBuilderFieldGroup: React.FC<ProcessBuilderFieldGroupProps> = ({
  allFields,
  fieldGroup,
  isPlaceholder,
  stepIndex,
  fieldGroupIndex,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    readOnly,
    handleAddNewField,
    hoveredFieldGroupIndex,
    setHoveredFieldGroupIndex,
    handleMoveFields,
    handleUpdateFields,
    datasetsValidation,
    fieldsValidation,
  } = useContext(ProcessBuilderContext);
  const fieldGroupId = useMemo(() => fieldGroup?.fields?.[0].id ?? uuidv4(), [fieldGroup]);
  const lastFieldIndexInGroup = useMemo(() => {
    if (!fieldGroup?.fields?.length) return 0;
    return allFields.findIndex((field) => field.id === fieldGroup?.fields?.[fieldGroup?.fields?.length - 1].id);
  }, [allFields, fieldGroup]);

  const groupNameRef = useRef<HTMLDivElement>(null);

  const errorMessage = useMemo(() => {
    const groupFieldIds = fieldGroup?.fields?.map((f) => f.id) ?? [];
    const groupDatasetIds = fieldGroup?.fields?.map((f) => f.dataset_id) ?? [];
    const applicableErrorMessages: string[] = [];
    [...groupFieldIds, ...groupDatasetIds].forEach((id) => {
      const validation = datasetsValidation?.[id] ?? fieldsValidation?.[id];
      if (validation?.isValid === false && validation?.message) {
        applicableErrorMessages.push(validation.message);
      }
    });
    return applicableErrorMessages.join(", ");
  }, [datasetsValidation, fieldsValidation, fieldGroup?.fields]);

  const handleUpdateGroupName = () => {
    if (!fieldGroup) return;
    handleUpdateFields(
      stepIndex,
      fieldGroup.fields.map((f) => f.id),
      "group_name",
      groupNameRef.current?.innerText,
    );
    if ([FieldType.Image, FieldType.File, FieldType.Signature, FieldType.Label].includes(fieldGroup?.type)) {
      // if the field group is an image or file, also update the prompt
      handleUpdateFields(
        stepIndex,
        fieldGroup.fields.map((f) => f.id),
        "prompt",
        groupNameRef.current?.innerText,
      );
    }
  };

  const handleAddNewFieldToGroup = () => {
    if (!fieldGroup) return;
    const lastField = fieldGroup.fields?.[fieldGroup?.fields?.length - 1];
    const indexOfLastField = allFields.findIndex((field) => field.id === lastField?.id);
    if (indexOfLastField === -1) return;
    handleAddNewField(stepIndex, indexOfLastField, fieldGroup.type, fieldGroup.name);
  };

  const [{ handlerId }, drop] = useDrop<FieldGroup, void, { handlerId: Identifier | null }>({
    accept: ProcessBuilderDraggableType.FieldGroup,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: FieldGroup, monitor) {
      const clientOffset = monitor.getClientOffset();
      if (!ref.current || !clientOffset) return;
      const hemisphere = getYHemisphere(ref, clientOffset);
      // get the last field in this group such that we can find the index of the first field in the next group
      const lastField = fieldGroup?.fields?.[fieldGroup?.fields?.length - 1];
      const prevGroupIndex = allFields.findIndex((field) => field.id === fieldGroupId) - 1;
      const nextGroupIndex = Math.min(
        allFields.findIndex((field) => field.id === lastField?.id),
        allFields.length - 1,
      );
      handleMoveFields(
        item.fields.map((field) => field.id),
        stepIndex,
        hemisphere === "top" ? prevGroupIndex : nextGroupIndex,
      );
    },
    hover(_item: FieldGroup, monitor) {
      const clientOffset = monitor.getClientOffset();
      if (!ref.current || !clientOffset) return;
      const hemisphere = getYHemisphere(ref, clientOffset);
      setHoveredFieldGroupIndex(hemisphere === "top" ? fieldGroupIndex : fieldGroupIndex + 1);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ProcessBuilderDraggableType.FieldGroup,
    item: () => {
      if (isPlaceholder || !fieldGroup)
        return {
          fields: [],
        };
      return { ...fieldGroup };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setHoveredFieldGroupIndex(null);
    },
  });

  drop(ref);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={`group flex w-full items-start ${isPlaceholder && "min-h-[40px]"} ${isDragging && "opacity-40"} relative`}
      onDragLeave={() => setHoveredFieldGroupIndex(null)}
    >
      {!readOnly && (
        <div className="absolute top-1 flex gap-x-1.5">
          <ProcessBuilderAddFieldDropdown stepIndex={stepIndex} tileIndex={lastFieldIndexInGroup} />
          {!isPlaceholder && (
            <button tabIndex={-1} ref={drag}>
              <FontAwesomeIcon
                className="text-serial-palette-400 hover:text-serial-palette-600 hidden group-hover:flex"
                icon={faGripVertical}
              />
            </button>
          )}
        </div>
      )}
      {!isPlaceholder && fieldGroup && (
        <div ref={preview} className="ml-10 mr-6 flex min-w-0 flex-grow flex-col gap-y-2 rounded border bg-white px-2 py-3">
          {errorMessage && (
            <Banner2 className="mx-1.5 mb-1" type="error" open={true} hideX>
              {errorMessage}
            </Banner2>
          )}
          <div className="mx-1.5 flex items-center gap-x-1.5">
            <TypeTag type={fieldGroup?.type} hideText className="h-5 w-8 text-[9px]" />
            <ContentEditable
              contentRef={groupNameRef}
              onInput={() => handleUpdateGroupName()}
              className="input-box text-serial-palette-600 w-full text-sm font-medium outline-0"
              placeholder="Group Name..."
              content={fieldGroup?.name}
              readOnly={readOnly}
            />
            {![FieldType.Image, FieldType.File, FieldType.Signature, FieldType.Label].includes(fieldGroup.type) && !readOnly && (
              <button
                tabIndex={-1}
                className="btn btn-xs serial-btn-dark m-0 mr-0.5 h-[18px] gap-x-1 whitespace-nowrap rounded-md text-xs"
                onClick={() => handleAddNewFieldToGroup()}
              >
                <FontAwesomeIcon icon={faPlus} size="xs" />
                Field
              </button>
            )}
          </div>
          {fieldGroup?.fields.map((field, index) => {
            return (
              <ProcessBuilderField
                key={index}
                allFields={allFields}
                field={field}
                stepIndex={stepIndex}
                allowDrag={![FieldType.Image, FieldType.File, FieldType.Signature, FieldType.Label].includes(field.type)}
              />
            );
          })}
        </div>
      )}
      {(isPlaceholder || fieldGroup?.fields.length === 0) && (
        <ProcessBuilderField allFields={allFields} stepIndex={stepIndex} isPlaceholder={true} />
      )}
      {(isPlaceholder || fieldGroup?.fields.length === 0) && (
        <div className="text-serial-palette-300 absolute flex w-full justify-center text-sm font-light italic">
          No data collection
          <br />
          Click below to add
        </div>
      )}
      <div
        className={`border-serial-palette-200 absolute -top-1.5 w-full border-b-4 transition-opacity ${fieldGroupIndex === hoveredFieldGroupIndex ? "opacity-100" : "opacity-0"}`}
      />
      <div
        className={`border-serial-palette-200 absolute -bottom-1.5 w-full border-b-4 transition-opacity ${fieldGroupIndex + 1 === hoveredFieldGroupIndex ? "opacity-100" : "opacity-0"}`}
      />
    </div>
  );
};

export default ProcessBuilderFieldGroup;
