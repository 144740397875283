import { FileInfo } from "@shared/components/FileInputBox";
import { Field, WorkOrder, Process, Component } from "@shared/types/databaseTypes";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";

export interface ProductionFormGenericFieldProps {
  field: Field;
  draftData: DraftSubmissionDatapoint | null;
}

export interface ProductionAppBackup {
  identifiers: string[];
  startTime: string;
  data: DraftSubmissionData;
  processId: string;
}

// --- Draft submission data and validation ---

export type DraftSubmissionData = {
  [fieldId: string]: DraftSubmissionDatapoint;
};

export interface DraftSubmissionDatapoint extends FieldValidationResult {
  value?: string | number | boolean;
  fileId?: string;
  fileInfo?: FileInfo;
  linkedIdentifier?: string;
}

export type DraftSubmissionValidationData = Record<string, FieldValidationResult>;

export interface FieldValidationResult {
  isValid?: boolean;
  message?: string;
  showInvalidAsWarning?: boolean;
}

// --- Identifiers and validation ---

export interface IdentifierValidationDatapoint {
  identifier?: string;
  isValid?: boolean;
  message?: string;
  result?: IdentifierValidationResultType;
}

export enum IdentifierValidationResultType {
  NeedInitialization = "NEED_INITIALIZATION",
  DifferentComponent = "DIFFERENT_COMPONENT",
  InvalidStation = "INVALID_STATION",
  MissingPriorProcess = "MISSING_PRIOR_PROCESS",
  DefectiveOnRetestProcess = "DEFECTIVE_ON_RETEST_PROCESS",
  DefectiveOnNewProcess = "DEFECTIVE_ON_NEW_PROCESS",
  UploadErrorWarning = "UPLOAD_ERROR_WARNING",
  ResubmissionWarning = "RESUBMISSION_WARNING",
  Ok = "OK",
}

export type BatchValidationData = {
  [validationResult in IdentifierValidationResultType]?: IdentifierValidationDatapoint[];
};

export interface FilteredComponent {
  component: Component;
  firstProductionProcessWithLowestOrder: Process;
}

export interface ProductionWorkInstructionBlockContentProps<T> {
  block: T;
}

export type WorkOrderWithCountsAndLatestTimestamp = WorkOrder & {
  counts_by_status: CountsByStatus;
  latestTimestamp: string;
};

export type WorkOrderWithCounts = WorkOrder & {
  counts_by_status: CountsByStatus;
};

export interface CountsByStatus {
  [UniqueIdentifierStatus.Wip]: number;
  [UniqueIdentifierStatus.Complete]: number;
  [UniqueIdentifierStatus.Defective]: number;
  [UniqueIdentifierStatus.Planned]: number;
}

export enum InstantiationModalMode {
  Create = "CREATE",
  Update = "UPDATE",
}
