import React, { useEffect, useState, MouseEvent, useRef } from "react";
import SidebarTransition from "@shared/components/SidebarTransition";
import PartNumberGrid, { RowState } from "./PartNumberGrid";
import PartNumberGridTopBar from "./PartNumberGridTopBar";
import PartNumberSidebarCsvUpload from "./PartNumberSidebarCsvUpload";
import { Component, Dataset, PartNumber } from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import Banner2 from "@shared/components/Banner2";
import { ErrorBanner } from "@shared/components/error/types";
import { fetchPartNumberDatasets, upsertPartNumbers } from "@shared/connections/supabasePartNumbers";
import { Loader } from "@shared/components/Loader";
import { validateGridDatasets, validatePartNumber } from "@shared/connections/supabasePartNumberValidation";
import { v4 as uuidv4 } from "uuid";
import { GridApi } from "ag-grid-community";
import useObserve from "@shared/hooks/useObserve";
import { fetchLargeTable } from "@shared/connections/supabaseGeneral";
import { createOrUpdateComponentInstance } from "@shared/connections/api/componentInstances";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import ProgressBar from "@shared/components/Progressbar";
import { getComponentPartNumbers } from "./connections/supabase";
import { UniqueIdentifier } from "@shared/types/databaseTypes";

interface Progress {
  numPass: number;
  numFail: number;
  numTotal: number;
  isLoading: boolean;
  message: string;
}

enum SidebarStates {
  CsvUpload = "CSV_UPLOAD",
  ColumnOptions = "COLUMN_OPTIONS",
}

interface PartNumberModalContentsProps {
  selectedComponent: Component;
  setModalOpen: (modalOpen: boolean) => void;
  setUnsavedChanges?: (unsavedChanges: boolean) => void;
}

export interface PartNumberWithState extends PartNumber {
  state?: RowState;
}

const PartNumbers: React.FC<PartNumberModalContentsProps> = ({ setModalOpen, selectedComponent, setUnsavedChanges }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [sidebarState, setSidebarState] = useState<SidebarStates>(SidebarStates.CsvUpload);
  const [gridPartNumbers, setGridPartNumbers] = useState<PartNumberWithState[]>([]);
  const [gridDatasets, setGridDatasets] = useState<Dataset[]>([]);
  const [filteredPartNumberIds, setFilteredPartNumberIds] = useState<string[]>([]);
  const [error, setError] = useState<ErrorBanner>({ hide: true, isValid: true, message: "", type: "info" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<Progress>({ numPass: 0, numFail: 0, numTotal: 0, isLoading: false, message: "" });
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const operators = useSelector((state: RootState) => state.db.operators);
  const userId = useSelector((state: RootState) => state.auth.supabase_uid);
  const observe = useObserve();

  const gridRef = useRef<HTMLDivElement>(null);

  const loadGridDatasets = async (pnDatasetIds?: string[]) => {
    if (!selectedComponent) return;
    const newGridDatasets = await fetchPartNumberDatasets(pnDatasetIds ?? selectedComponent.pn_metadata_dataset_ids);
    setGridDatasets(newGridDatasets);
  };

  const loadGridPartNumbers = async () => {
    const { data: partNumbers, error: partNumbersError } = await getComponentPartNumbers(selectedComponent.id);
    if (!partNumbers || partNumbersError) {
      console.error(partNumbersError);
      return;
    }
    setGridPartNumbers(partNumbers);
  };

  const loadAllData = async (pnDatasetIds?: string[]) => {
    await loadGridPartNumbers();
    await loadGridDatasets(pnDatasetIds);
    // Hack way to make sure loading the data doesn't trigger an unsaved changes flag
    setTimeout(() => setUnsavedChanges && setUnsavedChanges(false), 0);
  };

  useEffect(() => {
    setUnsavedChanges && setUnsavedChanges(true);
  }, [gridPartNumbers, gridDatasets]);

  useEffect(() => {
    loadAllData();
  }, []);

  useEffect(() => {
    // if gridPartNumbers contain any edited part number, show a warning
    const editedPartNumbers = gridPartNumbers.filter((partNumber) => partNumber.state === RowState.Edited);
    if (editedPartNumbers.length > 0) {
      setError({
        ...error,
        hide: false,
        isValid: false,
        message:
          "You are editing data on the active part numbers highlighted below. Data will NOT be retroactively updated unless the retroactive update button is pressed below.",
        type: "warning",
      });
    }
  }, [gridPartNumbers]);

  // automatically close the error banner after 5 seconds if the banner type is not 'warning'
  useEffect(() => {
    if (error.hide || error.type === "warning") return;
    const timeout = setTimeout(() => {
      setError({ ...error, hide: true });
    }, 5000);
    return () => clearTimeout(timeout);
  }, [error]);

  // if click is captured outside of the grid, run gridApi.stopEditing();
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!gridRef.current?.contains(e.target as Node)) {
        gridApi?.stopEditing();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [gridRef, gridApi]);

  const handleShowCsvUpload = () => {
    setSidebarState(SidebarStates.CsvUpload);
    setSidebarOpen(true);
  };

  const handleClose = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    loadGridDatasets();
    setError({ hide: true, isValid: true, message: "", type: "info" });
    setIsLoading(false);
    setModalOpen(false);
  };

  const handleSave = async () => {
    if (!selectedComponent || !gridApi) return;

    gridApi.stopEditing();

    // use setTimeout to move the rest of the function to the end of the event loop

    setTimeout(async () => {
      setIsLoading(true);

      observe.track("part-number_edit");

      // gridApi.stopEditing() will trigger an update to the gridPartNumbers state if a cell was being edited when the save button was clicked
      // That's why we use React functional updates here to make sure we're using the latest state (ie. the setGridPartNumbers((currentGridPartNumbers) => {...}) notation)
      // In this case, currentGridPartNumbers will include updates triggered by the gridApi.stopEditing() call
      setGridPartNumbers((currentGridPartNumbers) => {
        // Immediately start an async function so you can use await inside
        (async () => {
          // filter out part numbers that haven't been edited or added
          const partNumbersToUpdate = currentGridPartNumbers.filter(
            (partNumber) => partNumber.state === RowState.Edited || partNumber.state === RowState.New,
          );

          // validate data before saving
          for (const partNumber of partNumbersToUpdate) {
            const partNumberValidation: ErrorBanner = await validatePartNumber(partNumber);
            if (!partNumberValidation.isValid) {
              setError(partNumberValidation);
              setIsLoading(false);
              return currentGridPartNumbers;
            }
          }

          const gridDatasetsValidation = validateGridDatasets(gridDatasets);
          if (!gridDatasetsValidation.isValid) {
            setError({ ...error, hide: false, isValid: false, message: gridDatasetsValidation.message, type: "error" });
            setIsLoading(false);
            return currentGridPartNumbers;
          }

          // save part numbers and datasets
          const success = await upsertPartNumbers(partNumbersToUpdate, gridDatasets, selectedComponent);
          if (!success) {
            setError({
              ...error,
              hide: false,
              isValid: false,
              message: "Error saving part numbers, please try again or contact Serial support if issue persists.",
              type: "error",
            });
            setIsLoading(false);
            return currentGridPartNumbers;
          }

          // close modal
          setIsLoading(false);
          loadAllData(gridDatasets.map((dataset) => dataset.id));
          setError({ ...error, hide: false, isValid: true, message: "Successfully saved part numbers", type: "success" });
        })();
        return currentGridPartNumbers;
      });
    }, 0);
  };

  const handleRetroactiveUpdate = async () => {
    // Setup
    if (!selectedComponent?.id) {
      console.error("No component found");
      return;
    }
    const confirmation = window.confirm(
      `This will update all instances of the ${selectedComponent.name} component with the latest part number information. All prior data will be overwritten. Are you sure you want to continue?`,
    );
    if (!confirmation) return;
    const operatorId = operators.find((operator) => operator.user_id === userId)?.id ?? null;
    let numPass = 0;
    let numFail = 0;
    setProgress({ numPass: 0, numFail: 0, numTotal: 0, isLoading: true, message: "Updating units..." });

    // Save any pending part number changes and get all unique identifiers to update
    await handleSave();
    const uniqueIdentifiers = await fetchLargeTable<UniqueIdentifier>(
      "unique_identifiers",
      "id",
      "id, identifier, part_number_id, work_order_id",
      [{ modifier: "eq", key: "component_id", value: selectedComponent.id }],
    );
    setProgress((prevProgress) => ({ ...prevProgress, numTotal: uniqueIdentifiers.length }));

    // Update each unique identifier
    for (const uniqueIdentifier of uniqueIdentifiers) {
      const { error } = await createOrUpdateComponentInstance(
        selectedComponent.id,
        uniqueIdentifier.identifier,
        operatorId,
        null,
        uniqueIdentifier.part_number_id,
        uniqueIdentifier.work_order_id,
      );
      if (error) {
        console.error(error);
        numFail++;
        setProgress((prevProgress) => ({
          ...prevProgress,
          numFail: numFail,
          message: `Failed to update unit ${uniqueIdentifier.identifier}`,
        }));
      } else {
        numPass++;
        setProgress((prevProgress) => ({
          ...prevProgress,
          numPass: numPass,
          message: `Updated unit ${uniqueIdentifier.identifier} (${numPass} of ${uniqueIdentifiers.length})`,
        }));
      }
    }

    // Display results
    if (numFail > 0) {
      setError({
        ...error,
        hide: false,
        isValid: false,
        message: "Error updating units, please try again or contact Serial support if issue persists.",
        type: "error",
      });
    } else {
      setError({ ...error, hide: false, isValid: true, message: `Successfully updated ${numPass} units`, type: "success" });
    }
    setProgress((prevProgress) => ({ ...prevProgress, isLoading: false }));
  };

  // Add a new column to the grid
  const addNewColumn = (type: DataType, name?: string): string => {
    const datasetId = uuidv4();
    const newColumn: Dataset = {
      id: datasetId,
      company_id: "", // to be set on save event
      name: name ?? "",
      order: 0,
      data_type: type,
      process_id: "", // to be set on save event
      process_revision: 0, // to be set on save event
      child_component_id: null,
      is_active: true,
      created_at: "",
      lsl: null,
      usl: null,
      expected_value: null,
      unit: null,
      prior_values: null,
    };
    setGridDatasets((prevGridDatasets) => [...prevGridDatasets, newColumn]);
    observe.track("part-number_add-column", { dataType: type });
    return datasetId;
  };

  return (
    <div className="flex h-full w-full flex-col">
      {!error.hide && (
        <div className="px-3 pt-3">
          <Banner2
            id="part-number-error"
            clickXHandler={() => {}}
            hideX={false}
            className="w-full"
            type={error.type ?? "warning"}
            open={!error.hide}
            setOpen={() => setError({ ...error, hide: true })}
          >
            {error.message}
          </Banner2>
        </div>
      )}

      {/* Body */}
      {progress.isLoading && (
        <div className="flex h-full w-full flex-col items-center justify-center gap-5 overflow-hidden">
          <div>{progress.message}</div>
          <div className="w-1/2">
            <ProgressBar
              passPercent={(100 * progress.numPass) / progress.numTotal}
              failPercent={(100 * progress.numFail) / progress.numTotal}
            />
          </div>
        </div>
      )}
      {isLoading && !progress.isLoading && (
        <div className="flex h-full w-full items-center justify-center overflow-hidden">
          <Loader />
        </div>
      )}
      {!isLoading && !progress.isLoading && (
        <div className="flex h-full w-full overflow-hidden">
          {/* Main Content */}
          <div className="flex h-full w-full flex-col gap-4 p-4">
            {/* Top Filter / Button Bar */}
            <PartNumberGridTopBar
              addNewColumn={addNewColumn}
              handleShowCsvUpload={handleShowCsvUpload}
              partNumbers={gridPartNumbers}
              setFilteredPartNumberIds={setFilteredPartNumberIds}
            />

            {/* Grid */}
            <div ref={gridRef} className="flex h-full w-full flex-col overflow-hidden">
              <PartNumberGrid
                partNumbers={gridPartNumbers}
                setPartNumbers={setGridPartNumbers}
                loadGridPartNumbers={loadGridPartNumbers}
                gridDatasets={gridDatasets}
                setGridDatasets={setGridDatasets}
                filteredPartNumberIds={filteredPartNumberIds}
                setError={setError}
                setGridApi={setGridApi}
                gridApi={gridApi}
              />
            </div>
          </div>

          {/* Sidebar */}
          <SidebarTransition
            show={sidebarOpen}
            enter="transition duration-200 ease-out transform"
            enterFrom="translate-x-full"
            leave="transition duration-75 ease-out transform"
            className="flex h-full w-1/3 min-w-[380px] border-l shadow"
            appear={true}
          >
            {/* Sidebar Contents */}
            {sidebarState === SidebarStates.CsvUpload && (
              <PartNumberSidebarCsvUpload
                setSidebarOpen={setSidebarOpen}
                addNewColumn={addNewColumn}
                partNumbers={gridPartNumbers}
                gridDatasets={gridDatasets}
                setPartNumbers={setGridPartNumbers}
              />
            )}
          </SidebarTransition>
        </div>
      )}

      {/* Footer */}
      <div className="flex h-12 shrink-0 items-center justify-between border-t px-3">
        {/* Save / cancel buttons */}
        <div className="flex gap-x-2">
          <button className="btn-xs serial-btn-dark h-7" onClick={() => handleRetroactiveUpdate()}>
            Retroactively Update All Units
          </button>
        </div>
        <div className="flex space-x-2">
          <button className="btn-xs serial-btn-light h-7" onClick={(e) => handleClose(e)}>
            Cancel
          </button>
          <button className="btn-xs serial-btn-dark h-7" onClick={() => handleSave()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartNumbers;
