import React, { useMemo, useState } from "react";
import { IHeaderParams } from "ag-grid-community";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faSortAmountAsc, faSortAmountDesc, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MeasureAggregationOperator, MeasureKey, MeasureType, allowedMeasureAggregationOperators } from "@shared/measures/types";
import useMeasures from "@shared/measures/MeasuresProvider";
import DropdownMenu from "@shared/components/primitives/DropdownMenu";
import Button from "@shared/components/primitives/Button";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { cn } from "@shared/utils/tailwind";
import { isSameMeasureKey } from "@shared/measures/helpers/measureKeys";
import TagBasic from "@shared/components/primitives/TagBasic";
import MeasureKeyTimeOperatorModal from "../MeasureKeyTimeOperatorModal";

interface MeasuresGridHeaderCellTimestampProps extends IHeaderParams {
  measureKey: MeasureKey;
}

const MeasuresGridHeaderCell: React.FC<MeasuresGridHeaderCellTimestampProps> = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [timeOperatorModalOpen, setTimeOperatorModalOpen] = useState<boolean>(false);
  const {
    handleRemoveSelectedKey,
    handleUpdateKeyAggregationOperator,
    handleUpdateKeyFormatting,
    sortBy,
    handlePromoteSortCondition,
    handleRemoveSortCondition,
    componentId,
  } = useMeasures();

  const sortCondition = useMemo(() => {
    return sortBy.find((sort) => isSameMeasureKey(sort.key, props.measureKey));
  }, [sortBy, props.measureKey]);

  const handleSort = (ascending: boolean) => {
    if (sortCondition && ascending === sortCondition.ascending) {
      handleRemoveSortCondition(props.measureKey);
    } else {
      handlePromoteSortCondition(props.measureKey, ascending);
    }
  };

  return (
    <div className="group flex h-full w-full items-center justify-between">
      <div className="flex items-center overflow-hidden">
        <TagBasic variant={props.measureKey.type} size="xs" />
        <span className="truncate pl-2 font-semibold text-black">
          {props.displayName}
          {props.measureKey.aggregation_operator ? ` (${props.measureKey.aggregation_operator})` : ""}
        </span>
      </div>
      <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenu.Trigger asChild>
          <div className="relative">
            <Button size="xs" variant="outline" className={cn(!dropdownOpen && "opacity-0", "h-6 w-6 group-hover:opacity-100")}>
              <FontAwesomeIcon icon={faEllipsis} />
            </Button>
            {sortBy[0] && isSameMeasureKey(sortBy[0].key, props.measureKey) && (
              <div
                className={cn(
                  dropdownOpen ? "opacity-0" : "group-hover:opacity-0",
                  "absolute right-0 top-0 flex h-full w-full items-center justify-center",
                )}
              >
                <FontAwesomeIcon icon={sortCondition?.ascending ? faSortAmountDesc : faSortAmountAsc} />
              </div>
            )}
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="w-56" align="start">
          {[MeasureType.Timestamp, MeasureType.Datetime].includes(props.measureKey.type) && (
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <div className="flex w-full items-center justify-between">
                  <span className="font-medium">Formatting</span>
                  <span>{props.measureKey.formatting?.relative_time ? "Relative" : "Absolute"}</span>
                </div>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.RadioGroup
                  value={String(props.measureKey.formatting?.relative_time === true)}
                  onValueChange={(value) => handleUpdateKeyFormatting(props.measureKey, { relative_time: value === "true" })}
                >
                  <DropdownMenu.RadioItem value="true">Relative Time</DropdownMenu.RadioItem>
                  <DropdownMenu.RadioItem value="false">Absolute Time</DropdownMenu.RadioItem>
                </DropdownMenu.RadioGroup>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
          )}
          {allowedMeasureAggregationOperators[props.measureKey.type].length > 1 && (
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <div className="flex w-full items-center justify-between">
                  <span className="font-medium">Aggregation</span>
                  <span>{screamingSnakeCaseToTitleCase(props.measureKey.aggregation_operator ?? MeasureAggregationOperator.Latest)}</span>
                </div>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.RadioGroup
                  value={props.measureKey.aggregation_operator ?? MeasureAggregationOperator.Latest}
                  onValueChange={(value) => handleUpdateKeyAggregationOperator(props.measureKey, value as MeasureAggregationOperator)}
                >
                  {allowedMeasureAggregationOperators[props.measureKey.type].map((operator) => (
                    <DropdownMenu.RadioItem key={operator} value={operator}>
                      <span>{screamingSnakeCaseToTitleCase(operator)}</span>
                    </DropdownMenu.RadioItem>
                  ))}
                </DropdownMenu.RadioGroup>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
          )}
          {props.measureKey.component_id === componentId && (
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <div className="flex w-full items-center justify-between">
                  <span className="font-medium">Sort</span>
                  <span>{sortCondition?.ascending === true ? "Ascending" : sortCondition?.ascending === false ? "Descending" : ""}</span>
                </div>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.CheckboxItem checked={sortCondition?.ascending === true} onClick={() => handleSort(true)}>
                  Ascending
                </DropdownMenu.CheckboxItem>
                <DropdownMenu.CheckboxItem checked={sortCondition?.ascending === false} onClick={() => handleSort(false)}>
                  Descending
                </DropdownMenu.CheckboxItem>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
          )}
          {allowedMeasureAggregationOperators[props.measureKey.type].length > 1 && (
            <DropdownMenu.Item
              className="flex font-medium"
              key={"Advanced Options"}
              onClick={() => {
                setTimeOperatorModalOpen(true);
              }}
            >
              <div className="flex w-full items-center justify-between">
                <span>Advanced Options</span>
              </div>
            </DropdownMenu.Item>
          )}
          <DropdownMenu.Separator className="first:hidden last:hidden" />
          {!(props.measureKey.type === MeasureType.Identifier && componentId === props.measureKey.component_id) && (
            <DropdownMenu.Item
              className="flex font-medium !text-red-500 hover:text-red-600"
              onClick={() => handleRemoveSelectedKey(props.measureKey)}
            >
              <div className="flex w-4 justify-center">
                <FontAwesomeIcon icon={faTrash} />
              </div>
              <span>Remove Column</span>
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      <MeasureKeyTimeOperatorModal
        timeOperatorModalOpen={timeOperatorModalOpen}
        setTimeOperatorModalOpen={setTimeOperatorModalOpen}
        parentMeasureKey={props.measureKey}
        selectedKey={props.measureKey}
      />
    </div>
  );
};

export default MeasuresGridHeaderCell;
