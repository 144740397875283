import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WorkOrderContext } from "../WorkOrders";
import WorkOrderSummaryTableGroup from "./WorkOrderSummaryTableGroup";
import { workOrderStatusStyle } from "../contants";
import DropdownFilter from "@shared/components/dropdowns/DropdownFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { WorkOrderStatus } from "@shared/types/databaseEnums";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { UserRole } from "@shared/types/databaseEnums";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { useTranslation } from "react-i18next";

// construct key value pairs where the key is the name property of the workOrderStatusStyle object and the value is a boolean
const defaultFilterOptions = {
  [workOrderStatusStyle[WorkOrderStatus.Draft].name]: true,
  [workOrderStatusStyle[WorkOrderStatus.Review].name]: true,
  [workOrderStatusStyle[WorkOrderStatus.Production].name]: true,
  [workOrderStatusStyle[WorkOrderStatus.Complete].name]: true,
  [workOrderStatusStyle[WorkOrderStatus.Canceled].name]: false,
  [workOrderStatusStyle[WorkOrderStatus.Archived].name]: false,
};

interface WorkOrderSummaryTableProps {}

const WorkOrderSummaryTable: React.FC<WorkOrderSummaryTableProps> = () => {
  const [filterOptions, setFilterOptions] = useState<Record<string, boolean>>(defaultFilterOptions);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation();

  const context = useContext(WorkOrderContext);
  const role = useSelector((state: RootState) => state.auth.role);

  const navigate = useNavigate();
  const observe = useContext(ObservabilityContext);

  const toggleFilterOption = (option: string) => {
    setFilterOptions({
      ...filterOptions,
      [option]: !filterOptions[option],
    });
  };

  return (
    <div className="flex h-fit w-full flex-col p-8">
      <div className="flex w-full"></div>

      <div className="border-serial-palette-200  relative rounded-md border bg-white">
        <div className="flex w-full justify-between ">
          <header className="flex items-center justify-between px-5 py-4">
            <h2 className="text-serial-palette-800 font-semibold">
              {t("workOrders")}
              <span className="text-serial-palette-400 pl-1 pr-2 font-medium">{context?.workOrders?.length ?? 0}</span>
            </h2>
          </header>
          <div className="mx-3 flex items-center space-x-2">
            <div className="w-42 relative flex cursor-pointer gap-2 overflow-hidden rounded border transition-all">
              <input
                className="form-input w-full border-none pl-9"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm || ""}
              ></input>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="text-serial-palette-400 absolute left-3 top-1/2 -translate-y-1/2 transform"
              />
            </div>
            <DropdownFilter classOverride="" align="right" filterOptions={filterOptions} toggleFilterOption={toggleFilterOption} />
            {role === UserRole.Admin && (
              <button
                className="btn bg-serial-palette-800 hover:bg-serial-palette-600 text-white"
                onClick={() => (navigate("/workorder/new"), observe.track("work-order_create-new"))}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="xs:block ml-2 hidden">Create {t("workOrder")}</span>
              </button>
            )}
          </div>
        </div>
        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              {/* Table header */}
              <thead className="text-serial-palette-500 bg-serial-palette-100 border-serial-palette-200 border-b border-t text-xs font-semibold uppercase">
                <tr>
                  <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-center font-semibold">{t("workOrder")}</div>
                  </th>
                  <th className="w-full whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold"></div>
                  </th>
                  <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-right font-semibold">Component</div>
                  </th>
                  <th className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
                    <div className="text-center font-semibold">Assignee</div>
                  </th>
                  <th className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
                    <div className="text-center font-semibold">Qty</div>
                  </th>
                </tr>
              </thead>

              {/* Table body */}
              <tbody className="divide-serial-palette-200 divide-y border-b text-sm last:border-b-0">
                {Object.values(WorkOrderStatus)
                  .filter((status) => filterOptions[workOrderStatusStyle[status].name])
                  .map((status, index) => {
                    return <WorkOrderSummaryTableGroup key={index} status={status} searchTerm={searchTerm} />;
                  })}
              </tbody>
            </table>

            {context?.workOrders?.length === 0 && (
              <div className="w-full space-y-2 py-10">
                <div className="text-center">No {t("workOrders").toLowerCase()} are currently active.</div>
                <div className="text-center">
                  <span>Click </span>
                  <span className="font-bold">Create {t("workOrder")}</span>
                  <span> to get started.</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderSummaryTable;
