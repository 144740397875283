import React, { useContext, useEffect, useMemo, useState } from "react";
import { Loader } from "@shared/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import DropdownSearch from "@shared/components/dropdowns/DropdownSearch";
import StationSelectorItem from "./StationSelectorItem";
import DropdownFilter from "@shared/components/dropdowns/DropdownFilter";
import { createStation } from "../connections/supabase";
import { StationProcessLink } from "@shared/types/databaseTypes";
import { StationWithLinksAndProcessEntries } from "../types";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { useNavigate } from "react-router-dom";

interface StationSelectorProps {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  stations: StationWithLinksAndProcessEntries[];
  selectedStation: StationWithLinksAndProcessEntries | null;
  reloadData: () => Promise<StationWithLinksAndProcessEntries[] | null>;
}

interface FilterOptions {
  [key: string]: boolean;
}

interface DropdownOption {
  label: string;
  id: string;
}

const defaultFilterOptions: FilterOptions = {
  Active: true,
  Locked: true,
  Retired: false,
};

const StationSelector: React.FC<StationSelectorProps> = ({ isLoading, setIsLoading, stations, selectedStation, reloadData }) => {
  const [selectedComponentId, setSelectedComponentId] = useState<string | null>(null);
  const [selectedProcessId, setSelectedProcessId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredStations, setFilteredStations] = useState<StationWithLinksAndProcessEntries[]>([...stations]);
  const [filterStatusOptions, setFilterOptions] = useState<FilterOptions>(defaultFilterOptions);
  const observe = useContext(ObservabilityContext);
  const navigate = useNavigate();

  const components = useSelector((state: RootState) => state.db.components);

  const toggleFilterStatusOption = (optionKey: string): void => {
    const modifiedOptions = { ...filterStatusOptions };
    modifiedOptions[optionKey] = !filterStatusOptions[optionKey];
    setFilterOptions(modifiedOptions);
  };

  const handleAddStation = async () => {
    observe.track("station_add-station");
    setIsLoading(true);
    const newStation = await createStation();
    await reloadData();
    if (newStation) {
      navigate(`/stations/${newStation.id}`);
    }
    setIsLoading(false);
  };

  const processOptions = useMemo(() => {
    if (stations.some((station) => !station.station_process_links)) return [];
    return stations.reduce((acc: DropdownOption[], station: StationWithLinksAndProcessEntries) => {
      station.station_process_links?.forEach((link: StationProcessLink) => {
        const id = link.process_id ?? "";
        const label = link.process?.name ?? "";
        const process = { id, label };
        const componentMatches = link?.component?.id === selectedComponentId || selectedComponentId === null;

        if (!acc.some((c: DropdownOption) => c.id === id) && componentMatches) {
          acc.push(process);
        }
      });

      return acc;
    }, []);
  }, [stations, selectedComponentId]);

  const componentOptions = useMemo(() => {
    if (stations.some((station) => !station.station_process_links)) return [];
    return stations.reduce((acc: DropdownOption[], station: StationWithLinksAndProcessEntries) => {
      station.station_process_links?.forEach((link: StationProcessLink) => {
        const id = link.component_id ?? "";
        const component = components.find((component) => component.id === id);
        const componentOption = { id, label: component?.name ?? "" };

        if (!acc.some((c: DropdownOption) => c.id === id) && component?.is_active) {
          acc.push(componentOption);
        }
      });

      return acc;
    }, []);
  }, [stations]);

  // update filtered stations when stations change or when search or filter terms change
  useEffect(() => {
    let newFilteredStations = stations.filter((station) => {
      if (selectedComponentId && selectedProcessId) {
        return station.station_process_links?.some((link: StationProcessLink) => {
          return link.component_id === selectedComponentId && link.process_id === selectedProcessId;
        });
      } else if (selectedComponentId) {
        return station.station_process_links?.some((link: StationProcessLink) => {
          return link.component_id === selectedComponentId;
        });
      } else if (selectedProcessId) {
        return station.station_process_links?.some((link: StationProcessLink) => {
          return link.process_id === selectedProcessId;
        });
      } else {
        return true;
      }
    });

    if (searchTerm) {
      newFilteredStations = newFilteredStations.filter((station) => {
        return station.name?.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    newFilteredStations = newFilteredStations.filter((station) => {
      return filterStatusOptions[!station.is_active ? "Retired" : station.is_locked ? "Locked" : "Active"];
    });

    setFilteredStations(newFilteredStations);
  }, [stations, selectedComponentId, selectedProcessId, searchTerm, filterStatusOptions]);

  return (
    <div className="border-1.5 flex h-full w-full flex-col rounded-md bg-white">
      {/* Header */}
      <div className="border-b-1.5 flex h-16 w-full shrink-0 items-center justify-between px-4">
        <h2 className="text-serial-palette-800 flex text-lg font-semibold">
          <span className="">Stations</span>
          {isLoading ? (
            <Loader styleOverride="mx-2 w-[24px] h-[24px]" />
          ) : (
            <span className="text-serial-palette-400 mx-2 font-medium">{stations.length}</span>
          )}
        </h2>
        <button className="btn bg-serial-palette-800 hover:bg-serial-palette-600 h-8 text-white" onClick={() => handleAddStation()}>
          <FontAwesomeIcon icon={faPlus} />
          <span className="my-0 ml-2 hidden whitespace-nowrap py-0 lg:block">Add Station</span>
        </button>
      </div>

      {/* Filters */}
      <div className="flex w-full flex-col items-center gap-2 px-4 pt-5">
        <div className="relative flex w-full gap-2">
          <input
            className="form-input w-full pl-9"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm || ""}
          ></input>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="text-serial-palette-400 absolute left-3 top-1/2 -translate-y-1/2 transform"
          />
          <DropdownFilter align="left" filterOptions={filterStatusOptions} toggleFilterOption={toggleFilterStatusOption} classOverride="" />
        </div>
        <div className="flex w-full flex-col items-center justify-between gap-2">
          <DropdownSearch
            options={[{ id: null, label: "Component" }, ...componentOptions]}
            selected={selectedComponentId}
            setSelected={setSelectedComponentId}
            className=""
            placeholder="Component"
            dropdownClassName="w-[300px] "
          />
          <DropdownSearch
            options={[{ id: null, label: "Process" }, ...processOptions]}
            selected={selectedProcessId}
            setSelected={setSelectedProcessId}
            className=""
            placeholder="Process"
            dropdownClassName="w-[300px] right-0"
          />
        </div>
      </div>

      {/* List */}
      <div className="scrollbar-hide flex w-full flex-col items-center justify-between overflow-scroll pt-4">
        {/* sort station by latest_process_entries most recent submission time */}
        {filteredStations
          .sort((a, b) => {
            const aLatestEntry = a.latest_process_entries?.[0];
            const bLatestEntry = b.latest_process_entries?.[0];
            if (aLatestEntry && bLatestEntry) {
              return new Date(bLatestEntry.timestamp).getTime() - new Date(aLatestEntry.timestamp).getTime();
            } else if (aLatestEntry) {
              return -1;
            } else if (bLatestEntry) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((station, index) => (
            <button className="flex w-full" onClick={() => navigate(`/stations/${station.id}`)} key={index}>
              <StationSelectorItem station={station} selectedStation={selectedStation} />
            </button>
          ))}
      </div>
    </div>
  );
};

export default StationSelector;
