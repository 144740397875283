import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { useContext, useRef, useState } from "react";
import DropdownSearch from "@shared/components/dropdowns/DropdownSearch";
import { fetchComponentInstanceByIdentifier } from "../connections/supabase";
import { UniqueIdentifier } from "@shared/types/databaseTypes";

const ProcessBuilderPreviewIdentifierField: React.FC = () => {
  const { component, previewPartNumber, setPreviewPartNumber } = useContext(ProcessBuilderContext);
  const partNumbers = useSelector((state: RootState) => state.db.partNumbers).filter(
    (partNumber) => partNumber.component_id === component?.id,
  );

  const [identifier, setIdentifier] = useState<string | null>(null);
  const [componentInstance, setComponentInstance] = useState<UniqueIdentifier | null>(null);

  const componentInstanceAbortController = useRef<AbortController | null>(null);

  const handleUpdateIdentifier = async (newIdentifier: string) => {
    if (!newIdentifier) {
      setIdentifier(null);
      setComponentInstance(null);
      setPreviewPartNumber(null);
      return null;
    }
    setIdentifier(newIdentifier);
    componentInstanceAbortController.current?.abort();
    componentInstanceAbortController.current = new AbortController();
    const { data: updatedComponentInstance } = await fetchComponentInstanceByIdentifier(
      newIdentifier,
      componentInstanceAbortController.current.signal,
    );
    setPreviewPartNumber(updatedComponentInstance?.part_number ?? null);
    setComponentInstance(updatedComponentInstance ?? null);
  };

  const handleSelectPartNumber = (id: string | null) => {
    setPreviewPartNumber(partNumbers.find((partNumber) => partNumber.id === id) ?? null);
    setIdentifier(null);
    setComponentInstance(null);
  };

  return (
    <div className="group relative flex w-full cursor-default flex-col gap-y-2 border-b bg-white px-4 py-5">
      <div className="text-md font-bold">Test Filters</div>
      <div className="relative">
        <input
          className={`form-input flex w-full items-center justify-between rounded px-3 py-1.5 text-base ${componentInstance && "serial-input-valid"} ${identifier && !componentInstance && "serial-input-invalid"}`}
          value={identifier ?? ""}
          placeholder={component?.component_type === "SN" ? "Example Serial Number" : "Example Lot Code"}
          onChange={(e) => handleUpdateIdentifier(e.target.value)}
        />
        <FontAwesomeIcon className="absolute right-3 top-2.5" icon={faBarcode} />
      </div>
      {/* <div className='font-bold text-sm pt-1.5'>Part Number</div> */}
      <DropdownSearch
        options={partNumbers.map((partNumber) => ({ label: partNumber.pn, id: partNumber.id }))}
        selected={previewPartNumber?.id ?? null}
        setSelected={handleSelectPartNumber}
        placeholder="Part Number"
        className="h-10 text-base"
      />
    </div>
  );
};

export default ProcessBuilderPreviewIdentifierField;
