import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { faCompressAlt, faEllipsis, faExpandAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "@shared/components/Loader";
import logoBlackSm from "@images/serial_submark_black.png";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { dashboardInsightDefaultProperties } from "../constants";
import { DashboardFilters, DashboardInsightWidth } from "../types";
import { DashboardInsightType } from "@shared/types/databaseEnums";
import { DashboardContext } from "../Dashboards";

interface DashboardInsightCardProps {
  type: DashboardInsightType;
  width: DashboardInsightWidth;
  filters: DashboardFilters;
  setFilters: (filters: DashboardFilters) => void;
  rowIndex: number;
  insightIndex: number;
  config?: any;
  isLoading?: boolean;
  allowFullscreen?: boolean;
  className?: string;
}

interface DashboardInsightContextProps {
  isFullScreen: boolean;
  width: DashboardInsightWidth;
  height: number; // height in pixels
  config: any;
  filters: DashboardFilters;
  setFilters: (filters: DashboardFilters) => void;
  setSubtitle: (subtitle: string | null) => void;
  setTitle: (title: string) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const dashboardContextDefault: DashboardInsightContextProps = {
  isFullScreen: false,
  width: "xl",
  height: 0,
  config: {},
  filters: { componentId: null, dateRange: { from: undefined, to: undefined } },
  setFilters: () => {},
  setSubtitle: () => {},
  setTitle: () => {},
  setIsLoading: () => {},
};

const widthClassName = {
  sm: "col-span-full md:col-span-3 xl:col-span-2",
  md: "col-span-full xl:col-span-3",
  lg: "col-span-full xl:col-span-4",
  xl: "col-span-full",
};

export const DashboardInsightContext = createContext<DashboardInsightContextProps>(dashboardContextDefault);

const DashboardInsightCard: React.FC<DashboardInsightCardProps> = ({
  type,
  width,
  filters,
  setFilters,
  config,
  allowFullscreen,
  className,
  rowIndex,
  insightIndex,
}) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [subtitle, setSubtitle] = useState<string | null>(null);
  const [title, setTitle] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const context = useContext(DashboardContext);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === bodyRef.current) {
          setHeight(entry.contentRect.height);
          console.log(height);
        }
      }
    });

    if (bodyRef.current) {
      resizeObserver.observe(bodyRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const cardContext: DashboardInsightContextProps = useMemo(
    () => ({
      isFullScreen,
      width,
      height,
      config,
      filters,
      setFilters,
      setSubtitle,
      setTitle,
      setIsLoading,
    }),
    [isFullScreen, width, height, config, filters],
  );

  useEffect(() => {
    if (config?.title || dashboardInsightDefaultProperties[type].title) {
      setTitle(config?.title ?? dashboardInsightDefaultProperties[type].title);
    }
    if (config?.subtitle || dashboardInsightDefaultProperties[type].subtitle) {
      setSubtitle(config?.subtitle ?? dashboardInsightDefaultProperties[type].subtitle);
    }
  }, [config, type]);

  const BodyComponent = dashboardInsightDefaultProperties[type].body;

  if (isFullScreen) {
    return (
      <div className="fixed inset-2 z-50 rounded-lg border bg-white">
        <header className="flex h-12 items-center justify-between border-b pl-1 pr-2">
          <div className="inline-block h-10">
            <img className="h-full object-contain" src={logoBlackSm} />
          </div>
          <h2 className="space-x-2">
            <span className="text-serial-palette-800 font-semibold">{title}</span>
            {subtitle && <span className="text-serial-palette-400 font-medium">{subtitle}</span>}
          </h2>
          <div className="flex h-full flex-nowrap items-center gap-x-2">
            {isFullScreen && (
              <button
                onClick={() => setIsFullScreen(false)}
                className="btn serial-btn-light hover:border-serial-palette-200 border-white px-2"
              >
                <FontAwesomeIcon icon={faCompressAlt} />
              </button>
            )}
          </div>
        </header>
        <div ref={bodyRef} className="flex flex-col justify-between overflow-auto">
          <DashboardInsightContext.Provider value={cardContext}>
            <BodyComponent />
          </DashboardInsightContext.Provider>
        </div>
      </div>
    );
  }

  return (
    <div className={`border-serial-palette-200 h-full overflow-hidden rounded-md border bg-white ${widthClassName[width]} ${className}`}>
      <header className="flex h-12 items-center justify-between border-b pl-4 pr-2">
        <div className="flex items-center space-x-2">
          <span className="text-serial-palette-800 font-semibold">{title}</span>
          {(subtitle || isLoading) && (
            <span className="text-serial-palette-400 font-medium">
              {isLoading ? <Loader styleOverride="w-[18px] h-[18px]" /> : subtitle}
            </span>
          )}
        </div>

        <div className="flex h-full flex-nowrap items-center gap-x-2">
          <DropdownCustom
            align="right"
            className="flex h-full"
            dropdownClassName="pb-1.5"
            keepOpen={true}
            buttonContents={
              <div className="btn serial-btn-light hover:border-serial-palette-200 w-8 border-white">
                <FontAwesomeIcon icon={faEllipsis} size="lg" />
              </div>
            }
          >
            {allowFullscreen && (
              <button
                className="text-serial-palette-600 hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium"
                onClick={() => setIsFullScreen(true)}
              >
                <FontAwesomeIcon icon={faExpandAlt} className="text-serial-palette-500" />
                <div className="">Fullscreen</div>
              </button>
            )}
            {allowFullscreen && (
              <button
                className="hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium text-rose-500"
                onClick={() => context.handleRemoveInsightFromSelectedDashboard(rowIndex, insightIndex)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="text-rose-500" />
                <div className="">Delete</div>
              </button>
            )}
          </DropdownCustom>
        </div>
      </header>

      <div className={`flex h-full flex-col justify-between overflow-hidden rounded-md`}>
        <DashboardInsightContext.Provider value={cardContext}>
          <BodyComponent />
        </DashboardInsightContext.Provider>
      </div>
    </div>
  );
};

export default DashboardInsightCard;
