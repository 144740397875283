import React, { useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { UserRolePrettyNames } from "@shared/constants/serial";
import { useSelector } from "react-redux";

function InsufficientPrivileges() {
  const role = useSelector((state) => state.auth.role);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar />

      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header />

        <main>
          <div className="w-full px-8 py-8">
            <div className="m-auto mt-24 max-w-2xl">
              <div className="px-4 text-center">
                <div className="mb-8 inline-flex">
                  <FontAwesomeIcon size="6x" className="text-rose-300" icon={faXmarkCircle} />
                </div>
                <div className="pb-2 text-lg font-bold">
                  Oops... you do not have privileges to view this page.
                  <br />
                </div>
                <div className="mb-6">
                  Your role is currently set to "
                  {UserRolePrettyNames.find((r) => r.id === role) && UserRolePrettyNames.find((r) => r.id === role).pretty_name}".
                  <br />
                  Contact your Serial admin for details or to change your role.
                </div>
                <Link to="/" className="btn bg-serial-palette-800 hover:bg-serial-palette-600 text-white">
                  Back To Home
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default InsufficientPrivileges;
