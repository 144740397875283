import { Loader } from "@shared/components/Loader";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import React, { useContext, useState } from "react";
import DatasetAnalyticsPerformance from "./DatasetAnalyticsPerformance";
import { DatasetsContext } from "../Datasets";
import DateRangePicker from "@shared/components/DateRangePicker";

enum Navigation {
  Performance = "PERFORMANCE",
}

const DatasetAnalytics: React.FC = () => {
  const [activeNavOption, setActiveNavOption] = useState<Navigation>(Navigation.Performance);
  const context = useContext(DatasetsContext);

  return (
    <div className="flex min-h-0 min-w-0 flex-grow flex-col rounded-md border bg-white">
      <div className="flex w-full items-center justify-between border-b pl-[3px] pr-3">
        <div className="flex w-full overflow-x-auto pt-4">
          {Object.values(Navigation).map((option, index) => {
            return (
              <div key={index} className="flex justify-center">
                <h2
                  onClick={() => setActiveNavOption(option)}
                  className={`text-serial-palette-800 flex pb-[13px] ${index === 0 ? "pl-5" : "pl-3"} border-b-[3.5px] pr-3 ${activeNavOption === option ? "border-serial-palette-600 font-semibold" : "hover:border-serial-palette-400 border-white font-medium"} cursor-pointer`}
                >
                  <span className={`${activeNavOption !== option && "opacity-60"}`}>{screamingSnakeCaseToTitleCase(option)}</span>
                  {context.isLoading && activeNavOption === option && <Loader styleOverride="mx-2 w-[24px] h-[24px]" />}
                </h2>
              </div>
            );
          })}
        </div>
        <div className="ml-2 hidden xl:flex">
          <DateRangePicker dateRange={context.selectedDateRange} onDateRangeChange={context.setSelectedDateRange} />
        </div>
      </div>
      {activeNavOption === Navigation.Performance && <DatasetAnalyticsPerformance />}
    </div>
  );
};

export default DatasetAnalytics;
