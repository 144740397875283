import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faEllipsisV, faImage, faStream, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useContext, useMemo } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { Field, FieldType } from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

interface ProcessBuilderFieldEditDropdownProps {
  stepIndex: number;
  fieldIndex: number;
  field: Field;
}

const ProcessBuilderFieldEditDropdown: React.FC<ProcessBuilderFieldEditDropdownProps> = ({ stepIndex, fieldIndex, field }) => {
  const {
    handleDeleteField,
    handleUpdateFields,
    setSchemaDesignerOpen,
    setSelectedSourceDatasetId,
    draftSchemas,
    existingSchemas,
    setSelectedSchemaId,
  } = useContext(ProcessBuilderContext);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const datasetExists = useMemo(() => {
    return datasets.find((dataset) => dataset.id === field.dataset_id) !== undefined;
  }, [datasets, field.dataset_id]);

  const schemaId = useMemo(() => {
    if (!field.dataset_id) return null;
    const draftSchema = draftSchemas.find((schema) => schema.source_dataset_id === field.dataset_id);
    if (draftSchema) return draftSchema.id;
    const existingSchema = existingSchemas.find((schema) => schema.source_dataset_id === field.dataset_id);
    if (existingSchema) return existingSchema.id;
    return null;
  }, [draftSchemas, existingSchemas, field.dataset_id]);

  return (
    <DropdownCustom
      align="right"
      className="flex h-full"
      dropdownClassName="pb-1.5 w-52 z-20"
      closeOnMouseLeave={true}
      buttonContents={
        <div
          tabIndex={-1}
          className="text-serial-palette-400 hover:text-serial-palette-600 flex w-4 shrink-0 justify-center rounded bg-white"
        >
          <FontAwesomeIcon className="hidden group-hover:flex" icon={faEllipsisV} />
        </div>
      }
    >
      {field.type !== FieldType.Checkbox && (
        <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
          <div className="">Required</div>
          <div className="form-switch-sm">
            <input
              type="checkbox"
              id={`process-builder-switch-optional-field-${field.id}`}
              className="sr-only"
              checked={!field.is_optional}
              onChange={() => handleUpdateFields(stepIndex, [field.id], "is_optional", !field.is_optional)}
            />
            <label className="bg-serial-palette-200" htmlFor={`process-builder-switch-optional-field-${field.id}`}>
              <span className="bg-white " aria-hidden="true"></span>
              <span className="sr-only">Switch label</span>
            </label>
          </div>
        </div>
      )}
      {field.type === FieldType.File && (
        <button
          className="hover:bg-serial-palette-50 text-serial-palette-600 flex w-full cursor-pointer items-center gap-2 px-2 py-2 text-sm font-medium"
          onClick={() => {
            setSchemaDesignerOpen(true);
            setSelectedSourceDatasetId(field.dataset?.id ?? null);
            setSelectedSchemaId(schemaId);
          }}
        >
          <FontAwesomeIcon icon={faStream} className="text-serial-palette-600" />
          <div className="">Edit/Add Auto Parsing</div>
        </button>
      )}
      {field.type === FieldType.Image && (
        <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
          <div className="">Upload Method</div>
          <div className="flex">
            <button
              className={`btn-sm rounded-r-none ${field.method === "UPLOAD" ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateFields(stepIndex, [field.id], "method" as keyof Field, "UPLOAD")}
            >
              <FontAwesomeIcon icon={faImage} />
            </button>
            <button
              className={`btn-sm rounded-l-none ${field.method === "CAMERA" ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateFields(stepIndex, [field.id], "method" as keyof Field, "CAMERA")}
            >
              <FontAwesomeIcon icon={faCamera} size="xs" />
            </button>
          </div>
        </div>
      )}
      {field.type === FieldType.ManualEntry && (
        <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
          <div className="">Type</div>
          <div className="flex">
            <button
              disabled={field.dataset?.data_type === DataType.ParametricQuantitative && datasetExists}
              className={`btn-sm w-10 rounded-r-none text-[11px] font-bold ${field.data_validation === "STRING" ? "serial-btn-dark" : "serial-btn-light"} disabled:bg-serial-palette-50 disabled:cursor-not-allowed`}
              onClick={() => handleUpdateFields(stepIndex, [field.id], "data_validation" as keyof Field, "STRING")}
              title="String"
            >
              abc
            </button>
            <button
              disabled={field.dataset?.data_type === DataType.ParametricQualitative && datasetExists}
              className={`btn-sm w-10 rounded-none border-x-0 text-[11px] font-bold ${field.data_validation === "INTEGER" ? "serial-btn-dark" : "serial-btn-light"} disabled:bg-serial-palette-50 disabled:cursor-not-allowed`}
              onClick={() => handleUpdateFields(stepIndex, [field.id], "data_validation" as keyof Field, "INTEGER")}
              title="Integer"
            >
              ##
            </button>
            <button
              disabled={field.dataset?.data_type === DataType.ParametricQualitative && datasetExists}
              className={`btn-sm w-10 rounded-l-none text-[11px] font-bold ${field.data_validation === "NUMBER" ? "serial-btn-dark" : "serial-btn-light"} disabled:bg-serial-palette-50 disabled:cursor-not-allowed`}
              onClick={() => handleUpdateFields(stepIndex, [field.id], "data_validation" as keyof Field, "NUMBER")}
              title="Number"
            >
              #.##
            </button>
          </div>
        </div>
      )}
      <button
        className="hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-2 text-sm font-medium text-rose-500"
        onClick={() => handleDeleteField(stepIndex, fieldIndex)}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-rose-500" />
        <div className="">Delete</div>
      </button>
    </DropdownCustom>
  );
};

export default ProcessBuilderFieldEditDropdown;
