// Hooks
import { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import Header from "@shared/components/Header";
import Sidebar from "@shared/components/Sidebar";
import WorkOrderDetails from "./components/WorkOrderDetails";
import WorkOrderSummaryTable from "./components/WorkOrderSummaryTable";
import { useWorkOrders } from "./hooks/useWorkOrder";
import { WorkOrderContextProps } from "./types";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import { WorkOrder } from "@shared/types/databaseTypes";
import { blankWorkOrder } from "./contants";
import { useTranslation } from "react-i18next";

enum WorkOrderState {
  Summary = "SUMMARY",
  Single = "SINGLE",
}

const defaultBreadcrumbs = [
  {
    label: "Work Orders",
    link: "/workorder",
  },
];

export const WorkOrderContext = createContext<WorkOrderContextProps | null>(null);

function WorkOrders() {
  const location = useLocation();
  const { t } = useTranslation();

  const [workOrderState, setWorkOrderState] = useState<WorkOrderState>(
    location.pathname.includes("/workorder/") ? WorkOrderState.Single : WorkOrderState.Summary,
  );
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(defaultBreadcrumbs);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(null);

  const {
    workOrders,
    handleCreateWorkOrder,
    handleUpdateWorkOrder,
    handleDeleteWorkOrder,
    handleAddApprover,
    handleRemoveApprover,
    handleSetApproval,
    handleAddComment,
    handleSetStatus,
    handleAddAttachment,
    handleDeleteAttachment,
  } = useWorkOrders();

  // set work order page state to details view if the URL contains '/workorder/:id' or '/workorder/new'
  useEffect(() => {
    if (location.pathname.includes("/workorder/new")) {
      const newBreadcrumbs = [
        ...defaultBreadcrumbs,
        {
          label: "New",
          link: "/workorder/new",
        },
      ];
      setBreadcrumbs(newBreadcrumbs);
      setSelectedWorkOrder(blankWorkOrder);
      setWorkOrderState(WorkOrderState.Single);
    } else if (location.pathname.includes("/workorder/")) {
      const workOrderId = location.pathname.split("/workorder/")[1];
      const newSelectedWorkOrder = workOrders.find((workOrder) => workOrder.id === workOrderId) ?? null;
      setSelectedWorkOrder(newSelectedWorkOrder);
      // Update breadcrumbs and navigate to work order details view if a work order exists
      if (newSelectedWorkOrder) {
        const newBreadcrumbs = [
          ...defaultBreadcrumbs,
          {
            label: newSelectedWorkOrder
              ? `${t("workOrderPrefix")}-${newSelectedWorkOrder.wo_number}: ${newSelectedWorkOrder.name}`
              : `New ${t("workOrder")}`,
            link: `/workorder/${workOrderId}`,
          },
        ];
        setBreadcrumbs(newBreadcrumbs);
        setWorkOrderState(WorkOrderState.Single);
      }
    } else {
      setBreadcrumbs(defaultBreadcrumbs);
      setWorkOrderState(WorkOrderState.Summary);
    }
  }, [location.pathname, workOrders]);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar />
      {/* Content area */}
      <div className="relative flex h-full flex-1 flex-col">
        {/*  Site header */}
        <Header breadcrumbs={breadcrumbs} />
        {/* Grid Builder Body */}
        <div className="flex min-h-0 min-w-0 flex-grow overflow-auto">
          <WorkOrderContext.Provider
            value={{
              workOrders,
              handleCreateWorkOrder,
              handleUpdateWorkOrder,
              handleDeleteWorkOrder,
              handleAddApprover,
              handleRemoveApprover,
              handleSetApproval,
              handleAddComment,
              handleSetStatus,
              handleAddAttachment,
              handleDeleteAttachment,
            }}
          >
            {workOrderState === WorkOrderState.Single && <WorkOrderDetails workOrder={selectedWorkOrder ?? blankWorkOrder} />}
            {workOrderState === WorkOrderState.Summary && <WorkOrderSummaryTable />}
          </WorkOrderContext.Provider>
        </div>
      </div>
    </div>
  );
}

export default WorkOrders;
