import { ImageField } from "@shared/types/databaseTypes";
import { ProcessBuilderFieldContentsProps } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons";

const ProcessBuilderFieldImage: React.FC<ProcessBuilderFieldContentsProps<ImageField>> = ({ field }) => {
  return (
    <div className="relative w-full text-left">
      {!field.is_optional && <div className=" absolute right-1.5 top-1.5 pr-1 text-rose-500">*</div>}
      <div className="w-ful flex flex-col">
        <div className="border-serial-palette-200  text-serial-palette-400 flex w-full justify-between rounded border font-light italic  leading-5">
          {field?.method == "UPLOAD" && (
            <div className="flex h-36 w-full flex-col items-center justify-center gap-y-2">
              <FontAwesomeIcon size="3x" className="text-serial-palette-200" icon={faImage} />
              <div className="not-italic">Drag / Drop Images</div>
              <button tabIndex={-1} disabled className="btn-xs serial-btn-light mt-1">
                Browse Files
              </button>
            </div>
          )}
          {field?.method == "CAMERA" && (
            <div className="flex h-36 w-full flex-col items-center justify-center gap-y-2">
              <FontAwesomeIcon size="3x" className="text-serial-palette-200" icon={faCamera} />
              <div className="text-center text-sm not-italic">Camera (built or external webcam)</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProcessBuilderFieldImage;
