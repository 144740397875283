import { useContext, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { appendLatestProcessEntry } from "@shared/connections/supabaseProcessEntries";
import { RootState } from "@shared/redux/store";
import SummaryNumber from "@shared/components/SummaryNumber";
import { DashboardInsightContext } from "../DashboardInsightCard";
import { fetchYieldByTime, DailyData } from "../../connections/supabaseYield";
import { calculateYields, PerProcessYields } from "../../utils";
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import { tailwindConfig } from "@shared/utils/helpers";
import moment from "moment";

Chart.register(LineController, LinearScale, LineElement, Filler, Tooltip, PointElement, TimeScale);

interface ChartData {
  dates: string[];
  rollingFirstPassThroughputYield: number[];
  rollingAllPassThroughputYield: number[];
  perProcessData: PerProcessYields;
}

const YieldByComponent: React.FC<void> = () => {
  const canvas = useRef(null);

  {
    /* Get component from context */
  }
  const context = useContext(DashboardInsightContext);
  const filters = context.filters;
  const componentId = filters.componentId;
  const workOrderId = context.filters.workOrderId ?? null;
  const startDate = filters.dateRange?.from;
  const endDate = filters.dateRange?.to;
  const processes = useSelector((state: RootState) => state.db.processes);

  const [overallYield, setOverallYield] = useState<number>(0);
  const [overallFirstPassYield, setOverallFirstPassYield] = useState<number>(0);
  const [totalPartsProduced, setTotalPartsProduced] = useState<number>(0);
  const [daysOfProduction, setDaysOfProduction] = useState<number>(0);
  const [chartData, setChartData] = useState<ChartData>({
    dates: [],
    rollingFirstPassThroughputYield: [],
    rollingAllPassThroughputYield: [],
    perProcessData: {},
  });
  // Get componentInstances for selected component & filters
  // This is a copy pasta from the YieldComponentInstances.tsx file
  // This should ultimately be moved to a hook
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  useEffect(() => {
    const fetchComponentInstancesForComponent = async () => {
      if (!componentId) {
        return;
      }
      const componentInstancesForComponent = componentInstances.filter(
        (componentInstance) => componentInstance.component_id === componentId,
      );
      let componentInstancesWithProcessEntries = await appendLatestProcessEntry(componentInstancesForComponent);
      componentInstancesWithProcessEntries = componentInstancesWithProcessEntries.filter((componentInstance) => {
        return componentInstance.latest_process_entry !== null;
      });
      // Filter componentInstances by work_order_id if it exists
      if (workOrderId) {
        componentInstancesWithProcessEntries = componentInstancesWithProcessEntries.filter(
          (componentInstance) => componentInstance.work_order_id === workOrderId,
        );
      }
      // Filter componentInstances by filters.dateRange based on the latest process entry timestamp
      if (filters.dateRange?.from) {
        componentInstancesWithProcessEntries = componentInstancesWithProcessEntries.filter((componentInstance) => {
          return (
            filters.dateRange?.from?.getTime() &&
            new Date(componentInstance.latest_process_entry.timestamp).getTime() >= filters.dateRange.from?.getTime()
          );
        });
      }
      if (filters.dateRange?.to) {
        componentInstancesWithProcessEntries = componentInstancesWithProcessEntries.filter((componentInstance) => {
          return (
            filters.dateRange?.to?.getTime() &&
            new Date(componentInstance.latest_process_entry.timestamp).getTime() <= filters.dateRange?.to.getTime()
          );
        });
      }
      setTotalPartsProduced(componentInstancesWithProcessEntries.length);
    };
    fetchComponentInstancesForComponent();
  }, [componentId, componentInstances, filters]);
  // End of copy pasta

  useEffect(() => {
    const fetchData = async () => {
      context?.setIsLoading(true);

      const yieldData: DailyData[] = (await fetchYieldByTime(componentId, workOrderId, startDate, endDate))
        .map((yieldData) => {
          const processId = yieldData.process_id;
          const process = processes.find((p) => p.id === processId);
          if (process && process.is_mandatory) {
            return {
              ...yieldData,
              processName: process.name,
              isMandatory: process.is_mandatory,
            };
          }
          return yieldData;
        })
        .filter((yieldData) => yieldData.isMandatory);

      const { dailyYields, perProcessYields, overallFirstPassYield, overallAllPassYield } = calculateYields(yieldData);
      setOverallYield(overallAllPassYield);
      setOverallFirstPassYield(overallFirstPassYield);

      // perProcessYields can end up with an undefined key for non mandatory processes
      delete perProcessYields.undefined;

      const uniqueDates = [...new Set(yieldData.map((d) => d.date))];
      setDaysOfProduction(uniqueDates.length);

      setChartData({
        dates: uniqueDates,
        rollingFirstPassThroughputYield: uniqueDates.map((date) => dailyYields[date]?.first_pass_yield ?? 0),
        rollingAllPassThroughputYield: uniqueDates.map((date) => dailyYields[date]?.all_pass_yield ?? 0),
        perProcessData: perProcessYields,
      });
      context?.setIsLoading(false);
    };

    if (componentId && startDate && endDate) {
      fetchData();
    }
  }, [componentId, startDate, endDate, workOrderId]);

  useEffect(() => {
    let rollingAllPassThroughputYieldWithDates = chartData.dates.map((date, index) => {
      return { x: date, y: chartData.rollingAllPassThroughputYield[index] };
    });
    let rollingFirstPassThroughputYieldWithDates = chartData.dates.map((date, index) => {
      return { x: date, y: chartData.rollingFirstPassThroughputYield[index] };
    });
    let datasets = [
      {
        label: "All Pass Yield",
        data: rollingAllPassThroughputYieldWithDates,
        borderColor: (tailwindConfig().theme?.colors?.blue as any)?.[500],
        backgroundColor: (tailwindConfig().theme?.colors?.blue as any)?.[500],
        pointRadius: 3,
        borderWidth: 2,
      },
      {
        label: "First Pass Yield",
        data: rollingFirstPassThroughputYieldWithDates,
        borderColor: (tailwindConfig().theme?.colors?.green as any)?.[500],
        backgroundColor: (tailwindConfig().theme?.colors?.green as any)?.[500],
        pointRadius: 3,
        borderWidth: 2,
      },
    ];
    const ctx = canvas.current;

    if (ctx === null) {
      return;
    }

    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: chartData.dates,
        datasets: datasets,
      },
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 50,
          },
        },
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 10,
              format: {
                style: "percent",
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
            type: "time",
            time: {
              parser: "YYYY-MM-DD",
              unit: "day",
            },
            display: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => moment(context[0].label).format("DD MMMM YYYY"),
              label: (context) => {
                const yieldLabel = context.dataset.label + ": " + `${(context.parsed.y * 100).toFixed(2)}%`;
                return [yieldLabel];
              },
            },
          },
          legend: {
            display: false,
            position: "bottom",
            labels: {
              usePointStyle: true,
              boxWidth: 10,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
      },
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  return (
    <div className="border-serial-palette-200 col-span-full flex h-full flex-col">
      <div className="px-5 py-1">
        <div className="flex flex-wrap">
          <SummaryNumber title="Overall Yield" value={(overallYield * 100).toFixed(1) + "%"} />
          <SummaryNumber title="Overall First Pass Yield" value={(overallFirstPassYield * 100).toFixed(1) + "%"} />
          <SummaryNumber title="Total Parts Produced" value={totalPartsProduced} />
          <SummaryNumber title="Days of Production" value={daysOfProduction} />
        </div>
      </div>
      <div className="mb-10 h-full w-full grow">
        {componentId === null && startDate === null && endDate === null ? (
          <div className="flex h-full w-full items-center justify-center text-base">
            Select a component and time range to generate a yield graph
          </div>
        ) : (
          <div className="h-[350px] px-5 py-1">
            <canvas ref={canvas}></canvas>
          </div>
        )}
      </div>
    </div>
  );
};

export default YieldByComponent;
