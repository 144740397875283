import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { FileBlock, ImageBlock, PdfBlock, VideoBlock, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import { ProcessBuilderWorkInstructionBlockContentProps } from "../../types";
import FileInputBox from "@shared/components/FileInputBox";
import { faFile, faFilePdf, faImage, faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { fetchDownloadUrl, sentBlobToDownloadsFolder } from "@shared/connections/supabaseGeneral";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faGripLines, faPenNib, faTimes, faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import useProcessMediaNumbering from "@shared/hooks/useProcessMediaNumbering";
import ContentEditable from "@shared/components/ContentEditable";
import Button from "@shared/components/primitives/Button";
import ImageMarkup from "@shared/image-markup/ImageMarkup";
import { useImmer } from "use-immer";
import { ImageMarkupElement } from "@shared/types/databaseTypes";

const MIN_HEIGHT = 100;

const ProcessBuilderWorkInstructionBlockFileGeneric: React.FC<
  ProcessBuilderWorkInstructionBlockContentProps<FileBlock | ImageBlock | VideoBlock | PdfBlock>
> = ({ block, stepIndex, placeholderInputRef }) => {
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [startY, setStartY] = useState<number>(0);
  const [localHeight, setLocalHeight] = useState<number | null>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingMarkup, setEditingMarkup] = useState<boolean>(false);
  const [draftMarkup, setDraftMarkup] = useImmer<ImageMarkupElement[]>("markup" in block.content ? (block.content.markup ?? []) : []);

  const componentRef = useRef<HTMLDivElement>(null);
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const company = useSelector((state: RootState) => state.db.company);

  const { readOnly, handleUpdateWorkInstructionBlocks, handleUploadFile, draftProcess } = useContext(ProcessBuilderContext);
  const { fileNumber, imageNumber, videoNumber, pdfNumber } = useProcessMediaNumbering(draftProcess);
  const contentRef = useRef<HTMLDivElement>(null);

  const preCaptionNumbering = useMemo(() => {
    if (block.type === WorkInstructionBlockType.File) return `File ${fileNumber[block.id]}:`;
    if (block.type === WorkInstructionBlockType.Image) return `Image ${imageNumber[block.id]}:`;
    if (block.type === WorkInstructionBlockType.Video) return `Video ${videoNumber[block.id]}:`;
    if (block.type === WorkInstructionBlockType.PDF) return `PDF ${pdfNumber[block.id]}:`;
    return null;
  }, [block.type, fileNumber, imageNumber, videoNumber, pdfNumber, block.id]);

  const { icon, prompt } = useMemo(() => {
    switch (block.type) {
      case WorkInstructionBlockType.File:
        return {
          icon: faFile,
          prompt: "Drag and drop your file here or",
        };
      case WorkInstructionBlockType.Image:
        return {
          icon: faImage,
          prompt: "Drag and drop your image here or",
        };
      case WorkInstructionBlockType.Video:
        return {
          icon: faPlayCircle,
          prompt: "Drag and drop your video here or",
        };
      case WorkInstructionBlockType.PDF:
        return {
          icon: faFilePdf,
          prompt: "Drag and drop your PDF here or",
        };
      default:
        return {
          icon: faFile,
          prompt: "Drag and drop your file here or",
          allowedExtensions: ["*"],
        };
    }
  }, [block.type]);

  useEffect(() => {
    if (!contentRef.current || contentRef.current.innerText === block.content.caption || !block.content.caption) return;
    contentRef.current.innerText = block.content.caption;
  }, [block.content.caption]);

  useEffect(() => {
    if (!block.file_id || block.file_id === "") {
      setDownloadUrl(null);
      return;
    }
    fetchDownloadUrl("work-instructions", block.file_id, company.id).then((url) => {
      setDownloadUrl(url);
    });
  }, [block.file_id, company.id]);

  const handleUploadFromFileInput = async (files: File[]) => {
    if (files.length === 0) return;
    const file = files[0];
    setIsLoading(true);
    const fileId = await handleUploadFile(file, block.type);
    setIsLoading(false);
    if (!fileId) return;
    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "file_name", file.name, true);
    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "file_id", fileId, false);
  };

  const handleResizeMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (block.type === WorkInstructionBlockType.File) return;
    setIsResizing(true);
    setLocalHeight(block.content.height);
    setStartY(e.clientY);
  };

  const handleResizeMouseUp = () => {
    setIsResizing(false);
    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "height", localHeight, true);
    setLocalHeight(null);
  };

  const handleResizeMouseMove = useCallback(
    (e: MouseEvent) => {
      if (block.type === WorkInstructionBlockType.File) return;
      if (!isResizing || !localHeight) return;
      const newHeight = localHeight + (e.clientY - startY);
      setLocalHeight(Math.max(MIN_HEIGHT, newHeight));
      setStartY(e.clientY);
    },
    [isResizing, startY, handleUpdateWorkInstructionBlocks, block],
  );

  // a hack way to set the loader if the file is uploaded from outside of the component
  useEffect(() => {
    if (block.content.file_name && !block.file_id) {
      setIsLoading(true);
      // add timeout after 10 seconds to stop the loader in case an issue occurs
      setTimeout(() => {
        setIsLoading(false);
      }, 10000);
    } else {
      setIsLoading(false);
    }
  }, [block.content.file_name, block.file_id]);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleResizeMouseMove);
      window.addEventListener("mouseup", handleResizeMouseUp);
    } else {
      window.removeEventListener("mousemove", handleResizeMouseMove);
      window.removeEventListener("mouseup", handleResizeMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleResizeMouseMove);
      window.removeEventListener("mouseup", handleResizeMouseUp);
    };
  }, [isResizing, handleResizeMouseMove, handleResizeMouseUp]);

  // set focus to false when clicking outside of the component
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(e.target as Node)) {
        setFocus(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [componentRef]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      placeholderInputRef?.current?.focus();
    }
  };

  return (
    <div
      ref={componentRef}
      onClick={() => setFocus(true)}
      className="bg-serial-palette-50 relative flex w-full flex-col rounded-md px-4 pb-2 pt-0.5"
    >
      {!downloadUrl && (
        <FileInputBox
          handleUploadFiles={handleUploadFromFileInput}
          allowPaste={focus}
          fileInfo={null}
          className={`bg-white ${focus ? " border" : "border border-dashed"} !rounded-lg py-4`}
          icon={icon}
          prompt={prompt}
          isLoading={isLoading}
        />
      )}
      {downloadUrl && (
        <div className="flex w-full justify-center" ref={pdfContainerRef}>
          {block.type === WorkInstructionBlockType.Image && (
            <div className="relative flex w-full justify-center py-2" style={{ height: `${localHeight ?? block.content.height}px` }}>
              {!editingMarkup && (
                <Button
                  size="sm"
                  className="absolute -right-1 top-2 z-10 opacity-0 group-hover:opacity-100"
                  onClick={() => setEditingMarkup(true)}
                  variant="outline"
                >
                  <FontAwesomeIcon icon={faPenNib} />
                </Button>
              )}
              <ImageMarkup
                imageUrl={downloadUrl}
                markup={draftMarkup}
                onMarkupChange={(markup) => setDraftMarkup(markup)}
                editing={editingMarkup}
                onEditingChange={(editing) => {
                  setEditingMarkup(editing);
                  if (!editing) {
                    handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "markup", draftMarkup, true);
                  } else {
                    setDraftMarkup(block.content.markup ?? []);
                  }
                }}
                cover={true}
              />
            </div>
          )}
          {block.type === WorkInstructionBlockType.Video && (
            <video
              src={downloadUrl}
              className="my-2 h-64 w-fit rounded-md object-contain"
              style={{ height: `${localHeight ?? block.content.height}px` }}
              controls
            />
          )}
          {block.type === WorkInstructionBlockType.PDF && (
            <div className="h-64 w-full overflow-hidden rounded-md py-2" style={{ height: `${localHeight ?? block.content.height}px` }}>
              <iframe src={downloadUrl} width="100%" height="100%" />
            </div>
          )}
          {block.type === WorkInstructionBlockType.File && (
            <div className="flex w-full justify-between py-2">
              <div className="font-semibold">{block.content.file_name}</div>
              <button
                className="btn-xs serial-btn-light"
                onClick={() => sentBlobToDownloadsFolder(downloadUrl, block.content.file_name, true)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
            </div>
          )}
        </div>
      )}
      {block.content.caption !== undefined && (
        <div className="flex justify-center gap-x-1">
          {draftProcess?.show_media_numbering && <span className="text-right text-sm font-bold">{preCaptionNumbering}</span>}
          <ContentEditable
            contentRef={contentRef}
            onBlur={() => handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "caption", contentRef.current?.innerText, true)}
            className="whitespace-fill text-left text-sm font-medium outline-0"
            containerClassName="relative w-fit"
            onKeyDown={handleKeyDown}
            placeholder="Caption"
            content={block.content.caption ?? ""}
            readOnly={readOnly}
          />
        </div>
      )}
      {!(block.type === WorkInstructionBlockType.File && block.content.caption === undefined) && !readOnly && (
        <button
          className={`btn-sm absolute bottom-2 right-2 z-0 opacity-0 group-hover:opacity-100 ${block.content.caption === undefined && "serial-btn-light"}`}
          onClick={() =>
            handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "caption", block.content.caption === undefined ? "" : undefined, true)
          }
        >
          <FontAwesomeIcon icon={block.content.caption === undefined ? faWindowMaximize : faTimes} className="rotate-180" />
        </button>
      )}
      {block.type !== WorkInstructionBlockType.File && !readOnly && (
        <div className="absolute -bottom-[18px] left-0 flex w-full justify-center">
          <button
            className="btn-xs serial-btn-light z-10 cursor-ns-resize opacity-0 hover:opacity-100 group-hover:opacity-100"
            onMouseDown={(e) => handleResizeMouseDown(e)}
          >
            <FontAwesomeIcon icon={faGripLines} size="sm" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlockFileGeneric;
