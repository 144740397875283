import { fetchLargeTable } from "@shared/connections/supabaseGeneral";
import { ProcessEntry } from "@shared/types/databaseTypes";

export const fetchAllProcessEntries = async (processId: string, uniqueIdentiferId: string) => {
  const allProcessEntries = await fetchLargeTable<ProcessEntry>(
    "process_entries",
    "id",
    "*, operator:operator_id(*), station:station_id(*)",
    [
      { key: "process_id", modifier: "eq", value: processId },
      { key: "unique_identifier_id", modifier: "eq", value: uniqueIdentiferId },
      { key: "upload_error", modifier: "eq", value: "FALSE" },
    ],
  );

  return [...allProcessEntries].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
};
