import { Field, FieldType } from "@shared/types/databaseTypes";
import { ProcessBuilderDraggableType } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { useContext, useMemo, useRef } from "react";
import type { Identifier } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import { v4 as uuidv4 } from "uuid";
import ProcessBuilderFieldCheckbox from "./form-fields/ProcessBuilderFieldCheckbox";
import ProcessBuilderFieldImage from "./form-fields/ProcessBuilderFieldImage";
import ProcessBuilderFieldFile from "./form-fields/ProcessBuilderFieldFile";
import ProcessBuilderFieldPassFail from "./form-fields/ProcessBuilderFieldPassFail";
import ProcessBuilderFieldManualEntry from "./form-fields/ProcessBuilderFieldManualEntry";
import ProcessBuilderFieldDatetime from "./form-fields/ProcessBuilderFieldDatetime";
import ProcessBuilderFieldLink from "./form-fields/ProcessBuilderFieldLink";
import ProcessBuilderFieldSignature from "./form-fields/ProcessBuilderFieldSignature";
import ProcessBuilderFieldEditDropdown from "./ProcessBuilderFieldEditDropdown";
import ProcessBuilderFieldLabel from "./form-fields/ProcessBuilderLabel";

interface ProcessBuilderFieldProps {
  allFields: Field[];
  field?: Field;
  stepIndex: number;
  isPlaceholder?: boolean;
  allowDrag?: boolean;
}
const ProcessBuilderField: React.FC<ProcessBuilderFieldProps> = ({ allFields, field, stepIndex, isPlaceholder, allowDrag = true }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { readOnly, hoveredFieldId, setHoveredFieldId, handleMoveFields } = useContext(ProcessBuilderContext);
  const fieldId = useMemo(() => field?.id ?? uuidv4(), [field]);
  const currFieldIndex = useMemo(() => allFields.findIndex((field) => field.id === fieldId), [allFields, fieldId]);

  // Override allowDrag if readOnly is true
  allowDrag = allowDrag && !readOnly;

  const [{ handlerId }, drop] = useDrop<Field, void, { handlerId: Identifier | null }>({
    accept: ProcessBuilderDraggableType.Field,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: Field, _monitor) {
      if (!ref.current) {
        return;
      }
      const targetFieldIndex = allFields.findIndex((field) => field.id === fieldId);
      handleMoveFields([item.id], stepIndex, targetFieldIndex, field?.group_name);
    },
    hover() {
      setHoveredFieldId(fieldId);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ProcessBuilderDraggableType.Field,
    item: () => {
      if (isPlaceholder || !field)
        return {
          id: fieldId,
        };
      return { ...field };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setHoveredFieldId(null);
    },
  });

  drop(ref);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={`group relative flex w-full items-start ${isPlaceholder && "min-h-[48px]"} ${isDragging && "opacity-40"}`}
    >
      {!isPlaceholder && allowDrag && !readOnly && (
        <button tabIndex={-1} className="absolute top-1 flex" ref={drag}>
          <FontAwesomeIcon
            className="text-serial-palette-400 hover:text-serial-palette-600 hidden group-hover:flex"
            icon={faGripVertical}
            size="sm"
          />
        </button>
      )}
      {field && !readOnly && (
        <div
          className={`absolute right-1 ${[FieldType.File, FieldType.Image, FieldType.Signature, FieldType.Label].includes(field.type) ? "-top-7" : "top-0"}`}
        >
          <ProcessBuilderFieldEditDropdown stepIndex={stepIndex} fieldIndex={currFieldIndex} field={field} />
        </div>
      )}
      {!isPlaceholder && field && (
        <div
          ref={preview}
          className={`flex min-w-0 flex-grow flex-col transition-all ${allowDrag ? "mx-2 group-hover:ml-3.5 group-hover:mr-5" : "mx-2"}`}
        >
          {field.type === FieldType.Checkbox && <ProcessBuilderFieldCheckbox field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.Image && <ProcessBuilderFieldImage field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.File && <ProcessBuilderFieldFile field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.PassFail && <ProcessBuilderFieldPassFail field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.ManualEntry && <ProcessBuilderFieldManualEntry field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.Datetime && <ProcessBuilderFieldDatetime field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.Link && <ProcessBuilderFieldLink field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.Signature && <ProcessBuilderFieldSignature field={field} stepIndex={stepIndex} />}
          {field.type === FieldType.Label && <ProcessBuilderFieldLabel field={field} stepIndex={stepIndex} />}
        </div>
      )}
      {fieldId === hoveredFieldId && <div className="border-serial-palette-100 absolute -bottom-1.5 w-full border-b-4" />}
    </div>
  );
};

export default ProcessBuilderField;
