import TypeTag from "@shared/components/TypeTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircle as faCircleSolid } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import useFieldGroups, { FieldGroup } from "../hooks/useFieldGroups";
import { useContext, useEffect, useMemo, useRef } from "react";
import { ProductionContext } from "../ProductionProvider";
import { FieldType } from "@shared/types/databaseTypes";

interface ProductionFormChecklistProps {
  showChecklist: boolean;
  setShowChecklist: (showChecklist: boolean) => void;
}

const ProductionFormChecklist: React.FC<ProductionFormChecklistProps> = ({ showChecklist, setShowChecklist }) => {
  const { draftSubmission, filteredSteps, setSelectedStepIndex } = useContext(ProductionContext);
  const fields = useMemo(() => filteredSteps.flatMap((step) => step.fields), [filteredSteps]);
  const allFieldGroups = useFieldGroups({ fields });
  const checklistRef = useRef<HTMLDivElement>(null);

  const fieldGroupsByStep = useMemo(() => {
    const fieldGroupsByStep: FieldGroup[][] = [];
    filteredSteps.forEach((step) => {
      const stepFieldGroups = allFieldGroups.filter((group) => group.fields.some((field) => step.fields.includes(field)));
      fieldGroupsByStep.push(stepFieldGroups);
    });
    return fieldGroupsByStep;
  }, [filteredSteps, allFieldGroups]);

  // close on click outside
  useEffect(() => {
    if (!showChecklist) return;
    const clickHandler = ({ target }: any) => {
      if (checklistRef.current?.contains(target)) return;
      setShowChecklist(false);
    };
    setTimeout(() => document.addEventListener("click", clickHandler), 0);
    return () => document.removeEventListener("click", clickHandler);
  }, [showChecklist]);

  return (
    <div ref={checklistRef} className="flex h-fit flex-col rounded border bg-white shadow-xl">
      <div className="flex h-12 w-full items-center justify-between border-b pl-5 pr-3">
        <div className="text-serial-palette-800 font-semibold">Data Validation</div>
        <button tabIndex={-1} onClick={() => setShowChecklist(false)}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      <div className="flex flex-col gap-y-2 px-5 py-3">
        {fieldGroupsByStep.map((fieldGroups, stepIndex) => {
          return (
            <div key={stepIndex} className="flex flex-col gap-y-1.5">
              <div className="text-serial-palette-500 flex w-full text-sm font-bold">Step {stepIndex + 1}:</div>
              {fieldGroups.length === 0 && (
                <div
                  className="text-serial-palette-500 flex w-full cursor-pointer pl-2.5 italic"
                  onClick={() => setSelectedStepIndex(stepIndex)}
                >
                  No data collection
                </div>
              )}
              {fieldGroups.map((group, index) => {
                let checklistHeading = "";
                // Add checklist headings depending on the type of data being acquired
                switch (group.type) {
                  case FieldType.File:
                    checklistHeading = "File upload";
                    break;
                  case FieldType.Image:
                    checklistHeading = "Image upload";
                    break;
                  case FieldType.Signature:
                    checklistHeading = "Signature";
                    break;
                  case FieldType.Link:
                    checklistHeading = "Linked component";
                    break;
                  case FieldType.PassFail:
                    checklistHeading = "Pass / Fail";
                    break;
                  case FieldType.ManualEntry:
                    checklistHeading = "Manual Entry";
                    break;
                  case FieldType.Checkbox:
                    checklistHeading = "Checkbox";
                    break;
                }
                const groupIsAllValid = group.fields
                  .filter((field) => draftSubmission[field.id] !== undefined)
                  .every((field) => draftSubmission[field.id]?.isValid === true);
                const groupHasAnyInvalid = group.fields.some((field) => draftSubmission[field.id]?.isValid === false);
                const groupHasAnyEmpty = group.fields.some((field) => draftSubmission[field.id] == undefined);
                const groupIsAllOptional = group.fields.every((field) => field.is_optional === true);
                const groupRequiredFieldsAreNotEmpty = group.fields
                  .filter((field) => field.is_optional === false)
                  .every((field) => draftSubmission[field.id] !== undefined);
                return (
                  <div
                    key={index}
                    className="flex w-[310px] cursor-pointer items-center overflow-clip pl-2.5"
                    onClick={() => {
                      setSelectedStepIndex(stepIndex);
                      setShowChecklist(false);
                    }}
                  >
                    {groupIsAllValid && groupRequiredFieldsAreNotEmpty ? (
                      <FontAwesomeIcon className="text-green-600" size="lg" icon={faCircleSolid} />
                    ) : groupHasAnyInvalid ? (
                      <FontAwesomeIcon className="text-red-600" size="lg" icon={faCircleSolid} />
                    ) : groupHasAnyEmpty && !groupIsAllOptional ? (
                      <FontAwesomeIcon className="text-red-300" size="lg" icon={faCircle} />
                    ) : (
                      <FontAwesomeIcon className="text-serial-palette-300" size="lg" icon={faCircle} />
                    )}
                    <div className="ml-3 w-7 font-bold">{index + 1}.</div>
                    <div className="text-md truncate">{checklistHeading}</div>
                    <TypeTag className="ml-3 text-xs" type={group.type} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductionFormChecklist;
