import React, { useMemo, useState } from "react";
import { SnLookupTimelineType, SnLookupTimelineMonthGroup, SnLookupTimelineProcessEntryGroup, SnLookupTimeline } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faGear } from "@fortawesome/free-solid-svg-icons";
import SnLookUpTableTimelineItem from "./SnLookUpTimelineItem";
import UserAvatar from "@shared/components/UserAvatar";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";

interface SnLookUpTimelineMonthProps {
  monthGroup: SnLookupTimelineMonthGroup;
  oldestTimestamp: string;
}

const SnLookUpTimelineMonth: React.FC<SnLookUpTimelineMonthProps> = ({ monthGroup, oldestTimestamp }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const isLastMonth = monthGroup.activity.find((activity) => activity.timestamp === oldestTimestamp) !== undefined;
  const users = useSelector((state: RootState) => state.db.users);

  const squashedMonthGroup = useMemo(() => {
    // squash adjacent process activities that have activity.type === SnLookupTimelineType.ProcessEntryGroup and also have the same operator and occured on the same day
    const monthGroupCopy = JSON.parse(JSON.stringify(monthGroup)) as SnLookupTimelineMonthGroup; // make a copy so we don't mutate the original in the reducer
    const squashedMonthGroupTimeline = monthGroupCopy.activity.reduce<SnLookupTimeline[]>((acc, activity) => {
      const lastTimeline = acc[acc.length - 1];
      if (
        lastTimeline &&
        lastTimeline.type === SnLookupTimelineType.ProcessEntryGroup &&
        activity.type === SnLookupTimelineType.ProcessEntryGroup &&
        new Date(lastTimeline.timestamp).getDate() === new Date(activity.timestamp).getDate() &&
        (lastTimeline.operatorId === activity.operatorId || lastTimeline.userId === activity.userId)
      ) {
        (lastTimeline as SnLookupTimelineProcessEntryGroup).processEntries.push(
          ...(activity as SnLookupTimelineProcessEntryGroup).processEntries,
        );
      } else {
        acc.push(activity);
      }
      return acc;
    }, []);

    return { ...monthGroup, activity: squashedMonthGroupTimeline };
  }, [monthGroup]);

  return (
    <div key={monthGroup.month} className="relative flex flex-col gap-y-3">
      {!(!isExpanded && isLastMonth) && <div className="border-serial-palette-300 absolute -top-6 left-[18px] z-0 h-16 border-l" />}
      <div className="flex w-full justify-between">
        <div className="text-serial-palette-600 bg-serial-palette-100 z-10 w-fit rounded-md border px-1 text-sm ring ring-white">
          {monthGroup.month}
        </div>
        <div className="mx-3 h-3 w-full border-b" />
        <button onClick={() => setIsExpanded(!isExpanded)}>
          <FontAwesomeIcon className="w-6 bg-white" size="sm" icon={isExpanded ? faChevronDown : faChevronLeft} />
        </button>
      </div>
      {isExpanded &&
        squashedMonthGroup.activity.map((activity, index) => {
          const isLastTimeline = activity.timestamp === oldestTimestamp;
          const user = users.find((u) => u.supabase_uid === activity.userId);
          return (
            <div key={index} className="relative ml-1 flex w-full gap-x-2 ">
              {!isLastTimeline && <div className="border-serial-palette-300 absolute left-[14px] top-4 z-0 h-full border-l" />}
              <div className="bg-serial-palette-500 z-10 mt-1 flex h-8 w-8 items-center justify-center rounded-full border text-white ring ring-white">
                {activity.type === SnLookupTimelineType.Property && <UserAvatar user={user} size="sm" />}
                {activity.type === SnLookupTimelineType.Creation && <FontAwesomeIcon size="sm" icon={faGear} />}
                {activity.type === SnLookupTimelineType.Comment && <UserAvatar user={user} size="sm" />}
              </div>
              <div className="flex w-full rounded-md border bg-white">
                <SnLookUpTableTimelineItem activity={activity} user={user} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SnLookUpTimelineMonth;
