import { useContext, useEffect, useState } from "react";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState(document.body.classList.contains("dark"));
  const observe = useContext(ObservabilityContext);

  const toggleDarkMode = () => {
    document.body.classList.toggle("dark");
    if (document.body.classList.contains("dark")) {
      observe.track("Toggle Dark Mode", { state: "on" });
      localStorage.setItem("darkMode", "true");
    } else {
      observe.track("Toggle Dark Mode", { state: "off" });
      localStorage.setItem("darkMode", "false");
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setDarkMode(document.body.classList.contains("dark"));
    });
    observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });
    return () => observer.disconnect();
  }, []);

  return {
    darkMode,
    toggleDarkMode,
  };
};

export default useDarkMode;
