import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";
import { setOperators } from "@shared/redux/db";
import { supabaseSingleOverride } from "./supabaseDataModifiers";

// ------------------- READ FUNCTIONS ------------------- //

// Fetch company data from backend
export const fetchUsers = async () => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    let { data: usersData, errorUser } = await supabase.from("users").select("*");

    // Handle database error, else write to redux
    if (errorUser || usersData.length === 0) {
      console.log(errorUser);
    } else {
      // Get list of available files to download
      const { data: userImageList } = await supabase.storage.from("user-avatars").list(usersData[0].company_id);
      // Iterate through components and get image for each
      await Promise.all(
        usersData.map(async (user, index) => {
          if (userImageList.map((listItem) => listItem.name).includes(user.supabase_uid)) {
            const { data: imageData, error: errorImage } = await supabase.storage
              .from("user-avatars")
              .download(`${user.company_id}/${user.supabase_uid}`);
            if (errorImage) {
              console.log(errorImage);
            } else {
              usersData[index].url = URL.createObjectURL(imageData);
            }
          }
        }),
      );
      return usersData;
    }
  } catch (error) {
    // Handle request error
    console.error(error);
    return null;
  }
};

export const fetchOperators = async () => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let { data: operatorData, error: operatorError } = await supabase.from("operators").select("*").order("first_name", { ascending: true });

  // Handle database error, else write to redux
  if (operatorError) {
    console.log(operatorError);
    return null;
  }
  store.dispatch(setOperators(operatorData));
};

export const fetchOperatorByUserId = async (userId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let { data: operatorData, error: operatorError } = supabaseSingleOverride(
    await supabase.from("operators").select("*").eq("user_id", userId),
  );

  // Handle database error, else write to redux
  if (operatorError) {
    return null;
  }
  return operatorData;
};

export const fetchOperatorByPin = async (pin) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  let { data: operatorData, error: operatorError } = supabaseSingleOverride(await supabase.from("operators").select("*").eq("pin", pin));

  // Handle database error, else write to redux
  if (operatorError) {
    console.log(operatorError);
    return [];
  }
  return operatorData;
};

// ------------------- WRITE FUNCTIONS ------------------ //
// Update user avatar
export const upsertUserAvatar = async (userUrl, userId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    const response = await fetch(userUrl);
    const blob = await response.blob();
    const { data: uploadedFile, error: errorFileUpload } = await supabase.storage
      .from("user-avatars")
      .upload(`${state.db.company.id}/${userId}`, blob, { upsert: true });
    if (errorFileUpload) {
      console.log(errorFileUpload);
    }
    return uploadedFile;
  } catch (error) {
    // Handle request error
    console.error(error);
    return null;
  }
};

export const createOperatorBasedOnUser = async (userData) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const operatorPayload = {
    first_name: userData.first_name,
    last_name: userData.last_name,
    is_active: userData.is_active,
    company_id: userData.company_id,
    pin: null,
    user_id: userData.supabase_uid,
  };
  const { error } = await supabase.from("operators").insert([operatorPayload]).select();
  if (error) {
    console.log("Error creating operator: ");
    console.error(error);
  } else {
    // Refresh Operators
    fetchOperators();
  }
};

export const updateOperatorBasedOnUser = async (userData) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const operatorPayload = {
    first_name: userData.first_name,
    last_name: userData.last_name,
    is_active: userData.is_active,
  };

  const { error } = await supabase.from("operators").update(operatorPayload).eq("user_id", userData.supabase_uid).select();
  if (error) {
    console.log("Error updating operator: ");
    console.error(error);
  } else {
    // Refresh Operators
    fetchOperators();
  }
};

export const upsertOperator = async (operatorData) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  // New component payload
  const operatorPayload = {
    company_id: state.db.company.id,
    first_name: operatorData.first_name,
    last_name: operatorData.last_name,
    pin: operatorData.pin,
    is_active: true,
    // is_active, created_at, are all set automatically
  };

  if (operatorData.id !== "new") {
    operatorPayload.id = operatorData.id;
  }

  const { data: upsertedOperator, error } = await supabase.from("operators").upsert([operatorPayload]).select().single();

  // Handle database error, else return upserted part number
  if (error) {
    console.log(error);
    return null;
  }
  return upsertedOperator;
};

// Allow process link and field deletion only if there are no process entries for that process
const checkAllowDeleteOperator = async (operatorId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { data: firstOccurance } = await supabase.from("process_entries").select("*").eq("operator_id", operatorId).limit(1);

  if (firstOccurance.length === 0) {
    return true;
  }
  return false;
};

export const deleteOperator = async (operatorId) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const allowDeleteOperator = await checkAllowDeleteOperator(operatorId);
  if (allowDeleteOperator) {
    const { error: deleteOperatorError } = await supabase.from("operators").delete().eq("id", operatorId);
    if (deleteOperatorError) {
      console.log("Error deleting operator");
      console.log(deleteOperatorError);
      return false;
    }
  }
  return allowDeleteOperator;
};

export const setOperatorActivity = async (operatorId, isActive) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const { error: updateOperatorError } = await supabase.from("operators").update({ is_active: isActive }).eq("id", operatorId);
  if (updateOperatorError) {
    console.log("Error updating operator activity");
    console.error(updateOperatorError);
  }
};
