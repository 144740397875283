import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faExclamationTriangle,
  faHeading,
  faInfoCircle,
  faSkullCrossbones,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { WorkInstructionBlock, WorkInstructionBlockType } from "@shared/types/databaseTypes";

interface ProcessBuilderWorkInstructionEditDropdownProps {
  stepIndex: number;
  workInstructionBlockIndex: number;
  workInstructionBlock: WorkInstructionBlock;
}

const ProcessBuilderWorkInstructionEditDropdown: React.FC<ProcessBuilderWorkInstructionEditDropdownProps> = ({
  stepIndex,
  workInstructionBlockIndex,
  workInstructionBlock,
}) => {
  const { handleDeleteWorkInstructionBlock, handleUpdateWorkInstructionBlocks } = useContext(ProcessBuilderContext);

  return (
    <DropdownCustom
      align="right"
      className="flex h-full"
      dropdownClassName="pb-1.5 w-48"
      closeOnMouseLeave={true}
      keepOpen={true}
      buttonContents={
        <div tabIndex={-1} className="text-serial-palette-400 hover:text-serial-palette-600 w-4 justify-center">
          <FontAwesomeIcon className="hidden group-hover:flex" icon={faEllipsisV} />
        </div>
      }
    >
      {workInstructionBlock.type === WorkInstructionBlockType.Heading && (
        <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
          <div className="">Size</div>
          <div className="flex">
            <button
              className={`btn-sm rounded-r-none ${workInstructionBlock.content.level === 1 ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateWorkInstructionBlocks(stepIndex, [workInstructionBlock.id], "level", 1, true)}
            >
              <FontAwesomeIcon icon={faHeading} />
            </button>
            <button
              className={`btn-sm rounded-none border-x-0 ${workInstructionBlock.content.level === 2 ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateWorkInstructionBlocks(stepIndex, [workInstructionBlock.id], "level", 2, true)}
            >
              <FontAwesomeIcon icon={faHeading} size="sm" />
            </button>
            <button
              className={`btn-sm rounded-l-none ${workInstructionBlock.content.level === 3 ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateWorkInstructionBlocks(stepIndex, [workInstructionBlock.id], "level", 3, true)}
            >
              <FontAwesomeIcon icon={faHeading} size="xs" />
            </button>
          </div>
        </div>
      )}
      {workInstructionBlock.type === WorkInstructionBlockType.Callout && (
        <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
          <div className="">Type</div>
          <div className="flex">
            <button
              className={`btn-sm rounded-r-none ${workInstructionBlock.content.icon === "DANGER" ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateWorkInstructionBlocks(stepIndex, [workInstructionBlock.id], "icon", "DANGER", true)}
            >
              <FontAwesomeIcon icon={faSkullCrossbones} />
            </button>
            <button
              className={`btn-sm rounded-none border-x-0 ${workInstructionBlock.content.icon === "WARNING" ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateWorkInstructionBlocks(stepIndex, [workInstructionBlock.id], "icon", "WARNING", true)}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} size="sm" />
            </button>
            <button
              className={`btn-sm rounded-l-none ${workInstructionBlock.content.icon === "INFO" ? "serial-btn-dark" : "serial-btn-light"}`}
              onClick={() => handleUpdateWorkInstructionBlocks(stepIndex, [workInstructionBlock.id], "icon", "INFO", true)}
            >
              <FontAwesomeIcon icon={faInfoCircle} size="xs" />
            </button>
          </div>
        </div>
      )}
      {workInstructionBlock.type === WorkInstructionBlockType.List && (
        <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
          <div className="">Numbered List</div>
          <div className="form-switch-sm">
            <input
              type="checkbox"
              id={`process-builder-switch-list-type-${workInstructionBlock.id}`}
              className="sr-only"
              checked={workInstructionBlock.content.listType === "NUMBERED"}
              onChange={() =>
                handleUpdateWorkInstructionBlocks(
                  stepIndex,
                  [workInstructionBlock.id],
                  "listType",
                  workInstructionBlock.content.listType === "NUMBERED" ? "BULLETED" : "NUMBERED",
                  true,
                )
              }
            />
            <label className="bg-serial-palette-200" htmlFor={`process-builder-switch-list-type-${workInstructionBlock.id}`}>
              <span className="bg-white " aria-hidden="true"></span>
              <span className="sr-only">Switch label</span>
            </label>
          </div>
        </div>
      )}
      {(WorkInstructionBlockType.Video === workInstructionBlock.type ||
        WorkInstructionBlockType.Image === workInstructionBlock.type ||
        WorkInstructionBlockType.File === workInstructionBlock.type ||
        WorkInstructionBlockType.PDF === workInstructionBlock.type) && (
        <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
          <div className="">Caption</div>
          <div className="form-switch-sm">
            <input
              type="checkbox"
              id={`process-builder-switch-caption-${workInstructionBlock.id}`}
              className="sr-only"
              checked={workInstructionBlock.content.caption !== undefined}
              onChange={() =>
                handleUpdateWorkInstructionBlocks(
                  stepIndex,
                  [workInstructionBlock.id],
                  "caption",
                  workInstructionBlock.content.caption === undefined ? "" : undefined,
                  true,
                )
              }
            />
            <label className="bg-serial-palette-200" htmlFor={`process-builder-switch-caption-${workInstructionBlock.id}`}>
              <span className="bg-white " aria-hidden="true"></span>
              <span className="sr-only">Switch label</span>
            </label>
          </div>
        </div>
      )}
      {workInstructionBlock.type === WorkInstructionBlockType.Table && (
        <div className="flex flex-col">
          <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
            <div className="">Header Row</div>
            <div className="form-switch-sm">
              <input
                type="checkbox"
                id={`process-builder-switch-header-row-${workInstructionBlock.id}`}
                className="sr-only"
                checked={workInstructionBlock.content.header_row}
                onChange={() =>
                  handleUpdateWorkInstructionBlocks(
                    stepIndex,
                    [workInstructionBlock.id],
                    "header_row",
                    !workInstructionBlock.content.header_row,
                    true,
                  )
                }
              />
              <label className="bg-serial-palette-200" htmlFor={`process-builder-switch-header-row-${workInstructionBlock.id}`}>
                <span className="bg-white " aria-hidden="true"></span>
                <span className="sr-only">Switch label</span>
              </label>
            </div>
          </div>
          <div className="text-serial-palette-600 mb-2 flex w-full items-center justify-between gap-2 px-2 py-1 text-sm font-medium">
            <div className="">Header Column</div>
            <div className="form-switch-sm">
              <input
                type="checkbox"
                id={`process-builder-switch-header-col-${workInstructionBlock.id}`}
                className="sr-only"
                checked={workInstructionBlock.content.header_column}
                onChange={() =>
                  handleUpdateWorkInstructionBlocks(
                    stepIndex,
                    [workInstructionBlock.id],
                    "header_column",
                    !workInstructionBlock.content.header_column,
                    true,
                  )
                }
              />
              <label className="bg-serial-palette-200" htmlFor={`process-builder-switch-header-col-${workInstructionBlock.id}`}>
                <span className="bg-white " aria-hidden="true"></span>
                <span className="sr-only">Switch label</span>
              </label>
            </div>
          </div>
        </div>
      )}
      <button
        className="hover:bg-serial-palette-50 flex w-full cursor-pointer items-center gap-2 px-2 py-1 text-sm font-medium text-rose-500"
        onClick={() => handleDeleteWorkInstructionBlock(stepIndex, workInstructionBlockIndex)}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="text-rose-500" />
        <div className="">Delete</div>
      </button>
    </DropdownCustom>
  );
};

export default ProcessBuilderWorkInstructionEditDropdown;
