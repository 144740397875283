import React, { useContext, useEffect, useMemo, useState } from "react";
import { WorkOrderStatus } from "@shared/types/databaseEnums";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { useSelector } from "react-redux";
import { Component, PartNumber, UniqueIdentifier, User, WorkOrder } from "@shared/types/databaseTypes";
import DropdownClassic from "@shared/components/dropdowns/DropdownClassic";
import { RootState } from "@shared/redux/store";
import { WorkOrderContext } from "../WorkOrders";
import { Loader } from "@shared/components/Loader";
import { UserRole } from "@shared/types/databaseEnums";
import WorkOrderProgressBar from "./WorkOrderProgressBar";
import { CountsByStatus } from "src/features/production-app/types";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";
import { useTranslation } from "react-i18next";

interface WorkOrderOverviewProps {
  workOrder: WorkOrder;
  unitList: UniqueIdentifier[];
}

const WorkOrderOverview: React.FC<WorkOrderOverviewProps> = ({ workOrder }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const components = useSelector((state: RootState) => state.db.components);
  const users = useSelector((state: RootState) => state.db.users);
  const [workOrderLocalCopy, setWorkOrderLocalCopy] = useState<WorkOrder>(workOrder);
  const activeUsers = users.filter((user) => user.is_active === true);
  const partNumbers = useSelector((state: RootState) => state.db.partNumbers).filter(
    (pn: PartNumber) => pn.component_id === workOrderLocalCopy.component_id,
  );
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const context = useContext(WorkOrderContext);
  const observe = useContext(ObservabilityContext);
  const role = useSelector((state: RootState) => state.auth.role);
  const { t } = useTranslation();

  const isNew = useMemo(() => {
    return location.pathname.includes("/workorder/new");
  }, [location.pathname]);

  const handleUpsertWorkOrder = async () => {
    setIsLoading(true);
    if (isNew) {
      await context?.handleCreateWorkOrder(workOrderLocalCopy);
    } else {
      await context?.handleUpdateWorkOrder(workOrderLocalCopy);
    }
    setIsLoading(false);
  };

  let workOrderStatusCounts = useMemo<CountsByStatus>(() => {
    const statusCounts: CountsByStatus = {
      [UniqueIdentifierStatus.Planned]: 0,
      [UniqueIdentifierStatus.Wip]: 0,
      [UniqueIdentifierStatus.Complete]: 0,
      [UniqueIdentifierStatus.Defective]: 0,
    };

    // Filter instances by the given work_order_id
    const filteredInstances = componentInstances.filter((instance) => instance.work_order_id === workOrder.id);

    // Count the occurrences of each status
    filteredInstances.forEach((instance) => {
      if (instance.status !== null && statusCounts.hasOwnProperty(instance.status)) {
        statusCounts[instance.status]++;
      }
    });

    return statusCounts;
  }, [workOrder, componentInstances]);

  useEffect(() => {
    setWorkOrderLocalCopy(workOrder);
  }, [workOrder.status]);

  return (
    <div className="border-serial-palette-200 flex w-full flex-col justify-between rounded-md border bg-white">
      <header className="flex justify-between border-b py-2.5 pl-4 pr-2">
        <h2 className="text-serial-palette-800 flex items-center gap-1 pr-2 font-semibold">
          <span>Details</span>
          <span className="text-serial-palette-400 pl-1 pr-2 font-medium">
            {workOrderLocalCopy.wo_number ? `${t("workOrderPrefix")}-${workOrderLocalCopy.wo_number}` : `New ${t("workOrder")}`}
          </span>
        </h2>
        <div className="flex h-full flex-nowrap items-center gap-x-2">
          {role === UserRole.Admin && workOrderLocalCopy.status === WorkOrderStatus.Draft && (
            <button
              className="btn-sm bg-serial-palette-800 hover:bg-serial-palette-600 h-[30px] text-white"
              onClick={() => (handleUpsertWorkOrder(), observe.track("work-order_update-details"))}
            >
              {isLoading ? (
                <Loader styleOverride="w-[16px] h-[16px]" />
              ) : (
                <span className="xs:block hidden">{isNew ? `Publish ${t("workOrder")}` : "Save Details"}</span>
              )}
            </button>
          )}
        </div>
      </header>

      <div className="flex h-full w-full gap-6 p-5 ">
        <div className="hidden h-56 w-56 shrink-0 items-center justify-center overflow-hidden rounded-lg md:flex">
          <ComponentAvatar
            tooltip={false}
            component={components.find((c: Component) => c.id === workOrderLocalCopy.component_id)}
            size="xl"
          />
        </div>
        <div className="flex w-full flex-col gap-2 overflow-x-clip">
          <div className="flex w-full items-center justify-between">
            <div className="text-serial-palette-400 w-32 text-sm font-bold">
              <span>{t("workOrder")} Name </span>
              {workOrderLocalCopy.status === WorkOrderStatus.Draft && <span className="text-rose-500">*</span>}
            </div>
            <input
              className={`form-input m-0 w-full truncate border px-2 py-1 text-sm ${workOrderLocalCopy.status !== WorkOrderStatus.Draft && "bg-serial-palette-50"}`}
              disabled={workOrderLocalCopy.status !== WorkOrderStatus.Draft}
              placeholder={`Enter ${t("workOrder").toLowerCase()} name...`}
              onChange={(e) => setWorkOrderLocalCopy({ ...workOrderLocalCopy, name: e.target.value })}
              value={workOrderLocalCopy.name}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="text-serial-palette-400 w-32 text-sm font-bold">
              <span>Quantity </span>
              {workOrderLocalCopy.status === WorkOrderStatus.Draft && <span className="text-rose-500">*</span>}
            </div>
            <input
              type="number"
              min="1"
              step="1"
              className={`form-input m-0 w-full truncate border px-2 py-1 text-sm ${workOrderLocalCopy.status !== WorkOrderStatus.Draft && "bg-serial-palette-50"}`}
              disabled={workOrderLocalCopy.status !== WorkOrderStatus.Draft}
              placeholder="Enter input quantity"
              onChange={(e) => setWorkOrderLocalCopy({ ...workOrderLocalCopy, quantity: Number(e.target.value) })}
              value={workOrderLocalCopy.quantity === 0 ? "" : workOrderLocalCopy.quantity}
              onClick={(e) => (e.target as HTMLInputElement).select()}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="text-serial-palette-400 w-32 text-sm font-bold">
              <span>Component </span>
              {workOrderLocalCopy.status === WorkOrderStatus.Draft && <span className="text-rose-500">*</span>}
            </div>
            {workOrderLocalCopy.status === WorkOrderStatus.Draft ? (
              <DropdownClassic
                className="h-[32px] w-full"
                dropdownClassName="w-full"
                options={[
                  { id: null, label: "Select component..." },
                  ...components
                    .filter((c) => c.is_active)
                    ?.sort((a: Component, b: Component) => a.name.localeCompare(b.name))
                    ?.map((c: Component) => {
                      return { id: c.id, label: c.name };
                    }),
                ]}
                selected={workOrderLocalCopy.component_id || null}
                setSelected={(componentId: string) => setWorkOrderLocalCopy({ ...workOrderLocalCopy, component_id: componentId })}
              />
            ) : (
              <input
                className="form-input bg-serial-palette-50 m-0 h-[32px] w-full truncate border px-2 py-1 text-sm"
                value={components.find((c) => c.id === workOrderLocalCopy.component_id)?.name}
                disabled
              />
            )}
          </div>

          <div className="flex w-full items-center justify-between">
            <div className="text-serial-palette-400 w-32 text-sm font-bold">Part Number</div>
            {workOrderLocalCopy.status === WorkOrderStatus.Draft ? (
              <DropdownClassic
                className="h-[32px] w-full"
                options={[
                  { id: null, label: "Select part number..." },
                  ...partNumbers
                    .filter((p) => p.is_active)
                    ?.sort((a: PartNumber, b: PartNumber) => a.pn.localeCompare(b.pn))
                    ?.map((c: PartNumber) => {
                      return { id: c.id, label: c.pn };
                    }),
                ]}
                selected={workOrderLocalCopy.part_number_id || null}
                setSelected={(partNumberId: string) => setWorkOrderLocalCopy({ ...workOrderLocalCopy, part_number_id: partNumberId })}
              />
            ) : (
              <input
                className="form-input bg-serial-palette-50 m-0 h-[32px] w-full truncate border px-2 py-1 text-sm"
                value={partNumbers.find((pn) => pn.id === workOrderLocalCopy.part_number_id)?.pn}
                disabled
              />
            )}
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="text-serial-palette-400 w-32 text-sm font-bold">Phase</div>
            {workOrderLocalCopy.status === WorkOrderStatus.Draft ? (
              <input
                className={`form-input m-0 w-full truncate border px-2 py-1 text-sm ${workOrderLocalCopy.status !== WorkOrderStatus.Draft && "bg-serial-palette-50"}`}
                disabled={workOrderLocalCopy.status !== WorkOrderStatus.Draft}
                placeholder="Enter phase"
                onChange={(e) => setWorkOrderLocalCopy({ ...workOrderLocalCopy, phase: e.target.value })}
                value={workOrderLocalCopy.phase ?? ""}
              />
            ) : (
              <input
                className="form-input bg-serial-palette-50 m-0 h-[32px] w-full truncate border px-2 py-1 text-sm"
                value={workOrderLocalCopy.phase ?? ""}
                disabled
              />
            )}
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="text-serial-palette-400 w-32 text-sm font-bold">Assignee</div>
            {workOrderLocalCopy.status === WorkOrderStatus.Draft ? (
              <DropdownClassic
                className="h-[32px] w-full"
                options={[
                  { id: null, label: "Select assignee..." },
                  ...activeUsers.map((u: User) => {
                    return { id: u.supabase_uid, label: `${u.first_name} ${u.last_name}` };
                  }),
                ]}
                selected={workOrderLocalCopy.assignee_user_id || null}
                setSelected={(supabaseUid: string) => setWorkOrderLocalCopy({ ...workOrderLocalCopy, assignee_user_id: supabaseUid })}
              />
            ) : (
              <input
                className="form-input bg-serial-palette-50 m-0 h-[32px] w-full truncate border px-2 py-1 text-sm"
                value={
                  (users.find((u) => u.supabase_uid === workOrderLocalCopy.assignee_user_id)?.first_name ?? "") +
                  " " +
                  (users.find((u) => u.supabase_uid === workOrderLocalCopy.assignee_user_id)?.last_name ?? "")
                }
                disabled
              />
            )}
          </div>
        </div>
      </div>
      <div className="pl-5">
        <div className="flex-grow px-5 py-2">
          <WorkOrderProgressBar counts_by_status={workOrderStatusCounts} quantity={workOrderLocalCopy?.quantity} showLegend="full" />
        </div>
      </div>
    </div>
  );
};

export default WorkOrderOverview;
