import { useMemo, useState } from "react";
import { MeasureType, numericalRangeOperators } from "@shared/measures/types";
import useMeasures from "@shared/measures/MeasuresProvider";
import { isSameMeasureKey } from "@shared/measures/helpers/measureKeys";
import DatePicker from "@shared/components/DatePicker";

enum DropdownMode {
  Filters = "FILTERS",
  Values = "VALUES",
  Operators = "OPERATORS",
}

const MeasuresFilterBuilderValueSelector = ({
  focusedFilterConditionIndex,
  setSubDropdownMode,
}: {
  focusedFilterConditionIndex: number;
  setSubDropdownMode: (mode: DropdownMode | null) => void;
}) => {
  const { uniqueIdentifiers, filterBy, handleSetFilterConditionValue } = useMeasures();

  const [filterValueInputText, setFilterValueInputText] = useState<string>("");

  const focusedFilter = useMemo(() => {
    return filterBy[focusedFilterConditionIndex] ?? null;
  }, [focusedFilterConditionIndex, filterBy]);

  const suggestions = useMemo(() => {
    const focusedMeasureKey = focusedFilter?.key;
    if (!focusedMeasureKey) return [];

    const allSuggestions = uniqueIdentifiers.flatMap((identifier) => {
      return identifier.measures
        .filter((measure) => isSameMeasureKey(measure.key, focusedMeasureKey))
        .flatMap((measure) => {
          return Object.values(measure.valuesByIdentifier)
            .map((valuesWithAgg) => valuesWithAgg.aggregation)
            .flatMap((aggregation) => {
              return { value: aggregation.value, label: aggregation.formattedValue };
            });
        });
    });

    const uniqueSuggestions: { value: string; label: string }[] = [];
    allSuggestions.forEach((suggestion) => {
      if (!uniqueSuggestions.some((s) => s.value === suggestion.value)) {
        uniqueSuggestions.push(suggestion);
      }
    });

    return uniqueSuggestions;
  }, [uniqueIdentifiers, focusedFilter]);

  const filteredSuggestions = useMemo(() => {
    return suggestions.filter(
      (suggestion) => filterValueInputText === "" || suggestion.value.toLowerCase().includes(filterValueInputText.toLowerCase()),
    );
  }, [suggestions, filterValueInputText]);

  const handleUseInputAsFilterValue = () => {
    if (!focusedFilter) return;
    // if filter type is a numerical range filter and input is a valid number
    if (numericalRangeOperators.includes(focusedFilter.operator) && isNaN(Number(filterValueInputText))) return;
    handleSetFilterConditionValue(focusedFilterConditionIndex, filterValueInputText);
    setSubDropdownMode(null);
  };

  return (
    <>
      <div className="flex h-12 shrink-0 items-center border-b">
        {focusedFilter?.key.type === MeasureType.Timestamp || focusedFilter?.key.type === MeasureType.Datetime ? (
          <DatePicker
            className="h-full w-full rounded-none border-none"
            date={focusedFilter.value ? new Date(focusedFilter.value) : undefined}
            onDateChange={(date) => {
              date && handleSetFilterConditionValue(focusedFilterConditionIndex, date.toISOString());
            }}
          />
        ) : (
          <input
            className="form-input w-full border-none"
            placeholder="Enter value..."
            onChange={(e) => setFilterValueInputText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleUseInputAsFilterValue();
            }}
            value={filterValueInputText}
          />
        )}
      </div>
      {focusedFilter &&
        filteredSuggestions.map((suggestion, index) => {
          return (
            <button
              key={index}
              className={`btn-sm focus:bg-serial-palette-100 hover:bg-serial-palette-100 flex w-full cursor-pointer justify-start rounded-none bg-white focus:outline-none ${index === 0 && "mt-2"}`}
              onClick={() => {
                handleSetFilterConditionValue(focusedFilterConditionIndex, suggestion.value);
                setSubDropdownMode(null);
              }}
            >
              <span className="truncate whitespace-nowrap px-1 font-normal">{suggestion.label}</span>
            </button>
          );
        })}
    </>
  );
};

export default MeasuresFilterBuilderValueSelector;
