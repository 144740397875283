import { useEffect, useState } from "react";
import { Map, useControl, Marker, MarkerProps, ControlPosition } from "react-map-gl";
import MapboxGeocoder, { GeocoderOptions } from "@mapbox/mapbox-gl-geocoder";
import useDarkMode from "@shared/hooks/useDarkMode";
const MAPBOX_TOKEN = import.meta.env.VITE_APP_MAPBOX_TOKEN;

// This file is inspired by the demo code found on react-map-gl's examples page:
// https://visgl.github.io/react-map-gl/examples/geocoder

type GeocoderControlProps = Omit<GeocoderOptions, "accessToken" | "mapboxgl" | "marker"> & {
  mapboxAccessToken: string;
  marker?: boolean | Omit<MarkerProps, "longitude" | "latitude">;
  position: ControlPosition;
  onLoading?: (e: object) => void;
  onResults?: (e: object) => void;
  onResult?: (e: object) => void;
  onError?: (e: object) => void;
  onLocationChange?: (location: { latitude: number; longitude: number }) => void;
  location?: { latitude: number; longitude: number };
};

const GeocoderControl = (props: GeocoderControlProps) => {
  const [marker, setMarker] = useState<any>(null);

  useEffect(() => {
    if (props.location && props.marker) {
      const additionalMarkerProps = typeof props.marker === "object" ? props.marker : {};
      setMarker(<Marker {...additionalMarkerProps} longitude={props.location.longitude} latitude={props.location.latitude} />);
    }
  }, [location, props.marker]);

  // TODO: SER-1786
  // @ts-expect-error (TS2339) private member
  const geocoder = useControl<MapboxGeocoder>(
    () => {
      const ctrl = new MapboxGeocoder({
        ...props,
        marker: false,
        accessToken: props.mapboxAccessToken,
      });
      if (props.onLoading) ctrl.on("loading", props.onLoading);
      if (props.onResults) ctrl.on("results", props.onResults);
      ctrl.on("result", (evt) => {
        if (props.onResult) props.onResult(evt);

        const { result } = evt;
        const location = result && (result.center || (result.geometry?.type === "Point" && result.geometry.coordinates));
        if (location) {
          const newLocation = { latitude: location[1], longitude: location[0] };
          if (props.marker && typeof props.marker === "object") {
            setMarker(<Marker {...props.marker} longitude={location[0]} latitude={location[1]} />);
          }
          props.onLocationChange?.(newLocation);
        } else {
          setMarker(null);
        }
      });
      if (props.onError) ctrl.on("error", props.onError);
      return ctrl;
    },
    {
      position: props.position,
    },
  );

  // @ts-expect-error (TS2339) private member
  if (geocoder._map) {
    if (geocoder.getProximity() !== props.proximity && props.proximity !== undefined) {
      geocoder.setProximity(props.proximity);
    }
    if (geocoder.getRenderFunction() !== props.render && props.render !== undefined) {
      geocoder.setRenderFunction(props.render);
    }
    if (geocoder.getLanguage() !== props.language && props.language !== undefined) {
      geocoder.setLanguage(props.language);
    }
    if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
      geocoder.setZoom(props.zoom);
    }
    if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
      geocoder.setFlyTo(props.flyTo);
    }
    if (geocoder.getPlaceholder() !== props.placeholder && props.placeholder !== undefined) {
      geocoder.setPlaceholder(props.placeholder);
    }
    if (geocoder.getCountries() !== props.countries && props.countries !== undefined) {
      geocoder.setCountries(props.countries);
    }
    if (geocoder.getTypes() !== props.types && props.types !== undefined) {
      geocoder.setTypes(props.types);
    }
    if (geocoder.getMinLength() !== props.minLength && props.minLength !== undefined) {
      geocoder.setMinLength(props.minLength);
    }
    if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
      geocoder.setLimit(props.limit);
    }
    if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {
      geocoder.setFilter(props.filter);
    }
    if (geocoder.getOrigin() !== props.origin && props.origin !== undefined) {
      geocoder.setOrigin(props.origin);
    }
  }
  return marker;
};

const noop = () => {};

GeocoderControl.defaultProps = {
  marker: true,
  onLoading: noop,
  onResults: noop,
  onResult: noop,
  onError: noop,
};

const MapLocationSelector = ({
  location,
  onLocationChange,
}: {
  location?: {
    latitude: number;
    longitude: number;
  };
  onLocationChange?: (location: { latitude: number; longitude: number }) => void;
}) => {
  const { darkMode } = useDarkMode();

  return (
    <Map
      initialViewState={{
        latitude: location?.latitude || 40,
        longitude: location?.longitude || -100,
        zoom: location?.latitude && location?.longitude ? 10 : 1.5,
        bearing: 0,
        pitch: 0,
      }}
      mapStyle={`mapbox://styles/mapbox/${darkMode ? "dark" : "light"}-v11`}
      mapboxAccessToken={MAPBOX_TOKEN}
    >
      <GeocoderControl
        marker={{ color: darkMode ? "gray" : "black" }}
        location={location}
        mapboxAccessToken={MAPBOX_TOKEN}
        position="top-left"
        onLocationChange={onLocationChange}
      />
    </Map>
  );
};

export default MapLocationSelector;
