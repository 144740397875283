import { makeFetchRequest } from "@shared/connections/api/helpers";
import { ParsedResponse } from "@shared/types/apiTypes";
import { Process } from "@shared/types/databaseTypes";
import { GPTGeneratedStep } from "../types";
import { OpenaiVerboseTranscript } from "@shared/types/openai";
import { SerialProgrammingAssistantResponse } from "../types";

const API_URL = import.meta.env.VITE_APP_SERIAL_API_URL;

export const generateWorkInstructionsSuggestions = async (
  workInstructionsMarkdown: string,
): Promise<ParsedResponse<{ suggestions: string[] }>> => {
  return makeFetchRequest(
    `${API_URL}/ai/work-instructions/suggestions`,
    {
      method: "POST",
      body: JSON.stringify({
        work_instruction_markdown: workInstructionsMarkdown,
      }),
    },
    "Could not generate work instructions suggestions",
  );
};

export const generateWorkInstructionsFromVideo = async (
  vttTranscript: string,
  screenshots: {
    file_name: string;
    time: number;
    base64: string;
  }[],
  safetyFilter?: boolean,
): Promise<ParsedResponse<GPTGeneratedStep[]>> => {
  return makeFetchRequest(
    `${API_URL}/ai/work-instructions/video-to-instructions`,
    {
      method: "POST",
      body: JSON.stringify({
        vtt_transcript: vttTranscript,
        screenshots: screenshots,
        safety_filter: safetyFilter,
      }),
    },
    "Could not generate work instructions from video",
  );
};

export const transcribeAudio = async (audioFile: File): Promise<ParsedResponse<OpenaiVerboseTranscript>> => {
  const formData = new FormData();
  formData.append("file", audioFile);
  const response = await makeFetchRequest(
    `${API_URL}/ai/transcription`,
    {
      method: "POST",
      body: formData,
    },
    "Could not generate work instructions from video",
  );
  return {
    data: response.data ?? undefined,
    error: response.error ?? undefined,
  };
};

export const approveProcessRevision = async (processId: string, revision: number): Promise<ParsedResponse<Process>> => {
  return makeFetchRequest(
    `${API_URL}/processes/${processId}/${revision}/approve`,
    {
      method: "POST",
    },
    "Could not approve process revision",
  );
};

export const askSerialAPIProgrammingAssistant = async (
  messages: { role: string; content: string }[],
): Promise<ParsedResponse<Partial<SerialProgrammingAssistantResponse>>> => {
  return makeFetchRequest(
    `${API_URL}/ai/api-programming-assistant`,
    {
      method: "POST",
      body: JSON.stringify({
        messages: messages,
      }),
    },
    "An error occurred while running the python script",
  );
};
