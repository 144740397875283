import { useCallback, useEffect, useRef, useState } from "react";
import SupabaseClient from "@supabase/supabase-js/dist/module/SupabaseClient";
import { genericInsert, genericUpdate, genericDelete, setCompany } from "@shared/redux/db";
import { useDispatch, useSelector } from "react-redux";
import db from "@shared/connections/supabaseReduxDatabase";
import { RootState } from "@shared/redux/store";
import { useLocation } from "react-router-dom";
import { getSupabase } from "@shared/connections/supabaseAuth";

interface SupabasePostgresRealtimePayload {
  commit_timestamp: string;
  errors: any;
  eventType: "INSERT" | "UPDATE" | "DELETE";
  new: any;
  old: any;
  schema: string;
  table: string;
}

const useRealtime = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [prevTimestamp, setPrevTimestamp] = useState<Date>(new Date());
  const isLoaded = useSelector((state: RootState) => state.db.isLoaded);
  const location = useLocation();

  // Use refs to store previous timestamp and isLoaded value to avoid issues with closures
  const prevTimestampRef = useRef<Date>(new Date());
  const isLoadedRef = useRef<boolean>(false);

  useEffect(() => {
    prevTimestampRef.current = prevTimestamp;
    isLoadedRef.current = isLoaded;
  }, [prevTimestamp, isLoaded]);

  useEffect(() => {
    db.refreshCriticalData(location.pathname);
  }, [location.pathname]);

  const dispatch = useDispatch();

  const handleUpdateForeignKeyJoins = (tableName: string) => {
    const propertiesToRefresh = db.getPropertiesToUpdateOnForeignKeyChange(tableName);
    propertiesToRefresh.forEach((property) => {
      const refreshFunction = db.refreshFunctionMap[property.tableName];
      refreshFunction();
    });
  };

  const handleEventGeneric = useCallback((payload: SupabasePostgresRealtimePayload) => {
    // Don't do anything if the redux db is loading
    if (!isLoadedRef.current) {
      return;
    }

    // Check to make sure timestamps are increasing monotonically
    const timestamp = new Date(payload.commit_timestamp);
    if (timestamp <= prevTimestampRef.current) {
      console.log("Timestamps are not increasing monotonically. Refreshing all data.");
      db.refreshAllData();
      setPrevTimestamp(timestamp);
      return;
    }
    setPrevTimestamp(timestamp);

    // Dispatch the appropriate action based on the event type
    switch (payload.eventType) {
      case "INSERT":
        dispatch(genericInsert({ table: payload.table, record: payload.new }));
        break;
      case "UPDATE":
        dispatch(genericUpdate({ table: payload.table, record: payload.new }));
        break;
      case "DELETE":
        dispatch(genericDelete({ table: payload.table, record: payload.old }));
        break;
      default:
        console.error(`Unknown event type: ${payload.eventType}`);
        break;
    }

    // Refresh tables which contain a foreign key to the table that was updated
    handleUpdateForeignKeyJoins(payload.table);
  }, []);

  const handleUpdateCompany = useCallback((payload: SupabasePostgresRealtimePayload) => {
    // Don't do anything if the redux db is loading or if the event type is not 'UPDATE'
    if (!isLoadedRef.current || payload.eventType !== "UPDATE") {
      return;
    }

    // Check to make sure timestamps are increasing monotonically
    const timestamp = new Date(payload.commit_timestamp);
    if (timestamp <= prevTimestampRef.current) {
      console.log("Timestamps are not increasing monotonically. Refreshing all data.");
      db.refreshCompany();
      setPrevTimestamp(timestamp);
      return;
    }
    setPrevTimestamp(timestamp);

    // Replace the company in the redux db
    dispatch(setCompany(payload.new));

    // Refresh tables which contain a foreign key to the table that was updated
    handleUpdateForeignKeyJoins(payload.table);
  }, []);

  const handleUpdateUsers = useCallback(
    (payload: SupabasePostgresRealtimePayload) => {
      // Don't do anything if the redux db is loading
      if (!isLoadedRef.current) {
        return;
      }
      db.refreshUsers();

      // Refresh tables which contain a foreign key to the table that was updated
      handleUpdateForeignKeyJoins(payload.table);
    },
    [isLoaded],
  );

  const handleUpdateReportTemplates = useCallback(
    (payload: SupabasePostgresRealtimePayload) => {
      // Don't do anything if the redux db is loading
      if (!isLoadedRef.current) {
        return;
      }

      const timestamp = new Date(payload.commit_timestamp);
      setPrevTimestamp(timestamp);

      db.refreshReportTemplates();

      // Refresh tables which contain a foreign key to the table that was updated
      handleUpdateForeignKeyJoins(payload.table);
    },
    [isLoaded],
  );

  const handleUpdateProcesses = useCallback(
    (payload: SupabasePostgresRealtimePayload) => {
      // Don't do anything if the redux db is loading
      if (!isLoadedRef.current) {
        return;
      }

      const timestamp = new Date(payload.commit_timestamp);
      setPrevTimestamp(timestamp);

      db.refreshProcesses();

      // Refresh tables which contain a foreign key to the table that was updated
      handleUpdateForeignKeyJoins(payload.table);
    },
    [isLoaded],
  );

  const subscribe = (client: SupabaseClient) => {
    if (!client) {
      console.error("Supabase client not initialized");
      return;
    }

    // Unsubscribe from changes if already subscribed
    const channel = client.channel("db-changes");
    if (channel && client) {
      client.removeChannel(channel);
    }

    // Subscribe to changes on all redux db tables
    client
      .channel("db-changes")
      .on("postgres_changes", { event: "*", schema: "public", table: "companies" }, (payload) => handleUpdateCompany(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "components" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "processes" }, (payload) => handleUpdateProcesses(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "fields" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "component_links" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "component_process_links" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "datasets" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "stations" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "operators" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "part_numbers" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "users" }, (payload) => handleUpdateUsers(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "work_orders" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "unique_identifier_activity_log" }, (payload) =>
        handleEventGeneric(payload),
      )
      .on("postgres_changes", { event: "*", schema: "public", table: "report_templates" }, (payload) =>
        handleUpdateReportTemplates(payload),
      )
      .on("postgres_changes", { event: "*", schema: "public", table: "unique_identifiers" }, (payload) => handleEventGeneric(payload))
      .on("postgres_changes", { event: "*", schema: "public", table: "versions" }, (payload) => handleEventGeneric(payload))
      .subscribe((status: string) => {
        if (status === "SUBSCRIBED") {
          console.log("Successfully subscribed to Redux DB changes.");
          setIsActive(true);
        }
      });
  };

  // Unsubscribe from changes when hook unmounts
  useEffect(() => {
    return () => {
      const supabase = getSupabase();
      const channel = supabase?.channel("db-changes");
      if (channel && supabase) {
        supabase.removeChannel(channel);
        setIsActive(false);
      }
    };
  }, []);

  return {
    subscribe,
    client: getSupabase(),
    isActive,
  };
};

export default useRealtime;
