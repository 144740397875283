import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { LabelElementType } from "@shared/types/databaseEnums";
import { faBarcode, faFont, faImage, faQrcode, faSpellCheck } from "@fortawesome/free-solid-svg-icons";

// Most printers are 300 DPI. We cut that in half to 150 to make it show up better on the screen. Divide by 2 to make the math easier for scaling back up when printing.
export const PX_PER_MM = 300 / 25.4;

// Placeholder identifier for sample labels (in the label editor)
export const PLACEHOLDER_IDENTIFIER = "**SAMPLE**";

export const LabelElementStyle: {
  [key in LabelElementType]: {
    icon: IconProp;
    label: string;
  };
} = {
  [LabelElementType.Text]: {
    icon: faFont,
    label: "Text",
  },
  [LabelElementType.Image]: {
    icon: faImage,
    label: "Image",
  },
  [LabelElementType.Barcode]: {
    icon: faBarcode,
    label: "Barcode",
  },
  [LabelElementType.QRCode]: {
    icon: faQrcode,
    label: "QR Code",
  },
  [LabelElementType.Identifier]: {
    icon: faSpellCheck,
    label: "Identifier",
  },
};
