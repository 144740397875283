import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchLotLinks } from "@shared/connections/supabaseSnLookup";
import moment from "moment";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import TypeTag from "@shared/components/TypeTag";
import { SnLookupContext } from "../SnLookupProvider";

function LotLinkTable() {
  const { uniqueIdentifier } = useContext(SnLookupContext);

  const navigate = useNavigate();
  const location = useLocation();
  const uid = new URLSearchParams(location.search).get("uid");

  const componentsList = useSelector((state) => state.db.components);
  const [lotCodeLinks, setLotCodeLinks] = useState([]);
  const [hoverIdentiferIndex, setHoverIdentiferIndex] = useState(null);
  const [linkStatusCount, setLinkStatusCount] = useState({ numWIP: null, numCOMPLETE: null, numDEFECTIVE: null });

  const loadData = async () => {
    setLotCodeLinks(await fetchLotLinks(uniqueIdentifier.id));
  };

  useEffect(() => {
    loadData();
  }, [uniqueIdentifier]);

  useEffect(() => {
    let numWIP = null;
    let numCOMPLETE = null;
    let numDEFECTIVE = null;

    if (lotCodeLinks.length > 0) {
      for (var i = 0; i < lotCodeLinks.length; i++) {
        if (lotCodeLinks[i].unique_identifiers.status === "WIP") {
          numWIP++;
        } else if (lotCodeLinks[i].unique_identifiers.status === "COMPLETE") {
          numCOMPLETE++;
        } else if (lotCodeLinks[i].unique_identifiers.status === "DEFECTIVE") {
          numDEFECTIVE++;
        }
      }
      setLinkStatusCount({ numWIP, numCOMPLETE, numDEFECTIVE });
    }
  }, [lotCodeLinks]);

  return (
    <div className="border-serial-palette-200  relative rounded-md border bg-white">
      <header className="flex items-center justify-between px-5 py-4">
        <h2 className="text-serial-palette-800 font-semibold">
          {" "}
          Linked Serial Numbers <span className="text-serial-palette-400 font-medium">{lotCodeLinks.length}</span>
        </h2>
        <div className="space-x-5">
          {linkStatusCount.numWIP && (
            <h1 className="text-serial-palette-800 inline-flex items-center text-xl font-bold">
              {" "}
              {linkStatusCount.numWIP} <span className="ml-2 text-sm font-normal text-blue-500"> WIP </span>{" "}
            </h1>
          )}
          {linkStatusCount.numCOMPLETE && (
            <h1 className="text-serial-palette-800 inline-flex items-center text-xl font-bold">
              {" "}
              {linkStatusCount.numCOMPLETE} <span className="ml-2 text-sm font-normal text-green-500"> COMPLETE </span>{" "}
            </h1>
          )}
          {linkStatusCount.numDEFECTIVE && (
            <h1 className="text-serial-palette-800 inline-flex items-center text-xl font-bold">
              {" "}
              {linkStatusCount.numDEFECTIVE} <span className="ml-2 text-sm font-normal text-red-500"> DEFECTIVE </span>{" "}
            </h1>
          )}
        </div>
      </header>

      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="divide-serial-palette-200 w-full table-auto divide-y">
            {/* Table header */}
            <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-t text-xs uppercase">
              <tr>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">#</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Serial Number</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Component</th>
                <th className="whitespace-nowrap px-2 py-3 text-center font-semibold first:pl-5 last:pr-5">Status</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Time Linked</th>
              </tr>
            </thead>
            {/* Table body */}
            {lotCodeLinks.length > 0 &&
              lotCodeLinks.map((link, index) => {
                return (
                  <tbody key={index} className="text-sm font-light">
                    <tr
                      className="hover:bg-serial-palette-100 cursor-pointer"
                      onMouseEnter={() => setHoverIdentiferIndex(index)}
                      onMouseLeave={() => setHoverIdentiferIndex(null)}
                      onClick={(e) => {
                        navigate("/snlookup?uid=" + link.unique_identifiers.id);
                      }}
                    >
                      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">{index + 1}</td>
                      <td className="flex whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">
                        {link.unique_identifiers.identifier}
                        <CopyToClipboard
                          text={link.unique_identifiers.identifier}
                          className={`${hoverIdentiferIndex === index ? "" : "text-white"}`}
                        />
                      </td>
                      {componentsList.length > 0 && (
                        <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">
                          {componentsList.find((component) => component.id === link.unique_identifiers.component_id).name}
                        </td>
                      )}
                      <td className="flex justify-center">
                        <TypeTag className="w-32 text-xs" type={link?.unique_identifiers.status} />
                      </td>
                      <td className="whitespace-nowrap px-2 py-3 text-left first:pl-5 last:pr-5">
                        {moment(link.process_entries.timestamp).format("MM/DD/YY HH:mma")}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default LotLinkTable;
