import React, { useState, useRef, useEffect, ReactNode } from "react";
import Transition from "../Transition";

interface DropdownEditMenuProps {
  children: ReactNode;
  align?: string;
  size?: string | number;
  rounded?: string | number;
  dark?: boolean;
  [key: string]: any;
}

const DropdownEditMenu: React.FC<DropdownEditMenuProps> = ({ children, align, size, rounded, dark }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLUListElement>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || trigger.current?.contains(event.target as Node)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative flex">
      <button
        ref={trigger}
        className={`${rounded ? "rounded-" + rounded : "rounded"} ${dark ? "serial-btn-dark" : "serial-btn-light"} ${size ? `w-${size} h-${size}` : "h-8 w-8"}`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Menu</span>
        <svg className="flex h-full w-full fill-current" viewBox="0 0 32 32">
          <circle cx="16" cy="16" r="2" />
          <circle cx="10" cy="16" r="2" />
          <circle cx="22" cy="16" r="2" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`border-serial-palette-200 min-w-36 absolute top-full z-10 mt-1 origin-top-right overflow-hidden rounded border bg-white  py-1.5 shadow ${align === "right" ? "right-0" : "left-0"}`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        appear={null}
      >
        <ul ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          {children}
        </ul>
      </Transition>
    </div>
  );
};

export default DropdownEditMenu;
