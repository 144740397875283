import React, { useContext, useMemo } from "react";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import { formatTimeAgo } from "@shared/utils/time";
import TypeTag from "@shared/components/TypeTag";
import { ProductionContext } from "../ProductionProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UniqueIdentifierStatus, WorkOrderStatus } from "@shared/types/databaseEnums";
import ProductionComponentWipGraph from "./ProductionComponentWipGraph";
import WorkOrderProgressBar from "../../work-orders/components/WorkOrderProgressBar";
import { WorkOrderWithCounts } from "../types";
import { UniqueIdentifier } from "@shared/types/databaseTypes";
import { useTranslation } from "react-i18next";

interface ProductionSidebarProps {
  workOrder: WorkOrderWithCounts | null;
  setSidebarOpen: (state: boolean) => void;
}

const ProductionSidebar: React.FC<ProductionSidebarProps> = ({ workOrder, setSidebarOpen }) => {
  const { db } = useContext(ProductionContext);
  const { t } = useTranslation();

  const component = useMemo(() => {
    if (workOrder === null) {
      return undefined;
    }
    return db.components.find((comp) => comp.id === workOrder.component_id);
  }, [workOrder, db.components]);

  const workOrderIdentifiers = useMemo(() => {
    if (workOrder === null) {
      return [];
    }
    return db.componentInstances.filter((instance) => instance.work_order_id === workOrder.id);
  }, [workOrder, db.componentInstances]);

  return (
    <div className="col-span-full flex flex-col overflow-auto lg:col-span-4">
      <div className="w-full">
        <div className="inline-flex w-full items-center justify-between border-b px-5 py-4 ">
          <div className="inline-flex space-x-2">
            <h2 className="text-serial-palette-800 text-xl font-semibold">{workOrder?.name}</h2>
            <div className="text-serial-palette-500 text-xl">({workOrderIdentifiers.length})</div>
          </div>
          <button onClick={() => setSidebarOpen(false)} className="btn-xs bg-white bg-opacity-70">
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>
      </div>
      <div className="flex w-full items-center gap-6 p-5">
        <div className="hidden shrink-0 items-center justify-center overflow-hidden rounded-lg md:flex">
          <ComponentAvatar tooltip={false} component={component} size="lg" />
        </div>
        <div className="flex w-full flex-col gap-2 overflow-x-clip">
          <div className="w-full flex-col items-center justify-between space-y-2">
            <div className="inline-flex w-full">
              <div className=" text-serial-palette-400 w-32 font-bold">Quantity</div>
              <div>{workOrder?.quantity}</div>
            </div>
            <div className="inline-flex w-full">
              <div className=" text-serial-palette-400  w-32 font-bold">Part Number</div>
              <div>{(workOrder?.part_number ?? "N/A").toString()}</div>
            </div>
            <div className="inline-flex w-full">
              <div className=" text-serial-palette-400 w-32 font-bold">Phase</div>
              <div>{workOrder?.phase ?? "N/A"}</div>
            </div>
          </div>
        </div>
      </div>
      {workOrder?.counts_by_status && (
        <div className="px-5 py-2">
          <WorkOrderProgressBar counts_by_status={workOrder?.counts_by_status} quantity={workOrder?.quantity} showLegend="mini" />
        </div>
      )}
      {component && workOrder && workOrder.status == WorkOrderStatus.Production && (
        <div className="h-[25vh]">
          <ProductionComponentWipGraph
            componentId={component.id}
            workOrderId={workOrder.id}
            productionSidebar={true}
          />
        </div>
      )}
      {workOrderIdentifiers.length > 0 && (
        <table className="w-full table-auto ">
          {/* Table header */}
          <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
            <tr>
              <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                <div className="text-left font-semibold">Identifier</div>
              </th>
              <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                <div className="text-left font-semibold">Last Updated</div>
              </th>
              <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                <div className="text-left font-semibold">Latest Process</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="divide-serial-palette-200 divide-y border-b text-sm">
            {workOrderIdentifiers.map((identifier: UniqueIdentifier, index: number) => {
              return (
                <tr key={index}>
                  <td className="w-[200px] whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="flex items-center space-x-3">
                      <TypeTag
                        type={identifier.status ?? UniqueIdentifierStatus.Planned}
                        className="h-[20px] w-auto text-[10px]"
                        hideText={true}
                      />
                      <div className="truncate text-sm">{identifier.identifier}</div>
                    </div>
                  </td>
                  <td className="max-w-[200px] whitespace-nowrap px-2 py-3 first:pl-5  last:pr-5">
                    <div className="truncate text-sm ">{formatTimeAgo(identifier?.last_updated_at)}</div>
                  </td>
                  <td className="max-w-[200px] whitespace-nowrap px-2 py-3 first:pl-5  last:pr-5">
                    <div className="truncate text-sm">
                      {db.processes.find(process => process.id === identifier?.latest_process_entry?.process_id)?.name ?? "No Process"}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="px-5 py-5">
        {workOrder?.status == WorkOrderStatus.Review && (
          <div> This {t("workOrder").toLowerCase()} is under review and has not been approved for production </div>
        )}
      </div>
    </div>
  );
};

export default ProductionSidebar;
