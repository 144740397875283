import { getSupabase } from "@shared/connections/supabaseAuth";
import { supabaseSingleOverride } from "@shared/connections/supabaseDataModifiers";
import { ProcessType, UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { ComponentProcessLink } from "@shared/types/databaseTypes";

export const swapProcessOrders = async (processId1: string, processId2: string, componentId: string): Promise<boolean> => {
  const supabase = getSupabase();

  const { data: componentProcessLinks, error: componentProcessLinksError } = await supabase
    .from("component_process_links")
    .select("*")
    .in("process_id", [processId1, processId2])
    .eq("component_id", componentId)
    .returns<ComponentProcessLink[]>();

  if (componentProcessLinksError || !componentProcessLinks || componentProcessLinks.length !== 2) {
    console.log(componentProcessLinksError);
    console.error("Error swapping process orders");
    return false;
  }

  const process1SupabaseOrder = componentProcessLinks.find((link) => link.process_id === processId1)?.order;
  const process2SupabaseOrder = componentProcessLinks.find((link) => link.process_id === processId2)?.order;

  // Swap the order values
  const { error: process1UpdateError } = await supabase
    .from("component_process_links")
    .update({ order: process2SupabaseOrder })
    .eq("process_id", processId1)
    .eq("component_id", componentId);
  const { error: process2UpdateError } = await supabase
    .from("component_process_links")
    .update({ order: process1SupabaseOrder })
    .eq("process_id", processId2)
    .eq("component_id", componentId);

  // Check for errors
  if (process1UpdateError || process2UpdateError) {
    console.log(process1UpdateError);
    console.log(process2UpdateError);
    return false;
  }
  return true;
};

export const fetchUniqueIdentifiersCountByComponentId = async (componentId: string, status: UniqueIdentifierStatus | null, showArchivedComponentInstances?: boolean, showActiveComponentInstances?: boolean) => {
  const supabase = getSupabase();

  try {
    let query = supabase
      .from("unique_identifiers")
      .select("*", { count: "exact", head: true })
      .eq("component_id", componentId);

    // Conditionally add the status filter
    if (status != null) {
      query = query.eq("status", status);
    }
    if (showArchivedComponentInstances && !showActiveComponentInstances) {
      query = query.eq("is_archived", true);
    } else if (showActiveComponentInstances && !showArchivedComponentInstances) {
      query = query.eq("is_archived", false);
    }
    if (!showArchivedComponentInstances && !showActiveComponentInstances) {
      return 0;
    }

    const { count: uniqueIdentifiersCount, error: uniqueIdentifiersCountError } = await query;

    if (uniqueIdentifiersCountError) return 0;
    return uniqueIdentifiersCount;
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};

// Filter
export const fetchUniqueIdentifiersByComponentIdWithPagination = async (
  componentId: string,
  status: UniqueIdentifierStatus | null,
  startIndex: number,
  endIndex: number,
  showArchivedComponentInstances?: boolean,
  showActiveComponentInstances?: boolean,
) => {
  const supabase = getSupabase();

  try {
    let query = supabase
      .from("unique_identifiers")
      .select("*, part_number:part_number_id(*)")
      .range(startIndex, endIndex)
      .eq("component_id", componentId)
      .order("last_updated_at", { ascending: false });

    // Conditionally add the status filter
    if (status != null) {
      query = query.eq("status", status);
    }
    if (showArchivedComponentInstances && !showActiveComponentInstances) {
      query = query.eq("is_archived", true);
    } else if (showActiveComponentInstances && !showArchivedComponentInstances) {
      query = query.eq("is_archived", false);
    }
    if (!showArchivedComponentInstances && !showActiveComponentInstances) {
      return [];
    }

    const { data: uniqueIdentifiers, error: uniqueIdentifiersError } = await query;

    if (uniqueIdentifiersError || !uniqueIdentifiers) throw uniqueIdentifiersError;

    // Attach the most recent process entry to each unique identifier
    const data = await Promise.all(
      uniqueIdentifiers.map(async (identifier) => {
        const { data: processEntry } = supabaseSingleOverride(
          await supabase
            .from("process_entries")
            .select("*, process:processes!inner(*)") // use !inner to make the .eq and .neq filters work on the joined tables
            .eq("unique_identifier_id", identifier.id)
            .eq("processes.type", ProcessType.Production)
            .order("timestamp", { ascending: false })
            .limit(1),
        );

        return { ...identifier, latest_process_entry: processEntry };
      }),
    );

    if (uniqueIdentifiersError) throw uniqueIdentifiersError;

    return data;
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};
