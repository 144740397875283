import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "@shared/components/Loader";
import { upsertComponentAvatar, deleteComponent, updateComponent } from "@shared/connections/supabaseComponents";
import { createNewComponent } from "@shared/connections/api/components";
import Banner2 from "@shared/components/Banner2";
import Select from "@shared/components/primitives/Select";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { validateNewComponent } from "@shared/connections/supabaseComponentValidation";
import useObserve from "@shared/hooks/useObserve";
import { Component } from "@shared/types/databaseTypes";
import { ComponentType } from "@shared/types/databaseEnums";
import FileInputBox from "@shared/components/FileInputBox";
import { generateBlankComponent } from "../helpers";
import useCurrentUser from "@shared/hooks/useCurrentUser";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";

interface ComponentSettingsProps {
  selectedComponent: Component | null;
  setModalOpen: (isOpen: boolean) => void;
  setUnsavedChanges?: (unsavedChanges: boolean) => void;
}

const ComponentSettings: React.FC<ComponentSettingsProps> = ({ setModalOpen, selectedComponent, setUnsavedChanges }) => {
  const currUser = useCurrentUser();
  const company = useSelector((state: RootState) => state.db.company);

  const [component, setComponent] = useState<Component | null>(selectedComponent ?? null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorBannerOpen, setErrorBannerOpen] = useState<boolean>(false);
  const [error, setError] = useState<{ message: string; type: string }>({ message: "", type: "" });
  const [avatar, setAvatar] = useState<{ url: string; name?: string; type?: string } | undefined>(
    selectedComponent?.url ? { url: selectedComponent.url } : undefined,
  );
  const navigate = useNavigate();
  const observe = useObserve();

  const isNewComponent = useMemo(() => {
    return selectedComponent === null;
  }, [selectedComponent]);

  useEffect(() => {
    if (!selectedComponent) {
      if (!company || !currUser) {
        console.error("Company or user not found");
        return;
      }
      setComponent(generateBlankComponent(company.id, currUser.supabase_uid));
      setAvatar(undefined);
      return;
    }
    setComponent(selectedComponent);
    setAvatar(selectedComponent?.url ? { url: selectedComponent.url } : undefined);
  }, [selectedComponent]);

  // automatically close the error banner after 5 seconds if the banner type is not 'warning'
  useEffect(() => {
    if (!errorBannerOpen) return;
    const timeout = setTimeout(() => {
      setErrorBannerOpen(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [error]);

  const setSelectedAvatar = (files: File[]) => {
    setUnsavedChanges && setUnsavedChanges(true);
    if (!files || files.length === 0) {
      setAvatar(undefined);
      return;
    }
    const avatarUrl = URL.createObjectURL(files[0]);
    const avatarData = {
      url: avatarUrl,
      name: files[0].name,
      type: files[0].type,
    };
    setAvatar(avatarData);
    observe.track("component_avatar-upload");
  };

  const handleDelete = async () => {
    if (!component) {
      console.error("Component not found in component settings handleDelete");
      return;
    }
    setIsLoading(true);
    // Try to delete the component
    const successfulDelete = await deleteComponent(component.id);

    // If the component cannot be deleted, inactivate it and set the warning message
    if (successfulDelete) {
      navigate("/componentslist");
      setModalOpen(false);
    } else {
      setComponent({ ...component, is_active: false });
      await updateComponent({ ...component, is_active: false }, avatar?.url);
      setError({
        message:
          "This component cannot be deleted because your database already has records associated to it. The component has been deactivated instead.",
        type: "warning",
      });
      setErrorBannerOpen(true);
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    if (!component) {
      console.error("Component not found in component settings handleSave");
      return;
    }

    setIsLoading(true);

    // Create new component if is new component, otherwise update the properties
    if (isNewComponent) {
      const validation = await validateNewComponent(component);
      if (!validation.isValid) {
        setIsLoading(false);
        setError({ message: validation?.message ?? "Component data invalid", type: "error" });
        setErrorBannerOpen(true);
        return;
      }
      const { data: newComponent, error: newComponentError } = await createNewComponent(component);
      if (newComponentError || !newComponent) {
        setIsLoading(false);
        console.error("Error creating new component", newComponentError);
        setError({
          message:
            "Your component change / creation could not be completed. Make sure all the required fields have been filled out or contact Serial support.",
          type: "error",
        });
        setErrorBannerOpen(true);
        return;
      }
      await upsertComponentAvatar(avatar?.url, newComponent.id);
      observe.track("component_create");
      setModalOpen(false);
      navigate("/component/" + newComponent.id);
      return;
    }

    const changedComponent = await updateComponent(component, avatar?.url);
    observe.track("component_edit", { component_name: changedComponent.name });

    if (changedComponent) {
      setIsLoading(false);
      setError({ message: "Component settings saved", type: "success" });
      setErrorBannerOpen(true);
    } else {
      setIsLoading(false);
      setError({
        message:
          "Your component change / creation could not be completed. Make sure all the required fields have been filled out or contact Serial support.",
        type: "error",
      });
      setErrorBannerOpen(true);
    }
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (isNewComponent) {
      navigate("/componentslist");
    }
    setAvatar(undefined);
    setComponent(null);
    setModalOpen(false);
  };

  const handleUpdateComponent = (key: keyof Component, value: any) => {
    if (!component) {
      return;
    }
    setComponent({ ...component, [key]: value });
    setUnsavedChanges && setUnsavedChanges(true);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center py-16">
          <Loader styleOverride="w-16" />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col gap-y-4 overflow-auto px-5 py-4">
          <Banner2 type={error.type} open={errorBannerOpen} setOpen={setErrorBannerOpen}>
            {error.message}
          </Banner2>

          {/* Manual Entry of Station ID */}
          <div>
            <label className="mb-1 block text-sm font-medium">
              {" "}
              Component Name <span className="text-rose-500">*</span>{" "}
            </label>
            <input
              name="name"
              value={component?.name || ""}
              className="form-input w-full"
              type="text"
              onChange={(e) => handleUpdateComponent("name", e.target.value)}
              placeholder="My Component Name"
              required
            />
          </div>

          {/* Dropdown Selection of Station ID */}
          <div>
            <label className="mb-1 block text-sm font-medium">
              {" "}
              Component Identifier Type <span className="text-rose-500">*</span>{" "}
            </label>
            <Select.Root
              value={component?.component_type || "-"}
              onValueChange={(value) =>
                handleUpdateComponent("component_type", value === ComponentType.Sn ? ComponentType.Sn : ComponentType.Lot)
              }
              disabled={!isNewComponent}
              name="component_type"
            >
              <Select.Trigger className="w-24">
                <Select.Value></Select.Value>
              </Select.Trigger>
              <Select.Content>
                <Select.Item value={ComponentType.Sn}>SN</Select.Item>
                <Select.Item value={ComponentType.Lot}>LOT</Select.Item>
              </Select.Content>
            </Select.Root>
            {component?.component_type == undefined && (
              <h3 className="text-serial-palette-400 pt-2 align-middle text-sm font-light italic">
                Select either SN for 1:1 tracking or LOT for batch tacking
              </h3>
            )}
            {component?.component_type == "SN" && (
              <h3 className="text-serial-palette-400 pt-2 align-middle text-sm font-light italic">
                SNs (Serial Numbers) are unique to a single unit
              </h3>
            )}
            {component?.component_type == "LOT" && (
              <h3 className="text-serial-palette-400 pt-2 align-middle text-sm font-light italic">
                LOT codes are shared across many units
              </h3>
            )}
          </div>

          {/* File Upload for Component avatar */}
          <div className="flex w-full flex-col items-start">
            <label className="mb-1 block text-sm font-medium">Component Thumbnail</label>
            <div className="border-serial-palette-200 text-serial-palette-400 flex h-full w-full items-center justify-between rounded border font-light italic">
              {avatar?.url && (
                <div className="bg-serial-palette-50 border-serial-palette-200 my-4 ml-4 flex h-28 w-32 shrink-0 items-center justify-center overflow-hidden rounded-md border-2">
                  <img src={avatar.url} alt="" className="h-full w-full object-cover" />
                </div>
              )}
              <FileInputBox
                allowPaste
                handleUploadFiles={setSelectedAvatar}
                fileInfo={avatar ? { name: avatar.name ?? "", type: avatar.type } : null}
                prompt="Drag drop an image or "
                icon={faImage}
                className="h-full w-full"
              />
            </div>
          </div>

          {/* Active / Inactive */}
          <div>
            <label className="mb-1 block text-sm font-medium" htmlFor="role">
              {" "}
              Deactivate Component
            </label>
            <div className="flex items-center">
              <div className="form-switch">
                <input
                  name="is_active"
                  type="checkbox"
                  id="switch-1"
                  className="sr-only"
                  checked={component?.is_active ?? false}
                  onChange={() => handleUpdateComponent("is_active", component?.is_active ? false : true)}
                />
                <label className="bg-serial-palette-400" htmlFor="switch-1">
                  <span className="bg-white " aria-hidden="true"></span>
                  <span className="sr-only">Switch label</span>
                </label>
              </div>
              <div className="text-serial-palette-400 ml-2 text-sm italic">
                Component is{" "}
                {component?.is_active ? <span className="text-green-500">active</span> : <span className="text-red-500">inactive</span>}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="z-10 flex h-12 shrink-0 items-center justify-between border-t px-3">
        {!isNewComponent && (
          <button className="btn-xs serial-btn-danger h-7" type="button" onClick={() => handleDelete()}>
            Delete
          </button>
        )}
        <div className="flex items-center gap-x-1.5">
          <button
            className="btn-xs border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600 h-7"
            onClick={(e) => handleClose(e)}
          >
            Cancel
          </button>
          <button className="btn-xs bg-serial-palette-800 hover:bg-serial-palette-600 h-7 text-white lg:flex" onClick={() => handleSave()}>
            {isNewComponent ? "Create" : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ComponentSettings;
