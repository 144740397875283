import { useContext, useEffect, useRef } from "react";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import ProcessBuilderStep from "./ProcessBuilderStep";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProcessBuilderStepsSummary from "./ProcessBuilderStepsSummary";
import useStepsTruthTable from "../hooks/useStepsTruthTable";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import ProcessBuilderPreview from "./ProcessBuilderPreview";

const SCROLL_BUFFER = 100;

const ProcessBuilderBody: React.FC = () => {
  const { component, draftProcess, enablePreviewMode, setSelectedStepIndex, selectedStepIndex } = useContext(ProcessBuilderContext);

  const partNumbers = useSelector((state: RootState) => state.db.partNumbers).filter(
    (partNumber) => partNumber.component_id === component?.id,
  );
  const stepsTruthTable = useStepsTruthTable({ partNumbers: partNumbers ?? [], steps: draftProcess?.process_steps ?? [] });

  const stepRefs = useRef<HTMLDivElement[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleCreateRef = (index: number) => (ref: HTMLDivElement) => {
    stepRefs.current[index] = ref;
  };

  useEffect(() => {
    const onScroll = () => {
      const scrollPosition = scrollRef.current?.scrollTop;
      if (scrollPosition === undefined) return;
      let selectedIdx = null;
      stepRefs.current.forEach((ref, index) => {
        if (ref && scrollPosition + SCROLL_BUFFER >= ref.offsetTop) {
          selectedIdx = index;
        }
      });
      if (selectedIdx !== null) setSelectedStepIndex(selectedIdx);
    };

    const scrollableElement = scrollRef.current;
    scrollableElement?.addEventListener("scroll", onScroll);

    return () => {
      scrollableElement?.removeEventListener("scroll", onScroll);
    };
  }, []);

  const handleScrollToStep = (index: number) => {
    stepRefs.current?.[index]?.scrollIntoView({ behavior: "smooth" });
  };

  // scroll instantly to the selected step when preview mode is disabled
  useEffect(() => {
    if (!enablePreviewMode) {
      stepRefs.current?.[selectedStepIndex]?.scrollIntoView({ behavior: "instant" });
    }
  }, [enablePreviewMode]);

  return (
    <div className="relative flex min-h-0 min-w-0 flex-grow">
      <DndProvider backend={HTML5Backend}>
        <ProcessBuilderStepsSummary stepsTruthTable={stepsTruthTable} handleScrollToStep={handleScrollToStep} />
        <div
          ref={scrollRef}
          className={`scrollbar-hide bg-serial-palette-50 flex min-h-0 min-w-0 flex-grow flex-col overflow-y-auto transition-all duration-300 ${enablePreviewMode && "mx-10 my-8 rounded-md border shadow-lg"}`}
        >
          {!enablePreviewMode && (
            <div className="flex flex-col">
              {draftProcess?.process_steps.map((step, index) => {
                return <ProcessBuilderStep key={index} step={step} stepIndex={index} handleCreateRef={handleCreateRef} />;
              })}
              <ProcessBuilderStep isPlaceholder={true} stepIndex={draftProcess?.process_steps.length ?? 0} />
            </div>
          )}
          {enablePreviewMode && <ProcessBuilderPreview stepsTruthTable={stepsTruthTable} />}
        </div>
      </DndProvider>
    </div>
  );
};

export default ProcessBuilderBody;
