import { useContext } from "react";
import ProductionProvider, { ProductionContext, ProductionState } from "./ProductionProvider";
import ProductionHeader from "./components/ProductionHeader";
import { Loader } from "@shared/components/Loader";
import { UserRole } from "@shared/types/databaseEnums";
import ProductionStep from "./components/ProductionStep";
import ProductionSubmissionResponse from "./components/ProductionSubmissionResponse";
import ProductionModalInstantiation from "./components/ProductionModalInstantiation";
import ProductionModalOperator from "./components/ProductionModalOperator";
import ProductionModalStation from "./components/ProductionModalStation";
import ProductionProcessSelector from "./components/ProductionProcessSelector";

function Production() {
  return (
    <ProductionProvider>
      <ProductionWithContext />
    </ProductionProvider>
  );
}

function ProductionWithContext() {
  const {
    station,
    user,
    productionState,
    stationModalOpen,
    setStationModalOpen,
    operatorModalOpen,
    setOperatorModalOpen,
    instantiationModalOpen,
    setInstantiationModalOpen,
    submissionProgress,
    singleSubmissionMode,
  } = useContext(ProductionContext);

  const shouldOpenStationModal = (station?.is_locked && user?.role === UserRole.Admin) || !station?.is_locked;

  return (
    <div className="flex h-screen min-h-0 min-w-0 flex-grow flex-col overflow-hidden">
      {!singleSubmissionMode && <ProductionHeader />}
      {/* Page content */}
      {station && (!station?.is_active || station?.is_locked) ? (
        <div
          className={`flex h-full w-full items-center justify-center ${station && (station?.is_active ? (station?.is_locked ? "bg-amber-100" : "") : "bg-red-100")}`}
        >
          <p className="text-xl font-medium">
            Station is {station?.is_active ? (station?.is_locked ? "locked" : "active") : "retired"}, please select a different station or
            contact your administrator to enable
          </p>
        </div>
      ) : (
        <>
          {productionState === ProductionState.Loading && (
            <div className="text-serial-palette-400 flex h-full w-full flex-col">
              {submissionProgress > 0 && (
                <div className="flex h-1 w-full">
                  <div
                    className="bg-serial-palette-400 h-full transition-all duration-500 ease-in-out"
                    style={{ width: `${submissionProgress}%` }}
                  ></div>
                </div>
              )}
              <div className="flex min-h-0 min-w-0 flex-grow items-center justify-center">
                <Loader />
              </div>
            </div>
          )}
          {productionState === ProductionState.ProcessSelector && <ProductionProcessSelector />}
          {productionState === ProductionState.Steps && <ProductionStep />}
          {productionState === ProductionState.SubmissionResponse && <ProductionSubmissionResponse />}
        </>
      )}
      {shouldOpenStationModal && <ProductionModalStation modalOpen={stationModalOpen} setModalOpen={setStationModalOpen} />}
      <ProductionModalOperator modalOpen={operatorModalOpen} setModalOpen={setOperatorModalOpen} />
      <ProductionModalInstantiation modalOpen={instantiationModalOpen} setModalOpen={setInstantiationModalOpen} />
    </div>
  );
}

export default Production;
