import React, { useEffect, useRef, useState } from "react";
import TypeTag from "@shared/components/TypeTag";
import { Dataset } from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface PartNumberCustomHeaderProps {
  dataset: Dataset;
  handleSetColumnName: (dataset: Dataset, newName: string) => void;
  handleDeleteColumn: (dataset: Dataset) => void;
}

const placeholderText = (type: DataType) => {
  switch (type) {
    case DataType.ParametricQualitative:
      return "Parameter...";
    case DataType.ParametricQuantitative:
      return "Parameter...";
    case DataType.Image:
      return "Image Name...";
    case DataType.File:
      return "File Name...";
    default:
      return "Column Name...";
  }
};

const PartNumberCustomHeader: React.FC<PartNumberCustomHeaderProps> = ({ dataset, handleSetColumnName, handleDeleteColumn }) => {
  const [showDelete, setShowDelete] = useState(false);
  const headerNameRef: any = useRef(null);

  useEffect(() => {
    if (headerNameRef.current) {
      headerNameRef.current.textContent = dataset.name;
    }
  }, [dataset]);

  return (
    <div className="flex items-center gap-1.5 py-1" onMouseEnter={() => setShowDelete(true)} onMouseLeave={() => setShowDelete(false)}>
      <TypeTag type={dataset.data_type} hideText className="h-6 " />
      <div
        ref={headerNameRef}
        className="input-box w-full truncate text-sm font-semibold outline-0 focus:overflow-visible focus:whitespace-normal"
        contentEditable={true}
        onBlur={() => handleSetColumnName(dataset, headerNameRef.current.textContent)}
        // @ts-ignore placeholder is a valid attribute in chrome, firefox, & safari
        placeholder={placeholderText(dataset.data_type)}
      />
      {showDelete && (
        <button
          onClick={() => handleDeleteColumn(dataset)}
          className="btn-xs border-serial-palette-300 hover:border-serial w-5 border bg-white py-[3px] text-xs text-red-500 hover:text-red-600"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
};

export default PartNumberCustomHeader;
