import { useContext } from "react";
import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import SnLookUpGenealogyTable from "./components/SnLookUpGenealogyTable";
import SnLookUnitDetails from "./components/SnLookUpUnitDetails";
import SnLookUpPrimaryContent from "./components/SnLookUpPrimaryContent";
import LotLinkTable from "./components/LotLinkTable";
import OverrideResultModal from "./components/modals/OverrideResultModal";
import ExportDataModal from "./components/modals/ExportDataModal";
import { Breadcrumb } from "@shared/types/sharedComponentTypes";
import DataAnalysisModal from "./components/modals/DataAnalysisModal";
import SnLookUpProvider from "./SnLookupProvider";
import { SnLookupContext } from "./SnLookupProvider";

function SnLookup() {
  return (
    <SnLookUpProvider>
      <SnLookupWithContext />
    </SnLookUpProvider>
  );
}

function SnLookupWithContext() {
  const { uniqueIdentifier } = useContext(SnLookupContext);

  const breadcrumbs: Breadcrumb[] = [
    {
      label: "Components",
      link: "/componentslist",
    },
    {
      label: uniqueIdentifier?.component?.name ?? "",
      link: "/component/" + uniqueIdentifier?.component?.id,
    },
    {
      label: uniqueIdentifier?.identifier ?? "",
      link: "",
    },
  ];

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="relative flex h-full flex-1 flex-col">
        <Header breadcrumbs={breadcrumbs} />
        <div className="flex min-h-0 min-w-0 flex-grow overflow-auto p-3 lg:p-8">
          <div className="grid h-full w-full grid-cols-12 items-start gap-4">
            <div className="col-span-full flex w-full flex-col gap-4 sm:flex-row lg:col-span-3 lg:flex-col xl:col-span-3">
              <SnLookUnitDetails />
              <div className="flex w-full shrink-0 flex-col gap-y-4 sm:w-1/2 lg:w-full">
                <SnLookUpGenealogyTable />
                <DataAnalysisModal />
              </div>
            </div>
            <div className="col-span-full space-y-4 lg:col-span-9 xl:col-span-9">
              <SnLookUpPrimaryContent />
              {uniqueIdentifier && uniqueIdentifier.component && uniqueIdentifier.component.component_type === "LOT" && <LotLinkTable />}
            </div>
          </div>
          <OverrideResultModal />
          <ExportDataModal />
        </div>
      </div>
    </div>
  );
}

export default SnLookup;
