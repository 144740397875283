import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import { Loader } from "@shared/components/Loader";
import Select from "@shared/components/primitives/Select";
import ProcessBuilderPdf from "./ProcessBuilderPdf";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { Process, ProcessWithReferences } from "@shared/types/databaseTypes";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import Switch from "@shared/components/Switch";
import { fetchProcessRevisions } from "../connections/supabase";

interface ProcessBuilderExportProps {
  process: ProcessWithReferences | null;
}

interface ProcessBuilderExportOptions {
  showRevisions: boolean;
  showDatasets: boolean;
  showSummary: boolean;
  imageSize: "sm" | "md" | "lg";
  imageQuality: "low" | "medium" | "high";
  fileType: "Work Instruction" | "Test Procedure";
}

const ProcessBuilderExport: React.FC<ProcessBuilderExportProps> = ({ process }) => {
  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const [pdfDocumentUrl, setPdfDocumentUrl] = useState<string | null>(null);
  const [options, setOptions] = useState<ProcessBuilderExportOptions>({
    showRevisions: false,
    showDatasets: false,
    showSummary: false,
    fileType: "Work Instruction",
    imageSize: "md",
    imageQuality: "low",
  });
  const [processRevisions, setProcessRevisions] = useState<Process[]>([]);
  const [fileSize, setFileSize] = useState<number | null>(null);
  const { component, setEnableExportMode } = useContext(ProcessBuilderContext);
  const users = useSelector((state: RootState) => state.db.users);
  const company = useSelector((state: RootState) => state.db.company);

  const generatePdf = async () => {
    if (!component || !process) return;
    setPdfIsLoading(true);
    const pdfComponent = (
      <ProcessBuilderPdf
        process={process}
        processRevisions={processRevisions}
        component={component}
        users={users}
        company={company}
        options={options}
        setPdfIsLoading={setPdfIsLoading}
      />
    );
    const blob = await pdf(pdfComponent).toBlob();
    let url = URL.createObjectURL(blob);
    setPdfIsLoading(false);
    setFileSize(parseFloat((blob.size / 1000000).toFixed(2)));
    return url;
  };

  // Generate the PDF when the modal opens
  useEffect(() => {
    generatePdf().then((url) => {
      if (!url) return;
      setPdfDocumentUrl(url);
    });
  }, []);

  // Regenerate the PDF when the options or work instructions change (with 1000ms debounce)
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      generatePdf().then((url) => {
        if (!url) return;
        setPdfDocumentUrl(url);
      });
    }, 500); // 1000 ms delay

    return () => clearTimeout(debounceTimer);
  }, [options]);

  const handleDownloadPdf = () => {
    if (!pdfDocumentUrl) return;
    const link = document.createElement("a");
    link.href = pdfDocumentUrl;
    link.download = `${component?.name} - ${process?.name} v${process?.revision} - ${moment().format("YYYY-MM-DD HH-mm-ss-a")}.pdf`;
    link.click();
  };

  const loadRevisionHistory = async () => {
    if (process?.id) {
      const response = await fetchProcessRevisions(process.id);
      setProcessRevisions(response.data ?? []);
    }
  };

  useEffect(() => {
    loadRevisionHistory();
  }, [process?.id]);

  return (
    <div className="flex h-full w-full overflow-auto">
      {/* Sidebar */}
      <div className="flex w-[550px] flex-col justify-between gap-y-4 border-r bg-white">
        {/* Preferences */}
        <div className="flex h-full flex-col gap-y-4 overflow-hidden p-4 ">
          <div className="flex items-center justify-between">
            <label className="mb-1 text-sm font-medium">Show Revision History</label>
            <Switch
              checked={options?.showRevisions ?? false}
              onChange={() => setOptions({ ...options, showRevisions: !options?.showRevisions })}
            />
          </div>
          <div className="flex items-center justify-between">
            <label className="mb-1 text-sm font-medium">Show Datasets</label>
            <Switch
              checked={options?.showDatasets ?? false}
              onChange={() => setOptions({ ...options, showDatasets: !options?.showDatasets })}
            />
          </div>
          {/* For Process Type */}
          <div className="flex items-center justify-between">
            <label className="mb-1 text-sm font-medium">Process Type</label>
            <Select.Root
              value={options.fileType}
              onValueChange={(value) => setOptions({ ...options, fileType: value as "Work Instruction" | "Test Procedure" })}
            >
              <Select.Trigger>
                <Select.Value placeholder="Select Process Type" />
              </Select.Trigger>
              <Select.Content>
                <Select.Item value="Work Instruction">Work Instruction</Select.Item>
                <Select.Item value="Test Procedure">Test Procedure</Select.Item>
              </Select.Content>
            </Select.Root>
          </div>

          {/* For Image Size */}
          <div className="flex items-center justify-between">
            <label className="mb-1 text-sm font-medium">Image Size</label>
            <Select.Root
              value={options.imageSize}
              onValueChange={(value) => setOptions({ ...options, imageSize: value as "sm" | "md" | "lg" })}
            >
              <Select.Trigger>
                <Select.Value placeholder="Select Image Size" />
              </Select.Trigger>
              <Select.Content>
                <Select.Item value="sm">Small</Select.Item>
                <Select.Item value="md">Medium</Select.Item>
                <Select.Item value="lg">Large</Select.Item>
              </Select.Content>
            </Select.Root>
          </div>

          {/* For Image Quality */}
          <div className="flex items-center justify-between">
            <label className="mb-1 text-sm font-medium">Image Quality</label>
            <Select.Root
              value={options.imageQuality}
              onValueChange={(value) => setOptions({ ...options, imageQuality: value as "low" | "medium" | "high" })}
            >
              <Select.Trigger>
                <Select.Value placeholder="Select Image Quality" />
              </Select.Trigger>
              <Select.Content>
                <Select.Item value="low">Low</Select.Item>
                <Select.Item value="medium">Medium</Select.Item>
                <Select.Item value="high">High</Select.Item>
              </Select.Content>
            </Select.Root>
          </div>
        </div>
        <div className="flex w-full flex-col">
          {/* Loader */}
          {pdfIsLoading && (
            <div className="flex w-full items-center p-4">
              <Loader styleOverride="ml-2 mr-4 w-[24px] h-[24px]" />
              <div className="flex flex-col">
                <p className="font-medium">Your PDF is being rendered, please wait. </p>
                <p className="text-sm">This may take a some time for complex processes</p>
              </div>
            </div>
          )}
          {/* Buttons */}
          <div className="inline-flex w-full justify-end space-x-2 px-3 text-sm">
            <div className="font-medium">File Size: </div>
            <div>{fileSize}MB</div>
          </div>
          <div className="flex w-full gap-x-1.5 p-3">
            <button className="btn-sm serial-btn-light w-1/2" onClick={() => setEnableExportMode(false)}>
              Cancel Export
            </button>
            <button className="btn-sm serial-btn-dark w-1/2" onClick={() => handleDownloadPdf()}>
              Download PDF
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center">
        {pdfDocumentUrl && <iframe src={pdfDocumentUrl} width="100%" height="100%" />}
      </div>
    </div>
  );
};

export default ProcessBuilderExport;
