import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import DropdownSearch from "@shared/components/dropdowns/DropdownSearch";
import DatasetSelectorItem from "./DatasetSelectorItem";
import DropdownCustom from "@shared/components/dropdowns/DropdownCustom";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { DataType, ProcessType } from "@shared/types/databaseEnums";
import TypeTag from "@shared/components/TypeTag";
import { screamingSnakeCaseToTitleCase } from "@shared/utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";

// These datatypes will eventually be removed after dataset / fields refactoring
const DEPRECATED_DATA_TYPES = [DataType.Link, DataType.Uid];
const defaultFilterOptions = Object.values(DataType).reduce(
  (acc, type) => (DEPRECATED_DATA_TYPES.includes(type) ? acc : { ...acc, [type]: true }),
  {},
);

const DatasetSelector: React.FC = () => {
  const datasets = useSelector((state: RootState) => state.db.datasets).filter(
    (dataset) => !DEPRECATED_DATA_TYPES.includes(dataset.data_type) && dataset.process?.type === ProcessType.Production,
  );
  const processes = useSelector((state: RootState) => state.db.processes).filter((process) => process.type === ProcessType.Production);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const components = useSelector((state: RootState) => state.db.components);

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedComponentId, setSelectedComponentId] = useState<string | null>(null);
  const [selectedProcessId, setSelectedProcessId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterStatusOptions, setFilterOptions] = useState<Record<string, boolean>>(defaultFilterOptions);

  const toggleFilterStatusOption = (optionKey: string): void => {
    const modifiedOptions = { ...filterStatusOptions };
    modifiedOptions[optionKey] = !filterStatusOptions[optionKey];
    setFilterOptions(modifiedOptions);
  };

  const handleSelectAllFilterOptions = (): void => {
    const modifiedOptions = { ...filterStatusOptions };
    const allOptionsSelected = Object.values(modifiedOptions).every((value) => value);
    Object.keys(modifiedOptions).forEach((key) => {
      modifiedOptions[key] = !allOptionsSelected;
    });
    setFilterOptions(modifiedOptions);
  };

  // extract query parameters from url to set process and component filters
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const processId = urlParams.get("process_id");
    const componentId = urlParams.get("component_id");
    if (processId) {
      setSelectedProcessId(processId);
    }
    if (componentId) {
      setSelectedComponentId(componentId);
    }
  }, [location.search]);

  // add query parameters to url when process or component filters change
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (selectedProcessId) {
      urlParams.set("process_id", selectedProcessId);
    } else {
      urlParams.delete("process_id");
    }
    if (selectedComponentId) {
      urlParams.set("component_id", selectedComponentId);
    } else {
      urlParams.delete("component_id");
    }
    navigate({ search: urlParams.toString() });
  }, [selectedProcessId, selectedComponentId]);

  // Get list of processes to show in dropdown
  const processOptions = useMemo(() => {
    // Filter out processes that aren't related to the selected component (if one is selected)
    if (selectedComponentId) {
      const filteredComponentProcessLinks = componentProcessLinks.filter((link) => link.component_id === selectedComponentId);
      return filteredComponentProcessLinks.map((link) => ({ id: link.process_id, label: link.process?.name }));
    }
    return processes
      .filter((process) => {
        // only show processes that have at least one active link to an active component
        return (
          componentProcessLinks.find((link) => link.process_id === process.id && link.component?.is_active && link.is_active) !== undefined
        );
      })
      .map((process) => ({ id: process.id, label: process.name }));
  }, [processes, selectedComponentId, componentProcessLinks]);

  const componentOptions = useMemo(() => {
    // Filter out components that aren't related to the selected process (if one is selected)
    if (selectedProcessId) {
      const filteredComponentProcessLinks = componentProcessLinks.filter((link) => link.process_id === selectedProcessId);
      return filteredComponentProcessLinks.map((link) => ({ id: link.component_id, label: link.component?.name }));
    }
    // Show only active components
    return components.filter((component) => component.is_active).map((component) => ({ id: component.id, label: component.name }));
  }, [components, selectedProcessId, componentProcessLinks]);

  // Filter datasets based on selected component and process, type filters and search term
  const filteredDatasets = useMemo(() => {
    let newFilteredDatasets = datasets;
    if (selectedComponentId) {
      const filteredComponentProcessLinks = componentProcessLinks.filter((link) => link.component_id === selectedComponentId);
      newFilteredDatasets = newFilteredDatasets.filter((dataset) =>
        filteredComponentProcessLinks.find((link) => link.process_id === dataset.process_id),
      );
    }
    if (selectedProcessId) {
      newFilteredDatasets = newFilteredDatasets.filter((dataset) => dataset.process_id === selectedProcessId);
    }
    if (searchTerm) {
      newFilteredDatasets = newFilteredDatasets.filter((dataset) => dataset.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    // filter based on the dataset types filter
    newFilteredDatasets = newFilteredDatasets.filter((dataset) => filterStatusOptions[dataset.data_type]);
    // make sure the dataset's process_id exists in the processOptions list (otherwise we assume it's inactive)
    newFilteredDatasets = newFilteredDatasets.filter((dataset) =>
      processOptions.find((processOption) => processOption.id === dataset.process_id),
    );
    return newFilteredDatasets;
  }, [datasets, selectedComponentId, selectedProcessId, searchTerm, componentProcessLinks]);

  return (
    <div className="flex h-full w-full flex-col border-r bg-white">
      {/* Filters */}
      <div className="flex w-full flex-col items-center gap-2 border-b p-4">
        <div className="relative flex w-full gap-2">
          <input
            className="form-input w-full pl-9"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm || ""}
          ></input>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="text-serial-palette-400 absolute left-3 top-1/2 -translate-y-1/2 transform"
          />
          <DropdownCustom
            align="right"
            buttonContents={
              <div className="btn serial-btn-light h-10">
                <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                  <path d="M9 15H7a1 1 0 010-2h2a1 1 0 010 2zM11 11H5a1 1 0 010-2h6a1 1 0 010 2zM13 7H3a1 1 0 010-2h10a1 1 0 010 2zM15 3H1a1 1 0 010-2h14a1 1 0 010 2z" />
                </svg>
              </div>
            }
            className=""
            dropdownClassName="w-48"
            keepOpen={true}
          >
            <div
              className={`hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center px-4 py-1 last:mb-1.5 `}
              onClick={() => handleSelectAllFilterOptions()}
            >
              <input
                type="checkbox"
                className="form-checkbox mr-2 h-4 w-4"
                checked={Object.values(filterStatusOptions).every((value) => value)}
                readOnly
              />
              <div className="text-serial-palette-600 flex min-w-0 flex-grow text-sm font-medium">All</div>
            </div>
            <div className="my-1 w-full border-b" />
            {Object.entries(filterStatusOptions).map(([type, checked]) => {
              const labelOverrides = {
                [DataType.ParametricQuantitative as string]: "Numeric",
                [DataType.ParametricQualitative as string]: "Text",
              };
              const label = labelOverrides[type] ?? screamingSnakeCaseToTitleCase(type);
              return (
                <div
                  key={type}
                  className={`hover:bg-serial-palette-50 mb-0.5 flex w-full cursor-pointer items-center px-4 py-1 last:mb-1.5 `}
                  onClick={() => toggleFilterStatusOption(type)}
                >
                  <input type="checkbox" className="form-checkbox mr-2 h-4 w-4" checked={checked} readOnly />
                  <div className="text-serial-palette-600 flex min-w-0 flex-grow text-sm font-medium">{label}</div>
                  <TypeTag className={"w-10 text-xs"} hideText={true} type={type} />
                </div>
              );
            })}
          </DropdownCustom>
        </div>
        <div className="flex w-full flex-col items-center justify-between gap-2">
          <DropdownSearch
            options={[{ id: null, label: "Component" }, ...componentOptions]}
            selected={selectedComponentId}
            setSelected={setSelectedComponentId}
            className=""
            placeholder="Component"
            dropdownClassName="w-[280px] "
          />
          <DropdownSearch
            options={[{ id: null, label: "Process" }, ...processOptions]}
            selected={selectedProcessId}
            setSelected={setSelectedProcessId}
            className=""
            placeholder="Process"
            dropdownClassName="w-[280px]"
          />
        </div>
      </div>

      {/* List */}
      <div className="flex h-full w-full flex-col items-center divide-y overflow-y-scroll">
        {/* sort Dataset by latest_process_entries most recent submission time */}
        {filteredDatasets.map((dataset, index) => (
          <button className="flex w-full" onClick={() => navigate(`/datasets/${dataset.id}`)} key={index}>
            <DatasetSelectorItem dataset={dataset} />
          </button>
        ))}
        {filteredDatasets.length === 0 && <div className="my-6 text-sm italic">No matching results</div>}
      </div>
    </div>
  );
};

export default DatasetSelector;
