/**
 * This file is responsible for storing a lightweight local copy of the tennant's section of the database.
 * The imported data is lightweight, meaning it doesn't pull down any of the large data tables such as component
 * instances images or any other instance data.
 *
 * The data is stored in our Redux store to provide global access throughout the app. This allows any component
 * to access and interact with the tennant's data without making redundant database calls.
 */

import { createSlice } from "@reduxjs/toolkit";
import {
  Company,
  Component,
  ComponentLink,
  ComponentProcessLink,
  Dataset,
  Field,
  Operator,
  PartNumber,
  Process,
  ReportTemplate,
  Station,
  User,
  Version,
  WorkOrder,
  UniqueIdentifier,
  UniqueIdentifierActivityLog,
} from "@shared/types/databaseTypes";

const tableKeyMap: Record<string, string> = {
  company: "company",
  components: "components",
  processes: "processes",
  fields: "fields",
  component_links: "componentLinks",
  component_process_links: "componentProcessLinks",
  datasets: "datasets",
  stations: "stations",
  operators: "operators",
  part_numbers: "partNumbers",
  users: "users",
  work_orders: "workOrders",
  report_templates: "reportTemplates",
  unique_identifiers: "componentInstances",
  unique_identifier_activity_log: "uniqueIdentifierActivityLog",
  versions: "versions",
};

export interface DbReduxState {
  company: Company;
  components: Component[];
  processes: Process[];
  fields: Field[];
  componentLinks: ComponentLink[];
  componentProcessLinks: ComponentProcessLink[];
  datasets: Dataset[];
  stations: Station[];
  operators: Operator[];
  partNumbers: PartNumber[];
  users: User[];
  workOrders: WorkOrder[];
  uniqueIdentifierActivityLog: UniqueIdentifierActivityLog[];
  reportTemplates: ReportTemplate[];
  componentInstances: UniqueIdentifier[];
  versions: Version[];
  isLoaded: boolean;
}

// omit all non-array properties from DbReduxState
export interface DbReduxStateArraysOnly extends Omit<DbReduxState, "company" | "isLoaded"> {}

const initialState: DbReduxState = {
  company: {
    light_logo: null,
    dark_logo: null,
    light_icon: null,
    dark_icon: null,
    config: {},
    created_at: "",
    id: "",
    is_active: false,
    name: "",
  } as Company,
  components: [],
  processes: [],
  fields: [],
  componentLinks: [],
  componentProcessLinks: [],
  datasets: [],
  stations: [],
  operators: [],
  partNumbers: [],
  users: [],
  workOrders: [],
  uniqueIdentifierActivityLog: [],
  reportTemplates: [],
  componentInstances: [],
  versions: [],
  isLoaded: false,
};

export const dbSlice = createSlice({
  name: "db",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setComponents: (state, action) => {
      state.components = action.payload;
    },
    setProcesses: (state, action) => {
      state.processes = action.payload;
    },
    setFields: (state, action) => {
      state.fields = action.payload;
    },
    setComponentLinks: (state, action) => {
      state.componentLinks = action.payload;
    },
    setComponentProcessLinks: (state, action) => {
      state.componentProcessLinks = action.payload;
    },
    setDatasets: (state, action) => {
      state.datasets = action.payload;
    },
    setStations: (state, action) => {
      state.stations = action.payload;
    },
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
    setPartNumbers: (state, action) => {
      state.partNumbers = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setWorkOrders: (state, action) => {
      state.workOrders = action.payload;
    },
    setUniqueIdentifierActivityLog: (state, action) => {
      state.uniqueIdentifierActivityLog = action.payload;
    },
    setReportTemplates: (state, action) => {
      state.reportTemplates = action.payload;
    },
    setComponentInstances: (state, action) => {
      state.componentInstances = action.payload;
    },
    setVersions: (state, action) => {
      state.versions = action.payload;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    genericInsert: (state, action) => {
      const key = tableKeyMap[action.payload.table] as keyof DbReduxStateArraysOnly;
      const updatedData = [...state[key], action.payload.record];
      state[key] = updatedData;
    },
    genericUpdate: (state, action) => {
      const key = tableKeyMap[action.payload.table] as keyof DbReduxStateArraysOnly;
      state[key] = state[key].map((item: any) => {
        if (item.id === action.payload.record.id) {
          return { ...item, ...action.payload.record };
        }
        return item;
      });
    },
    genericDelete: (state, action) => {
      const key = tableKeyMap[action.payload.table] as keyof DbReduxStateArraysOnly;
      state[key] = (state[key] as any[]).filter((item: any) => item.id !== action.payload.record.id);
    },
    reduxDbReset: () => initialState,
  },
});

export const {
  setCompany,
  setComponents,
  setProcesses,
  setFields,
  setComponentLinks,
  setComponentProcessLinks,
  setDatasets,
  setStations,
  setOperators,
  setPartNumbers,
  setUsers,
  setWorkOrders,
  setUniqueIdentifierActivityLog,
  setReportTemplates,
  setComponentInstances,
  setVersions,
  genericInsert,
  genericUpdate,
  genericDelete,
  setIsLoaded,
  reduxDbReset,
} = dbSlice.actions;

export default dbSlice.reducer;
