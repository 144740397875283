export enum ApiKeyPermission {
  Read = "READ",
  Write = "WRITE",
}

export interface ApiKey {
  key: string;
  name: string;
  permissions: ApiKeyPermission[];
  created_at: string;
  pretty_name?: string;
  allowed_components_ids?: string[];
  allowed_process_ids?: string[];
}

export interface DeletedApiKey {
  name: string;
}

export interface ParsedResponse<DataType = any> {
  data: DataType|undefined;
  error: string|undefined;
}

// This doesn't currently belong to any table, but it's a useful enum to convert between apiTypes
// should eventually be moved to a table as a database enum

export enum ProcessDataType {
  Text = "TEXT",
  Numerical = "NUMERICAL",
  File = "FILE",
  Image = "IMAGE",
  Boolean = "BOOLEAN",
  Datetime = "DATETIME",
}
