import { useEffect, useState, ChangeEvent } from "react";
import Transition from "@shared/components/Transition";
import { Loader } from "@shared/components/Loader";
import Banner2 from "@shared/components/Banner2";
import { ApiKey, ApiKeyPermission } from "@shared/types/apiTypes";
import { ErrorBanner } from "@shared/components/error/types";
import { createApiKey } from "@shared/connections/api/auth";

interface SettingsApiModalProps {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  reloadApiKeyTable: () => void;
  apiKeys: ApiKey[];
}

// Consider moving this to the connections folder inside the auth connection file or perhaps even to validation on the server
const validateApiKey = (apiKey: ApiKey, onCreate: boolean, apiKeys: ApiKey[]): ErrorBanner => {
  let type: "error" | "warning" | "success" = "error";
  if (!onCreate) {
    type = "warning";
  }
  // --- CHECK 1 --- : Check if the component name is not empty
  if (apiKey.name.length === 0) {
    return { isValid: false, message: "API Key name cannot be empty.", type: type, hide: false };
  }
  // --- CHECK 2 --- : Check if permisssions exist
  if (apiKey.permissions.length === 0) {
    return { isValid: false, message: "You must include at least one permission.", type: type, hide: false };
  }
  // --- CHECK 3 --- : Check that the consumer name is valid
  const alphanumericLowercaseWithHyphenRegex = /^[a-z0-9-]+$/;
  if (!alphanumericLowercaseWithHyphenRegex.test(apiKey.name)) {
    return { isValid: false, message: "API Key names must include only numbers, lower case letters and hyphens.", type: type, hide: false };
  }
  // --- CHECK 4 --- : API key names must be unique
  if (apiKeys.some((key) => key.name === apiKey.name)) {
    return { isValid: false, message: "API Key names must be unique.", type: type, hide: false };
  }
  return { isValid: true, message: "", type: "success", hide: true };
};

const SettingsApiModal: React.FC<SettingsApiModalProps> = ({ modalOpen, setModalOpen, reloadApiKeyTable, apiKeys }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorBanner>({ isValid: true, message: "", type: "success", hide: true });
  const [newApiKey, setNewApiKey] = useState<ApiKey>({
    name: "",
    key: "",
    permissions: [ApiKeyPermission.Read, ApiKeyPermission.Write],
    created_at: "",
  });

  const handleSetApiKeyName = (e: ChangeEvent<HTMLInputElement>) => {
    let newApiKeyName: string = e.target.value.toLowerCase().replace(/ /g, "-");
    let updatedApiKey: ApiKey = { ...newApiKey, name: newApiKeyName };
    setNewApiKey(updatedApiKey);
    const validationError = validateApiKey(updatedApiKey, false, apiKeys);
    setError(validationError);
  };

  // reset the modal when it opens
  useEffect(() => {
    setNewApiKey({ name: "", key: "", permissions: [ApiKeyPermission.Read, ApiKeyPermission.Write], created_at: "" });
    setError({ isValid: true, message: "", type: "success", hide: true });
  }, [modalOpen]);

  // Create a new API key
  const handleCreate = async () => {
    setIsLoading(true);
    const validationError = validateApiKey(newApiKey, true, apiKeys);
    if (validationError.isValid) {
      let createdKey: ApiKey = await createApiKey(newApiKey.name);
      if (createdKey?.key?.length > 0) {
        setError({ isValid: true, message: "API Key successfully created!", type: "success", hide: false });
        reloadApiKeyTable();
        setTimeout(() => {
          setModalOpen(false);
        }, 500);
      } else {
        setError({
          isValid: false,
          message: "API Key creation failed. Please try again or contact Serial support.",
          type: "error",
          hide: false,
        });
      }
    } else {
      setError(validationError);
    }
    setIsLoading(false);
  };

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="light fixed inset-0 z-50 bg-[rgba(0,0,0,0.7)] transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
        appear={null}
      />
      {/* Modal dialog */}
      <Transition
        id="override-modal"
        className="fixed inset-0 z-50 my-4 flex items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
        appear={null}
      >
        <div className="max-h-full w-full max-w-3xl overflow-auto rounded border bg-white">
          {/* Modal Formatting */}
          <div className="border-serial-palette-200 border-b">
            {/* Header */}
            <div className="flex items-center justify-between border-b px-5 py-3">
              <div className="inline-flex items-end space-x-2">
                <div className="text-serial-palette-800 font-semibold">Create New API Key</div>
              </div>
              <button className="text-serial-palette-400 hover:text-serial-palette-500" onClick={() => setModalOpen(false)}>
                <div className="sr-only">Close</div>
                <svg className="h-4 w-4 fill-current">
                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            {isLoading ? (
              <div className="flex w-full items-center justify-center py-16">
                <Loader styleOverride="w-16" />
              </div>
            ) : (
              <div className="flex flex-col gap-y-4 px-5 py-4">
                <Banner2 type={error.type} open={!error.hide} setOpen={(value: boolean) => setError({ ...error, hide: value })}>
                  {error.message}
                </Banner2>

                {/* API Key Name */}
                <div>
                  <label className="mb-1 block text-sm font-medium">
                    Name<span className="pl-1 text-rose-500">*</span>{" "}
                  </label>
                  <input
                    name="name"
                    value={newApiKey.name}
                    className="form-input w-full"
                    type="text"
                    onChange={(e) => handleSetApiKeyName(e)}
                    placeholder="my-new-api-key"
                    required
                  />
                </div>
              </div>
            )}

            {/* Divider */}
            <div className="border-serial-palette-200 border-t"></div>

            {/* Footer */}
            <div className="flex w-full justify-end px-3 py-2">
              <button className="btn-sm bg-serial-palette-800 hover:bg-serial-palette-600 text-white" onClick={() => handleCreate()}>
                Create
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default SettingsApiModal;
