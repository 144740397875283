import { useMemo } from "react";
import { FilterConditionOperator } from "@shared/types/databaseTypes";
import { allowedFilterConditionOperators, filterConditionOperatorStyle } from "@shared/measures/types";
import useMeasures from "@shared/measures/MeasuresProvider";

enum DropdownMode {
  Filters = "FILTERS",
  Values = "VALUES",
  Operators = "OPERATORS",
}

const MeasuresFilterBuilderOperatorSelector = ({
  focusedFilterConditionIndex,
  setSubDropdownMode,
}: {
  focusedFilterConditionIndex: number;
  setSubDropdownMode: (mode: DropdownMode | null) => void;
}) => {
  const { filterBy, handleSetFilterConditionOperator } = useMeasures();

  const focusedFilter = useMemo(() => {
    return filterBy[focusedFilterConditionIndex] ?? null;
  }, [focusedFilterConditionIndex, filterBy]);

  return (
    <>
      {focusedFilter &&
        allowedFilterConditionOperators[focusedFilter.key.type].map((operator: FilterConditionOperator, index: number) => {
          return (
            <div
              key={index}
              className="btn-sm hover:bg-serial-palette-100 flex cursor-pointer justify-start rounded-none bg-white first:mt-2"
              onClick={() => {
                handleSetFilterConditionOperator(focusedFilterConditionIndex, operator);
                setSubDropdownMode(null);
              }}
            >
              <span className="w-7 whitespace-nowrap font-bold">{filterConditionOperatorStyle[operator].symbol}</span>
              <span className="whitespace-nowrap font-normal">{filterConditionOperatorStyle[operator].label}</span>
            </div>
          );
        })}
    </>
  );
};

export default MeasuresFilterBuilderOperatorSelector;
