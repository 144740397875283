import { useContext, useMemo, useRef } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { HeadingBlock } from "@shared/types/databaseTypes";
import { ProcessBuilderWorkInstructionBlockContentProps } from "../../types";
import ContentEditable from "@shared/components/ContentEditable";
import { workInstructionBlocksToMarkdown } from "../../helpers";
import { generateWorkInstructionsSuggestions } from "../../connections/api";

const ProcessBuilderWorkInstructionBlockHeading: React.FC<ProcessBuilderWorkInstructionBlockContentProps<HeadingBlock>> = ({
  block,
  stepIndex,
  blockIndex,
  placeholderInputRef,
}) => {
  const { readOnly, handleUpdateWorkInstructionBlocks, handleDeleteWorkInstructionBlock, draftProcess } = useContext(ProcessBuilderContext);
  const contentRef = useRef<HTMLDivElement>(null);

  const sizeClass = useMemo(() => {
    switch (block.content.level) {
      case 1:
        return "text-2xl";
      case 2:
        return "text-xl";
      case 3:
        return "text-lg";
    }
  }, [block.content.level]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // move to placeholder when enter is pressed
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent new line in content editable
      placeholderInputRef?.current?.focus();
    }
    if (event.key === "Backspace" && contentRef.current?.innerText === "") {
      handleDeleteWorkInstructionBlock(stepIndex, blockIndex);
    }
  };

  const getCopilotSuggestion = async () => {
    const workInstructionsPriorToCurrentStep =
      draftProcess?.process_steps.slice(0, stepIndex).flatMap((step) => step.work_instruction_blocks) ?? [];
    const workInstructionsUpToCursor = draftProcess?.process_steps[stepIndex]?.work_instruction_blocks?.slice(0, blockIndex + 1) ?? [];
    const markdownWorkInstructions = workInstructionBlocksToMarkdown([
      ...workInstructionsPriorToCurrentStep,
      ...workInstructionsUpToCursor,
    ]);
    return generateWorkInstructionsSuggestions(markdownWorkInstructions);
  };

  return (
    <div className="flex w-full" id={block.id} onClick={() => contentRef.current?.focus()}>
      <ContentEditable
        contentRef={contentRef}
        onKeyDown={handleKeyDown}
        onInput={() => handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "text", contentRef.current?.innerText, true)}
        className={`w-full font-bold outline-0 ${sizeClass}`}
        placeholder="Heading"
        content={block.content.text}
        copilot={true}
        handleAcceptCopilotSuggestion={(newContent) => handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "text", newContent, true)}
        getCopilotSuggestion={() => getCopilotSuggestion()}
        readOnly={readOnly}
      />
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlockHeading;
