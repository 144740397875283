import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";

interface DropdownProps {
  align?: "left" | "right";
  size?: "sm" | "md" | "lg";
  contents?: React.ReactNode;
  open?: boolean;
  onChange?: (open: boolean) => void;
  closeOnClick?: boolean;
  closeOnMouseLeave?: boolean;
  disabled?: boolean;
}

const MOUSE_OUT_BUFFER_PX = 40;

const Dropdown: React.FC<PropsWithChildren<DropdownProps>> = ({
  size = "md",
  align = "left",
  contents,
  open,
  onChange,
  closeOnClick,
  closeOnMouseLeave,
  children,
  disabled,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeOnMouseLeaveBoundaryRef = useRef<HTMLDivElement>(null);

  const widthPx = useMemo(() => {
    if (size === "sm") return 192; // w-48
    if (size === "md") return 256; // w-64
    if (size === "lg") return 384; // w-96
    return 256;
  }, [size]);

  const heightPx = useMemo(() => {
    return dropdownRef.current?.clientHeight ?? 50;
  }, [dropdownRef.current]);

  const handleOpenDropdown = () => {
    if (!buttonRef.current) return;
    const { x, y, right } = buttonRef.current.getBoundingClientRect();
    const xPosition = align === "left" ? x : right - widthPx; // 384 is the width of the dropdown
    setDropdownPosition({ x: xPosition, y });
    setShowDropdown(true);
  };

  const handleCloseOnMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!closeOnMouseLeaveBoundaryRef.current || !dropdownRef.current) return;
    // use cursor position to check if mouse is outside of the closeOnMouseLeaveBoundaryRef, close the dropdown
    const boundaryLimits = closeOnMouseLeaveBoundaryRef.current.getBoundingClientRect();
    const insideX = e.clientX >= boundaryLimits.left && e.clientX <= boundaryLimits.right;
    const insideY = e.clientY >= boundaryLimits.top && e.clientY <= boundaryLimits.bottom;
    if (!insideX || !insideY) setShowDropdown(false);
  };

  // close on click
  useEffect(() => {
    const clickHandler = (e: any) => {
      if (!dropdownRef.current || !buttonRef.current) return;
      // if the click is outside of the dropdown and button, close the dropdown
      if (!dropdownRef.current.contains(e.target) && !buttonRef.current.contains(e.target)) setShowDropdown(false);
      // otherwise, propagate the click forwards and close the dropdown if closeOnClick is true
      if (closeOnClick && (dropdownRef.current.contains(e.target) || buttonRef.current.contains(e.target))) {
        e.target?.click();
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", clickHandler);
    return () => document.removeEventListener("mousedown", clickHandler);
  });

  // close on

  useEffect(() => {
    if (onChange) onChange(showDropdown);
  }, [showDropdown]);

  useEffect(() => {
    if (open !== undefined) setShowDropdown(open);
  }, [open]);

  return (
    <div className="flex">
      <button
        ref={buttonRef}
        disabled={disabled ? true : false}
        className="flex h-fit w-fit"
        onClick={() => handleOpenDropdown()}
        tabIndex={-1}
      >
        {children}
      </button>
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="border-serial-palette-200 z-20 flex flex-col overflow-hidden rounded-md border bg-white shadow-lg"
          style={{ position: "fixed", left: `${dropdownPosition.x}px`, top: `${dropdownPosition.y + 32}px`, width: `${widthPx}px` }}
        >
          <div className="flex w-full">{contents}</div>
        </div>
      )}
      {closeOnMouseLeave && showDropdown && (
        <div
          ref={closeOnMouseLeaveBoundaryRef}
          className="z-10 h-full w-full"
          style={{
            position: "fixed",
            width: `${widthPx + MOUSE_OUT_BUFFER_PX * 2}px`,
            height: `${heightPx + MOUSE_OUT_BUFFER_PX * 2}px`,
            left: `${dropdownPosition.x - MOUSE_OUT_BUFFER_PX}px`,
            top: `${dropdownPosition.y + 32 - MOUSE_OUT_BUFFER_PX}px`,
          }}
          onMouseLeave={(e) => handleCloseOnMouseLeave(e)}
        />
      )}
    </div>
  );
};

export default Dropdown;
