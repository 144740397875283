import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@shared/components/Loader";
import { getSupabase } from "@shared/connections/supabaseAuth";

const AuthSignIn: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [wrongCredentials, setWrongCredentials] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickSignIn = async (email: string, password: string) => {
    setIsLoading(true);
    const supabase = getSupabase();
    const { error } = await supabase.auth.signInWithPassword({ email: email, password: password });
    if (error) {
      setWrongCredentials(true);
    } else {
      setWrongCredentials(false);
      navigate("/home");
    }
    setIsLoading(false);
  };

  // Trigger handleClickSignIn() on enter key press
  useEffect(() => {
    const handleEnterKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleClickSignIn(email, password);
      }
    };
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [email, password]);

  // Reset wrongCredentials state when user starts typing again
  useEffect(() => {
    setWrongCredentials(false);
  }, [email, password]);

  return (
    <div className="flex h-full w-full flex-col items-center px-10">
      <div className="pb-2 text-2xl font-medium">Welcome Back</div>
      <div className="pb-6">Sign in to Serial.</div>
      <input
        cypress-id="email"
        type="email"
        placeholder="Email Address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className={`form-input focus:ring-serial-palette-600 focus:border-serial-palette-600 mb-4 w-full text-lg focus:ring-1 ${wrongCredentials ? "border-red-500" : "border-serial-palette-300"} `}
      />
      <input
        cypress-id="password"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className={`form-input focus:ring-serial-palette-600 focus:border-serial-palette-600 mb-4 w-full text-lg focus:ring-1 ${wrongCredentials ? "border-red-500" : "border-serial-palette-300"} `}
      />
      {wrongCredentials && (
        <div className="mt-1 inline-flex w-full space-x-2 pb-4 text-left">
          <FontAwesomeIcon icon={faExclamationCircle} className="pt-[2px] text-red-500" />
          <div className="text-sm text-red-500">Wrong email or password</div>
        </div>
      )}
      <button
        className="btn bg-serial-palette-900 mb-4 h-12 w-full justify-center rounded-md text-white hover:cursor-pointer"
        onClick={() => handleClickSignIn(email, password)}
      >
        {isLoading ? <Loader styleOverride="w-6 h-6" /> : "Continue"}
      </button>
      <div className="w-full text-center text-sm font-bold hover:cursor-pointer" onClick={() => navigate("/auth/forgotpassword")}>
        Forgot password?
      </div>
    </div>
  );
};

export default AuthSignIn;
