import { faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { ParsedResponse } from "@shared/types/apiTypes";
import { cn } from "@shared/utils/tailwind";

interface ContentEditableProps {
  contentRef?: RefObject<HTMLDivElement>;
  onInput?: (event: React.FormEvent<HTMLDivElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  content?: string;
  className?: string;
  containerClassName?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  copilot?: boolean;
  handleAcceptCopilotSuggestion?: (newContent: string) => void;
  getCopilotSuggestion?: (newContent: string) => Promise<ParsedResponse>;
  readOnly?: boolean;
}

const setCursorToEnd = (element: HTMLElement | null) => {
  if (!element) return;
  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  selection?.removeAllRanges();
  selection?.addRange(range);
};

const ContentEditable: React.FC<ContentEditableProps> = ({
  contentRef,
  onInput,
  onKeyDown,
  onClick,
  onFocus,
  onBlur,
  content,
  className,
  containerClassName,
  style,
  placeholder,
  copilot,
  handleAcceptCopilotSuggestion,
  getCopilotSuggestion,
  readOnly,
}) => {
  const [suggestion, setSuggestion] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const suggestionTimer = useRef<any>(null);
  const company = useSelector((state: RootState) => state.db.company);

  // Set cursor to the end of the content editable div on focus
  useEffect(() => {
    if (!contentRef) return;
    contentRef.current?.addEventListener("focus", () => setCursorToEnd(contentRef.current));
    return () => {
      contentRef.current?.removeEventListener("focus", () => setCursorToEnd(contentRef.current));
    };
  }, [contentRef]);

  // Update content editable div with content
  useEffect(() => {
    if (!contentRef || !contentRef.current || contentRef.current.innerText === content || content === undefined) return;
    contentRef.current.innerText = content;
    setCursorToEnd(contentRef.current);
  }, [content, contentRef]);

  const triggerCopilot = (currContent: string) => {
    setSuggestion("");
    if (!copilot || !company.config.allow_ai || !company.config.work_instructions_copilot) return;
    // set a timer to get a new suggestion
    clearTimeout(suggestionTimer.current);
    suggestionTimer.current = setTimeout(() => {
      getSuggestion(currContent);
    }, 1000);
  };

  const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const selection = window.getSelection();
    if (!contentRef || !contentRef.current) return;
    if (!selection || !selection.rangeCount) return false;

    // Get the text from the clipboard and insert it into the content editable div
    const text = event.clipboardData.getData("text/plain");
    selection.deleteFromDocument();
    const range = selection.getRangeAt(0);
    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    // Move the cursor to the end of the inserted text
    const newRange = document.createRange();
    newRange.setStartAfter(textNode);
    newRange.setEndAfter(textNode);
    selection.removeAllRanges();
    selection.addRange(newRange);

    // Dispatch and input event to trigger onInput (since we are changing the content programmatically and parent component might be listening to onInput)
    const inputEvent = new Event("input", { bubbles: true });
    contentRef.current.dispatchEvent(inputEvent);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // if tab key is pressed append the suggestion to the content
    if (event.key === "Tab" && handleAcceptCopilotSuggestion) {
      event.preventDefault();
      event.stopPropagation();
      handleAcceptCopilotSuggestion(`${content ?? ""}${suggestion}`);
      setSuggestion("");
      return;
    }
  };

  const handleBlurCopilot = (e: React.FocusEvent<HTMLDivElement>) => {
    clearTimeout(suggestionTimer.current);
    setSuggestion("");
    onBlur?.(e);
  };

  const handleFocusCopilot = (e: React.FocusEvent<HTMLDivElement>) => {
    triggerCopilot(contentRef?.current?.innerText ?? "");
    onFocus?.(e);
  };

  const getSuggestion = async (currContent: string) => {
    if (!getCopilotSuggestion) return;
    setIsLoading(true);
    const { data } = await getCopilotSuggestion(currContent);
    const suggestion = data?.suggestions?.[0] ?? "";
    // if content hasn't changed and content editable div is still focused, set the suggestion
    if (contentRef?.current?.innerText === currContent && document.activeElement === contentRef?.current && suggestion) {
      setSuggestion(suggestion);
    }
    setIsLoading(false);
  };

  return (
    <div className={cn("relative w-full", containerClassName)}>
      <div
        contentEditable={!readOnly}
        ref={contentRef}
        onClick={onClick}
        onInput={onInput}
        onPaste={onPaste}
        onKeyUp={() => triggerCopilot(contentRef?.current?.innerText ?? "")}
        onKeyDown={onKeyDown}
        onKeyDownCapture={copilot ? (e) => handleKeyDown(e) : undefined}
        onFocus={copilot ? (e) => handleFocusCopilot(e) : onFocus}
        onBlur={copilot ? (e) => handleBlurCopilot(e) : onBlur}
        className={copilot ? `${className} ${content || contentRef?.current?.innerText ? "inline" : "block"}` : className}
        style={style}
        // @ts-ignore - placeholder has been added as a custom attribute for content editable div
        placeholder={copilot ? `${suggestion ? "" : placeholder}` : placeholder}
      />
      {copilot && <div className={`text-violet-300 ${className} inline opacity-100`}>{suggestion}</div>}
      {(isLoading || suggestion) && copilot && (
        <FontAwesomeIcon
          icon={faMagicWandSparkles}
          className="absolute -bottom-1 -right-8 animate-pulse bg-white text-violet-500"
          size="xs"
        />
      )}
    </div>
  );
};

export default ContentEditable;
