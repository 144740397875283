import { CycleTimeByDate, ProcessCycleTimeByDate } from "../types";
import { getRpcWithRetries } from "@shared/connections/supabaseGeneral";

// ------------------- READ FUNCTIONS ------------------- //

/*
Function: calculate_average_cycle_time

Description:
Calculates the daily average cycle time for a specified component within a date range. 
The average is determined by summing the cycle times of all process entries per day, 
divided by the number of unique component instances completed that day.

Parameters:
- component_id_arg (UUID): Identifier for the target component.
- start_date_arg (DATE): Start of the date range.
- end_date_arg (DATE): End of the date range.

Process:
Iterates through each day in the range, calculating total cycle time and counting component instances for that day. 
The daily average cycle time is then computed. Results for each day are stored in two arrays: 'cycle_times' (average cycle times) 
and 'dates' (corresponding dates in 'YYYY-MM-DD' format).

Returns:
- JSON object with two arrays: 'cycle_times' and 'dates'.

Note:
Average cycle time is set to zero for days with no completed instances to avoid division by zero.

Usage:
Call from an application or database tool supporting PostgreSQL functions. Useful for analyzing component processing efficiency over time.
*/

export const fetchCycleTimeByComponentEdgeFunction = async (
  component_id: string,
  start_date: Date,
  end_date: Date,
): Promise<CycleTimeByDate> => {
  let { data, error } = await getRpcWithRetries<CycleTimeByDate>("calculate_cycle_time_by_component", {
    component_id_arg: component_id,
    start_date_arg: start_date,
    end_date_arg: end_date,
  });

  if (error || !data) {
    console.error(error);
    return { dates: [], cycle_times: [], total_entries: [], num_unique_ids: [], retest_rates: [] };
  }

  return data;
};

/*
Function: calculate_cycle_time_by_process

Description:
Calculates average cycle times for each production process linked to a specific component over a given date range. 
Each day, the function iterates through production processes, summing the 'cycle_time' from 'process_entries' where 
'process_id' matches and counting distinct 'unique_identifier_ids'. It computes the daily average cycle time by dividing 
the total cycle time by the number of unique instances. The results are structured in a JSONB object, mapping each 
process name to its 'cycle_times' and 'dates', facilitating data visualization like line charts for process performance analysis.

Parameters:
- component_id_arg (UUID): Identifier for the target component.
- start_date_arg (DATE): Start of the date range.
- end_date_arg (DATE): End of the date range.

Process:
Iterates through each day within the range, aggregating cycle times and counting unique component instances per process. 
Computes average cycle time per process per day. Aggregates results in a JSONB object, with each key as a process name and 
values containing 'cycle_times' and 'dates' arrays.

Returns:
- JSONB object: Each key is a process name; each value is a JSON object with 'cycle_times' and 'dates' arrays.

Note:
Sets average cycle time to zero for days or processes with no data to prevent division by zero.

Usage:
Ideal for data visualization in frontend applications, offering insights into daily process performance, helping to identify trends or areas for optimization.
*/

export const fetchCycleTimeByProcessEdgeFunction = async (
  component_id: string,
  start_date: Date,
  end_date: Date,
): Promise<ProcessCycleTimeByDate> => {
  let { data, error } = await getRpcWithRetries<ProcessCycleTimeByDate>("calculate_cycle_time_by_process", {
    component_id_arg: component_id,
    start_date_arg: start_date,
    end_date_arg: end_date,
  });

  if (error || !data) {
    console.error(error);
    // Return an object that matches the CycleTimeByDate interface
    return {};
  }

  return data;
};
