import { RootState } from "@shared/redux/store";
import { ComponentLink, ComponentProcessLink } from "@shared/types/databaseTypes";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export interface ComponentGenealogyFamilyMember {
  parent_link: ComponentLink | null; // null for root component
  id: string;
  name: string;
  component_type: string;
  children: ComponentGenealogyFamilyMember[];
}

const fetchComponentChildren = (
  componentId: string,
  componentLinks: ComponentLink[],
  componentProcessLinks: ComponentProcessLink[],
): ComponentGenealogyFamilyMember[] => {
  let componentChildren: ComponentGenealogyFamilyMember[] = [];
  componentLinks
    .filter((componentLink) => {
      // only add component links that are children of the given component and currently active
      return componentLink.component_id === componentId && componentLink.is_active !== false;
    })
    .sort((a, b) => {
      // sort by the order of the process that created the link
      const processALink = componentProcessLinks.find((link) => link.process_id === a.process_id && link.component_id === a.component_id);
      const processBLink = componentProcessLinks.find((link) => link.process_id === b.process_id && link.component_id === b.component_id);
      return (processALink?.order ?? 0) - (processBLink?.order ?? 0);
    })
    .map((componentLink) => {
      // only add child component if the process that created the link is active
      const componentLinkCreationProcess = componentProcessLinks.find(
        (link) => link.process_id === componentLink.process_id && link.component_id === componentLink.component_id,
      );
      if (componentLinkCreationProcess && componentLinkCreationProcess?.is_active !== false && componentLink.child_component) {
        const newComponentChild = {
          parent_link: componentLink,
          id: componentLink.child_component.id,
          name: componentLink.child_component.name,
          component_type: componentLink.child_component.component_type,
          children: fetchComponentChildren(componentLink.child_component.id, componentLinks, componentProcessLinks),
        };
        componentChildren.push(newComponentChild);
      }
    });
  return componentChildren;
};

// Function to get all descendent component IDs for a given genealogy tree
export const getGenealogyComponentIds = (genealogy: ComponentGenealogyFamilyMember[]): string[] => {
  let componentIds: string[] = [];
  for (const familyMember of genealogy) {
    componentIds.push(familyMember.id);
    componentIds = componentIds.concat(getDescendentComponentIds(familyMember.children));
  }
  // Remove duplicates
  return [...new Set(componentIds)];
};

// Function to get all descendent component IDs for a given genealogy tree
export const getDescendentComponentIds = (genealogy: ComponentGenealogyFamilyMember[]): string[] => {
  let descendentComponentIds: string[] = [];
  for (const familyMember of genealogy) {
    // if the family member is not the root component, add it to the list of descendent component IDs
    if (familyMember.parent_link !== null) {
      descendentComponentIds.push(familyMember.id);
    }
    descendentComponentIds = descendentComponentIds.concat(getDescendentComponentIds(familyMember.children));
  }
  // Remove duplicates
  return [...new Set(descendentComponentIds)];
};

export const getDescendentComponentLinks = (genealogy: ComponentGenealogyFamilyMember[]): ComponentLink[] => {
  let descendentComponentLinks: ComponentLink[] = [];
  for (const familyMember of genealogy) {
    if (familyMember.parent_link) {
      descendentComponentLinks.push(familyMember.parent_link);
    }
    descendentComponentLinks = descendentComponentLinks.concat(getDescendentComponentLinks(familyMember.children));
  }
  return descendentComponentLinks;
};

// Function to get the level of a component in a genealogy tree. Returns 0 if the component is not in the tree.
export const getDescendentComponentLevel = (
  genealogy: ComponentGenealogyFamilyMember[],
  componentId: string,
  level: number = 0,
): number => {
  for (const familyMember of genealogy) {
    if (familyMember.id === componentId) {
      return level;
    }
    const descendentComponentLevel = getDescendentComponentLevel(familyMember.children, componentId, level + 1);
    if (descendentComponentLevel !== 0) {
      return descendentComponentLevel;
    }
  }
  return 0;
};

const useComponentGenealogy = (componentId?: string): ComponentGenealogyFamilyMember[] => {
  const componentLinks = useSelector((state: RootState) => state.db.componentLinks);
  const componentProcessLinks = useSelector((state: RootState) => state.db.componentProcessLinks);
  const components = useSelector((state: RootState) => state.db.components);

  const componentGenealogyTree = useMemo(() => {
    const component = components.find((component) => component.id === componentId);
    if (!componentId || !component) return [];
    return [
      {
        parent_link: null,
        id: componentId,
        name: component.name,
        component_type: component.component_type,
        children: fetchComponentChildren(componentId, componentLinks, componentProcessLinks),
      },
    ];
  }, [componentLinks, componentProcessLinks, components, componentId]);

  return componentGenealogyTree;
};

export default useComponentGenealogy;
