// format a string by styling substrings and backticks
export const formatOpenaiMessage = (str: string): React.ReactNode => {
  const substrings = str.split(/(`.*?`)/g);
  return (
    <>
      {substrings.map((substring, index) => {
        if (substring.startsWith("`")) {
          return (
            <span key={index} className="bg-serial-palette-200 text-serial-palette-700 rounded px-1 py-0.5 font-mono text-sm">
              {substring.replace(/`/g, "")}
            </span>
          );
        } else {
          return <span key={index}>{substring}</span>;
        }
      })}
    </>
  );
};
