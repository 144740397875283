import { Popup } from "react-map-gl";
import ComponentAvatar from "@shared/components/primitives/ComponentAvatar";
import { useTracking } from "../TrackingProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { GeoLocation } from "../types";
import useMeasures from "@shared/measures/MeasuresProvider";
import Button from "@shared/components/primitives/Button";

const TrackingPopup = () => {
  const { uniqueIdentifiers } = useMeasures();
  const {
    selectedUniqueIdentifier,
    setSelectedUniqueIdentifier,
    selectedUniqueIdentifierLocationIndex,
    setSelectedUniqueIdentifierLocationIndex,
    selectedComponent,
    uniqueIdentifierLocations,
  } = useTracking();

  const [matchingLocationIndex, setMatchingLocationIndex] = useState<number>(0);

  const { latitude, longitude } = useMemo(() => {
    if (!selectedUniqueIdentifier) return { latitude: null, longitude: null };
    const location: GeoLocation | undefined =
      uniqueIdentifierLocations[selectedUniqueIdentifier.id]?.[selectedUniqueIdentifierLocationIndex];
    return { latitude: location?.latitude ?? null, longitude: location?.longitude ?? null };
  }, [selectedUniqueIdentifier, selectedUniqueIdentifierLocationIndex, uniqueIdentifierLocations]);

  const uniqueIdentifiersWithMatchingLocations = useMemo(() => {
    return uniqueIdentifiers.filter((uniqueIdentifier) => {
      const locations: GeoLocation[] | undefined = uniqueIdentifierLocations[uniqueIdentifier.id];
      return (locations ?? []).some((location) => location.latitude === latitude && location.longitude === longitude);
    });
  }, [uniqueIdentifiers, uniqueIdentifierLocations, latitude, longitude]);

  useEffect(() => {
    if (matchingLocationIndex >= uniqueIdentifiersWithMatchingLocations.length) {
      setMatchingLocationIndex(0);
    }
  }, [matchingLocationIndex, uniqueIdentifiersWithMatchingLocations]);

  const handleNextPrev = (direction: "next" | "prev") => {
    let newIndex;
    if (direction === "next") {
      newIndex = matchingLocationIndex + 1;
      if (newIndex >= uniqueIdentifiersWithMatchingLocations.length) {
        newIndex = 0;
      }
    } else {
      newIndex = matchingLocationIndex - 1;
      if (newIndex < 0) {
        newIndex = uniqueIdentifiersWithMatchingLocations.length - 1;
      }
    }
    setMatchingLocationIndex(newIndex);
    setSelectedUniqueIdentifier(uniqueIdentifiersWithMatchingLocations[newIndex]);
  };

  if (!latitude || !longitude || !selectedUniqueIdentifier) return null;

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      onClose={() => {
        setSelectedUniqueIdentifier(null);
        setSelectedUniqueIdentifierLocationIndex(0);
      }}
      closeButton={false}
      closeOnClick={true}
      offset={20}
    >
      <div className="flex w-56 flex-col rounded-md border bg-white text-base shadow-lg">
        <div className="flex items-center justify-between border-b py-1.5 pl-3 pr-2 text-xs">
          <div>{`${matchingLocationIndex + 1} of ${uniqueIdentifiersWithMatchingLocations.length}`}</div>
          <div className="flex">
            <Button
              onClick={() => handleNextPrev("prev")}
              variant="ghost"
              size="xs"
              className="h-5 w-4"
              disabled={uniqueIdentifiersWithMatchingLocations.length <= 1}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </Button>
            <Button
              onClick={() => handleNextPrev("next")}
              variant="ghost"
              size="xs"
              className="h-5 w-4"
              disabled={uniqueIdentifiersWithMatchingLocations.length <= 1}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 p-2">
          {selectedComponent && (
            <div className="flex w-full gap-2">
              <ComponentAvatar component={selectedComponent} size="md" className="rounded border" />
              <div className="flex w-full flex-grow flex-col overflow-hidden">
                <Link to={`/snlookup?uid=${selectedUniqueIdentifier.id}`}>
                  <div className="cursor-pointer truncate hover:underline">{selectedUniqueIdentifier.identifier}</div>
                </Link>
                <Link to={`/component/${selectedComponent.id}`}>
                  <div className="hover-underline cursor-pointer truncate text-sm font-semibold">{selectedComponent.name}</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default TrackingPopup;
