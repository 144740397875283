import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { fetchLatestProcessEntries } from "../connections/supabaseLatestActivity";
import ComponentAvatar from "@shared/components/ComponentAvatar";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import TypeTag from "@shared/components/TypeTag";
import { ProcessEntry } from "@shared/types/databaseTypes";
import { RootState } from "@shared/redux/store";
import { Loader } from "@shared/components/Loader";
import UserAvatar from "@shared/components/UserAvatar";
import DropdownSearch from "@shared/components/dropdowns/DropdownSearch";
import PaginationFooter from "@shared/components/PaginationFooter";
import { ObservabilityContext } from "@shared/context/ObservabilityProvider";

const HomeLatestActivity: React.FC = () => {
  const componentInstances = useSelector((state: RootState) => state.db.componentInstances);
  const dbIsLoaded = useSelector((state: RootState) => state.db.isLoaded);
  const components = useSelector((state: RootState) => state.db.components).filter((component) => component.is_active);
  const operators = useSelector((state: RootState) => state.db.operators);
  const users = useSelector((state: RootState) => state.db.users);

  const [lastProcessEntries, setLastProcessEntries] = useState<ProcessEntry[]>([]);
  const [hoverIdentifierIndex, setHoverIdentifierIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(!dbIsLoaded);
  const [filteredComponentId, setFilteredComponentId] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  // filter component instances and sort by last updated
  const filteredComponentInstances = useMemo(() => {
    let newFilteredComponentInstances = [...componentInstances.filter((componentInstance) => componentInstance.is_archived === false)];
    if (filteredComponentId) {
      newFilteredComponentInstances = newFilteredComponentInstances.filter(
        (componentInstance) => componentInstance.component_id === filteredComponentId,
      );
    }
    const rangeStart = currentPage * rowsPerPage;
    const rangeEnd = rangeStart + rowsPerPage;
    return newFilteredComponentInstances
      .sort((a, b) => new Date(b.last_updated_at).getTime() - new Date(a.last_updated_at).getTime())
      .slice(rangeStart, rangeEnd);
  }, [componentInstances, filteredComponentId, currentPage, rowsPerPage]);

  const observe = useContext(ObservabilityContext);

  // load data from backend on page load
  useEffect(() => {
    setIsLoading(true);
    if (filteredComponentInstances.length === 0) return;
    fetchLatestProcessEntries(filteredComponentInstances).then((entries) => {
      setLastProcessEntries(entries);
      setIsLoading(false);
    });
  }, [filteredComponentInstances]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-2 border-b p-2">
        <div className="flex min-w-0 flex-grow gap-2">
          <h2 className="text-serial-palette-800 truncate pl-2 font-semibold">Latest Activity</h2>
          {isLoading && <Loader styleOverride="h-6 w-6" />}
        </div>
        <div className="w-72">
          <DropdownSearch
            options={components.map((component) => ({ id: component.id, label: component.name }))}
            placeholder="Filter by component"
            selected={filteredComponentId}
            setSelected={(value: string | null) => setFilteredComponentId(value)}
            className="h-8"
          />
        </div>
      </div>
      <div className="scrollbar-hide h-fit overflow-x-auto">
        <table className="w-full table-auto border-b">
          {/* Table header */}
          <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
            <tr>
              <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 xl:table-cell">
                <div className="text-left font-semibold">Component</div>
              </th>
              <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                <div className="text-left font-semibold">Identifier</div>
              </th>
              <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 sm:table-cell ">
                <div className="text-left font-semibold">Latest Entry</div>
              </th>
              <th className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                <div className="text-center font-semibold">Result</div>
              </th>
              <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 xl:table-cell">
                <div className="text-center font-semibold xl:text-left">Operator</div>
              </th>
              <th className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 xl:table-cell">
                <div className="text-center font-semibold xl:text-left">Timestamp</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="divide-serial-palette-200 divide-y text-sm ">
            {lastProcessEntries.map((entry, index) => {
              const operator = operators.find((operator) => operator.id === entry.operator_id);
              const user = users.find((user) => user.supabase_uid === operator?.user_id);
              const component = components.find((component) => component.id === entry.unique_identifier?.component_id);
              return (
                <tr key={index} onMouseEnter={() => setHoverIdentifierIndex(index)} onMouseLeave={() => setHoverIdentifierIndex(null)}>
                  <td className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 xl:table-cell">
                    <div className="flex items-center space-x-3 ">
                      <ComponentAvatar component={component} size="sm" tooltip={true} />
                      <Link
                        to={`/component/${component?.id}`}
                        onClick={() => observe.track("Navigate to Component", { "UI Source": "Home - Latest Activity" })}
                        className="cursor-pointer truncate text-left text-sm hover:underline"
                      >
                        {component?.name ?? "-"}
                      </Link>
                    </div>
                  </td>
                  <td className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 xl:table-cell">
                    <div className="flex items-center">
                      <Link
                        to={`/snlookup?uid=${entry.unique_identifier?.id}`}
                        onClick={() => observe.track("Navigate to SnLookup", { "UI Source": "Home - Latest Activity" })}
                        className="cursor-pointer truncate text-left hover:underline"
                      >
                        {entry.unique_identifier?.identifier}
                      </Link>
                      <CopyToClipboard
                        text={entry?.unique_identifier?.identifier ?? ""}
                        className={`${hoverIdentifierIndex === index ? "" : "text-white"}`}
                        noTooltip
                      />
                    </div>
                  </td>
                  <td className="max-w-[120px] cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 xl:hidden">
                    <div className="flex items-center space-x-3 ">
                      <ComponentAvatar component={component} size="sm" tooltip={true} />
                      <div className="flex flex-col overflow-hidden">
                        <Link to={`/component/${component?.id}`} className="truncate text-left text-sm font-bold hover:underline">
                          {component?.name ?? "-"}
                        </Link>
                        <div className="inline-flex">
                          <Link
                            to={`/snlookup?uid=${entry.unique_identifier?.id}`}
                            onClick={() => observe.track("Navigate to SnLookup", { "UI Source": "Home - Latest Activity" })}
                            className="truncate text-left hover:underline"
                          >
                            {entry.unique_identifier?.identifier}
                          </Link>
                          <CopyToClipboard
                            text={entry?.unique_identifier?.identifier ?? ""}
                            className={`${hoverIdentifierIndex === index ? "" : "text-white"}`}
                            noTooltip
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="hidden max-w-[150px] whitespace-normal px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
                    <div className="flex-col">
                      <div className="truncate text-left text-sm font-bold xl:font-normal">{entry.process?.name ?? "-"}</div>
                      <div className="truncate text-left xl:hidden">{moment(entry?.timestamp).format("MM/DD/YY HH:mma")}</div>
                    </div>
                  </td>
                  <td className="whitespace-normal px-2 py-3 first:pl-5 last:pr-5">
                    <div className="flex justify-center text-[10px]">
                      {entry.is_pass === null || entry.is_pass === undefined ? (
                        <TypeTag hideText className="w-8" type={"NA"} />
                      ) : entry.is_pass ? (
                        <TypeTag hideText className="w-8" type={"PASS"} />
                      ) : (
                        <TypeTag hideText className="w-8" type={"FAIL"} />
                      )}
                    </div>
                  </td>
                  <td className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 xl:table-cell">
                    <div className="flex items-center justify-center gap-x-2 xl:justify-start">
                      {operator ? <UserAvatar user={user ?? operator} size="sm" /> : "-"}{" "}
                      {/* Prefer user since users have avatars (operators do not) */}
                      <span className="flex">{operator ? `${operator.first_name} ${operator.last_name}` : ""}</span>
                    </div>
                  </td>
                  <td className="hidden whitespace-normal px-2 py-3 first:pl-5 last:pr-5 xl:table-cell">
                    <div className="flex items-center gap-x-2 text-left">{moment(entry?.timestamp).format("MM/DD/YY HH:mma")}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {lastProcessEntries.length === 0 && (
          <div className="text-serial-palette-500 w-full py-4 text-center text-sm italic">
            {filteredComponentId ? "No activity for this component" : "No activity for these components"}
          </div>
        )}
        {lastProcessEntries.length > 0 && (
          <PaginationFooter
            currentPage={currentPage + 1}
            totalPages={Math.ceil(filteredComponentInstances.length ?? 0 / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            availableRowsPerPage={[10, 20, 50]}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPrevPage={() => setCurrentPage(currentPage - 1)}
            onRowsPerPageChange={(newRowsPerPage: number) => {
              setRowsPerPage(newRowsPerPage);
              setCurrentPage(0);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeLatestActivity;
