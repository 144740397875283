import React, { useContext, useState } from "react";
import { RootState } from "@shared/redux/store";
import { useSelector } from "react-redux";
import { ProductionContext, ProductionState } from "../ProductionProvider";
import { UserRole } from "@shared/types/databaseEnums";
import Banner2 from "@shared/components/Banner2";
import { Link } from "react-router-dom";
import logoBlackLg from "@images/serial_primary_black.png";
import logoBlackSm from "@images/serial_submark_black.png";
import logoWhiteLg from "@images/serial_primary_white.png";
import logoWhiteSm from "@images/serial_submark_white.png";
import TypeTag from "@shared/components/TypeTag";
import ProductionHeaderClock from "./ProductionHeaderClock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faGlobe } from "@fortawesome/free-solid-svg-icons";
import VersionBlock from "@shared/components/VersionBlock";
import useDarkMode from "@shared/hooks/useDarkMode";

const ProductionHeader: React.FC = () => {
  const [warningBannerOpen, setWarningBannerOpen] = useState(true);

  const { station, user, operator, process, component, productionState, setStationModalOpen, setOperatorModalOpen } =
    useContext(ProductionContext);

  const showSetupWarning = user?.role !== UserRole.Admin && user?.role !== UserRole.Member && !station?.id;

  const company = useSelector((state: RootState) => state.db.company);

  const { darkMode } = useDarkMode();

  const customLogos = company.light_logo && company.dark_logo;
  const customIcons = company.light_icon && company.dark_icon;
  const logo = darkMode ? (customLogos ? company.dark_logo : logoWhiteLg) : customLogos ? company.light_logo : logoBlackLg;
  const icon = darkMode ? (customIcons ? company.dark_icon : logoWhiteSm) : customIcons ? company.light_icon : logoBlackSm;

  return (
    <div className="sticky top-0 flex flex-col gap-y-3 border-b bg-white py-1.5">
      {/* Banners */}
      {showSetupWarning && (
        <Banner2 className="w-full p-2 text-center" type="warning" open={warningBannerOpen} setOpen={setWarningBannerOpen}>
          Login as an admin user to setup this device
        </Banner2>
      )}

      {/* Regular header */}
      <div className="flex h-full w-full items-center justify-between pl-2 pr-3">
        {/* Logo */}
        <Link className="flex h-10 w-max shrink-0 items-center md:h-12" to="/home">
          <img className="hidden max-h-7 object-contain pl-2 md:block" src={logo} alt="Logo" />
          <img className="h-full object-contain md:hidden" src={icon} alt="Icon" />
        </Link>
        {/* Title */}
        {productionState === ProductionState.Steps ? (
          <div className="mx-1 flex flex-grow flex-col items-center justify-center overflow-hidden text-sm md:flex-row md:text-xl lg:mx-3">
            <div className="flex justify-center overflow-hidden overflow-ellipsis whitespace-nowrap font-bold md:justify-end">
              <div className="truncate">{component?.name ?? "Component"}</div>
            </div>
            <span className="hidden px-1.5 md:flex">|</span>
            <div className="flex items-center justify-center gap-x-2 overflow-hidden overflow-ellipsis whitespace-nowrap md:justify-start">
              <div className="truncate">{process?.name ?? "Process"}</div>
              <VersionBlock version={process?.revision.toString() || "0"} size="md" color={process?.approved ? "green" : "black"} />
            </div>
          </div>
        ) : (
          <button
            className={`hover:border-serial-palette-300 flex items-center rounded-md border p-1 text-sm md:text-lg lg:flex `}
            onClick={(e) => {
              e.stopPropagation();
              setStationModalOpen(true);
            }}
          >
            <span
              className={`font-semibold ${station ? "mx-3" : "ml-4"} ${station && (station?.is_active ? (station?.is_locked ? "text-amber-500" : "") : "text-red-500")}`}
            >
              {station &&
                (station?.is_active ? (
                  station?.is_locked ? (
                    <TypeTag type="LOCKED" className="pt-1 text-[10px]" hideText />
                  ) : station?.type === "UNIVERSAL" ? (
                    <FontAwesomeIcon icon={faGlobe} />
                  ) : (
                    <FontAwesomeIcon icon={faCrosshairs} />
                  )
                ) : (
                  <TypeTag type="RETIRED" className="my-0.5 py-0.5 text-[10px]" hideText />
                ))}
            </span>
            <span className="mr-3">{station ? station.name : "Select Station"}</span>
          </button>
        )}
        {/* User / Clock */}
        <div className="flex flex-col font-mono text-sm">
          <button
            className="flex items-center justify-end gap-x-2 pb-0.5"
            onClick={(e) => {
              e.stopPropagation();
              setOperatorModalOpen(true);
            }}
          >
            <TypeTag className="h-6 w-7 text-[10px] md:h-4" hideText={true} type="OPERATOR" />
            {operator?.id && (
              <div className="hidden text-base md:flex">
                {operator.first_name} {operator.last_name}
              </div>
            )}
          </button>
          <ProductionHeaderClock />
        </div>
      </div>
    </div>
  );
};

export default ProductionHeader;
