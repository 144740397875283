import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAvatar from "@shared/components/UserAvatar";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { UserRole } from "@shared/types/databaseEnums";
import { DashboardContext } from "../Dashboards";
import moment from "moment";

interface CustomDashboardTableProps {}

const CustomDashboardTable: React.FC<CustomDashboardTableProps> = ({}) => {
  const context = useContext(DashboardContext);
  const role = useSelector((state: RootState) => state.auth.role);
  const users = useSelector((state: RootState) => state.db.users);
  const userId = useSelector((state: RootState) => state.auth.supabase_uid);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigate = useNavigate();

  const filteredCustomDashboards = useMemo(() => {
    let customDashboards = context?.dashboards?.filter((dashboard) => dashboard.config.is_prebuilt === false);
    return customDashboards.filter((dashboard) =>
      (dashboard.name + dashboard.description).toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [context?.dashboards, searchTerm]);

  return (
    <div className="flex w-full flex-col">
      <div className="border-serial-palette-200  relative rounded-md border bg-white">
        <div className="flex w-full justify-between ">
          <header className="flex items-center justify-between px-5 py-4">
            <h2 className="text-serial-palette-800 font-semibold">
              Custom Dashboards{" "}
              <span className="text-serial-palette-400 pl-1 pr-2 font-medium">{filteredCustomDashboards?.length ?? 0}</span>
            </h2>
          </header>
          <div className="mx-3 flex items-center space-x-2">
            <div className="w-42 relative flex cursor-pointer gap-2 overflow-hidden rounded border transition-all">
              <input
                className="form-input w-full border-none pl-9"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm || ""}
              ></input>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="text-serial-palette-400 absolute left-3 top-1/2 -translate-y-1/2 transform"
              />
            </div>
            {role === UserRole.Admin && (
              <button
                className="btn bg-serial-palette-800 hover:bg-serial-palette-600 text-white"
                onClick={() => context.handleCreateDashboard()}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="xs:block ml-2 hidden">New Dashboard</span>
              </button>
            )}
          </div>
        </div>
        <div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              {/* Table header */}
              <thead className="text-serial-palette-500 bg-serial-palette-100 border-serial-palette-200 border-b border-t text-xs font-semibold uppercase">
                <tr>
                  <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold">Name</div>
                  </th>
                  <th className="w-full whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-left font-semibold">Description</div>
                  </th>
                  <th className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
                    <div className="text-center font-semibold">Created By</div>
                  </th>
                  <th className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
                    <div className="text-left font-semibold">Last Updated</div>
                  </th>
                  <th className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="text-center font-semibold">Edit</div>
                  </th>
                </tr>
              </thead>

              {/* Table body */}
              <tbody className="divide-serial-palette-200 divide-y border-b text-sm last:border-b-0">
                {filteredCustomDashboards.map((dashboard, index) => {
                  const owner = users.find((u) => u.supabase_uid === dashboard.created_by);
                  const disabled = dashboard.created_by !== userId;
                  return (
                    <tr
                      key={index}
                      className="hover:bg-serial-palette-50 cursor-pointer"
                      onClick={() => navigate("/dashboards/" + dashboard.id)}
                    >
                      <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div className="text-serial-palette-500 text-left font-medium">{dashboard.name}</div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div className="text-left">{dashboard.description}</div>
                      </td>
                      <td className="hidden whitespace-nowrap px-2 py-0.5 first:pl-5 last:pr-5 sm:table-cell">
                        <div className="flex items-center justify-center">
                          <UserAvatar user={owner} enableToolTip size="sm" />{" "}
                        </div>
                      </td>
                      <td className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 sm:table-cell">
                        <div className="text-center">{moment(dashboard?.last_edited_at).format("MM/DD/YY HH:mma")}</div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <button
                          disabled={disabled}
                          className={`btn serial-btn-light ${disabled ? "cursor-not-allowed opacity-25" : "cursor-pointer"}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            context.setSelectedDashboardId(dashboard.id);
                            context.setDashboardEditModalOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {filteredCustomDashboards?.length === 0 && (
              <div className="w-full space-y-2 py-10">
                <div className="text-center">No custom dashboards have been created.</div>
                <div className="text-center">
                  <span>Click </span>
                  <span className="font-bold">New Dashboard</span>
                  <span> to get started.</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDashboardTable;
