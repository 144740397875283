import TagBasic, { tagVariants } from "@shared/components/primitives/TagBasic";
import FrontendPrimitivesCard from "./FrontendPrimitivesCard";

const FrontendPrimitivesTagsBasic = () => {
  return (
    <FrontendPrimitivesCard title="Basic Tags">
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Sizes</div>
        <div className="flex flex-wrap items-start gap-2">
          {["xs", "sm", "md", "lg"].map((size) => (
            <TagBasic key={size} variant="CYCLE_TIME" size={size as "xs" | "sm" | "md" | "lg"} tooltip />
          ))}
        </div>
        <div className="flex flex-wrap items-start gap-2">
          {["xs", "sm", "md", "lg"].map((size) => (
            <TagBasic.Root key={size} variant="CYCLE_TIME" size={size as "xs" | "sm" | "md" | "lg"} tooltip>
              <TagBasic.Icon />
              <TagBasic.Name />
            </TagBasic.Root>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">Icon Only</div>
        <div className="flex flex-wrap gap-2">
          {Object.keys(tagVariants).map((variant) => (
            <TagBasic key={variant} variant={variant} size="sm" tooltip />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">With Name</div>
        <div className="flex flex-wrap gap-2">
          {Object.keys(tagVariants).map((variant) => (
            <TagBasic.Root key={variant} variant={variant} size="sm" tooltip>
              <TagBasic.Icon />
              <TagBasic.Name />
            </TagBasic.Root>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">With Custom Tooltip</div>
        <div className="flex flex-wrap items-start gap-2">
          <TagBasic variant="CYCLE_TIME" size="sm" tooltip="Custom Tooltip!" />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="text-lg font-medium">With Count</div>
        <div className="flex flex-wrap items-start gap-2">
          <TagBasic variant="RETEST" size="sm" count={5290} tooltip="Retest #5290" />
        </div>
      </div>
    </FrontendPrimitivesCard>
  );
};

export default FrontendPrimitivesTagsBasic;
