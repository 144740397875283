import { useContext, useEffect, useMemo } from "react";
import Sidebar from "@shared/components/Sidebar";
import Header from "@shared/components/Header";
import ComponentProvider, { ComponentContext } from "./ComponentProvider";
import { ComponentPageModal } from "./types";
import ComponentFlowchart from "./components/ComponentFlowchart";
import ProcessBuilder from "../process-builder/ProcessBuilder";
import ComponentModal from "./components/ComponentModal";
import ComponentSidebar from "./components/ComponentSidebar";
import { useLocation } from "react-router-dom";

function Component() {
  return (
    <ComponentProvider>
      <ComponentWithContext />
    </ComponentProvider>
  );
}

function ComponentWithContext() {
  const { topLevelComponent, focusedComponent, focusedProcess, activeModal, setActiveModal } = useContext(ComponentContext);

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: "Components",
        link: "/componentslist",
      },
      {
        label: topLevelComponent === null ? "New Component" : topLevelComponent?.name ?? "Loading...",
        link: "/component/" + topLevelComponent?.id,
      },
    ];
  }, [topLevelComponent]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith("/component/new")) {
      setActiveModal(ComponentPageModal.ComponentSettings);
    }
  }, [location]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <Header breadcrumbs={breadcrumbs} />
        <div className="relative flex h-full w-full bg-white">
          <ComponentFlowchart />
          <ComponentSidebar />
        </div>
      </div>
      <div className="absolute">
        <ComponentModal activeModal={activeModal} setActiveModal={setActiveModal} selectedComponent={focusedComponent} />
        <ProcessBuilder
          processId={focusedProcess?.id ?? null}
          componentId={focusedComponent?.id ?? null}
          processBuilderOpen={activeModal === ComponentPageModal.ProcessBuilder}
          setProcessBuilderOpen={(isOpen: boolean) => setActiveModal(isOpen ? ComponentPageModal.ProcessBuilder : null)}
        />
      </div>
    </div>
  );
}

export default Component;
