import Tooltip from "@shared/components/primitives/Tooltip";

const MeasuresGridBodyCellNotApplicable = () => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <div className="relative flex h-full w-full flex-col items-center justify-start">
          <div className="mt-1.5 flex h-1/3 w-1/3 border-b" />
        </div>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className="w-56 text-sm">
          <span className="font-bold">Not Applicable: </span>
          <span>This sub component is not directly related to the rest of the data in this row</span>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export default MeasuresGridBodyCellNotApplicable;
