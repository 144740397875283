import React from "react";
import { Image as PdfImage, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Process, WorkInstructionBlock, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import Table from "@shared/components/react-pdf/Table";
import { fetchDownloadUrl } from "@shared/connections/supabaseGeneral";
import { generatePngWithMarkup } from "@shared/image-markup/ImageMarkup";

type WorkInstructionProps = {
  process: Process;
  blocks: WorkInstructionBlock[];
  imageSize: "sm" | "md" | "lg";
  imageQuality: "low" | "medium" | "high";
  fileNumber: { [workInstructionId: string]: number };
  imageNumber: { [workInstructionId: string]: number };
  pdfNumber: { [workInstructionId: string]: number };
};

const styles = StyleSheet.create({
  dataIndented: {
    paddingLeft: 20,
  },
  heading: {
    fontFamily: "Roboto",
    fontWeight: "medium",
    underline: true,
  },
  delimiter: {
    borderBottomWidth: 2,
    borderBottomColor: "lightgray",
  },
  list: {
    fontSize: 11,
    flexDirection: "row",
    alignItems: "flex-start",
    fontFamily: "Roboto",
    fontWeight: "normal",
    marginRight: 10,
  },
  callout: {
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    fontSize: 11,
    fontFamily: "Roboto",
    fontWeight: "medium",
    borderRadius: 5,
    marginBottom: 5,
  },
  calloutInfo: {
    backgroundColor: "#eff6ff",
    borderColor: "#2563eb",
    borderWidth: 2,
  },
  calloutInfoText: {
    color: "#2563eb",
    fontFamily: "Roboto",
    fontSize: 11,
  },
  calloutWarning: {
    backgroundColor: "#fffbeb",
    borderColor: "#d97706",
    borderWidth: 2,
  },
  calloutWarningText: {
    color: "#d97706",
    fontFamily: "Roboto",
    fontSize: 11,
  },
  calloutDanger: {
    backgroundColor: "#fef2f2",
    borderColor: "#dc2626",
    borderWidth: 2,
  },
  calloutDangerText: {
    color: "#dc2626",
    fontFamily: "Roboto",
    fontSize: 11,
  },
  calloutCode: {
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "#eff6ff",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  dataImage: {
    width: "40%",
    height: "auto",
    resizeMode: "contain",
    marginBottom: 5,
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 11,
    fontWeight: "normal",
    marginRight: 10,
  },
});

const WorkInstruction: React.FC<WorkInstructionProps> = ({ process, blocks, imageSize, imageQuality, fileNumber, imageNumber, pdfNumber }) => {
  const imageSizeClass =
    imageSize === "sm"
      ? { width: "40%", height: "auto" }
      : imageSize === "md"
        ? { width: "60%", height: "auto" }
        : { width: "80%", height: "auto" };

  return (
    <View style={{ gap: 6 }}>
      {blocks.map((block, index) => {
        {
          /* Implement switch block for each type of work instruction block*/
        }
        switch (block.type) {
          case WorkInstructionBlockType.Heading:
            let fontSize;
            switch (block.content.level) {
              case 1:
                fontSize = 14;
                break;
              case 2:
                fontSize = 13;
                break;
              case 3:
                fontSize = 12;
              default:
                fontSize = 14;
            }
            return (
              <View key={index}>
                <Text style={[styles.heading, { fontSize: fontSize }]}>{block.content.text}</Text>
              </View>
            );
          case WorkInstructionBlockType.Text:
            return (
              <View key={index}>
                <Text style={[styles.text]}>{block.content.text}</Text>
              </View>
            );
          case WorkInstructionBlockType.Delimiter:
            return <View key={index} style={[styles.delimiter]} />;
          case WorkInstructionBlockType.List:
            return (
              <View key={index}>
                {block.content.items.map((item, listIndex) => (
                  <View key={listIndex} style={styles.list}>
                    {block.content.listType === "BULLETED" && <Text style={{ width: 10, marginLeft: 6 }}>{"\u2022"}</Text>}
                    {block.content.listType === "NUMBERED" && <Text style={{ width: 10, marginLeft: 6 }}>{listIndex + 1}.</Text>}
                    <Text> {item}</Text>
                  </View>
                ))}
              </View>
            );
          case WorkInstructionBlockType.Callout:
            let calloutContainerStyle;
            let calloutTextStyle;
            switch (block.content.icon) {
              case "INFO":
                calloutContainerStyle = styles.calloutInfo;
                calloutTextStyle = styles.calloutInfoText;
                break;
              case "WARNING":
                calloutContainerStyle = styles.calloutWarning;
                calloutTextStyle = styles.calloutWarningText;
                break;
              case "DANGER":
                calloutContainerStyle = styles.calloutDanger;
                calloutTextStyle = styles.calloutDangerText;
                break;
              default:
                calloutContainerStyle = {};
            }
            return (
              <View key={index}>
                <View key={index} style={[styles.callout, calloutContainerStyle]}>
                  <Text style={calloutTextStyle}>{block.content.text}</Text>
                </View>
              </View>
            );
          case WorkInstructionBlockType.Code:
            return (
              <View key={index}>
                <View key={index} style={[styles.callout, { backgroundColor: "#262626" }]}>
                  <Text style={{ color: "#fafaf9" }}>{block.content.text}</Text>
                </View>
              </View>
            );
          case WorkInstructionBlockType.Image:
            let imageSrc = fetchDownloadUrl(
              "work-instructions",
              block.file_id,
              block.company_id,
              undefined,
              undefined,
              undefined,
              imageQuality,
            ).then((url) => {
              if (url && block.content.markup && block.content.markup.length > 0) {
                return generatePngWithMarkup(url, block.content.markup);
              }
              return url;
            });
            return (
              <View key={index}>
                <View style={styles.imageContainer}>
                  {/* @ts-ignore Somehow this async function actually gets the image and displays it in the PDF. TODO: try passing images into PDF as base 64 like SN lookup export to see if that solves the rotation issue*/}
                  <PdfImage src={imageSrc} style={[styles.dataImage, imageSizeClass]} />
                  <Text style={styles.text}>{process?.show_media_numbering && block.content.caption ? `Image ${imageNumber[block.id]}: ` : ""}{block.content.caption}</Text>
                </View>
              </View>
            );
          case WorkInstructionBlockType.PDF:
            return (
              <View key={index}>
                <View key={index}>
                  <Text style={styles.text}>{process?.show_media_numbering && block.content.caption ? `PDF ${pdfNumber[block.id]}: ` : ""}{block.content.file_name}</Text>
                </View>
              </View>
            );
          case WorkInstructionBlockType.File:
            return (
              <View key={index}>
                <View key={index}>
                  <Text style={styles.text}>{process?.show_media_numbering && block.content.caption ? `File ${fileNumber[block.id]}: ` : ""}{block.content.file_name}</Text>
                </View>
              </View>
            );
          case WorkInstructionBlockType.Table:
            return (
              <View key={index}>
                <Table data={block.content.rows} headerRow={block.content.header_row} headerColumn={block.content.header_column} />
              </View>
            );
        }
      })}
    </View>
  );
};

export default WorkInstruction;
