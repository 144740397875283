import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UserTable from "./team/UserTable";
import OperatorTable from "./team/OperatorTable";
import { UserRole } from "@shared/types/databaseEnums";
import { RootState } from "@shared/redux/store";
import UserModal from "./team/UserModal";
import { User } from "@shared/types/databaseTypes";

const nullUser: User = {
  company_id: "",
  created_at: new Date().toISOString(),
  is_active: true,
  email: "",
  first_name: null,
  last_name: null,
  role: UserRole.Admin,
  supabase_uid: "",
  default_station_id: null,
  title: null,
};

function Users() {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const company = useSelector((state: RootState) => state.db.company);
  const [isNewUser, setIsNewUser] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const userList = useSelector((state: RootState) => state.db.users);
  const [sortedUserList, setSortedUserList] = useState<object[]>([]);

  // Edit existing user function
  const handleUserEdit = (supabase_uid: string) => {
    setSelectedUser(supabase_uid);
    setIsNewUser(false);
    setModalTitle("Edit User");
    setUserModalOpen(true);
  };

  // Add new user function
  const handleNewUser = () => {
    setSelectedUser(null);
    setIsNewUser(true);
    setModalTitle("Add New User");
    setUserModalOpen(true);
  };

  useEffect(() => {
    setSortedUserList(
      [...userList].sort((a, b) => {
        // First sort by active status
        if (a.is_active && !b.is_active) return -1;
        if (!a.is_active && b.is_active) return 1;

        // Then sort by role order as defined in the roles array
        const roles = Object.values(UserRole);

        const roleOrderA = roles.indexOf(a.role);
        const roleOrderB = roles.indexOf(b.role);

        if (roleOrderA !== roleOrderB) {
          return roleOrderA - roleOrderB;
        }

        // Finally, sort by first name
        if (a.first_name && b.first_name) {
          return a.first_name.localeCompare(b.first_name);
        } else {
          return 0;
        }
      }),
    );
  }, [userList]);

  return (
    <div className="mb-2 flex grow flex-col justify-between overflow-auto p-6 ">
      {/* Table */}
      <div className="flex flex-col space-y-6">
        <UserTable userList={sortedUserList} company={company.name} handleUserEdit={handleUserEdit} handleNewUser={handleNewUser} />
        {company?.config?.use_operator_pins && <OperatorTable />}
      </div>

      {/* User Modal */}
      <UserModal
        title={modalTitle}
        isNewUser={isNewUser}
        userData={selectedUser == null ? nullUser : userList.find((user) => user.supabase_uid === selectedUser) ?? nullUser}
        modalOpen={userModalOpen}
        setModalOpen={setUserModalOpen}
      />
    </div>
  );
}

export default Users;
