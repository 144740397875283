import React, { useState, useEffect } from "react";
import UserTableItem from "./UserTableItem";

function UserTable({ userList, company, handleUserEdit, handleNewUser }) {
  const [users, setUsers] = useState(userList);

  useEffect(() => {
    setUsers(userList);
  }, [userList]);

  return (
    <div className="relative ">
      <header className="mb-3 flex items-center justify-between">
        <h3 className="text-serial-palette-800 text-xl font-bold">
          Team<span className="text-serial-palette-400 pl-1.5 pr-2 font-medium">{users.filter((user) => user.is_active)?.length}</span>
        </h3>
        <button
          className="btn-sm bg-serial-palette-800 hover:bg-serial-palette-600 text-white"
          onClick={(e) => {
            e.stopPropagation();
            handleNewUser();
          }}
        >
          <svg className="h-4 w-4 shrink-0 fill-current" viewBox="0 0 16 16">
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
          <span className="ml-2 hidden sm:block">Add User</span>
        </button>
      </header>
      <div className="overflow-hidden rounded-md border">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            {/* Table header */}
            <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
              <tr>
                <th className="w-12 whitespace-nowrap px-2 py-3 text-center font-semibold first:pl-5 last:pr-5">Avatar</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Name</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Title</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Email</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Company</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Account Type</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Status</th>
                <th className="whitespace-nowrap px-2 py-3 text-left font-semibold first:pl-5 last:pr-5">Edit User</th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="border-serial-palette-200 divide-y text-sm">
              {users.map((user, index) => (
                <UserTableItem key={`User-${index}`} index={index} user={user} company={company} handleUserEdit={handleUserEdit} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UserTable;
