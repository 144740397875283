import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  Edge,
  MarkerType,
  Node,
  NodeChange,
  Panel,
  ReactFlowProvider,
  XYPosition,
  applyNodeChanges,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { ComponentPageModal, ComponentPageSidebar, ProcessWithOrderActivityDatasets } from "../types";
import {
  NodeType,
  nodeTypes,
  COMPONENT_BODY_PADDING,
  COMPONENT_NODE_HEADER_HEIGHT,
  COMPONENT_NODE_PITCH_X,
  COMPONENT_NODE_PITCH_Y,
  PROCESS_NODE_PITCH_Y,
  DESCENDENT_PROCESS_NODE_PITCH_Y,
  PROCESS_NODE_HEIGHT,
  COMPONENT_NODE_WIDTH,
  PROCESS_NODE_WIDTH,
  COMPONENT_INSTANCE_STATUS_WIDTH,
  COMPONENT_INSTANCE_STATUS_HEIGHT,
  COMPONENT_INSTANCE_STATUS_PITCH_Y,
  DESCENDENT_COMPONENT_INSTANCE_STATUS_PITCH_Y,
  COMPONENT_BODY_PADDING_TOP,
  PARENT_NODE_PITCH_Y,
  PARENT_NODE_WIDTH,
  PARENT_NODE_HEIGHT,
  COLLAPSED_NODE_PROCESS_COUNT_THRESHOLD,
} from "@shared/components/flowcharts/nodes/constants";
import { useSelector } from "react-redux";
import useComponentGenealogy, {
  getDescendentComponentIds,
  getDescendentComponentLevel,
  getDescendentComponentLinks,
  getGenealogyComponentIds,
} from "@shared/hooks/useComponentGenealogy";
import { RootState } from "@shared/redux/store";
import { UniqueIdentifierStatus } from "@shared/types/databaseEnums";
import { ComponentContext } from "../ComponentProvider";
import useComponentProcessesAllData from "../hooks/useComponentProcessesAllData";
import DropdownFilter from "@shared/components/dropdowns/DropdownFilter";
import ComponentFlowchartDownload from "./ComponentFlowchartDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Component } from "@shared/types/databaseTypes";

const generateComponentInstanceStatusNode = (
  componentId: string,
  status: UniqueIdentifierStatus,
  position: XYPosition,
  hideSourceHandle: boolean,
  parentNodeId?: string,
): Node => {
  return {
    id: `${componentId}_${status}`,
    data: {
      status,
      hideSourceHandle,
      draggable: parentNodeId === undefined,
    },
    type: NodeType.ComponentInstanceStatus,
    position: position,
    parentNode: parentNodeId,
    draggable: parentNodeId === undefined,
  } as Node;
};

const generateProcessNodesAndEdges = (
  componentId: string,
  processes: ProcessWithOrderActivityDatasets[],
  isInsideComponentNode: boolean,
  handleEditProcess?: (processId: string, componentId?: string) => void,
): [Node[], Edge[]] => {
  let processEdges: Edge[] = [];
  let processesNodes: Node[] = [];

  const xOffset = isInsideComponentNode ? COMPONENT_BODY_PADDING : 0;
  const yOffsetBase = isInsideComponentNode ? COMPONENT_BODY_PADDING_TOP + +COMPONENT_NODE_HEADER_HEIGHT : 0;
  const componentInstanceStatusPitch = isInsideComponentNode
    ? DESCENDENT_COMPONENT_INSTANCE_STATUS_PITCH_Y
    : COMPONENT_INSTANCE_STATUS_PITCH_Y;
  const processNodePitch = isInsideComponentNode ? DESCENDENT_PROCESS_NODE_PITCH_Y : PROCESS_NODE_PITCH_Y;

  // Add the "planned" component instance status node
  processesNodes.push(
    generateComponentInstanceStatusNode(
      componentId,
      UniqueIdentifierStatus.Planned,
      { x: PROCESS_NODE_WIDTH / 2 - COMPONENT_INSTANCE_STATUS_WIDTH / 2 + xOffset, y: yOffsetBase },
      false,
      isInsideComponentNode ? componentId : undefined,
    ),
  );

  let processesNodesYPositionTracker = yOffsetBase + componentInstanceStatusPitch;

  // Add the process nodes
  processes.forEach((process, index) => {
    const yPosition = processesNodesYPositionTracker;
    const subsequentProcesses = processes.slice(index + 1);
    const subsequentProcessesAreOptional = subsequentProcesses.every((p) => !p.is_mandatory); // will also be true if there are no subsequent processes
    const allProcessesAreOptional = processes.every((p) => !p.is_mandatory);
    processesNodes.push({
      id: `${componentId}_${process.id}`,
      data: {
        process,
        isConnectable: false,
        draggable: !isInsideComponentNode,
        handleEditProcess,
        componentId,
        hideSourceHandle: subsequentProcesses.length === 0 && !process.is_mandatory && !allProcessesAreOptional,
        hideAvatar: true,
      },
      type: NodeType.Process,
      position: { x: xOffset, y: yPosition },
      draggable: !isInsideComponentNode,
      parentNode: isInsideComponentNode ? componentId : undefined,
      extent: isInsideComponentNode ? "parent" : undefined,
    });

    // Two cases for displaying the "complete" tag:
    // 1. If the process is mandatory and all subsequent processes are optional
    // 2. If the process is the first process and all processes are optional
    if ((process.is_mandatory && subsequentProcessesAreOptional) || (index === 0 && allProcessesAreOptional)) {
      processesNodesYPositionTracker += PROCESS_NODE_HEIGHT + componentInstanceStatusPitch - COMPONENT_INSTANCE_STATUS_HEIGHT;

      // Add the "complete" component instance status node
      processesNodes.push(
        generateComponentInstanceStatusNode(
          componentId,
          UniqueIdentifierStatus.Complete,
          { x: PROCESS_NODE_WIDTH / 2 - COMPONENT_INSTANCE_STATUS_WIDTH / 2 + xOffset, y: processesNodesYPositionTracker },
          subsequentProcesses.length === 0,
          isInsideComponentNode ? componentId : undefined,
        ),
      );

      processesNodesYPositionTracker += componentInstanceStatusPitch;
    } else {
      processesNodesYPositionTracker += processNodePitch;
    }
  });

  // Add the process edges
  for (let i = 0; i < processesNodes.length - 1; i++) {
    processEdges.push({
      id: `edge_${processesNodes[i].id}_${processesNodes[i + 1].id}`,
      source: processesNodes[i].id,
      target: processesNodes[i + 1].id,
      type: "smoothstep",
      animated: !isInsideComponentNode,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: isInsideComponentNode ? "#000000" : "#b3b3b8",
      },
    });
  }

  return [processesNodes, processEdges];
};

const ComponentFlowchart: React.FC = () => {
  const {
    topLevelComponent,
    focusedComponent,
    handleSetFocusedComponent,
    setActiveSidebar,
    handleSetFocusedProcess,
    setActiveModal,
    showInactiveProcesses,
    setShowInactiveProcesses,
  } = useContext(ComponentContext);

  const [processNodes, setProcessNodes] = useState<Node[]>([]);
  const [processEdges, setProcessEdges] = useState<Edge[]>([]);

  const [collapsedComponents, setCollapsedComponents] = useState<{ [componentId: string]: boolean }>({});

  const components = useSelector((state: RootState) => state.db.components);
  const componentLinks = useSelector((state: RootState) => state.db.componentLinks);

  const genealogy = useComponentGenealogy(topLevelComponent?.id);
  const componentIds = useMemo(() => getGenealogyComponentIds(genealogy), [genealogy]);
  const descendentComponentIds = useMemo(() => getDescendentComponentIds(genealogy), [genealogy]);
  const descendentComponentLinks = useMemo(() => getDescendentComponentLinks(genealogy), [genealogy]);
  const parentComponentIds = useMemo(() => {
    return [
      ...new Set(
        componentLinks.filter((link) => link.has_child_of_id === topLevelComponent?.id && link.is_active).map((link) => link.component_id),
      ),
    ];
  }, [componentLinks, topLevelComponent?.id]);

  const processes = useComponentProcessesAllData({ componentIds });

  const { setCenter, fitView } = useReactFlow();

  // move to component node top center when focused, or fit view if no focused component
  // delay by 100ms to run after sidebar animation
  useEffect(() => {
    setTimeout(() => {
      if (focusedComponent) {
        const focusedComponentNode = processNodes.find((node) => node.id === focusedComponent.id);
        if (focusedComponentNode) {
          setCenter(focusedComponentNode.position.x + COMPONENT_NODE_WIDTH / 2, focusedComponentNode.position.y + 160, {
            zoom: 1.8,
            duration: 800,
          });
        }
      } else {
        fitView({ duration: 800, padding: 0.2 });
      }
    }, 100);
  }, [focusedComponent]);

  // if a top level component is loaded, fit view to it after 50ms
  useEffect(() => {
    if (topLevelComponent?.id) {
      setTimeout(() => fitView({ duration: 800, padding: 0.2 }), 50);
    }
  }, [topLevelComponent]);

  // If any component has over 5 processes and it's component id is not yet accounted for in the collapsedComponents state, collapse it
  useEffect(() => {
    const componentsToCollapse = componentIds.filter((componentId) => {
      const numVisibleProcesses = processes[componentId]?.filter(
        (process) => process.is_active || (!process.is_active && showInactiveProcesses),
      );
      return numVisibleProcesses && numVisibleProcesses.length > COLLAPSED_NODE_PROCESS_COUNT_THRESHOLD;
    });
    setCollapsedComponents((prevCollapsedComponents) => {
      const newCollapsedComponents = { ...prevCollapsedComponents };
      componentsToCollapse.forEach((componentId) => {
        if (!(componentId in newCollapsedComponents)) {
          newCollapsedComponents[componentId] = true;
        }
      });
      return newCollapsedComponents;
    });
  }, [processes, componentIds]);

  const handleEditProcess = (processId: string, componentId?: string) => {
    handleSetFocusedProcess(processId);
    handleSetFocusedComponent(componentId ?? null);
    setActiveModal(ComponentPageModal.ProcessBuilder);
  };

  const handleEditComponent = (componentId?: string) => {
    handleSetFocusedComponent(componentId ?? null);
    setActiveModal(ComponentPageModal.ComponentSettings);
  };

  const handleAddProcess = (componentId: string) => {
    handleSetFocusedProcess(null);
    handleSetFocusedComponent(componentId);
    setActiveModal(ComponentPageModal.ProcessBuilder);
  };

  const handleExpandAll = () => {
    setCollapsedComponents(componentIds.reduce((acc, componentId) => ({ ...acc, [componentId]: false }), {}));
    setTimeout(() => fitView({ duration: 800, padding: 0.2 }), 50);
  };

  const handleCollapseAll = () => {
    setCollapsedComponents(componentIds.reduce((acc, componentId) => ({ ...acc, [componentId]: true }), {}));
    setTimeout(() => fitView({ duration: 800, padding: 0.2 }), 50);
  };

  const showCollapseAll = useMemo(() => {
    return componentIds.some((componentId) => !collapsedComponents[componentId]);
  }, [componentIds, collapsedComponents]);

  useEffect(() => {
    if (!topLevelComponent) {
      setProcessNodes([]);
      setProcessEdges([]);
      return;
    }

    // -------- NODES --------

    const topLevelProcesses = processes[topLevelComponent.id] ?? [];
    const parentComponents = parentComponentIds
      .map((id) => components.find((c) => c.id === id))
      .filter((c) => c !== undefined) as Component[];

    // Add component nodes
    // Note that the children in the genealogy tree are already returned in the order of the processes that link them. Therefore we don't need to sort them.
    let yPositionTracker: Record<number, number> = {}; // indexed by descendent level in the tree
    const componentNodes = [...descendentComponentIds, topLevelComponent.id]
      .map((componentId) => {
        const currComponent = components.find((c) => c.id === componentId);
        if (!currComponent) return undefined;
        const descendentLevel = getDescendentComponentLevel(genealogy, componentId); // 1 means it's a child, 2 means it's a grandchild, etc.
        const filteredProcesses = (processes[componentId] ?? []).filter(
          (process) => process.is_active || (!process.is_active && showInactiveProcesses),
        );
        const numProcesses = filteredProcesses.length - 1;
        const collapsedHeight = COMPONENT_NODE_HEADER_HEIGHT + (COMPONENT_BODY_PADDING_TOP + 106);
        const expandedHeight =
          numProcesses * DESCENDENT_PROCESS_NODE_PITCH_Y +
          COMPONENT_NODE_HEADER_HEIGHT +
          PROCESS_NODE_HEIGHT +
          (COMPONENT_BODY_PADDING + COMPONENT_BODY_PADDING_TOP + 10) +
          2 * DESCENDENT_COMPONENT_INSTANCE_STATUS_PITCH_Y;
        const height = collapsedComponents[componentId] ? collapsedHeight : expandedHeight;
        const yPosition = yPositionTracker[descendentLevel] ?? COMPONENT_INSTANCE_STATUS_PITCH_Y;
        const xPosition = descendentLevel * COMPONENT_NODE_PITCH_X;
        yPositionTracker[descendentLevel] = yPosition + height + COMPONENT_NODE_PITCH_Y;
        return {
          id: currComponent.id,
          data: {
            component: currComponent,
            processes: filteredProcesses,
            datasets: filteredProcesses?.flatMap((process) => process.datasets) ?? [],
            connectable: false,
            hideSourceHandle: componentId === topLevelComponent.id && parentComponents.length === 0,
            focused: focusedComponent?.id === currComponent.id,
            handleFocusComponent: () => {
              setActiveSidebar(ComponentPageSidebar.ProcessSequence);
              handleSetFocusedComponent(currComponent.id);
            },
            handleEditComponent: handleEditComponent,
            handleAddProcess: handleAddProcess,
            collapsedComponents,
            setCollapsedComponents,
          },
          type: NodeType.Component,
          position: { x: xPosition, y: yPosition },
          dragHandle: ".component-node-drag-handle",
          style: {
            width: COMPONENT_NODE_WIDTH,
            height: height,
          },
        };
      })
      .filter((node) => node !== undefined) as Node[];

    // Main processes of the selected component
    let processNodes: Node[] = [];
    let processEdges: Edge[] = [];
    if (!collapsedComponents[topLevelComponent.id]) {
      const filteredProcesses = topLevelProcesses.filter((process) => process.is_active || (!process.is_active && showInactiveProcesses));
      [processNodes, processEdges] = generateProcessNodesAndEdges(topLevelComponent.id, filteredProcesses, true, handleEditProcess);
    }

    // Add sub nodes for the processes of descendent components
    const descendentComponentProcessNodes: Node[] = [];
    const componentProcessEdges: Edge[] = [];
    Object.entries(processes).forEach(([componentId, processes]) => {
      if (collapsedComponents[componentId]) return;
      if (componentId === topLevelComponent.id) return;
      const filteredProcesses = processes.filter((process) => process.is_active || (!process.is_active && showInactiveProcesses));
      const [processNodes, processEdges] = generateProcessNodesAndEdges(componentId, filteredProcesses, true, handleEditProcess);
      descendentComponentProcessNodes.push(...processNodes);
      componentProcessEdges.push(...processEdges);
    });

    // Add parent component nodes
    const parentComponentNodes: Node[] = parentComponents.map((parentComponent, index) => {
      const yPosition = index * PARENT_NODE_PITCH_Y + COMPONENT_INSTANCE_STATUS_PITCH_Y;
      const xPosition = -PARENT_NODE_WIDTH - COMPONENT_NODE_PITCH_X + COMPONENT_NODE_WIDTH;
      return {
        id: parentComponent.id,
        data: {
          component: parentComponent,
        },
        type: NodeType.Parent,
        position: { x: xPosition, y: yPosition },
        dragHandle: ".component-node-drag-handle",
        style: {
          width: PARENT_NODE_WIDTH,
          height: PARENT_NODE_HEIGHT,
        },
      };
    });

    // -------- EDGES --------

    // Add edges for the descendent component nodes
    let descendentComponentEdges: Edge[] = [];
    for (const link of descendentComponentLinks) {
      const source = link.has_child_of_id;
      const target = collapsedComponents[link.component_id] ? link.component_id : `${link.component_id}_${link.process_id}`;
      // Check if edge already exists
      const existingEdge = descendentComponentEdges.find((edge) => edge.source === source && edge.target === target);
      if (existingEdge) {
        // Increment the edge count and set the label equal to the count
        existingEdge.data.count += 1;
        existingEdge.label = existingEdge.data.count.toString();
      } else {
        descendentComponentEdges.push({
          id: `edge_${link.has_child_of_id}_${link.process_id}`,
          source: source,
          target: target,
          targetHandle: "linkhandle",
          data: {
            count: 1,
          },
          labelBgStyle: { fill: "#b3b3b8" },
          labelStyle: { fill: "#ffffff", fontSize: 10 },
          labelBgPadding: [6, 2],
          labelBgBorderRadius: 8,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        });
      }
    }

    // Add edges for the parent component nodes
    const parentComponentEdges: Edge[] = parentComponents.map((parentComponent) => {
      return {
        id: `edge_${parentComponent.id}_${topLevelComponent.id}`,
        target: parentComponent.id,
        source: topLevelComponent.id,
        animated: false,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      };
    });

    // Set the state
    setProcessNodes([...componentNodes, ...processNodes, ...descendentComponentProcessNodes, ...parentComponentNodes]);
    setProcessEdges([...componentProcessEdges, ...processEdges, ...descendentComponentEdges, ...parentComponentEdges]);
  }, [
    topLevelComponent,
    showInactiveProcesses,
    processes,
    descendentComponentLinks,
    genealogy,
    focusedComponent?.id,
    collapsedComponents,
    parentComponentIds,
  ]);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setProcessNodes((nodes) => applyNodeChanges(changes, nodes));
  }, []);

  const filterOptions = useMemo(() => {
    return {
      Active: true,
      Inactive: showInactiveProcesses,
    };
  }, [showInactiveProcesses]);

  const toggleFilterOption = (option: string) => {
    switch (option) {
      case "Inactive":
        setShowInactiveProcesses(!showInactiveProcesses);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex min-h-0 min-w-0 flex-grow bg-white">
      <ReactFlow nodes={processNodes} edges={processEdges} onNodesChange={onNodesChange} nodeTypes={nodeTypes} minZoom={0.25} fitView>
        <Panel position="top-right">
          <div className="-mr-0.5 -mt-0.5 flex items-center gap-x-1.5">
            <DropdownFilter classOverride="h-8" align="left" filterOptions={filterOptions} toggleFilterOption={toggleFilterOption} />
            <button
              className="btn bg-serial-palette-800 hover:bg-serial-palette-600 h-8 text-white"
              onClick={showCollapseAll ? handleCollapseAll : handleExpandAll}
            >
              <FontAwesomeIcon icon={showCollapseAll ? faArrowUp : faArrowDown} />
              <span className="ml-2 hidden md:block">{showCollapseAll ? "Collapse All" : "Expand All"}</span>
            </button>
            <ComponentFlowchartDownload component={topLevelComponent} />
          </div>
        </Panel>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <ComponentFlowchart />
  </ReactFlowProvider>
);
