import React, { useContext, useEffect, useState } from "react";
import ModalBasic from "@shared/components/modals/ModalBasic";
import { ProductionContext } from "../ProductionProvider";
import { UserRole } from "@shared/types/databaseEnums";
import Banner from "@shared/components/primitives/Banner";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "@shared/components/primitives/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@shared/components/Loader";
import { getDeviceName } from "@shared/utils/helpers";
import { createStation } from "../connections/supabase";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";

interface ProductionModalStationProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductionModalStation: React.FC<ProductionModalStationProps> = ({ modalOpen, setModalOpen }) => {
  const [stationName, setStationName] = useState("");
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { db, user, station, handleUpdateStation, setConfigureStation, process } = useContext(ProductionContext);

  const { triggerToast } = useContext(ToastContext);

  const handleCreateStation = async () => {
    if (!db.company?.id) return;
    const deviceName = await getDeviceName();
    const response = await createStation(deviceName, db.company.id);
    if (response.error || !response.data?.id) {
      console.error(response.error);
      triggerToast(ToastType.Error, "Stations", "Error creating station");
      return;
    }
    if (process?.id !== undefined && location.pathname.split("/")?.[3] === process?.id) {
      navigate(`/production/${response.data?.id}/${process?.id}${location.search}`);
    } else {
      navigate(`/production/${response.data?.id}`);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const { error } = await handleUpdateStation({ name: stationName });
    setIsLoading(false);
    if (!error) setModalOpen(false);
  };

  useEffect(() => {
    if (station) setStationName(station.name ?? "");
  }, [station]);

  return (
    <ModalBasic
      id="instantiation-modal"
      title="Station Management"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      classOverride="flex flex-col md:w-[600px]"
      keepOpen
    >
      {user && [UserRole.Admin, UserRole.Member].includes(user.role) ? (
        <div>
          <div className="flex w-full flex-col space-y-3 px-5 py-5">
            <label className="-mb-2 block text-sm font-medium" htmlFor="email">
              {" "}
              Selected Station{" "}
            </label>
            {/* Dropdown Selection of Station ID */}
            <div className="flex justify-between gap-2 whitespace-nowrap">
              <Select.Root
                value={station?.id || ""}
                onValueChange={(value) => {
                  if (process?.id !== undefined && location.pathname.split("/")?.[3] === process?.id) {
                    navigate(`/production/${value}/${process?.id}${location.search}`);
                  } else {
                    navigate(`/production/${value}`);
                  }
                }}
              >
                <Select.Trigger className="w-full">
                  <Select.Value placeholder="-" />
                </Select.Trigger>
                <Select.Content>
                  <Select.ScrollUpButton />
                  {db.stations
                    .filter((s) => s.is_active)
                    .map((stationOption, index) => (
                      <Select.Item key={index} value={stationOption.id}>
                        {stationOption.id === station?.id ? stationName : stationOption.name}
                        {stationOption?.is_locked ? " (Locked)" : ""}
                      </Select.Item>
                    ))}
                  <Select.ScrollDownButton />
                </Select.Content>
              </Select.Root>
              {user?.role === UserRole.Admin && (
                <button
                  className="btn text-serial-palette-600 bg-serial-palette-200 hover:bg-serial-palette-300 h-9 w-fit "
                  onClick={() => handleCreateStation()}
                >
                  Create New Station
                </button>
              )}
            </div>
            <div>
              <label className="mb-1 block text-sm font-medium" htmlFor="email">
                {" "}
                Station Name{" "}
              </label>
              <input
                name="station-name"
                className="form-input w-full"
                type="text"
                value={stationName}
                onChange={(e) => {
                  setStationName(e.target.value);
                }}
                placeholder="New Station Name"
                required
              />
            </div>
            <div>
              <label className="mb-1 block text-sm font-medium" htmlFor="email">
                {" "}
                Station ID{" "}
              </label>
              <input
                name="ip"
                className="form-input text-serial-palette-400 bg-serial-palette-50 w-full"
                disabled
                type="text"
                value={station?.id || ""}
              />
            </div>
          </div>
          {/* Footer */}
          <div className="flex justify-between border-t px-5 py-3">
            <button
              onClick={() => {
                setConfigureStation(true);
                setModalOpen(false);
              }}
              className="btn bg-serial-palette-800 hover:bg-serial-palette-600 gap-x-2 text-white"
            >
              <FontAwesomeIcon icon={faGear} />
              Configure
            </button>
            <div className="flex justify-end gap-x-2">
              <button
                className="btn border-serial-palette-200 hover:border-serial-palette-300 text-serial-palette-600"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(false);
                }}
              >
                Cancel
              </button>
              {db.stations.filter((s) => s.is_active).length > 0 && (
                <button
                  className="btn bg-serial-palette-800 hover:bg-serial-palette-600 whitespace-no-wrap w-16 text-white"
                  onClick={() => handleSave()}
                >
                  {isLoading ? <Loader styleOverride="w-[18px] h-[18px]" /> : "Save"}
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-36 items-center justify-center">
          {!bannerDismissed && (
            <Banner.Root variant="warning">
              <Banner.Text>Login as an admin or member user to change the station or setup a new station</Banner.Text>
              <Banner.Dismiss
                handleDismiss={() => {
                  setBannerDismissed(true);
                  setModalOpen(false);
                }}
              />
            </Banner.Root>
          )}
        </div>
      )}
    </ModalBasic>
  );
};

export default ProductionModalStation;
