import { makeFetchRequest } from "@shared/connections/api/helpers";
import { ParsedResponse } from "@shared/types/apiTypes";

const API_URL = import.meta.env.VITE_APP_SERIAL_API_URL;

export const deleteComponentInstanceLink = async (
  parentComponentInstanceId: string,
  childComponentInstanceId: string,
): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/components/instances/links?parent_component_instance_id=${parentComponentInstanceId}&child_component_instance_id=${childComponentInstanceId}`,
    {
      method: "DELETE",
    },
    "Could not delete component instance link",
  );
};

export const getComponentProperties = async (componentId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/components/${componentId}/properties`,
    {
      method: "GET",
    },
    "Could not get component properties",
  );
};

export const getComponentInstanceProperties = async (componentInstanceId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/components/instances/${componentInstanceId}/properties`,
    {
      method: "GET",
    },
    "Could not get component instance properties",
  );
};

export const updatePropertyValue = async (propertyId: string, value: string, componentInstanceId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/components/instances/${componentInstanceId}/properties/${propertyId}`,
    {
      method: "PUT",
      body: JSON.stringify({ value }),
    },
    "Could not update property",
  );
};

export const updatePropertyName = async (propertyId: string, name: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/datasets/${propertyId}`,
    {
      method: "PATCH",
      body: JSON.stringify({ name }),
    },
    "Could not update property",
  );
};

export const createNewProperty = async (
  componentId: string,
  name: string,
  type: string = "TEXT",
  id: string = "",
): Promise<ParsedResponse> => {
  const body = id ? JSON.stringify({ name, type, id }) : JSON.stringify({ name, type });
  return makeFetchRequest(
    `${API_URL}/components/${componentId}/properties`,
    {
      method: "POST",
      body: body,
    },
    "Could not create property",
  );
};

export const deactivateProperty = async (propertyId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/datasets/${propertyId}`,
    {
      method: "PATCH",
      body: JSON.stringify({ is_active: false }),
    },
    "Could not delete property",
  );
};

export const updateLocation = async (componentInstanceId: string, latitude: number, longitude: number): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/components/instances/${componentInstanceId}`,
    {
      method: "PATCH",
      body: JSON.stringify({ latitude, longitude }),
    },
    "Could not update location",
  );
};

export const addCommentToComponentInstance = async (comment: string, componentInstanceId: string): Promise<ParsedResponse> => {
  return makeFetchRequest(
    `${API_URL}/components/instances/${componentInstanceId}/comments/`,
    {
      method: "POST",
      body: JSON.stringify({ comment }),
    },
    "Could not add comment",
  );
};
