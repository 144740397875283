import React, { useContext, useMemo } from "react";
import { WorkOrderContext } from "../WorkOrders";
import WorkOrderSummaryTableRow from "./WorkOrderSummaryTableRow";
import WorkOrderStatusTag from "./WorkOrderStatusTag";
import { workOrderStatusStyle } from "../contants";
import { WorkOrder } from "@shared/types/databaseTypes";
import { WorkOrderStatus } from "@shared/types/databaseEnums";

interface WorkOrderSummaryTableGroupProps {
  status: WorkOrderStatus;
  searchTerm?: string;
}

const WorkOrderSummaryTableGroup: React.FC<WorkOrderSummaryTableGroupProps> = ({ status, searchTerm }) => {
  const context = useContext(WorkOrderContext);

  const filteredWorkOrders = useMemo(() => {
    if (context?.workOrders) {
      return context.workOrders.filter((workOrder: WorkOrder) => {
        return workOrder.name.toLowerCase().includes(searchTerm?.toLowerCase() || "") && workOrder.status === status;
      });
    }
    return [];
  }, [context?.workOrders, searchTerm]);

  return (
    <>
      <tr className="border-t">
        <td colSpan={6} className="bg-serial-palette-50 text-serial-palette-500 whitespace-nowrap rounded-b px-5 py-1.5">
          <div className="flex items-center gap-2 text-left text-xs uppercase">
            <WorkOrderStatusTag status={status} />
            <p className="pl-0.5 pt-0.5 font-semibold">{workOrderStatusStyle[status].name}</p>
            <p className="pt-0.5">{context?.workOrders?.filter((wo: WorkOrder) => wo.status === status).length}</p>
          </div>
        </td>
      </tr>
      {filteredWorkOrders.map((workOrder: WorkOrder, index: number) => {
        return <WorkOrderSummaryTableRow key={index} workOrder={workOrder} />;
      })}
    </>
  );
};

export default WorkOrderSummaryTableGroup;
