import React, { createContext, useContext } from "react";
import { User } from "@shared/types/databaseTypes";
import Tooltip from "./Tooltip";

interface UserAvatarProps {
  user: User | undefined;
  size: "xs" | "sm" | "md" | "lg";
  tooltip?: boolean | string;
  children?: React.ReactNode;
}

const UserAvatarContext = createContext<{
  size: "xs" | "sm" | "md" | "lg";
  user?: User;
}>({
  user: undefined,
  size: "md",
});

const sizeClasses = {
  xs: { w: "w-6", h: "h-6", fallbackText: "text-xs", nameText: "text-sm" },
  sm: { w: "w-8", h: "h-8", fallbackText: "text-sm", nameText: "text-md" },
  md: { w: "w-12", h: "h-12", fallbackText: "text-base", nameText: "text-lg" },
  lg: { w: "w-20", h: "h-20", fallbackText: "text-xl", nameText: "text-2xl" },
};

const UserAvatarRoot: React.FC<UserAvatarProps> = ({ user, size, tooltip, children }) => {
  const contents = <div className="relative flex h-fit items-center gap-2">{children ? children : <UserAvatarImage />}</div>;

  return (
    <UserAvatarContext.Provider value={{ user, size }}>
      {tooltip ? (
        <Tooltip.Root>
          <Tooltip.Trigger>{contents}</Tooltip.Trigger>
          <Tooltip.Content>
            {typeof tooltip === "string" ? tooltip : tooltip ? `${user?.first_name} ${user?.last_name}` : null}
          </Tooltip.Content>
        </Tooltip.Root>
      ) : (
        contents
      )}
    </UserAvatarContext.Provider>
  );
};

const UserAvatarName = () => {
  const { user, size } = useContext(UserAvatarContext);
  return (
    <div className={`font-medium ${sizeClasses[size].nameText}`}>
      {user?.first_name} {user?.last_name}
    </div>
  );
};

const UserAvatarImage = () => {
  const { user, size } = useContext(UserAvatarContext);
  const abbreviation = `${user?.first_name?.substring(0, 1).toUpperCase()}${user?.last_name?.substring(0, 1).toUpperCase()}`;

  return (
    <div
      className={`rounded-full ${sizeClasses[size].w} ${sizeClasses[size].h} flex items-center justify-center overflow-hidden bg-gray-200`}
    >
      {user?.url ? (
        <img className="h-full max-h-64 w-full max-w-64 object-cover" src={user?.url} alt="Avatar" />
      ) : (
        <span className={`text-black ${sizeClasses[size].fallbackText} font-semibold`}>{abbreviation}</span>
      )}
    </div>
  );
};

const UserAvatar = Object.assign(UserAvatarRoot, {
  Root: UserAvatarRoot,
  Name: UserAvatarName,
  Image: UserAvatarImage,
});

export default UserAvatar;
