import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  FilterConditionValueOption,
  allowedFilterConditionOperators,
  filterConditionOperatorStyle,
  numericalRangeOperators,
} from "./types";
import TypeTag from "@shared/components/TypeTag";
import FilterConditionBlock from "./FilterConditionBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FilterCondition, FilterConditionOperator, FilterJoinOperator } from "@shared/types/databaseTypes";

enum DropdownMode {
  Filters = "FILTERS",
  Values = "VALUES",
  Operators = "OPERATORS",
}

interface FilterDropdownProps {
  filterConditions: FilterCondition[];
  filterJoinOperator: FilterJoinOperator;
  filterConditionOptions: FilterCondition[];
  filterConditionValueOptions: { [filterId: string]: FilterConditionValueOption[] };
  buttonContents?: React.ReactNode;
  align: "left" | "right";
  handleSetFilterJoinOperator: (joinOperator: FilterJoinOperator) => void;
  handleAddFilterCondition: (filterCondition: FilterCondition) => void;
  handleRemoveFilterCondition: (filterConditionId: string) => void;
  handleSetFilterConditionValue: (filterConditionId: string, value: string, label: string, operator?: FilterConditionOperator) => void;
  handleSetFilterConditionOperator: (filterConditionId: string, operator: FilterConditionOperator) => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  filterConditions,
  filterJoinOperator,
  filterConditionOptions,
  filterConditionValueOptions,
  buttonContents,
  align,
  handleSetFilterJoinOperator,
  handleAddFilterCondition,
  handleRemoveFilterCondition,
  handleSetFilterConditionValue,
  handleSetFilterConditionOperator,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [subDropdownMode, setSubDropdownMode] = useState<DropdownMode | null>(DropdownMode.Filters);
  const [dropdownPosition, setDropdownPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [focusedFilterConditionId, setFocusedFilterConditionId] = useState<string | null>(null);
  const [filterValueInputText, setFilterValueInputText] = useState<string>("");
  const [displayedFilterConditionValueOptions, setDisplayedFilterConditionValueOptions] = useState<FilterConditionValueOption[]>([]);

  const handleSetSubDropdown = (mode?: DropdownMode, focusedFilterConditionId?: string) => {
    if (mode) setSubDropdownMode(mode);
    if (focusedFilterConditionId) setFocusedFilterConditionId(focusedFilterConditionId);
    if (mode === DropdownMode.Values && focusedFilterConditionId) {
      setFilterValueInputText("");
      setDisplayedFilterConditionValueOptions(filterConditionValueOptions[focusedFilterConditionId].slice(0, 5) ?? []);
    }
  };

  const handleUseInputAsFilterValue = () => {
    if (!focusedFilterConditionId) return;
    // check if filter type is a numerical range filter and input is a valid number
    const filterCondition = filterConditions.find((filterCondition) => filterCondition.id === focusedFilterConditionId);
    if (filterCondition && numericalRangeOperators.includes(filterCondition.operator) && !isNaN(Number(filterValueInputText))) {
      handleSetFilterConditionValue(focusedFilterConditionId, filterValueInputText, filterValueInputText);
      setSubDropdownMode(null);
      return;
    }
  };

  const handleSetFilterValueInput = (value: string) => {
    setFilterValueInputText(value);
    if (focusedFilterConditionId) {
      const updatedDisplayedFilterConditionValueOptions = filterConditionValueOptions[focusedFilterConditionId].filter((option) =>
        option.label.includes(value),
      );
      setDisplayedFilterConditionValueOptions(updatedDisplayedFilterConditionValueOptions.slice(0, 5));
    }
  };

  const focusedFilter = useMemo(() => {
    return filterConditions.find((filterCondition) => filterCondition.id === focusedFilterConditionId);
  }, [focusedFilterConditionId]);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOpenDropdown = () => {
    if (!buttonRef.current) return;
    const { x, y, right } = buttonRef.current.getBoundingClientRect();
    const xPosition = align === "left" ? x : right - 384; // 384 is the width of the dropdown
    setDropdownPosition({ x: xPosition, y });
    setShowDropdown(true);
  };

  // close on mouse down
  useEffect(() => {
    const clickHandler = ({ target }: any) => {
      if (!dropdownRef.current || !buttonRef.current) return;
      if (!showDropdown || dropdownRef.current.contains(target) || buttonRef.current.contains(target)) return;
      setShowDropdown(false);
    };
    document.addEventListener("mousedown", clickHandler);
    return () => document.removeEventListener("mousedown", clickHandler);
  });

  return (
    <div className="flex">
      <button ref={buttonRef} className="flex h-full" onClick={() => handleOpenDropdown()} tabIndex={-1}>
        {buttonContents ?? (
          <div className={`btn-sm h-7 ${filterConditions.length === 0 ? "serial-btn-light" : "serial-btn-dark"} items-center gap-1`}>
            <FontAwesomeIcon icon={faFilter} />
            <span className="ml-1 text-sm">Filters</span>
            {filterConditions.length > 0 && <span className="text-sm">({filterConditions.length})</span>}
          </div>
        )}
      </button>
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="border-serial-palette-200 z-20 flex w-96 flex-col overflow-hidden rounded-md border bg-white shadow-lg"
          style={{ position: "fixed", left: `${dropdownPosition.x}px`, top: `${dropdownPosition.y + 32}px` }}
        >
          <div className={`bg-serial-palette-50 z-10 flex flex-wrap gap-1 border-b p-2 ${filterConditions.length === 0 && "justify-end"}`}>
            {filterConditions.map((filterCondition, index) => {
              return (
                <React.Fragment key={index}>
                  <FilterConditionBlock
                    filterCondition={filterCondition}
                    focusedButton={
                      focusedFilterConditionId === filterCondition.id && showDropdown && subDropdownMode !== DropdownMode.Filters
                        ? subDropdownMode
                        : null
                    }
                    handleTriggerOperatorSelector={() => handleSetSubDropdown(DropdownMode.Operators, filterCondition.id)}
                    handleTriggerValueSelector={() => handleSetSubDropdown(DropdownMode.Values, filterCondition.id)}
                    handleRemoveFilterCondition={handleRemoveFilterCondition}
                  />
                  {index < filterConditions.length - 1 && (
                    <button
                      className="text-serial-palette-500 btn-xs hover:bg-serial-palette-200 h-8 px-1 text-sm font-medium"
                      onClick={() =>
                        handleSetFilterJoinOperator(
                          filterJoinOperator === FilterJoinOperator.And ? FilterJoinOperator.Or : FilterJoinOperator.And,
                        )
                      }
                    >
                      {filterJoinOperator === FilterJoinOperator.And ? "and" : "or"}
                    </button>
                  )}
                </React.Fragment>
              );
            })}
            <button
              className="text-serial-palette-500 btn-xs hover:bg-serial-palette-200 h-8 px-1.5 text-sm font-medium"
              onClick={() => handleSetSubDropdown(DropdownMode.Filters)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          <div className={`scrollbar-hide flex w-full flex-col overflow-auto transition-all ${subDropdownMode !== null ? "h-48" : "h-0"}`}>
            {/* Add New Filter */}
            {subDropdownMode === DropdownMode.Filters &&
              filterConditionOptions.map((filterCondition, index) => {
                return (
                  <button
                    key={index}
                    className={`btn-sm focus:bg-serial-palette-100 hover:bg-serial-palette-100 flex w-full cursor-pointer justify-start bg-white first:mt-2 focus:outline-none`}
                    onClick={() => {
                      handleAddFilterCondition(filterCondition);
                      setSubDropdownMode(null);
                    }}
                  >
                    <TypeTag type={filterCondition.type} hideText className="mr-2 h-5 w-8 text-[10px]" />
                    <span className="truncate whitespace-nowrap px-1 font-normal">{filterCondition.name}</span>
                  </button>
                );
              })}
            {/* Select Operator */}
            {subDropdownMode === DropdownMode.Operators &&
              focusedFilter &&
              allowedFilterConditionOperators[focusedFilter.type].map((operator: FilterConditionOperator, index: number) => {
                return (
                  <div
                    key={index}
                    className="btn-sm hover:bg-serial-palette-100 flex cursor-pointer justify-start bg-white first:mt-2"
                    onClick={() => {
                      handleSetFilterConditionOperator(focusedFilter.id, operator);
                      setSubDropdownMode(null);
                    }}
                  >
                    <span className="w-7 whitespace-nowrap font-bold">{filterConditionOperatorStyle[operator].symbol}</span>
                    <span className="whitespace-nowrap font-normal">{filterConditionOperatorStyle[operator].label}</span>
                  </div>
                );
              })}
            {/* Select Value */}
            {subDropdownMode === DropdownMode.Values && focusedFilterConditionId && (
              <>
                <div className="mx-2 mt-2">
                  <input
                    className={`form-input w-full`}
                    placeholder="Enter value..."
                    onChange={(e) => handleSetFilterValueInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleUseInputAsFilterValue();
                    }}
                    value={filterValueInputText}
                  />
                </div>
                {displayedFilterConditionValueOptions.map((option, index) => {
                  return (
                    <button
                      key={index}
                      className={`btn-sm focus:bg-serial-palette-100 hover:bg-serial-palette-100 flex w-full cursor-pointer justify-start bg-white focus:outline-none ${index === 0 && "mt-2"}`}
                      onClick={() => {
                        const filterCondition = filterConditions.find((filterCondition) => filterCondition.id === focusedFilterConditionId);
                        handleSetFilterConditionValue(focusedFilterConditionId, option.value, option.label, filterCondition?.operator);
                        setSubDropdownMode(null);
                      }}
                    >
                      <span className="truncate whitespace-nowrap px-1 font-normal">{option.label}</span>
                    </button>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
