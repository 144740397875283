import { useContext } from "react";
import { ProcessBuilderContext } from "../../ProcessBuilderProvider";
import { TextBlock } from "@shared/types/databaseTypes";
import { ProcessBuilderWorkInstructionBlockContentProps } from "../../types";
import { BaseMentionOption, DatasetMentionOption, MentionType, useMentions } from "@shared/hooks/useMentions";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import { MAX_WORK_INSTRUCTION_MENTIONS } from "../../constants";
import { workInstructionBlocksToMarkdown } from "../../helpers";
import { generateWorkInstructionsSuggestions } from "../../connections/api";

const ProcessBuilderWorkInstructionBlockText: React.FC<ProcessBuilderWorkInstructionBlockContentProps<TextBlock>> = ({
  block,
  stepIndex,
  blockIndex,
  placeholderInputRef,
}) => {
  const { readOnly, handleUpdateWorkInstructionBlocks, handleDeleteWorkInstructionBlock, component, draftProcess, previewPartNumber, componentDatasets } =
    useContext(ProcessBuilderContext);
  const datasets = useSelector((state: RootState) => state.db.datasets);

  const mentionOptions = component?.pn_metadata_dataset_ids.concat(componentDatasets.map((dataset) => dataset.id))
    .map((datasetId) => {
      const dataset = datasets.find((dataset) => dataset.id === datasetId);
      if (!dataset) return undefined;
      const mentionOption: DatasetMentionOption = {
        id: dataset.id,
        name: dataset.name,
        type: MentionType.Dataset,
        data: dataset,
      };
      return mentionOption;
    })
    .filter((mentionOption) => mentionOption !== undefined) as DatasetMentionOption[];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Backspace" && block.content.text === "") {
      handleDeleteWorkInstructionBlock(stepIndex, blockIndex);
    }
    if (event.key === "Enter") {
      event.preventDefault();
      placeholderInputRef?.current?.focus();
    }
  };

  const getCopilotSuggestion = async () => {
    const workInstructionsPriorToCurrentStep =
      draftProcess?.process_steps.slice(0, stepIndex).flatMap((step) => step.work_instruction_blocks) ?? [];
    const workInstructionsUpToCursor = draftProcess?.process_steps[stepIndex]?.work_instruction_blocks?.slice(0, blockIndex + 1) ?? [];
    const markdownWorkInstructions = workInstructionBlocksToMarkdown([
      ...workInstructionsPriorToCurrentStep,
      ...workInstructionsUpToCursor,
    ]);
    return generateWorkInstructionsSuggestions(markdownWorkInstructions);
  };

  const { MentionList, EditableContent, contentRef } = useMentions({
    types: [MentionType.Dataset],
    encodedContent: block.content.text,
    onInput: (string: string, mentions: BaseMentionOption[]) => {
      if (string === block.content.text) return;
      handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "text", string, true);
      for (let index = 0; index < MAX_WORK_INSTRUCTION_MENTIONS; index++) {
        const keyName = `dataset_ref_${index + 1}`;
        if (mentions[index] === undefined) {
          handleUpdateWorkInstructionBlocks(stepIndex, [block.id], keyName, null, false);
        } else {
          handleUpdateWorkInstructionBlocks(stepIndex, [block.id], keyName, mentions[index].id, false);
        }
      }
    },
    options: mentionOptions,
    onKeyDown: handleKeyDown,
    className: "outline-0 w-full",
    maxMentions: MAX_WORK_INSTRUCTION_MENTIONS,
    copilot: true,
    // concat the suggestion to the content because content prop in the ContentEditable component is not used with useMentions
    handleAcceptCopilotSuggestion: (newContent) =>
      handleUpdateWorkInstructionBlocks(stepIndex, [block.id], "text", `${block.content.text}${newContent}`, true),
    getCopilotSuggestion: getCopilotSuggestion,
    partNumber: previewPartNumber ?? undefined,
  });

  const ContentComponent = readOnly ? (
    <div className="w-full outline-0" style={{ pointerEvents: "none" }}>
      {block.content.text}
    </div>
  ) : (
    EditableContent
  );

  return (
    <div
      className="flex w-full"
      id={block.id}
      style={{ pointerEvents: readOnly ? "none" : "auto" }}
      onClick={() => contentRef.current?.focus()}
    >
      {ContentComponent}
      {MentionList}
    </div>
  );
};

export default ProcessBuilderWorkInstructionBlockText;
