import { Parametric } from "../../features/sn-lookup/types";
import store from "@shared/redux/store";
import { getSupabase } from "./supabaseAuth";
import {
  Checkbox,
  File,
  Image,
  ParametricQualitative,
  ParametricQuantitative,
  DatetimeData,
  ProcessEntry,
  UniqueIdentifier,
  UniqueIdentifierLink,
} from "@shared/types/databaseTypes";
import { MAX_IN_UUIDS } from "@shared/constants/supabase";

// Helper function to chunk an array
const chunkArray = (array: any[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

// ------------------- READ FUNCTIONS ------------------- //

export const fetchLatestProcessEntry = async (specificUniqueIdentifierId: string, processId: string): Promise<ProcessEntry | null> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Process Data Query
    const { data: queryData, error } = await supabase
      .from("process_entries")
      .select(
        "*, processes:processes( * ), stations:station_id( * ), unique_identifiers:unique_identifier_id( identifier, part_number_id )",
      )
      .eq("unique_identifier_id", specificUniqueIdentifierId)
      .eq("process_id", processId)
      .eq("upload_error", false)
      .order("timestamp", { ascending: false })
      .limit(1)
      .single();

    // Handle request and DB errors
    if (error) {
      console.error(error);
      return null;
    }
    return queryData;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchImageDataBulk = async (uniqueIdentifierIds: string[], processEntryIds?: string[]): Promise<Image[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const idChunks = chunkArray(uniqueIdentifierIds, MAX_IN_UUIDS);

  try {
    // Fetch data for each chunk in parallel
    const imageDataChunks = await Promise.all(
      idChunks.map(async (idChunk) => {
        const query = supabase.from("images").select("*, dataset:dataset_id(*)").in("unique_identifier_id", idChunk);
        if (processEntryIds) {
          query.in("process_entry_id", processEntryIds);
        }

        const { data: queryData, error } = await query;

        if (error) {
          console.error("Error fetching a chunk of images", error);
          return [];
        }
        return queryData ?? [];
      }),
    );

    // Flatten the array of image data chunks and sort
    const imageData = imageDataChunks.flat();
    return imageData.sort((a: any, b: any) => a.dataset?.order - b.dataset?.order) as Image[];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchImageDownloadUrls = async (images: Image[], quality: number): Promise<Image[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const newImages = [...images];

  // Iterate through image and get image url for each
  await Promise.all(
    images.map(async (image, index) => {
      const { data, error } = await supabase.storage.from(image.bucket_name).download(`${image.company_id}/${image.file_id ?? image.id}`, {
        transform: { quality: quality ?? 100, width: 1000, resize: "contain" },
      });
      if (!error) {
        const blob = new Blob([data], { type: data.type });
        newImages[index].url = URL.createObjectURL(blob);
      } else {
        console.error(error);
        return [];
      }
    }),
  );

  return newImages;
};

export const fetchFileDataBulk = async (uniqueIdentifierIds: string[], processEntryIds?: string[]): Promise<File[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const idChunks = chunkArray(uniqueIdentifierIds, MAX_IN_UUIDS);

  try {
    // Fetch data for each chunk in parallel
    const fileDataChunks = await Promise.all(
      idChunks.map(async (idChunk) => {
        const query = supabase
          .from("files")
          .select("*, dataset:dataset_id(*), process_entry:process_entry_id(*)")
          .in("unique_identifier_id", idChunk);
        if (processEntryIds) {
          query.in("process_entry_id", processEntryIds);
        }

        const { data: queryData, error } = await query;

        if (error) {
          console.error("Error fetching a chunk of files", error);
          return [];
        }

        return queryData ?? [];
      }),
    );

    // Flatten the array of file data chunks
    return fileDataChunks.flat() as File[];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchFileDownloadUrls = async (files: File[]) => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const newFiles = [...files];

  // Iterate through image and get image url for each
  await Promise.all(
    files.map(async (file, index) => {
      const { data, error } = await supabase.storage.from(file.bucket_name).download(`${file.company_id}/${file.file_id ?? file.id}`);
      if (!error) {
        const blob = new Blob([data], { type: data.type });
        newFiles[index].url = URL.createObjectURL(blob);
      } else {
        console.error(error);
        return [];
      }
    }),
  );

  return newFiles;
};

export const fetchLinkDataBulk = async (uniqueIdentifierIds: string[], processEntryIds: string[]): Promise<UniqueIdentifierLink[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const idChunks = chunkArray(uniqueIdentifierIds, MAX_IN_UUIDS);
  let allLinks: UniqueIdentifierLink[] = [];

  const fetchPromises = idChunks.map(async (idChunk) => {
    const { data: queryData, error } = (await supabase
      .from("unique_identifier_links")
      .select("*, dataset:dataset_id(*), unique_identifiers:has_child_of_id(*)")
      .in("unique_identifier_id", idChunk)
      .in("process_entry_id", processEntryIds)) as any;

    if (error || !queryData) {
      console.error("Error getting links for this process");
      console.error(error);
      return;
    }

    const uniqueIdentifierLinks = [...queryData] as UniqueIdentifierLink[];

    uniqueIdentifierLinks.map(async (link, index) => {
      const { data: componentInfoData, error: errorComponentInfo } = await supabase
        .from("unique_identifiers")
        .select("*, component:component_id(*)")
        .eq("id", link.has_child_of_id)
        .limit(1)
        .returns<UniqueIdentifier[]>();
      uniqueIdentifierLinks![index].linked_component_name = componentInfoData?.[0]?.component?.name;

      if (errorComponentInfo) {
        console.error(errorComponentInfo);
        return [];
      }
    });
    return uniqueIdentifierLinks;
  });
  const results = await Promise.all(fetchPromises);

  results.forEach((result) => {
    if (result) {
      allLinks = [...allLinks, ...result];
    }
  });

  return allLinks;
};

// Fetch Parametric quantitative data from backend for unique identifier IDs
export const fetchParametricQuantitativeDataBulk = async (
  uniqueIdentifierIds: string[],
  processEntryIds: string[],
): Promise<ParametricQuantitative[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const idChunks = chunkArray(uniqueIdentifierIds, MAX_IN_UUIDS);
  let allData: ParametricQuantitative[] = [];

  const fetchPromises = idChunks.map((idChunk) => {
    return supabase
      .from("parametric_quantitative")
      .select("*, dataset:dataset_id(*)")
      .in("unique_identifier_id", idChunk)
      .in("process_entry_id", processEntryIds);
  });

  const results = await Promise.all(fetchPromises);

  results.forEach(({ data, error }) => {
    if (data) {
      allData = [...allData, ...data];
    } else {
      console.error(error);
    }
  });

  return allData.sort((a: any, b: any) => a.datasets?.order - b.datasets?.order);
};

// Fetch Parametric qualitative data from backend for unique identifier IDs
export const fetchParametricQualitativeDataBulk = async (
  uniqueIdentifierIds: string[],
  processEntryIds: string[],
): Promise<ParametricQualitative[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const idChunks = chunkArray(uniqueIdentifierIds, MAX_IN_UUIDS);
  let allData: ParametricQualitative[] = [];

  const fetchPromises = idChunks.map((idChunk) => {
    return supabase
      .from("parametric_qualitative")
      .select("*, dataset:dataset_id(*)")
      .in("unique_identifier_id", idChunk)
      .in("process_entry_id", processEntryIds);
  });

  const results = await Promise.all(fetchPromises);

  results.forEach(({ data, error }) => {
    if (data) {
      allData = [...allData, ...data];
    } else {
      console.error(error);
    }
  });

  return allData.sort((a: any, b: any) => a.datasets?.order - b.datasets?.order);
};
//
// Fetch DatetimeData from backend for unique identifier IDs
export const fetchDatetimeDataBulk = async (uniqueIdentifierIds: string[], processEntryIds: string[]): Promise<DatetimeData[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const idChunks = chunkArray(uniqueIdentifierIds, MAX_IN_UUIDS);
  let allData: DatetimeData[] = [];

  const fetchPromises = idChunks.map((idChunk) => {
    return supabase
      .from("datetime_data")
      .select("*, dataset:dataset_id(*)")
      .in("unique_identifier_id", idChunk)
      .in("process_entry_id", processEntryIds);
  });

  const results = await Promise.all(fetchPromises);

  results.forEach(({ data, error }) => {
    if (data) {
      allData = [...allData, ...data];
    } else {
      console.error(error);
    }
  });

  return allData.sort((a: any, b: any) => a.datasets?.order - b.datasets?.order);
};

// Fetch Checkbox data from backend for unique identifier IDs
export const fetchCheckboxDataBulk = async (uniqueIdentifierIds: string[], processEntryIds: string[]): Promise<Checkbox[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  const idChunks = chunkArray(uniqueIdentifierIds, MAX_IN_UUIDS);
  let allData: Checkbox[] = [];

  const fetchPromises = idChunks.map((idChunk) => {
    return supabase
      .from("checkboxes")
      .select("*, dataset:dataset_id(*)")
      .in("unique_identifier_id", idChunk)
      .in("process_entry_id", processEntryIds);
  });

  const results = await Promise.all(fetchPromises);

  results.forEach(({ data, error }) => {
    if (data) {
      allData = [...allData, ...data];
    } else {
      console.error(error);
    }
  });

  return allData.sort((a: any, b: any) => a.dataset?.order - b.dataset?.order);
};

// ------ Fetch by Process Entry ------ //

// fetch image data from backend for unique identifier ID
export const fetchImageDataByProcessEntry = async (id: string): Promise<Image[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Query
    const { data: queryData, error } = (await supabase
      .from("images")
      .select("*, datasets:dataset_id(*)")
      .eq("process_entry_id", id)) as any;
    if (error || !queryData) {
      console.error("Error getting images for this process");
      console.error(error);
      return [];
    }

    // Iterate through image and get image url for each
    await Promise.all(
      queryData.map(async (image: Image, index: number) => {
        const { data, error } = await supabase.storage.from("data-images").download(`${image.company_id}/${image.id}`);
        if (!error) {
          const blob = new Blob([data], { type: data.type });
          queryData[index].url = URL.createObjectURL(blob);
        } else {
          console.error(error);
        }
      }),
    );

    // Set State
    return queryData as Image[];
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};

// fetch file data from backend for unique identifier ID
export const fetchFileDataByProcessEntry = async (id: string): Promise<File[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Query
    const { data: queryData, error } = (await supabase.from("files").select("*, datasets:dataset_id(*)").eq("process_entry_id", id)) as any;
    if (error || !queryData) {
      console.error("Error getting files for this process");
      console.error(error);
      return [];
    }

    // Iterate through image and get image url for each
    await Promise.all(
      queryData.map(async (file: File, index: number) => {
        const { data, error } = await supabase.storage.from("data-files").download(`${file.company_id}/${file.id}`);
        if (!error) {
          const blob = new Blob([data], { type: data.type });
          queryData[index].url = URL.createObjectURL(blob);
        } else {
          console.error(error);
        }
      }),
    );

    // Set State
    return queryData;
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};

// fetch Parametric data from backend for unique identifier ID
export const fetchParametricDataByProcessEntry = async (id: string): Promise<Parametric[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Query
    const { data: queryQualData, error: errorQual } = (await supabase
      .from("parametric_qualitative")
      .select("*, datasets:dataset_id(*)")
      .eq("process_entry_id", id)) as any;
    // Handle database error
    if (errorQual || !queryQualData) {
      console.error(errorQual);
    } else {
      queryQualData.map((_param: any, index: number) => (queryQualData[index].is_quantitative = false));
    }

    const { data: queryQuantData, error: errorQuant } = (await supabase
      .from("parametric_quantitative")
      .select("*, datasets:dataset_id(*)")
      .eq("process_entry_id", id)) as any;
    // Handle database error
    if (errorQuant || !queryQuantData) {
      console.error(errorQuant);
    } else {
      queryQuantData.map((_param: any, index: number) => (queryQuantData[index].is_quantitative = true));
    }

    // Set State
    return [...(queryQuantData ?? []), ...(queryQualData ?? [])];
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};

// fetch Checkbox data from backend for unique identifier ID
export const fetchCheckboxDataByProcessEntry = async (id: string): Promise<Checkbox[]> => {
  const state = store.getState();
  const supabase = getSupabase(state.auth.token);

  try {
    // Query
    const { data: queryCheckboxData, error: errorCheckbox } = await supabase
      .from("checkboxes")
      .select("*, datasets:dataset_id(*)")
      .eq("process_entry_id", id)
      .order("created_at", { ascending: true });
    // Handle database error
    if (errorCheckbox || !queryCheckboxData) {
      console.error(errorCheckbox);
      return [];
    }
    return queryCheckboxData;
  } catch (error) {
    // Handle request error
    console.error(error);
    return [];
  }
};
