const MeasuresGraphConfigRetestAggregationTooltip = () => (
  <>
    <span className="font-bold">Repeated Process Entry Aggregation</span>
    <span>
      If a process has been submitted more than once, you must define how to aggregated or select which entry to use for displaying data in
      the graph. Defaults to the latest entry.
    </span>
  </>
);

export default MeasuresGraphConfigRetestAggregationTooltip;
