import React from "react";
import { LabelFormatterLabelElementProps, IdentifierElement } from "../../types";
import { PX_PER_MM } from "../../constants";

const LabelElementIdentifier: React.FC<LabelFormatterLabelElementProps<IdentifierElement>> = ({ element, identifier }) => {
  return (
    <div className="select-none whitespace-nowrap font-mono font-bold" style={{ fontSize: `${element.font_size * PX_PER_MM}px` }}>
      {identifier}
    </div>
  );
};

export default LabelElementIdentifier;
