import { faCheckCircle, faExclamationTriangle, faInfoCircle, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@shared/utils/tailwind";
import React from "react";

const bannerConfig = {
  warning: {
    color: "bg-amber-100 border-amber-200 text-amber-600",
    icon: faExclamationTriangle,
  },
  error: {
    color: "bg-rose-100 border-rose-200 text-rose-600",
    icon: faTimesCircle,
  },
  success: {
    color: "bg-emerald-100 border-emerald-200 text-emerald-600",
    icon: faCheckCircle,
  },
  info: {
    color: "bg-blue-100 border-blue-200 text-blue-500",
    icon: faInfoCircle,
  },
};

interface BannerRootProps {
  variant: "warning" | "error" | "success" | "info";
  children?: React.ReactNode;
  className?: string;
}

const BannerRoot = ({ children, className, variant }: BannerRootProps) => {
  return (
    <div className={cn("flex w-full rounded-md border px-3 py-2 text-sm", bannerConfig[variant].color, className)}>
      <div className="flex w-full items-center">
        <FontAwesomeIcon className="pr-3 text-base" icon={bannerConfig[variant].icon} />
        <div className="flex w-full items-center justify-between">{children}</div>
      </div>
    </div>
  );
};

const BannerText = ({ children }: { children: React.ReactNode }) => {
  return <span>{children}</span>;
};

const BannerDismiss = ({ handleDismiss }: { handleDismiss: () => void }) => {
  return (
    <button tabIndex={-1} className="flex items-center pl-3 opacity-70 hover:opacity-80" onClick={() => handleDismiss()}>
      <FontAwesomeIcon size="lg" icon={faTimes} />
    </button>
  );
};

const Banner = Object.assign(BannerRoot, {
  Root: BannerRoot,
  Text: BannerText,
  Dismiss: BannerDismiss,
});

export default Banner;
