import { useContext, useEffect, useState, useRef } from "react";
import { DashboardInsightContext } from "../DashboardInsightCard";
import { Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip } from "chart.js";
import { tailwindConfig } from "@shared/utils/helpers";
import moment from "moment";
import { fetchCycleTimeByComponentEdgeFunction } from "../../connections/supabaseCycleTime";
import { CycleTimeByDate } from "../../types";
import { ChartCycleTimeMetaData } from "../../types";

Chart.register(LineController, LinearScale, LineElement, Filler, Tooltip, PointElement, TimeScale);

const CycleTimeByComponent: React.FC<void> = () => {
  const canvas = useRef(null);
  const [chartData, setChartData] = useState<CycleTimeByDate>({
    dates: [],
    cycle_times: [],
    total_entries: [],
    num_unique_ids: [],
    retest_rates: [],
  });

  {
    /* Get component from context */
  }
  const context = useContext(DashboardInsightContext);
  const component_id = context.filters.componentId;
  const start_date = context.filters.dateRange?.from;
  const end_date = context.filters.dateRange?.to;

  useEffect(() => {
    const fetchData = async () => {
      if (component_id && start_date && end_date) {
        context?.setIsLoading(true);
        const cycle_time = await fetchCycleTimeByComponentEdgeFunction(component_id, start_date, end_date);
        setChartData(cycle_time);
        context?.setIsLoading(false);
      }
    };
    fetchData();
  }, [component_id, start_date, end_date]);

  useEffect(() => {
    const ctx = canvas.current;

    if (ctx === null) {
      return;
    }

    const dataset: ChartCycleTimeMetaData = {
      label: "Cycle Time",
      data: chartData.cycle_times,
      borderColor: (tailwindConfig().theme?.colors?.blue as any)?.[500],
      backgroundColor: (tailwindConfig().theme?.colors?.blue as any)?.[500],
      pointRadius: 3,
      borderWidth: 2,
      fill: false,
      spanGaps: true,
      total_entries: chartData.total_entries,
      retest_rates: chartData.retest_rates,
    };

    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: chartData.dates,
        datasets: [dataset],
      },
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 50,
          },
        },
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 10,
            },
          },
          x: {
            grid: {
              display: false,
            },
            type: "time",
            time: {
              parser: "YYYY-MM-DD",
              unit: "day",
            },
            display: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => moment(context[0].label).format("DD MMMM YYYY"),
              label: (context) => {
                const datasetLabel = context.dataset.label ?? "";
                const value = context.parsed.y !== undefined ? context.parsed.y.toFixed(1) + "s" : "N/A";
                const dataIndex = context.dataIndex;
                const totalEntries =
                  dataset.total_entries && dataset.total_entries[dataIndex] !== undefined ? dataset.total_entries[dataIndex] : "N/A";
                const retestRate =
                  dataset.retest_rates && dataset.retest_rates[dataIndex] !== undefined
                    ? dataset.retest_rates[dataIndex].toFixed(1)
                    : "N/A";
                return [`${datasetLabel}`, `Avg Cycle Time: ${value}`, `Total Output: ${totalEntries}`, `Retest Rate: ${retestRate}%`];
              },
            },
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        maintainAspectRatio: false,
      },
    });
    return () => chart.destroy();
  }, [chartData]);

  return (
    <div className="mb-10 h-full w-full grow">
      {component_id === null && start_date === null && end_date === null ? (
        <div className="flex h-full w-full items-center justify-center text-base">
          Select a component and time range to generate a yield graph
        </div>
      ) : (
        <div className="h-[440px] px-5 py-1">
          <canvas ref={canvas}></canvas>
        </div>
      )}
    </div>
  );
};

export default CycleTimeByComponent;
