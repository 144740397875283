import React, { createContext, useContext } from "react";
import Tooltip from "./Tooltip";
import { cn } from "@shared/utils/tailwind";
import { tagVariants } from "./TagBasic";

interface TagKeyValueProps {
  variant?: string;
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
  tooltip?: string;
  children?: React.ReactNode;
}

const TagKeyValueContext = createContext<{
  variant?: string;
  size: "xs" | "sm" | "md" | "lg";
  count?: number;
}>({
  variant: "NA",
  size: "md",
  count: undefined,
});

const sizeClasses = {
  xs: "h-[18px] text-[10px]",
  sm: "h-[22px] text-xs",
  md: "h-[26px] text-sm",
  lg: "h-[32px] text-md",
};

const TagKeyValueRoot: React.FC<TagKeyValueProps> = ({ variant, size = "md", tooltip, className, children }) => {
  const contents = (
    <div className={cn("flex h-8 cursor-default items-center truncate rounded-md border bg-white", sizeClasses[size], className)}>
      {children}
    </div>
  );

  return (
    <TagKeyValueContext.Provider value={{ variant, size }}>
      {tooltip ? (
        <Tooltip.Root>
          <Tooltip.Trigger asChild>{contents}</Tooltip.Trigger>
          <Tooltip.Content className="w-72 text-center">{tooltip}</Tooltip.Content>
        </Tooltip.Root>
      ) : (
        contents
      )}
    </TagKeyValueContext.Provider>
  );
};

const TagKeyValueKey = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  const { variant } = useContext(TagKeyValueContext);
  return (
    <div
      ref={ref}
      className={cn(
        "flex h-full items-center whitespace-nowrap px-2 font-semibold",
        tagVariants[variant ?? ""]?.colorClass ?? "bg-serial-palette-500 text-white",
      )}
      {...props}
    ></div>
  );
});
TagKeyValueKey.displayName = "TagKeyValueKey";

interface TagKeyValueValueProps extends React.HTMLAttributes<HTMLDivElement> {
  tooltip?: string;
}

const TagKeyValueValue = React.forwardRef<HTMLDivElement, TagKeyValueValueProps>(({ tooltip, className, ...props }, ref) => {
  const content = <div ref={ref} className="text-serial-palette-600 truncate px-2 font-medium" {...props}></div>;
  if (tooltip)
    return (
      <Tooltip.Root delayDuration={400}>
        <Tooltip.Trigger asChild>{content}</Tooltip.Trigger>
        <Tooltip.Content>{tooltip}</Tooltip.Content>
      </Tooltip.Root>
    );
  return content;
});
TagKeyValueValue.displayName = "TagKeyValueValue";

const TagKeyValue = Object.assign(TagKeyValueRoot, {
  Root: TagKeyValueRoot,
  Key: TagKeyValueKey,
  Value: TagKeyValueValue,
});

export default TagKeyValue;
