import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Banner2 from "@shared/components/Banner2";
import { updateCompanyConfig } from "@shared/connections/supabaseCompany";
import { RootState } from "@shared/redux/store";
import Switch from "@shared/components/primitives/Switch";

const SettingsIntegrations = () => {
  const [enableDymoIntegration, setEnableDymoIntegration] = useState(false);
  const [enableZebraIntegration, setEnableZebraIntegration] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const company = useSelector((state: RootState) => state.db.company);

  const handleSave = async () => {
    const newConfig = {
      ...company.config,
      enable_dymo_integration: enableDymoIntegration,
      enable_zebra_integration: enableZebraIntegration,
    };
    const updateSuccessful = await updateCompanyConfig(newConfig);
    setShowBanner(updateSuccessful);
  };

  // Update the state when the company changes
  useEffect(() => {
    if (company) {
      setEnableDymoIntegration(company?.config?.enable_dymo_integration === true);
      setEnableZebraIntegration(company?.config?.enable_zebra_integration === true);
    }
  }, [company]);

  // A timer to dismiss the banner
  useEffect(() => {
    if (showBanner) {
      setTimeout(() => {
        setShowBanner(false);
      }, 1500);
    }
  }, [showBanner]);

  return (
    <div className="flex grow flex-col justify-between">
      {/* Panel body */}
      <div className="flex flex-col space-y-6 p-6">
        <Banner2
          className="w-full text-left"
          children="Settings updated successfully"
          type="success"
          open={showBanner}
          setOpen={setShowBanner}
        />
        <div>
          {/* Label Printers */}
          <span className="mb-1 text-xl font-bold leading-snug">Label Printers</span>
          <div>
            <div className="neutral-200 flex items-center justify-between py-3">
              <div>
                <div className="font-semibold">Zebra</div>
                <div className="text-sm">
                  Enable Zebra integration for printing labels. Requires Zebra Browser Print installed and a Zebra printer.
                </div>
              </div>
              <div className="flex items-center gap-2.5">
                <div className=" text-sm italic">{enableZebraIntegration ? "On" : "Off"}</div>
                <Switch checked={enableZebraIntegration} onCheckedChange={() => setEnableZebraIntegration(!enableZebraIntegration)} />
              </div>
            </div>
            <div className="neutral-200 flex items-center justify-between py-3">
              <div>
                <div className="font-semibold">Dymo</div>
                <div className="text-sm">
                  Enable Dymo integration for printing labels. Requires Dymo Connect installed and a Dymo printer.
                </div>
              </div>
              <div className="flex items-center gap-2.5">
                <div className=" text-sm italic">{enableDymoIntegration ? "On" : "Off"}</div>
                <Switch checked={enableDymoIntegration} onCheckedChange={() => setEnableDymoIntegration(!enableDymoIntegration)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Panel footer */}
      <footer>
        <div className="border- neutral-200 flex flex-col border-t px-6 py-5">
          <div className="flex self-end">
            <button onClick={() => handleSave()} className="btn bg-serial-palette-800 hover:bg-serial-palette-600 ml-3 text-white">
              Save Changes
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SettingsIntegrations;
