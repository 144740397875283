import { useContext, useEffect, useRef, useState } from "react";
import { ProcessWithReferences, WorkInstructionBlockContent, WorkInstructionBlockType } from "@shared/types/databaseTypes";
import FileInputBox from "@shared/components/FileInputBox";
import { ProcessBuilderContext } from "../ProcessBuilderProvider";
import NotionExport from "@images/notion-export.png";
import Banner2 from "@shared/components/Banner2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@shared/components/Loader";

interface DraftWorkInstructionBlock {
  file_id: string | null;
  type: WorkInstructionBlockType;
  content: WorkInstructionBlockContent;
}

const getMarkdownLineType = (line: string): WorkInstructionBlockType => {
  if (line.startsWith("```")) return WorkInstructionBlockType.Code;
  if (/^(-|\*|\d+\.)\s/.test(line)) return WorkInstructionBlockType.List;
  if (/^(---+|\*\*\*+|___+)$/.test(line)) return WorkInstructionBlockType.Delimiter; // Updated delimiter handling
  if (/^>/.test(line)) return WorkInstructionBlockType.Callout;
  if (/(\u00a9|\u00ae|[\u2030-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g.test(line))
    return WorkInstructionBlockType.Callout; // Starts with emoji
  if (line.startsWith("![") && line.includes("](")) return WorkInstructionBlockType.Image;
  if (line.startsWith("[") && line.includes("](")) return WorkInstructionBlockType.File;
  if (line.includes("|")) return WorkInstructionBlockType.Table;
  if (line.startsWith("#")) return WorkInstructionBlockType.Heading;
  return WorkInstructionBlockType.Text;
};

const markdownToWorkInstructionBlocks = (markdown: string): DraftWorkInstructionBlock[] => {
  let workInstructionBlocks: DraftWorkInstructionBlock[] = [];
  const lines = markdown.split("\n");
  let currWorkInstructionBlock: DraftWorkInstructionBlock | null = null;
  let inCodeBlock = false;

  for (const line of lines) {
    const trimmedLine = line.trim();

    // Handle Empty Lines
    if (trimmedLine === "" && currWorkInstructionBlock?.type !== WorkInstructionBlockType.Code) {
      if (currWorkInstructionBlock) {
        workInstructionBlocks.push(currWorkInstructionBlock);
        currWorkInstructionBlock = null;
      }
      continue;
    }

    // Code block continuation
    if (trimmedLine.startsWith("```")) {
      if (!inCodeBlock || (inCodeBlock && currWorkInstructionBlock?.type === WorkInstructionBlockType.Code)) {
        inCodeBlock = !inCodeBlock; // Toggle the flag
      }
    }

    const lineType = inCodeBlock ? WorkInstructionBlockType.Code : getMarkdownLineType(trimmedLine);

    if (currWorkInstructionBlock === null || lineType !== currWorkInstructionBlock?.type) {
      if (currWorkInstructionBlock) {
        workInstructionBlocks.push(currWorkInstructionBlock);
      }
      currWorkInstructionBlock = {
        type: lineType,
        content: {},
        file_id: null,
      };
    }

    switch (lineType) {
      case WorkInstructionBlockType.Code:
        if (!trimmedLine.startsWith("```")) {
          // Add lines that are not the starting or ending backticks
          const updatedCodeString = currWorkInstructionBlock.content.text
            ? `${currWorkInstructionBlock.content.text}\n${trimmedLine}`
            : trimmedLine;
          currWorkInstructionBlock.content.text = updatedCodeString;
        }
        break;
      case WorkInstructionBlockType.List:
        currWorkInstructionBlock.content.items = [
          ...(currWorkInstructionBlock.content.items ?? []),
          trimmedLine.replace(/^(-|\*|\d+\.)\s/, ""),
        ];
        break;
      case WorkInstructionBlockType.Delimiter:
        break;
      case WorkInstructionBlockType.Callout:
        currWorkInstructionBlock.content.text = trimmedLine.replace(/^>\s/, "");
        currWorkInstructionBlock.content.icon = "INFO";
        break;
      case WorkInstructionBlockType.Image:
        const imageMatch = trimmedLine.match(/^!\[(.*?)\]\((.*?)\)$/);
        if (imageMatch && imageMatch[2]) {
          const pathParts = imageMatch[2].split("/");
          let fileName = pathParts[pathParts.length - 1]; // Gets the last part of the path
          fileName = fileName.replace("%20", " "); // Replace %20 with spaces
          currWorkInstructionBlock.content.caption = fileName;
          currWorkInstructionBlock.content.file_name = fileName;
        }
        break;
      case WorkInstructionBlockType.File:
        const fileMatch = trimmedLine.match(/^\[(.*?)\]\((.*?)\)$/);
        if (fileMatch && fileMatch[2]) {
          const pathParts = fileMatch[2].split("/");
          let fileName = pathParts[pathParts.length - 1]; // Gets the last part of the path
          fileName = fileName.replace("%20", " "); // Replace %20 with spaces
          currWorkInstructionBlock.content.caption = fileName;
          currWorkInstructionBlock.content.file_name = fileName;
        }
        break;
      case WorkInstructionBlockType.Table:
        // check for header row
        if (trimmedLine.includes("| --- |")) {
          currWorkInstructionBlock.content.header_row = true;
        } else {
          const newRow = trimmedLine
            .split("|")
            .map((cell) => cell.trim())
            .slice(1, -1);
          currWorkInstructionBlock.content.rows = [...(currWorkInstructionBlock.content.rows ?? []), newRow];
        }
        break;
      case WorkInstructionBlockType.Heading:
        const headingLevel = trimmedLine.match(/^#+/)?.[0].length ?? 1; // Calculate heading level
        currWorkInstructionBlock.content.level = Math.min(headingLevel, 3) as 1 | 2 | 3; // Limit to 3
        currWorkInstructionBlock.content.text = trimmedLine.slice(headingLevel).trim();
        break;
      case WorkInstructionBlockType.Text:
        const updatedTextString = currWorkInstructionBlock.content.text
          ? `${currWorkInstructionBlock.content.text}\n${trimmedLine}`
          : trimmedLine;
        currWorkInstructionBlock.content.text = updatedTextString;
        break;
    }
  }

  if (currWorkInstructionBlock) {
    workInstructionBlocks.push(currWorkInstructionBlock);
  }

  return workInstructionBlocks;
};

const readFileAsync = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      if (typeof result === "string") {
        resolve(result);
      } else {
        reject(new Error("File read did not return a string"));
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsText(file);
  });
};

enum StepBreakpoint {
  HeadingLevel1 = "HEADING_LEVEL_1",
  HeadingLevel2 = "HEADING_LEVEL_2",
  HeadingLevel3 = "HEADING_LEVEL_3",
  Delimiter = "DELIMITER",
  List = "LIST",
}

const ProcessBuilderWorkInstructionImport: React.FC = () => {
  const {
    handleAddNewWorkInstructionBlock,
    draftProcess,
    setDraftProcess,
    setImportSidebarOpen,
    setPulseWorkInstructions,
    importSidebarOpen,
    handleUploadFile,
    handleUpdateWorkInstructionBlocks,
  } = useContext(ProcessBuilderContext);

  const componentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ type: "error" | "warning"; message: string } | null>(null);
  const [showHelpDocs, setShowHelpDocs] = useState<boolean>(false);
  const [stepBreakpoints, setStepBreakpoints] = useState<StepBreakpoint[]>([]);
  const [draftProcessCopy, setDraftProcessCopy] = useState<ProcessWithReferences | undefined>(undefined);
  const [newDraftWorkInstructionBlocks, setNewDraftWorkInstructionBlocks] = useState<DraftWorkInstructionBlock[]>([]);
  const [fileUploadsChecklist, setFileUploadsChecklist] = useState<{ fileName: string; isUploaded: boolean }[]>([]);

  const applyNewWorkInstructionBlocks = async (
    newWorkInstructionBlocks: DraftWorkInstructionBlock[],
    step: number,
    currBreakpoints?: StepBreakpoint[],
  ) => {
    const breakpoints = currBreakpoints ?? stepBreakpoints;
    const newBlockInfo = await Promise.all(
      newWorkInstructionBlocks.map(async (draftWorkInstructionBlock, newBlockIndex) => {
        if (newBlockIndex !== 0) {
          if (
            breakpoints.includes(StepBreakpoint.HeadingLevel1) &&
            draftWorkInstructionBlock.type === WorkInstructionBlockType.Heading &&
            draftWorkInstructionBlock.content.level === 1
          ) {
            step++;
          } else if (
            breakpoints.includes(StepBreakpoint.HeadingLevel2) &&
            draftWorkInstructionBlock.type === WorkInstructionBlockType.Heading &&
            draftWorkInstructionBlock.content.level === 2
          ) {
            step++;
          } else if (
            breakpoints.includes(StepBreakpoint.HeadingLevel3) &&
            draftWorkInstructionBlock.type === WorkInstructionBlockType.Heading &&
            draftWorkInstructionBlock.content.level === 3
          ) {
            step++;
          } else if (
            breakpoints.includes(StepBreakpoint.Delimiter) &&
            draftWorkInstructionBlock.type === WorkInstructionBlockType.Delimiter
          ) {
            step++;
          } else if (breakpoints.includes(StepBreakpoint.List) && draftWorkInstructionBlock.type === WorkInstructionBlockType.List) {
            step++;
          }
        }
        let newBlockId = handleAddNewWorkInstructionBlock(
          step,
          newBlockIndex,
          draftWorkInstructionBlock.type,
          false,
          draftWorkInstructionBlock.content,
        );
        if (!newBlockId) {
          console.error("Error creating new work instruction block");
          newBlockId = "";
        } else if (newBlockId && draftWorkInstructionBlock.file_id) {
          handleUpdateWorkInstructionBlocks(step, [newBlockId], "file_id", draftWorkInstructionBlock.file_id, false);
        }
        return {
          id: newBlockId,
          stepIndex: step,
          type: draftWorkInstructionBlock.type,
          content: draftWorkInstructionBlock.content,
        };
      }),
    );
    return newBlockInfo;
  };

  const handleUploadFiles = async (files: File[]) => {
    setIsLoading(true);
    setDraftProcessCopy(JSON.parse(JSON.stringify(draftProcess)));
    const markdownFile = files.find((file) => file.name.endsWith(".md"));
    if (!markdownFile) {
      setError({ type: "error", message: "Please upload a .md file" });
      setIsLoading(false);
      return;
    }
    try {
      const content = await readFileAsync(markdownFile);
      const newWorkInstructionBlocks = markdownToWorkInstructionBlocks(content);
      setNewDraftWorkInstructionBlocks(newWorkInstructionBlocks);
      const newBlockInfo = await applyNewWorkInstructionBlocks(newWorkInstructionBlocks, draftProcess?.process_steps.length ?? 0);
      if (newBlockInfo.length !== newWorkInstructionBlocks.length) {
        throw new Error("Not all work instruction blocks were created");
      }
      const filesChecklist = [{ fileName: markdownFile.name, isUploaded: true }];
      for (const newBlock of newBlockInfo) {
        if (
          (newBlock.type === WorkInstructionBlockType.Image || newBlock.type === WorkInstructionBlockType.File) &&
          newBlock.content.file_name
        ) {
          const fileToUpload = files.find((file) => file.name === newBlock.content.file_name);
          if (fileToUpload) {
            const fileId = await handleUploadFile(fileToUpload, newBlock.type);
            if (fileId) handleUpdateWorkInstructionBlocks(newBlock.stepIndex, [newBlock.id], "file_id", fileId, false);
            filesChecklist.push({ fileName: newBlock.content.file_name, isUploaded: fileId ? true : false });
            // update file_id in newWorkInstructionBlocks
            const newWorkInstructionBlockIndex = newWorkInstructionBlocks.findIndex(
              (block) => block.content.file_name === newBlock.content.file_name,
            );
            newWorkInstructionBlocks[newWorkInstructionBlockIndex].file_id = fileId ?? null;
          } else {
            filesChecklist.push({ fileName: newBlock.content.file_name, isUploaded: false });
          }
        }
        setFileUploadsChecklist(filesChecklist);
      }
      setNewDraftWorkInstructionBlocks(newWorkInstructionBlocks);
    } catch (error) {
      console.error("Error reading notion file or converting .md to json:", error);
      setError({ type: "error", message: "There was an error reading the notion file or uploading files" });
    }
    setPulseWorkInstructions(true);
    setIsLoading(false);
  };

  const handleStepBreakpointChange = (stepBreakpoint: StepBreakpoint) => {
    let updatedStepBreakpoints: StepBreakpoint[] = [];
    if (stepBreakpoints.includes(stepBreakpoint)) {
      updatedStepBreakpoints = stepBreakpoints.filter((breakpoint) => breakpoint !== stepBreakpoint);
    } else {
      updatedStepBreakpoints = [...stepBreakpoints, stepBreakpoint];
    }
    setStepBreakpoints(updatedStepBreakpoints);
    if (newDraftWorkInstructionBlocks.length > 0) {
      setDraftProcess(draftProcessCopy);
      applyNewWorkInstructionBlocks(newDraftWorkInstructionBlocks, draftProcessCopy?.process_steps.length ?? 0, updatedStepBreakpoints);
    }
  };

  const handleCancel = () => {
    setImportSidebarOpen(false);
    setPulseWorkInstructions(false);
    setDraftProcess(draftProcessCopy);
    setNewDraftWorkInstructionBlocks([]);
    setStepBreakpoints([]);
    setFileUploadsChecklist([]);
  };

  const handleApply = () => {
    setImportSidebarOpen(false);
    setPulseWorkInstructions(false);
    setDraftProcessCopy(undefined);
    setNewDraftWorkInstructionBlocks([]);
    setStepBreakpoints([]);
    setFileUploadsChecklist([]);
  };

  useEffect(() => {
    if (!importSidebarOpen) {
      setPulseWorkInstructions(false);
    } else {
      setDraftProcessCopy(JSON.parse(JSON.stringify(draftProcess)));
    }
    setNewDraftWorkInstructionBlocks([]);
    setStepBreakpoints([]);
    setFileUploadsChecklist([]);
  }, [importSidebarOpen]);

  return (
    <div ref={componentRef} className="flex h-full w-full flex-col">
      <div className="bg-serial-palette-100 relative flex flex-col gap-y-1 border-b px-4 pt-4">
        <h3 className="text-xl font-semibold">Import Notion Document</h3>
        <div onClick={() => setShowHelpDocs(!showHelpDocs)} className="flex cursor-pointer gap-2 pb-4 text-sm">
          <FontAwesomeIcon className="pt-1" icon={showHelpDocs ? faCaretDown : faCaretRight} />
          {showHelpDocs ? (
            <div className="flex flex-col">
              <div className="flex h-full w-full"></div>
              <div className="text-md">1) From Notion, export as "Markdown & CSV"</div>
              <div className="flex w-full justify-center py-3 pl-3 pr-6">
                <img className="w-full rounded-lg" src={NotionExport} />
              </div>
              <div className="text-md pb-1">2) Upload the .md file and all attachments below</div>
              <div className="text-md pb-1">3) Select how you want to break up steps</div>
              <div className="text-md">4) Click Apply</div>
            </div>
          ) : (
            <p className="text-md">How to import?</p>
          )}
        </div>
      </div>
      {error && (
        <div className="p-2">
          <Banner2 className="w-full" type={error.type} open={error !== null} setOpen={() => setError(null)}>
            {error.message}
          </Banner2>
        </div>
      )}
      {/* 4 Checkboxes to specify how step breaks are defined (heading levels / delimiters) */}
      <div className="flex flex-col border-b p-3">
        <div className="text-md pb-1 font-bold">Break Steps At</div>
        <div className="flex flex-col text-sm">
          {Object.values(StepBreakpoint).map((stepBreakpoint, index) => {
            return (
              <div
                key={index}
                className={`flex items-center gap-x-2 ${isLoading ? "cursor-not-allowed opacity-60" : "cursor-pointer"}`}
                onClick={() => handleStepBreakpointChange(stepBreakpoint)}
              >
                <input
                  disabled={isLoading}
                  className="form-checkbox"
                  type="checkbox"
                  checked={stepBreakpoints.includes(stepBreakpoint)}
                  onChange={() => handleStepBreakpointChange(stepBreakpoint)}
                />
                <div>
                  {stepBreakpoint
                    .replaceAll("_", " ")
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {fileUploadsChecklist.length === 0 && (
        <FileInputBox
          handleUploadFiles={handleUploadFiles}
          fileInfo={null}
          className={`!rounded-lg bg-white py-4`}
          prompt="Drag and drop your .md notion export & images here"
          isLoading={isLoading}
        />
      )}
      {fileUploadsChecklist.length !== 0 && (
        <div className="flex h-full flex-col gap-y-0.5 overflow-auto p-3">
          <div className="text-md flex items-center pb-1 font-bold">
            Files Uploaded{isLoading && <Loader styleOverride="ml-2 h-[16px] w-[16px]" />}
          </div>
          {fileUploadsChecklist.map((file, index) => {
            return (
              <div key={index} className="flex gap-x-1.5">
                <FontAwesomeIcon
                  size="sm"
                  icon={file.isUploaded ? faCheckCircle : faExclamationCircle}
                  className={`mt-1 ${file.isUploaded ? "text-green-500" : "text-red-500"}`}
                />
                <div className="text-sm">{file.fileName}</div>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex w-full gap-x-1.5 p-3">
        <button className="btn-sm serial-btn-light w-1/2" onClick={() => handleCancel()}>
          Cancel
        </button>
        <button className="btn-sm serial-btn-dark w-1/2" onClick={() => handleApply()}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default ProcessBuilderWorkInstructionImport;
