import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Component, UniqueIdentifier, UniqueIdentifierLink } from "@shared/types/databaseTypes";
import { useLocation, useNavigate } from "react-router-dom";
import useMeasures from "@shared/measures/MeasuresProvider";
import { useSelector } from "react-redux";
import { RootState } from "@shared/redux/store";
import useTopLevelComponents from "@shared/hooks/useTopLevelComponents";
import { LngLatBounds } from "mapbox-gl";
import { findLocationsRecursive } from "./helpers/locations";
import { fetchLargeTable } from "@shared/connections/supabaseGeneral";
import { GeoLocation } from "./types";

interface TrackingContextProps {
  // state
  selectedComponent: Component | null;
  uniqueIdentifierLocations: { [uniqueIdentifierId: string]: GeoLocation[] };
  // state with setters
  mapBounds: LngLatBounds | null;
  setMapBounds: (mapBounds: LngLatBounds) => void;
  selectedUniqueIdentifier: UniqueIdentifier | null;
  setSelectedUniqueIdentifier: (uniqueIdentifiers: UniqueIdentifier | null) => void;
  selectedUniqueIdentifierLocationIndex: number;
  setSelectedUniqueIdentifierLocationIndex: (index: number) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  // event handlers
  handleSelectComponent: (componentId: string | null) => void;
}

// Create a default context value for TrackingContext to avoid having to initialize the context with undefined
const defaultContext: TrackingContextProps = {
  // state
  selectedComponent: null,
  uniqueIdentifierLocations: {},
  // state with setters
  mapBounds: null,
  setMapBounds: () => {},
  selectedUniqueIdentifier: null,
  setSelectedUniqueIdentifier: () => {},
  selectedUniqueIdentifierLocationIndex: 0,
  setSelectedUniqueIdentifierLocationIndex: () => {},
  sidebarOpen: false,
  setSidebarOpen: () => {},
  // event handlers
  handleSelectComponent: () => {},
};

export const TrackingContext = createContext<TrackingContextProps>(defaultContext);

const TrackingProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [selectedUniqueIdentifier, setSelectedUniqueIdentifier] = useState<UniqueIdentifier | null>(null);
  const [selectedUniqueIdentifierLocationIndex, setSelectedUniqueIdentifierLocationIndex] = useState<number>(0);
  const [mapBounds, setMapBounds] = useState<LngLatBounds | null>(null);
  const [allUniqueIdentifierLinks, setAllUniqueIdentifierLinks] = useState<UniqueIdentifierLink[]>([]);

  const topLevelComponents = useTopLevelComponents();
  const location = useLocation();
  const navigate = useNavigate();
  const { uniqueIdentifiers } = useMeasures();

  const components = useSelector((state: RootState) => state.db.components);
  const dbLoaded = useSelector((state: RootState) => state.db.isLoaded);

  const handleSelectComponent = (componentId: string | null) => {
    if (!componentId) return navigate({ search: "" });
    navigate({ search: `?component_id=${componentId}` });
  };

  const fetchAllUniqueIdentifierLinks = async () => {
    const uniqueIdentifierLinks = await fetchLargeTable<UniqueIdentifierLink>(
      "unique_identifier_links",
      "id",
      "*, unique_identifiers:unique_identifier_id(*)",
      [{ modifier: "eq", key: "is_active", value: "true" }],
    );
    setAllUniqueIdentifierLinks(uniqueIdentifierLinks);
  };

  const selectedComponent = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentId = searchParams.get("component_id");
    const component = components.find((component) => component.id === selectedComponentId) ?? null;
    return component;
  }, [location, components]);

  const uniqueIdentifierLocations: { [uniqueIdentifierId: string]: GeoLocation[] } = useMemo(() => {
    if (!selectedComponent) return {};
    const newLocations = uniqueIdentifiers.reduce<{ [uniqueIdentifierId: string]: GeoLocation[] }>((acc, uniqueIdentifier) => {
      acc[uniqueIdentifier.id] = findLocationsRecursive(uniqueIdentifier, allUniqueIdentifierLinks, selectedComponent.component_type);
      return acc;
    }, {});
    return newLocations;
  }, [uniqueIdentifiers, allUniqueIdentifierLinks, selectedComponent]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentId = searchParams.get("component_id");
    if (!selectedComponentId) {
      handleSelectComponent(topLevelComponents[0].id);
    }
  }, []);

  useEffect(() => {
    if (!dbLoaded) return;
    fetchAllUniqueIdentifierLinks();
  }, [dbLoaded]);

  return (
    <TrackingContext.Provider
      value={{
        selectedComponent,
        uniqueIdentifierLocations,
        mapBounds,
        setMapBounds,
        selectedUniqueIdentifier,
        setSelectedUniqueIdentifier,
        selectedUniqueIdentifierLocationIndex,
        setSelectedUniqueIdentifierLocationIndex,
        sidebarOpen,
        setSidebarOpen,
        handleSelectComponent,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error("useTracking must be used within a TrackingProvider");
  }
  return context;
};

export default TrackingProvider;
