import { Field, FieldType } from "@shared/types/databaseTypes";
import { useMemo } from "react";

interface UseFieldGroupsProps {
  fields: Field[] | null;
}

export interface FieldGroup {
  name: string;
  type: FieldType;
  processStepId: string;
  fields: Field[];
}

const useFieldGroups = ({ fields }: UseFieldGroupsProps): FieldGroup[] => {
  const fieldGroups = useMemo(() => {
    const groups: FieldGroup[] = [];
    let currIndex: number = -1; // -1 to force first iteration to push a new group
    for (const field of fields ?? []) {
      if ([FieldType.File, FieldType.Image, FieldType.Signature].includes(field.type)) {
        groups.push({
          name: field.group_name ?? field.prompt,
          type: field.type,
          processStepId: field.process_step_id,
          fields: [field],
        });
        currIndex++;
      } else if (
        groups[currIndex]?.name === field.group_name &&
        groups[currIndex]?.type === field.type &&
        groups[currIndex].processStepId === field.process_step_id
      ) {
        groups[currIndex].fields.push(field);
      } else {
        groups.push({
          name: field.group_name ?? field.prompt,
          type: field.type,
          processStepId: field.process_step_id,
          fields: [field],
        });
        currIndex++;
      }
    }
    return groups;
  }, [fields]);

  return fieldGroups;
};

export default useFieldGroups;
