import React, { ReactElement, useState, useRef, useEffect } from "react";

interface TooltipProps {
  position: "right" | "left" | "bottom" | "top";
  content: string | ReactElement;
  className?: string;
  style?: "light" | "dark";
  size?: "sm" | "md" | "lg";
  override?: boolean; // optional prop to force tooltip to show or hide
  timeout?: number; // optional prop to set timeout to hidden after a certain amount of time (in ms)
  children: ReactElement;
}

const MARGIN = 6;

const Tooltip: React.FC<TooltipProps> = ({ position, content, className, size, style, override, timeout, children }) => {
  const [show, setShow] = useState(override === true);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const childRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (childRef.current && tooltipRef.current && show) {
      const childRect = childRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const positions = {
        right: { top: childRect.top - MARGIN * 2, left: childRect.right },
        left: { top: childRect.top - MARGIN * 2, left: childRect.left - tooltipRect.width },
        bottom: { top: childRect.bottom, left: childRect.left + (childRect.width - tooltipRect.width) / 2 },
        top: { top: childRect.top - tooltipRect.height, left: childRect.left + (childRect.width - tooltipRect.width) / 2 },
      };

      setTooltipPosition(positions[position]);
    }
  }, [show, position]);

  // hide the tooltip after a certain amount of time
  useEffect(() => {
    if (timeout && override && show) {
      const timer = setTimeout(() => setShow(false), timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout, override, show]);

  // set showInternalState to show if show changes
  useEffect(() => {
    setShow(show);
  }, [override]);

  const widthClass = size === "lg" ? "w-72" : size === "md" ? "w-52" : "w-32";

  return (
    <div
      ref={childRef}
      className="inline-block"
      onMouseEnter={() => override === undefined && setShow(true)}
      onMouseLeave={() => override === undefined && setShow(false)}
    >
      {children}
      {show && (
        <div
          className="fixed z-10"
          ref={tooltipRef}
          style={{ padding: `${MARGIN}px`, top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}
        >
          <div
            className={`rounded p-2 text-sm shadow-sm ${style === "dark" ? "bg-serial-palette-700 text-white" : "border bg-white"} ${widthClass} ${className}`}
          >
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
