import React, { useEffect, useState } from "react";
import { ApiKey, DeletedApiKey } from "@shared/types/apiTypes";
import CopyToClipboard from "@shared/components/CopyToClipboard";
import { useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getApiKeys, deleteApiKey } from "@shared/connections/api/auth";
import SettingsApiModal from "./SettingsApiModal";
import Banner2 from "@shared/components/Banner2";
import { ErrorBanner } from "@shared/components/error/types";

interface SettingsApiProps {}

const SettingsApi: React.FC<SettingsApiProps> = () => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const supabaseToken = useSelector((state: any) => state.auth.token);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<ErrorBanner>({ isValid: true, message: "", type: "success", hide: true });

  const loadData = async () => {
    let newApiKeys: ApiKey[] = await getApiKeys();
    setApiKeys(newApiKeys);
  };

  const handleDelete = async (name: string) => {
    let deletedKey: DeletedApiKey = await deleteApiKey(name);
    if (deletedKey.name !== name) {
      setError({
        isValid: false,
        message: "API Key deletion failed. Please try again or contact Serial support.",
        type: "error",
        hide: false,
      });
    } else {
      setApiKeys(apiKeys.filter((key) => key.name !== name));
    }
  };

  useEffect(() => {
    if (supabaseToken) {
      loadData();
    }
  }, [supabaseToken]);

  return (
    <div className="flex w-full flex-col gap-y-3 overflow-clip p-6">
      <Banner2 type={error.type} open={!error.hide} setOpen={(value: boolean) => setError({ ...error, hide: value })}>
        {error.message}
      </Banner2>
      <div className="mb-3 flex w-full items-end justify-between">
        <h3 className="text-xl font-bold leading-snug">Serial API</h3>
        <button className="btn-sm bg-serial-palette-800 hover:bg-serial-palette-600 text-white" onClick={() => setModalOpen(true)}>
          <FontAwesomeIcon icon={faPlus} />
          <span className="xs:block ml-2 hidden">Create API Key</span>
        </button>
      </div>
      <div className="max-h-124 scrollbar-hide overflow-x-auto rounded border">
        <table className="w-full table-auto">
          {/* Table header */}
          <thead className="text-serial-palette-500 bg-serial-palette-50 border-serial-palette-200 border-b text-xs font-semibold uppercase">
            <tr>
              <th className="whitespace-normal px-2 py-3 text-left first:pl-5 last:pr-5">Name</th>
              <th className="whitespace-normal px-2 py-3 text-left first:pl-5 last:pr-5">Key</th>
              <th className="hidden whitespace-normal px-2 py-3 text-left first:pl-5 last:pr-5 lg:table-cell">Created</th>
              <th className="whitespace-normal px-2 py-3 text-left first:pl-5 last:pr-5">Permissions</th>
              <th className="whitespace-normal px-2 py-3 text-center first:pl-5 last:pr-5">Delete</th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="divide-serial-palette-200 divide-y text-sm">
            {apiKeys.map((keyObject, index) => {
              return (
                <tr key={index}>
                  <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">{keyObject.pretty_name}</td>
                  <td className="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <div className="flex w-fit rounded border p-0.5 pl-2">
                      <pre>
                        <div className="text-left text-sm">{`${keyObject.key.slice(0, 8)}***${keyObject.key.slice(-6)}`}</div>
                      </pre>
                      <CopyToClipboard text={keyObject.key} className="text-serial-palette-500" />
                    </div>
                  </td>
                  <td className="hidden whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 lg:table-cell">
                    {moment(keyObject.created_at).format("MM/DD/YY")}
                  </td>
                  <td className="px-2 py-3 first:pl-5 last:pr-5">
                    <div className="flex flex-nowrap gap-x-1">
                      {keyObject.permissions.map((permission, index) => {
                        return (
                          <div key={index} className="flex w-fit rounded border p-0.5 px-2">
                            <pre>
                              <div className="text-left text-xs">{permission}</div>
                            </pre>
                          </div>
                        );
                      })}
                      {(keyObject.allowed_components_ids?.length || keyObject.allowed_process_ids?.length) && (
                        <div key={index} className="flex w-fit rounded border p-0.5 px-2">
                          <pre>
                            <div className="text-left text-xs">…</div>
                          </pre>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="w-px whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                    <button
                      className="btn border-serial-palette-200 hover:border-serial-palette-300 hover:text-red-700"
                      onClick={() => handleDelete(keyObject.name)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="text-xs" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      <SettingsApiModal modalOpen={modalOpen} setModalOpen={() => setModalOpen(false)} reloadApiKeyTable={loadData} apiKeys={apiKeys} />
    </div>
  );
};

export default SettingsApi;
