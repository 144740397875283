import { ReactNode } from "react";

interface FrontendPrimitivesCard {
  children: ReactNode;
  title: string;
}

const FrontendPrimitivesCard: React.FC<FrontendPrimitivesCard> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-2 rounded-md border bg-white p-6">
      <div className="text-2xl font-bold">{title}</div>
      <div className="flex flex-col gap-3">{children}</div>
    </div>
  );
};

export default FrontendPrimitivesCard;
