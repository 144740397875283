// Get the y position of the mouse relative to the hovered element
export const getYHemisphere = (ref: React.RefObject<HTMLDivElement>, clientOffset: { x: number; y: number }): "top" | "bottom" => {
  if (ref.current) {
    const hoverBoundingRect = ref.current.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    return hoverClientY < hoverMiddleY ? "top" : "bottom";
  }
  return "bottom"; // Default or fallback value, in case ref is not set
};

// Get the x position of the mouse relative to the hovered element
export const getXHemisphere = (ref: React.RefObject<HTMLDivElement>, clientOffset: { x: number; y: number }): "left" | "right" => {
  if (ref.current) {
    const hoverBoundingRect = ref.current.getBoundingClientRect();
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    return hoverClientX < hoverMiddleX ? "left" : "right";
  }
  return "right"; // Default or fallback value, in case ref is not set
};
