import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { Checkbox, Dataset, File, Image, ParametricQualitative, ParametricQuantitative, DatetimeData } from "@shared/types/databaseTypes";
import { DataType } from "@shared/types/databaseEnums";
import { fetchDataByTableNameAndDatasetId } from "../connections/supabaseDatasets";
import { GenericData, GenericDataWithRetestNumber } from "../types";

const addRetestNumbers = (data: GenericData[]): GenericDataWithRetestNumber[] => {
  const retestCounter: { [key: string]: number } = {};
  return data.map((datapoint) => {
    const hash = `${datapoint.unique_identifier_id}_${datapoint.process_entry?.process_id}`;
    if (retestCounter[hash] !== undefined) {
      retestCounter[hash] += 1;
    } else {
      retestCounter[hash] = 0;
    }
    return {
      ...datapoint,
      retestNumber: retestCounter[hash],
    };
  });
};

export const useDatasets = () => {
  // ----------- State ----------- //
  const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(null);
  const [allData, setAllData] = useState<GenericDataWithRetestNumber[]>([]);
  const [datasetEditModalOpen, setDatasetEditModalOpen] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    setAllData([]);
    let fetchedData: GenericData[] = [];
    switch (selectedDataset?.data_type) {
      case DataType.File:
        fetchedData = await fetchDataByTableNameAndDatasetId<File>("files", selectedDataset.id);
        break;
      case DataType.Image:
        fetchedData = await fetchDataByTableNameAndDatasetId<Image>("images", selectedDataset.id);
        break;
      case DataType.Checkbox:
        fetchedData = await fetchDataByTableNameAndDatasetId<Checkbox>("checkboxes", selectedDataset.id);
        break;
      case DataType.ParametricQualitative:
        fetchedData = await fetchDataByTableNameAndDatasetId<ParametricQualitative>("parametric_qualitative", selectedDataset.id);
        break;
      case DataType.ParametricQuantitative:
        fetchedData = await fetchDataByTableNameAndDatasetId<ParametricQuantitative>("parametric_quantitative", selectedDataset.id);
        break;
      case DataType.PassFail:
        fetchedData = await fetchDataByTableNameAndDatasetId<ParametricQualitative>("parametric_qualitative", selectedDataset.id);
        break;
      case DataType.Datetime:
        fetchedData = await fetchDataByTableNameAndDatasetId<DatetimeData>("datetime_data", selectedDataset.id);
        break;
      default:
        break;
    }
    const fetchedDataWithRetestNumbers = addRetestNumbers(fetchedData);
    setAllData(fetchedDataWithRetestNumbers);
    setIsLoading(false);
  };

  // Fetch data when selected dataset changes
  useEffect(() => {
    if (selectedDataset) {
      fetchData();
    } else {
      setAllData([]);
    }
  }, [selectedDataset]);

  // filter data based off of selectedDateRange
  const filteredData = useMemo(() => {
    return allData.filter((datapoint) => {
      if (selectedDateRange?.from === undefined || selectedDateRange?.to === undefined) return true;
      const datapointDate = new Date(datapoint.process_entry?.timestamp ?? datapoint.created_at);
      return datapointDate >= selectedDateRange.from && datapointDate <= selectedDateRange.to;
    });
  }, [selectedDateRange, allData]);

  return {
    selectedDataset,
    setSelectedDataset,
    data: filteredData,
    datasetEditModalOpen,
    setDatasetEditModalOpen,
    selectedDateRange,
    setSelectedDateRange,
    isLoading,
  };
};

export default useDatasets;
